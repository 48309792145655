/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DialogTitle } from '@mui/material';
import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import PreDialog from '../../../../components/presentations/PreDialog/PreDialog';
import EvidenceForm from './EvidenceForm/EvidenceForm';
import { EvidenceFieldArrayHandles, EvidenceListEntry } from './RootQuestionEvidences';

type AddEvidenceDialogProps = {
  reportId: number;
  open: boolean;
  closeDialog: () => void;
  displayEvidenceRef: RefObject<EvidenceFieldArrayHandles>;
};

function AddEvidenceDialog({ reportId, open, closeDialog, displayEvidenceRef }: AddEvidenceDialogProps) {
  const { t } = useTranslation();
  return (
    <PreDialog type="definedByChildren" fullWidth open={open} onClose={closeDialog}>
      <DialogTitle>{t('General_AddEvidence')}</DialogTitle>

      <EvidenceForm
        reportId={reportId}
        closeDialog={closeDialog}
        onEvidencesAdded={(evidences: EvidenceListEntry[]) => {
          //@ts-ignore
          displayEvidenceRef.current.appendEvidences(evidences);
          closeDialog();
        }}
        selectedEvidenceIds={displayEvidenceRef.current?.evidences?.map((evidence) => evidence.id) ?? []}
      />
    </PreDialog>
  );
}

export default AddEvidenceDialog;
