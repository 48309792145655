/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Card, useTheme } from '@mui/material';
import { eu_taxonomy_compliance_enum } from '@predium/enums';
import { translateEuTaxonomyComplianceEnum_dynamic } from '@predium/i18n/client';
import { fShortenNumber } from '@predium/utils';
import merge from 'lodash/merge';
import sum from 'lodash/sum';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import useEsgAnalysisFilters from '../../../hooks/useEsgAnalysisFilters';
import { EsgAnalysisFiltersKeys } from '../../../sections/EsgAnalysis/EsgAnalysisFilters/EsgAnalysisFilters';
import BaseOptionChart from '../../../theme/apexcharts';
import { disableCurserPointerForMarkers } from '../PathGraph';
import { updateStylingForLegend } from './BuildingsByEnergySourceTypeGraph';

type Props = {
  scopeData: {
    euTaxonomyCompliance: eu_taxonomy_compliance_enum;
    count: number;
  }[];
  animated?: boolean;
};

export default function EuTaxonomyGraph({ scopeData, animated }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { setFilter } = useEsgAnalysisFilters();

  const labels = [
    translateEuTaxonomyComplianceEnum_dynamic(eu_taxonomy_compliance_enum.COMPLIANT, t),
    translateEuTaxonomyComplianceEnum_dynamic(eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA, t),
    translateEuTaxonomyComplianceEnum_dynamic(eu_taxonomy_compliance_enum.NON_COMPLIANT, t),
    translateEuTaxonomyComplianceEnum_dynamic(eu_taxonomy_compliance_enum.UNKNOWN, t),
  ];

  const series = scopeData.map((item) => item.count);

  const totalValue = sum(series);

  const colors = [
    theme.palette.euTaxonomyColors[eu_taxonomy_compliance_enum.COMPLIANT].light,
    theme.palette.euTaxonomyColors[eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA].light,
    theme.palette.euTaxonomyColors[eu_taxonomy_compliance_enum.NON_COMPLIANT].light,
    theme.palette.euTaxonomyColors[eu_taxonomy_compliance_enum.UNKNOWN].light,
  ];

  const chartState: ApexCharts.ApexOptions = {
    colors,
    chart: {
      animations: {
        enabled: !!animated,
      },
      type: 'donut',
      events: {
        mounted: function (chart) {
          updateStylingForLegend(chart.el.id);
          disableCurserPointerForMarkers(chart.el.id);
        },
        updated: function (chart) {
          updateStylingForLegend(chart.el.id);
          disableCurserPointerForMarkers(chart.el.id);
        },
        dataPointSelection: (_, __, config) => {
          setTimeout(() => {
            const value = config.w.config.labels[config.dataPointIndex];
            let compliance: eu_taxonomy_compliance_enum;
            switch (labels.indexOf(value)) {
              case 0:
                compliance = eu_taxonomy_compliance_enum.COMPLIANT;
                break;
              case 1:
                compliance = eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA;
                break;
              case 2:
                compliance = eu_taxonomy_compliance_enum.NON_COMPLIANT;
                break;
              case 3:
                compliance = eu_taxonomy_compliance_enum.UNKNOWN;
                break;
              default:
                compliance = eu_taxonomy_compliance_enum.UNKNOWN;
            }

            setFilter(EsgAnalysisFiltersKeys.euTaxonomyCompliances, compliance);
          });
        },
        dataPointMouseEnter: function (event) {
          event.target.style.cursor = 'pointer';
        },
      },
    },
    title: {
      text: t('General_EUTaxonomy'),
      offsetY: 5,
      margin: 20,
      style: {
        fontSize: theme.typography.h5.fontSize as string,
      },
    },
    legend: {
      position: 'right',
      formatter: (legendEntry, opts) => {
        const value = opts.w.globals.series[opts.seriesIndex];
        const shortenedValue = fShortenNumber(value);
        const percent = fShortenNumber((value / totalValue) * 100, 1);
        return `<strong>${legendEntry}</strong> <span style="font-size: 13px; color: ${theme.palette.grey[500]}">(${percent}%)</span><p style="margin-left: 15px; font-size: 13px; color: ${theme.palette.grey[500]}">${shortenedValue} </p>`;
      },
      fontSize: '18px',
      fontWeight: 600,
      fontFamily: theme.typography.fontFamily,
      offsetX: -10,
      markers: {
        offsetX: -5,
      },
      itemMargin: {
        vertical: 10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      y: {
        formatter: (value: number) => `${fShortenNumber(value)}`,
        title: {
          formatter: (seriesName: string) => seriesName,
        },
      },
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '90%',
          labels: {
            show: true,
            total: {
              show: true,
              label: t('General_Total'),
              color: theme.palette.text.secondary,
              fontSize: theme.typography.subtitle2.fontSize as string,
              fontWeight: theme.typography.subtitle2.fontWeight,
              fontFamily: theme.typography.fontFamily,
              formatter: () => fShortenNumber(totalValue),
            },
            value: {
              show: true,
              offsetY: -20,
              color: theme.palette.text.primary,
              fontSize: theme.typography.h3.fontSize as string,
              fontWeight: theme.typography.h3.fontWeight,
              fontFamily: theme.typography.fontFamily,
              formatter: (value) => fShortenNumber(value),
            },
            name: {
              show: true,
              formatter: (value) => `(${value})`,
              offsetY: 20,
              color: theme.palette.text.secondary,
            },
          },
        },
      },
    },
    labels,
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'darken',
          value: 0.5,
        },
      },
    },

    // ---------
    // 'totalValue' here is very important!
    // Reason: if totalValue changes, the ChartOptions object must be updated to reflect these changes in ApexCharts
    // https://github.com/apexcharts/react-apexcharts/issues/107#issuecomment-557318940
    //@ts-ignore
    totalValue,
    // ---------
  };

  return (
    <Card sx={{ p: 3 }}>
      <ReactApexChart
        id="scope_123_graph"
        //@ts-ignore
        type={chartState.chart.type}
        options={merge(BaseOptionChart(), chartState)}
        series={series}
        height="300px"
      />
    </Card>
  );
}
