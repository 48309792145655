import { data_source_type_enum, envelope_type_enum } from '@predium/enums';
import { accessEnum } from '@predium/utils';
import {
  DoorFormValuesProps,
  EnvelopeFormValues,
  OtherFormValuesProps,
  PitchedRoofFormValuesProps,
  WindowFormValuesProps,
} from '../BuildingEnvelope';

export const duplicateComponent = (
  fields: WindowFormValuesProps | OtherFormValuesProps | DoorFormValuesProps | PitchedRoofFormValuesProps,
) => {
  return {
    ...fields,
    create: true,
    envelope_id: null as any,
    data_source_type_id: data_source_type_enum.MANUAL,
  };
};

export function countApproximatedEnvelopes(fields: Record<any, string>[]) {
  return fields.filter(
    (field) => accessEnum(data_source_type_enum, field.data_source_type_id) !== data_source_type_enum.MANUAL,
  ).length;
}

export function areFieldArraysDirty(
  dirtyFields: Partial<EnvelopeFormValues> | EnvelopeFormValues,
  fieldArrayNames: envelope_type_enum[],
) {
  return fieldArrayNames.some((fieldName) => {
    const fieldDirty = dirtyFields[fieldName];
    return Array.isArray(fieldDirty) && fieldDirty.some((obj) => Object.keys(obj).length > 0);
  });
}

/**
 * Generates a field name based on a type, index, and subfield.
 *
 * @param {string} type - The type of the field.
 * @param {number} index - The index of the field.
 * @returns {string} The generated field name.
 */
export const generateFieldName = (type: envelope_type_enum, index: number) => {
  const fieldNamePrefix = `${type}[${index}]`;
  return {
    areaFieldName: `${fieldNamePrefix}.area`,
    deleteFieldName: `${fieldNamePrefix}.delete`,
    createFieldName: `${fieldNamePrefix}.create`,
    dataSourceTypeFieldName: `${fieldNamePrefix}.data_source_type_id`,
  };
};

export function getEnvelopeIcon(envelopeType: envelope_type_enum): string {
  switch (envelopeType) {
    case envelope_type_enum.FLAT_ROOF:
      return 'vaadin:line-h';
    case envelope_type_enum.PITCHED_ROOF:
      return 'mdi:home-roof';
    case envelope_type_enum.TOP_FLOOR_CEILING:
      return 'gis:layer';
    case envelope_type_enum.WALL:
      return 'ph:wall-light';
    case envelope_type_enum.BASEMENT_CEILING:
      return 'bi:layers-half';
    case envelope_type_enum.WINDOW:
      return 'cil:window';
    case envelope_type_enum.DOOR:
      return 'mdi:door';
    case envelope_type_enum.FLOOR:
      return 'uil:layers';
    default:
      const exhaustiveCheck: never = envelopeType;
      throw new Error(`Unhandled envelope_type_enum ${envelopeType}: ${exhaustiveCheck}`);
  }
}
