/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Chip, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import useEsgAnalysisFilters from '../../../hooks/useEsgAnalysisFilters';
import EsgAnalysisFilterValueLabel from './EsgAnalysisFilterValueLabel';
import { EsgAnalysisFiltersKeys, FindEsgAnalysisFilterValue, esgAnalysisFilters } from './EsgAnalysisFilters';

const WrapperStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  alignItems: 'stretch',
  margin: '0 8px',
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.divider}`,
}));

const LabelStyle = styled('span')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  borderRight: `solid 1px ${theme.palette.divider}`,
  fontSize: '14px',
  fontWeight: '500',
}));

type FilterOptionsAsTagsProps<Key extends EsgAnalysisFiltersKeys> = {
  title: React.ReactNode;
  type: Key;
  selectedOptions: FindEsgAnalysisFilterValue<Key>[];
};

function FilterOptionsAsTags<Key extends EsgAnalysisFiltersKeys>({
  selectedOptions,
  title,
  type,
}: FilterOptionsAsTagsProps<Key>) {
  const { setFilter } = useEsgAnalysisFilters();

  if (selectedOptions.length === 0) {
    return null;
  }

  return (
    <WrapperStyle>
      <LabelStyle>{title}:</LabelStyle>
      <Stack direction={'row'} sx={{ p: 0.75, alignItems: 'center' }}>
        {selectedOptions.map((value, index) => (
          <Chip
            key={index}
            label={<EsgAnalysisFilterValueLabel type={type} value={value} />}
            size="small"
            onDelete={() => setFilter(type, value)}
            sx={{
              m: 0.5,
              p: 0.5,
              height: '30px',
              '& .MuiChip-deleteIcon': {
                fontSize: '20px',
              },
            }}
          />
        ))}
      </Stack>
    </WrapperStyle>
  );
}

export default function EsgAnalysisFiltersTags() {
  const { filters } = useEsgAnalysisFilters();

  const chipsFilters = esgAnalysisFilters.filter((filter) => filter.showAsChip);

  return (
    <Stack direction="row">
      {chipsFilters.map(({ key, title }) => (
        //@ts-ignore
        <FilterOptionsAsTags key={key} title={title} type={key} selectedOptions={filters[key]} />
      ))}
    </Stack>
  );
}
