/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RHFDatePicker } from '../../../../components/hook-form';
import { ReportQuestionSwitchProps } from '../ReportQuestion';

function DateQuestion({ question, isSubQuestion, isEditable, autoFocus, formArrayProps }: ReportQuestionSwitchProps) {
  const { t } = useTranslation();
  const { setFocus } = useFormContext();

  //@ts-ignore
  const indexInForm = formArrayProps.fields.findIndex((field) => field._id === question.id);

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        setFocus(`questions.${indexInForm}.value`);
        // TODO: resolve same workaround as in RHFTextField.tsx
      }, 250);
    }
  }, [autoFocus, indexInForm, setFocus]);

  return (
    <>
      {isSubQuestion && (
        <Box sx={{ ml: 1 }}>
          <Typography>{question.question}</Typography>
        </Box>
      )}
      <Stack spacing={3} sx={{ p: 3 }}>
        <RHFDatePicker name={`questions.${indexInForm}.value`} label={t('General_Answer')} disabled={!isEditable} />
      </Stack>
    </>
  );
}

export default DateQuestion;
