import { useCallback, useState } from 'react';

const FIXED_DELAY = 1000;

/**
 * Hook to simulate a loading state for a short period of time.
 *
 * @example
 * const { dummyLoading, startLoading } = useDummyLoading();
 *
 * const handleSave = async () => {
 *  await startLoading([]);
 *  // Save data
 * };
 *
 * @example
 * const { dummyLoading, startLoading } = useDummyLoading();
 *
 * const handleSave = async () => {
 *  const request = doMutation();
 *
 *  const [result] = await startLoading([request]);
 *
 *  const data = result.data;
 *  // Handle data
 * }
 */
export const useDummyLoading = () => {
  const [dummyLoading, setDummyLoading] = useState(false);

  const startLoading = useCallback(async <T>(promises: Promise<T>[]) => {
    setDummyLoading(true);

    const fixedDelay = new Promise((resolve) => setTimeout(resolve, FIXED_DELAY));

    const [, ...result] = await Promise.all([fixedDelay, ...promises]);

    setDummyLoading(false);

    return result.slice(0, promises.length);
  }, []);

  return {
    dummyLoading,
    startLoading,
  };
};
