import { energy_source_type_enum } from '@predium/enums';

const DISTRICT_HEATING_CHP = [
  energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL,
  energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS,
  energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE,
];
const DISTRICT_HEATING_PLANTS = [
  energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL,
  energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS,
  energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE,
];

export const checkDistrictHeating = (energySourceType: energy_source_type_enum) => {
  const isDistrictHeatingPlants = DISTRICT_HEATING_PLANTS.includes(energySourceType);
  const isDistrictHeatingChp = DISTRICT_HEATING_CHP.includes(energySourceType);
  return {
    isDistrictHeatingChp,
    isDistrictHeatingPlants,
    isDistrictHeating: isDistrictHeatingPlants || isDistrictHeatingChp,
  };
};
