import { subsidy_region_type_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateSubsidyRegionTypeEnum = (enumValue: subsidy_region_type_enum) => {
  switch (enumValue) {
    case subsidy_region_type_enum.FEDERAL:
      return <Trans i18nKey="Enum_SubsidyRegionTypeEnum-FEDERAL" />;
    case subsidy_region_type_enum.LOCAL:
      return <Trans i18nKey="Enum_SubsidyRegionTypeEnum-LOCAL" />;
    case subsidy_region_type_enum.OTHER:
      return <Trans i18nKey="Enum_SubsidyRegionTypeEnum-OTHER" />;
    case subsidy_region_type_enum.STATE:
      return <Trans i18nKey="Enum_SubsidyRegionTypeEnum-STATE" />;
  }
};

export const translateSubsidyRegionTypeEnum_dynamic = (
  enumValue: subsidy_region_type_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case subsidy_region_type_enum.FEDERAL:
      return t('Enum_SubsidyRegionTypeEnum-FEDERAL');
    case subsidy_region_type_enum.LOCAL:
      return t('Enum_SubsidyRegionTypeEnum-LOCAL');
    case subsidy_region_type_enum.OTHER:
      return t('Enum_SubsidyRegionTypeEnum-OTHER');
    case subsidy_region_type_enum.STATE:
      return t('Enum_SubsidyRegionTypeEnum-STATE');
  }
};
