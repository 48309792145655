import { Checkbox, Chip, ChipProps, InputAdornment, Menu, MenuItem, Stack, TextField } from '@mui/material';
import { useRef, useState } from 'react';
import Iconify from './Iconify';

export type FilterItem = {
  name: string;
  value: string;
};

type Props = {
  /**
   * Array of items to be displayed in the filter
   */
  items: FilterItem[];
  /**
   * Array of items that are currently selected
   */
  selectedItems: FilterItem[];
  /**
   * Callback function that is called when an item is selected
   * @param item The value of the selected item
   * @returns void
   */
  onChanged: (item: string) => void;
  /**
   * The label of the filter when more than one item is selected, needs to be in plural form
   */
  selectedLabel: string;
  /**
   * Whether the list of filter options is searchable
   * @default false
   */
  searchable?: boolean;
};
/**
 * A controlled, generic chip component, meant to be used as a filter. Supports multi select & a search field.
 */
export default function ChipFilter({
  items,
  onChanged,
  selectedItems,
  selectedLabel,
  label,
  searchable,
  sx,
  ...other
}: Props & ChipProps) {
  const [anchorEl, setAnchorEl] = useState<Element | ((element: Element) => Element) | null>(null);
  const [searchInput, setSearchInput] = useState('');

  const chipRef = useRef(null);

  const handleClick = (item: FilterItem) => {
    onChanged(item.value);
  };

  const openMenu = () => {
    setAnchorEl(chipRef.current);
  };

  const filteredItems = items.filter((item) => item.name.toLowerCase().includes(searchInput.toLowerCase()));

  const filterLabelContent =
    selectedItems.length === 0
      ? label
      : selectedItems.length === 1
      ? selectedItems[0].name
      : `${selectedItems.length} ${selectedLabel}`;

  const filterLabel = (
    <Stack flexDirection={'row'} alignItems={'center'} gap={0.5}>
      {selectedItems.length > 0 && (
        <Iconify icon={'material-symbols:check'} color="text.secondary" width={16} height={16} />
      )}
      {filterLabelContent}
    </Stack>
  );

  return (
    <>
      <Chip
        {...other}
        ref={chipRef}
        label={filterLabel}
        onClick={openMenu}
        onDelete={openMenu}
        deleteIcon={<Iconify icon={'material-symbols:keyboard-arrow-down'} color="text.secondary" />}
        sx={{
          ...sx,
          backgroundColor: selectedItems.length === 0 ? 'white' : 'rgba(145, 158, 171, 0.08)',
          borderRadius: 1,
          border: '1px solid #919eab24',
          height: '100%',
          '& .MuiChip-label': {
            color: 'text.primary',
            fontWeight: 600,
            fontSize: 16,
            py: 1,
          },
        }}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        {searchable && (
          <TextField
            placeholder="Search"
            value={searchInput}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) => setSearchInput(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              ),
            }}
            sx={{
              width: 300,
              mx: 1,
              my: 1,
              '.MuiOutlinedInput-input': {
                py: 1.5,
              },
            }}
          />
        )}

        {filteredItems.map((item) => (
          <MenuItem key={item.value} onClick={() => handleClick(item)} sx={{ pl: 1 }}>
            <Checkbox checked={selectedItems.some((selectedItem) => selectedItem.value === item.value)} />
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
