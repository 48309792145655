import { envelope_type_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateEnvelopeTypeEnum_insulation = (enumValue: envelope_type_enum) => {
  switch (enumValue) {
    case envelope_type_enum.BASEMENT_CEILING:
      return <Trans i18nKey="Enum_EnvelopeTypeEnum-insulation-BASEMENT_CEILING" />;
    case envelope_type_enum.DOOR:
      return <Trans i18nKey="Enum_EnvelopeTypeEnum-insulation-DOOR" />;
    case envelope_type_enum.FLAT_ROOF:
      return <Trans i18nKey="Enum_EnvelopeTypeEnum-insulation-FLAT_ROOF" />;
    case envelope_type_enum.FLOOR:
      return <Trans i18nKey="Enum_EnvelopeTypeEnum-insulation-FLOOR" />;
    case envelope_type_enum.PITCHED_ROOF:
      return <Trans i18nKey="Enum_EnvelopeTypeEnum-insulation-PITCHED_ROOF" />;
    case envelope_type_enum.TOP_FLOOR_CEILING:
      return <Trans i18nKey="Enum_EnvelopeTypeEnum-insulation-TOP_FLOOR_CEILING" />;
    case envelope_type_enum.WALL:
      return <Trans i18nKey="Enum_EnvelopeTypeEnum-insulation-WALL" />;
    case envelope_type_enum.WINDOW:
      return <Trans i18nKey="Enum_EnvelopeTypeEnum-insulation-WINDOW" />;
  }
};

export const translateEnvelopeTypeEnum_insulation_dynamic = (
  enumValue: envelope_type_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case envelope_type_enum.BASEMENT_CEILING:
      return t('Enum_EnvelopeTypeEnum-insulation-BASEMENT_CEILING');
    case envelope_type_enum.DOOR:
      return t('Enum_EnvelopeTypeEnum-insulation-DOOR');
    case envelope_type_enum.FLAT_ROOF:
      return t('Enum_EnvelopeTypeEnum-insulation-FLAT_ROOF');
    case envelope_type_enum.FLOOR:
      return t('Enum_EnvelopeTypeEnum-insulation-FLOOR');
    case envelope_type_enum.PITCHED_ROOF:
      return t('Enum_EnvelopeTypeEnum-insulation-PITCHED_ROOF');
    case envelope_type_enum.TOP_FLOOR_CEILING:
      return t('Enum_EnvelopeTypeEnum-insulation-TOP_FLOOR_CEILING');
    case envelope_type_enum.WALL:
      return t('Enum_EnvelopeTypeEnum-insulation-WALL');
    case envelope_type_enum.WINDOW:
      return t('Enum_EnvelopeTypeEnum-insulation-WINDOW');
  }
};
