import { Box, useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';

const StickyBox = ({ children }: PropsWithChildren) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        transition: 'transform 0.3s ease',
        position: 'sticky',
        top: 0,
        zIndex: 2,
        backgroundColor: theme.palette.common.white,
        pb: 2,
        width: '100%',
      }}
    >
      {children}
    </Box>
  );
};

export default StickyBox;
