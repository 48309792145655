/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, MenuItem, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RHFSelect } from '../../../../components/hook-form';
import { ReportQuestionDC } from '../../ReportingDataClasses/ReportQuestion.dc';
import ReportQuestion, { ReportQuestionSwitchProps, isAnsweredQuestion, isAutoFillQuestion } from '../ReportQuestion';

function SingleChoiceQuestion({
  question,
  isSubQuestion,
  isEditable,
  autoFocus,
  formArrayProps,
  reportId,
}: ReportQuestionSwitchProps) {
  const { t } = useTranslation();
  const { watch, setFocus } = useFormContext();

  const { fields } = formArrayProps!;

  const indexInForm = fields.findIndex((field) => field._id === question.id);

  const currentSelectedOptionValue: string = watch(`questions.${indexInForm}.singleChoiceOptionId`);

  const currentSelectedOptionIndex = question.options!.findIndex(
    (option) => option.id === parseInt(currentSelectedOptionValue),
  );

  //helper to have a list of subquestions of currently selected option
  let subQuestions: ReportQuestionDC[];

  //@ts-ignore
  if (!question?.options[currentSelectedOptionIndex]?.report_template_child_questions) {
    subQuestions = [];
  } else if (Array.isArray(question.options[currentSelectedOptionIndex].report_template_child_questions)) {
    //@ts-ignore
    subQuestions = question.options[currentSelectedOptionIndex].report_template_child_questions;
  } else {
    //@ts-ignore
    subQuestions = question.options[currentSelectedOptionIndex].report_template_child_questions;
  }

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        setFocus(`questions.${indexInForm}.singleChoiceOptionId`);
        // TODO: I don't know why a timeout is needed here, but without it the focus is not set on consecutive questions.
        // In the worst case the focus might not get set which is not crucial.
      }, 250);
    }
  }, [autoFocus, indexInForm, setFocus]);

  const showHelperText = isAutoFillQuestion(question) && isAnsweredQuestion(question);

  return (
    <>
      {isSubQuestion && (
        <Box sx={{ ml: 1 }}>
          <Typography>{question.question}</Typography>
        </Box>
      )}
      <Stack spacing={3} sx={{ p: 3 }}>
        <RHFSelect
          label={t('General_Selection')}
          placeholder={t('SingleChoiceQuestion_Select-placeholder')}
          name={`questions.${indexInForm}.singleChoiceOptionId`}
          disabled={!isEditable}
          helperText={showHelperText ? t('ReportQuestions_HelperText') : undefined}
        >
          {question.options!.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.value}
            </MenuItem>
          ))}
        </RHFSelect>
      </Stack>

      {subQuestions.map((subQuestion, index) => (
        <ReportQuestion
          key={subQuestion.id}
          reportId={reportId}
          question={subQuestion}
          isSubQuestion
          isEditable={isEditable}
          formArrayProps={formArrayProps}
          autoFocus={index === 0}
        />
      ))}
    </>
  );
}

export default SingleChoiceQuestion;
