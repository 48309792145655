import { useTheme } from '@mui/material';
import { PropsWithChildren, createContext, useEffect, useState } from 'react';
import useResponsive from '../hooks/useResponsive';

// ----------------------------------------------------------------------

type CollapseDrawerContextProps = {
  isCollapse: boolean;
  collapseHover: boolean;
  onToggleCollapse: VoidFunction;
  onHoverEnter: VoidFunction;
  onHoverLeave: VoidFunction;
};

const initialState: CollapseDrawerContextProps = {
  isCollapse: false,
  collapseHover: false,
  onToggleCollapse: () => {},
  onHoverEnter: () => {},
  onHoverLeave: () => {},
};

const CollapseDrawerContext = createContext(initialState);

/**
 * @deprecated has been removed in Minimal@4. You can use it but have to maintain it yourself.
 * */
function CollapseDrawerProvider({ children }: PropsWithChildren) {
  const isDesktop = useResponsive('up', 'lg');
  const theme = useTheme();

  const [currentTrigger, setCurrentTrigger] = useState<'CLICK' | 'HOVER'>('CLICK');
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (!isDesktop) {
      setCurrentTrigger('CLICK');
      setIsCollapsed(false);
    }
  }, [isDesktop]);

  const handleToggleCollapse = () => {
    if (currentTrigger === 'HOVER') {
      setIsCollapsed(false);
      setCurrentTrigger('CLICK');
    } else {
      setIsCollapsed(true);

      // To avoid the drawer from being open by default on switching to hover trigger
      // we wait until transition finishes
      const timeToCloseTheDrawer = theme.transitions.duration.standard;
      setTimeout(() => setCurrentTrigger('HOVER'), timeToCloseTheDrawer);
    }
  };

  const handleHoverEnter = () => {
    if (currentTrigger === 'HOVER') {
      setIsCollapsed(false);
    }
  };

  const handleHoverLeave = () => {
    if (currentTrigger === 'HOVER') {
      setIsCollapsed(true);
    }
  };

  return (
    <CollapseDrawerContext.Provider
      value={{
        isCollapse: isCollapsed,
        collapseHover: currentTrigger === 'HOVER',
        onToggleCollapse: handleToggleCollapse,
        onHoverEnter: handleHoverEnter,
        onHoverLeave: handleHoverLeave,
      }}
    >
      {children}
    </CollapseDrawerContext.Provider>
  );
}

export { CollapseDrawerContext, CollapseDrawerProvider };
