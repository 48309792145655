import { createSvgIcon } from '@mui/material';

const CommercialIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g textAnchor="middle" transform="translate(5, 3)">
      <path d="M12 2V16H10H8H2V2H12ZM14 0H0V18H14V0Z" fill="currentColor" />
      <path d="M10 6H8V4H10V6Z" fill="currentColor" />
      <path d="M6 6H4V4H6V6Z" fill="currentColor" />
      <path d="M10 10H8V8H10V10Z" fill="currentColor" />
      <path d="M6 10H4V8H6V10Z" fill="currentColor" />
      <path d="M10 14H8V12H10V14Z" fill="currentColor" />
      <path d="M6 14H4V12H6V14Z" fill="currentColor" />
    </g>
  </svg>,
  'Commercial',
);

export default CommercialIcon;
