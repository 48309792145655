import { useMutation } from '@apollo/client';
import { Box, Button } from '@mui/material';
import { DataCollectionSubBuildingConsumptionDraftFragment } from '@predium/client-graphql';
import { consumption_source_type_enum } from '@predium/enums';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import { SnackbarTimeouts } from '../../components/NotistackProvider';
import Scrollbar from '../../components/Scrollbar';
import PreDialog, { PreDialogBody } from '../../components/presentations/PreDialog/PreDialog';
import {
  DELETE_CONSUMPTION_DRAFT,
  INSERT_SINGLE_CONSUMPTION_FROM_DRAFT,
  INSERT_SINGLE_WASTE_DETAILS,
} from '../../graphql/DataCollection.mutations';
import { GET_BUILDING, GET_CONSUMPTION_SUMMARIES } from '../../graphql/DataCollection.queries';
import SubBuildingConsumptionInvoiceDraft from '../../sections/DataCollection/Building/Consumption/SubBuildingConsumptionInvoiceDraft';
import SubBuildingSingleConsumptionDraftForm, {
  OnCreateSingleConsumption,
} from '../../sections/DataCollection/Building/Drafts/SubBuildingSingleConsumptionDraftForm';

type Props = {
  buildingId: number;
  subBuildingId: number;
  isOpenForSource: consumption_source_type_enum | null;
  onClose: () => void;
  consumptionDraftData: DataCollectionSubBuildingConsumptionDraftFragment | null;
  setConsumptionDraftData: React.Dispatch<
    React.SetStateAction<DataCollectionSubBuildingConsumptionDraftFragment | null>
  >;
};

export default function DataCollectionSingleConsumptionDraftModal({
  buildingId,
  subBuildingId,
  isOpenForSource,
  onClose,
  consumptionDraftData,
  setConsumptionDraftData,
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [deleteConsumptionDraftMutation] = useMutation(DELETE_CONSUMPTION_DRAFT, {
    variables: {
      consumptionDraftId: consumptionDraftData?.id!,
    },
    update: (cache, { data }) => {
      if (data?.delete_consumption_draft_by_pk) {
        const normalizedId = cache.identify({
          id: data.delete_consumption_draft_by_pk.id,
          __typename: data.delete_consumption_draft_by_pk.__typename,
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
    onCompleted: (data, options) => {
      if (data.delete_consumption_draft_by_pk && !options?.context?.skipNotification) {
        enqueueSnackbar(t('DataCollectionSubBuildingConsumptionDraft_DeleteConsumptionDraft-success'), {
          variant: 'success',
          autoHideDuration: SnackbarTimeouts.Success,
        });
      }
      setDeleteModalOpen(false);
      onClose();
    },
    onError: () =>
      enqueueSnackbar(t('DataCollectionSubBuildingConsumptionDraft_DeleteConsumptionDraft-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
  });

  const [createConsumptionFromDraftMutation] = useMutation(INSERT_SINGLE_CONSUMPTION_FROM_DRAFT, {
    refetchQueries: [
      { query: GET_BUILDING, variables: { buildingId, year: new Date().getFullYear() } },
      { query: GET_CONSUMPTION_SUMMARIES, variables: { buildingId, year: new Date().getFullYear() } },
    ],
    onError: () =>
      enqueueSnackbar(t('DataCollectionSubBuildingConsumptionDraft_CreateConsumption-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
    onCompleted: () =>
      deleteConsumptionDraftMutation({ context: { skipNotification: true } }).then(() => {
        enqueueSnackbar(t('DataCollectionSubBuildingConsumptionDraft_CreateConsumption-success'), {
          variant: 'success',
          autoHideDuration: SnackbarTimeouts.Success,
        });
      }),
  });

  const [createWasteDetailsFromDraftMutation] = useMutation(INSERT_SINGLE_WASTE_DETAILS);

  const handleCreateSingleConsumption: OnCreateSingleConsumption = async ({ consumptionDraft, wasteDetailsDraft }) => {
    const { data } = await createConsumptionFromDraftMutation({
      variables: consumptionDraft,
    });

    if (!data?.insert_consumption_one) {
      console.error('Failed to create consumption');
      return;
    }

    if (wasteDetailsDraft) {
      await createWasteDetailsFromDraftMutation({
        variables: {
          ...wasteDetailsDraft,
          consumptionId: data.insert_consumption_one.id,
        },
      });
    }
  };

  return (
    <>
      <PreDialog open={Boolean(isOpenForSource)} onClose={onClose} type="definedByChildren" maxWidth="sm" fullWidth>
        <SubBuildingSingleConsumptionDraftForm
          subBuildingId={subBuildingId}
          buildingId={buildingId}
          consumptionDraftData={consumptionDraftData}
          setConsumptionDraftData={setConsumptionDraftData}
          sourceTypeId={isOpenForSource!}
          onCreateSingleConsumption={handleCreateSingleConsumption}
          renderChildren={(submitForm) => (
            <PreDialogBody
              dialogtitle={
                isOpenForSource === consumption_source_type_enum.ESTIMATION
                  ? t('DataCollection_ConsumptionDraftModalTitle-estimation')
                  : t('PageName_DataCollection_ConsumptionDraft')
              }
              content={
                <Scrollbar
                  sx={{
                    // make actions sticky and content scrollable by fixing maxHeight
                    // full height - dialog top margin - dialog bottom margin - header - actions
                    maxHeight: 'calc(100vh - 32px - 32px - 76px - 84px)',
                  }}
                >
                  <SubBuildingConsumptionInvoiceDraft isSingleConsumption />
                </Scrollbar>
              }
              actions={
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    variant="text"
                    color="error"
                    disabled={!consumptionDraftData}
                    onClick={() => setDeleteModalOpen(true)}
                  >
                    {t('General_Delete')}
                  </Button>

                  <Box>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        submitForm().then(() => {
                          onClose();
                        });
                      }}
                    >
                      {t('General_Save')}
                    </Button>
                    <Button sx={{ marginLeft: 1 }} variant="contained" type="submit">
                      {t('General_Approve')}
                    </Button>
                  </Box>
                </Box>
              }
            />
          )}
        />
      </PreDialog>

      {isOpenForSource && (
        <DeleteConfirmationModal
          open={deleteModalOpen}
          title={t('DataCollection_DeleteModalTitle-ConsumptionDraft')}
          description={t('DataCollection_DeleteModalDescription-ConsumptionDraft')}
          onClose={() => setDeleteModalOpen(false)}
          onDelete={async () => {
            deleteConsumptionDraftMutation();
          }}
        />
      )}
    </>
  );
}
