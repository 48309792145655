/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Button, Card, CardMedia, Divider, Grid, Link, MenuItem, Stack, Typography } from '@mui/material';
import { DataCollectionEnergyCertificateDraftSaveEnergyCertificateDraftMutation } from '@predium/client-graphql';
import { draft_status_enum } from '@predium/enums';
import { PropsWithChildren, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  DataCollectionSaveConsumptionInvoiceDraftMutation,
  DataCollectionSaveConsumptionInvoiceMutation,
} from '../../../../../../../libs/generated/client-graphql/src/generated/graphql';
import { ICONS } from '../../../../assets/icons';
import Iconify from '../../../../components/Iconify';
import NavigationModal from '../../../../components/NavigationModal/NavigationModal';
import PdfViewer from '../../../../components/PdfViewer';
import StepperNavigation from '../../../../components/StepperNavigation';
import PreDialog from '../../../../components/presentations/PreDialog/PreDialog';
import TableMoreMenu from '../../../../components/table/TableMoreMenu';

// Timeout in minutes so we can directly display it in the UI
export const UPLOAD_TIMEOUT_MINUTES = 1;
export const OCR_TIMEOUT_MINUTES = 3;

type DataCollectionDraftDeprecatedProps = PropsWithChildren<{
  downloadUrl: string;
  draftStatus: draft_status_enum;
  submitForm: () =>
    | Promise<DataCollectionEnergyCertificateDraftSaveEnergyCertificateDraftMutation>
    | Promise<DataCollectionSaveConsumptionInvoiceDraftMutation>
    | Promise<DataCollectionSaveConsumptionInvoiceMutation>;
  stepperProps: {
    maxSteps: number;
    handleNext: () => void;
    handleBack: () => void;
    activeStep: number;
    nextStepTitle: string;
    backStepTitle: string;
  };

  acceptOcrResultModalOpen?: boolean;
  setAcceptOcrResultModalOpen?: (open: boolean) => void;

  createMutation?: () => Promise<void>;
  createDraftModalOpen?: boolean;
  setCreateDraftModalOpen?: (open: boolean) => void;

  hideManualSubmitLabel?: boolean;
  // Labels
  createLabel: string;
  createDialogTitle: string;
  createDialogDescription: string;
  createDialogButtonTitle: string;

  hasEditAccess?: boolean;

  deleteModalOpen: boolean;
  setDeleteModalOpen: (open: boolean) => void;
}>;

/**
 * Just used on the energy certificate upload. Replace it later.
 */
export default function DataCollectionDraftDeprecated({
  downloadUrl,
  draftStatus,
  submitForm,
  children,
  stepperProps: { maxSteps, handleNext, handleBack, activeStep, backStepTitle, nextStepTitle },
  createMutation,
  createDraftModalOpen = false,
  setCreateDraftModalOpen,
  acceptOcrResultModalOpen = false,
  setAcceptOcrResultModalOpen,

  hideManualSubmitLabel = false,

  createLabel,
  createDialogTitle,
  createDialogDescription,
  createDialogButtonTitle,

  hasEditAccess = false,

  deleteModalOpen,
  setDeleteModalOpen,
}: DataCollectionDraftDeprecatedProps) {
  const { t } = useTranslation();
  const [isCreationButtonDisabled, setIsCreationButtonDisabled] = useState(false);

  const {
    formState: { isDirty, isValid, errors },
  } = useFormContext();

  const isEditable = !(
    draftStatus === draft_status_enum.UPLOADING || draftStatus === draft_status_enum.OCR_IN_PROGRESS
  );

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%', mt: -5 }}>
        <Box sx={{ width: 400 }}></Box>

        <Box sx={{ width: 200 }}>
          <StepperNavigation
            variant="horizontal"
            maxSteps={maxSteps}
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            backStepTitle={backStepTitle}
            nextStepTitle={nextStepTitle}
          />
        </Box>

        <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ width: 400 }}>
          {draftStatus === draft_status_enum.OCR_REVIEW && !isDirty && isValid && (
            <Button
              size="small"
              variant="outlined"
              //@ts-ignore
              onClick={() => setAcceptOcrResultModalOpen(true)}
              startIcon={<Iconify icon="bi:check" />}
              sx={{ mr: 2 }}
            >
              {t('General_AcceptResult')}
            </Button>
          )}

          {!hideManualSubmitLabel && (
            <Button
              disabled={!isDirty}
              size="small"
              variant="outlined"
              onClick={submitForm}
              startIcon={<Iconify icon="material-symbols:save" />}
              sx={{ mr: 2 }}
            >
              {t('General_SaveDraft')}
            </Button>
          )}
          {hasEditAccess && (
            <Button
              disabled={Object.keys(errors).length > 0}
              variant="contained"
              size="small"
              type="submit"
              startIcon={<Iconify icon="healthicons:i-documents-accepted" />}
              sx={{ mr: 2 }}
            >
              {createLabel}
            </Button>
          )}

          <TableMoreMenu
            actions={
              <>
                <MenuItem component={Link} target="_blank" href={downloadUrl}>
                  <Iconify icon={'mdi:tray-arrow-down'} />
                  {t('General_Download')}
                </MenuItem>
                {hasEditAccess && (
                  <MenuItem onClick={() => setDeleteModalOpen(true)} sx={{ color: 'error.main' }}>
                    <Iconify icon={ICONS.TRASH} /> {t('General_Delete')}
                  </MenuItem>
                )}
              </>
            }
          />
        </Stack>
      </Stack>

      {draftStatus === draft_status_enum.OCR_FAILED && (
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            p: 2,
            mb: 4,
            backgroundColor: 'rgba(255,0,0,0.2)',
          }}
        >
          <CardMedia>
            <Iconify icon="ant-design:warning-outlined" width={30} height={30} color="TextGray" />
          </CardMedia>
          <Typography sx={{ pl: 2, whiteSpace: 'pre-line' }}>{t('General_OCRFailed')}</Typography>
        </Card>
      )}

      {isEditable && (
        <Grid container component={Card} sx={{ mt: 2 }}>
          <Grid item xs={6} sx={{ pl: 0 }}>
            {children}
          </Grid>
          <Divider orientation="vertical" flexItem sx={{ mr: '-10px', mt: 6, mb: 6 }} />

          <Grid item xs={6} sx={{ pl: 2 }}>
            <PdfViewer fileURL={downloadUrl} />
          </Grid>
        </Grid>
      )}

      {/* DIALOGS AND PROMPTS */}
      <PreDialog
        type="definedByProperty"
        open={acceptOcrResultModalOpen}
        //@ts-ignore
        onClose={() => setAcceptOcrResultModalOpen(false)}
        dialogtitle={t('General_AcceptOCRResult-Title')}
        content={
          <Stack>
            <Typography sx={{ pb: 1 }}>{t('General_OCRResultReady')}</Typography>
            <Typography>{t('General_AcceptOCRResult')}</Typography>
          </Stack>
        }
        actions={
          <Button
            variant="contained"
            autoFocus
            startIcon={<Iconify icon="bi:check" width={20} height={20} />}
            onClick={async () => {
              await submitForm();

              //@ts-ignore
              setAcceptOcrResultModalOpen(false);
            }}
          >
            {t('General_AcceptResult')}
          </Button>
        }
      />

      <PreDialog
        type="definedByProperty"
        open={createDraftModalOpen}
        //@ts-ignore
        onClose={() => setCreateDraftModalOpen(false)}
        dialogtitle={createDialogTitle}
        content={createDialogDescription}
        actions={
          <Button
            variant="contained"
            autoFocus
            disabled={isCreationButtonDisabled}
            startIcon={<Iconify icon="fa-solid:save" width={20} height={20} />}
            onClick={async () => {
              setIsCreationButtonDisabled(true);

              //@ts-ignore
              await createMutation();

              //@ts-ignore
              setCreateDraftModalOpen(false);
            }}
          >
            {createDialogButtonTitle}
          </Button>
        }
      />

      <NavigationModal
        title={t('DataCollection_BuildingDocuments_UnsavedChangesInDraft-Title')}
        message={t('DataCollection_BuildingDocuments_UnsavedChangesInDraft-Message')}
        primaryActionText={t('General_Save')}
        shouldBlock={isDirty && !deleteModalOpen && !createDraftModalOpen}
        primaryActionCallback={async () => {
          const energyCertificateDraft = await submitForm();
          return Boolean(energyCertificateDraft);
        }}
      />
    </>
  );
}
