import { energy_storage_type_enum, energy_system_type_enum } from '@predium/enums';

//
// StorageTypesForSystemType - a mapping of supported storage types for a given system type
//

export const StorageTypesForSystemType: Record<energy_system_type_enum, energy_storage_type_enum[]> = {
  [energy_system_type_enum.COOLING]: [],
  [energy_system_type_enum.ELECTRICITY]: [],
  [energy_system_type_enum.GENERAL]: [],
  [energy_system_type_enum.HEATING]: [
    energy_storage_type_enum.BUFFER_STORAGE_ELECTRIC,
    energy_storage_type_enum.BUFFER_STORAGE,
  ],
  [energy_system_type_enum.HOT_WATER]: [
    energy_storage_type_enum.BUFFER_STORAGE_ELECTRIC,
    energy_storage_type_enum.BUFFER_STORAGE,
    energy_storage_type_enum.CENTRAL_INSIDE,
    energy_storage_type_enum.CENTRAL_OUTSIDE,
  ],
  [energy_system_type_enum.LIGHTING]: [],
  [energy_system_type_enum.SOLAR]: [],
  [energy_system_type_enum.VENTILATION]: [],
};
