import { subsidy_category_type_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateSubsidyCategoryTypeEnum = (enumValue: subsidy_category_type_enum) => {
  switch (enumValue) {
    case subsidy_category_type_enum.ENVELOPE_CEILING:
      return <Trans i18nKey="Enum_SubsidyCategoryTypeEnum-ENVELOPE_CEILING" />;
    case subsidy_category_type_enum.ENVELOPE_FLOOR:
      return <Trans i18nKey="Enum_SubsidyCategoryTypeEnum-ENVELOPE_FLOOR" />;
    case subsidy_category_type_enum.ENVELOPE_ROOF:
      return <Trans i18nKey="Enum_SubsidyCategoryTypeEnum-ENVELOPE_ROOF" />;
    case subsidy_category_type_enum.ENVELOPE_UNCLASSIFIED:
      return <Trans i18nKey="Enum_SubsidyCategoryTypeEnum-ENVELOPE_UNCLASSIFIED" />;
    case subsidy_category_type_enum.ENVELOPE_WALL:
      return <Trans i18nKey="Enum_SubsidyCategoryTypeEnum-ENVELOPE_WALL" />;
    case subsidy_category_type_enum.ENVELOPE_WINDOW:
      return <Trans i18nKey="Enum_SubsidyCategoryTypeEnum-ENVELOPE_WINDOW" />;
    case subsidy_category_type_enum.SYSTEMS_HEATING:
      return <Trans i18nKey="Enum_SubsidyCategoryTypeEnum-SYSTEMS_HEATING" />;
    case subsidy_category_type_enum.SYSTEMS_PV:
      return <Trans i18nKey="Enum_SubsidyCategoryTypeEnum-SYSTEMS_PV" />;
    case subsidy_category_type_enum.SYSTEMS_VENTILATION:
      return <Trans i18nKey="Enum_SubsidyCategoryTypeEnum-SYSTEMS_VENTILATION" />;
  }
};

export const translateSubsidyCategoryTypeEnum_dynamic = (
  enumValue: subsidy_category_type_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case subsidy_category_type_enum.ENVELOPE_CEILING:
      return t('Enum_SubsidyCategoryTypeEnum-ENVELOPE_CEILING');
    case subsidy_category_type_enum.ENVELOPE_FLOOR:
      return t('Enum_SubsidyCategoryTypeEnum-ENVELOPE_FLOOR');
    case subsidy_category_type_enum.ENVELOPE_ROOF:
      return t('Enum_SubsidyCategoryTypeEnum-ENVELOPE_ROOF');
    case subsidy_category_type_enum.ENVELOPE_UNCLASSIFIED:
      return t('Enum_SubsidyCategoryTypeEnum-ENVELOPE_UNCLASSIFIED');
    case subsidy_category_type_enum.ENVELOPE_WALL:
      return t('Enum_SubsidyCategoryTypeEnum-ENVELOPE_WALL');
    case subsidy_category_type_enum.ENVELOPE_WINDOW:
      return t('Enum_SubsidyCategoryTypeEnum-ENVELOPE_WINDOW');
    case subsidy_category_type_enum.SYSTEMS_HEATING:
      return t('Enum_SubsidyCategoryTypeEnum-SYSTEMS_HEATING');
    case subsidy_category_type_enum.SYSTEMS_PV:
      return t('Enum_SubsidyCategoryTypeEnum-SYSTEMS_PV');
    case subsidy_category_type_enum.SYSTEMS_VENTILATION:
      return t('Enum_SubsidyCategoryTypeEnum-SYSTEMS_VENTILATION');
  }
};
