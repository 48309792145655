/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Box, Card, Grid, Typography } from '@mui/material';
import { EsgAnalysisGetPortfolioQuery, EsgAnalysisPortfolioFragment } from '@predium/client-graphql';
import { pathValueAtYear } from '@predium/client-lookup';
import {
  climate_risk_type_enum,
  efficiency_class_enum,
  energy_source_type_enum,
  sub_building_class_enum,
} from '@predium/enums';
import { getAnalyzedBuildingsWhere } from '@predium/graphql-where-client';
import { fShortenToTwoDecimal } from '@predium/utils';
import sum from 'lodash/sum';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DelayedLoading } from '../../components/Loading';
import SimpleComparisonMetric from '../../components/SimpleComparisonMetric';
import Unavailable from '../../components/Unavailable';
import BuildingEfficiencyCard from '../../components/data-visialization/esg-analysis/BuildingEfficiencyCard';
import BuildingsByEnergySourceType from '../../components/data-visialization/esg-analysis/BuildingsByEnergySourceTypeGraph';
import CO2CostDevelopmentGraph from '../../components/data-visialization/esg-analysis/CO2CostDevelopmentGraph';
import { ClimateRiskGraph } from '../../components/data-visialization/esg-analysis/ClimateRiskChart';
import EnergyCostDevelopmentGraph from '../../components/data-visialization/esg-analysis/EnergyCostDevelopmentGraph';
import {
  EnergySourceCO2Graph,
  EnergySourceConsumptionGraph,
} from '../../components/data-visialization/esg-analysis/EnergySourceGraph';
import EuTaxonomyGraph from '../../components/data-visialization/esg-analysis/EuTaxonomyGraph';
import {
  PortfolioCO2CostDistributionGraph,
  PortfolioEfficiencyClassDistributionGraph,
} from '../../components/data-visialization/esg-analysis/PortfolioBuildingDistributionGraph';
import Scope123Graph from '../../components/data-visialization/esg-analysis/Scope123Graph';
import StrandingRiskCard from '../../components/data-visialization/esg-analysis/StrandingRiskCard';
import SubBuildingClassDistributionGraph from '../../components/data-visialization/esg-analysis/SubBuildingClassDistributionGraph';
import { GET_BENCHMARK, GET_PORTFOLIO } from '../../graphql/EsgAnalysis.queries';
import useEsgAnalysisFilters from '../../hooks/useEsgAnalysisFilters';
import { PATHS } from '../../routes';
import { getBuildingWhereFilters } from '../../utils/getWhereFilters';
import { DataCollectionTabActionsEnum } from '../DataCollection/Buildings/TabsEnums';
import { ENTIRE_COMPANY } from './EsgAnalysisBenchmark';
import { EsgAnalysisFiltersKeys } from './EsgAnalysisFilters/EsgAnalysisFilters';
import { CurrentView } from './EsgAnalysisTabs';
import AreaBubbleChart from './Visualization/AreaBubbleChart';
import { EsgPathGraph } from './Visualization/EsgPathGraph';

// ----------------------------------------------------------------------

type Props = {
  benchmarkId: number;
  showTotal: boolean;
  totalBuildings: number;
  portfolios: EsgAnalysisPortfolioFragment[];
  constructionYear: number;
  currentView?: CurrentView;
};

export default function EsgAnalysisSection({
  benchmarkId,
  showTotal,
  totalBuildings,
  portfolios,
  constructionYear,
  currentView = 'esg-portfolio',
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { filters, setFilter } = useEsgAnalysisFilters();
  const currentYear = new Date().getFullYear();

  const { data: benchmarkData, loading: benchmarkLoading } = useQuery(GET_BENCHMARK, {
    variables: {
      portfolioId: benchmarkId,
    },
  });

  const {
    error: noBuildingsAvailable,
    data,
    loading,
  } = useQuery<EsgAnalysisGetPortfolioQuery>(GET_PORTFOLIO, {
    variables: {
      buildingWhere: getAnalyzedBuildingsWhere(getBuildingWhereFilters(filters), currentYear),
    },
  });

  const benchmarks = useMemo(
    () => [
      { id: ENTIRE_COMPANY, name: t('EsgAnalysisBenchmark_EntireCompany') },
      ...portfolios.map((portfolio) => ({
        id: portfolio.id,
        name: `Portfolio ${portfolio.name}`,
      })),
    ],
    [portfolios, t],
  );

  if (noBuildingsAvailable) {
    return (
      <Grid item xs={6}>
        <Unavailable
          title={t('General_BuildingsUnavailable-title')}
          subTitle={t('ESGAnalysisSection_AddFirstBuildingToPortfolio')}
          onClick={() =>
            navigate(
              PATHS.dataCollection.buildings(null, {
                action: DataCollectionTabActionsEnum.CREATE_ENERGY_CERTIFICATE_DRAFT,
              }),
            )
          }
        />
      </Grid>
    );
  }

  if (benchmarkLoading || loading) {
    return <DelayedLoading />;
  }

  if (!benchmarkData || !data) {
    return <Unavailable title={t('EsgAnalysisSection_AnalysisNotAvailable')} />;
  }

  const calculateBenchmarkChange = (value: number, benchmarkPath?: number[]) => {
    if (!benchmarkPath) return null;

    //@ts-ignore
    return (1 - value / pathValueAtYear(benchmarkPath, currentYear)) * -1;
  };

  const handleEnergySourceSelect = (value: energy_source_type_enum) => {
    setFilter(EsgAnalysisFiltersKeys.energySourceTypes, value);
  };

  const handledSubBuildingClassSelected = (subBuildingClass: sub_building_class_enum) => {
    setFilter(EsgAnalysisFiltersKeys.subBuildingClasses, subBuildingClass);
  };

  const benchmark = benchmarkData.getBenchmark;
  const { getAnalyzedBuildings: analyzedBuildings } = data;

  if (analyzedBuildings.analyzedBuildingsCount === 0) {
    return (
      <Unavailable
        title={t('ESGAnalysisSection_FilterNotApplicable')}
        subTitle={t('ESGAnalysisSection_ChangeFilterToStartAnalysis')}
      />
    );
  }

  const series = analyzedBuildings?.floorAreaBySubBuildingClass.map((group) => group.area);
  const totalArea = sum(series);

  const { onePointFive: strandingYearOnePointFive } = analyzedBuildings.strandingDates.co2;
  const strandingDateChangeOnePointFive =
    strandingYearOnePointFive.length > 0 && benchmark.strandingDates.co2.onePointFive.length > 0
      ? strandingYearOnePointFive[0].startYear - benchmark.strandingDates.co2.onePointFive[0].startYear
      : null;

  const co2Emissions = pathValueAtYear(analyzedBuildings.co2Paths.pathM2, currentYear);

  //@ts-ignore
  const co2EmissionsChange = calculateBenchmarkChange(co2Emissions, benchmark?.co2Paths.pathM2);

  const co2Costs = pathValueAtYear(analyzedBuildings.co2Paths.costsM2, currentYear);

  //@ts-ignore
  const co2CostsChange = calculateBenchmarkChange(co2Costs, benchmark?.co2Paths.costsM2);

  const energyDemand = pathValueAtYear(analyzedBuildings.energyPaths.pathM2, currentYear);

  //@ts-ignore
  const energyDemandChange = calculateBenchmarkChange(energyDemand, benchmark?.energyPaths.pathM2);

  const energyCosts = pathValueAtYear(analyzedBuildings.energyPaths.costsM2, currentYear);

  //@ts-ignore
  const energyCostsChange = calculateBenchmarkChange(energyCosts, benchmark?.energyPaths.costsM2);

  const primaryEnergy = analyzedBuildings.averagePrimaryEnergy;

  //@ts-ignore
  const primaryEnergyChange = (1 - primaryEnergy / benchmark?.averagePrimaryEnergy) * -1;

  const currentEnergyDataBySource = analyzedBuildings.energyDataBySourceType;

  //@ts-ignore
  const energySources = currentEnergyDataBySource.map((energySource) => ({
    energy_source_type: energySource.energySourceType,
    co2_emissions: energySource.co2EmissionsM2,
    energy_consumption: energySource.energyConsumptionM2,
    energy_consumption_total: energySource.energyConsumptionTotal,
    co2_emissions_total: energySource.co2EmissionsTotal,
  }));

  const climateRisks = analyzedBuildings.climateRisks.map((risk) => ({
    ...risk,
    type: climate_risk_type_enum[risk.type],
  }));

  const scope123Data = pathValueAtYear(analyzedBuildings.scope123Co2Emissions, currentYear);
  const buildingsByEnergySource = analyzedBuildings.buildingsByEnergySourceType;
  const buildingsByEUTaxonomy = analyzedBuildings.buildingsByEUTaxonomyCompliance;

  //@ts-ignore
  const selectedBenchmarkName = benchmarks.find((benchmark) => benchmark.id === benchmarkId).name;

  const changeEndAdornment = `${t('General_Vs')} ${selectedBenchmarkName}`;

  return (
    <Grid container spacing={3}>
      <Grid container item xs={6}>
        <Card sx={{ p: 3, pb: 1, width: '100%' }}>
          <Grid container spacing={3}>
            <Grid container item xs={12} spacing={2} sx={{ p: 2 }}>
              <Grid container item xs={6}>
                <Grid item xs={12}>
                  <Typography sx={{ pb: 1 }} variant="body2" color="text.secondary">
                    {t('ESGAnalysisSection_TotalBuildings')}
                  </Typography>
                  <Typography variant="subtitle1">{totalBuildings}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ pb: 1 }} variant="body2" color="text.secondary">
                    {t('ESGAnalysisSection_TotalUsableArea')}
                  </Typography>{' '}
                  <Typography variant="subtitle1">{fShortenToTwoDecimal(totalArea)} m²</Typography>
                </Grid>
              </Grid>
              {analyzedBuildings?.floorAreaBySubBuildingClass && (
                <Grid item xs={6}>
                  <Typography sx={{ pb: 1 }} variant="body2" color="text.secondary">
                    {t('ESGAnalysisSection_AreaDistributionBySubBuildingClass')}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <SubBuildingClassDistributionGraph
                      floorAreaBySubBuildingClass={analyzedBuildings.floorAreaBySubBuildingClass}
                      onSubBuildingClassSelected={handledSubBuildingClassSelected}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid container item xs={6} spacing={3}>
        <Grid item xs={6}>
          <BuildingEfficiencyCard
            efficiencyClass={efficiency_class_enum[analyzedBuildings.averageEfficiencyClass]}
            landlordProportion={analyzedBuildings.averageTaxBracket.landlordProportion}
            change={energyDemandChange}
            isAverage
            // TODO: PRE-3640
            showResidentialLabel={analyzedBuildings.floorAreaBySubBuildingClass.length > 1}
            sx={{ height: '100%' }}
          />
        </Grid>
        <Grid item xs={6}>
          <StrandingRiskCard
            strandingYearOnePointFive={strandingYearOnePointFive[0]?.startYear ?? 2050}
            change={strandingDateChangeOnePointFive}
            sx={{ height: '100%' }}
            selectedBenchmarkName={selectedBenchmarkName}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={3} sx={{ pt: 3 }}>
        <Grid container item xs={12} spacing={3} wrap="nowrap">
          <Grid item xs zeroMinWidth>
            <SimpleComparisonMetric
              title={t('General_CO2Intensity')}
              //@ts-ignore
              value={co2Emissions}
              change={co2EmissionsChange}
              changeEndAdornment={changeEndAdornment}
              unit="kg CO₂e"
              showTotal={showTotal}
              totalArea={totalArea}
            />
          </Grid>
          <Grid item xs zeroMinWidth>
            <SimpleComparisonMetric
              title={t('General_CO2Taxes')}
              //@ts-ignore
              value={co2Costs}
              change={co2CostsChange}
              changeEndAdornment={changeEndAdornment}
              unit="€"
              showTotal={showTotal}
              totalArea={totalArea}
            />
          </Grid>
          <Grid item xs zeroMinWidth>
            <SimpleComparisonMetric
              title={t('General_FinalEnergy')}
              //@ts-ignore
              value={energyDemand}
              change={energyDemandChange}
              changeEndAdornment={changeEndAdornment}
              unit="kWh"
              showTotal={showTotal}
              totalArea={totalArea}
            />
          </Grid>
          <Grid item xs zeroMinWidth>
            <SimpleComparisonMetric
              title={t('General_EnergyCost')}
              //@ts-ignore
              value={energyCosts}
              change={energyCostsChange}
              changeEndAdornment={changeEndAdornment}
              unit="€"
              showTotal={showTotal}
              totalArea={totalArea}
            />
          </Grid>
          <Grid item xs zeroMinWidth>
            <SimpleComparisonMetric
              title={t('General_PrimaryEnergy')}
              value={primaryEnergy}
              change={primaryEnergyChange}
              changeEndAdornment={changeEndAdornment}
              unit="kWh"
              showTotal={showTotal}
              totalArea={totalArea}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <EsgPathGraph
            co2Path={analyzedBuildings.co2Paths.pathM2}
            co2Targets={analyzedBuildings.co2Targets}
            energyPath={analyzedBuildings.energyPaths.pathM2}
            energyTargets={analyzedBuildings.energyTargets}
            buildingLegendName={t('General_Portfolio')}
            strandingYears={strandingYearOnePointFive}
            constructionYear={constructionYear}
          />
        </Grid>
        {currentView === 'esg-portfolio' && (
          <Grid item xs={12}>
            <AreaBubbleChart buildings={analyzedBuildings.buildingsGeneralData} />
          </Grid>
        )}
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={6}>
            <EnergySourceCO2Graph
              energySources={energySources}
              showTotal={showTotal}
              onItemSelect={handleEnergySourceSelect}
            />
          </Grid>
          <Grid item xs={6}>
            <EnergySourceConsumptionGraph
              energySources={energySources}
              showTotal={showTotal}
              totalArea={totalArea}
              onItemSelect={handleEnergySourceSelect}
            />
          </Grid>
          <Grid item container xs={12} spacing={3}>
            <Grid item xs={6}>
              <CO2CostDevelopmentGraph
                co2Costs={analyzedBuildings.co2Paths.costsM2}
                co2CostsTotal={analyzedBuildings.co2Paths.costsTotal}
                landlordProportion={analyzedBuildings.averageTaxBracket.landlordProportion}
                showTotal={showTotal}
                sx={{ minHeight: '100%' }}
              />
            </Grid>
            <Grid item xs={6}>
              <EnergyCostDevelopmentGraph
                energyCosts={analyzedBuildings.energyPaths.costsM2}
                energyCostsTotal={analyzedBuildings.energyPaths.costsTotal}
                showTotal={showTotal}
                sx={{ minHeight: '100%' }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={6}>
            <PortfolioCO2CostDistributionGraph buildingsByTaxBracket={analyzedBuildings.buildingsByTaxBracket} />
          </Grid>
          <Grid item xs={6}>
            <PortfolioEfficiencyClassDistributionGraph
              buildingsByEfficiencyClass={analyzedBuildings.buildingsByEfficiencyClass}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={3}>
          <Grid item xs={6}>
            {/*@ts-ignore */}
            <Scope123Graph scopeData={scope123Data.data} showTotal={showTotal} />
          </Grid>
          <Grid item xs={6}>
            <BuildingsByEnergySourceType data={buildingsByEnergySource} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ClimateRiskGraph climateRisks={climateRisks} />
        </Grid>

        <Grid item xs={6}>
          <EuTaxonomyGraph scopeData={buildingsByEUTaxonomy} />
        </Grid>
      </Grid>
    </Grid>
  );
}
