import { emission_factor_origin_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateEmissionFactorOriginEnum = (enumValue: emission_factor_origin_enum) => {
  switch (enumValue) {
    case emission_factor_origin_enum.CERTIFICATE:
      return <Trans i18nKey="Enum_EmissionFactorOriginEnum-CERTIFICATE" />;
    case emission_factor_origin_enum.GLOBAL_PREFERENCE:
      return <Trans i18nKey="Enum_EmissionFactorOriginEnum-GLOBAL_PREFERENCE" />;
  }
};

export const translateEmissionFactorOriginEnum_dynamic = (
  enumValue: emission_factor_origin_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case emission_factor_origin_enum.CERTIFICATE:
      return t('Enum_EmissionFactorOriginEnum-CERTIFICATE');
    case emission_factor_origin_enum.GLOBAL_PREFERENCE:
      return t('Enum_EmissionFactorOriginEnum-GLOBAL_PREFERENCE');
  }
};
