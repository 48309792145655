/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { PropsWithChildren, ReactNode, useState } from 'react';
import Iconify from '../../../../../components/Iconify';
import useBuilding from '../../Context/useBuilding';
import EnvelopeSectionHead from './EnvelopeSectionHead';

type Props = {
  title: string;
  icon: string;
  totalTabulaEnvelopes: number;
  totalEnvelopes: number;
  actions: ReactNode;
  resetSectionEnvelopes?: () => void;
  isSectionDirty: boolean;
};

export type EnvelopeProps = {
  title: string;
  icon: string;
};

export function EnvelopeSectionWrapper({
  children,
  title,
  icon,
  actions,
  totalTabulaEnvelopes,
  totalEnvelopes,
  resetSectionEnvelopes,
  isSectionDirty,
}: PropsWithChildren<Props>) {
  const theme = useTheme();
  const { hasEditAccess } = useBuilding();

  const [expanded, setExpanded] = useState(true);

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      {totalEnvelopes !== 0 ? (
        <Box sx={{ borderRadius: 3, mb: 3 }}>
          <Accordion
            style={{ boxShadow: 'none', borderRadius: '24px' }}
            disableGutters={true}
            defaultExpanded={true}
            expanded={expanded}
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              '&.Mui-expanded': {
                background: theme.palette.grey[200],
              },
              background: theme.palette.grey[200],
              p: 1,
            }}
          >
            <AccordionSummary
              onClick={toggleAccordion}
              sx={{
                '.MuiAccordionSummary-expandIconWrapper': {
                  width: 36,
                  height: 36,
                },
                px: 2.5,
              }}
              expandIcon={
                <IconButton>
                  <Iconify
                    icon="ic:outline-arrow-forward-ios"
                    style={{ transform: 'rotate(90deg)' }}
                    width={18}
                    height={18}
                  />
                </IconButton>
              }
            >
              <EnvelopeSectionHead
                icon={icon}
                title={title}
                expanded={expanded}
                totalTabulaEnvelopes={totalTabulaEnvelopes}
                actions={actions}
                //@ts-ignore
                resetSectionEnvelopes={resetSectionEnvelopes}
                isSectionDirty={isSectionDirty}
              />
            </AccordionSummary>
            <AccordionDetails sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                {children}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      ) : (
        hasEditAccess && (
          <Box
            p={3}
            mb={3}
            sx={{
              background: 'transparent',
              borderRadius: 3,
              border: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Stack direction={'row'} alignItems={'center'}>
              <Box component="img" src={icon} width={36} height={36} mr={2} />
              <Typography variant="h5" mr={2}>
                {title}
              </Typography>
            </Stack>
            {children}
          </Box>
        )
      )}
    </>
  );
}
