import { Box, Stack } from '@mui/material';
import { data_source_type_enum } from '@predium/enums';
import { ReactNode } from 'react';
import ActionMoreMenu from '../common/ActionMoreMenu';
import { RHFNumberFieldProps } from '../hook-form/RHFNumberField';
import Input, { InputProps } from './Input';
import Value from './Value';

type Props = {
  suffix?: string;
  source?: data_source_type_enum;
  actions?: ReactNode;
  numberFieldProps?: Partial<RHFNumberFieldProps>;
  onValueChange?: (value: number) => void;
  onClickAway?: InputProps['onClickAway'];
  showTooltip?: boolean;
  isLoading?: boolean;
  ignoreIsDirty?: boolean;
};

type PropsValue = Props & {
  value: number;
};

type PropsName = Props & {
  name: string;
};

export type CustomDataSourceInputProps = PropsName | PropsValue;

const CustomDataSourceInput = ({
  actions,
  source,
  suffix,
  numberFieldProps,
  onValueChange,
  onClickAway,
  showTooltip,
  isLoading,
  ignoreIsDirty,
  ...props
}: CustomDataSourceInputProps) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {'name' in props && props.name && (
        <Input
          name={props.name}
          source={source}
          suffix={suffix}
          numberFieldProps={numberFieldProps}
          onValueChange={onValueChange}
          onClickAway={onClickAway}
          showTooltip={showTooltip}
          isLoading={isLoading}
          ignoreIsDirty={ignoreIsDirty}
        />
      )}
      {'value' in props && typeof props.value === 'number' && (
        <Value value={props.value} source={source} suffix={suffix} isLoading={isLoading} />
      )}
      {actions && (
        <Box>
          <ActionMoreMenu
            variant="icon"
            actions={actions}
            sx={{
              mr: -1.5,
              svg: {
                width: 16,
                height: 16,
              },
            }}
          />
        </Box>
      )}
    </Stack>
  );
};

export default CustomDataSourceInput;
