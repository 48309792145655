import { energy_consumer_technology_type_enum, energy_source_type_enum } from '@predium/enums';

export type EnergySystemConsumerRoute = { energy_final: number; energy_source_type_id: energy_source_type_enum }[];

export const primaryEnergySourceFromEnergySystems = (
  energyConsumerSystemRoutes: EnergySystemConsumerRoute,
): energy_source_type_enum => {
  const sumByEnergySourceType = energyConsumerSystemRoutes.reduce<{ [key in energy_source_type_enum]?: number }>(
    (acc, curr) => {
      acc[curr.energy_source_type_id] = (acc[curr.energy_source_type_id] ?? 0) + curr.energy_final;
      return acc;
    },
    {},
  );
  const aggregatedEnergySystemConsumerRoutes = Object.entries(sumByEnergySourceType).map((group) => ({
    energy_source_type_id: group[0],
    energy_final: group[1],
  }));
  const sorted = aggregatedEnergySystemConsumerRoutes.sort((a, b) => {
    return b.energy_final - a.energy_final;
  });
  return sorted?.[0].energy_source_type_id as energy_source_type_enum;
};

/**
 * As Tabula does not contain all energy_source_type's we have, we need to map them to the closest match.
 *
 * This mapping is only used to filter/match the Tabula-routes and find a route even though it's not a 100% match with the energy_source_type.
 */
export const missingEnergySourceTypeMapper = (energySourceType: energy_source_type_enum): energy_source_type_enum => {
  switch (energySourceType) {
    case energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS:
    case energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL:
      return energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL;

    case energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE:
      return energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE;

    case energy_source_type_enum.BIO_GAS:
    case energy_source_type_enum.LPG:
      return energy_source_type_enum.NATURAL_GAS;

    // This might be especially confusing, but is nevertheless our best match
    // As we also map the energy_consumer_technology_type to the closest match, this is fine
    case energy_source_type_enum.COAL:
    case energy_source_type_enum.LIGNITE:
      return energy_source_type_enum.WOODEN_PELLETS;

    case energy_source_type_enum.WOOD:
      return energy_source_type_enum.WOODEN_PELLETS;
    default:
      return energySourceType;
  }
};

export const missingEnergyConsumerTechnologyTypeMapper = (
  energySourceType: energy_source_type_enum,
  energyConsumerTechnologyType: energy_consumer_technology_type_enum,
): energy_consumer_technology_type_enum => {
  switch (energySourceType) {
    case energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL:
    case energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS:
    case energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE:
      return energy_consumer_technology_type_enum.DISTRICT_HEATING_WITHOUT_KWK;

    case energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL:
    case energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS:
    case energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE:
      return energy_consumer_technology_type_enum.DISTRICT_HEATING_WITH_KWK;

    case energy_source_type_enum.BIO_GAS:
    case energy_source_type_enum.LPG:
      return energy_consumer_technology_type_enum.GAS_NON_CONDENSING_BOILER;

    // Here we make up for the weird mapping from COAL/LIGNITE -> WOODEN_PELLETS in the energy_source_type mapping
    case energy_source_type_enum.COAL:
    case energy_source_type_enum.LIGNITE:
      return energy_consumer_technology_type_enum.COAL_FURNACE;

    case energy_source_type_enum.WOOD:
      return energy_consumer_technology_type_enum.WOOD_FURNACE;
    default:
      return energyConsumerTechnologyType;
  }
};
