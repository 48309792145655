/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableRow,
  alpha,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataCollectionEmissionCertificateDraftFragment,
  DataCollectionEmissionCertificateFragment,
} from '../../../../../../libs/generated/client-graphql/src/generated/graphql';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import Unavailable from '../../../components/Unavailable';
import TablePaginationStandard from '../../../components/table/TablePaginationStandard';
import TableRowWithHighlight, { hasRecentlyChanged } from '../../../components/table/TableRowWithHighlight';
import useTable, { applySortFilter } from '../../../hooks/useTable';
import { useLanguage } from '../../../provider/LanguageProvider';
import DataCollectionHead, { HeadLabel } from '../../../sections/DataCollection/Buildings/DataCollectionHead';

import map from 'lodash/map';
import uniq from 'lodash/uniq';
import { useNavigate } from 'react-router-dom';
import OverflowText from '../../../components/OverflowText';
import useSessionData from '../../../hooks/useSessionData';
import { PATHS } from '../../../routes';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '../../../utils/formatTime';
import EmissionCertificateActionMenu from './EmissionCertificateActionMenu';
import { EmissionCertificateStatusType, EmissionCertificatesTabName } from './EmissionCertificatesTabs';

type EmissionCertificatesListProps = {
  selectedTab: EmissionCertificatesTabName;
  emissionCertificates: DataCollectionEmissionCertificateFragment[];
  openCreationModal: () => void;
};

type RowProps = {
  row: DataCollectionEmissionCertificateFragment;
};

export const getStatusFromCertificate = (
  certificate: DataCollectionEmissionCertificateDraftFragment | DataCollectionEmissionCertificateFragment,
): EmissionCertificateStatusType => {
  if (dayjs(certificate.valid_until).isBefore(dayjs())) {
    return 'expired';
  }

  return 'active';
};

export default function EmissionCertificatesList({
  selectedTab,
  emissionCertificates,
  openCreationModal,
}: EmissionCertificatesListProps) {
  const { t } = useTranslation();
  const { isAdmin } = useSessionData();

  const COLUMNS: HeadLabel[] = [
    { id: 'issuer', label: t('General_Issuer'), minWidth: 250 },
    { id: 'emission_factor', label: t('General_EmissionFactor'), minWidth: 180 },
    { id: 'primary_energy_factor', label: t('General_PrimaryEnergyFactor'), minWidth: 180, alignment: 'right' },
    { id: 'valid_until', label: t('General_ValidUntil'), minWidth: 160 },
    //We disable sorting here, because we already group by status in the tabs
    { id: 'status', label: t('General_Status'), sortingDisabled: true, minWidth: 100 },
  ];

  // add the number of linked buildings if the user is an admin + the action menu
  if (isAdmin) {
    COLUMNS.splice(3, 0, {
      id: 'linked_buildings',
      label: t('DataCollection_EmissionCertificates_LinkedBuildings'),
      minWidth: 200,
      alignment: 'right',
    });

    COLUMNS.push({ id: 'action_menu', label: '', sortingDisabled: true });
  }

  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    order,
    orderBy,
    // filterName,
    // handleFilterByName,
    handleRequestSort,
  } = useTable({ defaultOrderBy: 'created_at', defaultOrder: 'desc', defaultRowsPerPage: 10 });

  const filteredRows = emissionCertificates.filter((item) => {
    if (selectedTab === 'all') {
      return true;
    }

    return getStatusFromCertificate(item) === selectedTab;
  });

  const sortedRows = applySortFilter({
    data: filteredRows,
    orderOptions: {
      order,
      orderBy,
    },
  });

  // tracking the empty rows in the next page, to prevent the table from shifting
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows.length) : 0;

  const noData = filteredRows.length === 0;

  return (
    <>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <DataCollectionHead
                order={order}
                orderBy={orderBy}
                headLabel={COLUMNS}
                rowCount={sortedRows.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return <Row key={row.id} row={row} />;
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {noData && <EmptyState openCreationModal={openCreationModal} />}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePaginationStandard
          count={sortedRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Card>
    </>
  );
}

function Row({ row }: RowProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAdmin } = useSessionData();

  const linkedBuildings = useMemo(() => {
    if (!isAdmin) {
      return 0;
    }

    const emissionFactorCertificates = row.energy_system_consumer_routes;
    const primaryEnergyFactorCertificates = row.primary_energy_factor_energy_system_consumer_routes;

    const ids = uniq(
      map(
        [...emissionFactorCertificates, ...primaryEnergyFactorCertificates],
        'energy_system.building_model.building_id',
      ),
    );

    return ids.length;
  }, [isAdmin, row]);

  const onClickHandler = () => {
    navigate(PATHS.dataCollection.emissionCertificate({ id: row.id }));
  };

  return (
    <>
      <TableRowWithHighlight
        background={hasRecentlyChanged(row.created_at) ? 'success' : 'default'}
        sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }}
        hover
        onClick={onClickHandler}
      >
        <TableCell>
          <OverflowText
            text={row.issuer}
            maxWidth="250px"
            variant="subtitle2"
            TooltipProps={{
              arrow: true,
              placement: 'top-start',
            }}
          />
        </TableCell>
        <TableCell>
          {row.emission_factor !== null ? `${row.emission_factor} ${t('General_Units_KGCO2KWH')}` : '-'}
        </TableCell>
        <TableCell align="right">{row.primary_energy_factor ?? '-'}</TableCell>
        {isAdmin && <TableCell align="right">{linkedBuildings}</TableCell>}
        <DateCell
          sx={{
            color: dayjs(row.valid_until).isBefore(dayjs()) ? theme.palette.error.main : 'inherit',
          }}
          date={row.valid_until}
        />
        <TableCell>
          <StatusBadge status={getStatusFromCertificate(row)} />
        </TableCell>
        {isAdmin && (
          <TableCell
            align="right"
            onClick={(e) => {
              // prevent the edit modal from opening when clicking on the action menu
              e.stopPropagation();
            }}
          >
            <EmissionCertificateActionMenu
              openEditModal={onClickHandler}
              emissionCertificate={row}
              linkedBuildings={linkedBuildings}
            />
          </TableCell>
        )}
      </TableRowWithHighlight>
    </>
  );
}

function StatusBadge({ status }: { status: EmissionCertificateStatusType }) {
  const { t } = useTranslation();
  const theme = useTheme();

  let textColor: string, backgroundColor: string;

  switch (status) {
    case 'active':
      textColor = theme.palette.success.dark;
      backgroundColor = alpha(theme.palette.success.main, 0.16);
      break;
    case 'in_review':
      textColor = theme.palette.info.dark;
      backgroundColor = theme.palette.info.lighter;
      break;
    case 'expired':
      textColor = theme.palette.error.dark;
      backgroundColor = alpha(theme.palette.error.main, 0.16);
      break;
    default:
      textColor = theme.palette.grey[200];
      backgroundColor = theme.palette.grey[900];
  }

  const getDisplayValueFromStatus = (status: EmissionCertificateStatusType) => {
    switch (status) {
      case 'active':
        return t('General_Valid');
      case 'expired':
        return t('General_Expired');
      case 'in_review':
        return t('General_InReview');
      default:
        return '';
    }
  };

  return (
    <Label
      sx={{
        color: textColor,
        backgroundColor,
      }}
    >
      {getDisplayValueFromStatus(status)}
    </Label>
  );
}

function EmptyState({ openCreationModal }: { openCreationModal: () => void }) {
  const { t } = useTranslation();
  const { isAdmin } = useSessionData();

  return (
    <TableBody>
      <TableRow>
        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
          {isAdmin ? (
            <Unavailable
              iconString="gridicons:create"
              title={t('General_NoEmissionCertificatesFound')}
              subTitle={t('DataCollection_EmissionCertificates_AddCertificatePrompt')}
              onClick={() => {
                openCreationModal();
              }}
            />
          ) : (
            <Unavailable title={t('General_NoEmissionCertificatesFound')} />
          )}
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

/**
 * @TODO: maybe add this component to its own file in /table folder
 */

type DateCellProps = {
  date: string;
};

function DateCell({ date, ...other }: DateCellProps & TableCellProps) {
  const { language } = useLanguage();

  return <TableCell {...other}>{formatDateToLocale(date, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language)}</TableCell>;
}
