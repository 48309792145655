/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Chip, ChipProps } from '@mui/material';
import Iconify from '../Iconify';

type Props = {
  /**
   * this will set an icon if defined. Use Iconfify icon name only.
   */
  startIcon?: string;
  customColor: string;
  backgroundColor: string;
};

const PreChip = ({
  startIcon,
  customColor,
  backgroundColor,
  size = 'small',
  variant = 'soft',
  label,
  sx,
}: Props & ChipProps) => {
  return (
    <Chip
      //@ts-ignore
      icon={startIcon && <Iconify icon={startIcon} color={customColor} width={18} height={18} />}
      label={label}
      sx={{
        background: backgroundColor,
        color: customColor,
        px: 1,
        mr: 1,
        fontWeight: 500,
        '&:hover': {
          background: backgroundColor,
        },
        ...sx,
      }}
      variant={variant}
      size={size}
    />
  );
};

export default PreChip;
