// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 60,
  MAIN_DESKTOP_HEIGHT: 60,
  DASHBOARD_DESKTOP_HEIGHT: 60,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 60,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  FILTER_SIDEBAR_WIDTH: 300,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

type ThemeModeValue = 'light' | 'dark';
type ThemeDirectionValue = 'rtl' | 'ltr';
type ThemeContrastValue = 'default' | 'bold';
type ThemeLayoutValue = 'vertical' | 'horizontal' | 'mini';
type ThemeColorPresetsValue = 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red';
type ThemeStretchValue = boolean;

type SettingsValueProps = {
  themeMode: ThemeModeValue;
  themeLayout: ThemeLayoutValue;
  themeStretch: ThemeStretchValue;
  themeContrast: ThemeContrastValue;
  themeDirection: ThemeDirectionValue;
  themeColorPresets: ThemeColorPresetsValue;
};

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'default',
  themeStretch: false,
};

// TABLE HIGHLIGHTING

/**
 * How many ms ago the table row was created/updated to be considered "new" and highlighted
 *  */
export const HIGHLIGHT_TRIGGER_DURATION = 20000;

/**
 * How long the table row should be highlighted for
 *  */
export const HIGHLIGHT_AUTO_HIDE_DURATION = 600;

/**
 * Highlight color opacity (0-1)
 */
export const HIGHLIGHT_OPACITY = 0.12;
