import { Box, Button, Stack, Typography } from '@mui/material';
import {
  DataCollectionEnergyCertificateDraftSaveEnergyCertificateDraftMutation,
  DataCollectionSaveConsumptionInvoiceDraftMutation,
  DataCollectionSaveConsumptionInvoiceMutation,
} from '@predium/client-graphql';
import { PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../components/Iconify';
import NavigationModal from '../../../../components/NavigationModal/NavigationModal';
import PreDialog from '../../../../components/presentations/PreDialog/PreDialog';

// Timeout in minutes so we can directly display it in the UI
export const UPLOAD_TIMEOUT_MINUTES = 1;
export const OCR_TIMEOUT_MINUTES = 3;

type DataCollectionDraftProps = PropsWithChildren<{
  submitForm: () =>
    | Promise<DataCollectionEnergyCertificateDraftSaveEnergyCertificateDraftMutation>
    | Promise<DataCollectionSaveConsumptionInvoiceDraftMutation>
    | Promise<DataCollectionSaveConsumptionInvoiceMutation>;
  deleteModalOpen: boolean;

  acceptOcrResultModalOpen?: boolean;
  setAcceptOcrResultModalOpen?: (open: boolean) => void;
}>;

export default function DataCollectionDraft({
  submitForm,
  deleteModalOpen,
  children,
  acceptOcrResultModalOpen = false,
  setAcceptOcrResultModalOpen,
}: DataCollectionDraftProps) {
  const { t } = useTranslation();

  const {
    formState: { dirtyFields },
  } = useFormContext();

  return (
    <>
      <Box
        sx={{
          // right content part of the focus view - padding left - padding right
          width: 'calc(30vw - 24px - 12px)',
        }}
      >
        {children}
      </Box>

      {/* DIALOGS AND PROMPTS */}
      <PreDialog
        type="definedByProperty"
        open={acceptOcrResultModalOpen}
        onClose={() => setAcceptOcrResultModalOpen!(false)}
        dialogtitle={t('General_AcceptOCRResult-Title')}
        content={
          <Stack>
            <Typography sx={{ pb: 1 }}>{t('General_OCRResultReady')}</Typography>
            <Typography>{t('General_AcceptOCRResult')}</Typography>
          </Stack>
        }
        actions={
          <Button
            variant="contained"
            autoFocus
            startIcon={<Iconify icon="bi:check" width={20} height={20} />}
            onClick={async () => {
              await submitForm();

              setAcceptOcrResultModalOpen!(false);
            }}
          >
            {t('General_AcceptResult')}
          </Button>
        }
      />

      <NavigationModal
        title={t('DataCollection_BuildingDocuments_UnsavedChangesInDraft-Title')}
        message={t('DataCollection_BuildingDocuments_UnsavedChangesInDraft-Message')}
        primaryActionText={t('General_Save')}
        // isDirty is somehow true even though no fields are dirty after reset.
        shouldBlock={Object.keys(dirtyFields).length > 0 && !deleteModalOpen}
        primaryActionCallback={async () => {
          const energyCertificateDraft = await submitForm();
          return Boolean(energyCertificateDraft);
        }}
      />
    </>
  );
}
