import { country_enum, country_state_enum, energy_source_type_enum } from '@predium/enums';
import { EmissionFactorProvider } from './emission-factor-provider.type';

const GEG_lookup: Record<country_enum, Record<energy_source_type_enum, number>> = {
  [country_enum.DE]: {
    [energy_source_type_enum.FUEL_OIL]: 0.31,
    [energy_source_type_enum.NATURAL_GAS]: 0.24,
    [energy_source_type_enum.LPG]: 0.27,
    [energy_source_type_enum.COAL]: 0.4,
    [energy_source_type_enum.LIGNITE]: 0.43,
    [energy_source_type_enum.WOOD]: 0.02,
    [energy_source_type_enum.WOODEN_PELLETS]: 0.02,
    [energy_source_type_enum.UNSPECIFIED]: 1,
    [energy_source_type_enum.ELECTRICITY_MIX]: 0.56,
    [energy_source_type_enum.ELECTRICITY_GREEN]: 0,
    [energy_source_type_enum.SOLAR]: 0,
    [energy_source_type_enum.BIO_GAS]: 0.14,

    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]: 0.4,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]: 0.3,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: 0.06,

    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]: 0.3,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]: 0.18,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]: 0.04,
  },

  [country_enum.AT]: {
    [energy_source_type_enum.FUEL_OIL]: 0.271,
    [energy_source_type_enum.NATURAL_GAS]: 0.201,
    [energy_source_type_enum.LPG]: 0.271,
    [energy_source_type_enum.COAL]: 0.36,
    [energy_source_type_enum.LIGNITE]: 0.36,
    [energy_source_type_enum.WOOD]: 0.09,
    [energy_source_type_enum.WOODEN_PELLETS]: 0.09,
    [energy_source_type_enum.UNSPECIFIED]: 0.36,
    [energy_source_type_enum.ELECTRICITY_MIX]: 0.156,
    [energy_source_type_enum.ELECTRICITY_GREEN]: 0,
    [energy_source_type_enum.SOLAR]: 0,
    [energy_source_type_enum.BIO_GAS]: 0.018,

    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]: 0.193,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]: 0.193,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: 0.059,

    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]: 0.067,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]: 0.067,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]: 0.067,
  },

  [country_enum.PL]: {
    [energy_source_type_enum.FUEL_OIL]: 0.279,
    [energy_source_type_enum.NATURAL_GAS]: 0.202,
    [energy_source_type_enum.LPG]: 0.231,
    [energy_source_type_enum.COAL]: 0.341,
    [energy_source_type_enum.LIGNITE]: 0.375,
    [energy_source_type_enum.WOOD]: 0.112,
    [energy_source_type_enum.WOODEN_PELLETS]: 0.112,
    [energy_source_type_enum.UNSPECIFIED]: 0.399,
    [energy_source_type_enum.ELECTRICITY_MIX]: 0.1821,
    [energy_source_type_enum.ELECTRICITY_GREEN]: 0,
    [energy_source_type_enum.SOLAR]: 0,
    [energy_source_type_enum.BIO_GAS]: 0,

    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]: 0.399,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]: 0.399,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: 0.399,

    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]: 0.399,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]: 0.399,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]: 0.399,
  },
};

export const GegEmissionFactorProvider: EmissionFactorProvider = {
  getEmissionFactor({
    energySourceType,
    country,
  }: {
    energySourceType: energy_source_type_enum;
    year: number;
    country: country_enum;
    countryState: country_state_enum;
    postalCode: string;
  }): number {
    return GEG_lookup[country][energySourceType];
  },
};
