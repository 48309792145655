import { graphql } from '@predium/client-graphql';

graphql(`
  fragment DataCollectionSubBuildingConsumption on consumption {
    id
    area_id
    provider
    to
    from
    cost
    display_unit_value
    value
    created_by_user {
      first_name
      last_name
    }
    created_at
    consumption_type_id
    source_type_id
    invoice_id
    energy_source_type_id
    sub_type_id
    consumption_waste_detail {
      id
      volume
      display_unit_volume
      frequency
      display_unit_frequency
      amount
      price
    }
  }
`);

graphql(`
  fragment DataCollectionSubBuildingConsumptionDraft on consumption_draft {
    id
    area_id
    provider
    to
    from
    cost
    display_unit_value
    value
    created_by_user {
      first_name
      last_name
    }
    created_at
    consumption_type_id
    source_type_id
    energy_source_type_id
    sub_type_id
    consumption_invoice_draft {
      id
      draft_status
    }
    consumption_waste_details_draft {
      id
      volume
      display_unit_volume
      frequency
      display_unit_frequency
      amount
      price
    }
  }
`);

graphql(`
  fragment DataCollectionSubBuildingSubBuildingSubsidy on subsidy {
    id
    email
    amount_description
    provider_updated_at
    region_type
    categories {
      subsidy_category_type
    }
    title
    type
    url
    __typename
  }
`);

graphql(`
  fragment DataCollectionBuildingEnvelope on envelope {
    id
    data_source_type_id
    inclination
    orientation
    envelope_type_id
    insulation_thickness
    insulation_material_name
    insulation_material_category_id
    insulation_method_id
    insulation_lambda
    base_construction_thickness
    base_construction_material_name
    base_construction_lambda
    area
    u_value
  }
`);

graphql(`
  fragment DataCollectionBuildingEnergySystemConsumerRoute on energy_system_consumer_route {
    id
    energy_final
    energy_effective
    energy_source_type_id
    energy_primary
    emission_factor_origin_id
    emission_certificate_id
    emission_certificate {
      emission_factor
    }
    primary_energy_factor_emission_certificate_id
    primary_energy_factor_emission_certificate {
      primary_energy_factor
    }
    energy_consumer {
      id
      energy_consumer_technology_type_id
      energy_consumer_type_id
      efficiency
      construction_year
    }
    energy_distribution {
      id
      energy_distribution_type_id
      heat_distribution_loss
      heat_transfer_loss
      last_hydraulic_balancing
      last_balancing_set_to_never
      construction_year
    }
    energy_storage {
      id
      energy_storage_type_id
      annual_heat_loss
      construction_year
    }
  }
`);

graphql(`
  fragment DataCollectionBuildingEnergySystemProducerRoute on energy_system_producer_route {
    energy_source_type_id
    energy_producer {
      name
    }
  }
`);

graphql(`
  fragment DataCollectionBuildingEnergySystem on energy_system {
    id
    energy_system_type_id
    data_source_type_id
    energy_system_consumer_routes(order_by: { id: asc }) {
      ...DataCollectionBuildingEnergySystemConsumerRoute
    }
    energy_system_producer_routes {
      ...DataCollectionBuildingEnergySystemProducerRoute
    }
  }
`);

graphql(`
  fragment DataCollectionBuildingModel on building_model {
    id
    energy_final
    action_planning_available
    envelope_units(order_by: { id: asc }) {
      ...DataCollectionBuildingEnvelope
    }
    energy_systems(order_by: { energy_system_type_id: asc }) {
      ...DataCollectionBuildingEnergySystem
    }
    energy_paths(where: { year: { _eq: $year } }) {
      efficiency_class_id
    }
  }
`);

graphql(`
  fragment DataCollectionBuildingSubBuilding on sub_building {
    id
    energy_standard
    sub_building_class
    units_residential
    units_commercial
    building_id
    consumptions {
      ...DataCollectionSubBuildingConsumption
    }
    consumption_drafts {
      ...DataCollectionSubBuildingConsumptionDraft
    }
    subsidies {
      subsidy {
        ...DataCollectionSubBuildingSubBuildingSubsidy
      }
    }
    subsidies_updated_at
  }
`);

graphql(`
  fragment DataCollectionBuildingWithSubBuildings on building {
    __typename
    id
    year_constructed
    building_state_id
    monument_protection
    leasehold
    milieu_protection
    heritage_district
    address {
      street
      city
      postal_code
      country_state_id
      country_id
      mapbox_building_ids
      mapbox_pitch
      mapbox_bearing
      mapbox_zoom
      latitude
      longitude
    }
    areas(order_by: { id: asc }) {
      id
      area_type_id
      area_system_id
      is_rented
      class_of_use_id
      data_source_id
      value
      type_of_use_id
      parent_id
      description
      name
    }
    responsible_user {
      first_name
      last_name
    }
    sub_buildings {
      ...DataCollectionBuildingSubBuilding
    }
    economic_unit {
      id
      name
      associatedBuildingsAndDrafts {
        amount
      }
      portfolio {
        id
        name
        emission_factor_type_id
        __typename
      }
      __typename
    }
    active_building_model {
      ...DataCollectionBuildingModel
    }
    climate_risks {
      value
      uncertainty
      type
      timespan
      created_at
    }
    climate_risks_aggregate {
      aggregate {
        max {
          created_at
        }
      }
    }
  }
`);

graphql(`
  fragment DataCollectionYearlyConsumption on YearlyConsumption {
    value
    providers
    cost
    consumption_type
  }
`);

graphql(`
  fragment DataCollectionConsumptionConsumption on consumption {
    id
    from
    to
    value
    display_unit_value
    cost
    consumption_type_id
    energy_source_type_id
    sub_type_id
    provider
    area_id

    consumption_waste_detail {
      id
      frequency
      display_unit_frequency
      volume
      display_unit_volume
      amount
      price
    }
  }
`);

graphql(`
  fragment DataCollectionConsumptionDraftConsumptionDraft on consumption_draft {
    id
    from
    to
    value
    cost
    consumption_type_id
    display_unit_value
    energy_source_type_id
    sub_type_id
    provider
    area_id

    # TODO: Migrate to building
    sub_building_id

    consumption_waste_details_draft {
      id
      frequency
      display_unit_frequency
      volume
      display_unit_volume
      amount
      price
    }
  }
`);

graphql(`
  fragment DataCollectionConsumptionDraftConsumptionDraftSubBuildingAreas on sub_building {
    id
    building {
      id
      address {
        street
        postal_code
        city
      }
      economic_unit {
        id
        portfolio {
          id
          name
        }
      }
      areas(where: { area_system_id: { _eq: GIF2017_DE }, area_type_id: { _nin: [MFG_0, BGF] } }) {
        id
        area_type_id
        area_system_id
        name
        description
        value
      }
    }
  }
`);

graphql(`
  fragment DataCollectionEnergyCertificateDraftEnergyCertificateDraft on energy_certificate_draft {
    id
    street
    postal_code
    city
    country_state_id
    country_id
    building_state_id
    portfolio_id
    economic_unit_id
    responsible_user_id
    year_constructed
    area_usable
    sub_building_class_id
    type_of_use_id
    draft_status_id
    energy_certificate_type_id
    expiry_date
    units
    monument_protection
    leasehold
    milieu_protection
    heritage_district
    energy_certificate_consumers {
      energy_system_type_id
      energy_source_type_id
      energy_final
    }
  }
`);

graphql(`
  fragment DataCollectionEnergyCertificateDraftUser on user {
    id
    first_name
    last_name
  }
`);

graphql(`
  fragment SubBuildingDocumentsFile on file {
    __typename
    id
    name
    created_at
    downloadUrl
  }
`);

graphql(`
  fragment DataCollectionEnergyCertificateDraftsListEnergyCertificateDraft on energy_certificate_draft {
    id
    user {
      id
      first_name
      last_name
    }
    created_at
    updated_at
    street
    city
    draft_status_id
    file {
      name
      downloadUrl
    }
    portfolio {
      name
    }
  }
`);

graphql(`
  fragment DataCollectionEnergyCertificateDraftsListEnergyCertificateDraftSubscription on energy_certificate_draft {
    id
    user {
      id
      first_name
      last_name
    }
    updated_at
    created_at
    street
    city
    draft_status_id
    portfolio {
      name
    }
  }
`);

graphql(`
  fragment DataCollectionSubBuilding on sub_building {
    __typename
    id
    building {
      year_constructed
      building_state_id
      economic_unit {
        portfolio {
          id
          name
        }
      }
    }
  }
`);

graphql(`
  fragment DataCollectionEconomicUnit on economic_unit {
    __typename
    id
    name
    customer_economic_unit_id
  }
`);

graphql(`
  fragment DataCollectionEconomicUnitPortfolios on economic_unit {
    __typename
    id
    name
    customer_economic_unit_id
    portfolio {
      id
      name
      __typename
    }
    associatedBuildingsAndDrafts {
      amount
    }
  }
`);

graphql(`
  fragment DataCollectionBuildingsListBuilding on building {
    __typename
    year_constructed
    id
    building_state_id
    address {
      id
      street
      city
      postal_code
      __typename
    }
    areas(where: { area_type_id: { _eq: NUF } }) {
      type_of_use_id
      value
      area_type_id
    }
    sub_buildings {
      ...DataCollectionSubBuilding
    }
    economic_unit {
      id
      portfolio {
        id
        name
      }
      associatedBuildingsAndDrafts {
        amount
      }
      __typename
    }
    created_at
    updated_at
    active_building_model {
      action_planning_available
      processing
    }
  }
`);

graphql(`
  fragment DataCollectionGetEconomicUnitWithBuildings on economic_unit {
    __typename
    id
    name
    associatedBuildingsAndDrafts {
      amount
    }
    created_at
    updated_at
    customer_economic_unit_id
    buildings {
      ...DataCollectionBuildingsListBuilding
    }
    portfolio {
      __typename
      id
      name
    }
  }
`);

graphql(`
  fragment BuildingModelMutationAvailability on GetBuildingModelMutationAvailabilityOutput {
    action_deletion_necessary
    affected_action_plan_count
    affected_users {
      id
      name
    }
  }
`);

graphql(`
  fragment DataCollectionEmissionCertificateDraft on emission_certificate_draft {
    __typename
    created_at
    issuer
    emission_factor
    primary_energy_factor
    file_id
    file {
      ...DataCollectionDocumentDraft
    }
    org {
      name
    }
    org_id
    id
    valid_from
    valid_until
  }
`);

graphql(`
  fragment DataCollectionEmissionCertificate on emission_certificate {
    __typename
    created_at
    issuer
    emission_factor
    primary_energy_factor
    file_id
    file {
      ...DataCollectionDocumentDraft
    }
    org {
      name
    }
    org_id
    id
    valid_from
    valid_until
    energy_system_consumer_routes {
      energy_system {
        building_model {
          building_id
        }
      }
    }
    primary_energy_factor_energy_system_consumer_routes {
      energy_system {
        building_model {
          building_id
        }
      }
    }
  }
`);

graphql(`
  fragment DataCollectionDocumentDraft on file {
    downloadUrl
    id
    name
    created_at
  }
`);

//Economic unit ------------------------------------

export const GET_ECONOMIC_UNITS_LIST = graphql(`
  query DataCollectionGetEconomicUnitsList {
    economic_unit {
      ...DataCollectionEconomicUnit
    }
  }
`);

export const GET_ECONOMIC_UNITS_WITH_PORTFOLIOS = graphql(`
  query DataCollectionGetEconomicUnitsWithPortfolios {
    economic_unit(order_by: { name: asc }) {
      ...DataCollectionEconomicUnitPortfolios
    }
  }
`);

export const GET_ECONOMIC_UNITS_WITH_BUILDINGS = graphql(`
  query DataCollectionGetEconomicUnitsListWithBuildings {
    economic_unit {
      ...DataCollectionGetEconomicUnitWithBuildings
    }
  }
`);

// Building ------------------------------------

export const GET_BUILDING = graphql(`
  query DataCollectionBuildingGetBuilding($buildingId: Int!, $year: Int!) {
    building_by_pk(id: $buildingId) {
      ...DataCollectionBuildingWithSubBuildings
    }
  }
`);

export const GET_ALL_BUILDINGS_WITH_SUB_BUILDINGS = graphql(`
  query DataCollectionGetAllBuildings {
    building {
      ...DataCollectionBuildingsListBuilding
    }
  }
`);

export const GET_BUILDINGS_ECONOMIC_UNIT_IDS = graphql(`
  query GET_ECONOMIC_UNIT_IDS($buildingIds: [Int!]!) {
    economic_unit(where: { buildings: { id: { _in: $buildingIds } } }) {
      id
    }
  }
`);

export const GET_ALL_BUILDINGS = graphql(`
  query DataCollectionGetAllBuildingsTable(
    $limit: Int!
    $offset: Int!
    $where: building_bool_exp
    $orderBy: [building_order_by!]
  ) {
    building(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...DataCollectionBuildingsListBuilding
    }
    building_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`);

export const GET_ALL_EMISSION_CERTIFICATES_DROPDOWN = graphql(`
  query DataCollectionGetAllEmissionCertificatesDropdown {
    emission_factor_emission_certificates: emission_certificate(where: { emission_factor: { _is_null: false } }) {
      id
      issuer
      emission_factor
    }
    primary_energy_factor_emission_certificates: emission_certificate(
      where: { primary_energy_factor: { _is_null: false } }
    ) {
      id
      issuer
      primary_energy_factor
    }
  }
`);

//Sub building ------------------------------------

export const GET_REPORTS_AND_ACTIONS_BY_BUILDINGS_ID = graphql(`
  query ReportAndActionsSubBuildings($buildingIds: [Int!]!) {
    report_sub_building(where: { sub_building: { building_id: { _in: $buildingIds } } }) {
      report {
        id
        progress
        report_sub_buildings {
          sub_building_id
        }
        report_template {
          title
        }
      }
    }
    action_plan(where: { building_id: { _in: $buildingIds } }) {
      id
      actions_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`);

export const GET_CONSUMPTION_SUMMARIES = graphql(`
  query DataCollectionGetConsumptionSummaries($buildingId: Int!, $year: Int!) {
    getYearlyConsumptionSummary(building_id: $buildingId, year: $year) {
      consumptionCategories {
        ...DataCollectionYearlyConsumption
      }
    }
  }
`);

//Sub building draft ------------------------------------

export const GET_ENERGY_CERTIFICATE_DRAFT = graphql(`
  query DataCollectionEnergyCertificateDraftGetEnergyCertificateDraft($energyCertificateDraftId: Int!) {
    energy_certificate_draft_by_pk(id: $energyCertificateDraftId) {
      ...DataCollectionEnergyCertificateDraftEnergyCertificateDraft
      file {
        downloadUrl
      }
      portfolio_id
    }
    economic_unit {
      ...DataCollectionGetEconomicUnitWithBuildings
    }
    user {
      ...DataCollectionEnergyCertificateDraftUser
    }
  }
`);

export const GET_ALL_ENERGY_CERTIFICATE_DRAFTS_SUBSCRIPTION = graphql(`
  subscription DataCollectionEnergyCertificateDraftsListGetAllEnergyCertificateDraftsSubscription {
    energy_certificate_draft(order_by: { updated_at: desc }) {
      ...DataCollectionEnergyCertificateDraftsListEnergyCertificateDraftSubscription
    }
  }
`);

export const GET_ALL_ENERGY_CERTIFICATE_DRAFTS = graphql(`
  query DataCollectionEnergyCertificateDraftsListGetAllEnergyCertificateDrafts {
    energy_certificate_draft(order_by: { updated_at: desc }) {
      ...DataCollectionEnergyCertificateDraftsListEnergyCertificateDraft
    }
    portfolio(order_by: { name: asc }) {
      id
      name
    }
  }
`);

//TODO move subscriptions to its own file
export const ENERGY_CERTIFICATE_DRAFT_STATUS_SUBSCRIPTION = graphql(`
  subscription EnergyCertificateDraftStatus($energyCertificateDraftId: Int!) {
    energy_certificate_draft_by_pk(id: $energyCertificateDraftId) {
      draft_status_id
      updated_at
    }
  }
`);

//Consumption  ------------------------------------

export const GET_CONSUMPTION_INVOICE = graphql(`
  query DataCollectionGetConsumptionInvoice($consumptionInvoiceId: Int!) {
    consumption_invoice_by_pk(id: $consumptionInvoiceId) {
      id
      file {
        downloadUrl
        name
      }
      date
      invoice_payer
      building {
        id
        address {
          id
          street
          city
          postal_code
        }
        areas {
          id
          name
          description
          area_type_id
        }
      }

      consumptions {
        ...DataCollectionConsumptionConsumption
      }
    }
  }
`);

export const GET_CONSUMPTIONS_INVOICES = graphql(`
  query DataCollectionSubBuildingConsumptionGetConsumptions($buildingId: Int!) {
    consumption_invoice(where: { building_id: { _eq: $buildingId } }) {
      __typename
      id
    }
  }
`);

//Consumption draft ------------------------------------

export const GET_BUILDING_CONSUMPTION_PRECONDITION = graphql(`
  query DataCollectionGetBuildingConsumptionPrecondition($orgId: Int!) {
    building(where: { economic_unit: { portfolio: { org: { id: { _eq: $orgId } } } } }) {
      id
      address {
        id
        city
        street
        postal_code
      }
      # TODO: Deprecate when moving to building
      sub_buildings {
        id
      }
      building_models {
        id
        energy_systems {
          id
          energy_system_type_id
        }
      }
      areas(where: { area_type_id: { _in: [MFG_1, MFG, MFG_2] } }) {
        id
        name
        description
        area_type_id
      }
      economic_unit {
        id
        portfolio {
          id
        }
      }
    }
  }
`);

export const GET_CONSUMPTION_DRAFTS = graphql(`
  query DataCollectionGetConsumptionDrafts {
    consumption_draft {
      ...DataCollectionConsumptionDraftConsumptionDraft
    }
  }
`);

export const GET_CONSUMPTION_INVOICE_DRAFT = graphql(`
  query DataCollectionGetConsumptionInvoiceDraft($consumptionInvoiceDraftId: Int!) {
    consumption_invoice_draft_by_pk(id: $consumptionInvoiceDraftId) {
      id
      file {
        downloadUrl
        name
      }
      date
      draft_status
      invoice_payer
      building {
        id
        address {
          id
          street
          city
          postal_code
        }
        areas {
          id
          name
          description
          area_type_id
        }
      }

      consumption_drafts {
        ...DataCollectionConsumptionDraftConsumptionDraft
      }
    }
  }
`);

// TODO PRE-789: change query to properly fetching with building_id
export const GET_CONSUMPTION_INVOICE_DRAFTS = graphql(`
  query DataCollectionSubBuildingGetConsumptionDrafts($buildingId: Int!) {
    consumption_invoice_draft(where: { building_id: { _eq: $buildingId } }) {
      id
    }
  }
`);

//Energy certificate -----------------------------------

/**
 * Gets a sub_buildings energy certificate if it has no file attached to it.
 */
export const GET_MISSING_ENERGY_CERTIFICATE_FILES = graphql(`
  query getMissingEnergyCertificateFiles($subBuildingId: Int!) {
    sub_building(
      where: { _and: { id: { _eq: $subBuildingId }, energy_certificates: { file_id: { _is_null: true } } } }
    ) {
      __typename
      id
      energy_certificates {
        __typename
        id
        file {
          __typename
          id
        }
      }
    }
  }
`);

/**
 * Gets an sub buildings energy certificate files and consumption files if they are already successfully uploaded.
 */
export const GET_SUB_BUILDING_FILES = graphql(`
  query getAllEnergyCertificatesForSubBuilding($id: Int!) {
    sub_building_by_pk(id: $id) {
      __typename
      id
      energy_certificates {
        __typename
        id
        file {
          ...SubBuildingDocumentsFile
        }
      }
      consumptions {
        id
        consumption_invoice {
          id
          file {
            ...SubBuildingDocumentsFile
          }
        }
      }
    }
  }
`);

/**
 * Gets all energy certificates for a sub building + type of use and area
 */

export const GET_ALL_ENERGY_CERTIFICATES_WITH_AREA = graphql(`
  query getAllEnergyCertificatesForSubBuildingWithArea($id: Int!) {
    sub_building_by_pk(id: $id) {
      __typename
      id
      type_of_use_id
      energy_certificates {
        __typename
        id
        area_usable
        file {
          ...SubBuildingDocumentsFile
        }
      }
    }
  }
`);

/**
 * Pre-checks if we need to show actions deletion banner or not
 */
export const GET_BUILDING_MODEL_ACTIONS_STATUS = graphql(`
  query getBuildingModelMutationAvailability($buildingModelId: Int!) {
    getBuildingModelMutationAvailability(building_model_id: $buildingModelId) {
      ...BuildingModelMutationAvailability
    }
  }
`);

/**
 * Fetches all the emission certificates
 */
export const GET_EMISSION_CERTIFICATES = graphql(`
  query getEmissionCertificates {
    emission_certificate(order_by: { created_at: asc }) {
      ...DataCollectionEmissionCertificate
    }
  }
`);

/**
 * Fetches a specific emission certificate by id
 */
export const GET_EMISSION_CERTIFICATE = graphql(`
  query getEmissionCertificate($emissionCertificateId: Int!) {
    emission_certificate_by_pk(id: $emissionCertificateId) {
      ...DataCollectionEmissionCertificate
    }
  }
`);

/**
 * Fetches all the emission certificate drafts
 */
export const GET_EMISSION_CERTIFICATE_DRAFTS = graphql(`
  query getEmissionCertificateDrafts {
    emission_certificate_draft(order_by: { created_at: asc }) {
      ...DataCollectionEmissionCertificateDraft
    }
  }
`);

/**
 * Fetches an emission certificate draft by id
 */
export const GET_EMISSION_CERTIFICATE_DRAFT = graphql(`
  query getEmissionCertificateDraft($emissionCertificateDraftId: Int!) {
    emission_certificate_draft_by_pk(id: $emissionCertificateDraftId) {
      ...DataCollectionEmissionCertificateDraft
    }
  }
`);

/**
 * Fetches an emission certificate draft by file_id
 */
export const GET_EMISSION_CERTIFICATE_DRAFT_BY_FILE = graphql(`
  query getEmissionCertificateDraftByFile($emissionCertificateDraftFileId: Int!) {
    emission_certificate_draft(where: { file_id: { _eq: $emissionCertificateDraftFileId } }) {
      id
    }
  }
`);

/**
 *  Fetches all associated action plans and reports with the building
 */
export const GET_ASSOCIATED_ACTION_PLANS_AND_REPORTS_FROM_BUILDING = graphql(`
  query getAssociatedActionPlansAndReportsFromEmissionCertificate($emissionCertificateId: Int!) {
    emission_factor_buildings: building(
      where: {
        building_models: {
          energy_systems: {
            energy_system_consumer_routes: { emission_certificate_id: { _eq: $emissionCertificateId } }
          }
        }
      }
    ) {
      id
      economic_unit {
        portfolio {
          emission_factor_type_id
        }
      }
      action_plans_aggregate {
        aggregate {
          count
        }
      }
      sub_buildings {
        report_sub_buildings {
          report_id
        }
      }
    }
    primary_energy_factor_buildings: building(
      where: {
        building_models: {
          energy_systems: {
            energy_system_consumer_routes: {
              primary_energy_factor_emission_certificate_id: { _eq: $emissionCertificateId }
            }
          }
        }
      }
    ) {
      id
      economic_unit {
        portfolio {
          emission_factor_type_id
        }
      }
      action_plans_aggregate {
        aggregate {
          count
        }
      }
      sub_buildings {
        report_sub_buildings {
          report_id
        }
      }
    }
  }
`);

export const GET_ENERGY_CERTIFICATE_DRAFT_SUBSCRIPTION_FILES = graphql(`
  query ALLEnergyCertificateFiles($ids: [Int!]) {
    energy_certificate_draft(where: { id: { _in: $ids } }) {
      id
      file {
        id
        downloadUrl
        name
      }
      portfolio_id
    }
  }
`);

export const BUILDING_PROCESSING_SUBSCRIPTION = graphql(`
  subscription BuildingProcessingSubscription($buildingModelId: Int!) {
    building_model_by_pk(id: $buildingModelId) {
      processing
    }
  }
`);

export const CONSUMPTION_DRAFT_COUNT = graphql(`
  query SubBuildingConsumptionTabsConsumptionDraftCount($subBuildingId: Int!) {
    consumption_draft_aggregate(where: { sub_building_id: { _eq: $subBuildingId } }) {
      aggregate {
        count
      }
    }
  }
`);

export const IS_LAST_EU_FROM_BUILDINGS = graphql(`
  query hasSingleBuildingOrDraft($ids: [Int!]!) {
    hasSingleBuildingOrDraft(ids: $ids)
  }
`);
