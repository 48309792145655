import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mui/material';
import {
  area_system_type_enum,
  area_type_enum,
  data_source_type_enum,
  sub_building_class_enum,
  type_of_use_enum,
} from '@predium/enums';
import { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import NavigationModal from '../../../../components/NavigationModal/NavigationModal';
import { FormProvider } from '../../../../components/hook-form';
import AccessRightsWrapper from '../../../../components/permission-tooltips/AccessRightsWrapper';
import { FormRef, SetFormState } from '../../../../pages/DataCollection/DataCollectionBuilding';
import { InconsistentDataAlert } from '../Common/BuildingAlerts';
import useBuilding from '../Context/useBuilding';
import BGFArea from './BGFArea';
import RecalculateProvider from './RecalculationProvider';
import SaveChangesModal from './SaveChangesModal';
import DINSystem from './Systems/DINSystem';
import GIFSystem from './Systems/GIFSystem';
import ValidationAlert from './Validation/ValidationAlert';
import { ValidationProvider } from './Validation/ValidationProvider';
import { Area, AreaFormValues } from './types';

type Props = {
  initialAreas: Area[];
  setFormState: SetFormState;
  isSaveModalOpen: boolean;
  setIsSaveModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isActionPlanningAvailable: boolean;
};

const AreaSchema = yup.object({
  areas: yup.array().of(
    yup.object({
      id: yup.number().optional(),
      value: yup.number().required(),
      create: yup.boolean().when('id', {
        is: (id: number) => id !== undefined,
        then: (schema) => schema.oneOf([false]).optional(),
      }),
      delete: yup.boolean().when('id', {
        is: (id: number) => id === undefined,
        then: (schema) => schema.oneOf([false]).optional(),
      }),
      area_type_id: yup.mixed<area_type_enum>().oneOf(Object.values(area_type_enum)).required(),
      area_system_id: yup.mixed<area_system_type_enum>().oneOf(Object.values(area_system_type_enum)).required(),
      is_rented: yup.boolean().required(),
      class_of_use_id: yup.mixed<sub_building_class_enum>().oneOf(Object.values(sub_building_class_enum)).required(),
      type_of_use_id: yup.mixed<type_of_use_enum>().oneOf(Object.values(type_of_use_enum)).required(),
      data_source_id: yup.mixed<data_source_type_enum>().oneOf(Object.values(data_source_type_enum)).optional(),
      parent_id: yup.number().nullable(),
      name: yup.string(),
      description: yup.string(),
    }),
  ),
});

const SubBuildingAreas = forwardRef<FormRef<AreaFormValues>, Props>(
  ({ initialAreas, setFormState, isSaveModalOpen, setIsSaveModalOpen, isActionPlanningAvailable }, ref) => {
    const { t } = useTranslation();
    const { hasEditAccess } = useBuilding();

    const methods = useForm<AreaFormValues>({
      defaultValues: {
        areas: initialAreas,
      },
      resolver: yupResolver(AreaSchema),
    });

    const {
      reset,
      formState: { errors, dirtyFields, isDirty },
    } = methods;

    const editCount = dirtyFields.areas?.length ?? 0;
    const hasError = Object.keys(errors).length > 0;

    const handlePropagateFormState = useCallback(() => {
      // we don't show the errors in the tab bar,
      // we only disable the save button if there is an error
      setFormState({ isDirty, errorCount: hasError ? 1 : 0, editCount });
    }, [editCount, hasError, isDirty, setFormState]);

    useEffect(() => {
      reset({ areas: initialAreas });
      setFormState({ isDirty: false, errorCount: 0, editCount: 0 });
    }, [initialAreas, reset, setFormState]);

    useImperativeHandle(ref, () => ({ methods }));

    return (
      <FormProvider methods={methods}>
        <ValidationProvider methods={methods} handlePropagateFormState={handlePropagateFormState}>
          <RecalculateProvider>
            <AccessRightsWrapper hasAccess={hasEditAccess}>
              {!isActionPlanningAvailable && (
                <Box mb={3}>
                  <InconsistentDataAlert />
                </Box>
              )}
            </AccessRightsWrapper>
            <ValidationAlert />
            <Stack spacing={2} direction="row">
              <DINSystem />
              <GIFSystem />
            </Stack>
            <BGFArea />
            <NavigationModal
              title={t('General_LeavePageTitle')}
              message={t('General_CancelEditModalDescription')}
              primaryActionText={t('General_KeepEditing')}
              secondaryActionText={t('General_Continue')}
              primaryActionCallback={() => false}
              reverseActions
              singleBlock={false}
              shouldBlock={isDirty}
              primaryActionProps={{
                variant: 'outlined',
              }}
              secondaryActionProps={{
                variant: 'contained',
                startIcon: null,
              }}
            />
            <SaveChangesModal isSaveModalOpen={isSaveModalOpen} setIsSaveModalOpen={setIsSaveModalOpen} />
          </RecalculateProvider>
        </ValidationProvider>
      </FormProvider>
    );
  },
);

export default SubBuildingAreas;
