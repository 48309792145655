import { useTranslation } from 'react-i18next';
import PreToggleButton from '../../../components/presentations/PreToggleButton';

type Props = {
  onEmissionTypeToggle: (value: EmissionFactorToggleEnum) => void;
  defaultValue: EmissionFactorToggleEnum;
};

export enum EmissionFactorToggleEnum {
  CUSTOM = 'custom',
  DEFAULT = 'default',
}

const EmissionFactorToggle = ({ onEmissionTypeToggle, defaultValue }: Props) => {
  const { t } = useTranslation();

  return (
    <PreToggleButton
      leftValue={{ key: EmissionFactorToggleEnum.DEFAULT, value: t('General_Standard') }}
      rightValue={{ key: EmissionFactorToggleEnum.CUSTOM, value: t('General_Specific') }}
      size="small"
      onChange={(_, value) => onEmissionTypeToggle(value)}
      initialValueType={defaultValue}
      onToggle={onEmissionTypeToggle}
    />
  );
};

export default EmissionFactorToggle;
