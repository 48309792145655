import { language_enum } from '@predium/enums';
import {
  en as englishTranslation,
  de as germanTranslation,
  getLanguageLocale,
  initLibraryWithI18n,
} from '@predium/i18n/client';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// NAMING PATTERN FOR TRANSLATION KEYS:
// context_element-modifier
// This results in something like: "Actionplanning_CreateActionDialogTitle-variant2"
// The "-modifier" part is optional but required if there are multiple translations for the same context and element.

// WORKFLOW:
// 1. Add the translation key with the above naming convention directly to the React code (probably use the useTranslation hook)
// 2. Run the script "translation:sync". It will add this missing key to the JSON file and remove ununsed keys.
// 3. Translate the text for the new key in the JSON file. Make sure you didnt accidentally remove keys that are still used (Maybe the parser doensnt get them. You can exclude them from being removed in the script).

export const DEFAULT_LANGUAGE = language_enum.deDE;

const resources: Record<string, Record<'translation', any>> = {
  [getLanguageLocale(language_enum.deDE)]: {
    translation: germanTranslation,
  },
  [getLanguageLocale(language_enum.enGB)]: {
    translation: englishTranslation,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: getLanguageLocale(DEFAULT_LANGUAGE),
  lng: getLanguageLocale(DEFAULT_LANGUAGE), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
  // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
  // if you're using a language detector, do not define the lng option

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

initLibraryWithI18n(i18n);

export default i18n;
