import { useQuery } from '@apollo/client';
import { Container, PaperProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GET_PORTFOLIOS } from '../graphql/Portfolio.queries';
import { PATHS } from '../routes';
import PortfolioCreateDialog from '../sections/Org/Portfolio/PortfolioCreateDialog';
import HeaderBreadcrumbs from './HeaderBreadcrumbs';
import Iconify from './Iconify';
import Page from './Page';

// ----------------------------------------------------------------------

interface Props extends PaperProps {
  iconString?: string;
  title: string;
  subTitle?: string;
  onClick?: () => void;
}

export default function Unavailable({ sx, iconString = 'ant-design:stop-outlined', title, subTitle, onClick }: Props) {
  const theme = useTheme();
  const RootStyle = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    padding: theme.spacing(3),
    cursor: onClick ? 'pointer' : 'default',
  });

  const handleClick = () => {
    onClick?.();
  };

  return (
    <RootStyle sx={sx} onClick={handleClick}>
      {iconString && (
        <Iconify icon={iconString} width={50} height={50} sx={{ mb: 1 }} color={theme.palette.grey[500]} />
      )}
      <Typography variant="h4" color={theme.palette.grey[500]}>
        {title}
      </Typography>
      {subTitle && (
        <Typography textAlign="center" variant="body1" color={theme.palette.grey[500]}>
          {subTitle}
        </Typography>
      )}
    </RootStyle>
  );
}

type UnavailableForMissingPortfoliosProps = {
  title: string;
  heading: string;
};

export function UnavailablePageForMissingPortfolios({
  title,
  heading,
  children,
}: PropsWithChildren<UnavailableForMissingPortfoliosProps>) {
  const { data } = useQuery(GET_PORTFOLIOS);

  if (data?.portfolio.length === 0) {
    return (
      <Page title={title}>
        <Container maxWidth="lg" sx={{ mb: 4 }}>
          <HeaderBreadcrumbs heading={heading} links={[]} />
          <UnavailablePortfolioMessage />
        </Container>
      </Page>
    );
  } else {
    return <>{children}</>;
  }
}

export function UnavailablePortfolioMessage({ subTitle }: { subTitle?: string }) {
  const { t } = useTranslation();
  const [showCreatePortfolioDialog, setShowCreatePortfolioDialog] = useState<boolean>(false);
  const onCloseModal = () => {
    setShowCreatePortfolioDialog(false);
  };
  return (
    <>
      <Unavailable
        iconString="gridicons:create"
        title={t('UnavailablePortfolio_Title')}
        subTitle={subTitle ?? t('UnavailablePortfolio_SubTitle')}
        onClick={() => {
          setShowCreatePortfolioDialog(true);
        }}
      />
      <PortfolioCreateDialog open={showCreatePortfolioDialog} onClose={onCloseModal} />
    </>
  );
}

export function UnavailablePageForEconomicUnits({ subTitle }: { subTitle?: string }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Page title={subTitle ?? t('UnavailableForEconomicUnits_SubTitle')}>
      <Container maxWidth="lg" sx={{ mb: 4 }}>
        <Unavailable
          iconString="fluent:settings-32-regular"
          title={t('UnavailableForEconomicUnits_Title')}
          subTitle={subTitle ?? t('UnavailableForEconomicUnits_SubTitle')}
          onClick={() => navigate(PATHS.org.settings())}
        />
      </Container>
    </Page>
  );
}
