import { graphql } from '@predium/client-graphql';

export const DELETE_REPORT = graphql(`
  mutation ReportDeleteReport($id: Int!) {
    delete_report_by_pk(id: $id) {
      __typename
      id
    }
  }
`);

export const CREATE_REPORT = graphql(`
  mutation CreateReport(
    $sub_buildings: [Int!]!
    $due_date: DateTime!
    $end_date: DateTime!
    $report_template_id: Int!
    $responsible_user_id: Int
    $start_date: DateTime!
  ) {
    createReport(
      parameters: {
        sub_buildings: $sub_buildings
        due_date: $due_date
        end_date: $end_date
        report_template_id: $report_template_id
        start_date: $start_date
        responsible_user_id: $responsible_user_id
      }
    ) {
      report_id
    }
  }
`);

/**
 * This mutation sets the state of an answer to a root question of a single report and sub_building to UNANSWERED and its value to null.
 * Also deletes all chosen options of that answer and deletes all child answers of that root question.
 */
export const RESET_REPORT_ANSWER = graphql(`
  mutation ResetReportAnswer($reportId: Int!, $subBuildingId: Int, $rootQuestionId: Int!) {
    resetAnswers(sub_building_id: $subBuildingId, report_id: $reportId, root_question_id: $rootQuestionId) {
      id
    }
  }
`);

export const COMPLETE_REPORT = graphql(`
  mutation completeReport($id: Int!) {
    update_report_by_pk(pk_columns: { id: $id }, _set: { completed: true }) {
      id
      __typename
      completed
    }
  }
`);

export const ACCEPT_AUTOFILL_REPORT = graphql(`
  mutation AcceptAutofillReport($reportId: Int!) {
    acceptAutofillReport(report_id: $reportId) {
      report_question_ids {
        id
      }
    }
  }
`);

export const ANSWER_QUESTIONS = graphql(`
  mutation AnswerQuestions($answers: [AnswerParamsInput!]!, $report_id: Int!, $sub_building_id: Int) {
    answerQuestions(report_id: $report_id, answers: $answers, sub_building_id: $sub_building_id) {
      report_answer_ids {
        ReportAnswerId {
          __typename
          id
        }
      }
    }
  }
`);

export const CREATE_EVIDENCE_FROM_FILE = graphql(`
  mutation createEvidenceFromFile($reportId: Int!) {
    createEvidenceFromFile(report_id: $reportId) {
      CreateEvidenceOutput {
        __typename
        id
        file {
          id
        }
      }
      upload_url
    }
  }
`);

export const CREATE_EVIDENCE = graphql(`
  mutation createEvidence($reportId: Int!, $url: String!) {
    insert_report_evidence_one(object: { report_id: $reportId, url: $url }) {
      __typename
      id
      url
    }
  }
`);

export const DELETE_EVIDENCE = graphql(`
  mutation deleteEvidence($evidenceId: Int!) {
    delete_report_evidence_by_pk(id: $evidenceId) {
      __typename
      id
    }
  }
`);

export const EXPORT_REPORT_AS_PDF = graphql(`
  mutation ExportReportAsPdf($reportId: Int!) {
    exportReportAsPdf(report_id: $reportId) {
      body
    }
  }
`);

export const EXPORT_REPORT_AS_EXCEL = graphql(`
  mutation ExportReportAsExcel($reportId: Int!) {
    exportReportAsExcel(report_id: $reportId) {
      body
    }
  }
`);
