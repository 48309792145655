import { MenuItem, Select, SelectChangeEvent, Stack, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../components/Iconify';
import { AccessRole, getAvailableRolesByCurrentRole } from './utils';

type Props = {
  onRoleChange: (role: AccessRole) => void;
  type: 'portfolio' | 'building';
  value: AccessRole | 'mixed';
  allValues?: {
    role: AccessRole;
    portfolioRole: AccessRole;
  }[];
};

export default function AccessControlRoleSelector({ onRoleChange, type, value, allValues = [] }: Props) {
  const { t } = useTranslation();
  const roles =
    type === 'portfolio'
      ? [AccessRole.NO_ACCESS, AccessRole.CONTRIBUTOR, AccessRole.MANAGER]
      : getAvailableRolesByCurrentRole(allValues);

  const icon = {
    NO_ACCESS: 'material-symbols:close',
    CONTRIBUTOR: 'mdi:users',
    MANAGER: 'mdi:pencil',
  };

  const name = {
    NO_ACCESS: t('AccessControl_Roles_NoAccess'),
    CONTRIBUTOR: t('AccessControl_Roles_Contributor'),
    MANAGER: t('AccessControl_Roles_Manager'),
  };

  const description =
    type === 'portfolio'
      ? {
          NO_ACCESS: t('AccessControl_RoleDescription_NoAccess-portfolio'),
          CONTRIBUTOR: t('AccessControl_RoleDescription_Contributor-portfolio'),
          MANAGER: t('AccessControl_RoleDescription_Manager-portfolio'),
        }
      : {
          NO_ACCESS: t('AccessControl_RoleDescription_NoAccess-building'),
          CONTRIBUTOR: t('AccessControl_RoleDescription_Contributor-building'),
          MANAGER: t('AccessControl_RoleDescription_Manager-building'),
        };

  const onChange = (event: SelectChangeEvent<AccessRole | 'mixed'>) => {
    onRoleChange(event.target.value as AccessRole);
  };

  const disabled = roles.length === 1;

  return (
    <Tooltip title={disabled && t('OrgSettings_AccessRights_InferredTooltip')} arrow placement="top">
      <Select
        name="role_selector"
        onChange={onChange}
        value={value !== 'mixed' ? value : ''}
        displayEmpty
        disabled={disabled}
        sx={{
          width: '100%',
          maxWidth: 300,
          '& .MuiOutlinedInput-input': {
            py: 1,
            pl: 1.5,
          },
        }}
        renderValue={(role) => {
          if (value === 'mixed' || !role) {
            return (
              <Stack direction="row" gap={1} alignItems="center" sx={{ width: '100%' }}>
                <Iconify icon={'entypo:sound-mix'} width={16} height={16} />
                <Typography variant="button" sx={{ width: '100%' }}>
                  {t('General_Mixed')}
                </Typography>
              </Stack>
            );
          }
          return <RoleSelectorTitle icon={icon[role]} name={name[role]} />;
        }}
      >
        {roles.map((role) => (
          <MenuItem
            key={role}
            value={role}
            sx={{
              maxWidth: 400,
              whiteSpace: 'pre-wrap',
            }}
          >
            <Stack sx={{ width: '100%' }}>
              <RoleSelectorTitle icon={icon[role]} name={name[role]} checked={value === role} />
              <Typography variant="body2" color="text.secondary">
                {description[role]}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </Tooltip>
  );
}

type RoleSelectorTitleProps = {
  icon: string;
  name: string;
  checked?: boolean;
};

function RoleSelectorTitle({ icon, name, checked }: RoleSelectorTitleProps) {
  return (
    <Stack direction="row" gap={1} alignItems="center" sx={{ width: '100%' }}>
      <Iconify icon={icon} width={16} height={16} />
      <Typography variant="button" sx={{ width: '100%' }}>
        {name}
      </Typography>
      {checked && <Iconify icon="mdi:check" width={16} height={16} />}
    </Stack>
  );
}
