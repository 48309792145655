import { subsidy_type_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateSubsidyTypeEnum = (enumValue: subsidy_type_enum) => {
  switch (enumValue) {
    case subsidy_type_enum.BOND:
      return <Trans i18nKey="Enum_SubsidyTypeEnum-BOND" />;
    case subsidy_type_enum.GRANT:
      return <Trans i18nKey="Enum_SubsidyTypeEnum-GRANT" />;
    case subsidy_type_enum.LOAN:
      return <Trans i18nKey="Enum_SubsidyTypeEnum-LOAN" />;
  }
};

export const translateSubsidyTypeEnum_dynamic = (
  enumValue: subsidy_type_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case subsidy_type_enum.BOND:
      return t('Enum_SubsidyTypeEnum-BOND');
    case subsidy_type_enum.GRANT:
      return t('Enum_SubsidyTypeEnum-GRANT');
    case subsidy_type_enum.LOAN:
      return t('Enum_SubsidyTypeEnum-LOAN');
  }
};
