/* eslint-disable @typescript-eslint/ban-ts-comment */
import Grid from '@mui/material/Grid';
import { envelope_type_enum } from '@predium/enums';
import { useTranslation } from 'react-i18next';
import { DoorFormValuesProps } from '../../BuildingEnvelope';
import { getEnvelopeIcon } from '../Envelope.util';
import { EnvelopeUnitField } from './EnvelopeUnitField';
import { EnvelopeUnitWrapper, FieldSectionTitle } from './EnvelopeUnitWrapper';

export function EnvelopeDoorUnit({
  data,
  index,
  handleCopy,
  handleRemove,
  title,
}: {
  data: DoorFormValuesProps;
  index: number;
  handleCopy: (index: number) => void;
  handleRemove: (index: number) => void;
  title: string;
}) {
  const { t } = useTranslation();
  const fieldNamePrefix = `${envelope_type_enum.DOOR}[${index}]`;

  return (
    <EnvelopeUnitWrapper
      title={title}
      icon={getEnvelopeIcon(envelope_type_enum.DOOR)}
      data={data}
      index={index}
      handleCopy={handleCopy}
      handleRemove={handleRemove}
    >
      <>
        {/* general information */}
        <Grid item container md={4}>
          <Grid item md={12}>
            <FieldSectionTitle title={t('General_BasicData')} />
          </Grid>
          <Grid item xs={6}>
            <EnvelopeUnitField
              title={t('General_ComponentArea')}
              unit={'m²'}
              name={`${fieldNamePrefix}.area`}
              //@ts-ignore
              id={data.id}
              fieldType="number"
            ></EnvelopeUnitField>
          </Grid>
          <Grid item xs={6}>
            <EnvelopeUnitField
              title={t('General_Orientation')}
              name={`${fieldNamePrefix}.orientation`}
              //@ts-ignore
              id={data.id}
              fieldType="orientation"
            />
          </Grid>
          <Grid item xs={6}>
            <EnvelopeUnitField
              title={t('General_UValue')}
              unit="W/(m²K)"
              name={`${fieldNamePrefix}.u_value`}
              //@ts-ignore
              id={data.id}
              fieldType="number"
            />
          </Grid>
        </Grid>

        <Grid item md={4} sx={{ pl: 3 }}>
          <FieldSectionTitle title={t('General_BasicConstruction')} />
          <EnvelopeUnitField
            title={t('General_Type')}
            name={`${fieldNamePrefix}.insulation_material_name`}
            //@ts-ignore
            id={data.id}
            fieldType="text"
          />
        </Grid>
      </>
    </EnvelopeUnitWrapper>
  );
}
