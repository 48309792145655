/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Accordion, AccordionDetails, AccordionSummary, Typography, useTheme } from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';
import Iconify from '../../../components/Iconify';
import LinearProgressBar from '../../../components/data-visialization/LinearProgressBar';
import { ReportDC } from '../ReportingDataClasses/Report.dc';
import { ReportSectionDC, ReportTemplateDC } from '../ReportingDataClasses/ReportTemplate.dc';
import ReportSectionLabel from './ReportEntitySectionLabel';
import { doesSectionContainTargetSection } from './ReportRootSectionOrigin';

type Props = {
  section: ReportSectionDC;
  report: ReportDC;
  setActiveSection: (activeSection: ReportSectionDC) => void;
  activeSection: ReportSectionDC;
  isSelected: boolean;
  subBuildingId?: number;
  isEntitySection: boolean;
};

function ReportSection({
  section,
  report,
  setActiveSection,
  activeSection,
  isSelected,
  subBuildingId,
  isEntitySection,
}: Props) {
  return section.children.length > 0 ? (
    <SectionAccordionForSubsections
      isSelected={isSelected}
      section={section}
      activeSection={activeSection}
      reportSections={report.report_template.report_sections}
      // FIXME: Currently the progress data is only correct for entity questions: https://linear.app/predium/issue/PRE-1168/add-missing-progress-data-for-structural-asset-sections
      progress={
        isEntitySection
          ? //@ts-ignore
            report.report_template.linearSectionStructure.find((sectionMeta) => sectionMeta.id === section.id)
              .progressMeta[-1].progress
          : undefined
      }
    >
      {section.children.map((subSection, index) => {
        return (
          <ReportSection
            key={index}
            section={subSection}
            report={report}
            setActiveSection={setActiveSection}
            activeSection={activeSection}
            isSelected={isSelected}
            subBuildingId={subBuildingId}
            isEntitySection={isEntitySection}
          />
        );
      })}
    </SectionAccordionForSubsections>
  ) : (
    <ReportSectionLabel
      section={section}
      active={activeSection.id === section.id && isSelected}
      setActiveSection={setActiveSection}
      progressLabelData={
        //@ts-ignore
        report.report_template.linearSectionStructure.find((sectionMeta) => sectionMeta.id === section.id).progressMeta[
          //@ts-ignore
          isEntitySection ? -1 : subBuildingId
        ]
      }
    />
  );
}

function SectionAccordionForSubsections({
  isSelected,
  section,
  activeSection,
  reportSections,
  progress,
  children,
}: PropsWithChildren<{
  isSelected: boolean;
  section: ReportSectionDC;
  activeSection: ReportSectionDC;
  reportSections: ReportTemplateDC['report_sections'];
  progress?: number;
}>) {
  const theme = useTheme();
  const isSelectedSection = isSelected && doesSectionContainTargetSection(activeSection.id, section, reportSections);
  const [expanded, setExpanded] = useState(isSelectedSection);

  useEffect(() => {
    if (isSelectedSection) {
      setExpanded(isSelectedSection);
    }
  }, [isSelectedSection, setExpanded]);

  if (section.parent_section_id === null) return <>{children}</>;

  return (
    <Accordion
      expanded={expanded}
      onChange={(_, expanded) => {
        setExpanded(expanded);
      }}
      style={{ boxShadow: 'none' }}
      sx={{
        '&:before': {
          display: 'none',
        },
      }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        sx={{
          ml: 5,
          pl: 1,
          flexDirection: 'row-reverse',
          backgroundColor: isSelectedSection ? theme.palette.grey[500_12] : '',
          borderRadius: 1,
          '& .MuiAccordionSummary-content': {
            justifyContent: 'space-between',
          },
        }}
        expandIcon={
          <Iconify width={16} height={16} icon={'material-symbols:arrow-forward-ios-rounded'} {...{ rotate: 1 }} />
        }
      >
        <Typography sx={{ ml: 2 }}>{section.name}</Typography>

        {progress !== undefined && <LinearProgressBar sx={{ width: '70px' }} progress={progress} />}
      </AccordionSummary>
      <AccordionDetails sx={{ ml: 6, px: 0 }}>{children}</AccordionDetails>
    </Accordion>
  );
}

export default ReportSection;
