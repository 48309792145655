import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../../components/Iconify';
import InputStyle from '../../../../../components/InputStyle';
import { AVAILABLE_DATA_TYPES } from './EvidenceExplorer';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

// ----------------------------------------------------------------------

type Props = {
  selectedDataType: (typeof AVAILABLE_DATA_TYPES)[number];
  handleDataTypeChange: (value: (typeof AVAILABLE_DATA_TYPES)[number]) => void;
  filterName: string;
  handleFilterNameChange: (value: string) => void;
};

function EvidenceExplorerToolbar({
  filterName,
  handleFilterNameChange,
  handleDataTypeChange,
  selectedDataType,
}: Props) {
  const { t } = useTranslation();

  return (
    <RootStyle>
      <Stack direction="row">
        <InputStyle
          value={filterName}
          onChange={(event) => handleFilterNameChange(event.target.value)}
          placeholder={t('ReportEvidence_SearchEvidence-placeholder')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            ),
          }}
          sx={{ minWidth: 300, mr: 3 }}
        />

        <TextField
          select
          label={t('ReportEvidence_FilterBy')}
          value={selectedDataType}
          onChange={(event) => handleDataTypeChange(event.target.value as (typeof AVAILABLE_DATA_TYPES)[number])}
          sx={{
            minWidth: 250,
          }}
        >
          {AVAILABLE_DATA_TYPES.map((type) => (
            <MenuItem key={type} value={type} sx={{ typography: 'body2', mx: 1, my: 0.5, borderRadius: 0.75 }}>
              {type === 'file'
                ? t('General_PDF')
                : type === 'url'
                ? t('General_Link')
                : type === 'all_files'
                ? t('General_AllFiles')
                : type}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    </RootStyle>
  );
}

export default EvidenceExplorerToolbar;
