import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListSubheader, { ListSubheaderProps } from '@mui/material/ListSubheader';
import { styled } from '@mui/material/styles';
import { PropsWithChildren } from 'react';
import { NavSectionProps } from '../type';
import { NavListRoot } from './NavList';

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props: PropsWithChildren<ListSubheaderProps>) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

// NavSectionVertical is still Minimal v3, because an update comes with to many substantial changes for now.
// It can be evaluated if the v4 version is compatible or provides a better experience.
export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }: NavSectionProps) {
  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {group.subheader}
          </ListSubheaderStyle>

          {group.items.map((list) => (
            <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
          ))}
        </List>
      ))}
    </Box>
  );
}
