import { Badge, Stack, Typography, useTheme } from '@mui/material';

type Props = {
  label: string;
  count: number;
  isActive: boolean;
};

export default function TableTabCount({ label, count, isActive }: Props) {
  const theme = useTheme();

  const textColor = isActive ? theme.palette.grey[200] : theme.palette.text.secondary;
  const backgroundColor = isActive ? theme.palette.grey[900] : theme.palette.grey[500_24];

  return (
    <>
      <Stack direction="row" justifyContent="center" gap={2} alignItems="center" sx={{ px: 2 }}>
        <Typography variant="subtitle1" fontWeight={700}>
          {label}
        </Typography>
        {count > 0 && (
          <Badge
            badgeContent={count}
            sx={{
              '& .MuiBadge-badge': { color: textColor, backgroundColor, borderRadius: '6px', py: 1.5 },
            }}
          />
        )}
      </Stack>
    </>
  );
}
