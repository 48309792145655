/* eslint-disable @typescript-eslint/ban-ts-comment */
import { envelope_type_enum } from '@predium/client-graphql';
import { ActionProps } from '../ViewAction';
import DoorAction from './EnvelopeActions/DoorAction';
import NonWindowDoorAction from './EnvelopeActions/NonWindowDoorAction';
import WindowAction from './EnvelopeActions/WindowAction';

export default function EnvelopeAction({
  currentActionBuildingModel,
  action,
  previousActionBuildingModel,
  affectedParts,
}: ActionProps) {
  const envelopeData = action.renovations_envelope[0].new_envelope;

  //@ts-ignore
  const envelopeType = envelopeData.envelope_type_id;

  const props: ActionProps = {
    currentActionBuildingModel: currentActionBuildingModel,
    action: action,
    affectedParts: affectedParts,
    previousActionBuildingModel: previousActionBuildingModel,
  };

  switch (envelopeType) {
    case envelope_type_enum.WINDOW:
      return <WindowAction {...props} />;
    case envelope_type_enum.DOOR:
      return <DoorAction {...props} />;
    case envelope_type_enum.WALL:
    case envelope_type_enum.FLAT_ROOF:
    case envelope_type_enum.PITCHED_ROOF:
    case envelope_type_enum.FLOOR:
    case envelope_type_enum.BASEMENT_CEILING:
    case envelope_type_enum.TOP_FLOOR_CEILING:
      return <NonWindowDoorAction {...props} />;

    default:
      const exhaustiveCheck = envelopeType;
      throw new Error(`Unhandled energy_consumer_type_enum ${envelopeType}: ${exhaustiveCheck}`);
  }
}
