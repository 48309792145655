import { RHFTextField } from '../../../../../../../../components/hook-form';
import { technologyNumberFieldSx } from './TechnologyNumberField';

type Props = {
  name: string;
  label: string;
  defaultValue?: string;
};

const TechnologyTextField: React.FC<Props> = ({ name, label, defaultValue }) => (
  <RHFTextField
    name={name}
    label={label}
    variant="outlined"
    sx={technologyNumberFieldSx}
    defaultValue={defaultValue || '-'}
    disabled
  />
);

export default TechnologyTextField;
