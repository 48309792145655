import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { fPercent } from '../../../../../libs/utils/src/lib/utils';
import Iconify from '../Iconify';
import Label, { LabelColor } from '../Label';
import { classifyPercentage } from './WidgetSummary';

type Props = {
  percent: number;
  unit?: string;
  inverse?: boolean;
};

export default function WidgetSummaryLabel({ percent, unit = '%', inverse = false }: Props) {
  const { t } = useTranslation();

  if (percent === Infinity) {
    percent = 100;
  } else if (percent === -Infinity) {
    percent = -100;
  } else if (Number.isNaN(percent)) {
    percent = 0;
  }

  const percentageSignal = classifyPercentage(percent);

  if (percentageSignal === 'neutral') {
    return <Label sx={{ minWidth: '75px' }}>{t('ESGAnalysis_WidgetSummaryLabel')}</Label>;
  }

  let color: LabelColor, icon;

  if (inverse) {
    color = percentageSignal === 'positive' ? 'success' : 'error';
    icon = percentageSignal === 'positive' ? 'mdi:arrow-bottom' : 'mdi:arrow-top';
  } else {
    color = percentageSignal === 'positive' ? 'error' : 'success';
    icon = percentageSignal === 'positive' ? 'mdi:arrow-top' : 'mdi:arrow-bottom';
  }

  return (
    <Label sx={{ minWidth: '75px' }} color={color} startIcon={<Iconify width={16} height={16} icon={icon} />}>
      {unit === 'a' ? (
        `${Math.abs(Math.trunc(percent))}a`
      ) : unit === '%' ? (
        <Typography variant="caption" fontWeight={700}>
          {fPercent(percent, true, 'never')}
        </Typography>
      ) : (
        '-'
      )}
    </Label>
  );
}
