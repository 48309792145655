/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SubsectionProgressFragment, report_template_section_target_enum } from '@predium/client-graphql';
import { useEffect, useState } from 'react';
import Iconify from '../../../components/Iconify';
import { DelayedLoading } from '../../../components/Loading';
import Scrollbar from '../../../components/Scrollbar';
import LinearProgressBar from '../../../components/data-visialization/LinearProgressBar';
import { SUBSECTIONS_PROGRESS } from '../../../graphql/Report.queries';
import { ReportDC } from '../ReportingDataClasses/Report.dc';
import { ReportSectionDC } from '../ReportingDataClasses/ReportTemplate.dc';
import ReportSection from './ReportRootSection';
import { doesSectionContainTargetSection, getLastSection } from './ReportRootSectionOrigin';

/**
 * This recursive function finds the first child in the tree with a target that is not completed yet.
 * Method returns null if all children are completed.
 */
export const findFirstChildWithIncompleteProgress = (
  target: report_template_section_target_enum,
  report: ReportDC,
  sectionId: number,
  progressData: SubsectionProgressFragment[],
): ReportSectionDC | null => {
  const nextSection = report.report_template.getNextSectionId(sectionId);
  if (!nextSection) {
    return null;
  }
  const progress = progressData.find(
    ({ report_template_section }) => report_template_section.id === nextSection.id,
  )?.progress;

  //@ts-ignore
  if (progress < 1 && nextSection.target === target) {
    return nextSection;
  } else {
    return findFirstChildWithIncompleteProgress(target, report, nextSection.id, progressData);
  }
};

type Props = {
  entityRootSections: ReportSectionDC[];
  report: ReportDC;
  activeSection: ReportSectionDC | null;
  setActiveSection: (section: ReportSectionDC) => void;
  triggerSkipToNextIncompleteSection: boolean;
  skipToNextSectionCompleted: () => void;
};

function ReportSectionsEntity({
  entityRootSections,
  report,
  activeSection,
  setActiveSection,
  skipToNextSectionCompleted,
  triggerSkipToNextIncompleteSection,
}: Props) {
  // We want to initialize with the first section and asset/entity that is not completed yet.
  // We wait until the progress is fetched and then look up the assets of the unanswered section.
  // If everything has been answered we fallback to the first section and asset/entity.
  useQuery(SUBSECTIONS_PROGRESS, {
    variables: {
      reportId: report.id,
    },
    skip: Boolean(activeSection) && !triggerSkipToNextIncompleteSection,
    onCompleted: (data) => {
      let incompleteSectionWithQuestions: ReportSectionDC | undefined;

      // Handle if rootSection is the section with questions
      const firstSection = entityRootSections[0];
      if (!triggerSkipToNextIncompleteSection && firstSection.children.length === 0) {
        //@ts-ignore

        //@ts-ignore
        const progress = data.report_by_pk.report_sections_progress.find(
          ({ report_template_section }) => report_template_section.id === firstSection.id,
        ).progress;

        if (progress < 1) {
          incompleteSectionWithQuestions = firstSection;
        }
      }

      if (!incompleteSectionWithQuestions) {
        //@ts-ignore
        incompleteSectionWithQuestions = findFirstChildWithIncompleteProgress(
          report_template_section_target_enum.ORG,
          report,

          //@ts-ignore
          triggerSkipToNextIncompleteSection ? activeSection.id : firstSection.id,

          //@ts-ignore
          data.report_by_pk.report_sections_progress,
        );
      }
      skipToNextSectionCompleted();

      // If everything is completed just set the first section
      if (!incompleteSectionWithQuestions) {
        // If there is no incomplete section after the current just skip.
        // TODO: If there are requirements exchange it with the actual implementation
        if (triggerSkipToNextIncompleteSection) {
          return;
        }

        const firstSectionWithoutChildren = getLastSection(entityRootSections);

        setActiveSection(firstSectionWithoutChildren);
        return;
      }

      setActiveSection(incompleteSectionWithQuestions);
    },
  });

  if (activeSection === null) return <DelayedLoading />;

  return (
    // screen - card padding top - card padding bottom - tabs - some spacer padding
    <Scrollbar sx={{ maxHeight: 'calc(100vh - 16px - 4px - 48px - 16px)' }}>
      {entityRootSections.map((rootSection) => {
        const isSelectedEntitySection = doesSectionContainTargetSection(
          activeSection.id,
          rootSection,
          report.report_template.report_sections,
        );

        return (
          <AccordionWrapper
            key={rootSection.id}
            isSelectedEntitySection={isSelectedEntitySection}
            activeSection={activeSection}
            report={report}
            rootSection={rootSection}
            setActiveSection={setActiveSection}
          />
        );
      })}
    </Scrollbar>
  );
}

type AccordionWrapperProps = {
  isSelectedEntitySection: boolean;
  rootSection: ReportSectionDC;
  report: ReportDC;
  activeSection: ReportSectionDC | null;
  setActiveSection: (section: ReportSectionDC) => void;
};

function AccordionWrapper({
  isSelectedEntitySection,
  report,
  rootSection,
  activeSection,
  setActiveSection,
}: AccordionWrapperProps) {
  const theme = useTheme();

  const [expanded, setExpanded] = useState(isSelectedEntitySection);

  useEffect(() => {
    if (isSelectedEntitySection) {
      setExpanded(isSelectedEntitySection);
    }
  }, [isSelectedEntitySection, setExpanded]);

  return (
    <Accordion
      key={rootSection.id}
      expanded={expanded}
      onChange={(_, expanded) => {
        setExpanded(expanded);
      }}
      style={{
        boxShadow: 'none',
      }}
      sx={{
        ':before': {
          display: 'none',
        },
      }}
      title={rootSection.name}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        sx={{
          flexDirection: 'row-reverse',
          backgroundColor: isSelectedEntitySection ? theme.palette.grey[500_12] : '',
          borderRadius: 1,
          '& .MuiAccordionSummary-content': {
            justifyContent: 'space-between',
          },
        }}
        expandIcon={
          <Iconify width={16} height={16} icon={'material-symbols:arrow-forward-ios-rounded'} {...{ rotate: 1 }} />
        }
      >
        <Typography sx={{ ml: 2 }}>{rootSection.name}</Typography>

        <LinearProgressBar
          sx={{ width: '70px' }}
          progress={
            //@ts-ignore
            report.report_template.linearSectionStructure.find((sectionMeta) => sectionMeta.id === rootSection.id)
              .progressMeta[-1].progress
          }
        />
      </AccordionSummary>
      <AccordionDetails sx={{ px: 0 }}>
        <ReportSection
          section={rootSection}
          report={report}
          setActiveSection={(activeSection: ReportSectionDC) => {
            setActiveSection(activeSection);
          }}
          //@ts-ignore
          activeSection={activeSection}
          isSelected={isSelectedEntitySection}
          isEntitySection
        />
      </AccordionDetails>
    </Accordion>
  );
}

export default ReportSectionsEntity;
