/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Grid, ListItemIcon, MenuItem, useTheme } from '@mui/material';
import { EnvelopeUnitFragment, envelope_type_enum, renovation_type_enum } from '@predium/client-graphql';
import { translateRenovationTypeEnum } from '@predium/i18n/client';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormProvider, RHFDatePicker, RHFNumberField, RHFSelect } from '../../../../../../components/hook-form';
import InfoTable, { InfoTableData } from '../../../../../../components/presentations/InfoTable';
import { BuildingTabEnum } from '../../../../../../pages/DataCollection/DataCollectionBuilding';
import { getActionIcon } from '../../../../../../utils/icons';
import { createSolarInfoTable } from '../../../../Scenario/scenarios.util';
import { NumberInputSx, SelectSx, StyledListItemText } from '../../../Components/ActionCustomStyles';
import { ActionProps } from '../../ViewAction';

type SolarActionForm = {
  action: renovation_type_enum;
  technology_name?: string;
  implementation_to: string;
  total_module_power?: number; //nominal_power in db
  module_peak_power?: number;
  module_angle: number;
  solar_active_area?: number;
  roof_area_ratio: number;
  cost_per_m2?: number;
};

const SOLAR_PLANT_TYPE = renovation_type_enum.SOLAR_PLANT;

export default function SolarAction({ action, previousActionBuildingModel }: ActionProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const envelopes = previousActionBuildingModel.envelope_units;
  const buildingId = previousActionBuildingModel.building!.id;

  const maxRoofArea = Math.floor(
    envelopes
      .filter((envelope: EnvelopeUnitFragment) =>
        [envelope_type_enum.FLAT_ROOF, envelope_type_enum.PITCHED_ROOF, envelope_type_enum.TOP_FLOOR_CEILING].includes(
          envelope.envelope_type_id,
        ),
      )
      .reduce((acc: number, curr: EnvelopeUnitFragment) => acc + curr.area, 0),
  );

  const infoTableData: InfoTableData[] = createSolarInfoTable(maxRoofArea, previousActionBuildingModel, t);

  const actionData = action.renovations_energy_producer[0].new_energy_producer ?? {
    nominal_power: undefined,
    name: '',
    module_peak_power: undefined,
    module_angle: undefined,
    solar_active_area: undefined,
    roof_area_ratio: undefined,
    cost_per_m2: undefined,
  };
  const {
    nominal_power,
    name: technologyName,
    module_peak_power,
    module_angle,
    solar_active_area,
    cost_per_m2,
  } = actionData;

  const roof_area_ratio = actionData.roof_area_ratio ? actionData.roof_area_ratio * 100 : undefined;

  const defaultValues = {
    action: SOLAR_PLANT_TYPE,
    technology_name: technologyName ?? '',
    implementation_to: action.implementation_to,
    total_module_power: nominal_power ?? undefined,
    module_peak_power: module_peak_power ?? undefined,
    module_angle: module_angle ?? undefined,
    solar_active_area: solar_active_area ?? undefined,
    roof_area_ratio: roof_area_ratio ?? undefined,
    cost_per_m2: cost_per_m2 ?? undefined,
  };

  const formMethods = useForm<SolarActionForm>({
    defaultValues: defaultValues,
  });

  return (
    <FormProvider methods={formMethods}>
      <Box my={3}>
        <RHFSelect
          name="action"
          label={t('General_Category')}
          size={'small'}
          sx={{ ...SelectSx, mb: 3 }}
          disabled={true}
        >
          <MenuItem value={SOLAR_PLANT_TYPE}>
            <ListItemIcon style={{ minWidth: 24 }}>
              <Box component="img" src={getActionIcon(SOLAR_PLANT_TYPE)} />
            </ListItemIcon>
            <StyledListItemText action={translateRenovationTypeEnum(SOLAR_PLANT_TYPE)} />
          </MenuItem>
        </RHFSelect>

        {infoTableData && (
          <Box mb={3}>
            <InfoTable
              buildingId={buildingId}
              showTab={BuildingTabEnum.technology}
              data={infoTableData}
              title={t('General_StateBeforeRenovation')}
            />
          </Box>
        )}

        <Box
          sx={{
            p: 2,
            border: `1px solid ${theme.palette.grey[500_32]}`,
            borderRadius: '8px',
            mb: 3,
          }}
        >
          <RHFSelect
            name="technology_name"
            label={t('General_Technology')}
            size={'small'}
            sx={{ ...SelectSx, mb: 3 }}
            disabled
          >
            <MenuItem value={technologyName ?? ''}>
              <StyledListItemText action={technologyName ?? ''} secondary={`${cost_per_m2 ? '€' : ''}`} />
            </MenuItem>
          </RHFSelect>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <RHFNumberField
                name="total_module_power"
                label={t('ScenariosUtil_TotalModulPower') + ' [kWp]'}
                size={'small'}
                sx={NumberInputSx}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <RHFNumberField
                name="module_peak_power"
                label={t('CreateNewTechnicalAction_ModulePeakPower')}
                size={'small'}
                sx={NumberInputSx}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <RHFNumberField
                name="module_angle"
                label={t('CreateNewTechnicalAction_ModuleAngle')}
                size={'small'}
                sx={NumberInputSx}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <RHFNumberField
                name="solar_active_area"
                label={t('CreateNewTechnicalAction_SolarActiveArea')}
                size={'small'}
                sx={NumberInputSx}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <RHFNumberField
                name="roof_area_ratio"
                label={t('CreateNewTechnicalAction_RoofAreaRatio')}
                size={'small'}
                sx={NumberInputSx}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <RHFNumberField
                name="cost_per_m2"
                label={t('General_PricePerM2KwPeak')}
                size={'small'}
                sx={NumberInputSx}
                disabled
              />
            </Grid>
          </Grid>
        </Box>
        <RHFDatePicker
          label={t('General_PlannedFor')}
          name="implementation_to"
          size={'small'}
          sx={NumberInputSx}
          disabled
        />
      </Box>
    </FormProvider>
  );
}
