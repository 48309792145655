import { TARGET_PATH_LAST_YEAR, TARGET_PATH_YEARS_RANGE } from '@predium/client-lookup';
import { target_path_template_type_enum } from '@predium/enums';
import _ from 'lodash';

/**
 * Returns the 1.5° CO2 target path values for each year based on the target_path including target_path_template.
 */
export const getOnePointFiveTargetPath = (
  targetPaths: {
    value: number;
    year: number;
    target_path_template: {
      name: string;
      target_path_template_type_id: target_path_template_type_enum;
    };
  }[],
): { year: number; value: number }[] => {
  return targetPaths
    .filter(
      (targetPath) =>
        targetPath.target_path_template.name === 'onePointFive' &&
        targetPath.target_path_template.target_path_template_type_id === target_path_template_type_enum.CO2,
    )
    .sort((a, b) => a.year - b.year)
    .map(({ year, value }) => ({ year, value }));
};

/**
 * Returns the stranding year ranges of a building or multiple buildings.
 * TargetPaths or actualPaths could be for one building or an aggregation of multiple buildings
 * A stranding year happens when the value of the actual path is less than the target path
 * @param targetPaths
 * @param actualPaths
 * @param constructionYear construction year of a building, or earliest construction year in case of multiple building
 * @returns array of stranding ranges, the first object should be the earliest after the construction year
 * The ranges are inclusive (startYear and endYear are in the stranding period)
 */
export const getStrandingYearRanges = ({
  targetPath,
  path,
  constructionYear,
}: {
  targetPath: { year: number; value: number }[];
  path: { year: number; value: number }[];
  constructionYear: number;
}): { startYear: number; endYear: number }[] => {
  // -------- validation steps --------
  if (path.length !== TARGET_PATH_YEARS_RANGE.length || targetPath.length !== TARGET_PATH_YEARS_RANGE.length) {
    console.error('Target paths and actual paths are not of the same length');
    return [];
  }
  TARGET_PATH_YEARS_RANGE.forEach((year, index) => {
    if (year !== path[index].year || year !== path[index].year) {
      console.error('Target paths and actual paths are not ordered or years are not matching ');
      return [];
    }
  });

  if (constructionYear > TARGET_PATH_YEARS_RANGE[TARGET_PATH_YEARS_RANGE.length - 1]) {
    // Construction year comes after the range, It is probably an invalid case
    console.error('Construction year comes after the target path years range');
    return [];
  }

  //--------  Generate stranding year ranges   --------
  // stranding years can only occur after the construction year, so we start iteration from construction year
  // or if it is before the target path years range, then we take the first year in the range
  let yearIndexIterator =
    constructionYear < TARGET_PATH_YEARS_RANGE[0] ? 0 : TARGET_PATH_YEARS_RANGE.indexOf(constructionYear);

  let currentStrandingStartYear = null;
  let currentStrandingEndYear = null;
  const strandingYearRanges = [];
  for (yearIndexIterator; yearIndexIterator < TARGET_PATH_YEARS_RANGE.length; yearIndexIterator += 1) {
    currentStrandingStartYear = TARGET_PATH_YEARS_RANGE[yearIndexIterator];

    while (
      yearIndexIterator < TARGET_PATH_YEARS_RANGE.length &&
      path[yearIndexIterator].value >= targetPath[yearIndexIterator].value
    ) {
      // A stranding year is found, so we keep iterating until we reach the end of the stranding period
      currentStrandingEndYear = TARGET_PATH_YEARS_RANGE[yearIndexIterator];
      yearIndexIterator += 1;
    }

    // Either, there is a stranding range from the previous while loop, which will change `currentStrandingEndYear` from null to a value so will enter the condition
    // or no stranding years so far and nothing happen
    if (currentStrandingEndYear !== null) {
      strandingYearRanges.push({
        startYear: currentStrandingStartYear,
        endYear:
          currentStrandingEndYear === TARGET_PATH_LAST_YEAR ? currentStrandingEndYear : currentStrandingEndYear + 1,
      });
      // reset the stranding start year and stranding end year
      currentStrandingStartYear = null;
      currentStrandingEndYear = null;
    }
  }
  return strandingYearRanges;
};

export const getStrandingYearRangesFromValues = ({
  targetPath,
  path,
  constructionYear,
}: {
  targetPath: number[];
  path: number[];
  constructionYear: number;
}): { startYear: number; endYear: number }[] => {
  if (path.length !== TARGET_PATH_YEARS_RANGE.length || targetPath.length !== TARGET_PATH_YEARS_RANGE.length) {
    console.error('Target paths and actual paths are not of the same length');
    return [];
  }
  return getStrandingYearRanges({
    targetPath: TARGET_PATH_YEARS_RANGE.map((targetPathYear, index) => {
      return { value: targetPath[index], year: targetPathYear };
    }),
    path: TARGET_PATH_YEARS_RANGE.map((actualPathYear, index) => {
      return { value: path[index], year: actualPathYear };
    }),
    constructionYear,
  });
};

export const getFirstStrandingYear = ({
  targetPath,
  path,
  constructionYear,
}: {
  targetPath: number[];
  path: number[];
  constructionYear: number;
}): number => {
  if (path.length !== TARGET_PATH_YEARS_RANGE.length || targetPath.length !== TARGET_PATH_YEARS_RANGE.length) {
    console.error('Target paths and actual paths are not of the same length');
    return TARGET_PATH_LAST_YEAR;
  }
  const strandingDateRanges = getStrandingYearRanges({
    targetPath: TARGET_PATH_YEARS_RANGE.map((targetPathYear, index) => {
      return { value: targetPath[index], year: targetPathYear };
    }),
    path: TARGET_PATH_YEARS_RANGE.map((actualPathYear, index) => {
      return { value: path[index], year: actualPathYear };
    }),
    constructionYear,
  });
  if (strandingDateRanges.length == 0) {
    return TARGET_PATH_LAST_YEAR;
  } else {
    return strandingDateRanges[0].startYear;
  }
};

export const getFirstStrandingDate = ({
  targetPath,
  path,
  constructionYear,
}: {
  targetPath: number[];
  path: number[];
  constructionYear: number;
}): Date => {
  return new Date(
    getFirstStrandingYear({
      targetPath,
      path,
      constructionYear,
    }),
    0,
    1,
  );
};

/**
 * Computes the stranding date of a building after implementing an action. Note
 * that this is slightly different from calculating the stranding date of a
 * building. If the stranding date after implementing an action is the same as
 * the implementation date, this means that the action has no impact on the
 * stranding date, and thus the previous stranding date is returned.
 * */
export const getActionStrandingDate = ({
  implementationTo,
  targetPath,
  path,
  previousStrandingDate,
}: {
  implementationTo: Date;
  targetPath: number[];
  path: number[];
  previousStrandingDate: Date;
}): Date => {
  const strandingDate = getFirstStrandingDate({
    constructionYear: implementationTo.getFullYear(),
    targetPath,
    path,
  });

  if (strandingDate.getFullYear() === implementationTo.getFullYear()) {
    return previousStrandingDate;
  }
  return strandingDate;
};

/**
 * Computes the stranding date of a building after implementing all the actions. Note
 * that this is slightly different from calculating the stranding date of a
 * building. If the stranding date after implementing an action is the same as
 * the implementation date, this means that the action has no impact on the
 * stranding date, and thus the previous stranding date is returned. If the
 * total path is stranded at multiple points, the stranding date returned refers
 * to that after the last action.
 * */
export const getActionsStrandingDate = ({
  targetPath,
  building,
  actions,
}: {
  actions: { implementationTo: Date; path: number[] }[];
  building: { path: number[]; constructionYear: number };
  targetPath: number[];
}): Date => {
  const buildingStrandingDate = getFirstStrandingDate({
    constructionYear: building.constructionYear,
    targetPath,
    path: building.path,
  });

  return _.reduce(
    actions,
    (previousStrandingDate, { implementationTo, path }) =>
      getActionStrandingDate({
        implementationTo,
        targetPath,
        path,
        previousStrandingDate,
      }),
    buildingStrandingDate,
  );
};
