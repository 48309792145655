import { Box } from '@mui/material';
import { fShortenNumber } from '@predium/utils';
import merge from 'lodash/merge';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import BaseOptionChart from '../../../../theme/apexcharts';

type Props = {
  chartData: (number | undefined)[];
  colors: string[];
  unit: string;
  isSimulated: boolean;
};

export default function ActionBeforeAfterChart({ chartData, colors, unit, isSimulated }: Props) {
  const { t } = useTranslation();
  const [chartKey, setChartKey] = useState(0);

  const newChartData: number[] = [chartData[0] ?? 0, chartData[1] ?? 0];

  useEffect(() => {
    //PRE-4227 using key state to re-render the graph after simulation with updated values. it doesn't work with just the isSimulated value change
    setChartKey((prevKey) => prevKey + 1);
  }, [isSimulated]);

  const chartOptions = merge(BaseOptionChart(), {
    colors,
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
        barHeight: '20%',
        borderRadius: 2,
        position: 'top',
        fill: {
          colors: colors,
        },
      },
    },

    xaxis: {
      type: 'category',
      categories: [t('General_PreModificationShort'), t('General_PostModificationShort')],
      labels: {
        style: {
          fontSize: '12px',
        },
        formatter: (value: number) => (value === 0 ? '0' : value), // avoid showing 0.0
      },
      tickAmount: newChartData[1] === 0 && newChartData[0] === 0 ? 2 : 5,
    },

    yaxis: {
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
    legend: {
      show: false,
    },

    dataLabels: {
      enabled: true,
      formatter: function (val: number, opts: any) {
        const dataPointIndex = opts.dataPointIndex;
        return !isSimulated && dataPointIndex === 1 ? '' : `${fShortenNumber(val, 1)} ${unit}`;
      },
      offsetY: -15,
      offsetX: 15,
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },
  });

  return (
    <Box pr={1}>
      <ReactApexChart
        key={chartKey} // Use the chartKey to re-render after simulation
        type="bar"
        series={[{ data: newChartData }]}
        options={chartOptions}
        height={150}
      />
    </Box>
  );
}
