import { orientation_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateOrientationEnum = (enumValue: orientation_enum) => {
  switch (enumValue) {
    case orientation_enum.NORTH:
      return <Trans i18nKey="Enum_OrientationEnum-NORTH" />;
    case orientation_enum.NORTH_EAST:
      return <Trans i18nKey="Enum_OrientationEnum-NORTH_EAST" />;
    case orientation_enum.EAST:
      return <Trans i18nKey="Enum_OrientationEnum-EAST" />;
    case orientation_enum.SOUTH_EAST:
      return <Trans i18nKey="Enum_OrientationEnum-SOUTH_EAST" />;
    case orientation_enum.SOUTH:
      return <Trans i18nKey="Enum_OrientationEnum-SOUTH" />;
    case orientation_enum.SOUTH_WEST:
      return <Trans i18nKey="Enum_OrientationEnum-SOUTH_WEST" />;
    case orientation_enum.WEST:
      return <Trans i18nKey="Enum_OrientationEnum-WEST" />;
    case orientation_enum.NORTH_WEST:
      return <Trans i18nKey="Enum_OrientationEnum-NORTH_WEST" />;
    case orientation_enum.HORIZONTAL:
      return <Trans i18nKey="Enum_OrientationEnum-HORIZONTAL" />;
  }
};

export const translateOrientationEnum_dynamic = (
  enumValue: orientation_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case orientation_enum.NORTH:
      return t('Enum_OrientationEnum-NORTH');
    case orientation_enum.NORTH_EAST:
      return t('Enum_OrientationEnum-NORTH_EAST');
    case orientation_enum.EAST:
      return t('Enum_OrientationEnum-EAST');
    case orientation_enum.SOUTH_EAST:
      return t('Enum_OrientationEnum-SOUTH_EAST');
    case orientation_enum.SOUTH:
      return t('Enum_OrientationEnum-SOUTH');
    case orientation_enum.SOUTH_WEST:
      return t('Enum_OrientationEnum-SOUTH_WEST');
    case orientation_enum.WEST:
      return t('Enum_OrientationEnum-WEST');
    case orientation_enum.NORTH_WEST:
      return t('Enum_OrientationEnum-NORTH_WEST');
    case orientation_enum.HORIZONTAL:
      return t('Enum_OrientationEnum-HORIZONTAL');
  }
};
