import { TableCell } from '@mui/material';
import isNil from 'lodash/isNil';
import OverflowText from '../../OverflowText';
import { BaseColumn, IBaseColumn } from './BaseColumn';

export const textColumn = {
  ...BaseColumn,
  type: 'text',
  sortable: true,
  minWidth: 160,
  renderCell(data) {
    if (isNil(this.minWidth)) {
      throw new Error('minWidth is required');
    }

    return (
      <TableCell sx={{ minWidth: this.minWidth }}>
        <OverflowText maxWidth={this.minWidth} text={this.getValueLabel(this.getValue(data))} />
      </TableCell>
    );
  },
} satisfies IBaseColumn;
