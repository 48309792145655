/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AssociatedDataType } from '../components/DeleteConfirmationModal';
import { SnackbarTimeouts } from '../components/NotistackProvider';
import { GET_REPORTS_AND_ACTIONS_BY_BUILDINGS_ID } from '../graphql/DataCollection.queries';

interface Props {
  modalOpen: boolean;
  buildingIds: number[];
}

export default function useGetAssociatedDataForBuilding({ buildingIds, modalOpen }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const queueSnackBarMessage = t('DataCollection_AssociatedDataFetch-error');

  const { data: subBuildingData } = useQuery(GET_REPORTS_AND_ACTIONS_BY_BUILDINGS_ID, {
    variables: { buildingIds },
    skip: !modalOpen,
    onError: () =>
      enqueueSnackbar(queueSnackBarMessage, {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
  });

  const totalReportsAmount = useMemo(() => {
    const changedReportsAmount =
      subBuildingData?.report_sub_building?.filter((element) => element.report.report_sub_buildings.length > 1)
        .length ?? 0;
    const deletedReportsAmount =
      subBuildingData?.report_sub_building?.filter((element) => element.report.report_sub_buildings.length === 1)
        .length ?? 0;
    return changedReportsAmount + deletedReportsAmount;
  }, [subBuildingData]);

  const actionPlanAmount = useMemo(() => {
    return subBuildingData?.action_plan?.length ?? 0;
  }, [subBuildingData]);

  const actionAmount = useMemo(() => {
    return (
      //@ts-ignore
      subBuildingData?.action_plan?.reduce((prev, curr) => prev + curr.actions_aggregate?.aggregate?.count ?? 0, 0) ?? 0
    );
  }, [subBuildingData]);

  const associatedDataForDeletion = useMemo(() => {
    const map = new Map();
    if (totalReportsAmount > 0) {
      map.set(AssociatedDataType.REPORT, totalReportsAmount);
    }
    if (actionPlanAmount > 0) {
      map.set(AssociatedDataType.ACTION_PLAN, actionPlanAmount);
    }
    if (actionAmount > 0) {
      map.set(AssociatedDataType.ACTION, actionAmount);
    }
    return map;
  }, [totalReportsAmount, actionAmount, actionPlanAmount]);

  return { associatedDataForDeletion };
}
