import { ApolloQueryResult, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { PropsWithChildren, createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DataCollectionBuildingGetBuildingQuery } from '../../../../../../../libs/generated/client-graphql/src';
import LoadingScreen from '../../../../components/LoadingScreen';
import { createStandardErrorSnackbar } from '../../../../components/NotistackProvider';
import { PermissionType } from '../../../../contexts/PermissionContext';
import { GET_BUILDING } from '../../../../graphql/DataCollection.queries';
import usePermissions from '../../../../hooks/usePermissions';

type BuildingContextProps = {
  building: NonNullable<DataCollectionBuildingGetBuildingQuery['building_by_pk']>;
  hasEditAccess: boolean;
  reloadCurrentBuilding: () => Promise<ApolloQueryResult<DataCollectionBuildingGetBuildingQuery>>;
};

const BuildingContext = createContext<BuildingContextProps | null>(null);

type Props = PropsWithChildren<{}>;

function BuildingContextProvider({ children }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { id: buildingIdString } = useParams();
  const buildingId = parseInt(buildingIdString!);

  const { data, refetch: reloadCurrentBuilding } = useQuery(GET_BUILDING, {
    variables: { buildingId, year: new Date().getFullYear() },
    onError: () => createStandardErrorSnackbar(t)(enqueueSnackbar),
  });

  const { checkBuildingPermission } = usePermissions();

  const hasEditAccess =
    (data?.building_by_pk ?? false) && checkBuildingPermission(data!.building_by_pk!.id, PermissionType.WRITE);

  if (!data) return <LoadingScreen />;

  return (
    <BuildingContext.Provider
      value={{
        building: data.building_by_pk!,
        hasEditAccess,
        reloadCurrentBuilding,
      }}
    >
      {children}
    </BuildingContext.Provider>
  );
}

export { BuildingContext, BuildingContextProvider };
