import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Logo from '../../components/Logo';
import Page from '../../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

export default function Login() {
  const { t } = useTranslation();
  const { loginWithRedirect, logout, error, isAuthenticated } = useAuth0();

  if (error && !isAuthenticated) {
    logout({
      returnTo: window.location.origin,
    });
    return null;
  }

  const handleLoginAuth0 = async () => {
    try {
      const pathToRedirect = window.location.search
        ? window.location.pathname + window.location.search
        : window.location.pathname;
      await loginWithRedirect({
        appState: {
          returnTo: pathToRedirect,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RootStyle title={t('Login_Title')}>
      <SectionStyle>
        <Logo sx={{ width: '250px' }} />
      </SectionStyle>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                {t('Login_SubTitle')}
              </Typography>
            </Box>
          </Stack>
          <Button fullWidth size="large" type="submit" variant="contained" onClick={handleLoginAuth0}>
            {t('Login_LoginButton')}
          </Button>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
