import { energy_source_type_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateEnergySourceTypeEnum = (enumValue: energy_source_type_enum) => {
  switch (enumValue) {
    case energy_source_type_enum.BIO_GAS:
      return <Trans i18nKey="Enum_EnergySourceTypeEnum-BIO_GAS" />;
    case energy_source_type_enum.COAL:
      return <Trans i18nKey="Enum_EnergySourceTypeEnum-COAL" />;
    case energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL:
      return <Trans i18nKey="Enum_EnergySourceTypeEnum-DISTRICT_HEATING_CHP_FOSSIL_COAL" />;
    case energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS:
      return <Trans i18nKey="Enum_EnergySourceTypeEnum-DISTRICT_HEATING_CHP_FOSSIL_GAS" />;
    case energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE:
      return <Trans i18nKey="Enum_EnergySourceTypeEnum-DISTRICT_HEATING_CHP_RENEWABLE" />;
    case energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL:
      return <Trans i18nKey="Enum_EnergySourceTypeEnum-DISTRICT_HEATING_PLANTS_FOSSIL_COAL" />;
    case energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS:
      return <Trans i18nKey="Enum_EnergySourceTypeEnum-DISTRICT_HEATING_PLANTS_FOSSIL_GAS" />;
    case energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE:
      return <Trans i18nKey="Enum_EnergySourceTypeEnum-DISTRICT_HEATING_PLANTS_RENEWABLE" />;
    case energy_source_type_enum.ELECTRICITY_GREEN:
      return <Trans i18nKey="Enum_EnergySourceTypeEnum-ELECTRICITY_GREEN" />;
    case energy_source_type_enum.ELECTRICITY_MIX:
      return <Trans i18nKey="Enum_EnergySourceTypeEnum-ELECTRICITY_MIX" />;
    case energy_source_type_enum.FUEL_OIL:
      return <Trans i18nKey="Enum_EnergySourceTypeEnum-FUEL_OIL" />;
    case energy_source_type_enum.LIGNITE:
      return <Trans i18nKey="Enum_EnergySourceTypeEnum-LIGNITE" />;
    case energy_source_type_enum.LPG:
      return <Trans i18nKey="Enum_EnergySourceTypeEnum-LPG" />;
    case energy_source_type_enum.NATURAL_GAS:
      return <Trans i18nKey="Enum_EnergySourceTypeEnum-NATURAL_GAS" />;
    case energy_source_type_enum.SOLAR:
      return <Trans i18nKey="Enum_EnergySourceTypeEnum-SOLAR" />;
    case energy_source_type_enum.UNSPECIFIED:
      return <Trans i18nKey="Enum_EnergySourceTypeEnum-UNSPECIFIED" />;
    case energy_source_type_enum.WOOD:
      return <Trans i18nKey="Enum_EnergySourceTypeEnum-WOOD" />;
    case energy_source_type_enum.WOODEN_PELLETS:
      return <Trans i18nKey="Enum_EnergySourceTypeEnum-WOODEN_PELLETS" />;
  }
};

export const translateEnergySourceTypeEnum_dynamic = (
  enumValue: energy_source_type_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case energy_source_type_enum.BIO_GAS:
      return t('Enum_EnergySourceTypeEnum-BIO_GAS');
    case energy_source_type_enum.COAL:
      return t('Enum_EnergySourceTypeEnum-COAL');
    case energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL:
      return t('Enum_EnergySourceTypeEnum-DISTRICT_HEATING_CHP_FOSSIL_COAL');
    case energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS:
      return t('Enum_EnergySourceTypeEnum-DISTRICT_HEATING_CHP_FOSSIL_GAS');
    case energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE:
      return t('Enum_EnergySourceTypeEnum-DISTRICT_HEATING_CHP_RENEWABLE');
    case energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL:
      return t('Enum_EnergySourceTypeEnum-DISTRICT_HEATING_PLANTS_FOSSIL_COAL');
    case energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS:
      return t('Enum_EnergySourceTypeEnum-DISTRICT_HEATING_PLANTS_FOSSIL_GAS');
    case energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE:
      return t('Enum_EnergySourceTypeEnum-DISTRICT_HEATING_PLANTS_RENEWABLE');
    case energy_source_type_enum.ELECTRICITY_GREEN:
      return t('Enum_EnergySourceTypeEnum-ELECTRICITY_GREEN');
    case energy_source_type_enum.ELECTRICITY_MIX:
      return t('Enum_EnergySourceTypeEnum-ELECTRICITY_MIX');
    case energy_source_type_enum.FUEL_OIL:
      return t('Enum_EnergySourceTypeEnum-FUEL_OIL');
    case energy_source_type_enum.LIGNITE:
      return t('Enum_EnergySourceTypeEnum-LIGNITE');
    case energy_source_type_enum.LPG:
      return t('Enum_EnergySourceTypeEnum-LPG');
    case energy_source_type_enum.NATURAL_GAS:
      return t('Enum_EnergySourceTypeEnum-NATURAL_GAS');
    case energy_source_type_enum.SOLAR:
      return t('Enum_EnergySourceTypeEnum-SOLAR');
    case energy_source_type_enum.UNSPECIFIED:
      return t('Enum_EnergySourceTypeEnum-UNSPECIFIED');
    case energy_source_type_enum.WOOD:
      return t('Enum_EnergySourceTypeEnum-WOOD');
    case energy_source_type_enum.WOODEN_PELLETS:
      return t('Enum_EnergySourceTypeEnum-WOODEN_PELLETS');
  }
};
