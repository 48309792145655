import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Logo from '../../../components/Logo';
import LogoSmall from '../../../components/LogoSmall';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
import { NAVBAR } from '../../../config';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import useOrgPreferences from '../../../hooks/useOrgPreferences';
import useResponsive from '../../../hooks/useResponsive';
import cssStyles from '../../../utils/cssStyles';
import CollapseButton from './CollapseButton';
import getNavConfig from './NavConfig';
import NavbarOrg from './NavbarOrg';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');
  const { isCollapse, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } = useCollapseDrawer();
  const orgPerferences = useOrgPreferences();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <>
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        }}
      >
        <Stack direction="column" justifyContent="space-between" sx={{ height: '100%' }}>
          <Stack>
            <Stack
              spacing={3}
              sx={{
                py: 2,
                flexShrink: 0,
                pl: 4,
                pr: 2,
                height: 72,
              }}
            >
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                {!isCollapse ? <Logo sx={{ height: 28 }} /> : <LogoSmall sx={{ width: 21, pt: 1 }} />}

                {isDesktop && !isCollapse && (
                  <CollapseButton onToggleCollapse={onToggleCollapse} collapseHover={collapseHover} />
                )}
              </Stack>
            </Stack>

            <NavSectionVertical
              navConfig={getNavConfig(t, orgPerferences.economicUnitsToggled)}
              isCollapse={isCollapse}
            />
          </Stack>
          <NavbarOrg isCollapse={isCollapse} />
        </Stack>
      </Scrollbar>
    </>
  );

  return (
    <RootStyle
      sx={{
        position: 'absolute',
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
      }}
    >
      {!isDesktop && (
        <>
          <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
            {renderContent}
          </Drawer>
        </>
      )}

      {isDesktop && (
        <>
          <Drawer
            open
            variant="persistent"
            onMouseEnter={onHoverEnter}
            onMouseLeave={onHoverLeave}
            PaperProps={{
              sx: {
                width: NAVBAR.DASHBOARD_WIDTH,
                borderRightStyle: 'solid',
                bgcolor: 'background.default',
                transition: (theme) =>
                  theme.transitions.create(['width', 'box-shadow'], {
                    duration: theme.transitions.duration.standard,
                  }),
                ...(isCollapse && {
                  width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
                }),
                ...(collapseHover &&
                  !isCollapse && {
                    ...cssStyles(theme).bgBlur(),
                    boxShadow: (theme) => theme.customShadows.z24,
                  }),
                '&:hover .collapse-button': {
                  opacity: 0.7,
                },
              },
            }}
          >
            {renderContent}
          </Drawer>
        </>
      )}
    </RootStyle>
  );
}
