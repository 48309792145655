import { consumption_type_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateConsumptionTypeEnum = (enumValue: consumption_type_enum) => {
  switch (enumValue) {
    case consumption_type_enum.ELECTRICITY:
      return <Trans i18nKey="Enum_ConsumptionTypeEnum-ELECTRICITY" />;
    case consumption_type_enum.HEATING:
      return <Trans i18nKey="Enum_ConsumptionTypeEnum-HEATING" />;
    case consumption_type_enum.WASTE:
      return <Trans i18nKey="Enum_ConsumptionTypeEnum-WASTE" />;
    case consumption_type_enum.WATER:
      return <Trans i18nKey="Enum_ConsumptionTypeEnum-WATER" />;
  }
};

export const translateConsumptionTypeEnum_dynamic = (
  enumValue: consumption_type_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case consumption_type_enum.ELECTRICITY:
      return t('Enum_ConsumptionTypeEnum-ELECTRICITY');
    case consumption_type_enum.HEATING:
      return t('Enum_ConsumptionTypeEnum-HEATING');
    case consumption_type_enum.WASTE:
      return t('Enum_ConsumptionTypeEnum-WASTE');
    case consumption_type_enum.WATER:
      return t('Enum_ConsumptionTypeEnum-WATER');
  }
};
