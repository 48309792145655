import { Alert, AlertTitle, Button, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/icons';
import PreDialog, { PreDialogTitle } from '../../../../components/presentations/PreDialog/PreDialog';

type Props = {
  actionPlanCount: number;
  setDeleteActionPlanModalOpen: (open: boolean) => void;
  open: boolean;
  onDeleteConfirm: () => void;
  deleteMessage: string;
};

export function ActionPlanDeleteConfirmationDialog({
  open,
  setDeleteActionPlanModalOpen,
  actionPlanCount,
  onDeleteConfirm,
  deleteMessage,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <PreDialog
      type="definedByProperty"
      dialogtitle={
        <PreDialogTitle
          icon={ICONS.TRASH}
          title={t('DataCollection_Technology_ActionPlanDeleteDialogTitle', { count: actionPlanCount })}
        />
      }
      open={open}
      onClose={() => {
        setDeleteActionPlanModalOpen(false);
      }}
      content={
        <>
          <Typography sx={{ mb: 2 }} gutterBottom={true} color={theme.palette.text.secondary}>
            {deleteMessage}
          </Typography>
          <Alert severity="error" sx={{ my: 3 }}>
            <AlertTitle>{t('DataCollection_Technology_ActionDeleteDialogContentTitle')}</AlertTitle>
            {t('DataCollection_Technology_ActionDeleteDialogContentDescription')}
          </Alert>
        </>
      }
      actions={
        <>
          <Button
            color="error"
            variant="outlined"
            onClick={() => {
              setDeleteActionPlanModalOpen(false);
              onDeleteConfirm();
            }}
          >
            {t('General_ConfirmDeletion')}
          </Button>
          <Button
            onClick={() => {
              setDeleteActionPlanModalOpen(false);
            }}
            variant="contained"
          >
            {t('General_Cancel')}
          </Button>
        </>
      }
    />
  );
}
