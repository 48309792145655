import { emission_factor_type_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateEmissionFactorTypeEnum = (enumValue: emission_factor_type_enum) => {
  switch (enumValue) {
    case emission_factor_type_enum.BAHNERS_SCHMITZ:
      return <Trans i18nKey="Enum_EmissionFactorTypeEnum-BAHNERS_SCHMITZ" />;
    case emission_factor_type_enum.CO2TAX:
      return <Trans i18nKey="Enum_EmissionFactorTypeEnum-CO2TAX" />;
    case emission_factor_type_enum.CREEM:
      return <Trans i18nKey="Enum_EmissionFactorTypeEnum-CREEM" />;
    case emission_factor_type_enum.CREEM_V204_NORMALIZED:
      return <Trans i18nKey="Enum_EmissionFactorTypeEnum-CREEM_V204_NORMALIZED" />;
    case emission_factor_type_enum.GEG:
      return <Trans i18nKey="Enum_EmissionFactorTypeEnum-GEG" />;
    case emission_factor_type_enum.GEG_CRREM:
      return <Trans i18nKey="Enum_EmissionFactorTypeEnum-GEG_CRREM" />;
    case emission_factor_type_enum.GEMIS_V495:
      return <Trans i18nKey="Enum_EmissionFactorTypeEnum-GEMIS_V495" />;
    case emission_factor_type_enum.GEMIS_V500:
      return <Trans i18nKey="Enum_EmissionFactorTypeEnum-GEMIS_V500" />;
    case emission_factor_type_enum.WUEST_PARTNER:
      return <Trans i18nKey="Enum_EmissionFactorTypeEnum-WUEST_PARTNER" />;
  }
};

export const translateEmissionFactorTypeEnum_dynamic = (
  enumValue: emission_factor_type_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case emission_factor_type_enum.BAHNERS_SCHMITZ:
      return t('Enum_EmissionFactorTypeEnum-BAHNERS_SCHMITZ');
    case emission_factor_type_enum.CO2TAX:
      return t('Enum_EmissionFactorTypeEnum-CO2TAX');
    case emission_factor_type_enum.CREEM:
      return t('Enum_EmissionFactorTypeEnum-CREEM');
    case emission_factor_type_enum.CREEM_V204_NORMALIZED:
      return t('Enum_EmissionFactorTypeEnum-CREEM_V204_NORMALIZED');
    case emission_factor_type_enum.GEG:
      return t('Enum_EmissionFactorTypeEnum-GEG');
    case emission_factor_type_enum.GEG_CRREM:
      return t('Enum_EmissionFactorTypeEnum-GEG_CRREM');
    case emission_factor_type_enum.GEMIS_V495:
      return t('Enum_EmissionFactorTypeEnum-GEMIS_V495');
    case emission_factor_type_enum.GEMIS_V500:
      return t('Enum_EmissionFactorTypeEnum-GEMIS_V500');
    case emission_factor_type_enum.WUEST_PARTNER:
      return t('Enum_EmissionFactorTypeEnum-WUEST_PARTNER');
  }
};
