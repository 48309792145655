import { energy_consumer_type_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateEnergyConsumerTypeEnum = (enumValue: energy_consumer_type_enum) => {
  switch (enumValue) {
    case energy_consumer_type_enum.COOLING:
      return <Trans i18nKey="Enum_EnergyConsumerTypeEnum-COOLING" />;
    case energy_consumer_type_enum.ELECTRICITY:
      return <Trans i18nKey="Enum_EnergyConsumerTypeEnum-ELECTRICITY" />;
    case energy_consumer_type_enum.GENERAL:
      return <Trans i18nKey="Enum_EnergyConsumerTypeEnum-GENERAL" />;
    case energy_consumer_type_enum.HEATING:
      return <Trans i18nKey="Enum_EnergyConsumerTypeEnum-HEATING" />;
    case energy_consumer_type_enum.HOT_WATER:
      return <Trans i18nKey="Enum_EnergyConsumerTypeEnum-HOT_WATER" />;
    case energy_consumer_type_enum.LIGHTING:
      return <Trans i18nKey="Enum_EnergyConsumerTypeEnum-LIGHTING" />;
    case energy_consumer_type_enum.VENTILATION:
      return <Trans i18nKey="Enum_EnergyConsumerTypeEnum-VENTILATION" />;
  }
};

export const translateEnergyConsumerTypeEnum_dynamic = (
  enumValue: energy_consumer_type_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case energy_consumer_type_enum.COOLING:
      return t('Enum_EnergyConsumerTypeEnum-COOLING');
    case energy_consumer_type_enum.ELECTRICITY:
      return t('Enum_EnergyConsumerTypeEnum-ELECTRICITY');
    case energy_consumer_type_enum.GENERAL:
      return t('Enum_EnergyConsumerTypeEnum-GENERAL');
    case energy_consumer_type_enum.HEATING:
      return t('Enum_EnergyConsumerTypeEnum-HEATING');
    case energy_consumer_type_enum.HOT_WATER:
      return t('Enum_EnergyConsumerTypeEnum-HOT_WATER');
    case energy_consumer_type_enum.LIGHTING:
      return t('Enum_EnergyConsumerTypeEnum-LIGHTING');
    case energy_consumer_type_enum.VENTILATION:
      return t('Enum_EnergyConsumerTypeEnum-VENTILATION');
  }
};
