import { createSvgIcon } from '@mui/material';

const IconStylus = createSvgIcon(
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    transform="translate(2, 2)"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.475 16.825L20.85 8.45L19.55 7.15L11.175 15.525L12.475 16.825ZM5.95 19C4.28333 18.9167 3.04167 18.5667 2.225 17.95C1.40833 17.3333 1 16.4417 1 15.275C1 14.1917 1.446 13.3127 2.338 12.638C3.23 11.9633 4.46733 11.559 6.05 11.425C6.7 11.375 7.18767 11.271 7.513 11.113C7.83833 10.955 8.00067 10.734 8 10.45C8 10.0167 7.75433 9.69166 7.263 9.475C6.77167 9.25833 5.959 9.1 4.825 9L5 7C6.71667 7.13333 7.97933 7.47933 8.788 8.038C9.59667 8.59666 10.0007 9.40066 10 10.45C10 11.3333 9.67933 12.025 9.038 12.525C8.39667 13.025 7.45067 13.325 6.2 13.425C5.13333 13.5083 4.33333 13.7043 3.8 14.013C3.26667 14.3217 3 14.7423 3 15.275C3 15.8583 3.23333 16.2793 3.7 16.538C4.16667 16.7967 4.95 16.9507 6.05 17L5.95 19ZM12.95 19.175L8.825 15.05L19.55 4.325L23.7 8.45L12.95 19.175ZM12.95 19.175L7.75 20.25L8.825 15.05L12.95 19.175Z"
      fill="#637381"
    />
  </svg>,
  'IconStylus',
);

export default IconStylus;
