/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { forwardRef, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import { SnackbarTimeouts } from '../../../../../components/NotistackProvider';
import { FormProvider, RHFTextField } from '../../../../../components/hook-form';
import { CREATE_EVIDENCE } from '../../../../../graphql/Report.mutations';
import { GET_REPORT_EVIDENCES } from '../../../../../graphql/Report.queries';
import { EvidenceListEntry } from '../RootQuestionEvidences';

type Props = {
  reportId: number;
  onEvidencesAdded: (evidences: EvidenceListEntry[]) => void;
};

export type AddURLEvidenceFormHandles = {
  submit: () => void;
};

/**
 * The detached form for adding a URL as evidence.
 */
const AddURLEvidenceForm = forwardRef<AddURLEvidenceFormHandles, Props>(({ reportId, onEvidencesAdded }, ref) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const [createEvidenceMutation] = useMutation(CREATE_EVIDENCE, {
    refetchQueries: [{ query: GET_REPORT_EVIDENCES, variables: { reportId } }],
    onCompleted: () =>
      enqueueSnackbar(t('AddURLEvidenceForm_CreateEvidence-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      }),
    onError: () =>
      enqueueSnackbar(t('AddURLEvidenceForm_CreateEvidence-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
  });

  const FormSchema = object().shape({
    url: string()
      .required(t('AddURLEvidenceForm_UrlValidation-required'))
      .url(t('AddURLEvidenceForm_UrlValidation-required')),
    description: string(),
  });

  type FormValues = {
    url: string;
    description: string;
  };
  const methods = useForm<FormValues>({
    reValidateMode: 'onChange',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      url: '',
      description: '',
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = async (values: FormValues): Promise<void> => {
    const {
      //@ts-ignore
      data: { insert_report_evidence_one },
    } = await createEvidenceMutation({
      variables: { reportId, url: values.url },
    });

    if (insert_report_evidence_one) {
      onEvidencesAdded([
        {
          id: insert_report_evidence_one.id,
          type: 'url',
          title: insert_report_evidence_one.url,
          url: insert_report_evidence_one.url,
          description: values.description,
        },
      ]);
    }
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      handleSubmit(onSubmit)();
    },
  }));

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Typography style={{ marginTop: 16, marginBottom: 8 }}>{t('AddUrlEvidenceForm_EvidenceForm-title')}</Typography>
      <RHFTextField name="url" defaultValue="" placeholder={t('AddUrlEvidenceForm_EvidenceForm-placeholder')} />

      <Typography style={{ marginTop: 16, marginBottom: 8 }}>
        <Trans
          i18nKey="ReportEvidence_EvidenceHintOptional"
          components={{
            span: <span style={{ color: theme.palette.grey[600] }} />,
          }}
        />
      </Typography>
      <RHFTextField
        name="description"
        multiline
        fullWidth
        label={t('General_Note')}
        minRows={3}
        maxRows={6}
        defaultValue=""
      />
    </FormProvider>
  );
});

export default AddURLEvidenceForm;
