import { country_enum, country_state_enum, emission_factor_type_enum, energy_source_type_enum } from '@predium/enums';
import sumBy from 'lodash/sumBy';
import { BahnersSchmitzEmissionFactorProvider } from './emission-factor-providers/bahners-schmitz.emission-factor-provider';
import { Co2TaxEmissionFactorProvider } from './emission-factor-providers/co2-tax.emission-factor-provider';
import { CRREMEmissionFactorProvider } from './emission-factor-providers/crrem.emission-factor-provider';
import { CrremNormalizedEmissionFactorProvider } from './emission-factor-providers/crrem.normalized-provider';
import { EmissionFactorProvider } from './emission-factor-providers/emission-factor-provider.type';
import { GegCRREMEmissionFactorProvider } from './emission-factor-providers/geg-crrem.emission-factor-provider';
import { GegEmissionFactorProvider } from './emission-factor-providers/geg.emission-factor-provider';
import { Gemis495EmissionFactorProvider } from './emission-factor-providers/gemis495.emission-factor-provider';
import { Gemis500EmissionFactorProvider } from './emission-factor-providers/gemis500.emission-factor-provider';
import { WuestEmissionFactorProvider } from './emission-factor-providers/wuest.emission-factor-provider';

const providerLibrary: Record<emission_factor_type_enum, EmissionFactorProvider> = {
  BAHNERS_SCHMITZ: BahnersSchmitzEmissionFactorProvider,
  CO2TAX: Co2TaxEmissionFactorProvider,
  CREEM: CRREMEmissionFactorProvider,
  GEG: GegEmissionFactorProvider,
  GEG_CRREM: GegCRREMEmissionFactorProvider,
  GEMIS_V495: Gemis495EmissionFactorProvider,
  GEMIS_V500: Gemis500EmissionFactorProvider,
  CREEM_V204_NORMALIZED: CrremNormalizedEmissionFactorProvider,
  WUEST_PARTNER: WuestEmissionFactorProvider,
};

/**
 * Returns the co2 emissions for the given energy source, final energy and emission factor type.
 *
 * @export
 * @param {{
 *   energySourceType: energy_source_type_enum;
 *   emissionFactorsSource: emission_factor_type_enum;
 *   year: number;
 *   country_state: country_state_enum;
 *   postalCode: string;
 *   finalEnergy: number;
 * }} {
 *   energySourceType,
 *   emissionFactorsSource,
 *   year,
 *   country_state,
 *   postalCode,
 *   finalEnergy,
 * }
 * @return {*}
 */
export function getCO2Emissions({
  energySourceType,
  emissionFactorType,
  year,
  country,
  countryState,
  postalCode,
  energyFinal,
}: {
  energySourceType: energy_source_type_enum;
  emissionFactorType: emission_factor_type_enum;
  year: number;
  country: country_enum;
  countryState: country_state_enum;
  postalCode: string;
  energyFinal: number;
}) {
  return (
    getEmissionsFactor({
      energySourceType,
      emissionFactorType,
      year,
      country,
      countryState,
      postalCode,
    }) * energyFinal
  );
}

/**
 * @deprecated
 *
 * @export
 * @param {{
 *   sources: Array<{
 *     energy_source_type: energy_source_type_enum;
 *     energy_final: number;
 *   }>;
 *   year: number;
 *   emissionFactorType: emission_factor_type_enum;
 *   country: country_enum;
 *   countryState: country_state_enum;
 *   postalCode: string;
 * }} {
 *   sources,
 *   year,
 *   emissionFactorType,
 *   country,
 *   countryState,
 *   postalCode,
 * }
 * @return {*}  {number}
 */
export function getCO2EmissionsForEnergySources({
  sources,
  year,
  emissionFactorType,
  country,
  countryState,
  postalCode,
}: {
  sources: Array<{
    energy_source_type: energy_source_type_enum;
    energy_final: number;
  }>;
  year: number;
  emissionFactorType: emission_factor_type_enum;
  country: country_enum;
  countryState: country_state_enum;
  postalCode: string;
}): number {
  return sumBy(sources, (source) =>
    getCO2Emissions({
      energyFinal: source.energy_final,
      energySourceType: energy_source_type_enum[source.energy_source_type],
      year,
      emissionFactorType,
      country,
      countryState,
      postalCode,
    }),
  );
}

export function getCO2EmissionsForRoutes({
  routes,
  year,
  emissionFactorType,
  country,
  countryState,
  postalCode,
}: {
  routes: { energy_final: number; energy_source_type_id: energy_source_type_enum }[];
  year: number;
  emissionFactorType: emission_factor_type_enum;
  country: country_enum;
  countryState: country_state_enum;
  postalCode: string;
}): number {
  return sumBy(routes, (route) =>
    getCO2Emissions({
      energyFinal: route.energy_final,
      energySourceType: energy_source_type_enum[route.energy_source_type_id],
      year,
      country,
      emissionFactorType,
      countryState,
      postalCode,
    }),
  );
}

// TODO (PRE-1596): Refactor this method: Currently this method dumps for each call all values in memory and calculates all kinds
//  of factors (unused) -> very inefficient, must be refactored soon!
export function getEmissionsFactor({
  energySourceType,
  emissionFactorType,
  year,
  country,
  countryState,
  postalCode,
}: {
  // country: country_enum,
  energySourceType: energy_source_type_enum;
  emissionFactorType: emission_factor_type_enum;
  year: number;
  country: country_enum;
  countryState: country_state_enum;
  postalCode: string;
}): number {
  //TODO: remove default once lookup is refactored
  if (!country) {
    country = country_enum.DE;
  }

  if (year < 2020) {
    year = 2020;
  }
  if (year > 2050) {
    year = 2050;
  }

  return providerLibrary[emissionFactorType].getEmissionFactor({
    energySourceType,
    year,
    country,
    countryState,
    postalCode,
  });
}
