import { InputLabel, SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { StylesConfig } from 'react-select';

const DARK_COLOR = 'rgb(33, 43, 54)';

type SelectWithSearchValue = {
  value: string | number;
  label: string | number;
};

type Props = {
  values: SelectWithSearchValue[];
  onSelect: (selectedValue: SelectWithSearchValue) => void;
  label?: string;
  defaultValue?: SelectWithSearchValue;
  alwaysFocused?: boolean;
  sx?: SxProps<Theme>;
};

export default function SelectWithSearch(props: Props) {
  const { t } = useTranslation();
  const [value, setValue] = useState<null | SelectWithSearchValue>(null);
  const [shouldRenderValue, setShouldRenderValue] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    if (props.defaultValue) {
      setValue(props.defaultValue ?? null);
      props.onSelect(props.defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue?.value]); // Render only initially and if defaultValue changes

  const handleChange = (newValue: SelectWithSearchValue) => {
    const selectedValue = props.values.find((value) => value.value === newValue.value);
    setValue(selectedValue ?? null);
    if (selectedValue) props.onSelect(selectedValue);
  };

  const customStyles: StylesConfig = {
    control: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      border: '1px solid rgba(145, 158, 171, 0.32)',
      borderRadius: 8,
      color: DARK_COLOR,
      padding: '16px 0px 16px 14px',
      fontSize: '1rem',
      boxShadow: state.isFocused ? `0 0 0 1px ${DARK_COLOR}` : 'none',
      '&:hover': {
        color: DARK_COLOR,
        boxShadow: `0 0 0 1px ${DARK_COLOR}`,
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
      margin: 0,
      fontSize: '0.875rem',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: 0,
    }),
    input: (provided) => ({
      ...provided,
      margin: 0,
      padding: 0,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0 8px 0 8px',
      color: 'rgb(99, 115, 129)',
      scale: '85%',
    }),
    menu: (provided) => ({
      ...provided,
      padding: 0,
      borderRadius: theme.shape.borderRadius,
    }),
    menuList: (provided) => ({
      ...provided,
      '::-webkit-scrollbar': {
        display: 'none',
      },
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      width: '100%',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: DARK_COLOR,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: DARK_COLOR,
    }),

    option: (provided, state) => ({
      ...provided,
      width: 'auto',
      whiteSpace: 'nowrap',
      typography: 'body2',
      mx: 1,
      my: 0.5,
      borderRadius: '6px',
      fontSize: '0.875rem',
      margin: '6px 10px',
      backgroundColor: state.isSelected ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
      color: DARK_COLOR,
      '&:hover': {
        backgroundColor: 'rgba(145, 158, 171, 0.08)',
      },
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 2,
    }),
  };

  const options = props.values.map((value) => ({ value: value.value as string, label: value.label as string }));

  return (
    <Box sx={{ ...props.sx, minWidth: 120 }}>
      <FormControl fullWidth>
        {props.label && (
          <InputLabel shrink sx={{ backgroundColor: 'white', padding: '0 8px', marginLeft: '-5px' }}>
            {props.label}
          </InputLabel>
        )}
        <Select
          value={value}
          onChange={handleChange as any}
          options={options}
          isSearchable={true}
          menuPortalTarget={document.body}
          styles={customStyles}
          menuPlacement="auto"
          placeholder={t('General_SearchAction')}
          components={{
            IndicatorSeparator: null,
          }}
          // don't show the selected value when the dropdown is open and show search message
          onMenuOpen={() => setShouldRenderValue(false)}
          onMenuClose={() => setShouldRenderValue(true)}
          controlShouldRenderValue={shouldRenderValue}
        />
      </FormControl>
    </Box>
  );
}
