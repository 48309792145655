/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { DialogContent, DialogTitle } from '@mui/material';
import { useMemo } from 'react';
import { DelayedLoading } from '../../../../../components/Loading';
import PreDialog from '../../../../../components/presentations/PreDialog/PreDialog';
import { GET_ROOT_QUESTION_AND_ANSWER } from '../../../../../graphql/Report.queries';
import { ReportQuestionDC } from '../../../ReportingDataClasses/ReportQuestion.dc';
import { ReportQuestionRoot } from '../../ReportQuestion';

type Props = {
  onClose: () => void;
  reportId: number;
  questionDetails: { id: number; title: string } | null;
};

function EvidenceQuestionDisplay({ onClose, reportId, questionDetails }: Props) {
  return (
    <PreDialog
      type="definedByChildren"
      fullWidth
      maxWidth="md"
      open={questionDetails?.id !== undefined}
      onClose={onClose}
    >
      <DialogTitle>{questionDetails?.title}</DialogTitle>
      <DialogContent>
        {/*@ts-ignore */}
        <EvidenceQuestionDisplayContent questionId={questionDetails?.id} reportId={reportId} onClose={onClose} />
      </DialogContent>
    </PreDialog>
  );
}

type EvidenceQuestionDisplayContentProps = {
  onClose: () => void;
  reportId: number;
  questionId: number;
};

function EvidenceQuestionDisplayContent({ onClose, questionId, reportId }: EvidenceQuestionDisplayContentProps) {
  const { data, loading } = useQuery(GET_ROOT_QUESTION_AND_ANSWER, {
    variables: {
      reportId,
      questionId,
    },
  });

  const question = useMemo(() => {
    if (!data) return undefined;

    //@ts-ignore
    const answer = data.report_template_question_by_pk.report_answers[0];
    const question = answer.report_template_question;

    return new ReportQuestionDC({
      ...question,
      answer,
      reportId,
    });
  }, [data, reportId]);

  if (loading || !question) return <DelayedLoading />;

  return (
    <ReportQuestionRoot
      reportId={reportId}
      question={question}
      isSubQuestion={false}
      isAnsweredQuestion
      autoFocus
      onDeleteCallback={() => {
        onClose();
      }}
    />
  );
}

export default EvidenceQuestionDisplay;
