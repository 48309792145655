import { MenuItem, Stack, Typography } from '@mui/material';
import { data_source_type_enum, envelope_type_enum } from '@predium/enums';
import { useMemo } from 'react';
import { useFieldArray, useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../../components/Iconify';
import { createEnvelopeDefaultValues } from '../../BuildingEnvelope';

import {
  areFieldArraysDirty,
  countApproximatedEnvelopes,
  duplicateComponent,
  generateFieldName,
  getEnvelopeIcon,
} from '../Envelope.util';
import { EmptyEnvelopeUnit } from '../EnvelopeUnit/EmptyEnvelopeUnit';
import { EnvelopeOtherUnit } from '../EnvelopeUnit/EnvelopeOtherUnit';
import { EnvelopeProps, EnvelopeSectionWrapper } from './EnvelopeSectionWrapper';

export function BottomEnvelopesSection({ title, icon }: EnvelopeProps) {
  const { t } = useTranslation();
  const { control, watch, resetField, setValue } = useFormContext();
  const { dirtyFields } = useFormState({
    control,
  });

  const { fields: floorFields, append: appendFloor } = useFieldArray({
    control,
    name: envelope_type_enum.FLOOR,
  });

  const { fields: basementCeilingFields, append: appendBasementCeiling } = useFieldArray({
    control,
    name: envelope_type_enum.BASEMENT_CEILING,
  });

  const totalTabulaEnvelopes = useMemo(() => {
    return countApproximatedEnvelopes(floorFields) + countApproximatedEnvelopes(basementCeilingFields);
  }, [floorFields, basementCeilingFields]);

  const totalEnvelopes = useMemo(() => {
    return floorFields.length + basementCeilingFields.length;
  }, [floorFields, basementCeilingFields]);

  const watchFloorFieldsArray = watch(envelope_type_enum.FLOOR);
  const controlledFloorFields = floorFields.map((field, index) => ({
    ...field,
    ...watchFloorFieldsArray[index],
  }));

  const watchBasemenCeilingFieldArray = watch(envelope_type_enum.BASEMENT_CEILING);
  const controlledBasementCeilingFields = basementCeilingFields.map((field, index) => ({
    ...field,
    ...watchBasemenCeilingFieldArray[index],
  }));

  const resetFieldArray = () => {
    resetField(envelope_type_enum.BASEMENT_CEILING);
    resetField(envelope_type_enum.FLOOR);
  };

  const isFieldArrayDirty = areFieldArraysDirty(dirtyFields, [
    envelope_type_enum.BASEMENT_CEILING,
    envelope_type_enum.FLOOR,
  ]);

  const totalFloor = controlledFloorFields.length;
  const totalBasementCeiling = controlledBasementCeilingFields.length;

  const addFloor = () => {
    appendFloor(createEnvelopeDefaultValues(envelope_type_enum.FLOOR), {
      focusName: generateFieldName(envelope_type_enum.FLOOR, totalFloor).areaFieldName,
    });
  };

  const addBasementCeiling = () => {
    appendBasementCeiling(createEnvelopeDefaultValues(envelope_type_enum.BASEMENT_CEILING), {
      focusName: generateFieldName(envelope_type_enum.BASEMENT_CEILING, totalBasementCeiling).areaFieldName,
    });
  };

  const removeEnvelope = (index: number, type: envelope_type_enum) => {
    setValue(generateFieldName(type, index).deleteFieldName, true, {
      shouldDirty: true,
    });
    setValue(generateFieldName(type, index).dataSourceTypeFieldName, data_source_type_enum.MANUAL);
  };

  return (
    <EnvelopeSectionWrapper
      title={title}
      icon={icon}
      totalTabulaEnvelopes={totalTabulaEnvelopes}
      totalEnvelopes={totalEnvelopes}
      resetSectionEnvelopes={resetFieldArray}
      isSectionDirty={isFieldArrayDirty}
      actions={
        <>
          <MenuItem onClick={addFloor}>
            <Iconify icon={getEnvelopeIcon(envelope_type_enum.FLOOR)} />
            {t('General_Floor_one')}
          </MenuItem>
          <MenuItem onClick={addBasementCeiling}>
            <Iconify icon={getEnvelopeIcon(envelope_type_enum.FLOOR)} />
            {t('General_BasementCeiling_one')}
          </MenuItem>
        </>
      }
    >
      <>
        {controlledFloorFields.map((data, index) => {
          return (
            <EnvelopeOtherUnit
              data={data}
              type={envelope_type_enum.FLOOR}
              title={t('DataCollection_Envelopes_FloorWithIndex', {
                index: controlledFloorFields.length === 1 ? '' : index + 1,
              })}
              key={index}
              index={index}
              handleCopy={() =>
                appendFloor(
                  duplicateComponent({
                    ...controlledFloorFields[index],
                    orientation: null,
                  }),
                  {
                    focusName: generateFieldName(envelope_type_enum.FLOOR, totalFloor).areaFieldName,
                  },
                )
              }
              handleRemove={() => removeEnvelope(index, envelope_type_enum.FLOOR)}
            />
          );
        })}

        {controlledBasementCeilingFields.map((data, index) => {
          return (
            <EnvelopeOtherUnit
              data={data}
              type={envelope_type_enum.BASEMENT_CEILING}
              title={t('DataCollection_Envelopes_BasementCeilingWithIndex', {
                index: controlledBasementCeilingFields.length === 1 ? '' : index + 1,
              })}
              key={index}
              index={index}
              handleCopy={() =>
                appendBasementCeiling(
                  duplicateComponent({
                    ...controlledBasementCeilingFields[index],
                    orientation: null,
                  }),
                  {
                    focusName: generateFieldName(envelope_type_enum.BASEMENT_CEILING, totalBasementCeiling)
                      .areaFieldName,
                  },
                )
              }
              handleRemove={() => removeEnvelope(index, envelope_type_enum.BASEMENT_CEILING)}
            />
          );
        })}

        {totalEnvelopes === 0 && (
          <Stack direction={'row'} alignItems={'center'} mt={3} mb={1}>
            <EmptyEnvelopeUnit
              title={t('General_Floor_one')}
              icon={getEnvelopeIcon(envelope_type_enum.FLOOR)}
              handleClick={addFloor}
            />
            <Typography sx={{ mx: 1 }}>{t('General_Or')}</Typography>
            <EmptyEnvelopeUnit
              title={t('General_BasementCeiling_one')}
              icon={getEnvelopeIcon(envelope_type_enum.BASEMENT_CEILING)}
              handleClick={addBasementCeiling}
            />
          </Stack>
        )}
      </>
    </EnvelopeSectionWrapper>
  );
}
