import { Button, Typography } from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IMAGES } from '../assets';
import Iconify from '../components/Iconify';
import Logo from '../components/Logo';
import useAccessToken from '../hooks/useAccessToken';

/**
 * Sometimes a firewall blocks access to our API and potentially it can also be down at times.
 * This component does a healthcheck and shows an error screen in these cases.
 */
function ApiHealthCheckPage({ children }: PropsWithChildren<{}>) {
  const { t } = useTranslation();
  const { accessToken } = useAccessToken();
  const [showNetworkErrorScreen, setShowNetworkErrorScreen] = useState(false);

  useEffect(() => {
    if (accessToken) {
      // Request the Hasura instance, not the GQL API
      fetch(
        import.meta.env.VITE_SANDBOX === 'true'
          ? `${import.meta.env.VITE_HASURA_ENDPOINT}healthz?strict=false`
          : `${import.meta.env.VITE_HASURA_ENDPOINT.split('/v1/graphql')[0]}/healthz?strict=false`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
        .then(({ ok }) => {
          if (!ok) {
            setShowNetworkErrorScreen(true);
          }
        })
        .catch(() => setShowNetworkErrorScreen(true));
    }
  }, [accessToken]);

  return showNetworkErrorScreen ? (
    <>
      <Logo sx={{ width: '150px', position: 'absolute', top: '32px', left: '32px' }} />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          gap: '32px',
        }}
      >
        <Typography variant="h3">{t('ApiHealthCheckPage_PageTitle')}</Typography>

        <Typography sx={{ width: '470px' }} textAlign="center">
          <Trans i18nKey="ApiHealthCheckPage_InfoTextPrimary">
            We're having trouble connecting. Possible reasons: our system currently runs a short update or Predium is
            blocked by your security settings. Check with your IT department to ensure that the URL
            <strong>api.predium.de</strong>
            is allowed.
          </Trans>
        </Typography>
        <Typography sx={{ width: '470px' }} textAlign="center">
          {t('ApiHealthCheckPage_InfoTextSecondary')}
        </Typography>

        <img
          src={IMAGES.NetworkError}
          alt="A red haired girl with a tablet in hand smiles next to a server with a lock symbol on it"
          height={270}
          width={361}
          typeof="image/svg+xml"
        />

        {/* eslint-disable-next-line no-restricted-globals */}
        <Button variant="contained" startIcon={<Iconify icon="mdi:reload" hFlip />} onClick={() => location.reload()}>
          {t('ApiHealthCheckPage_ReloadButtonTitle')}
        </Button>
      </div>
    </>
  ) : (
    <>{children}</>
  );
}

export default ApiHealthCheckPage;
