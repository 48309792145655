import { SxProps, TextField, TextFieldProps, Theme } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat, { NumberFormatProps, NumberFormatValues, SourceInfo } from 'react-number-format';

// ----------------------------------------------------------------------

interface IProps {
  name: string;
  label?: string;
  valueAsText?: boolean;
  min?: number;
  max?: number;
  size?: TextFieldProps['size']; // Update the type declaration for size
  sx?: SxProps<Theme>;
  disabled?: boolean;
  onBlur?: () => void;
  onEnter?: () => void;
  onValueChange?: (values: NumberFormatValues, sourceInfo: SourceInfo) => void;
  variant?: TextFieldProps['variant'];
  placeholder?: string;
  decimalScale?: number;
  thousandSeparator?: string;
  decimalSeparator?: string;
  suffix?: string;
  helperText?: string;
  nullable?: boolean;
  autoFocus?: boolean;
  numberFormatProps?: NumberFormatProps<TextFieldProps>;
}

export type RHFNumberFieldProps = IProps & Pick<TextFieldProps, 'InputProps' | 'onChange'>;

export default function RHFNumberField({
  name,
  valueAsText,
  size = 'medium',
  disabled = false,
  min,
  max,
  sx,
  onBlur,
  onEnter,
  variant,
  placeholder,
  helperText,
  nullable,
  numberFormatProps = {},
  ...other
}: RHFNumberFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => {
        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const inputValue = e.target.value;

          if (nullable && inputValue === '') {
            field.onChange(null);
          } else {
            const value = valueAsText ? e.target.value : +e.target.value;
            field.onChange(value);
          }

          other.onChange?.(e);
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter' || e.key === 'Escape') {
            onBlur?.(); // Trigger onBlur on Enter key press
            onEnter?.();
            e.preventDefault();
            e.stopPropagation();
          }
        };

        return (
          <NumberFormat
            allowNegative={false}
            allowLeadingZeros={false}
            allowedDecimalSeparators={[',', '.']}
            inputRef={ref}
            variant={variant}
            customInput={TextField}
            color="primary"
            fullWidth
            size={size}
            error={!!error}
            helperText={error?.message ?? helperText}
            sx={[{ maxWidth: '100%' }, ...(Array.isArray(sx) ? sx : [sx])]}
            disabled={disabled}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            isAllowed={(values) => {
              if (!min && !max) {
                return true;
              }
              const floatValue = values?.floatValue;
              const isValueWithinRange =
                (!min || (floatValue !== undefined && floatValue >= min)) &&
                (!max || (floatValue !== undefined && floatValue <= max) || (!min && floatValue === undefined));
              // if max is defined an min is 0: allow empty input
              return isValueWithinRange;
            }}
            {...numberFormatProps}
            {...field}
            {...other}
            onBlur={onBlur}
            onChange={onChange}
          />
        );
      }}
    />
  );
}
