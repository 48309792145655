import { LinearProgress, SxProps, Theme, Typography } from '@mui/material';
import { fPercent } from '@predium/utils';

type Props = {
  /**
   * Progress value between 0 and 1.
   */
  progress: number;
  hideLabel?: boolean;
  sx?: SxProps<Theme>;
};

function LinearProgressBar({ progress, hideLabel = false, sx }: Props) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
      <LinearProgress
        sx={{ width: '100%', height: 6, ...sx }}
        color={
          (progress >= 0.75 && 'success') ||
          (progress < 0.75 && progress >= 0.5 && 'warning') ||
          (progress < 0.5 && 'error') ||
          undefined
        }
        variant="determinate"
        value={progress * 100}
      />
      {hideLabel && <Typography>{fPercent(progress * 100, true)}</Typography>}
    </div>
  );
}

export default LinearProgressBar;
