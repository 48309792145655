import get from 'lodash/get';
import { ReactNode } from 'react';
import DataTableColumnHead from '../Components/DataTableColumnHead';
import { isValidReactNode } from '../utils';

export interface IBaseColumn<TData = unknown> {
  id: string;
  type: string;
  label: string;
  sortable: boolean;
  minWidth?: number | string;
  getId: () => string;
  getLabel: () => ReactNode;
  getValue: (data?: TData) => any;
  getValueLabel: (value: any) => ReactNode;
  renderCell: (data: TData) => ReactNode;
  renderHead: () => ReactNode;
}

export const BaseColumn: IBaseColumn = {
  id: '',
  type: '',
  label: '',
  sortable: false,
  getId() {
    if (!this.id) {
      throw new Error('id is required');
    }

    return this.id;
  },
  getLabel(): ReactNode {
    return this.label;
  },
  getValue(data) {
    return get(data, this.getId());
  },
  getValueLabel(value) {
    if (!isValidReactNode(value)) {
      throw new Error(`getValueLabel must return a valid ReactNode. Got: ${typeof value} - ${value}`);
    }

    return value;
  },
  renderCell(): ReactNode {
    throw new Error('renderCell is not implemented.');
  },
  renderHead() {
    return <DataTableColumnHead id={this.id} label={this.getLabel()} sortable={this.sortable} />;
  },
};
