import range from 'lodash/range';

/**
 * First reference year considered for all paths (2020).
 *
 * NOTE-1: If this changes also the TemperatureTargetPath.length must be changed.
 * */
export const TARGET_PATH_FIRST_YEAR = 2020;

/**
 * Last reference year considered for all paths (2050).
 *
 * NOTE-1: If this changes also the TemperatureTargetPath.length must be changed.
 * */
export const TARGET_PATH_LAST_YEAR = 2050;

/**
 * Years for which we consider CO2 Emissions (2020 to 2050)
 * */
export const TARGET_PATH_YEARS_RANGE = range(TARGET_PATH_FIRST_YEAR, TARGET_PATH_LAST_YEAR + 1);
