import { energy_consumer_technology_type_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateEnergyConsumerTechnologyTypeEnum = (
  enumValue: energy_consumer_technology_type_enum,
  count: number,
) => {
  switch (enumValue) {
    case energy_consumer_technology_type_enum.CENTRAL_AIR_CONDITIONING:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-CENTRAL_AIR_CONDITIONING" count={count} />;
    case energy_consumer_technology_type_enum.CENTRAL_ELECTRIC_STORAGE:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-CENTRAL_ELECTRIC_STORAGE" count={count} />;
    case energy_consumer_technology_type_enum.CENTRAL_EXHAUST:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-CENTRAL_EXHAUST" count={count} />;
    case energy_consumer_technology_type_enum.CENTRAL_SUPPLY_EXHAUST_WITHOUT_HRU:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-CENTRAL_SUPPLY_EXHAUST_WITHOUT_HRU" count={count} />;
    case energy_consumer_technology_type_enum.CENTRAL_SUPPLY_EXHAUST_WITH_HRU:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-CENTRAL_SUPPLY_EXHAUST_WITH_HRU" count={count} />;
    case energy_consumer_technology_type_enum.COAL_FURNACE:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-COAL_FURNACE" count={count} />;
    case energy_consumer_technology_type_enum.CONDENSING_BOILER:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-CONDENSING_BOILER" count={count} />;
    case energy_consumer_technology_type_enum.DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITHOUT_HRU:
      return (
        <Trans
          i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITHOUT_HRU"
          count={count}
        />
      );
    case energy_consumer_technology_type_enum.DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITH_HRU:
      return (
        <Trans
          i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITH_HRU"
          count={count}
        />
      );
    case energy_consumer_technology_type_enum.DIRECT_ELECTRICITY_HEATING:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-DIRECT_ELECTRICITY_HEATING" count={count} />;
    case energy_consumer_technology_type_enum.DISTRICT_HEATING_WITHOUT_KWK:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-DISTRICT_HEATING_WITHOUT_KWK" count={count} />;
    case energy_consumer_technology_type_enum.DISTRICT_HEATING_WITH_KWK:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-DISTRICT_HEATING_WITH_KWK" count={count} />;
    case energy_consumer_technology_type_enum.ELECTRICITY_MISCELLANEOUS:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-ELECTRICITY_MISCELLANEOUS" count={count} />;
    case energy_consumer_technology_type_enum.ELECTRIC_FLOW_HEATER:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-ELECTRIC_FLOW_HEATER" count={count} />;
    case energy_consumer_technology_type_enum.ELECTRIC_HEAT_PUMP_AIR:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-ELECTRIC_HEAT_PUMP_AIR" count={count} />;
    case energy_consumer_technology_type_enum.ELECTRIC_HEAT_PUMP_GEO:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-ELECTRIC_HEAT_PUMP_GEO" count={count} />;
    case energy_consumer_technology_type_enum.ELECTRIC_IMMERSION_HEATER:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-ELECTRIC_IMMERSION_HEATER" count={count} />;
    case energy_consumer_technology_type_enum.FLUORESCENT_LAMPS:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-FLUORESCENT_LAMPS" count={count} />;
    case energy_consumer_technology_type_enum.GAS_CONDENSING_BOILER:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-GAS_CONDENSING_BOILER" count={count} />;
    case energy_consumer_technology_type_enum.GAS_FLOOR_HEATING:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-GAS_FLOOR_HEATING" count={count} />;
    case energy_consumer_technology_type_enum.GAS_FLOW_HEATER:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-GAS_FLOW_HEATER" count={count} />;
    case energy_consumer_technology_type_enum.GAS_NON_CONDENSING_BOILER:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-GAS_NON_CONDENSING_BOILER" count={count} />;
    case energy_consumer_technology_type_enum.GAS_ROOM_HEATER:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-GAS_ROOM_HEATER" count={count} />;
    case energy_consumer_technology_type_enum.HALOGEN_LAMPS:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-HALOGEN_LAMPS" count={count} />;
    case energy_consumer_technology_type_enum.INCANDESCENT_LAMPS:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-INCANDESCENT_LAMPS" count={count} />;
    case energy_consumer_technology_type_enum.LED:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-LED" count={count} />;
    case energy_consumer_technology_type_enum.LOW_TEMPERATURE_BOILER:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-LOW_TEMPERATURE_BOILER" count={count} />;
    case energy_consumer_technology_type_enum.OIL_FURNACE:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-OIL_FURNACE" count={count} />;
    case energy_consumer_technology_type_enum.REVERSIBLE_AIR_WATER_HEAT_PUMP:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-REVERSIBLE_AIR_WATER_HEAT_PUMP" count={count} />;
    case energy_consumer_technology_type_enum.REVERSIBLE_BRINE_WATER_HEAT_PUMP:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-REVERSIBLE_BRINE_WATER_HEAT_PUMP" count={count} />;
    case energy_consumer_technology_type_enum.ROOM_AIR_CONDITIONING:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-ROOM_AIR_CONDITIONING" count={count} />;
    case energy_consumer_technology_type_enum.SMALL_ELECTRIC_STORAGE:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-SMALL_ELECTRIC_STORAGE" count={count} />;
    case energy_consumer_technology_type_enum.SOLAR_PLANT:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-SOLAR_PLANT" count={count} />;
    case energy_consumer_technology_type_enum.STANDARD_BOILER:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-STANDARD_BOILER" count={count} />;
    case energy_consumer_technology_type_enum.WOOD_BOILER:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-WOOD_BOILER" count={count} />;
    case energy_consumer_technology_type_enum.WOOD_FURNACE:
      return <Trans i18nKey="Enum_EnergyConsumerTechnologyTypeEnum-WOOD_FURNACE" count={count} />;
  }
};

export const translateEnergyConsumerTechnologyTypeEnum_dynamic = (
  enumValue: energy_consumer_technology_type_enum,
  t: TFunction<'translation', undefined>,
  count: number,
) => {
  switch (enumValue) {
    case energy_consumer_technology_type_enum.CENTRAL_AIR_CONDITIONING:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-CENTRAL_AIR_CONDITIONING', { count });
    case energy_consumer_technology_type_enum.CENTRAL_ELECTRIC_STORAGE:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-CENTRAL_ELECTRIC_STORAGE', { count });
    case energy_consumer_technology_type_enum.CENTRAL_EXHAUST:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-CENTRAL_EXHAUST', { count });
    case energy_consumer_technology_type_enum.CENTRAL_SUPPLY_EXHAUST_WITHOUT_HRU:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-CENTRAL_SUPPLY_EXHAUST_WITHOUT_HRU', { count });
    case energy_consumer_technology_type_enum.CENTRAL_SUPPLY_EXHAUST_WITH_HRU:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-CENTRAL_SUPPLY_EXHAUST_WITH_HRU', { count });
    case energy_consumer_technology_type_enum.COAL_FURNACE:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-COAL_FURNACE', { count });
    case energy_consumer_technology_type_enum.CONDENSING_BOILER:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-CONDENSING_BOILER', { count });
    case energy_consumer_technology_type_enum.DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITHOUT_HRU:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITHOUT_HRU', { count });
    case energy_consumer_technology_type_enum.DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITH_HRU:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITH_HRU', { count });
    case energy_consumer_technology_type_enum.DIRECT_ELECTRICITY_HEATING:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-DIRECT_ELECTRICITY_HEATING', { count });
    case energy_consumer_technology_type_enum.DISTRICT_HEATING_WITHOUT_KWK:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-DISTRICT_HEATING_WITHOUT_KWK', { count });
    case energy_consumer_technology_type_enum.DISTRICT_HEATING_WITH_KWK:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-DISTRICT_HEATING_WITH_KWK', { count });
    case energy_consumer_technology_type_enum.ELECTRICITY_MISCELLANEOUS:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-ELECTRICITY_MISCELLANEOUS', { count });
    case energy_consumer_technology_type_enum.ELECTRIC_FLOW_HEATER:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-ELECTRIC_FLOW_HEATER', { count });
    case energy_consumer_technology_type_enum.ELECTRIC_HEAT_PUMP_AIR:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-ELECTRIC_HEAT_PUMP_AIR', { count });
    case energy_consumer_technology_type_enum.ELECTRIC_HEAT_PUMP_GEO:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-ELECTRIC_HEAT_PUMP_GEO', { count });
    case energy_consumer_technology_type_enum.ELECTRIC_IMMERSION_HEATER:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-ELECTRIC_IMMERSION_HEATER', { count });
    case energy_consumer_technology_type_enum.FLUORESCENT_LAMPS:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-FLUORESCENT_LAMPS', { count });
    case energy_consumer_technology_type_enum.GAS_CONDENSING_BOILER:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-GAS_CONDENSING_BOILER', { count });
    case energy_consumer_technology_type_enum.GAS_FLOOR_HEATING:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-GAS_FLOOR_HEATING', { count });
    case energy_consumer_technology_type_enum.GAS_FLOW_HEATER:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-GAS_FLOW_HEATER', { count });
    case energy_consumer_technology_type_enum.GAS_NON_CONDENSING_BOILER:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-GAS_NON_CONDENSING_BOILER', { count });
    case energy_consumer_technology_type_enum.GAS_ROOM_HEATER:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-GAS_ROOM_HEATER', { count });
    case energy_consumer_technology_type_enum.HALOGEN_LAMPS:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-HALOGEN_LAMPS', { count });
    case energy_consumer_technology_type_enum.INCANDESCENT_LAMPS:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-INCANDESCENT_LAMPS', { count });
    case energy_consumer_technology_type_enum.LED:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-LED', { count });
    case energy_consumer_technology_type_enum.LOW_TEMPERATURE_BOILER:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-LOW_TEMPERATURE_BOILER', { count });
    case energy_consumer_technology_type_enum.OIL_FURNACE:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-OIL_FURNACE', { count });
    case energy_consumer_technology_type_enum.REVERSIBLE_AIR_WATER_HEAT_PUMP:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-REVERSIBLE_AIR_WATER_HEAT_PUMP', { count });
    case energy_consumer_technology_type_enum.REVERSIBLE_BRINE_WATER_HEAT_PUMP:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-REVERSIBLE_BRINE_WATER_HEAT_PUMP', { count });
    case energy_consumer_technology_type_enum.ROOM_AIR_CONDITIONING:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-ROOM_AIR_CONDITIONING', { count });
    case energy_consumer_technology_type_enum.SMALL_ELECTRIC_STORAGE:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-SMALL_ELECTRIC_STORAGE', { count });
    case energy_consumer_technology_type_enum.SOLAR_PLANT:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-SOLAR_PLANT', { count });
    case energy_consumer_technology_type_enum.STANDARD_BOILER:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-STANDARD_BOILER', { count });
    case energy_consumer_technology_type_enum.WOOD_BOILER:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-WOOD_BOILER', { count });
    case energy_consumer_technology_type_enum.WOOD_FURNACE:
      return t('Enum_EnergyConsumerTechnologyTypeEnum-WOOD_FURNACE', { count });
  }
};
