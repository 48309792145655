export type InputValue = string | number | null;

/**
 * Formats a decimal percentage as a whole number percentage with a percent symbol.
 *
 * @param {number} percentage - The decimal percentage to format (e.g., 0.5 for 50%).
 * @returns {string} The formatted whole number percentage with a percent symbol.
 */
export function fPercentFormatted(percentage: number): string {
  return (percentage * 100).toFixed(0) + '%';
}

/**
 * Checks if a number is less than or equal to a predefined threshold.
 *
 * @param {number} number - The numeric value to compare with the threshold.
 * @returns {boolean} `true` if the number is less than or equal to the threshold, `false` otherwise.
 */
function isLessThanOrEqualToThreshold(number: number): boolean {
  return number <= 1e-7;
}

type fCurrencyTrimmedOptionsProps = {
  /**
   * The value to round to (e.g., 100 for rounding to the nearest hundred). Set to 0 or `null` to disable rounding.
   */
  roundTo?: number;
  /**
   * The currency unit to append to the formatted value.
   */
  unit?: string;
};

/**
 * Formats a numeric value as currency with optional trimming.
 *
 * @param {InputValue} number - The numeric value to format as currency.
 * @param {fCurrencyTrimmedOptionsProps} [options] - Optional parameters
 * @returns {string} The formatted currency value with the specified unit.
 */
export function fCurrencyTrimmed(
  number: InputValue,
  { roundTo = 100, unit = ' €' }: fCurrencyTrimmedOptionsProps = { roundTo: 100, unit: ' €' },
): string {
  const numberValue = Number(number) || 0;
  const trimmed = roundTo ? Math.round(numberValue / roundTo) * roundTo : numberValue;
  const formattedNumber = new Intl.NumberFormat('en-US').format(trimmed);
  return formattedNumber.replace(/,/g, '.') + unit;
}

/**
 * Rounds a numeric value and returns a punctuated string ("." as thousand separator).
 *
 * @param {InputValue} number - The numeric value to format.
 * @returns {string} The formatted value.
 */
export function fPunctuatedNumber(number: InputValue): string {
  let numberValue = Number(number) || 0;
  numberValue = Math.round(numberValue);
  return numberValue.toLocaleString('de-DE');
}

/**
 * Formats a numeric value as a percentage.
 *
 * @param {number} number - The numeric value to format as a percentage.
 * @param {boolean} [singleDigit=false] - A flag indicating whether to format the percentage with a single digit precision (e.g., "50%" vs. "50.0%").
 * @param {Intl.NumberFormatOptions["signDisplay"]} [signDisplay='auto'] - Controls the display of the plus or minus sign for positive and negative numbers.
 * @param {boolean} [spaceAfterSign=false] - A flag indicating whether to add a space between the plus or minus sign and the number.
 * @returns {string} The formatted percentage value as a string.
 */
export function fPercent(
  number: number,
  singleDigit = false,
  signDisplay: Intl.NumberFormatOptions['signDisplay'] = 'auto',
  spaceAfterSign = false,
): string {
  const format = singleDigit ? '0%' : '0.0%';

  const formattedValue = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: format.includes('.') ? 1 : 0,
    useGrouping: false, // Disable the thousands separator
    signDisplay: signDisplay,
  }).format(number / 100);

  //value is the return by int formatter without space (5%). and need to show space between the number and % the symbol in the UI (e.g.: 5 %).
  let num = `${formattedValue.replace('%', '').trim()} %`;

  if (spaceAfterSign) {
    num = num.replace(/([-+])(\d)/, '$1 $2');
  }

  return num;
}

/**
 * Formats a numeric value as a string with optional formatting based on a threshold.
 *
 * @param {string|number} number - The numeric value to format as a string.
 * @returns {string} The formatted numeric value as a string with commas replaced by periods.
 */
export function fNumber(number: string | number): string {
  const numberValue = Number(number) || 0;
  const format = isLessThanOrEqualToThreshold(numberValue) ? '0' : new Intl.NumberFormat('en-US').format(numberValue);
  return format.replace(/,/g, '.');
}

/**
 * Shortens a numeric value by adding a suffix for thousands (k), millions (m), billions (b), and so on.
 *
 * @param {string|number} number - The numeric value to shorten or a string that represents a number.
 * @param {number} [decimals=1] - The number of decimal places to round the value before shortening.
 * @returns {string} The shortened numeric value with an appropriate suffix (k, m, b, etc.).
 */
export function fShortenNumber(number: string | number, decimals = 1): string {
  if (typeof number === 'string') {
    number = parseFloat(number);
  }
  let absNumber = Math.abs(number as number);
  const suffixes = ['', 'k', 'm', 'b', 't']; // Add more as needed

  let power = 0;
  while (absNumber >= 1000 && power < suffixes.length - 1) {
    absNumber /= 1000;
    power++;
  }

  if (absNumber !== 0 && +absNumber.toFixed(1) === 0) {
    return '<0.1';
  }

  const formattedNumber = absNumber.toFixed(decimals).replace(/\.0+$/, ''); // Remove decimal part if it's .0

  return `${formattedNumber}${suffixes[power]}`;
}

/**
 * Shortens a numeric value to two decimal places and returns it as a string.
 *
 * @param {number} number - The numeric value to shorten to two decimal places.
 * @returns {string} The numeric value shortened to two decimal places as a string with commas replaced by periods.
 */
export function fShortenToTwoDecimal(number: number): string {
  return (Math.round(number * 100) / 100).toString().replace(/,/g, '.');
}
/**
 * Formats a numeric value representing a size with a specified unit.
 *
 * @param {number} size - The numeric value to format as a size.
 * @param {string} unit - The unit of the size (e.g., "KB", "MB", "GB", etc.).
 * @returns {string} The formatted size with the specified unit.
 */
function formatSize(size: number, unit: string): string {
  /**
   * Formats a numeric value representing a size with a specified unit.
   *
   * @param {number} size - The numeric value to format as a size.
   * @param {string} unit - The unit of the size (e.g., "KB", "MB", "GB", etc.).
   * @returns {string} The formatted size with the specified unit.
   */
  return size % 1 === 0
    ? new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        useGrouping: false,
      }).format(size) + ` ${unit}`
    : new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }).format(size) + ` ${unit}`;
}

/**
 * Formats a numeric value representing a data size with an appropriate unit (B, KB, MB, GB, TB).
 *
 * @param {InputValue} number - The numeric value to format as a data size.
 * @returns {string} The formatted data size with the appropriate unit (B, KB, MB, GB, TB).
 */
export function fData(number: InputValue): string {
  /**
   * Formats a numeric value representing a data size with an appropriate unit (B, KB, MB, GB, TB).
   *
   * @param {InputValue} number - The numeric value to format as a data size.
   * @returns {string} The formatted data size with the appropriate unit (B, KB, MB, GB, TB).
   */
  if (number) {
    const bytes = Number(number);
    if (bytes >= 1e12) {
      // Terabytes
      const terabytes = bytes / 1e12;
      const response = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: terabytes % 1 === 0 ? 0 : 1,
        maximumFractionDigits: terabytes % 1 === 0 ? 0 : 1,
      }).format(terabytes);
      return formatSize(Number(response), 'TB');
    } else if (bytes >= 1e9) {
      // Gigabytes
      const gigabytes = bytes / 1e9;
      const response = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: gigabytes % 1 === 0 ? 0 : 1,
        maximumFractionDigits: gigabytes % 1 === 0 ? 0 : 1,
      }).format(gigabytes);
      return formatSize(Number(response), 'GB');
    } else if (bytes >= 1e6) {
      // Megabytes
      const megabytes = bytes / 1e6;
      const response = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: megabytes % 1 === 0 ? 0 : 1,
        maximumFractionDigits: megabytes % 1 === 0 ? 0 : 1,
      }).format(megabytes);
      return formatSize(Number(response), 'MB');
    } else if (bytes >= 1e3) {
      // Kilobytes
      const kilobytes = bytes / 1e3;
      const response = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: kilobytes % 1 === 0 ? 0 : 1,
        maximumFractionDigits: kilobytes % 1 === 0 ? 0 : 1,
      }).format(kilobytes);
      return formatSize(Number(response), 'KB');
    } else {
      // Bytes
      const response = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        useGrouping: false,
      }).format(bytes);
      return formatSize(Number(response), 'B');
    }
  }
  return '';
}

export function currencyString(value: number, currency: string): string {
  return `${(value * 1000000).toFixed(2)} ${currency}`;
}

/**
 * Rounds a numeric value to the nearest hundred (e.g., 49 => 0, 50 => 100, 1234 => 1200).
 *
 * @param {InputValue} value - The numeric value to be rounded to the nearest hundred.
 * @returns {number} The rounded numeric value.
 */
export function fTrimmedCosts(value: InputValue): number {
  if (!value) {
    return 0;
  }

  const number = parseFloat(value as string);
  return parseFloat((number / 100).toFixed(0)) * 100;
}

/**
 * Rounds a numeric value to a specified number of decimal places.
 *
 * @param {InputValue} value - The numeric value to be rounded.
 * @param {number} [decimals=1] - The number of decimal places to round to (default is 1).
 * @returns {number} The rounded numeric value.
 */
export function fRoundedNumber(value: InputValue, decimals = 1): number {
  if (!value) {
    return 0;
  }
  const number = parseFloat(value as string);
  return parseFloat((number * Math.pow(10, decimals)).toFixed(0)) / Math.pow(10, decimals);
}

/**
 * Checks if a number is close to an expected value within a certain precision.
 *
 * @param {number} value - The number to check.
 * @param {number} expected - The expected value.
 * @param {number} [precision=1e-5] - The precision within which the values should be considered close.
 * @returns {boolean} Returns true if the absolute difference between the value and the expected value is less than the precision.
 */

export function isCloseTo(value: number, expected: number, precision = 1e-5): boolean {
  return Math.abs(value - expected) < precision;
}

/**
 * This methods expects two values, before and after an implemented action and returns the impact of in **percent**.
 * As both values can be undefined the special cases are handled to avoid NaN or Infinity.
 *
 * @param after the numerator (the value after the action)
 * @param before the denominator (the value before the action)
 */
export function calculateImpact(after?: number, before?: number): number {
  if (before === undefined || after === undefined) {
    return 0;
  }
  if (before === 0) {
    if (after === 0) {
      return 0;
    } else if (after > 0) {
      return 100;
    } else {
      return -100;
    }
  }
  return (1 - after / before) * -1 * 100;
}

/**
 * This methods expects two values, before and after an implemented action and returns the **absolute** savings while the individual numbers being rounded first.
 * As both values can be undefined the special cases are handled to avoid NaN or Infinity.
 *
 * @param after the value after the action
 * @param before the value before the action
 */
export function calculateSavings(after?: number | null, before?: number | null): number | undefined {
  if (before === undefined || before === null || after === undefined || after === null) {
    return undefined;
  }
  return Math.round(after) - Math.round(before);
}

/**
 * Converts centimeters to meters with a specified number of decimal places.
 *
 * @param {number} cm - The length in centimeters to convert to meters.
 * @param {number} decimalPlaces - The number of decimal places to round the result to. Default is 2.
 * @returns {string} The converted length in meters as a string with the specified precision.
 */ export const cmToM = (cm: number, decimalPlaces = 2): number => parseFloat((cm / 100).toFixed(decimalPlaces));

/**
 * Converts meters to centimeters with a specified number of decimal places.
 *
 * @param {number} m - The length in meters to convert to centimeters.
 * @param {number} decimalPlaces - The number of decimal places to round the result to. Default is 2.
 * @returns {string} The converted length in centimeters as a string with the specified precision.
 */

export const mToCm = (m: number, decimalPlaces = 2): number => parseFloat((m * 100).toFixed(decimalPlaces));

/* This method helps solving the floating-point bug in JavaScript.
 * Source: https://github.com/maslianok/js-floating-point/blob/master/src/index.js
 * @param value
 * @param recurringSymbols
 * @returns
 */
export function fFloatingPoint(value: number, recurringSymbols = 6) {
  const [intPart, decimalPart] = `${value}`.split('.');

  if (!decimalPart) {
    // no decimal part
    return value;
  }

  const regex = new RegExp(`(9{${recurringSymbols},}|0{${recurringSymbols},})(\\d)*$`, 'gm');
  const matched = decimalPart.match(regex);

  if (!matched) {
    // no floating-point bug
    return value;
  }

  const [wrongPart] = matched;
  const correctDecimalsLength = decimalPart.length - wrongPart.length;
  return Number(parseFloat(`${intPart}.${decimalPart}`).toFixed(correctDecimalsLength));
}

export function convertUTCDateToLocalDate(date: Date) {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}
