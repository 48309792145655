import { graphql } from '@predium/client-graphql';

export const CREATE_USER = graphql(`
  mutation CreateUser($email: String!, $first_name: String!, $last_name: String!, $role: role_enum!) {
    createUser(email: $email, first_name: $first_name, last_name: $last_name, role: $role) {
      user_id
    }
  }
`);

export const UPDATE_USER = graphql(`
  mutation UpdateUser($id: Int!, $first_name: String!, $last_name: String!) {
    update_user_by_pk(pk_columns: { id: $id }, _set: { first_name: $first_name, last_name: $last_name }) {
      created_at
    }
  }
`);

export const UPDATE_USER_ROLE = graphql(`
  mutation UpdateUserRole($user_id: Int!, $role: role_enum = USER) {
    update_user_role(where: { user_id: { _eq: $user_id } }, _set: { role: $role }) {
      affected_rows
      returning {
        user_id
      }
    }
  }
`);

export const CHANGE_USER_LANGUAGE = graphql(`
  mutation ChangeUserLanguage($id: Int!, $language: language_enum!) {
    update_user_by_pk(pk_columns: { id: $id }, _set: { language: $language }) {
      id
      language
    }
  }
`);

export const DELETE_USER = graphql(`
  mutation DeleteUserAndtransferOwnership($userId: Int!, $transferOwnershipToUserId: Int) {
    deleteUser(user_id: $userId, transfer_ownership_to_user_id: $transferOwnershipToUserId) {
      UserId {
        __typename
        id
      }
    }
  }
`);

export const UPSERT_USER_BUILDING_PERMISSION = graphql(`
  mutation UpsertBuildingPermissions($objects: [user_building_permission_insert_input!] = {}) {
    insert_user_building_permission(
      objects: $objects
      on_conflict: { constraint: user_building_permission_user_id_building_id_key, update_columns: [read, write] }
    ) {
      affected_rows
    }
  }
`);

export const UPSERT_USER_PORTFOLIO_PERMISSION = graphql(`
  mutation UpsertPortfolioPermissions($objects: [user_portfolio_permission_insert_input!] = {}) {
    insert_user_portfolio_permission(
      objects: $objects
      on_conflict: { constraint: user_portfolio_permission_user_id_portfolio_id_key, update_columns: [read, write] }
    ) {
      affected_rows
    }
  }
`);
