import { Button, DialogActions, DialogContent } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileId } from '../../../../../components/DropzoneComponent';
import PreToggleButton from '../../../../../components/presentations/PreToggleButton';
import { EvidenceListEntry } from '../RootQuestionEvidences';
import { AddURLEvidenceFormHandles } from './AddURLEvidenceForm';
import ExistingEvidenceForm from './ExistingEvidenceForm';
import NewEvidence from './NewEvidence';

type Props = {
  reportId: number;
  closeDialog: () => void;
  onEvidencesAdded: (evidences: EvidenceListEntry[]) => void;
  selectedEvidenceIds: number[];
};

/**
 * Needed to separate the form from the Dialog, otherwise it is animated again if the component updates.
 */
function EvidenceForm({ reportId, closeDialog, onEvidencesAdded, selectedEvidenceIds }: Props) {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<'new_evidence' | 'existing_evidence'>('new_evidence');

  const evidenceFormRef = useRef<AddURLEvidenceFormHandles>(null);
  const persistedUploadFileRef = useRef<(Dropzone.DropzoneFile & FileId) | null>(null);

  const callSubmitOfEitherForm = () => {
    evidenceFormRef.current?.submit();
  };

  return (
    <>
      <DialogContent>
        <PreToggleButton
          leftValue={{ key: 'new_evidence', value: t('EvidenceForm_NewEvidence') }}
          rightValue={{ key: 'existing_evidence', value: t('EvidenceForm_ExistingEvidence') }}
          initialValueType="new_evidence"
          onToggle={(newValue) => setCurrentTab(newValue)}
          fullWidth
          style={{ marginBottom: 16 }}
          size="medium"
        />

        {currentTab === 'new_evidence' && (
          <NewEvidence
            reportId={reportId}
            onEvidencesAdded={onEvidencesAdded}
            ref={evidenceFormRef}
            persistedUploadFileRef={persistedUploadFileRef}
          />
        )}

        {currentTab === 'existing_evidence' && (
          <ExistingEvidenceForm
            reportId={reportId}
            onEvidencesAdded={onEvidencesAdded}
            selectedEvidenceIds={selectedEvidenceIds}
            ref={evidenceFormRef}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={closeDialog} type="button">
          {t('General_Cancel')}
        </Button>
        <Button variant="contained" type="button" onClick={callSubmitOfEitherForm}>
          {t('General_AddEvidence')}
        </Button>
      </DialogActions>
    </>
  );
}

export default EvidenceForm;
