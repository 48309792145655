import {
  consumption_sub_type_enum,
  consumption_type_enum,
  consumption_unit_enum,
  energy_source_type_enum,
} from '@predium/enums';

export const CONSUMPTION_UNIT_MAPPING: Record<consumption_type_enum, consumption_unit_enum> = {
  [consumption_type_enum.HEATING]: consumption_unit_enum.KWH,
  [consumption_type_enum.ELECTRICITY]: consumption_unit_enum.KWH,
  [consumption_type_enum.WATER]: consumption_unit_enum.M3,
  [consumption_type_enum.WASTE]: consumption_unit_enum.T,
};

export const ENERGY_SOURCE_TYPES_MAPPING: Partial<Record<consumption_type_enum, energy_source_type_enum[]>> = {
  [consumption_type_enum.HEATING]: [
    energy_source_type_enum.BIO_GAS,
    energy_source_type_enum.COAL,
    energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL,
    energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS,
    energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE,
    energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL,
    energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS,
    energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE,
    energy_source_type_enum.FUEL_OIL,
    energy_source_type_enum.LIGNITE,
    energy_source_type_enum.LPG,
    energy_source_type_enum.NATURAL_GAS,
    energy_source_type_enum.WOOD,
    energy_source_type_enum.WOODEN_PELLETS,
    energy_source_type_enum.UNSPECIFIED,
  ],
  [consumption_type_enum.ELECTRICITY]: [
    energy_source_type_enum.ELECTRICITY_GREEN,
    energy_source_type_enum.ELECTRICITY_MIX,
    energy_source_type_enum.SOLAR,
    energy_source_type_enum.UNSPECIFIED,
  ],
};

export const CONSUMPTION_SUB_TYPE_MAPPING: Partial<Record<consumption_type_enum, consumption_sub_type_enum[]>> = {
  [consumption_type_enum.WASTE]: [
    consumption_sub_type_enum.WASTE_CONSTRUCTION_AND_EXCAVATION,
    consumption_sub_type_enum.WASTE_ELECTRONIC_EQUIPMENT,
    consumption_sub_type_enum.WASTE_GLASS,
    consumption_sub_type_enum.WASTE_MUNICIPAL,
    consumption_sub_type_enum.WASTE_ORGANIC,
    consumption_sub_type_enum.WASTE_OTHER_NOT_RECYCLEABLE,
    consumption_sub_type_enum.WASTE_OTHER_RECYCLEABLE,
    consumption_sub_type_enum.WASTE_PACKAGING,
    consumption_sub_type_enum.WASTE_PAPER,
    consumption_sub_type_enum.WASTE_RESIDUAL,
    consumption_sub_type_enum.WASTE_WOOD,
  ],
  [consumption_type_enum.WATER]: [
    consumption_sub_type_enum.WATER_DRINKING,
    consumption_sub_type_enum.WATER_RAINWATER,
    consumption_sub_type_enum.WATER_SEWAGE,
  ],
};
