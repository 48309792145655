import { FormHelperText } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { Controller, useFormContext } from 'react-hook-form';

// ----------------------------------------------------------------------

interface Props extends SwitchProps {
  name: string;
  helperText?: React.ReactNode;
  label: React.ReactNode;
}

/**
 * Minimal@4 component.
 */
export default function RHFSwitch({ name, helperText, label, onChange: onValueChange, ...props }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div>
          <FormControlLabel
            label={label}
            control={
              <Switch
                onChange={(_, value) => {
                  onValueChange?.(_, value);
                  onChange(value);
                }}
                checked={value}
                {...props}
              />
            }
          />

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </div>
      )}
    />
  );
}
