import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { area_type_enum } from '@predium/enums';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../../components/Iconify';
import InfoTooltip from '../../../../../components/InfoTooltip';
import useBuilding from '../../Context/useBuilding';
import SquareMeterRow from './SquareMeterRow';

type Props = {
  title: string;
  subtitle: string;

  areaTitle: string;
  areaTitleSuffix: string;
  areaTooltip: string;

  addAreaLabel?: string;
  onAddAreaClick?: VoidFunction;

  areas: ReactNode;
  areaSum: number;
  listAreaType: area_type_enum;
  summary: ReactNode;
};

const System = ({
  areaTitle,
  areaTitleSuffix,
  areaTooltip,
  areas,
  subtitle,
  summary,
  title,
  addAreaLabel,
  onAddAreaClick,
  areaSum,
  listAreaType,
}: Props) => {
  const { t } = useTranslation();
  const { hasEditAccess } = useBuilding();

  return (
    <Stack flex={1} spacing={1}>
      <Stack px={2.5} py={2.5}>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body2" color="grey.600">
          {subtitle}
        </Typography>
      </Stack>
      <Stack
        flex={1}
        minHeight="250px"
        border="1px solid"
        borderColor="divider"
        sx={{ borderTopLeftRadius: 16, borderTopRightRadius: 16 }}
      >
        <Stack flex={1} px={2.5} pt={1.5} pb={1}>
          <Stack direction="row" justifyContent="space-between" mb={2} height={36}>
            <Stack direction="row" spacing={0.75} alignItems="center">
              <Typography variant="subtitle2" fontWeight={700}>
                {areaTitle}
              </Typography>
              <Typography variant="subtitle2" fontWeight={400}>
                {areaTitleSuffix}
              </Typography>
              <InfoTooltip text={areaTooltip} sx={{ p: 0, svg: { height: 14, width: 14 } }} />
            </Stack>
            {hasEditAccess && onAddAreaClick && (
              <Button onClick={onAddAreaClick} variant="text" startIcon={<Iconify icon="mdi:plus" />}>
                {addAreaLabel}
              </Button>
            )}
          </Stack>
          <Stack minHeight={220} flex={1}>
            <Box mb={1}>{areas}</Box>
            <Divider sx={{ mt: 'auto', mb: 1 }} />
            <SquareMeterRow
              labels={{
                title: t('DataCollectionAreas_Sum'),
              }}
              value={areaSum}
              titleProps={{ variant: 'body2', color: 'grey.600' }}
              areaType={listAreaType}
            />
          </Stack>
        </Stack>
        <Box px={2.5} py={2} bgcolor="grey.100">
          {summary}
        </Box>
      </Stack>
    </Stack>
  );
};

export default System;
