import { role_enum } from '@predium/enums';
import { translateRoleEnum_dynamic } from '@predium/i18n/client';
import { TFunction } from 'i18next';

export type RoleOption = { name: role_enum | 'all'; displayName: string };

export const RoleSortingOptions = (t: TFunction<'translation', undefined>): Array<RoleOption> => {
  return [
    { name: 'all', displayName: t('General_All') },
    { name: role_enum.ORG_ADMIN, displayName: translateRoleEnum_dynamic(role_enum.ORG_ADMIN, t) },
    { name: role_enum.USER, displayName: translateRoleEnum_dynamic(role_enum.USER, t) },
  ];
};
