/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FileThumbnail from '../../../../components/FileThumbnail';
import Iconify from '../../../../components/Iconify';
import { GET_All_EVIDENCES, GET_QUESTION_EVIDENCES } from '../../../../graphql/Report.queries';
import { FormValues } from '../ReportQuestion';
import CountEvidence from './CountEvidence';
import EditEvidenceDescriptionDialog from './EditEvidenceDescriptionDialog';
import EvidenceDocumentViewer from './EvidenceForm/EvidenceDocumentViewer';

type Props = {
  required: boolean;
  openDialog: () => void;
  reportId: number;
  questionId: number;
  isAnsweredQuestion: boolean;
  isEditable: boolean;
};

export type EvidenceDataTypes = 'url' | 'file';

export type EvidenceListEntry = {
  id: number;
  title: string;
  type: EvidenceDataTypes;
  url: string;
  description: string;
};

export type EvidenceFieldArrayHandles = {
  appendEvidences: (evidences: EvidenceListEntry[]) => void;
  evidences: EvidenceListEntry[];
};

/**
 * Currently only root questions can have evidences attached. Some slight adjustments are needed to make it work for every question.
 * This component displays the attached evidences and manages that part of the form.
 *
 * If required === true the array is required to have at least 1 attached evidence.
 * If required === false the array is not required to have any attached evidence but must be defined.
 * If required === null the form field must not be specified
 */
const RootQuestionEvidences = forwardRef<EvidenceFieldArrayHandles, Props>(
  ({ openDialog, required, isAnsweredQuestion, isEditable, questionId, reportId }, ref) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const [expanded, setExpanded] = useState(false);
    const [showDocumentByUrl, setShowDocumentByUrl] = useState<string | null>(null);
    // additional information for the evidences that is not in the form but displayed. In sync with the field array.

    //@ts-ignore
    const [evidences, setEvidences] = useState<EvidenceListEntry[]>(isAnsweredQuestion ? undefined : []);

    const { setValue, formState } = useFormContext<FormValues>();

    useQuery(GET_QUESTION_EVIDENCES, {
      variables: {
        reportId,
        questionId,
      },
      skip: !isAnsweredQuestion || evidences !== undefined,
      onCompleted: (data) => {
        setEvidences(
          //@ts-ignore
          data.report_question_evidence.map(({ report_evidence, description }) => ({
            id: report_evidence.id,

            //@ts-ignore
            title: report_evidence.url ?? report_evidence.file.name,
            type: report_evidence.url ? 'url' : 'file',

            //@ts-ignore
            url: report_evidence.url ?? report_evidence.file.downloadUrl,
            description: description ?? '',
          })),
        );
      },
    });

    // This query watch updates the list of set evidences should one be deleted from the evidence manager.
    // As only evidences can be deleted that are not attached to any questions this query is only executed for unanswered questions.
    useQuery(GET_All_EVIDENCES, {
      variables: { reportId },
      skip: isAnsweredQuestion,
      onCompleted: (data) => {
        const allEvidencesStillAvailableAfterDelete = evidences.filter((setEvidence) =>
          data.report_evidence.find(({ id }) => id === setEvidence.id),
        );
        if (evidences.length !== allEvidencesStillAvailableAfterDelete.length) {
          setEvidences(allEvidencesStillAvailableAfterDelete);
        }
      },
    });

    // I needed to use and imperative API because when calling useFieldArray and [] is registered in the form which should only happen when we actually have the option to append an evidence.
    // I needed to split the Dialog and the Display because submitting the add-evidence form submitted the report-question form (even though its in a portal). Also it makes for a more reusable composition, so its not all bad.
    useImperativeHandle(ref, () => ({
      appendEvidences: (newEvidences: EvidenceListEntry[]) => {
        setEvidences([...evidences, ...newEvidences]);
      },
      evidences,
    }));

    const removeEvidenceFromList = (removeIndex: number) => {
      // removes the index from the array in a more efficient way than filter

      //@ts-ignore
      setEvidences([].concat(evidences.slice(0, removeIndex), evidences.slice(removeIndex + 1)));
    };

    useEffect(() => {
      if (evidences) {
        setValue(
          'questions.0.evidences',
          evidences.map(({ id, description }) => ({ id, description })),
          {
            shouldValidate: evidences.length > 0,
          },
        );
      }
    }, [evidences, setValue]);

    const formErrorMessage: string | undefined = formState.errors['questions']?.[0]?.evidences?.message;

    useEffect(() => {
      if (formErrorMessage) {
        setExpanded(true);
      }
    }, [formErrorMessage]);

    if (!evidences) return null;

    return (
      <>
        <Accordion
          expanded={expanded || formErrorMessage !== undefined}
          onChange={(_, expanded) => {
            if (formErrorMessage === undefined) {
              setExpanded(expanded);
            }
          }}
          style={{
            margin: '0 24px 16px 24px',
            boxShadow: 'none',
          }}
          sx={{
            '&:before': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <AccordionSummary
            sx={{ p: 0 }}
            style={{
              cursor: formErrorMessage === undefined ? 'pointer' : 'default',
            }}
            expandIcon={
              <Iconify width={16} height={16} icon={'material-symbols:arrow-forward-ios-rounded'} {...{ rotate: 1 }} />
            }
          >
            {t('General_Evidence', { count: 2 })}
            <span style={{ marginLeft: 6, marginRight: 6, color: theme.palette.grey[600] }}>
              ({required ? t('General_Required') : t('General_Optional')})
            </span>
            {isAnsweredQuestion && <CountEvidence reportId={reportId} questionId={questionId} />}
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <Box
              sx={{
                p: 2,
                border: `1px solid ${formErrorMessage ? theme.palette.error.main : theme.palette.grey[300]}`,
                borderRadius: 1,
              }}
            >
              {evidences.length === 0 && (
                <Typography color={theme.palette.grey[600]} sx={{ my: 1 }}>
                  {t('Reporting_EvidenceSubTitle')} {!required && `(${t('General_Optional')})`}.
                </Typography>
              )}

              {evidences.map((evidence, index) => {
                const { id, description, title, type, url } = evidence;

                return (
                  <Box
                    key={id}
                    sx={{
                      mb: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 1,
                        mb: 1,
                      }}
                    >
                      {type === 'file' ? (
                        <>
                          <IconButton
                            sx={{ mr: 0.75 }}
                            onClick={() => {
                              setShowDocumentByUrl(url);
                            }}
                          >
                            <FileThumbnail format="pdf" />
                          </IconButton>
                          <Typography style={{ flexGrow: 1 }}>{title}</Typography>
                        </>
                      ) : (
                        <>
                          <Iconify
                            style={{ marginRight: 16, marginLeft: 14 }}
                            width={24}
                            height={24}
                            icon="material-symbols:link"
                          />
                          <Link
                            style={{ flexGrow: 1, maxWidth: '80%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                            href={url}
                          >
                            {title}
                          </Link>
                        </>
                      )}

                      <EditEvidenceDescriptionDialog
                        evidence={evidence}
                        defaultValue={description}
                        disabled={!isEditable}
                        onCompleted={(updatedDescription) => {
                          // update the description in the list
                          const newEvidences = [...evidences];
                          newEvidences[index].description = updatedDescription;
                          setEvidences(newEvidences);
                        }}
                      />

                      <Tooltip title="Nachweis entfernen">
                        <span>
                          <IconButton
                            color="error"
                            disabled={!isEditable}
                            onClick={() => {
                              removeEvidenceFromList(index);
                            }}
                          >
                            <Iconify icon="eva:close-fill" />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Box>
                  </Box>
                );
              })}

              <Button
                style={{ marginTop: 16 }}
                size="large"
                disabled={!isEditable && isAnsweredQuestion}
                onClick={openDialog}
                startIcon={<Iconify icon="ic:baseline-plus" />}
              >
                {t('General_AddEvidence')}
              </Button>
            </Box>
            {formErrorMessage && <Typography color="error">{formErrorMessage}</Typography>}
          </AccordionDetails>
        </Accordion>

        <EvidenceDocumentViewer
          open={Boolean(showDocumentByUrl)}
          onClose={() => setShowDocumentByUrl(null)}
          //@ts-ignore
          documentUrl={showDocumentByUrl}
        />
      </>
    );
  },
);

export default RootQuestionEvidences;
