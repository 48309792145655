/* eslint-disable @typescript-eslint/ban-ts-comment */
import { EsgAnalysisGetBuildingsPortfolioAndAddressFragment } from '@predium/client-graphql';
import { useTranslation } from 'react-i18next';
import SelectMultiWithSearch, { SelectWithSearchValue } from '../../components/SelectMultiWithSearch';
import useEsgAnalysisFilters from '../../hooks/useEsgAnalysisFilters';
import { EsgAnalysisFiltersKeys } from './EsgAnalysisFilters/EsgAnalysisFilters';

type Props = {
  buildings: EsgAnalysisGetBuildingsPortfolioAndAddressFragment[];
};

export default function EsgAnalysisFilterBarBuildingFilter({ buildings }: Props) {
  const { t } = useTranslation();
  const { filters, setFilter, resetFilter } = useEsgAnalysisFilters();

  const mapToBuildingSelectOption = (
    building: EsgAnalysisGetBuildingsPortfolioAndAddressFragment,
  ): { value: number; label: string } => {
    return {
      value: building.id,
      label: building.address.street,
    };
  };

  const getBuildingsFromIds = (ids: number[]) => {
    return buildings.filter((building) => ids.includes(building.id)).map(mapToBuildingSelectOption);
  };

  const onBuildingSelected = (selectedBuilding: SelectWithSearchValue) => {
    const foundBuilding = buildings.find(
      (building: EsgAnalysisGetBuildingsPortfolioAndAddressFragment) => building.id === selectedBuilding.value,
    );
    if (!foundBuilding) {
      // Don't change building filters if the selected building is not found
      return;
    }

    setFilter(EsgAnalysisFiltersKeys.buildingIds, foundBuilding.id);
  };

  //@ts-ignore
  if (filters.buildingIds.length === 0) {
    return null;
  }

  return (
    <SelectMultiWithSearch
      name={t('General_Building')}
      //@ts-ignore
      options={getBuildingsFromIds(filters.buildingIds)}
      onSelect={onBuildingSelected}
      //@ts-ignore
      selectedValues={getBuildingsFromIds(filters.buildingIds)}
      sx={{ minWidth: 400 }}
      onResetAll={() => resetFilter(EsgAnalysisFiltersKeys.buildingIds)}
      noDataText={t('EsgAnalysisFilterBarBuildingFilter_NoBuildingsFound')}
    />
  );
}
