import { invoice_payer_type_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateInvoicePayerTypeEnum = (enumValue: invoice_payer_type_enum) => {
  switch (enumValue) {
    case invoice_payer_type_enum.OWNER:
      return <Trans i18nKey="Enum_InvoicePayerTypeEnum-OWNER" />;
    case invoice_payer_type_enum.TENANT:
      return <Trans i18nKey="Enum_InvoicePayerTypeEnum-TENANT" />;
  }
};

export const translateInvoicePayerTypeEnum_dynamic = (
  enumValue: invoice_payer_type_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case invoice_payer_type_enum.OWNER:
      return t('Enum_InvoicePayerTypeEnum-OWNER');
    case invoice_payer_type_enum.TENANT:
      return t('Enum_InvoicePayerTypeEnum-TENANT');
  }
};
