/* eslint-disable @typescript-eslint/ban-ts-comment */
import { IconProps, IconifyIcon } from '@iconify/react';
import { Box, CardHeader, Grid, LinearProgress, LinearProgressProps, Typography, useTheme } from '@mui/material';
import { DataCollectionBuildingWithSubBuildingsFragment } from '@predium/client-graphql';
import { climate_risk_type_enum } from '@predium/enums';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../components/Iconify';
import InfoTooltip from '../../../../components/InfoTooltip';

function Accuracy({ uncertainty }: { uncertainty: number }) {
  const { t } = useTranslation();

  /**
   * we have to define these inside the component, because we need to call them with the hook, otherwise the translation:sync won't pick them up as keys
   */
  const labels = {
    high: t('General_High'),
    medium: t('General_Medium'),
    low: t('General_Low'),
  };

  const AccuracyBox = ({
    label,
    icon,
    color,
  }: {
    label: string;
    icon: string | IconifyIcon;
    color: IconProps['color'];
  }) => (
    <Box>
      <Typography>{label}</Typography>
      <Iconify icon={icon} height={30} width={30} color={color} />
    </Box>
  );

  if (uncertainty <= 1) return <AccuracyBox label={labels.high} icon="mdi:speedometer" color="success.main" />;
  if (uncertainty <= 4) return <AccuracyBox label={labels.medium} icon="mdi:speedometer-medium" color="warning.main" />;
  return <AccuracyBox label={labels.low} icon="mdi:speedometer-slow" color="error.main" />;
}

function Risk({ risk }: { risk: number }) {
  const { t } = useTranslation();

  // same thing as above, see the comment there as to why its duplicated
  const labels = {
    high: t('General_High'),
    medium: t('General_Medium'),
    low: t('General_Low'),
  };

  const RiskBox = ({ label, color }: { label: string; color: LinearProgressProps['color'] }) => (
    <Box>
      <Typography component="label" sx={{ fontWeight: 'bold' }}>
        {label}
      </Typography>
      <Typography component="label" color="text.secondary">
        {' '}
        ({risk}%)
      </Typography>
      <LinearProgress variant="determinate" color={color} value={risk} sx={{ mt: 1 }} />
    </Box>
  );

  if (risk <= 33) return <RiskBox label={labels.low} color="success" />;
  if (risk <= 67) return <RiskBox label={labels.medium} color="warning" />;
  return <RiskBox label={labels.high} color="error" />;
}

type BuildingClimateRiskItemProps = {
  building: DataCollectionBuildingWithSubBuildingsFragment;
  riskType: climate_risk_type_enum;
  label: string | JSX.Element;
  info: ReactNode;
};

export default function BuildingClimateRiskItem({ building, riskType, label, info }: BuildingClimateRiskItemProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const data = building.climate_risks.filter((risk) => risk.type === riskType);

  const displayTimespan = (timespan?: string): string =>
    !timespan || timespan === 'Present' ? t('General_Current') : timespan;

  return (
    <Box sx={{ ml: 3 }}>
      <CardHeader
        sx={{ mb: 2 }}
        title={
          <Grid container>
            <Grid item>
              {label}
              <InfoTooltip text={info} maxwidth={450} />
            </Grid>
          </Grid>
        }
      />
      <Grid container sx={{ mb: 3 }}>
        <Grid item xs={12} md={4} sx={{ textAlign: 'left', pl: 3 }}>
          <Typography sx={{ color: 'text.secondary' }}>{t('General_Timespan')}</Typography>
        </Grid>
        <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
          <Typography sx={{ color: 'text.secondary' }}>{t('General_Risk')}</Typography>
        </Grid>
        <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
          <Typography sx={{ color: 'text.secondary' }}>{t('General_Accuracy')}</Typography>
        </Grid>
      </Grid>

      {data.map((elem) => (
        <Box
          key={elem.timespan}
          sx={{ border: 1, borderColor: theme.palette.grey[400], borderRadius: '16px', pt: 1, pb: 1, mb: 2 }}
        >
          <Grid container alignItems={'center'}>
            <Grid item xs={12} md={4} sx={{ textAlign: 'left', pl: 3 }}>
              {/*@ts-ignore */}
              <Typography sx={{ fontWeight: 'bold' }}>{displayTimespan(elem.timespan)}</Typography>
            </Grid>
            <Grid item xs={12} md={4} sx={{ textAlign: 'center', pl: 3, pr: 3 }}>
              <Risk risk={elem.value} />
            </Grid>
            <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
              <Accuracy uncertainty={elem.uncertainty} />
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
}
