import { graphql } from '@predium/client-graphql';

graphql(`
  fragment EnvelopeUnit on envelope {
    id
    area
    envelope_type_id
    insulation_material_name
    u_value
    insulation_thickness
    orientation
    inclination
  }
`);

graphql(`
  fragment EnergyConsumer on energy_consumer {
    energy_consumer_type_id
    energy_source_type_id
    energy_consumer_technology_type_id
  }
`);

graphql(`
  fragment EnergyPaths on energy_path {
    year
    co2_emissions
    energy_consumption
    energy_costs_total
    co2_costs_total
    efficiency_class_id
  }
`);

graphql(`
  fragment BuildingModel on building_model {
    building {
      id
      year_constructed
    }
    energy_final
    energy_primary
    energy_paths(order_by: { year: asc }) {
      ...EnergyPaths
    }
    envelope_units(order_by: { id: asc }) {
      ...EnvelopeUnit
    }
    energy_consumers {
      id
      energy_consumer_type_id
      energy_consumer_technology_type_id
      efficiency
    }
    energy_distributions {
      id
      heat_transfer_loss
      last_hydraulic_balancing
      last_balancing_set_to_never
      balancing_with_components
    }
    energy_systems {
      id
      energy_system_type_id
      energy_system_consumer_routes {
        id
        energy_consumer {
          ...EnergyConsumer
        }
        energy_final
        energy_source_type_id
        energy_distribution {
          id
          last_hydraulic_balancing
          last_balancing_set_to_never
          heat_transfer_loss
          balancing_with_components
        }
      }
      energy_system_producer_routes {
        energy_source_type_id
        energy_producer {
          nominal_power
        }
      }
    }
  }
`);

graphql(`
  fragment EnvelopeRenovation on renovation_envelope {
    new_envelope {
      id
      area
      envelope_type_id
      insulation_material_name
      u_value
      cost_per_m2
      insulation_lambda
      insulation_method_id
      insulation_thickness
      insulation_material_category_id
    }
    old_entity_id
    old_envelope {
      id
      building_model {
        envelope_units(order_by: { id: asc }) {
          ...EnvelopeUnit
        }
      }
    }
  }
`);

graphql(`
  fragment Action on action {
    id
    created_at
    implementation_to
    order
    renovations {
      renovation_type_id
      cost_total
      cost_total_predium
      cost_total_custom
      use_custom_cost
    }
    renovations_energy_consumer {
      new_energy_consumer {
        name
        energy_consumer_technology_type_id
        cost_per_m2
        efficiency
        energy_consumer_type_id
        energy_system_consumer_routes {
          energy_source_type_id
        }
      }
    }

    renovations_envelope {
      ...EnvelopeRenovation
    }

    renovations_energy_routes {
      new_energy_system_route {
        energy_source_type_id
      }
    }

    renovations_energy_producer {
      new_energy_producer {
        name
        nominal_power
        module_peak_power
        module_angle
        solar_active_area
        roof_area_ratio
        cost_per_m2
      }
    }

    building_models {
      ...BuildingModel
    }
  }
`);

graphql(`
  fragment ActionPlanningActionSubsidy on action_subsidy {
    id
    subsidy {
      id
      title
      categories {
        subsidy_category_type
      }
    }
    type
    value
    action {
      id
      renovations {
        cost_total
        cost_total_predium
        renovation_type_id
      }
    }
  }
`);

graphql(`
  fragment ModificationImpacts on ModificationImpact {
    co2_emissions {
      after
      before
      impact
    }
    final_energy {
      after
      before
      impact
    }
    primary_energy {
      after
      before
      impact
    }
    efficiency_class {
      before
      after
    }
    stranding_date {
      before
      after
      impact
    }
    energy_cost_total {
      after
      before
      impact
    }
    co2_cost_total {
      after
      before
      impact
    }
  }
`);

graphql(`
  fragment ActionImpactDataOutput on ActionImpactDataOutput {
    action_id
    total_renovation_cost
    total_subsidy_cost
    total_estimated_cost
    modification_impact {
      ...ModificationImpacts
    }
  }
`);

graphql(`
  fragment ActionPlanImpactDataOutput on ActionPlanImpactDataOutput {
    total_renovation_cost
    total_subsidy_cost
    total_estimated_cost
    modification_impact {
      ...ModificationImpacts
    }
  }
`);

graphql(`
  fragment ActionPlanMetrics on ActionPlanMetricsOutput {
    action_plan {
      ...ActionPlanImpactDataOutput
    }
    actions {
      ...ActionImpactDataOutput
    }
  }
`);

graphql(`
  fragment TargetPaths on target_path {
    value
    year
    target_path_template {
      name
      target_path_template_type_id
    }
  }
`);

graphql(`
  fragment ActionPlanningActionPlanActionPlan on action_plan {
    id
    created_at
    scenario {
      name
      id
      created_at
      portfolio {
        name
      }
      owner {
        first_name
        last_name
        id
      }
    }
    building {
      id
      monument_protection
      year_constructed
      address {
        street
        city
        country_id
        postal_code
      }
      areas(where: { area_type_id: { _eq: NUF } }) {
        value
        type_of_use_id
        area_type_id
        class_of_use_id
      }
      active_building_model {
        ...BuildingModel
      }
      sub_buildings {
        __typename
        subsidies {
          subsidy {
            ...DataCollectionSubBuildingSubBuildingSubsidy
          }
        }
      }
      target_paths(order_by: { year: asc }) {
        ...TargetPaths
      }
    }
  }
`);

graphql(`
  fragment ActionPlanningScenarioActionPlan on action_plan {
    id
    created_at
    building {
      address {
        street
        city
        postal_code
      }
      active_building_model {
        energy_paths(where: { year: { _eq: $year } }) {
          ...EnergyPaths
        }
      }
    }
    actions_aggregate {
      aggregate {
        count
        max {
          updated_at
        }
      }
    }
    actions(order_by: { order: desc }, limit: 1) {
      building_models(limit: 1) {
        energy_paths(order_by: { year: asc }) {
          efficiency_class_id
        }
      }
    }
  }
`);

graphql(`
  fragment ScenarioActionPlans on action_plan {
    id
    actions_aggregate {
      aggregate {
        count
        max {
          updated_at
        }
      }
    }
  }
`);

graphql(`
  fragment ActionPlanningScenariosScenario on scenario {
    description
    name
    portfolio {
      id
      name
    }
    id
    created_at
    owner {
      id
      first_name
      last_name
    }
    action_plans {
      ...ScenarioActionPlans
    }
  }
`);

graphql(`
  fragment ActionPlanningScenarioScenario on scenario {
    ...ActionPlanningScenariosScenario
    action_plans {
      ...ActionPlanningScenarioActionPlan
    }
  }
`);

graphql(`
  fragment CreateActionPlanModalBuilding on building {
    id
    address {
      street
      city
      postal_code
    }
    active_building_model {
      action_planning_available
      energy_paths(where: { year: { _eq: $year } }) {
        ...EnergyPaths
      }
    }
  }
`);

export const GET_ACTIONPLAN = graphql(`
  query ActionPlanningActionPlanGetActionPlan($actionPlanId: Int!) {
    action_plan_by_pk(id: $actionPlanId) {
      ...ActionPlanningActionPlanActionPlan
    }
  }
`);

export const GET_SUBSIDIES = graphql(`
  query ActionPlanningGetSubsidies($actionPlanId: Int!) {
    action_subsidy(where: { action: { action_plan_id: { _eq: $actionPlanId } } }) {
      ...ActionPlanningActionSubsidy
    }
  }
`);

export const GET_SCENARIO = graphql(`
  query ActionPlanningScenarioGetScenario($scenarioId: Int!, $year: Int!) {
    scenario_by_pk(id: $scenarioId) {
      ...ActionPlanningScenarioScenario
    }
  }
`);

export const GET_SCENARIOS = graphql(`
  query ActionPlanningScenariosAllScenarios {
    scenario {
      ...ActionPlanningScenariosScenario
    }
  }
`);

export const GET_BUILDINGS = graphql(`
  query CreateActionPlanModalGetBuildings(
    $where: building_bool_exp!
    $limit: Int!
    $offset: Int!
    $orderBy: [building_order_by!]
    $year: Int!
  ) {
    building(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
      __typename
      id
      address {
        __typename
        id
        street
        city
        postal_code
      }
      active_building_model {
        id
        action_planning_available
        energy_paths(where: { year: { _eq: $year } }) {
          ...EnergyPaths
        }
      }
    }
    building_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`);

export const EXPORT_ACTION_PLAN_PDF = graphql(`
  query ExportActionPlanPdf($actionPlanId: Int!, $title: String, $description: String) {
    getActionPlanPdf(actionPlanId: $actionPlanId, title: $title, description: $description) {
      body
    }
  }
`);

export const GET_ACTION_PLAN_METRICS = graphql(`
  query GetActionsPlanMetrics($actionPlanId: Int!) {
    getActionPlanMetrics(id: $actionPlanId) {
      ...ActionPlanMetrics
    }
  }
`);

export const GET_ACTIONS_FOR_ACTION_PLAN = graphql(`
  query GetActionsForActionPlan($actionPlanId: Int!) {
    action(where: { action_plan_id: { _eq: $actionPlanId } }, order_by: { order: asc }) {
      ...Action
    }
  }
`);
