// eslint-disable-next-line no-restricted-imports
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { PATHS } from './paths';

export const redirects = [
  {
    from: '/action-planning/actionplan/:id',
    to: PATHS.actionPlanning.scenarios,
  },
  {
    from: '/data-collection/building-draft/:id',
    to: PATHS.dataCollection.energyCertificateDraft,
  },
  {
    from: '/data-collection/building/:id/consumption/:consumptionId',
    to: PATHS.dataCollection.buildingConsumptionInvoice,
  },
  {
    from: '/data-collection/building/:id',
    to: PATHS.dataCollection.building,
  },
  {
    from: '/org/user/:id',
    to: PATHS.org.user,
  },
  {
    from: '/data-collection/emission-certificate/:id',
    to: PATHS.dataCollection.emissionCertificate,
  },
  {
    from: '/data-collection/emission-certificate-draft/:id',
    to: PATHS.dataCollection.emissionCertificateDraft,
  },
  {
    from: '/data-collection/emission-certificate-drafts',
    to: PATHS.dataCollection.emissionCertificateDrafts,
  },
];

export const Redirect = ({ redirect }: { redirect: (typeof redirects)[number] }) => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  return (
    <Navigate
      to={{
        pathname: redirect.to(params as any),
        search: searchParams.toString().replace('showTab', 'tab'),
      }}
      replace
    />
  );
};
