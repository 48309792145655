import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Typography } from '@mui/material';
import { GetSessionDataUserFragment } from '@predium/client-graphql';
import { role_enum } from '@predium/enums';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { FormProvider, RHFRadioButton, RHFTextField } from '../../../components/hook-form';
import PreDialog, { PreDialogBody } from '../../../components/presentations/PreDialog/PreDialog';
import { GET_USERS } from '../../../graphql/Report.queries';
import { CREATE_USER } from '../../../graphql/Users.mutations';
import usePosthogTracking from '../../../hooks/usePosthogTracking';

type Props = {
  open: boolean;
  onClose: () => void;
  users: GetSessionDataUserFragment[];
};

type FormValuesProps = {
  first_name: string;
  last_name: string;
  email: string;
  isAdmin: boolean;
  isUser: boolean;
};

export default function UserCreateDialog({ open, onClose, users }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { trackEvent } = usePosthogTracking();

  const [createUserMutation, { loading }] = useMutation(CREATE_USER, {
    onError: () => {
      enqueueSnackbar(t('UserCreateDialog_Snackbar-Error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
    onCompleted: () => {
      enqueueSnackbar(t('UserCreateDialog_Snackbar-Success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      onClose();
      reset();
    },
    refetchQueries: [{ query: GET_USERS }],
  });

  const NewUserSchema = Yup.object().shape({
    first_name: Yup.string().required(t('General_FirstNameIsRequired')),
    last_name: Yup.string().required(t('General_LastNameIsRequired')),
    email: Yup.string()
      .required(t('General_EmailIsRequired'))
      .email(t('General_InvalidEmailAddress'))
      .notOneOf(
        users.map((user) => user.email),
        t('General_UserAlreadyExists'),
      ),
    isAdmin: Yup.boolean().required(),
    isUser: Yup.boolean().required(),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewUserSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      isAdmin: false,
      isUser: true,
    },
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    const result = await createUserMutation({
      variables: {
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        role: data.isAdmin ? role_enum.ORG_ADMIN : role_enum.USER,
      },
    });
    if (result.errors?.length !== 0 || !result.errors) {
      trackEvent('USER_CREATED', {
        type: data.isAdmin ? role_enum.ORG_ADMIN : role_enum.USER,
      });
    }
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleChange = (field: 'isAdmin' | 'isUser') => {
    methods.setValue(field, true);
    methods.setValue(field === 'isAdmin' ? 'isUser' : 'isAdmin', false);
  };

  return (
    <PreDialog open={open} onClose={handleClose} fullWidth type="definedByChildren">
      <FormProvider
        methods={methods}
        onSubmit={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <PreDialogBody
          dialogtitle={t('General_CreateNewUser')}
          content={
            <>
              <Typography sx={{ textAlign: 'justify', mb: 3 }}></Typography>
              <RHFTextField name="first_name" label={t('General_FirstName')} sx={{ mb: 3 }} />
              <RHFTextField name="last_name" label={t('General_LastName')} sx={{ mb: 3 }} />
              <RHFTextField name="email" label={t('General_Email')} sx={{ mb: 3 }} trim />
              <Typography fontWeight={700} fontSize={16} sx={{ mb: 2 }}>
                {t('General_Role')}
              </Typography>
              <RHFRadioButton
                name="isUser"
                onChange={() => handleChange('isUser')}
                title={t('General_User')}
                subtitle={t('UserCreateDialog_UserRoleSubtitle')}
              />
              <RHFRadioButton
                name="isAdmin"
                onChange={() => handleChange('isAdmin')}
                title={t('General_Admin')}
                subtitle={t('UserCreateDialog_AdminRoleSubtitle')}
              />
            </>
          }
          actions={
            <>
              <Button variant="outlined" onClick={handleClose}>
                {t('General_Cancel')}
              </Button>
              <LoadingButton type="submit" variant="contained" loading={loading}>
                {t('General_Create')}
              </LoadingButton>
            </>
          }
        ></PreDialogBody>
      </FormProvider>
    </PreDialog>
  );
}
