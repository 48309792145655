/* eslint-disable @typescript-eslint/ban-ts-comment */
import { area_type_enum } from '@predium/enums';
import { fFloatingPoint, getAreaSum, getNetArea } from '@predium/utils';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import { PropsWithChildren, ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { UseFormReturn, useFormState } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { AreaFormValues } from '../types';

type Validation = {
  equation: string;
  equationLabel: string;
  fulfillValue: number;
  affectedAreas: { type: area_type_enum; label?: string; fulfillValue: number }[];
};

type TContext = {
  activeError: Validation | null;
  activeErrorIndex: number | null;
  errorsCount: number;

  getAreaError: (areaType: area_type_enum) => ReactNode;
  nextError: () => void;
  previousError: () => void;

  hasNextError: boolean;
  hasPreviousError: boolean;

  getEquationValueLabel: (equation: string) => string;
  triggerValidation: () => void;
};

const ValidationContext = createContext<TContext | null>(null);

const nrfEquation = 'NRF = NUF + VF + TF';
const bgfDINEquation = 'BGF = KGF + NRF';
const kgfNrfEquation = 'KGF + NRF = MFG_0 + MFG';
const bgfGIFEquation = 'BGF = MFG_0 + MFG';
const mfgEquation = 'MFG = MFG_1 + MFG_2';

/**
 * We have multiple equations that we need to validate, we need to check if the values are correct
 * and if not, we need to show the user the error and the affected areas.
 *
 * We only show one active error at a time, and we can navigate between them.
 *
 * We have 5 equations:
 * 1. NRF = NUF + VF + TF
 * 2. BGF = KGF + NRF
 * 3. KGF + NRF = MFG-0 + MFG
 * 4. BGF = MFG-0 + MFG
 * 5. MFG = MFG-1 + MFG-2
 *
 */
export const ValidationProvider = ({
  children,
  methods,
  handlePropagateFormState,
}: PropsWithChildren<{ methods: UseFormReturn<AreaFormValues>; handlePropagateFormState: VoidFunction }>) => {
  const [activeErrorIndex, setActiveErrorIndex] = useState<number | null>(null);
  const [activeErrors, setActiveErrors] = useState<Validation[]>([]);

  const { clearErrors: clearFormErrors, setError: setFormError, getValues } = methods;

  const getAreasByType = useCallback(() => {
    const areas = getValues('areas');
    return {
      ...mapValues(keyBy(areas, 'area_type_id'), (area) => area.value),
      NUF: getNetArea(areas.filter((area) => area.area_type_id === area_type_enum.NUF && !area.delete)),
      MFG_1: getAreaSum(areas.filter((area) => area.area_type_id === area_type_enum.MFG_1 && !area.delete)),
    } as Record<area_type_enum, number>;
  }, [getValues]);

  const getEquationValueLabel = useCallback(
    (equation: string) => {
      const areasByType = getAreasByType();

      Object.keys(areasByType).forEach((key) => {
        equation = equation.replace(key, areasByType[key as area_type_enum].toString());
      });

      equation = equation.replace('=', '≠');

      return equation;
    },
    [getAreasByType],
  );

  const getValidators = useCallback(() => {
    const areasByType = getAreasByType();

    return [
      {
        equation: nrfEquation,
        equationLabel: 'NRF = NUF + VF + TF',
        fulfillValue: fFloatingPoint(
          fFloatingPoint(areasByType.NRF) - fFloatingPoint(areasByType.NUF + areasByType.VF + areasByType.TF),
        ),
        affectedAreas: [
          {
            type: area_type_enum.NRF,
            fulfillValue: fFloatingPoint(areasByType.NUF + areasByType.VF + areasByType.TF),
          },
          {
            type: area_type_enum.NUF,
            fulfillValue: fFloatingPoint(areasByType.NRF - areasByType.VF - areasByType.TF),
          },
          {
            type: area_type_enum.VF,
            fulfillValue: fFloatingPoint(areasByType.NRF - areasByType.NUF - areasByType.TF),
          },
          {
            type: area_type_enum.TF,
            fulfillValue: fFloatingPoint(areasByType.NRF - areasByType.NUF - areasByType.VF),
          },
        ],
      },
      {
        equation: bgfDINEquation,
        equationLabel: 'BGF = KGF + NRF',
        fulfillValue: fFloatingPoint(
          fFloatingPoint(areasByType.BGF) - fFloatingPoint(areasByType.KGF + areasByType.NRF),
        ),
        affectedAreas: [
          {
            type: area_type_enum.BGF,
            fulfillValue: fFloatingPoint(areasByType.KGF + areasByType.NRF),
          },
          {
            type: area_type_enum.KGF,
            fulfillValue: fFloatingPoint(areasByType.BGF - areasByType.NRF),
          },
          {
            type: area_type_enum.NRF,
            fulfillValue: fFloatingPoint(areasByType.BGF - areasByType.KGF),
          },
        ],
      },
      {
        equation: kgfNrfEquation,
        equationLabel: 'KGF + NRF = MFG-0 + MFG',
        fulfillValue: fFloatingPoint(
          fFloatingPoint(areasByType.KGF + areasByType.NRF) - fFloatingPoint(areasByType.MFG_0 + areasByType.MFG),
        ),
        affectedAreas: [
          {
            type: area_type_enum.KGF,
            fulfillValue: fFloatingPoint(areasByType.MFG_0 + areasByType.MFG - areasByType.NRF),
          },
          {
            type: area_type_enum.NRF,
            fulfillValue: fFloatingPoint(areasByType.MFG_0 + areasByType.MFG - areasByType.KGF),
          },
          {
            type: area_type_enum.MFG_0,
            label: 'MFG-0',
            fulfillValue: fFloatingPoint(areasByType.KGF + areasByType.NRF - areasByType.MFG),
          },
          {
            type: area_type_enum.MFG,
            fulfillValue: fFloatingPoint(areasByType.KGF + areasByType.NRF - areasByType.MFG_0),
          },
        ],
      },
      {
        equation: bgfGIFEquation,
        equationLabel: 'BGF = MFG-0 + MFG',
        fulfillValue: fFloatingPoint(
          fFloatingPoint(areasByType.BGF) - fFloatingPoint(areasByType.MFG_0 + areasByType.MFG),
        ),
        affectedAreas: [
          {
            type: area_type_enum.MFG_0,
            label: 'MFG-0',
            fulfillValue: fFloatingPoint(areasByType.BGF - areasByType.MFG),
          },
          {
            type: area_type_enum.MFG,
            fulfillValue: fFloatingPoint(areasByType.BGF - areasByType.MFG_0),
          },
          {
            type: area_type_enum.BGF,
            fulfillValue: fFloatingPoint(areasByType.MFG_0 + areasByType.MFG),
          },
        ],
      },
      {
        equation: mfgEquation,
        equationLabel: 'MFG = MFG-1 + MFG-2',
        fulfillValue: fFloatingPoint(
          fFloatingPoint(areasByType.MFG) - fFloatingPoint(areasByType.MFG_1 + areasByType.MFG_2),
        ),
        affectedAreas: [
          {
            type: area_type_enum.MFG,
            fulfillValue: fFloatingPoint(areasByType.MFG_1 + areasByType.MFG_2),
          },
          {
            type: area_type_enum.MFG_1,
            label: 'MFG-1',
            fulfillValue: fFloatingPoint(areasByType.MFG - areasByType.MFG_2),
          },
          {
            type: area_type_enum.MFG_2,
            label: 'MFG-2',
            fulfillValue: fFloatingPoint(areasByType.MFG - areasByType.MFG_1),
          },
        ],
      },
    ] as Validation[];
  }, [getAreasByType]);

  const triggerFormError = useCallback(
    (hasError: boolean) => {
      if (hasError) {
        setFormError('equation', { type: 'custom' });
      } else {
        clearFormErrors('equation');
      }
    },
    [clearFormErrors, setFormError],
  );

  const triggerValidation = useCallback(() => {
    const validators = getValidators();

    const newErrors = validators.filter((validator) => validator.fulfillValue !== 0);

    //@ts-ignore
    const newErrorIndex = newErrors.findIndex((error) => error.equation === activeErrors[activeErrorIndex]?.equation);

    setActiveErrors(newErrors);
    setActiveErrorIndex(newErrorIndex !== -1 ? newErrorIndex : newErrors.length ? 0 : null);

    triggerFormError(newErrors.length > 0);
  }, [activeErrorIndex, activeErrors, getValidators, triggerFormError]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handlePropagateFormState(), [activeErrors]);

  const formState = useFormState({ control: methods.control, name: 'equation' });

  useEffect(() => {
    if (!formState.errors.equation && activeErrors.length > 0) {
      setActiveErrors([]);
      setActiveErrorIndex(null);
    }
  }, [activeErrors.length, formState]);

  const hasNextError = activeErrorIndex !== null && activeErrorIndex < activeErrors.length - 1;

  const hasPreviousError = activeErrorIndex !== null && activeErrorIndex > 0;

  const nextError = () => {
    //@ts-ignore
    setActiveErrorIndex(activeErrorIndex + 1);
  };

  const previousError = () => {
    //@ts-ignore
    setActiveErrorIndex(activeErrorIndex - 1);
  };

  //@ts-ignore
  const activeError = activeErrors[activeErrorIndex];

  const getAreaError = useCallback(
    (areaType: area_type_enum) => {
      if (!activeError) {
        return null;
      }

      //@ts-ignore
      const areaError = activeError.affectedAreas.find((area) => area.type === areaType);

      if (!areaError) {
        return null;
      }

      const areaLabel = areaError.label || areaType;
      const areaFulfillValue = areaError.fulfillValue;
      const equation = activeError.equationLabel;

      if (areaFulfillValue > 0) {
        return (
          <Trans
            i18nKey="DataCollectionAreas_EquationErrorSingleAreaMore"
            values={{
              area: areaLabel,
              value: areaFulfillValue,
              equation,
            }}
            components={{ bold: <strong />, sup: <sup /> }}
          />
        );
      }

      return (
        <Trans
          i18nKey="DataCollectionAreas_EquationErrorSingleAreaLess"
          values={{ equation }}
          components={{ bold: <strong /> }}
        />
      );
    },
    [activeError],
  );

  return (
    <ValidationContext.Provider
      value={{
        errorsCount: activeErrors.length,
        activeErrorIndex,
        activeError,
        getAreaError,
        nextError,
        previousError,
        hasNextError,
        hasPreviousError,
        getEquationValueLabel,
        triggerValidation,
      }}
    >
      {children}
    </ValidationContext.Provider>
  );
};

export const useValidation = () => {
  const context = useContext(ValidationContext);

  if (!context) {
    throw new Error('useValidation must be used within a ValidationProvider');
  }

  return context;
};
