import { MenuItem, Stack, Typography } from '@mui/material';
import { data_source_type_enum, envelope_type_enum } from '@predium/enums';
import { useMemo } from 'react';
import { useFieldArray, useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../../components/Iconify';
import { createEnvelopeDefaultValues } from '../../BuildingEnvelope';
import {
  areFieldArraysDirty,
  countApproximatedEnvelopes,
  duplicateComponent,
  generateFieldName,
  getEnvelopeIcon,
} from '../Envelope.util';
import { EmptyEnvelopeUnit } from '../EnvelopeUnit/EmptyEnvelopeUnit';
import { EnvelopeDoorUnit } from '../EnvelopeUnit/EnvelopeDoorUnit';
import { EnvelopeOtherUnit } from '../EnvelopeUnit/EnvelopeOtherUnit';
import { EnvelopeWindowUnit } from '../EnvelopeUnit/EnvelopeWindowUnit';
import { EnvelopeProps, EnvelopeSectionWrapper } from './EnvelopeSectionWrapper';

export function MiddleEnvelopesSection({ title, icon }: EnvelopeProps) {
  const { t } = useTranslation();
  const { control, watch, resetField, setValue } = useFormContext();
  const { dirtyFields } = useFormState({
    control,
  });

  const { fields: windowFields, append: appendWindow } = useFieldArray({
    control,
    name: envelope_type_enum.WINDOW,
  });

  const { fields: doorFields, append: appendDoor } = useFieldArray({
    control,
    name: envelope_type_enum.DOOR,
  });

  const { fields: wallsFields, append: appendWall } = useFieldArray({
    control,
    name: envelope_type_enum.WALL,
  });

  const totalTabulaEnvelopes = useMemo(() => {
    return (
      countApproximatedEnvelopes(windowFields) +
      countApproximatedEnvelopes(doorFields) +
      countApproximatedEnvelopes(wallsFields)
    );
  }, [windowFields, doorFields, wallsFields]);

  const totalEnvelopes = useMemo(() => {
    return windowFields.length + doorFields.length + wallsFields.length;
  }, [windowFields, doorFields, wallsFields]);

  const watchWindowFieldsArray = watch(envelope_type_enum.WINDOW);
  const controlledWindowFields = windowFields.map((field, index) => ({
    ...field,
    ...watchWindowFieldsArray[index],
  }));

  const watchDoorFieldArray = watch(envelope_type_enum.DOOR);
  const controlledDoorFields = doorFields.map((field, index) => ({
    ...field,
    ...watchDoorFieldArray[index],
  }));

  const watchWallsFieldArray = watch(envelope_type_enum.WALL);
  const controlledWallsFields = wallsFields.map((field, index) => ({
    ...field,
    ...watchWallsFieldArray[index],
  }));

  const resetFieldArray = () => {
    resetField(envelope_type_enum.WINDOW);
    resetField(envelope_type_enum.DOOR);
    resetField(envelope_type_enum.WALL);
  };

  const isFieldArrayDirty = areFieldArraysDirty(dirtyFields, [
    envelope_type_enum.WINDOW,
    envelope_type_enum.DOOR,
    envelope_type_enum.WALL,
  ]);

  const totalWalls = controlledWallsFields.length;
  const totalWindows = controlledWindowFields.length;
  const totalDoors = controlledDoorFields.length;

  const addWallEnvelope = () => {
    appendWall(createEnvelopeDefaultValues(envelope_type_enum.WALL), {
      focusName: generateFieldName(envelope_type_enum.WALL, totalWalls).areaFieldName,
    });
  };

  const addWindowEnvelope = () => {
    appendWindow(createEnvelopeDefaultValues(envelope_type_enum.WINDOW), {
      focusName: generateFieldName(envelope_type_enum.WINDOW, totalWindows).areaFieldName,
    });
  };

  const addDoorEnvelope = () => {
    appendDoor(createEnvelopeDefaultValues(envelope_type_enum.DOOR), {
      focusName: generateFieldName(envelope_type_enum.DOOR, totalDoors).areaFieldName,
    });
  };

  const removeEnvelope = (index: number, type: envelope_type_enum) => {
    setValue(generateFieldName(type, index).deleteFieldName, true, {
      shouldDirty: true,
    });
    setValue(generateFieldName(type, index).dataSourceTypeFieldName, data_source_type_enum.MANUAL);
  };

  return (
    <EnvelopeSectionWrapper
      title={title}
      icon={icon}
      totalTabulaEnvelopes={totalTabulaEnvelopes}
      totalEnvelopes={totalEnvelopes}
      resetSectionEnvelopes={resetFieldArray}
      isSectionDirty={isFieldArrayDirty}
      actions={
        <>
          <MenuItem onClick={addWallEnvelope}>
            <Iconify icon={getEnvelopeIcon(envelope_type_enum.WALL)} />
            {t('General_ExteriorWall')}
          </MenuItem>
          <MenuItem onClick={addWindowEnvelope}>
            <Iconify icon={getEnvelopeIcon(envelope_type_enum.WINDOW)} />
            {t('General_Window_one')}
          </MenuItem>
          <MenuItem onClick={addDoorEnvelope}>
            <Iconify icon={getEnvelopeIcon(envelope_type_enum.DOOR)} />
            {t('General_Door_one')}
          </MenuItem>
        </>
      }
    >
      <>
        {controlledWallsFields.map((data, index) => {
          return (
            <EnvelopeOtherUnit
              data={data}
              type={envelope_type_enum.WALL}
              title={`${t('General_ExteriorWall')} ${controlledWallsFields.length === 1 ? '' : index + 1}`}
              key={index}
              index={index}
              handleCopy={() =>
                appendWall(duplicateComponent(controlledWallsFields[index]), {
                  focusName: generateFieldName(envelope_type_enum.WALL, totalWalls).areaFieldName,
                })
              }
              handleRemove={() => removeEnvelope(index, envelope_type_enum.WALL)}
            />
          );
        })}

        {controlledWindowFields.map((data, index) => {
          return (
            <EnvelopeWindowUnit
              title={t('General_Window-WithIndex', { index: controlledWindowFields.length === 1 ? '' : index + 1 })}
              data={data}
              key={index}
              index={index}
              handleCopy={() =>
                appendWindow(duplicateComponent(controlledWindowFields[index]), {
                  focusName: generateFieldName(envelope_type_enum.WINDOW, totalWindows).areaFieldName,
                })
              }
              handleRemove={() => removeEnvelope(index, envelope_type_enum.WINDOW)}
            />
          );
        })}

        {controlledDoorFields.map((data, index) => {
          return (
            <EnvelopeDoorUnit
              title={t('General_Door-WithIndex', { index: controlledDoorFields.length === 1 ? '' : index + 1 })}
              data={data}
              key={index}
              index={index}
              handleCopy={() =>
                appendDoor(duplicateComponent(controlledDoorFields[index]), {
                  focusName: generateFieldName(envelope_type_enum.DOOR, totalDoors).areaFieldName,
                })
              }
              handleRemove={() => removeEnvelope(index, envelope_type_enum.DOOR)}
            />
          );
        })}

        {totalEnvelopes === 0 && (
          <Stack direction={'row'} alignItems={'center'} mt={3} mb={1}>
            <EmptyEnvelopeUnit
              title={t('General_ExteriorWall')}
              icon={getEnvelopeIcon(envelope_type_enum.WALL)}
              handleClick={addWallEnvelope}
            />
            <Typography sx={{ mx: 1 }}>{t('General_Or')}</Typography>
            <EmptyEnvelopeUnit
              title={t('General_Window_one')}
              icon={getEnvelopeIcon(envelope_type_enum.WINDOW)}
              handleClick={addWindowEnvelope}
            />
            <Typography sx={{ mx: 1 }}>{t('General_Or')}</Typography>
            <EmptyEnvelopeUnit
              title={t('General_Door_one')}
              icon={getEnvelopeIcon(envelope_type_enum.DOOR)}
              handleClick={addDoorEnvelope}
            />
          </Stack>
        )}
      </>
    </EnvelopeSectionWrapper>
  );
}
