import { language_enum } from '@predium/enums';
import { i18n } from 'i18next';
import { setI18n } from 'react-i18next';

/**
 * Why is this necessary?
 * Apparently, after bundling client/ using vite, two react-i18next instances are created - one for the app, one for this library.
 * Thus, the library does not have access to the i18n instance of the app and cannot translate anything.
 *
 * vite.config.ts:build.rollupOptions.external does not work for some reason.
 *
 * Thus, the next best way to fix this manually setting the i18n instance of the app for this library.
 *
 * TODO PRE-2110: evaluate this solution and figure out if there is a better way to do this.
 */
export const initLibraryWithI18n = (appI18n: i18n) => {
  setI18n(appI18n);
};

/**
 *  Supported IETF language tags
 */
export const getLanguageLocale = (language: language_enum): string => {
  switch (language) {
    case language_enum.deDE:
      return 'de-DE';

    case language_enum.enGB:
      return 'en-GB';

    default:
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = language;
      throw new Error(`Unhandled locale: ${exhaustiveCheck}`);
  }
};
