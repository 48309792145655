import { country_enum, country_state_enum, energy_source_type_enum } from '@predium/enums';
import isNil from 'lodash/isNil';
import {
  crremNormalizedDistrictHeating,
  crremNormalizedElectricity,
  crremNormalizedGasOilOther,
  getRegionCode as getCRREMRegionCode,
} from './crrem-normalized/crrem-normalized.helper';
import { CRREMEmissionFactorProvider } from './crrem.emission-factor-provider';
import { EmissionFactorProvider } from './emission-factor-provider.type';

// Custom DistrictHeatingLookup specific postal codes
// TODO: PRE-2062 this code has to be removed and will be replaced by the release of custom emission factors
//  with emission certificates
function WohrBauerDistrictHeatingLookup(postalCode: string): number | undefined {
  switch (postalCode) {
    // Wohr und Bauer
    case '80807': {
      return 0.066;
    }
    case '80539': {
      return 0.066;
    }
    case '81671': {
      return 0.066;
    }
    case '70174': {
      return 0.0;
    }
    case '85540': {
      return 0.052;
    }
    case '60486': {
      return 0.0654;
    }
    // Bayerische Hausbau
    case '81925': {
      return 0.066;
    }
    case '80333': {
      return 0.066;
    }
    case '80335': {
      return 0.066;
    }
    case '80469': {
      return 0.066;
    }
    case '80331': {
      return 0.066;
    }
    case '80339': {
      return 0.066;
    }
    case '81667': {
      return 0.066;
    }
    case '80636': {
      return 0.066;
    }
    case '81669': {
      return 0.066;
    }
    case '80337': {
      return 0.066;
    }
    case '81541': {
      return 0.066;
    }
    case '10787': {
      return 0.0556;
    }
    case '10623': {
      return 0.0556;
    }
  }
}

function CustomElectricityLookup(postalCode: string, sourceType: energy_source_type_enum): number | undefined {
  // Check if the source type is electricity green or electricity mix
  if (![energy_source_type_enum.ELECTRICITY_GREEN, energy_source_type_enum.ELECTRICITY_MIX].includes(sourceType)) {
    return undefined;
  }

  switch (postalCode) {
    // Wohr und Bauer
    case '81671': {
      return sourceType === energy_source_type_enum.ELECTRICITY_MIX ? 0.19 : undefined;
    }
    // Proximus
    case '55294': {
      return sourceType === energy_source_type_enum.ELECTRICITY_GREEN ? 0 : undefined;
    }
    case '35683': {
      return sourceType === energy_source_type_enum.ELECTRICITY_GREEN ? 0 : undefined;
    }
    case '79115': {
      return sourceType === energy_source_type_enum.ELECTRICITY_GREEN ? 0 : undefined;
    }
    case '65239': {
      return sourceType === energy_source_type_enum.ELECTRICITY_GREEN ? 0 : undefined;
    }
    case '67657': {
      return sourceType === energy_source_type_enum.ELECTRICITY_MIX ? 0.227 : undefined;
    }
    case '66849': {
      return sourceType === energy_source_type_enum.ELECTRICITY_GREEN ? 0 : undefined;
    }
    case '78315': {
      return sourceType === energy_source_type_enum.ELECTRICITY_GREEN ? 0 : undefined;
    }
    case '83022': {
      return sourceType === energy_source_type_enum.ELECTRICITY_MIX ? 0.248 : undefined;
    }
  }
}

export const CrremNormalizedEmissionFactorProvider: EmissionFactorProvider = {
  getEmissionFactor({
    energySourceType,
    year,
    country,
    countryState,
    postalCode,
  }: {
    energySourceType: energy_source_type_enum;
    year: number;
    country: country_enum;
    countryState: country_state_enum;
    postalCode: string;
  }): number {
    const regionCode = getCRREMRegionCode(country, postalCode);
    const crremBaseEmissionFactor = CRREMEmissionFactorProvider.getEmissionFactor({
      energySourceType,
      year: 2020,
      country,
      countryState,
      postalCode,
    });
    switch (energySourceType) {
      case energy_source_type_enum.FUEL_OIL:
      case energy_source_type_enum.NATURAL_GAS:
      case energy_source_type_enum.LPG:
      case energy_source_type_enum.COAL:
      case energy_source_type_enum.LIGNITE:
      case energy_source_type_enum.WOODEN_PELLETS:
      case energy_source_type_enum.WOOD:
      case energy_source_type_enum.UNSPECIFIED:
      case energy_source_type_enum.BIO_GAS: {
        return crremBaseEmissionFactor * crremNormalizedGasOilOther(year, country, regionCode);
      }
      case energy_source_type_enum.ELECTRICITY_MIX:
      case energy_source_type_enum.ELECTRICITY_GREEN:
      case energy_source_type_enum.SOLAR: {
        const crremBaseEmissionFactorElectricity =
          CustomElectricityLookup(postalCode, energySourceType) ?? crremBaseEmissionFactor;

        const electricity = CustomElectricityLookup(postalCode, energySourceType);
        // check if the postal code is in the custom lookup and re-normalize the emission factor so that the factor of year 2024 is the custom factor

        if (!isNil(electricity)) {
          if (electricity === 0) {
            return 0;
          }

          return (
            (crremBaseEmissionFactorElectricity * crremNormalizedElectricity(year, regionCode, country) * electricity) /
            (crremBaseEmissionFactorElectricity * crremNormalizedElectricity(2024, regionCode, country))
          );
        } else {
          return (
            crremBaseEmissionFactorElectricity *
            crremNormalizedElectricity(
              energySourceType === energy_source_type_enum.ELECTRICITY_GREEN ? 2050 : year, // if the energy source is electricity green, use the 2050 factor
              regionCode,
              country,
            )
          );
        }
      }
      case energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE:
      case energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE:
      case energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL:
      case energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS:
      case energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS:
      case energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL: {
        const crremBaseEmissionFactorElectricityCountry = CRREMEmissionFactorProvider.getEmissionFactor({
          energySourceType: energy_source_type_enum.ELECTRICITY_MIX,
          year:
            [
              energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE,
              energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE,
            ].indexOf(energySourceType) !== -1
              ? 2050 // if the energy source is renewable, use the 2050 factor
              : year,
          country,
          countryState,
          postalCode,
        });
        // Normalize the emission factor for the country by the UK Government GHG Conversion Factors for Company Reporting according to CRREM
        const crremBaseEmissionFactorElectricityUK = CRREMEmissionFactorProvider.getEmissionFactor({
          energySourceType: energy_source_type_enum.ELECTRICITY_MIX,
          year: 2020,
          country: 'UK' as unknown as country_enum,
          countryState,
          postalCode,
        });
        const crremNormalizedDistrictHeatingFactor =
          WohrBauerDistrictHeatingLookup(postalCode) ??
          (crremBaseEmissionFactor * crremBaseEmissionFactorElectricityCountry) / crremBaseEmissionFactorElectricityUK;

        const districtHeating = WohrBauerDistrictHeatingLookup(postalCode);
        // check if the postal code is in the custom lookup and re-normalize the emission factor so that the factor of year 2024 is the custom factor
        if (districtHeating) {
          return (
            (crremNormalizedDistrictHeatingFactor *
              crremNormalizedDistrictHeating(year, regionCode, country) *
              districtHeating) /
            (crremNormalizedDistrictHeatingFactor * crremNormalizedDistrictHeating(2024, regionCode, country))
          );
        } else {
          return (
            crremNormalizedDistrictHeatingFactor *
            crremNormalizedDistrictHeating(
              [
                energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE,
                energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE,
              ].indexOf(energySourceType) !== -1
                ? 2050 // if the energy source is renewable, use the 2050 factor
                : year, // otherwise use the year factor
              regionCode,
              country,
            )
          );
        }
      }
      default: {
        const exhaustiveCheck: never = energySourceType;
        throw new Error(`Unhandled energy_source_type_enum ${energySourceType}: ${exhaustiveCheck}`);
      }
    }
  },
};
