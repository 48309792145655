import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { energy_source_type_enum } from '@predium/enums';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../components/Iconify';
import SubBuildingConsumptionDraftConsumptionTypeSelect from './SubBuildingConsumptionDraftConsumptionTypeSelect';
import ConsumptionTypeInputs from './SubBuildingConsumptionTypeInputs';

type Props = {
  id: string;
  index: number;
  limitEnergySourcesBy: energy_source_type_enum[];
  disabled?: boolean;
  isSingleConsumption?: boolean;
  remove: (index: number) => void;
};

function SingleConsumptionDraftBox({
  id,
  index,
  limitEnergySourcesBy,
  remove,
  disabled,
  isSingleConsumption = false,
}: Props) {
  const { t } = useTranslation();

  const { watch } = useFormContext();

  /**
   * In case the select is empty a more detailed variant is displayed that does behave the same way as the "standard" select when a value is displayed.
   */
  const isEmpty = !watch(`consumptionDrafts.${index}.consumptionTypeId`);

  return (
    <Box
      key={id}
      sx={
        isEmpty
          ? undefined
          : {
              p: 2,
              border: '1px solid',
              borderColor: 'grey.300',
              borderRadius: 2,
              backgroundColor: 'grey.100',
            }
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={isSingleConsumption || isEmpty ? 12 : 10}>
          <SubBuildingConsumptionDraftConsumptionTypeSelect
            index={index}
            isEmpty={isEmpty}
            disabled={disabled}
            limitEnergySourcesBy={limitEnergySourcesBy}
            remove={() => remove(index)}
            isSingleConsumption={isSingleConsumption}
          />
        </Grid>

        {!isSingleConsumption && !isEmpty && (
          <Grid item xs={2}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Tooltip title={t('DataCollection_RemoveConsumptionButton-tooltip')} placement="top" arrow>
                <IconButton onClick={() => remove(index)}>
                  <Iconify icon={'charm:cross'} width={24} height={24} />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        )}

        {!isEmpty && <ConsumptionTypeInputs index={index} disabled={disabled} />}
      </Grid>
    </Box>
  );
}

export default SingleConsumptionDraftBox;
