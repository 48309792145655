import { BoxProps } from '@mui/material';
import { Theme, alpha, styled, useTheme } from '@mui/material/styles';
import { efficiency_class_enum } from '@predium/enums';
import { translateEfficiencyClassEnum } from '@predium/i18n/client';

type LabelVariant = 'filled' | 'outlined' | 'ghost';

const RootStyle = styled('span')(
  ({
    theme,
    ownerState,
  }: {
    theme: Theme;
    ownerState: {
      efficiencyClass: efficiency_class_enum;
      variant: LabelVariant;
    };
  }) => {
    const isLight = theme.palette.mode === 'light';
    const { efficiencyClass, variant } = ownerState;
    const styleFilled = (color: efficiency_class_enum) => ({
      color: theme.palette.energyEfficiencyClassColors[color].darker,
      backgroundColor: theme.palette.energyEfficiencyClassColors[color].light,
    });

    const styleOutlined = (color: efficiency_class_enum) => ({
      color: theme.palette.energyEfficiencyClassColors[color].main,
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.energyEfficiencyClassColors[color].main}`,
    });

    const styleGhost = (color: efficiency_class_enum) => ({
      // @ts-ignore must extend Palette type for this to be typesafe. Is it worth the effort?
      color: theme.palette[color][isLight ? 'dark' : 'light'],
      // @ts-ignore
      backgroundColor: alpha(theme.palette[color].main, 0.16),
    });

    return {
      height: 22,
      minWidth: 22,
      lineHeight: 0,
      borderRadius: 6,
      cursor: 'default',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      display: 'inline-flex',
      justifyContent: 'center',
      padding: theme.spacing(0, 1),
      color: theme.palette.grey[800],
      fontSize: theme.typography.pxToRem(12),
      fontFamily: theme.typography.fontFamily,
      backgroundColor: theme.palette.grey[300],
      fontWeight: theme.typography.fontWeightBold,

      ...(efficiencyClass !== efficiency_class_enum.UNKNOWN && efficiencyClass !== efficiency_class_enum.NOT_APPLICABLE
        ? {
            ...(variant === 'filled' && { ...styleFilled(efficiencyClass) }),
            ...(variant === 'outlined' && { ...styleOutlined(efficiencyClass) }),
            ...(variant === 'ghost' && { ...styleGhost(efficiencyClass) }),
          }
        : {
            ...(variant === 'outlined' && {
              backgroundColor: 'transparent',
              color: theme.palette.text.primary,
              border: `1px solid ${theme.palette.grey[500_32]}`,
            }),
            ...(variant === 'ghost' && {
              color: isLight ? theme.palette.text.secondary : theme.palette.common.white,
              backgroundColor: theme.palette.grey[500_16],
            }),
          }),
    };
  },
);

interface EfficiencyClassLabelProps extends BoxProps {
  efficiencyClass: efficiency_class_enum;
  variant?: LabelVariant;
}

export default function EfficiencyClassLabel({
  efficiencyClass: givenEfficiencyClass,
  variant = 'filled',
  children,
  sx,
}: EfficiencyClassLabelProps) {
  const theme = useTheme();

  return (
    <RootStyle ownerState={{ efficiencyClass: givenEfficiencyClass, variant }} sx={sx} theme={theme}>
      <div>
        {translateEfficiencyClassEnum(givenEfficiencyClass)}
        {children}
      </div>
    </RootStyle>
  );
}
