/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import Container from '@mui/material/Container';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { DelayedLoading } from '../../components/Loading';
import Page from '../../components/Page';
import { GET_REPORT, GET_SECTIONS_ANSWER_COUNT, GET_SECTIONS_PROGRESS } from '../../graphql/Report.queries';
import { PATHS, SEARCH_PARAMS, useTypeSafeSearchParams } from '../../routes';
import AcceptAllAutofill from '../../sections/Reporting/Report/AcceptAllAutofill';
import DeleteReport from '../../sections/Reporting/Report/DeleteReport';
import ReportActionMenu from '../../sections/Reporting/Report/ReportActionMenu';
import ReportComplete from '../../sections/Reporting/Report/ReportComplete';
import EvidenceExplorer from '../../sections/Reporting/Report/ReportEvidence/EvidenceExplorer/EvidenceExplorer';
import ReportIntroduction, { ReportIntroVariants } from '../../sections/Reporting/Report/ReportIntroduction';
import ReportRootSectionOrigin from '../../sections/Reporting/Report/ReportRootSectionOrigin';
import ReportHead from '../../sections/Reporting/ReportHead/ReportHead';
import { ReportDC } from '../../sections/Reporting/ReportingDataClasses/Report.dc';
import { getTemplateMetadataForTemplateTitle } from '../../sections/Reporting/ReportingDataClasses/ReportTemplate.dc';

export default function ReportingReport() {
  const { t } = useTranslation();

  const [isIntroShown, setIsIntroShown] = useState(false);
  const [reportToDelete, setReportToDelete] = useState<ReportDC | null>(null);
  const [introVariant, setIntroVariant] = useState<ReportIntroVariants>('common');

  const { id } = useParams();
  const { searchParams } = useTypeSafeSearchParams(SEARCH_PARAMS.report);

  const { data } = useQuery(GET_REPORT, {
    //@ts-ignore
    variables: { id: parseInt(id) },
  });
  const { data: sectionsAnswerData } = useQuery(GET_SECTIONS_ANSWER_COUNT, {
    //@ts-ignore
    variables: { reportId: parseInt(id) },
  });
  const { data: sectionsProgressData } = useQuery(GET_SECTIONS_PROGRESS, {
    //@ts-ignore
    variables: { reportId: parseInt(id) },
  });

  const report = useMemo(
    () =>
      data && sectionsAnswerData && sectionsProgressData
        ? new ReportDC(
            //@ts-ignore
            data.report_by_pk,
            sectionsAnswerData.report_answer,
            sectionsProgressData.report_section_progress,
          )
        : undefined,
    [data, sectionsAnswerData, sectionsProgressData],
  );

  useEffect(() => {
    // If the report has autofill wait for the user to accept or skip autofill (show autofill or common intro).
    // Otherwise continue here by showing the common intro.
    if (report?.hasAutofillQuestions === false) {
      setIsIntroShown(true);
    }
  }, [report?.hasAutofillQuestions]);

  if (!report) return <DelayedLoading />;

  const type = report.report_template.title;
  const readableTitle = getTemplateMetadataForTemplateTitle(type).readableTitle;
  const exportTemplates = [report.report_template.excel_template_path ? 'excel' : null];

  return (
    <Page title={t('ReportingReport_Title', { reportTitle: readableTitle })}>
      <Container maxWidth={'lg'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }} id="report-intro-autofill-step-3">
          <HeaderBreadcrumbs
            heading={t('ReportingReport_Title', { reportTitle: readableTitle })}
            links={[{ name: t('Reporting_Reports'), href: PATHS.reporting.root() }, { name: t('Reporting_Report') }]}
            // TODO: Add infoText
          />

          <ReportIntroduction isShown={isIntroShown} variant={introVariant} />

          <Box sx={{ my: 4, display: 'flex', alignItems: 'center', gap: 1, height: 48 }}>
            <EvidenceExplorer reportId={report.id} />
            <AcceptAllAutofill
              reportId={report.id}
              initialOpen={searchParams.init && report.hasAutofillQuestions}
              title={t('ReportingReport_AutofillSuggestionsAvailable')}
              description={t('ReportingReport_Description')}
              cancelButtonText={t('General_Skip')}
              displayIntroDialog={(introVariantToShow: ReportIntroVariants) => {
                setIntroVariant(introVariantToShow);
                setIsIntroShown(true);
              }}
            />
            {/*@ts-ignore */}
            <ReportComplete title={readableTitle} report={report} />
            <ReportActionMenu
              reportId={report.id}
              reportTemplateType={type}
              showDeleteReport={() => setReportToDelete(report)}
              //@ts-ignore
              exportTemplates={exportTemplates}
              completed={report.completed}
            />
          </Box>
        </Box>

        <ReportHead report={report} />

        <ReportRootSectionOrigin report={report} />
      </Container>

      <DeleteReport report={reportToDelete} closeDeleteReportModal={() => setReportToDelete(null)} />
    </Page>
  );
}
