import { Alert, Box, Button, Card, Divider, Grid, IconButton, Link, MenuItem, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  DataCollectionSubBuildingSubBuildingSubsidyFragment,
  action_subsidy_value_type_enum,
} from '@predium/client-graphql';
import { fCurrencyTrimmed } from '@predium/utils';
import { useEffect } from 'react';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import Iconify from '../../../../../../../components/Iconify';
import RHFInputWithSelect from '../../../../../../../components/hook-form/RHFInputWithSelect';
import { useLanguage } from '../../../../../../../provider/LanguageProvider';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '../../../../../../../utils/formatTime';
import { AddSubsidyFormValueProps } from '../AddSubsidyModal';
import { SubsidyTypeBadge } from './SubsidyTypeBadge';

export default function SelectedSubsidies({
  subBuildingSubsidies,
  selectedSubsidies,
  actionCost,
  deselectSubsidy,
}: {
  selectedSubsidies: FieldArrayWithId<AddSubsidyFormValueProps, 'selectedSubsidies', 'id'>[];
  subBuildingSubsidies: DataCollectionSubBuildingSubBuildingSubsidyFragment[];
  actionCost: number;
  deselectSubsidy: (index: number) => void;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  if (selectedSubsidies.length === 0) {
    return <EmptySelection />;
  }

  return (
    <Stack direction={'column'} spacing={2} sx={{ height: '100%' }}>
      <Alert
        severity="info"
        color="info"
        // overriding the info theme, will be changed in the future, currently the info color is a blueish color
        // @TODO: PRE-1995
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          color: theme.palette.text.primary,
          '& .MuiAlert-icon': {
            color: theme.palette.grey[600],
          },
        }}
      >
        {t('ActionPlanningSubsidiesSelectedSubsidies_InfoAlert')}
      </Alert>
      {selectedSubsidies.map((item, index) => {
        const selectedSubsidy = subBuildingSubsidies.find((subsidy) => subsidy.id === item.subsidyId);

        // This should never happen, as you cannot select a subsidy that is not part of the list
        if (!selectedSubsidy) {
          return null;
        }

        return (
          <Stack key={item.subsidyId} spacing={2} sx={{ pt: index > 0 ? 2 : 0, pb: 3 }}>
            <Typography variant="subtitle1" fontWeight={700}>
              {t('ActionPlanningSubsidiesSelectedSubsidies_Title', { index: index + 1 })}
            </Typography>
            <SelectedSubsidy
              index={index}
              selectedSubsidy={selectedSubsidy}
              actionCost={actionCost}
              onDelete={() => deselectSubsidy(index)}
            />
          </Stack>
        );
      })}
    </Stack>
  );
}

function SelectedSubsidy({
  index,
  selectedSubsidy,
  actionCost,
  onDelete,
}: {
  index: number;
  selectedSubsidy: DataCollectionSubBuildingSubBuildingSubsidyFragment;
  actionCost: number;
  onDelete: () => void;
}) {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const theme = useTheme();
  const methods = useFormContext<AddSubsidyFormValueProps>();

  const dateString = formatDateToLocale(
    selectedSubsidy.provider_updated_at,
    COMMON_DATE_FORMATS.DAY_MONTH_YEAR,
    language,
  );

  const currentAmount = methods.watch(`selectedSubsidies.${index}.amount`);
  const currentType = methods.watch(`selectedSubsidies.${index}.type`);

  const renderValue = (value: action_subsidy_value_type_enum) => {
    return value === action_subsidy_value_type_enum.percentage ? '%' : '€';
  };

  const shareOfActionCost =
    currentType === action_subsidy_value_type_enum.percentage
      ? fCurrencyTrimmed((currentAmount / 100) * actionCost, { roundTo: 1 })
      : (100 - ((actionCost - currentAmount) / actionCost) * 100).toFixed(1) + '%';

  const hasError = methods.formState.errors.selectedSubsidies?.[index]?.amount;

  const totalSubsidyShare =
    methods.watch('selectedSubsidies').reduce((acc, curr) => {
      if (curr.type === 'percentage') {
        return acc + (curr.amount / 100) * actionCost;
      }
      return acc + curr.amount;
    }, 0) / actionCost;

  //if the totalSubsidyShare is greater than 100% and has no error set the field to error
  if (totalSubsidyShare > 1 && !hasError) {
    methods.setError(`selectedSubsidies.${index}.amount`, {
      type: 'manual',
      message: t('ActionPlanning_SubsidiesAddSubsidyForm-notMoreThanActionCostError'),
    });
  }

  useEffect(() => {
    if (totalSubsidyShare <= 1 && hasError) {
      methods.clearErrors(`selectedSubsidies.${index}.amount`);
    }
  }, [totalSubsidyShare, hasError, methods, index]);

  return (
    <Card
      sx={{
        border: '1px solid',
        borderColor: theme.palette.grey[500_24],
        borderRadius: '8px',
        py: 2,
      }}
    >
      <Stack
        sx={{
          px: 4,
        }}
        spacing={2}
      >
        <Stack flex={'row'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant="subtitle1" fontWeight={700} maxWidth={'85%'}>
            {selectedSubsidy.title}
          </Typography>
          <IconButton onClick={onDelete}>
            <Iconify icon="ph:x" width={'20px'} height={'20px'} />
          </IconButton>
        </Stack>
        <Stack
          direction="row"
          sx={{
            mb: 1,
          }}
          alignItems={'center'}
        >
          <SubsidyTypeBadge type={selectedSubsidy.type} size={'medium'} />
          <Box sx={{ textOverflow: 'ellipsis', maxWidth: '80%' }}>
            <Typography variant="subtitle1" fontWeight={500} component={'span'}>
              {selectedSubsidy.amount_description}
            </Typography>
          </Box>
        </Stack>
        <Typography color="text.secondary">{t('General_UpdatedOnWithDate', { date: dateString })}</Typography>
        <Stack direction={'row'} spacing={2}>
          {selectedSubsidy.url && (
            <Button
              sx={{
                borderColor: theme.palette.grey[500_24],
              }}
              href={selectedSubsidy.url}
              target="_blank"
              rel="noopener noreferrer"
              variant={'outlined'}
              startIcon={<Iconify icon="material-symbols:open-in-new" />}
            >
              {t('General_MoreInformation')}
            </Button>
          )}
          {selectedSubsidy.email && (
            <Button
              sx={{
                borderColor: theme.palette.grey[500_24],
              }}
              variant={'outlined'}
              startIcon={<Iconify icon="ic:outline-email" />}
              target="_blank"
              rel="noopener noreferrer"
              href={`mailto:${selectedSubsidy.email}?subject=Anfrage%20zur%20Förderung%20${selectedSubsidy.title}}`}
            >
              {t('General_EmailForInformation')}
            </Button>
          )}
        </Stack>
      </Stack>
      <Divider sx={{ my: 3 }} />
      <Stack
        sx={{
          px: 4,
        }}
        spacing={1}
      >
        <Typography variant="body2" fontSize={'14px'} fontWeight={400}>
          {t('ActionPlanningSubsidiesAddSubsidyForm_AmountPrompt')}
        </Typography>
        <RHFInputWithSelect
          selectName={`selectedSubsidies.${index}.type`}
          inputName={`selectedSubsidies.${index}.amount`}
          variant="outlined"
          InputProps={{
            autoComplete: 'off',
          }}
          SelectProps={{
            onChange: () => {
              methods.setValue(`selectedSubsidies.${index}.amount`, 0);
            },
            renderValue: (value) => renderValue(value as action_subsidy_value_type_enum),
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              MenuListProps: {
                sx: {
                  minWidth: '200px',
                },
              },
            },
            SelectDisplayProps: {
              style: {
                //otherwise we get a truncate
                overflow: 'visible',
                //match the padding of the select, to get the selected element centered
                paddingRight: '24px',
              },
            },
          }}
        >
          <MenuItem value={action_subsidy_value_type_enum.percentage}>
            {t('ActionPlanningSubsidiesAddSubsidyForm_PercentageMenuItem')}
          </MenuItem>
          <MenuItem value={action_subsidy_value_type_enum.absolute}>
            {t('ActionPlanningSubsidiesAddSubsidyForm_AbsoluteMenuItem')}
          </MenuItem>
        </RHFInputWithSelect>
        {!hasError && (
          <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
            {t('ActionPlanningSubsidiesAddSubsidyForm_ShareOfActionCost', { share: shareOfActionCost })}
          </Typography>
        )}
        {totalSubsidyShare >= 0.6 && (
          <Typography variant="caption" sx={{ color: theme.palette.grey[600] }}>
            <Trans
              i18nKey="ActionPlanningSubsidiesAddSubsidyForm_TotalSubsidyShareBegWarning"
              components={{
                faq: (
                  <Link
                    href="https://www.energiewechsel.de/KAENEF/Redaktion/DE/FAQ/FAQ-Uebersicht/BEG/faq-bundesfoerderung-fuer-effiziente-gebaeude.html#:~:text=1.[…]60%20%25%3F"
                    rel="noopener noreferrer"
                    target="_blank"
                    underline="always"
                    sx={{
                      color: theme.palette.grey[600],
                      '&:hover': {
                        color: theme.palette.grey[800],
                      },
                    }}
                  />
                ),
              }}
            />
          </Typography>
        )}
      </Stack>
    </Card>
  );
}

function EmptySelection() {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Grid
      color="text.disabled"
      sx={{
        textAlign: 'center',
        height: '100%',
        placeItems: 'center',
      }}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Iconify icon={'ic:outline-percentage'} width={64} height={64} color={theme.palette.text.disabled} />
      <Typography variant="h6"> {t('ActionPlanningSubsidiesAddSubsidyForm_NoSubsidiesSelectedTitle')}</Typography>

      <Typography variant="body2">
        {t('ActionPlanningSubsidiesAddSubsidyForm_NoSubsidiesSelectedDescription')}
      </Typography>
    </Grid>
  );
}
