import { FormHelperText, ToggleButtonGroupProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import PreToggleButton from '../presentations/PreToggleButton';

// ----------------------------------------------------------------------

interface IProps {
  name: string;
  leftValue: { key: string; value: string | JSX.Element };
  rightValue: { key: string; value: string | JSX.Element };
}

export default function RHFToggleButton({ name, leftValue, rightValue, ...other }: IProps & ToggleButtonGroupProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const onToggle = (key: string) => {
          let newKey = null;
          if (leftValue.key === key) {
            newKey = leftValue.key;
          } else if (rightValue.key === key) {
            newKey = rightValue.key;
          }
          field.onChange(newKey);
          other.onChange?.({} as React.MouseEvent<HTMLElement, MouseEvent>, newKey);
        };

        const initialValue =
          leftValue.key === field.value ? leftValue.key : rightValue.key === field.value ? rightValue.key : null;

        return (
          <>
            <PreToggleButton
              {...field}
              {...other}
              leftValue={leftValue}
              rightValue={rightValue}
              initialValueType={initialValue}
              fullWidth
              onToggle={onToggle}
              ref={null}
            />
            {!!error && (
              <FormHelperText error sx={{ px: 2 }}>
                {error.message}
              </FormHelperText>
            )}
          </>
        );
      }}
    />
  );
}
