/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Card, CardProps, useTheme } from '@mui/material';
import { energy_source_type_enum } from '@predium/enums';
import { translateEnergySourceTypeEnum_dynamic } from '@predium/i18n/client';
import { fShortenNumber } from '@predium/utils';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import BaseOptionChart from '../../../theme/apexcharts';

interface EnergySourceCO2GraphProps extends CardProps {
  energySources: {
    energy_source_type: string;
    co2_emissions: number;
    co2_emissions_total: number;
  }[];
  showTotal: boolean;
  onItemSelect?: (value: energy_source_type_enum) => void;
}

export function EnergySourceCO2Graph({ energySources, showTotal, onItemSelect }: EnergySourceCO2GraphProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const sourceWithCO2Emissions = energySources.map((energySource) => {
    const co2Emissions = energySource.co2_emissions;
    return [energySource.energy_source_type, showTotal ? energySource.co2_emissions_total : co2Emissions];
  });

  const energySourceDistribution: { [key: string]: number } = Object.fromEntries(sourceWithCO2Emissions);

  const unit = showTotal ? 'kg CO₂e/a' : 'kg CO₂e/m²a';

  return (
    <EnergySourceGraph
      title={t('General_CO2IntensityByEnergySource')}
      series={Object.values(energySourceDistribution)}
      labels={Object.keys(energySourceDistribution)}
      colors={Object.keys(energySourceDistribution).map(
        (key) => theme.palette.energySourceTypeColors[key as energy_source_type_enum].light,
      )}
      unit={unit}
      onItemSelect={onItemSelect}
    />
  );
}

interface EnergySourceConsumptionGraphProps extends CardProps {
  energySources: {
    energy_source_type: string;
    energy_consumption: number;
    energy_consumption_total: number;
  }[];
  totalArea: number;
  showTotal: boolean;
  onItemSelect?: (value: energy_source_type_enum) => void;
}

export function EnergySourceConsumptionGraph({
  energySources,
  showTotal,
  onItemSelect,
}: EnergySourceConsumptionGraphProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const sourceWithConsumption = energySources.map((energySource) => {
    return [
      energySource.energy_source_type,
      showTotal ? energySource.energy_consumption_total : energySource.energy_consumption,
    ];
  });

  const energySourceDistribution: { [key: string]: number } = Object.fromEntries(sourceWithConsumption);

  const unit = showTotal ? 'kWh/a' : 'kWh/m²a';

  return (
    <EnergySourceGraph
      title={t('General_FinalEnergyByEnergySource')}
      series={Object.values(energySourceDistribution)}
      labels={Object.keys(energySourceDistribution)}
      colors={Object.keys(energySourceDistribution).map(
        (key) => theme.palette.energySourceTypeColors[key as energy_source_type_enum].light,
      )}
      unit={unit}
      onItemSelect={onItemSelect}
    />
  );
}

interface Props extends CardProps {
  title: string;
  series: number[];
  labels: string[];
  unit: string;
  colors: string[];
  animated?: boolean;
  onItemSelect?: (value: energy_source_type_enum) => void;
}

function EnergySourceGraph({ title, series, labels, colors, unit, animated, onItemSelect }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const totalValue = series.reduce((acc, cur) => acc + cur);

  const chartState: ApexCharts.ApexOptions = {
    colors,
    chart: {
      animations: {
        enabled: !!animated,
      },
      type: 'donut',
      events: {
        dataPointSelection: (_, __, config) => {
          setTimeout(() => {
            const value: energy_source_type_enum = config.w.config.labels[config.dataPointIndex];
            onItemSelect?.(value);
          });
        },
        dataPointMouseEnter: function (event) {
          event.target.style.cursor = 'pointer';
        },
      },
    },
    title: {
      text: title,
      offsetY: 5,
      margin: 20,
      style: {
        fontSize: theme.typography.h5.fontSize as string,
      },
    },
    legend: {
      position: 'right',
      formatter: (legendEntry: energy_source_type_enum, opts) => {
        const value = opts.w.globals.series[opts.seriesIndex];
        const percent = fShortenNumber((value / totalValue) * 100, 1);

        return `<strong>${translateEnergySourceTypeEnum_dynamic(legendEntry, t)}
        </strong> <span style="font-size: 13px; color: ${
          theme.palette.grey[500]
        }">(${percent}%)</span><p style="font-size: 13px; color: ${theme.palette.grey[500]}">${fShortenNumber(
          value,
        )} ${unit}</p>`;
      },
      fontSize: '16px',
      fontWeight: 600,
      fontFamily: theme.typography.fontFamily,
      offsetX: -10,
      offsetY: 10,
      markers: {
        offsetX: -5,
      },
      itemMargin: {
        vertical: 5,
      },
      width: 250,
    },

    dataLabels: { enabled: false },
    tooltip: {
      y: {
        formatter: (value) => `${fShortenNumber(value)} <span style="font-size: 10px">${unit}</span>`,
        title: {
          formatter: (seriesName: energy_source_type_enum) => translateEnergySourceTypeEnum_dynamic(seriesName, t),
        },
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '90%',
          labels: {
            show: true,
            total: {
              show: true,
              label: t('General_Total'),
              color: theme.palette.text.secondary,
              fontSize: theme.typography.subtitle2.fontSize as string,
              fontWeight: theme.typography.subtitle2.fontWeight,
              fontFamily: theme.typography.fontFamily,
              formatter: () => `${fShortenNumber(totalValue)}`,
            },
            value: {
              show: true,
              offsetY: -20,
              color: theme.palette.text.primary,
              fontSize: theme.typography.h3.fontSize as string,
              fontWeight: theme.typography.h3.fontWeight,
              fontFamily: theme.typography.fontFamily,
              formatter: (value) => `${fShortenNumber(value)}`,
            },
            name: {
              show: true,
              formatter: (value: energy_source_type_enum | string) =>
                `${unit} (${
                  value === t('General_Total')
                    ? t('General_Total')
                    : translateEnergySourceTypeEnum_dynamic(value as energy_source_type_enum, t)
                })`,
              color: theme.palette.text.secondary,
              offsetY: 20,
            },
          },
        },
      },
    },
    labels,
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 0.5,
        },
      },
    },
    stroke: {
      width: 0,
    },
    // ---------
    // 'totalValue' here is very important!
    // Reason: if totalValue changes, the ChartOptions object must be updated to reflect these changes in ApexCharts
    // https://github.com/apexcharts/react-apexcharts/issues/107#issuecomment-557318940
    //@ts-ignore
    totalValue,
    // ---------
  };

  return (
    <Card
      sx={{
        p: 4,
        '.apexcharts-legend-series': {
          display: 'flex',
          flexDirection: 'row',
        },
        '.apexcharts-legend-marker': {
          marginTop: 0.5,
          minWidth: 12,
        },
      }}
    >
      <ReactApexChart
        //@ts-ignore
        type={chartState.chart.type}
        id={title.replace(/ /g, '_')}
        options={merge(BaseOptionChart(), chartState)}
        series={series}
        height={300}
      />
    </Card>
  );
}
