/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { tooltipClasses } from '@mui/material/Tooltip';
import { role_enum } from '@predium/enums';
import { usePostHog } from 'posthog-js/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';
import { TileRole } from '../../../components/Tile';
import HelpCenterModal from '../../../components/helpcenter/HelpCenterModal';
import LanguageSelect from '../../../components/i18n/LanguageSelect';
import useAccessToken from '../../../hooks/useAccessToken';
import useSessionData from '../../../hooks/useSessionData';
import { PATHS } from '../../../routes';

type OrgConfig = {
  title: string;
  icon: string;
  path: string | null;
  role: TileRole;
  tile: string;
  allowedRoles: role_enum[];
  onClick?: () => void;
};

type OrgMenuProps = {
  openPopover: HTMLElement | null;
  handleClosePopover: () => void;
};
export default function OrgMenu({ openPopover, handleClosePopover }: OrgMenuProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const { org, user, isAdmin } = useSessionData();
  const { invalidateAccess } = useAccessToken();
  const [helpCenterIsOpen, setHelpCenterIsOpen] = useState(false);
  const name = `${user?.first_name ?? ''} ${user?.last_name ?? ''}`;
  const initials = `${user?.first_name?.[0] ?? ''}${user?.last_name?.[0] ?? ''}`;
  const orgName = org?.name ?? '';
  const userRole = user?.user_role[0]?.role;
  const role = isAdmin ? userRole : role_enum.UNAUTHORIZED;

  const orgAllUsersConfigs: OrgConfig[] = [
    {
      title: t('PageName_ManageUsers'),
      icon: 'mdi:account-group-outline',
      path: PATHS.org.users(),
      role: 'button',
      tile: 'users',
      allowedRoles: [role_enum.ORG_ADMIN],
      onClick: handleClosePopover,
    },
    {
      title: t('Settings_ManagePortfolios'),
      icon: 'material-symbols:home-work-outline',
      path: PATHS.org.portfolios(),
      role: 'button',
      tile: 'portfolios',
      allowedRoles: [role_enum.ORG_ADMIN],
      onClick: handleClosePopover,
    },
    {
      title: t('Settings_ManageOrganization'),
      icon: 'material-symbols:tune',
      path: PATHS.org.settings(),
      role: 'button',
      tile: 'settings',
      allowedRoles: [role_enum.ORG_ADMIN],
      onClick: handleClosePopover,
    },
  ];
  const allowedOrgConfigs: OrgConfig[] = orgAllUsersConfigs.filter(
    //@ts-ignore
    (orgConfig) => orgConfig.allowedRoles.indexOf(role) !== -1,
  );
  const handleLogout = () => {
    invalidateAccess();
    if (posthog) {
      posthog.reset();
    }
  };

  const handleHelpCenterClick = () => {
    setHelpCenterIsOpen(true);
    handleClosePopover();
  };

  return (
    <>
      <HelpCenterModal onClose={() => setHelpCenterIsOpen(false)} open={helpCenterIsOpen} />
      <MenuPopover
        arrow="bottom-center"
        disabledArrow={true}
        open={openPopover}
        anchorEl={openPopover}
        onClose={handleClosePopover}
        handleClick={handleClosePopover}
        sx={{ width: 300, padding: 0, mt: -2 }}
      >
        <Stack direction="column" alignContent="flex-end">
          <Stack direction="column" alignContent="center" alignItems="center" sx={{ py: 1 }}>
            <Avatar
              sx={{
                bgcolor: '#ffab00',
                color: 'white',
                width: 48,
                height: 48,
                mb: 1,
                fontWeight: 700,
                fontSize: '14px',
              }}
              alt={name}
            >
              {initials}
            </Avatar>
            <Typography variant="subtitle2" fontSize={'16px'}>
              {name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {orgName}
            </Typography>
          </Stack>
          <Divider variant="fullWidth" sx={{ mx: 0.5 }} />
          <Box sx={{ width: '100%' }}>
            <List sx={{ padding: 0 }}>
              {allowedOrgConfigs.length > 0 && (
                <Stack>
                  {allowedOrgConfigs.map((orgConfig) => (
                    <ListItem
                      disablePadding
                      //@ts-ignore
                      component={RouterLink}
                      to={orgConfig.path}
                      sx={{
                        color: 'text.primary',
                      }}
                      key={orgConfig.title}
                    >
                      <ListItemButton onClick={orgConfig.onClick}>
                        <ListItemIcon>
                          <Iconify icon={orgConfig.icon} color="action.active" />
                        </ListItemIcon>
                        <ListItemText primary={orgConfig.title} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                  <Divider variant="fullWidth" sx={{ mx: 0.5 }} />
                </Stack>
              )}
              <ListItem disablePadding>
                {/* Tooltip was used MAINLY for UX purposes. */}
                <Tooltip
                  title={<LanguageSelect handleParentMenuClose={handleClosePopover} />}
                  componentsProps={{
                    popper: {
                      sx: {
                        [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: {
                          marginLeft: '0px',
                        },
                      },
                    },
                    tooltip: {
                      sx: {
                        bgcolor: 'white',
                        p: 0,
                        borderRadius: 1.5,
                        boxShadow: '-20px 20px 40px -4px rgba(145, 158, 171, 0.24)',
                      },
                    },
                  }}
                  placement="right-start"
                  disableFocusListener
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <Iconify icon="mdi:translate" color="action.active" />
                    </ListItemIcon>
                    <ListItemText primary={t('Settings_Language')} />
                    <ListItemIcon sx={{ mr: 0 }}>
                      <Iconify icon="mdi:chevron-right" color="action.active" />
                    </ListItemIcon>
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              {/* WIP in PRE-2100 */}
              <ListItem disablePadding>
                <ListItemButton onClick={handleHelpCenterClick}>
                  <ListItemIcon>
                    <Iconify icon="mdi:help-circle-outline" color="action.active" />
                  </ListItemIcon>
                  <ListItemText primary={t('Settings_Support')} />
                </ListItemButton>
              </ListItem>
              <Divider variant="fullWidth" sx={{ mx: 0.5 }} />
              <ListItem disablePadding>
                <ListItemButton onClick={handleLogout}>
                  <ListItemIcon>
                    <Iconify icon="mdi:logout" sx={{ color: 'red' }} />
                  </ListItemIcon>
                  <ListItemText primary={t('General_Logout')} sx={{ color: 'red' }} />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Stack>
      </MenuPopover>
    </>
  );
}
