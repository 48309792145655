import {
  energy_consumer_technology_type_enum,
  energy_consumer_type_enum,
  energy_source_type_enum,
  energy_system_type_enum,
  insulation_material_category_enum,
  insulation_method_enum,
  language_enum,
} from '@predium/enums';
import { t } from 'i18next'; // Assuming i18next is used for translations
import { SetValueConfig } from 'react-hook-form';
import * as yup from 'yup';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '../../../../../utils/formatTime';
import { getMaxDate } from '../../ActionPlan.utils';

const GeneralRequiredMessage = () => t('General_Required');

export const SetValueOptions: SetValueConfig = {
  shouldDirty: true,
  shouldValidate: true,
};

//TODO: move this lib during action validations feature PRE-3890. all the validations are temporary and only covers required validations. all specific range and data types will be handle later with features PRE-3890

export const implementationToSchema = (implementationTo: Date, language: language_enum) =>
  yup.object().shape({
    implementation_to: yup
      .date()
      .min(
        implementationTo,
        t('General_MinDateMessage', {
          minDate: formatDateToLocale(implementationTo, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language),
        }),
      )
      .max(
        getMaxDate(),
        t('General_MaxDateMessage', {
          maxDate: formatDateToLocale(getMaxDate(), COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language),
        }),
      )
      .typeError(t('General_InvalidDate'))
      .required(GeneralRequiredMessage()),
  });

export const DoorValidationSchema = (implementationTo: Date, language: language_enum) =>
  yup.object().shape({
    renovation_ids: yup.array().of(yup.number()).required(GeneralRequiredMessage()),
    envelope_renovation_parameter: yup
      .object({
        material_name: yup.string().required(GeneralRequiredMessage()),
        cost_per_m2: yup.number().when('material_name', {
          is: (material_name: string) => material_name && material_name.length > 0,
          then: yup.number().required(GeneralRequiredMessage()),
          otherwise: yup.number().optional(),
        }),
        u_value: yup.number().when('material_name', {
          is: (material_name: string) => material_name && material_name.length > 0,
          then: yup.number().required(GeneralRequiredMessage()),
          otherwise: yup.number().optional(),
        }),
        envelope_type: yup.string().when('material_name', {
          is: (material_name: string) => material_name && material_name.length > 0,
          then: yup.string().oneOf(['DOOR']).required(GeneralRequiredMessage()),
          otherwise: yup.string().optional(),
        }),
      })
      .required(),
    ...implementationToSchema(implementationTo, language).fields,
    affected_parts: yup.array().min(1, GeneralRequiredMessage()).required(GeneralRequiredMessage()),
  });

export const WindowActionFormSchema = (implementationTo: Date, language: language_enum) =>
  yup.object().shape({
    renovation_ids: yup.array().of(yup.number()).required(GeneralRequiredMessage()),
    ...implementationToSchema(implementationTo, language).fields,
    envelope_renovation_parameter: yup
      .object({
        envelope_type: yup.string().oneOf(['WINDOW']).required(GeneralRequiredMessage()),
        material_name: yup.string().required(GeneralRequiredMessage()),
        insulation_lambda: yup.number().when('material_name', {
          is: (material_name: string) => !!material_name && material_name.length > 0,
          then: yup.number().required(GeneralRequiredMessage()),
          otherwise: yup.number().nullable().optional(),
        }),
        cost_per_m2: yup.number().when('material_name', {
          is: (material_name: string) => !!material_name && material_name.length > 0,
          then: yup.number().required(GeneralRequiredMessage()),
          otherwise: yup.number().nullable().optional(),
        }),
        u_value: yup.number().when('material_name', {
          is: (material_name: string) => !!material_name && material_name.length > 0,
          then: yup.number().required(GeneralRequiredMessage()),
          otherwise: yup.number().nullable().optional(),
        }),
      })
      .required(),
    affected_parts: yup.array().min(1, GeneralRequiredMessage()).required(GeneralRequiredMessage()),
  });

export const NonWindowDoorActionFormSchema = (implementationTo: Date, language: language_enum) =>
  yup.object().shape({
    renovation_ids: yup.array().of(yup.number()).required(GeneralRequiredMessage()),
    ...implementationToSchema(implementationTo, language).fields,
    envelope_renovation_parameter: yup
      .object({
        envelope_type: yup.string().required(GeneralRequiredMessage()), // Assuming NotWindowDoorEnvelopeType values are validated elsewhere
        insulation_method: yup.string().oneOf(Object.values(insulation_method_enum)).required(GeneralRequiredMessage()),
        material_name: yup.string().required(GeneralRequiredMessage()),
        insulation_material_category: yup.string().when('material_name', {
          is: (material_name: string) => !!material_name && material_name.length > 0,
          then: yup.string().oneOf(Object.values(insulation_material_category_enum)).required(GeneralRequiredMessage()),
          otherwise: yup.string().optional(),
        }),
        insulation_lambda: yup.number().when('material_name', {
          is: (material_name: string) => !!material_name && material_name.length > 0,
          then: yup.number().required(GeneralRequiredMessage()),
          otherwise: yup.number().nullable().optional(),
        }),
        insulation_thickness: yup.number().when('material_name', {
          is: (material_name: string) => !!material_name && material_name.length > 0,
          then: yup.number().required(GeneralRequiredMessage()),
          otherwise: yup.number().nullable().optional(),
        }),
        cost_per_m2: yup.number().when('material_name', {
          is: (material_name: string) => !!material_name && material_name.length > 0,
          then: yup.number().required(GeneralRequiredMessage()),
          otherwise: yup.number().nullable().optional(),
        }),
      })
      .required(),
    affected_parts: yup.array().min(1, GeneralRequiredMessage()).required(GeneralRequiredMessage()),
  });

export const SolarActionFormSchema = (implementationTo: Date, language: language_enum) =>
  yup.object().shape({
    ...implementationToSchema(implementationTo, language).fields,

    solar_renovation_parameter: yup
      .object({
        module_peak_power: yup.number().when('technology_name', {
          is: (technology_name: string) => !!technology_name && technology_name.length > 0,
          then: yup.number().required(GeneralRequiredMessage()),
          otherwise: yup.number().nullable().optional(),
        }),
        module_angle: yup.number().when('technology_name', {
          is: (technology_name: string) => !!technology_name && technology_name.length > 0,
          then: yup.number().required(GeneralRequiredMessage()),
          otherwise: yup.number().nullable().optional(),
        }),
        solar_active_area: yup.number().when('technology_name', {
          is: (technology_name: string) => !!technology_name && technology_name.length > 0,
          then: yup.number().required(GeneralRequiredMessage()),
          otherwise: yup.number().nullable().optional(),
        }),
        roof_area_ratio: yup.number().when('technology_name', {
          is: (technology_name: string) => !!technology_name && technology_name.length > 0,
          then: yup.number().required(GeneralRequiredMessage()),
          otherwise: yup.number().nullable().optional(),
        }),
        cost_per_m2: yup.number().when('technology_name', {
          is: (technology_name: string) => !!technology_name && technology_name.length > 0,
          then: yup.number().required(GeneralRequiredMessage()),
          otherwise: yup.number().nullable().optional(),
        }),
        technology_name: yup.string().required(GeneralRequiredMessage()),
      })
      .required(),
    renovation_ids: yup.array().of(yup.number().positive().integer()).required(),
  });

export const HeatingHotWaterActionFormSchema = (implementationTo: Date, language: language_enum) =>
  yup.object().shape({
    ...implementationToSchema(implementationTo, language).fields,

    system_renovation_parameter: yup
      .object({
        consumer_technology_type: yup
          .string()
          .oneOf([...Object.values(energy_consumer_technology_type_enum)])
          .required(GeneralRequiredMessage()),
        technology_name: yup.string().when(['consumer_technology_type'], {
          is: (consumer_technology_type: energy_consumer_technology_type_enum) =>
            !!consumer_technology_type && consumer_technology_type.length > 0,
          then: yup.string().required(GeneralRequiredMessage()),
          otherwise: yup.string().nullable().optional(),
        }),
        efficiency: yup.number().when(['technology_name', 'consumer_technology_type'], {
          is: (technology_name: string, consumer_technology_type: string) =>
            !!technology_name &&
            technology_name.length > 0 &&
            !!consumer_technology_type &&
            consumer_technology_type.length > 0,
          then: yup.number().required(GeneralRequiredMessage()),
          otherwise: yup.number().nullable().optional(),
        }),
        cost_per_m2: yup.number().when(['technology_name', 'consumer_technology_type'], {
          is: (technology_name: string, consumer_technology_type: string) =>
            !!technology_name &&
            technology_name.length > 0 &&
            !!consumer_technology_type &&
            consumer_technology_type.length > 0,
          then: yup.number().required(GeneralRequiredMessage()),
          otherwise: yup.number().nullable().optional(),
        }),
        energy_source_type: yup.string().when(['technology_name', 'consumer_technology_type'], {
          is: (technology_name: string, consumer_technology_type: string) =>
            !!technology_name &&
            technology_name.length > 0 &&
            !!consumer_technology_type &&
            consumer_technology_type.length > 0,
          then: yup
            .string()
            .oneOf([...Object.values(energy_source_type_enum)])
            .required(GeneralRequiredMessage()),
          otherwise: yup.string().nullable().optional(),
        }),

        system_type: yup
          .string()
          .oneOf([...Object.values(energy_system_type_enum)])
          .required(GeneralRequiredMessage()),
      })
      .required(),
    renovation_ids: yup.array().of(yup.number().integer()).required(),
  });

export const LightingActionFormSchema = (implementationTo: Date, language: language_enum) =>
  yup.object().shape({
    ...implementationToSchema(implementationTo, language).fields,

    system_renovation_parameter: yup
      .object({
        efficiency: yup.number().required(GeneralRequiredMessage()),
        system_type: yup.string().oneOf([energy_consumer_type_enum.LIGHTING]).required(GeneralRequiredMessage()),
        consumer_technology_type: yup
          .string()
          .oneOf(Object.values(energy_consumer_technology_type_enum))
          .required(GeneralRequiredMessage()),
        energy_source_type: yup
          .string()
          .oneOf(Object.values(energy_source_type_enum))
          .required(GeneralRequiredMessage()),
        technology_name: yup.string().optional(),
      })
      .required(),
    renovation_ids: yup.array().of(yup.number().integer()).required(GeneralRequiredMessage()),
  });

export const EnergyRouteActionFormSchema = (implementationTo: Date, language: language_enum) =>
  yup.object().shape({
    ...implementationToSchema(implementationTo, language).fields,

    energy_system_route_renovation_parameter: yup
      .object({
        energy_source_type: yup
          .string()
          .oneOf(Object.values(energy_source_type_enum))
          .required(GeneralRequiredMessage()),
      })
      .required(GeneralRequiredMessage()),

    renovation_ids: yup.array().of(yup.number().integer()).required(GeneralRequiredMessage()),
  });

export const HydraulicBalancingFormSchema = (implementationTo: Date, language: language_enum) =>
  yup.object().shape({
    ...implementationToSchema(implementationTo, language).fields,

    hydraulic_balancing_renovation_parameter: yup
      .object({
        last_hydraulic_balancing: yup.date().required(GeneralRequiredMessage()),
        costs_with_components: yup.boolean().required(GeneralRequiredMessage()),
      })
      .required(GeneralRequiredMessage()),
  });
