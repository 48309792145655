/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Card, Grid, Typography } from '@mui/material';
import { EsgAnalysisGetBuildingFragment } from '@predium/client-graphql';
import { translateSubBuildingClassEnum, translateTypeOfUseEnum_dynamic } from '@predium/i18n/client';
import { getNetArea, getNufTypesOfUse } from '@predium/utils';
import { useTranslation } from 'react-i18next';
import InlineUser from '../../components/InlineUser';

type Props = {
  building: EsgAnalysisGetBuildingFragment;
};

export default function EsgAnalysisBuildingSingleSection({ building }: Props) {
  const { t } = useTranslation();

  const typesOfUse = getNufTypesOfUse(building.areas);

  const classOfUse = building.areas?.[0]?.class_of_use_id;

  return (
    <Grid container item xs={6}>
      <Card sx={{ p: 3, pb: 1, width: '100%' }}>
        <Grid container spacing={3}>
          <Grid container item xs={12} spacing={2} sx={{ p: 2 }}>
            <Grid item xs={6}>
              <Typography sx={{ pb: 1 }} variant="body2" color="text.secondary">
                {t('General_Portfolio')}
              </Typography>
              <Typography variant="subtitle1">{building.economic_unit.portfolio.name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ pb: 1 }} variant="body2" color="text.secondary">
                {t('General_UsableArea')}
              </Typography>
              <Typography variant="subtitle1">{getNetArea(building.areas)} m²</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ pb: 1 }} variant="body2" color="text.secondary">
                {t('General_SubBuildingClass')}
              </Typography>
              <Typography variant="subtitle1">{translateSubBuildingClassEnum(classOfUse)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ pb: 1 }} variant="body2" color="text.secondary">
                {t('General_ConstructionYear')}
              </Typography>
              <Typography variant="subtitle1">{building.year_constructed}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ pb: 1 }} variant="body2" color="text.secondary">
                {t('General_TypeOfUse')}
              </Typography>
              <Typography variant="subtitle1">
                {typesOfUse.map((type) => translateTypeOfUseEnum_dynamic(type, t)).join(', ')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ pb: 1 }} variant="body2" color="text.secondary">
                {t('General_ResponsibleUser')}
              </Typography>
              <InlineUser
                firstName={building.responsible_user.first_name}
                lastName={building.responsible_user.last_name}
                size={25}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
