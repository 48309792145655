/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CardProps, useTheme } from '@mui/material';
import { sub_building_class_enum } from '@predium/enums';
import { translateSubBuildingClassEnum_dynamic } from '@predium/i18n/client';
import { fShortenNumber } from '@predium/utils';
import merge from 'lodash/merge';
import sum from 'lodash/sum';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import BaseOptionChart from '../../../theme/apexcharts';

interface Props extends CardProps {
  floorAreaBySubBuildingClass: {
    area: number;
    subBuildingClass: sub_building_class_enum;
  }[];
  animated?: boolean;
  onSubBuildingClassSelected?: (subBuildingClass: sub_building_class_enum) => void;
}

export default function SubBuildingClassDistributionGraph({
  floorAreaBySubBuildingClass,
  animated,
  onSubBuildingClassSelected,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const labels = floorAreaBySubBuildingClass.map((group) => sub_building_class_enum[group.subBuildingClass]);
  const series = floorAreaBySubBuildingClass.map((group) => group.area);

  // adds up all array items
  const totalValue = sum(series);

  const chartState: ApexCharts.ApexOptions = {
    chart: {
      animations: {
        enabled: !!animated,
      },
      type: 'donut',
      offsetX: -27,
      toolbar: {
        show: false,
      },
      events: {
        ...(onSubBuildingClassSelected && {
          dataPointSelection: (_, __, config) => {
            //Delaying updating the filters somehow fixes the t.parentNode is null error
            setTimeout(() => {
              const value: sub_building_class_enum = config.w.config.labels[config.dataPointIndex];
              onSubBuildingClassSelected(value);
            });
          },
          dataPointMouseEnter: function (event) {
            event.target.style.cursor = 'pointer';
          },
        }),
      },
    },
    colors: floorAreaBySubBuildingClass.map(
      (group) => theme.palette.subBuildingClassColors[sub_building_class_enum[group.subBuildingClass]].main,
    ),
    legend: {
      position: 'bottom',
      formatter: (legendEntry: sub_building_class_enum, opts) => {
        const value = opts.w.globals.series[opts.seriesIndex];
        const percent = fShortenNumber((value / totalValue) * 100, 1);

        return `<strong>${translateSubBuildingClassEnum_dynamic(
          legendEntry,
          t,
        )}</strong> <span style="font-size: 13px; color: ${theme.palette.grey[500]}">(${percent}%)</span>`;
      },
      fontSize: '18px',
      fontWeight: 600,
      fontFamily: theme.typography.fontFamily,
      offsetX: 0,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      y: {
        formatter: (value: number) => `${fShortenNumber(value)} <span style="font-size: 10px">m²</span>`,
        title: {
          formatter: (seriesName: sub_building_class_enum) => translateSubBuildingClassEnum_dynamic(seriesName, t),
        },
      },
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '75%',
          labels: {
            show: true,
            total: {
              show: true,
              label: 'm²',
              color: theme.palette.text.secondary,
              fontSize: theme.typography.body2.fontSize as string,
              fontWeight: theme.typography.fontWeightBold,
              fontFamily: theme.typography.fontFamily,
              formatter: () => `${fShortenNumber(totalValue)}`,
            },
            value: {
              show: true,
              offsetY: -17,
              color: theme.palette.text.secondary,
              fontSize: theme.typography.body2.fontSize as string,
              fontWeight: theme.typography.fontWeightBold,
              fontFamily: theme.typography.fontFamily,
              formatter: (value) => `${fShortenNumber(value)}`,
            },
            name: {
              show: true,
              formatter: () => 'm²',
              offsetY: 17,
              color: theme.palette.text.secondary,
            },
          },
        },
      },
    },
    labels,
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'darken',
          value: 0.5,
        },
      },
    },
  };

  return (
    <ReactApexChart
      //@ts-ignore
      type={chartState.chart.type}
      options={merge(BaseOptionChart(), chartState)}
      series={series}
      height={200}
    />
  );
}
