/* eslint-disable @typescript-eslint/ban-ts-comment */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, InputAdornment, MenuItem, Stack } from '@mui/material';
import { getSupportedStorageTypesForSystemType } from '@predium/client-lookup';
import { data_source_type_enum, energy_storage_type_enum, energy_system_type_enum } from '@predium/enums';
import { translateEnergyStorageTypeEnum } from '@predium/i18n/client';
import { useEffect, useMemo } from 'react';
import { useForm, useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { number, object, string } from 'yup';
import { TechnologyIcons } from '../../../../../../../../assets/images';
import Iconify from '../../../../../../../../components/Iconify';
import { FormProvider, RHFAutocomplete, RHFNumberField, RHFSelect } from '../../../../../../../../components/hook-form';
import PreDialog, {
  PreDialogBody,
  PreDialogTitle,
} from '../../../../../../../../components/presentations/PreDialog/PreDialog';
import { SystemRoute } from '../../../../../BuildingTechnology';
import {
  generateSystemFieldNames,
  generateSystemRouteFieldNames,
  generateYearRange,
} from '../../../../../Common/building.util';
import useBuilding from '../../../../../Context/useBuilding';
import { fieldIcon } from '../../TechnologyConsumerRoute';
import { getSelectStyles } from '../EnergySource/EnergySourceEditModal';

export type StorageFormType = {
  storageType: energy_storage_type_enum | '';
  storageHeatLoss: number;
  constructionYear: string | null;
};

const allStorageTypes = Object.values(energy_storage_type_enum);

type Props = {
  energySystemRoute: SystemRoute;
  open: boolean;
  onClose: () => void;
  systemIndex: number;
};

const StorageEditModal = ({ energySystemRoute, onClose, open, systemIndex }: Props) => {
  const { t } = useTranslation();

  const { building } = useBuilding();
  const endYear = new Date().getFullYear();
  const buildingConstructionYear = building.year_constructed;
  const REQUIRED_MESSAGE = t('General_Required');
  //reusing the final energy range message . will be updated with validation ticket
  const MIN_RANGE_MESSAGE = t('DataCollection_TechnologyEditForm_FinalEnergyMinMessage');
  const MAX_RANGE_MESSAGE = t('DataCollection_TechnologyEditForm_FinalEnergyMaxMessage');

  const CONSTRUCTION_YEAR_MESSAGE = t('DataCollection_TechnologyEditForm_ConstructionYearMessage', {
    start: buildingConstructionYear,
    end: endYear,
  });

  //Main system form context
  const { getValues, setValue } = useFormContext();
  const allSystemsData = getValues();

  const { energySystemType } = energySystemRoute;

  //@ts-ignore
  const storageTypes = getSupportedStorageTypesForSystemType(energySystemType);

  const { energyStorageHeatLoss, energyStorageType, energyStorageConstructionYear, storageId } = energySystemRoute;

  //@ts-ignore
  const defaultValues: StorageFormType = useMemo(() => {
    return {
      storageType: energyStorageType ?? '',
      storageHeatLoss: energyStorageHeatLoss ?? undefined,
      constructionYear: energyStorageConstructionYear ? energyStorageConstructionYear.toString() : null,
    };
  }, [energyStorageType, energyStorageHeatLoss, energyStorageConstructionYear]);

  const storageValidationSchema = object().shape({
    storageType: string().required(REQUIRED_MESSAGE).oneOf(allStorageTypes, REQUIRED_MESSAGE),
    storageHeatLoss: number().nullable().moreThan(0, MIN_RANGE_MESSAGE).max(99999, MAX_RANGE_MESSAGE),
    constructionYear: number()
      .nullable()
      .min(buildingConstructionYear, CONSTRUCTION_YEAR_MESSAGE)
      .max(endYear, CONSTRUCTION_YEAR_MESSAGE),
  });

  const methods = useForm<StorageFormType>({
    resolver: yupResolver(storageValidationSchema),
    defaultValues: defaultValues,
    mode: 'onChange',
  });

  const { handleSubmit, reset, getFieldState, watch, control } = methods;

  const { isDirty } = useFormState({
    control,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const isStorageTypeDirty = getFieldState('storageType').isDirty;
  const isConstructionYearDirty = getFieldState('constructionYear').isDirty;
  const isHeatLossDirty = getFieldState('storageHeatLoss').isDirty;

  const newStorageType = watch('storageType');
  const newConstructionYear = watch('constructionYear');
  const newHeatLoss = watch('storageHeatLoss');

  const onSubmit = (data: StorageFormType) => {
    handleClose();
    /**
     * Storage can be shared betweeen routes so currently edited unit needs to shown as updated in all routes
     */
    for (const [systemType, systems] of Object.entries(allSystemsData)) {
      for (const system of systems) {
        for (const [routeIndex, route] of system.routes.entries()) {
          if (route.storageId === storageId) {
            const {
              energyStorageTypeFieldname,
              energyStorageHeatLossFieldname,
              energyStorageConstructionYearFieldname,
            } = generateSystemRouteFieldNames(systemType as energy_system_type_enum, systemIndex, routeIndex);

            if (isStorageTypeDirty) {
              setValue(energyStorageTypeFieldname, data.storageType, {
                shouldDirty: true,
              });
            }
            if (isConstructionYearDirty) {
              setValue(energyStorageConstructionYearFieldname, data.constructionYear ?? null, {
                shouldDirty: true,
              });
            }
            if (isHeatLossDirty) {
              setValue(energyStorageHeatLossFieldname, data.storageHeatLoss, {
                shouldDirty: true,
              });
            }
            if (isDirty) {
              //@ts-ignore
              const { dataSourceTypeFieldname } = generateSystemFieldNames(energySystemType, systemIndex);

              setValue(dataSourceTypeFieldname, data_source_type_enum.MANUAL, {
                shouldDirty: true,
              });
            }
          }
        }
      }
    }
  };

  const onDelete = () => {
    handleClose();
    /**
     * Storage can be shared betweeen routes so currently deleted unit needs to shown as deleted in all routes
     */
    for (const [systemType, systems] of Object.entries(allSystemsData)) {
      for (const [systemIndex, system] of systems.entries()) {
        for (const [routeIndex, route] of system.routes.entries()) {
          if (route.storageId === storageId) {
            const { storageDeletedFieldname } = generateSystemRouteFieldNames(
              systemType as energy_system_type_enum,
              systemIndex,
              routeIndex,
            );

            setValue(storageDeletedFieldname, true, {
              shouldDirty: true,
            });

            //@ts-ignore
            const { dataSourceTypeFieldname } = generateSystemFieldNames(energySystemType, systemIndex);
            setValue(dataSourceTypeFieldname, data_source_type_enum.MANUAL);
          }
        }
      }
    }
  };

  const autocompleteOptions = generateYearRange(buildingConstructionYear);

  return (
    <PreDialog open={open} onClose={onClose} fullWidth type="definedByChildren">
      <FormProvider
        methods={methods}
        onSubmit={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <PreDialogBody
          dialogtitle={
            <PreDialogTitle
              icon={<Box component="img" src={TechnologyIcons.storage} mt={0.25} mr={1} width={24} height={24} />}
              title={t('DataCollection_Technology_EditStorageTitle')}
            />
          }
          content={
            <Box my={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} mb={2}>
                  <RHFSelect
                    select
                    label={t('General_Storage')}
                    name="storageType"
                    sx={getSelectStyles(isStorageTypeDirty)}
                  >
                    {storageTypes.map((type) => (
                      <MenuItem sx={{ height: 40 }} key={type} value={type}>
                        {isStorageTypeDirty && newStorageType === type && fieldIcon}
                        <Box>{translateEnergyStorageTypeEnum(type)}</Box>
                      </MenuItem>
                    ))}
                  </RHFSelect>
                </Grid>

                <Grid item xs={12} mb={2}>
                  <RHFAutocomplete
                    name="constructionYear"
                    label={t('General_YearOfInstallation')}
                    options={autocompleteOptions}
                    type="number"
                    InputProps={{
                      startAdornment:
                        isConstructionYearDirty && newConstructionYear !== energyStorageConstructionYear?.toString()
                          ? fieldIcon
                          : null,
                    }}
                  />
                </Grid>

                <Grid item xs={12} mb={2}>
                  <RHFNumberField
                    name={'storageHeatLoss'}
                    label={t('General_StorageLoss')}
                    variant="outlined"
                    InputProps={{
                      startAdornment: isHeatLossDirty && newHeatLoss !== energyStorageHeatLoss ? fieldIcon : null,
                      endAdornment: <InputAdornment position="end">kWh/m²a</InputAdornment>,
                      autoComplete: 'off',
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          }
          actions={
            <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
              <Box>
                {storageId > 0 && (
                  <Button
                    variant="text"
                    onClick={onDelete}
                    startIcon={<Iconify icon="mdi:trash-can-outline" widths={24} height={24} />}
                  >
                    {t('General_Delete')}
                  </Button>
                )}
              </Box>
              <Box>
                <Button variant="outlined" onClick={handleClose} sx={{ mr: 1.5 }}>
                  {t('General_Cancel')}
                </Button>
                <Button type="submit" variant="contained">
                  {t('DataCollection_Technology_EnergySourceEditDone')}
                </Button>
              </Box>
            </Stack>
          }
        ></PreDialogBody>
      </FormProvider>
    </PreDialog>
  );
};

export default StorageEditModal;
