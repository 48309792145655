import { energy_system_type_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateEnergySystemTypeEnum = (enumValue: energy_system_type_enum) => {
  switch (enumValue) {
    case energy_system_type_enum.COOLING:
      return <Trans i18nKey="Enum_EnergySystemTypeEnum-COOLING" />;
    case energy_system_type_enum.ELECTRICITY:
      return <Trans i18nKey="Enum_EnergySystemTypeEnum-ELECTRICITY" />;
    case energy_system_type_enum.GENERAL:
      return <Trans i18nKey="Enum_EnergySystemTypeEnum-GENERAL" />;
    case energy_system_type_enum.HEATING:
      return <Trans i18nKey="Enum_EnergySystemTypeEnum-HEATING" />;
    case energy_system_type_enum.HOT_WATER:
      return <Trans i18nKey="Enum_EnergySystemTypeEnum-HOT_WATER" />;
    case energy_system_type_enum.LIGHTING:
      return <Trans i18nKey="Enum_EnergySystemTypeEnum-LIGHTING" />;
    case energy_system_type_enum.SOLAR:
      return <Trans i18nKey="Enum_EnergySystemTypeEnum-SOLAR" />;
    case energy_system_type_enum.VENTILATION:
      return <Trans i18nKey="Enum_EnergySystemTypeEnum-VENTILATION" />;
  }
};

export const translateEnergySystemTypeEnum_dynamic = (
  enumValue: energy_system_type_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case energy_system_type_enum.COOLING:
      return t('Enum_EnergySystemTypeEnum-COOLING');
    case energy_system_type_enum.ELECTRICITY:
      return t('Enum_EnergySystemTypeEnum-ELECTRICITY');
    case energy_system_type_enum.GENERAL:
      return t('Enum_EnergySystemTypeEnum-GENERAL');
    case energy_system_type_enum.HEATING:
      return t('Enum_EnergySystemTypeEnum-HEATING');
    case energy_system_type_enum.HOT_WATER:
      return t('Enum_EnergySystemTypeEnum-HOT_WATER');
    case energy_system_type_enum.LIGHTING:
      return t('Enum_EnergySystemTypeEnum-LIGHTING');
    case energy_system_type_enum.SOLAR:
      return t('Enum_EnergySystemTypeEnum-SOLAR');
    case energy_system_type_enum.VENTILATION:
      return t('Enum_EnergySystemTypeEnum-VENTILATION');
  }
};
