import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import IntroductionPopover, {
  State,
  Step,
  createIntroReducer,
} from '../../../components/presentations/introduction/IntroductionPopover';

export type ReportIntroVariants = 'autofill' | 'common';

type Props = {
  isShown: boolean;
  /**
   * Determines the Intro to be shown. Each Intro will only be shown once per user.
   */
  variant: ReportIntroVariants;
};

function ReportIntroduction({ isShown, variant }: Props) {
  const { t } = useTranslation();

  const reportIntroStepsCommon: Step[] = [
    {
      title: t('ReportIntroduction_Tabs-title'),
      description: t('ReportIntroduction_Tabs-description'),
    },
  ];
  const reportIntroStepsAutofill: Step[] = [
    {
      title: t('ReportIntroduction_Autofill-title'),
      description: t('ReportIntroduction_Autofill-description'),
    },
    ...reportIntroStepsCommon,
  ];

  // TODO: Unite in a common place with other local storage keys
  const localStorageKeyReportAutofill: [string, string, Step[]] = [
    'report-intro-autofill-shown',
    'report-intro-autofill-step',
    reportIntroStepsAutofill,
  ];
  const localStorageKeyReportCommon: [string, string, Step[]] = [
    'report-intro-common-shown',
    'report-intro-common-step',
    reportIntroStepsCommon,
  ];

  const [localStorageKey, elementId, steps] =
    variant === 'autofill' ? localStorageKeyReportAutofill : localStorageKeyReportCommon;
  const reportIntroductionState: State = {
    currentStep: steps[0],
    maxSteps: steps.length,
    currentStepIndex: 0,
    currentElementId: `${elementId}-0`,
  };

  const handleClose = () => {
    localStorage.setItem(localStorageKey, 'true');
  };

  const introductionIntroReducer = useMemo(
    () => createIntroReducer(elementId, steps, handleClose),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variant],
  );
  const hasBeenShown = useMemo(() => localStorage.getItem(localStorageKey) === 'true', [localStorageKey]);

  if (!isShown || hasBeenShown) return null;

  return (
    <IntroductionPopover
      reducer={introductionIntroReducer}
      initialState={reportIntroductionState}
      handleClose={handleClose}
    />
  );
}

export default ReportIntroduction;
