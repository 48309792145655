import { tax_bracket_enum } from '@predium/enums';
import sumBy from 'lodash/sumBy';

const TaxBracketOrder = [
  tax_bracket_enum._100_0,
  tax_bracket_enum._90_10,
  tax_bracket_enum._80_20,
  tax_bracket_enum._70_30,
  tax_bracket_enum._60_40,
  tax_bracket_enum._50_50,
  tax_bracket_enum._40_60,
  tax_bracket_enum._30_70,
  tax_bracket_enum._20_80,
  tax_bracket_enum._10_90,
];

export function averageTaxBracket(
  taxBrackets: {
    area: number;
    taxBracket: tax_bracket_enum;
  }[],
): tax_bracket_enum {
  const orderedTaxBracket = TaxBracketOrder.map((taxBracket) => {
    const matching = taxBrackets.filter((c) => c.taxBracket === taxBracket);
    if (matching.length === 0) {
      return {
        area: 0,
        taxBracket,
      };
    }
    return {
      area: sumBy(matching, ({ area }) => area),
      taxBracket,
    };
  });
  const totalArea = sumBy(orderedTaxBracket, ({ area }) => area);
  if (totalArea === 0) {
    throw new Error('No area provided');
  }

  const averageWeightedTaxBracketIndex = orderedTaxBracket.reduce((acc, taxBracket, index) => {
    const idx = index + 1; //+1 because otherwise class 0 has no effect
    return acc + (taxBracket.area / totalArea) * idx;
  }, -1); //start with -1 because otherwise class 0 has no effect

  return TaxBracketOrder[Math.round(averageWeightedTaxBracketIndex)]; //round to get the closest class and get enum value for it
}
