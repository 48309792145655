export enum ICONS {
  TRASH = 'mdi:trash-can-outline',
  EDIT = 'mdi:square-edit-outline',
  FILEUPLOADOUTLINE = 'mdi:file-upload-outline',
  GAUGE = 'mdi:gauge',
  VOLUME = 'mdi:delete-variant',
  CLOSE = 'mdi:close',
  PDF_EXPORT = 'mdi:file-pdf-outline',
  U_VALUE = 'mdi:alpha-u-box',
  ORIENTATION = 'mdi:compass-outline',
  AREA = 'mdi:surface-area',
  INSULATION_THICKNESS = 'mdi:arrow-expand-horizontal',
  CHEVRON_DOWN = 'mdi:chevron-down',
  CHEVRON_UP = 'mdi:chevron-up',
  PAGE_SEARCH = 'iconoir:page-search',
  CONVERSION_PATH = 'material-symbols:conversion-path',
  CHART_TIMELINE = 'mdi:chart-timeline',
  WATER = 'mdi:drop-outline',
  ELECTRICITY = 'mdi:electricity-outline',
  HEAT = 'material-symbols:heat',
  ARROW_UP = 'mdi:arrow-up',
  ENERGY = 'mdi:energy-circle',
}
