import { Tooltip } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import useSessionData from '../../hooks/useSessionData';

type Props = {
  /** A boolean indicating whether the user has the required access rights.
   *  Will be overwritten if the user is an admin.
   */
  hasAccess: boolean;
  /** How to handle missing permissions, either hiding the content or disabling it.
   *  Note that this prop is not actually disabling the content, just showing a tooltip,
   *  the underlying children should be disabled manually.
   */
  type?: 'hide' | 'disable';
};

/**
 * A wrapper component that checks if the user has the required access rights. Meant to be used
 * for disabling/hiding components based on the user's permissions.
 */
export default function AccessRightsWrapper({ children, hasAccess, type = 'hide' }: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const { isAdmin } = useSessionData();

  if (hasAccess || isAdmin) {
    return <>{children}</>;
  }

  return type === 'hide' ? null : (
    <Tooltip title={t('General_AccessRightsRequired')} arrow>
      <>{children}</>
    </Tooltip>
  );
}
