import { climate_risk_type_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateClimateRiskTypeEnum = (enumValue: climate_risk_type_enum) => {
  switch (enumValue) {
    case climate_risk_type_enum.EARTHQUAKE:
      return <Trans i18nKey="Enum_ClimateRiskTypeEnum-EARTHQUAKE" />;
    case climate_risk_type_enum.FOREST_FIRE:
      return <Trans i18nKey="Enum_ClimateRiskTypeEnum-FOREST_FIRE" />;
    case climate_risk_type_enum.HAIL:
      return <Trans i18nKey="Enum_ClimateRiskTypeEnum-HAIL" />;
    case climate_risk_type_enum.HEAT:
      return <Trans i18nKey="Enum_ClimateRiskTypeEnum-HEAT" />;
    case climate_risk_type_enum.HEAVY_RAIN:
      return <Trans i18nKey="Enum_ClimateRiskTypeEnum-HEAVY_RAIN" />;
    case climate_risk_type_enum.LIGHTNING_STRIKE:
      return <Trans i18nKey="Enum_ClimateRiskTypeEnum-LIGHTNING_STRIKE" />;
    case climate_risk_type_enum.SNOW_LOAD:
      return <Trans i18nKey="Enum_ClimateRiskTypeEnum-SNOW_LOAD" />;
    case climate_risk_type_enum.WINTER_STORM:
      return <Trans i18nKey="Enum_ClimateRiskTypeEnum-WINTER_STORM" />;
  }
};

export const translateClimateRiskTypeEnum_dynamic = (
  enumValue: climate_risk_type_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case climate_risk_type_enum.EARTHQUAKE:
      return t('Enum_ClimateRiskTypeEnum-EARTHQUAKE');
    case climate_risk_type_enum.FOREST_FIRE:
      return t('Enum_ClimateRiskTypeEnum-FOREST_FIRE');
    case climate_risk_type_enum.HAIL:
      return t('Enum_ClimateRiskTypeEnum-HAIL');
    case climate_risk_type_enum.HEAT:
      return t('Enum_ClimateRiskTypeEnum-HEAT');
    case climate_risk_type_enum.HEAVY_RAIN:
      return t('Enum_ClimateRiskTypeEnum-HEAVY_RAIN');
    case climate_risk_type_enum.LIGHTNING_STRIKE:
      return t('Enum_ClimateRiskTypeEnum-LIGHTNING_STRIKE');
    case climate_risk_type_enum.SNOW_LOAD:
      return t('Enum_ClimateRiskTypeEnum-SNOW_LOAD');
    case climate_risk_type_enum.WINTER_STORM:
      return t('Enum_ClimateRiskTypeEnum-WINTER_STORM');
  }
};
