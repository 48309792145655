import { useLazyQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react'; // Import useRef
import { useTranslation } from 'react-i18next';
import { SnackbarTimeouts } from '../../components/NotistackProvider';
import ExportPdfDialog, { PdfFormData } from '../../components/documents/export-pdf/ExportPdfDialog';
import { EXPORT_ESG_ANALYSIS_PDF } from '../../graphql/EsgAnalysis.queries';
import { useLanguage } from '../../provider/LanguageProvider';
import { onExportCompleted } from '../../utils/createDownloadLink';

type Props = {
  building: {
    id: number;
    street: string;
    city: string;
    postalCode: string;
  };
  handleClose: () => void;
};

const EsgPdfExport = ({ building, handleClose }: Props) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const abortController = useRef(new AbortController()); // Create a ref to the AbortController

  const [createPdfExport] = useLazyQuery(EXPORT_ESG_ANALYSIS_PDF, {
    onError: () => {
      enqueueSnackbar(t('EsgAnalysis_ExportESGPDF-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
      handleClose();
      setLoading(false);
    },
    onCompleted: (data) => {
      onExportCompleted(
        data.getEsgAnalysisPdf.body,
        'pdf',
        `OnePager_${building.street}_${building.postalCode}_${building.city}`,
        language,
        true,
      );
      enqueueSnackbar(t('EsgAnalysis_ExportESGPDF-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      handleClose();
      setLoading(false);
    },
    context: {
      // Pass the abort controller's signal to the mutation context
      fetchOptions: {
        signal: abortController.current.signal,
      },
    },
  });

  const onSubmit = (data: PdfFormData) => {
    setLoading(true);
    createPdfExport({
      variables: {
        buildingId: building.id,
        // to avoid sending empty strings
        title: data.title || undefined,
        description: data.description || undefined,
      },
    });
  };

  const onCancel = () => {
    // Abort the mutation when the cancel button is clicked
    if (loading) {
      abortController.current.abort();
    }
    setLoading(false);
    handleClose();
  };

  return (
    <ExportPdfDialog
      open={Boolean(building)}
      onCancel={onCancel}
      onFormSubmit={(data) => onSubmit(data)}
      loading={loading}
      dialogTitle={t('EsgAnalysis_ExportDialogTitle')}
      descriptions={[t('EsgAnalysis_ExportDialogDescriptionFirst'), t('EsgAnalysis_ExportDialogDescriptionSecond')]}
    />
  );
};

export default EsgPdfExport;
