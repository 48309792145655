import { country_state_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateCountryStateEnum = (enumValue: country_state_enum) => {
  switch (enumValue) {
    case country_state_enum.AT_BURGENLAND:
      return <Trans i18nKey="Enum_CountryStateEnum-AT_BURGENLAND" />;
    case country_state_enum.AT_CARINTHIA:
      return <Trans i18nKey="Enum_CountryStateEnum-AT_CARINTHIA" />;
    case country_state_enum.AT_LOWER_AUSTRIA:
      return <Trans i18nKey="Enum_CountryStateEnum-AT_LOWER_AUSTRIA" />;
    case country_state_enum.AT_SALZBURG:
      return <Trans i18nKey="Enum_CountryStateEnum-AT_SALZBURG" />;
    case country_state_enum.AT_STYRIA:
      return <Trans i18nKey="Enum_CountryStateEnum-AT_STYRIA" />;
    case country_state_enum.AT_TYROL:
      return <Trans i18nKey="Enum_CountryStateEnum-AT_TYROL" />;
    case country_state_enum.AT_UPPER_AUSTRIA:
      return <Trans i18nKey="Enum_CountryStateEnum-AT_UPPER_AUSTRIA" />;
    case country_state_enum.AT_VIENNA:
      return <Trans i18nKey="Enum_CountryStateEnum-AT_VIENNA" />;
    case country_state_enum.AT_VORARLBERG:
      return <Trans i18nKey="Enum_CountryStateEnum-AT_VORARLBERG" />;
    case country_state_enum.DE_BADEN_WUERTTEMBERG:
      return <Trans i18nKey="Enum_CountryStateEnum-DE_BADEN_WUERTTEMBERG" />;
    case country_state_enum.DE_BAYERN:
      return <Trans i18nKey="Enum_CountryStateEnum-DE_BAYERN" />;
    case country_state_enum.DE_BERLIN:
      return <Trans i18nKey="Enum_CountryStateEnum-DE_BERLIN" />;
    case country_state_enum.DE_BRANDENBURG:
      return <Trans i18nKey="Enum_CountryStateEnum-DE_BRANDENBURG" />;
    case country_state_enum.DE_BREMEN:
      return <Trans i18nKey="Enum_CountryStateEnum-DE_BREMEN" />;
    case country_state_enum.DE_HAMBURG:
      return <Trans i18nKey="Enum_CountryStateEnum-DE_HAMBURG" />;
    case country_state_enum.DE_HESSEN:
      return <Trans i18nKey="Enum_CountryStateEnum-DE_HESSEN" />;
    case country_state_enum.DE_MECKLENBURG_VORPOMMERN:
      return <Trans i18nKey="Enum_CountryStateEnum-DE_MECKLENBURG_VORPOMMERN" />;
    case country_state_enum.DE_NIEDERSACHSEN:
      return <Trans i18nKey="Enum_CountryStateEnum-DE_NIEDERSACHSEN" />;
    case country_state_enum.DE_NORDRHEIN_WESTFALEN:
      return <Trans i18nKey="Enum_CountryStateEnum-DE_NORDRHEIN_WESTFALEN" />;
    case country_state_enum.DE_RHEINLAND_PFALZ:
      return <Trans i18nKey="Enum_CountryStateEnum-DE_RHEINLAND_PFALZ" />;
    case country_state_enum.DE_SAARLAND:
      return <Trans i18nKey="Enum_CountryStateEnum-DE_SAARLAND" />;
    case country_state_enum.DE_SACHSEN:
      return <Trans i18nKey="Enum_CountryStateEnum-DE_SACHSEN" />;
    case country_state_enum.DE_SACHSEN_ANHALT:
      return <Trans i18nKey="Enum_CountryStateEnum-DE_SACHSEN_ANHALT" />;
    case country_state_enum.DE_SCHLESWIG_HOLSTEIN:
      return <Trans i18nKey="Enum_CountryStateEnum-DE_SCHLESWIG_HOLSTEIN" />;
    case country_state_enum.DE_THUERINGEN:
      return <Trans i18nKey="Enum_CountryStateEnum-DE_THUERINGEN" />;
    case country_state_enum.PL_GREATER_POLAND:
      return <Trans i18nKey="Enum_CountryStateEnum-PL_GREATER_POLAND" />;
    case country_state_enum.PL_HOLY_CROSS:
      return <Trans i18nKey="Enum_CountryStateEnum-PL_HOLY_CROSS" />;
    case country_state_enum.PL_KUYAVIAN_POMERANIAN:
      return <Trans i18nKey="Enum_CountryStateEnum-PL_KUYAVIAN_POMERANIAN" />;
    case country_state_enum.PL_LESSER_POLAND:
      return <Trans i18nKey="Enum_CountryStateEnum-PL_LESSER_POLAND" />;
    case country_state_enum.PL_LODZ:
      return <Trans i18nKey="Enum_CountryStateEnum-PL_LODZ" />;
    case country_state_enum.PL_LOWER_SILESIAN:
      return <Trans i18nKey="Enum_CountryStateEnum-PL_LOWER_SILESIAN" />;
    case country_state_enum.PL_LUBLIN:
      return <Trans i18nKey="Enum_CountryStateEnum-PL_LUBLIN" />;
    case country_state_enum.PL_LUBUSZ:
      return <Trans i18nKey="Enum_CountryStateEnum-PL_LUBUSZ" />;
    case country_state_enum.PL_MASOVIAN:
      return <Trans i18nKey="Enum_CountryStateEnum-PL_MASOVIAN" />;
    case country_state_enum.PL_OPOLE:
      return <Trans i18nKey="Enum_CountryStateEnum-PL_OPOLE" />;
    case country_state_enum.PL_PODLASKIE:
      return <Trans i18nKey="Enum_CountryStateEnum-PL_PODLASKIE" />;
    case country_state_enum.PL_POMERANIAN:
      return <Trans i18nKey="Enum_CountryStateEnum-PL_POMERANIAN" />;
    case country_state_enum.PL_SILESIAN:
      return <Trans i18nKey="Enum_CountryStateEnum-PL_SILESIAN" />;
    case country_state_enum.PL_SUBCARPATHIAN:
      return <Trans i18nKey="Enum_CountryStateEnum-PL_SUBCARPATHIAN" />;
    case country_state_enum.PL_WARMIAN_MASURIAN:
      return <Trans i18nKey="Enum_CountryStateEnum-PL_WARMIAN_MASURIAN" />;
    case country_state_enum.PL_WEST_POMERANIAN:
      return <Trans i18nKey="Enum_CountryStateEnum-PL_WEST_POMERANIAN" />;
    case country_state_enum.UNKNOWN:
      return <Trans i18nKey="Enum_CountryStateEnum-UNKNOWN" />;
  }
};

export const translateCountryStateEnum_dynamic = (
  enumValue: country_state_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case country_state_enum.AT_BURGENLAND:
      return t('Enum_CountryStateEnum-AT_BURGENLAND');
    case country_state_enum.AT_CARINTHIA:
      return t('Enum_CountryStateEnum-AT_CARINTHIA');
    case country_state_enum.AT_LOWER_AUSTRIA:
      return t('Enum_CountryStateEnum-AT_LOWER_AUSTRIA');
    case country_state_enum.AT_SALZBURG:
      return t('Enum_CountryStateEnum-AT_SALZBURG');
    case country_state_enum.AT_STYRIA:
      return t('Enum_CountryStateEnum-AT_STYRIA');
    case country_state_enum.AT_TYROL:
      return t('Enum_CountryStateEnum-AT_TYROL');
    case country_state_enum.AT_UPPER_AUSTRIA:
      return t('Enum_CountryStateEnum-AT_UPPER_AUSTRIA');
    case country_state_enum.AT_VIENNA:
      return t('Enum_CountryStateEnum-AT_VIENNA');
    case country_state_enum.AT_VORARLBERG:
      return t('Enum_CountryStateEnum-AT_VORARLBERG');
    case country_state_enum.DE_BADEN_WUERTTEMBERG:
      return t('Enum_CountryStateEnum-DE_BADEN_WUERTTEMBERG');
    case country_state_enum.DE_BAYERN:
      return t('Enum_CountryStateEnum-DE_BAYERN');
    case country_state_enum.DE_BERLIN:
      return t('Enum_CountryStateEnum-DE_BERLIN');
    case country_state_enum.DE_BRANDENBURG:
      return t('Enum_CountryStateEnum-DE_BRANDENBURG');
    case country_state_enum.DE_BREMEN:
      return t('Enum_CountryStateEnum-DE_BREMEN');
    case country_state_enum.DE_HAMBURG:
      return t('Enum_CountryStateEnum-DE_HAMBURG');
    case country_state_enum.DE_HESSEN:
      return t('Enum_CountryStateEnum-DE_HESSEN');
    case country_state_enum.DE_MECKLENBURG_VORPOMMERN:
      return t('Enum_CountryStateEnum-DE_MECKLENBURG_VORPOMMERN');
    case country_state_enum.DE_NIEDERSACHSEN:
      return t('Enum_CountryStateEnum-DE_NIEDERSACHSEN');
    case country_state_enum.DE_NORDRHEIN_WESTFALEN:
      return t('Enum_CountryStateEnum-DE_NORDRHEIN_WESTFALEN');
    case country_state_enum.DE_RHEINLAND_PFALZ:
      return t('Enum_CountryStateEnum-DE_RHEINLAND_PFALZ');
    case country_state_enum.DE_SAARLAND:
      return t('Enum_CountryStateEnum-DE_SAARLAND');
    case country_state_enum.DE_SACHSEN:
      return t('Enum_CountryStateEnum-DE_SACHSEN');
    case country_state_enum.DE_SACHSEN_ANHALT:
      return t('Enum_CountryStateEnum-DE_SACHSEN_ANHALT');
    case country_state_enum.DE_SCHLESWIG_HOLSTEIN:
      return t('Enum_CountryStateEnum-DE_SCHLESWIG_HOLSTEIN');
    case country_state_enum.DE_THUERINGEN:
      return t('Enum_CountryStateEnum-DE_THUERINGEN');
    case country_state_enum.PL_GREATER_POLAND:
      return t('Enum_CountryStateEnum-PL_GREATER_POLAND');
    case country_state_enum.PL_HOLY_CROSS:
      return t('Enum_CountryStateEnum-PL_HOLY_CROSS');
    case country_state_enum.PL_KUYAVIAN_POMERANIAN:
      return t('Enum_CountryStateEnum-PL_KUYAVIAN_POMERANIAN');
    case country_state_enum.PL_LESSER_POLAND:
      return t('Enum_CountryStateEnum-PL_LESSER_POLAND');
    case country_state_enum.PL_LODZ:
      return t('Enum_CountryStateEnum-PL_LODZ');
    case country_state_enum.PL_LOWER_SILESIAN:
      return t('Enum_CountryStateEnum-PL_LOWER_SILESIAN');
    case country_state_enum.PL_LUBLIN:
      return t('Enum_CountryStateEnum-PL_LUBLIN');
    case country_state_enum.PL_LUBUSZ:
      return t('Enum_CountryStateEnum-PL_LUBUSZ');
    case country_state_enum.PL_MASOVIAN:
      return t('Enum_CountryStateEnum-PL_MASOVIAN');
    case country_state_enum.PL_OPOLE:
      return t('Enum_CountryStateEnum-PL_OPOLE');
    case country_state_enum.PL_PODLASKIE:
      return t('Enum_CountryStateEnum-PL_PODLASKIE');
    case country_state_enum.PL_POMERANIAN:
      return t('Enum_CountryStateEnum-PL_POMERANIAN');
    case country_state_enum.PL_SILESIAN:
      return t('Enum_CountryStateEnum-PL_SILESIAN');
    case country_state_enum.PL_SUBCARPATHIAN:
      return t('Enum_CountryStateEnum-PL_SUBCARPATHIAN');
    case country_state_enum.PL_WARMIAN_MASURIAN:
      return t('Enum_CountryStateEnum-PL_WARMIAN_MASURIAN');
    case country_state_enum.PL_WEST_POMERANIAN:
      return t('Enum_CountryStateEnum-PL_WEST_POMERANIAN');
    case country_state_enum.UNKNOWN:
      return t('Enum_CountryStateEnum-UNKNOWN');
  }
};
