import { Box, FormControl, InputLabel, SxProps, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Select, { ActionMeta, OnChangeValue, StylesConfig, components } from 'react-select';

const DARK_COLOR = 'rgb(33, 43, 54)';

type Props = {
  name: string;
  options: SelectWithSearchValue[];
  sx?: SxProps;
  defaultValue?: SelectWithSearchValue;
  onSelect: (selectedValue: SelectWithSearchValue) => void;
  selectedValues?: SelectWithSearchValue[];
  onResetAll?: () => void;
  noDataText: string;
};

export type SelectWithSearchValue = {
  value: string | number;
  label: string | number;
  isFixed?: boolean;
};

const orderOptions = (values: readonly SelectWithSearchValue[]) => {
  return values.filter((v) => v.isFixed).concat(values.filter((v) => !v.isFixed));
};

export default function SelectMultiWithSearch({
  name,
  options,
  sx,
  defaultValue,
  onSelect,
  selectedValues,
  onResetAll,
  noDataText,
}: Props) {
  const { t } = useTranslation();
  const [value, setValue] = useState<SelectWithSearchValue[]>(orderOptions([]));
  const theme = useTheme();

  useEffect(() => {
    if (selectedValues && selectedValues.length) {
      setValue(selectedValues);
    } else {
      setValue(defaultValue ? [defaultValue] : []);
    }
  }, [defaultValue, onSelect, selectedValues]);

  const styles: StylesConfig<SelectWithSearchValue, true> = {
    valueContainer: (base) => ({
      ...base,
      overflowX: 'auto',
      flexWrap: 'unset',
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      display: state.data?.isFixed ? 'none' : 'flex',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.background.default,
        background: theme.palette.grey[500],
      },
      borderRadius: '50%',
      background: theme.palette.grey[500_56],
      height: '17px',
      width: '17px',
      padding: '2.5px',
      margin: '7px 5px 7px 5px',
      alignItems: 'center',
      color: theme.palette.background.default,
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      padding: '0 6px !important',
    }),
    multiValue: (provided, state) => ({
      ...provided,
      borderRadius: '20px',
      padding: state.data?.isFixed ? '6px' : '0 4px',
      background: theme.palette.grey[500_16],
      color: '#212B36',
      flex: '1 0 auto',
      maxWidth: 'fit-content',
      alignItems: 'center',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    control: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      border: '1px solid rgba(145, 158, 171, 0.32)',
      borderRadius: 8,
      color: DARK_COLOR,
      padding: '10px',
      marginRight: '8px',
      overflow: 'auto',
      boxShadow: state.isFocused ? `0 0 0 1px ${DARK_COLOR}` : 'none',
      '&:hover': {
        color: DARK_COLOR,
        boxShadow: `0 0 0 1px ${DARK_COLOR}`,
      },
      /*
      Overriding default scrollbar to mui style
      Doesnt work with firefox, will be fixed later or component itself will be replaced with MUI select
      PRE-491 rReplace react-select with MUI multi-select with search
      */
      '*::-webkit-scrollbar': {
        height: '5px',
      },
      '*::-webkit-scrollbar-track': {
        background: theme.palette.common.white,
      },
      '*::-webkit-scrollbar-thumb': {
        background: theme.palette.grey[500_48],
        borderRadius: '9px',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      width: 'auto',
      whiteSpace: 'nowrap',
      typography: 'body2',
      mx: 1,
      my: 0.5,
      borderRadius: '6px',
      fontSize: '0.875rem',
      margin: '6px 10px',
      backgroundColor: state.isSelected ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
      color: DARK_COLOR,
      '&:hover': {
        backgroundColor: 'rgba(145, 158, 171, 0.08)',
      },
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 2,
    }),
  };

  const onChange = (
    newValue: OnChangeValue<SelectWithSearchValue, true>,
    actionMeta: ActionMeta<SelectWithSearchValue>,
  ) => {
    switch (actionMeta.action) {
      case 'select-option':
      case 'deselect-option':
        if (actionMeta.option) onSelect(actionMeta.option);
        break;
      case 'remove-value':
      case 'pop-value':
        onSelect(actionMeta.removedValue);
        if (actionMeta.removedValue?.isFixed) {
          return;
        }
        break;
      case 'clear':
        newValue = options.filter((option: SelectWithSearchValue) => option?.isFixed);
        onResetAll?.();
        setValue(defaultValue ? [defaultValue] : []);
        break;
    }

    setValue(orderOptions(newValue));
  };

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>{noDataText}</span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <Box sx={{ ...sx }}>
      <FormControl fullWidth>
        {name && (
          <InputLabel shrink sx={{ backgroundColor: 'white', padding: '0 8px', marginLeft: '-5px' }}>
            {name}
          </InputLabel>
        )}
        <Select
          value={value}
          isMulti
          styles={styles}
          isClearable={value.some((option: SelectWithSearchValue) => !option?.isFixed)}
          name={name}
          placeholder={t('General_SearchAction')}
          classNamePrefix="select"
          onChange={onChange}
          options={options}
          hideSelectedOptions={false}
          components={{ NoOptionsMessage }}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
        />
      </FormControl>
    </Box>
  );
}
