export const COUNTRIES_ICON_PATH = '/icons/countries/';

export enum IMAGES {
  ErrorFallbackGeneral = '/images/error-fallback-general.svg',
  ErrorFallbackPage = '/images/file-not-found-icon.svg',
  ActionEngineComingSoon = '/images/action-engine-coming-soon.svg',
  AutofillAvailable = '/images/autofill_available.svg',
  AutofilledAnswer = '/images/autofilled_answer.svg',
  ExportFile = '/images/export_file.svg',
  NetworkError = '/images/illustration_500.png',
  ESGExport = '/images/esg-export.png',
  ESGExportWithTitle = '/images/esg-export-with-title.png',
  ESGExportWithTitleAndDesc = '/images/esg-export-with-title-desc.png',
  CollapseIcon = '/icons/collapse.svg',
}

export enum TechnologyIcons {
  energySource = '/icons/technology/route/energy_source.svg',
  energySourceV2 = '/icons/technology/route/energy_source_v2.svg',
  generator = '/icons/technology/route/generator.svg',
  storage = '/icons/technology/route/storage.svg',
  distribution = '/icons/technology/route/distribution.svg',

  heating = '/icons/technology/heating.svg',
  cooling = '/icons/technology/cooling.svg',
  lighting = '/icons/technology/lighting.svg',
  ventilation = '/icons/technology/ventilation.svg',
  warm_water = '/icons/technology/warm_water.svg',
  electricity = '/icons/technology/electricity.svg',
  solar = '/icons/technology/solar.svg',
}
