import { energy_distribution_type_enum, energy_system_type_enum } from '@predium/enums';

//
// DistributionTypesForSystemType - a mapping of supported distribution types for a given system type
//

export const DistributionTypesForSystemType: Record<energy_system_type_enum, energy_distribution_type_enum[]> = {
  [energy_system_type_enum.COOLING]: [
    energy_distribution_type_enum.THERMAL_COMPONENT_ACTIVATION,
    energy_distribution_type_enum.AIR_DUCTS_INTERNAL,
  ],
  [energy_system_type_enum.ELECTRICITY]: [],
  [energy_system_type_enum.GENERAL]: [],
  [energy_system_type_enum.HEATING]: [
    energy_distribution_type_enum.CENTRAL_INSIDE,
    energy_distribution_type_enum.CENTRAL_OUTSIDE,
  ],
  [energy_system_type_enum.HOT_WATER]: [
    energy_distribution_type_enum.CENTRAL_NO_CIRCULATION_INSIDE,
    energy_distribution_type_enum.CENTRAL_NO_CIRCULATION_OUTSIDE,
    energy_distribution_type_enum.CENTRAL_CIRCULATION_INSIDE,
    energy_distribution_type_enum.CENTRAL_CIRCULATION_OUTSIDE,
    energy_distribution_type_enum.DECENTRAL,
  ],
  [energy_system_type_enum.LIGHTING]: [],
  [energy_system_type_enum.SOLAR]: [],
  [energy_system_type_enum.VENTILATION]: [
    energy_distribution_type_enum.AIR_DUCTS_INTERNAL,
    energy_distribution_type_enum.FAN,
  ],
};
