/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQuery } from '@apollo/client';
import { DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import {
  DELETE_EMPTY_EU_AFTER_MOVE_BUILDINGS,
  MOVE_BUILDINGS_TO_ECONOMIC_UNIT,
} from '../../../graphql/DataCollection.mutations';
import {
  GET_ALL_BUILDINGS_WITH_SUB_BUILDINGS,
  GET_ECONOMIC_UNITS_WITH_BUILDINGS,
  IS_LAST_EU_FROM_BUILDINGS,
} from '../../../graphql/DataCollection.queries';
import { DeleteEmptyEconomicUnit } from './Common/DeleteEmptyEconomicUnit';
import EconomicUnitsListToMove from './EconomicUnitsToMove';

type props = {
  buildingIds: number[];
  economicUnitIds: number[];
  onMoveCompleted: () => void;
  onCancel: () => void;
};

export default function MoveBuildingsModal({ economicUnitIds, buildingIds, onMoveCompleted, onCancel }: props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [keepEmptyEu, setKeepEmptyEu] = useState(false);
  const [selectedEconomicUnitId, setSelectedEconomicUnitId] = useState<number | null>(null);

  const { data } = useQuery(IS_LAST_EU_FROM_BUILDINGS, {
    variables: {
      ids: buildingIds,
    },
  });
  const showErrorSnackbar = () => {
    enqueueSnackbar(t('DataCollection_MoveBuildingsDialog-error'), {
      variant: 'error',
      autoHideDuration: SnackbarTimeouts.Error,
    });
  };

  const [removeEmptyEuAfterMove] = useMutation(DELETE_EMPTY_EU_AFTER_MOVE_BUILDINGS, {
    refetchQueries: [{ query: GET_ECONOMIC_UNITS_WITH_BUILDINGS }, { query: GET_ALL_BUILDINGS_WITH_SUB_BUILDINGS }],
  });

  const [moveBuildings] = useMutation(MOVE_BUILDINGS_TO_ECONOMIC_UNIT, {
    variables: {
      ids: buildingIds,

      //@ts-ignore
      economicUnitId: selectedEconomicUnitId,
    },
    refetchQueries: [{ query: GET_ECONOMIC_UNITS_WITH_BUILDINGS }, { query: GET_ALL_BUILDINGS_WITH_SUB_BUILDINGS }],
    onError: () => showErrorSnackbar(),
    onCompleted: async (data) => {
      if (!data.update_building?.affected_rows) {
        showErrorSnackbar();
        return;
      }
      enqueueSnackbar(t('DataCollection_MoveBuildingsDialog-success', { count: data.update_building.affected_rows }), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });

      if (!keepEmptyEu && economicUnitIds.length) {
        await removeEmptyEuAfterMove({
          variables: {
            ids: economicUnitIds,
          },
        });
      }
      onMoveCompleted();
    },
  });

  return (
    <>
      <DialogTitle>{t('DataCollection_MoveBuildingsDialog-title', { count: buildingIds.length })}</DialogTitle>
      <EconomicUnitsListToMove
        sx={{
          justifyContent:
            data?.hasSingleBuildingOrDraft && data.hasSingleBuildingOrDraft ? 'space-between' : 'flex-end',
        }}
        selectedEconomicUnitId={selectedEconomicUnitId}
        setSelectedEconomicUnitId={setSelectedEconomicUnitId}
        onCancel={onCancel}
        onMove={moveBuildings}
      >
        {data?.hasSingleBuildingOrDraft && data.hasSingleBuildingOrDraft ? (
          <DeleteEmptyEconomicUnit economicUnitsToggled isBulkOperation setKeepEmptyEu={setKeepEmptyEu} />
        ) : null}
      </EconomicUnitsListToMove>
    </>
  );
}
