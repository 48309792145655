import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import { IMAGES } from '../../../assets';
import { ICONS } from '../../../assets/icons';
import Iconify from '../../Iconify';
import FormProvider from '../../hook-form/FormProvider';
import RHFTextField from '../../hook-form/RHFTextField';
import PreDialog, { PreDialogBody, PreDialogTitle } from '../../presentations/PreDialog/PreDialog';

export type PdfFormData = {
  title: string;
  description: string;
};

type Props = {
  open: boolean;
  onFormSubmit: (data: PdfFormData) => void;
  loading: boolean;
  dialogTitle: string;
  onCancel: () => void;
  descriptions: string[];
};

const ExportPdfDialog = ({ open, loading, onFormSubmit, onCancel, dialogTitle, descriptions }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [isTitleOpen, setIsTitleOpen] = useState(true);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);

  const PdfExportDataSchema = object().shape({
    title: string().when('description', {
      is: (description: string) => description?.length > 0,
      then: string().required(t('Validation_Required')).max(70, t('Validation_MaxLength')),
      otherwise: string().optional().max(70, t('Validation_MaxLength')),
    }),
    description: string().optional().max(140, t('Validation_MaxLength')),
  });

  const methods = useForm<PdfFormData>({
    defaultValues: { title: '', description: '' },
    resolver: yupResolver(PdfExportDataSchema),
    mode: 'onChange',
    shouldUnregister: true,
  });

  const { handleSubmit, watch } = methods;

  const handleToggleTitle = (_: unknown, checked: boolean) => {
    setIsTitleOpen(checked);
  };

  const handleToggleDescription = (_: unknown, checked: boolean) => {
    setIsDescriptionOpen(checked);
  };

  const title = watch('title');
  const isDescriptionDisabled = !title;

  const onSubmit = (values: PdfFormData) => {
    onFormSubmit(values);
  };

  return (
    <PreDialog
      sx={{ '.MuiPaper-root': { maxWidth: 1050 } }}
      open={open}
      onClose={onCancel}
      fullWidth
      type="definedByChildren"
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <PreDialogBody
          dialogtitle={<PreDialogTitle title={dialogTitle} />}
          content={
            <Stack direction="row" spacing={4}>
              <Box flex={1}>
                <img
                  height={600}
                  src={
                    isTitleOpen
                      ? isDescriptionOpen
                        ? IMAGES.ESGExportWithTitleAndDesc
                        : IMAGES.ESGExportWithTitle
                      : IMAGES.ESGExport
                  }
                  alt="the generated ESG export pdf with markings where the title and description will be."
                  style={{ width: '100%' }}
                />
              </Box>

              <Stack flex={1} spacing={3}>
                <Typography>
                  {descriptions[0]}
                  <br />
                  <br />
                  {descriptions[1]}
                </Typography>
                <Stack spacing={2}>
                  <Accordion
                    expanded={isTitleOpen}
                    disableGutters
                    style={{ boxShadow: 'none' }}
                    sx={{
                      '&:not(:last-child)': {
                        borderBottom: 0,
                      },
                      '&::before': {
                        display: 'none',
                      },
                      '.MuiAccordionSummary-root': {
                        padding: 0,
                      },
                    }}
                    TransitionProps={{
                      unmountOnExit: true,
                    }}
                  >
                    <AccordionSummary
                      sx={{
                        '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                          transform: 'unset',
                        },
                      }}
                      expandIcon={
                        <Switch
                          size="small"
                          sx={{ marginRight: -1 }}
                          checked={isTitleOpen}
                          onChange={handleToggleTitle}
                        />
                      }
                      onClick={() => setIsTitleOpen(!isTitleOpen)}
                      disabled={loading}
                    >
                      <Stack direction="row" alignItems="center" gap={1}>
                        <Typography
                          sx={{
                            background: theme.palette.primary.main,
                            borderRadius: 25,
                            textAlign: 'center',
                            width: 20,
                            height: 20,
                            fontSize: 14,
                            color: theme.palette.primary.contrastText,
                          }}
                        >
                          1
                        </Typography>
                        <Typography fontWeight={700}>{t('General_Title')}</Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      <RHFTextField
                        name="title"
                        fullWidth
                        variant="outlined"
                        placeholder={t('EsgAnalysis_ExportDialogInputPlaceholder_Title')}
                        characterLimit={70}
                        disabled={loading}
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Tooltip
                    title={isDescriptionDisabled ? t('EsgAnalysis_ExportDialogInput_TitleRequirement') : ''}
                    placement="top"
                    followCursor
                  >
                    <Accordion
                      expanded={!isDescriptionDisabled && isDescriptionOpen}
                      disableGutters
                      disabled={isDescriptionDisabled}
                      style={{ boxShadow: 'none' }}
                      sx={{
                        '&:not(:last-child)': {
                          borderBottom: 0,
                        },
                        '&::before': {
                          display: 'none',
                        },
                        '&.Mui-disabled .order-counter': {
                          bgcolor: theme.palette.action.disabledBackground,
                        },
                        '.MuiAccordionSummary-root': {
                          padding: 0,
                        },
                      }}
                      TransitionProps={{
                        unmountOnExit: true,
                      }}
                    >
                      <AccordionSummary
                        sx={{
                          '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                            transform: 'unset',
                          },
                        }}
                        expandIcon={
                          <Switch
                            size="small"
                            sx={{ marginRight: -1 }}
                            disabled={isDescriptionDisabled || loading}
                            checked={!isDescriptionDisabled && isDescriptionOpen}
                            onChange={handleToggleDescription}
                          />
                        }
                        onClick={() => setIsDescriptionOpen(!isDescriptionOpen)}
                      >
                        <Stack direction="row" alignItems="center" gap={1}>
                          <Typography
                            className="order-counter"
                            sx={{
                              background: theme.palette.primary.main,
                              borderRadius: 25,
                              textAlign: 'center',
                              width: 20,
                              height: 20,
                              fontSize: 14,
                              color: theme.palette.primary.contrastText,
                            }}
                          >
                            2
                          </Typography>
                          <Typography fontWeight={700}>{t('General_Subtitle')}</Typography>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails sx={{ p: 0 }}>
                        <RHFTextField
                          name="description"
                          fullWidth
                          variant="outlined"
                          characterLimit={140}
                          placeholder={t('EsgAnalysis_ExportDialogInputPlaceholder_Description')}
                          multiline
                          rows={3}
                          maxRows={5}
                          disabled={loading}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Tooltip>
                </Stack>
              </Stack>
            </Stack>
          }
          actions={
            <>
              <Button variant="outlined" onClick={onCancel}>
                {t('General_Cancel')}
              </Button>
              <LoadingButton
                sx={{
                  '.MuiButton-startIcon': {
                    marginRight: 1,
                  },
                  '.MuiLoadingButton-loadingIndicatorStart': {
                    left: 24,
                  },
                  minWidth: 160, // need to set a min width to avoid the button jumping around when in loading state
                }}
                loadingPosition="start"
                type="submit"
                variant="contained"
                loading={loading}
                //loadingPosition prop needs a start icon to avoid the console warning
                startIcon={<Iconify icon={ICONS.PDF_EXPORT} />}
              >
                {loading ? t('General_Exporting') : t('General_Export')}
              </LoadingButton>
            </>
          }
        />
      </FormProvider>
    </PreDialog>
  );
};

export default ExportPdfDialog;
