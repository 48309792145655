/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import { displayCost } from '../../../ActionPlan.utils';

export function RowEstimatedCosts(props: { estimatedCost: number }) {
  const { t } = useTranslation();
  const { estimatedCost } = props;

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset', whiteSpace: 'nowrap', width: '100%' } }}>
        <TableCell component="th" scope="row">
          <Typography variant="subtitle2" fontWeight={700}>
            {t('ActionPricingTotalCost_EstimatedCosts')}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant="subtitle2" fontWeight={700}>
            {estimatedCost ? displayCost(estimatedCost) : '-'}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
}
