import { Box, Stack, SxProps, Typography, useTheme } from '@mui/material';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/cjs/Page/AnnotationLayer.css'; // To display annotations correctly (e.g. links) in PDFs rendered by React-PDF https://github.com/wojtekmaj/react-pdf/tree/main#support-for-annotations
import 'react-pdf/dist/cjs/Page/TextLayer.css'; // Need it for supporting text layer, e.g: copy-pasting https://github.com/wojtekmaj/react-pdf/tree/main#support-for-text-layer
import Iconify from './Iconify';
import { DelayedLoading } from './Loading';
import { SemanticButton } from './Tile';

// For React-PDF to work, PDF.js worker needs to be provided. https://github.com/wojtekmaj/react-pdf/tree/main#configure-pdfjs-worker
pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

type Props = {
  fileURL?: string;
  /**
   * If `true`, the component will be displayed with scrollbar and it will fit the height of it's parent container.
   */
  compact?: boolean;
};

export default function PdfViewer({ fileURL, compact }: Props) {
  const { t } = useTranslation();

  /**
   * DocumentRenderError must be created within this component to make sure that the translation hook is called no matter if the component is rendered or not.
   * Otherwise this leads to crashing the whole app when the component is rendered.
   */
  const DocumentRenderError = () => {
    return (
      <Stack sx={{ alignItems: 'center', mb: 4 }}>
        <Iconify icon="material-symbols:error-rounded" width={48} height={48} />
        <Typography variant="body1" textAlign="center">
          {t('PdfViewer_RenderError')}
        </Typography>
      </Stack>
    );
  };

  const file = fileURL;
  const error = DocumentRenderError;
  // not perfectly centered but no control over the wrapper.
  // TODO fix this
  const loading = <DelayedLoading sx={{ marginTop: '50%' }} />;

  const [numPages, setNumPages] = useState<null | number>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const containerRef = useRef<HTMLDivElement>();
  const ref = createRef<HTMLDivElement>();

  const [width, setWidth] = useState<number | null>(null);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  const isPreviousPageAvailable = pageNumber > 1;
  const isNextPageAvailable = numPages !== null ? pageNumber < numPages : false;

  const content = (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        mt: 4,
        ...(compact && {
          maxWidth: '900px',
          mx: 'auto',
        }),
      }}
    >
      <Box
        ref={containerRef}
        sx={{
          mx: 4,
          ...(!file && {
            display: 'flex',
            alignItems: 'center',
          }),
        }}
      >
        <Document inputRef={ref} file={file} loading={loading} onLoadSuccess={onDocumentLoadSuccess} error={error}>
          <Page pageNumber={pageNumber} error={error} width={width ?? 0} loading={''} />
        </Document>
      </Box>
      {numPages && numPages > 0 && (
        <>
          {numPages > 1 && (
            <>
              <PageNavigationButton
                icon={'material-symbols:arrow-back-ios-new-rounded'}
                handleClick={() => setPageNumber(pageNumber + -1)}
                isAvailable={isPreviousPageAvailable}
                sx={{
                  left: 8,
                  zIndex: 10,
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              />

              <PageNavigationButton
                icon={'material-symbols:arrow-forward-ios-rounded'}
                handleClick={() => setPageNumber(pageNumber + 1)}
                isAvailable={isNextPageAvailable}
                sx={{
                  right: 8,
                  zIndex: 10,
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              />
            </>
          )}

          <PageNumberBox pageNumber={pageNumber} numPages={numPages} />
        </>
      )}
    </Box>
  );

  if (compact) {
    return (
      <Box overflow="auto" height="100%">
        {content}
      </Box>
    );
  }

  return content;
}

type PageNumberBoxProps = {
  pageNumber: number;
  numPages: number;
};

const PageNumberBox: React.FC<PageNumberBoxProps> = ({ pageNumber, numPages }) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        alignItems: 'center',
        my: 2,
      }}
    >
      <Typography
        variant="caption"
        sx={{
          color: theme.palette.common.white,
          background: theme.palette.primary.main,
          borderRadius: 10,
          fontWeight: 600,
          px: 1.5,
          py: 0.5,
        }}
      >
        {pageNumber} / {numPages}
      </Typography>
    </Stack>
  );
};

type PageNavigationButtonProps = {
  icon: string;
  handleClick: () => void;
  sx: SxProps;
  isAvailable: boolean;
};

const PageNavigationButton = ({ icon, handleClick, sx, isAvailable }: PageNavigationButtonProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1,
        ...sx,
      }}
    >
      <SemanticButton
        onClick={handleClick}
        disabled={!isAvailable}
        style={{ opacity: !isAvailable ? 0.5 : 1 }}
        type="button"
      >
        <Iconify
          icon={icon}
          sx={{
            color: theme.palette.common.white,
            background: theme.palette.primary.main,
            borderRadius: 12,
            p: 1,
            width: 32,
            height: 32,
          }}
          onClick={handleClick}
        />
      </SemanticButton>
    </Box>
  );
};
