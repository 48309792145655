import { tax_bracket_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateTaxBracketEnum = (enumValue: tax_bracket_enum) => {
  switch (enumValue) {
    case tax_bracket_enum._10_90:
      return <Trans i18nKey="Enum_TaxBracketEnum-_10_90" />;
    case tax_bracket_enum._20_80:
      return <Trans i18nKey="Enum_TaxBracketEnum-_20_80" />;
    case tax_bracket_enum._30_70:
      return <Trans i18nKey="Enum_TaxBracketEnum-_30_70" />;
    case tax_bracket_enum._40_60:
      return <Trans i18nKey="Enum_TaxBracketEnum-_40_60" />;
    case tax_bracket_enum._50_50:
      return <Trans i18nKey="Enum_TaxBracketEnum-_50_50" />;
    case tax_bracket_enum._60_40:
      return <Trans i18nKey="Enum_TaxBracketEnum-_60_40" />;
    case tax_bracket_enum._70_30:
      return <Trans i18nKey="Enum_TaxBracketEnum-_70_30" />;
    case tax_bracket_enum._80_20:
      return <Trans i18nKey="Enum_TaxBracketEnum-_80_20" />;
    case tax_bracket_enum._90_10:
      return <Trans i18nKey="Enum_TaxBracketEnum-_90_10" />;
    case tax_bracket_enum._100_0:
      return <Trans i18nKey="Enum_TaxBracketEnum-_100_0" />;
  }
};

export const translateTaxBracketEnum_dynamic = (
  enumValue: tax_bracket_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case tax_bracket_enum._10_90:
      return t('Enum_TaxBracketEnum-_10_90');
    case tax_bracket_enum._20_80:
      return t('Enum_TaxBracketEnum-_20_80');
    case tax_bracket_enum._30_70:
      return t('Enum_TaxBracketEnum-_30_70');
    case tax_bracket_enum._40_60:
      return t('Enum_TaxBracketEnum-_40_60');
    case tax_bracket_enum._50_50:
      return t('Enum_TaxBracketEnum-_50_50');
    case tax_bracket_enum._60_40:
      return t('Enum_TaxBracketEnum-_60_40');
    case tax_bracket_enum._70_30:
      return t('Enum_TaxBracketEnum-_70_30');
    case tax_bracket_enum._80_20:
      return t('Enum_TaxBracketEnum-_80_20');
    case tax_bracket_enum._90_10:
      return t('Enum_TaxBracketEnum-_90_10');
    case tax_bracket_enum._100_0:
      return t('Enum_TaxBracketEnum-_100_0');
  }
};
