import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Iconify from '../../components/Iconify';
import Logo from '../../components/Logo';
import { IconButtonAnimate } from '../../components/animate';
import HelpCenter from '../../components/helpcenter/HelpCenter';
import Notifications from '../../components/notifications/Notifications';
import { HEADER, NAVBAR, defaultSettings } from '../../config';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useResponsive from '../../hooks/useResponsive';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import NavbarVertical from './navbar/NavbarVertical';

// ----------------------------------------------------------------------

type MainStyleProps = {
  collapseHover: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseHover',
})<MainStyleProps>(({ collapseHover, theme }) => ({
  flexGrow: 1,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    marginLeft: collapseHover ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { collapseHover } = useCollapseDrawer();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = defaultSettings.themeLayout === 'vertical';

  if (verticalLayout) {
    return (
      <>
        {/* <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} /> */}

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        // display: { lg: 'flex' },
        // minHeight: { lg: 1 },
        minHeight: 1,
        display: 'flex',
      }}
    >
      <Box sx={{ height: '40px', position: 'absolute', padding: 2 }}>
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate
            data-cy="cy-settings-button-open"
            onClick={() => setOpen(true)}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
      </Box>

      {/* <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} /> */}

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseHover={collapseHover} sx={{ pb: 6 }}>
        {/* TODO: This should be exchanged with a real HeadMenu component at some point */}

        <Container
          maxWidth="lg"
          sx={{
            mb: `${HEADER.MOBILE_HEIGHT - 32}px`,
            width: '100%',
            marginTop: '16px',
            height: '40px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <HelpCenter />
          <Notifications />
        </Container>
        <Outlet />
      </MainStyle>
    </Box>
  );
}
