/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState } from 'react';
// @mui
import { useMutation } from '@apollo/client';
import {
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GetNotificationsQuery, graphql } from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../provider/LanguageProvider';
import { durationFromNow } from '../../utils/formatTime';
import Iconify from '../Iconify';
import MenuPopover from '../MenuPopover';
import { SnackbarTimeouts } from '../NotistackProvider';
import Scrollbar from '../Scrollbar';
import { IconButtonAnimate } from '../animate';

/**
 * Marks given notifications as read.
 */
const READ_NOTIFICATION = graphql(`
  mutation ReadNotifications($notificationIds: [Int!]!) {
    update_notification(where: { id: { _in: $notificationIds } }, _set: { read: true }) {
      returning {
        id
        __typename
        read
      }
    }
  }
`);

type Props = {
  notifications: GetNotificationsQuery['notification'];
};

/**
 * Button that fits the NavBar. Clicking the button shows a Popover Menu with a list of notifications.
 * These notifications are ordered for read/unread and recency.
 */
export default function NotificationsPopover({ notifications }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const [markNotifications] = useMutation(READ_NOTIFICATION, {
    // Update "read" in the cache with the response instead of making another query.

    //@ts-ignore
    update(cache, { data: { update_notification } }) {
      cache.modify({
        fields: {
          notification(existingNotifications: GetNotificationsQuery['notification']) {
            //@ts-ignore
            update_notification.returning.forEach((update) => {
              const index = existingNotifications.findIndex((notification) => notification.id === update.id);

              if (index !== -1) {
                existingNotifications[index].read = update.read;
              }
            });

            return existingNotifications;
          },
        },
      });
    },
    onError: () =>
      enqueueSnackbar(t('NotificationPopover_ReadNotification-Error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
  });

  const readNotifications = notifications.filter((item) => item.read);
  const unreadNotifications = notifications.filter((item) => item.read === false);
  const totalUnReadCount = unreadNotifications.length;

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleMarkAllAsRead = () => {
    markNotifications({
      variables: {
        notificationIds: unreadNotifications.map((notification) => notification.id),
      },
    });
  };

  const readSingleNotification = (notificationId: number) => {
    markNotifications({
      variables: {
        notificationIds: [notificationId],
      },
    });
  };

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnReadCount} color="error">
          <Iconify icon="mdi:bell-outline" />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        anchorEl={openPopover}
        onClose={handleClosePopover}
        handleClick={handleClosePopover}
        sx={{ width: 360, p: 0, overflow: 'hidden' }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 2,
            pl: 2.5,
            pr: 1,
          }}
        >
          <div>
            <Typography variant="subtitle1">{t('General_Notifications')}</Typography>

            {totalUnReadCount > 0 && (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('NotificationPopover_UnreadMessagesTitle', { count: totalUnReadCount })}
              </Typography>
            )}
          </div>

          {totalUnReadCount > 0 && (
            <Tooltip title={t('NotificationPopover_MarkMessagesAsRead')}>
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ maxHeight: 340 }}>
          {unreadNotifications.length > 0 && (
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  {t('General_New')}
                </ListSubheader>
              }
            >
              {unreadNotifications.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  readSingleNotification={readSingleNotification}
                />
              ))}
            </List>
          )}

          {readNotifications.length > 0 && (
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  {t('NotificationPopover_Read')}
                </ListSubheader>
              }
            >
              {readNotifications.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  readSingleNotification={readSingleNotification}
                />
              ))}
            </List>
          )}

          {totalUnReadCount === 0 && readNotifications.length === 0 && (
            <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
              {t('NotificationPopover_NoNotificationsAvailable')}
            </ListSubheader>
          )}
        </Scrollbar>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

type NotificationItemProps = {
  notification: GetNotificationsQuery['notification'][number];
  readSingleNotification: (notificationId: number) => void;
};

/**
 * Shows a single notification that is clickable if it is unread or a link.
 * Clicking the notification will mark it as read. A different end icon is displayed on the type of notification.
 */
function NotificationItem({ notification, readSingleNotification }: NotificationItemProps) {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();

  const { language } = useLanguage();

  const isUnread = !notification.read;

  const getActionButton = () => {
    if (notification.notification_template.href) {
      return (
        <IconButton
          edge="end"
          aria-label="comments"
          href={notification.notification_template.href}
          onClick={() => readSingleNotification(notification.id)}
          target="_blank"
        >
          <Iconify icon={'mingcute:external-link-line'} width={25} height={25} />
        </IconButton>
      );
    } else return null;
  };

  const description = notification.notification_template.description;
  const shouldTruncate = description.length > 500;

  const content = (
    <ListItemText
      disableTypography
      // description should get whole width minus the end icon and some padding if there is an icon.
      sx={{ width: `calc(100% - ${notification.notification_template.href ? '30px' : '0'})`, pl: 2 }}
      primary={
        <>
          {isUnread && (
            <Iconify
              icon={'ph:dot-outline-fill'}
              width={35}
              height={35}
              color={theme.palette.info.main}
              sx={{ position: 'absolute', left: 8, top: 6 }}
            />
          )}
          <Typography variant="subtitle2">{notification.notification_template.title}</Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {shouldTruncate && !expanded ? description.substring(0, 500) + '...' : description}

            {shouldTruncate && (
              <IconButton sx={{ padding: 0, marginLeft: 1 }} onClick={() => setExpanded(!expanded)}>
                {expanded ? (
                  <Iconify icon="material-symbols:arrow-drop-up-rounded" />
                ) : (
                  <Iconify icon="material-symbols:arrow-drop-down-rounded" />
                )}
              </IconButton>
            )}
          </Typography>
        </>
      }
      secondary={
        <Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled', alignItems: 'center' }}>
          <Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} />
          <Typography variant="caption">{durationFromNow(notification.created_at, language)}</Typography>
        </Stack>
      }
    />
  );

  return (
    <ListItem secondaryAction={getActionButton()} disablePadding>
      {isUnread ? (
        <ListItemButton
          onClick={() => {
            if (isUnread) {
              readSingleNotification(notification.id);
            }
          }}
          sx={{
            py: 1.5,
            px: 2.5,
            mt: '1px',
            ...(isUnread && {
              bgcolor: 'action.selected',
            }),
          }}
        >
          {content}
        </ListItemButton>
      ) : (
        <Box
          sx={{
            py: 1.5,
            px: 2.5,
            mt: '1px',
            width: '100%',
          }}
        >
          {content}
        </Box>
      )}
    </ListItem>
  );
}
