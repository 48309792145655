/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OtherFormValuesProps } from '../../BuildingEnvelope';
import { EnvelopeUnitField } from './EnvelopeUnitField';
import { FieldSectionTitle } from './EnvelopeUnitWrapper';

export function EnvelopeConstructionDetails({ data, index }: { data: OtherFormValuesProps; index: number }) {
  const { t } = useTranslation();
  const { envelope_type: type, id } = data;
  const fieldNamePrefix = `${type}[${index}]`;

  return (
    <>
      <Grid item xs={12}>
        <FieldSectionTitle title={t('General_BasicConstruction')} />
      </Grid>
      <Grid item xs={12}>
        <EnvelopeUnitField
          title={t('General_Type')}
          name={`${fieldNamePrefix}.base_construction_material_name`}
          //@ts-ignore
          id={id}
          fieldType="text"
        />
      </Grid>
      <Grid item xs={6}>
        <EnvelopeUnitField
          title={t('General_ComponentThickness')}
          name={`${fieldNamePrefix}.base_construction_thickness`}
          unit="cm"
          //@ts-ignore
          id={id}
          fieldType="number"
        />
      </Grid>
      <Grid item xs={6}>
        <EnvelopeUnitField
          title={t('General_ThermalConductivityLambda')}
          name={`${fieldNamePrefix}.base_construction_lambda`}
          //@ts-ignore
          id={id}
          fieldType="number"
          unit="W/(m·K)"
        />
      </Grid>
    </>
  );
}
