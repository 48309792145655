import { styled } from '@mui/material/styles';
import { MAPBOX_OTHER_BUILDINGS_LAYER_ID, MAPBOX_SELECTED_BUILDING_LAYER_ID } from '@predium/client-lookup';
import bbox from '@turf/bbox';
import center from '@turf/center';
import { featureCollection, union } from '@turf/turf';
import { Feature, MultiPolygon, Polygon } from 'geojson';
import uniq from 'lodash/uniq';
import mapboxgl, { Map } from 'mapbox-gl';
import { useSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../provider/LanguageProvider';
import useBuilding from '../../sections/DataCollection/Building/Context/useBuilding';
import addCustomMarkerToMap from './MapControlMarker';
import CustomNavigationControl from './MapNavigationControl';

declare global {
  interface Window {
    enableMapboxBuildingIdentification: boolean;
  }
}

window.enableMapboxBuildingIdentification = false;

const RootStyle = styled('div')(({ theme }) => ({
  zIndex: 0,
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  '& .mapboxgl-ctrl-logo, .mapboxgl-ctrl-bottom-right': {
    display: 'none',
  },
}));

export default function BuildingMap() {
  const { building } = useBuilding();
  const { address } = building;

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement | null>(null);
  const { language } = useLanguage();

  mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

  useEffect(() => {
    let selectedBuildings: Feature<Polygon>[] = [];

    let isFitBoundsActive = false;

    const { longitude, latitude } = address;

    if (ref?.current && typeof ref?.current !== 'undefined') {
      const map = Object.assign(
        new Map({
          container: 'map-container',
          //@ts-ignore
          center: [longitude, latitude],
          zoom: 16,
          pitch: 0,
          dragPan: window.enableMapboxBuildingIdentification,
          dragRotate: true,
          touchZoomRotate: false,
          doubleClickZoom: false,
          style: 'mapbox://styles/predium/clzgxumtz00h801qqek1f2mlh',
          config: {
            basemap: {
              show3dObjects: false,
            },
          },
        }),
        { language: 'de' },
      );

      if (address.mapbox_building_ids?.length < 1) {
        new mapboxgl.Popup({ closeOnClick: false, closeButton: false })
          .setLngLat([longitude!, latitude!])
          .setHTML(`<span style="font-size: 14px">${t('General_BuildingInformationNotAvailableMessage')}</span>`)
          .setMaxWidth('300px')
          .addTo(map);
      }

      map.scrollZoom.disable();

      const customNav = new CustomNavigationControl(
        { showCompass: true, showZoom: true, visualizePitch: true },
        t('MapNavigationControlRecenter_Title'),
        () => {
          recenterMap();
        },
        false,
      );

      map.addControl(customNav, 'top-right');

      //@ts-ignore
      const marker = addCustomMarkerToMap(longitude, latitude);

      map.on('render', () => {
        if (ref?.current && typeof ref?.current !== 'undefined') {
          //this works for german and english, but wont work for all languages
          const lang = language.slice(0, 2);
          map.language = lang;
        }
      });

      map.on('style.load', () => {
        (map as any)?.setConfigProperty('basemap', 'lightPreset', 'day');
        (map as any)?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
        (map as any)?.setConfigProperty('basemap', 'showRoadLabels', true);
        (map as any)?.setConfigProperty('basemap', 'show3dObjects', false);
      });

      map.on('zoom', () => {
        customNav.centerOff = true;
        customNav.updateButton();

        if (map.getZoom() < 15) {
          marker.addTo(map);
        } else {
          marker.remove();
        }
      });

      map.on('move', () => {
        customNav.centerOff = true;
        customNav.updateButton();
      });

      map.on('load', async () => {
        highlightBuilding();

        if (window.enableMapboxBuildingIdentification) {
          setupIdentificationScript();
        }
      });

      map.on('moveend', () => {
        if (isFitBoundsActive && map.getPitch().toFixed(0) === '55') {
          customNav.centerOff = false;
          customNav.updateButton();
          isFitBoundsActive = false;
        }
      });

      const highlightBuilding = () => {
        const ids = address.mapbox_building_ids.map((idsAsString: string) => parseInt(idsAsString));
        // 'as any' is needed because the filter type seems not to consider 'literal' to be expecting numbers
        const selectedBuildingsFilter = ['in', ['id'], ['literal', ids]] as any;
        selectedBuildings = map.querySourceFeatures('composite', {
          sourceLayer: 'building',
          filter: selectedBuildingsFilter,
        }) as Feature<Polygon>[];

        map.setFilter(MAPBOX_SELECTED_BUILDING_LAYER_ID, selectedBuildingsFilter);
        map.setFilter(MAPBOX_OTHER_BUILDINGS_LAYER_ID, ['!', selectedBuildingsFilter]);

        recenterMap();
      };

      const recenterMap = () => {
        if (selectedBuildings.length === 0) {
          console.error('No buildings selected');
          return;
        }

        const mergedPolygons = mergePolygons(selectedBuildings as any);

        if (mergedPolygons === null) {
          console.error('Could not merge polygons');
          return;
        }

        const bounds = bbox(mergedPolygons);
        const centerPoint = center(mergedPolygons).geometry.coordinates as [number, number];

        map.fitBounds(
          [
            [bounds[0], bounds[1]],
            [bounds[2], bounds[3]],
          ],
          {
            padding: { top: 10, bottom: 25, left: 15, right: 15 },
            center: centerPoint,
            // use user defined zoom but a little less because the pdf map is so small
            maxZoom: Math.max(
              16,
              address.mapbox_zoom ? address.mapbox_zoom - 0.5 : 17.5 - (selectedBuildings.length - 1) * 0.25,
            ),
            speed: 0.5,
            bearing: address.mapbox_bearing ?? 0,
            pitch: address.mapbox_pitch ?? 55,
            curve: 1,
            essential: true,
          },
        );
        isFitBoundsActive = true;
      };

      const setupIdentificationScript = () => {
        const script = document.createElement('script');
        script.src = 'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v5.0.0/mapbox-gl-geocoder.min.js';
        script.async = true;
        document.body.appendChild(script);

        const stylesheet = document.createElement('link');
        stylesheet.rel = 'stylesheet';
        stylesheet.href =
          'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v5.0.0/mapbox-gl-geocoder.css';
        stylesheet.type = 'text/css';

        setTimeout(() => {
          document.head.appendChild(stylesheet);
          // @ts-ignore
          const geocoder = new MapboxGeocoder({
            accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
            mapboxgl: mapboxgl,
            flyTo: true,
            marker: false,
            country: ['de', 'at', 'pl'],
          });
          map.addControl(geocoder);
        }, 5000);

        map.on('click', function (e) {
          // Get features at the point of click
          var features = map.queryRenderedFeatures(e.point, {
            layers: [MAPBOX_OTHER_BUILDINGS_LAYER_ID, MAPBOX_SELECTED_BUILDING_LAYER_ID],
          });

          if (features.length > 0) {
            // Assume the first feature is the building
            const building = features[0];
            const centroid = center(building as any);
            const ids = uniq(features.map((building) => building.id));
            enqueueSnackbar(
              'Building IDs: ' +
                ids.join(', ') +
                '\n' +
                'Longitude: ' +
                centroid.geometry.coordinates[0] +
                '\n' +
                'Latitude: ' +
                centroid.geometry.coordinates[1] +
                '\n' +
                'pitch: ' +
                map.getPitch() +
                '\n' +
                'bearing: ' +
                map.getBearing() +
                '\n' +
                'zoom: ' +
                map.getZoom(),
              {
                variant: 'info',
                persist: true,
                style: { whiteSpace: 'pre-line' },
              },
            );

            console.log('Building IDs:', ids);

            // Optional: Highlight the clicked building
            map.setFilter('highlighted-building', ['in', ['id'], ['literal', ids]] as any);
          } else {
            console.log('No buildings found at this location.');
          }
        });

        map.addLayer({
          id: 'highlighted-building',
          type: 'fill',
          source: 'composite',
          'source-layer': MAPBOX_SELECTED_BUILDING_LAYER_ID,
          paint: {
            'fill-color': '#f08',
            'fill-opacity': 0.75,
          },
          filter: ['==', 'id', ''], // Initial filter that does not match anything
        });
      };
    }
  }, [language, ref, t, enqueueSnackbar, address]);

  return (
    <RootStyle>
      <div
        id="map-container"
        style={{
          height: '100%',
          width: '100%',
          opacity: address.mapbox_building_ids?.length < 1 ? 0.7 : 1,
        }}
        ref={ref}
      />
    </RootStyle>
  );
}

function mergePolygons(polygons: Feature<Polygon | MultiPolygon>[]): Feature<Polygon | MultiPolygon> | null {
  if (polygons.length === 1) {
    return polygons[0];
  }
  return union(featureCollection(polygons));
}
