/* eslint-disable @typescript-eslint/ban-ts-comment */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, InputAdornment } from '@mui/material';
import {
  energy_consumer_technology_type_enum,
  energy_consumer_type_enum,
  energy_source_type_enum,
  energy_system_type_enum,
} from '@predium/client-graphql';
import { getConsumerEfficiency } from '@predium/client-lookup';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormProvider, RHFDatePicker, RHFNumberField } from '../../../../../../../components/hook-form';
import InfoTable, { InfoTableData } from '../../../../../../../components/presentations/InfoTable';
import { BuildingTabEnum } from '../../../../../../../pages/DataCollection/DataCollectionBuilding';
import { useLanguage } from '../../../../../../../provider/LanguageProvider';
import { createSystemInfoTable } from '../../../../../Scenario/scenarios.util';
import { getMaxDate } from '../../../../ActionPlan.utils';
import { NumberInputSx } from '../../../../Components/ActionCustomStyles';
import { TechnicalActionCreationProps, availableOptions } from '../../TechnicalAction';
import { LightingActionFormSchema } from '../../validation-schema';

export type LightingActionForm = {
  system_renovation_parameter: {
    efficiency: number | undefined;
    system_type: energy_system_type_enum;
    consumer_technology_type: energy_consumer_technology_type_enum;
    energy_source_type: energy_source_type_enum;
    technology_name: string;
    cost_per_m2: number;
  };
  renovation_ids: number[];
  implementation_to: DateTime | '';
};

const LightingAction = forwardRef(
  ({ previousActionBuildingModel, resetSimulatedData, minimumDate, loading }: TechnicalActionCreationProps, ref) => {
    const { language } = useLanguage();
    const { t } = useTranslation();

    const { energySources } = availableOptions(previousActionBuildingModel);
    const buildingId = previousActionBuildingModel.building!.id;

    const infoTableData: InfoTableData[] = createSystemInfoTable(
      energy_consumer_type_enum.LIGHTING,
      previousActionBuildingModel,
      t,
    );

    const efficiency = getConsumerEfficiency({
      energyConsumerTechnologyType: energy_consumer_technology_type_enum.LED,
      energySystemType: energy_system_type_enum.LIGHTING,
      area: 0, //irrelevant
      yearConstructed: 0, //irrelevant
    });

    const defaultValues = {
      system_renovation_parameter: {
        system_type: energy_system_type_enum.LIGHTING,
        efficiency,
        //TODO: consumer_technology_type and energy_source_type should be set backend if they are required. frontend don't have input for them
        consumer_technology_type: energy_consumer_technology_type_enum.LED,
        energy_source_type: energySources.includes(energy_source_type_enum.ELECTRICITY_GREEN)
          ? energy_source_type_enum.ELECTRICITY_GREEN
          : energy_source_type_enum.ELECTRICITY_MIX,
        technology_name: '',
      },
      implementation_to: '',
      //TODO: shouldn't we check here type of selected system? no check in the original code
      renovation_ids: previousActionBuildingModel.energy_systems.map((system) => system.id),
      cost_per_m2: 0,
    };

    const formMethods = useForm<LightingActionForm>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: defaultValues,
      resolver: yupResolver(LightingActionFormSchema(minimumDate, language)),
    });

    const { watch } = formMethods;

    useImperativeHandle(ref, () => ({
      methods: formMethods,
    }));

    const systemType = watch('system_renovation_parameter.system_type');
    const materialName = watch('system_renovation_parameter.technology_name');
    const energySourceType = watch('system_renovation_parameter.energy_source_type');
    const consumerTechnologyType = watch('system_renovation_parameter.consumer_technology_type');
    const implementationTo = watch('implementation_to');

    useEffect(() => {
      resetSimulatedData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [materialName, energySourceType, consumerTechnologyType, efficiency, systemType, implementationTo]);

    return (
      <FormProvider methods={formMethods}>
        <Box my={3}>
          {infoTableData && (
            <Box mb={3}>
              <InfoTable
                buildingId={buildingId}
                showTab={BuildingTabEnum.technology}
                data={infoTableData}
                title={t('General_StateBeforeRenovation')}
              />
            </Box>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} my={3}>
              <RHFNumberField
                name="system_renovation_parameter.efficiency"
                label={t('General_LightYield')}
                size={'small'}
                sx={NumberInputSx}
                disabled={loading}
                InputProps={{
                  endAdornment: <InputAdornment position="end">lm/W</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>

          <RHFDatePicker
            label={t('General_PlannedFor')}
            name="implementation_to"
            size={'small'}
            sx={NumberInputSx}
            disabled={loading}
            minDate={minimumDate}
            maxDate={getMaxDate()}
          />
        </Box>
      </FormProvider>
    );
  },
);

export default LightingAction;
