import { useTranslation } from 'react-i18next';
import PreToggleButton from '../../components/presentations/PreToggleButton';

type Props = {
  onDisplayBaseChange: (newDisplayBase: 'per_square_meter' | 'absolut') => void;
};

export default function EsgAnalysisUnitSwitcher({ onDisplayBaseChange }: Props) {
  const { t } = useTranslation();

  return (
    <PreToggleButton
      leftValue={{ key: 'per_square_meter', value: t('EsgAnalysisBenchmark_DisplayBasePerSquareMeter') }}
      rightValue={{ key: 'absolut', value: t('EsgAnalysisBenchmark_DisplayBaseAbsolut') }}
      initialValueType="per_square_meter"
      onToggle={onDisplayBaseChange}
      size="small"
      sx={{
        width: 130,
        '& .MuiToggleButton-root': {
          width: 60,
          fontSize: 12,
          fontWeight: 600,
          height: 30,
        },
      }}
    />
  );
}
