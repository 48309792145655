import { eu_taxonomy_compliance_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateEuTaxonomyComplianceEnum = (enumValue: eu_taxonomy_compliance_enum) => {
  switch (enumValue) {
    case eu_taxonomy_compliance_enum.COMPLIANT:
      return <Trans i18nKey="Enum_EuTaxonomyComplianceEnum-COMPLIANT" />;
    case eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA:
      return <Trans i18nKey="Enum_EuTaxonomyComplianceEnum-MEETS_DNSH_CRITERIA" />;
    case eu_taxonomy_compliance_enum.NON_COMPLIANT:
      return <Trans i18nKey="Enum_EuTaxonomyComplianceEnum-NON_COMPLIANT" />;
    case eu_taxonomy_compliance_enum.UNKNOWN:
      return <Trans i18nKey="Enum_EuTaxonomyComplianceEnum-UNKNOWN" />;
  }
};

export const translateEuTaxonomyComplianceEnum_dynamic = (
  enumValue: eu_taxonomy_compliance_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case eu_taxonomy_compliance_enum.COMPLIANT:
      return t('Enum_EuTaxonomyComplianceEnum-COMPLIANT');
    case eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA:
      return t('Enum_EuTaxonomyComplianceEnum-MEETS_DNSH_CRITERIA');
    case eu_taxonomy_compliance_enum.NON_COMPLIANT:
      return t('Enum_EuTaxonomyComplianceEnum-NON_COMPLIANT');
    case eu_taxonomy_compliance_enum.UNKNOWN:
      return t('Enum_EuTaxonomyComplianceEnum-UNKNOWN');
  }
};
