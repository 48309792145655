const ROOT = '/';

export const removeRootPath = (path: string) => {
  const index = path.indexOf(ROOT);

  // Get the route after root
  return path.substring(index + ROOT.length);
};

type URL = string;
export type URLParam = string | number;

/**
 * This hook offers type-safe access to a routes search params if defined in the PATHS object.
 *
 * Regex match one or more `/` characters and replace them with a single `/`.
 *
 * NOTE: URLSearchParams should be the last element in the array.
 */
export const joinPaths = (...paths: (URL | URLParam | URLSearchParams)[]) => {
  const searchParams = paths.at(-1);
  const hasSearchParams = searchParams instanceof URLSearchParams;

  const pathExcludingSearchParams = hasSearchParams ? paths.slice(0, -1) : paths;

  const url = pathExcludingSearchParams.join('/').replace(/\/+/g, '/');

  if (hasSearchParams && searchParams.toString() !== '') {
    return `${url}?${searchParams}`;
  }

  return url;
};
