import { orientation_enum } from '@predium/enums';

/**
 * Maps a numeric orientation range value for current db value.
 *
 * @param {number} orientation - The numeric orientation value (0 to 360).
 * @returns {orientation} - The corresponding orientation default value.
 */
export function getDefaultRangeForCurrentOrientation(orientation: number): number {
  orientation = orientation % 360;

  if ((orientation >= 337.5 && orientation <= 360) || (orientation >= 0 && orientation < 22.5)) {
    return 0;
  } else if (orientation >= 22.5 && orientation < 67.5) {
    return 45;
  } else if (orientation >= 67.5 && orientation < 112.5) {
    return 90;
  } else if (orientation >= 112.5 && orientation < 157.5) {
    return 135;
  } else if (orientation >= 157.5 && orientation < 202.5) {
    return 180;
  } else if (orientation >= 202.5 && orientation < 247.5) {
    return 225;
  } else if (orientation >= 247.5 && orientation < 292.5) {
    return 270;
  } else if (orientation >= 292.5 && orientation < 337.5) {
    return 315;
  }

  throw new Error('Invalid orientation value'); // unreachable
}

/**
 * Gets the numeric angle for a given orientation_enum value.
 *
 * @param orientation - The orientation_enum value.
 * @returns - The corresponding numeric angle (0 to 360).
 */
export function getOrientationAngle(orientation: orientation_enum): number {
  const orientationToAngle: Record<orientation_enum, number> = {
    [orientation_enum.NORTH]: 0,
    [orientation_enum.NORTH_EAST]: 45,
    [orientation_enum.EAST]: 90,
    [orientation_enum.SOUTH_EAST]: 135,
    [orientation_enum.SOUTH]: 180,
    [orientation_enum.SOUTH_WEST]: 225,
    [orientation_enum.WEST]: 270,
    [orientation_enum.NORTH_WEST]: 315,
    [orientation_enum.HORIZONTAL]: 0,
  };

  return orientationToAngle[orientation];
}

/**
 *
 * @param orientation
 * @param inclination
 * @returns given orientation and inclination returns the orientation from orientation_enum
 */
export function getOrientationEnum(orientation: number, inclination: number): orientation_enum {
  if (inclination === 0) {
    return orientation_enum.HORIZONTAL;
  }
  switch (true) {
    case (orientation >= 337.5 && orientation <= 360) || (orientation >= 0 && orientation < 22.5):
      return orientation_enum.NORTH;
    case orientation >= 22.5 && orientation < 67.5:
      return orientation_enum.NORTH_EAST;
    case orientation >= 67.5 && orientation < 112.5:
      return orientation_enum.EAST;
    case orientation >= 112.5 && orientation < 157.5:
      return orientation_enum.SOUTH_EAST;
    case orientation >= 157.5 && orientation < 202.5:
      return orientation_enum.SOUTH;
    case orientation >= 202.5 && orientation < 247.5:
      return orientation_enum.SOUTH_WEST;
    case orientation >= 247.5 && orientation < 292.5:
      return orientation_enum.WEST;
    case orientation >= 292.5 && orientation < 337.5:
      return orientation_enum.NORTH_WEST;
    default:
      throw new Error('Invalid orientation value');
  }
}

/**
 * The azimuth, or orientation, is the angle of the PV modules relative to the direction due South.
 *
 * -90° is East, 0° is South and 90° is West.
 *
 * Our orientation_enum is defined like this: North: 0°, East: 90°, South: 180°, West: 270° -> We need to convert this to the correct orientation by subtracting 180°.
 *
 * @param orientation The orientation (0 to 360)
 * @returns The corresponding azimuth angle in rad (-PI to PI).
 */
export function orientationToAzimuth(orientation: number): number {
  return ((orientation - 180) * Math.PI) / 180;
}
