/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { ObjectPermission } from '../../../../contexts/PermissionContext';
import { GET_PORTFOLIO_AND_BUILDING_PERMISSIONS } from '../../../../graphql/Permissions.queries';

export enum AccessRole {
  NO_ACCESS = 'NO_ACCESS',
  CONTRIBUTOR = 'CONTRIBUTOR',
  MANAGER = 'MANAGER',
}

/**
 * Get the role of the user based on the permissions.
 * @param permissions - The permissions of the user.
 * @returns The role of the user.
 */
export const getRoleByPermissions = (permissions?: ObjectPermission): AccessRole => {
  let currentRole: AccessRole;

  if (!permissions || (!permissions.read && !permissions.write)) {
    currentRole = AccessRole.NO_ACCESS;
  } else {
    currentRole = permissions.write && permissions.read ? AccessRole.MANAGER : AccessRole.CONTRIBUTOR;
  }

  return currentRole;
};

/**
 * Calculates the possible options of the role selector based on the currentRole or the values of the multi-selection
 * @param currentRole - The current role of the user.
 * @param allValues - The values of the multi-selection.
 * @param portfolioRole - The role of the portfolio.
 * @param isPortfolioLower - Whether the portfolio role is lower than the current role.
 * @returns
 */
export const getAvailableRolesByCurrentRole = (
  allValues: {
    role: AccessRole;
    portfolioRole: AccessRole;
  }[],
): AccessRole[] => {
  const getRole = (currentRole: AccessRole) => {
    switch (currentRole) {
      case AccessRole.NO_ACCESS:
        return [AccessRole.NO_ACCESS, AccessRole.CONTRIBUTOR, AccessRole.MANAGER];
      case AccessRole.CONTRIBUTOR:
        return [AccessRole.CONTRIBUTOR, AccessRole.MANAGER];
      case AccessRole.MANAGER:
        return [AccessRole.MANAGER];
      default:
        return [AccessRole.NO_ACCESS, AccessRole.CONTRIBUTOR, AccessRole.MANAGER];
    }
  };

  if (!allValues.length) return getRole(AccessRole.NO_ACCESS);

  const allRoles = allValues.map((role) => {
    return getRole(compareAccessRole(role.portfolioRole, role.role) < 0 ? role.portfolioRole : role.role);
  });
  //create an array that contains the roles that are included in every array in allRoles
  return allRoles.reduce((acc, val) => acc.filter((v) => val.includes(v)));
};

/**
 * Get the permissions of the user from the `GET_PORTFOLIO_AND_BUILDING_PERMISSIONS` query.
 * @param userId - The user id to get the permissions for.
 * @returns `portfolios` - The permissions of the user for the portfolios.
 * @returns `buildings` - The permissions of the user for the buildings.
 * @returns `loading` - Whether the query is still loading.
 */
export const useGetPermissionsFromQuery = (userId: number) => {
  const { data, loading } = useQuery(GET_PORTFOLIO_AND_BUILDING_PERMISSIONS, {
    variables: { user_id: userId },
  });

  //@ts-ignore
  const portfolios: ObjectPermission[] = data?.user_portfolio_permission.map((permission) => {
    return { id: permission.portfolio_id, read: permission.read, write: permission.write };
  });

  //@ts-ignore
  const buildings: ObjectPermission[] = data?.building.map((building) => {
    const relatedPortfolioPermission = portfolios.find((p) => p.id === building.economic_unit.portfolio_id);
    const relatedBuildingPermission = data?.user_building_permission.find((p) => p.building_id === building.id);
    const read = !!relatedPortfolioPermission?.read || !!relatedBuildingPermission?.read;
    const write = !!relatedPortfolioPermission?.write || !!relatedBuildingPermission?.write;
    return { id: building.id, read, write };
  });

  return { portfolios, buildings, loading };
};

export const getRoleFromSelection = (selectedPortfolioIds: number[], permissions: ObjectPermission[]) => {
  const selectedPermissions = selectedPortfolioIds.map((id) => permissions.find((p) => p.id === id));
  const allSameRead = selectedPermissions.every((p) => p?.read === selectedPermissions[0]?.read);
  const allSameWrite = selectedPermissions.every((p) => p?.write === selectedPermissions[0]?.write);

  if (allSameRead && allSameWrite) {
    return getRoleByPermissions(selectedPermissions[0]);
  }

  return 'mixed';
};

const compareAccessRole = (role1: AccessRole, role2: AccessRole) => {
  const role1Index = Object.keys(AccessRole).indexOf(role1);
  const role2Index = Object.keys(AccessRole).indexOf(role2);

  return role1Index - role2Index;
};
