import { TypedDocumentNode } from '@apollo/client';
import isNil from 'lodash/isNil';
import { isValidElement, ReactNode } from 'react';
import { IBaseColumn } from './Columns/BaseColumn';

// https://stackoverflow.com/questions/71096136/how-to-implement-a-gettypebypath-type-in-typescript
/**
 * Get a type by a key.
 *
 * It doesn't support path like 'a.b'.
 *
 * @example
 * type T = { a: { b: { c: string } } };
 * type A = Idx<T, 'a'>; // { b: { c: string } }
 */
type Idx<T, K extends string> = K extends keyof T
  ? T[K]
  : K extends `${number}`
  ? number extends keyof T
    ? T[number]
    : never
  : never;

/**
 * Get a type by a path.
 *
 * @example
 *
 * type T = { a: { b: { c: string } } };
 * type B = Get<T, 'a.b'>; // { c: string }
 * type C = Get<T, 'a.b.c'>; // string
 */
export type Get<T, K extends string> = T extends object
  ? K extends `${infer F}.${infer R}`
    ? Get<Idx<T, F>, R>
    : Idx<T, K>
  : never;

/**
 * Get the first key of an object. exclude keys that end with '_aggregate'.
 *
 * @example
 * type T = { building_aggregate: boolean; building: string;  };
 * type A = FirstKey<T>; // 'a'
 */
type FirstKey<T> = keyof {
  [K in keyof T as K extends `${string}_aggregate` ? never : {} extends { [P in K]: T[K] } ? never : K]: T[K];
};

/**
 * Gets the result type of a graphql query.
 * @template Query - The graphql query.
 *
 * @example
 * type Result = QueryResult<typeof GET_BUILDING>;
 */
export type QueryResult<T> = T extends TypedDocumentNode<infer ResultType, any>
  ? ResultType[FirstKey<ResultType>]
  : never;

/**
 * Gets the value type of a graphql query.
 *
 * @example
 * type IdType = QueryResultValue<typeof GET_BUILDING, 'id'>;
 */
export type QueryResultValue<T, ColumnKey extends string> = Get<QueryResult<T>[number], ColumnKey>;

/**
 * Gets the variables type of a graphql query.
 * @template Query - The graphql query.
 *
 * @example
 * type Variables = QueryVariables<typeof GET_BUILDING>;
 * const variables: Variables = { id: '1' };
 */
export type QueryVariables<T> = T extends TypedDocumentNode<any, infer VariablesType> ? Partial<VariablesType> : never;

/**
 * Gets the columns type for a graphql query to build the DataTable columns prop.
 *
 * @template TQuery - The graphql query.
 *
 * @example
 * const columns: TColumns<typeof GET_BUILDING> = [
 *  { id: 'id', label: 'ID', sortable: true },
 *  { id: 'name', label: 'Name', sortable: true },
 * ];
 */
export type DataTableColumns<T> = Partial<IBaseColumn<QueryResult<T>[number]>>[];

export type MaybeFunction<Arg, T> = T | ((arg: Arg) => T);

export const isValidReactNode = (value: any): value is ReactNode => {
  return (
    isNil(value) ||
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    isValidElement(value)
  );
};
