/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Card, Divider, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ModificationImpactsFragment, efficiency_class_enum } from '@predium/client-graphql';
import { t } from 'i18next';
import isNil from 'lodash/isNil';
import { ReactNode } from 'react';
import WidgetSummary from '../../../../components/data-visialization/WidgetSummary';
import { getEfficiencyClassColor } from '../ActionPlan.utils';
import ActionBeforeAfterChart from './ActionBeforeAfterChart';
import { EfficiencyClassAndYearContainer } from './EfficiencyClassAndYear';

export type StrandingDate = {
  after?: Date;
  before?: Date;
};

type CO2EmissionsProps = {
  isOverview: boolean;
  modificationImpact: ModificationImpactsFragment;
  isSimulated: boolean;
};

export function CO2Emissions({ isOverview, isSimulated, modificationImpact }: CO2EmissionsProps) {
  const theme = useTheme();

  const {
    co2_emissions: co2Emissions,
    efficiency_class: efficiencyClass,
    stranding_date: strandingDate,
  } = modificationImpact;

  const co2EmissionChartData = [co2Emissions?.before ?? undefined, co2Emissions?.after ?? undefined];

  const yearToShow = (date: DateTime | undefined): ReactNode => {
    return date ? <Typography>{new Date(date).getFullYear()}</Typography> : <Box pl={2}>-</Box>;
  };

  return (
    <Card>
      <Stack direction="row" sx={{ pl: 3, pt: 3 }}>
        <Typography variant="h6">{t('General_CO2Intensity')}</Typography>
        {!isNil(co2Emissions?.impact) ? <WidgetSummary percent={co2Emissions?.impact} /> : null}
      </Stack>

      <Stack>
        <Grid container spacing={1}>
          <Grid item xs={8} md={8} lg={8}>
            <Box px={2} py={isOverview ? 2 : 0}>
              <ActionBeforeAfterChart
                chartData={co2EmissionChartData}
                colors={getEfficiencyClassColor(
                  efficiencyClass?.before ?? efficiency_class_enum.UNKNOWN,
                  efficiencyClass?.after ?? efficiency_class_enum.UNKNOWN,
                  theme,
                )}
                unit={'kg CO₂e/m²a'}
                isSimulated={isSimulated}
              />
            </Box>
          </Grid>
          <Divider orientation="vertical" flexItem sx={{ mr: '-5px', mb: 2 }} variant="middle" />

          <Grid item xs={4} md={4} lg={4}>
            <Box p={isOverview ? 2 : 0}>
              <EfficiencyClassAndYearContainer
                title={t('General_StrandingDate15Celsius')}
                before={yearToShow(strandingDate?.before)}
                after={strandingDate?.after ? yearToShow(strandingDate?.after) : undefined}
              />
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
}
