import { MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../assets/icons';
import Iconify from '../../../components/Iconify';
import TableMoreMenu from '../../../components/table/TableMoreMenu';
import useSessionData from '../../../hooks/useSessionData';
import EmissionCertificateDraftDeleteModal from './EmissionCertificateDraftDeleteModal';

type Props = {
  filename: string;
  openEditModal: () => void;
  emissionCertificateDraftId: number;
};
export default function EmissionCertificateDraftActionMenu({
  filename,
  emissionCertificateDraftId,
  openEditModal,
}: Props) {
  const { t } = useTranslation();
  const { isAdmin } = useSessionData();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <>
      <TableMoreMenu
        closeOnClick
        actions={
          <>
            <MenuItem onClick={openEditModal}>
              <Iconify icon={'fa-regular:edit'} />
              {t('General_Edit')}
            </MenuItem>
            {!isAdmin || (
              <MenuItem onClick={() => setDeleteModalOpen(true)}>
                <Iconify icon={ICONS.TRASH} color="error.main" width={16} height={16} />
                <Typography variant="body2" color="error.main">
                  {t('General_Delete')}
                </Typography>
              </MenuItem>
            )}
          </>
        }
      />
      <EmissionCertificateDraftDeleteModal
        open={deleteModalOpen}
        filename={filename}
        onClose={() => setDeleteModalOpen(false)}
        emissionCertificateDraftId={emissionCertificateDraftId}
      />
    </>
  );
}
