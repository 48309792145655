import { t } from 'i18next';
import { WarningModal } from '../../../../components/WarningModal';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onAcknowledge: () => void;
};

const DiscardActionModal = ({ isOpen, onClose, onAcknowledge }: Props) => {
  return (
    <WarningModal
      open={isOpen}
      onClose={onClose}
      title={t('ActionPlanning_Action-DiscardActionTitle')}
      description={t('ActionPlanning_Action-DiscardActionDescription')}
      onAcknowledge={onAcknowledge}
      buttonText={t('ActionPlanning_Action-DiscardActionConfirmButton')}
      cancelText={t('ActionPlanning_Action-DiscardActionCancelButton')}
    />
  );
};

export default DiscardActionModal;
