/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button, MenuItem, Stack, TextField, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Iconify from '../../components/Iconify';
import { getTemplateMetadataForTemplateTitle } from './ReportingDataClasses/ReportTemplate.dc';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

type Props = {
  availableTemplates: { id: number; title: string }[];
  availableOwners: { label: string; value: number }[];
  selectedOwnerIdFilter: 'General_AllOwners' | number;
  setSelectedOwnerIdFilter: (value: null | number) => void;
  selectedTemplateFilter: string;
  setSelectedTemplateFilter: (value: string) => void;
  onCreateReport: VoidFunction;
};

export default function ReportingTableToolbar({
  availableTemplates,
  availableOwners,
  selectedOwnerIdFilter,
  setSelectedOwnerIdFilter,
  selectedTemplateFilter,
  setSelectedTemplateFilter,
  onCreateReport,
}: Props) {
  const { t } = useTranslation();

  return (
    <RootStyle>
      <Stack direction="row" gap={1}>
        <TextField
          select
          label={t('General_Template')}
          onChange={(event) => setSelectedTemplateFilter(event.target.value)}
          value={selectedTemplateFilter}
          style={{ width: 300 }}
        >
          <MenuItem value="Reporting_AllTemplates">{t('Reporting_AllTemplates')}</MenuItem>
          {availableTemplates.map(({ id, title }) => (
            <MenuItem key={id} value={title}>
              {getTemplateMetadataForTemplateTitle(title).readableTitle}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          label={t('General_Owner')}
          onChange={(event) => setSelectedOwnerIdFilter(event.target.value as any)}
          value={selectedOwnerIdFilter}
          style={{ width: 250 }}
        >
          <MenuItem value="General_AllOwners">{t('General_AllOwners')}</MenuItem>
          {availableOwners.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      <Button
        variant="contained"
        startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
        sx={{ py: 1.5 }}
        onClick={onCreateReport}
      >
        {t('Reporting_CreateReportButton')}
      </Button>
    </RootStyle>
  );
}
