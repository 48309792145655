/**
 * Access an enum value by its string representation. If the value is not a member of the enum, an error is thrown.
 * */
export function accessEnum<T extends object>(enumObject: T, value: string): T[keyof T] {
  const e = maybeEnum(enumObject, value);
  if (e === undefined) {
    throw new Error(`Value ${value} is not a member of the enum`);
  }
  return e;
}

/**
 * Access an enum value by its string representation. If the value is not a member of the enum, undefined is returned.
 */
export function maybeEnum<T extends object>(enumObject: T, value: string | null | undefined): T[keyof T] | undefined {
  if (value !== null && value !== undefined && Object.values(enumObject).includes(value)) {
    return value as T[keyof T];
  }
  return undefined;
}

/**
 * Like maybeEnum, but returns null instead of undefined.
 */
export function maybeEnumNull<T extends object>(enumObject: T, value: string | null | undefined): T[keyof T] | null {
  if (value !== null && value !== undefined && Object.values(enumObject).includes(value)) {
    return value as T[keyof T];
  }
  return null;
}
