import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputAdornment,
  List,
  ListItem,
  MenuItem,
  Stack,
  Tooltip,
} from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Fragment, PropsWithChildren, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Iconify from './Iconify';
import { FormProvider, RHFSelect } from './hook-form';
import PreDialog from './presentations/PreDialog/PreDialog';

type Props = {
  /** Whether the dialog is open or not. */
  open: boolean;
  /** Title that should be shown in heading. */
  title: string;
  /** Description that should be shown before associated objects. */
  description: string | React.ReactNode;
  /** Function that is called when the dialog is closed. */
  onClose: () => void;
  /** Function that is called when the dialog is confirmed. ATTENTTION! When overriding the button, you need to specify the behaviour in the onClick of the button instead of onDelete. */
  onDelete: () => Promise<void> | void;
  /** Array of objects that are associated with the object to be deleted. The user will see object type names (keys) and the number of associated objects (values). @default new Map() */
  associatedData?: Map<AssociatedDataType, number>;
  /** If set, an additional confirmation checkbox along with a warning will be shown. The delete confirmation text will be shown next to the checkbox. */
  deleteConfirmationText?: string;
  /** Alternate delete button. If not set, the standard red "Delete" button is used. */
  overrideDeleteButton?: JSX.Element;
  /** Function that is called when the user selects a new owner for the user to be deleted. */
  transferOwnershipSelection?: (value: any) => void;
  /** Array of objects representing the users that can take over the ownership of the user to be deleted. @default [] */
  ownershipOptions?: { id: number; first_name?: string; last_name?: string }[];
  /** The id of the user that will take over the ownership of the associated data to user to be deleted. @default '' */
  selectedOwnershipOptionId?: string;
  /** Map of tooltips for each associated data type. If a tooltip is provided, the associated data type will have an info icon next to it. @default new Map() */
  tooltips?: Map<AssociatedDataType, JSX.Element>;
  /** Whether to show a warning text in darker error color directly under the title */
  dataLossWarningText?: string;

  /**
   * If true, the primary button will be shown as loading
   */
  loading?: boolean;
};

// When creating a new associated data type, make sure to add a translation key to the getAssociatedDataTypeToTranslationKey function inside DeleteConfirmationModalUnified.
// Please make sure that each of the keys exists in the i18n translation file and has a plural form.
// Example: for the associated data {AssociatedDataType.ACTION: 2} we should have the following entries in the translation file: "General_Action_one", "General_Action_other".

export enum AssociatedDataType {
  BUILDING,
  ACTION,
  ACTION_LOWERCASE,
  ACTION_PLAN,
  REPORT,
  ENERGY_CERTIFICATE,
  SCENARIO,
  ECONOMIC_UNIT,
  CONSUMPTION,
  CONSUMPTION_DRAFT,
}

type DeleteModalFormValueProps = {
  selectedOwnershipOptionId: string;
  confirmed: boolean;
};

/**
 * Reuseable Delete Dialog. It is used to confirm the deletion of an object.
 */
export default function DeleteConfirmationModal({
  open = false,
  onClose,
  onDelete,
  associatedData = new Map(),
  deleteConfirmationText,
  title,
  description,
  overrideDeleteButton,
  transferOwnershipSelection,
  selectedOwnershipOptionId,
  ownershipOptions = [],
  tooltips = new Map(),
  dataLossWarningText,
  loading = false,
  children,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const methods = useForm<DeleteModalFormValueProps>({
    defaultValues: {
      selectedOwnershipOptionId,
      confirmed: false,
    },
  });
  const { handleSubmit, reset, watch, setValue } = methods;
  const selectedOwnershipOptionIdForm = watch('selectedOwnershipOptionId');
  const confirmed = watch('confirmed');

  /* Important! i18n translation sync will remove translation keys if never called.
  This means that we can't put the translation keys to an external "constants" file or similar.
  Also, something like this would not work:

  return t(BUILDING_CONSTANT, { count: value })

  This would result in the automatic removal of 'General_Building_one' and 'General_Building_other' from the translation file.

  see https://github.com/i18next/i18next-parser?tab=readme-ov-file#caveats

  Note that the associated data objects should be capitalized according to our design.
  
  Also note that this must be inside the functional component, as it depends on the t function
  */
  const getAssociatedDataTypeToTranslationKey = useCallback(
    (associatedDataType: AssociatedDataType, count: number): string => {
      switch (associatedDataType) {
        case AssociatedDataType.BUILDING:
          return t('General_Building', { count });
        case AssociatedDataType.ACTION:
          return t('General_Action', { count });
        case AssociatedDataType.ACTION_LOWERCASE:
          return t('General_action', { count });
        case AssociatedDataType.ACTION_PLAN:
          return t('General_ActionPlan', { count });
        case AssociatedDataType.REPORT:
          return t('General_Report', { count });
        case AssociatedDataType.ENERGY_CERTIFICATE:
          return t('General_EnergyCertificate', { count });
        case AssociatedDataType.SCENARIO:
          return t('General_Scenario', { count });
        case AssociatedDataType.ECONOMIC_UNIT:
          return t('General_EconomicUnit', { count });
        case AssociatedDataType.CONSUMPTION:
          return t('General_Invoice', { count });
        case AssociatedDataType.CONSUMPTION_DRAFT:
          return t('General_InvoiceDraft', { count });
        default:
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const exhaustiveCheck: never = associatedDataType;
          throw new Error(`Unhandled AssociatedDataType: ${exhaustiveCheck}`);
      }
    },
    [t],
  );

  const handleClose = () => {
    reset();
    onClose();
    transferOwnershipSelection?.(null);
  };

  const showAssociatedDataList = associatedData && associatedData.size > 0;

  const AssociatedDataListItem = ({
    associatedDataType,
    count,
  }: {
    associatedDataType: AssociatedDataType;
    count: number;
  }) => {
    return (
      <ListItem>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%', py: 1 }}>
          <Stack direction="row" alignItems="center" alignContent="center" spacing={1}>
            <Typography color="text.secondary">
              {getAssociatedDataTypeToTranslationKey(associatedDataType, count)}
            </Typography>
            {tooltips.has(associatedDataType) && (
              <Tooltip title={tooltips.get(associatedDataType)} placement="right" arrow>
                <div>
                  <Iconify
                    icon="zondicons:information-outline"
                    sx={{ color: 'text.secondary', margin: 'auto', display: 'block' }}
                  />
                </div>
              </Tooltip>
            )}
          </Stack>
          <Box>
            <strong>{count}</strong>
          </Box>
        </Stack>
      </ListItem>
    );
  };

  return (
    <FormProvider
      methods={methods}
      onSubmit={(event) => {
        event.stopPropagation();
        event.preventDefault();
        handleSubmit(onDelete)();
      }}
    >
      <PreDialog
        open={open}
        onClose={handleClose}
        type="definedByChildren"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '480px',
            },
          },
        }}
      >
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
          {dataLossWarningText ? (
            <Box sx={{ bgcolor: 'error.lighter', borderRadius: 1, color: 'error.darker' }} mb={3} p={2}>
              <Stack direction="row" spacing={2}>
                <Iconify icon="zondicons:exclamation-solid" width={24} height={24} sx={{ color: 'error.main' }} />
                <Typography>{dataLossWarningText}</Typography>
              </Stack>
            </Box>
          ) : null}
          {<Typography sx={{ mb: 3 }}>{description}</Typography>}

          {showAssociatedDataList ? (
            <List sx={{ border: 1, borderColor: 'divider', borderRadius: 1, py: 0 }}>
              {[...associatedData].map(([key, value], index) => {
                const showDivider = index <= associatedData.size - 2;
                return (
                  <Fragment key={key}>
                    <AssociatedDataListItem associatedDataType={key} count={value} />
                    {showDivider && <Divider component="li" variant="middle" key={`${key}_divider`} />}
                  </Fragment>
                );
              })}
            </List>
          ) : null}

          {children}

          {deleteConfirmationText ? (
            <Stack direction="row" alignItems="center" mt={3}>
              <Checkbox checked={confirmed} onChange={(event) => setValue('confirmed', event.target.checked)} />
              <Typography
                onClick={() => setValue('confirmed', !confirmed)}
                sx={{ userSelect: 'none', cursor: 'pointer' }}
              >
                {deleteConfirmationText}
              </Typography>
            </Stack>
          ) : null}

          {transferOwnershipSelection && showAssociatedDataList ? (
            <Box mt={3} sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <RHFSelect
                  name="selectedOwnershipOptionId"
                  label={t('General_NewResponsiblePerson')}
                  onValueChange={transferOwnershipSelection}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify
                          icon="mdi:account-circle-outline"
                          sx={{ color: 'action.active', width: 20, height: 20 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                >
                  {ownershipOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.first_name} {option.last_name}
                    </MenuItem>
                  ))}
                </RHFSelect>
                <Typography color="grey.600" variant="caption" pt={1} pl={1}>
                  {t('General_ResponsibilityReassignment')}
                </Typography>
              </FormControl>
            </Box>
          ) : null}
        </DialogContent>

        <DialogActions>
          <Button type="button" variant="outlined" onClick={handleClose}>
            {t('General_Cancel')}
          </Button>
          {overrideDeleteButton ?? (
            <LoadingButton
              loading={loading}
              color="error"
              type="submit"
              variant="contained"
              disabled={
                (deleteConfirmationText && !confirmed) ||
                (transferOwnershipSelection && showAssociatedDataList && !selectedOwnershipOptionIdForm)
              }
              onClick={() => handleSubmit(onDelete)()}
            >
              {t('General_Delete')}
            </LoadingButton>
          )}
        </DialogActions>
      </PreDialog>
    </FormProvider>
  );
}
