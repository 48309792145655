/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { CreatedEnergyCertificateDraftFragment } from '@predium/client-graphql';
import { building_state_enum } from '@predium/enums';
import { translateBuildingStateEnum } from '@predium/i18n/client';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Iconify from '../../../components/Iconify';
import InputStyle from '../../../components/InputStyle';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { WarningModal } from '../../../components/WarningModal';
import PreDialog from '../../../components/presentations/PreDialog/PreDialog';
import usePermissions from '../../../hooks/usePermissions';
import { PATHS } from '../../../routes';
import { ALL_PORTFOLIOS } from '../../EsgAnalysis/EsgAnalysisFilterBarPortfolio';
import UploadEnergyCertificateDraftModalCommon from '../Building/Drafts/UploadEnergyCertificateDraftModalCommon';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

// ----------------------------------------------------------------------

type Props = {
  filterName?: string;
  onFilterName?: (value: string) => void;
  handlePortfolioChange: (event: string) => void;
  selectedPortfolio: string;
  portfolioOptions: string[];
  selectedStatus?: building_state_enum | 'all';
  /**
   * Will open modal to create subBuilding if true.
   * We use this to dynamically open the modal after navigation.
   * Defaults to false.
   */
  shouldOpenCreateModal?: boolean;
  handleStatusChange?: (event: building_state_enum | 'all') => void;
  setCurrentTab?: () => void;
};

export default function DataCollectionToolbar({
  filterName,
  onFilterName,
  handlePortfolioChange,
  selectedPortfolio,
  portfolioOptions,
  selectedStatus,
  shouldOpenCreateModal = false,
  handleStatusChange,
  setCurrentTab,
}: Props) {
  const { t } = useTranslation();
  const { hasPortfolioWithWriteAccess } = usePermissions();
  const [uploadEnergyCertificateDraftModalOpen, setUploadEnergyCertificateDraftModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const createdEnergyCertificateDrafts = useRef<CreatedEnergyCertificateDraftFragment[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (shouldOpenCreateModal) {
      setUploadEnergyCertificateDraftModalOpen(true);
    }
  }, [shouldOpenCreateModal, setUploadEnergyCertificateDraftModalOpen]);

  const onEnergyCertificateDraftsAdded = (energyCertificateDraftIds: number[]) => {
    if (energyCertificateDraftIds.length > 0) {
      setUploadEnergyCertificateDraftModalOpen(false);
      createdEnergyCertificateDrafts.current = [];

      if (energyCertificateDraftIds.length === 1) {
        // Navigate to the newly created energyCertificateDraft
        navigate(PATHS.dataCollection.energyCertificateDraft({ id: energyCertificateDraftIds[0] }));
      } else {
        //@ts-ignore
        setCurrentTab();
      }

      enqueueSnackbar(t('General_DataUploadedSuccessMessage'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    }
  };

  return (
    <RootStyle>
      <Stack direction="row" sx={{ width: '100%' }}>
        <TextField
          select
          label={t('General_Portfolio')}
          value={selectedPortfolio}
          onChange={(event) => handlePortfolioChange(event.target.value)}
          sx={{
            minWidth: 250,
            mr: 3,
          }}
        >
          {portfolioOptions.map((portfolio) => (
            <MenuItem
              key={portfolio}
              value={portfolio}
              sx={{ typography: 'body2', mx: 1, my: 0.5, borderRadius: 0.75 }}
            >
              {portfolio === ALL_PORTFOLIOS ? t('General_AllPortfolios') : portfolio}
            </MenuItem>
          ))}
        </TextField>
        {selectedStatus && handleStatusChange && (
          <TextField
            select
            label={t('General_Status')}
            value={selectedStatus}
            onChange={(event) =>
              handleStatusChange(building_state_enum[event.target.value as building_state_enum] ?? 'all')
            }
            sx={{
              minWidth: 200,
              mr: 3,
            }}
          >
            <MenuItem key="all" value="all" sx={{ typography: 'body2', mx: 1, my: 0.5, borderRadius: 0.75 }}>
              {t('General_All')}
            </MenuItem>
            {Object.keys(building_state_enum).map((buildingStateKey) => (
              <MenuItem
                key={buildingStateKey}
                value={buildingStateKey}
                sx={{ typography: 'body2', mx: 1, my: 0.5, borderRadius: 0.75 }}
              >
                {translateBuildingStateEnum(building_state_enum[buildingStateKey as building_state_enum])}
              </MenuItem>
            ))}
          </TextField>
        )}
        {onFilterName && (
          <InputStyle
            value={filterName}
            onChange={(event) => onFilterName(event.target.value)}
            placeholder={t('General_SearchBuildingPlaceholder')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              ),
            }}
            sx={{ minWidth: 250 }}
          />
        )}

        {hasPortfolioWithWriteAccess && (
          <Button
            fullWidth={false}
            variant="contained"
            onClick={() => setUploadEnergyCertificateDraftModalOpen(true)}
            sx={{ marginLeft: 'auto' }}
            startIcon={<Iconify icon={'ic:baseline-plus'} />}
          >
            {t('General_AddBuilding')}
          </Button>
        )}
      </Stack>

      <WarningModal
        title={t('General_Note')}
        open={warningModalOpen}
        description={t('DataCollection_DraftsUpload_WarningMessage')}
        onAcknowledge={() => {
          setWarningModalOpen(false);

          //@ts-ignore
          setCurrentTab();
        }}
        onClose={() => {
          setWarningModalOpen(false);

          //@ts-ignore
          setCurrentTab();
        }}
        buttonText={t('General_Ok')}
      />
      <PreDialog
        type="definedByChildren"
        fullWidth
        open={uploadEnergyCertificateDraftModalOpen}
        onClose={() => {
          if (createdEnergyCertificateDrafts.current.length > 0) {
            setWarningModalOpen(true);
            createdEnergyCertificateDrafts.current = [];
          }
          setUploadEnergyCertificateDraftModalOpen(false);
        }}
      >
        <UploadEnergyCertificateDraftModalCommon
          onEnergyCertificateDraftsAdded={onEnergyCertificateDraftsAdded}
          createdEnergyCertificateDrafts={createdEnergyCertificateDrafts}
        />
      </PreDialog>
    </RootStyle>
  );
}
