import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IMAGES } from '../../../assets';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

type Props = {
  onToggleCollapse: VoidFunction;
  collapseHover: boolean;
};

export default function CollapseButton({ onToggleCollapse, collapseHover }: Props) {
  const { t } = useTranslation();

  return (
    <Tooltip title={!collapseHover ? t('General_CollapseSidebar') : t('General_LockSidebar')} placement="right">
      <IconButtonAnimate
        onClick={onToggleCollapse}
        className="collapse-button"
        sx={{
          transition: (theme) =>
            theme.transitions.create('opacity', {
              duration: theme.transitions.duration.shorter,
            }),
          // when sidebar get hovered, opacity goes to 100
          opacity: 0,
        }}
      >
        <img src={IMAGES.CollapseIcon} width={24} height={24} alt="" />
      </IconButtonAnimate>
    </Tooltip>
  );
}
