/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RHFMultiCheckbox } from '../../../../components/hook-form';
import { ReportQuestionDC } from '../../ReportingDataClasses/ReportQuestion.dc';
import ReportQuestion, { ReportQuestionSwitchProps, isAnsweredQuestion, isAutoFillQuestion } from '../ReportQuestion';

function MultipleChoiceQuestion({
  question,
  isSubQuestion,
  isEditable,
  autoFocus,
  formArrayProps,
  reportId,
}: ReportQuestionSwitchProps) {
  const { t } = useTranslation();
  const { watch, setValue, setFocus } = useFormContext();

  const { fields } = formArrayProps!;

  const indexInForm = fields.findIndex((field) => field._id === question.id);

  const multipleChoiceOptionIds: number[] = watch(`questions.${indexInForm}.multipleChoiceOptionIds`);

  //helper to have a list of subquestions of currently selected option
  let selectedOptions = question.options!.filter((option) => multipleChoiceOptionIds?.includes(option.id));

  //get the subquestions of all selected options
  const subQuestions: ReportQuestionDC[] = selectedOptions.reduce((acc, cur) => {
    if (cur.report_template_child_questions) {
      if (Array.isArray(cur.report_template_child_questions)) {
        return [...acc, ...cur.report_template_child_questions];
      } else {
        return [...acc, cur.report_template_child_questions];
      }
    } else {
      return acc;
    }
  }, [] as ReportQuestionDC[]);

  // Unfortunately RHF doesn't seem to sync the array value (watch shows it but its not in the submitted values)
  const handleChange = (values: number[]) => {
    setValue(`questions.${indexInForm}.multipleChoiceOptionIds`, values, { shouldValidate: true });
  };

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        setFocus(`questions.${indexInForm}.multipleChoiceOptionIds`);
        // TODO: I don't know why a timeout is needed here, but without it the focus is not set on consecutive questions.
        // In the worst case the focus might not get set which is not crucial.
      }, 250);
    }
  }, [autoFocus, indexInForm, setFocus]);

  const showHelperText = isAutoFillQuestion(question) && isAnsweredQuestion(question);

  return (
    <>
      {isSubQuestion && (
        <Box sx={{ ml: 1 }}>
          <Typography>{question.question}</Typography>
        </Box>
      )}
      <Stack spacing={3} sx={{ p: 3 }}>
        <RHFMultiCheckbox
          name={`questions.${indexInForm}.multipleChoiceOptionIds`}
          //@ts-ignore
          options={question.options.map((option) => ({ value: option.id, label: option.value }))}
          sx={{ width: 1 }}
          onChange={handleChange}
          disabled={!isEditable}
          helperText={showHelperText ? t('ReportQuestions_HelperText') : undefined}
        />
      </Stack>

      {subQuestions.map((subQuestion, index) => (
        <ReportQuestion
          key={subQuestion.id}
          reportId={reportId}
          question={subQuestion}
          isSubQuestion
          isEditable={isEditable}
          formArrayProps={formArrayProps}
          autoFocus={index === 0}
        />
      ))}
    </>
  );
}

export default MultipleChoiceQuestion;
