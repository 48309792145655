import { Box, ClickAwayListener, InputAdornment } from '@mui/material';
import { data_source_type_enum } from '@predium/client-graphql';
import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Iconify from '../Iconify';
import RHFNumberField, { RHFNumberFieldProps } from '../hook-form/RHFNumberField';
import Value from './Value';

export type InputProps = {
  name: string;
  suffix?: string;
  source?: data_source_type_enum;
  onValueChange?: (value: number) => void;
  onClickAway?: (arg: {
    /**
     * The user has typed a different value while in edit mode.
     */
    hasChangedByUser: boolean;
    /**
     * If the value is different from the initial form value.
     */
    isDirty: boolean;
  }) => void;
  numberFieldProps?: Partial<RHFNumberFieldProps>;
  showTooltip?: boolean;
  isLoading?: boolean;
  ignoreIsDirty?: boolean;
};

const Input = ({
  name,
  source,
  suffix = '',
  numberFieldProps,
  onValueChange,
  onClickAway,
  showTooltip,
  isLoading = false,
  ignoreIsDirty = false,
}: InputProps) => {
  const [editMode, setEditMode] = useState(false);
  const { watch, getFieldState } = useFormContext();

  /**
   * Store the value before entering edit mode.
   * This is used to determine if the value has changed when the user clicks away.
   */
  const tempValue = useRef<number | null>(null);

  const value = watch(name);

  const isDirty = getFieldState(name).isDirty;

  const handleOpenEditMode = () => {
    setEditMode(true);
    tempValue.current = value;
  };

  const handleClickAway = () => {
    if (!editMode) {
      return;
    }

    setEditMode(false);

    onClickAway?.({
      hasChangedByUser: value !== tempValue.current,
      isDirty,
    });
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        {!editMode && (
          <Value
            value={value}
            source={source}
            suffix={suffix}
            sx={{
              cursor: 'pointer',
              borderRadius: 0.75,
              '&:hover': {
                bgcolor: 'white',
              },
            }}
            onClick={handleOpenEditMode}
            showTooltip={showTooltip}
            isLoading={isLoading}
            isEdited={!ignoreIsDirty && isDirty}
          />
        )}

        {editMode && (
          <RHFNumberField
            name={name}
            size="small"
            sx={{ width: 170 }}
            decimalScale={2}
            onEnter={handleClickAway}
            onValueChange={({ floatValue }) => onValueChange?.(floatValue ?? 0)}
            InputProps={{
              endAdornment: <InputAdornment position="end">{suffix}</InputAdornment>,
              startAdornment: <Iconify fontSize={40} color="success.main" icon="mdi:pencil-box" mr={1} />,
              autoComplete: 'off',
              autoFocus: true,
            }}
            {...numberFieldProps}
          />
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default Input;
