import { ChipProps, alpha, useTheme } from '@mui/material';
import { data_source_type_enum } from '@predium/enums';
import PreChip from '../../../../components/presentations/PreChip';

interface Props {
  type: data_source_type_enum;
  value: ChipProps['label'];
}

const DataSourceTypeChip = ({ type, value }: Props) => {
  const theme = useTheme();

  return type === data_source_type_enum.MANUAL ? (
    <PreChip
      startIcon="mdi:pencil-box"
      backgroundColor={alpha(theme.palette.success.main, 0.16)}
      customColor={theme.palette.success.dark}
      label={value}
    />
  ) : (
    <PreChip
      startIcon="mdi:chart-timeline-variant-shimmer"
      backgroundColor={alpha(theme.palette.info.main, 0.16)}
      customColor={theme.palette.info.dark}
      label={value}
    />
  );
};

export default DataSourceTypeChip;
