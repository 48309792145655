/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { consumption_type_enum } from '@predium/client-graphql';
import { translateConsumptionTypeEnum } from '@predium/i18n/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../components/Iconify';
import { DelayedLoading } from '../../../components/Loading';
import { GET_CONSUMPTION_SUMMARIES } from '../../../graphql/DataCollection.queries';
import SubBuildingConsumptionElectricity from './Consumption/SubBuildingConsumptionElectricity';
import SubBuildingConsumptionHeating from './Consumption/SubBuildingConsumptionHeating';
import SubBuildingConsumptionListTabs from './Consumption/SubBuildingConsumptionListTabs';
import SubBuildingConsumptionWaste from './Consumption/SubBuildingConsumptionWaste';
import SubBuildingConsumptionWater from './Consumption/SubBuildingConsumptionWater';
import useBuilding from './Context/useBuilding';

const currentYear = new Date().getFullYear();
const YEARS = [currentYear - 4, currentYear - 3, currentYear - 2, currentYear - 1, currentYear];

export function getConsumptionCategoryIconName(category: consumption_type_enum): string {
  let exhaustiveCheck: never;

  switch (category) {
    case consumption_type_enum.HEATING:
      return 'la:fire-alt';
    case consumption_type_enum.ELECTRICITY:
      return 'heroicons-outline:lightning-bolt';
    case consumption_type_enum.WASTE:
      return 'mdi:trash-can-outline';
    case consumption_type_enum.WATER:
      return 'akar-icons:water';
    default:
      exhaustiveCheck = category;
      throw new Error(`Unhandled consumption_type_enum: ${exhaustiveCheck}`);
  }
}

export default function SubBuildingConsumption() {
  const { t } = useTranslation();
  const [selectedYear, setSelectedYear] = useState(YEARS[YEARS.length - 1]);
  const { building } = useBuilding();

  // QUERY / MUTATION
  const { data } = useQuery(GET_CONSUMPTION_SUMMARIES, {
    variables: { buildingId: building.id, year: selectedYear },
  });

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedYear(parseInt(event.target.value)); //refetch happening through state change
  };
  if (!data) return <DelayedLoading />;

  const SECTIONS = [
    {
      label: translateConsumptionTypeEnum(consumption_type_enum.HEATING),
      icon: <Iconify icon={getConsumptionCategoryIconName(consumption_type_enum.HEATING)} width={50} height={50} />,
      component: (
        <SubBuildingConsumptionHeating
          //@ts-ignore
          consumptionSummary={data.getYearlyConsumptionSummary.consumptionCategories.find(
            (category) => category.consumption_type === consumption_type_enum.HEATING,
          )}
        />
      ),
    },
    {
      label: translateConsumptionTypeEnum(consumption_type_enum.ELECTRICITY),
      icon: <Iconify icon={getConsumptionCategoryIconName(consumption_type_enum.ELECTRICITY)} width={50} height={50} />,
      component: (
        <SubBuildingConsumptionElectricity
          //@ts-ignore
          consumptionSummary={data.getYearlyConsumptionSummary.consumptionCategories.find(
            (category) => category.consumption_type === consumption_type_enum.ELECTRICITY,
          )}
        />
      ),
    },

    {
      label: translateConsumptionTypeEnum(consumption_type_enum.WATER),
      icon: <Iconify icon={getConsumptionCategoryIconName(consumption_type_enum.WATER)} width={50} height={50} />,
      component: (
        <SubBuildingConsumptionWater
          //@ts-ignore
          consumptionSummary={data.getYearlyConsumptionSummary.consumptionCategories.find(
            (category) => category.consumption_type === consumption_type_enum.WATER,
          )}
        />
      ),
    },
    {
      label: translateConsumptionTypeEnum(consumption_type_enum.WASTE),
      icon: <Iconify icon={getConsumptionCategoryIconName(consumption_type_enum.WASTE)} width={50} height={50} />,
      component: (
        <SubBuildingConsumptionWaste
          //@ts-ignore
          consumptionSummary={data.getYearlyConsumptionSummary.consumptionCategories.find(
            (category) => category.consumption_type === consumption_type_enum.WASTE,
          )}
        />
      ),
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item md={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
        <Typography variant="h6" sx={{ mr: 2 }}>
          {t('General_ReportingYear')}:
        </Typography>

        <Select value={selectedYear.toString()} onChange={handleChange}>
          {YEARS.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      {SECTIONS.map((section, index) => (
        <Grid item xs={12} md={6} key={index}>
          <Card sx={{ p: 3, height: '100%' }}>
            <Grid container>
              <Grid item md={1} sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                {section.icon}
              </Grid>
              <Grid item md={11}>
                {section.component}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ))}
      <Grid item md={12}>
        <Card sx={{ p: 3 }}>
          <SubBuildingConsumptionListTabs />
        </Card>
      </Grid>
    </Grid>
  );
}
