import {
  Card,
  IconButton,
  SxProps,
  Theme,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { ReactNode } from 'react';
import Iconify from './Iconify';

interface TransparentTooltipProps {
  maxwidth?: number;
}

const TransparentPopperTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ maxwidth }: TransparentTooltipProps) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    /* HACK: make the original popper component invisible so we replace it with a card */
    backgroundColor: 'transparent',
    color: 'transparent',
    maxWidth: maxwidth,
    padding: 0,
    margin: 0,
  },
}));

interface Props {
  text: ReactNode;
  maxwidth?: number;
  sx?: SxProps<Theme>;
}

const InfoTooltip = ({ text, maxwidth = 500, sx }: Props) => (
  <TransparentPopperTooltip
    maxwidth={maxwidth}
    placement="bottom-start"
    title={
      <>
        <Card sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', p: 2 }}>
          <Typography sx={{ pl: 2, whiteSpace: 'pre-line' }}>{text}</Typography>
        </Card>
      </>
    }
  >
    {/* TODO something else instead of a button */}
    <IconButton disableRipple sx={{ cursor: 'default', ...sx }}>
      <Iconify icon={'material-symbols:info-outline'} width={25} height={25} />
    </IconButton>
  </TransparentPopperTooltip>
);

export default InfoTooltip;
