import { Button, IconButton } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../Iconify';

type Props = {
  /**
   * resolves the promise for the Apollo RetryLink
   */
  resolve: (value: boolean | PromiseLike<boolean>) => void;
  snackbarKey: SnackbarKey;
};

function NetworkRetrySnackbarAction({ resolve, snackbarKey }: Props) {
  const { t } = useTranslation();
  const { closeSnackbar } = useSnackbar();

  const [isOnline, setIsOnline] = useState(() => navigator.onLine);

  const onClose = (key: SnackbarKey) => {
    resolve(true);
    closeSnackbar(key);
  };

  const setOnline = () => {
    setIsOnline(true);
  };

  const setOffline = () => {
    setIsOnline(false);
  };

  useEffect(() => {
    // Enable "retry" button only when there is a stable internet connection
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      // remove event listeners on unmount
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  });

  return (
    <>
      <Button size="small" onClick={() => onClose(snackbarKey)} sx={{ p: 0.5 }} disabled={!isOnline}>
        {t('Snackbars_NetworkRetryButtonLabel')}
      </Button>
      <IconButton size="small" onClick={() => closeSnackbar(snackbarKey)} sx={{ p: 0.5 }}>
        <Iconify icon="eva:close-fill" />
      </IconButton>
    </>
  );
}

export default NetworkRetrySnackbarAction;
