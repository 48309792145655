import { graphql } from '@predium/client-graphql';

/*
   Upsert a org preferences: updating economicUnitEnabled if they already exist else inserting a new row if doesn't exist
*/
export const UPDATE_ORG_PREFERENCE_FOR_ECONOMIC_UNIT = graphql(`
  mutation UpdateOrgePreferenceForEconomicUnit($orgId: Int!, $economicUnitEnabled: Boolean!) {
    update_org_preference(where: { org_id: { _eq: $orgId } }, _set: { economic_unit_enabled: $economicUnitEnabled }) {
      returning {
        id
        emission_factor_type_id
      }
    }
  }
`);

export const UPDATE_ORG_PREFERENCE_EMISSION_FACTOR_TYPE = graphql(`
  mutation UpdateOrgPreference($orgId: Int, $emissionFactorTypeId: emission_factor_type_enum) {
    update_org_preference(
      where: { org_id: { _eq: $orgId } }
      _set: { emission_factor_type_id: $emissionFactorTypeId }
    ) {
      returning {
        id
        emission_factor_type_id
      }
    }
  }
`);
