/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Grid, Stack } from '@mui/material';
import {
  DataCollectionEconomicUnitFragment,
  EsgAnalysisGetAllBuildingFilterValuesQuery,
  EsgAnalysisGetBuildingsPortfolioAndAddressFragment,
} from '@predium/client-graphql';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Scrollbar from '../../components/Scrollbar';
import SelectMultiWithSearch, { SelectWithSearchValue } from '../../components/SelectMultiWithSearch';
import useEsgAnalysisFilters from '../../hooks/useEsgAnalysisFilters';
import usePosthogTracking from '../../hooks/usePosthogTracking';
import EsgAnalysisFilterBarBuildingFilter from './EsgAnalysisFilterBarBuildingFilter';
import { EsgAnalysisFiltersKeys } from './EsgAnalysisFilters/EsgAnalysisFilters';
import EsgAnalysisFiltersSidebar from './EsgAnalysisFilters/EsgAnalysisFiltersSidebar';
import EsgAnalysisFiltersTags from './EsgAnalysisFilters/EsgAnalysisFiltersTags';

type Props = {
  economicUnits: DataCollectionEconomicUnitFragment[];
  buildings: EsgAnalysisGetBuildingsPortfolioAndAddressFragment[];
  filtersList: EsgAnalysisGetAllBuildingFilterValuesQuery;
};

const ALL_ECONOMIC_UNITS = 'allEconomicUnits';

export default function EsgAnalysisFilterBarEconomicUnits({ economicUnits, buildings, filtersList }: Props) {
  const { t } = useTranslation();
  const { trackEvent } = usePosthogTracking();
  const [openFilter, setOpenFilter] = useState(false);
  const defaultEconomicUnitValue: SelectWithSearchValue = {
    label: t('General_AllEconomicUnits'),
    value: ALL_ECONOMIC_UNITS,
    isFixed: true,
  };

  const { filters, setFilter, resetAllFilters, resetFilter } = useEsgAnalysisFilters();

  /**
   * Creates each option for the economicUnits select
   */
  const mapToEconomicUnitSelectOption = (
    economicUnit: DataCollectionEconomicUnitFragment,
  ): { value: number; label: string } => {
    return {
      value: economicUnit.id,
      label: economicUnit.name,
    };
  };

  const getEconomicUnitFromIds = (ids: number[]) => {
    return economicUnits.filter((economicUnit) => ids.includes(economicUnit.id)).map(mapToEconomicUnitSelectOption);
  };

  const onEconomicUnitSelected = (selectedEconomicUnit: SelectWithSearchValue) => {
    if (selectedEconomicUnit.value === ALL_ECONOMIC_UNITS) {
      resetFilter(EsgAnalysisFiltersKeys.economicUnitIds);
    } else {
      const foundEconomicUnit = economicUnits.find(
        (economicUnit: DataCollectionEconomicUnitFragment) => economicUnit.id === selectedEconomicUnit.value,
      );
      if (!foundEconomicUnit) {
        // Don't change economic unit filters if the selected economic unit is not found
        resetFilter(EsgAnalysisFiltersKeys.economicUnitIds);
        return;
      }

      setFilter(EsgAnalysisFiltersKeys.economicUnitIds, foundEconomicUnit.id);
      resetFilter(EsgAnalysisFiltersKeys.buildingIds);
    }

    trackEvent('ECONOMIC_UNIT_SELECTED', {
      economic_unit_id: selectedEconomicUnit.value,
    });
  };

  return (
    <Grid container alignItems={'center'}>
      <Grid item xs={12} md={10} lg={10}>
        <Scrollbar>
          <Stack direction="row" height={72} alignItems={'center'} my={1}>
            <SelectMultiWithSearch
              name={t('EsgAnalysisFilterBarEconomicUnits_EconomicUnitsSelect')}
              options={[defaultEconomicUnitValue, ...economicUnits.map(mapToEconomicUnitSelectOption)]}
              onSelect={onEconomicUnitSelected}
              defaultValue={defaultEconomicUnitValue}
              //@ts-ignore
              selectedValues={getEconomicUnitFromIds(filters.economicUnitIds)}
              sx={{ minWidth: 400 }}
              onResetAll={() => resetFilter(EsgAnalysisFiltersKeys.economicUnitIds)}
              noDataText={t('EsgAnalysisFilterBarEconomicUnits_NoEconomicUnitsFound')}
            />
            <EsgAnalysisFilterBarBuildingFilter buildings={buildings} />
            <EsgAnalysisFiltersTags />
          </Stack>
        </Scrollbar>
      </Grid>
      <Grid item xs={12} md={2} lg={2}>
        <Stack direction="row" justifyContent={'end'}>
          <EsgAnalysisFiltersSidebar
            onResetAll={resetAllFilters}
            isOpen={openFilter}
            onOpen={() => setOpenFilter(true)}
            onClose={() => setOpenFilter(false)}
            filtersList={filtersList}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}
