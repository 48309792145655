/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQuery } from '@apollo/client';
import { PortfolioManagementPortfolioFragment } from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import DeleteConfirmationModal, { AssociatedDataType } from '../../../components/DeleteConfirmationModal';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { DELETE_PORTFOLIO } from '../../../graphql/Portfolio.mutations';
import { GET_PORTFOLIO_OBJECTS_AGGREGATE } from '../../../graphql/Portfolio.queries';
import useTooltipConstants from '../../../hooks/useTooltipConstants';

type Props = {
  portfolio: PortfolioManagementPortfolioFragment | null;
  onClose: () => void;
  open: boolean;
};

export default function PortfolioDeleteDialog({ portfolio, open, onClose }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { tooltips } = useTooltipConstants();

  const { data } = useQuery(GET_PORTFOLIO_OBJECTS_AGGREGATE, {
    skip: !open,

    //@ts-ignore
    variables: { portfolioId: portfolio?.id },
  });

  const associatedData = useMemo(() => {
    const map = new Map();
    if ((data?.building_aggregate?.aggregate?.count ?? 0) > 0) {
      //@ts-ignore

      //@ts-ignore
      map.set(AssociatedDataType.BUILDING, data.building_aggregate.aggregate.count);
    }
    if ((data?.energy_certificate_draft_aggregate?.aggregate?.count ?? 0) > 0) {
      //@ts-ignore

      //@ts-ignore
      map.set(AssociatedDataType.ENERGY_CERTIFICATE, data.energy_certificate_draft_aggregate.aggregate.count);
    }
    if ((data?.report_aggregate?.aggregate?.count ?? 0) > 0) {
      //@ts-ignore

      //@ts-ignore
      map.set(AssociatedDataType.REPORT, data.report_aggregate.aggregate.count);
    }
    if ((data?.economic_unit_aggregate?.aggregate?.count ?? 0) > 0) {
      //@ts-ignore

      //@ts-ignore
      map.set(AssociatedDataType.ECONOMIC_UNIT, data.economic_unit_aggregate.aggregate.count);
    }
    if ((data?.scenario_aggregate?.aggregate?.count ?? 0) > 0) {
      //@ts-ignore

      //@ts-ignore
      map.set(AssociatedDataType.SCENARIO, data.scenario_aggregate.aggregate.count);
    }
    if ((data?.action_plan_aggregate?.aggregate?.count ?? 0) > 0) {
      //@ts-ignore

      //@ts-ignore
      map.set(AssociatedDataType.ACTION_PLAN, data.action_plan_aggregate.aggregate.count);
    }
    if ((data?.action_aggregate?.aggregate?.count ?? 0) > 0) {
      //@ts-ignore

      //@ts-ignore
      map.set(AssociatedDataType.ACTION, data.action_aggregate.aggregate.count);
    }
    return map;
  }, [data]);

  const [deletePortfolio] = useMutation(DELETE_PORTFOLIO, {
    variables: {
      //@ts-ignore
      id: portfolio?.id,
    },
    update: (cache, { data }) => {
      //@ts-ignore
      if (data.delete_portfolio_by_pk) {
        const normalizedId = cache.identify({
          //@ts-ignore
          id: data.delete_portfolio_by_pk.id,

          //@ts-ignore
          __typename: data.delete_portfolio_by_pk.__typename,
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
    onError: () => {
      enqueueSnackbar(t('PortfolioDeleteDialog_SnackbarResponse-Error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
    onCompleted: () => {
      enqueueSnackbar(t('PortfolioDeleteDialog_SnackbarResponse-Success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      onClose();
    },
  });

  return (
    <DeleteConfirmationModal
      open={open}
      title={t('General_DeleteModalTitle-Portfolio', { count: 1 })}
      description={
        <Trans
          i18nKey={'General_DeleteModalDescription-Portfolio'}
          values={{
            name: portfolio?.name ?? '',
            count: 1,
            alongWithAssociatedData:
              associatedData.size > 0 ? t('General_DeleteWarningAssociatedDataText', { count: 1 }) : '',
          }}
          components={{ bold: <strong /> }}
        />
      }
      dataLossWarningText={associatedData.size > 0 ? t('General_DeleteModalWarning-Portfolio') : undefined}
      associatedData={associatedData}
      deleteConfirmationText={t('General_DeleteModalConfirmation-Portfolio', {
        count: 1,
      })}
      tooltips={tooltips}
      onClose={onClose}
      onDelete={() => {
        deletePortfolio();
      }}
    />
  );
}
