import { Box, Stack, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { FieldIcon, IconState } from '../../../Common/FieldIcon';

export type TableData = {
  label: string;
  value: number | string | null;
  isDirty?: boolean;
};

type TableProps = {
  data: TableData[];
  isDeleted?: boolean;
};

const TechnologyUnitTable = ({ data, isDeleted = false }: TableProps) => {
  return (
    <Table
      sx={{
        p: 0,
        td: {
          p: 0,
        },
      }}
    >
      <TableBody>
        {data.map((item, index) => {
          const { label, value, isDirty } = item;

          return (
            <TableRow key={index}>
              <TableCell align="left">
                <Box sx={{ textDecoration: isDeleted ? 'line-through' : 'none' }}>{label}</Box>
              </TableCell>
              <TableCell align="right">
                {isDirty ? (
                  <Stack display="inline-flex" direction="row" alignItems="center" spacing={0.25}>
                    <FieldIcon state={IconState.ManualEdit} noWrapper />
                    <Box>{value}</Box>
                  </Stack>
                ) : (
                  <Box sx={{ textDecoration: isDeleted ? 'line-through' : 'none' }}>{value}</Box>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default TechnologyUnitTable;
