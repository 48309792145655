export enum orientation_enum {
  NORTH = 'NORTH',
  NORTH_EAST = 'NORTH_EAST',
  EAST = 'EAST',
  SOUTH_EAST = 'SOUTH_EAST',
  SOUTH = 'SOUTH',
  SOUTH_WEST = 'SOUTH_WEST',
  WEST = 'WEST',
  NORTH_WEST = 'NORTH_WEST',
  HORIZONTAL = 'HORIZONTAL',
}

/**
 * This enum actually come from hasura..
 * If its not referenced by a fk_relationship its not generated though (why?)
 * So for now we manually declare it here
 */
export enum roof_type_enum {
  FLAT_ROOF = 'FLAT_ROOF',
  GABLE_ROOF = 'GABLE_ROOF',
  SHED_ROOF = 'SHED_ROOF',
}
