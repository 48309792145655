import { Button, Typography } from '@mui/material';
import { ReactNode } from 'react';
import PreDialog from './presentations/PreDialog/PreDialog';

type Props = {
  onAcknowledge: () => void;
  title: string;
  description: string | ReactNode;
  buttonText: string;
  cancelText?: string;
  open: boolean;
  onClose: () => void;
};

export function WarningModal({ title, description, buttonText, onAcknowledge, cancelText, open, onClose }: Props) {
  return (
    <PreDialog
      open={open}
      onClose={onClose}
      type="definedByProperty"
      dialogtitle={title}
      content={<Typography>{description}</Typography>}
      actions={
        <>
          {cancelText && (
            <Button variant="outlined" onClick={onClose}>
              {cancelText}
            </Button>
          )}
          <Button variant="contained" onClick={onAcknowledge} autoFocus>
            {buttonText}
          </Button>
        </>
      }
    />
  );
}
