/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { GetAllUsersQuery } from '../../../../../../libs/generated/client-graphql/src';
import DeleteConfirmationModal, { AssociatedDataType } from '../../../components/DeleteConfirmationModal';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { GET_SCENARIOS } from '../../../graphql/ActionPlanning.queries';
import {
  GET_ALL_BUILDINGS_WITH_SUB_BUILDINGS,
  GET_ALL_ENERGY_CERTIFICATE_DRAFTS,
} from '../../../graphql/DataCollection.queries';
import { GET_REPORTS } from '../../../graphql/Report.queries';
import { DELETE_USER } from '../../../graphql/Users.mutations';
import { GET_USERS, GET_USER_OBJECTS_AGGREGATE } from '../../../graphql/Users.queries';

type Props = {
  userToDelete: GetAllUsersQuery['user'][number] | null;
  closeUserDeleteDialog: () => void;
};

function UserDelete({ closeUserDeleteDialog, userToDelete }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [transferOwnershipToUserId, setTransferOwnershipToUserId] = useState<number | undefined>(undefined);

  const { data: aggregateData } = useQuery(GET_USER_OBJECTS_AGGREGATE, {
    variables: {
      //@ts-ignore
      userId: userToDelete?.id,
    },
    skip: !userToDelete,
  });
  const associatedData = useMemo(() => {
    const map = new Map();
    if ((aggregateData?.building_aggregate?.aggregate?.count ?? 0) > 0) {
      //@ts-ignore

      //@ts-ignore
      map.set(AssociatedDataType.BUILDING, aggregateData.building_aggregate.aggregate.count);
    }
    if ((aggregateData?.energy_certificate_draft_aggregate?.aggregate?.count ?? 0) > 0) {
      //@ts-ignore

      //@ts-ignore
      map.set(AssociatedDataType.ENERGY_CERTIFICATE, aggregateData.energy_certificate_draft_aggregate.aggregate.count);
    }
    if ((aggregateData?.report_aggregate?.aggregate?.count ?? 0) > 0) {
      //@ts-ignore

      //@ts-ignore
      map.set(AssociatedDataType.REPORT, aggregateData.report_aggregate.aggregate.count);
    }
    if ((aggregateData?.scenario_aggregate?.aggregate?.count ?? 0) > 0) {
      //@ts-ignore

      //@ts-ignore
      map.set(AssociatedDataType.SCENARIO, aggregateData.scenario_aggregate.aggregate.count);
    }
    if ((aggregateData?.action_plan_aggregate?.aggregate?.count ?? 0) > 0) {
      //@ts-ignore

      //@ts-ignore
      map.set(AssociatedDataType.ACTION_PLAN, aggregateData.action_plan_aggregate.aggregate.count);
    }
    if ((aggregateData?.action_aggregate?.aggregate?.count ?? 0) > 0) {
      //@ts-ignore

      //@ts-ignore
      map.set(AssociatedDataType.ACTION, aggregateData.action_aggregate.aggregate.count);
    }
    if ((aggregateData?.consumption_aggregate?.aggregate?.count ?? 0) > 0) {
      //@ts-ignore

      //@ts-ignore
      map.set(AssociatedDataType.CONSUMPTION, aggregateData.consumption_aggregate.aggregate.count);
    }
    if ((aggregateData?.consumption_draft_aggregate?.aggregate?.count ?? 0) > 0) {
      //@ts-ignore

      //@ts-ignore
      map.set(AssociatedDataType.CONSUMPTION_DRAFT, aggregateData.consumption_draft_aggregate.aggregate.count);
    }
    return map;
  }, [aggregateData]);

  const { data: allUsersData } = useQuery(GET_USERS);

  const [deleteUserMutation] = useMutation(DELETE_USER);

  const allUsersDataWithoutDeletedUser = userToDelete
    ? allUsersData?.user.filter(({ id }) => id !== userToDelete.id) ?? []
    : [];
  const userName = `${userToDelete?.first_name} ${userToDelete?.last_name}`;

  const onSubmit = () => {
    deleteUserMutation({
      variables: {
        //@ts-ignore
        userId: userToDelete?.id,
        transferOwnershipToUserId,
      },
      update: (cache, { errors }) => {
        if (errors) return;

        const normalizedId = cache.identify({
          id: userToDelete?.id,
          __typename: 'user',
        });

        cache.evict({ id: normalizedId });
        cache.gc();
      },
      refetchQueries: [
        ...([
          //@ts-ignore

          //@ts-ignore
          aggregateData.building_aggregate.aggregate.count > 0 ? { query: GET_ALL_BUILDINGS_WITH_SUB_BUILDINGS } : {},

          //@ts-ignore

          //@ts-ignore
          aggregateData.energy_certificate_draft_aggregate.aggregate.count > 0
            ? { query: GET_ALL_ENERGY_CERTIFICATE_DRAFTS }
            : {},

          //@ts-ignore

          //@ts-ignore
          aggregateData.report_aggregate.aggregate.count > 0 ? { query: GET_REPORTS } : {},

          //@ts-ignore

          //@ts-ignore
          aggregateData.scenario_aggregate.aggregate.count > 0 ? { query: GET_SCENARIOS } : {},
        ].filter(Boolean) as any),
      ],
      onCompleted: () => {
        closeUserDeleteDialog();
        enqueueSnackbar(t('General_UserWasDeleted'), {
          variant: 'success',
          autoHideDuration: SnackbarTimeouts.Success,
        });
      },
      onError: () =>
        enqueueSnackbar(t('General_UserCouldNotBeDeleted'), {
          variant: 'error',
          autoHideDuration: SnackbarTimeouts.Error,
        }),
    });
  };
  return (
    <>
      <DeleteConfirmationModal
        open={Boolean(userToDelete)}
        title={t('General_DeleteModalTitle-User', { count: 1 })}
        description={
          associatedData.size > 0 ? (
            <Trans
              i18nKey={'General_DeleteModalDescription-User'}
              values={{
                name: userName,
                count: 1,
              }}
              components={{ bold: <strong />, br: <br /> }}
            />
          ) : (
            <Trans
              i18nKey={'General_DeleteNoAssociatedDataForUserText'}
              values={{
                name: userName,
                count: 1,
              }}
              components={{ bold: <strong /> }}
            />
          )
        }
        onClose={() => {
          setTransferOwnershipToUserId(undefined);
          closeUserDeleteDialog();
        }}
        onDelete={onSubmit}
        associatedData={associatedData}
        transferOwnershipSelection={setTransferOwnershipToUserId}
        //@ts-ignore
        ownershipOptions={allUsersDataWithoutDeletedUser}
        selectedOwnershipOptionId={transferOwnershipToUserId ? transferOwnershipToUserId.toString() : ''}
      />
    </>
  );
}

export default UserDelete;
