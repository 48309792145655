/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  ReportGetReportByPkReportTemplateFragment,
  ReportGetReportByPkReportTemplateSectionsFragment,
} from '@predium/client-graphql';
import { ReportQuestionDC } from './ReportQuestion.dc';

export type ReportSectionDC = ReportGetReportByPkReportTemplateSectionsFragment & {
  children: ReportSectionDC[];
  parent: ReportSectionDC | null;
  questions?: ReportQuestionDC[];
};

// TODO: Add exhaustiveness check once enums for templates are shared
// TODO move colors to common location or palette
export function getTemplateMetadataForTemplateTitle(title: string) {
  switch (title) {
    case 'ECORE':
      return {
        logo: '/reporting/logo_ecore.svg',
        color: '#83CFA6',
        readableTitle: 'ECORE',
      };
    case 'ECOREQUICKCHECK':
      return {
        logo: '/reporting/logo_ecore_quickcheck.svg',
        color: '#83CFA6',
        readableTitle: 'ECORE Quick-Check',
      };
    case 'GRESB':
      return {
        logo: '/reporting/logo_gresb.png',
        color: '#9DCAE4',
        readableTitle: 'GRESB',
      };
    // TODO ADAPT
    case 'GRESB – Asset Spreadsheet':
      return {
        logo: '/reporting/logo_gresb.png',
        color: '#9DCAE4',
        readableTitle: 'GRESB – Asset Spreadsheet',
      };
    case 'DIQUICKCHECK':
      return {
        logo: '/reporting/logo_diquickcheck.svg',
        color: '#0C4B68',
        readableTitle: 'Deutsche Investment Quick-Check',
      };
    case 'Prüfprotokoll (Artikel 9)':
      return {
        logo: '/reporting/logo_dipruf.svg',
        color: '#0C4B68',
        readableTitle: 'Deutsche Investment Prüfprotokoll',
      };
    case 'DISCORINGMODELL':
      return {
        logo: '/reporting/logo_discoring.svg',
        color: '#0C4B68',
        readableTitle: 'Deutsche Investment Scoring Modell',
      };
    case 'European ESG Template (EET)':
      return {
        logo: '/reporting/logo_eet.svg',
        color: '#0d4096',
        readableTitle: 'European ESG Template',
      };
    case 'ECORE (Ambitionslevel 1)':
      return {
        logo: '/reporting/logo_ecore.svg',
        color: '#83CFA6',
        readableTitle: 'ECORE (Ambitionslevel 1)',
      };
    case 'Taxonomy Check (Acquisition)':
      return {
        logo: '/reporting/taxonomy_aquisitionOwnership_EN.svg',
        color: '#0d4096',
        readableTitle: 'Taxonomy Check (Acquisition)',
      };
    case 'Taxonomy Pre-Check (Erwerb & Eigentum)':
      return {
        logo: '/reporting/taxonomy_aquisitionOwnership_DE.svg',
        color: '#0d4096',
        readableTitle: 'Taxonomy Check (Erwerb & Eigentum)',
      };
    case 'Taxonomy Check (Building Renovation)':
      return {
        logo: '/reporting/taxonomy_renovation_EN.svg',
        color: '#0d4096',
        readableTitle: 'Taxonomy Check (Building Renovation)',
      };
    case 'Taxonomy Pre-Check (Sanierung)':
      return {
        logo: '/reporting/taxonomy_renovation_DE.svg',
        color: '#0d4096',
        readableTitle: 'Taxonomy Pre-Check (Sanierung)',
      };
    case 'Taxonomy Pre-Check (Neubau)':
      return {
        logo: '/reporting/taxonomy_newConstruction_DE.svg',
        color: '#0d4096',
        readableTitle: 'Taxonomy Pre-Check (Neubau)',
      };
    case 'DB GSE Checklist':
      return {
        logo: '/reporting/db.svg',
        color: '#0d4096',
        readableTitle: 'Checkliste Gebäudesanierungsempfehlung',
      };
    default:
      return {
        logo: null as any,
        color: 'white',
      };
  }
}

/**
 * This class provides abstraction to the report data structure coming from GraphQL. It builds a tree of sections and provides a way to traverse the tree.
 *
 * @export
 * @class Report
 */
export class ReportTemplateDC implements ReportGetReportByPkReportTemplateFragment {
  public rootSections: ReportSectionDC[];

  //@ts-ignore
  public title: string;

  //@ts-ignore
  public report_sections: Omit<ReportSectionDC, 'parent' | 'children'>[];

  //@ts-ignore
  public single_sub_building: boolean;

  //@ts-ignore
  public excel_template_path: string;

  public logo: string | null;
  public color: string | null;

  public linearSectionStructure: Array<
    ReportSectionDC & {
      // This meta object holds the amount of answered/unanswered questions for display in the UI
      // The key is subBuildingId which the count is for. A key of -1 is the count for ORG sections
      progressMeta: Record<
        string,
        {
          progress: number;
          // only usefull on target sections otherwise just 0
          answeredCount: number;
          unansweredCount: number;
        }
      >;
    }
  > = [];

  public getNextSectionId(currentSectionId: number): ReportSectionDC | null {
    const currentSectionIndex = this.linearSectionStructure.findIndex(({ id }) => id === currentSectionId);
    if (currentSectionIndex === -1 || currentSectionIndex === this.linearSectionStructure.length - 1) {
      return null;
    }

    return this.linearSectionStructure[currentSectionIndex + 1];
  }

  constructor(unstructuredTemplate: ReportGetReportByPkReportTemplateFragment) {
    // Copying all the properties from the unstructured report to this class
    Object.assign(this, unstructuredTemplate);

    //set hardcoded template metadata

    //@ts-ignore
    const templateMetadata = getTemplateMetadataForTemplateTitle(this.title);
    this.logo = templateMetadata.logo;
    this.color = templateMetadata.color;

    // This is the function that recursively builds the section tree
    const getChildren = (section: ReportGetReportByPkReportTemplateSectionsFragment): any => {
      return unstructuredTemplate.report_sections
        .filter((child) => child.parent_section_id === section.id)
        .map((child) => ({
          ...child,
          children: getChildren(child),
          parent: section,
        }));
    };

    //building the tree starting with the root sections
    this.rootSections = unstructuredTemplate.report_sections
      .filter((section) => section.parent_section_id === null)
      .map((section) => ({
        ...section,
        children: getChildren(section),
        parent: null,
      }));

    const addChildToLinearStructure = (section: ReportSectionDC) => {
      section.children.forEach((child) => {
        this.linearSectionStructure.push({ ...child, progressMeta: {} });
        addChildToLinearStructure(child);
      });
    };

    this.rootSections.forEach((section) => {
      this.linearSectionStructure.push({ ...section, progressMeta: {} });
      addChildToLinearStructure(section);
    });
  }
}
