import { Box, Stack, SxProps, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

type FileIconProps = {
  format: (typeof supportedFormats)[number];
  tooltip?: boolean;
  sx?: SxProps<Theme>;
  title?: string;
};

const supportedFormats = ['pdf'] as const;

/**
 * This was a MinimalV4 component but I removed overkill code and made it my own.
 */
export default function FileThumbnail({ title, tooltip, sx, format }: FileIconProps) {
  let iconUrl = '';
  switch (format) {
    case 'pdf':
      iconUrl = '/icons/ic_pdf.svg';
      break;

    default:
      const t: never = format;
      throw new Error(`Unhandled icon: ${t}`);
  }

  const renderContent = (
    <Box
      component="img"
      src={iconUrl}
      sx={{
        width: 32,
        height: 32,
        flexShrink: 0,
        ...sx,
      }}
    />
  );

  if (tooltip) {
    return (
      <Tooltip title={title}>
        <Stack
          flexShrink={0}
          component="span"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: 'fit-content',
            height: 'inherit',
          }}
        >
          {renderContent}
          {/* Download Button clashes with Button style overrides */}
          {/* {onClick && <DownloadButton onDownload={onClick} />} */}
        </Stack>
      </Tooltip>
    );
  }

  return (
    <>
      {renderContent}
      {/* {onClick && <DownloadButton onDownload={onClick} />} */}
    </>
  );
}
