import styled from '@emotion/styled';
import { Card, CardContent, CardHeader, Grid, SxProps, Typography, useTheme } from '@mui/material';
import { KeyboardEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import Iconify from './Iconify';

export type TileRole = 'link' | 'button';

type CommonTileProps = {
  title: string;
  description: string;
  icon: string;
  tabIndex?: number;
};

type LinkTileProps = CommonTileProps & {
  role: 'link';
  path: string;
};

type ButtonTileProps = CommonTileProps & {
  role: 'button';
  onClick: () => void;
};

type Props = LinkTileProps | ButtonTileProps;

/**
 * A MUI Card behaves like a button / link depending on the role prop.
 */
export default function Tile({ title, description, icon, tabIndex = 0, ...functionalProps }: Props) {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleClick = () => {
    if (functionalProps.role === 'button') {
      functionalProps.onClick();
    } else if (functionalProps.role === 'link') {
      navigate(functionalProps.path);
    }
  };

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      if (functionalProps.role === 'button') {
        functionalProps.onClick();
      }
    }
  };

  return (
    <Card
      sx={{
        p: 3,
        height: '180px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
        '&:focus': {
          backgroundColor: theme.palette.action.focus,
        },
      }}
      onClick={handleClick}
      tabIndex={tabIndex}
      role={functionalProps.role}
      onKeyDown={onKeyDown}
    >
      <Grid container>
        <Grid item xs={3} sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
          <Iconify icon={icon} width={80} height={80} />
        </Grid>

        <Grid item xs={9}>
          <CardHeader title={title} />
          {description && (
            <CardContent>
              <Typography>{description}</Typography>
            </CardContent>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}

/**
 * A semantic button wrapper without any styles that can be applied to provide accessibility and the natural behavior of a button (e.g. keyboard interactions)
 */
export const SemanticButton = styled('button')<{ sx?: SxProps; disabled?: boolean }>(
  ({ sx = {} as any, disabled = false }) => {
    // revert pointer and hover styles if button is disabled.
    // property itself is propagated to the button but manual styles must be set.
    const disabledStyles = disabled
      ? {
          cursor: 'default',
          pointerEvents: 'none',
        }
      : {};

    return {
      background: 'none',
      color: 'inherit',
      border: 'inherit',
      padding: '0',
      font: 'inherit',
      cursor: 'pointer',
      outline: 'inherit',
      borderRadius: 'inherit',
      ...sx,

      ...disabledStyles,
    };
  },
);
