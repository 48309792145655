import { Box, Stack, Tab, Tabs } from '@mui/material';
import { EsgAnalysisGetFilteredBuildingsQuery, EsgAnalysisPortfolioFragment } from '@predium/client-graphql';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EsgAnalysisBuildingListTab from './EsgAnalysisBuildingListTab';
import EsgAnalysisSection from './EsgAnalysisSection';

// ----------------------------------------------------------------------

export type CurrentView = 'esg-portfolio' | 'esg-economics-units';

type Props = {
  benchmarkId: number;
  showTotal: boolean;
  buildings: EsgAnalysisGetFilteredBuildingsQuery;
  portfolios: EsgAnalysisPortfolioFragment[];
  unitSwitcher: ReactNode;
  currentView?: CurrentView;
};

export default function EsgAnalysisTabs({
  benchmarkId,
  showTotal,
  buildings,
  portfolios,
  unitSwitcher,
  currentView,
}: Props) {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<'ESG_ANALYSIS' | 'BUILDING_LIST'>('ESG_ANALYSIS');

  const constructionYear = Math.min(...buildings.building.map((building) => building.year_constructed));

  const tabs = [
    {
      label: t('General_Dashboard'),
      value: 'ESG_ANALYSIS',
      component: (
        <EsgAnalysisSection
          showTotal={showTotal}
          benchmarkId={benchmarkId}
          totalBuildings={buildings.building.length}
          portfolios={portfolios}
          constructionYear={constructionYear}
          currentView={currentView}
        />
      ),
    },
    {
      label: t('EsgAnalysisTabs_ListWithBuildingCount', { buildingCount: buildings.building.length }),
      value: 'BUILDING_LIST',
      component: <EsgAnalysisBuildingListTab filteredBuildings={buildings.building} showTotal={showTotal} />,
    },
  ];

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(_, value) => setCurrentTab(value)}
        >
          {tabs.map((tab) => (
            <Tab disableRipple key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>

        <Box>{unitSwitcher}</Box>
      </Stack>

      <Box sx={{ pt: 3 }}>
        {tabs.map((tab) => {
          return tab.value === currentTab && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Box>
    </Box>
  );
}
