import { CardProps } from '@mui/material';
import { PaletteColor, Theme, styled, useTheme } from '@mui/material/styles';

interface Props extends CardProps {
  year: number;
  state: 'positive' | 'neutral' | 'negative';
}

type LabelVariant = 'filled' | 'outlined';

const RootStyle = styled('span')(
  ({
    theme,
    ownerState,
  }: {
    theme: Theme;
    ownerState: {
      color: PaletteColor;
      variant: LabelVariant;
    };
  }) => {
    const { color, variant } = ownerState;

    const styleFilled = (color: PaletteColor) => ({
      color: color.main,
      backgroundColor: color.lighter,
    });

    const styleOutlined = (color: PaletteColor) => ({
      color: color.main,
      backgroundColor: 'transparent',
      border: `1px solid ${color.main}`,
    });

    return {
      height: 50,
      minWidth: 90,
      lineHeight: 0,
      borderRadius: 10,
      cursor: 'default',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      display: 'inline-flex',
      justifyContent: 'center',
      padding: theme.spacing(0, 1),
      color: theme.palette.grey[800],
      fontSize: theme.typography.pxToRem(25),
      fontFamily: theme.typography.fontFamily,
      backgroundColor: theme.palette.grey[300],
      fontWeight: theme.typography.fontWeightBold,

      ...(variant === 'filled' && { ...styleFilled(color) }),
      ...(variant === 'outlined' && { ...styleOutlined(color) }),
    };
  },
);

export default function StrandedSubBuildingBadge({ year, state, sx }: Props) {
  const theme = useTheme();

  const colorMap = {
    positive: theme.palette.success,
    neutral: theme.palette.warning,
    negative: theme.palette.error,
  };

  return (
    <RootStyle ownerState={{ color: colorMap[state], variant: 'filled' }} theme={theme} sx={sx}>
      {year ?? '-'}
    </RootStyle>
  );
}
