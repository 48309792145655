import { useTheme } from '@mui/material';

export default function BaseOptionChart(): ApexCharts.ApexOptions {
  const theme = useTheme();

  return {
    chart: {
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: [
      theme.palette.primary.main,
      theme.palette.chart.yellow[0],
      theme.palette.chart.blue[0],
      theme.palette.chart.violet[0],
      theme.palette.chart.green[0],
      theme.palette.chart.red[0],
    ],
    title: {
      align: 'left' as any,
      offsetY: 13.5,
      offsetX: 13.5,
      style: {
        fontSize: `${theme.typography.h5.fontSize}`,
        fontWeight: `${theme.typography.h5.fontWeight}`,
        fontFamily: theme.typography.fontFamily,
      },
    },
    subtitle: {
      offsetX: 14,
      style: {
        fontSize: '14px',
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.grey[600],
      },
    },
    stroke: {
      width: 0,
      curve: 'straight' as any,
      lineCap: 'round' as any,
    },
    dataLabels: {
      enabled: false,
      style: {
        colors: [theme.palette.grey[600]],
        fontSize: '13px',
        fontFamily: theme.typography.fontFamily,
      },
    },
    grid: {
      strokeDashArray: 3,
      borderColor: theme.palette.divider,
    },
    fill: {
      opacity: 1,
      gradient: {
        type: 'vertical' as any,
        shadeIntensity: 0,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 100],
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: theme.palette.grey[600],
          fontSize: '14px',
          fontFamily: theme.typography.fontFamily,
        },
      },
    },
    yaxis: {
      title: {
        style: {
          color: theme.palette.grey[600],
          fontSize: '12px',
          fontWeight: theme.typography.fontWeightLight?.toString(),
          fontFamily: theme.typography.fontFamily,
        },
      },
      labels: {
        style: {
          colors: theme.palette.grey[600],
          fontSize: '14px',
          fontFamily: theme.typography.fontFamily,
        },
        offsetY: 4,
      },
    },
    markers: {
      size: 0,
      strokeColors: theme.palette.background.paper,
    },
    tooltip: {
      marker: {
        show: false,
      },
      x: {
        show: false,
      },
    },
    legend: {
      position: 'bottom',
      labels: {
        colors: theme.palette.grey[600],
      },
      fontSize: '12px',
      fontWeight: theme.typography.fontWeightLight?.toString(),
      fontFamily: theme.typography.fontFamily,
    },
    states: {
      hover: {
        filter: {
          type: 'lighten',
          value: 0.05,
        },
      },
    },
  };
}
