import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Theme, useTheme } from '@mui/material/styles';
import createAvatar from '../utils/createAvatar';

interface InlineUserProps extends React.ComponentProps<typeof Avatar> {
  firstName?: string | null;
  lastName?: string | null;
  onClick?: VoidFunction;
  size?: number;
}

export default function InlineUser({ firstName, lastName, onClick, size = 40, sx }: InlineUserProps) {
  const theme: Theme = useTheme();

  const fontSize = size > 30 ? theme.typography.body1.fontSize : theme.typography.body2.fontSize;

  const fullName = [firstName, lastName].filter(Boolean).join(' ');

  return (
    <Stack direction="row" alignItems="center" onClick={onClick} sx={{ ...sx }}>
      <Avatar sx={{ width: size, height: size, fontSize }} color={createAvatar(fullName).color}>
        {createAvatar(fullName).name}
      </Avatar>
      <Typography sx={{ pl: 2, fontSize }} variant="subtitle1">
        {fullName}
      </Typography>
    </Stack>
  );
}
