import { energy_consumer_type_enum } from '@predium/client-graphql';
import { ActionProps } from '../../../ViewAction';
import HeatingAction from './HeatingAction';
import HotWaterAction from './HotWaterAction';
import LightingAction from './LightingAction';

export default function SystemsAction({
  currentActionBuildingModel,
  action,
  previousActionBuildingModel,
}: ActionProps) {
  const props: ActionProps = {
    currentActionBuildingModel: currentActionBuildingModel,
    action: action,
    previousActionBuildingModel: previousActionBuildingModel,
    affectedParts: [],
  };

  const consumerData = action.renovations_energy_consumer[0]?.new_energy_consumer;
  const systemType = consumerData?.energy_consumer_type_id;

  switch (systemType) {
    case energy_consumer_type_enum.LIGHTING:
      return <LightingAction {...props} />;
    case energy_consumer_type_enum.HEATING:
      return <HeatingAction {...props} />;
    case energy_consumer_type_enum.HOT_WATER:
      return <HotWaterAction {...props} />;

    default:
      const exhaustiveCheck = systemType;
      throw new Error(`Unhandled energy_consumer_type_enum ${systemType}: ${exhaustiveCheck}`);
  }
}
