import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../components/Iconify';
import InputStyle from '../../../../components/InputStyle';
import { AVAILABLE_DATA_TYPES, TDataType } from './SubBuildingDocuments';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

// ----------------------------------------------------------------------

type Props = {
  selectedDataType: TDataType;
  handleDataTypeChange: (value: TDataType) => void;
  filterName: string;
  handleFilterNameChange: (value: string) => void;
};

export default function SubBuildingDocumentsToolbar({
  filterName,
  handleFilterNameChange,
  handleDataTypeChange,
  selectedDataType,
}: Props) {
  const { t } = useTranslation();
  return (
    <RootStyle>
      <Stack direction="row">
        <InputStyle
          value={filterName}
          onChange={(event) => handleFilterNameChange(event.target.value)}
          placeholder={t('General_SearchPlaceholder_Document')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            ),
          }}
          sx={{ minWidth: 250, mr: 3 }}
        />

        <TextField
          select
          label={t('General_FileType')}
          value={selectedDataType}
          onChange={(event) => handleDataTypeChange(event.target.value as TDataType)}
          sx={{
            minWidth: 250,
            '& .MuiSelect-select': {
              typography: 'body2',
            },
          }}
        >
          {AVAILABLE_DATA_TYPES.map((type) => (
            <MenuItem key={type.key} value={type.key} sx={{ typography: 'body2', mx: 1, my: 0.5, borderRadius: 0.75 }}>
              {type.label}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    </RootStyle>
  );
}
