import { createSvgIcon } from '@mui/material';
import palette from '../../../theme/palette';

export const IconConsumptionWaste = createSvgIcon(
  <svg width="20" height="20" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 2V2.66667H2.66667V4H3.33334V12.6667C3.33334 13.0203 3.47381 13.3594 3.72386 13.6095C3.97391 13.8595 4.31305 14 4.66667 14H11.3333C11.687 14 12.0261 13.8595 12.2761 13.6095C12.5262 13.3594 12.6667 13.0203 12.6667 12.6667V4H13.3333V2.66667H10V2H6ZM4.66667 4H11.3333V12.6667H4.66667V4ZM6 5.33333V11.3333H7.33334V5.33333H6ZM8.66667 5.33333V11.3333H10V5.33333H8.66667Z"
      fill="#637381"
    />
  </svg>,
  'IconConsumptionWaste',
);

const IconConsumptionWaste_Colored = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 2V2.66667H2.66667V4H3.33334V12.6667C3.33334 13.0203 3.47381 13.3594 3.72386 13.6095C3.97391 13.8595 4.31305 14 4.66667 14H11.3333C11.687 14 12.0261 13.8595 12.2761 13.6095C12.5262 13.3594 12.6667 13.0203 12.6667 12.6667V4H13.3333V2.66667H10V2H6ZM4.66667 4H11.3333V12.6667H4.66667V4ZM6 5.33333V11.3333H7.33334V5.33333H6ZM8.66667 5.33333V11.3333H10V5.33333H8.66667Z"
      fill={palette.light.success.dark}
    />
  </svg>,
  'IconConsumptionWaste',
);

export default IconConsumptionWaste_Colored;
