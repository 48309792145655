/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ModificationImpactsFragment, efficiency_class_enum } from '@predium/client-graphql';
import isNil from 'lodash/isNil';
import WidgetSummary from '../../../../../components/data-visialization/WidgetSummary';
import { getEfficiencyClassColor } from '../../ActionPlan.utils';
import ActionBeforeAfterChart from '../ActionBeforeAfterChart';

type EnergyDemandProps = {
  modificationImpact: ModificationImpactsFragment;
  isOverview: boolean;
  isSimulated: boolean;
  usePrimaryEnergy?: boolean;
  title: string;
};

export function EnergyWithoutEfficiencyClass({
  modificationImpact,
  isOverview,
  isSimulated,
  usePrimaryEnergy = false,
  title,
}: EnergyDemandProps) {
  const theme = useTheme();
  const { efficiency_class, final_energy: consumption, primary_energy: primaryEnergy } = modificationImpact;

  const energyData = usePrimaryEnergy ? primaryEnergy : consumption;
  const energyDemandChartData = [energyData.before ?? undefined, energyData.after ?? undefined];
  const efficiencyClass = {
    before: efficiency_class?.before ?? efficiency_class_enum.UNKNOWN,
    after: efficiency_class?.after ?? efficiency_class_enum.UNKNOWN,
  };

  return (
    <Card>
      <Stack direction="row" sx={{ pl: 3, pt: 3 }}>
        <Typography variant="h6">{title}</Typography>
        {!isNil(energyData?.impact) ? <WidgetSummary percent={energyData?.impact} /> : null}
      </Stack>

      <Stack>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <Box px={2} py={isOverview ? 2 : 0}>
              <ActionBeforeAfterChart
                chartData={energyDemandChartData}
                colors={getEfficiencyClassColor(efficiencyClass.before, efficiencyClass.after, theme)}
                unit={'kWh/m²a'}
                isSimulated={isSimulated}
              />
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
}
