/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Alert, Box, Button, Card, Container, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { role_enum } from '../../../../../libs/generated/enums/src/generated/enums';
import { translateRoleEnum } from '../../../../../libs/i18n/client/src/enums';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import LoadingScreen from '../../components/LoadingScreen';
import Page from '../../components/Page';
import TableTabCount from '../../components/table/TableTabCount';
import { GET_USER_BY_ID } from '../../graphql/Users.queries';
import { GET_ALL_PORTFOLIOS_AND_BUILDINGS } from '../../graphql/common.queries';
import { PATHS } from '../../routes';
import UserEditDialog from '../../sections/Org/User/UserEditDialog';
import AccessControlBuildingList from '../../sections/Org/User/access-control/AccessControlBuildingList';
import AccessControlPortfolioList from '../../sections/Org/User/access-control/AccessControlPortfolioList';

type UserPermissionsTabName = 'portfolios' | 'buildings';

export default function UserProfile() {
  const { t } = useTranslation();
  const { id } = useParams();

  const [currentTab, setCurrentTab] = useState<UserPermissionsTabName>('portfolios');
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);

  const { data: user, loading: userLoading } = useQuery(GET_USER_BY_ID, {
    variables: {
      //@ts-ignore
      userId: parseInt(id),
    },
  });

  const isAdmin = user?.user_by_pk?.user_role[0].role === role_enum.ORG_ADMIN;

  const { data: portfoliosAndBuildings, loading: portfoliosAndBuildingsLoading } = useQuery(
    GET_ALL_PORTFOLIOS_AND_BUILDINGS,
  );

  if (userLoading || portfoliosAndBuildingsLoading) return <LoadingScreen />;

  const userPermissionsTabs: { label: string; value: UserPermissionsTabName }[] = [
    {
      label: t('General_Portfolio', { count: 2 }),
      value: 'portfolios',
    },
    {
      label: t('General_Building', { count: 2 }),
      value: 'buildings',
    },
  ];
  const fullName = `${user?.user_by_pk?.first_name} ${user?.user_by_pk?.last_name}`;

  //@ts-ignore
  const portfolioCount = portfoliosAndBuildings.portfolio.length;

  //@ts-ignore
  const buildingCount = portfoliosAndBuildings.building.length;

  return (
    <Page title={t('PageName_ManageUsers')}>
      <Container maxWidth={'lg'}>
        <HeaderBreadcrumbs heading={fullName} links={[{ name: t('PageName_ManageUsers'), href: PATHS.org.users() }]} />
        <Card sx={{ p: 4, display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6">{t('General_PersonalInformation')}</Typography>
            <Button variant="outlined" color="inherit" sx={{ gap: 1 }} onClick={() => setEditUserDialogOpen(true)}>
              <Iconify icon="lucide:edit" width={16} height={16} />
              <Typography>{t('General_Edit')}</Typography>
            </Button>
          </Box>
          <Box>
            <Typography variant="caption" color="text.secondary">
              {t('General_Email')}
            </Typography>
            <Typography variant="body1">{user?.user_by_pk?.email}</Typography>
          </Box>
          <Box>
            <Typography variant="caption" color="text.secondary">
              {t('General_Role')}
            </Typography>
            {/*@ts-ignore */}
            <Typography variant="body1">{translateRoleEnum(user?.user_by_pk?.user_role[0].role)}</Typography>
          </Box>
        </Card>
        <Typography variant="h5" sx={{ mt: 4 }}>
          {t('General_Permission', { count: 2 })}
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }} color="text.secondary">
          {t('Settings_UserProfile_PermissionsSubheader')}
        </Typography>
        {isAdmin ? (
          <Alert severity="info" color="info" sx={{ mt: 2 }}>
            {t('Settings_UserProfile_AdminInfo')}
          </Alert>
        ) : (
          <>
            <Tabs
              value={currentTab}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              onChange={(_: React.ChangeEvent<{}>, newValue: UserPermissionsTabName) => setCurrentTab(newValue)}
              sx={{ mt: 4 }}
            >
              {userPermissionsTabs.map((tab) => {
                const count = tab.value === 'portfolios' ? portfolioCount : buildingCount;
                return (
                  <Tab
                    disableRipple
                    key={tab.value}
                    label={<TableTabCount count={count} isActive={currentTab === tab.value} label={tab.label} />}
                    value={tab.value}
                  />
                );
              })}
            </Tabs>
            {userPermissionsTabs.map((tab) => {
              if (tab.value === currentTab) {
                return currentTab === 'portfolios' ? (
                  //@ts-ignore
                  <AccessControlPortfolioList portfolios={portfoliosAndBuildings.portfolio} key={tab.value} />
                ) : (
                  <AccessControlBuildingList
                    //@ts-ignore
                    buildings={portfoliosAndBuildings.building}
                    //@ts-ignore
                    portfolios={portfoliosAndBuildings.portfolio}
                    key={tab.value}
                  />
                );
              }
              return null;
            })}
          </>
        )}
        {/*@ts-ignore */}
        <UserEditDialog open={editUserDialogOpen} onClose={() => setEditUserDialogOpen(false)} user={user.user_by_pk} />
      </Container>
    </Page>
  );
}
