import { debounce } from '@mui/material/utils';
import { useEffect, useMemo, useState } from 'react';

export const useDebounce = <T extends unknown>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const debounced = useMemo(() => debounce((value) => setDebouncedValue(value), delay), [delay]);

  useEffect(() => {
    return debounced(value);
  }, [debounced, value]);

  return debouncedValue;
};
