import { consumption_sub_type_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateConsumptionSubTypeEnum = (enumValue: consumption_sub_type_enum) => {
  switch (enumValue) {
    case consumption_sub_type_enum.WASTE_CONSTRUCTION_AND_EXCAVATION:
      return <Trans i18nKey="Enum_ConsumptionSubTypeEnum-WASTE_CONSTRUCTION_AND_EXCAVATION" />;
    case consumption_sub_type_enum.WASTE_ELECTRONIC_EQUIPMENT:
      return <Trans i18nKey="Enum_ConsumptionSubTypeEnum-WASTE_ELECTRONIC_EQUIPMENT" />;
    case consumption_sub_type_enum.WASTE_GLASS:
      return <Trans i18nKey="Enum_ConsumptionSubTypeEnum-WASTE_GLASS" />;
    case consumption_sub_type_enum.WASTE_MUNICIPAL:
      return <Trans i18nKey="Enum_ConsumptionSubTypeEnum-WASTE_MUNICIPAL" />;
    case consumption_sub_type_enum.WASTE_ORGANIC:
      return <Trans i18nKey="Enum_ConsumptionSubTypeEnum-WASTE_ORGANIC" />;
    case consumption_sub_type_enum.WASTE_OTHER_NOT_RECYCLEABLE:
      return <Trans i18nKey="Enum_ConsumptionSubTypeEnum-WASTE_OTHER_NOT_RECYCLEABLE" />;
    case consumption_sub_type_enum.WASTE_OTHER_RECYCLEABLE:
      return <Trans i18nKey="Enum_ConsumptionSubTypeEnum-WASTE_OTHER_RECYCLEABLE" />;
    case consumption_sub_type_enum.WASTE_PACKAGING:
      return <Trans i18nKey="Enum_ConsumptionSubTypeEnum-WASTE_PACKAGING" />;
    case consumption_sub_type_enum.WASTE_PAPER:
      return <Trans i18nKey="Enum_ConsumptionSubTypeEnum-WASTE_PAPER" />;
    case consumption_sub_type_enum.WASTE_RESIDUAL:
      return <Trans i18nKey="Enum_ConsumptionSubTypeEnum-WASTE_RESIDUAL" />;
    case consumption_sub_type_enum.WASTE_WOOD:
      return <Trans i18nKey="Enum_ConsumptionSubTypeEnum-WASTE_WOOD" />;
    case consumption_sub_type_enum.WATER_DRINKING:
      return <Trans i18nKey="Enum_ConsumptionSubTypeEnum-WATER_DRINKING" />;
    case consumption_sub_type_enum.WATER_RAINWATER:
      return <Trans i18nKey="Enum_ConsumptionSubTypeEnum-WATER_RAINWATER" />;
    case consumption_sub_type_enum.WATER_SEWAGE:
      return <Trans i18nKey="Enum_ConsumptionSubTypeEnum-WATER_SEWAGE" />;
  }
};

export const translateConsumptionSubTypeEnum_dynamic = (
  enumValue: consumption_sub_type_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case consumption_sub_type_enum.WASTE_CONSTRUCTION_AND_EXCAVATION:
      return t('Enum_ConsumptionSubTypeEnum-WASTE_CONSTRUCTION_AND_EXCAVATION');
    case consumption_sub_type_enum.WASTE_ELECTRONIC_EQUIPMENT:
      return t('Enum_ConsumptionSubTypeEnum-WASTE_ELECTRONIC_EQUIPMENT');
    case consumption_sub_type_enum.WASTE_GLASS:
      return t('Enum_ConsumptionSubTypeEnum-WASTE_GLASS');
    case consumption_sub_type_enum.WASTE_MUNICIPAL:
      return t('Enum_ConsumptionSubTypeEnum-WASTE_MUNICIPAL');
    case consumption_sub_type_enum.WASTE_ORGANIC:
      return t('Enum_ConsumptionSubTypeEnum-WASTE_ORGANIC');
    case consumption_sub_type_enum.WASTE_OTHER_NOT_RECYCLEABLE:
      return t('Enum_ConsumptionSubTypeEnum-WASTE_OTHER_NOT_RECYCLEABLE');
    case consumption_sub_type_enum.WASTE_OTHER_RECYCLEABLE:
      return t('Enum_ConsumptionSubTypeEnum-WASTE_OTHER_RECYCLEABLE');
    case consumption_sub_type_enum.WASTE_PACKAGING:
      return t('Enum_ConsumptionSubTypeEnum-WASTE_PACKAGING');
    case consumption_sub_type_enum.WASTE_PAPER:
      return t('Enum_ConsumptionSubTypeEnum-WASTE_PAPER');
    case consumption_sub_type_enum.WASTE_RESIDUAL:
      return t('Enum_ConsumptionSubTypeEnum-WASTE_RESIDUAL');
    case consumption_sub_type_enum.WASTE_WOOD:
      return t('Enum_ConsumptionSubTypeEnum-WASTE_WOOD');
    case consumption_sub_type_enum.WATER_DRINKING:
      return t('Enum_ConsumptionSubTypeEnum-WATER_DRINKING');
    case consumption_sub_type_enum.WATER_RAINWATER:
      return t('Enum_ConsumptionSubTypeEnum-WATER_RAINWATER');
    case consumption_sub_type_enum.WATER_SEWAGE:
      return t('Enum_ConsumptionSubTypeEnum-WATER_SEWAGE');
  }
};
