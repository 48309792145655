/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useApolloClient, useMutation } from '@apollo/client';
import { Alert, Button, List, ListItem, Stack, Typography } from '@mui/material';
import { UpdateAreasMutation } from '@predium/client-graphql';
import { area_type_enum } from '@predium/enums';
import { useSnackbar } from 'notistack';
import { PropsWithChildren, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { DelayedLoading } from '../../../../components/Loading';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import PreDialog, { PreDialogBody } from '../../../../components/presentations/PreDialog/PreDialog';
import { UPDATE_AREAS, UPDATE_AREAS_WITH_RECALCULATION } from '../../../../graphql/DataCollection.mutations';
import { GET_BUILDING } from '../../../../graphql/DataCollection.queries';
import usePosthogTracking from '../../../../hooks/usePosthogTracking';
import { ConflictDialog } from '../Common/ConflictDialog';
import useBuilding from '../Context/useBuilding';
import { useRecalculate } from './RecalculationProvider';
import { AreaFormValues } from './types';
import { getAreaMutationsFromForm } from './utils';

const PreList = ({ children }: PropsWithChildren) => (
  <List
    sx={{
      listStyleType: 'disc',
      pt: 0,
      pl: 2,
      '& .MuiListItem-root': {
        display: 'list-item',
        pl: 0,
        pb: 0,
      },
    }}
  >
    {children}
  </List>
);

type SaveChangesModalContentProps = {
  onClose: VoidFunction;
  handleSave: VoidFunction;
};

const SaveChangesModalContent = ({ onClose, handleSave }: SaveChangesModalContentProps) => {
  const { formState, getValues } = useFormContext<AreaFormValues>();
  const { t } = useTranslation();

  const isAreaChanged = (areaType: area_type_enum) => {
    const areas = getValues('areas');
    const dirtyAreas = formState.dirtyFields.areas;

    const areasIndexes = areas.map((area, index) => (area.area_type_id === areaType ? index : null));

    //@ts-ignore
    return dirtyAreas.some((fields, index) => Object.keys(fields).length > 0 && areasIndexes.includes(index));
  };

  const isNUFChanged = isAreaChanged(area_type_enum.NUF);
  const isAnyMFGChanged = [area_type_enum.MFG_1, area_type_enum.MFG_2, area_type_enum.MFG].some((areaType) =>
    isAreaChanged(areaType),
  );

  const isNUFAndMFGsChanged = isNUFChanged && isAnyMFGChanged;

  const isNoneChanged = !isNUFChanged && !isAnyMFGChanged;

  return (
    <PreDialogBody
      dialogtitle={t('General_SaveChanges')}
      content={
        <Stack spacing={2}>
          {isNoneChanged && <Typography>{t('DataCollectionAreas_AreaChangedTextSaveModal')}</Typography>}
          {!isNoneChanged && (
            <>
              {isNUFChanged && <Alert severity="error">{t('DataCollectionAreas_NUFChangeAlert')}</Alert>}
              <Stack>
                {isNUFAndMFGsChanged && <Typography fontWeight={700}>NUF</Typography>}
                {isNUFChanged && (
                  <PreList>
                    <Trans
                      i18nKey="DataCollectionAreas_NUFChangeContent"
                      components={{
                        sup: <sup />,
                        li: <ListItem />,
                      }}
                    />
                  </PreList>
                )}
              </Stack>
              <Stack>
                {isNUFAndMFGsChanged && <Typography fontWeight={700}>MFG, MFG-1, {t('General_And')} MFG-2</Typography>}
                {isAnyMFGChanged && (
                  <PreList>
                    <Trans
                      i18nKey="DataCollectionAreas_MFGChangeContent"
                      components={{
                        sup: <sup />,
                        li: <ListItem />,
                      }}
                    />
                  </PreList>
                )}
              </Stack>
            </>
          )}
        </Stack>
      }
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            {t('General_KeepEditing')}
          </Button>
          <Button variant="contained" onClick={handleSave}>
            {t('General_Save')}
          </Button>
        </>
      }
    />
  );
};

type Props = {
  isSaveModalOpen: boolean;
  setIsSaveModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SaveChangesModal = ({ isSaveModalOpen, setIsSaveModalOpen }: Props) => {
  const { trackEvent } = usePosthogTracking();
  const { t } = useTranslation();
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { formState, handleSubmit } = useFormContext<AreaFormValues>();
  const { building } = useBuilding();
  const [openConflictDialog, setOpenConflictDialog] = useState(false);

  const { getIsOnlyNUFChanged, getNUFsForRecalculate } = useRecalculate();

  const handleClose = () => {
    setIsSaveModalOpen(false);
  };

  const handleError = () => {
    enqueueSnackbar(t('General_BuildingUpdated-error'), {
      variant: 'error',
      autoHideDuration: SnackbarTimeouts.Error,
    });
  };

  const handleOnCompleted = ({ success, scalingResult }: UpdateAreasMutation['updateAreas']) => {
    if (!success) {
      if (scalingResult && !scalingResult.scaling_successful) {
        setOpenConflictDialog(true);
      } else {
        enqueueSnackbar(t('General_BuildingUpdated-error'), {
          variant: 'error',
          autoHideDuration: SnackbarTimeouts.Error,
        });
      }
    } else {
      enqueueSnackbar(t('General_BuildingUpdated-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });

      client.refetchQueries({
        include: [GET_BUILDING],
      });

      trackEvent('BUILDING_AREA_UPDATED');
    }

    handleClose();
  };

  const [updateAreas, { loading: updateAreasLoading, data: updateAreasData }] = useMutation(UPDATE_AREAS, {
    onError: handleError,
    onCompleted: ({ updateAreas }) => handleOnCompleted(updateAreas),
  });

  const [
    updateAreasWithRecalculate,
    { loading: updateAreasWithRecalculationLoading, data: updateAreasWithRecalculationData },
  ] = useMutation(UPDATE_AREAS_WITH_RECALCULATION, {
    onError: handleError,
    onCompleted: ({ recalculateAreas }) => handleOnCompleted(recalculateAreas),
  });

  const isLoading = updateAreasLoading || updateAreasWithRecalculationLoading;

  const { final_energy, final_energy_scaled } =
    (updateAreasData?.updateAreas ?? updateAreasWithRecalculationData?.recalculateAreas)?.scalingResult ?? {};

  const createHandleSave = (saveInvalidState = false) =>
    handleSubmit((data) => {
      if (getIsOnlyNUFChanged()) {
        updateAreasWithRecalculate({
          variables: {
            buildingId: building.id,
            areaMutations: getNUFsForRecalculate(),
            saveInvalidState,
          },
        });

        return;
      }

      updateAreas({
        variables: {
          buildingId: building.id,
          areaMutations: getAreaMutationsFromForm(data.areas, formState),
          saveInvalidState,
        },
      });
    });

  const handleSave = createHandleSave();
  const handleSaveInvalidState = createHandleSave(true);

  return (
    <>
      <PreDialog fullWidth open={isSaveModalOpen} onClose={handleClose} type="definedByChildren">
        {isSaveModalOpen && <SaveChangesModalContent handleSave={handleSave} onClose={handleClose} />}
      </PreDialog>
      <ConflictDialog
        open={openConflictDialog}
        onClose={() => setOpenConflictDialog(false)}
        onClick={() => {
          setOpenConflictDialog(false);
          handleSaveInvalidState();
        }}
        finalEnergy={final_energy ?? 0}
        finalEnergyScaled={final_energy_scaled ?? 0}
      />
      {isLoading && <DelayedLoading text={t('DataCollection_EnvelopeEditForm_LoaderText')} />}
    </>
  );
};

export default SaveChangesModal;
