import { List, ListItem, Tooltip, Typography } from '@mui/material';
import { BuildingModelFragment } from '@predium/client-graphql';
import { renovation_type_enum } from '@predium/enums';
import { useTranslation } from 'react-i18next';
import { getEnvelopeLabel } from '../ActionPlan.utils';
import { PartialRenovationOption } from '../Actions/CreateAction';

type Props = {
  affectedParts: PartialRenovationOption[];
  renovationType: renovation_type_enum;
  buildingModel: BuildingModelFragment;
};

export default function AffectedPartsSubtext({ affectedParts, renovationType, buildingModel }: Props) {
  const { t } = useTranslation();
  const MAX_AFFECTED_PARTS_TO_SHOW = 2;

  if (!affectedParts || renovationType !== renovation_type_enum.ENVELOPE || affectedParts.length === 0) return null;

  const possibleAffectedParts =
    buildingModel?.envelope_units.filter((part) => part.envelope_type_id === affectedParts[0].envelope_type_id) ?? [];

  if (possibleAffectedParts.length <= affectedParts.length) {
    return (
      <Typography variant="caption" color="text.secondary">
        {t('General_AllParts')}
      </Typography>
    );
  }

  return (
    <Typography variant="caption" color="text.secondary">
      {affectedParts
        ?.slice(0, MAX_AFFECTED_PARTS_TO_SHOW)
        .map((part) => {
          if (!part || !part?.id) {
            return '';
          }
          return `${getEnvelopeLabel(part, t)}`;
        })
        .join(', ')}
      {affectedParts.length > MAX_AFFECTED_PARTS_TO_SHOW && (
        <Tooltip
          placement="top"
          arrow
          title={
            <List dense sx={{ p: 0 }}>
              {affectedParts.slice(MAX_AFFECTED_PARTS_TO_SHOW).map((part, index) => {
                if (!part || !part?.id) {
                  return '';
                }
                return (
                  <ListItem key={index} sx={{ listStyleType: 'none' }}>
                    {getEnvelopeLabel(part, t)}
                  </ListItem>
                );
              })}
            </List>
          }
        >
          <span>{`, +${affectedParts.length - MAX_AFFECTED_PARTS_TO_SHOW}`}</span>
        </Tooltip>
      )}
    </Typography>
  );
}
