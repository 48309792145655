/* eslint-disable @typescript-eslint/ban-ts-comment */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import {
  energy_consumer_technology_type_enum,
  energy_source_type_enum,
  energy_system_type_enum,
} from '@predium/client-graphql';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormProvider } from '../../../../../../../components/hook-form';
import InfoTable from '../../../../../../../components/presentations/InfoTable';
import { BuildingTabEnum } from '../../../../../../../pages/DataCollection/DataCollectionBuilding';
import { useLanguage } from '../../../../../../../provider/LanguageProvider';
import { createSystemInfoTable } from '../../../../../Scenario/scenarios.util';
import { TechnicalActionCreationProps } from '../../TechnicalAction';
import { HeatingHotWaterActionFormSchema } from '../../validation-schema';
import { HeatingHotWaterDefaultType } from './HeatingAction';
import TechnicalCommonFields from './TechnicalCommonFields';

const HotWaterAction = forwardRef(
  ({ previousActionBuildingModel, resetSimulatedData, minimumDate, loading }: TechnicalActionCreationProps, ref) => {
    const { language } = useLanguage();

    const { t } = useTranslation();
    const currentActionType = energy_system_type_enum.HOT_WATER;

    const defaultValues: HeatingHotWaterDefaultType = {
      system_renovation_parameter: {
        consumer_technology_type: '' as energy_consumer_technology_type_enum,
        efficiency: undefined,
        energy_source_type: '' as energy_source_type_enum,
        cost_per_m2: undefined,
        technology_name: '',
        system_type: currentActionType,
      },
      implementation_to: '',
      //TODO: shouldn't we check here type of selected system? no check in the original code
      renovation_ids: previousActionBuildingModel.energy_systems.map((system) => system.id),
    };

    const buildingId = previousActionBuildingModel.building!.id;

    const formMethods = useForm<HeatingHotWaterDefaultType>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: defaultValues,
      resolver: yupResolver(HeatingHotWaterActionFormSchema(minimumDate, language)),
    });

    const { watch } = formMethods;

    useImperativeHandle(ref, () => ({
      methods: formMethods,
    }));

    const materialName = watch('system_renovation_parameter.technology_name');
    const efficiency = watch('system_renovation_parameter.efficiency');
    const costPerM2 = watch('system_renovation_parameter.cost_per_m2');
    const energySourceType = watch('system_renovation_parameter.energy_source_type');
    const consumerTechnologyType = watch('system_renovation_parameter.consumer_technology_type');
    const implementationTo = watch('implementation_to');
    const systemType = watch('system_renovation_parameter.system_type');

    useEffect(() => {
      resetSimulatedData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [systemType, materialName, energySourceType, consumerTechnologyType, efficiency, costPerM2, implementationTo]);

    const infoTableData = createSystemInfoTable(currentActionType, previousActionBuildingModel, t);

    return (
      <FormProvider methods={formMethods}>
        <Box my={3}>
          {infoTableData && (
            <Box mb={3}>
              <InfoTable
                buildingId={buildingId}
                showTab={BuildingTabEnum.technology}
                data={infoTableData}
                title={t('General_StateBeforeRenovation')}
              />
            </Box>
          )}

          <TechnicalCommonFields selectedActionType={currentActionType} minimumDate={minimumDate} loading={loading} />
        </Box>
      </FormProvider>
    );
  },
);

export default HotWaterAction;
