import { Box, CardProps, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';
import StrandedSubBuildingBadge from '../StrandedSubBuildingBadge';
import WidgetSummaryLabel from '../WidgetSummaryLabel';

interface Props extends CardProps {
  strandingYearOnePointFive: number;
  change: number | null | undefined;
  selectedBenchmarkName: string;
}

export default function StrandingRiskCard({ strandingYearOnePointFive, change, selectedBenchmarkName, sx }: Props) {
  const { t } = useTranslation();
  const strandingYear15State = getStrandingState(strandingYearOnePointFive);

  return (
    <Card sx={{ p: 3, ...sx }}>
      <Stack justifyContent="space-between" alignItems={'center'} sx={{ height: 1 }}>
        <Box width={'100%'}>
          <Typography sx={{ typography: 'h5', padding: '2px 0', marginRight: 'auto', mb: 1 }}>
            {t('General_StrandingRisk')}
          </Typography>
          <Box>
            <Typography variant="body2" color="text.secondary">
              <Trans
                i18nKey="General_1_5StrandingDate-TargetPath"
                components={{ bold: <span style={{ fontWeight: 700 }} /> }}
              />
            </Typography>
            <StrandedSubBuildingBadge
              year={strandingYearOnePointFive}
              state={strandingYear15State}
              sx={{ mt: 0.5, width: '100%' }}
            />
          </Box>
        </Box>
        <Stack mt={1} direction={'row'}>
          {!!change && (
            <>
              <WidgetSummaryLabel percent={change} unit="a" inverse />

              <Typography sx={{ ml: 1 }}>vs {selectedBenchmarkName}</Typography>
            </>
          )}
        </Stack>
      </Stack>
    </Card>
  );
}

const getStrandingState = (strandingYear: number) => {
  if (typeof strandingYear !== 'number') {
    return 'negative';
  }

  const currentYear = new Date().getFullYear();
  const difference = strandingYear - currentYear;
  if (difference <= 5) {
    return 'negative';
  } else if (difference <= 10) {
    return 'neutral';
  }
  return 'positive';
};
