/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Grid, MenuItem, Typography } from '@mui/material';
import { emission_factor_type_enum } from '@predium/enums';
import { translateEmissionFactorTypeEnum } from '@predium/i18n/client';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import Label from '../../../components/Label';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';
import PreDialog, { PreDialogBody } from '../../../components/presentations/PreDialog/PreDialog';
import { CREATE_PORTFOLIO } from '../../../graphql/Portfolio.mutations';
import { GET_PORTFOLIOS } from '../../../graphql/Portfolio.queries';
import useOrgPreferences from '../../../hooks/useOrgPreferences';
import usePosthogTracking from '../../../hooks/usePosthogTracking';
import useSessionData from '../../../hooks/useSessionData';
import { updatedEmissionFactorTypes } from '../Settings/settings.util';
import EmissionFactorToggle, { EmissionFactorToggleEnum } from './EmissionFactorToggle';

export const EMISSION_FACTOR_REQUIRED_MESSAGE = 'Die Datenbank für Emissionsfaktoren ist ein Pflichtfeld';

type Props = {
  open: boolean;
  onClose: () => void;
  onPortfolioCreate?: () => void;
};

export type PortfolioFormValuesProps = {
  name: string;
  emissionFactorType: emission_factor_type_enum | '';
};

export default function PortfolioCreateDialog({ open, onClose, onPortfolioCreate }: Props) {
  const { t } = useTranslation();
  const { trackEvent } = usePosthogTracking();
  const { enqueueSnackbar } = useSnackbar();
  const { org } = useSessionData();
  const { data } = useQuery(GET_PORTFOLIOS);

  const [currentEmissionFactorToggle, setCurrentEmissionFactorToggle] = useState(EmissionFactorToggleEnum.DEFAULT);

  const portfolios = data?.portfolio ? data.portfolio : [];
  const { orgEmissionFactorType } = useOrgPreferences();

  const [createMutateFunction, { loading }] = useMutation(CREATE_PORTFOLIO, {
    onError: () => {
      enqueueSnackbar(t('PortfolioCreateDialog_SnackbarResponse-Error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },

    onCompleted: () => {
      enqueueSnackbar(t('PortfolioCreateDialog_SnackbarResponse-Success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      onClose();
      reset();
      onPortfolioCreate?.();
    },
    refetchQueries: [GET_PORTFOLIOS],
  });

  const NewPortfolioSchema = object().shape({
    name: string()
      .required(t('General_PortfolioNameIsRequired'))
      .notOneOf(
        portfolios.map((p) => p.name),
        t('General_PortfolioAlreadyExists'),
      ),
    emissionFactorType: string()
      .required(EMISSION_FACTOR_REQUIRED_MESSAGE)
      .oneOf(Object.keys(emission_factor_type_enum), EMISSION_FACTOR_REQUIRED_MESSAGE),
  });

  const defaultValues: PortfolioFormValuesProps = useMemo(
    () => ({
      name: '',
      emissionFactorType: orgEmissionFactorType ?? '',
    }),
    [orgEmissionFactorType],
  );

  const methods = useForm<PortfolioFormValuesProps>({
    resolver: yupResolver(NewPortfolioSchema),
    defaultValues,
    mode: 'onChange',
  });

  const { handleSubmit, watch, reset, setValue } = methods;
  const emissionFactorTypeFieldName = 'emissionFactorType';

  const currentEmissionFactorType = watch(emissionFactorTypeFieldName);

  const onEmissionTypeToggle = (value: EmissionFactorToggleEnum) => {
    setCurrentEmissionFactorToggle(value);
    if (value === EmissionFactorToggleEnum.DEFAULT) {
      //@ts-ignore
      setValue(emissionFactorTypeFieldName, orgEmissionFactorType);
    } else {
      setValue(emissionFactorTypeFieldName, '');
    }
  };

  const onSubmit = async (data: PortfolioFormValuesProps) => {
    const result = await createMutateFunction({
      variables: {
        name: data.name,
        emissionFactorType:
          currentEmissionFactorToggle === EmissionFactorToggleEnum.DEFAULT
            ? null
            : (data.emissionFactorType as emission_factor_type_enum),
      },
    });
    if (result.errors?.length !== 0 || !result.errors) {
      trackEvent('PORTFOLIO_CREATED', {
        emissionFactorType:
          currentEmissionFactorToggle === EmissionFactorToggleEnum.DEFAULT
            ? null
            : (data.emissionFactorType as emission_factor_type_enum),
      });
    }
  };

  //@ts-ignore
  const updatedTypes = updatedEmissionFactorTypes(currentEmissionFactorType as emission_factor_type_enum, org);

  const onDialogClose = () => {
    setCurrentEmissionFactorToggle(EmissionFactorToggleEnum.DEFAULT);
    onClose();
  };

  return (
    <PreDialog open={open} onClose={onDialogClose} fullWidth type="definedByChildren">
      <FormProvider
        methods={methods}
        onSubmit={(event) => {
          //TODO Adding stopPropagation and  preventDefault for here now as page reloads on submit, investigate this later why.
          event.stopPropagation();
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <PreDialogBody
          dialogtitle={t('General_CreateNewPortfolio')}
          content={
            <Grid container>
              <Typography sx={{ textAlign: 'justify', mb: 3 }}>{t('PortfolioCreateDialog_Description')}</Typography>
              <Grid item xs={12}>
                <RHFTextField name="name" label={t('General_EnterName')} />
              </Grid>
              <Grid container alignItems={'center'} mt={4} mb={2}>
                <Grid item xs={8}>
                  <Typography variant="subtitle1">{t('OrgSettings_DatabaseEmissionFactors')}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <EmissionFactorToggle
                    onEmissionTypeToggle={onEmissionTypeToggle}
                    defaultValue={currentEmissionFactorToggle}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} mb={2}>
                <RHFSelect
                  select
                  name="emissionFactorType"
                  label={t('General_SelectDatabaseEmissionFactors')}
                  disabled={currentEmissionFactorToggle === EmissionFactorToggleEnum.DEFAULT}
                >
                  {updatedTypes.map((type) => (
                    <MenuItem
                      key={type}
                      value={type}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {translateEmissionFactorTypeEnum(type)}
                      {orgEmissionFactorType === type && <Label ml={1}> {t('General_Standard')} </Label>}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
            </Grid>
          }
          actions={
            <>
              <Button variant="outlined" onClick={onClose}>
                {t('General_Cancel')}
              </Button>
              <LoadingButton type="submit" variant="contained" loading={loading}>
                {t('General_Create')}
              </LoadingButton>
            </>
          }
        ></PreDialogBody>
      </FormProvider>
    </PreDialog>
  );
}
