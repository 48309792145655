import { Button, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSessionData from '../../hooks/useSessionData';
import Iconify from '../Iconify';

/**
 * A generic permission wrapper for a particular page that requires admin access.
 * The user can still use other parts of the application.
 */
export default function AdminAccessRequired({ children }: PropsWithChildren) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAdmin } = useSessionData();

  if (isAdmin) {
    return <>{children}</>;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '2rem',
        // respecting the padding of the main component
        height: 'calc(100vh - 84px)',
      }}
    >
      <Iconify icon={'carbon:locked'} width={64} height={64} />
      <Typography variant="h3">{t('General_MissingPermissions-title')}</Typography>
      <Typography textAlign="center" maxWidth={600}>
        <Trans i18nKey="General_MissingPermissions-subtitle" />
      </Typography>

      <Button
        variant="contained"
        onClick={() => {
          navigate(-1);
        }}
      >
        {t('General_ReturnToPreviousPage')}
      </Button>
    </div>
  );
}
