import { yupResolver } from '@hookform/resolvers/yup';
import { InputAdornment, MenuItem, Stack } from '@mui/material';
import { getClassOfUse } from '@predium/client-lookup';
import { area_system_type_enum, area_type_enum, type_of_use_enum } from '@predium/enums';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FormProvider, RHFNumberField, RHFSelect, RHFTextField } from '../../../../../components/hook-form';
import { AreaMutation } from '../types';
import { getSortedTypesOfUse } from '../utils';

// due to circular dependency, we need to define the type here instead of using yup.InferType<typeof schema>
type FormValue = {
  name: string;
  description: string;
  is_rented: boolean;
  type_of_use_id: type_of_use_enum;
  value: number;
};

type Props = {
  defaultValue?: FormValue;
  onSubmit: (values: AreaMutation) => void;
};

const MFG1Form = ({ onSubmit, defaultValue }: Props) => {
  const { t } = useTranslation();

  const schema = yup.object().shape(
    {
      name: yup.string().when('description', {
        is: (description: string) => description === '',
        then: yup.string().required(t('DataCollectionAreas_NameOrDescriptionIsRequired')),
        otherwise: yup.string(),
      }),
      description: yup.string().when('name', {
        is: (name: string) => name === '',
        then: yup.string().required(t('DataCollectionAreas_NameOrDescriptionIsRequired')),
        otherwise: yup.string(),
      }),
      is_rented: yup.boolean().typeError(t('General_Required')).required(t('General_Required')),
      type_of_use_id: yup
        .mixed<type_of_use_enum>()
        .oneOf(Object.values(type_of_use_enum), t('General_Required'))
        .required(t('General_Required')),
      value: yup.number().required(t('General_Required')),
    },
    // to avoid circular dependency
    [['name', 'description']],
  );

  const methods = useForm<FormValue>({
    defaultValues: {
      name: defaultValue?.name ?? '',
      description: defaultValue?.description ?? '',
      is_rented: defaultValue?.is_rented,
      type_of_use_id: defaultValue?.type_of_use_id,
      value: defaultValue?.value ?? 0,
    },
    resolver: yupResolver(schema),
  });

  const handleSubmit = methods.handleSubmit((values) => {
    onSubmit({
      ...values,
      area_system_id: area_system_type_enum.GIF2017_DE,
      area_type_id: area_type_enum.MFG_1,
      class_of_use_id: getClassOfUse(values.type_of_use_id),
    });
  });

  const sortedTypeOfUse = getSortedTypesOfUse();

  return (
    <FormProvider formId="mfg1-form" methods={methods} onSubmit={handleSubmit}>
      <Stack spacing={4} my={1}>
        <RHFTextField name="description" label={t('General_Title')} variant="outlined" />
        <RHFTextField name="name" label="ID" variant="outlined" helperText={t('DataCollectionAreas_TitleHelperText')} />
        <RHFSelect name="is_rented" label={t('DataCollectionAreas_Management')}>
          <MenuItem value="true">{t('DataCollectionAreas_Rented')}</MenuItem>
          <MenuItem value="false">{t('DataCollectionAreas_OwnUse')}</MenuItem>
        </RHFSelect>
        <RHFSelect name="type_of_use_id" label={t('General_TypeOfUse')}>
          {sortedTypeOfUse.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </RHFSelect>
        <RHFNumberField
          name="value"
          label={t('General_Area')}
          variant="outlined"
          decimalScale={2}
          InputProps={{
            endAdornment: <InputAdornment position="end">m²</InputAdornment>,
          }}
        />
      </Stack>
    </FormProvider>
  );
};

export default MFG1Form;
