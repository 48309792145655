/* eslint-disable @typescript-eslint/ban-ts-comment */
import { TARGET_PATH_FIRST_YEAR } from './TargetPathConstants';

const pathToYearIndex = (year: number): number => year - TARGET_PATH_FIRST_YEAR;
const emissionYearToIndex = (year: number): number => year - TARGET_PATH_FIRST_YEAR;

export function pathValueAtYear<T>(path: T[], year: number): T {
  return path[pathToYearIndex(year)];
}

export function pathValuesFromYear<T>(path: T[], year: number): T[] {
  if (!path || !year) return [];

  return path.slice(pathToYearIndex(year));
}

export function pathValuesWithYearFromYear<T>(path: T[], year: number = TARGET_PATH_FIRST_YEAR): [number, T][] {
  if (!path || !year) return [];

  const pathFromYear = pathValuesFromYear(path, year);
  return pathFromYear.map((value, idx) => [year + idx, value]);
}

export function co2EmissionsAtYear(emissions: number[], year: number): number {
  return emissions[emissionYearToIndex(year)];
}

export function co2EmissionsFromYear(emissions: number[], year: number): number[] {
  return emissions.slice(emissionYearToIndex(year));
}
