import { energy_consumer_technology_type_enum, energy_system_type_enum } from '@predium/enums';

const ConsumerEfficiencyHeating: Record<
  energy_consumer_technology_type_enum,
  Record<number, Record<number, number>>
> = {
  //Bundesanzeiger values for heating
  [energy_consumer_technology_type_enum.STANDARD_BOILER]: {
    [0]: { [0]: 1.47, [500]: 1.36, [2500]: 1.28 },
    [1987]: { [0]: 1.34, [500]: 1.26, [2500]: 1.19 },
    [1995]: { [0]: 1.33, [500]: 1.23, [2500]: 1.16 },
  },
  [energy_consumer_technology_type_enum.LOW_TEMPERATURE_BOILER]: {
    [0]: { [0]: 1.24, [500]: 1.21, [2500]: 1.18 },
    [1987]: { [0]: 1.19, [500]: 1.15, [2500]: 1.13 },
    [1995]: { [0]: 1.14, [500]: 1.11, [2500]: 1.09 },
  },
  [energy_consumer_technology_type_enum.WOOD_BOILER]: {
    [0]: { [0]: 1.24, [500]: 1.21, [2500]: 1.18 },
    [1987]: { [0]: 1.19, [500]: 1.15, [2500]: 1.13 },
    [1995]: { [0]: 1.14, [500]: 1.11, [2500]: 1.09 },
  },
  [energy_consumer_technology_type_enum.GAS_NON_CONDENSING_BOILER]: {
    [0]: { [0]: 1.24, [500]: 1.21, [2500]: 1.18 },
    [1987]: { [0]: 1.19, [500]: 1.15, [2500]: 1.13 },
    [1995]: { [0]: 1.14, [500]: 1.11, [2500]: 1.09 },
  },
  [energy_consumer_technology_type_enum.GAS_CONDENSING_BOILER]: {
    [0]: { [0]: 1.11, [500]: 1.09, [2500]: 1.07 },
    [1987]: { [0]: 1.09, [500]: 1.06, [2500]: 1.04 },
    [1995]: { [0]: 1.07, [500]: 1.05, [2500]: 1.04 },
    [1999]: { [0]: 0.99, [500]: 0.98, [2500]: 0.97 },
  },
  [energy_consumer_technology_type_enum.CONDENSING_BOILER]: {
    [0]: { [0]: 1.11, [500]: 1.09, [2500]: 1.07 },
    [1987]: { [0]: 1.09, [500]: 1.06, [2500]: 1.04 },
    [1995]: { [0]: 1.07, [500]: 1.05, [2500]: 1.04 },
    [1999]: { [0]: 0.99, [500]: 0.98, [2500]: 0.97 },
  },
  [energy_consumer_technology_type_enum.DISTRICT_HEATING_WITHOUT_KWK]: { [0]: { [0]: 1.02 } },
  [energy_consumer_technology_type_enum.DISTRICT_HEATING_WITH_KWK]: { [0]: { [0]: 1.02 } },
  [energy_consumer_technology_type_enum.ELECTRIC_HEAT_PUMP_AIR]: {
    [0]: { [0]: 0.45, [500]: 0.45, [2500]: 0.45 },
    [1995]: { [0]: 0.43, [500]: 0.43, [2500]: 0.43 },
  },
  [energy_consumer_technology_type_enum.ELECTRIC_HEAT_PUMP_GEO]: {
    [0]: { [0]: 0.36, [500]: 0.36, [2500]: 0.36 },
    [1995]: { [0]: 0.3, [500]: 0.3, [2500]: 0.3 },
  },
  [energy_consumer_technology_type_enum.CENTRAL_ELECTRIC_STORAGE]: { [0]: { [0]: 1.02 } },
  [energy_consumer_technology_type_enum.OIL_FURNACE]: { [0]: { [0]: 1.4 } },
  [energy_consumer_technology_type_enum.COAL_FURNACE]: { [0]: { [0]: 1.6 } },
  [energy_consumer_technology_type_enum.GAS_ROOM_HEATER]: { [0]: { [0]: 1.47 } },
  [energy_consumer_technology_type_enum.DIRECT_ELECTRICITY_HEATING]: { [0]: { [0]: 1.02 } },
  [energy_consumer_technology_type_enum.GAS_FLOOR_HEATING]: {
    [0]: { [0]: 1.24 },
    [1995]: { [0]: 1.14 },
  },
  [energy_consumer_technology_type_enum.GAS_FLOW_HEATER]: {
    [0]: { [0]: 1.24 },
    [1995]: { [0]: 1.14 },
  },
  [energy_consumer_technology_type_enum.SMALL_ELECTRIC_STORAGE]: {
    [0]: { [0]: 1.12 },
    [1995]: { [0]: 1.05 },
  },
  [energy_consumer_technology_type_enum.WOOD_FURNACE]: { [0]: { [0]: 1.6 } },

  // not applicable for heating
  [energy_consumer_technology_type_enum.ELECTRIC_FLOW_HEATER]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.ELECTRIC_IMMERSION_HEATER]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.SOLAR_PLANT]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.INCANDESCENT_LAMPS]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.FLUORESCENT_LAMPS]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.LED]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.HALOGEN_LAMPS]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.CENTRAL_AIR_CONDITIONING]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.CENTRAL_EXHAUST]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.CENTRAL_SUPPLY_EXHAUST_WITHOUT_HRU]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.CENTRAL_SUPPLY_EXHAUST_WITH_HRU]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITHOUT_HRU]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITH_HRU]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.REVERSIBLE_AIR_WATER_HEAT_PUMP]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.REVERSIBLE_BRINE_WATER_HEAT_PUMP]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.ROOM_AIR_CONDITIONING]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.ELECTRICITY_MISCELLANEOUS]: { [0]: { [0]: 1 } },
};

const ConsumerEfficiencyHotWater: Record<
  energy_consumer_technology_type_enum,
  Record<number, Record<number, number>>
> = {
  //Bundesanzeiger values for hot water
  [energy_consumer_technology_type_enum.STANDARD_BOILER]: {
    [0]: { [0]: 2.05, [500]: 1.64, [2500]: 1.33 },
    [1987]: { [0]: 1.9, [500]: 1.57, [2500]: 1.31 },
    [1995]: { [0]: 1.71, [500]: 1.46, [2500]: 1.26 },
  },
  [energy_consumer_technology_type_enum.LOW_TEMPERATURE_BOILER]: {
    [0]: { [0]: 1.3, [500]: 1.23, [2500]: 1.18 },
    [1987]: { [0]: 1.31, [500]: 1.23, [2500]: 1.17 },
    [1995]: { [0]: 1.19, [500]: 1.15, [2500]: 1.12 },
  },
  [energy_consumer_technology_type_enum.GAS_NON_CONDENSING_BOILER]: {
    [0]: { [0]: 1.3, [500]: 1.23, [2500]: 1.18 },
    [1987]: { [0]: 1.31, [500]: 1.23, [2500]: 1.17 },
    [1995]: { [0]: 1.19, [500]: 1.15, [2500]: 1.12 },
  },
  [energy_consumer_technology_type_enum.WOOD_BOILER]: {
    [0]: { [0]: 1.3, [500]: 1.23, [2500]: 1.18 },
    [1987]: { [0]: 1.31, [500]: 1.23, [2500]: 1.17 },
    [1995]: { [0]: 1.19, [500]: 1.15, [2500]: 1.12 },
  },
  [energy_consumer_technology_type_enum.GAS_CONDENSING_BOILER]: {
    [0]: { [0]: 1.24, [500]: 1.17, [2500]: 1.13 },
    [1987]: { [0]: 1.25, [500]: 1.17, [2500]: 1.12 },
    [1995]: { [0]: 1.15, [500]: 1.12, [2500]: 1.09 },
    [1999]: { [0]: 1.13, [500]: 1.1, [2500]: 1.07 },
  },
  [energy_consumer_technology_type_enum.CONDENSING_BOILER]: {
    [0]: { [0]: 1.24, [500]: 1.17, [2500]: 1.13 },
    [1987]: { [0]: 1.25, [500]: 1.17, [2500]: 1.12 },
    [1995]: { [0]: 1.15, [500]: 1.12, [2500]: 1.09 },
    [1999]: { [0]: 1.13, [500]: 1.1, [2500]: 1.07 },
  },
  [energy_consumer_technology_type_enum.DISTRICT_HEATING_WITHOUT_KWK]: { [0]: { [0]: 1.14 } },
  [energy_consumer_technology_type_enum.DISTRICT_HEATING_WITH_KWK]: { [0]: { [0]: 1.14 } },
  [energy_consumer_technology_type_enum.ELECTRIC_HEAT_PUMP_AIR]: {
    [0]: { [0]: 0.44, [500]: 0.44, [2500]: 0.44 },
    [1995]: { [0]: 0.41, [500]: 0.41, [2500]: 0.41 },
  },
  [energy_consumer_technology_type_enum.ELECTRIC_HEAT_PUMP_GEO]: {
    [0]: { [0]: 0.38, [500]: 0.38, [2500]: 0.38 },
    [1995]: { [0]: 0.32, [500]: 0.32, [2500]: 0.32 },
  },
  [energy_consumer_technology_type_enum.ELECTRIC_FLOW_HEATER]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.GAS_FLOOR_HEATING]: {
    [0]: { [0]: 1.32 },
  },
  [energy_consumer_technology_type_enum.GAS_FLOW_HEATER]: {
    [0]: { [0]: 1.19, [500]: 1.19, [2500]: 1.19 },
    [1995]: { [0]: 1.16, [500]: 1.16, [2500]: 1.16 },
  },
  [energy_consumer_technology_type_enum.CENTRAL_ELECTRIC_STORAGE]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.SMALL_ELECTRIC_STORAGE]: { [0]: { [0]: 1 } },

  // not applicable for hot water
  [energy_consumer_technology_type_enum.SOLAR_PLANT]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.ELECTRIC_IMMERSION_HEATER]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.DIRECT_ELECTRICITY_HEATING]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.WOOD_FURNACE]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.OIL_FURNACE]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.COAL_FURNACE]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.GAS_ROOM_HEATER]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.INCANDESCENT_LAMPS]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.FLUORESCENT_LAMPS]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.LED]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.HALOGEN_LAMPS]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.CENTRAL_AIR_CONDITIONING]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.CENTRAL_EXHAUST]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.CENTRAL_SUPPLY_EXHAUST_WITHOUT_HRU]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.CENTRAL_SUPPLY_EXHAUST_WITH_HRU]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITHOUT_HRU]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITH_HRU]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.REVERSIBLE_AIR_WATER_HEAT_PUMP]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.REVERSIBLE_BRINE_WATER_HEAT_PUMP]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.ROOM_AIR_CONDITIONING]: { [0]: { [0]: 1 } },
  [energy_consumer_technology_type_enum.ELECTRICITY_MISCELLANEOUS]: { [0]: { [0]: 1 } },
};

//the efficiency of ventilation consumers is the heat recovery efficiency [0,1]
const ConsumerEfficiencyHeatRecoveryVentilation: Record<energy_consumer_technology_type_enum, number> = {
  [energy_consumer_technology_type_enum.CENTRAL_EXHAUST]: 0,
  [energy_consumer_technology_type_enum.CENTRAL_SUPPLY_EXHAUST_WITHOUT_HRU]: 0,
  [energy_consumer_technology_type_enum.CENTRAL_SUPPLY_EXHAUST_WITH_HRU]: 0.9,
  [energy_consumer_technology_type_enum.DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITHOUT_HRU]: 0,
  [energy_consumer_technology_type_enum.DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITH_HRU]: 0.73,

  // not applicable for heat recovery ventilation
  [energy_consumer_technology_type_enum.REVERSIBLE_AIR_WATER_HEAT_PUMP]: 0,
  [energy_consumer_technology_type_enum.REVERSIBLE_BRINE_WATER_HEAT_PUMP]: 0,
  [energy_consumer_technology_type_enum.ROOM_AIR_CONDITIONING]: 0,
  [energy_consumer_technology_type_enum.CENTRAL_AIR_CONDITIONING]: 0,
  [energy_consumer_technology_type_enum.STANDARD_BOILER]: 0,
  [energy_consumer_technology_type_enum.LOW_TEMPERATURE_BOILER]: 0,
  [energy_consumer_technology_type_enum.GAS_NON_CONDENSING_BOILER]: 0,
  [energy_consumer_technology_type_enum.WOOD_BOILER]: 0,
  [energy_consumer_technology_type_enum.GAS_CONDENSING_BOILER]: 0,
  [energy_consumer_technology_type_enum.CONDENSING_BOILER]: 0,
  [energy_consumer_technology_type_enum.DISTRICT_HEATING_WITHOUT_KWK]: 0,
  [energy_consumer_technology_type_enum.DISTRICT_HEATING_WITH_KWK]: 0,
  [energy_consumer_technology_type_enum.ELECTRIC_HEAT_PUMP_AIR]: 0,
  [energy_consumer_technology_type_enum.ELECTRIC_HEAT_PUMP_GEO]: 0,
  [energy_consumer_technology_type_enum.ELECTRIC_FLOW_HEATER]: 0,
  [energy_consumer_technology_type_enum.GAS_FLOOR_HEATING]: 0,
  [energy_consumer_technology_type_enum.GAS_FLOW_HEATER]: 0,
  [energy_consumer_technology_type_enum.CENTRAL_ELECTRIC_STORAGE]: 0,
  [energy_consumer_technology_type_enum.SMALL_ELECTRIC_STORAGE]: 0,
  [energy_consumer_technology_type_enum.SOLAR_PLANT]: 0,
  [energy_consumer_technology_type_enum.ELECTRIC_IMMERSION_HEATER]: 0,
  [energy_consumer_technology_type_enum.DIRECT_ELECTRICITY_HEATING]: 0,
  [energy_consumer_technology_type_enum.WOOD_FURNACE]: 0,
  [energy_consumer_technology_type_enum.OIL_FURNACE]: 0,
  [energy_consumer_technology_type_enum.COAL_FURNACE]: 0,
  [energy_consumer_technology_type_enum.GAS_ROOM_HEATER]: 0,
  [energy_consumer_technology_type_enum.INCANDESCENT_LAMPS]: 0,
  [energy_consumer_technology_type_enum.FLUORESCENT_LAMPS]: 0,
  [energy_consumer_technology_type_enum.LED]: 0,
  [energy_consumer_technology_type_enum.HALOGEN_LAMPS]: 0,
  [energy_consumer_technology_type_enum.ELECTRICITY_MISCELLANEOUS]: 0,
};
//the efficiency of lighting consumers is the light yield [0.001, 300]
const ConsumerLightYieldLighting: Record<energy_consumer_technology_type_enum, number> = {
  //TODO PRE-2962: introduce new types and set the light yield
  [energy_consumer_technology_type_enum.INCANDESCENT_LAMPS]: 10.5,
  [energy_consumer_technology_type_enum.FLUORESCENT_LAMPS]: 53,
  [energy_consumer_technology_type_enum.LED]: 108,
  [energy_consumer_technology_type_enum.HALOGEN_LAMPS]: 13,

  // not applicable for heat light yield
  [energy_consumer_technology_type_enum.ELECTRICITY_MISCELLANEOUS]: 0,
  [energy_consumer_technology_type_enum.CENTRAL_EXHAUST]: 0,
  [energy_consumer_technology_type_enum.CENTRAL_SUPPLY_EXHAUST_WITHOUT_HRU]: 0,
  [energy_consumer_technology_type_enum.CENTRAL_SUPPLY_EXHAUST_WITH_HRU]: 0,
  [energy_consumer_technology_type_enum.DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITHOUT_HRU]: 0,
  [energy_consumer_technology_type_enum.DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITH_HRU]: 0,
  [energy_consumer_technology_type_enum.REVERSIBLE_AIR_WATER_HEAT_PUMP]: 0,
  [energy_consumer_technology_type_enum.REVERSIBLE_BRINE_WATER_HEAT_PUMP]: 0,
  [energy_consumer_technology_type_enum.ROOM_AIR_CONDITIONING]: 0,
  [energy_consumer_technology_type_enum.CENTRAL_AIR_CONDITIONING]: 0,
  [energy_consumer_technology_type_enum.STANDARD_BOILER]: 0,
  [energy_consumer_technology_type_enum.LOW_TEMPERATURE_BOILER]: 0,
  [energy_consumer_technology_type_enum.GAS_NON_CONDENSING_BOILER]: 0,
  [energy_consumer_technology_type_enum.WOOD_BOILER]: 0,
  [energy_consumer_technology_type_enum.GAS_CONDENSING_BOILER]: 0,
  [energy_consumer_technology_type_enum.CONDENSING_BOILER]: 0,
  [energy_consumer_technology_type_enum.DISTRICT_HEATING_WITHOUT_KWK]: 0,
  [energy_consumer_technology_type_enum.DISTRICT_HEATING_WITH_KWK]: 0,
  [energy_consumer_technology_type_enum.ELECTRIC_HEAT_PUMP_AIR]: 0,
  [energy_consumer_technology_type_enum.ELECTRIC_HEAT_PUMP_GEO]: 0,
  [energy_consumer_technology_type_enum.ELECTRIC_FLOW_HEATER]: 0,
  [energy_consumer_technology_type_enum.GAS_FLOOR_HEATING]: 0,
  [energy_consumer_technology_type_enum.GAS_FLOW_HEATER]: 0,
  [energy_consumer_technology_type_enum.CENTRAL_ELECTRIC_STORAGE]: 0,
  [energy_consumer_technology_type_enum.SMALL_ELECTRIC_STORAGE]: 0,
  [energy_consumer_technology_type_enum.SOLAR_PLANT]: 0,
  [energy_consumer_technology_type_enum.ELECTRIC_IMMERSION_HEATER]: 0,
  [energy_consumer_technology_type_enum.DIRECT_ELECTRICITY_HEATING]: 0,
  [energy_consumer_technology_type_enum.WOOD_FURNACE]: 0,
  [energy_consumer_technology_type_enum.OIL_FURNACE]: 0,
  [energy_consumer_technology_type_enum.COAL_FURNACE]: 0,
  [energy_consumer_technology_type_enum.GAS_ROOM_HEATER]: 0,
};

export function getConsumerEfficiency({
  energyConsumerTechnologyType,
  energySystemType,
  yearConstructed,
  area,
}: {
  energyConsumerTechnologyType: energy_consumer_technology_type_enum;
  energySystemType:
    | energy_system_type_enum.HEATING
    | energy_system_type_enum.HOT_WATER
    | energy_system_type_enum.VENTILATION
    | energy_system_type_enum.LIGHTING;
  yearConstructed: number;
  area: number;
}): number {
  //VENTILATION - the efficiency of ventilation consumers is the heat recovery efficiency
  if (energySystemType == energy_system_type_enum.VENTILATION) {
    return ConsumerEfficiencyHeatRecoveryVentilation[energyConsumerTechnologyType];
  }

  //LIGHTING - the efficiency of ventilation consumers is the heat recovery efficiency
  if (energySystemType == energy_system_type_enum.LIGHTING) {
    return ConsumerLightYieldLighting[energyConsumerTechnologyType];
  }

  //HEATING AND HOT WATER
  else {
    const technologyData =
      energySystemType === energy_system_type_enum.HEATING
        ? ConsumerEfficiencyHeating[energyConsumerTechnologyType]
        : ConsumerEfficiencyHotWater[energyConsumerTechnologyType];

    const closestConstructionYear = Math.max(
      ...Object.keys(technologyData)
        .map(Number)
        .filter((year) => year <= yearConstructed),
    );

    const areaData = technologyData[closestConstructionYear];

    // Find the closest area that is less than or equal to the given area
    const closestArea = Math.max(
      ...Object.keys(areaData)
        .map(Number)
        .filter((a) => a <= area),
    );

    return areaData[closestArea];
  }
}
