import { useContext } from 'react';
import { DataCollectionFiltersContext } from '../contexts/DataCollectionFiltersContext';

const useDataCollectionFilters = () => {
  const context = useContext(DataCollectionFiltersContext);

  if (context === null) {
    throw new Error('useDataCollectionFilters must be used within a DataCollectionFilterContextProvider');
  }

  return context;
};

export default useDataCollectionFilters;
