import { Box, FormHelperText, SxProps, TextFieldProps, Theme } from '@mui/material';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker, DesktopDatePickerProps, DesktopDatePickerSlotsComponent } from '@mui/x-date-pickers';
import React, { ReactNode, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useOutsideHandler from '../../hooks/useOutsideHandler';

//  Done via: https://mui.com/x/react-date-pickers/adapters-locale/#with-dayjs

interface IPropsBase {
  name: string;
  size?: TextFieldProps['size'];
  sx?: SxProps<Theme>;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  onChange?: (value: Date | null) => void;
  overrideValue?: Date | string | null;
  customButton?: JSX.Element;
}

type ControlledComponent = IPropsBase & { open: boolean; setOpen: (open: boolean) => void };
type UncontrolledComponent = IPropsBase & { open?: never; setOpen?: never };

type IProps = ControlledComponent | UncontrolledComponent;

export default function RHFDatePicker({
  name,
  size = 'medium',
  sx,
  onChange,
  overrideValue,
  customButton,
  startAdornment = null,
  endAdornment = null,
  open,
  setOpen,
  ...other
}: IProps & Omit<DesktopDatePickerProps<Date, Date>, 'onChange' | 'value' | 'renderInput' | 'InputProps'>) {
  const { control } = useFormContext();
  const wrapperRef = useRef(null);
  useOutsideHandler(wrapperRef, () => setOpen?.(false));

  const components = {
    PaperContent: ({ children }: { children: ReactNode }) => <div ref={wrapperRef}>{children}</div>,
  } as Partial<DesktopDatePickerSlotsComponent>;
  if (customButton) {
    components['ActionBar'] = () => customButton;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <DesktopDatePicker
            value={field.value ?? null}
            onChange={(value) => {
              field.onChange(value);
              onChange?.(value);
              setOpen?.(false);
            }}
            open={open}
            components={components}
            renderInput={(params) => {
              const calendarButton = params.InputProps?.endAdornment;
              let value = overrideValue ?? field.value;

              return (
                <TextField
                  sx={{ width: '100%', ...sx }}
                  size={size}
                  value={value}
                  disabled={field.value === null && !!overrideValue}
                  inputRef={params.inputRef}
                  label={params.label}
                  // if we have specified an overrideValue, we need to make sure that strings can be displayed
                  {...(field.value || !overrideValue ? params : {})}
                  error={!!error}
                  InputProps={{
                    startAdornment,
                    endAdornment: (
                      <>
                        {endAdornment}
                        <Box onClick={() => setOpen?.(!open)}>{calendarButton}</Box>
                      </>
                    ),
                    autoComplete: 'off',
                  }}
                />
              );
            }}
            inputRef={field.ref}
            {...other}
          />
          {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {error.message}
            </FormHelperText>
          )}
        </>
      )}
    />
  );
}
