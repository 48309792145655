/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Button, Divider, Stack, Step, StepButton, StepLabel, Stepper, Typography } from '@mui/material';
import {
  DataCollectionEconomicUnitFragment,
  DataCollectionEnergyCertificateDraftEnergyCertificateDraftFragment,
  DataCollectionEnergyCertificateDraftSaveEnergyCertificateDraftMutation,
  DataCollectionEnergyCertificateDraftUserFragment,
  DataCollectionGetEconomicUnitWithBuildingsFragment,
  PortfolioManagementPortfolioFragment,
} from '@predium/client-graphql';
import { draft_status_enum } from '@predium/enums';
import { useState } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import Iconify from '../../../../components/Iconify';
import EnergyCertificateDraftBasicDataTab from './EnergyCertificateDraftBasicDataTab';
import EnergyCertificateDraftEnergyDataTab from './EnergyCertificateDraftEnergyDataTab';
import {
  DATA_COLLECTION_DRAFT_FORM_BASIC_DATA_KEYS,
  DATA_COLLECTION_DRAFT_FORM_ENERGY_DATA_KEYS,
} from './EnergyCertificateDraftForm';
import { arrangeEconomicUnitsByPortfolios } from './UploadEnergyCertificateDraftModalCommon';

type EnergyCertificateDraftProps = {
  portfolios: PortfolioManagementPortfolioFragment[];
  users: DataCollectionEnergyCertificateDraftUserFragment[];
  economicUnits: DataCollectionGetEconomicUnitWithBuildingsFragment[];
  cleanEnergyCertificateDraft: DataCollectionEnergyCertificateDraftEnergyCertificateDraftFragment;
  draftStatus: string;
  setAcceptOcrResultModalOpen: (open: boolean) => void;
  submitForm: () => Promise<DataCollectionEnergyCertificateDraftSaveEnergyCertificateDraftMutation>;
};

export default function EnergyCertificateDraftTabs({
  cleanEnergyCertificateDraft,
  economicUnits,
  portfolios,
  users,
  setAcceptOcrResultModalOpen,
  draftStatus,
  submitForm,
}: EnergyCertificateDraftProps) {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);

  const { watch, control } = useFormContext();
  const { errors, isDirty, isValid } = useFormState({ control });

  // This is all the state that is propagated to the parent component on change
  const portfolioId = watch('portfolio_id');
  const portfolioData = arrangeEconomicUnitsByPortfolios(economicUnits);
  const portfolio = portfolioData.find((portfolio) => portfolio.id === parseInt(portfolioId as unknown as string));
  const economicUnitsByPortfolio: DataCollectionEconomicUnitFragment[] = portfolio?.economicUnits ?? [];

  const invalidFieldNames: string[] = [];
  Object.entries(errors).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      // Check for nested properties and add key multiple times
      // i.e. energy_consumers.0/1/.../.energy_final_demand/energy_consumer_type
      value.filter(Boolean).forEach((entry) => {
        invalidFieldNames.push(...Array(Object.keys(entry).length).fill(key));
      });
    } else {
      invalidFieldNames.push(key);
    }
  });

  const energyCertificateDraftSteps = [
    {
      label: t('General_KeyData'),
      invalidCount: invalidFieldNames.filter((field) =>
        DATA_COLLECTION_DRAFT_FORM_BASIC_DATA_KEYS.some((key) => key === field),
      ).length,
      value: 'general_data',
      icon: <Iconify icon={'la:building-solid'} width={20} height={20} />,
      component: (
        <EnergyCertificateDraftBasicDataTab
          //@ts-ignore
          portfolios={portfolios}
          //@ts-ignore
          users={users}
          economicUnits={economicUnitsByPortfolio}
        />
      ),
    },
    {
      label: t('General_EnergyData'),
      invalidCount: invalidFieldNames.filter((field) =>
        DATA_COLLECTION_DRAFT_FORM_ENERGY_DATA_KEYS.some((key) => key === field),
      ).length,
      value: 'energy_data',
      icon: <Iconify icon={'la:cog'} width={20} height={20} />,
      component: <EnergyCertificateDraftEnergyDataTab />,
    },
  ];

  const totalSteps = energyCertificateDraftSteps.length;
  const isLastStep = activeStep === totalSteps - 1;

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  return (
    <Stack sx={{ p: 4, height: '100%' }} justifyContent={'space-between'}>
      <Box sx={{ mb: 4 }}>
        <Stack alignItems={'center'} justifyContent={'center'}>
          <Box sx={{ width: 400 }}>
            <Stepper nonLinear activeStep={activeStep} sx={{ py: 1 }}>
              {energyCertificateDraftSteps.map((step, index) => (
                // TODO: Not entirely correct but the tabs might be removed in a next iteration anyway. If not we would need to check the default values if everything has been provided and if there are no invalid values.
                // Skipping this implementation now for simplicity reasons.
                <Step
                  key={index}
                  completed={cleanEnergyCertificateDraft.draft_status_id === draft_status_enum.COMPLETE}
                >
                  <StepButton
                    optional={
                      step.invalidCount > 0 ? (
                        <Typography variant="caption" color="error">
                          <Trans
                            i18nKey="DataCollection_EnergyCertificateDrafts_MissingData"
                            values={{ count: step.invalidCount }}
                          />
                        </Typography>
                      ) : null
                    }
                    onClick={() => setActiveStep(index)}
                    sx={{ textAlign: 'left' }}
                    disableRipple
                  >
                    <StepLabel>{step.label}</StepLabel>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Stack>

        {energyCertificateDraftSteps[activeStep].component}
      </Box>

      <Box>
        <Divider />
        <Stack justifyContent="space-between" direction="row" sx={{ mt: 4, mb: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
            startIcon={<Iconify icon={'material-symbols:arrow-back-ios-new'} width={18} height={18} />}
          >
            {t('General_Back')}
          </Button>

          <Box>
            {draftStatus === draft_status_enum.OCR_REVIEW && isValid && !isDirty ? (
              <Button
                sx={{ mr: 1 }}
                type="button"
                variant="contained"
                onClick={() => setAcceptOcrResultModalOpen(true)}
                startIcon={<Iconify icon="bi:check" />}
              >
                {t('General_AcceptResult')}
              </Button>
            ) : (
              <Button
                sx={{ mr: 1 }}
                variant="outlined"
                disabled={!isDirty}
                startIcon={<Iconify icon="material-symbols:save" />}
                onClick={submitForm}
              >
                {t('General_SaveDraft')}
              </Button>
            )}

            <Button
              variant="contained"
              type="submit"
              startIcon={<Iconify icon="healthicons:i-documents-accepted" />}
              disabled={Object.keys(errors).length > 0}
            >
              {t('General_CreateBuilding')}
            </Button>
          </Box>

          <Button
            onClick={handleNext}
            disabled={isLastStep}
            endIcon={<Iconify icon={'material-symbols:arrow-forward-ios'} width={18} height={18} />}
          >
            {t('General_Continue')}
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
}
