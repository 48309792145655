import { MenuItem, Stack, StackProps, TextField, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EmissionCertificateForm from './forms/EmissionCertificateForm';

type Props = {
  sx?: StackProps['sx'];
  fileId: number;
};

export default function ProcessDocumentForm({ fileId, ...props }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  //make this extendable later on
  const extendedForm = <EmissionCertificateForm />;

  return (
    // for the focus page view scrollbar
    <Stack {...props} sx={{ gap: 2, width: 'calc(30vw - 24px - 12px)' }}>
      <Typography variant="h6">{t('DataCollection_ProcessDocument_FormTitle')}</Typography>
      <TextField
        select
        label={t('DataCollection_ProcessDocument_DocumentType')}
        value={'emission_certificate'}
        disabled
        sx={{
          display: 'inline-block',
          width: '100%',
          mt: 2,
          '& fieldset': { border: '0 !important' },
          '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
          '& .MuiOutlinedInput-root': { borderRadius: 1, width: '100%' },
          '& .MuiNativeSelect-icon': { top: 4, right: 4, width: 20, height: 20 },
          '& .Mui-disabled': { backgroundColor: theme.palette.grey[200], color: 'text.secondary', borderRadius: 1 },
          '& .Mui-disabled .MuiOutlinedInput-input': {
            WebkitTextFillColor: theme.palette.text.primary,
          },
        }}
      >
        <MenuItem value="emission_certificate">{t('General_EmissionCertificate', { count: 1 })}</MenuItem>
      </TextField>
      {/**
       * needs to be called like this, because otherwise it will
       * be an anonymous function and react will rerender the form,
       * which will cause react to treat this as a new form and
       * the form will lose its state and focus -- can maybe be solved better
       */}
      {extendedForm}
    </Stack>
  );
}
