/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/icons';
import DeleteConfirmationModal from '../../../../components/DeleteConfirmationModal';
import Iconify from '../../../../components/Iconify';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import { DELETE_ENERGY_CERTIFICATE_FILE } from '../../../../graphql/DataCollection.mutations';
import { GET_MISSING_ENERGY_CERTIFICATE_FILES } from '../../../../graphql/DataCollection.queries';
import useBuilding from '../Context/useBuilding';
import { EnrichedFileType } from './SubBuildingDocuments';

type Props = {
  subBuildingId: number;
  file: EnrichedFileType;
};

function DeleteEnergyCertificateFile({ subBuildingId, file }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { hasEditAccess } = useBuilding();

  const [documentToDelete, setDocumentToDelete] = useState<EnrichedFileType | null>(null);

  const showErrorSnackbar = () => {
    enqueueSnackbar(t('DataCollection_EnergyCertificateDelete_ErrorMessage'), {
      variant: 'error',
      autoHideDuration: SnackbarTimeouts.Error,
    });
  };

  const [deleteEnergyCertificateFileMutation] = useMutation(DELETE_ENERGY_CERTIFICATE_FILE, {
    variables: {
      //@ts-ignore
      fileId: documentToDelete?.id,
    },
    refetchQueries: [{ query: GET_MISSING_ENERGY_CERTIFICATE_FILES, variables: { subBuildingId } }],
    update: (cache, { data }) => {
      //@ts-ignore
      if (data.delete_file_by_pk) {
        const normalizedId = cache.identify({
          //@ts-ignore
          id: data.delete_file_by_pk.id,

          //@ts-ignore
          __typename: data.delete_file_by_pk.__typename,
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
    onCompleted: (data) => {
      if (!data.delete_file_by_pk) {
        showErrorSnackbar();
      }
    },
    onError: () => showErrorSnackbar(),
  });

  const handleDeleteDocument = () => {
    deleteEnergyCertificateFileMutation();
    setDocumentToDelete(null);
  };

  return (
    <>
      {hasEditAccess && (
        <MenuItem onClick={() => setDocumentToDelete(file)} sx={{ color: 'error.main' }}>
          <Iconify icon={ICONS.TRASH} />
          {t('General_Delete')}
        </MenuItem>
      )}

      <DeleteConfirmationModal
        open={documentToDelete !== null}
        title={t('General_DeleteModalTitle-EnergyCertificate', { count: 1 })}
        description={
          <Trans
            i18nKey={'General_DeleteModalDescription-EnergyCertificate'}
            values={{
              name: documentToDelete?.name ?? '',
              count: 1,
            }}
            components={{ bold: <strong /> }}
          />
        }
        onClose={() => setDocumentToDelete(null)}
        onDelete={handleDeleteDocument}
      />
    </>
  );
}

export default DeleteEnergyCertificateFile;
