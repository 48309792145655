import { country_enum, energy_source_type_enum } from '@predium/enums';

/**
 * Returns the primary energy coefficient for a given energy source and country
 *
 * @export
 * @param {country_enum} country
 * @param {energy_source_type_enum} energySource
 * @return {*}  {number}
 */
export function getPrimaryEnergyCoefficientForEnergySource(
  country: country_enum,
  energySource: energy_source_type_enum,
): number {
  return PrimaryEnergyCoefficientForEnergySource[country][energySource];
}

const PrimaryEnergyCoefficientForEnergySource: {
  [key in country_enum]: {
    [key in energy_source_type_enum]: number;
  };
} = {
  [country_enum.DE]: {
    [energy_source_type_enum.NATURAL_GAS]: 1.1,
    [energy_source_type_enum.FUEL_OIL]: 1.1,
    [energy_source_type_enum.COAL]: 1.1,
    [energy_source_type_enum.LIGNITE]: 1.1,
    [energy_source_type_enum.LPG]: 1.1,
    [energy_source_type_enum.BIO_GAS]: 1.1,
    [energy_source_type_enum.WOODEN_PELLETS]: 0.2,
    [energy_source_type_enum.WOOD]: 0.2,
    [energy_source_type_enum.ELECTRICITY_MIX]: 1.8,
    [energy_source_type_enum.ELECTRICITY_GREEN]: 1.8,
    [energy_source_type_enum.SOLAR]: 0,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]: 0.7,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]: 0.7,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]: 0,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]: 1.3,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]: 1.3,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: 0.1,
    [energy_source_type_enum.UNSPECIFIED]: 1.3,
  },
  [country_enum.PL]: {
    [energy_source_type_enum.NATURAL_GAS]: 1.1,
    [energy_source_type_enum.FUEL_OIL]: 1.1,
    [energy_source_type_enum.COAL]: 1.1,
    [energy_source_type_enum.LIGNITE]: 1.1,
    [energy_source_type_enum.LPG]: 1.1,
    [energy_source_type_enum.BIO_GAS]: 0.5,
    [energy_source_type_enum.WOODEN_PELLETS]: 0.2,
    [energy_source_type_enum.WOOD]: 0.2,
    [energy_source_type_enum.ELECTRICITY_MIX]: 2.5,
    [energy_source_type_enum.ELECTRICITY_GREEN]: 2.5,
    [energy_source_type_enum.SOLAR]: 0,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]: 0.8,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]: 0.8,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]: 0.15,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]: 1.3,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]: 1.2,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: 0.1,
    [energy_source_type_enum.UNSPECIFIED]: 1.3,
  },
  [country_enum.AT]: {
    [energy_source_type_enum.NATURAL_GAS]: 1.1,
    [energy_source_type_enum.FUEL_OIL]: 1.2,
    [energy_source_type_enum.COAL]: 1.46,
    [energy_source_type_enum.LIGNITE]: 1.46,
    [energy_source_type_enum.LPG]: 1.2,
    [energy_source_type_enum.BIO_GAS]: 1.2,
    [energy_source_type_enum.WOODEN_PELLETS]: 1.13,
    [energy_source_type_enum.WOOD]: 1.13,
    [energy_source_type_enum.ELECTRICITY_MIX]: 1.76,
    [energy_source_type_enum.ELECTRICITY_GREEN]: 1.76,
    [energy_source_type_enum.SOLAR]: 0,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]: 0.59,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]: 0.59,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]: 0.59,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]: 1.48,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]: 1.48,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: 1.72,
    [energy_source_type_enum.UNSPECIFIED]: 1.72,
  },
};
