import { useLazyQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React, { useRef } from 'react'; // Import useRef
import { useTranslation } from 'react-i18next';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import ExportPdfDialog, { PdfFormData } from '../../../../components/documents/export-pdf/ExportPdfDialog';
import { EXPORT_ACTION_PLAN_PDF } from '../../../../graphql/ActionPlanning.queries';
import { useLanguage } from '../../../../provider/LanguageProvider';
import { onExportCompleted } from '../../../../utils/createDownloadLink';
import { ActionPlanType } from './ActionPlanActionMenu';

type Props = {
  handleClose: () => void;
  open: boolean;
  actionPlan: ActionPlanType;
};

const ActionPlanExportPdf: React.FC<Props> = ({ open, handleClose, actionPlan }) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { enqueueSnackbar } = useSnackbar();

  const abortController = useRef(new AbortController()); // Create a ref to the AbortController

  const { street, postalCode, city } = actionPlan.buildingAddress;
  const fileName = `${street}_${postalCode}_${city}`;

  const [createPdfExport, { loading }] = useLazyQuery(EXPORT_ACTION_PLAN_PDF, {
    onError: () => {
      enqueueSnackbar(t('ActionPlanning_ActionPlan-PdfExportError'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
      handleClose();
    },
    onCompleted: async (data) => {
      onExportCompleted(
        data.getActionPlanPdf.body,
        'pdf',
        `${t('ActionPlanning_ActionPlan-PdfExportName')}_${fileName}`,
        language,
        true,
      );
      enqueueSnackbar(t('ActionPlanning_ActionPlan-PdfExportSuccess'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      handleClose();
    },
    context: {
      // Pass the abort controller's signal to the mutation context
      fetchOptions: {
        signal: abortController.current.signal,
      },
    },
  });

  const onSubmit = async (data: PdfFormData) => {
    createPdfExport({
      variables: {
        actionPlanId: actionPlan.id,
        // to avoid sending empty strings
        title: data.title || undefined,
        description: data.description || undefined,
      },
    });
  };

  const handleCancel = () => {
    // Abort the mutation when the cancel button is clicked
    if (loading) {
      abortController.current.abort();
    }

    handleClose();
  };

  return (
    <ExportPdfDialog
      open={open}
      loading={loading}
      onFormSubmit={(data) => onSubmit(data)}
      onCancel={handleCancel}
      dialogTitle={t('ActionPlanning_ActionPlan-ExportAsPdf')}
      descriptions={[
        t('ActionPlanning_ActionPlan-ExportPdfDescriptionFirst'),
        t('ActionPlanning_ActionPlan-ExportPdfDescriptionSecond'),
      ]}
    />
  );
};

export default ActionPlanExportPdf;
