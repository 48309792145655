import { TableRow, TableRowProps, alpha, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { HIGHLIGHT_AUTO_HIDE_DURATION, HIGHLIGHT_OPACITY, HIGHLIGHT_TRIGGER_DURATION } from '../../config';

export type TableRowWithHighlightProps = {
  children?: React.ReactNode;
  /**
   * Background color of the highlight. The `lighter` variant of the color inside the palette will be used.
   */
  background?: 'success' | 'warning' | 'error' | 'default';
  /**
   * If `true`, the highlight will be removed onHover.
   * @default true
   */
  removeHighlightOnHover?: boolean;
} & TableRowProps;

/**
 *  Wrapper for table row component that allows for highlighting.
 *
 * @returns a vanilla MUI TableRow
 */
export default function TableRowWithHighlight({
  children,
  background = 'default',
  removeHighlightOnHover = true,
  ...tableRowProps
}: TableRowWithHighlightProps) {
  const theme = useTheme();
  const defaultColor = theme.palette.background.default;

  //we need to remove the sx prop from the table row props, otherwise it will override the background color
  const { sx: sxProps, ...props } = tableRowProps;

  const getPalletteColor = useCallback(
    (color: typeof background) => {
      switch (color) {
        case 'success':
          return theme.palette.success.light;
        case 'warning':
          return theme.palette.warning.light;
        case 'error':
          return theme.palette.error.light;
        case 'default':
          return defaultColor;
        default:
          const exhaustiveCheck: never = color;
          throw new Error(`Unhandled color: ${exhaustiveCheck}`);
      }
    },
    [theme, defaultColor],
  );

  const [backgroundColor, setBackgroundColor] = useState<string>(
    alpha(getPalletteColor(background), HIGHLIGHT_OPACITY),
  );

  const handleOnMouseEnter = () => {
    //disable any highlights
    if (removeHighlightOnHover) setBackgroundColor(defaultColor);
  };

  //auto hide the background
  useEffect(() => {
    if (backgroundColor === defaultColor) return;
    const timer = setTimeout(() => {
      setBackgroundColor(defaultColor);
    }, HIGHLIGHT_AUTO_HIDE_DURATION);
    return () => clearTimeout(timer);
  }, [setBackgroundColor, backgroundColor, defaultColor]);

  useEffect(() => {
    setBackgroundColor(alpha(getPalletteColor(background), HIGHLIGHT_OPACITY));
  }, [setBackgroundColor, background, getPalletteColor]);

  return (
    <TableRow
      sx={[
        {
          background: backgroundColor,
          transition: `background ${HIGHLIGHT_AUTO_HIDE_DURATION / 1000}s ease-out`,
        },
        ...(Array.isArray(sxProps) ? sxProps : [sxProps]),
      ]}
      onMouseEnter={handleOnMouseEnter}
      {...props}
    >
      {children}
    </TableRow>
  );
}

/**
 * Helper function to check if any of the given timestamps are "new". This is used to highlight newly added/edited entries in a table.
 *
 * @param {(string | number | Date)[]} isoTimeStamps ISO time stamps to check
 * @returns {boolean} `true` if any of the timestamps have changed recently
 */
export const hasRecentlyChanged = (...isoTimeStamps: (string | number | Date)[]): boolean =>
  isoTimeStamps.some((timeStamp) => {
    const now = new Date();
    const then = new Date(timeStamp);
    const diff = now.getTime() - then.getTime();
    return diff < HIGHLIGHT_TRIGGER_DURATION;
  });
