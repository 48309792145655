import { Stack, Typography } from '@mui/material';
import { fShortenNumber } from '@predium/utils';
import isNil from 'lodash/isNil';

type Props = {
  title: string;
  value?: number;
  /**
   * Whether the value is available or not, it will be displayed as '—' if false
   */
  hasValue: boolean;
  unit: string;
  percentage?: number;
};

const Meter = ({ title, value = 0, hasValue, percentage, unit }: Props) => {
  return (
    <Stack direction="row">
      <Typography variant="body2" color="text.secondary">
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary" mr={1} ml="auto">
        {isNil(percentage) ? '' : hasValue ? `${percentage}%` : '—%'}
      </Typography>
      <Typography variant="body2" fontWeight={700} minWidth={110} textAlign="right">
        {hasValue ? fShortenNumber(value) : '—'} {unit}
      </Typography>
    </Stack>
  );
};

export default Meter;
