import { country_enum, country_state_enum, energy_source_type_enum } from '@predium/enums';
import { EmissionFactorProvider } from './emission-factor-provider.type';

const WUESTPARTNERDistrictHeatingPerCountryState: Record<country_state_enum, number> = {
  [country_state_enum.DE_BADEN_WUERTTEMBERG]: 0.207,
  [country_state_enum.DE_BAYERN]: 0.196,
  [country_state_enum.DE_BERLIN]: 0.255,
  [country_state_enum.DE_BRANDENBURG]: 0.243,
  [country_state_enum.DE_BREMEN]: 0.305,
  [country_state_enum.DE_HAMBURG]: 0.223,
  [country_state_enum.DE_HESSEN]: 0.232,
  [country_state_enum.DE_MECKLENBURG_VORPOMMERN]: 0, // TODO
  [country_state_enum.DE_NIEDERSACHSEN]: 0.311,
  [country_state_enum.DE_NORDRHEIN_WESTFALEN]: 0.201,
  [country_state_enum.DE_RHEINLAND_PFALZ]: 0.169,
  [country_state_enum.DE_SAARLAND]: 0, // TODO
  [country_state_enum.DE_SACHSEN]: 0.224,
  [country_state_enum.DE_SACHSEN_ANHALT]: 0.212,
  [country_state_enum.DE_SCHLESWIG_HOLSTEIN]: 0.238,
  [country_state_enum.DE_THUERINGEN]: 0.052,
  [country_state_enum.UNKNOWN]: 0,

  //poland (not supported)
  [country_state_enum.PL_GREATER_POLAND]: 0,
  [country_state_enum.PL_HOLY_CROSS]: 0,
  [country_state_enum.PL_KUYAVIAN_POMERANIAN]: 0,
  [country_state_enum.PL_LESSER_POLAND]: 0,
  [country_state_enum.PL_LODZ]: 0,
  [country_state_enum.PL_LOWER_SILESIAN]: 0,
  [country_state_enum.PL_LUBLIN]: 0,
  [country_state_enum.PL_LUBUSZ]: 0,
  [country_state_enum.PL_OPOLE]: 0,
  [country_state_enum.PL_MASOVIAN]: 0,
  [country_state_enum.PL_POMERANIAN]: 0,
  [country_state_enum.PL_SILESIAN]: 0,
  [country_state_enum.PL_SUBCARPATHIAN]: 0,
  [country_state_enum.PL_WARMIAN_MASURIAN]: 0,
  [country_state_enum.PL_WEST_POMERANIAN]: 0,
  [country_state_enum.PL_PODLASKIE]: 0,

  //austria (not supported)
  [country_state_enum.AT_BURGENLAND]: 0,
  [country_state_enum.AT_CARINTHIA]: 0,
  [country_state_enum.AT_LOWER_AUSTRIA]: 0,
  [country_state_enum.AT_SALZBURG]: 0,
  [country_state_enum.AT_STYRIA]: 0,
  [country_state_enum.AT_TYROL]: 0,
  [country_state_enum.AT_UPPER_AUSTRIA]: 0,
  [country_state_enum.AT_VIENNA]: 0,
  [country_state_enum.AT_VORARLBERG]: 0,
};

const WUESTPARTNERElectricityMixPerCountryState: Record<country_state_enum, number> = {
  [country_state_enum.DE_BADEN_WUERTTEMBERG]: 0.27,
  [country_state_enum.DE_BAYERN]: 0.094,
  [country_state_enum.DE_BERLIN]: 0.506,
  [country_state_enum.DE_BRANDENBURG]: 0.695,
  [country_state_enum.DE_BREMEN]: 0.768,
  [country_state_enum.DE_HAMBURG]: 0.644,
  [country_state_enum.DE_HESSEN]: 0.311,
  [country_state_enum.DE_MECKLENBURG_VORPOMMERN]: 0, // TODO
  [country_state_enum.DE_NIEDERSACHSEN]: 0.203,
  [country_state_enum.DE_NORDRHEIN_WESTFALEN]: 0.835,
  [country_state_enum.DE_RHEINLAND_PFALZ]: 0.191,
  [country_state_enum.DE_SAARLAND]: 0, // TODO
  [country_state_enum.DE_SACHSEN]: 0.759,
  [country_state_enum.DE_SACHSEN_ANHALT]: 0.358,
  [country_state_enum.DE_SCHLESWIG_HOLSTEIN]: 0.083,
  [country_state_enum.DE_THUERINGEN]: 0.128,
  [country_state_enum.UNKNOWN]: 0,

  //poland (not supported)
  [country_state_enum.PL_GREATER_POLAND]: 0,
  [country_state_enum.PL_HOLY_CROSS]: 0,
  [country_state_enum.PL_KUYAVIAN_POMERANIAN]: 0,
  [country_state_enum.PL_LESSER_POLAND]: 0,
  [country_state_enum.PL_LODZ]: 0,
  [country_state_enum.PL_LOWER_SILESIAN]: 0,
  [country_state_enum.PL_LUBLIN]: 0,
  [country_state_enum.PL_LUBUSZ]: 0,
  [country_state_enum.PL_OPOLE]: 0,
  [country_state_enum.PL_MASOVIAN]: 0,
  [country_state_enum.PL_POMERANIAN]: 0,
  [country_state_enum.PL_SILESIAN]: 0,
  [country_state_enum.PL_SUBCARPATHIAN]: 0,
  [country_state_enum.PL_WARMIAN_MASURIAN]: 0,
  [country_state_enum.PL_WEST_POMERANIAN]: 0,
  [country_state_enum.PL_PODLASKIE]: 0,

  //austria (not supported)
  [country_state_enum.AT_BURGENLAND]: 0,
  [country_state_enum.AT_CARINTHIA]: 0,
  [country_state_enum.AT_LOWER_AUSTRIA]: 0,
  [country_state_enum.AT_SALZBURG]: 0,
  [country_state_enum.AT_STYRIA]: 0,
  [country_state_enum.AT_TYROL]: 0,
  [country_state_enum.AT_UPPER_AUSTRIA]: 0,
  [country_state_enum.AT_VIENNA]: 0,
  [country_state_enum.AT_VORARLBERG]: 0,
};

/**
 * Emission factors per postal code for customer: "Wöhr + Bauer".
 *
 * This must be refactored in the future and moved into a dynamic lookup table for customer values.
 */
export const WuestEmissionFactorProvider: EmissionFactorProvider = {
  getEmissionFactor({
    energySourceType,
    countryState,
  }: {
    energySourceType: energy_source_type_enum;
    year: number;
    country: country_enum;
    countryState: country_state_enum;
    postalCode: string;
  }): number {
    const WUEST_PARTNER_lookup: Record<energy_source_type_enum, number> = {
      [energy_source_type_enum.FUEL_OIL]: 0.31,
      [energy_source_type_enum.NATURAL_GAS]: 0.202,
      [energy_source_type_enum.LPG]: 0.27,
      [energy_source_type_enum.COAL]: 0.4,
      [energy_source_type_enum.LIGNITE]: 0.43,
      [energy_source_type_enum.WOODEN_PELLETS]: 0.023,
      [energy_source_type_enum.WOOD]: 0.023,
      [energy_source_type_enum.UNSPECIFIED]: 1,
      [energy_source_type_enum.ELECTRICITY_MIX]: WUESTPARTNERElectricityMixPerCountryState[countryState],
      [energy_source_type_enum.ELECTRICITY_GREEN]: 0,
      [energy_source_type_enum.SOLAR]: 0,
      [energy_source_type_enum.BIO_GAS]: 0.14,

      [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]:
        WUESTPARTNERDistrictHeatingPerCountryState[countryState],
      [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]:
        WUESTPARTNERDistrictHeatingPerCountryState[countryState],
      [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]:
        WUESTPARTNERDistrictHeatingPerCountryState[countryState],

      [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]:
        WUESTPARTNERDistrictHeatingPerCountryState[countryState],
      [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]:
        WUESTPARTNERDistrictHeatingPerCountryState[countryState],
      [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]:
        WUESTPARTNERDistrictHeatingPerCountryState[countryState],
    };

    return WUEST_PARTNER_lookup[energySourceType];
  },
};
