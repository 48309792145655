import { TableCell, TableRow, useTheme } from '@mui/material';
import { ActionPlanningActionSubsidyFragment, action_subsidy_value_type_enum } from '@predium/client-graphql';
import { SemanticButton } from '../../../../../../components/Tile';
import ExpectedSubsidyFunding from './ExpectedSubsidyFunding';

export default function EditSubsidyButton({
  actionSubsidies,
  totalCost,
  onClick,
  isOverview,
}: {
  actionSubsidies: Omit<ActionPlanningActionSubsidyFragment, 'id'>[];
  totalCost: number;
  onClick: () => void;
  isOverview?: boolean;
}) {
  const theme = useTheme();

  const totalExpectedSubsidyFunding = actionSubsidies.reduce((acc, curr) => {
    if (curr.type === action_subsidy_value_type_enum.percentage) {
      return acc + totalCost * (curr.value / 100);
    }
    return acc + curr.value;
  }, 0);

  return (
    <TableRow>
      <TableCell
        colSpan={9}
        sx={{
          pb: '0px !important',
        }}
      >
        <SemanticButton
          sx={{
            borderRadius: 10,
            '&:hover': {
              backgroundColor: theme.palette.grey[500_12],
            },
            //extremely hacky, but the table doesnt like the button as a child
            //@TODO: FIXME: find a better solution
            width: 'calc(100% + 16px) !important',
            height: '100% !important',
            padding: '8px !important',
            marginLeft: '-8px !important',
            cursor: 'pointer',
          }}
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.preventDefault();
            event.stopPropagation();
            onClick();
          }}
        >
          <ExpectedSubsidyFunding
            total={totalExpectedSubsidyFunding}
            actionSubsidies={actionSubsidies}
            actionCost={totalCost}
            useActionCostsInDB={isOverview}
            canEdit
          />
        </SemanticButton>
      </TableCell>
    </TableRow>
  );
}
