/* eslint-disable @typescript-eslint/ban-ts-comment */
import { getAreaSum } from '@predium/utils';
import { createSearchParams } from 'react-router-dom';
import { esgAnalysisFilters, EsgAnalysisFiltersSearchParams } from './EsgAnalysisFilters/EsgAnalysisFilters';

type UnitId = 'co2_emissions' | 'co2_taxes' | 'primary_energy' | 'final_energy';

type UnitMap = {
  [key in UnitId]: string;
};

//TODO move this to a shared libs PRE-3193
/**
 * Returns the unit corresponding to the given id.
 *
 * @param {UnitId} id - The id for the Unit.
 * @returns {string} The unit corresponding to the id.
 */
export const getUnit = (id: UnitId, absolute: boolean = false) => {
  const unitsMap: UnitMap = {
    co2_emissions: absolute ? 'kg CO₂e/a' : 'kg CO₂e/m²a',
    co2_taxes: absolute ? '€/a' : '€/m²a',
    primary_energy: absolute ? 'kWh/a' : 'kWh/m²a',
    final_energy: absolute ? 'kWh/a' : 'kWh/m²a',
  };

  if (!unitsMap[id]) {
    throw new Error(`Unhandled id ${id}`);
  }

  return unitsMap[id];
};

/**
 * This function calculates the absolute value multiplier by summing all the values in the areas array.
 * If the showTotal flag is true, the sum of all values is returned, otherwise 1 is returned (meaning that we show the value per m2).
 *
 * @param {boolean} showTotal - The flag to determine if the sum of all values should be returned.
 * @param {object} building - The building object containing the areas array.
 * @returns {number} The absolute value multiplier.
 */
export const getAbsoluteValueMultiplier = (showTotal: boolean, building: { areas: { value: number }[] }) => {
  if (showTotal) {
    return getAreaSum(building.areas);
  }
  return 1;
};

export const filtersToSearchParams = (filters: EsgAnalysisFiltersSearchParams) =>
  createSearchParams(
    Object.fromEntries(
      Object.entries(filters).map(([key, value]) => {
        //@ts-ignore
        const { type, paramKey } = esgAnalysisFilters.find((param) => param.key === key);

        return [paramKey, type.stringify(value as any)];
      }),
    ),
  );

export const searchParamsToFilters = (searchParams: URLSearchParams) =>
  Object.fromEntries(
    esgAnalysisFilters.map(({ key, type, paramKey }) => [key, type.parse(searchParams.getAll(paramKey))]),
  );

/**
 * Returns the value or the absolute value depending on the isAbsolute flag.
 *
 * it accepts an object with a key and a keyAbsolute property.
 *
 * @param {boolean} isAbsolute - The flag to determine if the absolute value should be returned.
 * @param {object} obj - The object containing the key and keyAbsolute properties.
 * @param {string} key - The key to be used to get the value.
 *
 * @returns {number} The value or the absolute value.
 */
export const getValueOrAbsolute = <T extends Record<string, any>, K extends keyof T & string>(
  isAbsolute: boolean,
  obj: {
    [P in K]: number;
  } & {
    [P in `${K & string}Absolute`]: number;
  },
  key: K,
) => {
  if (isAbsolute) {
    return obj[`${key}Absolute`];
  }

  return obj[key];
};
