/* eslint-disable @typescript-eslint/ban-ts-comment */
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useTranslation } from 'react-i18next';

const visuallyHidden = {
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: '1px',
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: '1px',
} as const;

export type HeadLabel = {
  id: string;
  label: string;
  sortingDisabled?: Boolean;
  minWidth?: number;
};

type Props = {
  order: 'asc' | 'desc';
  orderBy: string;
  headLabel: HeadLabel[];
  onRequestSort: (id: string) => void;
};

// TODO: Duplicated from ScenariosTableHead.tsx but different concerns. Unify into a single resuable table head component later...
export default function ReportingReportsTableHead({ order, orderBy, headLabel, onRequestSort }: Props) {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            //@ts-ignore
            sx={{ ...(!isNaN(headCell.minWidth) && { minWidth: headCell.minWidth }) }}
          >
            {!headCell.sortingDisabled ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => onRequestSort(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === 'desc' ? t('General_SortedDescending') : t('General_SortedAscending')}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
