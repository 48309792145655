import { Button } from '@mui/material';
import Iconify from '../../../../../components/Iconify';

export function EmptyEnvelopeUnit({
  title,
  icon,
  handleClick,
}: {
  title: string;
  icon: string;
  handleClick: () => void;
}) {
  return (
    <Button variant="contained" onClick={handleClick} startIcon={<Iconify icon={icon} />}>
      {title}
    </Button>
  );
}
