/* eslint-disable @typescript-eslint/ban-ts-comment */
import { energy_system_type_enum } from '@predium/enums';
import { EnergySystemFormValues, SystemRoute } from '../BuildingTechnology';

export const generateYearRange = (startYear: number) => {
  const endYear = new Date().getFullYear();
  const years = [...Array(endYear - startYear + 1)].map((_, index) => startYear + index);
  return years;
};

/**
 * Generates a field name based on a type, index.
 *
 * @param {string} type - The type of the field.
 * @param {number} index - The index of the field.
 * @returns {{[key: string]: string}} The generated field names.
 */
export const generateSystemFieldNames = (type: energy_system_type_enum, index: number): { [key: string]: string } => {
  const fieldNamePrefix = `${type}[${index}]`;

  return {
    dataSourceTypeFieldname: `${fieldNamePrefix}.dataSourceType`,
    energySystemTypeFieldname: `${fieldNamePrefix}.type`,
    systemIdFieldname: `${fieldNamePrefix}.id`,
    systemRoutesFieldname: `${fieldNamePrefix}.routes`,
  };
};

/**
 * Generates a field name based on a type, index.
 *
 * @param {string} type - The type of the field.
 * @param {number} index - The index of the field.
 * @returns {{[key: string]: string}} The generated field names.
 */
export const generateSystemRouteFieldNames = (
  type: energy_system_type_enum,
  systemIndex: number,
  routeIndex: number,
) => {
  const fieldNamePrefix = `${type}[${systemIndex}].routes[${routeIndex}]`;

  return {
    energySourcefieldname: `${fieldNamePrefix}.energySource`,
    energyFinalFieldname: `${fieldNamePrefix}.energyFinal`,
    consumerTechnologyFieldname: `${fieldNamePrefix}.consumerTechnologyType`,
    energyDistributionTypeFieldname: `${fieldNamePrefix}.energyDistributionType`,
    emissionFactorFieldname: `${fieldNamePrefix}.emissionFactor`,
    emissionFactorOriginIdFieldname: `${fieldNamePrefix}.emissionFactorOriginId`,
    emissionCertificateIdFieldname: `${fieldNamePrefix}.emissionCertificateId`,
    primaryEnergyFactorEmissionCertificateIdFieldname: `${fieldNamePrefix}.primaryEnergyFactorEmissionCertificateId`,
    primaryFactorFieldname: `${fieldNamePrefix}.primaryEnergyFactor`,
    pricePerKwhFieldname: `${fieldNamePrefix}.pricePerKwh`,
    expenditureFactorFieldname: `${fieldNamePrefix}.expenditureFactor`,
    energyConsumerTypeFieldname: `${fieldNamePrefix}.energyConsumerType`,
    energyStorageTypeFieldname: `${fieldNamePrefix}.energyStorageType`,
    energyStorageHeatLossFieldname: `${fieldNamePrefix}.energyStorageHeatLoss`,
    distributionHeatLossFieldname: `${fieldNamePrefix}.distributionHeatLoss`,
    transferHeatLossFieldname: `${fieldNamePrefix}.transferHeatLoss`,
    lastHydraulicBalancingFieldname: `${fieldNamePrefix}.lastHydraulicBalancing`,
    lastBalancingSetToNeverFieldname: `${fieldNamePrefix}.lastBalancingSetToNever`,
    consumerConstructionYearFieldname: `${fieldNamePrefix}.energyConsumerConstructionYear`,
    energyStorageConstructionYearFieldname: `${fieldNamePrefix}.energyStorageConstructionYear`,
    energyDistributionConstructionYearFieldname: `${fieldNamePrefix}.energyDistributionConstructionYear`,
    energyStorageIdFieldname: `${fieldNamePrefix}.storageId`,
    energyDistributionIdFieldname: `${fieldNamePrefix}.distributionId`,
    energyConsumerIdFieldname: `${fieldNamePrefix}.consumerId`,
    energyRouteIdFieldname: `${fieldNamePrefix}.energyRouteId`,
    storageDeletedFieldname: `${fieldNamePrefix}.storageDeleted`,
    distributionDeletedFieldname: `${fieldNamePrefix}.distributionDeleted`,
    energySystemTypeFieldname: `${fieldNamePrefix}.energySystemType`,
    systemIdFieldname: `${fieldNamePrefix}.systemId`,
    energyRouteDeletedFieldname: `${fieldNamePrefix}.routeDeleted`,
  } satisfies Record<string, string>;
};

/**
 * This function generates a unique negative ID for an energy system.
 * It does this by finding the smallest negative ID currently in use and subtracting 1 from it.
 * If no negative ID is in use, it defaults to -1.
 *
 * @param systems - An object representing the energy systems. Each key in the object is a system type,
 *                  and the value is an array of routes for that system.
 * @returns A unique negative ID.
 */
export function generateUniqueNegativeId(systems: EnergySystemFormValues): number {
  // Flatten the arrays of routes into a single array of IDs
  const uniqueId =
    //@ts-ignore
    Object.values(systems)
      .flatMap((systemArray) =>
        systemArray.flatMap((system) =>
          system.routes.flatMap((item: SystemRoute) => [
            item.storageId,
            item.energyRouteId,
            item.consumerId,
            item.distributionId,
            system.id,
          ]),
        ),
      )
      // Filter out null and undefined IDs
      .filter((id) => id != null)
      // Sort the IDs in ascending order
      .sort((a, b) => a - b)
      // Find the first (smallest) negative ID
      .find((id) => id < 0) - 1 || -1;

  // Return the smallest negative ID minus 1, or -1 if no negative ID was found
  return uniqueId;
}
