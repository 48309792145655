import { m } from 'framer-motion';
// @mui
import { SxProps, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import LogoSmall from './LogoSmall';
import ProgressBar from './ProgressBar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 9998,
  width: '100%',
  height: '100%',
  // position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>;
};

/**
 * Minimal@4 component.
 */
export default function LoadingScreen({ ...other }: Props) {
  return (
    <>
      <ProgressBar />

      <RootStyle {...other}>
        <m.div
          animate={{
            scale: [1, 0.9, 0.9, 1, 1],
            opacity: [1, 0.48, 0.48, 1, 1],
          }}
          transition={{
            duration: 2,
            ease: 'easeInOut',
            repeatDelay: 1,
            repeat: Infinity,
          }}
        >
          <LogoSmall sx={{ width: 64, height: 64 }} />
        </m.div>
      </RootStyle>
    </>
  );
}
