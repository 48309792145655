import { country_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateCountryEnum = (enumValue: country_enum) => {
  switch (enumValue) {
    case country_enum.AT:
      return <Trans i18nKey="Enum_CountryEnum-AT" />;
    case country_enum.DE:
      return <Trans i18nKey="Enum_CountryEnum-DE" />;
    case country_enum.PL:
      return <Trans i18nKey="Enum_CountryEnum-PL" />;
  }
};

export const translateCountryEnum_dynamic = (enumValue: country_enum, t: TFunction<'translation', undefined>) => {
  switch (enumValue) {
    case country_enum.AT:
      return t('Enum_CountryEnum-AT');
    case country_enum.DE:
      return t('Enum_CountryEnum-DE');
    case country_enum.PL:
      return t('Enum_CountryEnum-PL');
  }
};
