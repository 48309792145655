/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  building_state_enum,
  efficiency_class_enum,
  energy_source_type_enum,
  eu_taxonomy_compliance_enum,
  sub_building_class_enum,
  tax_bracket_enum,
  type_of_use_enum,
} from '@predium/enums';
import { has } from 'lodash';

/**
 * Represents all supported operators from Hasura which can be used on single values
 *
 * Hasura supports these: _eq, _neq, _gt, _gte, _lt, _lte, _is_null
 *  */
export enum OperatorEnum {
  _eq = '_eq',
  _neq = '_neq',
  _gt = '_gt',
  _gte = '_gte',
  _lt = '_lt',
  _lte = '_lte',
}

/**
 * Represents all supported operators from Hasura which can be used on array values
 *
 * Hasura supports these: _in, _nin
 *  */
export enum OperatorInEnum {
  _in = '_in',
  _nin = '_nin',
}

/**
 * Represents all supported operators from Hasura which can be used as conjunction
 *
 * Hasura supports these: _and, _or, _not
 *  */
export enum ConjunctionEnum {
  _and = '_and',
  _or = '_or',
}

/**
 * Represents all supported operators from Prisma which can be used to filter through N:M relations
 *
 * Prisma supports these: none, some, every (@see https://www.prisma.io/docs/concepts/components/prisma-client/relation-queries#filter-on--to-many-relations)
 */
export enum MultiNestedTypeEnum {
  none = 'none',
  some = 'some',
  every = 'every',
}

/**
 * Interface for declaring a where-attribute a value.
 */
export type IWhere = {
  value?: unknown;
  op: OperatorEnum | OperatorInEnum;
};

export const isIWhere = (t: unknown): t is IWhere => {
  return has(t, 'value') && (has(t, 'op') || has(t, 'opIn'));
};

export type IConjunctionWhere = {
  conjunction: ConjunctionEnum;
  values: IWhere[];
};
export const isIConjunctionWhere = (t: unknown): t is IConjunctionWhere => {
  return has(t, 'conjunction') && has(t, 'values');
};

/**
 * Marker interface for declaring a where input as nested for 1:1 or 1:M relations.
 *
 * Only condition is that all properties of the object must be of type IWhere or INestedWhere or IMultiNestedType.
 * The relationship between the parent and nested property is 1:1 or 1:M.
 */
export type INestedWhere<T extends PropertyKey> = Partial<Record<T, IWhere | unknown>>; // TODO: can we actually create a recursive type here?
export const isINestedWhere = (t: unknown): t is INestedWhere<PropertyKey> => {
  if (typeof t === 'string') {
    // End of recursion (Object.values(t) yields property names of t as string)
    return true;
  }

  //@ts-ignore
  const values: unknown[] = Object.entries(t)
    .filter(([k]) => k !== 'nestingType')
    .map(([, v]) => v);
  return values.every((v) => isIWhere(v) || isINestedWhere(v) || isIMultiNestedWhere(v));
};

/**
 * Marker interface for declaring a where input as nested for N:M relations.
 *
 * It must contain a nestingType property while all other properties of the object must be of type INestedWhere or IMultiNestedWhere.
 * The relationship between the parent and nested property is N:M.
 */
export type IMultiNestedWhere<T extends PropertyKey> = INestedWhere<T> & {
  nestingType: MultiNestedTypeEnum;
};
export const isIMultiNestedWhere = (t: unknown): t is IMultiNestedWhere<PropertyKey> => {
  const { nestingType } = t as IMultiNestedWhere<PropertyKey>;
  if (!(nestingType in MultiNestedTypeEnum)) {
    return false;
  }

  //@ts-ignore
  const values: unknown[] = Object.entries(t)
    .filter(([k]) => k !== 'nestingType')
    .map(([, v]) => v);
  return values.every((v) => isINestedWhere(v) || isIMultiNestedWhere(v));
};

export interface IWhereString extends IWhere {
  value?: string;
  op: OperatorEnum;
}

export interface IWhereInt extends IWhere {
  value?: number;
  op: OperatorEnum;
}

export interface IWhereBoolean extends IWhere {
  value?: boolean;
  op: OperatorEnum;
}

export interface IConjunctionWhereInt extends IConjunctionWhere {
  conjunction: ConjunctionEnum;
  values: IWhereInt[];
}

export interface IWhereInts extends IWhere {
  value?: number[];
  op: OperatorInEnum;
}

export interface IWhereTypeOfUse extends IWhere {
  value: type_of_use_enum[];
  op: OperatorInEnum;
}

export interface IWhereSubBuildingClass extends IWhere {
  value: sub_building_class_enum[];
  op: OperatorInEnum;
}

export interface IWhereEfficiencyClass extends IWhere {
  value: efficiency_class_enum[];
  op: OperatorInEnum;
}

export interface IWhereEuTaxonomyCompliance extends IWhere {
  value: eu_taxonomy_compliance_enum[];
  op: OperatorInEnum;
}

export interface IWhereEnergySourceType extends IWhere {
  value: energy_source_type_enum[];
  op: OperatorInEnum;
}

export interface IWhereTaxBracket extends IWhere {
  value: tax_bracket_enum[];
  op: OperatorInEnum;
}

export interface IWhereBuildingState extends IWhere {
  value: building_state_enum[];
  op: OperatorInEnum;
}
