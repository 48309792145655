import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { data_source_type_enum } from '@predium/enums';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../../components/Iconify';
import ActionMoreMenu from '../../../../../components/common/ActionMoreMenu';
import DataSourceTypeChip from '../../Common/DataSourceTypeChip';
import useBuilding from '../../Context/useBuilding';

type Props = {
  icon: string;
  title: string;
  totalTabulaEnvelopes: number;
  actions: ReactNode;
  expanded: boolean;
  resetSectionEnvelopes: () => void;
  isSectionDirty: boolean;
};

const EnvelopeSectionHead: React.FC<Props> = ({
  icon,
  title,
  totalTabulaEnvelopes,
  actions,
  expanded,
  resetSectionEnvelopes,
  isSectionDirty,
}) => {
  const { t } = useTranslation();
  const { hasEditAccess } = useBuilding();

  return (
    <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <Stack sx={{ alignItems: 'center' }} direction="row">
        <Stack direction={'row'} alignItems={'center'} sx={{ mr: 1 }}>
          <Box component="img" src={icon} width={36} height={36} mr={2} />
          <Typography variant="h5">{title}</Typography>
        </Stack>

        <Box sx={{ pointerEvents: 'none' }}>
          {totalTabulaEnvelopes > 0 && (
            <DataSourceTypeChip value={totalTabulaEnvelopes} type={data_source_type_enum.TABULA} />
          )}
          {/* {totalManualEnvelopes > 0 && (
            <DataSourceTypeChip value={totalManualEnvelopes} type={data_source_type_enum.MANUAL} />
          )} */}
        </Box>
      </Stack>

      <Box
        onClick={(event: React.MouseEvent) => {
          // Prevent the click event from reaching the parent Accordian summary so it doesnt expand according and perform actual action
          event.stopPropagation();
        }}
      >
        {expanded && hasEditAccess && (
          <ActionMoreMenu
            variant="button"
            icon="mdi:plus"
            buttonText={t('General_AddComponent')}
            actions={actions}
            sx={{ mr: 1 }}
          />
        )}
        {isSectionDirty && (
          <Button
            onClick={resetSectionEnvelopes}
            sx={{ mr: 1 }}
            startIcon={<Iconify icon={'mdi:undo-variant'} width={20} height={20} />}
          >
            {t('General_UndoChanges')}
          </Button>
        )}
      </Box>
    </Stack>
  );
};

export default EnvelopeSectionHead;
