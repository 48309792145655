/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Accordion, AccordionDetails, AccordionSummary, InputAdornment, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReportGetReportByPkSubBuildingFragment, report_template_section_target_enum } from '@predium/client-graphql';
import { useEffect, useState, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../components/Iconify';
import InputStyle from '../../../components/InputStyle';
import { DelayedLoading } from '../../../components/Loading';
import Scrollbar from '../../../components/Scrollbar';
import { SUBSECTIONS_PROGRESS } from '../../../graphql/Report.queries';
import { ReportDC } from '../ReportingDataClasses/Report.dc';
import { ReportSectionDC } from '../ReportingDataClasses/ReportTemplate.dc';
import ReportSection from './ReportRootSection';
import { doesSectionContainTargetSection, getLastSection } from './ReportRootSectionOrigin';
import { findFirstChildWithIncompleteProgress } from './ReportSectionsEntity';

type Props = {
  report: ReportDC;
  subBuildingRootSections: ReportSectionDC[];
  currentSubBuildingId: number | null;
  setCurrentSubBuildingId: (id: number) => void;
  activeSection: ReportSectionDC | null;
  setActiveSection: (section: ReportSectionDC) => void;
  triggerSkipToNextIncompleteSection: boolean;
  skipToNextSectionCompleted: () => void;
};

function ReportSectionsSubBuildings({
  report,
  subBuildingRootSections,
  currentSubBuildingId,
  setCurrentSubBuildingId,
  activeSection,
  setActiveSection,
  skipToNextSectionCompleted,
  triggerSkipToNextIncompleteSection,
}: Props) {
  const { t } = useTranslation();
  const [filterName, setFilterName] = useState('');
  const [, startTransition] = useTransition();
  // We want to initialize with the first section and asset/entity that is not completed yet.
  // We wait until the progress is fetched and then look up the assets of the unanswered section.
  // If everything has been answered we fallback to the first section and asset/entity.
  useQuery(SUBSECTIONS_PROGRESS, {
    variables: {
      reportId: report.id,
    },
    skip: Boolean(activeSection) && !triggerSkipToNextIncompleteSection,
    onCompleted: (data) => {
      const progressData =
        currentSubBuildingId !== null
          ? //@ts-ignore

            //@ts-ignore
            data.report_by_pk.report_sub_buildings.find(
              ({ sub_building_id }) => sub_building_id === currentSubBuildingId,
            ).report_sections_progress
          : //@ts-ignore
            data.report_by_pk.report_sections_progress;
      let incompleteSectionWithQuestions: ReportSectionDC | undefined;

      // Handle if rootSection is the section with questions
      const firstSection = subBuildingRootSections[0];
      if (!triggerSkipToNextIncompleteSection && firstSection.children.length === 0) {
        //@ts-ignore
        const progress = data.report_by_pk.report_sections_progress.find(
          ({ report_template_section }) => report_template_section.id === firstSection.id,
        ).progress;

        if (progress < 1) {
          incompleteSectionWithQuestions = firstSection;
        }
      }

      if (!incompleteSectionWithQuestions) {
        //@ts-ignore
        incompleteSectionWithQuestions = findFirstChildWithIncompleteProgress(
          report_template_section_target_enum.SUB_BUILDING,
          report,

          //@ts-ignore
          triggerSkipToNextIncompleteSection ? activeSection.id : firstSection.id,
          progressData,
        );
      }

      skipToNextSectionCompleted();

      // If everything is completed just set the first section
      if (!incompleteSectionWithQuestions) {
        // If there is no incomplete section after the current just skip.
        if (triggerSkipToNextIncompleteSection) {
          return;
        }

        const firstSectionWithoutChildren = getLastSection(subBuildingRootSections);

        setActiveSection(firstSectionWithoutChildren);
        if (currentSubBuildingId === null) {
          setCurrentSubBuildingId(report.report_sub_buildings[0].id);
        }
        return;
      }

      // Find the first section with incomplete progress and set its child with questions as active section and the appropriate root section tab
      setActiveSection(incompleteSectionWithQuestions);

      // If we already have a subBuilding we never change it automatically
      if (currentSubBuildingId === null) {
        // Find the first subBuilding that has the incomplete section and set it as active

        //@ts-ignore
        for (let i = 0; i < data.report_by_pk.report_sub_buildings.length; i++) {
          //@ts-ignore
          const subBuilding = data.report_by_pk.report_sub_buildings[i];

          const hasIncompleteActiveSection = subBuilding.report_sections_progress.some(
            ({ progress, report_template_section }) =>
              //@ts-ignore
              report_template_section.id === incompleteSectionWithQuestions.id && progress < 1,
          );

          if (hasIncompleteActiveSection) {
            setCurrentSubBuildingId(subBuilding.sub_building_id);
            break;
          }
        }
      }
    },
  });

  if (activeSection === null) return <DelayedLoading />;

  //  screen - card padding top - card padding bottom - tabs - some spacer padding
  const baseHeight = 'calc(100vh - 16px - 4px - 56px - 28px)';
  // search input height
  const scrollHeightContainer = report.report_sub_buildings_count > 1 ? `calc(${baseHeight} - 56px)` : baseHeight;

  const filteredSubBuildings = report.report_sub_buildings.filter((subBuilding) =>
    subBuilding.building.address.street.toLowerCase().includes(filterName.toLowerCase()),
  );

  return (
    <>
      {report.report_sub_buildings_count > 1 && (
        <InputStyle
          sx={{ width: '100%', my: 1 }}
          onChange={(event) => {
            startTransition(() => {
              setFilterName(event.target.value);
            });
          }}
          placeholder={t('General_SearchBuildingPlaceholder')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            ),
          }}
        />
      )}

      <Scrollbar sx={{ maxHeight: scrollHeightContainer }}>
        {filteredSubBuildings.length > 0 ? (
          filteredSubBuildings.map((reportSubBuilding) => {
            const isSelectedSubBuilding = reportSubBuilding.id === currentSubBuildingId;

            return (
              <ReportSectionsSubBuildingsWrapper
                key={reportSubBuilding.id}
                activeSection={activeSection}
                isSelectedSubBuilding={isSelectedSubBuilding}
                report={report}
                reportSubBuilding={reportSubBuilding}
                setActiveSection={setActiveSection}
                setCurrentSubBuildingId={setCurrentSubBuildingId}
                subBuildingRootSections={subBuildingRootSections}
              />
            );
          })
        ) : (
          <Typography textAlign="center" sx={{ mt: 3 }}>
            {t('ReportSectionsSubBuildings_NoBuildingsFoundMessage', { filterName })}
          </Typography>
        )}
      </Scrollbar>
    </>
  );
}

type ReportSectionsSubBuildingsWrapperProps = {
  reportSubBuilding: ReportGetReportByPkSubBuildingFragment;
  isSelectedSubBuilding: boolean;
  report: ReportDC;
  activeSection: ReportSectionDC;
  subBuildingRootSections: ReportSectionDC[];
  setActiveSection: (section: ReportSectionDC) => void;
  setCurrentSubBuildingId: (id: number) => void;
};

function ReportSectionsSubBuildingsWrapper({
  activeSection,
  isSelectedSubBuilding,
  report,
  reportSubBuilding,
  subBuildingRootSections,
  setActiveSection,
  setCurrentSubBuildingId,
}: ReportSectionsSubBuildingsWrapperProps) {
  const theme = useTheme();

  return (
    <Accordion
      key={reportSubBuilding.id}
      expanded={isSelectedSubBuilding}
      onChange={() => {
        if (!isSelectedSubBuilding) {
          setCurrentSubBuildingId(reportSubBuilding.id);
          // This is to trigger the fetching of the first incomplete section in the subBuilding again.

          //@ts-ignore
          setActiveSection(null);
        }
      }}
      style={{ boxShadow: 'none' }}
      sx={{
        ':before': {
          display: 'none',
        },
      }}
      title={reportSubBuilding.building.address.street}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        sx={{
          flexDirection: 'row-reverse',
          backgroundColor: isSelectedSubBuilding ? theme.palette.grey[500_12] : '',
          borderRadius: 1,
        }}
        // selected subBuilding does not allow clicking
        style={{ cursor: isSelectedSubBuilding ? 'default' : 'pointer' }}
        expandIcon={
          <Iconify width={16} height={16} icon={'material-symbols:arrow-forward-ios-rounded'} {...{ rotate: 1 }} />
        }
      >
        <Typography sx={{ ml: 2 }}>{reportSubBuilding.building.address.street}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 0 }}>
        {subBuildingRootSections.map((rootSection) =>
          rootSection.children.length > 0 ? (
            <RootSectionHasTargetWrapper
              key={rootSection.id}
              activeSection={activeSection}
              isSelectedSubBuilding={isSelectedSubBuilding}
              report={report}
              reportSubBuilding={reportSubBuilding}
              rootSection={rootSection}
              setActiveSection={setActiveSection}
              setCurrentSubBuildingId={setCurrentSubBuildingId}
            />
          ) : (
            <ReportSection
              key={rootSection.id}
              section={rootSection}
              report={report}
              setActiveSection={(activeSection: ReportSectionDC) => {
                setCurrentSubBuildingId(reportSubBuilding.id);
                setActiveSection(activeSection);
              }}
              activeSection={activeSection}
              isSelected={isSelectedSubBuilding}
              subBuildingId={reportSubBuilding.id}
              isEntitySection={false}
            />
          ),
        )}
      </AccordionDetails>
    </Accordion>
  );
}

type RootSectionHasTargetWrapperProps = {
  rootSection: ReportSectionDC;
  report: ReportDC;
  setActiveSection: (section: ReportSectionDC) => void;
  setCurrentSubBuildingId: (id: number) => void;
  reportSubBuilding: ReportGetReportByPkSubBuildingFragment;
  activeSection: ReportSectionDC;
  isSelectedSubBuilding: boolean;
};

function RootSectionHasTargetWrapper({
  activeSection,
  isSelectedSubBuilding,
  report,
  reportSubBuilding,
  rootSection,
  setActiveSection,
  setCurrentSubBuildingId,
}: RootSectionHasTargetWrapperProps) {
  const isSelectedSection =
    isSelectedSubBuilding &&
    doesSectionContainTargetSection(activeSection.id, rootSection, report.report_template.report_sections);
  const [expanded, setExpanded] = useState(isSelectedSection);

  useEffect(() => {
    if (isSelectedSection) {
      setExpanded(isSelectedSection);
    }
  }, [isSelectedSection, setExpanded]);

  return (
    <Accordion
      expanded={expanded}
      onChange={(_, expanded) => {
        setExpanded(expanded);
      }}
      style={{ boxShadow: 'none' }}
      sx={{
        ':before': {
          display: 'none',
        },
      }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        sx={{ ml: 6, px: 0, flexDirection: 'row-reverse' }}
        expandIcon={
          <Iconify width={16} height={16} icon={'material-symbols:arrow-forward-ios-rounded'} {...{ rotate: 1 }} />
        }
      >
        <Typography sx={{ ml: 2 }}>{rootSection.name}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ ml: 6, px: 0 }}>
        <ReportSection
          key={rootSection.id}
          section={rootSection}
          report={report}
          setActiveSection={(activeSection: ReportSectionDC) => {
            setCurrentSubBuildingId(reportSubBuilding.id);
            setActiveSection(activeSection);
          }}
          activeSection={activeSection}
          isSelected={isSelectedSubBuilding}
          subBuildingId={reportSubBuilding.id}
          isEntitySection={false}
        />
      </AccordionDetails>
    </Accordion>
  );
}

export default ReportSectionsSubBuildings;
