import { useMutation } from '@apollo/client';
import { Button, MenuItem } from '@mui/material';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { ICONS } from '../../../assets/icons';
import FullScreenLoader from '../../../components/FullScreenLoader';
import Iconify from '../../../components/Iconify';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import ActionMoreMenu from '../../../components/common/ActionMoreMenu';
import { EXPORT_SCENARIO_AS_EXCEL } from '../../../graphql/ActionPlanning.mutations';
import { useLanguage } from '../../../provider/LanguageProvider';
import { downloadFile } from '../../../utils/createDownloadLink';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '../../../utils/formatTime';
import DeleteScenarioModal from './DeleteScenarioModal';

export type ViewType = 'table' | 'page';

export type ScenarioType = {
  id: number;
  name: string;
  totalActionPlans: number;
  totalActions: number;
};

type Props = {
  scenario: ScenarioType;
  hasScenarioDeletePermission: boolean;
  viewType: ViewType;
};

const ScenarioActionMenu: React.FC<Props> = ({ scenario, hasScenarioDeletePermission, viewType }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { language } = useLanguage();

  const hasAtleastOneAction = scenario.totalActionPlans > 0;

  const [downloadScenario, { loading: loadingAction }] = useMutation(EXPORT_SCENARIO_AS_EXCEL, {
    variables: {
      scenarioId: scenario.id,
    },
    onCompleted: async (data) => {
      const currentDate = formatDateToLocale(new Date(), COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language);

      const filename = `${currentDate}-Predium-${scenario.name}-action-plan.xlsx`;
      downloadFile(data?.exportScenarioAsExcel.body, 'xlsx', filename);
    },
    onError: () => {
      enqueueSnackbar(t('ActionPlanningScenario_ScenarioCouldNotBeExported'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
  });

  const exportIcon = <Iconify icon="mdi:file-download-outline" width={18} height={18} mr={1} />;

  return (
    <>
      {viewType === 'page' && hasAtleastOneAction && (
        <Button
          onClick={async () => {
            await downloadScenario();
          }}
          variant={'outlined'}
          size="small"
          sx={{ mr: 2 }}
        >
          {exportIcon} {t('ActionPlanningScenario_ExportAsExcel')}
        </Button>
      )}
      <ActionMoreMenu
        variant="icon"
        actions={
          <>
            {viewType === 'table' && hasAtleastOneAction && (
              <MenuItem
                onClick={async () => {
                  await downloadScenario();
                }}
              >
                {exportIcon} {t('ActionPlanningScenario_ExportAsExcel')}
              </MenuItem>
            )}
            {hasScenarioDeletePermission && (
              <MenuItem onClick={() => setDeleteModalOpen(true)} sx={{ color: 'error.main' }}>
                <Iconify icon={ICONS.TRASH} width={18} height={18} />
                {t('General_Delete')}
              </MenuItem>
            )}
          </>
        }
      />
      <DeleteScenarioModal
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={() => setDeleteModalOpen(false)}
        scenario={scenario}
      />
      <FullScreenLoader open={loadingAction} disableCloseAction text={t('General_InProgress')} />
    </>
  );
};

export default ScenarioActionMenu;
