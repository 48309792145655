import { energy_distribution_type_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateEnergyDistributionTypeEnum = (enumValue: energy_distribution_type_enum) => {
  switch (enumValue) {
    case energy_distribution_type_enum.AIR_DUCTS_INTERNAL:
      return <Trans i18nKey="Enum_EnergyDistributionTypeEnum-AIR_DUCTS_INTERNAL" />;
    case energy_distribution_type_enum.CENTRAL_CIRCULATION_INSIDE:
      return <Trans i18nKey="Enum_EnergyDistributionTypeEnum-CENTRAL_CIRCULATION_INSIDE" />;
    case energy_distribution_type_enum.CENTRAL_CIRCULATION_OUTSIDE:
      return <Trans i18nKey="Enum_EnergyDistributionTypeEnum-CENTRAL_CIRCULATION_OUTSIDE" />;
    case energy_distribution_type_enum.CENTRAL_INSIDE:
      return <Trans i18nKey="Enum_EnergyDistributionTypeEnum-CENTRAL_INSIDE" />;
    case energy_distribution_type_enum.CENTRAL_NO_CIRCULATION_INSIDE:
      return <Trans i18nKey="Enum_EnergyDistributionTypeEnum-CENTRAL_NO_CIRCULATION_INSIDE" />;
    case energy_distribution_type_enum.CENTRAL_NO_CIRCULATION_OUTSIDE:
      return <Trans i18nKey="Enum_EnergyDistributionTypeEnum-CENTRAL_NO_CIRCULATION_OUTSIDE" />;
    case energy_distribution_type_enum.CENTRAL_OUTSIDE:
      return <Trans i18nKey="Enum_EnergyDistributionTypeEnum-CENTRAL_OUTSIDE" />;
    case energy_distribution_type_enum.DECENTRAL:
      return <Trans i18nKey="Enum_EnergyDistributionTypeEnum-DECENTRAL" />;
    case energy_distribution_type_enum.FAN:
      return <Trans i18nKey="Enum_EnergyDistributionTypeEnum-FAN" />;
    case energy_distribution_type_enum.THERMAL_COMPONENT_ACTIVATION:
      return <Trans i18nKey="Enum_EnergyDistributionTypeEnum-THERMAL_COMPONENT_ACTIVATION" />;
  }
};

export const translateEnergyDistributionTypeEnum_dynamic = (
  enumValue: energy_distribution_type_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case energy_distribution_type_enum.AIR_DUCTS_INTERNAL:
      return t('Enum_EnergyDistributionTypeEnum-AIR_DUCTS_INTERNAL');
    case energy_distribution_type_enum.CENTRAL_CIRCULATION_INSIDE:
      return t('Enum_EnergyDistributionTypeEnum-CENTRAL_CIRCULATION_INSIDE');
    case energy_distribution_type_enum.CENTRAL_CIRCULATION_OUTSIDE:
      return t('Enum_EnergyDistributionTypeEnum-CENTRAL_CIRCULATION_OUTSIDE');
    case energy_distribution_type_enum.CENTRAL_INSIDE:
      return t('Enum_EnergyDistributionTypeEnum-CENTRAL_INSIDE');
    case energy_distribution_type_enum.CENTRAL_NO_CIRCULATION_INSIDE:
      return t('Enum_EnergyDistributionTypeEnum-CENTRAL_NO_CIRCULATION_INSIDE');
    case energy_distribution_type_enum.CENTRAL_NO_CIRCULATION_OUTSIDE:
      return t('Enum_EnergyDistributionTypeEnum-CENTRAL_NO_CIRCULATION_OUTSIDE');
    case energy_distribution_type_enum.CENTRAL_OUTSIDE:
      return t('Enum_EnergyDistributionTypeEnum-CENTRAL_OUTSIDE');
    case energy_distribution_type_enum.DECENTRAL:
      return t('Enum_EnergyDistributionTypeEnum-DECENTRAL');
    case energy_distribution_type_enum.FAN:
      return t('Enum_EnergyDistributionTypeEnum-FAN');
    case energy_distribution_type_enum.THERMAL_COMPONENT_ACTIVATION:
      return t('Enum_EnergyDistributionTypeEnum-THERMAL_COMPONENT_ACTIVATION');
  }
};
