import { energy_source_type_enum, energy_system_type_enum } from '@predium/enums';

//
// SourceTypesForSystemType - a mapping of supported source types for a given system type
//

export const SourceTypesForSystemType: Record<energy_system_type_enum, energy_source_type_enum[]> = {
  [energy_system_type_enum.COOLING]: [
    energy_source_type_enum.ELECTRICITY_GREEN,
    energy_source_type_enum.ELECTRICITY_MIX,
  ],
  [energy_system_type_enum.ELECTRICITY]: [
    energy_source_type_enum.ELECTRICITY_GREEN,
    energy_source_type_enum.ELECTRICITY_MIX,
  ],
  [energy_system_type_enum.GENERAL]: [
    energy_source_type_enum.ELECTRICITY_GREEN,
    energy_source_type_enum.ELECTRICITY_MIX,
  ],
  [energy_system_type_enum.HEATING]: [
    energy_source_type_enum.BIO_GAS,
    energy_source_type_enum.COAL,
    energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL,
    energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS,
    energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE,
    energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL,
    energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS,
    energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE,
    energy_source_type_enum.ELECTRICITY_GREEN,
    energy_source_type_enum.ELECTRICITY_MIX,
    energy_source_type_enum.FUEL_OIL,
    energy_source_type_enum.LIGNITE,
    energy_source_type_enum.LPG,
    energy_source_type_enum.NATURAL_GAS,
    energy_source_type_enum.WOOD,
    energy_source_type_enum.WOODEN_PELLETS,
  ],
  [energy_system_type_enum.HOT_WATER]: [
    energy_source_type_enum.BIO_GAS,
    energy_source_type_enum.COAL,
    energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL,
    energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS,
    energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE,
    energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL,
    energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS,
    energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE,
    energy_source_type_enum.ELECTRICITY_GREEN,
    energy_source_type_enum.ELECTRICITY_MIX,
    energy_source_type_enum.FUEL_OIL,
    energy_source_type_enum.LIGNITE,
    energy_source_type_enum.LPG,
    energy_source_type_enum.NATURAL_GAS,
    energy_source_type_enum.WOOD,
    energy_source_type_enum.WOODEN_PELLETS,
  ],
  [energy_system_type_enum.LIGHTING]: [
    energy_source_type_enum.ELECTRICITY_GREEN,
    energy_source_type_enum.ELECTRICITY_MIX,
  ],
  [energy_system_type_enum.SOLAR]: [],
  [energy_system_type_enum.VENTILATION]: [
    energy_source_type_enum.ELECTRICITY_GREEN,
    energy_source_type_enum.ELECTRICITY_MIX,
  ],
};
