/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box } from '@mui/material';
import { area_system_type_enum, area_type_enum } from '@predium/enums';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SquareMeterRow from './Components/SquareMeterRow';
import { useArea } from './hooks';

const BGFArea = () => {
  const { t } = useTranslation();

  const { fields, updateArea, getPropsForEditableArea } = useArea();

  const bgfOfDIN227 = fields.find(
    (area) => area.area_type_id === area_type_enum.BGF && area.area_system_id === area_system_type_enum.DIN227_DE,
  );

  const bgfOfGIF2017 = fields.find(
    (area) => area.area_type_id === area_type_enum.BGF && area.area_system_id === area_system_type_enum.GIF2017_DE,
  );

  // We have to update both DIN227 and GIF2017 BGF areas when BGF Square Meter is changed
  useEffect(() => {
    //@ts-ignore

    //@ts-ignore
    if (bgfOfDIN227.value === bgfOfGIF2017.value) {
      return;
    }

    updateArea(
      //@ts-ignore
      bgfOfGIF2017.uid,
      {
        //@ts-ignore
        value: bgfOfDIN227.value,
      },
      {
        // we don't want to trigger validation on key press
        skipValidation: true,
      },
    );

    //@ts-ignore

    //@ts-ignore

    //@ts-ignore
  }, [bgfOfDIN227.value, bgfOfGIF2017.uid, bgfOfGIF2017.value, updateArea]);

  return (
    <Box
      border="1px solid"
      borderColor="divider"
      bgcolor="grey.100"
      p={2}
      sx={{ borderBottomLeftRadius: 16, borderBottomRightRadius: 16 }}
    >
      <SquareMeterRow
        labels={{
          title: t('DataCollectionAreas_GrossFloorArea'),

          //@ts-ignore
          titleSuffix: bgfOfDIN227.name,
          tooltip: t('DataCollectionAreas_GrossFloorAreaTooltip'),
        }}
        // Both of the areas have the same value, so we can use any of them

        //@ts-ignore
        {...getPropsForEditableArea(bgfOfDIN227.uid)}
      />
    </Box>
  );
};

export default BGFArea;
