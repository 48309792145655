import { createSvgIcon } from '@mui/material';

const GeneralDataIcon = createSvgIcon(
  <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 2H2C0.91 2.04444 0.04 3.01111 0 4.22222V19.7778C0.04 20.9889 0.91 21.9556 2 22H22C23.09 21.9556 23.96 20.9889 24 19.7778V4.22222C23.9816 3.63936 23.765 3.08594 23.3938 2.67354C23.0227 2.26114 22.5246 2.02045 22 2H16V4H22V20H2V4H8V2Z"
      fill="currentColor"
    />
    <path d="M20 13H15V11H20V13Z" fill="currentColor" />
    <path d="M20 9H15V7H20V9Z" fill="currentColor" />
    <path d="M20 17H15V15H20V17Z" fill="currentColor" />
    <path d="M8 13V16H6V12H4L9 8L14 12H12V16H10V13H8Z" fill="currentColor" />
    <path d="M9 0H15V4H9V0Z" fill="currentColor" />
  </svg>,
  'GeneralData',
);

export default GeneralDataIcon;
