import { useMutation } from '@apollo/client';
import { IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/icons';
import DeleteConfirmationModal from '../../../../components/DeleteConfirmationModal';
import Iconify from '../../../../components/Iconify';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import { RESET_REPORT_ANSWER } from '../../../../graphql/Report.mutations';
import {
  GET_ALL_REPORT_AUTOFILL_QUESTIONS,
  GET_All_EVIDENCES,
  GET_ROOT_QUESTIONS_AND_ANSWERS,
  REPORT_HEAD_GET_PROGRESS,
} from '../../../../graphql/Report.queries';
import { ReportQuestionDC } from '../../ReportingDataClasses/ReportQuestion.dc';

type Props = {
  question: ReportQuestionDC;
  isEditable: boolean;
  onDeleteCallback?: () => void;
};

function DeleteReportAnswer({ question, isEditable, onDeleteCallback }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [resetReportAnswer] = useMutation(RESET_REPORT_ANSWER, {
    variables: {
      reportId: question.reportId,
      subBuildingId: question.subBuildingId,
      rootQuestionId: question.id,
    },
    refetchQueries: [
      GET_ROOT_QUESTIONS_AND_ANSWERS,
      REPORT_HEAD_GET_PROGRESS,
      GET_ALL_REPORT_AUTOFILL_QUESTIONS,
      {
        query: GET_All_EVIDENCES,
        variables: {
          reportId: question.reportId,
        },
      },
    ],
    onError: () =>
      enqueueSnackbar(t('DeleteReportAnswer_ResetAnswer-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
    onCompleted: () => {
      enqueueSnackbar(t('DeleteReportAnswer_ResetAnswer-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      setModalIsOpen(false);
      onDeleteCallback?.();
    },
  });

  const handleDeleteQuestionAnswer = () => {
    resetReportAnswer();
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Tooltip title={t('DeleteReportSection_ResetAnswer')}>
        <span>
          <IconButton color="error" onClick={() => setModalIsOpen(true)} disabled={!isEditable}>
            <Iconify icon={ICONS.TRASH} />
          </IconButton>
        </span>
      </Tooltip>

      <DeleteConfirmationModal
        open={modalIsOpen}
        title={t('General_DeleteModalTitle-ReportAnswer')}
        description={t('General_DeleteModalDescription-ReportAnswer')}
        onClose={() => setModalIsOpen(false)}
        onDelete={handleDeleteQuestionAnswer}
      />
    </div>
  );
}

export default DeleteReportAnswer;
