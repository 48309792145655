/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FetchResult, InternalRefetchQueriesInclude, useMutation } from '@apollo/client';
import { graphql } from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { SnackbarTimeouts } from '../components/NotistackProvider';

const UPDATE_FILE_NAME = graphql(`
  mutation UpdateFileName($fileId: Int!, $fileName: String!) {
    update_file_by_pk(pk_columns: { id: $fileId }, _set: { name: $fileName, uploading: false }) {
      name
    }
  }
`);

type UseUpdateFileNameProps = {
  updateFileName(
    fileId: number,
    fileName: string,
    refetchQueries?: ((result: FetchResult<unknown>) => InternalRefetchQueriesInclude) | InternalRefetchQueriesInclude,
  ): Promise<string>;
};

/**
 * Updates the filename after the file has been uploaded.
 * The file has been created on creation of the sub-building draft, to generate s3_key and uploadUrl.
 * We now set a proper name after the upload has been completed.
 */
export default function useUpdateFileName(): UseUpdateFileNameProps {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [changeFileNameMutation] = useMutation(UPDATE_FILE_NAME, {
    onError: () => {
      enqueueSnackbar(t('UpdateFileName_Snackbar-Error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
  });

  const updateFileName = async (
    fileId: number,
    fileName: string,
    refetchQueries?: ((result: FetchResult<unknown>) => InternalRefetchQueriesInclude) | InternalRefetchQueriesInclude,
  ): Promise<string> => {
    const res = await changeFileNameMutation({
      variables: {
        fileId,
        fileName,
      },
      refetchQueries,
    });

    //@ts-ignore
    return res?.data?.update_file_by_pk?.name;
  };

  return {
    updateFileName,
  };
}
