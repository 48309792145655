/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, IconButton, MenuItem, TextField, Typography, useTheme } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { number, object, string } from 'yup';
import FileThumbnail from '../../../../../components/FileThumbnail';
import Iconify from '../../../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../../../components/hook-form';
import { GET_REPORT_EVIDENCES } from '../../../../../graphql/Report.queries';
import { EvidenceListEntry } from '../RootQuestionEvidences';
import { AddURLEvidenceFormHandles } from './AddURLEvidenceForm';
import EvidenceDocumentViewer from './EvidenceDocumentViewer';

type Props = {
  reportId: number;
  selectedEvidenceIds: number[];
  onEvidencesAdded: (evidences: EvidenceListEntry[]) => void;
};

const ExistingEvidenceForm = forwardRef<AddURLEvidenceFormHandles, Props>(
  ({ reportId, selectedEvidenceIds, onEvidencesAdded }, ref) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const textFieldInputRef = useRef<HTMLInputElement>();

    const [showDocumentByUrl, setShowDocumentByUrl] = useState<string | null>(null);

    const { data } = useQuery(GET_REPORT_EVIDENCES, { variables: { reportId } });

    const FormSchema = object().shape({
      evidenceId: number().integer('Der Nachweis ist nicht korrekt').required('Erforderlich'),
      description: string(),
    });

    type FormValues = {
      evidenceId: number;
      description: string;
    };
    const methods = useForm<FormValues>({
      reValidateMode: 'onChange',
      resolver: yupResolver(FormSchema),
      defaultValues: {
        evidenceId: undefined,
        description: '',
      },
    });

    const { handleSubmit, setValue, control, getValues } = methods;

    const filterOptions = [
      { value: 'all', label: 'Alle' },
      { value: 'file', label: 'Dokumente' },
      { value: 'url', label: 'URL' },
    ] as const;
    const [optionsFilter, setOptionsFilter] = useState<(typeof filterOptions)[number]['value']>('all');

    const autocompleteOptions = useMemo(
      () =>
        data?.report_evidence
          .filter((evidence) => {
            // An evidence can only be attached once
            if (selectedEvidenceIds.includes(evidence.id)) {
              return false;
            }
            // switch case over optionsFilter with exhaustiveness check
            switch (optionsFilter) {
              case 'all':
                return true;
              case 'file':
                return evidence.file !== null;
              case 'url':
                return evidence.url !== null;
              default:
                const exhaustiveCheck: never = optionsFilter;
                throw new Error(`Unhandled evidence: ${exhaustiveCheck}`);
            }
          })
          .map((evidence) => ({
            //@ts-ignore
            label: evidence.url ?? evidence.file.name ?? '---',
            value: evidence.id,
            downloadUrl: evidence.file?.downloadUrl,
          })) ?? [],
      [data, optionsFilter, selectedEvidenceIds],
    );

    const onSubmit = ({ evidenceId, description }: FormValues): void => {
      //@ts-ignore

      //@ts-ignore

      //@ts-ignore
      const { value, downloadUrl, label } = autocompleteOptions.find((option) => evidenceId === option.value);
      const displayedEvidences: EvidenceListEntry = {
        id: value,
        title: label,
        type: downloadUrl ? 'file' : 'url',
        url: downloadUrl ?? label,
        description,
      };

      onEvidencesAdded([displayedEvidences]);
    };

    // When the options filter is changed and the current selected evidence is not in the new options, reset the form control and input
    useEffect(() => {
      const selectedEvidenceId = getValues('evidenceId');
      const isSelectedEvidenceInOptions = autocompleteOptions.some((option) => selectedEvidenceId === option.value);
      if (selectedEvidenceId !== undefined && !isSelectedEvidenceInOptions) {
        //@ts-ignore
        setValue('evidenceId', undefined);

        // setting the value above is setting the input value again. Therefore we need to defer the update slightly.

        //@ts-ignore
        setTimeout(() => (textFieldInputRef.current.value = ''));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionsFilter]);

    useImperativeHandle(ref, () => ({
      submit: () => {
        handleSubmit(onSubmit)();
      },
    }));

    return (
      <>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div style={{ display: 'flex', gap: 8 }}>
            <Autocomplete
              style={{ flexGrow: 1 }}
              options={autocompleteOptions}
              onChange={(_, chosenOption) => {
                if (!chosenOption) {
                  //@ts-ignore
                  setValue('evidenceId', undefined);
                } else {
                  const chosenValue = chosenOption as unknown as (typeof autocompleteOptions)[number];

                  setValue('evidenceId', chosenValue.value, { shouldValidate: true });
                }
              }}
              renderOption={(props, option) => (
                <li {...props} key={option.value} style={{ display: 'flex' }}>
                  {option.downloadUrl ? (
                    <IconButton
                      sx={{ mr: 0.75 }}
                      onClick={(event) => {
                        event.stopPropagation();

                        //@ts-ignore
                        setShowDocumentByUrl(option.downloadUrl);
                      }}
                    >
                      <FileThumbnail format="pdf" />
                    </IconButton>
                  ) : (
                    <Iconify
                      style={{ marginRight: 16, marginLeft: 10 }}
                      width={24}
                      height={24}
                      icon="material-symbols:link"
                    />
                  )}

                  <span style={{ flexGrow: 1, maxWidth: '70%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {option.label}
                  </span>

                  {option.downloadUrl ? (
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();

                        //@ts-ignore
                        setShowDocumentByUrl(option.downloadUrl);
                      }}
                    >
                      <Iconify icon="material-symbols:arrow-outward" />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      href={option.label}
                      target="_blank"
                      rel="noopener"
                    >
                      <Iconify icon="material-symbols:arrow-outward" />
                    </IconButton>
                  )}
                </li>
              )}
              renderInput={(params) => (
                <Controller
                  name="evidenceId"
                  control={control}
                  render={({ fieldState: { error } }) => (
                    <TextField
                      {...params}
                      error={Boolean(error)}
                      helperText={error ? error?.message : undefined}
                      placeholder={t('ReportEvidence_SearchEvidence-placeholder')}
                      inputRef={textFieldInputRef}
                    />
                  )}
                />
              )}
            />

            <TextField
              select
              label={t('ReportEvidence_FilterBy')}
              style={{ width: 150 }}
              value={optionsFilter}
              onChange={(event) => setOptionsFilter(event.target.value as (typeof filterOptions)[number]['value'])}
            >
              {filterOptions.map(({ label, value }, index) => (
                <MenuItem key={index} value={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <Typography style={{ marginTop: 16, marginBottom: 8 }}>
            <Trans
              i18nKey="ReportEvidence_EvidenceHintOptional"
              components={{
                span: <span style={{ color: theme.palette.grey[600] }} />,
              }}
            />
          </Typography>
          <RHFTextField
            name="description"
            multiline
            fullWidth
            label={t('General_Note')}
            minRows={3}
            maxRows={6}
            defaultValue=""
          />
        </FormProvider>

        <EvidenceDocumentViewer
          open={Boolean(showDocumentByUrl)}
          onClose={() => setShowDocumentByUrl(null)}
          //@ts-ignore
          documentUrl={showDocumentByUrl}
        />
      </>
    );
  },
);

export default ExistingEvidenceForm;
