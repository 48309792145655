import { Checkbox, TableCell } from '@mui/material';
import { FC } from 'react';
import { useDataTableAPI } from '../DataTableAPIContext';
import { BaseColumn, IBaseColumn } from './BaseColumn';

export const selectColumn = {
  ...BaseColumn,
  id: 'checkbox',
  type: 'select',
  renderHead() {
    return (
      <TableCell padding="checkbox" sx={{ minWidth: this.minWidth }}>
        <SelectAllItemsOnThisPage />
      </TableCell>
    );
  },
  renderCell({ id }) {
    return (
      <TableCell padding="checkbox" onClick={(e) => e.stopPropagation()}>
        <SelectItemCheckBox id={id} />
      </TableCell>
    );
  },
} satisfies IBaseColumn<{ id: number }>;

const SelectItemCheckBox: FC<{ id: number }> = ({ id }) => {
  const { onSelectItem, selectedItems } = useDataTableAPI();

  return <Checkbox checked={selectedItems.includes(id)} onChange={() => onSelectItem(id)} />;
};

const SelectAllItemsOnThisPage = () => {
  const { onTogglePageItemSelect } = useDataTableAPI();

  return <Checkbox checked={false} onChange={onTogglePageItemSelect} />;
};
