import { Grid, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { SemanticButton } from '../../../components/Tile';
import { ReportSectionDC } from '../ReportingDataClasses/ReportTemplate.dc';

// ----------------------------------------------------------------------

type SectionLabelProps = {
  section: ReportSectionDC;
  active: boolean;
  setActiveSection: (activeSection: ReportSectionDC) => void;
  progressLabelData: {
    progress: number;
    answeredCount: number;
    unansweredCount: number;
  };
};

function ReportSectionLabel({ section, active, setActiveSection, progressLabelData }: SectionLabelProps) {
  return (
    <SectionLabelButton
      active={active}
      progressLabel={`${progressLabelData.answeredCount}/${
        progressLabelData.answeredCount + progressLabelData.unansweredCount
      }`}
      section={section}
      setActiveSection={setActiveSection}
    />
  );
}

export default ReportSectionLabel;

type SectionLabelButtonProps = {
  active: boolean;
  progressLabel: string;
  section: ReportSectionDC;
  setActiveSection: (activeSection: ReportSectionDC) => void;
};

/**
 * Structural component for the button that is used to select the next active section to display its questions.
 * Used both for entity and subBuilding sections.
 */
const SectionLabelButton = ({ active, progressLabel, section, setActiveSection }: SectionLabelButtonProps) => {
  const theme = useTheme();

  return (
    <SemanticButton
      style={{
        width: '100%',
        marginBottom: '4px',
      }}
      onClick={() => setActiveSection(section)}
      data-cy={`cy-report-section-label-${section.id}`}
      data-cy-props={JSON.stringify({
        active,
      })}
    >
      <Box
        sx={{
          py: 2,
          pl: 10,
          borderRadius: 1,
          cursor: 'pointer',
          backgroundColor: active ? theme.palette.grey[500_12] : '',
          border: '1px solid white',
          '&:hover': {
            border: `1px solid ${theme.palette.grey[500_32]}`,
          },
        }}
      >
        <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid item xs={10}>
            <Typography variant="body1" fontWeight={active ? 700 : 400} textAlign="left">
              {section.name}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color={theme.palette.grey[500]}>{progressLabel}</Typography>
          </Grid>
        </Grid>
      </Box>
    </SemanticButton>
  );
};
