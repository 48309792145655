/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { DataCollectionGetEconomicUnitWithBuildingsFragment, type_of_use_enum } from '@predium/client-graphql';
import { building_state_enum } from '@predium/enums';
import { translateBuildingStateEnum, translateTypeOfUseEnum_dynamic } from '@predium/i18n/client';
import { getNetArea, getNufTypesOfUse } from '@predium/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Iconify from '../../../components/Iconify';
import { DelayedLoading } from '../../../components/Loading';
import OverflowText from '../../../components/OverflowText';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import Unavailable from '../../../components/Unavailable';
import PreDialog from '../../../components/presentations/PreDialog/PreDialog';
import { TableSelectedActions } from '../../../components/table';
import TablePaginationStandard from '../../../components/table/TablePaginationStandard';
import TableRowWithHighlight, { hasRecentlyChanged } from '../../../components/table/TableRowWithHighlight';
import { GET_ECONOMIC_UNITS_WITH_BUILDINGS } from '../../../graphql/DataCollection.queries';
import { GET_PORTFOLIOS } from '../../../graphql/Portfolio.queries';
import useDataCollectionFilters from '../../../hooks/useDataCollectionFilters';
import usePermissions from '../../../hooks/usePermissions';
import useTable, { applySortFilter } from '../../../hooks/useTable';
import { PATHS } from '../../../routes';
import { ALL_PORTFOLIOS } from '../../EsgAnalysis/EsgAnalysisFilterBarPortfolio';
import BuildingActionMenu from '../Building/BuildingActionMenu';
import AddEconomicUnitModal from '../EconomicUnit/AddEconomicUnitModal';
import EconomicUnitActionMenu from '../EconomicUnit/EconomicUnitActionMenu';
import EconomicUnitActionsMenu from './EconomicUnitActionsMenu';
import EconomicUnitTableHead, { HeadLabel } from './EconomicUnitsHead';
import EconomicUnitToolbar from './EconomicUnitsToolbar';

const arrangeDataForEconomicUnitTable = (
  economicUnits: DataCollectionGetEconomicUnitWithBuildingsFragment[],
): CustomEconomicUnit[] => {
  //@ts-ignore
  return economicUnits.map((economicUnit) => {
    const economicUnitInfo = {
      id: economicUnit.id,
      economic_unit_name: economicUnit.name,
      portfolio: {
        id: economicUnit.portfolio.id,
        name: economicUnit.portfolio.name,
      },
      customer_economic_unit_id: economicUnit.customer_economic_unit_id,
      created_at: economicUnit.created_at,
      updated_at: economicUnit.updated_at,
      currentBuildingsInEu: economicUnit.associatedBuildingsAndDrafts.amount,
    };

    if (economicUnit.buildings.length > 0 && economicUnit.buildings[0].sub_buildings?.length > 0) {
      //TODO removed this after building table is updated

      const { address } = economicUnit.buildings[0];
      return {
        ...address,
        ...economicUnitInfo,

        buildings: economicUnit.buildings.map((building) => ({
          id: building.id,
          typesOfUse: getNufTypesOfUse(building.areas),
          street: building.address.street,
          city: building.address.city,
          postal_code: building.address.postal_code,
          status: building.building_state_id,
          year: building.year_constructed,
          area: getNetArea(building.areas),
          sub_building_ids: building.sub_buildings?.map((b) => b.id) ?? [],
        })),
      };
    } else {
      return {
        ...economicUnitInfo,

        street: null,
        city: null,
        postal_code: null,
        typesOfUse: [],
        buildings: [],
      };
    }
  });
};

export default function DataCollectionEconomicUnitList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hasPortfolioWithWriteAccess } = usePermissions();

  const { page, setPage, rowsPerPage, setRowsPerPage, order, orderBy, setOrder, setOrderBy, handleRequestSort } =
    useTable({
      defaultOrderBy: 'economic_unit_name',
      defaultRowsPerPage: 25,
    });
  const [selectedEconomicUnits, setSelectedEconomicUnits] = useState<number[]>([]);
  const [openEconomicUnitModal, setEconomicUnitsModalOpen] = useState<boolean>(false);
  const [allEconomicUnits, setEconomicUnits] = useState<DataCollectionGetEconomicUnitWithBuildingsFragment[]>([]);
  const [portfolioOptions, setPortfolioOptions] = useState<string[]>([]);

  const {
    filters: {
      dataCollectionEconomicUnitsFilters: { filterName, selectedPortfolio },
    },
    setSelectedPortfolioEconomicUnits: setSelectedPortfolio,
    setFilterNameEconomicUnit: handleFilterByName,
  } = useDataCollectionFilters();
  const { loading: portfoliosLoading } = useQuery(GET_PORTFOLIOS, {
    onCompleted: (data) => {
      //@ts-ignore
      setPortfolioOptions([ALL_PORTFOLIOS, ...data.portfolio.map((portfolio) => portfolio.name)]);
    },
  });

  const {
    loading: economicsUnitsLoading,
    data: economicUnits,
    refetch: refetchEconomicUnits,
  } = useQuery(GET_ECONOMIC_UNITS_WITH_BUILDINGS, {
    onCompleted: (data) => {
      setEconomicUnits(data.economic_unit);
    },
  });

  if (economicsUnitsLoading || portfoliosLoading) {
    return <DelayedLoading />;
  }

  //@ts-ignore
  const economicUnitsData = arrangeDataForEconomicUnitTable(economicUnits.economic_unit);
  const filteredData = applySortFilter({
    data: economicUnitsData,
    nameFilter: {
      fieldName: 'economic_unit_name',
      filterValue: filterName,
    },

    //@ts-ignore
    dataFilters: [
      selectedPortfolio !== ALL_PORTFOLIOS
        ? (economicUnit: (typeof economicUnitsData)[number]) => economicUnit.portfolio.name === selectedPortfolio
        : undefined,
    ].filter(Boolean),
    orderOptions: {
      order,
      orderBy,
    },
  });

  const handleRowClick = (buildingId: number) => {
    navigate(PATHS.dataCollection.building({ id: buildingId }));
  };

  const handleEconomicUnitCheckbox = (id: number) => {
    const selectedIndex = selectedEconomicUnits.indexOf(id);

    if (selectedIndex === -1) {
      setSelectedEconomicUnits([...selectedEconomicUnits, id]);
    } else {
      // removes the index from the array in a more efficient way than filter
      setSelectedEconomicUnits(
        //@ts-ignore
        [].concat(selectedEconomicUnits.slice(0, selectedIndex), selectedEconomicUnits.slice(selectedIndex + 1)),
      );
    }
  };

  const handleSelectAllEconomicUnits = (checked: boolean) => {
    if (checked) {
      const newSelectedIds = filteredData.map((units) => units.id);
      setSelectedEconomicUnits(newSelectedIds);
    } else {
      setSelectedEconomicUnits([]);
    }
  };

  const onEconomicUnitAdded = () => {
    refetchEconomicUnits();
    setEconomicUnitsModalOpen(false);

    //make sure the newest economic unit is on top
    setOrderBy('created_at');
    setOrder('desc');
  };

  const isNotFound = filteredData.length === 0 && Boolean(filterName);

  const economicUnitsColumns: HeadLabel[] = [
    { id: 'economic_unit_name', label: t('General_EconomicUnit'), minWidth: 300 },
    { id: 'customer_economic_unit_id', label: t('General_Identifier'), minWidth: 160 },
    { id: 'city', label: t('General_City'), minWidth: 140 },
    { id: 'postal_code', label: t('General_PostalCode'), minWidth: 140 },
    { id: 'portfolio', label: t('General_Portfolio'), sortingDisabled: true, minWidth: 160 },
    { id: 'action_menu', label: '', sortingDisabled: true },
  ];

  return (
    <>
      {/*@ts-ignore */}
      {economicUnits.economic_unit.length === 0 ? (
        <>
          <Unavailable
            title={t('DataCollection_NoEconomicUnitsAvailable-title')}
            subTitle={t('DataCollection_NoEconomicUnitsAvailable-subtitle')}
          />
          <Stack
            sx={{
              alignItems: 'center',
              mt: 0.5,
            }}
          >
            <Button
              onClick={() => setEconomicUnitsModalOpen(true)}
              size="medium"
              variant="contained"
              startIcon={<Iconify icon={'ic:baseline-plus'} />}
            >
              {t('General_AddEconomicUnit')}
            </Button>
          </Stack>
        </>
      ) : (
        <Card>
          <EconomicUnitToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            handlePortfolioChange={(portfolioName) => setSelectedPortfolio(portfolioName)}
            portfolioOptions={portfolioOptions}
            selectedPortfolio={selectedPortfolio}
          >
            {hasPortfolioWithWriteAccess && (
              <Button
                variant="contained"
                onClick={() => setEconomicUnitsModalOpen(true)}
                sx={{ marginLeft: 'auto' }}
                size="large"
                startIcon={<Iconify icon={'ic:baseline-plus'} />}
              >
                {t('General_AddEconomicUnit')}
              </Button>
            )}
          </EconomicUnitToolbar>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {selectedEconomicUnits.length > 0 && (
                <TableSelectedActions
                  selectedText={t('General_EconomicUnitSelected', { count: selectedEconomicUnits.length })}
                  numSelected={selectedEconomicUnits.length}
                  rowCount={selectedEconomicUnits.length}
                  onSelectAllRows={handleSelectAllEconomicUnits}
                  action={
                    <EconomicUnitActionsMenu
                      selectedEconomicUnits={selectedEconomicUnits}
                      economicUnitsData={allEconomicUnits}
                      onActionCompleted={() => setSelectedEconomicUnits([])}
                    />
                  }
                  sx={{ pl: 8.5, pr: 3 }}
                />
              )}

              <Table>
                <EconomicUnitTableHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={economicUnitsColumns}
                  rowCount={filteredData.length}
                  numSelected={selectedEconomicUnits.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllEconomicUnits}
                />
                {/*
              Using colgroup to set the width of the columns. needed for nested tables to also have same width as parent columns width
              there might be another solution for this but will be explored later
              */}
                <colgroup>
                  <col width={'60px'}></col>
                  <col width={'60px'}></col>
                  <col width={'30%'}></col>
                  <col width={'15%'}></col>
                  <col width={'15%'}></col>
                  <col width={'15%'}></col>
                  <col width={'15%'}></col>
                  <col></col>
                </colgroup>
                {filteredData.length > 0 && (
                  <TableBody>
                    {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      return (
                        <Row
                          key={row.id}
                          row={row}
                          selectedEconomicUnits={selectedEconomicUnits}
                          handleEconomicUnitCheckbox={handleEconomicUnitCheckbox}
                          handleRowClick={handleRowClick}
                        />
                      );
                    })}
                  </TableBody>
                )}
                {isNotFound && (
                  <TableBody>
                    <SearchNotFound searchQuery={filterName} />
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePaginationStandard
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Card>
      )}
      <PreDialog
        type="definedByChildren"
        fullWidth
        open={openEconomicUnitModal}
        onClose={() => {
          setEconomicUnitsModalOpen(false);
        }}
      >
        <AddEconomicUnitModal
          onEconomicUnitAdded={onEconomicUnitAdded}
          onCancel={() => setEconomicUnitsModalOpen(false)}
        />
      </PreDialog>
    </>
  );
}

export type CustomEconomicUnit = {
  id: number;
  currentBuildingsInEu: number;
  created_at: string;
  updated_at: string;
  economic_unit_name: string;
  customer_economic_unit_id: string | null;
  street: string | null;
  city: string | null;
  postal_code: string | null;
  portfolio: {
    id: number;
    name?: string;
  };
  buildings: {
    id: number;
    typesOfUse: type_of_use_enum[];
    street: string;
    city: string;
    postal_code: string;
    year: number;
    area: number;
    status: building_state_enum;
    sub_building_ids: number[];
  }[];
};

type RowProps = {
  handleRowClick: (buildingId: number) => void;
  row: CustomEconomicUnit;
  handleEconomicUnitCheckbox: (id: number) => void;
  selectedEconomicUnits: number[];
};

function Row({ handleRowClick, row, handleEconomicUnitCheckbox, selectedEconomicUnits }: RowProps) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const isItemSelected = selectedEconomicUnits.indexOf(row.id) !== -1;
  const theme = useTheme();

  // This table is not sortable because it is nested inside EconomicUnitTable
  const buildingsColumns: HeadLabel[] = [
    { id: 'street', label: t('General_Street') },
    { id: 'typesOfUse', label: t('General_TypeOfUse') },
    { id: 'year', label: t('General_ConstructionYear') },
    { id: 'area', label: t('General_Area') },
    { id: 'status', label: t('General_Status') },
    { id: 'action_menu', label: '' },
  ];

  return (
    <>
      <TableRowWithHighlight
        background={
          hasRecentlyChanged(row.created_at) ? 'success' : hasRecentlyChanged(row.updated_at) ? 'warning' : 'default'
        }
        removeHighlightOnHover={false}
        sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }}
        selected={isItemSelected}
      >
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            <Iconify icon="ic:outline-arrow-forward-ios" style={{ transform: `rotate(${open ? '90deg' : '0deg'})` }} />
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <Checkbox checked={isItemSelected} onClick={() => handleEconomicUnitCheckbox(row.id)} />
        </TableCell>
        <TableCell onClick={() => setOpen(!open)}>
          <OverflowText
            text={row.economic_unit_name}
            maxWidth="250px"
            TooltipProps={{
              arrow: true,
              placement: 'top-start',
            }}
          />
        </TableCell>
        <TableCell onClick={() => setOpen(!open)}>{row.customer_economic_unit_id ?? '-'}</TableCell>
        <TableCell onClick={() => setOpen(!open)}>{row.city ?? '-'}</TableCell>
        <TableCell onClick={() => setOpen(!open)}>{row.postal_code ?? '-'}</TableCell>
        <TableCell onClick={() => setOpen(!open)}>{row.portfolio.name}</TableCell>
        <TableCell align="right">
          <EconomicUnitActionMenu economicUnit={row} />
        </TableCell>
      </TableRowWithHighlight>
      {row.buildings.length > 0 ? (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <TableContainer
                sx={{
                  border: `1px solid ${theme.palette.grey[500_32]}`,
                  borderRadius: '8px',
                  width: 'auto',
                  ml: '110px',
                  my: 2,
                }}
              >
                <Table>
                  <colgroup>
                    <col width={'31%'}></col>
                    <col width={'16%'}></col>
                    <col width={'16%'}></col>
                    <col width={'16%'}></col>
                    <col width={'16%'}></col>
                    <col></col>
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      {buildingsColumns.map((column) => (
                        <TableCell key={column.id}>{column.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.buildings.map((build) => {
                      const { typesOfUse, year, area, status, id: buildingId, street } = build;
                      return (
                        <TableRow key={buildingId} hover selected={isItemSelected}>
                          <TableCell sx={{ cursor: 'pointer', pl: 4 }} onClick={() => handleRowClick(buildingId)}>
                            {street}
                          </TableCell>
                          <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleRowClick(buildingId)}>
                            {typesOfUse.map((type) => translateTypeOfUseEnum_dynamic(type, t)).join(', ')}
                          </TableCell>
                          <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleRowClick(buildingId)}>
                            {year}
                          </TableCell>
                          <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleRowClick(buildingId)}>
                            {area} m2
                          </TableCell>
                          <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleRowClick(buildingId)}>
                            {translateBuildingStateEnum(status)}
                          </TableCell>
                          <TableCell>
                            <BuildingActionMenu
                              economicUnitId={row.id}
                              currentAmountBuildingsInEu={row.currentBuildingsInEu}
                              buildingId={buildingId}
                              street={street}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ textAlign: 'center', p: 2 }}>
                <Typography variant="caption">{t('DataCollection_NoBuildingExistsInEconomicUnit')}</Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
