import { useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { GET_PORTFOLIO_AND_BUILDING_PERMISSIONS } from '../../../../graphql/Permissions.queries';

/**
 * Convenience hook to check if a user has a permission table entry for a building or portfolio.
 * @param userId - The user id to check for.
 * @returns two functions to check if a user has a permission table entry for a building or portfolio.
 */
export const usePermissionTableEntry = (userId: number) => {
  const { data } = useQuery(GET_PORTFOLIO_AND_BUILDING_PERMISSIONS, {
    variables: { user_id: userId },
  });

  /**
   * Check if a there is an entry in the user_building_permission table for a building for the given user.
   */
  const checkBuildingPermissionTableEntry = useCallback(
    (buildingId: number) => {
      return data?.user_building_permission.find((permission) => permission.building_id === buildingId);
    },
    [data],
  );

  /**
   * Check if a there is an entry in the user_portfolio_permission table for a portfolio for the given user.
   */
  const checkPortfolioPermissionTableEntry = useCallback(
    (portfolioId: number) => {
      return data?.user_portfolio_permission.find((permission) => permission.portfolio_id === portfolioId);
    },
    [data],
  );

  return {
    checkBuildingPermissionTableEntry,
    checkPortfolioPermissionTableEntry,
  };
};
