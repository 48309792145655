import { Box, alpha, useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';

export type IconColors = 'success' | 'error' | 'info' | 'warning';

type Props = {
  variant: IconColors;
  fixedWidth?: boolean;
};

function ColoredLabel({ variant, fixedWidth = true, children }: PropsWithChildren<Props>) {
  const theme = useTheme();

  let backgroundColor: string;

  switch (variant) {
    case 'success':
      backgroundColor = alpha(theme.palette.success.light, 0.24);
      break;

    case 'error':
      backgroundColor = alpha(theme.palette.error.light, 0.24);
      break;

    case 'info':
      backgroundColor = alpha(theme.palette.info.light, 0.24);
      break;

    case 'warning':
      backgroundColor = alpha(theme.palette.warning.light, 0.24);
      break;

    default:
      const exhaustiveCheck: never = variant;
      throw new Error(`Unhandled variant: ${exhaustiveCheck}`);
  }

  return (
    <div style={{ display: 'inline-block' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: backgroundColor,
          padding: '6px',
          borderRadius: '6px',
          width: fixedWidth ? '36px' : 'auto',
        }}
      >
        {children}
      </Box>
    </div>
  );
}

export default ColoredLabel;
