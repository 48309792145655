/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Trans, useTranslation } from 'react-i18next';
import DeleteConfirmationModal from '../../../../../components/DeleteConfirmationModal';
import { SnackbarTimeouts } from '../../../../../components/NotistackProvider';
import { DELETE_EVIDENCE } from '../../../../../graphql/Report.mutations';

type Props = {
  onClose: () => void;
  evidenceToDelete: {
    id: number;
    title: string;
  } | null;
};

function DeleteEvidence({ onClose, evidenceToDelete }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteEvidence] = useMutation(DELETE_EVIDENCE, {
    //@ts-ignore
    variables: { evidenceId: evidenceToDelete?.id },
    optimisticResponse: {
      delete_report_evidence_by_pk: {
        __typename: 'report_evidence',

        //@ts-ignore
        id: evidenceToDelete?.id,
      },
    },
    update: (cache, { data }) => {
      //@ts-ignore
      if (data.delete_report_evidence_by_pk) {
        const normalizedId = cache.identify({
          //@ts-ignore
          id: data.delete_report_evidence_by_pk.id,

          //@ts-ignore
          __typename: data.delete_report_evidence_by_pk.__typename,
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
    onCompleted: () => {
      enqueueSnackbar(t('DeleteEvidence_DeleteEvidence-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
    onError: () => {
      enqueueSnackbar(t('DeleteEvidence_DeleteEvidence-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
  });

  return (
    <DeleteConfirmationModal
      open={Boolean(evidenceToDelete)}
      title={t('General_DeleteModalTitle-ReportEvidence', { count: 1 })}
      description={
        <Trans
          i18nKey={'General_DeleteModalDescription-ReportEvidence'}
          values={{
            name: evidenceToDelete?.title,
            count: 1,
          }}
          components={{ bold: <strong /> }}
        />
      }
      onClose={onClose}
      onDelete={() => {
        deleteEvidence();
        onClose();
      }}
    />
  );
}

export default DeleteEvidence;
