/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import {
  action_constraint,
  action_plan_constraint,
  action_plan_select_column,
  action_plan_update_column,
  action_select_column,
  action_subsidy_constraint,
  action_subsidy_select_column,
  action_subsidy_update_column,
  action_subsidy_value_type_constraint,
  action_subsidy_value_type_enum,
  action_subsidy_value_type_select_column,
  action_subsidy_value_type_update_column,
  action_update_column,
  address_constraint,
  address_select_column,
  address_update_column,
  api_user_constraint,
  api_user_select_column,
  api_user_update_column,
  area_constraint,
  area_select_column,
  area_select_column_area_aggregate_bool_exp_avg_arguments_columns,
  area_select_column_area_aggregate_bool_exp_bool_and_arguments_columns,
  area_select_column_area_aggregate_bool_exp_bool_or_arguments_columns,
  area_select_column_area_aggregate_bool_exp_corr_arguments_columns,
  area_select_column_area_aggregate_bool_exp_covar_samp_arguments_columns,
  area_select_column_area_aggregate_bool_exp_max_arguments_columns,
  area_select_column_area_aggregate_bool_exp_min_arguments_columns,
  area_select_column_area_aggregate_bool_exp_stddev_samp_arguments_columns,
  area_select_column_area_aggregate_bool_exp_sum_arguments_columns,
  area_select_column_area_aggregate_bool_exp_var_samp_arguments_columns,
  area_system_type_constraint,
  area_system_type_enum,
  area_system_type_select_column,
  area_system_type_update_column,
  area_type_constraint,
  area_type_enum,
  area_type_select_column,
  area_type_update_column,
  area_update_column,
  async_job_constraint,
  async_job_select_column,
  async_job_status_type_constraint,
  async_job_status_type_enum,
  async_job_status_type_select_column,
  async_job_status_type_update_column,
  async_job_update_column,
  building_constraint,
  building_model_constraint,
  building_model_select_column,
  building_model_select_column_building_model_aggregate_bool_exp_bool_and_arguments_columns,
  building_model_select_column_building_model_aggregate_bool_exp_bool_or_arguments_columns,
  building_model_update_column,
  building_select_column,
  building_select_column_building_aggregate_bool_exp_bool_and_arguments_columns,
  building_select_column_building_aggregate_bool_exp_bool_or_arguments_columns,
  building_state_constraint,
  building_state_enum,
  building_state_select_column,
  building_state_update_column,
  building_update_column,
  climate_region_constraint,
  climate_region_enum,
  climate_region_select_column,
  climate_region_update_column,
  climate_risk_constraint,
  climate_risk_select_column,
  climate_risk_type_constraint,
  climate_risk_type_enum,
  climate_risk_type_select_column,
  climate_risk_type_update_column,
  climate_risk_update_column,
  consumption_constraint,
  consumption_draft_constraint,
  consumption_draft_select_column,
  consumption_draft_update_column,
  consumption_frequency_type_constraint,
  consumption_frequency_type_enum,
  consumption_frequency_type_select_column,
  consumption_frequency_type_update_column,
  consumption_invoice_constraint,
  consumption_invoice_draft_constraint,
  consumption_invoice_draft_select_column,
  consumption_invoice_draft_update_column,
  consumption_invoice_select_column,
  consumption_invoice_update_column,
  consumption_select_column,
  consumption_source_type_constraint,
  consumption_source_type_enum,
  consumption_source_type_select_column,
  consumption_source_type_update_column,
  consumption_sub_type_constraint,
  consumption_sub_type_enum,
  consumption_sub_type_select_column,
  consumption_sub_type_update_column,
  consumption_type_constraint,
  consumption_type_enum,
  consumption_type_select_column,
  consumption_type_update_column,
  consumption_unit_constraint,
  consumption_unit_enum,
  consumption_unit_select_column,
  consumption_unit_update_column,
  consumption_update_column,
  consumption_waste_details_constraint,
  consumption_waste_details_draft_constraint,
  consumption_waste_details_draft_select_column,
  consumption_waste_details_draft_update_column,
  consumption_waste_details_select_column,
  consumption_waste_details_update_column,
  country_constraint,
  country_enum,
  country_select_column,
  country_state_constraint,
  country_state_enum,
  country_state_select_column,
  country_state_update_column,
  country_update_column,
  cursor_ordering,
  data_source_type_constraint,
  data_source_type_enum,
  data_source_type_select_column,
  data_source_type_update_column,
  draft_status_constraint,
  draft_status_enum,
  draft_status_select_column,
  draft_status_update_column,
  economic_unit_constraint,
  economic_unit_select_column,
  economic_unit_update_column,
  efficiency_class_constraint,
  efficiency_class_enum,
  efficiency_class_select_column,
  efficiency_class_update_column,
  emission_certificate_constraint,
  emission_certificate_draft_constraint,
  emission_certificate_draft_select_column,
  emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_avg_arguments_columns,
  emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_corr_arguments_columns,
  emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_covar_samp_arguments_columns,
  emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_max_arguments_columns,
  emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_min_arguments_columns,
  emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_stddev_samp_arguments_columns,
  emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_sum_arguments_columns,
  emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_var_samp_arguments_columns,
  emission_certificate_draft_update_column,
  emission_certificate_select_column,
  emission_certificate_select_column_emission_certificate_aggregate_bool_exp_avg_arguments_columns,
  emission_certificate_select_column_emission_certificate_aggregate_bool_exp_corr_arguments_columns,
  emission_certificate_select_column_emission_certificate_aggregate_bool_exp_covar_samp_arguments_columns,
  emission_certificate_select_column_emission_certificate_aggregate_bool_exp_max_arguments_columns,
  emission_certificate_select_column_emission_certificate_aggregate_bool_exp_min_arguments_columns,
  emission_certificate_select_column_emission_certificate_aggregate_bool_exp_stddev_samp_arguments_columns,
  emission_certificate_select_column_emission_certificate_aggregate_bool_exp_sum_arguments_columns,
  emission_certificate_select_column_emission_certificate_aggregate_bool_exp_var_samp_arguments_columns,
  emission_certificate_update_column,
  emission_factor_origin_constraint,
  emission_factor_origin_enum,
  emission_factor_origin_select_column,
  emission_factor_origin_update_column,
  emission_factor_type_constraint,
  emission_factor_type_enum,
  emission_factor_type_select_column,
  emission_factor_type_update_column,
  energy_certificate_constraint,
  energy_certificate_consumer_constraint,
  energy_certificate_consumer_select_column,
  energy_certificate_consumer_update_column,
  energy_certificate_draft_constraint,
  energy_certificate_draft_select_column,
  energy_certificate_draft_select_column_energy_certificate_draft_aggregate_bool_exp_bool_and_arguments_columns,
  energy_certificate_draft_select_column_energy_certificate_draft_aggregate_bool_exp_bool_or_arguments_columns,
  energy_certificate_draft_update_column,
  energy_certificate_select_column,
  energy_certificate_type_constraint,
  energy_certificate_type_enum,
  energy_certificate_type_select_column,
  energy_certificate_type_update_column,
  energy_certificate_update_column,
  energy_consumer_constraint,
  energy_consumer_select_column,
  energy_consumer_technology_type_constraint,
  energy_consumer_technology_type_enum,
  energy_consumer_technology_type_select_column,
  energy_consumer_technology_type_update_column,
  energy_consumer_type_constraint,
  energy_consumer_type_enum,
  energy_consumer_type_select_column,
  energy_consumer_type_update_column,
  energy_consumer_update_column,
  energy_consumption_type_enum,
  energy_distribution_constraint,
  energy_distribution_select_column,
  energy_distribution_select_column_energy_distribution_aggregate_bool_exp_bool_and_arguments_columns,
  energy_distribution_select_column_energy_distribution_aggregate_bool_exp_bool_or_arguments_columns,
  energy_distribution_type_constraint,
  energy_distribution_type_enum,
  energy_distribution_type_select_column,
  energy_distribution_type_update_column,
  energy_distribution_update_column,
  energy_path_constraint,
  energy_path_select_column,
  energy_path_update_column,
  energy_producer_constraint,
  energy_producer_select_column,
  energy_producer_update_column,
  energy_source_constraint,
  energy_source_select_column,
  energy_source_type_constraint,
  energy_source_type_enum,
  energy_source_type_select_column,
  energy_source_type_update_column,
  energy_source_update_column,
  energy_standard_constraint,
  energy_standard_enum,
  energy_standard_select_column,
  energy_standard_update_column,
  energy_storage_constraint,
  energy_storage_select_column,
  energy_storage_type_constraint,
  energy_storage_type_enum,
  energy_storage_type_select_column,
  energy_storage_type_update_column,
  energy_storage_update_column,
  energy_system_constraint,
  energy_system_consumer_route_constraint,
  energy_system_consumer_route_select_column,
  energy_system_consumer_route_update_column,
  energy_system_producer_route_constraint,
  energy_system_producer_route_select_column,
  energy_system_producer_route_update_column,
  energy_system_select_column,
  energy_system_type_constraint,
  energy_system_type_enum,
  energy_system_type_select_column,
  energy_system_type_update_column,
  energy_system_update_column,
  envelope_constraint,
  envelope_select_column,
  envelope_type_constraint,
  envelope_type_enum,
  envelope_type_select_column,
  envelope_type_update_column,
  envelope_update_column,
  eu_taxonomy_compliance_constraint,
  eu_taxonomy_compliance_enum,
  eu_taxonomy_compliance_select_column,
  eu_taxonomy_compliance_update_column,
  file_constraint,
  file_select_column,
  file_update_column,
  insulation_material_category_constraint,
  insulation_material_category_enum,
  insulation_material_category_select_column,
  insulation_material_category_update_column,
  insulation_method_constraint,
  insulation_method_enum,
  insulation_method_select_column,
  insulation_method_update_column,
  invoice_payer_type_constraint,
  invoice_payer_type_enum,
  invoice_payer_type_select_column,
  invoice_payer_type_update_column,
  langfuse_datasets_temporary_item_constraint,
  langfuse_datasets_temporary_item_select_column,
  langfuse_datasets_temporary_item_update_column,
  language_constraint,
  language_enum,
  language_select_column,
  language_update_column,
  meter_constraint,
  meter_integration_building_constraint,
  meter_integration_building_select_column,
  meter_integration_building_update_column,
  meter_integration_constraint,
  meter_integration_select_column,
  meter_integration_type_constraint,
  meter_integration_type_enum,
  meter_integration_type_select_column,
  meter_integration_type_update_column,
  meter_integration_update_column,
  meter_select_column,
  meter_update_column,
  MultiNestedTypeEnum,
  notification_constraint,
  notification_select_column,
  notification_select_column_notification_aggregate_bool_exp_bool_and_arguments_columns,
  notification_select_column_notification_aggregate_bool_exp_bool_or_arguments_columns,
  notification_template_constraint,
  notification_template_select_column,
  notification_template_update_column,
  notification_type_constraint,
  notification_type_enum,
  notification_type_select_column,
  notification_type_update_column,
  notification_update_column,
  operation_model_type_constraint,
  operation_model_type_enum,
  operation_model_type_select_column,
  operation_model_type_update_column,
  OperatorEnum,
  OperatorInEnum,
  order_by,
  org_constraint,
  org_preference_constraint,
  org_preference_select_column,
  org_preference_update_column,
  org_select_column,
  org_status_type_constraint,
  org_status_type_enum,
  org_status_type_select_column,
  org_status_type_update_column,
  org_update_column,
  portfolio_constraint,
  portfolio_select_column,
  portfolio_update_column,
  queue_status_enum,
  renovation_constraint,
  renovation_energy_consumer_select_column,
  renovation_energy_distribution_select_column,
  renovation_energy_producer_select_column,
  renovation_energy_storage_select_column,
  renovation_energy_system_route_select_column,
  renovation_envelope_select_column,
  renovation_select_column,
  renovation_select_column_renovation_aggregate_bool_exp_bool_and_arguments_columns,
  renovation_select_column_renovation_aggregate_bool_exp_bool_or_arguments_columns,
  renovation_type_constraint,
  renovation_type_enum,
  renovation_type_select_column,
  renovation_type_update_column,
  renovation_update_column,
  report_answer_autofill_option_constraint,
  report_answer_autofill_option_select_column,
  report_answer_autofill_option_update_column,
  report_answer_chosen_option_constraint,
  report_answer_chosen_option_select_column,
  report_answer_chosen_option_update_column,
  report_answer_constraint,
  report_answer_select_column,
  report_answer_state_constraint,
  report_answer_state_enum,
  report_answer_state_select_column,
  report_answer_state_update_column,
  report_answer_update_column,
  report_constraint,
  report_evidence_constraint,
  report_evidence_select_column,
  report_evidence_update_column,
  report_question_evidence_constraint,
  report_question_evidence_select_column,
  report_question_evidence_update_column,
  report_score_constraint,
  report_score_select_column,
  report_score_update_column,
  report_section_progress_constraint,
  report_section_progress_select_column,
  report_section_progress_update_column,
  report_select_column,
  report_sub_building_constraint,
  report_sub_building_select_column,
  report_sub_building_update_column,
  report_template_constraint,
  report_template_question_constraint,
  report_template_question_option_constraint,
  report_template_question_option_select_column,
  report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_avg_arguments_columns,
  report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_corr_arguments_columns,
  report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_covar_samp_arguments_columns,
  report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_max_arguments_columns,
  report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_min_arguments_columns,
  report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_stddev_samp_arguments_columns,
  report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_sum_arguments_columns,
  report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_var_samp_arguments_columns,
  report_template_question_option_update_column,
  report_template_question_select_column,
  report_template_question_select_column_report_template_question_aggregate_bool_exp_avg_arguments_columns,
  report_template_question_select_column_report_template_question_aggregate_bool_exp_bool_and_arguments_columns,
  report_template_question_select_column_report_template_question_aggregate_bool_exp_bool_or_arguments_columns,
  report_template_question_select_column_report_template_question_aggregate_bool_exp_corr_arguments_columns,
  report_template_question_select_column_report_template_question_aggregate_bool_exp_covar_samp_arguments_columns,
  report_template_question_select_column_report_template_question_aggregate_bool_exp_max_arguments_columns,
  report_template_question_select_column_report_template_question_aggregate_bool_exp_min_arguments_columns,
  report_template_question_select_column_report_template_question_aggregate_bool_exp_stddev_samp_arguments_columns,
  report_template_question_select_column_report_template_question_aggregate_bool_exp_sum_arguments_columns,
  report_template_question_select_column_report_template_question_aggregate_bool_exp_var_samp_arguments_columns,
  report_template_question_type_constraint,
  report_template_question_type_enum,
  report_template_question_type_select_column,
  report_template_question_type_update_column,
  report_template_question_update_column,
  report_template_score_constraint,
  report_template_score_display_type_constraint,
  report_template_score_display_type_enum,
  report_template_score_display_type_select_column,
  report_template_score_display_type_update_column,
  report_template_score_select_column,
  report_template_score_select_column_report_template_score_aggregate_bool_exp_bool_and_arguments_columns,
  report_template_score_select_column_report_template_score_aggregate_bool_exp_bool_or_arguments_columns,
  report_template_score_update_column,
  report_template_section_constraint,
  report_template_section_select_column,
  report_template_section_target_constraint,
  report_template_section_target_enum,
  report_template_section_target_select_column,
  report_template_section_target_update_column,
  report_template_section_update_column,
  report_template_select_column,
  report_template_to_all_questions_select_column,
  report_template_update_column,
  report_update_column,
  role_constraint,
  role_enum,
  role_select_column,
  role_update_column,
  roof_type_constraint,
  roof_type_select_column,
  roof_type_update_column,
  scenario_constraint,
  scenario_select_column,
  scenario_update_column,
  sub_building_class_constraint,
  sub_building_class_enum,
  sub_building_class_select_column,
  sub_building_class_update_column,
  sub_building_constraint,
  sub_building_select_column,
  sub_building_subsidy_constraint,
  sub_building_subsidy_select_column,
  sub_building_subsidy_update_column,
  sub_building_update_column,
  subsidy_category_type_constraint,
  subsidy_category_type_enum,
  subsidy_category_type_select_column,
  subsidy_category_type_update_column,
  subsidy_constraint,
  subsidy_region_type_constraint,
  subsidy_region_type_enum,
  subsidy_region_type_select_column,
  subsidy_region_type_update_column,
  subsidy_select_column,
  subsidy_subsidy_category_constraint,
  subsidy_subsidy_category_select_column,
  subsidy_subsidy_category_update_column,
  subsidy_type_constraint,
  subsidy_type_enum,
  subsidy_type_select_column,
  subsidy_type_update_column,
  subsidy_update_column,
  sync_state_constraint,
  sync_state_enum,
  sync_state_select_column,
  sync_state_update_column,
  target_path_constraint,
  target_path_select_column,
  target_path_template_constraint,
  target_path_template_select_column,
  target_path_template_type_constraint,
  target_path_template_type_enum,
  target_path_template_type_select_column,
  target_path_template_type_update_column,
  target_path_template_update_column,
  target_path_update_column,
  tax_bracket_constraint,
  tax_bracket_enum,
  tax_bracket_select_column,
  tax_bracket_update_column,
  type_of_use_constraint,
  type_of_use_enum,
  type_of_use_select_column,
  type_of_use_update_column,
  user_building_permission_constraint,
  user_building_permission_select_column,
  user_building_permission_select_column_user_building_permission_aggregate_bool_exp_bool_and_arguments_columns,
  user_building_permission_select_column_user_building_permission_aggregate_bool_exp_bool_or_arguments_columns,
  user_building_permission_update_column,
  user_constraint,
  user_portfolio_permission_constraint,
  user_portfolio_permission_select_column,
  user_portfolio_permission_select_column_user_portfolio_permission_aggregate_bool_exp_bool_and_arguments_columns,
  user_portfolio_permission_select_column_user_portfolio_permission_aggregate_bool_exp_bool_or_arguments_columns,
  user_portfolio_permission_update_column,
  user_role_constraint,
  user_role_select_column,
  user_role_update_column,
  user_select_column,
  user_update_column,
  window_type_constraint,
  window_type_select_column,
  window_type_update_column,
} from '@predium/enums';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: DateTime;
  float8: number;
  jsonb: any;
  numeric: number;
  timestamp: string;
  timestamptz: string;
  uuid: string;
};

export type AcceptAutofillReportOutput = {
  report_question_ids: Array<ReportQuestionId>;
};

export type ActionImpactDataOutput = {
  action_id: Scalars['Int'];
  modification_impact: ModificationImpact;
  total_estimated_cost: Scalars['Float'];
  total_renovation_cost: Scalars['Float'];
  total_subsidy_cost: Scalars['Float'];
};

export type ActionPlanImpactDataOutput = {
  implementation_to: Scalars['DateTime'];
  modification_impact: ModificationImpact;
  total_estimated_cost: Scalars['Float'];
  total_renovation_cost: Scalars['Float'];
  total_subsidy_cost: Scalars['Float'];
};

export type ActionPlanMetricsOutput = {
  action_plan: ActionPlanImpactDataOutput;
  actions: Array<ActionImpactDataOutput>;
};

export type AffectedMutationUser = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type AnalyzeBuildingOutput = {
  climateRisks: Array<ClimateRiskOutput>;
  co2Paths: PathsAndCostsOutput;
  co2StrandingDates: StrandingDatesOutput;
  co2Targets: DerivedPathOutput;
  efficiencyClass: efficiency_class_enum;
  energyConsumers: Array<EnergyConsumerBuildingDataOutput>;
  energyPaths: PathsAndCostsOutput;
  energyTargets: DerivedPathOutput;
  euTaxonomyCompliance: eu_taxonomy_compliance_enum;
  floorAreaBySubBuildingClass: Array<FloorAreaBySubBuildingClassOutput>;
  primaryEnergy: Scalars['Float'];
  scope123Co2Emissions: Scope123Co2EmissionsOutput;
  taxBracket: TaxBracketDataOutput;
};

export type AnalyzeBuildingsOutput = {
  analyzedBuildingsCount: Scalars['Int'];
  averageEfficiencyClass: efficiency_class_enum;
  averagePrimaryEnergy: Scalars['Float'];
  averageTaxBracket: TaxBracketDataOutput;
  buildingsByEUTaxonomyCompliance: Array<BuildingsByEUTaxonomyComplianceOutput>;
  buildingsByEfficiencyClass: Array<BuildingsByEfficiencyClassOutput>;
  buildingsByEnergySourceType: Array<BuildingsByEnergySourceTypeOutput>;
  buildingsByTaxBracket: Array<BuildingsByTaxBracketOutput>;
  buildingsGeneralData: Array<BuildingsGeneralDataOutput>;
  climateRisks: Array<ClimateRiskOutput>;
  co2Paths: PathsAndCostsOutput;
  co2Targets: DerivedPathOutput;
  energyDataBySourceType: Array<EnergyConsumerBuildingDataOutput>;
  energyPaths: PathsAndCostsOutput;
  energyTargets: DerivedPathOutput;
  floorAreaBySubBuildingClass: Array<FloorAreaBySubBuildingClassOutput>;
  scope123Co2Emissions: Array<Scope123Co2EmissionsAnalysisOutput>;
  strandingDates: StrandingDatesCombinedOutput;
};

export type AnswerParamsInput = {
  evidences?: InputMaybe<Array<EvidenceInput>>;
  report_question_id: Scalars['Int'];
  selected_option_ids?: InputMaybe<Array<Scalars['Int']>>;
  value?: InputMaybe<Scalars['String']>;
};

export type AnswerQuestionsOutput = {
  report_answer_ids: Array<ReportAnswerId>;
};

export type ApplyApproximationOutput = {
  errorMessage?: Maybe<Scalars['String']>;
  scalingResult?: Maybe<ScalingResultOutput>;
  success: Scalars['Boolean'];
};

export type ApplyBuildingModelMutationsOutput = {
  final_energy: Scalars['Float'];
  final_energy_scaled: Scalars['Float'];
  scaling_successful: Scalars['Boolean'];
};

export type AreaRecalculateMutation = {
  area_system_id: area_system_type_enum;
  area_type_id: area_type_enum;
  id?: InputMaybe<Scalars['Int']>;
  type_of_use_id: type_of_use_enum;
  value: Scalars['Float'];
};

export type AreaRecalculateMutationResponse = {
  areas?: Maybe<Array<AreaRecalculateResult>>;
  errorMessage?: Maybe<Scalars['String']>;
  scalingResult?: Maybe<ApplyBuildingModelMutationsOutput>;
  success: Scalars['Boolean'];
};

export type AreaRecalculateResult = {
  area_type_id?: Maybe<area_type_enum>;
  id?: Maybe<Scalars['Int']>;
  type_of_use_id?: Maybe<type_of_use_enum>;
  value: Scalars['Float'];
};

export type AreaUpdateMutation = {
  area_system_id?: InputMaybe<area_system_type_enum>;
  area_type_id?: InputMaybe<area_type_enum>;
  class_of_use_id?: InputMaybe<sub_building_class_enum>;
  create?: InputMaybe<Scalars['Boolean']>;
  data_source_id?: InputMaybe<data_source_type_enum>;
  delete?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_rented?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  type_of_use_id?: InputMaybe<type_of_use_enum>;
  value: Scalars['Float'];
};

export type AreaUpdateMutationResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  scalingResult?: Maybe<ApplyBuildingModelMutationsOutput>;
  success: Scalars['Boolean'];
};

export type BeforeAfterDataWithImpactOutput = {
  after?: Maybe<Scalars['Float']>;
  before?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['Float']>;
};

export type BenchmarkOutput = {
  averagePrimaryEnergy: Scalars['Float'];
  co2Paths: PathsAndCostsOutput;
  co2Targets: DerivedPathOutput;
  energyPaths: PathsAndCostsOutput;
  energyTargets: DerivedPathOutput;
  strandingDates: StrandingDatesCombinedOutput;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_comparison_exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type BuildingWhere = {
  active_building_model?: InputMaybe<NestedBuildingModel>;
  areas?: InputMaybe<NestedArea>;
  building_state_id?: InputMaybe<WhereBuildingState>;
  economic_unit?: InputMaybe<NestedEconomicUnit>;
  heritage_district?: InputMaybe<WhereBoolean>;
  id?: InputMaybe<WhereInts>;
  leasehold?: InputMaybe<WhereBoolean>;
  milieu_protection?: InputMaybe<WhereBoolean>;
  monument_protection?: InputMaybe<WhereBoolean>;
};

export type BuildingsByEUTaxonomyComplianceOutput = {
  count: Scalars['Int'];
  euTaxonomyCompliance: eu_taxonomy_compliance_enum;
};

export type BuildingsByEfficiencyClassOutput = {
  areaUsable: Scalars['Float'];
  count: Scalars['Int'];
  efficiencyClass: efficiency_class_enum;
};

export type BuildingsByEnergySourceTypeOutput = {
  count: Scalars['Int'];
  energySourceType: energy_source_type_enum;
};

export type BuildingsByTaxBracketOutput = {
  count: Scalars['Int'];
  taxBracket: TaxBracketDataOutput;
};

export type BuildingsGeneralDataOutput = {
  address: Scalars['String'];
  area: Scalars['Float'];
  buildingId: Scalars['Int'];
  co2Intensity: Scalars['Float'];
  finalEnergy: Scalars['Float'];
  mainEnergySourceType: energy_source_type_enum;
  typeOfUse: Array<type_of_use_enum>;
  year: Scalars['Int'];
};

export type ClimateRiskOutput = {
  timespan?: Maybe<Scalars['String']>;
  type: climate_risk_type_enum;
  value: Scalars['Float'];
};

export type Co2EmissionsOutput = {
  co2Emissions: Scalars['Float'];
  co2EmissionsTotal: Scalars['Float'];
};

export type ConsumptionAllocation = {
  carbonEmissions: ConsumptionAllocationCarbonEmission;
  co2Tax: ConsumptionAllocationTax;
  energyConsumptions: Array<ConsumptionAllocationEnergyConsumption>;
};

export type ConsumptionAllocationCarbonEmission = {
  intensity: Scalars['Float'];
  intensityAbsolute: Scalars['Float'];
  scope1: ConsumptionAllocationCarbonEmissionScope;
  scope2: ConsumptionAllocationCarbonEmissionScope;
};

export type ConsumptionAllocationCarbonEmissionScope = {
  percentage: Scalars['Float'];
  value: Scalars['Float'];
};

export type ConsumptionAllocationEnergyConsumption = {
  items: Array<ConsumptionAllocationEnergyConsumptionItem>;
  total: Scalars['Float'];
  totalAbsolute: Scalars['Float'];
  type: energy_consumption_type_enum;
};

export type ConsumptionAllocationEnergyConsumptionItem = {
  percentage: Scalars['Float'];
  type: Scalars['String'];
  value: Scalars['Float'];
  valueAbsolute: Scalars['Float'];
};

export type ConsumptionAllocationTax = {
  costShare: Scalars['Float'];
  forecastItems: Array<ConsumptionAllocationTaxForecast>;
  total: Scalars['Float'];
  totalAbsolute: Scalars['Float'];
};

export type ConsumptionAllocationTaxForecast = {
  landlordShare: Scalars['Float'];
  landlordShareAbsolute: Scalars['Float'];
  landlordSharePercentage: Scalars['Float'];
  tenantShare: Scalars['Float'];
  tenantShareAbsolute: Scalars['Float'];
  tenantSharePercentage: Scalars['Float'];
  year: Scalars['Int'];
};

export type ConsumptionAnalysis = {
  data: Array<ConsumptionAnalysisItem>;
};

export type ConsumptionAnalysisChart = {
  count: Scalars['Int'];
  from: Scalars['Float'];
  sum: Scalars['Float'];
  to: Scalars['Float'];
};

export type ConsumptionAnalysisItem = {
  average: Scalars['Float'];
  series: Array<ConsumptionAnalysisChart>;
  type: consumption_type_enum;
};

export type ConsumptionDraftInput = {
  areaId?: InputMaybe<Scalars['Int']>;
  consumptionTypeId?: InputMaybe<consumption_type_enum>;
  cost?: InputMaybe<Scalars['Float']>;
  displayUnitValue?: InputMaybe<consumption_unit_enum>;
  energySourceTypeId?: InputMaybe<energy_source_type_enum>;
  from?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Scalars['String']>;
  subTypeId?: InputMaybe<consumption_sub_type_enum>;
  to?: InputMaybe<Scalars['DateTime']>;
  value?: InputMaybe<Scalars['Float']>;
  wasteDetails?: InputMaybe<ConsumptionDraftWasteDetailsInput>;
};

export type ConsumptionDraftWasteDetailsInput = {
  amount?: InputMaybe<Scalars['Float']>;
  displayUnitFrequency?: InputMaybe<consumption_unit_enum>;
  displayUnitVolume?: InputMaybe<consumption_unit_enum>;
  frequency?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Float']>;
  volume?: InputMaybe<Scalars['Float']>;
};

export type ConsumptionInput = {
  areaId: Scalars['Int'];
  consumptionTypeId: consumption_type_enum;
  cost?: InputMaybe<Scalars['Float']>;
  displayUnitValue?: InputMaybe<consumption_unit_enum>;
  energySourceTypeId?: InputMaybe<energy_source_type_enum>;
  from: Scalars['DateTime'];
  id?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Scalars['String']>;
  subTypeId?: InputMaybe<consumption_sub_type_enum>;
  to: Scalars['DateTime'];
  value?: InputMaybe<Scalars['Float']>;
  wasteDetails?: InputMaybe<ConsumptionWasteDetailsInput>;
};

export type ConsumptionSummary = {
  consumptionCategories: Array<YearlyConsumption>;
};

export type ConsumptionWasteDetailsInput = {
  amount: Scalars['Float'];
  displayUnitFrequency?: InputMaybe<consumption_unit_enum>;
  displayUnitVolume?: InputMaybe<consumption_unit_enum>;
  frequency: Scalars['Float'];
  id?: InputMaybe<Scalars['Int']>;
  price: Scalars['Float'];
  volume: Scalars['Float'];
};

export type Coordinates = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CreateBuildingFromEnergyCertificateDraftOutput = {
  building_id: Scalars['Int'];
};

export type CreateConsumptionInvoiceDraftOutput = {
  CreateConsumptionInvoiceDraftOutput?: Maybe<consumption_invoice_draft>;
  consumption_invoice_draft_id: Scalars['Int'];
  upload_url: Scalars['String'];
};

export type CreateConsumptionInvoiceFromDraftOutput = {
  CreateConsumptionInvoiceFromDraftOutput?: Maybe<consumption_invoice>;
  consumption_invoice_id: Scalars['Int'];
};

export type CreateEmissionCertificateDraftOutput = {
  CreateEmissionCertificateDraftOutput?: Maybe<emission_certificate_draft>;
  emission_certificate_draft_id: Scalars['Int'];
  upload_url: Scalars['String'];
};

export type CreateEmissionCertificateFromDraftOutput = {
  CreateEmissionCertificateFromDraftOutput?: Maybe<emission_certificate>;
  emission_certificate_id: Scalars['Int'];
};

export type CreateEnergyCertificateDraftOutput = {
  CreateEnergyCertificateDraftOutput?: Maybe<energy_certificate_draft>;
  energy_certificate_draft_id: Scalars['Int'];
  upload_url: Scalars['String'];
};

export type CreateEnergyCertificateFileOutput = {
  CreateEnergyCertificateFileOutput: Array<file>;
  CreateEnergyCertificateFileOutput_aggregate: file_aggregate;
  file_id: Scalars['Int'];
  upload_url: Scalars['String'];
};

export type CreateEnergyCertificateFileOutputCreateEnergyCertificateFileOutputArgs = {
  distinct_on?: InputMaybe<Array<file_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<file_order_by>>;
  where?: InputMaybe<file_bool_exp>;
};

export type CreateEnergyCertificateFileOutputCreateEnergyCertificateFileOutput_aggregateArgs = {
  distinct_on?: InputMaybe<Array<file_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<file_order_by>>;
  where?: InputMaybe<file_bool_exp>;
};

export type CreateEvidenceOutput = {
  CreateEvidenceOutput: Array<report_evidence>;
  CreateEvidenceOutput_aggregate: report_evidence_aggregate;
  evidence_id: Scalars['Int'];
  upload_url: Scalars['String'];
};

export type CreateEvidenceOutputCreateEvidenceOutputArgs = {
  distinct_on?: InputMaybe<Array<report_evidence_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_evidence_order_by>>;
  where?: InputMaybe<report_evidence_bool_exp>;
};

export type CreateEvidenceOutputCreateEvidenceOutput_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_evidence_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_evidence_order_by>>;
  where?: InputMaybe<report_evidence_bool_exp>;
};

export type CreateReportOutput = {
  CreateReportOutput?: Maybe<report>;
  report_id: Scalars['Int'];
};

export type CustomCostInput = {
  cost_total_custom?: Scalars['Float'];
  use_custom_cost?: InputMaybe<Scalars['Boolean']>;
};

export type DeleteEmissionCertificateOutput = {
  success: Scalars['Boolean'];
};

export type DerivedPathOutput = {
  onePointFive: Array<Scalars['Float']>;
};

export type EconomicUnitAmount = {
  amount: Scalars['Int'];
};

export type EconomicUnitOutput = {
  ids: Array<Scalars['Int']>;
};

export type EfficiencyClassOutput = {
  after?: Maybe<efficiency_class_enum>;
  before?: Maybe<efficiency_class_enum>;
};

export type EmissionCertificateMutation = {
  delete?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
};

export type EmissionCertificateUpdateInput = {
  emission_factor?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  issuer: Scalars['String'];
  primary_energy_factor?: InputMaybe<Scalars['Float']>;
  valid_from: Scalars['DateTime'];
  valid_until: Scalars['DateTime'];
};

export type EmissionCertificateUpdateResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type EnergyConsumerBuildingDataOutput = {
  co2EmissionsM2: Scalars['Float'];
  co2EmissionsTotal: Scalars['Float'];
  energyConsumptionM2: Scalars['Float'];
  energyConsumptionTotal: Scalars['Float'];
  energySourceType: energy_source_type_enum;
};

export type EnergyConsumerMutation = {
  construction_year?: InputMaybe<Scalars['Int']>;
  cost_per_m2?: InputMaybe<Scalars['Float']>;
  create?: InputMaybe<Scalars['Boolean']>;
  efficiency?: InputMaybe<Scalars['Float']>;
  energy_consumer_technology_type_id?: InputMaybe<energy_consumer_technology_type_enum>;
  energy_consumer_type_id?: InputMaybe<energy_consumer_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type EnergyDistributionMutation = {
  construction_year?: InputMaybe<Scalars['Int']>;
  create?: InputMaybe<Scalars['Boolean']>;
  delete?: InputMaybe<Scalars['Boolean']>;
  energy_distribution_type_id?: InputMaybe<energy_distribution_type_enum>;
  heat_distribution_loss?: InputMaybe<Scalars['Float']>;
  heat_transfer_loss?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  last_balancing_set_to_never?: InputMaybe<Scalars['Boolean']>;
  last_hydraulic_balancing?: InputMaybe<Scalars['DateTime']>;
  recoverable_heat_loss?: InputMaybe<Scalars['Float']>;
};

export type EnergyStorageMutation = {
  annual_heat_loss?: InputMaybe<Scalars['Float']>;
  construction_year?: InputMaybe<Scalars['Int']>;
  create?: InputMaybe<Scalars['Boolean']>;
  delete?: InputMaybe<Scalars['Boolean']>;
  energy_storage_type_id?: InputMaybe<energy_storage_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  recoverable_heat_loss?: InputMaybe<Scalars['Float']>;
};

export type EnergySystemMutation = {
  create?: InputMaybe<Scalars['Boolean']>;
  energy_system_route_mutations: Array<EnergySystemRouteMutation>;
  energy_system_type_id?: InputMaybe<energy_system_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
};

export type EnergySystemRouteMutation = {
  create?: InputMaybe<Scalars['Boolean']>;
  delete?: InputMaybe<Scalars['Boolean']>;
  emission_certificate?: InputMaybe<EmissionCertificateMutation>;
  energy_consumer_id?: InputMaybe<Scalars['Int']>;
  energy_distribution_id?: InputMaybe<Scalars['Int']>;
  energy_final?: InputMaybe<Scalars['Float']>;
  energy_source_type?: InputMaybe<energy_source_type_enum>;
  energy_storage_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  primary_energy_factor_emission_certificate?: InputMaybe<EmissionCertificateMutation>;
};

export type EnergySystemRouteRenovationParameterInput = {
  energy_source_type: energy_source_type_enum;
};

export type EnvelopeMutation = {
  area?: InputMaybe<Scalars['Float']>;
  base_construction_lambda?: InputMaybe<Scalars['Float']>;
  base_construction_material_name?: InputMaybe<Scalars['String']>;
  base_construction_thickness?: InputMaybe<Scalars['Float']>;
  create?: InputMaybe<Scalars['Boolean']>;
  delete?: InputMaybe<Scalars['Boolean']>;
  envelope_type?: InputMaybe<envelope_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  inclination?: InputMaybe<Scalars['Float']>;
  insulation_lambda?: InputMaybe<Scalars['Float']>;
  insulation_material_category?: InputMaybe<insulation_material_category_enum>;
  insulation_material_name?: InputMaybe<Scalars['String']>;
  insulation_method?: InputMaybe<insulation_method_enum>;
  insulation_thickness?: InputMaybe<Scalars['Float']>;
  orientation?: InputMaybe<Scalars['Float']>;
  u_value?: InputMaybe<Scalars['Float']>;
};

export type EnvelopeRenovationParameterInput = {
  cost_per_m2?: InputMaybe<Scalars['Float']>;
  envelope_type: envelope_type_enum;
  insulation_lambda?: InputMaybe<Scalars['Float']>;
  insulation_material_category?: InputMaybe<insulation_material_category_enum>;
  insulation_method?: InputMaybe<insulation_method_enum>;
  insulation_thickness?: InputMaybe<Scalars['Float']>;
  material_name: Scalars['String'];
  u_value?: InputMaybe<Scalars['Float']>;
};

export type EsgConsumptionAnalysis = {
  co2Intensity: Scalars['Float'];
  co2IntensityAbsolute: Scalars['Float'];
  co2Tax: Scalars['Float'];
  co2TaxAbsolute: Scalars['Float'];
  efficiencyClass: efficiency_class_enum;
  energyCost: Scalars['Float'];
  energyCostAbsolute: Scalars['Float'];
  euTaxonomy: eu_taxonomy_compliance_enum;
  finalEnergy: Scalars['Float'];
  finalEnergyAbsolute: Scalars['Float'];
  primaryEnergy: Scalars['Float'];
  primaryEnergyAbsolute: Scalars['Float'];
};

export type EvidenceInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

export type ExcelExportOutput = {
  body: Scalars['String'];
};

export type ExportActionPlanAsPdfOutput = {
  body: Scalars['String'];
};

export type ExportEsgAnalysisAsPdfOutput = {
  body: Scalars['String'];
};

export type ExportReportAsPdfOutput = {
  body: Scalars['String'];
};

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_comparison_exp = {
  _eq?: InputMaybe<Scalars['Float']>;
  _gt?: InputMaybe<Scalars['Float']>;
  _gte?: InputMaybe<Scalars['Float']>;
  _in?: InputMaybe<Array<Scalars['Float']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Float']>;
  _lte?: InputMaybe<Scalars['Float']>;
  _neq?: InputMaybe<Scalars['Float']>;
  _nin?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloorAreaBySubBuildingClassOutput = {
  area: Scalars['Float'];
  subBuildingClass: sub_building_class_enum;
};

export type GetBuildingModelMutationAvailabilityOutput = {
  action_deletion_necessary: Scalars['Boolean'];
  affected_action_plan_count: Scalars['Int'];
  affected_users: Array<AffectedMutationUser>;
};

export type GetUserFeatureFlagsOutput = {
  AREA_MANAGEMENT: Scalars['Boolean'];
  BUILDING_CREATION_CONSUMER_EXTENSION: Scalars['Boolean'];
  CONFIGMANAGER_EMISSIONFACTORTYPE_DISABLED: Scalars['Boolean'];
  CONSUMPTION_ANALYSIS: Scalars['Boolean'];
  ENERGY_CERTIFICATE_LANGFUSE_OCR: Scalars['Boolean'];
};

export type GlobalNotificationInput = {
  notification: NotificationInput;
};

export type HydraulicBalancingRenovationParameterInput = {
  costs_with_components?: InputMaybe<Scalars['Boolean']>;
  last_hydraulic_balancing: Scalars['DateTime'];
};

export type ImplementActionOutput = {
  ImplementActionOutput?: Maybe<action>;
  action_id: Scalars['Int'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_comparison_exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type ModificationImpact = {
  co2_cost_total: BeforeAfterDataWithImpactOutput;
  co2_emissions: BeforeAfterDataWithImpactOutput;
  efficiency_class: EfficiencyClassOutput;
  energy_cost_total: BeforeAfterDataWithImpactOutput;
  final_energy: BeforeAfterDataWithImpactOutput;
  primary_energy: BeforeAfterDataWithImpactOutput;
  stranding_date: StrandingDateOutput;
};

export { MultiNestedTypeEnum };

export type NestedArea = {
  class_of_use_id?: InputMaybe<WhereAreaClass>;
  nestingType?: InputMaybe<MultiNestedTypeEnum>;
};

export type NestedBuildingModel = {
  energy_paths?: InputMaybe<NestedEnergyPath>;
  energy_systems?: InputMaybe<NestedEnergySystem>;
};

export type NestedEconomicUnit = {
  id?: InputMaybe<WhereInts>;
  portfolio_id?: InputMaybe<WhereInts>;
};

export type NestedEnergyPath = {
  efficiency_class_id?: InputMaybe<WhereEfficiencyClass>;
  eu_taxonomy_compliance_id?: InputMaybe<WhereEUTaxonomyCompliance>;
  nestingType?: InputMaybe<MultiNestedTypeEnum>;
  tax_bracket_id?: InputMaybe<WhereTaxBracket>;
  year?: InputMaybe<WhereInt>;
};

export type NestedEnergySystem = {
  energy_system_consumer_routes?: InputMaybe<NestedEnergySystemConsumerRoute>;
  nestingType?: InputMaybe<MultiNestedTypeEnum>;
};

export type NestedEnergySystemConsumerRoute = {
  energy_source_type_id?: InputMaybe<WhereEnergySourceType>;
  nestingType?: InputMaybe<MultiNestedTypeEnum>;
};

export type NotificationInput = {
  description: Scalars['String'];
  href?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type NotificationTemplateId = {
  NotificationTemplateId?: Maybe<notification_template>;
  notification_template_id: Scalars['Int'];
};

export { OperatorEnum };

export { OperatorInEnum };

export type OrgNotificationInput = {
  notification: NotificationInput;
  org_id: Scalars['Int'];
};

export type PathsAndCostsOutput = {
  costsM2: Array<Scalars['Float']>;
  costsTotal: Array<Scalars['Float']>;
  pathM2: Array<Scalars['Float']>;
  pathTotal: Array<Scalars['Float']>;
};

export type QueueStatusOutput = {
  completedJobs: Scalars['Float'];
  failedJobs: Scalars['Float'];
  isPaused: Scalars['Boolean'];
  name: Scalars['String'];
  waitingJobs: Scalars['Float'];
};

export type QueueStatusParameterInput = {
  queueName?: InputMaybe<Scalars['String']>;
  queue_status_enum: queue_status_enum;
};

export type QueueWhere = {
  queueName: WhereString;
};

export type ReportAnswerId = {
  ReportAnswerId?: Maybe<report_answer>;
  id: Scalars['Int'];
};

export type ReportInstantiationOptionsInput = {
  due_date: Scalars['DateTime'];
  end_date: Scalars['DateTime'];
  report_template_id: Scalars['Int'];
  responsible_user_id?: InputMaybe<Scalars['Int']>;
  start_date: Scalars['DateTime'];
  sub_buildings: Array<Scalars['Int']>;
};

export type ReportQuestionId = {
  ReportQuestionId?: Maybe<report_template_question>;
  id: Scalars['Int'];
};

export type ReportTemplateOutput = {
  description: Scalars['String'];
  id: Scalars['Int'];
  org_id?: Maybe<Scalars['Int']>;
  single_sub_building: Scalars['Boolean'];
  title: Scalars['String'];
  version: Scalars['String'];
};

export type ScalingResultOutput = {
  final_energy: Scalars['Float'];
  final_energy_scaled: Scalars['Float'];
  scaling_successful: Scalars['Boolean'];
};

export type Scope123Co2EmissionsAnalysisOutput = {
  data: Scope123Co2EmissionsOutput;
  year: Scalars['String'];
};

export type Scope123Co2EmissionsOutput = {
  scope1: Co2EmissionsOutput;
  scope2: Co2EmissionsOutput;
  scope3: Co2EmissionsOutput;
};

export type SetupMeterIntegrationOutput = {
  integration_id: Scalars['Int'];
};

export type SimulateActionOutput = {
  co2_emissions: Scalars['Float'];
  co2_tax_cost: Scalars['Float'];
  efficiency_class: efficiency_class_enum;
  energy_final: Scalars['Float'];
  energy_final_cost: Scalars['Float'];
  energy_primary: Scalars['Float'];
  renovation_costs?: Maybe<Scalars['Float']>;
  stranding_date: Scalars['DateTime'];
};

export type SolarRenovationParameterInput = {
  cost_per_m2: Scalars['Float'];
  module_angle: Scalars['Float'];
  module_peak_power: Scalars['Float'];
  roof_area_ratio: Scalars['Float'];
  solar_active_area: Scalars['Float'];
  technology_name: Scalars['String'];
};

export type StrandingDateOutput = {
  after?: Maybe<Scalars['DateTime']>;
  before: Scalars['DateTime'];
  impact?: Maybe<Scalars['Int']>;
};

export type StrandingDatesCombinedOutput = {
  co2: StrandingDatesOutput;
  energy: StrandingDatesOutput;
};

export type StrandingDatesOutput = {
  onePointFive: Array<StrandingRangeOutput>;
};

export type StrandingRangeOutput = {
  endYear: Scalars['Int'];
  startYear: Scalars['Int'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_array_comparison_exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']>>;
  _eq?: InputMaybe<Array<Scalars['String']>>;
  _gt?: InputMaybe<Array<Scalars['String']>>;
  _gte?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Array<Scalars['String']>>;
  _lte?: InputMaybe<Array<Scalars['String']>>;
  _neq?: InputMaybe<Array<Scalars['String']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_comparison_exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type SubsidyInput = {
  id: Scalars['Int'];
  type: action_subsidy_value_type_enum;
  value: Scalars['Float'];
};

export type SyncMeterIntegrationOutput = {
  integration_id: Scalars['Int'];
};

export type SystemRenovationParameterInput = {
  consumer_technology_type: energy_consumer_technology_type_enum;
  cost_per_m2?: InputMaybe<Scalars['Float']>;
  efficiency: Scalars['Float'];
  energy_source_type: energy_source_type_enum;
  system_type: energy_system_type_enum;
  technology_name: Scalars['String'];
};

export type TaxBracketDataOutput = {
  landlordProportion: Scalars['Float'];
  taxBracket: tax_bracket_enum;
};

export type UpdateConsumptionInvoiceDraftOutput = {
  UpdateConsumptionInvoiceDraftOutput?: Maybe<consumption_invoice_draft>;
  consumptionInvoiceDraftId: Scalars['Int'];
};

export type UpdateConsumptionInvoiceOutput = {
  UpdateConsumptionInvoiceOutput?: Maybe<consumption_invoice>;
  consumptionInvoiceId: Scalars['Int'];
};

export type UpdateOrgLogoOutput = {
  UpdateOrgLogoOutput?: Maybe<file>;
  file_id: Scalars['Int'];
  upload_url: Scalars['String'];
};

export type UpdateSelectedIdsOutput = {
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  updatedIds: Array<Scalars['String']>;
};

export type UserId = {
  UserId?: Maybe<user>;
  user_id?: Maybe<Scalars['Int']>;
};

export type UsersNotificationInput = {
  notification: NotificationInput;
  user_ids: Array<Scalars['Int']>;
};

export type WhereAreaClass = {
  op: OperatorInEnum;
  value: Array<sub_building_class_enum>;
};

export type WhereBoolean = {
  op: OperatorEnum;
  value?: InputMaybe<Scalars['Boolean']>;
};

export type WhereBuildingState = {
  op: OperatorInEnum;
  value: Array<building_state_enum>;
};

export type WhereEUTaxonomyCompliance = {
  op: OperatorInEnum;
  value: Array<eu_taxonomy_compliance_enum>;
};

export type WhereEfficiencyClass = {
  op: OperatorInEnum;
  value: Array<efficiency_class_enum>;
};

export type WhereEnergySourceType = {
  op: OperatorInEnum;
  value: Array<energy_source_type_enum>;
};

export type WhereInt = {
  op: OperatorEnum;
  value?: InputMaybe<Scalars['Int']>;
};

export type WhereInts = {
  op: OperatorInEnum;
  value?: InputMaybe<Array<Scalars['Int']>>;
};

export type WhereString = {
  op: OperatorEnum;
  value?: InputMaybe<Scalars['String']>;
};

export type WhereTaxBracket = {
  op: OperatorInEnum;
  value: Array<tax_bracket_enum>;
};

export type YearlyConsumption = {
  consumption_type: consumption_type_enum;
  cost: Scalars['Float'];
  providers: Array<Scalars['String']>;
  value: Scalars['Float'];
};

/** columns and relationships of "action" */
export type action = {
  /** An object relationship */
  action_plan: action_plan;
  action_plan_id: Scalars['Int'];
  /** An array relationship */
  building_models: Array<building_model>;
  /** An aggregate relationship */
  building_models_aggregate: building_model_aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  implementation_from: Scalars['timestamptz'];
  implementation_to: Scalars['timestamptz'];
  order: Scalars['Int'];
  /** An array relationship */
  renovations: Array<renovation>;
  /** An aggregate relationship */
  renovations_aggregate: renovation_aggregate;
  /** An array relationship */
  renovations_energy_consumer: Array<renovation_energy_consumer>;
  /** An aggregate relationship */
  renovations_energy_consumer_aggregate: renovation_energy_consumer_aggregate;
  /** An array relationship */
  renovations_energy_distribution: Array<renovation_energy_distribution>;
  /** An aggregate relationship */
  renovations_energy_distribution_aggregate: renovation_energy_distribution_aggregate;
  /** An array relationship */
  renovations_energy_producer: Array<renovation_energy_producer>;
  /** An aggregate relationship */
  renovations_energy_producer_aggregate: renovation_energy_producer_aggregate;
  /** An array relationship */
  renovations_energy_routes: Array<renovation_energy_system_route>;
  /** An aggregate relationship */
  renovations_energy_routes_aggregate: renovation_energy_system_route_aggregate;
  /** An array relationship */
  renovations_energy_storage: Array<renovation_energy_storage>;
  /** An aggregate relationship */
  renovations_energy_storage_aggregate: renovation_energy_storage_aggregate;
  /** An array relationship */
  renovations_envelope: Array<renovation_envelope>;
  /** An aggregate relationship */
  renovations_envelope_aggregate: renovation_envelope_aggregate;
  /** An array relationship */
  subsidies: Array<action_subsidy>;
  /** An aggregate relationship */
  subsidies_aggregate: action_subsidy_aggregate;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "action" */
export type actionbuilding_modelsArgs = {
  distinct_on?: InputMaybe<Array<building_model_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<building_model_order_by>>;
  where?: InputMaybe<building_model_bool_exp>;
};

/** columns and relationships of "action" */
export type actionbuilding_models_aggregateArgs = {
  distinct_on?: InputMaybe<Array<building_model_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<building_model_order_by>>;
  where?: InputMaybe<building_model_bool_exp>;
};

/** columns and relationships of "action" */
export type actionrenovationsArgs = {
  distinct_on?: InputMaybe<Array<renovation_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_order_by>>;
  where?: InputMaybe<renovation_bool_exp>;
};

/** columns and relationships of "action" */
export type actionrenovations_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_order_by>>;
  where?: InputMaybe<renovation_bool_exp>;
};

/** columns and relationships of "action" */
export type actionrenovations_energy_consumerArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_consumer_order_by>>;
  where?: InputMaybe<renovation_energy_consumer_bool_exp>;
};

/** columns and relationships of "action" */
export type actionrenovations_energy_consumer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_consumer_order_by>>;
  where?: InputMaybe<renovation_energy_consumer_bool_exp>;
};

/** columns and relationships of "action" */
export type actionrenovations_energy_distributionArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_distribution_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_distribution_order_by>>;
  where?: InputMaybe<renovation_energy_distribution_bool_exp>;
};

/** columns and relationships of "action" */
export type actionrenovations_energy_distribution_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_distribution_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_distribution_order_by>>;
  where?: InputMaybe<renovation_energy_distribution_bool_exp>;
};

/** columns and relationships of "action" */
export type actionrenovations_energy_producerArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_producer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_producer_order_by>>;
  where?: InputMaybe<renovation_energy_producer_bool_exp>;
};

/** columns and relationships of "action" */
export type actionrenovations_energy_producer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_producer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_producer_order_by>>;
  where?: InputMaybe<renovation_energy_producer_bool_exp>;
};

/** columns and relationships of "action" */
export type actionrenovations_energy_routesArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_system_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_system_route_order_by>>;
  where?: InputMaybe<renovation_energy_system_route_bool_exp>;
};

/** columns and relationships of "action" */
export type actionrenovations_energy_routes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_system_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_system_route_order_by>>;
  where?: InputMaybe<renovation_energy_system_route_bool_exp>;
};

/** columns and relationships of "action" */
export type actionrenovations_energy_storageArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_storage_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_storage_order_by>>;
  where?: InputMaybe<renovation_energy_storage_bool_exp>;
};

/** columns and relationships of "action" */
export type actionrenovations_energy_storage_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_storage_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_storage_order_by>>;
  where?: InputMaybe<renovation_energy_storage_bool_exp>;
};

/** columns and relationships of "action" */
export type actionrenovations_envelopeArgs = {
  distinct_on?: InputMaybe<Array<renovation_envelope_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_envelope_order_by>>;
  where?: InputMaybe<renovation_envelope_bool_exp>;
};

/** columns and relationships of "action" */
export type actionrenovations_envelope_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_envelope_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_envelope_order_by>>;
  where?: InputMaybe<renovation_envelope_bool_exp>;
};

/** columns and relationships of "action" */
export type actionsubsidiesArgs = {
  distinct_on?: InputMaybe<Array<action_subsidy_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_subsidy_order_by>>;
  where?: InputMaybe<action_subsidy_bool_exp>;
};

/** columns and relationships of "action" */
export type actionsubsidies_aggregateArgs = {
  distinct_on?: InputMaybe<Array<action_subsidy_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_subsidy_order_by>>;
  where?: InputMaybe<action_subsidy_bool_exp>;
};

/** aggregated selection of "action" */
export type action_aggregate = {
  aggregate?: Maybe<action_aggregate_fields>;
  nodes: Array<action>;
};

export type action_aggregate_bool_exp = {
  count?: InputMaybe<action_aggregate_bool_exp_count>;
};

export type action_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<action_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<action_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "action" */
export type action_aggregate_fields = {
  avg?: Maybe<action_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<action_max_fields>;
  min?: Maybe<action_min_fields>;
  stddev?: Maybe<action_stddev_fields>;
  stddev_pop?: Maybe<action_stddev_pop_fields>;
  stddev_samp?: Maybe<action_stddev_samp_fields>;
  sum?: Maybe<action_sum_fields>;
  var_pop?: Maybe<action_var_pop_fields>;
  var_samp?: Maybe<action_var_samp_fields>;
  variance?: Maybe<action_variance_fields>;
};

/** aggregate fields of "action" */
export type action_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<action_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "action" */
export type action_aggregate_order_by = {
  avg?: InputMaybe<action_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<action_max_order_by>;
  min?: InputMaybe<action_min_order_by>;
  stddev?: InputMaybe<action_stddev_order_by>;
  stddev_pop?: InputMaybe<action_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<action_stddev_samp_order_by>;
  sum?: InputMaybe<action_sum_order_by>;
  var_pop?: InputMaybe<action_var_pop_order_by>;
  var_samp?: InputMaybe<action_var_samp_order_by>;
  variance?: InputMaybe<action_variance_order_by>;
};

/** input type for inserting array relation for remote table "action" */
export type action_arr_rel_insert_input = {
  data: Array<action_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<action_on_conflict>;
};

/** aggregate avg on columns */
export type action_avg_fields = {
  action_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "action" */
export type action_avg_order_by = {
  action_plan_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "action". All fields are combined with a logical 'AND'. */
export type action_bool_exp = {
  _and?: InputMaybe<Array<action_bool_exp>>;
  _not?: InputMaybe<action_bool_exp>;
  _or?: InputMaybe<Array<action_bool_exp>>;
  action_plan?: InputMaybe<action_plan_bool_exp>;
  action_plan_id?: InputMaybe<Int_comparison_exp>;
  building_models?: InputMaybe<building_model_bool_exp>;
  building_models_aggregate?: InputMaybe<building_model_aggregate_bool_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  implementation_from?: InputMaybe<timestamptz_comparison_exp>;
  implementation_to?: InputMaybe<timestamptz_comparison_exp>;
  order?: InputMaybe<Int_comparison_exp>;
  renovations?: InputMaybe<renovation_bool_exp>;
  renovations_aggregate?: InputMaybe<renovation_aggregate_bool_exp>;
  renovations_energy_consumer?: InputMaybe<renovation_energy_consumer_bool_exp>;
  renovations_energy_consumer_aggregate?: InputMaybe<renovation_energy_consumer_aggregate_bool_exp>;
  renovations_energy_distribution?: InputMaybe<renovation_energy_distribution_bool_exp>;
  renovations_energy_distribution_aggregate?: InputMaybe<renovation_energy_distribution_aggregate_bool_exp>;
  renovations_energy_producer?: InputMaybe<renovation_energy_producer_bool_exp>;
  renovations_energy_producer_aggregate?: InputMaybe<renovation_energy_producer_aggregate_bool_exp>;
  renovations_energy_routes?: InputMaybe<renovation_energy_system_route_bool_exp>;
  renovations_energy_routes_aggregate?: InputMaybe<renovation_energy_system_route_aggregate_bool_exp>;
  renovations_energy_storage?: InputMaybe<renovation_energy_storage_bool_exp>;
  renovations_energy_storage_aggregate?: InputMaybe<renovation_energy_storage_aggregate_bool_exp>;
  renovations_envelope?: InputMaybe<renovation_envelope_bool_exp>;
  renovations_envelope_aggregate?: InputMaybe<renovation_envelope_aggregate_bool_exp>;
  subsidies?: InputMaybe<action_subsidy_bool_exp>;
  subsidies_aggregate?: InputMaybe<action_subsidy_aggregate_bool_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

export { action_constraint };

/** input type for incrementing numeric columns in table "action" */
export type action_inc_input = {
  action_plan_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "action" */
export type action_insert_input = {
  action_plan?: InputMaybe<action_plan_obj_rel_insert_input>;
  action_plan_id?: InputMaybe<Scalars['Int']>;
  building_models?: InputMaybe<building_model_arr_rel_insert_input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  implementation_from?: InputMaybe<Scalars['timestamptz']>;
  implementation_to?: InputMaybe<Scalars['timestamptz']>;
  order?: InputMaybe<Scalars['Int']>;
  renovations?: InputMaybe<renovation_arr_rel_insert_input>;
  renovations_energy_consumer?: InputMaybe<renovation_energy_consumer_arr_rel_insert_input>;
  renovations_energy_distribution?: InputMaybe<renovation_energy_distribution_arr_rel_insert_input>;
  renovations_energy_producer?: InputMaybe<renovation_energy_producer_arr_rel_insert_input>;
  renovations_energy_routes?: InputMaybe<renovation_energy_system_route_arr_rel_insert_input>;
  renovations_energy_storage?: InputMaybe<renovation_energy_storage_arr_rel_insert_input>;
  renovations_envelope?: InputMaybe<renovation_envelope_arr_rel_insert_input>;
  subsidies?: InputMaybe<action_subsidy_arr_rel_insert_input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type action_max_fields = {
  action_plan_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  implementation_from?: Maybe<Scalars['timestamptz']>;
  implementation_to?: Maybe<Scalars['timestamptz']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "action" */
export type action_max_order_by = {
  action_plan_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  implementation_from?: InputMaybe<order_by>;
  implementation_to?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type action_min_fields = {
  action_plan_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  implementation_from?: Maybe<Scalars['timestamptz']>;
  implementation_to?: Maybe<Scalars['timestamptz']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "action" */
export type action_min_order_by = {
  action_plan_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  implementation_from?: InputMaybe<order_by>;
  implementation_to?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "action" */
export type action_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<action>;
};

/** input type for inserting object relation for remote table "action" */
export type action_obj_rel_insert_input = {
  data: action_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<action_on_conflict>;
};

/** on_conflict condition type for table "action" */
export type action_on_conflict = {
  constraint: action_constraint;
  update_columns?: Array<action_update_column>;
  where?: InputMaybe<action_bool_exp>;
};

/** Ordering options when selecting data from "action". */
export type action_order_by = {
  action_plan?: InputMaybe<action_plan_order_by>;
  action_plan_id?: InputMaybe<order_by>;
  building_models_aggregate?: InputMaybe<building_model_aggregate_order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  implementation_from?: InputMaybe<order_by>;
  implementation_to?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  renovations_aggregate?: InputMaybe<renovation_aggregate_order_by>;
  renovations_energy_consumer_aggregate?: InputMaybe<renovation_energy_consumer_aggregate_order_by>;
  renovations_energy_distribution_aggregate?: InputMaybe<renovation_energy_distribution_aggregate_order_by>;
  renovations_energy_producer_aggregate?: InputMaybe<renovation_energy_producer_aggregate_order_by>;
  renovations_energy_routes_aggregate?: InputMaybe<renovation_energy_system_route_aggregate_order_by>;
  renovations_energy_storage_aggregate?: InputMaybe<renovation_energy_storage_aggregate_order_by>;
  renovations_envelope_aggregate?: InputMaybe<renovation_envelope_aggregate_order_by>;
  subsidies_aggregate?: InputMaybe<action_subsidy_aggregate_order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: action */
export type action_pk_columns_input = {
  id: Scalars['Int'];
};

/** columns and relationships of "action_plan" */
export type action_plan = {
  /** An array relationship */
  actions: Array<action>;
  /** An aggregate relationship */
  actions_aggregate: action_aggregate;
  /** An object relationship */
  building: building;
  building_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  scenario: scenario;
  scenario_id: Scalars['Int'];
};

/** columns and relationships of "action_plan" */
export type action_planactionsArgs = {
  distinct_on?: InputMaybe<Array<action_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_order_by>>;
  where?: InputMaybe<action_bool_exp>;
};

/** columns and relationships of "action_plan" */
export type action_planactions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<action_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_order_by>>;
  where?: InputMaybe<action_bool_exp>;
};

/** aggregated selection of "action_plan" */
export type action_plan_aggregate = {
  aggregate?: Maybe<action_plan_aggregate_fields>;
  nodes: Array<action_plan>;
};

export type action_plan_aggregate_bool_exp = {
  count?: InputMaybe<action_plan_aggregate_bool_exp_count>;
};

export type action_plan_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<action_plan_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<action_plan_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "action_plan" */
export type action_plan_aggregate_fields = {
  avg?: Maybe<action_plan_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<action_plan_max_fields>;
  min?: Maybe<action_plan_min_fields>;
  stddev?: Maybe<action_plan_stddev_fields>;
  stddev_pop?: Maybe<action_plan_stddev_pop_fields>;
  stddev_samp?: Maybe<action_plan_stddev_samp_fields>;
  sum?: Maybe<action_plan_sum_fields>;
  var_pop?: Maybe<action_plan_var_pop_fields>;
  var_samp?: Maybe<action_plan_var_samp_fields>;
  variance?: Maybe<action_plan_variance_fields>;
};

/** aggregate fields of "action_plan" */
export type action_plan_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<action_plan_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "action_plan" */
export type action_plan_aggregate_order_by = {
  avg?: InputMaybe<action_plan_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<action_plan_max_order_by>;
  min?: InputMaybe<action_plan_min_order_by>;
  stddev?: InputMaybe<action_plan_stddev_order_by>;
  stddev_pop?: InputMaybe<action_plan_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<action_plan_stddev_samp_order_by>;
  sum?: InputMaybe<action_plan_sum_order_by>;
  var_pop?: InputMaybe<action_plan_var_pop_order_by>;
  var_samp?: InputMaybe<action_plan_var_samp_order_by>;
  variance?: InputMaybe<action_plan_variance_order_by>;
};

/** input type for inserting array relation for remote table "action_plan" */
export type action_plan_arr_rel_insert_input = {
  data: Array<action_plan_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<action_plan_on_conflict>;
};

/** aggregate avg on columns */
export type action_plan_avg_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  scenario_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "action_plan" */
export type action_plan_avg_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  scenario_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "action_plan". All fields are combined with a logical 'AND'. */
export type action_plan_bool_exp = {
  _and?: InputMaybe<Array<action_plan_bool_exp>>;
  _not?: InputMaybe<action_plan_bool_exp>;
  _or?: InputMaybe<Array<action_plan_bool_exp>>;
  actions?: InputMaybe<action_bool_exp>;
  actions_aggregate?: InputMaybe<action_aggregate_bool_exp>;
  building?: InputMaybe<building_bool_exp>;
  building_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  scenario?: InputMaybe<scenario_bool_exp>;
  scenario_id?: InputMaybe<Int_comparison_exp>;
};

export { action_plan_constraint };

/** input type for incrementing numeric columns in table "action_plan" */
export type action_plan_inc_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  scenario_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "action_plan" */
export type action_plan_insert_input = {
  actions?: InputMaybe<action_arr_rel_insert_input>;
  building?: InputMaybe<building_obj_rel_insert_input>;
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  scenario?: InputMaybe<scenario_obj_rel_insert_input>;
  scenario_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type action_plan_max_fields = {
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  scenario_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "action_plan" */
export type action_plan_max_order_by = {
  building_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  scenario_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type action_plan_min_fields = {
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  scenario_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "action_plan" */
export type action_plan_min_order_by = {
  building_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  scenario_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "action_plan" */
export type action_plan_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<action_plan>;
};

/** input type for inserting object relation for remote table "action_plan" */
export type action_plan_obj_rel_insert_input = {
  data: action_plan_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<action_plan_on_conflict>;
};

/** on_conflict condition type for table "action_plan" */
export type action_plan_on_conflict = {
  constraint: action_plan_constraint;
  update_columns?: Array<action_plan_update_column>;
  where?: InputMaybe<action_plan_bool_exp>;
};

/** Ordering options when selecting data from "action_plan". */
export type action_plan_order_by = {
  actions_aggregate?: InputMaybe<action_aggregate_order_by>;
  building?: InputMaybe<building_order_by>;
  building_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  scenario?: InputMaybe<scenario_order_by>;
  scenario_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: action_plan */
export type action_plan_pk_columns_input = {
  id: Scalars['Int'];
};

export { action_plan_select_column };

/** input type for updating data in table "action_plan" */
export type action_plan_set_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  scenario_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type action_plan_stddev_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  scenario_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "action_plan" */
export type action_plan_stddev_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  scenario_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type action_plan_stddev_pop_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  scenario_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "action_plan" */
export type action_plan_stddev_pop_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  scenario_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type action_plan_stddev_samp_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  scenario_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "action_plan" */
export type action_plan_stddev_samp_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  scenario_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "action_plan" */
export type action_plan_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: action_plan_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type action_plan_stream_cursor_value_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  scenario_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type action_plan_sum_fields = {
  building_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  scenario_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "action_plan" */
export type action_plan_sum_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  scenario_id?: InputMaybe<order_by>;
};

export { action_plan_update_column };

export type action_plan_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<action_plan_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<action_plan_set_input>;
  /** filter the rows which have to be updated */
  where: action_plan_bool_exp;
};

/** aggregate var_pop on columns */
export type action_plan_var_pop_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  scenario_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "action_plan" */
export type action_plan_var_pop_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  scenario_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type action_plan_var_samp_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  scenario_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "action_plan" */
export type action_plan_var_samp_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  scenario_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type action_plan_variance_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  scenario_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "action_plan" */
export type action_plan_variance_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  scenario_id?: InputMaybe<order_by>;
};

export { action_select_column };

/** input type for updating data in table "action" */
export type action_set_input = {
  action_plan_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  implementation_from?: InputMaybe<Scalars['timestamptz']>;
  implementation_to?: InputMaybe<Scalars['timestamptz']>;
  order?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type action_stddev_fields = {
  action_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "action" */
export type action_stddev_order_by = {
  action_plan_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type action_stddev_pop_fields = {
  action_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "action" */
export type action_stddev_pop_order_by = {
  action_plan_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type action_stddev_samp_fields = {
  action_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "action" */
export type action_stddev_samp_order_by = {
  action_plan_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "action" */
export type action_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: action_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type action_stream_cursor_value_input = {
  action_plan_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  implementation_from?: InputMaybe<Scalars['timestamptz']>;
  implementation_to?: InputMaybe<Scalars['timestamptz']>;
  order?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** applied subsidies to an action of a building */
export type action_subsidy = {
  /** An object relationship */
  action: action;
  action_id: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  subsidy: subsidy;
  subsidy_id: Scalars['Int'];
  type: action_subsidy_value_type_enum;
  value: Scalars['numeric'];
};

/** aggregated selection of "action_subsidy" */
export type action_subsidy_aggregate = {
  aggregate?: Maybe<action_subsidy_aggregate_fields>;
  nodes: Array<action_subsidy>;
};

export type action_subsidy_aggregate_bool_exp = {
  count?: InputMaybe<action_subsidy_aggregate_bool_exp_count>;
};

export type action_subsidy_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<action_subsidy_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<action_subsidy_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "action_subsidy" */
export type action_subsidy_aggregate_fields = {
  avg?: Maybe<action_subsidy_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<action_subsidy_max_fields>;
  min?: Maybe<action_subsidy_min_fields>;
  stddev?: Maybe<action_subsidy_stddev_fields>;
  stddev_pop?: Maybe<action_subsidy_stddev_pop_fields>;
  stddev_samp?: Maybe<action_subsidy_stddev_samp_fields>;
  sum?: Maybe<action_subsidy_sum_fields>;
  var_pop?: Maybe<action_subsidy_var_pop_fields>;
  var_samp?: Maybe<action_subsidy_var_samp_fields>;
  variance?: Maybe<action_subsidy_variance_fields>;
};

/** aggregate fields of "action_subsidy" */
export type action_subsidy_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<action_subsidy_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "action_subsidy" */
export type action_subsidy_aggregate_order_by = {
  avg?: InputMaybe<action_subsidy_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<action_subsidy_max_order_by>;
  min?: InputMaybe<action_subsidy_min_order_by>;
  stddev?: InputMaybe<action_subsidy_stddev_order_by>;
  stddev_pop?: InputMaybe<action_subsidy_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<action_subsidy_stddev_samp_order_by>;
  sum?: InputMaybe<action_subsidy_sum_order_by>;
  var_pop?: InputMaybe<action_subsidy_var_pop_order_by>;
  var_samp?: InputMaybe<action_subsidy_var_samp_order_by>;
  variance?: InputMaybe<action_subsidy_variance_order_by>;
};

/** input type for inserting array relation for remote table "action_subsidy" */
export type action_subsidy_arr_rel_insert_input = {
  data: Array<action_subsidy_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<action_subsidy_on_conflict>;
};

/** aggregate avg on columns */
export type action_subsidy_avg_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  subsidy_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "action_subsidy" */
export type action_subsidy_avg_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "action_subsidy". All fields are combined with a logical 'AND'. */
export type action_subsidy_bool_exp = {
  _and?: InputMaybe<Array<action_subsidy_bool_exp>>;
  _not?: InputMaybe<action_subsidy_bool_exp>;
  _or?: InputMaybe<Array<action_subsidy_bool_exp>>;
  action?: InputMaybe<action_bool_exp>;
  action_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  subsidy?: InputMaybe<subsidy_bool_exp>;
  subsidy_id?: InputMaybe<Int_comparison_exp>;
  type?: InputMaybe<action_subsidy_value_type_enum_comparison_exp>;
  value?: InputMaybe<numeric_comparison_exp>;
};

export { action_subsidy_constraint };

/** input type for incrementing numeric columns in table "action_subsidy" */
export type action_subsidy_inc_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  subsidy_id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "action_subsidy" */
export type action_subsidy_insert_input = {
  action?: InputMaybe<action_obj_rel_insert_input>;
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  subsidy?: InputMaybe<subsidy_obj_rel_insert_input>;
  subsidy_id?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<action_subsidy_value_type_enum>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type action_subsidy_max_fields = {
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  subsidy_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "action_subsidy" */
export type action_subsidy_max_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type action_subsidy_min_fields = {
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  subsidy_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "action_subsidy" */
export type action_subsidy_min_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** response of any mutation on the table "action_subsidy" */
export type action_subsidy_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<action_subsidy>;
};

/** on_conflict condition type for table "action_subsidy" */
export type action_subsidy_on_conflict = {
  constraint: action_subsidy_constraint;
  update_columns?: Array<action_subsidy_update_column>;
  where?: InputMaybe<action_subsidy_bool_exp>;
};

/** Ordering options when selecting data from "action_subsidy". */
export type action_subsidy_order_by = {
  action?: InputMaybe<action_order_by>;
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  subsidy?: InputMaybe<subsidy_order_by>;
  subsidy_id?: InputMaybe<order_by>;
  type?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: action_subsidy */
export type action_subsidy_pk_columns_input = {
  id: Scalars['Int'];
};

export { action_subsidy_select_column };

/** input type for updating data in table "action_subsidy" */
export type action_subsidy_set_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  subsidy_id?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<action_subsidy_value_type_enum>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type action_subsidy_stddev_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  subsidy_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "action_subsidy" */
export type action_subsidy_stddev_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type action_subsidy_stddev_pop_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  subsidy_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "action_subsidy" */
export type action_subsidy_stddev_pop_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type action_subsidy_stddev_samp_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  subsidy_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "action_subsidy" */
export type action_subsidy_stddev_samp_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "action_subsidy" */
export type action_subsidy_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: action_subsidy_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type action_subsidy_stream_cursor_value_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  subsidy_id?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<action_subsidy_value_type_enum>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type action_subsidy_sum_fields = {
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  subsidy_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "action_subsidy" */
export type action_subsidy_sum_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

export { action_subsidy_update_column };

export type action_subsidy_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<action_subsidy_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<action_subsidy_set_input>;
  /** filter the rows which have to be updated */
  where: action_subsidy_bool_exp;
};

/** defines the type of value that is used for an action_subsidy */
export type action_subsidy_value_type = {
  id: Scalars['String'];
};

/** aggregated selection of "action_subsidy_value_type" */
export type action_subsidy_value_type_aggregate = {
  aggregate?: Maybe<action_subsidy_value_type_aggregate_fields>;
  nodes: Array<action_subsidy_value_type>;
};

/** aggregate fields of "action_subsidy_value_type" */
export type action_subsidy_value_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<action_subsidy_value_type_max_fields>;
  min?: Maybe<action_subsidy_value_type_min_fields>;
};

/** aggregate fields of "action_subsidy_value_type" */
export type action_subsidy_value_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<action_subsidy_value_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "action_subsidy_value_type". All fields are combined with a logical 'AND'. */
export type action_subsidy_value_type_bool_exp = {
  _and?: InputMaybe<Array<action_subsidy_value_type_bool_exp>>;
  _not?: InputMaybe<action_subsidy_value_type_bool_exp>;
  _or?: InputMaybe<Array<action_subsidy_value_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { action_subsidy_value_type_constraint };

export { action_subsidy_value_type_enum };

/** Boolean expression to compare columns of type "action_subsidy_value_type_enum". All fields are combined with logical 'AND'. */
export type action_subsidy_value_type_enum_comparison_exp = {
  _eq?: InputMaybe<action_subsidy_value_type_enum>;
  _in?: InputMaybe<Array<action_subsidy_value_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<action_subsidy_value_type_enum>;
  _nin?: InputMaybe<Array<action_subsidy_value_type_enum>>;
};

/** input type for inserting data into table "action_subsidy_value_type" */
export type action_subsidy_value_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type action_subsidy_value_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type action_subsidy_value_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "action_subsidy_value_type" */
export type action_subsidy_value_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<action_subsidy_value_type>;
};

/** on_conflict condition type for table "action_subsidy_value_type" */
export type action_subsidy_value_type_on_conflict = {
  constraint: action_subsidy_value_type_constraint;
  update_columns?: Array<action_subsidy_value_type_update_column>;
  where?: InputMaybe<action_subsidy_value_type_bool_exp>;
};

/** Ordering options when selecting data from "action_subsidy_value_type". */
export type action_subsidy_value_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: action_subsidy_value_type */
export type action_subsidy_value_type_pk_columns_input = {
  id: Scalars['String'];
};

export { action_subsidy_value_type_select_column };

/** input type for updating data in table "action_subsidy_value_type" */
export type action_subsidy_value_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "action_subsidy_value_type" */
export type action_subsidy_value_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: action_subsidy_value_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type action_subsidy_value_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { action_subsidy_value_type_update_column };

export type action_subsidy_value_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<action_subsidy_value_type_set_input>;
  /** filter the rows which have to be updated */
  where: action_subsidy_value_type_bool_exp;
};

/** aggregate var_pop on columns */
export type action_subsidy_var_pop_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  subsidy_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "action_subsidy" */
export type action_subsidy_var_pop_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type action_subsidy_var_samp_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  subsidy_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "action_subsidy" */
export type action_subsidy_var_samp_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type action_subsidy_variance_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  subsidy_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "action_subsidy" */
export type action_subsidy_variance_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate sum on columns */
export type action_sum_fields = {
  action_plan_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "action" */
export type action_sum_order_by = {
  action_plan_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

export { action_update_column };

export type action_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<action_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<action_set_input>;
  /** filter the rows which have to be updated */
  where: action_bool_exp;
};

/** aggregate var_pop on columns */
export type action_var_pop_fields = {
  action_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "action" */
export type action_var_pop_order_by = {
  action_plan_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type action_var_samp_fields = {
  action_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "action" */
export type action_var_samp_order_by = {
  action_plan_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type action_variance_fields = {
  action_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "action" */
export type action_variance_order_by = {
  action_plan_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** columns and relationships of "address" */
export type address = {
  appartment?: Maybe<Scalars['String']>;
  /** An array relationship */
  buildings: Array<building>;
  /** An aggregate relationship */
  buildings_aggregate: building_aggregate;
  city: Scalars['String'];
  climate_region_id?: Maybe<climate_region_enum>;
  country_id: country_enum;
  country_state_id: country_state_enum;
  house_number: Scalars['String'];
  id: Scalars['Int'];
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  mapbox_bearing?: Maybe<Scalars['float8']>;
  mapbox_building_ids: Array<Scalars['String']>;
  mapbox_pitch?: Maybe<Scalars['float8']>;
  mapbox_zoom?: Maybe<Scalars['float8']>;
  postal_code: Scalars['String'];
  street: Scalars['String'];
};

/** columns and relationships of "address" */
export type addressbuildingsArgs = {
  distinct_on?: InputMaybe<Array<building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<building_order_by>>;
  where?: InputMaybe<building_bool_exp>;
};

/** columns and relationships of "address" */
export type addressbuildings_aggregateArgs = {
  distinct_on?: InputMaybe<Array<building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<building_order_by>>;
  where?: InputMaybe<building_bool_exp>;
};

/** aggregated selection of "address" */
export type address_aggregate = {
  aggregate?: Maybe<address_aggregate_fields>;
  nodes: Array<address>;
};

/** aggregate fields of "address" */
export type address_aggregate_fields = {
  avg?: Maybe<address_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<address_max_fields>;
  min?: Maybe<address_min_fields>;
  stddev?: Maybe<address_stddev_fields>;
  stddev_pop?: Maybe<address_stddev_pop_fields>;
  stddev_samp?: Maybe<address_stddev_samp_fields>;
  sum?: Maybe<address_sum_fields>;
  var_pop?: Maybe<address_var_pop_fields>;
  var_samp?: Maybe<address_var_samp_fields>;
  variance?: Maybe<address_variance_fields>;
};

/** aggregate fields of "address" */
export type address_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<address_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type address_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  mapbox_bearing?: Maybe<Scalars['Float']>;
  mapbox_pitch?: Maybe<Scalars['Float']>;
  mapbox_zoom?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "address". All fields are combined with a logical 'AND'. */
export type address_bool_exp = {
  _and?: InputMaybe<Array<address_bool_exp>>;
  _not?: InputMaybe<address_bool_exp>;
  _or?: InputMaybe<Array<address_bool_exp>>;
  appartment?: InputMaybe<String_comparison_exp>;
  buildings?: InputMaybe<building_bool_exp>;
  buildings_aggregate?: InputMaybe<building_aggregate_bool_exp>;
  city?: InputMaybe<String_comparison_exp>;
  climate_region_id?: InputMaybe<climate_region_enum_comparison_exp>;
  country_id?: InputMaybe<country_enum_comparison_exp>;
  country_state_id?: InputMaybe<country_state_enum_comparison_exp>;
  house_number?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  latitude?: InputMaybe<numeric_comparison_exp>;
  longitude?: InputMaybe<numeric_comparison_exp>;
  mapbox_bearing?: InputMaybe<float8_comparison_exp>;
  mapbox_building_ids?: InputMaybe<String_array_comparison_exp>;
  mapbox_pitch?: InputMaybe<float8_comparison_exp>;
  mapbox_zoom?: InputMaybe<float8_comparison_exp>;
  postal_code?: InputMaybe<String_comparison_exp>;
  street?: InputMaybe<String_comparison_exp>;
};

export { address_constraint };

/** input type for incrementing numeric columns in table "address" */
export type address_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['numeric']>;
  longitude?: InputMaybe<Scalars['numeric']>;
  mapbox_bearing?: InputMaybe<Scalars['float8']>;
  mapbox_pitch?: InputMaybe<Scalars['float8']>;
  mapbox_zoom?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "address" */
export type address_insert_input = {
  appartment?: InputMaybe<Scalars['String']>;
  buildings?: InputMaybe<building_arr_rel_insert_input>;
  city?: InputMaybe<Scalars['String']>;
  climate_region_id?: InputMaybe<climate_region_enum>;
  country_id?: InputMaybe<country_enum>;
  country_state_id?: InputMaybe<country_state_enum>;
  house_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['numeric']>;
  longitude?: InputMaybe<Scalars['numeric']>;
  mapbox_bearing?: InputMaybe<Scalars['float8']>;
  mapbox_building_ids?: InputMaybe<Array<Scalars['String']>>;
  mapbox_pitch?: InputMaybe<Scalars['float8']>;
  mapbox_zoom?: InputMaybe<Scalars['float8']>;
  postal_code?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type address_max_fields = {
  appartment?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  house_number?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  mapbox_bearing?: Maybe<Scalars['float8']>;
  mapbox_building_ids?: Maybe<Array<Scalars['String']>>;
  mapbox_pitch?: Maybe<Scalars['float8']>;
  mapbox_zoom?: Maybe<Scalars['float8']>;
  postal_code?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type address_min_fields = {
  appartment?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  house_number?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  mapbox_bearing?: Maybe<Scalars['float8']>;
  mapbox_building_ids?: Maybe<Array<Scalars['String']>>;
  mapbox_pitch?: Maybe<Scalars['float8']>;
  mapbox_zoom?: Maybe<Scalars['float8']>;
  postal_code?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "address" */
export type address_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<address>;
};

/** input type for inserting object relation for remote table "address" */
export type address_obj_rel_insert_input = {
  data: address_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<address_on_conflict>;
};

/** on_conflict condition type for table "address" */
export type address_on_conflict = {
  constraint: address_constraint;
  update_columns?: Array<address_update_column>;
  where?: InputMaybe<address_bool_exp>;
};

/** Ordering options when selecting data from "address". */
export type address_order_by = {
  appartment?: InputMaybe<order_by>;
  buildings_aggregate?: InputMaybe<building_aggregate_order_by>;
  city?: InputMaybe<order_by>;
  climate_region_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  country_state_id?: InputMaybe<order_by>;
  house_number?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  latitude?: InputMaybe<order_by>;
  longitude?: InputMaybe<order_by>;
  mapbox_bearing?: InputMaybe<order_by>;
  mapbox_building_ids?: InputMaybe<order_by>;
  mapbox_pitch?: InputMaybe<order_by>;
  mapbox_zoom?: InputMaybe<order_by>;
  postal_code?: InputMaybe<order_by>;
  street?: InputMaybe<order_by>;
};

/** primary key columns input for table: address */
export type address_pk_columns_input = {
  id: Scalars['Int'];
};

export { address_select_column };

/** input type for updating data in table "address" */
export type address_set_input = {
  appartment?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  climate_region_id?: InputMaybe<climate_region_enum>;
  country_id?: InputMaybe<country_enum>;
  country_state_id?: InputMaybe<country_state_enum>;
  house_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['numeric']>;
  longitude?: InputMaybe<Scalars['numeric']>;
  mapbox_bearing?: InputMaybe<Scalars['float8']>;
  mapbox_building_ids?: InputMaybe<Array<Scalars['String']>>;
  mapbox_pitch?: InputMaybe<Scalars['float8']>;
  mapbox_zoom?: InputMaybe<Scalars['float8']>;
  postal_code?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type address_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  mapbox_bearing?: Maybe<Scalars['Float']>;
  mapbox_pitch?: Maybe<Scalars['Float']>;
  mapbox_zoom?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type address_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  mapbox_bearing?: Maybe<Scalars['Float']>;
  mapbox_pitch?: Maybe<Scalars['Float']>;
  mapbox_zoom?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type address_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  mapbox_bearing?: Maybe<Scalars['Float']>;
  mapbox_pitch?: Maybe<Scalars['Float']>;
  mapbox_zoom?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "address" */
export type address_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: address_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type address_stream_cursor_value_input = {
  appartment?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  climate_region_id?: InputMaybe<climate_region_enum>;
  country_id?: InputMaybe<country_enum>;
  country_state_id?: InputMaybe<country_state_enum>;
  house_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['numeric']>;
  longitude?: InputMaybe<Scalars['numeric']>;
  mapbox_bearing?: InputMaybe<Scalars['float8']>;
  mapbox_building_ids?: InputMaybe<Array<Scalars['String']>>;
  mapbox_pitch?: InputMaybe<Scalars['float8']>;
  mapbox_zoom?: InputMaybe<Scalars['float8']>;
  postal_code?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type address_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  mapbox_bearing?: Maybe<Scalars['float8']>;
  mapbox_pitch?: Maybe<Scalars['float8']>;
  mapbox_zoom?: Maybe<Scalars['float8']>;
};

export { address_update_column };

export type address_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<address_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<address_set_input>;
  /** filter the rows which have to be updated */
  where: address_bool_exp;
};

/** aggregate var_pop on columns */
export type address_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  mapbox_bearing?: Maybe<Scalars['Float']>;
  mapbox_pitch?: Maybe<Scalars['Float']>;
  mapbox_zoom?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type address_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  mapbox_bearing?: Maybe<Scalars['Float']>;
  mapbox_pitch?: Maybe<Scalars['Float']>;
  mapbox_zoom?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type address_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  mapbox_bearing?: Maybe<Scalars['Float']>;
  mapbox_pitch?: Maybe<Scalars['Float']>;
  mapbox_zoom?: Maybe<Scalars['Float']>;
};

/** Table to store auth0 ClientID, orgId and the permission of this auth0's client */
export type api_user = {
  auth0_client_id: Scalars['String'];
  id: Scalars['Int'];
  /** An object relationship */
  org: org;
  org_id: Scalars['Int'];
  role_id: role_enum;
};

/** aggregated selection of "api_user" */
export type api_user_aggregate = {
  aggregate?: Maybe<api_user_aggregate_fields>;
  nodes: Array<api_user>;
};

/** aggregate fields of "api_user" */
export type api_user_aggregate_fields = {
  avg?: Maybe<api_user_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<api_user_max_fields>;
  min?: Maybe<api_user_min_fields>;
  stddev?: Maybe<api_user_stddev_fields>;
  stddev_pop?: Maybe<api_user_stddev_pop_fields>;
  stddev_samp?: Maybe<api_user_stddev_samp_fields>;
  sum?: Maybe<api_user_sum_fields>;
  var_pop?: Maybe<api_user_var_pop_fields>;
  var_samp?: Maybe<api_user_var_samp_fields>;
  variance?: Maybe<api_user_variance_fields>;
};

/** aggregate fields of "api_user" */
export type api_user_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<api_user_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type api_user_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "api_user". All fields are combined with a logical 'AND'. */
export type api_user_bool_exp = {
  _and?: InputMaybe<Array<api_user_bool_exp>>;
  _not?: InputMaybe<api_user_bool_exp>;
  _or?: InputMaybe<Array<api_user_bool_exp>>;
  auth0_client_id?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  org?: InputMaybe<org_bool_exp>;
  org_id?: InputMaybe<Int_comparison_exp>;
  role_id?: InputMaybe<role_enum_comparison_exp>;
};

export { api_user_constraint };

/** input type for incrementing numeric columns in table "api_user" */
export type api_user_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "api_user" */
export type api_user_insert_input = {
  auth0_client_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  org?: InputMaybe<org_obj_rel_insert_input>;
  org_id?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<role_enum>;
};

/** aggregate max on columns */
export type api_user_max_fields = {
  auth0_client_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type api_user_min_fields = {
  auth0_client_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "api_user" */
export type api_user_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<api_user>;
};

/** on_conflict condition type for table "api_user" */
export type api_user_on_conflict = {
  constraint: api_user_constraint;
  update_columns?: Array<api_user_update_column>;
  where?: InputMaybe<api_user_bool_exp>;
};

/** Ordering options when selecting data from "api_user". */
export type api_user_order_by = {
  auth0_client_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org?: InputMaybe<org_order_by>;
  org_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: api_user */
export type api_user_pk_columns_input = {
  id: Scalars['Int'];
};

export { api_user_select_column };

/** input type for updating data in table "api_user" */
export type api_user_set_input = {
  auth0_client_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<role_enum>;
};

/** aggregate stddev on columns */
export type api_user_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type api_user_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type api_user_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "api_user" */
export type api_user_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: api_user_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type api_user_stream_cursor_value_input = {
  auth0_client_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<role_enum>;
};

/** aggregate sum on columns */
export type api_user_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
};

export { api_user_update_column };

export type api_user_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<api_user_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<api_user_set_input>;
  /** filter the rows which have to be updated */
  where: api_user_bool_exp;
};

/** aggregate var_pop on columns */
export type api_user_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type api_user_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type api_user_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "area" */
export type area = {
  area_system_id: area_system_type_enum;
  area_type_id: area_type_enum;
  /** An object relationship */
  building: building;
  building_id: Scalars['Int'];
  can_be_rented: Scalars['Boolean'];
  class_of_use_id: sub_building_class_enum;
  data_source_id?: Maybe<data_source_type_enum>;
  description: Scalars['String'];
  id: Scalars['Int'];
  is_rented: Scalars['Boolean'];
  name: Scalars['String'];
  parent_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  sub_building?: Maybe<sub_building>;
  sub_building_id?: Maybe<Scalars['Int']>;
  type_of_use_id: type_of_use_enum;
  value: Scalars['float8'];
};

/** aggregated selection of "area" */
export type area_aggregate = {
  aggregate?: Maybe<area_aggregate_fields>;
  nodes: Array<area>;
};

export type area_aggregate_bool_exp = {
  avg?: InputMaybe<area_aggregate_bool_exp_avg>;
  bool_and?: InputMaybe<area_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<area_aggregate_bool_exp_bool_or>;
  corr?: InputMaybe<area_aggregate_bool_exp_corr>;
  count?: InputMaybe<area_aggregate_bool_exp_count>;
  covar_samp?: InputMaybe<area_aggregate_bool_exp_covar_samp>;
  max?: InputMaybe<area_aggregate_bool_exp_max>;
  min?: InputMaybe<area_aggregate_bool_exp_min>;
  stddev_samp?: InputMaybe<area_aggregate_bool_exp_stddev_samp>;
  sum?: InputMaybe<area_aggregate_bool_exp_sum>;
  var_samp?: InputMaybe<area_aggregate_bool_exp_var_samp>;
};

export type area_aggregate_bool_exp_avg = {
  arguments: area_select_column_area_aggregate_bool_exp_avg_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<area_bool_exp>;
  predicate: float8_comparison_exp;
};

export type area_aggregate_bool_exp_bool_and = {
  arguments: area_select_column_area_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<area_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type area_aggregate_bool_exp_bool_or = {
  arguments: area_select_column_area_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<area_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type area_aggregate_bool_exp_corr = {
  arguments: area_aggregate_bool_exp_corr_arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<area_bool_exp>;
  predicate: float8_comparison_exp;
};

export type area_aggregate_bool_exp_corr_arguments = {
  X: area_select_column_area_aggregate_bool_exp_corr_arguments_columns;
  Y: area_select_column_area_aggregate_bool_exp_corr_arguments_columns;
};

export type area_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<area_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<area_bool_exp>;
  predicate: Int_comparison_exp;
};

export type area_aggregate_bool_exp_covar_samp = {
  arguments: area_aggregate_bool_exp_covar_samp_arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<area_bool_exp>;
  predicate: float8_comparison_exp;
};

export type area_aggregate_bool_exp_covar_samp_arguments = {
  X: area_select_column_area_aggregate_bool_exp_covar_samp_arguments_columns;
  Y: area_select_column_area_aggregate_bool_exp_covar_samp_arguments_columns;
};

export type area_aggregate_bool_exp_max = {
  arguments: area_select_column_area_aggregate_bool_exp_max_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<area_bool_exp>;
  predicate: float8_comparison_exp;
};

export type area_aggregate_bool_exp_min = {
  arguments: area_select_column_area_aggregate_bool_exp_min_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<area_bool_exp>;
  predicate: float8_comparison_exp;
};

export type area_aggregate_bool_exp_stddev_samp = {
  arguments: area_select_column_area_aggregate_bool_exp_stddev_samp_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<area_bool_exp>;
  predicate: float8_comparison_exp;
};

export type area_aggregate_bool_exp_sum = {
  arguments: area_select_column_area_aggregate_bool_exp_sum_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<area_bool_exp>;
  predicate: float8_comparison_exp;
};

export type area_aggregate_bool_exp_var_samp = {
  arguments: area_select_column_area_aggregate_bool_exp_var_samp_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<area_bool_exp>;
  predicate: float8_comparison_exp;
};

/** aggregate fields of "area" */
export type area_aggregate_fields = {
  avg?: Maybe<area_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<area_max_fields>;
  min?: Maybe<area_min_fields>;
  stddev?: Maybe<area_stddev_fields>;
  stddev_pop?: Maybe<area_stddev_pop_fields>;
  stddev_samp?: Maybe<area_stddev_samp_fields>;
  sum?: Maybe<area_sum_fields>;
  var_pop?: Maybe<area_var_pop_fields>;
  var_samp?: Maybe<area_var_samp_fields>;
  variance?: Maybe<area_variance_fields>;
};

/** aggregate fields of "area" */
export type area_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<area_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "area" */
export type area_aggregate_order_by = {
  avg?: InputMaybe<area_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<area_max_order_by>;
  min?: InputMaybe<area_min_order_by>;
  stddev?: InputMaybe<area_stddev_order_by>;
  stddev_pop?: InputMaybe<area_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<area_stddev_samp_order_by>;
  sum?: InputMaybe<area_sum_order_by>;
  var_pop?: InputMaybe<area_var_pop_order_by>;
  var_samp?: InputMaybe<area_var_samp_order_by>;
  variance?: InputMaybe<area_variance_order_by>;
};

/** input type for inserting array relation for remote table "area" */
export type area_arr_rel_insert_input = {
  data: Array<area_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<area_on_conflict>;
};

/** aggregate avg on columns */
export type area_avg_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "area" */
export type area_avg_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  parent_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "area". All fields are combined with a logical 'AND'. */
export type area_bool_exp = {
  _and?: InputMaybe<Array<area_bool_exp>>;
  _not?: InputMaybe<area_bool_exp>;
  _or?: InputMaybe<Array<area_bool_exp>>;
  area_system_id?: InputMaybe<area_system_type_enum_comparison_exp>;
  area_type_id?: InputMaybe<area_type_enum_comparison_exp>;
  building?: InputMaybe<building_bool_exp>;
  building_id?: InputMaybe<Int_comparison_exp>;
  can_be_rented?: InputMaybe<Boolean_comparison_exp>;
  class_of_use_id?: InputMaybe<sub_building_class_enum_comparison_exp>;
  data_source_id?: InputMaybe<data_source_type_enum_comparison_exp>;
  description?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  is_rented?: InputMaybe<Boolean_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  parent_id?: InputMaybe<Int_comparison_exp>;
  sub_building?: InputMaybe<sub_building_bool_exp>;
  sub_building_id?: InputMaybe<Int_comparison_exp>;
  type_of_use_id?: InputMaybe<type_of_use_enum_comparison_exp>;
  value?: InputMaybe<float8_comparison_exp>;
};

export { area_constraint };

/** input type for incrementing numeric columns in table "area" */
export type area_inc_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  parent_id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "area" */
export type area_insert_input = {
  area_system_id?: InputMaybe<area_system_type_enum>;
  area_type_id?: InputMaybe<area_type_enum>;
  building?: InputMaybe<building_obj_rel_insert_input>;
  building_id?: InputMaybe<Scalars['Int']>;
  can_be_rented?: InputMaybe<Scalars['Boolean']>;
  class_of_use_id?: InputMaybe<sub_building_class_enum>;
  data_source_id?: InputMaybe<data_source_type_enum>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_rented?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Scalars['Int']>;
  sub_building?: InputMaybe<sub_building_obj_rel_insert_input>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  type_of_use_id?: InputMaybe<type_of_use_enum>;
  value?: InputMaybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type area_max_fields = {
  building_id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "area" */
export type area_max_order_by = {
  building_id?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  parent_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type area_min_fields = {
  building_id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "area" */
export type area_min_order_by = {
  building_id?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  parent_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** response of any mutation on the table "area" */
export type area_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<area>;
};

/** input type for inserting object relation for remote table "area" */
export type area_obj_rel_insert_input = {
  data: area_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<area_on_conflict>;
};

/** on_conflict condition type for table "area" */
export type area_on_conflict = {
  constraint: area_constraint;
  update_columns?: Array<area_update_column>;
  where?: InputMaybe<area_bool_exp>;
};

/** Ordering options when selecting data from "area". */
export type area_order_by = {
  area_system_id?: InputMaybe<order_by>;
  area_type_id?: InputMaybe<order_by>;
  building?: InputMaybe<building_order_by>;
  building_id?: InputMaybe<order_by>;
  can_be_rented?: InputMaybe<order_by>;
  class_of_use_id?: InputMaybe<order_by>;
  data_source_id?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  is_rented?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  parent_id?: InputMaybe<order_by>;
  sub_building?: InputMaybe<sub_building_order_by>;
  sub_building_id?: InputMaybe<order_by>;
  type_of_use_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: area */
export type area_pk_columns_input = {
  id: Scalars['Int'];
};

export { area_select_column };

export { area_select_column_area_aggregate_bool_exp_avg_arguments_columns };

export { area_select_column_area_aggregate_bool_exp_bool_and_arguments_columns };

export { area_select_column_area_aggregate_bool_exp_bool_or_arguments_columns };

export { area_select_column_area_aggregate_bool_exp_corr_arguments_columns };

export { area_select_column_area_aggregate_bool_exp_covar_samp_arguments_columns };

export { area_select_column_area_aggregate_bool_exp_max_arguments_columns };

export { area_select_column_area_aggregate_bool_exp_min_arguments_columns };

export { area_select_column_area_aggregate_bool_exp_stddev_samp_arguments_columns };

export { area_select_column_area_aggregate_bool_exp_sum_arguments_columns };

export { area_select_column_area_aggregate_bool_exp_var_samp_arguments_columns };

/** input type for updating data in table "area" */
export type area_set_input = {
  area_system_id?: InputMaybe<area_system_type_enum>;
  area_type_id?: InputMaybe<area_type_enum>;
  building_id?: InputMaybe<Scalars['Int']>;
  can_be_rented?: InputMaybe<Scalars['Boolean']>;
  class_of_use_id?: InputMaybe<sub_building_class_enum>;
  data_source_id?: InputMaybe<data_source_type_enum>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_rented?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  type_of_use_id?: InputMaybe<type_of_use_enum>;
  value?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type area_stddev_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "area" */
export type area_stddev_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  parent_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type area_stddev_pop_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "area" */
export type area_stddev_pop_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  parent_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type area_stddev_samp_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "area" */
export type area_stddev_samp_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  parent_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "area" */
export type area_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: area_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type area_stream_cursor_value_input = {
  area_system_id?: InputMaybe<area_system_type_enum>;
  area_type_id?: InputMaybe<area_type_enum>;
  building_id?: InputMaybe<Scalars['Int']>;
  can_be_rented?: InputMaybe<Scalars['Boolean']>;
  class_of_use_id?: InputMaybe<sub_building_class_enum>;
  data_source_id?: InputMaybe<data_source_type_enum>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_rented?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  type_of_use_id?: InputMaybe<type_of_use_enum>;
  value?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type area_sum_fields = {
  building_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  parent_id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "area" */
export type area_sum_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  parent_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** columns and relationships of "area_system_type" */
export type area_system_type = {
  id: Scalars['String'];
};

/** aggregated selection of "area_system_type" */
export type area_system_type_aggregate = {
  aggregate?: Maybe<area_system_type_aggregate_fields>;
  nodes: Array<area_system_type>;
};

/** aggregate fields of "area_system_type" */
export type area_system_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<area_system_type_max_fields>;
  min?: Maybe<area_system_type_min_fields>;
};

/** aggregate fields of "area_system_type" */
export type area_system_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<area_system_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "area_system_type". All fields are combined with a logical 'AND'. */
export type area_system_type_bool_exp = {
  _and?: InputMaybe<Array<area_system_type_bool_exp>>;
  _not?: InputMaybe<area_system_type_bool_exp>;
  _or?: InputMaybe<Array<area_system_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { area_system_type_constraint };

export { area_system_type_enum };

/** Boolean expression to compare columns of type "area_system_type_enum". All fields are combined with logical 'AND'. */
export type area_system_type_enum_comparison_exp = {
  _eq?: InputMaybe<area_system_type_enum>;
  _in?: InputMaybe<Array<area_system_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<area_system_type_enum>;
  _nin?: InputMaybe<Array<area_system_type_enum>>;
};

/** input type for inserting data into table "area_system_type" */
export type area_system_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type area_system_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type area_system_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "area_system_type" */
export type area_system_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<area_system_type>;
};

/** on_conflict condition type for table "area_system_type" */
export type area_system_type_on_conflict = {
  constraint: area_system_type_constraint;
  update_columns?: Array<area_system_type_update_column>;
  where?: InputMaybe<area_system_type_bool_exp>;
};

/** Ordering options when selecting data from "area_system_type". */
export type area_system_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: area_system_type */
export type area_system_type_pk_columns_input = {
  id: Scalars['String'];
};

export { area_system_type_select_column };

/** input type for updating data in table "area_system_type" */
export type area_system_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "area_system_type" */
export type area_system_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: area_system_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type area_system_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { area_system_type_update_column };

export type area_system_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<area_system_type_set_input>;
  /** filter the rows which have to be updated */
  where: area_system_type_bool_exp;
};

/** columns and relationships of "area_type" */
export type area_type = {
  id: Scalars['String'];
};

/** aggregated selection of "area_type" */
export type area_type_aggregate = {
  aggregate?: Maybe<area_type_aggregate_fields>;
  nodes: Array<area_type>;
};

/** aggregate fields of "area_type" */
export type area_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<area_type_max_fields>;
  min?: Maybe<area_type_min_fields>;
};

/** aggregate fields of "area_type" */
export type area_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<area_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "area_type". All fields are combined with a logical 'AND'. */
export type area_type_bool_exp = {
  _and?: InputMaybe<Array<area_type_bool_exp>>;
  _not?: InputMaybe<area_type_bool_exp>;
  _or?: InputMaybe<Array<area_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { area_type_constraint };

export { area_type_enum };

/** Boolean expression to compare columns of type "area_type_enum". All fields are combined with logical 'AND'. */
export type area_type_enum_comparison_exp = {
  _eq?: InputMaybe<area_type_enum>;
  _in?: InputMaybe<Array<area_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<area_type_enum>;
  _nin?: InputMaybe<Array<area_type_enum>>;
};

/** input type for inserting data into table "area_type" */
export type area_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type area_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type area_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "area_type" */
export type area_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<area_type>;
};

/** on_conflict condition type for table "area_type" */
export type area_type_on_conflict = {
  constraint: area_type_constraint;
  update_columns?: Array<area_type_update_column>;
  where?: InputMaybe<area_type_bool_exp>;
};

/** Ordering options when selecting data from "area_type". */
export type area_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: area_type */
export type area_type_pk_columns_input = {
  id: Scalars['String'];
};

export { area_type_select_column };

/** input type for updating data in table "area_type" */
export type area_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "area_type" */
export type area_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: area_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type area_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { area_type_update_column };

export type area_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<area_type_set_input>;
  /** filter the rows which have to be updated */
  where: area_type_bool_exp;
};

export { area_update_column };

export type area_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<area_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<area_set_input>;
  /** filter the rows which have to be updated */
  where: area_bool_exp;
};

/** aggregate var_pop on columns */
export type area_var_pop_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "area" */
export type area_var_pop_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  parent_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type area_var_samp_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "area" */
export type area_var_samp_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  parent_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type area_variance_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "area" */
export type area_variance_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  parent_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** columns and relationships of "async_job" */
export type async_job = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  entity_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** An object relationship */
  org?: Maybe<org>;
  org_id?: Maybe<Scalars['Int']>;
  status_id: async_job_status_type_enum;
  title: Scalars['String'];
  total_jobs_created?: Maybe<Scalars['Int']>;
  total_jobs_finished?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<user>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "async_job" */
export type async_job_aggregate = {
  aggregate?: Maybe<async_job_aggregate_fields>;
  nodes: Array<async_job>;
};

/** aggregate fields of "async_job" */
export type async_job_aggregate_fields = {
  avg?: Maybe<async_job_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<async_job_max_fields>;
  min?: Maybe<async_job_min_fields>;
  stddev?: Maybe<async_job_stddev_fields>;
  stddev_pop?: Maybe<async_job_stddev_pop_fields>;
  stddev_samp?: Maybe<async_job_stddev_samp_fields>;
  sum?: Maybe<async_job_sum_fields>;
  var_pop?: Maybe<async_job_var_pop_fields>;
  var_samp?: Maybe<async_job_var_samp_fields>;
  variance?: Maybe<async_job_variance_fields>;
};

/** aggregate fields of "async_job" */
export type async_job_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<async_job_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type async_job_avg_fields = {
  entity_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  total_jobs_created?: Maybe<Scalars['Float']>;
  total_jobs_finished?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "async_job". All fields are combined with a logical 'AND'. */
export type async_job_bool_exp = {
  _and?: InputMaybe<Array<async_job_bool_exp>>;
  _not?: InputMaybe<async_job_bool_exp>;
  _or?: InputMaybe<Array<async_job_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  description?: InputMaybe<String_comparison_exp>;
  entity_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  org?: InputMaybe<org_bool_exp>;
  org_id?: InputMaybe<Int_comparison_exp>;
  status_id?: InputMaybe<async_job_status_type_enum_comparison_exp>;
  title?: InputMaybe<String_comparison_exp>;
  total_jobs_created?: InputMaybe<Int_comparison_exp>;
  total_jobs_finished?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  user?: InputMaybe<user_bool_exp>;
  user_id?: InputMaybe<Int_comparison_exp>;
};

export { async_job_constraint };

/** input type for incrementing numeric columns in table "async_job" */
export type async_job_inc_input = {
  entity_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
  total_jobs_created?: InputMaybe<Scalars['Int']>;
  total_jobs_finished?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "async_job" */
export type async_job_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  entity_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  org?: InputMaybe<org_obj_rel_insert_input>;
  org_id?: InputMaybe<Scalars['Int']>;
  status_id?: InputMaybe<async_job_status_type_enum>;
  title?: InputMaybe<Scalars['String']>;
  total_jobs_created?: InputMaybe<Scalars['Int']>;
  total_jobs_finished?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<user_obj_rel_insert_input>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type async_job_max_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  entity_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total_jobs_created?: Maybe<Scalars['Int']>;
  total_jobs_finished?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type async_job_min_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  entity_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total_jobs_created?: Maybe<Scalars['Int']>;
  total_jobs_finished?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "async_job" */
export type async_job_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<async_job>;
};

/** on_conflict condition type for table "async_job" */
export type async_job_on_conflict = {
  constraint: async_job_constraint;
  update_columns?: Array<async_job_update_column>;
  where?: InputMaybe<async_job_bool_exp>;
};

/** Ordering options when selecting data from "async_job". */
export type async_job_order_by = {
  created_at?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  entity_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org?: InputMaybe<org_order_by>;
  org_id?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  total_jobs_created?: InputMaybe<order_by>;
  total_jobs_finished?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  user?: InputMaybe<user_order_by>;
  user_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: async_job */
export type async_job_pk_columns_input = {
  id: Scalars['Int'];
};

export { async_job_select_column };

/** input type for updating data in table "async_job" */
export type async_job_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  entity_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
  status_id?: InputMaybe<async_job_status_type_enum>;
  title?: InputMaybe<Scalars['String']>;
  total_jobs_created?: InputMaybe<Scalars['Int']>;
  total_jobs_finished?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "async_job_status_type" */
export type async_job_status_type = {
  id: Scalars['String'];
};

/** aggregated selection of "async_job_status_type" */
export type async_job_status_type_aggregate = {
  aggregate?: Maybe<async_job_status_type_aggregate_fields>;
  nodes: Array<async_job_status_type>;
};

/** aggregate fields of "async_job_status_type" */
export type async_job_status_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<async_job_status_type_max_fields>;
  min?: Maybe<async_job_status_type_min_fields>;
};

/** aggregate fields of "async_job_status_type" */
export type async_job_status_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<async_job_status_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "async_job_status_type". All fields are combined with a logical 'AND'. */
export type async_job_status_type_bool_exp = {
  _and?: InputMaybe<Array<async_job_status_type_bool_exp>>;
  _not?: InputMaybe<async_job_status_type_bool_exp>;
  _or?: InputMaybe<Array<async_job_status_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { async_job_status_type_constraint };

export { async_job_status_type_enum };

/** Boolean expression to compare columns of type "async_job_status_type_enum". All fields are combined with logical 'AND'. */
export type async_job_status_type_enum_comparison_exp = {
  _eq?: InputMaybe<async_job_status_type_enum>;
  _in?: InputMaybe<Array<async_job_status_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<async_job_status_type_enum>;
  _nin?: InputMaybe<Array<async_job_status_type_enum>>;
};

/** input type for inserting data into table "async_job_status_type" */
export type async_job_status_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type async_job_status_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type async_job_status_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "async_job_status_type" */
export type async_job_status_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<async_job_status_type>;
};

/** on_conflict condition type for table "async_job_status_type" */
export type async_job_status_type_on_conflict = {
  constraint: async_job_status_type_constraint;
  update_columns?: Array<async_job_status_type_update_column>;
  where?: InputMaybe<async_job_status_type_bool_exp>;
};

/** Ordering options when selecting data from "async_job_status_type". */
export type async_job_status_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: async_job_status_type */
export type async_job_status_type_pk_columns_input = {
  id: Scalars['String'];
};

export { async_job_status_type_select_column };

/** input type for updating data in table "async_job_status_type" */
export type async_job_status_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "async_job_status_type" */
export type async_job_status_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: async_job_status_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type async_job_status_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { async_job_status_type_update_column };

export type async_job_status_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<async_job_status_type_set_input>;
  /** filter the rows which have to be updated */
  where: async_job_status_type_bool_exp;
};

/** aggregate stddev on columns */
export type async_job_stddev_fields = {
  entity_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  total_jobs_created?: Maybe<Scalars['Float']>;
  total_jobs_finished?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type async_job_stddev_pop_fields = {
  entity_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  total_jobs_created?: Maybe<Scalars['Float']>;
  total_jobs_finished?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type async_job_stddev_samp_fields = {
  entity_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  total_jobs_created?: Maybe<Scalars['Float']>;
  total_jobs_finished?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "async_job" */
export type async_job_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: async_job_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type async_job_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  entity_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
  status_id?: InputMaybe<async_job_status_type_enum>;
  title?: InputMaybe<Scalars['String']>;
  total_jobs_created?: InputMaybe<Scalars['Int']>;
  total_jobs_finished?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type async_job_sum_fields = {
  entity_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  total_jobs_created?: Maybe<Scalars['Int']>;
  total_jobs_finished?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

export { async_job_update_column };

export type async_job_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<async_job_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<async_job_set_input>;
  /** filter the rows which have to be updated */
  where: async_job_bool_exp;
};

/** aggregate var_pop on columns */
export type async_job_var_pop_fields = {
  entity_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  total_jobs_created?: Maybe<Scalars['Float']>;
  total_jobs_finished?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type async_job_var_samp_fields = {
  entity_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  total_jobs_created?: Maybe<Scalars['Float']>;
  total_jobs_finished?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type async_job_variance_fields = {
  entity_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  total_jobs_created?: Maybe<Scalars['Float']>;
  total_jobs_finished?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "building" */
export type building = {
  /** An array relationship */
  action_plans: Array<action_plan>;
  /** An aggregate relationship */
  action_plans_aggregate: action_plan_aggregate;
  /** An object relationship */
  active_building_model?: Maybe<building_model>;
  active_building_model_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  address: address;
  address_id: Scalars['Int'];
  /** An array relationship */
  areas: Array<area>;
  /** An aggregate relationship */
  areas_aggregate: area_aggregate;
  /** An array relationship */
  building_models: Array<building_model>;
  /** An aggregate relationship */
  building_models_aggregate: building_model_aggregate;
  building_state_id: building_state_enum;
  /** An array relationship */
  climate_risks: Array<climate_risk>;
  /** An aggregate relationship */
  climate_risks_aggregate: climate_risk_aggregate;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  economic_unit: economic_unit;
  economic_unit_id: Scalars['Int'];
  heritage_district: Scalars['Boolean'];
  id: Scalars['Int'];
  leasehold: Scalars['Boolean'];
  milieu_protection: Scalars['Boolean'];
  monument_protection: Scalars['Boolean'];
  /** An object relationship */
  responsible_user: user;
  responsible_user_id: Scalars['Int'];
  /** An array relationship */
  sub_buildings: Array<sub_building>;
  /** An aggregate relationship */
  sub_buildings_aggregate: sub_building_aggregate;
  /** An array relationship */
  target_paths: Array<target_path>;
  /** An aggregate relationship */
  target_paths_aggregate: target_path_aggregate;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  user_permissions: Array<user_building_permission>;
  /** An aggregate relationship */
  user_permissions_aggregate: user_building_permission_aggregate;
  year_constructed: Scalars['Int'];
};

/** columns and relationships of "building" */
export type buildingaction_plansArgs = {
  distinct_on?: InputMaybe<Array<action_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_plan_order_by>>;
  where?: InputMaybe<action_plan_bool_exp>;
};

/** columns and relationships of "building" */
export type buildingaction_plans_aggregateArgs = {
  distinct_on?: InputMaybe<Array<action_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_plan_order_by>>;
  where?: InputMaybe<action_plan_bool_exp>;
};

/** columns and relationships of "building" */
export type buildingareasArgs = {
  distinct_on?: InputMaybe<Array<area_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<area_order_by>>;
  where?: InputMaybe<area_bool_exp>;
};

/** columns and relationships of "building" */
export type buildingareas_aggregateArgs = {
  distinct_on?: InputMaybe<Array<area_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<area_order_by>>;
  where?: InputMaybe<area_bool_exp>;
};

/** columns and relationships of "building" */
export type buildingbuilding_modelsArgs = {
  distinct_on?: InputMaybe<Array<building_model_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<building_model_order_by>>;
  where?: InputMaybe<building_model_bool_exp>;
};

/** columns and relationships of "building" */
export type buildingbuilding_models_aggregateArgs = {
  distinct_on?: InputMaybe<Array<building_model_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<building_model_order_by>>;
  where?: InputMaybe<building_model_bool_exp>;
};

/** columns and relationships of "building" */
export type buildingclimate_risksArgs = {
  distinct_on?: InputMaybe<Array<climate_risk_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<climate_risk_order_by>>;
  where?: InputMaybe<climate_risk_bool_exp>;
};

/** columns and relationships of "building" */
export type buildingclimate_risks_aggregateArgs = {
  distinct_on?: InputMaybe<Array<climate_risk_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<climate_risk_order_by>>;
  where?: InputMaybe<climate_risk_bool_exp>;
};

/** columns and relationships of "building" */
export type buildingsub_buildingsArgs = {
  distinct_on?: InputMaybe<Array<sub_building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<sub_building_order_by>>;
  where?: InputMaybe<sub_building_bool_exp>;
};

/** columns and relationships of "building" */
export type buildingsub_buildings_aggregateArgs = {
  distinct_on?: InputMaybe<Array<sub_building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<sub_building_order_by>>;
  where?: InputMaybe<sub_building_bool_exp>;
};

/** columns and relationships of "building" */
export type buildingtarget_pathsArgs = {
  distinct_on?: InputMaybe<Array<target_path_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<target_path_order_by>>;
  where?: InputMaybe<target_path_bool_exp>;
};

/** columns and relationships of "building" */
export type buildingtarget_paths_aggregateArgs = {
  distinct_on?: InputMaybe<Array<target_path_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<target_path_order_by>>;
  where?: InputMaybe<target_path_bool_exp>;
};

/** columns and relationships of "building" */
export type buildinguser_permissionsArgs = {
  distinct_on?: InputMaybe<Array<user_building_permission_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_building_permission_order_by>>;
  where?: InputMaybe<user_building_permission_bool_exp>;
};

/** columns and relationships of "building" */
export type buildinguser_permissions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<user_building_permission_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_building_permission_order_by>>;
  where?: InputMaybe<user_building_permission_bool_exp>;
};

/** aggregated selection of "building" */
export type building_aggregate = {
  aggregate?: Maybe<building_aggregate_fields>;
  nodes: Array<building>;
};

export type building_aggregate_bool_exp = {
  bool_and?: InputMaybe<building_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<building_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<building_aggregate_bool_exp_count>;
};

export type building_aggregate_bool_exp_bool_and = {
  arguments: building_select_column_building_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<building_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type building_aggregate_bool_exp_bool_or = {
  arguments: building_select_column_building_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<building_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type building_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<building_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<building_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "building" */
export type building_aggregate_fields = {
  avg?: Maybe<building_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<building_max_fields>;
  min?: Maybe<building_min_fields>;
  stddev?: Maybe<building_stddev_fields>;
  stddev_pop?: Maybe<building_stddev_pop_fields>;
  stddev_samp?: Maybe<building_stddev_samp_fields>;
  sum?: Maybe<building_sum_fields>;
  var_pop?: Maybe<building_var_pop_fields>;
  var_samp?: Maybe<building_var_samp_fields>;
  variance?: Maybe<building_variance_fields>;
};

/** aggregate fields of "building" */
export type building_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<building_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "building" */
export type building_aggregate_order_by = {
  avg?: InputMaybe<building_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<building_max_order_by>;
  min?: InputMaybe<building_min_order_by>;
  stddev?: InputMaybe<building_stddev_order_by>;
  stddev_pop?: InputMaybe<building_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<building_stddev_samp_order_by>;
  sum?: InputMaybe<building_sum_order_by>;
  var_pop?: InputMaybe<building_var_pop_order_by>;
  var_samp?: InputMaybe<building_var_samp_order_by>;
  variance?: InputMaybe<building_variance_order_by>;
};

/** input type for inserting array relation for remote table "building" */
export type building_arr_rel_insert_input = {
  data: Array<building_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<building_on_conflict>;
};

/** aggregate avg on columns */
export type building_avg_fields = {
  active_building_model_id?: Maybe<Scalars['Float']>;
  address_id?: Maybe<Scalars['Float']>;
  economic_unit_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  responsible_user_id?: Maybe<Scalars['Float']>;
  year_constructed?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "building" */
export type building_avg_order_by = {
  active_building_model_id?: InputMaybe<order_by>;
  address_id?: InputMaybe<order_by>;
  economic_unit_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "building". All fields are combined with a logical 'AND'. */
export type building_bool_exp = {
  _and?: InputMaybe<Array<building_bool_exp>>;
  _not?: InputMaybe<building_bool_exp>;
  _or?: InputMaybe<Array<building_bool_exp>>;
  action_plans?: InputMaybe<action_plan_bool_exp>;
  action_plans_aggregate?: InputMaybe<action_plan_aggregate_bool_exp>;
  active_building_model?: InputMaybe<building_model_bool_exp>;
  active_building_model_id?: InputMaybe<Int_comparison_exp>;
  address?: InputMaybe<address_bool_exp>;
  address_id?: InputMaybe<Int_comparison_exp>;
  areas?: InputMaybe<area_bool_exp>;
  areas_aggregate?: InputMaybe<area_aggregate_bool_exp>;
  building_models?: InputMaybe<building_model_bool_exp>;
  building_models_aggregate?: InputMaybe<building_model_aggregate_bool_exp>;
  building_state_id?: InputMaybe<building_state_enum_comparison_exp>;
  climate_risks?: InputMaybe<climate_risk_bool_exp>;
  climate_risks_aggregate?: InputMaybe<climate_risk_aggregate_bool_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  economic_unit?: InputMaybe<economic_unit_bool_exp>;
  economic_unit_id?: InputMaybe<Int_comparison_exp>;
  heritage_district?: InputMaybe<Boolean_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  leasehold?: InputMaybe<Boolean_comparison_exp>;
  milieu_protection?: InputMaybe<Boolean_comparison_exp>;
  monument_protection?: InputMaybe<Boolean_comparison_exp>;
  responsible_user?: InputMaybe<user_bool_exp>;
  responsible_user_id?: InputMaybe<Int_comparison_exp>;
  sub_buildings?: InputMaybe<sub_building_bool_exp>;
  sub_buildings_aggregate?: InputMaybe<sub_building_aggregate_bool_exp>;
  target_paths?: InputMaybe<target_path_bool_exp>;
  target_paths_aggregate?: InputMaybe<target_path_aggregate_bool_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  user_permissions?: InputMaybe<user_building_permission_bool_exp>;
  user_permissions_aggregate?: InputMaybe<user_building_permission_aggregate_bool_exp>;
  year_constructed?: InputMaybe<Int_comparison_exp>;
};

export { building_constraint };

/** input type for incrementing numeric columns in table "building" */
export type building_inc_input = {
  active_building_model_id?: InputMaybe<Scalars['Int']>;
  address_id?: InputMaybe<Scalars['Int']>;
  economic_unit_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  responsible_user_id?: InputMaybe<Scalars['Int']>;
  year_constructed?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "building" */
export type building_insert_input = {
  action_plans?: InputMaybe<action_plan_arr_rel_insert_input>;
  active_building_model?: InputMaybe<building_model_obj_rel_insert_input>;
  active_building_model_id?: InputMaybe<Scalars['Int']>;
  address?: InputMaybe<address_obj_rel_insert_input>;
  address_id?: InputMaybe<Scalars['Int']>;
  areas?: InputMaybe<area_arr_rel_insert_input>;
  building_models?: InputMaybe<building_model_arr_rel_insert_input>;
  building_state_id?: InputMaybe<building_state_enum>;
  climate_risks?: InputMaybe<climate_risk_arr_rel_insert_input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  economic_unit?: InputMaybe<economic_unit_obj_rel_insert_input>;
  economic_unit_id?: InputMaybe<Scalars['Int']>;
  heritage_district?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  leasehold?: InputMaybe<Scalars['Boolean']>;
  milieu_protection?: InputMaybe<Scalars['Boolean']>;
  monument_protection?: InputMaybe<Scalars['Boolean']>;
  responsible_user?: InputMaybe<user_obj_rel_insert_input>;
  responsible_user_id?: InputMaybe<Scalars['Int']>;
  sub_buildings?: InputMaybe<sub_building_arr_rel_insert_input>;
  target_paths?: InputMaybe<target_path_arr_rel_insert_input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_permissions?: InputMaybe<user_building_permission_arr_rel_insert_input>;
  year_constructed?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type building_max_fields = {
  active_building_model_id?: Maybe<Scalars['Int']>;
  address_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  economic_unit_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  responsible_user_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year_constructed?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "building" */
export type building_max_order_by = {
  active_building_model_id?: InputMaybe<order_by>;
  address_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  economic_unit_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type building_min_fields = {
  active_building_model_id?: Maybe<Scalars['Int']>;
  address_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  economic_unit_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  responsible_user_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year_constructed?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "building" */
export type building_min_order_by = {
  active_building_model_id?: InputMaybe<order_by>;
  address_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  economic_unit_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
};

/** a building model is a full representation of a building during a period of time */
export type building_model = {
  /** An object relationship */
  action?: Maybe<action>;
  action_id?: Maybe<Scalars['Int']>;
  action_planning_available: Scalars['Boolean'];
  /** An object relationship */
  active_for_building?: Maybe<building>;
  air_change_rate_by_infiltration: Scalars['Float'];
  annual_heating_days: Scalars['Float'];
  /** An object relationship */
  building?: Maybe<building>;
  building_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  energy_consumers: Array<energy_consumer>;
  /** An aggregate relationship */
  energy_consumers_aggregate: energy_consumer_aggregate;
  /** An array relationship */
  energy_distributions: Array<energy_distribution>;
  /** An aggregate relationship */
  energy_distributions_aggregate: energy_distribution_aggregate;
  energy_effective?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  /** An array relationship */
  energy_paths: Array<energy_path>;
  /** An aggregate relationship */
  energy_paths_aggregate: energy_path_aggregate;
  energy_primary?: Maybe<Scalars['Float']>;
  /** An array relationship */
  energy_producers: Array<energy_producer>;
  /** An aggregate relationship */
  energy_producers_aggregate: energy_producer_aggregate;
  /** An array relationship */
  energy_storages: Array<energy_storage>;
  /** An aggregate relationship */
  energy_storages_aggregate: energy_storage_aggregate;
  /** An array relationship */
  energy_systems: Array<energy_system>;
  /** An aggregate relationship */
  energy_systems_aggregate: energy_system_aggregate;
  /** An array relationship */
  envelope_units: Array<envelope>;
  /** An aggregate relationship */
  envelope_units_aggregate: envelope_aggregate;
  external_temperature: Scalars['Float'];
  id: Scalars['Int'];
  internal_temperature: Scalars['Float'];
  processing: Scalars['Boolean'];
  reference_building_id?: Maybe<Scalars['String']>;
  thermal_bridging: Scalars['Float'];
  updated_at: Scalars['timestamptz'];
  valid_from?: Maybe<Scalars['timestamptz']>;
  valid_to?: Maybe<Scalars['timestamptz']>;
};

/** a building model is a full representation of a building during a period of time */
export type building_modelenergy_consumersArgs = {
  distinct_on?: InputMaybe<Array<energy_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_consumer_order_by>>;
  where?: InputMaybe<energy_consumer_bool_exp>;
};

/** a building model is a full representation of a building during a period of time */
export type building_modelenergy_consumers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_consumer_order_by>>;
  where?: InputMaybe<energy_consumer_bool_exp>;
};

/** a building model is a full representation of a building during a period of time */
export type building_modelenergy_distributionsArgs = {
  distinct_on?: InputMaybe<Array<energy_distribution_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_distribution_order_by>>;
  where?: InputMaybe<energy_distribution_bool_exp>;
};

/** a building model is a full representation of a building during a period of time */
export type building_modelenergy_distributions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_distribution_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_distribution_order_by>>;
  where?: InputMaybe<energy_distribution_bool_exp>;
};

/** a building model is a full representation of a building during a period of time */
export type building_modelenergy_pathsArgs = {
  distinct_on?: InputMaybe<Array<energy_path_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_path_order_by>>;
  where?: InputMaybe<energy_path_bool_exp>;
};

/** a building model is a full representation of a building during a period of time */
export type building_modelenergy_paths_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_path_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_path_order_by>>;
  where?: InputMaybe<energy_path_bool_exp>;
};

/** a building model is a full representation of a building during a period of time */
export type building_modelenergy_producersArgs = {
  distinct_on?: InputMaybe<Array<energy_producer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_producer_order_by>>;
  where?: InputMaybe<energy_producer_bool_exp>;
};

/** a building model is a full representation of a building during a period of time */
export type building_modelenergy_producers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_producer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_producer_order_by>>;
  where?: InputMaybe<energy_producer_bool_exp>;
};

/** a building model is a full representation of a building during a period of time */
export type building_modelenergy_storagesArgs = {
  distinct_on?: InputMaybe<Array<energy_storage_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_storage_order_by>>;
  where?: InputMaybe<energy_storage_bool_exp>;
};

/** a building model is a full representation of a building during a period of time */
export type building_modelenergy_storages_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_storage_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_storage_order_by>>;
  where?: InputMaybe<energy_storage_bool_exp>;
};

/** a building model is a full representation of a building during a period of time */
export type building_modelenergy_systemsArgs = {
  distinct_on?: InputMaybe<Array<energy_system_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_order_by>>;
  where?: InputMaybe<energy_system_bool_exp>;
};

/** a building model is a full representation of a building during a period of time */
export type building_modelenergy_systems_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_system_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_order_by>>;
  where?: InputMaybe<energy_system_bool_exp>;
};

/** a building model is a full representation of a building during a period of time */
export type building_modelenvelope_unitsArgs = {
  distinct_on?: InputMaybe<Array<envelope_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<envelope_order_by>>;
  where?: InputMaybe<envelope_bool_exp>;
};

/** a building model is a full representation of a building during a period of time */
export type building_modelenvelope_units_aggregateArgs = {
  distinct_on?: InputMaybe<Array<envelope_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<envelope_order_by>>;
  where?: InputMaybe<envelope_bool_exp>;
};

/** aggregated selection of "building_model" */
export type building_model_aggregate = {
  aggregate?: Maybe<building_model_aggregate_fields>;
  nodes: Array<building_model>;
};

export type building_model_aggregate_bool_exp = {
  bool_and?: InputMaybe<building_model_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<building_model_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<building_model_aggregate_bool_exp_count>;
};

export type building_model_aggregate_bool_exp_bool_and = {
  arguments: building_model_select_column_building_model_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<building_model_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type building_model_aggregate_bool_exp_bool_or = {
  arguments: building_model_select_column_building_model_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<building_model_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type building_model_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<building_model_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<building_model_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "building_model" */
export type building_model_aggregate_fields = {
  avg?: Maybe<building_model_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<building_model_max_fields>;
  min?: Maybe<building_model_min_fields>;
  stddev?: Maybe<building_model_stddev_fields>;
  stddev_pop?: Maybe<building_model_stddev_pop_fields>;
  stddev_samp?: Maybe<building_model_stddev_samp_fields>;
  sum?: Maybe<building_model_sum_fields>;
  var_pop?: Maybe<building_model_var_pop_fields>;
  var_samp?: Maybe<building_model_var_samp_fields>;
  variance?: Maybe<building_model_variance_fields>;
};

/** aggregate fields of "building_model" */
export type building_model_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<building_model_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "building_model" */
export type building_model_aggregate_order_by = {
  avg?: InputMaybe<building_model_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<building_model_max_order_by>;
  min?: InputMaybe<building_model_min_order_by>;
  stddev?: InputMaybe<building_model_stddev_order_by>;
  stddev_pop?: InputMaybe<building_model_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<building_model_stddev_samp_order_by>;
  sum?: InputMaybe<building_model_sum_order_by>;
  var_pop?: InputMaybe<building_model_var_pop_order_by>;
  var_samp?: InputMaybe<building_model_var_samp_order_by>;
  variance?: InputMaybe<building_model_variance_order_by>;
};

/** input type for inserting array relation for remote table "building_model" */
export type building_model_arr_rel_insert_input = {
  data: Array<building_model_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<building_model_on_conflict>;
};

/** aggregate avg on columns */
export type building_model_avg_fields = {
  action_id?: Maybe<Scalars['Float']>;
  air_change_rate_by_infiltration?: Maybe<Scalars['Float']>;
  annual_heating_days?: Maybe<Scalars['Float']>;
  building_id?: Maybe<Scalars['Float']>;
  energy_effective?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  external_temperature?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  internal_temperature?: Maybe<Scalars['Float']>;
  thermal_bridging?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "building_model" */
export type building_model_avg_order_by = {
  action_id?: InputMaybe<order_by>;
  air_change_rate_by_infiltration?: InputMaybe<order_by>;
  annual_heating_days?: InputMaybe<order_by>;
  building_id?: InputMaybe<order_by>;
  energy_effective?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  external_temperature?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  internal_temperature?: InputMaybe<order_by>;
  thermal_bridging?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "building_model". All fields are combined with a logical 'AND'. */
export type building_model_bool_exp = {
  _and?: InputMaybe<Array<building_model_bool_exp>>;
  _not?: InputMaybe<building_model_bool_exp>;
  _or?: InputMaybe<Array<building_model_bool_exp>>;
  action?: InputMaybe<action_bool_exp>;
  action_id?: InputMaybe<Int_comparison_exp>;
  action_planning_available?: InputMaybe<Boolean_comparison_exp>;
  active_for_building?: InputMaybe<building_bool_exp>;
  air_change_rate_by_infiltration?: InputMaybe<Float_comparison_exp>;
  annual_heating_days?: InputMaybe<Float_comparison_exp>;
  building?: InputMaybe<building_bool_exp>;
  building_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  energy_consumers?: InputMaybe<energy_consumer_bool_exp>;
  energy_consumers_aggregate?: InputMaybe<energy_consumer_aggregate_bool_exp>;
  energy_distributions?: InputMaybe<energy_distribution_bool_exp>;
  energy_distributions_aggregate?: InputMaybe<energy_distribution_aggregate_bool_exp>;
  energy_effective?: InputMaybe<Float_comparison_exp>;
  energy_final?: InputMaybe<Float_comparison_exp>;
  energy_paths?: InputMaybe<energy_path_bool_exp>;
  energy_paths_aggregate?: InputMaybe<energy_path_aggregate_bool_exp>;
  energy_primary?: InputMaybe<Float_comparison_exp>;
  energy_producers?: InputMaybe<energy_producer_bool_exp>;
  energy_producers_aggregate?: InputMaybe<energy_producer_aggregate_bool_exp>;
  energy_storages?: InputMaybe<energy_storage_bool_exp>;
  energy_storages_aggregate?: InputMaybe<energy_storage_aggregate_bool_exp>;
  energy_systems?: InputMaybe<energy_system_bool_exp>;
  energy_systems_aggregate?: InputMaybe<energy_system_aggregate_bool_exp>;
  envelope_units?: InputMaybe<envelope_bool_exp>;
  envelope_units_aggregate?: InputMaybe<envelope_aggregate_bool_exp>;
  external_temperature?: InputMaybe<Float_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  internal_temperature?: InputMaybe<Float_comparison_exp>;
  processing?: InputMaybe<Boolean_comparison_exp>;
  reference_building_id?: InputMaybe<String_comparison_exp>;
  thermal_bridging?: InputMaybe<Float_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  valid_from?: InputMaybe<timestamptz_comparison_exp>;
  valid_to?: InputMaybe<timestamptz_comparison_exp>;
};

export { building_model_constraint };

/** input type for incrementing numeric columns in table "building_model" */
export type building_model_inc_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  air_change_rate_by_infiltration?: InputMaybe<Scalars['Float']>;
  annual_heating_days?: InputMaybe<Scalars['Float']>;
  building_id?: InputMaybe<Scalars['Int']>;
  energy_effective?: InputMaybe<Scalars['Float']>;
  energy_final?: InputMaybe<Scalars['Float']>;
  energy_primary?: InputMaybe<Scalars['Float']>;
  external_temperature?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  internal_temperature?: InputMaybe<Scalars['Float']>;
  thermal_bridging?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "building_model" */
export type building_model_insert_input = {
  action?: InputMaybe<action_obj_rel_insert_input>;
  action_id?: InputMaybe<Scalars['Int']>;
  action_planning_available?: InputMaybe<Scalars['Boolean']>;
  active_for_building?: InputMaybe<building_obj_rel_insert_input>;
  air_change_rate_by_infiltration?: InputMaybe<Scalars['Float']>;
  annual_heating_days?: InputMaybe<Scalars['Float']>;
  building?: InputMaybe<building_obj_rel_insert_input>;
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  energy_consumers?: InputMaybe<energy_consumer_arr_rel_insert_input>;
  energy_distributions?: InputMaybe<energy_distribution_arr_rel_insert_input>;
  energy_effective?: InputMaybe<Scalars['Float']>;
  energy_final?: InputMaybe<Scalars['Float']>;
  energy_paths?: InputMaybe<energy_path_arr_rel_insert_input>;
  energy_primary?: InputMaybe<Scalars['Float']>;
  energy_producers?: InputMaybe<energy_producer_arr_rel_insert_input>;
  energy_storages?: InputMaybe<energy_storage_arr_rel_insert_input>;
  energy_systems?: InputMaybe<energy_system_arr_rel_insert_input>;
  envelope_units?: InputMaybe<envelope_arr_rel_insert_input>;
  external_temperature?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  internal_temperature?: InputMaybe<Scalars['Float']>;
  processing?: InputMaybe<Scalars['Boolean']>;
  reference_building_id?: InputMaybe<Scalars['String']>;
  thermal_bridging?: InputMaybe<Scalars['Float']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valid_from?: InputMaybe<Scalars['timestamptz']>;
  valid_to?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type building_model_max_fields = {
  action_id?: Maybe<Scalars['Int']>;
  air_change_rate_by_infiltration?: Maybe<Scalars['Float']>;
  annual_heating_days?: Maybe<Scalars['Float']>;
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  energy_effective?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  external_temperature?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  internal_temperature?: Maybe<Scalars['Float']>;
  reference_building_id?: Maybe<Scalars['String']>;
  thermal_bridging?: Maybe<Scalars['Float']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valid_from?: Maybe<Scalars['timestamptz']>;
  valid_to?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "building_model" */
export type building_model_max_order_by = {
  action_id?: InputMaybe<order_by>;
  air_change_rate_by_infiltration?: InputMaybe<order_by>;
  annual_heating_days?: InputMaybe<order_by>;
  building_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  energy_effective?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  external_temperature?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  internal_temperature?: InputMaybe<order_by>;
  reference_building_id?: InputMaybe<order_by>;
  thermal_bridging?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  valid_from?: InputMaybe<order_by>;
  valid_to?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type building_model_min_fields = {
  action_id?: Maybe<Scalars['Int']>;
  air_change_rate_by_infiltration?: Maybe<Scalars['Float']>;
  annual_heating_days?: Maybe<Scalars['Float']>;
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  energy_effective?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  external_temperature?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  internal_temperature?: Maybe<Scalars['Float']>;
  reference_building_id?: Maybe<Scalars['String']>;
  thermal_bridging?: Maybe<Scalars['Float']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valid_from?: Maybe<Scalars['timestamptz']>;
  valid_to?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "building_model" */
export type building_model_min_order_by = {
  action_id?: InputMaybe<order_by>;
  air_change_rate_by_infiltration?: InputMaybe<order_by>;
  annual_heating_days?: InputMaybe<order_by>;
  building_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  energy_effective?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  external_temperature?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  internal_temperature?: InputMaybe<order_by>;
  reference_building_id?: InputMaybe<order_by>;
  thermal_bridging?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  valid_from?: InputMaybe<order_by>;
  valid_to?: InputMaybe<order_by>;
};

/** response of any mutation on the table "building_model" */
export type building_model_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<building_model>;
};

/** input type for inserting object relation for remote table "building_model" */
export type building_model_obj_rel_insert_input = {
  data: building_model_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<building_model_on_conflict>;
};

/** on_conflict condition type for table "building_model" */
export type building_model_on_conflict = {
  constraint: building_model_constraint;
  update_columns?: Array<building_model_update_column>;
  where?: InputMaybe<building_model_bool_exp>;
};

/** Ordering options when selecting data from "building_model". */
export type building_model_order_by = {
  action?: InputMaybe<action_order_by>;
  action_id?: InputMaybe<order_by>;
  action_planning_available?: InputMaybe<order_by>;
  active_for_building?: InputMaybe<building_order_by>;
  air_change_rate_by_infiltration?: InputMaybe<order_by>;
  annual_heating_days?: InputMaybe<order_by>;
  building?: InputMaybe<building_order_by>;
  building_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  energy_consumers_aggregate?: InputMaybe<energy_consumer_aggregate_order_by>;
  energy_distributions_aggregate?: InputMaybe<energy_distribution_aggregate_order_by>;
  energy_effective?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_paths_aggregate?: InputMaybe<energy_path_aggregate_order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_producers_aggregate?: InputMaybe<energy_producer_aggregate_order_by>;
  energy_storages_aggregate?: InputMaybe<energy_storage_aggregate_order_by>;
  energy_systems_aggregate?: InputMaybe<energy_system_aggregate_order_by>;
  envelope_units_aggregate?: InputMaybe<envelope_aggregate_order_by>;
  external_temperature?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  internal_temperature?: InputMaybe<order_by>;
  processing?: InputMaybe<order_by>;
  reference_building_id?: InputMaybe<order_by>;
  thermal_bridging?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  valid_from?: InputMaybe<order_by>;
  valid_to?: InputMaybe<order_by>;
};

/** primary key columns input for table: building_model */
export type building_model_pk_columns_input = {
  id: Scalars['Int'];
};

export { building_model_select_column };

export { building_model_select_column_building_model_aggregate_bool_exp_bool_and_arguments_columns };

export { building_model_select_column_building_model_aggregate_bool_exp_bool_or_arguments_columns };

/** input type for updating data in table "building_model" */
export type building_model_set_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  action_planning_available?: InputMaybe<Scalars['Boolean']>;
  air_change_rate_by_infiltration?: InputMaybe<Scalars['Float']>;
  annual_heating_days?: InputMaybe<Scalars['Float']>;
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  energy_effective?: InputMaybe<Scalars['Float']>;
  energy_final?: InputMaybe<Scalars['Float']>;
  energy_primary?: InputMaybe<Scalars['Float']>;
  external_temperature?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  internal_temperature?: InputMaybe<Scalars['Float']>;
  processing?: InputMaybe<Scalars['Boolean']>;
  reference_building_id?: InputMaybe<Scalars['String']>;
  thermal_bridging?: InputMaybe<Scalars['Float']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valid_from?: InputMaybe<Scalars['timestamptz']>;
  valid_to?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type building_model_stddev_fields = {
  action_id?: Maybe<Scalars['Float']>;
  air_change_rate_by_infiltration?: Maybe<Scalars['Float']>;
  annual_heating_days?: Maybe<Scalars['Float']>;
  building_id?: Maybe<Scalars['Float']>;
  energy_effective?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  external_temperature?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  internal_temperature?: Maybe<Scalars['Float']>;
  thermal_bridging?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "building_model" */
export type building_model_stddev_order_by = {
  action_id?: InputMaybe<order_by>;
  air_change_rate_by_infiltration?: InputMaybe<order_by>;
  annual_heating_days?: InputMaybe<order_by>;
  building_id?: InputMaybe<order_by>;
  energy_effective?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  external_temperature?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  internal_temperature?: InputMaybe<order_by>;
  thermal_bridging?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type building_model_stddev_pop_fields = {
  action_id?: Maybe<Scalars['Float']>;
  air_change_rate_by_infiltration?: Maybe<Scalars['Float']>;
  annual_heating_days?: Maybe<Scalars['Float']>;
  building_id?: Maybe<Scalars['Float']>;
  energy_effective?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  external_temperature?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  internal_temperature?: Maybe<Scalars['Float']>;
  thermal_bridging?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "building_model" */
export type building_model_stddev_pop_order_by = {
  action_id?: InputMaybe<order_by>;
  air_change_rate_by_infiltration?: InputMaybe<order_by>;
  annual_heating_days?: InputMaybe<order_by>;
  building_id?: InputMaybe<order_by>;
  energy_effective?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  external_temperature?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  internal_temperature?: InputMaybe<order_by>;
  thermal_bridging?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type building_model_stddev_samp_fields = {
  action_id?: Maybe<Scalars['Float']>;
  air_change_rate_by_infiltration?: Maybe<Scalars['Float']>;
  annual_heating_days?: Maybe<Scalars['Float']>;
  building_id?: Maybe<Scalars['Float']>;
  energy_effective?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  external_temperature?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  internal_temperature?: Maybe<Scalars['Float']>;
  thermal_bridging?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "building_model" */
export type building_model_stddev_samp_order_by = {
  action_id?: InputMaybe<order_by>;
  air_change_rate_by_infiltration?: InputMaybe<order_by>;
  annual_heating_days?: InputMaybe<order_by>;
  building_id?: InputMaybe<order_by>;
  energy_effective?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  external_temperature?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  internal_temperature?: InputMaybe<order_by>;
  thermal_bridging?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "building_model" */
export type building_model_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: building_model_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type building_model_stream_cursor_value_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  action_planning_available?: InputMaybe<Scalars['Boolean']>;
  air_change_rate_by_infiltration?: InputMaybe<Scalars['Float']>;
  annual_heating_days?: InputMaybe<Scalars['Float']>;
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  energy_effective?: InputMaybe<Scalars['Float']>;
  energy_final?: InputMaybe<Scalars['Float']>;
  energy_primary?: InputMaybe<Scalars['Float']>;
  external_temperature?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  internal_temperature?: InputMaybe<Scalars['Float']>;
  processing?: InputMaybe<Scalars['Boolean']>;
  reference_building_id?: InputMaybe<Scalars['String']>;
  thermal_bridging?: InputMaybe<Scalars['Float']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valid_from?: InputMaybe<Scalars['timestamptz']>;
  valid_to?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type building_model_sum_fields = {
  action_id?: Maybe<Scalars['Int']>;
  air_change_rate_by_infiltration?: Maybe<Scalars['Float']>;
  annual_heating_days?: Maybe<Scalars['Float']>;
  building_id?: Maybe<Scalars['Int']>;
  energy_effective?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  external_temperature?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  internal_temperature?: Maybe<Scalars['Float']>;
  thermal_bridging?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "building_model" */
export type building_model_sum_order_by = {
  action_id?: InputMaybe<order_by>;
  air_change_rate_by_infiltration?: InputMaybe<order_by>;
  annual_heating_days?: InputMaybe<order_by>;
  building_id?: InputMaybe<order_by>;
  energy_effective?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  external_temperature?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  internal_temperature?: InputMaybe<order_by>;
  thermal_bridging?: InputMaybe<order_by>;
};

export { building_model_update_column };

export type building_model_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<building_model_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<building_model_set_input>;
  /** filter the rows which have to be updated */
  where: building_model_bool_exp;
};

/** aggregate var_pop on columns */
export type building_model_var_pop_fields = {
  action_id?: Maybe<Scalars['Float']>;
  air_change_rate_by_infiltration?: Maybe<Scalars['Float']>;
  annual_heating_days?: Maybe<Scalars['Float']>;
  building_id?: Maybe<Scalars['Float']>;
  energy_effective?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  external_temperature?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  internal_temperature?: Maybe<Scalars['Float']>;
  thermal_bridging?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "building_model" */
export type building_model_var_pop_order_by = {
  action_id?: InputMaybe<order_by>;
  air_change_rate_by_infiltration?: InputMaybe<order_by>;
  annual_heating_days?: InputMaybe<order_by>;
  building_id?: InputMaybe<order_by>;
  energy_effective?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  external_temperature?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  internal_temperature?: InputMaybe<order_by>;
  thermal_bridging?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type building_model_var_samp_fields = {
  action_id?: Maybe<Scalars['Float']>;
  air_change_rate_by_infiltration?: Maybe<Scalars['Float']>;
  annual_heating_days?: Maybe<Scalars['Float']>;
  building_id?: Maybe<Scalars['Float']>;
  energy_effective?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  external_temperature?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  internal_temperature?: Maybe<Scalars['Float']>;
  thermal_bridging?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "building_model" */
export type building_model_var_samp_order_by = {
  action_id?: InputMaybe<order_by>;
  air_change_rate_by_infiltration?: InputMaybe<order_by>;
  annual_heating_days?: InputMaybe<order_by>;
  building_id?: InputMaybe<order_by>;
  energy_effective?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  external_temperature?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  internal_temperature?: InputMaybe<order_by>;
  thermal_bridging?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type building_model_variance_fields = {
  action_id?: Maybe<Scalars['Float']>;
  air_change_rate_by_infiltration?: Maybe<Scalars['Float']>;
  annual_heating_days?: Maybe<Scalars['Float']>;
  building_id?: Maybe<Scalars['Float']>;
  energy_effective?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  external_temperature?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  internal_temperature?: Maybe<Scalars['Float']>;
  thermal_bridging?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "building_model" */
export type building_model_variance_order_by = {
  action_id?: InputMaybe<order_by>;
  air_change_rate_by_infiltration?: InputMaybe<order_by>;
  annual_heating_days?: InputMaybe<order_by>;
  building_id?: InputMaybe<order_by>;
  energy_effective?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  external_temperature?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  internal_temperature?: InputMaybe<order_by>;
  thermal_bridging?: InputMaybe<order_by>;
};

/** response of any mutation on the table "building" */
export type building_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<building>;
};

/** input type for inserting object relation for remote table "building" */
export type building_obj_rel_insert_input = {
  data: building_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<building_on_conflict>;
};

/** on_conflict condition type for table "building" */
export type building_on_conflict = {
  constraint: building_constraint;
  update_columns?: Array<building_update_column>;
  where?: InputMaybe<building_bool_exp>;
};

/** Ordering options when selecting data from "building". */
export type building_order_by = {
  action_plans_aggregate?: InputMaybe<action_plan_aggregate_order_by>;
  active_building_model?: InputMaybe<building_model_order_by>;
  active_building_model_id?: InputMaybe<order_by>;
  address?: InputMaybe<address_order_by>;
  address_id?: InputMaybe<order_by>;
  areas_aggregate?: InputMaybe<area_aggregate_order_by>;
  building_models_aggregate?: InputMaybe<building_model_aggregate_order_by>;
  building_state_id?: InputMaybe<order_by>;
  climate_risks_aggregate?: InputMaybe<climate_risk_aggregate_order_by>;
  created_at?: InputMaybe<order_by>;
  economic_unit?: InputMaybe<economic_unit_order_by>;
  economic_unit_id?: InputMaybe<order_by>;
  heritage_district?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  leasehold?: InputMaybe<order_by>;
  milieu_protection?: InputMaybe<order_by>;
  monument_protection?: InputMaybe<order_by>;
  responsible_user?: InputMaybe<user_order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  sub_buildings_aggregate?: InputMaybe<sub_building_aggregate_order_by>;
  target_paths_aggregate?: InputMaybe<target_path_aggregate_order_by>;
  updated_at?: InputMaybe<order_by>;
  user_permissions_aggregate?: InputMaybe<user_building_permission_aggregate_order_by>;
  year_constructed?: InputMaybe<order_by>;
};

/** primary key columns input for table: building */
export type building_pk_columns_input = {
  id: Scalars['Int'];
};

export { building_select_column };

export { building_select_column_building_aggregate_bool_exp_bool_and_arguments_columns };

export { building_select_column_building_aggregate_bool_exp_bool_or_arguments_columns };

/** input type for updating data in table "building" */
export type building_set_input = {
  active_building_model_id?: InputMaybe<Scalars['Int']>;
  address_id?: InputMaybe<Scalars['Int']>;
  building_state_id?: InputMaybe<building_state_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  economic_unit_id?: InputMaybe<Scalars['Int']>;
  heritage_district?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  leasehold?: InputMaybe<Scalars['Boolean']>;
  milieu_protection?: InputMaybe<Scalars['Boolean']>;
  monument_protection?: InputMaybe<Scalars['Boolean']>;
  responsible_user_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  year_constructed?: InputMaybe<Scalars['Int']>;
};

/** State in which a sub-building can be. */
export type building_state = {
  id: Scalars['String'];
};

/** aggregated selection of "building_state" */
export type building_state_aggregate = {
  aggregate?: Maybe<building_state_aggregate_fields>;
  nodes: Array<building_state>;
};

/** aggregate fields of "building_state" */
export type building_state_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<building_state_max_fields>;
  min?: Maybe<building_state_min_fields>;
};

/** aggregate fields of "building_state" */
export type building_state_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<building_state_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "building_state". All fields are combined with a logical 'AND'. */
export type building_state_bool_exp = {
  _and?: InputMaybe<Array<building_state_bool_exp>>;
  _not?: InputMaybe<building_state_bool_exp>;
  _or?: InputMaybe<Array<building_state_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { building_state_constraint };

export { building_state_enum };

/** Boolean expression to compare columns of type "building_state_enum". All fields are combined with logical 'AND'. */
export type building_state_enum_comparison_exp = {
  _eq?: InputMaybe<building_state_enum>;
  _in?: InputMaybe<Array<building_state_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<building_state_enum>;
  _nin?: InputMaybe<Array<building_state_enum>>;
};

/** input type for inserting data into table "building_state" */
export type building_state_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type building_state_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type building_state_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "building_state" */
export type building_state_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<building_state>;
};

/** on_conflict condition type for table "building_state" */
export type building_state_on_conflict = {
  constraint: building_state_constraint;
  update_columns?: Array<building_state_update_column>;
  where?: InputMaybe<building_state_bool_exp>;
};

/** Ordering options when selecting data from "building_state". */
export type building_state_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: building_state */
export type building_state_pk_columns_input = {
  id: Scalars['String'];
};

export { building_state_select_column };

/** input type for updating data in table "building_state" */
export type building_state_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "building_state" */
export type building_state_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: building_state_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type building_state_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { building_state_update_column };

export type building_state_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<building_state_set_input>;
  /** filter the rows which have to be updated */
  where: building_state_bool_exp;
};

/** aggregate stddev on columns */
export type building_stddev_fields = {
  active_building_model_id?: Maybe<Scalars['Float']>;
  address_id?: Maybe<Scalars['Float']>;
  economic_unit_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  responsible_user_id?: Maybe<Scalars['Float']>;
  year_constructed?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "building" */
export type building_stddev_order_by = {
  active_building_model_id?: InputMaybe<order_by>;
  address_id?: InputMaybe<order_by>;
  economic_unit_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type building_stddev_pop_fields = {
  active_building_model_id?: Maybe<Scalars['Float']>;
  address_id?: Maybe<Scalars['Float']>;
  economic_unit_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  responsible_user_id?: Maybe<Scalars['Float']>;
  year_constructed?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "building" */
export type building_stddev_pop_order_by = {
  active_building_model_id?: InputMaybe<order_by>;
  address_id?: InputMaybe<order_by>;
  economic_unit_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type building_stddev_samp_fields = {
  active_building_model_id?: Maybe<Scalars['Float']>;
  address_id?: Maybe<Scalars['Float']>;
  economic_unit_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  responsible_user_id?: Maybe<Scalars['Float']>;
  year_constructed?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "building" */
export type building_stddev_samp_order_by = {
  active_building_model_id?: InputMaybe<order_by>;
  address_id?: InputMaybe<order_by>;
  economic_unit_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "building" */
export type building_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: building_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type building_stream_cursor_value_input = {
  active_building_model_id?: InputMaybe<Scalars['Int']>;
  address_id?: InputMaybe<Scalars['Int']>;
  building_state_id?: InputMaybe<building_state_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  economic_unit_id?: InputMaybe<Scalars['Int']>;
  heritage_district?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  leasehold?: InputMaybe<Scalars['Boolean']>;
  milieu_protection?: InputMaybe<Scalars['Boolean']>;
  monument_protection?: InputMaybe<Scalars['Boolean']>;
  responsible_user_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  year_constructed?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type building_sum_fields = {
  active_building_model_id?: Maybe<Scalars['Int']>;
  address_id?: Maybe<Scalars['Int']>;
  economic_unit_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  responsible_user_id?: Maybe<Scalars['Int']>;
  year_constructed?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "building" */
export type building_sum_order_by = {
  active_building_model_id?: InputMaybe<order_by>;
  address_id?: InputMaybe<order_by>;
  economic_unit_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
};

export { building_update_column };

export type building_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<building_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<building_set_input>;
  /** filter the rows which have to be updated */
  where: building_bool_exp;
};

/** aggregate var_pop on columns */
export type building_var_pop_fields = {
  active_building_model_id?: Maybe<Scalars['Float']>;
  address_id?: Maybe<Scalars['Float']>;
  economic_unit_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  responsible_user_id?: Maybe<Scalars['Float']>;
  year_constructed?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "building" */
export type building_var_pop_order_by = {
  active_building_model_id?: InputMaybe<order_by>;
  address_id?: InputMaybe<order_by>;
  economic_unit_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type building_var_samp_fields = {
  active_building_model_id?: Maybe<Scalars['Float']>;
  address_id?: Maybe<Scalars['Float']>;
  economic_unit_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  responsible_user_id?: Maybe<Scalars['Float']>;
  year_constructed?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "building" */
export type building_var_samp_order_by = {
  active_building_model_id?: InputMaybe<order_by>;
  address_id?: InputMaybe<order_by>;
  economic_unit_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type building_variance_fields = {
  active_building_model_id?: Maybe<Scalars['Float']>;
  address_id?: Maybe<Scalars['Float']>;
  economic_unit_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  responsible_user_id?: Maybe<Scalars['Float']>;
  year_constructed?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "building" */
export type building_variance_order_by = {
  active_building_model_id?: InputMaybe<order_by>;
  address_id?: InputMaybe<order_by>;
  economic_unit_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
};

/** columns and relationships of "climate_region" */
export type climate_region = {
  id: Scalars['String'];
};

/** aggregated selection of "climate_region" */
export type climate_region_aggregate = {
  aggregate?: Maybe<climate_region_aggregate_fields>;
  nodes: Array<climate_region>;
};

/** aggregate fields of "climate_region" */
export type climate_region_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<climate_region_max_fields>;
  min?: Maybe<climate_region_min_fields>;
};

/** aggregate fields of "climate_region" */
export type climate_region_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<climate_region_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "climate_region". All fields are combined with a logical 'AND'. */
export type climate_region_bool_exp = {
  _and?: InputMaybe<Array<climate_region_bool_exp>>;
  _not?: InputMaybe<climate_region_bool_exp>;
  _or?: InputMaybe<Array<climate_region_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { climate_region_constraint };

export { climate_region_enum };

/** Boolean expression to compare columns of type "climate_region_enum". All fields are combined with logical 'AND'. */
export type climate_region_enum_comparison_exp = {
  _eq?: InputMaybe<climate_region_enum>;
  _in?: InputMaybe<Array<climate_region_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<climate_region_enum>;
  _nin?: InputMaybe<Array<climate_region_enum>>;
};

/** input type for inserting data into table "climate_region" */
export type climate_region_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type climate_region_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type climate_region_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "climate_region" */
export type climate_region_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<climate_region>;
};

/** on_conflict condition type for table "climate_region" */
export type climate_region_on_conflict = {
  constraint: climate_region_constraint;
  update_columns?: Array<climate_region_update_column>;
  where?: InputMaybe<climate_region_bool_exp>;
};

/** Ordering options when selecting data from "climate_region". */
export type climate_region_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: climate_region */
export type climate_region_pk_columns_input = {
  id: Scalars['String'];
};

export { climate_region_select_column };

/** input type for updating data in table "climate_region" */
export type climate_region_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "climate_region" */
export type climate_region_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: climate_region_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type climate_region_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { climate_region_update_column };

export type climate_region_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<climate_region_set_input>;
  /** filter the rows which have to be updated */
  where: climate_region_bool_exp;
};

/** columns and relationships of "climate_risk" */
export type climate_risk = {
  /** An object relationship */
  building: building;
  building_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  timespan?: Maybe<Scalars['String']>;
  type: climate_risk_type_enum;
  uncertainty: Scalars['Int'];
  value: Scalars['Int'];
};

/** aggregated selection of "climate_risk" */
export type climate_risk_aggregate = {
  aggregate?: Maybe<climate_risk_aggregate_fields>;
  nodes: Array<climate_risk>;
};

export type climate_risk_aggregate_bool_exp = {
  count?: InputMaybe<climate_risk_aggregate_bool_exp_count>;
};

export type climate_risk_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<climate_risk_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<climate_risk_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "climate_risk" */
export type climate_risk_aggregate_fields = {
  avg?: Maybe<climate_risk_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<climate_risk_max_fields>;
  min?: Maybe<climate_risk_min_fields>;
  stddev?: Maybe<climate_risk_stddev_fields>;
  stddev_pop?: Maybe<climate_risk_stddev_pop_fields>;
  stddev_samp?: Maybe<climate_risk_stddev_samp_fields>;
  sum?: Maybe<climate_risk_sum_fields>;
  var_pop?: Maybe<climate_risk_var_pop_fields>;
  var_samp?: Maybe<climate_risk_var_samp_fields>;
  variance?: Maybe<climate_risk_variance_fields>;
};

/** aggregate fields of "climate_risk" */
export type climate_risk_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<climate_risk_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "climate_risk" */
export type climate_risk_aggregate_order_by = {
  avg?: InputMaybe<climate_risk_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<climate_risk_max_order_by>;
  min?: InputMaybe<climate_risk_min_order_by>;
  stddev?: InputMaybe<climate_risk_stddev_order_by>;
  stddev_pop?: InputMaybe<climate_risk_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<climate_risk_stddev_samp_order_by>;
  sum?: InputMaybe<climate_risk_sum_order_by>;
  var_pop?: InputMaybe<climate_risk_var_pop_order_by>;
  var_samp?: InputMaybe<climate_risk_var_samp_order_by>;
  variance?: InputMaybe<climate_risk_variance_order_by>;
};

/** input type for inserting array relation for remote table "climate_risk" */
export type climate_risk_arr_rel_insert_input = {
  data: Array<climate_risk_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<climate_risk_on_conflict>;
};

/** aggregate avg on columns */
export type climate_risk_avg_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uncertainty?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "climate_risk" */
export type climate_risk_avg_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  uncertainty?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "climate_risk". All fields are combined with a logical 'AND'. */
export type climate_risk_bool_exp = {
  _and?: InputMaybe<Array<climate_risk_bool_exp>>;
  _not?: InputMaybe<climate_risk_bool_exp>;
  _or?: InputMaybe<Array<climate_risk_bool_exp>>;
  building?: InputMaybe<building_bool_exp>;
  building_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  timespan?: InputMaybe<String_comparison_exp>;
  type?: InputMaybe<climate_risk_type_enum_comparison_exp>;
  uncertainty?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<Int_comparison_exp>;
};

export { climate_risk_constraint };

/** input type for incrementing numeric columns in table "climate_risk" */
export type climate_risk_inc_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  uncertainty?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "climate_risk" */
export type climate_risk_insert_input = {
  building?: InputMaybe<building_obj_rel_insert_input>;
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  timespan?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<climate_risk_type_enum>;
  uncertainty?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type climate_risk_max_fields = {
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  timespan?: Maybe<Scalars['String']>;
  uncertainty?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "climate_risk" */
export type climate_risk_max_order_by = {
  building_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  timespan?: InputMaybe<order_by>;
  uncertainty?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type climate_risk_min_fields = {
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  timespan?: Maybe<Scalars['String']>;
  uncertainty?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "climate_risk" */
export type climate_risk_min_order_by = {
  building_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  timespan?: InputMaybe<order_by>;
  uncertainty?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** response of any mutation on the table "climate_risk" */
export type climate_risk_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<climate_risk>;
};

/** on_conflict condition type for table "climate_risk" */
export type climate_risk_on_conflict = {
  constraint: climate_risk_constraint;
  update_columns?: Array<climate_risk_update_column>;
  where?: InputMaybe<climate_risk_bool_exp>;
};

/** Ordering options when selecting data from "climate_risk". */
export type climate_risk_order_by = {
  building?: InputMaybe<building_order_by>;
  building_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  timespan?: InputMaybe<order_by>;
  type?: InputMaybe<order_by>;
  uncertainty?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: climate_risk */
export type climate_risk_pk_columns_input = {
  id: Scalars['Int'];
};

export { climate_risk_select_column };

/** input type for updating data in table "climate_risk" */
export type climate_risk_set_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  timespan?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<climate_risk_type_enum>;
  uncertainty?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type climate_risk_stddev_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uncertainty?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "climate_risk" */
export type climate_risk_stddev_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  uncertainty?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type climate_risk_stddev_pop_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uncertainty?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "climate_risk" */
export type climate_risk_stddev_pop_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  uncertainty?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type climate_risk_stddev_samp_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uncertainty?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "climate_risk" */
export type climate_risk_stddev_samp_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  uncertainty?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "climate_risk" */
export type climate_risk_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: climate_risk_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type climate_risk_stream_cursor_value_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  timespan?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<climate_risk_type_enum>;
  uncertainty?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type climate_risk_sum_fields = {
  building_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  uncertainty?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "climate_risk" */
export type climate_risk_sum_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  uncertainty?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** Enum for tracking climate risk type */
export type climate_risk_type = {
  id: Scalars['String'];
};

/** aggregated selection of "climate_risk_type" */
export type climate_risk_type_aggregate = {
  aggregate?: Maybe<climate_risk_type_aggregate_fields>;
  nodes: Array<climate_risk_type>;
};

/** aggregate fields of "climate_risk_type" */
export type climate_risk_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<climate_risk_type_max_fields>;
  min?: Maybe<climate_risk_type_min_fields>;
};

/** aggregate fields of "climate_risk_type" */
export type climate_risk_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<climate_risk_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "climate_risk_type". All fields are combined with a logical 'AND'. */
export type climate_risk_type_bool_exp = {
  _and?: InputMaybe<Array<climate_risk_type_bool_exp>>;
  _not?: InputMaybe<climate_risk_type_bool_exp>;
  _or?: InputMaybe<Array<climate_risk_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { climate_risk_type_constraint };

export { climate_risk_type_enum };

/** Boolean expression to compare columns of type "climate_risk_type_enum". All fields are combined with logical 'AND'. */
export type climate_risk_type_enum_comparison_exp = {
  _eq?: InputMaybe<climate_risk_type_enum>;
  _in?: InputMaybe<Array<climate_risk_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<climate_risk_type_enum>;
  _nin?: InputMaybe<Array<climate_risk_type_enum>>;
};

/** input type for inserting data into table "climate_risk_type" */
export type climate_risk_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type climate_risk_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type climate_risk_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "climate_risk_type" */
export type climate_risk_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<climate_risk_type>;
};

/** on_conflict condition type for table "climate_risk_type" */
export type climate_risk_type_on_conflict = {
  constraint: climate_risk_type_constraint;
  update_columns?: Array<climate_risk_type_update_column>;
  where?: InputMaybe<climate_risk_type_bool_exp>;
};

/** Ordering options when selecting data from "climate_risk_type". */
export type climate_risk_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: climate_risk_type */
export type climate_risk_type_pk_columns_input = {
  id: Scalars['String'];
};

export { climate_risk_type_select_column };

/** input type for updating data in table "climate_risk_type" */
export type climate_risk_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "climate_risk_type" */
export type climate_risk_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: climate_risk_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type climate_risk_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { climate_risk_type_update_column };

export type climate_risk_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<climate_risk_type_set_input>;
  /** filter the rows which have to be updated */
  where: climate_risk_type_bool_exp;
};

export { climate_risk_update_column };

export type climate_risk_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<climate_risk_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<climate_risk_set_input>;
  /** filter the rows which have to be updated */
  where: climate_risk_bool_exp;
};

/** aggregate var_pop on columns */
export type climate_risk_var_pop_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uncertainty?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "climate_risk" */
export type climate_risk_var_pop_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  uncertainty?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type climate_risk_var_samp_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uncertainty?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "climate_risk" */
export type climate_risk_var_samp_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  uncertainty?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type climate_risk_variance_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uncertainty?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "climate_risk" */
export type climate_risk_variance_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  uncertainty?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** columns and relationships of "consumption" */
export type consumption = {
  /** An object relationship */
  area: area;
  area_id: Scalars['Int'];
  /** An object relationship */
  consumption_invoice?: Maybe<consumption_invoice>;
  consumption_type_id: consumption_type_enum;
  /** An object relationship */
  consumption_waste_detail?: Maybe<consumption_waste_details>;
  cost?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  created_by_user?: Maybe<user>;
  created_by_user_id?: Maybe<Scalars['Int']>;
  display_unit_value?: Maybe<consumption_unit_enum>;
  energy_source_type_id?: Maybe<energy_source_type_enum>;
  from: Scalars['timestamptz'];
  id: Scalars['Int'];
  invoice_date_deprecated?: Maybe<Scalars['timestamptz']>;
  invoice_file_id_deprecated?: Maybe<Scalars['Int']>;
  invoice_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  meter?: Maybe<meter>;
  meter_id?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  source_type_id: consumption_source_type_enum;
  /** An object relationship */
  sub_building: sub_building;
  sub_building_id: Scalars['Int'];
  sub_type_id?: Maybe<consumption_sub_type_enum>;
  to: Scalars['timestamptz'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "consumption" */
export type consumption_aggregate = {
  aggregate?: Maybe<consumption_aggregate_fields>;
  nodes: Array<consumption>;
};

export type consumption_aggregate_bool_exp = {
  count?: InputMaybe<consumption_aggregate_bool_exp_count>;
};

export type consumption_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<consumption_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<consumption_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "consumption" */
export type consumption_aggregate_fields = {
  avg?: Maybe<consumption_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<consumption_max_fields>;
  min?: Maybe<consumption_min_fields>;
  stddev?: Maybe<consumption_stddev_fields>;
  stddev_pop?: Maybe<consumption_stddev_pop_fields>;
  stddev_samp?: Maybe<consumption_stddev_samp_fields>;
  sum?: Maybe<consumption_sum_fields>;
  var_pop?: Maybe<consumption_var_pop_fields>;
  var_samp?: Maybe<consumption_var_samp_fields>;
  variance?: Maybe<consumption_variance_fields>;
};

/** aggregate fields of "consumption" */
export type consumption_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<consumption_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "consumption" */
export type consumption_aggregate_order_by = {
  avg?: InputMaybe<consumption_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<consumption_max_order_by>;
  min?: InputMaybe<consumption_min_order_by>;
  stddev?: InputMaybe<consumption_stddev_order_by>;
  stddev_pop?: InputMaybe<consumption_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<consumption_stddev_samp_order_by>;
  sum?: InputMaybe<consumption_sum_order_by>;
  var_pop?: InputMaybe<consumption_var_pop_order_by>;
  var_samp?: InputMaybe<consumption_var_samp_order_by>;
  variance?: InputMaybe<consumption_variance_order_by>;
};

/** input type for inserting array relation for remote table "consumption" */
export type consumption_arr_rel_insert_input = {
  data: Array<consumption_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<consumption_on_conflict>;
};

/** aggregate avg on columns */
export type consumption_avg_fields = {
  area_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  created_by_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_file_id_deprecated?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
  meter_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "consumption" */
export type consumption_avg_order_by = {
  area_id?: InputMaybe<order_by>;
  cost?: InputMaybe<order_by>;
  created_by_user_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_file_id_deprecated?: InputMaybe<order_by>;
  invoice_id?: InputMaybe<order_by>;
  meter_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "consumption". All fields are combined with a logical 'AND'. */
export type consumption_bool_exp = {
  _and?: InputMaybe<Array<consumption_bool_exp>>;
  _not?: InputMaybe<consumption_bool_exp>;
  _or?: InputMaybe<Array<consumption_bool_exp>>;
  area?: InputMaybe<area_bool_exp>;
  area_id?: InputMaybe<Int_comparison_exp>;
  consumption_invoice?: InputMaybe<consumption_invoice_bool_exp>;
  consumption_type_id?: InputMaybe<consumption_type_enum_comparison_exp>;
  consumption_waste_detail?: InputMaybe<consumption_waste_details_bool_exp>;
  cost?: InputMaybe<numeric_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  created_by_user?: InputMaybe<user_bool_exp>;
  created_by_user_id?: InputMaybe<Int_comparison_exp>;
  display_unit_value?: InputMaybe<consumption_unit_enum_comparison_exp>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum_comparison_exp>;
  from?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  invoice_date_deprecated?: InputMaybe<timestamptz_comparison_exp>;
  invoice_file_id_deprecated?: InputMaybe<Int_comparison_exp>;
  invoice_id?: InputMaybe<Int_comparison_exp>;
  meter?: InputMaybe<meter_bool_exp>;
  meter_id?: InputMaybe<Int_comparison_exp>;
  provider?: InputMaybe<String_comparison_exp>;
  source_type_id?: InputMaybe<consumption_source_type_enum_comparison_exp>;
  sub_building?: InputMaybe<sub_building_bool_exp>;
  sub_building_id?: InputMaybe<Int_comparison_exp>;
  sub_type_id?: InputMaybe<consumption_sub_type_enum_comparison_exp>;
  to?: InputMaybe<timestamptz_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  value?: InputMaybe<numeric_comparison_exp>;
};

export { consumption_constraint };

/** columns and relationships of "consumption_draft" */
export type consumption_draft = {
  /** An object relationship */
  area?: Maybe<area>;
  area_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  consumption_invoice_draft?: Maybe<consumption_invoice_draft>;
  consumption_type_id?: Maybe<consumption_type_enum>;
  /** An object relationship */
  consumption_waste_details_draft?: Maybe<consumption_waste_details_draft>;
  cost?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  created_by_user?: Maybe<user>;
  created_by_user_id?: Maybe<Scalars['Int']>;
  display_unit_value?: Maybe<consumption_unit_enum>;
  draft_status_deprecated?: Maybe<draft_status_enum>;
  energy_source_type_id?: Maybe<energy_source_type_enum>;
  from?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  invoice_date_deprecated?: Maybe<Scalars['timestamptz']>;
  invoice_draft_id?: Maybe<Scalars['Int']>;
  invoice_file_id_deprecated?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  source_type_id: consumption_source_type_enum;
  /** An object relationship */
  sub_building: sub_building;
  sub_building_id: Scalars['Int'];
  sub_type_id?: Maybe<consumption_sub_type_enum>;
  to?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "consumption_draft" */
export type consumption_draft_aggregate = {
  aggregate?: Maybe<consumption_draft_aggregate_fields>;
  nodes: Array<consumption_draft>;
};

export type consumption_draft_aggregate_bool_exp = {
  count?: InputMaybe<consumption_draft_aggregate_bool_exp_count>;
};

export type consumption_draft_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<consumption_draft_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<consumption_draft_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "consumption_draft" */
export type consumption_draft_aggregate_fields = {
  avg?: Maybe<consumption_draft_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<consumption_draft_max_fields>;
  min?: Maybe<consumption_draft_min_fields>;
  stddev?: Maybe<consumption_draft_stddev_fields>;
  stddev_pop?: Maybe<consumption_draft_stddev_pop_fields>;
  stddev_samp?: Maybe<consumption_draft_stddev_samp_fields>;
  sum?: Maybe<consumption_draft_sum_fields>;
  var_pop?: Maybe<consumption_draft_var_pop_fields>;
  var_samp?: Maybe<consumption_draft_var_samp_fields>;
  variance?: Maybe<consumption_draft_variance_fields>;
};

/** aggregate fields of "consumption_draft" */
export type consumption_draft_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<consumption_draft_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "consumption_draft" */
export type consumption_draft_aggregate_order_by = {
  avg?: InputMaybe<consumption_draft_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<consumption_draft_max_order_by>;
  min?: InputMaybe<consumption_draft_min_order_by>;
  stddev?: InputMaybe<consumption_draft_stddev_order_by>;
  stddev_pop?: InputMaybe<consumption_draft_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<consumption_draft_stddev_samp_order_by>;
  sum?: InputMaybe<consumption_draft_sum_order_by>;
  var_pop?: InputMaybe<consumption_draft_var_pop_order_by>;
  var_samp?: InputMaybe<consumption_draft_var_samp_order_by>;
  variance?: InputMaybe<consumption_draft_variance_order_by>;
};

/** input type for inserting array relation for remote table "consumption_draft" */
export type consumption_draft_arr_rel_insert_input = {
  data: Array<consumption_draft_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<consumption_draft_on_conflict>;
};

/** aggregate avg on columns */
export type consumption_draft_avg_fields = {
  area_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  created_by_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_draft_id?: Maybe<Scalars['Float']>;
  invoice_file_id_deprecated?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "consumption_draft" */
export type consumption_draft_avg_order_by = {
  area_id?: InputMaybe<order_by>;
  cost?: InputMaybe<order_by>;
  created_by_user_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_draft_id?: InputMaybe<order_by>;
  invoice_file_id_deprecated?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "consumption_draft". All fields are combined with a logical 'AND'. */
export type consumption_draft_bool_exp = {
  _and?: InputMaybe<Array<consumption_draft_bool_exp>>;
  _not?: InputMaybe<consumption_draft_bool_exp>;
  _or?: InputMaybe<Array<consumption_draft_bool_exp>>;
  area?: InputMaybe<area_bool_exp>;
  area_id?: InputMaybe<Int_comparison_exp>;
  consumption_invoice_draft?: InputMaybe<consumption_invoice_draft_bool_exp>;
  consumption_type_id?: InputMaybe<consumption_type_enum_comparison_exp>;
  consumption_waste_details_draft?: InputMaybe<consumption_waste_details_draft_bool_exp>;
  cost?: InputMaybe<numeric_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  created_by_user?: InputMaybe<user_bool_exp>;
  created_by_user_id?: InputMaybe<Int_comparison_exp>;
  display_unit_value?: InputMaybe<consumption_unit_enum_comparison_exp>;
  draft_status_deprecated?: InputMaybe<draft_status_enum_comparison_exp>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum_comparison_exp>;
  from?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  invoice_date_deprecated?: InputMaybe<timestamptz_comparison_exp>;
  invoice_draft_id?: InputMaybe<Int_comparison_exp>;
  invoice_file_id_deprecated?: InputMaybe<Int_comparison_exp>;
  provider?: InputMaybe<String_comparison_exp>;
  source_type_id?: InputMaybe<consumption_source_type_enum_comparison_exp>;
  sub_building?: InputMaybe<sub_building_bool_exp>;
  sub_building_id?: InputMaybe<Int_comparison_exp>;
  sub_type_id?: InputMaybe<consumption_sub_type_enum_comparison_exp>;
  to?: InputMaybe<timestamptz_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  value?: InputMaybe<numeric_comparison_exp>;
};

export { consumption_draft_constraint };

/** input type for incrementing numeric columns in table "consumption_draft" */
export type consumption_draft_inc_input = {
  area_id?: InputMaybe<Scalars['Int']>;
  cost?: InputMaybe<Scalars['numeric']>;
  created_by_user_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_draft_id?: InputMaybe<Scalars['Int']>;
  invoice_file_id_deprecated?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "consumption_draft" */
export type consumption_draft_insert_input = {
  area?: InputMaybe<area_obj_rel_insert_input>;
  area_id?: InputMaybe<Scalars['Int']>;
  consumption_invoice_draft?: InputMaybe<consumption_invoice_draft_obj_rel_insert_input>;
  consumption_type_id?: InputMaybe<consumption_type_enum>;
  consumption_waste_details_draft?: InputMaybe<consumption_waste_details_draft_obj_rel_insert_input>;
  cost?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_user?: InputMaybe<user_obj_rel_insert_input>;
  created_by_user_id?: InputMaybe<Scalars['Int']>;
  display_unit_value?: InputMaybe<consumption_unit_enum>;
  draft_status_deprecated?: InputMaybe<draft_status_enum>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum>;
  from?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_date_deprecated?: InputMaybe<Scalars['timestamptz']>;
  invoice_draft_id?: InputMaybe<Scalars['Int']>;
  invoice_file_id_deprecated?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Scalars['String']>;
  source_type_id?: InputMaybe<consumption_source_type_enum>;
  sub_building?: InputMaybe<sub_building_obj_rel_insert_input>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  sub_type_id?: InputMaybe<consumption_sub_type_enum>;
  to?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type consumption_draft_max_fields = {
  area_id?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_user_id?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  invoice_date_deprecated?: Maybe<Scalars['timestamptz']>;
  invoice_draft_id?: Maybe<Scalars['Int']>;
  invoice_file_id_deprecated?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  sub_building_id?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "consumption_draft" */
export type consumption_draft_max_order_by = {
  area_id?: InputMaybe<order_by>;
  cost?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  created_by_user_id?: InputMaybe<order_by>;
  from?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_date_deprecated?: InputMaybe<order_by>;
  invoice_draft_id?: InputMaybe<order_by>;
  invoice_file_id_deprecated?: InputMaybe<order_by>;
  provider?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  to?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type consumption_draft_min_fields = {
  area_id?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_user_id?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  invoice_date_deprecated?: Maybe<Scalars['timestamptz']>;
  invoice_draft_id?: Maybe<Scalars['Int']>;
  invoice_file_id_deprecated?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  sub_building_id?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "consumption_draft" */
export type consumption_draft_min_order_by = {
  area_id?: InputMaybe<order_by>;
  cost?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  created_by_user_id?: InputMaybe<order_by>;
  from?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_date_deprecated?: InputMaybe<order_by>;
  invoice_draft_id?: InputMaybe<order_by>;
  invoice_file_id_deprecated?: InputMaybe<order_by>;
  provider?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  to?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** response of any mutation on the table "consumption_draft" */
export type consumption_draft_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<consumption_draft>;
};

/** input type for inserting object relation for remote table "consumption_draft" */
export type consumption_draft_obj_rel_insert_input = {
  data: consumption_draft_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<consumption_draft_on_conflict>;
};

/** on_conflict condition type for table "consumption_draft" */
export type consumption_draft_on_conflict = {
  constraint: consumption_draft_constraint;
  update_columns?: Array<consumption_draft_update_column>;
  where?: InputMaybe<consumption_draft_bool_exp>;
};

/** Ordering options when selecting data from "consumption_draft". */
export type consumption_draft_order_by = {
  area?: InputMaybe<area_order_by>;
  area_id?: InputMaybe<order_by>;
  consumption_invoice_draft?: InputMaybe<consumption_invoice_draft_order_by>;
  consumption_type_id?: InputMaybe<order_by>;
  consumption_waste_details_draft?: InputMaybe<consumption_waste_details_draft_order_by>;
  cost?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  created_by_user?: InputMaybe<user_order_by>;
  created_by_user_id?: InputMaybe<order_by>;
  display_unit_value?: InputMaybe<order_by>;
  draft_status_deprecated?: InputMaybe<order_by>;
  energy_source_type_id?: InputMaybe<order_by>;
  from?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_date_deprecated?: InputMaybe<order_by>;
  invoice_draft_id?: InputMaybe<order_by>;
  invoice_file_id_deprecated?: InputMaybe<order_by>;
  provider?: InputMaybe<order_by>;
  source_type_id?: InputMaybe<order_by>;
  sub_building?: InputMaybe<sub_building_order_by>;
  sub_building_id?: InputMaybe<order_by>;
  sub_type_id?: InputMaybe<order_by>;
  to?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: consumption_draft */
export type consumption_draft_pk_columns_input = {
  id: Scalars['Int'];
};

export { consumption_draft_select_column };

/** input type for updating data in table "consumption_draft" */
export type consumption_draft_set_input = {
  area_id?: InputMaybe<Scalars['Int']>;
  consumption_type_id?: InputMaybe<consumption_type_enum>;
  cost?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_user_id?: InputMaybe<Scalars['Int']>;
  display_unit_value?: InputMaybe<consumption_unit_enum>;
  draft_status_deprecated?: InputMaybe<draft_status_enum>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum>;
  from?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_date_deprecated?: InputMaybe<Scalars['timestamptz']>;
  invoice_draft_id?: InputMaybe<Scalars['Int']>;
  invoice_file_id_deprecated?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Scalars['String']>;
  source_type_id?: InputMaybe<consumption_source_type_enum>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  sub_type_id?: InputMaybe<consumption_sub_type_enum>;
  to?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type consumption_draft_stddev_fields = {
  area_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  created_by_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_draft_id?: Maybe<Scalars['Float']>;
  invoice_file_id_deprecated?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "consumption_draft" */
export type consumption_draft_stddev_order_by = {
  area_id?: InputMaybe<order_by>;
  cost?: InputMaybe<order_by>;
  created_by_user_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_draft_id?: InputMaybe<order_by>;
  invoice_file_id_deprecated?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type consumption_draft_stddev_pop_fields = {
  area_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  created_by_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_draft_id?: Maybe<Scalars['Float']>;
  invoice_file_id_deprecated?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "consumption_draft" */
export type consumption_draft_stddev_pop_order_by = {
  area_id?: InputMaybe<order_by>;
  cost?: InputMaybe<order_by>;
  created_by_user_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_draft_id?: InputMaybe<order_by>;
  invoice_file_id_deprecated?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type consumption_draft_stddev_samp_fields = {
  area_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  created_by_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_draft_id?: Maybe<Scalars['Float']>;
  invoice_file_id_deprecated?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "consumption_draft" */
export type consumption_draft_stddev_samp_order_by = {
  area_id?: InputMaybe<order_by>;
  cost?: InputMaybe<order_by>;
  created_by_user_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_draft_id?: InputMaybe<order_by>;
  invoice_file_id_deprecated?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "consumption_draft" */
export type consumption_draft_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: consumption_draft_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type consumption_draft_stream_cursor_value_input = {
  area_id?: InputMaybe<Scalars['Int']>;
  consumption_type_id?: InputMaybe<consumption_type_enum>;
  cost?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_user_id?: InputMaybe<Scalars['Int']>;
  display_unit_value?: InputMaybe<consumption_unit_enum>;
  draft_status_deprecated?: InputMaybe<draft_status_enum>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum>;
  from?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_date_deprecated?: InputMaybe<Scalars['timestamptz']>;
  invoice_draft_id?: InputMaybe<Scalars['Int']>;
  invoice_file_id_deprecated?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Scalars['String']>;
  source_type_id?: InputMaybe<consumption_source_type_enum>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  sub_type_id?: InputMaybe<consumption_sub_type_enum>;
  to?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type consumption_draft_sum_fields = {
  area_id?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['numeric']>;
  created_by_user_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  invoice_draft_id?: Maybe<Scalars['Int']>;
  invoice_file_id_deprecated?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "consumption_draft" */
export type consumption_draft_sum_order_by = {
  area_id?: InputMaybe<order_by>;
  cost?: InputMaybe<order_by>;
  created_by_user_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_draft_id?: InputMaybe<order_by>;
  invoice_file_id_deprecated?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

export { consumption_draft_update_column };

export type consumption_draft_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<consumption_draft_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<consumption_draft_set_input>;
  /** filter the rows which have to be updated */
  where: consumption_draft_bool_exp;
};

/** aggregate var_pop on columns */
export type consumption_draft_var_pop_fields = {
  area_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  created_by_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_draft_id?: Maybe<Scalars['Float']>;
  invoice_file_id_deprecated?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "consumption_draft" */
export type consumption_draft_var_pop_order_by = {
  area_id?: InputMaybe<order_by>;
  cost?: InputMaybe<order_by>;
  created_by_user_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_draft_id?: InputMaybe<order_by>;
  invoice_file_id_deprecated?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type consumption_draft_var_samp_fields = {
  area_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  created_by_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_draft_id?: Maybe<Scalars['Float']>;
  invoice_file_id_deprecated?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "consumption_draft" */
export type consumption_draft_var_samp_order_by = {
  area_id?: InputMaybe<order_by>;
  cost?: InputMaybe<order_by>;
  created_by_user_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_draft_id?: InputMaybe<order_by>;
  invoice_file_id_deprecated?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type consumption_draft_variance_fields = {
  area_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  created_by_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_draft_id?: Maybe<Scalars['Float']>;
  invoice_file_id_deprecated?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "consumption_draft" */
export type consumption_draft_variance_order_by = {
  area_id?: InputMaybe<order_by>;
  cost?: InputMaybe<order_by>;
  created_by_user_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_draft_id?: InputMaybe<order_by>;
  invoice_file_id_deprecated?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** columns and relationships of "consumption_frequency_type" */
export type consumption_frequency_type = {
  id: Scalars['String'];
};

/** aggregated selection of "consumption_frequency_type" */
export type consumption_frequency_type_aggregate = {
  aggregate?: Maybe<consumption_frequency_type_aggregate_fields>;
  nodes: Array<consumption_frequency_type>;
};

/** aggregate fields of "consumption_frequency_type" */
export type consumption_frequency_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<consumption_frequency_type_max_fields>;
  min?: Maybe<consumption_frequency_type_min_fields>;
};

/** aggregate fields of "consumption_frequency_type" */
export type consumption_frequency_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<consumption_frequency_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "consumption_frequency_type". All fields are combined with a logical 'AND'. */
export type consumption_frequency_type_bool_exp = {
  _and?: InputMaybe<Array<consumption_frequency_type_bool_exp>>;
  _not?: InputMaybe<consumption_frequency_type_bool_exp>;
  _or?: InputMaybe<Array<consumption_frequency_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { consumption_frequency_type_constraint };

export { consumption_frequency_type_enum };

/** Boolean expression to compare columns of type "consumption_frequency_type_enum". All fields are combined with logical 'AND'. */
export type consumption_frequency_type_enum_comparison_exp = {
  _eq?: InputMaybe<consumption_frequency_type_enum>;
  _in?: InputMaybe<Array<consumption_frequency_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<consumption_frequency_type_enum>;
  _nin?: InputMaybe<Array<consumption_frequency_type_enum>>;
};

/** input type for inserting data into table "consumption_frequency_type" */
export type consumption_frequency_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type consumption_frequency_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type consumption_frequency_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "consumption_frequency_type" */
export type consumption_frequency_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<consumption_frequency_type>;
};

/** on_conflict condition type for table "consumption_frequency_type" */
export type consumption_frequency_type_on_conflict = {
  constraint: consumption_frequency_type_constraint;
  update_columns?: Array<consumption_frequency_type_update_column>;
  where?: InputMaybe<consumption_frequency_type_bool_exp>;
};

/** Ordering options when selecting data from "consumption_frequency_type". */
export type consumption_frequency_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: consumption_frequency_type */
export type consumption_frequency_type_pk_columns_input = {
  id: Scalars['String'];
};

export { consumption_frequency_type_select_column };

/** input type for updating data in table "consumption_frequency_type" */
export type consumption_frequency_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "consumption_frequency_type" */
export type consumption_frequency_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: consumption_frequency_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type consumption_frequency_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { consumption_frequency_type_update_column };

export type consumption_frequency_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<consumption_frequency_type_set_input>;
  /** filter the rows which have to be updated */
  where: consumption_frequency_type_bool_exp;
};

/** input type for incrementing numeric columns in table "consumption" */
export type consumption_inc_input = {
  area_id?: InputMaybe<Scalars['Int']>;
  cost?: InputMaybe<Scalars['numeric']>;
  created_by_user_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_file_id_deprecated?: InputMaybe<Scalars['Int']>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  meter_id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "consumption" */
export type consumption_insert_input = {
  area?: InputMaybe<area_obj_rel_insert_input>;
  area_id?: InputMaybe<Scalars['Int']>;
  consumption_invoice?: InputMaybe<consumption_invoice_obj_rel_insert_input>;
  consumption_type_id?: InputMaybe<consumption_type_enum>;
  consumption_waste_detail?: InputMaybe<consumption_waste_details_obj_rel_insert_input>;
  cost?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_user?: InputMaybe<user_obj_rel_insert_input>;
  created_by_user_id?: InputMaybe<Scalars['Int']>;
  display_unit_value?: InputMaybe<consumption_unit_enum>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum>;
  from?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_date_deprecated?: InputMaybe<Scalars['timestamptz']>;
  invoice_file_id_deprecated?: InputMaybe<Scalars['Int']>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  meter?: InputMaybe<meter_obj_rel_insert_input>;
  meter_id?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Scalars['String']>;
  source_type_id?: InputMaybe<consumption_source_type_enum>;
  sub_building?: InputMaybe<sub_building_obj_rel_insert_input>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  sub_type_id?: InputMaybe<consumption_sub_type_enum>;
  to?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** relates to a file and bundles meta information for many consumptions */
export type consumption_invoice = {
  /** An object relationship */
  building: building;
  building_id: Scalars['Int'];
  /** An array relationship */
  consumptions: Array<consumption>;
  /** An aggregate relationship */
  consumptions_aggregate: consumption_aggregate;
  created_at: Scalars['timestamptz'];
  date: Scalars['timestamptz'];
  /** An object relationship */
  file?: Maybe<file>;
  file_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  invoice_payer: invoice_payer_type_enum;
  updated_at: Scalars['timestamptz'];
};

/** relates to a file and bundles meta information for many consumptions */
export type consumption_invoiceconsumptionsArgs = {
  distinct_on?: InputMaybe<Array<consumption_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_order_by>>;
  where?: InputMaybe<consumption_bool_exp>;
};

/** relates to a file and bundles meta information for many consumptions */
export type consumption_invoiceconsumptions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_order_by>>;
  where?: InputMaybe<consumption_bool_exp>;
};

/** aggregated selection of "consumption_invoice" */
export type consumption_invoice_aggregate = {
  aggregate?: Maybe<consumption_invoice_aggregate_fields>;
  nodes: Array<consumption_invoice>;
};

export type consumption_invoice_aggregate_bool_exp = {
  count?: InputMaybe<consumption_invoice_aggregate_bool_exp_count>;
};

export type consumption_invoice_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<consumption_invoice_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<consumption_invoice_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "consumption_invoice" */
export type consumption_invoice_aggregate_fields = {
  avg?: Maybe<consumption_invoice_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<consumption_invoice_max_fields>;
  min?: Maybe<consumption_invoice_min_fields>;
  stddev?: Maybe<consumption_invoice_stddev_fields>;
  stddev_pop?: Maybe<consumption_invoice_stddev_pop_fields>;
  stddev_samp?: Maybe<consumption_invoice_stddev_samp_fields>;
  sum?: Maybe<consumption_invoice_sum_fields>;
  var_pop?: Maybe<consumption_invoice_var_pop_fields>;
  var_samp?: Maybe<consumption_invoice_var_samp_fields>;
  variance?: Maybe<consumption_invoice_variance_fields>;
};

/** aggregate fields of "consumption_invoice" */
export type consumption_invoice_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<consumption_invoice_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "consumption_invoice" */
export type consumption_invoice_aggregate_order_by = {
  avg?: InputMaybe<consumption_invoice_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<consumption_invoice_max_order_by>;
  min?: InputMaybe<consumption_invoice_min_order_by>;
  stddev?: InputMaybe<consumption_invoice_stddev_order_by>;
  stddev_pop?: InputMaybe<consumption_invoice_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<consumption_invoice_stddev_samp_order_by>;
  sum?: InputMaybe<consumption_invoice_sum_order_by>;
  var_pop?: InputMaybe<consumption_invoice_var_pop_order_by>;
  var_samp?: InputMaybe<consumption_invoice_var_samp_order_by>;
  variance?: InputMaybe<consumption_invoice_variance_order_by>;
};

/** input type for inserting array relation for remote table "consumption_invoice" */
export type consumption_invoice_arr_rel_insert_input = {
  data: Array<consumption_invoice_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<consumption_invoice_on_conflict>;
};

/** aggregate avg on columns */
export type consumption_invoice_avg_fields = {
  building_id?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "consumption_invoice" */
export type consumption_invoice_avg_order_by = {
  building_id?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "consumption_invoice". All fields are combined with a logical 'AND'. */
export type consumption_invoice_bool_exp = {
  _and?: InputMaybe<Array<consumption_invoice_bool_exp>>;
  _not?: InputMaybe<consumption_invoice_bool_exp>;
  _or?: InputMaybe<Array<consumption_invoice_bool_exp>>;
  building?: InputMaybe<building_bool_exp>;
  building_id?: InputMaybe<Int_comparison_exp>;
  consumptions?: InputMaybe<consumption_bool_exp>;
  consumptions_aggregate?: InputMaybe<consumption_aggregate_bool_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  date?: InputMaybe<timestamptz_comparison_exp>;
  file?: InputMaybe<file_bool_exp>;
  file_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  invoice_payer?: InputMaybe<invoice_payer_type_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

export { consumption_invoice_constraint };

/** relates to a file and bundles nullable meta information for many consumptions, is converted to an invoice */
export type consumption_invoice_draft = {
  /** An object relationship */
  building: building;
  building_id: Scalars['Int'];
  /** An array relationship */
  consumption_drafts: Array<consumption_draft>;
  /** An aggregate relationship */
  consumption_drafts_aggregate: consumption_draft_aggregate;
  created_at: Scalars['timestamptz'];
  date?: Maybe<Scalars['timestamptz']>;
  draft_status: draft_status_enum;
  /** An object relationship */
  file?: Maybe<file>;
  file_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  invoice_payer?: Maybe<invoice_payer_type_enum>;
  updated_at: Scalars['timestamptz'];
};

/** relates to a file and bundles nullable meta information for many consumptions, is converted to an invoice */
export type consumption_invoice_draftconsumption_draftsArgs = {
  distinct_on?: InputMaybe<Array<consumption_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_draft_order_by>>;
  where?: InputMaybe<consumption_draft_bool_exp>;
};

/** relates to a file and bundles nullable meta information for many consumptions, is converted to an invoice */
export type consumption_invoice_draftconsumption_drafts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_draft_order_by>>;
  where?: InputMaybe<consumption_draft_bool_exp>;
};

/** aggregated selection of "consumption_invoice_draft" */
export type consumption_invoice_draft_aggregate = {
  aggregate?: Maybe<consumption_invoice_draft_aggregate_fields>;
  nodes: Array<consumption_invoice_draft>;
};

export type consumption_invoice_draft_aggregate_bool_exp = {
  count?: InputMaybe<consumption_invoice_draft_aggregate_bool_exp_count>;
};

export type consumption_invoice_draft_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<consumption_invoice_draft_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<consumption_invoice_draft_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "consumption_invoice_draft" */
export type consumption_invoice_draft_aggregate_fields = {
  avg?: Maybe<consumption_invoice_draft_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<consumption_invoice_draft_max_fields>;
  min?: Maybe<consumption_invoice_draft_min_fields>;
  stddev?: Maybe<consumption_invoice_draft_stddev_fields>;
  stddev_pop?: Maybe<consumption_invoice_draft_stddev_pop_fields>;
  stddev_samp?: Maybe<consumption_invoice_draft_stddev_samp_fields>;
  sum?: Maybe<consumption_invoice_draft_sum_fields>;
  var_pop?: Maybe<consumption_invoice_draft_var_pop_fields>;
  var_samp?: Maybe<consumption_invoice_draft_var_samp_fields>;
  variance?: Maybe<consumption_invoice_draft_variance_fields>;
};

/** aggregate fields of "consumption_invoice_draft" */
export type consumption_invoice_draft_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<consumption_invoice_draft_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "consumption_invoice_draft" */
export type consumption_invoice_draft_aggregate_order_by = {
  avg?: InputMaybe<consumption_invoice_draft_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<consumption_invoice_draft_max_order_by>;
  min?: InputMaybe<consumption_invoice_draft_min_order_by>;
  stddev?: InputMaybe<consumption_invoice_draft_stddev_order_by>;
  stddev_pop?: InputMaybe<consumption_invoice_draft_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<consumption_invoice_draft_stddev_samp_order_by>;
  sum?: InputMaybe<consumption_invoice_draft_sum_order_by>;
  var_pop?: InputMaybe<consumption_invoice_draft_var_pop_order_by>;
  var_samp?: InputMaybe<consumption_invoice_draft_var_samp_order_by>;
  variance?: InputMaybe<consumption_invoice_draft_variance_order_by>;
};

/** input type for inserting array relation for remote table "consumption_invoice_draft" */
export type consumption_invoice_draft_arr_rel_insert_input = {
  data: Array<consumption_invoice_draft_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<consumption_invoice_draft_on_conflict>;
};

/** aggregate avg on columns */
export type consumption_invoice_draft_avg_fields = {
  building_id?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "consumption_invoice_draft" */
export type consumption_invoice_draft_avg_order_by = {
  building_id?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "consumption_invoice_draft". All fields are combined with a logical 'AND'. */
export type consumption_invoice_draft_bool_exp = {
  _and?: InputMaybe<Array<consumption_invoice_draft_bool_exp>>;
  _not?: InputMaybe<consumption_invoice_draft_bool_exp>;
  _or?: InputMaybe<Array<consumption_invoice_draft_bool_exp>>;
  building?: InputMaybe<building_bool_exp>;
  building_id?: InputMaybe<Int_comparison_exp>;
  consumption_drafts?: InputMaybe<consumption_draft_bool_exp>;
  consumption_drafts_aggregate?: InputMaybe<consumption_draft_aggregate_bool_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  date?: InputMaybe<timestamptz_comparison_exp>;
  draft_status?: InputMaybe<draft_status_enum_comparison_exp>;
  file?: InputMaybe<file_bool_exp>;
  file_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  invoice_payer?: InputMaybe<invoice_payer_type_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

export { consumption_invoice_draft_constraint };

/** input type for incrementing numeric columns in table "consumption_invoice_draft" */
export type consumption_invoice_draft_inc_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "consumption_invoice_draft" */
export type consumption_invoice_draft_insert_input = {
  building?: InputMaybe<building_obj_rel_insert_input>;
  building_id?: InputMaybe<Scalars['Int']>;
  consumption_drafts?: InputMaybe<consumption_draft_arr_rel_insert_input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  draft_status?: InputMaybe<draft_status_enum>;
  file?: InputMaybe<file_obj_rel_insert_input>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_payer?: InputMaybe<invoice_payer_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type consumption_invoice_draft_max_fields = {
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "consumption_invoice_draft" */
export type consumption_invoice_draft_max_order_by = {
  building_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type consumption_invoice_draft_min_fields = {
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "consumption_invoice_draft" */
export type consumption_invoice_draft_min_order_by = {
  building_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "consumption_invoice_draft" */
export type consumption_invoice_draft_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<consumption_invoice_draft>;
};

/** input type for inserting object relation for remote table "consumption_invoice_draft" */
export type consumption_invoice_draft_obj_rel_insert_input = {
  data: consumption_invoice_draft_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<consumption_invoice_draft_on_conflict>;
};

/** on_conflict condition type for table "consumption_invoice_draft" */
export type consumption_invoice_draft_on_conflict = {
  constraint: consumption_invoice_draft_constraint;
  update_columns?: Array<consumption_invoice_draft_update_column>;
  where?: InputMaybe<consumption_invoice_draft_bool_exp>;
};

/** Ordering options when selecting data from "consumption_invoice_draft". */
export type consumption_invoice_draft_order_by = {
  building?: InputMaybe<building_order_by>;
  building_id?: InputMaybe<order_by>;
  consumption_drafts_aggregate?: InputMaybe<consumption_draft_aggregate_order_by>;
  created_at?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  draft_status?: InputMaybe<order_by>;
  file?: InputMaybe<file_order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_payer?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: consumption_invoice_draft */
export type consumption_invoice_draft_pk_columns_input = {
  id: Scalars['Int'];
};

export { consumption_invoice_draft_select_column };

/** input type for updating data in table "consumption_invoice_draft" */
export type consumption_invoice_draft_set_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  draft_status?: InputMaybe<draft_status_enum>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_payer?: InputMaybe<invoice_payer_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type consumption_invoice_draft_stddev_fields = {
  building_id?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "consumption_invoice_draft" */
export type consumption_invoice_draft_stddev_order_by = {
  building_id?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type consumption_invoice_draft_stddev_pop_fields = {
  building_id?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "consumption_invoice_draft" */
export type consumption_invoice_draft_stddev_pop_order_by = {
  building_id?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type consumption_invoice_draft_stddev_samp_fields = {
  building_id?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "consumption_invoice_draft" */
export type consumption_invoice_draft_stddev_samp_order_by = {
  building_id?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "consumption_invoice_draft" */
export type consumption_invoice_draft_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: consumption_invoice_draft_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type consumption_invoice_draft_stream_cursor_value_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  draft_status?: InputMaybe<draft_status_enum>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_payer?: InputMaybe<invoice_payer_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type consumption_invoice_draft_sum_fields = {
  building_id?: Maybe<Scalars['Int']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "consumption_invoice_draft" */
export type consumption_invoice_draft_sum_order_by = {
  building_id?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

export { consumption_invoice_draft_update_column };

export type consumption_invoice_draft_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<consumption_invoice_draft_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<consumption_invoice_draft_set_input>;
  /** filter the rows which have to be updated */
  where: consumption_invoice_draft_bool_exp;
};

/** aggregate var_pop on columns */
export type consumption_invoice_draft_var_pop_fields = {
  building_id?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "consumption_invoice_draft" */
export type consumption_invoice_draft_var_pop_order_by = {
  building_id?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type consumption_invoice_draft_var_samp_fields = {
  building_id?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "consumption_invoice_draft" */
export type consumption_invoice_draft_var_samp_order_by = {
  building_id?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type consumption_invoice_draft_variance_fields = {
  building_id?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "consumption_invoice_draft" */
export type consumption_invoice_draft_variance_order_by = {
  building_id?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** input type for incrementing numeric columns in table "consumption_invoice" */
export type consumption_invoice_inc_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "consumption_invoice" */
export type consumption_invoice_insert_input = {
  building?: InputMaybe<building_obj_rel_insert_input>;
  building_id?: InputMaybe<Scalars['Int']>;
  consumptions?: InputMaybe<consumption_arr_rel_insert_input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  file?: InputMaybe<file_obj_rel_insert_input>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_payer?: InputMaybe<invoice_payer_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type consumption_invoice_max_fields = {
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "consumption_invoice" */
export type consumption_invoice_max_order_by = {
  building_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type consumption_invoice_min_fields = {
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "consumption_invoice" */
export type consumption_invoice_min_order_by = {
  building_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "consumption_invoice" */
export type consumption_invoice_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<consumption_invoice>;
};

/** input type for inserting object relation for remote table "consumption_invoice" */
export type consumption_invoice_obj_rel_insert_input = {
  data: consumption_invoice_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<consumption_invoice_on_conflict>;
};

/** on_conflict condition type for table "consumption_invoice" */
export type consumption_invoice_on_conflict = {
  constraint: consumption_invoice_constraint;
  update_columns?: Array<consumption_invoice_update_column>;
  where?: InputMaybe<consumption_invoice_bool_exp>;
};

/** Ordering options when selecting data from "consumption_invoice". */
export type consumption_invoice_order_by = {
  building?: InputMaybe<building_order_by>;
  building_id?: InputMaybe<order_by>;
  consumptions_aggregate?: InputMaybe<consumption_aggregate_order_by>;
  created_at?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  file?: InputMaybe<file_order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_payer?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: consumption_invoice */
export type consumption_invoice_pk_columns_input = {
  id: Scalars['Int'];
};

export { consumption_invoice_select_column };

/** input type for updating data in table "consumption_invoice" */
export type consumption_invoice_set_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_payer?: InputMaybe<invoice_payer_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type consumption_invoice_stddev_fields = {
  building_id?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "consumption_invoice" */
export type consumption_invoice_stddev_order_by = {
  building_id?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type consumption_invoice_stddev_pop_fields = {
  building_id?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "consumption_invoice" */
export type consumption_invoice_stddev_pop_order_by = {
  building_id?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type consumption_invoice_stddev_samp_fields = {
  building_id?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "consumption_invoice" */
export type consumption_invoice_stddev_samp_order_by = {
  building_id?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "consumption_invoice" */
export type consumption_invoice_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: consumption_invoice_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type consumption_invoice_stream_cursor_value_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_payer?: InputMaybe<invoice_payer_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type consumption_invoice_sum_fields = {
  building_id?: Maybe<Scalars['Int']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "consumption_invoice" */
export type consumption_invoice_sum_order_by = {
  building_id?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

export { consumption_invoice_update_column };

export type consumption_invoice_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<consumption_invoice_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<consumption_invoice_set_input>;
  /** filter the rows which have to be updated */
  where: consumption_invoice_bool_exp;
};

/** aggregate var_pop on columns */
export type consumption_invoice_var_pop_fields = {
  building_id?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "consumption_invoice" */
export type consumption_invoice_var_pop_order_by = {
  building_id?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type consumption_invoice_var_samp_fields = {
  building_id?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "consumption_invoice" */
export type consumption_invoice_var_samp_order_by = {
  building_id?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type consumption_invoice_variance_fields = {
  building_id?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "consumption_invoice" */
export type consumption_invoice_variance_order_by = {
  building_id?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate max on columns */
export type consumption_max_fields = {
  area_id?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_user_id?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  invoice_date_deprecated?: Maybe<Scalars['timestamptz']>;
  invoice_file_id_deprecated?: Maybe<Scalars['Int']>;
  invoice_id?: Maybe<Scalars['Int']>;
  meter_id?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  sub_building_id?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "consumption" */
export type consumption_max_order_by = {
  area_id?: InputMaybe<order_by>;
  cost?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  created_by_user_id?: InputMaybe<order_by>;
  from?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_date_deprecated?: InputMaybe<order_by>;
  invoice_file_id_deprecated?: InputMaybe<order_by>;
  invoice_id?: InputMaybe<order_by>;
  meter_id?: InputMaybe<order_by>;
  provider?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  to?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type consumption_min_fields = {
  area_id?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_user_id?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  invoice_date_deprecated?: Maybe<Scalars['timestamptz']>;
  invoice_file_id_deprecated?: Maybe<Scalars['Int']>;
  invoice_id?: Maybe<Scalars['Int']>;
  meter_id?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  sub_building_id?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "consumption" */
export type consumption_min_order_by = {
  area_id?: InputMaybe<order_by>;
  cost?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  created_by_user_id?: InputMaybe<order_by>;
  from?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_date_deprecated?: InputMaybe<order_by>;
  invoice_file_id_deprecated?: InputMaybe<order_by>;
  invoice_id?: InputMaybe<order_by>;
  meter_id?: InputMaybe<order_by>;
  provider?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  to?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** response of any mutation on the table "consumption" */
export type consumption_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<consumption>;
};

/** input type for inserting object relation for remote table "consumption" */
export type consumption_obj_rel_insert_input = {
  data: consumption_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<consumption_on_conflict>;
};

/** on_conflict condition type for table "consumption" */
export type consumption_on_conflict = {
  constraint: consumption_constraint;
  update_columns?: Array<consumption_update_column>;
  where?: InputMaybe<consumption_bool_exp>;
};

/** Ordering options when selecting data from "consumption". */
export type consumption_order_by = {
  area?: InputMaybe<area_order_by>;
  area_id?: InputMaybe<order_by>;
  consumption_invoice?: InputMaybe<consumption_invoice_order_by>;
  consumption_type_id?: InputMaybe<order_by>;
  consumption_waste_detail?: InputMaybe<consumption_waste_details_order_by>;
  cost?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  created_by_user?: InputMaybe<user_order_by>;
  created_by_user_id?: InputMaybe<order_by>;
  display_unit_value?: InputMaybe<order_by>;
  energy_source_type_id?: InputMaybe<order_by>;
  from?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_date_deprecated?: InputMaybe<order_by>;
  invoice_file_id_deprecated?: InputMaybe<order_by>;
  invoice_id?: InputMaybe<order_by>;
  meter?: InputMaybe<meter_order_by>;
  meter_id?: InputMaybe<order_by>;
  provider?: InputMaybe<order_by>;
  source_type_id?: InputMaybe<order_by>;
  sub_building?: InputMaybe<sub_building_order_by>;
  sub_building_id?: InputMaybe<order_by>;
  sub_type_id?: InputMaybe<order_by>;
  to?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: consumption */
export type consumption_pk_columns_input = {
  id: Scalars['Int'];
};

export { consumption_select_column };

/** input type for updating data in table "consumption" */
export type consumption_set_input = {
  area_id?: InputMaybe<Scalars['Int']>;
  consumption_type_id?: InputMaybe<consumption_type_enum>;
  cost?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_user_id?: InputMaybe<Scalars['Int']>;
  display_unit_value?: InputMaybe<consumption_unit_enum>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum>;
  from?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_date_deprecated?: InputMaybe<Scalars['timestamptz']>;
  invoice_file_id_deprecated?: InputMaybe<Scalars['Int']>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  meter_id?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Scalars['String']>;
  source_type_id?: InputMaybe<consumption_source_type_enum>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  sub_type_id?: InputMaybe<consumption_sub_type_enum>;
  to?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** Enum table for where consumption data is coming from */
export type consumption_source_type = {
  id: Scalars['String'];
};

/** aggregated selection of "consumption_source_type" */
export type consumption_source_type_aggregate = {
  aggregate?: Maybe<consumption_source_type_aggregate_fields>;
  nodes: Array<consumption_source_type>;
};

/** aggregate fields of "consumption_source_type" */
export type consumption_source_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<consumption_source_type_max_fields>;
  min?: Maybe<consumption_source_type_min_fields>;
};

/** aggregate fields of "consumption_source_type" */
export type consumption_source_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<consumption_source_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "consumption_source_type". All fields are combined with a logical 'AND'. */
export type consumption_source_type_bool_exp = {
  _and?: InputMaybe<Array<consumption_source_type_bool_exp>>;
  _not?: InputMaybe<consumption_source_type_bool_exp>;
  _or?: InputMaybe<Array<consumption_source_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { consumption_source_type_constraint };

export { consumption_source_type_enum };

/** Boolean expression to compare columns of type "consumption_source_type_enum". All fields are combined with logical 'AND'. */
export type consumption_source_type_enum_comparison_exp = {
  _eq?: InputMaybe<consumption_source_type_enum>;
  _in?: InputMaybe<Array<consumption_source_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<consumption_source_type_enum>;
  _nin?: InputMaybe<Array<consumption_source_type_enum>>;
};

/** input type for inserting data into table "consumption_source_type" */
export type consumption_source_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type consumption_source_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type consumption_source_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "consumption_source_type" */
export type consumption_source_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<consumption_source_type>;
};

/** on_conflict condition type for table "consumption_source_type" */
export type consumption_source_type_on_conflict = {
  constraint: consumption_source_type_constraint;
  update_columns?: Array<consumption_source_type_update_column>;
  where?: InputMaybe<consumption_source_type_bool_exp>;
};

/** Ordering options when selecting data from "consumption_source_type". */
export type consumption_source_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: consumption_source_type */
export type consumption_source_type_pk_columns_input = {
  id: Scalars['String'];
};

export { consumption_source_type_select_column };

/** input type for updating data in table "consumption_source_type" */
export type consumption_source_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "consumption_source_type" */
export type consumption_source_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: consumption_source_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type consumption_source_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { consumption_source_type_update_column };

export type consumption_source_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<consumption_source_type_set_input>;
  /** filter the rows which have to be updated */
  where: consumption_source_type_bool_exp;
};

/** aggregate stddev on columns */
export type consumption_stddev_fields = {
  area_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  created_by_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_file_id_deprecated?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
  meter_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "consumption" */
export type consumption_stddev_order_by = {
  area_id?: InputMaybe<order_by>;
  cost?: InputMaybe<order_by>;
  created_by_user_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_file_id_deprecated?: InputMaybe<order_by>;
  invoice_id?: InputMaybe<order_by>;
  meter_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type consumption_stddev_pop_fields = {
  area_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  created_by_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_file_id_deprecated?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
  meter_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "consumption" */
export type consumption_stddev_pop_order_by = {
  area_id?: InputMaybe<order_by>;
  cost?: InputMaybe<order_by>;
  created_by_user_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_file_id_deprecated?: InputMaybe<order_by>;
  invoice_id?: InputMaybe<order_by>;
  meter_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type consumption_stddev_samp_fields = {
  area_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  created_by_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_file_id_deprecated?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
  meter_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "consumption" */
export type consumption_stddev_samp_order_by = {
  area_id?: InputMaybe<order_by>;
  cost?: InputMaybe<order_by>;
  created_by_user_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_file_id_deprecated?: InputMaybe<order_by>;
  invoice_id?: InputMaybe<order_by>;
  meter_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "consumption" */
export type consumption_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: consumption_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type consumption_stream_cursor_value_input = {
  area_id?: InputMaybe<Scalars['Int']>;
  consumption_type_id?: InputMaybe<consumption_type_enum>;
  cost?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_user_id?: InputMaybe<Scalars['Int']>;
  display_unit_value?: InputMaybe<consumption_unit_enum>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum>;
  from?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_date_deprecated?: InputMaybe<Scalars['timestamptz']>;
  invoice_file_id_deprecated?: InputMaybe<Scalars['Int']>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  meter_id?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Scalars['String']>;
  source_type_id?: InputMaybe<consumption_source_type_enum>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  sub_type_id?: InputMaybe<consumption_sub_type_enum>;
  to?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** categorization for consumption types WATER and WASTE */
export type consumption_sub_type = {
  id: Scalars['String'];
};

/** aggregated selection of "consumption_sub_type" */
export type consumption_sub_type_aggregate = {
  aggregate?: Maybe<consumption_sub_type_aggregate_fields>;
  nodes: Array<consumption_sub_type>;
};

/** aggregate fields of "consumption_sub_type" */
export type consumption_sub_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<consumption_sub_type_max_fields>;
  min?: Maybe<consumption_sub_type_min_fields>;
};

/** aggregate fields of "consumption_sub_type" */
export type consumption_sub_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<consumption_sub_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "consumption_sub_type". All fields are combined with a logical 'AND'. */
export type consumption_sub_type_bool_exp = {
  _and?: InputMaybe<Array<consumption_sub_type_bool_exp>>;
  _not?: InputMaybe<consumption_sub_type_bool_exp>;
  _or?: InputMaybe<Array<consumption_sub_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { consumption_sub_type_constraint };

export { consumption_sub_type_enum };

/** Boolean expression to compare columns of type "consumption_sub_type_enum". All fields are combined with logical 'AND'. */
export type consumption_sub_type_enum_comparison_exp = {
  _eq?: InputMaybe<consumption_sub_type_enum>;
  _in?: InputMaybe<Array<consumption_sub_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<consumption_sub_type_enum>;
  _nin?: InputMaybe<Array<consumption_sub_type_enum>>;
};

/** input type for inserting data into table "consumption_sub_type" */
export type consumption_sub_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type consumption_sub_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type consumption_sub_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "consumption_sub_type" */
export type consumption_sub_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<consumption_sub_type>;
};

/** on_conflict condition type for table "consumption_sub_type" */
export type consumption_sub_type_on_conflict = {
  constraint: consumption_sub_type_constraint;
  update_columns?: Array<consumption_sub_type_update_column>;
  where?: InputMaybe<consumption_sub_type_bool_exp>;
};

/** Ordering options when selecting data from "consumption_sub_type". */
export type consumption_sub_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: consumption_sub_type */
export type consumption_sub_type_pk_columns_input = {
  id: Scalars['String'];
};

export { consumption_sub_type_select_column };

/** input type for updating data in table "consumption_sub_type" */
export type consumption_sub_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "consumption_sub_type" */
export type consumption_sub_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: consumption_sub_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type consumption_sub_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { consumption_sub_type_update_column };

export type consumption_sub_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<consumption_sub_type_set_input>;
  /** filter the rows which have to be updated */
  where: consumption_sub_type_bool_exp;
};

/** aggregate sum on columns */
export type consumption_sum_fields = {
  area_id?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['numeric']>;
  created_by_user_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  invoice_file_id_deprecated?: Maybe<Scalars['Int']>;
  invoice_id?: Maybe<Scalars['Int']>;
  meter_id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "consumption" */
export type consumption_sum_order_by = {
  area_id?: InputMaybe<order_by>;
  cost?: InputMaybe<order_by>;
  created_by_user_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_file_id_deprecated?: InputMaybe<order_by>;
  invoice_id?: InputMaybe<order_by>;
  meter_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** columns and relationships of "consumption_type" */
export type consumption_type = {
  id: Scalars['String'];
};

/** aggregated selection of "consumption_type" */
export type consumption_type_aggregate = {
  aggregate?: Maybe<consumption_type_aggregate_fields>;
  nodes: Array<consumption_type>;
};

/** aggregate fields of "consumption_type" */
export type consumption_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<consumption_type_max_fields>;
  min?: Maybe<consumption_type_min_fields>;
};

/** aggregate fields of "consumption_type" */
export type consumption_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<consumption_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "consumption_type". All fields are combined with a logical 'AND'. */
export type consumption_type_bool_exp = {
  _and?: InputMaybe<Array<consumption_type_bool_exp>>;
  _not?: InputMaybe<consumption_type_bool_exp>;
  _or?: InputMaybe<Array<consumption_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { consumption_type_constraint };

export { consumption_type_enum };

/** Boolean expression to compare columns of type "consumption_type_enum". All fields are combined with logical 'AND'. */
export type consumption_type_enum_comparison_exp = {
  _eq?: InputMaybe<consumption_type_enum>;
  _in?: InputMaybe<Array<consumption_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<consumption_type_enum>;
  _nin?: InputMaybe<Array<consumption_type_enum>>;
};

/** input type for inserting data into table "consumption_type" */
export type consumption_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type consumption_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type consumption_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "consumption_type" */
export type consumption_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<consumption_type>;
};

/** on_conflict condition type for table "consumption_type" */
export type consumption_type_on_conflict = {
  constraint: consumption_type_constraint;
  update_columns?: Array<consumption_type_update_column>;
  where?: InputMaybe<consumption_type_bool_exp>;
};

/** Ordering options when selecting data from "consumption_type". */
export type consumption_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: consumption_type */
export type consumption_type_pk_columns_input = {
  id: Scalars['String'];
};

export { consumption_type_select_column };

/** input type for updating data in table "consumption_type" */
export type consumption_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "consumption_type" */
export type consumption_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: consumption_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type consumption_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { consumption_type_update_column };

export type consumption_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<consumption_type_set_input>;
  /** filter the rows which have to be updated */
  where: consumption_type_bool_exp;
};

/** columns and relationships of "consumption_unit" */
export type consumption_unit = {
  id: Scalars['String'];
};

/** aggregated selection of "consumption_unit" */
export type consumption_unit_aggregate = {
  aggregate?: Maybe<consumption_unit_aggregate_fields>;
  nodes: Array<consumption_unit>;
};

/** aggregate fields of "consumption_unit" */
export type consumption_unit_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<consumption_unit_max_fields>;
  min?: Maybe<consumption_unit_min_fields>;
};

/** aggregate fields of "consumption_unit" */
export type consumption_unit_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<consumption_unit_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "consumption_unit". All fields are combined with a logical 'AND'. */
export type consumption_unit_bool_exp = {
  _and?: InputMaybe<Array<consumption_unit_bool_exp>>;
  _not?: InputMaybe<consumption_unit_bool_exp>;
  _or?: InputMaybe<Array<consumption_unit_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { consumption_unit_constraint };

export { consumption_unit_enum };

/** Boolean expression to compare columns of type "consumption_unit_enum". All fields are combined with logical 'AND'. */
export type consumption_unit_enum_comparison_exp = {
  _eq?: InputMaybe<consumption_unit_enum>;
  _in?: InputMaybe<Array<consumption_unit_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<consumption_unit_enum>;
  _nin?: InputMaybe<Array<consumption_unit_enum>>;
};

/** input type for inserting data into table "consumption_unit" */
export type consumption_unit_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type consumption_unit_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type consumption_unit_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "consumption_unit" */
export type consumption_unit_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<consumption_unit>;
};

/** on_conflict condition type for table "consumption_unit" */
export type consumption_unit_on_conflict = {
  constraint: consumption_unit_constraint;
  update_columns?: Array<consumption_unit_update_column>;
  where?: InputMaybe<consumption_unit_bool_exp>;
};

/** Ordering options when selecting data from "consumption_unit". */
export type consumption_unit_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: consumption_unit */
export type consumption_unit_pk_columns_input = {
  id: Scalars['String'];
};

export { consumption_unit_select_column };

/** input type for updating data in table "consumption_unit" */
export type consumption_unit_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "consumption_unit" */
export type consumption_unit_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: consumption_unit_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type consumption_unit_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { consumption_unit_update_column };

export type consumption_unit_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<consumption_unit_set_input>;
  /** filter the rows which have to be updated */
  where: consumption_unit_bool_exp;
};

export { consumption_update_column };

export type consumption_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<consumption_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<consumption_set_input>;
  /** filter the rows which have to be updated */
  where: consumption_bool_exp;
};

/** aggregate var_pop on columns */
export type consumption_var_pop_fields = {
  area_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  created_by_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_file_id_deprecated?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
  meter_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "consumption" */
export type consumption_var_pop_order_by = {
  area_id?: InputMaybe<order_by>;
  cost?: InputMaybe<order_by>;
  created_by_user_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_file_id_deprecated?: InputMaybe<order_by>;
  invoice_id?: InputMaybe<order_by>;
  meter_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type consumption_var_samp_fields = {
  area_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  created_by_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_file_id_deprecated?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
  meter_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "consumption" */
export type consumption_var_samp_order_by = {
  area_id?: InputMaybe<order_by>;
  cost?: InputMaybe<order_by>;
  created_by_user_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_file_id_deprecated?: InputMaybe<order_by>;
  invoice_id?: InputMaybe<order_by>;
  meter_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type consumption_variance_fields = {
  area_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  created_by_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_file_id_deprecated?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
  meter_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "consumption" */
export type consumption_variance_order_by = {
  area_id?: InputMaybe<order_by>;
  cost?: InputMaybe<order_by>;
  created_by_user_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_file_id_deprecated?: InputMaybe<order_by>;
  invoice_id?: InputMaybe<order_by>;
  meter_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** waste specific consumtion date, created from a draft */
export type consumption_waste_details = {
  amount: Scalars['numeric'];
  /** An object relationship */
  consumption: consumption;
  consumption_id: Scalars['Int'];
  display_unit_frequency?: Maybe<consumption_unit_enum>;
  display_unit_volume?: Maybe<consumption_unit_enum>;
  frequency: Scalars['numeric'];
  id: Scalars['Int'];
  price: Scalars['numeric'];
  volume: Scalars['numeric'];
};

/** aggregated selection of "consumption_waste_details" */
export type consumption_waste_details_aggregate = {
  aggregate?: Maybe<consumption_waste_details_aggregate_fields>;
  nodes: Array<consumption_waste_details>;
};

/** aggregate fields of "consumption_waste_details" */
export type consumption_waste_details_aggregate_fields = {
  avg?: Maybe<consumption_waste_details_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<consumption_waste_details_max_fields>;
  min?: Maybe<consumption_waste_details_min_fields>;
  stddev?: Maybe<consumption_waste_details_stddev_fields>;
  stddev_pop?: Maybe<consumption_waste_details_stddev_pop_fields>;
  stddev_samp?: Maybe<consumption_waste_details_stddev_samp_fields>;
  sum?: Maybe<consumption_waste_details_sum_fields>;
  var_pop?: Maybe<consumption_waste_details_var_pop_fields>;
  var_samp?: Maybe<consumption_waste_details_var_samp_fields>;
  variance?: Maybe<consumption_waste_details_variance_fields>;
};

/** aggregate fields of "consumption_waste_details" */
export type consumption_waste_details_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<consumption_waste_details_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type consumption_waste_details_avg_fields = {
  amount?: Maybe<Scalars['Float']>;
  consumption_id?: Maybe<Scalars['Float']>;
  frequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "consumption_waste_details". All fields are combined with a logical 'AND'. */
export type consumption_waste_details_bool_exp = {
  _and?: InputMaybe<Array<consumption_waste_details_bool_exp>>;
  _not?: InputMaybe<consumption_waste_details_bool_exp>;
  _or?: InputMaybe<Array<consumption_waste_details_bool_exp>>;
  amount?: InputMaybe<numeric_comparison_exp>;
  consumption?: InputMaybe<consumption_bool_exp>;
  consumption_id?: InputMaybe<Int_comparison_exp>;
  display_unit_frequency?: InputMaybe<consumption_unit_enum_comparison_exp>;
  display_unit_volume?: InputMaybe<consumption_unit_enum_comparison_exp>;
  frequency?: InputMaybe<numeric_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  price?: InputMaybe<numeric_comparison_exp>;
  volume?: InputMaybe<numeric_comparison_exp>;
};

export { consumption_waste_details_constraint };

/** waste specific consumption date, a draft that will eventually be converted. */
export type consumption_waste_details_draft = {
  amount?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  consumption_draft: consumption_draft;
  consumption_draft_id: Scalars['Int'];
  display_unit_frequency?: Maybe<consumption_unit_enum>;
  display_unit_volume?: Maybe<consumption_unit_enum>;
  frequency?: Maybe<Scalars['numeric']>;
  id: Scalars['Int'];
  price?: Maybe<Scalars['numeric']>;
  volume?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "consumption_waste_details_draft" */
export type consumption_waste_details_draft_aggregate = {
  aggregate?: Maybe<consumption_waste_details_draft_aggregate_fields>;
  nodes: Array<consumption_waste_details_draft>;
};

/** aggregate fields of "consumption_waste_details_draft" */
export type consumption_waste_details_draft_aggregate_fields = {
  avg?: Maybe<consumption_waste_details_draft_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<consumption_waste_details_draft_max_fields>;
  min?: Maybe<consumption_waste_details_draft_min_fields>;
  stddev?: Maybe<consumption_waste_details_draft_stddev_fields>;
  stddev_pop?: Maybe<consumption_waste_details_draft_stddev_pop_fields>;
  stddev_samp?: Maybe<consumption_waste_details_draft_stddev_samp_fields>;
  sum?: Maybe<consumption_waste_details_draft_sum_fields>;
  var_pop?: Maybe<consumption_waste_details_draft_var_pop_fields>;
  var_samp?: Maybe<consumption_waste_details_draft_var_samp_fields>;
  variance?: Maybe<consumption_waste_details_draft_variance_fields>;
};

/** aggregate fields of "consumption_waste_details_draft" */
export type consumption_waste_details_draft_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<consumption_waste_details_draft_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type consumption_waste_details_draft_avg_fields = {
  amount?: Maybe<Scalars['Float']>;
  consumption_draft_id?: Maybe<Scalars['Float']>;
  frequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "consumption_waste_details_draft". All fields are combined with a logical 'AND'. */
export type consumption_waste_details_draft_bool_exp = {
  _and?: InputMaybe<Array<consumption_waste_details_draft_bool_exp>>;
  _not?: InputMaybe<consumption_waste_details_draft_bool_exp>;
  _or?: InputMaybe<Array<consumption_waste_details_draft_bool_exp>>;
  amount?: InputMaybe<numeric_comparison_exp>;
  consumption_draft?: InputMaybe<consumption_draft_bool_exp>;
  consumption_draft_id?: InputMaybe<Int_comparison_exp>;
  display_unit_frequency?: InputMaybe<consumption_unit_enum_comparison_exp>;
  display_unit_volume?: InputMaybe<consumption_unit_enum_comparison_exp>;
  frequency?: InputMaybe<numeric_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  price?: InputMaybe<numeric_comparison_exp>;
  volume?: InputMaybe<numeric_comparison_exp>;
};

export { consumption_waste_details_draft_constraint };

/** input type for incrementing numeric columns in table "consumption_waste_details_draft" */
export type consumption_waste_details_draft_inc_input = {
  amount?: InputMaybe<Scalars['numeric']>;
  consumption_draft_id?: InputMaybe<Scalars['Int']>;
  frequency?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  volume?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "consumption_waste_details_draft" */
export type consumption_waste_details_draft_insert_input = {
  amount?: InputMaybe<Scalars['numeric']>;
  consumption_draft?: InputMaybe<consumption_draft_obj_rel_insert_input>;
  consumption_draft_id?: InputMaybe<Scalars['Int']>;
  display_unit_frequency?: InputMaybe<consumption_unit_enum>;
  display_unit_volume?: InputMaybe<consumption_unit_enum>;
  frequency?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  volume?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type consumption_waste_details_draft_max_fields = {
  amount?: Maybe<Scalars['numeric']>;
  consumption_draft_id?: Maybe<Scalars['Int']>;
  frequency?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  volume?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type consumption_waste_details_draft_min_fields = {
  amount?: Maybe<Scalars['numeric']>;
  consumption_draft_id?: Maybe<Scalars['Int']>;
  frequency?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  volume?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "consumption_waste_details_draft" */
export type consumption_waste_details_draft_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<consumption_waste_details_draft>;
};

/** input type for inserting object relation for remote table "consumption_waste_details_draft" */
export type consumption_waste_details_draft_obj_rel_insert_input = {
  data: consumption_waste_details_draft_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<consumption_waste_details_draft_on_conflict>;
};

/** on_conflict condition type for table "consumption_waste_details_draft" */
export type consumption_waste_details_draft_on_conflict = {
  constraint: consumption_waste_details_draft_constraint;
  update_columns?: Array<consumption_waste_details_draft_update_column>;
  where?: InputMaybe<consumption_waste_details_draft_bool_exp>;
};

/** Ordering options when selecting data from "consumption_waste_details_draft". */
export type consumption_waste_details_draft_order_by = {
  amount?: InputMaybe<order_by>;
  consumption_draft?: InputMaybe<consumption_draft_order_by>;
  consumption_draft_id?: InputMaybe<order_by>;
  display_unit_frequency?: InputMaybe<order_by>;
  display_unit_volume?: InputMaybe<order_by>;
  frequency?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  volume?: InputMaybe<order_by>;
};

/** primary key columns input for table: consumption_waste_details_draft */
export type consumption_waste_details_draft_pk_columns_input = {
  id: Scalars['Int'];
};

export { consumption_waste_details_draft_select_column };

/** input type for updating data in table "consumption_waste_details_draft" */
export type consumption_waste_details_draft_set_input = {
  amount?: InputMaybe<Scalars['numeric']>;
  consumption_draft_id?: InputMaybe<Scalars['Int']>;
  display_unit_frequency?: InputMaybe<consumption_unit_enum>;
  display_unit_volume?: InputMaybe<consumption_unit_enum>;
  frequency?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  volume?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type consumption_waste_details_draft_stddev_fields = {
  amount?: Maybe<Scalars['Float']>;
  consumption_draft_id?: Maybe<Scalars['Float']>;
  frequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type consumption_waste_details_draft_stddev_pop_fields = {
  amount?: Maybe<Scalars['Float']>;
  consumption_draft_id?: Maybe<Scalars['Float']>;
  frequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type consumption_waste_details_draft_stddev_samp_fields = {
  amount?: Maybe<Scalars['Float']>;
  consumption_draft_id?: Maybe<Scalars['Float']>;
  frequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "consumption_waste_details_draft" */
export type consumption_waste_details_draft_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: consumption_waste_details_draft_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type consumption_waste_details_draft_stream_cursor_value_input = {
  amount?: InputMaybe<Scalars['numeric']>;
  consumption_draft_id?: InputMaybe<Scalars['Int']>;
  display_unit_frequency?: InputMaybe<consumption_unit_enum>;
  display_unit_volume?: InputMaybe<consumption_unit_enum>;
  frequency?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  volume?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type consumption_waste_details_draft_sum_fields = {
  amount?: Maybe<Scalars['numeric']>;
  consumption_draft_id?: Maybe<Scalars['Int']>;
  frequency?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  volume?: Maybe<Scalars['numeric']>;
};

export { consumption_waste_details_draft_update_column };

export type consumption_waste_details_draft_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<consumption_waste_details_draft_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<consumption_waste_details_draft_set_input>;
  /** filter the rows which have to be updated */
  where: consumption_waste_details_draft_bool_exp;
};

/** aggregate var_pop on columns */
export type consumption_waste_details_draft_var_pop_fields = {
  amount?: Maybe<Scalars['Float']>;
  consumption_draft_id?: Maybe<Scalars['Float']>;
  frequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type consumption_waste_details_draft_var_samp_fields = {
  amount?: Maybe<Scalars['Float']>;
  consumption_draft_id?: Maybe<Scalars['Float']>;
  frequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type consumption_waste_details_draft_variance_fields = {
  amount?: Maybe<Scalars['Float']>;
  consumption_draft_id?: Maybe<Scalars['Float']>;
  frequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "consumption_waste_details" */
export type consumption_waste_details_inc_input = {
  amount?: InputMaybe<Scalars['numeric']>;
  consumption_id?: InputMaybe<Scalars['Int']>;
  frequency?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  volume?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "consumption_waste_details" */
export type consumption_waste_details_insert_input = {
  amount?: InputMaybe<Scalars['numeric']>;
  consumption?: InputMaybe<consumption_obj_rel_insert_input>;
  consumption_id?: InputMaybe<Scalars['Int']>;
  display_unit_frequency?: InputMaybe<consumption_unit_enum>;
  display_unit_volume?: InputMaybe<consumption_unit_enum>;
  frequency?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  volume?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type consumption_waste_details_max_fields = {
  amount?: Maybe<Scalars['numeric']>;
  consumption_id?: Maybe<Scalars['Int']>;
  frequency?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  volume?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type consumption_waste_details_min_fields = {
  amount?: Maybe<Scalars['numeric']>;
  consumption_id?: Maybe<Scalars['Int']>;
  frequency?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  volume?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "consumption_waste_details" */
export type consumption_waste_details_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<consumption_waste_details>;
};

/** input type for inserting object relation for remote table "consumption_waste_details" */
export type consumption_waste_details_obj_rel_insert_input = {
  data: consumption_waste_details_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<consumption_waste_details_on_conflict>;
};

/** on_conflict condition type for table "consumption_waste_details" */
export type consumption_waste_details_on_conflict = {
  constraint: consumption_waste_details_constraint;
  update_columns?: Array<consumption_waste_details_update_column>;
  where?: InputMaybe<consumption_waste_details_bool_exp>;
};

/** Ordering options when selecting data from "consumption_waste_details". */
export type consumption_waste_details_order_by = {
  amount?: InputMaybe<order_by>;
  consumption?: InputMaybe<consumption_order_by>;
  consumption_id?: InputMaybe<order_by>;
  display_unit_frequency?: InputMaybe<order_by>;
  display_unit_volume?: InputMaybe<order_by>;
  frequency?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  volume?: InputMaybe<order_by>;
};

/** primary key columns input for table: consumption_waste_details */
export type consumption_waste_details_pk_columns_input = {
  id: Scalars['Int'];
};

export { consumption_waste_details_select_column };

/** input type for updating data in table "consumption_waste_details" */
export type consumption_waste_details_set_input = {
  amount?: InputMaybe<Scalars['numeric']>;
  consumption_id?: InputMaybe<Scalars['Int']>;
  display_unit_frequency?: InputMaybe<consumption_unit_enum>;
  display_unit_volume?: InputMaybe<consumption_unit_enum>;
  frequency?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  volume?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type consumption_waste_details_stddev_fields = {
  amount?: Maybe<Scalars['Float']>;
  consumption_id?: Maybe<Scalars['Float']>;
  frequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type consumption_waste_details_stddev_pop_fields = {
  amount?: Maybe<Scalars['Float']>;
  consumption_id?: Maybe<Scalars['Float']>;
  frequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type consumption_waste_details_stddev_samp_fields = {
  amount?: Maybe<Scalars['Float']>;
  consumption_id?: Maybe<Scalars['Float']>;
  frequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "consumption_waste_details" */
export type consumption_waste_details_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: consumption_waste_details_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type consumption_waste_details_stream_cursor_value_input = {
  amount?: InputMaybe<Scalars['numeric']>;
  consumption_id?: InputMaybe<Scalars['Int']>;
  display_unit_frequency?: InputMaybe<consumption_unit_enum>;
  display_unit_volume?: InputMaybe<consumption_unit_enum>;
  frequency?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  volume?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type consumption_waste_details_sum_fields = {
  amount?: Maybe<Scalars['numeric']>;
  consumption_id?: Maybe<Scalars['Int']>;
  frequency?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  volume?: Maybe<Scalars['numeric']>;
};

export { consumption_waste_details_update_column };

export type consumption_waste_details_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<consumption_waste_details_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<consumption_waste_details_set_input>;
  /** filter the rows which have to be updated */
  where: consumption_waste_details_bool_exp;
};

/** aggregate var_pop on columns */
export type consumption_waste_details_var_pop_fields = {
  amount?: Maybe<Scalars['Float']>;
  consumption_id?: Maybe<Scalars['Float']>;
  frequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type consumption_waste_details_var_samp_fields = {
  amount?: Maybe<Scalars['Float']>;
  consumption_id?: Maybe<Scalars['Float']>;
  frequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type consumption_waste_details_variance_fields = {
  amount?: Maybe<Scalars['Float']>;
  consumption_id?: Maybe<Scalars['Float']>;
  frequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "country" */
export type country = {
  id: Scalars['String'];
};

/** aggregated selection of "country" */
export type country_aggregate = {
  aggregate?: Maybe<country_aggregate_fields>;
  nodes: Array<country>;
};

/** aggregate fields of "country" */
export type country_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<country_max_fields>;
  min?: Maybe<country_min_fields>;
};

/** aggregate fields of "country" */
export type country_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<country_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "country". All fields are combined with a logical 'AND'. */
export type country_bool_exp = {
  _and?: InputMaybe<Array<country_bool_exp>>;
  _not?: InputMaybe<country_bool_exp>;
  _or?: InputMaybe<Array<country_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { country_constraint };

export { country_enum };

/** Boolean expression to compare columns of type "country_enum". All fields are combined with logical 'AND'. */
export type country_enum_comparison_exp = {
  _eq?: InputMaybe<country_enum>;
  _in?: InputMaybe<Array<country_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<country_enum>;
  _nin?: InputMaybe<Array<country_enum>>;
};

/** input type for inserting data into table "country" */
export type country_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type country_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type country_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "country" */
export type country_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<country>;
};

/** on_conflict condition type for table "country" */
export type country_on_conflict = {
  constraint: country_constraint;
  update_columns?: Array<country_update_column>;
  where?: InputMaybe<country_bool_exp>;
};

/** Ordering options when selecting data from "country". */
export type country_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: country */
export type country_pk_columns_input = {
  id: Scalars['String'];
};

export { country_select_column };

/** input type for updating data in table "country" */
export type country_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "country_state" */
export type country_state = {
  id: Scalars['String'];
};

/** aggregated selection of "country_state" */
export type country_state_aggregate = {
  aggregate?: Maybe<country_state_aggregate_fields>;
  nodes: Array<country_state>;
};

/** aggregate fields of "country_state" */
export type country_state_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<country_state_max_fields>;
  min?: Maybe<country_state_min_fields>;
};

/** aggregate fields of "country_state" */
export type country_state_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<country_state_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "country_state". All fields are combined with a logical 'AND'. */
export type country_state_bool_exp = {
  _and?: InputMaybe<Array<country_state_bool_exp>>;
  _not?: InputMaybe<country_state_bool_exp>;
  _or?: InputMaybe<Array<country_state_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { country_state_constraint };

export { country_state_enum };

/** Boolean expression to compare columns of type "country_state_enum". All fields are combined with logical 'AND'. */
export type country_state_enum_comparison_exp = {
  _eq?: InputMaybe<country_state_enum>;
  _in?: InputMaybe<Array<country_state_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<country_state_enum>;
  _nin?: InputMaybe<Array<country_state_enum>>;
};

/** input type for inserting data into table "country_state" */
export type country_state_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type country_state_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type country_state_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "country_state" */
export type country_state_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<country_state>;
};

/** on_conflict condition type for table "country_state" */
export type country_state_on_conflict = {
  constraint: country_state_constraint;
  update_columns?: Array<country_state_update_column>;
  where?: InputMaybe<country_state_bool_exp>;
};

/** Ordering options when selecting data from "country_state". */
export type country_state_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: country_state */
export type country_state_pk_columns_input = {
  id: Scalars['String'];
};

export { country_state_select_column };

/** input type for updating data in table "country_state" */
export type country_state_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "country_state" */
export type country_state_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: country_state_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type country_state_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { country_state_update_column };

export type country_state_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<country_state_set_input>;
  /** filter the rows which have to be updated */
  where: country_state_bool_exp;
};

/** Streaming cursor of the table "country" */
export type country_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: country_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type country_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { country_update_column };

export type country_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<country_set_input>;
  /** filter the rows which have to be updated */
  where: country_bool_exp;
};

export { cursor_ordering };

/** columns and relationships of "data_source_type" */
export type data_source_type = {
  id: Scalars['String'];
};

/** aggregated selection of "data_source_type" */
export type data_source_type_aggregate = {
  aggregate?: Maybe<data_source_type_aggregate_fields>;
  nodes: Array<data_source_type>;
};

/** aggregate fields of "data_source_type" */
export type data_source_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<data_source_type_max_fields>;
  min?: Maybe<data_source_type_min_fields>;
};

/** aggregate fields of "data_source_type" */
export type data_source_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<data_source_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "data_source_type". All fields are combined with a logical 'AND'. */
export type data_source_type_bool_exp = {
  _and?: InputMaybe<Array<data_source_type_bool_exp>>;
  _not?: InputMaybe<data_source_type_bool_exp>;
  _or?: InputMaybe<Array<data_source_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { data_source_type_constraint };

export { data_source_type_enum };

/** Boolean expression to compare columns of type "data_source_type_enum". All fields are combined with logical 'AND'. */
export type data_source_type_enum_comparison_exp = {
  _eq?: InputMaybe<data_source_type_enum>;
  _in?: InputMaybe<Array<data_source_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<data_source_type_enum>;
  _nin?: InputMaybe<Array<data_source_type_enum>>;
};

/** input type for inserting data into table "data_source_type" */
export type data_source_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type data_source_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type data_source_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "data_source_type" */
export type data_source_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<data_source_type>;
};

/** on_conflict condition type for table "data_source_type" */
export type data_source_type_on_conflict = {
  constraint: data_source_type_constraint;
  update_columns?: Array<data_source_type_update_column>;
  where?: InputMaybe<data_source_type_bool_exp>;
};

/** Ordering options when selecting data from "data_source_type". */
export type data_source_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: data_source_type */
export type data_source_type_pk_columns_input = {
  id: Scalars['String'];
};

export { data_source_type_select_column };

/** input type for updating data in table "data_source_type" */
export type data_source_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "data_source_type" */
export type data_source_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: data_source_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type data_source_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { data_source_type_update_column };

export type data_source_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<data_source_type_set_input>;
  /** filter the rows which have to be updated */
  where: data_source_type_bool_exp;
};

/** columns and relationships of "draft_status" */
export type draft_status = {
  id: Scalars['String'];
};

/** aggregated selection of "draft_status" */
export type draft_status_aggregate = {
  aggregate?: Maybe<draft_status_aggregate_fields>;
  nodes: Array<draft_status>;
};

/** aggregate fields of "draft_status" */
export type draft_status_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<draft_status_max_fields>;
  min?: Maybe<draft_status_min_fields>;
};

/** aggregate fields of "draft_status" */
export type draft_status_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<draft_status_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "draft_status". All fields are combined with a logical 'AND'. */
export type draft_status_bool_exp = {
  _and?: InputMaybe<Array<draft_status_bool_exp>>;
  _not?: InputMaybe<draft_status_bool_exp>;
  _or?: InputMaybe<Array<draft_status_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { draft_status_constraint };

export { draft_status_enum };

/** Boolean expression to compare columns of type "draft_status_enum". All fields are combined with logical 'AND'. */
export type draft_status_enum_comparison_exp = {
  _eq?: InputMaybe<draft_status_enum>;
  _in?: InputMaybe<Array<draft_status_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<draft_status_enum>;
  _nin?: InputMaybe<Array<draft_status_enum>>;
};

/** input type for inserting data into table "draft_status" */
export type draft_status_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type draft_status_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type draft_status_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "draft_status" */
export type draft_status_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<draft_status>;
};

/** input type for inserting object relation for remote table "draft_status" */
export type draft_status_obj_rel_insert_input = {
  data: draft_status_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<draft_status_on_conflict>;
};

/** on_conflict condition type for table "draft_status" */
export type draft_status_on_conflict = {
  constraint: draft_status_constraint;
  update_columns?: Array<draft_status_update_column>;
  where?: InputMaybe<draft_status_bool_exp>;
};

/** Ordering options when selecting data from "draft_status". */
export type draft_status_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: draft_status */
export type draft_status_pk_columns_input = {
  id: Scalars['String'];
};

export { draft_status_select_column };

/** input type for updating data in table "draft_status" */
export type draft_status_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "draft_status" */
export type draft_status_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: draft_status_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type draft_status_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { draft_status_update_column };

export type draft_status_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<draft_status_set_input>;
  /** filter the rows which have to be updated */
  where: draft_status_bool_exp;
};

/** columns and relationships of "economic_unit" */
export type economic_unit = {
  associatedBuildingsAndDrafts: EconomicUnitAmount;
  /** An array relationship */
  buildings: Array<building>;
  /** An aggregate relationship */
  buildings_aggregate: building_aggregate;
  created_at: Scalars['timestamptz'];
  customer_economic_unit_id?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  portfolio: portfolio;
  portfolio_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "economic_unit" */
export type economic_unitbuildingsArgs = {
  distinct_on?: InputMaybe<Array<building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<building_order_by>>;
  where?: InputMaybe<building_bool_exp>;
};

/** columns and relationships of "economic_unit" */
export type economic_unitbuildings_aggregateArgs = {
  distinct_on?: InputMaybe<Array<building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<building_order_by>>;
  where?: InputMaybe<building_bool_exp>;
};

/** aggregated selection of "economic_unit" */
export type economic_unit_aggregate = {
  aggregate?: Maybe<economic_unit_aggregate_fields>;
  nodes: Array<economic_unit>;
};

export type economic_unit_aggregate_bool_exp = {
  count?: InputMaybe<economic_unit_aggregate_bool_exp_count>;
};

export type economic_unit_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<economic_unit_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<economic_unit_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "economic_unit" */
export type economic_unit_aggregate_fields = {
  avg?: Maybe<economic_unit_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<economic_unit_max_fields>;
  min?: Maybe<economic_unit_min_fields>;
  stddev?: Maybe<economic_unit_stddev_fields>;
  stddev_pop?: Maybe<economic_unit_stddev_pop_fields>;
  stddev_samp?: Maybe<economic_unit_stddev_samp_fields>;
  sum?: Maybe<economic_unit_sum_fields>;
  var_pop?: Maybe<economic_unit_var_pop_fields>;
  var_samp?: Maybe<economic_unit_var_samp_fields>;
  variance?: Maybe<economic_unit_variance_fields>;
};

/** aggregate fields of "economic_unit" */
export type economic_unit_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<economic_unit_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "economic_unit" */
export type economic_unit_aggregate_order_by = {
  avg?: InputMaybe<economic_unit_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<economic_unit_max_order_by>;
  min?: InputMaybe<economic_unit_min_order_by>;
  stddev?: InputMaybe<economic_unit_stddev_order_by>;
  stddev_pop?: InputMaybe<economic_unit_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<economic_unit_stddev_samp_order_by>;
  sum?: InputMaybe<economic_unit_sum_order_by>;
  var_pop?: InputMaybe<economic_unit_var_pop_order_by>;
  var_samp?: InputMaybe<economic_unit_var_samp_order_by>;
  variance?: InputMaybe<economic_unit_variance_order_by>;
};

/** input type for inserting array relation for remote table "economic_unit" */
export type economic_unit_arr_rel_insert_input = {
  data: Array<economic_unit_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<economic_unit_on_conflict>;
};

/** aggregate avg on columns */
export type economic_unit_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "economic_unit" */
export type economic_unit_avg_order_by = {
  id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "economic_unit". All fields are combined with a logical 'AND'. */
export type economic_unit_bool_exp = {
  _and?: InputMaybe<Array<economic_unit_bool_exp>>;
  _not?: InputMaybe<economic_unit_bool_exp>;
  _or?: InputMaybe<Array<economic_unit_bool_exp>>;
  buildings?: InputMaybe<building_bool_exp>;
  buildings_aggregate?: InputMaybe<building_aggregate_bool_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  customer_economic_unit_id?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  portfolio?: InputMaybe<portfolio_bool_exp>;
  portfolio_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

export { economic_unit_constraint };

/** input type for incrementing numeric columns in table "economic_unit" */
export type economic_unit_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  portfolio_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "economic_unit" */
export type economic_unit_insert_input = {
  buildings?: InputMaybe<building_arr_rel_insert_input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_economic_unit_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  portfolio?: InputMaybe<portfolio_obj_rel_insert_input>;
  portfolio_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type economic_unit_max_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_economic_unit_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  portfolio_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "economic_unit" */
export type economic_unit_max_order_by = {
  created_at?: InputMaybe<order_by>;
  customer_economic_unit_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type economic_unit_min_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_economic_unit_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  portfolio_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "economic_unit" */
export type economic_unit_min_order_by = {
  created_at?: InputMaybe<order_by>;
  customer_economic_unit_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "economic_unit" */
export type economic_unit_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<economic_unit>;
};

/** input type for inserting object relation for remote table "economic_unit" */
export type economic_unit_obj_rel_insert_input = {
  data: economic_unit_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<economic_unit_on_conflict>;
};

/** on_conflict condition type for table "economic_unit" */
export type economic_unit_on_conflict = {
  constraint: economic_unit_constraint;
  update_columns?: Array<economic_unit_update_column>;
  where?: InputMaybe<economic_unit_bool_exp>;
};

/** Ordering options when selecting data from "economic_unit". */
export type economic_unit_order_by = {
  buildings_aggregate?: InputMaybe<building_aggregate_order_by>;
  created_at?: InputMaybe<order_by>;
  customer_economic_unit_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  portfolio?: InputMaybe<portfolio_order_by>;
  portfolio_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: economic_unit */
export type economic_unit_pk_columns_input = {
  id: Scalars['Int'];
};

export { economic_unit_select_column };

/** input type for updating data in table "economic_unit" */
export type economic_unit_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_economic_unit_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  portfolio_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type economic_unit_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "economic_unit" */
export type economic_unit_stddev_order_by = {
  id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type economic_unit_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "economic_unit" */
export type economic_unit_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type economic_unit_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "economic_unit" */
export type economic_unit_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "economic_unit" */
export type economic_unit_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: economic_unit_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type economic_unit_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_economic_unit_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  portfolio_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type economic_unit_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  portfolio_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "economic_unit" */
export type economic_unit_sum_order_by = {
  id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
};

export { economic_unit_update_column };

export type economic_unit_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<economic_unit_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<economic_unit_set_input>;
  /** filter the rows which have to be updated */
  where: economic_unit_bool_exp;
};

/** aggregate var_pop on columns */
export type economic_unit_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "economic_unit" */
export type economic_unit_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type economic_unit_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "economic_unit" */
export type economic_unit_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type economic_unit_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "economic_unit" */
export type economic_unit_variance_order_by = {
  id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
};

/** columns and relationships of "efficiency_class" */
export type efficiency_class = {
  id: Scalars['String'];
};

/** aggregated selection of "efficiency_class" */
export type efficiency_class_aggregate = {
  aggregate?: Maybe<efficiency_class_aggregate_fields>;
  nodes: Array<efficiency_class>;
};

/** aggregate fields of "efficiency_class" */
export type efficiency_class_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<efficiency_class_max_fields>;
  min?: Maybe<efficiency_class_min_fields>;
};

/** aggregate fields of "efficiency_class" */
export type efficiency_class_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<efficiency_class_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "efficiency_class". All fields are combined with a logical 'AND'. */
export type efficiency_class_bool_exp = {
  _and?: InputMaybe<Array<efficiency_class_bool_exp>>;
  _not?: InputMaybe<efficiency_class_bool_exp>;
  _or?: InputMaybe<Array<efficiency_class_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { efficiency_class_constraint };

export { efficiency_class_enum };

/** Boolean expression to compare columns of type "efficiency_class_enum". All fields are combined with logical 'AND'. */
export type efficiency_class_enum_comparison_exp = {
  _eq?: InputMaybe<efficiency_class_enum>;
  _in?: InputMaybe<Array<efficiency_class_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<efficiency_class_enum>;
  _nin?: InputMaybe<Array<efficiency_class_enum>>;
};

/** input type for inserting data into table "efficiency_class" */
export type efficiency_class_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type efficiency_class_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type efficiency_class_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "efficiency_class" */
export type efficiency_class_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<efficiency_class>;
};

/** input type for inserting object relation for remote table "efficiency_class" */
export type efficiency_class_obj_rel_insert_input = {
  data: efficiency_class_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<efficiency_class_on_conflict>;
};

/** on_conflict condition type for table "efficiency_class" */
export type efficiency_class_on_conflict = {
  constraint: efficiency_class_constraint;
  update_columns?: Array<efficiency_class_update_column>;
  where?: InputMaybe<efficiency_class_bool_exp>;
};

/** Ordering options when selecting data from "efficiency_class". */
export type efficiency_class_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: efficiency_class */
export type efficiency_class_pk_columns_input = {
  id: Scalars['String'];
};

export { efficiency_class_select_column };

/** input type for updating data in table "efficiency_class" */
export type efficiency_class_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "efficiency_class" */
export type efficiency_class_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: efficiency_class_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type efficiency_class_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { efficiency_class_update_column };

export type efficiency_class_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<efficiency_class_set_input>;
  /** filter the rows which have to be updated */
  where: efficiency_class_bool_exp;
};

/** columns and relationships of "emission_certificate" */
export type emission_certificate = {
  created_at: Scalars['timestamptz'];
  emission_factor?: Maybe<Scalars['float8']>;
  /** An array relationship */
  energy_system_consumer_routes: Array<energy_system_consumer_route>;
  /** An aggregate relationship */
  energy_system_consumer_routes_aggregate: energy_system_consumer_route_aggregate;
  /** An object relationship */
  file: file;
  file_id: Scalars['Int'];
  id: Scalars['Int'];
  issuer: Scalars['String'];
  /** An object relationship */
  org: org;
  org_id: Scalars['Int'];
  primary_energy_factor?: Maybe<Scalars['float8']>;
  /** An array relationship */
  primary_energy_factor_energy_system_consumer_routes: Array<energy_system_consumer_route>;
  /** An aggregate relationship */
  primary_energy_factor_energy_system_consumer_routes_aggregate: energy_system_consumer_route_aggregate;
  updated_at: Scalars['timestamptz'];
  valid_from: Scalars['timestamptz'];
  valid_until: Scalars['timestamptz'];
};

/** columns and relationships of "emission_certificate" */
export type emission_certificateenergy_system_consumer_routesArgs = {
  distinct_on?: InputMaybe<Array<energy_system_consumer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_consumer_route_order_by>>;
  where?: InputMaybe<energy_system_consumer_route_bool_exp>;
};

/** columns and relationships of "emission_certificate" */
export type emission_certificateenergy_system_consumer_routes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_system_consumer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_consumer_route_order_by>>;
  where?: InputMaybe<energy_system_consumer_route_bool_exp>;
};

/** columns and relationships of "emission_certificate" */
export type emission_certificateprimary_energy_factor_energy_system_consumer_routesArgs = {
  distinct_on?: InputMaybe<Array<energy_system_consumer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_consumer_route_order_by>>;
  where?: InputMaybe<energy_system_consumer_route_bool_exp>;
};

/** columns and relationships of "emission_certificate" */
export type emission_certificateprimary_energy_factor_energy_system_consumer_routes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_system_consumer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_consumer_route_order_by>>;
  where?: InputMaybe<energy_system_consumer_route_bool_exp>;
};

/** aggregated selection of "emission_certificate" */
export type emission_certificate_aggregate = {
  aggregate?: Maybe<emission_certificate_aggregate_fields>;
  nodes: Array<emission_certificate>;
};

export type emission_certificate_aggregate_bool_exp = {
  avg?: InputMaybe<emission_certificate_aggregate_bool_exp_avg>;
  corr?: InputMaybe<emission_certificate_aggregate_bool_exp_corr>;
  count?: InputMaybe<emission_certificate_aggregate_bool_exp_count>;
  covar_samp?: InputMaybe<emission_certificate_aggregate_bool_exp_covar_samp>;
  max?: InputMaybe<emission_certificate_aggregate_bool_exp_max>;
  min?: InputMaybe<emission_certificate_aggregate_bool_exp_min>;
  stddev_samp?: InputMaybe<emission_certificate_aggregate_bool_exp_stddev_samp>;
  sum?: InputMaybe<emission_certificate_aggregate_bool_exp_sum>;
  var_samp?: InputMaybe<emission_certificate_aggregate_bool_exp_var_samp>;
};

export type emission_certificate_aggregate_bool_exp_avg = {
  arguments: emission_certificate_select_column_emission_certificate_aggregate_bool_exp_avg_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<emission_certificate_bool_exp>;
  predicate: float8_comparison_exp;
};

export type emission_certificate_aggregate_bool_exp_corr = {
  arguments: emission_certificate_aggregate_bool_exp_corr_arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<emission_certificate_bool_exp>;
  predicate: float8_comparison_exp;
};

export type emission_certificate_aggregate_bool_exp_corr_arguments = {
  X: emission_certificate_select_column_emission_certificate_aggregate_bool_exp_corr_arguments_columns;
  Y: emission_certificate_select_column_emission_certificate_aggregate_bool_exp_corr_arguments_columns;
};

export type emission_certificate_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<emission_certificate_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<emission_certificate_bool_exp>;
  predicate: Int_comparison_exp;
};

export type emission_certificate_aggregate_bool_exp_covar_samp = {
  arguments: emission_certificate_aggregate_bool_exp_covar_samp_arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<emission_certificate_bool_exp>;
  predicate: float8_comparison_exp;
};

export type emission_certificate_aggregate_bool_exp_covar_samp_arguments = {
  X: emission_certificate_select_column_emission_certificate_aggregate_bool_exp_covar_samp_arguments_columns;
  Y: emission_certificate_select_column_emission_certificate_aggregate_bool_exp_covar_samp_arguments_columns;
};

export type emission_certificate_aggregate_bool_exp_max = {
  arguments: emission_certificate_select_column_emission_certificate_aggregate_bool_exp_max_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<emission_certificate_bool_exp>;
  predicate: float8_comparison_exp;
};

export type emission_certificate_aggregate_bool_exp_min = {
  arguments: emission_certificate_select_column_emission_certificate_aggregate_bool_exp_min_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<emission_certificate_bool_exp>;
  predicate: float8_comparison_exp;
};

export type emission_certificate_aggregate_bool_exp_stddev_samp = {
  arguments: emission_certificate_select_column_emission_certificate_aggregate_bool_exp_stddev_samp_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<emission_certificate_bool_exp>;
  predicate: float8_comparison_exp;
};

export type emission_certificate_aggregate_bool_exp_sum = {
  arguments: emission_certificate_select_column_emission_certificate_aggregate_bool_exp_sum_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<emission_certificate_bool_exp>;
  predicate: float8_comparison_exp;
};

export type emission_certificate_aggregate_bool_exp_var_samp = {
  arguments: emission_certificate_select_column_emission_certificate_aggregate_bool_exp_var_samp_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<emission_certificate_bool_exp>;
  predicate: float8_comparison_exp;
};

/** aggregate fields of "emission_certificate" */
export type emission_certificate_aggregate_fields = {
  avg?: Maybe<emission_certificate_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<emission_certificate_max_fields>;
  min?: Maybe<emission_certificate_min_fields>;
  stddev?: Maybe<emission_certificate_stddev_fields>;
  stddev_pop?: Maybe<emission_certificate_stddev_pop_fields>;
  stddev_samp?: Maybe<emission_certificate_stddev_samp_fields>;
  sum?: Maybe<emission_certificate_sum_fields>;
  var_pop?: Maybe<emission_certificate_var_pop_fields>;
  var_samp?: Maybe<emission_certificate_var_samp_fields>;
  variance?: Maybe<emission_certificate_variance_fields>;
};

/** aggregate fields of "emission_certificate" */
export type emission_certificate_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<emission_certificate_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "emission_certificate" */
export type emission_certificate_aggregate_order_by = {
  avg?: InputMaybe<emission_certificate_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<emission_certificate_max_order_by>;
  min?: InputMaybe<emission_certificate_min_order_by>;
  stddev?: InputMaybe<emission_certificate_stddev_order_by>;
  stddev_pop?: InputMaybe<emission_certificate_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<emission_certificate_stddev_samp_order_by>;
  sum?: InputMaybe<emission_certificate_sum_order_by>;
  var_pop?: InputMaybe<emission_certificate_var_pop_order_by>;
  var_samp?: InputMaybe<emission_certificate_var_samp_order_by>;
  variance?: InputMaybe<emission_certificate_variance_order_by>;
};

/** input type for inserting array relation for remote table "emission_certificate" */
export type emission_certificate_arr_rel_insert_input = {
  data: Array<emission_certificate_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<emission_certificate_on_conflict>;
};

/** aggregate avg on columns */
export type emission_certificate_avg_fields = {
  emission_factor?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  primary_energy_factor?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "emission_certificate" */
export type emission_certificate_avg_order_by = {
  emission_factor?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  primary_energy_factor?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "emission_certificate". All fields are combined with a logical 'AND'. */
export type emission_certificate_bool_exp = {
  _and?: InputMaybe<Array<emission_certificate_bool_exp>>;
  _not?: InputMaybe<emission_certificate_bool_exp>;
  _or?: InputMaybe<Array<emission_certificate_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  emission_factor?: InputMaybe<float8_comparison_exp>;
  energy_system_consumer_routes?: InputMaybe<energy_system_consumer_route_bool_exp>;
  energy_system_consumer_routes_aggregate?: InputMaybe<energy_system_consumer_route_aggregate_bool_exp>;
  file?: InputMaybe<file_bool_exp>;
  file_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  issuer?: InputMaybe<String_comparison_exp>;
  org?: InputMaybe<org_bool_exp>;
  org_id?: InputMaybe<Int_comparison_exp>;
  primary_energy_factor?: InputMaybe<float8_comparison_exp>;
  primary_energy_factor_energy_system_consumer_routes?: InputMaybe<energy_system_consumer_route_bool_exp>;
  primary_energy_factor_energy_system_consumer_routes_aggregate?: InputMaybe<energy_system_consumer_route_aggregate_bool_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  valid_from?: InputMaybe<timestamptz_comparison_exp>;
  valid_until?: InputMaybe<timestamptz_comparison_exp>;
};

export { emission_certificate_constraint };

/** columns and relationships of "emission_certificate_draft" */
export type emission_certificate_draft = {
  created_at: Scalars['timestamptz'];
  emission_factor?: Maybe<Scalars['float8']>;
  /** An object relationship */
  file: file;
  file_id: Scalars['Int'];
  id: Scalars['Int'];
  issuer?: Maybe<Scalars['String']>;
  /** An object relationship */
  org: org;
  org_id: Scalars['Int'];
  primary_energy_factor?: Maybe<Scalars['float8']>;
  updated_at: Scalars['timestamptz'];
  valid_from?: Maybe<Scalars['timestamptz']>;
  valid_until?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "emission_certificate_draft" */
export type emission_certificate_draft_aggregate = {
  aggregate?: Maybe<emission_certificate_draft_aggregate_fields>;
  nodes: Array<emission_certificate_draft>;
};

export type emission_certificate_draft_aggregate_bool_exp = {
  avg?: InputMaybe<emission_certificate_draft_aggregate_bool_exp_avg>;
  corr?: InputMaybe<emission_certificate_draft_aggregate_bool_exp_corr>;
  count?: InputMaybe<emission_certificate_draft_aggregate_bool_exp_count>;
  covar_samp?: InputMaybe<emission_certificate_draft_aggregate_bool_exp_covar_samp>;
  max?: InputMaybe<emission_certificate_draft_aggregate_bool_exp_max>;
  min?: InputMaybe<emission_certificate_draft_aggregate_bool_exp_min>;
  stddev_samp?: InputMaybe<emission_certificate_draft_aggregate_bool_exp_stddev_samp>;
  sum?: InputMaybe<emission_certificate_draft_aggregate_bool_exp_sum>;
  var_samp?: InputMaybe<emission_certificate_draft_aggregate_bool_exp_var_samp>;
};

export type emission_certificate_draft_aggregate_bool_exp_avg = {
  arguments: emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_avg_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<emission_certificate_draft_bool_exp>;
  predicate: float8_comparison_exp;
};

export type emission_certificate_draft_aggregate_bool_exp_corr = {
  arguments: emission_certificate_draft_aggregate_bool_exp_corr_arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<emission_certificate_draft_bool_exp>;
  predicate: float8_comparison_exp;
};

export type emission_certificate_draft_aggregate_bool_exp_corr_arguments = {
  X: emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_corr_arguments_columns;
  Y: emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_corr_arguments_columns;
};

export type emission_certificate_draft_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<emission_certificate_draft_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<emission_certificate_draft_bool_exp>;
  predicate: Int_comparison_exp;
};

export type emission_certificate_draft_aggregate_bool_exp_covar_samp = {
  arguments: emission_certificate_draft_aggregate_bool_exp_covar_samp_arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<emission_certificate_draft_bool_exp>;
  predicate: float8_comparison_exp;
};

export type emission_certificate_draft_aggregate_bool_exp_covar_samp_arguments = {
  X: emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_covar_samp_arguments_columns;
  Y: emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_covar_samp_arguments_columns;
};

export type emission_certificate_draft_aggregate_bool_exp_max = {
  arguments: emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_max_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<emission_certificate_draft_bool_exp>;
  predicate: float8_comparison_exp;
};

export type emission_certificate_draft_aggregate_bool_exp_min = {
  arguments: emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_min_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<emission_certificate_draft_bool_exp>;
  predicate: float8_comparison_exp;
};

export type emission_certificate_draft_aggregate_bool_exp_stddev_samp = {
  arguments: emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_stddev_samp_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<emission_certificate_draft_bool_exp>;
  predicate: float8_comparison_exp;
};

export type emission_certificate_draft_aggregate_bool_exp_sum = {
  arguments: emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_sum_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<emission_certificate_draft_bool_exp>;
  predicate: float8_comparison_exp;
};

export type emission_certificate_draft_aggregate_bool_exp_var_samp = {
  arguments: emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_var_samp_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<emission_certificate_draft_bool_exp>;
  predicate: float8_comparison_exp;
};

/** aggregate fields of "emission_certificate_draft" */
export type emission_certificate_draft_aggregate_fields = {
  avg?: Maybe<emission_certificate_draft_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<emission_certificate_draft_max_fields>;
  min?: Maybe<emission_certificate_draft_min_fields>;
  stddev?: Maybe<emission_certificate_draft_stddev_fields>;
  stddev_pop?: Maybe<emission_certificate_draft_stddev_pop_fields>;
  stddev_samp?: Maybe<emission_certificate_draft_stddev_samp_fields>;
  sum?: Maybe<emission_certificate_draft_sum_fields>;
  var_pop?: Maybe<emission_certificate_draft_var_pop_fields>;
  var_samp?: Maybe<emission_certificate_draft_var_samp_fields>;
  variance?: Maybe<emission_certificate_draft_variance_fields>;
};

/** aggregate fields of "emission_certificate_draft" */
export type emission_certificate_draft_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<emission_certificate_draft_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "emission_certificate_draft" */
export type emission_certificate_draft_aggregate_order_by = {
  avg?: InputMaybe<emission_certificate_draft_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<emission_certificate_draft_max_order_by>;
  min?: InputMaybe<emission_certificate_draft_min_order_by>;
  stddev?: InputMaybe<emission_certificate_draft_stddev_order_by>;
  stddev_pop?: InputMaybe<emission_certificate_draft_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<emission_certificate_draft_stddev_samp_order_by>;
  sum?: InputMaybe<emission_certificate_draft_sum_order_by>;
  var_pop?: InputMaybe<emission_certificate_draft_var_pop_order_by>;
  var_samp?: InputMaybe<emission_certificate_draft_var_samp_order_by>;
  variance?: InputMaybe<emission_certificate_draft_variance_order_by>;
};

/** input type for inserting array relation for remote table "emission_certificate_draft" */
export type emission_certificate_draft_arr_rel_insert_input = {
  data: Array<emission_certificate_draft_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<emission_certificate_draft_on_conflict>;
};

/** aggregate avg on columns */
export type emission_certificate_draft_avg_fields = {
  emission_factor?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  primary_energy_factor?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "emission_certificate_draft" */
export type emission_certificate_draft_avg_order_by = {
  emission_factor?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  primary_energy_factor?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "emission_certificate_draft". All fields are combined with a logical 'AND'. */
export type emission_certificate_draft_bool_exp = {
  _and?: InputMaybe<Array<emission_certificate_draft_bool_exp>>;
  _not?: InputMaybe<emission_certificate_draft_bool_exp>;
  _or?: InputMaybe<Array<emission_certificate_draft_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  emission_factor?: InputMaybe<float8_comparison_exp>;
  file?: InputMaybe<file_bool_exp>;
  file_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  issuer?: InputMaybe<String_comparison_exp>;
  org?: InputMaybe<org_bool_exp>;
  org_id?: InputMaybe<Int_comparison_exp>;
  primary_energy_factor?: InputMaybe<float8_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  valid_from?: InputMaybe<timestamptz_comparison_exp>;
  valid_until?: InputMaybe<timestamptz_comparison_exp>;
};

export { emission_certificate_draft_constraint };

/** input type for incrementing numeric columns in table "emission_certificate_draft" */
export type emission_certificate_draft_inc_input = {
  emission_factor?: InputMaybe<Scalars['float8']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
  primary_energy_factor?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "emission_certificate_draft" */
export type emission_certificate_draft_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  emission_factor?: InputMaybe<Scalars['float8']>;
  file?: InputMaybe<file_obj_rel_insert_input>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  issuer?: InputMaybe<Scalars['String']>;
  org?: InputMaybe<org_obj_rel_insert_input>;
  org_id?: InputMaybe<Scalars['Int']>;
  primary_energy_factor?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valid_from?: InputMaybe<Scalars['timestamptz']>;
  valid_until?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type emission_certificate_draft_max_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  emission_factor?: Maybe<Scalars['float8']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  issuer?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Int']>;
  primary_energy_factor?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valid_from?: Maybe<Scalars['timestamptz']>;
  valid_until?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "emission_certificate_draft" */
export type emission_certificate_draft_max_order_by = {
  created_at?: InputMaybe<order_by>;
  emission_factor?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  issuer?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  primary_energy_factor?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  valid_from?: InputMaybe<order_by>;
  valid_until?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type emission_certificate_draft_min_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  emission_factor?: Maybe<Scalars['float8']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  issuer?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Int']>;
  primary_energy_factor?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valid_from?: Maybe<Scalars['timestamptz']>;
  valid_until?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "emission_certificate_draft" */
export type emission_certificate_draft_min_order_by = {
  created_at?: InputMaybe<order_by>;
  emission_factor?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  issuer?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  primary_energy_factor?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  valid_from?: InputMaybe<order_by>;
  valid_until?: InputMaybe<order_by>;
};

/** response of any mutation on the table "emission_certificate_draft" */
export type emission_certificate_draft_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<emission_certificate_draft>;
};

/** on_conflict condition type for table "emission_certificate_draft" */
export type emission_certificate_draft_on_conflict = {
  constraint: emission_certificate_draft_constraint;
  update_columns?: Array<emission_certificate_draft_update_column>;
  where?: InputMaybe<emission_certificate_draft_bool_exp>;
};

/** Ordering options when selecting data from "emission_certificate_draft". */
export type emission_certificate_draft_order_by = {
  created_at?: InputMaybe<order_by>;
  emission_factor?: InputMaybe<order_by>;
  file?: InputMaybe<file_order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  issuer?: InputMaybe<order_by>;
  org?: InputMaybe<org_order_by>;
  org_id?: InputMaybe<order_by>;
  primary_energy_factor?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  valid_from?: InputMaybe<order_by>;
  valid_until?: InputMaybe<order_by>;
};

/** primary key columns input for table: emission_certificate_draft */
export type emission_certificate_draft_pk_columns_input = {
  id: Scalars['Int'];
};

export { emission_certificate_draft_select_column };

export { emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_avg_arguments_columns };

export { emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_corr_arguments_columns };

export { emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_covar_samp_arguments_columns };

export { emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_max_arguments_columns };

export { emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_min_arguments_columns };

export { emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_stddev_samp_arguments_columns };

export { emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_sum_arguments_columns };

export { emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_var_samp_arguments_columns };

/** input type for updating data in table "emission_certificate_draft" */
export type emission_certificate_draft_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  emission_factor?: InputMaybe<Scalars['float8']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  issuer?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['Int']>;
  primary_energy_factor?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valid_from?: InputMaybe<Scalars['timestamptz']>;
  valid_until?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type emission_certificate_draft_stddev_fields = {
  emission_factor?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  primary_energy_factor?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "emission_certificate_draft" */
export type emission_certificate_draft_stddev_order_by = {
  emission_factor?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  primary_energy_factor?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type emission_certificate_draft_stddev_pop_fields = {
  emission_factor?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  primary_energy_factor?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "emission_certificate_draft" */
export type emission_certificate_draft_stddev_pop_order_by = {
  emission_factor?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  primary_energy_factor?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type emission_certificate_draft_stddev_samp_fields = {
  emission_factor?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  primary_energy_factor?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "emission_certificate_draft" */
export type emission_certificate_draft_stddev_samp_order_by = {
  emission_factor?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  primary_energy_factor?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "emission_certificate_draft" */
export type emission_certificate_draft_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: emission_certificate_draft_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type emission_certificate_draft_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  emission_factor?: InputMaybe<Scalars['float8']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  issuer?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['Int']>;
  primary_energy_factor?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valid_from?: InputMaybe<Scalars['timestamptz']>;
  valid_until?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type emission_certificate_draft_sum_fields = {
  emission_factor?: Maybe<Scalars['float8']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  primary_energy_factor?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "emission_certificate_draft" */
export type emission_certificate_draft_sum_order_by = {
  emission_factor?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  primary_energy_factor?: InputMaybe<order_by>;
};

export { emission_certificate_draft_update_column };

export type emission_certificate_draft_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<emission_certificate_draft_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<emission_certificate_draft_set_input>;
  /** filter the rows which have to be updated */
  where: emission_certificate_draft_bool_exp;
};

/** aggregate var_pop on columns */
export type emission_certificate_draft_var_pop_fields = {
  emission_factor?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  primary_energy_factor?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "emission_certificate_draft" */
export type emission_certificate_draft_var_pop_order_by = {
  emission_factor?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  primary_energy_factor?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type emission_certificate_draft_var_samp_fields = {
  emission_factor?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  primary_energy_factor?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "emission_certificate_draft" */
export type emission_certificate_draft_var_samp_order_by = {
  emission_factor?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  primary_energy_factor?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type emission_certificate_draft_variance_fields = {
  emission_factor?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  primary_energy_factor?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "emission_certificate_draft" */
export type emission_certificate_draft_variance_order_by = {
  emission_factor?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  primary_energy_factor?: InputMaybe<order_by>;
};

/** input type for incrementing numeric columns in table "emission_certificate" */
export type emission_certificate_inc_input = {
  emission_factor?: InputMaybe<Scalars['float8']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
  primary_energy_factor?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "emission_certificate" */
export type emission_certificate_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  emission_factor?: InputMaybe<Scalars['float8']>;
  energy_system_consumer_routes?: InputMaybe<energy_system_consumer_route_arr_rel_insert_input>;
  file?: InputMaybe<file_obj_rel_insert_input>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  issuer?: InputMaybe<Scalars['String']>;
  org?: InputMaybe<org_obj_rel_insert_input>;
  org_id?: InputMaybe<Scalars['Int']>;
  primary_energy_factor?: InputMaybe<Scalars['float8']>;
  primary_energy_factor_energy_system_consumer_routes?: InputMaybe<energy_system_consumer_route_arr_rel_insert_input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valid_from?: InputMaybe<Scalars['timestamptz']>;
  valid_until?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type emission_certificate_max_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  emission_factor?: Maybe<Scalars['float8']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  issuer?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Int']>;
  primary_energy_factor?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valid_from?: Maybe<Scalars['timestamptz']>;
  valid_until?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "emission_certificate" */
export type emission_certificate_max_order_by = {
  created_at?: InputMaybe<order_by>;
  emission_factor?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  issuer?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  primary_energy_factor?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  valid_from?: InputMaybe<order_by>;
  valid_until?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type emission_certificate_min_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  emission_factor?: Maybe<Scalars['float8']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  issuer?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Int']>;
  primary_energy_factor?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valid_from?: Maybe<Scalars['timestamptz']>;
  valid_until?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "emission_certificate" */
export type emission_certificate_min_order_by = {
  created_at?: InputMaybe<order_by>;
  emission_factor?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  issuer?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  primary_energy_factor?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  valid_from?: InputMaybe<order_by>;
  valid_until?: InputMaybe<order_by>;
};

/** response of any mutation on the table "emission_certificate" */
export type emission_certificate_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<emission_certificate>;
};

/** input type for inserting object relation for remote table "emission_certificate" */
export type emission_certificate_obj_rel_insert_input = {
  data: emission_certificate_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<emission_certificate_on_conflict>;
};

/** on_conflict condition type for table "emission_certificate" */
export type emission_certificate_on_conflict = {
  constraint: emission_certificate_constraint;
  update_columns?: Array<emission_certificate_update_column>;
  where?: InputMaybe<emission_certificate_bool_exp>;
};

/** Ordering options when selecting data from "emission_certificate". */
export type emission_certificate_order_by = {
  created_at?: InputMaybe<order_by>;
  emission_factor?: InputMaybe<order_by>;
  energy_system_consumer_routes_aggregate?: InputMaybe<energy_system_consumer_route_aggregate_order_by>;
  file?: InputMaybe<file_order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  issuer?: InputMaybe<order_by>;
  org?: InputMaybe<org_order_by>;
  org_id?: InputMaybe<order_by>;
  primary_energy_factor?: InputMaybe<order_by>;
  primary_energy_factor_energy_system_consumer_routes_aggregate?: InputMaybe<energy_system_consumer_route_aggregate_order_by>;
  updated_at?: InputMaybe<order_by>;
  valid_from?: InputMaybe<order_by>;
  valid_until?: InputMaybe<order_by>;
};

/** primary key columns input for table: emission_certificate */
export type emission_certificate_pk_columns_input = {
  id: Scalars['Int'];
};

export { emission_certificate_select_column };

export { emission_certificate_select_column_emission_certificate_aggregate_bool_exp_avg_arguments_columns };

export { emission_certificate_select_column_emission_certificate_aggregate_bool_exp_corr_arguments_columns };

export { emission_certificate_select_column_emission_certificate_aggregate_bool_exp_covar_samp_arguments_columns };

export { emission_certificate_select_column_emission_certificate_aggregate_bool_exp_max_arguments_columns };

export { emission_certificate_select_column_emission_certificate_aggregate_bool_exp_min_arguments_columns };

export { emission_certificate_select_column_emission_certificate_aggregate_bool_exp_stddev_samp_arguments_columns };

export { emission_certificate_select_column_emission_certificate_aggregate_bool_exp_sum_arguments_columns };

export { emission_certificate_select_column_emission_certificate_aggregate_bool_exp_var_samp_arguments_columns };

/** input type for updating data in table "emission_certificate" */
export type emission_certificate_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  emission_factor?: InputMaybe<Scalars['float8']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  issuer?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['Int']>;
  primary_energy_factor?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valid_from?: InputMaybe<Scalars['timestamptz']>;
  valid_until?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type emission_certificate_stddev_fields = {
  emission_factor?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  primary_energy_factor?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "emission_certificate" */
export type emission_certificate_stddev_order_by = {
  emission_factor?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  primary_energy_factor?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type emission_certificate_stddev_pop_fields = {
  emission_factor?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  primary_energy_factor?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "emission_certificate" */
export type emission_certificate_stddev_pop_order_by = {
  emission_factor?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  primary_energy_factor?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type emission_certificate_stddev_samp_fields = {
  emission_factor?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  primary_energy_factor?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "emission_certificate" */
export type emission_certificate_stddev_samp_order_by = {
  emission_factor?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  primary_energy_factor?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "emission_certificate" */
export type emission_certificate_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: emission_certificate_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type emission_certificate_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  emission_factor?: InputMaybe<Scalars['float8']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  issuer?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['Int']>;
  primary_energy_factor?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valid_from?: InputMaybe<Scalars['timestamptz']>;
  valid_until?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type emission_certificate_sum_fields = {
  emission_factor?: Maybe<Scalars['float8']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  primary_energy_factor?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "emission_certificate" */
export type emission_certificate_sum_order_by = {
  emission_factor?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  primary_energy_factor?: InputMaybe<order_by>;
};

export { emission_certificate_update_column };

export type emission_certificate_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<emission_certificate_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<emission_certificate_set_input>;
  /** filter the rows which have to be updated */
  where: emission_certificate_bool_exp;
};

/** aggregate var_pop on columns */
export type emission_certificate_var_pop_fields = {
  emission_factor?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  primary_energy_factor?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "emission_certificate" */
export type emission_certificate_var_pop_order_by = {
  emission_factor?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  primary_energy_factor?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type emission_certificate_var_samp_fields = {
  emission_factor?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  primary_energy_factor?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "emission_certificate" */
export type emission_certificate_var_samp_order_by = {
  emission_factor?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  primary_energy_factor?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type emission_certificate_variance_fields = {
  emission_factor?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  primary_energy_factor?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "emission_certificate" */
export type emission_certificate_variance_order_by = {
  emission_factor?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  primary_energy_factor?: InputMaybe<order_by>;
};

/** Which basis was used to set the emission factor */
export type emission_factor_origin = {
  id: Scalars['String'];
};

/** aggregated selection of "emission_factor_origin" */
export type emission_factor_origin_aggregate = {
  aggregate?: Maybe<emission_factor_origin_aggregate_fields>;
  nodes: Array<emission_factor_origin>;
};

/** aggregate fields of "emission_factor_origin" */
export type emission_factor_origin_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<emission_factor_origin_max_fields>;
  min?: Maybe<emission_factor_origin_min_fields>;
};

/** aggregate fields of "emission_factor_origin" */
export type emission_factor_origin_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<emission_factor_origin_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "emission_factor_origin". All fields are combined with a logical 'AND'. */
export type emission_factor_origin_bool_exp = {
  _and?: InputMaybe<Array<emission_factor_origin_bool_exp>>;
  _not?: InputMaybe<emission_factor_origin_bool_exp>;
  _or?: InputMaybe<Array<emission_factor_origin_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { emission_factor_origin_constraint };

export { emission_factor_origin_enum };

/** Boolean expression to compare columns of type "emission_factor_origin_enum". All fields are combined with logical 'AND'. */
export type emission_factor_origin_enum_comparison_exp = {
  _eq?: InputMaybe<emission_factor_origin_enum>;
  _in?: InputMaybe<Array<emission_factor_origin_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<emission_factor_origin_enum>;
  _nin?: InputMaybe<Array<emission_factor_origin_enum>>;
};

/** input type for inserting data into table "emission_factor_origin" */
export type emission_factor_origin_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type emission_factor_origin_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type emission_factor_origin_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "emission_factor_origin" */
export type emission_factor_origin_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<emission_factor_origin>;
};

/** on_conflict condition type for table "emission_factor_origin" */
export type emission_factor_origin_on_conflict = {
  constraint: emission_factor_origin_constraint;
  update_columns?: Array<emission_factor_origin_update_column>;
  where?: InputMaybe<emission_factor_origin_bool_exp>;
};

/** Ordering options when selecting data from "emission_factor_origin". */
export type emission_factor_origin_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: emission_factor_origin */
export type emission_factor_origin_pk_columns_input = {
  id: Scalars['String'];
};

export { emission_factor_origin_select_column };

/** input type for updating data in table "emission_factor_origin" */
export type emission_factor_origin_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "emission_factor_origin" */
export type emission_factor_origin_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: emission_factor_origin_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type emission_factor_origin_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { emission_factor_origin_update_column };

export type emission_factor_origin_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<emission_factor_origin_set_input>;
  /** filter the rows which have to be updated */
  where: emission_factor_origin_bool_exp;
};

/** columns and relationships of "emission_factor_type" */
export type emission_factor_type = {
  id: Scalars['String'];
};

/** aggregated selection of "emission_factor_type" */
export type emission_factor_type_aggregate = {
  aggregate?: Maybe<emission_factor_type_aggregate_fields>;
  nodes: Array<emission_factor_type>;
};

/** aggregate fields of "emission_factor_type" */
export type emission_factor_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<emission_factor_type_max_fields>;
  min?: Maybe<emission_factor_type_min_fields>;
};

/** aggregate fields of "emission_factor_type" */
export type emission_factor_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<emission_factor_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "emission_factor_type". All fields are combined with a logical 'AND'. */
export type emission_factor_type_bool_exp = {
  _and?: InputMaybe<Array<emission_factor_type_bool_exp>>;
  _not?: InputMaybe<emission_factor_type_bool_exp>;
  _or?: InputMaybe<Array<emission_factor_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { emission_factor_type_constraint };

export { emission_factor_type_enum };

/** Boolean expression to compare columns of type "emission_factor_type_enum". All fields are combined with logical 'AND'. */
export type emission_factor_type_enum_comparison_exp = {
  _eq?: InputMaybe<emission_factor_type_enum>;
  _in?: InputMaybe<Array<emission_factor_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<emission_factor_type_enum>;
  _nin?: InputMaybe<Array<emission_factor_type_enum>>;
};

/** input type for inserting data into table "emission_factor_type" */
export type emission_factor_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type emission_factor_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type emission_factor_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "emission_factor_type" */
export type emission_factor_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<emission_factor_type>;
};

/** on_conflict condition type for table "emission_factor_type" */
export type emission_factor_type_on_conflict = {
  constraint: emission_factor_type_constraint;
  update_columns?: Array<emission_factor_type_update_column>;
  where?: InputMaybe<emission_factor_type_bool_exp>;
};

/** Ordering options when selecting data from "emission_factor_type". */
export type emission_factor_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: emission_factor_type */
export type emission_factor_type_pk_columns_input = {
  id: Scalars['String'];
};

export { emission_factor_type_select_column };

/** input type for updating data in table "emission_factor_type" */
export type emission_factor_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "emission_factor_type" */
export type emission_factor_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: emission_factor_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type emission_factor_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { emission_factor_type_update_column };

export type emission_factor_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<emission_factor_type_set_input>;
  /** filter the rows which have to be updated */
  where: emission_factor_type_bool_exp;
};

/** columns and relationships of "energy_certificate" */
export type energy_certificate = {
  area_usable?: Maybe<Scalars['numeric']>;
  city?: Maybe<Scalars['String']>;
  co2_emissions?: Maybe<Scalars['numeric']>;
  country_id: country_enum;
  country_state_id: country_state_enum;
  created_at: Scalars['timestamptz'];
  efficiency_class: efficiency_class_enum;
  /** An array relationship */
  energy_certificate_consumers: Array<energy_certificate_consumer>;
  /** An aggregate relationship */
  energy_certificate_consumers_aggregate: energy_certificate_consumer_aggregate;
  energy_certificate_type: energy_certificate_type_enum;
  energy_effective_consumption?: Maybe<Scalars['numeric']>;
  energy_effective_demand?: Maybe<Scalars['numeric']>;
  energy_final_consumption?: Maybe<Scalars['numeric']>;
  energy_final_demand?: Maybe<Scalars['numeric']>;
  energy_primary_consumption?: Maybe<Scalars['numeric']>;
  energy_primary_demand?: Maybe<Scalars['numeric']>;
  expiry_date?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  file?: Maybe<file>;
  file_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** An object relationship */
  org: org;
  org_id: Scalars['Int'];
  postal_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  /** An object relationship */
  sub_building: sub_building;
  sub_building_id: Scalars['Int'];
  units_commercial?: Maybe<Scalars['Int']>;
  units_residential?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  year_constructed?: Maybe<Scalars['Int']>;
  year_renovated?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "energy_certificate" */
export type energy_certificateenergy_certificate_consumersArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_consumer_order_by>>;
  where?: InputMaybe<energy_certificate_consumer_bool_exp>;
};

/** columns and relationships of "energy_certificate" */
export type energy_certificateenergy_certificate_consumers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_consumer_order_by>>;
  where?: InputMaybe<energy_certificate_consumer_bool_exp>;
};

/** aggregated selection of "energy_certificate" */
export type energy_certificate_aggregate = {
  aggregate?: Maybe<energy_certificate_aggregate_fields>;
  nodes: Array<energy_certificate>;
};

export type energy_certificate_aggregate_bool_exp = {
  count?: InputMaybe<energy_certificate_aggregate_bool_exp_count>;
};

export type energy_certificate_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<energy_certificate_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<energy_certificate_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "energy_certificate" */
export type energy_certificate_aggregate_fields = {
  avg?: Maybe<energy_certificate_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<energy_certificate_max_fields>;
  min?: Maybe<energy_certificate_min_fields>;
  stddev?: Maybe<energy_certificate_stddev_fields>;
  stddev_pop?: Maybe<energy_certificate_stddev_pop_fields>;
  stddev_samp?: Maybe<energy_certificate_stddev_samp_fields>;
  sum?: Maybe<energy_certificate_sum_fields>;
  var_pop?: Maybe<energy_certificate_var_pop_fields>;
  var_samp?: Maybe<energy_certificate_var_samp_fields>;
  variance?: Maybe<energy_certificate_variance_fields>;
};

/** aggregate fields of "energy_certificate" */
export type energy_certificate_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<energy_certificate_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "energy_certificate" */
export type energy_certificate_aggregate_order_by = {
  avg?: InputMaybe<energy_certificate_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<energy_certificate_max_order_by>;
  min?: InputMaybe<energy_certificate_min_order_by>;
  stddev?: InputMaybe<energy_certificate_stddev_order_by>;
  stddev_pop?: InputMaybe<energy_certificate_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<energy_certificate_stddev_samp_order_by>;
  sum?: InputMaybe<energy_certificate_sum_order_by>;
  var_pop?: InputMaybe<energy_certificate_var_pop_order_by>;
  var_samp?: InputMaybe<energy_certificate_var_samp_order_by>;
  variance?: InputMaybe<energy_certificate_variance_order_by>;
};

/** input type for inserting array relation for remote table "energy_certificate" */
export type energy_certificate_arr_rel_insert_input = {
  data: Array<energy_certificate_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<energy_certificate_on_conflict>;
};

/** aggregate avg on columns */
export type energy_certificate_avg_fields = {
  area_usable?: Maybe<Scalars['Float']>;
  co2_emissions?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['Float']>;
  energy_effective_demand?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['Float']>;
  energy_primary_demand?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  units_commercial?: Maybe<Scalars['Float']>;
  units_residential?: Maybe<Scalars['Float']>;
  year_constructed?: Maybe<Scalars['Float']>;
  year_renovated?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "energy_certificate" */
export type energy_certificate_avg_order_by = {
  area_usable?: InputMaybe<order_by>;
  co2_emissions?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  units_commercial?: InputMaybe<order_by>;
  units_residential?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "energy_certificate". All fields are combined with a logical 'AND'. */
export type energy_certificate_bool_exp = {
  _and?: InputMaybe<Array<energy_certificate_bool_exp>>;
  _not?: InputMaybe<energy_certificate_bool_exp>;
  _or?: InputMaybe<Array<energy_certificate_bool_exp>>;
  area_usable?: InputMaybe<numeric_comparison_exp>;
  city?: InputMaybe<String_comparison_exp>;
  co2_emissions?: InputMaybe<numeric_comparison_exp>;
  country_id?: InputMaybe<country_enum_comparison_exp>;
  country_state_id?: InputMaybe<country_state_enum_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  efficiency_class?: InputMaybe<efficiency_class_enum_comparison_exp>;
  energy_certificate_consumers?: InputMaybe<energy_certificate_consumer_bool_exp>;
  energy_certificate_consumers_aggregate?: InputMaybe<energy_certificate_consumer_aggregate_bool_exp>;
  energy_certificate_type?: InputMaybe<energy_certificate_type_enum_comparison_exp>;
  energy_effective_consumption?: InputMaybe<numeric_comparison_exp>;
  energy_effective_demand?: InputMaybe<numeric_comparison_exp>;
  energy_final_consumption?: InputMaybe<numeric_comparison_exp>;
  energy_final_demand?: InputMaybe<numeric_comparison_exp>;
  energy_primary_consumption?: InputMaybe<numeric_comparison_exp>;
  energy_primary_demand?: InputMaybe<numeric_comparison_exp>;
  expiry_date?: InputMaybe<timestamptz_comparison_exp>;
  file?: InputMaybe<file_bool_exp>;
  file_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  org?: InputMaybe<org_bool_exp>;
  org_id?: InputMaybe<Int_comparison_exp>;
  postal_code?: InputMaybe<String_comparison_exp>;
  state?: InputMaybe<String_comparison_exp>;
  street?: InputMaybe<String_comparison_exp>;
  sub_building?: InputMaybe<sub_building_bool_exp>;
  sub_building_id?: InputMaybe<Int_comparison_exp>;
  units_commercial?: InputMaybe<Int_comparison_exp>;
  units_residential?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  year_constructed?: InputMaybe<Int_comparison_exp>;
  year_renovated?: InputMaybe<Int_comparison_exp>;
};

export { energy_certificate_constraint };

/** energy consumer listed in the energy certificate */
export type energy_certificate_consumer = {
  /** An object relationship */
  energy_certificate?: Maybe<energy_certificate>;
  /** An object relationship */
  energy_certificate_draft?: Maybe<energy_certificate_draft>;
  energy_certificate_draft_id?: Maybe<Scalars['Int']>;
  energy_certificate_id?: Maybe<Scalars['Int']>;
  energy_final: Scalars['Float'];
  energy_source_type_id: energy_source_type_enum;
  energy_system_type_id: energy_system_type_enum;
  id: Scalars['Int'];
};

/** aggregated selection of "energy_certificate_consumer" */
export type energy_certificate_consumer_aggregate = {
  aggregate?: Maybe<energy_certificate_consumer_aggregate_fields>;
  nodes: Array<energy_certificate_consumer>;
};

export type energy_certificate_consumer_aggregate_bool_exp = {
  count?: InputMaybe<energy_certificate_consumer_aggregate_bool_exp_count>;
};

export type energy_certificate_consumer_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<energy_certificate_consumer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<energy_certificate_consumer_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "energy_certificate_consumer" */
export type energy_certificate_consumer_aggregate_fields = {
  avg?: Maybe<energy_certificate_consumer_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<energy_certificate_consumer_max_fields>;
  min?: Maybe<energy_certificate_consumer_min_fields>;
  stddev?: Maybe<energy_certificate_consumer_stddev_fields>;
  stddev_pop?: Maybe<energy_certificate_consumer_stddev_pop_fields>;
  stddev_samp?: Maybe<energy_certificate_consumer_stddev_samp_fields>;
  sum?: Maybe<energy_certificate_consumer_sum_fields>;
  var_pop?: Maybe<energy_certificate_consumer_var_pop_fields>;
  var_samp?: Maybe<energy_certificate_consumer_var_samp_fields>;
  variance?: Maybe<energy_certificate_consumer_variance_fields>;
};

/** aggregate fields of "energy_certificate_consumer" */
export type energy_certificate_consumer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<energy_certificate_consumer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "energy_certificate_consumer" */
export type energy_certificate_consumer_aggregate_order_by = {
  avg?: InputMaybe<energy_certificate_consumer_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<energy_certificate_consumer_max_order_by>;
  min?: InputMaybe<energy_certificate_consumer_min_order_by>;
  stddev?: InputMaybe<energy_certificate_consumer_stddev_order_by>;
  stddev_pop?: InputMaybe<energy_certificate_consumer_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<energy_certificate_consumer_stddev_samp_order_by>;
  sum?: InputMaybe<energy_certificate_consumer_sum_order_by>;
  var_pop?: InputMaybe<energy_certificate_consumer_var_pop_order_by>;
  var_samp?: InputMaybe<energy_certificate_consumer_var_samp_order_by>;
  variance?: InputMaybe<energy_certificate_consumer_variance_order_by>;
};

/** input type for inserting array relation for remote table "energy_certificate_consumer" */
export type energy_certificate_consumer_arr_rel_insert_input = {
  data: Array<energy_certificate_consumer_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<energy_certificate_consumer_on_conflict>;
};

/** aggregate avg on columns */
export type energy_certificate_consumer_avg_fields = {
  energy_certificate_draft_id?: Maybe<Scalars['Float']>;
  energy_certificate_id?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "energy_certificate_consumer" */
export type energy_certificate_consumer_avg_order_by = {
  energy_certificate_draft_id?: InputMaybe<order_by>;
  energy_certificate_id?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "energy_certificate_consumer". All fields are combined with a logical 'AND'. */
export type energy_certificate_consumer_bool_exp = {
  _and?: InputMaybe<Array<energy_certificate_consumer_bool_exp>>;
  _not?: InputMaybe<energy_certificate_consumer_bool_exp>;
  _or?: InputMaybe<Array<energy_certificate_consumer_bool_exp>>;
  energy_certificate?: InputMaybe<energy_certificate_bool_exp>;
  energy_certificate_draft?: InputMaybe<energy_certificate_draft_bool_exp>;
  energy_certificate_draft_id?: InputMaybe<Int_comparison_exp>;
  energy_certificate_id?: InputMaybe<Int_comparison_exp>;
  energy_final?: InputMaybe<Float_comparison_exp>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum_comparison_exp>;
  energy_system_type_id?: InputMaybe<energy_system_type_enum_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
};

export { energy_certificate_consumer_constraint };

/** input type for incrementing numeric columns in table "energy_certificate_consumer" */
export type energy_certificate_consumer_inc_input = {
  energy_certificate_draft_id?: InputMaybe<Scalars['Int']>;
  energy_certificate_id?: InputMaybe<Scalars['Int']>;
  energy_final?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "energy_certificate_consumer" */
export type energy_certificate_consumer_insert_input = {
  energy_certificate?: InputMaybe<energy_certificate_obj_rel_insert_input>;
  energy_certificate_draft?: InputMaybe<energy_certificate_draft_obj_rel_insert_input>;
  energy_certificate_draft_id?: InputMaybe<Scalars['Int']>;
  energy_certificate_id?: InputMaybe<Scalars['Int']>;
  energy_final?: InputMaybe<Scalars['Float']>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum>;
  energy_system_type_id?: InputMaybe<energy_system_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type energy_certificate_consumer_max_fields = {
  energy_certificate_draft_id?: Maybe<Scalars['Int']>;
  energy_certificate_id?: Maybe<Scalars['Int']>;
  energy_final?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "energy_certificate_consumer" */
export type energy_certificate_consumer_max_order_by = {
  energy_certificate_draft_id?: InputMaybe<order_by>;
  energy_certificate_id?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type energy_certificate_consumer_min_fields = {
  energy_certificate_draft_id?: Maybe<Scalars['Int']>;
  energy_certificate_id?: Maybe<Scalars['Int']>;
  energy_final?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "energy_certificate_consumer" */
export type energy_certificate_consumer_min_order_by = {
  energy_certificate_draft_id?: InputMaybe<order_by>;
  energy_certificate_id?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "energy_certificate_consumer" */
export type energy_certificate_consumer_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<energy_certificate_consumer>;
};

/** on_conflict condition type for table "energy_certificate_consumer" */
export type energy_certificate_consumer_on_conflict = {
  constraint: energy_certificate_consumer_constraint;
  update_columns?: Array<energy_certificate_consumer_update_column>;
  where?: InputMaybe<energy_certificate_consumer_bool_exp>;
};

/** Ordering options when selecting data from "energy_certificate_consumer". */
export type energy_certificate_consumer_order_by = {
  energy_certificate?: InputMaybe<energy_certificate_order_by>;
  energy_certificate_draft?: InputMaybe<energy_certificate_draft_order_by>;
  energy_certificate_draft_id?: InputMaybe<order_by>;
  energy_certificate_id?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_source_type_id?: InputMaybe<order_by>;
  energy_system_type_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: energy_certificate_consumer */
export type energy_certificate_consumer_pk_columns_input = {
  id: Scalars['Int'];
};

export { energy_certificate_consumer_select_column };

/** input type for updating data in table "energy_certificate_consumer" */
export type energy_certificate_consumer_set_input = {
  energy_certificate_draft_id?: InputMaybe<Scalars['Int']>;
  energy_certificate_id?: InputMaybe<Scalars['Int']>;
  energy_final?: InputMaybe<Scalars['Float']>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum>;
  energy_system_type_id?: InputMaybe<energy_system_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type energy_certificate_consumer_stddev_fields = {
  energy_certificate_draft_id?: Maybe<Scalars['Float']>;
  energy_certificate_id?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "energy_certificate_consumer" */
export type energy_certificate_consumer_stddev_order_by = {
  energy_certificate_draft_id?: InputMaybe<order_by>;
  energy_certificate_id?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type energy_certificate_consumer_stddev_pop_fields = {
  energy_certificate_draft_id?: Maybe<Scalars['Float']>;
  energy_certificate_id?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "energy_certificate_consumer" */
export type energy_certificate_consumer_stddev_pop_order_by = {
  energy_certificate_draft_id?: InputMaybe<order_by>;
  energy_certificate_id?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type energy_certificate_consumer_stddev_samp_fields = {
  energy_certificate_draft_id?: Maybe<Scalars['Float']>;
  energy_certificate_id?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "energy_certificate_consumer" */
export type energy_certificate_consumer_stddev_samp_order_by = {
  energy_certificate_draft_id?: InputMaybe<order_by>;
  energy_certificate_id?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "energy_certificate_consumer" */
export type energy_certificate_consumer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: energy_certificate_consumer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type energy_certificate_consumer_stream_cursor_value_input = {
  energy_certificate_draft_id?: InputMaybe<Scalars['Int']>;
  energy_certificate_id?: InputMaybe<Scalars['Int']>;
  energy_final?: InputMaybe<Scalars['Float']>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum>;
  energy_system_type_id?: InputMaybe<energy_system_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type energy_certificate_consumer_sum_fields = {
  energy_certificate_draft_id?: Maybe<Scalars['Int']>;
  energy_certificate_id?: Maybe<Scalars['Int']>;
  energy_final?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "energy_certificate_consumer" */
export type energy_certificate_consumer_sum_order_by = {
  energy_certificate_draft_id?: InputMaybe<order_by>;
  energy_certificate_id?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

export { energy_certificate_consumer_update_column };

export type energy_certificate_consumer_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<energy_certificate_consumer_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<energy_certificate_consumer_set_input>;
  /** filter the rows which have to be updated */
  where: energy_certificate_consumer_bool_exp;
};

/** aggregate var_pop on columns */
export type energy_certificate_consumer_var_pop_fields = {
  energy_certificate_draft_id?: Maybe<Scalars['Float']>;
  energy_certificate_id?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "energy_certificate_consumer" */
export type energy_certificate_consumer_var_pop_order_by = {
  energy_certificate_draft_id?: InputMaybe<order_by>;
  energy_certificate_id?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type energy_certificate_consumer_var_samp_fields = {
  energy_certificate_draft_id?: Maybe<Scalars['Float']>;
  energy_certificate_id?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "energy_certificate_consumer" */
export type energy_certificate_consumer_var_samp_order_by = {
  energy_certificate_draft_id?: InputMaybe<order_by>;
  energy_certificate_id?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type energy_certificate_consumer_variance_fields = {
  energy_certificate_draft_id?: Maybe<Scalars['Float']>;
  energy_certificate_id?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "energy_certificate_consumer" */
export type energy_certificate_consumer_variance_order_by = {
  energy_certificate_draft_id?: InputMaybe<order_by>;
  energy_certificate_id?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** columns and relationships of "energy_certificate_draft" */
export type energy_certificate_draft = {
  area_usable?: Maybe<Scalars['numeric']>;
  building_state_id?: Maybe<building_state_enum>;
  city?: Maybe<Scalars['String']>;
  country_id: country_enum;
  country_state_id?: Maybe<country_state_enum>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  draft_status: draft_status;
  draft_status_id: draft_status_enum;
  /** An object relationship */
  economic_unit?: Maybe<economic_unit>;
  economic_unit_id?: Maybe<Scalars['Int']>;
  efficiency_class_id?: Maybe<efficiency_class_enum>;
  /** An array relationship */
  energy_certificate_consumers: Array<energy_certificate_consumer>;
  /** An aggregate relationship */
  energy_certificate_consumers_aggregate: energy_certificate_consumer_aggregate;
  energy_certificate_file_id: Scalars['Int'];
  energy_certificate_type_id?: Maybe<energy_certificate_type_enum>;
  energy_effective_consumption?: Maybe<Scalars['numeric']>;
  energy_effective_demand?: Maybe<Scalars['numeric']>;
  energy_final_consumption?: Maybe<Scalars['numeric']>;
  energy_final_demand?: Maybe<Scalars['numeric']>;
  energy_primary_consumption?: Maybe<Scalars['numeric']>;
  energy_primary_demand?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  energy_sources: Array<energy_source>;
  /** An aggregate relationship */
  energy_sources_aggregate: energy_source_aggregate;
  expiry_date?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  file: file;
  heritage_district: Scalars['Boolean'];
  id: Scalars['Int'];
  leasehold: Scalars['Boolean'];
  milieu_protection: Scalars['Boolean'];
  monument_protection: Scalars['Boolean'];
  /** An object relationship */
  org: org;
  org_id: Scalars['Int'];
  /** An object relationship */
  portfolio?: Maybe<portfolio>;
  portfolio_id?: Maybe<Scalars['Int']>;
  postal_code?: Maybe<Scalars['String']>;
  responsible_user_id?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  sub_building_class_id?: Maybe<sub_building_class_enum>;
  type_of_use_id?: Maybe<type_of_use_enum>;
  units?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<user>;
  year_constructed?: Maybe<Scalars['Int']>;
  year_renovated?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "energy_certificate_draft" */
export type energy_certificate_draftenergy_certificate_consumersArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_consumer_order_by>>;
  where?: InputMaybe<energy_certificate_consumer_bool_exp>;
};

/** columns and relationships of "energy_certificate_draft" */
export type energy_certificate_draftenergy_certificate_consumers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_consumer_order_by>>;
  where?: InputMaybe<energy_certificate_consumer_bool_exp>;
};

/** columns and relationships of "energy_certificate_draft" */
export type energy_certificate_draftenergy_sourcesArgs = {
  distinct_on?: InputMaybe<Array<energy_source_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_source_order_by>>;
  where?: InputMaybe<energy_source_bool_exp>;
};

/** columns and relationships of "energy_certificate_draft" */
export type energy_certificate_draftenergy_sources_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_source_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_source_order_by>>;
  where?: InputMaybe<energy_source_bool_exp>;
};

/** aggregated selection of "energy_certificate_draft" */
export type energy_certificate_draft_aggregate = {
  aggregate?: Maybe<energy_certificate_draft_aggregate_fields>;
  nodes: Array<energy_certificate_draft>;
};

export type energy_certificate_draft_aggregate_bool_exp = {
  bool_and?: InputMaybe<energy_certificate_draft_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<energy_certificate_draft_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<energy_certificate_draft_aggregate_bool_exp_count>;
};

export type energy_certificate_draft_aggregate_bool_exp_bool_and = {
  arguments: energy_certificate_draft_select_column_energy_certificate_draft_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<energy_certificate_draft_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type energy_certificate_draft_aggregate_bool_exp_bool_or = {
  arguments: energy_certificate_draft_select_column_energy_certificate_draft_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<energy_certificate_draft_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type energy_certificate_draft_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<energy_certificate_draft_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<energy_certificate_draft_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "energy_certificate_draft" */
export type energy_certificate_draft_aggregate_fields = {
  avg?: Maybe<energy_certificate_draft_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<energy_certificate_draft_max_fields>;
  min?: Maybe<energy_certificate_draft_min_fields>;
  stddev?: Maybe<energy_certificate_draft_stddev_fields>;
  stddev_pop?: Maybe<energy_certificate_draft_stddev_pop_fields>;
  stddev_samp?: Maybe<energy_certificate_draft_stddev_samp_fields>;
  sum?: Maybe<energy_certificate_draft_sum_fields>;
  var_pop?: Maybe<energy_certificate_draft_var_pop_fields>;
  var_samp?: Maybe<energy_certificate_draft_var_samp_fields>;
  variance?: Maybe<energy_certificate_draft_variance_fields>;
};

/** aggregate fields of "energy_certificate_draft" */
export type energy_certificate_draft_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<energy_certificate_draft_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "energy_certificate_draft" */
export type energy_certificate_draft_aggregate_order_by = {
  avg?: InputMaybe<energy_certificate_draft_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<energy_certificate_draft_max_order_by>;
  min?: InputMaybe<energy_certificate_draft_min_order_by>;
  stddev?: InputMaybe<energy_certificate_draft_stddev_order_by>;
  stddev_pop?: InputMaybe<energy_certificate_draft_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<energy_certificate_draft_stddev_samp_order_by>;
  sum?: InputMaybe<energy_certificate_draft_sum_order_by>;
  var_pop?: InputMaybe<energy_certificate_draft_var_pop_order_by>;
  var_samp?: InputMaybe<energy_certificate_draft_var_samp_order_by>;
  variance?: InputMaybe<energy_certificate_draft_variance_order_by>;
};

/** input type for inserting array relation for remote table "energy_certificate_draft" */
export type energy_certificate_draft_arr_rel_insert_input = {
  data: Array<energy_certificate_draft_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<energy_certificate_draft_on_conflict>;
};

/** aggregate avg on columns */
export type energy_certificate_draft_avg_fields = {
  area_usable?: Maybe<Scalars['Float']>;
  economic_unit_id?: Maybe<Scalars['Float']>;
  energy_certificate_file_id?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['Float']>;
  energy_effective_demand?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['Float']>;
  energy_primary_demand?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
  responsible_user_id?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
  year_constructed?: Maybe<Scalars['Float']>;
  year_renovated?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "energy_certificate_draft" */
export type energy_certificate_draft_avg_order_by = {
  area_usable?: InputMaybe<order_by>;
  economic_unit_id?: InputMaybe<order_by>;
  energy_certificate_file_id?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  units?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "energy_certificate_draft". All fields are combined with a logical 'AND'. */
export type energy_certificate_draft_bool_exp = {
  _and?: InputMaybe<Array<energy_certificate_draft_bool_exp>>;
  _not?: InputMaybe<energy_certificate_draft_bool_exp>;
  _or?: InputMaybe<Array<energy_certificate_draft_bool_exp>>;
  area_usable?: InputMaybe<numeric_comparison_exp>;
  building_state_id?: InputMaybe<building_state_enum_comparison_exp>;
  city?: InputMaybe<String_comparison_exp>;
  country_id?: InputMaybe<country_enum_comparison_exp>;
  country_state_id?: InputMaybe<country_state_enum_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  draft_status?: InputMaybe<draft_status_bool_exp>;
  draft_status_id?: InputMaybe<draft_status_enum_comparison_exp>;
  economic_unit?: InputMaybe<economic_unit_bool_exp>;
  economic_unit_id?: InputMaybe<Int_comparison_exp>;
  efficiency_class_id?: InputMaybe<efficiency_class_enum_comparison_exp>;
  energy_certificate_consumers?: InputMaybe<energy_certificate_consumer_bool_exp>;
  energy_certificate_consumers_aggregate?: InputMaybe<energy_certificate_consumer_aggregate_bool_exp>;
  energy_certificate_file_id?: InputMaybe<Int_comparison_exp>;
  energy_certificate_type_id?: InputMaybe<energy_certificate_type_enum_comparison_exp>;
  energy_effective_consumption?: InputMaybe<numeric_comparison_exp>;
  energy_effective_demand?: InputMaybe<numeric_comparison_exp>;
  energy_final_consumption?: InputMaybe<numeric_comparison_exp>;
  energy_final_demand?: InputMaybe<numeric_comparison_exp>;
  energy_primary_consumption?: InputMaybe<numeric_comparison_exp>;
  energy_primary_demand?: InputMaybe<numeric_comparison_exp>;
  energy_sources?: InputMaybe<energy_source_bool_exp>;
  energy_sources_aggregate?: InputMaybe<energy_source_aggregate_bool_exp>;
  expiry_date?: InputMaybe<timestamptz_comparison_exp>;
  file?: InputMaybe<file_bool_exp>;
  heritage_district?: InputMaybe<Boolean_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  leasehold?: InputMaybe<Boolean_comparison_exp>;
  milieu_protection?: InputMaybe<Boolean_comparison_exp>;
  monument_protection?: InputMaybe<Boolean_comparison_exp>;
  org?: InputMaybe<org_bool_exp>;
  org_id?: InputMaybe<Int_comparison_exp>;
  portfolio?: InputMaybe<portfolio_bool_exp>;
  portfolio_id?: InputMaybe<Int_comparison_exp>;
  postal_code?: InputMaybe<String_comparison_exp>;
  responsible_user_id?: InputMaybe<Int_comparison_exp>;
  street?: InputMaybe<String_comparison_exp>;
  sub_building_class_id?: InputMaybe<sub_building_class_enum_comparison_exp>;
  type_of_use_id?: InputMaybe<type_of_use_enum_comparison_exp>;
  units?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  user?: InputMaybe<user_bool_exp>;
  year_constructed?: InputMaybe<Int_comparison_exp>;
  year_renovated?: InputMaybe<Int_comparison_exp>;
};

export { energy_certificate_draft_constraint };

/** input type for incrementing numeric columns in table "energy_certificate_draft" */
export type energy_certificate_draft_inc_input = {
  area_usable?: InputMaybe<Scalars['numeric']>;
  economic_unit_id?: InputMaybe<Scalars['Int']>;
  energy_certificate_file_id?: InputMaybe<Scalars['Int']>;
  energy_effective_consumption?: InputMaybe<Scalars['numeric']>;
  energy_effective_demand?: InputMaybe<Scalars['numeric']>;
  energy_final_consumption?: InputMaybe<Scalars['numeric']>;
  energy_final_demand?: InputMaybe<Scalars['numeric']>;
  energy_primary_consumption?: InputMaybe<Scalars['numeric']>;
  energy_primary_demand?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
  portfolio_id?: InputMaybe<Scalars['Int']>;
  responsible_user_id?: InputMaybe<Scalars['Int']>;
  units?: InputMaybe<Scalars['Int']>;
  year_constructed?: InputMaybe<Scalars['Int']>;
  year_renovated?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "energy_certificate_draft" */
export type energy_certificate_draft_insert_input = {
  area_usable?: InputMaybe<Scalars['numeric']>;
  building_state_id?: InputMaybe<building_state_enum>;
  city?: InputMaybe<Scalars['String']>;
  country_id?: InputMaybe<country_enum>;
  country_state_id?: InputMaybe<country_state_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  draft_status?: InputMaybe<draft_status_obj_rel_insert_input>;
  draft_status_id?: InputMaybe<draft_status_enum>;
  economic_unit?: InputMaybe<economic_unit_obj_rel_insert_input>;
  economic_unit_id?: InputMaybe<Scalars['Int']>;
  efficiency_class_id?: InputMaybe<efficiency_class_enum>;
  energy_certificate_consumers?: InputMaybe<energy_certificate_consumer_arr_rel_insert_input>;
  energy_certificate_file_id?: InputMaybe<Scalars['Int']>;
  energy_certificate_type_id?: InputMaybe<energy_certificate_type_enum>;
  energy_effective_consumption?: InputMaybe<Scalars['numeric']>;
  energy_effective_demand?: InputMaybe<Scalars['numeric']>;
  energy_final_consumption?: InputMaybe<Scalars['numeric']>;
  energy_final_demand?: InputMaybe<Scalars['numeric']>;
  energy_primary_consumption?: InputMaybe<Scalars['numeric']>;
  energy_primary_demand?: InputMaybe<Scalars['numeric']>;
  energy_sources?: InputMaybe<energy_source_arr_rel_insert_input>;
  expiry_date?: InputMaybe<Scalars['timestamptz']>;
  file?: InputMaybe<file_obj_rel_insert_input>;
  heritage_district?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  leasehold?: InputMaybe<Scalars['Boolean']>;
  milieu_protection?: InputMaybe<Scalars['Boolean']>;
  monument_protection?: InputMaybe<Scalars['Boolean']>;
  org?: InputMaybe<org_obj_rel_insert_input>;
  org_id?: InputMaybe<Scalars['Int']>;
  portfolio?: InputMaybe<portfolio_obj_rel_insert_input>;
  portfolio_id?: InputMaybe<Scalars['Int']>;
  postal_code?: InputMaybe<Scalars['String']>;
  responsible_user_id?: InputMaybe<Scalars['Int']>;
  street?: InputMaybe<Scalars['String']>;
  sub_building_class_id?: InputMaybe<sub_building_class_enum>;
  type_of_use_id?: InputMaybe<type_of_use_enum>;
  units?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<user_obj_rel_insert_input>;
  year_constructed?: InputMaybe<Scalars['Int']>;
  year_renovated?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type energy_certificate_draft_max_fields = {
  area_usable?: Maybe<Scalars['numeric']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  economic_unit_id?: Maybe<Scalars['Int']>;
  energy_certificate_file_id?: Maybe<Scalars['Int']>;
  energy_effective_consumption?: Maybe<Scalars['numeric']>;
  energy_effective_demand?: Maybe<Scalars['numeric']>;
  energy_final_consumption?: Maybe<Scalars['numeric']>;
  energy_final_demand?: Maybe<Scalars['numeric']>;
  energy_primary_consumption?: Maybe<Scalars['numeric']>;
  energy_primary_demand?: Maybe<Scalars['numeric']>;
  expiry_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  portfolio_id?: Maybe<Scalars['Int']>;
  postal_code?: Maybe<Scalars['String']>;
  responsible_user_id?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year_constructed?: Maybe<Scalars['Int']>;
  year_renovated?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "energy_certificate_draft" */
export type energy_certificate_draft_max_order_by = {
  area_usable?: InputMaybe<order_by>;
  city?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  economic_unit_id?: InputMaybe<order_by>;
  energy_certificate_file_id?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  expiry_date?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
  postal_code?: InputMaybe<order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  street?: InputMaybe<order_by>;
  units?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type energy_certificate_draft_min_fields = {
  area_usable?: Maybe<Scalars['numeric']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  economic_unit_id?: Maybe<Scalars['Int']>;
  energy_certificate_file_id?: Maybe<Scalars['Int']>;
  energy_effective_consumption?: Maybe<Scalars['numeric']>;
  energy_effective_demand?: Maybe<Scalars['numeric']>;
  energy_final_consumption?: Maybe<Scalars['numeric']>;
  energy_final_demand?: Maybe<Scalars['numeric']>;
  energy_primary_consumption?: Maybe<Scalars['numeric']>;
  energy_primary_demand?: Maybe<Scalars['numeric']>;
  expiry_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  portfolio_id?: Maybe<Scalars['Int']>;
  postal_code?: Maybe<Scalars['String']>;
  responsible_user_id?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year_constructed?: Maybe<Scalars['Int']>;
  year_renovated?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "energy_certificate_draft" */
export type energy_certificate_draft_min_order_by = {
  area_usable?: InputMaybe<order_by>;
  city?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  economic_unit_id?: InputMaybe<order_by>;
  energy_certificate_file_id?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  expiry_date?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
  postal_code?: InputMaybe<order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  street?: InputMaybe<order_by>;
  units?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** response of any mutation on the table "energy_certificate_draft" */
export type energy_certificate_draft_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<energy_certificate_draft>;
};

/** input type for inserting object relation for remote table "energy_certificate_draft" */
export type energy_certificate_draft_obj_rel_insert_input = {
  data: energy_certificate_draft_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<energy_certificate_draft_on_conflict>;
};

/** on_conflict condition type for table "energy_certificate_draft" */
export type energy_certificate_draft_on_conflict = {
  constraint: energy_certificate_draft_constraint;
  update_columns?: Array<energy_certificate_draft_update_column>;
  where?: InputMaybe<energy_certificate_draft_bool_exp>;
};

/** Ordering options when selecting data from "energy_certificate_draft". */
export type energy_certificate_draft_order_by = {
  area_usable?: InputMaybe<order_by>;
  building_state_id?: InputMaybe<order_by>;
  city?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  country_state_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  draft_status?: InputMaybe<draft_status_order_by>;
  draft_status_id?: InputMaybe<order_by>;
  economic_unit?: InputMaybe<economic_unit_order_by>;
  economic_unit_id?: InputMaybe<order_by>;
  efficiency_class_id?: InputMaybe<order_by>;
  energy_certificate_consumers_aggregate?: InputMaybe<energy_certificate_consumer_aggregate_order_by>;
  energy_certificate_file_id?: InputMaybe<order_by>;
  energy_certificate_type_id?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  energy_sources_aggregate?: InputMaybe<energy_source_aggregate_order_by>;
  expiry_date?: InputMaybe<order_by>;
  file?: InputMaybe<file_order_by>;
  heritage_district?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  leasehold?: InputMaybe<order_by>;
  milieu_protection?: InputMaybe<order_by>;
  monument_protection?: InputMaybe<order_by>;
  org?: InputMaybe<org_order_by>;
  org_id?: InputMaybe<order_by>;
  portfolio?: InputMaybe<portfolio_order_by>;
  portfolio_id?: InputMaybe<order_by>;
  postal_code?: InputMaybe<order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  street?: InputMaybe<order_by>;
  sub_building_class_id?: InputMaybe<order_by>;
  type_of_use_id?: InputMaybe<order_by>;
  units?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  user?: InputMaybe<user_order_by>;
  year_constructed?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** primary key columns input for table: energy_certificate_draft */
export type energy_certificate_draft_pk_columns_input = {
  id: Scalars['Int'];
};

export { energy_certificate_draft_select_column };

export { energy_certificate_draft_select_column_energy_certificate_draft_aggregate_bool_exp_bool_and_arguments_columns };

export { energy_certificate_draft_select_column_energy_certificate_draft_aggregate_bool_exp_bool_or_arguments_columns };

/** input type for updating data in table "energy_certificate_draft" */
export type energy_certificate_draft_set_input = {
  area_usable?: InputMaybe<Scalars['numeric']>;
  building_state_id?: InputMaybe<building_state_enum>;
  city?: InputMaybe<Scalars['String']>;
  country_id?: InputMaybe<country_enum>;
  country_state_id?: InputMaybe<country_state_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  draft_status_id?: InputMaybe<draft_status_enum>;
  economic_unit_id?: InputMaybe<Scalars['Int']>;
  efficiency_class_id?: InputMaybe<efficiency_class_enum>;
  energy_certificate_file_id?: InputMaybe<Scalars['Int']>;
  energy_certificate_type_id?: InputMaybe<energy_certificate_type_enum>;
  energy_effective_consumption?: InputMaybe<Scalars['numeric']>;
  energy_effective_demand?: InputMaybe<Scalars['numeric']>;
  energy_final_consumption?: InputMaybe<Scalars['numeric']>;
  energy_final_demand?: InputMaybe<Scalars['numeric']>;
  energy_primary_consumption?: InputMaybe<Scalars['numeric']>;
  energy_primary_demand?: InputMaybe<Scalars['numeric']>;
  expiry_date?: InputMaybe<Scalars['timestamptz']>;
  heritage_district?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  leasehold?: InputMaybe<Scalars['Boolean']>;
  milieu_protection?: InputMaybe<Scalars['Boolean']>;
  monument_protection?: InputMaybe<Scalars['Boolean']>;
  org_id?: InputMaybe<Scalars['Int']>;
  portfolio_id?: InputMaybe<Scalars['Int']>;
  postal_code?: InputMaybe<Scalars['String']>;
  responsible_user_id?: InputMaybe<Scalars['Int']>;
  street?: InputMaybe<Scalars['String']>;
  sub_building_class_id?: InputMaybe<sub_building_class_enum>;
  type_of_use_id?: InputMaybe<type_of_use_enum>;
  units?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  year_constructed?: InputMaybe<Scalars['Int']>;
  year_renovated?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type energy_certificate_draft_stddev_fields = {
  area_usable?: Maybe<Scalars['Float']>;
  economic_unit_id?: Maybe<Scalars['Float']>;
  energy_certificate_file_id?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['Float']>;
  energy_effective_demand?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['Float']>;
  energy_primary_demand?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
  responsible_user_id?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
  year_constructed?: Maybe<Scalars['Float']>;
  year_renovated?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "energy_certificate_draft" */
export type energy_certificate_draft_stddev_order_by = {
  area_usable?: InputMaybe<order_by>;
  economic_unit_id?: InputMaybe<order_by>;
  energy_certificate_file_id?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  units?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type energy_certificate_draft_stddev_pop_fields = {
  area_usable?: Maybe<Scalars['Float']>;
  economic_unit_id?: Maybe<Scalars['Float']>;
  energy_certificate_file_id?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['Float']>;
  energy_effective_demand?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['Float']>;
  energy_primary_demand?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
  responsible_user_id?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
  year_constructed?: Maybe<Scalars['Float']>;
  year_renovated?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "energy_certificate_draft" */
export type energy_certificate_draft_stddev_pop_order_by = {
  area_usable?: InputMaybe<order_by>;
  economic_unit_id?: InputMaybe<order_by>;
  energy_certificate_file_id?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  units?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type energy_certificate_draft_stddev_samp_fields = {
  area_usable?: Maybe<Scalars['Float']>;
  economic_unit_id?: Maybe<Scalars['Float']>;
  energy_certificate_file_id?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['Float']>;
  energy_effective_demand?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['Float']>;
  energy_primary_demand?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
  responsible_user_id?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
  year_constructed?: Maybe<Scalars['Float']>;
  year_renovated?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "energy_certificate_draft" */
export type energy_certificate_draft_stddev_samp_order_by = {
  area_usable?: InputMaybe<order_by>;
  economic_unit_id?: InputMaybe<order_by>;
  energy_certificate_file_id?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  units?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "energy_certificate_draft" */
export type energy_certificate_draft_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: energy_certificate_draft_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type energy_certificate_draft_stream_cursor_value_input = {
  area_usable?: InputMaybe<Scalars['numeric']>;
  building_state_id?: InputMaybe<building_state_enum>;
  city?: InputMaybe<Scalars['String']>;
  country_id?: InputMaybe<country_enum>;
  country_state_id?: InputMaybe<country_state_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  draft_status_id?: InputMaybe<draft_status_enum>;
  economic_unit_id?: InputMaybe<Scalars['Int']>;
  efficiency_class_id?: InputMaybe<efficiency_class_enum>;
  energy_certificate_file_id?: InputMaybe<Scalars['Int']>;
  energy_certificate_type_id?: InputMaybe<energy_certificate_type_enum>;
  energy_effective_consumption?: InputMaybe<Scalars['numeric']>;
  energy_effective_demand?: InputMaybe<Scalars['numeric']>;
  energy_final_consumption?: InputMaybe<Scalars['numeric']>;
  energy_final_demand?: InputMaybe<Scalars['numeric']>;
  energy_primary_consumption?: InputMaybe<Scalars['numeric']>;
  energy_primary_demand?: InputMaybe<Scalars['numeric']>;
  expiry_date?: InputMaybe<Scalars['timestamptz']>;
  heritage_district?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  leasehold?: InputMaybe<Scalars['Boolean']>;
  milieu_protection?: InputMaybe<Scalars['Boolean']>;
  monument_protection?: InputMaybe<Scalars['Boolean']>;
  org_id?: InputMaybe<Scalars['Int']>;
  portfolio_id?: InputMaybe<Scalars['Int']>;
  postal_code?: InputMaybe<Scalars['String']>;
  responsible_user_id?: InputMaybe<Scalars['Int']>;
  street?: InputMaybe<Scalars['String']>;
  sub_building_class_id?: InputMaybe<sub_building_class_enum>;
  type_of_use_id?: InputMaybe<type_of_use_enum>;
  units?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  year_constructed?: InputMaybe<Scalars['Int']>;
  year_renovated?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type energy_certificate_draft_sum_fields = {
  area_usable?: Maybe<Scalars['numeric']>;
  economic_unit_id?: Maybe<Scalars['Int']>;
  energy_certificate_file_id?: Maybe<Scalars['Int']>;
  energy_effective_consumption?: Maybe<Scalars['numeric']>;
  energy_effective_demand?: Maybe<Scalars['numeric']>;
  energy_final_consumption?: Maybe<Scalars['numeric']>;
  energy_final_demand?: Maybe<Scalars['numeric']>;
  energy_primary_consumption?: Maybe<Scalars['numeric']>;
  energy_primary_demand?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  portfolio_id?: Maybe<Scalars['Int']>;
  responsible_user_id?: Maybe<Scalars['Int']>;
  units?: Maybe<Scalars['Int']>;
  year_constructed?: Maybe<Scalars['Int']>;
  year_renovated?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "energy_certificate_draft" */
export type energy_certificate_draft_sum_order_by = {
  area_usable?: InputMaybe<order_by>;
  economic_unit_id?: InputMaybe<order_by>;
  energy_certificate_file_id?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  units?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

export { energy_certificate_draft_update_column };

export type energy_certificate_draft_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<energy_certificate_draft_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<energy_certificate_draft_set_input>;
  /** filter the rows which have to be updated */
  where: energy_certificate_draft_bool_exp;
};

/** aggregate var_pop on columns */
export type energy_certificate_draft_var_pop_fields = {
  area_usable?: Maybe<Scalars['Float']>;
  economic_unit_id?: Maybe<Scalars['Float']>;
  energy_certificate_file_id?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['Float']>;
  energy_effective_demand?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['Float']>;
  energy_primary_demand?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
  responsible_user_id?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
  year_constructed?: Maybe<Scalars['Float']>;
  year_renovated?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "energy_certificate_draft" */
export type energy_certificate_draft_var_pop_order_by = {
  area_usable?: InputMaybe<order_by>;
  economic_unit_id?: InputMaybe<order_by>;
  energy_certificate_file_id?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  units?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type energy_certificate_draft_var_samp_fields = {
  area_usable?: Maybe<Scalars['Float']>;
  economic_unit_id?: Maybe<Scalars['Float']>;
  energy_certificate_file_id?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['Float']>;
  energy_effective_demand?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['Float']>;
  energy_primary_demand?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
  responsible_user_id?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
  year_constructed?: Maybe<Scalars['Float']>;
  year_renovated?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "energy_certificate_draft" */
export type energy_certificate_draft_var_samp_order_by = {
  area_usable?: InputMaybe<order_by>;
  economic_unit_id?: InputMaybe<order_by>;
  energy_certificate_file_id?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  units?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type energy_certificate_draft_variance_fields = {
  area_usable?: Maybe<Scalars['Float']>;
  economic_unit_id?: Maybe<Scalars['Float']>;
  energy_certificate_file_id?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['Float']>;
  energy_effective_demand?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['Float']>;
  energy_primary_demand?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
  responsible_user_id?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
  year_constructed?: Maybe<Scalars['Float']>;
  year_renovated?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "energy_certificate_draft" */
export type energy_certificate_draft_variance_order_by = {
  area_usable?: InputMaybe<order_by>;
  economic_unit_id?: InputMaybe<order_by>;
  energy_certificate_file_id?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  units?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** input type for incrementing numeric columns in table "energy_certificate" */
export type energy_certificate_inc_input = {
  area_usable?: InputMaybe<Scalars['numeric']>;
  co2_emissions?: InputMaybe<Scalars['numeric']>;
  energy_effective_consumption?: InputMaybe<Scalars['numeric']>;
  energy_effective_demand?: InputMaybe<Scalars['numeric']>;
  energy_final_consumption?: InputMaybe<Scalars['numeric']>;
  energy_final_demand?: InputMaybe<Scalars['numeric']>;
  energy_primary_consumption?: InputMaybe<Scalars['numeric']>;
  energy_primary_demand?: InputMaybe<Scalars['numeric']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  units_commercial?: InputMaybe<Scalars['Int']>;
  units_residential?: InputMaybe<Scalars['Int']>;
  year_constructed?: InputMaybe<Scalars['Int']>;
  year_renovated?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "energy_certificate" */
export type energy_certificate_insert_input = {
  area_usable?: InputMaybe<Scalars['numeric']>;
  city?: InputMaybe<Scalars['String']>;
  co2_emissions?: InputMaybe<Scalars['numeric']>;
  country_id?: InputMaybe<country_enum>;
  country_state_id?: InputMaybe<country_state_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  efficiency_class?: InputMaybe<efficiency_class_enum>;
  energy_certificate_consumers?: InputMaybe<energy_certificate_consumer_arr_rel_insert_input>;
  energy_certificate_type?: InputMaybe<energy_certificate_type_enum>;
  energy_effective_consumption?: InputMaybe<Scalars['numeric']>;
  energy_effective_demand?: InputMaybe<Scalars['numeric']>;
  energy_final_consumption?: InputMaybe<Scalars['numeric']>;
  energy_final_demand?: InputMaybe<Scalars['numeric']>;
  energy_primary_consumption?: InputMaybe<Scalars['numeric']>;
  energy_primary_demand?: InputMaybe<Scalars['numeric']>;
  expiry_date?: InputMaybe<Scalars['timestamptz']>;
  file?: InputMaybe<file_obj_rel_insert_input>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  org?: InputMaybe<org_obj_rel_insert_input>;
  org_id?: InputMaybe<Scalars['Int']>;
  postal_code?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  sub_building?: InputMaybe<sub_building_obj_rel_insert_input>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  units_commercial?: InputMaybe<Scalars['Int']>;
  units_residential?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  year_constructed?: InputMaybe<Scalars['Int']>;
  year_renovated?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type energy_certificate_max_fields = {
  area_usable?: Maybe<Scalars['numeric']>;
  city?: Maybe<Scalars['String']>;
  co2_emissions?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  energy_effective_consumption?: Maybe<Scalars['numeric']>;
  energy_effective_demand?: Maybe<Scalars['numeric']>;
  energy_final_consumption?: Maybe<Scalars['numeric']>;
  energy_final_demand?: Maybe<Scalars['numeric']>;
  energy_primary_consumption?: Maybe<Scalars['numeric']>;
  energy_primary_demand?: Maybe<Scalars['numeric']>;
  expiry_date?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  postal_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  sub_building_id?: Maybe<Scalars['Int']>;
  units_commercial?: Maybe<Scalars['Int']>;
  units_residential?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year_constructed?: Maybe<Scalars['Int']>;
  year_renovated?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "energy_certificate" */
export type energy_certificate_max_order_by = {
  area_usable?: InputMaybe<order_by>;
  city?: InputMaybe<order_by>;
  co2_emissions?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  expiry_date?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  postal_code?: InputMaybe<order_by>;
  state?: InputMaybe<order_by>;
  street?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  units_commercial?: InputMaybe<order_by>;
  units_residential?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type energy_certificate_min_fields = {
  area_usable?: Maybe<Scalars['numeric']>;
  city?: Maybe<Scalars['String']>;
  co2_emissions?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  energy_effective_consumption?: Maybe<Scalars['numeric']>;
  energy_effective_demand?: Maybe<Scalars['numeric']>;
  energy_final_consumption?: Maybe<Scalars['numeric']>;
  energy_final_demand?: Maybe<Scalars['numeric']>;
  energy_primary_consumption?: Maybe<Scalars['numeric']>;
  energy_primary_demand?: Maybe<Scalars['numeric']>;
  expiry_date?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  postal_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  sub_building_id?: Maybe<Scalars['Int']>;
  units_commercial?: Maybe<Scalars['Int']>;
  units_residential?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year_constructed?: Maybe<Scalars['Int']>;
  year_renovated?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "energy_certificate" */
export type energy_certificate_min_order_by = {
  area_usable?: InputMaybe<order_by>;
  city?: InputMaybe<order_by>;
  co2_emissions?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  expiry_date?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  postal_code?: InputMaybe<order_by>;
  state?: InputMaybe<order_by>;
  street?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  units_commercial?: InputMaybe<order_by>;
  units_residential?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** response of any mutation on the table "energy_certificate" */
export type energy_certificate_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<energy_certificate>;
};

/** input type for inserting object relation for remote table "energy_certificate" */
export type energy_certificate_obj_rel_insert_input = {
  data: energy_certificate_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<energy_certificate_on_conflict>;
};

/** on_conflict condition type for table "energy_certificate" */
export type energy_certificate_on_conflict = {
  constraint: energy_certificate_constraint;
  update_columns?: Array<energy_certificate_update_column>;
  where?: InputMaybe<energy_certificate_bool_exp>;
};

/** Ordering options when selecting data from "energy_certificate". */
export type energy_certificate_order_by = {
  area_usable?: InputMaybe<order_by>;
  city?: InputMaybe<order_by>;
  co2_emissions?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  country_state_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  efficiency_class?: InputMaybe<order_by>;
  energy_certificate_consumers_aggregate?: InputMaybe<energy_certificate_consumer_aggregate_order_by>;
  energy_certificate_type?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  expiry_date?: InputMaybe<order_by>;
  file?: InputMaybe<file_order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org?: InputMaybe<org_order_by>;
  org_id?: InputMaybe<order_by>;
  postal_code?: InputMaybe<order_by>;
  state?: InputMaybe<order_by>;
  street?: InputMaybe<order_by>;
  sub_building?: InputMaybe<sub_building_order_by>;
  sub_building_id?: InputMaybe<order_by>;
  units_commercial?: InputMaybe<order_by>;
  units_residential?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** primary key columns input for table: energy_certificate */
export type energy_certificate_pk_columns_input = {
  id: Scalars['Int'];
};

export { energy_certificate_select_column };

/** input type for updating data in table "energy_certificate" */
export type energy_certificate_set_input = {
  area_usable?: InputMaybe<Scalars['numeric']>;
  city?: InputMaybe<Scalars['String']>;
  co2_emissions?: InputMaybe<Scalars['numeric']>;
  country_id?: InputMaybe<country_enum>;
  country_state_id?: InputMaybe<country_state_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  efficiency_class?: InputMaybe<efficiency_class_enum>;
  energy_certificate_type?: InputMaybe<energy_certificate_type_enum>;
  energy_effective_consumption?: InputMaybe<Scalars['numeric']>;
  energy_effective_demand?: InputMaybe<Scalars['numeric']>;
  energy_final_consumption?: InputMaybe<Scalars['numeric']>;
  energy_final_demand?: InputMaybe<Scalars['numeric']>;
  energy_primary_consumption?: InputMaybe<Scalars['numeric']>;
  energy_primary_demand?: InputMaybe<Scalars['numeric']>;
  expiry_date?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
  postal_code?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  units_commercial?: InputMaybe<Scalars['Int']>;
  units_residential?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  year_constructed?: InputMaybe<Scalars['Int']>;
  year_renovated?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type energy_certificate_stddev_fields = {
  area_usable?: Maybe<Scalars['Float']>;
  co2_emissions?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['Float']>;
  energy_effective_demand?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['Float']>;
  energy_primary_demand?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  units_commercial?: Maybe<Scalars['Float']>;
  units_residential?: Maybe<Scalars['Float']>;
  year_constructed?: Maybe<Scalars['Float']>;
  year_renovated?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "energy_certificate" */
export type energy_certificate_stddev_order_by = {
  area_usable?: InputMaybe<order_by>;
  co2_emissions?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  units_commercial?: InputMaybe<order_by>;
  units_residential?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type energy_certificate_stddev_pop_fields = {
  area_usable?: Maybe<Scalars['Float']>;
  co2_emissions?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['Float']>;
  energy_effective_demand?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['Float']>;
  energy_primary_demand?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  units_commercial?: Maybe<Scalars['Float']>;
  units_residential?: Maybe<Scalars['Float']>;
  year_constructed?: Maybe<Scalars['Float']>;
  year_renovated?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "energy_certificate" */
export type energy_certificate_stddev_pop_order_by = {
  area_usable?: InputMaybe<order_by>;
  co2_emissions?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  units_commercial?: InputMaybe<order_by>;
  units_residential?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type energy_certificate_stddev_samp_fields = {
  area_usable?: Maybe<Scalars['Float']>;
  co2_emissions?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['Float']>;
  energy_effective_demand?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['Float']>;
  energy_primary_demand?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  units_commercial?: Maybe<Scalars['Float']>;
  units_residential?: Maybe<Scalars['Float']>;
  year_constructed?: Maybe<Scalars['Float']>;
  year_renovated?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "energy_certificate" */
export type energy_certificate_stddev_samp_order_by = {
  area_usable?: InputMaybe<order_by>;
  co2_emissions?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  units_commercial?: InputMaybe<order_by>;
  units_residential?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "energy_certificate" */
export type energy_certificate_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: energy_certificate_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type energy_certificate_stream_cursor_value_input = {
  area_usable?: InputMaybe<Scalars['numeric']>;
  city?: InputMaybe<Scalars['String']>;
  co2_emissions?: InputMaybe<Scalars['numeric']>;
  country_id?: InputMaybe<country_enum>;
  country_state_id?: InputMaybe<country_state_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  efficiency_class?: InputMaybe<efficiency_class_enum>;
  energy_certificate_type?: InputMaybe<energy_certificate_type_enum>;
  energy_effective_consumption?: InputMaybe<Scalars['numeric']>;
  energy_effective_demand?: InputMaybe<Scalars['numeric']>;
  energy_final_consumption?: InputMaybe<Scalars['numeric']>;
  energy_final_demand?: InputMaybe<Scalars['numeric']>;
  energy_primary_consumption?: InputMaybe<Scalars['numeric']>;
  energy_primary_demand?: InputMaybe<Scalars['numeric']>;
  expiry_date?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
  postal_code?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  units_commercial?: InputMaybe<Scalars['Int']>;
  units_residential?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  year_constructed?: InputMaybe<Scalars['Int']>;
  year_renovated?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type energy_certificate_sum_fields = {
  area_usable?: Maybe<Scalars['numeric']>;
  co2_emissions?: Maybe<Scalars['numeric']>;
  energy_effective_consumption?: Maybe<Scalars['numeric']>;
  energy_effective_demand?: Maybe<Scalars['numeric']>;
  energy_final_consumption?: Maybe<Scalars['numeric']>;
  energy_final_demand?: Maybe<Scalars['numeric']>;
  energy_primary_consumption?: Maybe<Scalars['numeric']>;
  energy_primary_demand?: Maybe<Scalars['numeric']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
  units_commercial?: Maybe<Scalars['Int']>;
  units_residential?: Maybe<Scalars['Int']>;
  year_constructed?: Maybe<Scalars['Int']>;
  year_renovated?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "energy_certificate" */
export type energy_certificate_sum_order_by = {
  area_usable?: InputMaybe<order_by>;
  co2_emissions?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  units_commercial?: InputMaybe<order_by>;
  units_residential?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** columns and relationships of "energy_certificate_type" */
export type energy_certificate_type = {
  id: Scalars['String'];
};

/** aggregated selection of "energy_certificate_type" */
export type energy_certificate_type_aggregate = {
  aggregate?: Maybe<energy_certificate_type_aggregate_fields>;
  nodes: Array<energy_certificate_type>;
};

/** aggregate fields of "energy_certificate_type" */
export type energy_certificate_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<energy_certificate_type_max_fields>;
  min?: Maybe<energy_certificate_type_min_fields>;
};

/** aggregate fields of "energy_certificate_type" */
export type energy_certificate_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<energy_certificate_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "energy_certificate_type". All fields are combined with a logical 'AND'. */
export type energy_certificate_type_bool_exp = {
  _and?: InputMaybe<Array<energy_certificate_type_bool_exp>>;
  _not?: InputMaybe<energy_certificate_type_bool_exp>;
  _or?: InputMaybe<Array<energy_certificate_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { energy_certificate_type_constraint };

export { energy_certificate_type_enum };

/** Boolean expression to compare columns of type "energy_certificate_type_enum". All fields are combined with logical 'AND'. */
export type energy_certificate_type_enum_comparison_exp = {
  _eq?: InputMaybe<energy_certificate_type_enum>;
  _in?: InputMaybe<Array<energy_certificate_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<energy_certificate_type_enum>;
  _nin?: InputMaybe<Array<energy_certificate_type_enum>>;
};

/** input type for inserting data into table "energy_certificate_type" */
export type energy_certificate_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type energy_certificate_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type energy_certificate_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "energy_certificate_type" */
export type energy_certificate_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<energy_certificate_type>;
};

/** on_conflict condition type for table "energy_certificate_type" */
export type energy_certificate_type_on_conflict = {
  constraint: energy_certificate_type_constraint;
  update_columns?: Array<energy_certificate_type_update_column>;
  where?: InputMaybe<energy_certificate_type_bool_exp>;
};

/** Ordering options when selecting data from "energy_certificate_type". */
export type energy_certificate_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: energy_certificate_type */
export type energy_certificate_type_pk_columns_input = {
  id: Scalars['String'];
};

export { energy_certificate_type_select_column };

/** input type for updating data in table "energy_certificate_type" */
export type energy_certificate_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "energy_certificate_type" */
export type energy_certificate_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: energy_certificate_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type energy_certificate_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { energy_certificate_type_update_column };

export type energy_certificate_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<energy_certificate_type_set_input>;
  /** filter the rows which have to be updated */
  where: energy_certificate_type_bool_exp;
};

export { energy_certificate_update_column };

export type energy_certificate_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<energy_certificate_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<energy_certificate_set_input>;
  /** filter the rows which have to be updated */
  where: energy_certificate_bool_exp;
};

/** aggregate var_pop on columns */
export type energy_certificate_var_pop_fields = {
  area_usable?: Maybe<Scalars['Float']>;
  co2_emissions?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['Float']>;
  energy_effective_demand?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['Float']>;
  energy_primary_demand?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  units_commercial?: Maybe<Scalars['Float']>;
  units_residential?: Maybe<Scalars['Float']>;
  year_constructed?: Maybe<Scalars['Float']>;
  year_renovated?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "energy_certificate" */
export type energy_certificate_var_pop_order_by = {
  area_usable?: InputMaybe<order_by>;
  co2_emissions?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  units_commercial?: InputMaybe<order_by>;
  units_residential?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type energy_certificate_var_samp_fields = {
  area_usable?: Maybe<Scalars['Float']>;
  co2_emissions?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['Float']>;
  energy_effective_demand?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['Float']>;
  energy_primary_demand?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  units_commercial?: Maybe<Scalars['Float']>;
  units_residential?: Maybe<Scalars['Float']>;
  year_constructed?: Maybe<Scalars['Float']>;
  year_renovated?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "energy_certificate" */
export type energy_certificate_var_samp_order_by = {
  area_usable?: InputMaybe<order_by>;
  co2_emissions?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  units_commercial?: InputMaybe<order_by>;
  units_residential?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type energy_certificate_variance_fields = {
  area_usable?: Maybe<Scalars['Float']>;
  co2_emissions?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['Float']>;
  energy_effective_demand?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['Float']>;
  energy_primary_demand?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  units_commercial?: Maybe<Scalars['Float']>;
  units_residential?: Maybe<Scalars['Float']>;
  year_constructed?: Maybe<Scalars['Float']>;
  year_renovated?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "energy_certificate" */
export type energy_certificate_variance_order_by = {
  area_usable?: InputMaybe<order_by>;
  co2_emissions?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  units_commercial?: InputMaybe<order_by>;
  units_residential?: InputMaybe<order_by>;
  year_constructed?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** columns and relationships of "energy_consumer" */
export type energy_consumer = {
  /** An object relationship */
  action?: Maybe<action>;
  action_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  building_model?: Maybe<building_model>;
  building_model_id?: Maybe<Scalars['Int']>;
  construction_year?: Maybe<Scalars['Int']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  created_at: Scalars['timestamptz'];
  efficiency?: Maybe<Scalars['Float']>;
  energy_consumer_technology_type_id?: Maybe<energy_consumer_technology_type_enum>;
  energy_consumer_type_id: energy_consumer_type_enum;
  energy_effective_consumption?: Maybe<Scalars['numeric']>;
  energy_effective_demand?: Maybe<Scalars['numeric']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['numeric']>;
  energy_final_demand?: Maybe<Scalars['numeric']>;
  energy_source_type_id?: Maybe<energy_source_type_enum>;
  /** An array relationship */
  energy_system_consumer_routes: Array<energy_system_consumer_route>;
  /** An aggregate relationship */
  energy_system_consumer_routes_aggregate: energy_system_consumer_route_aggregate;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  source_renovations?: Maybe<renovation_energy_consumer>;
  /** An object relationship */
  sub_building?: Maybe<sub_building>;
  sub_building_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  target_renovations: Array<renovation_energy_consumer>;
  /** An aggregate relationship */
  target_renovations_aggregate: renovation_energy_consumer_aggregate;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "energy_consumer" */
export type energy_consumerenergy_system_consumer_routesArgs = {
  distinct_on?: InputMaybe<Array<energy_system_consumer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_consumer_route_order_by>>;
  where?: InputMaybe<energy_system_consumer_route_bool_exp>;
};

/** columns and relationships of "energy_consumer" */
export type energy_consumerenergy_system_consumer_routes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_system_consumer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_consumer_route_order_by>>;
  where?: InputMaybe<energy_system_consumer_route_bool_exp>;
};

/** columns and relationships of "energy_consumer" */
export type energy_consumertarget_renovationsArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_consumer_order_by>>;
  where?: InputMaybe<renovation_energy_consumer_bool_exp>;
};

/** columns and relationships of "energy_consumer" */
export type energy_consumertarget_renovations_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_consumer_order_by>>;
  where?: InputMaybe<renovation_energy_consumer_bool_exp>;
};

/** aggregated selection of "energy_consumer" */
export type energy_consumer_aggregate = {
  aggregate?: Maybe<energy_consumer_aggregate_fields>;
  nodes: Array<energy_consumer>;
};

export type energy_consumer_aggregate_bool_exp = {
  count?: InputMaybe<energy_consumer_aggregate_bool_exp_count>;
};

export type energy_consumer_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<energy_consumer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<energy_consumer_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "energy_consumer" */
export type energy_consumer_aggregate_fields = {
  avg?: Maybe<energy_consumer_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<energy_consumer_max_fields>;
  min?: Maybe<energy_consumer_min_fields>;
  stddev?: Maybe<energy_consumer_stddev_fields>;
  stddev_pop?: Maybe<energy_consumer_stddev_pop_fields>;
  stddev_samp?: Maybe<energy_consumer_stddev_samp_fields>;
  sum?: Maybe<energy_consumer_sum_fields>;
  var_pop?: Maybe<energy_consumer_var_pop_fields>;
  var_samp?: Maybe<energy_consumer_var_samp_fields>;
  variance?: Maybe<energy_consumer_variance_fields>;
};

/** aggregate fields of "energy_consumer" */
export type energy_consumer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<energy_consumer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "energy_consumer" */
export type energy_consumer_aggregate_order_by = {
  avg?: InputMaybe<energy_consumer_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<energy_consumer_max_order_by>;
  min?: InputMaybe<energy_consumer_min_order_by>;
  stddev?: InputMaybe<energy_consumer_stddev_order_by>;
  stddev_pop?: InputMaybe<energy_consumer_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<energy_consumer_stddev_samp_order_by>;
  sum?: InputMaybe<energy_consumer_sum_order_by>;
  var_pop?: InputMaybe<energy_consumer_var_pop_order_by>;
  var_samp?: InputMaybe<energy_consumer_var_samp_order_by>;
  variance?: InputMaybe<energy_consumer_variance_order_by>;
};

/** input type for inserting array relation for remote table "energy_consumer" */
export type energy_consumer_arr_rel_insert_input = {
  data: Array<energy_consumer_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<energy_consumer_on_conflict>;
};

/** aggregate avg on columns */
export type energy_consumer_avg_fields = {
  action_id?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['Float']>;
  energy_effective_demand?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "energy_consumer" */
export type energy_consumer_avg_order_by = {
  action_id?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  efficiency?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "energy_consumer". All fields are combined with a logical 'AND'. */
export type energy_consumer_bool_exp = {
  _and?: InputMaybe<Array<energy_consumer_bool_exp>>;
  _not?: InputMaybe<energy_consumer_bool_exp>;
  _or?: InputMaybe<Array<energy_consumer_bool_exp>>;
  action?: InputMaybe<action_bool_exp>;
  action_id?: InputMaybe<Int_comparison_exp>;
  building_model?: InputMaybe<building_model_bool_exp>;
  building_model_id?: InputMaybe<Int_comparison_exp>;
  construction_year?: InputMaybe<Int_comparison_exp>;
  cost_per_m2?: InputMaybe<Float_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  efficiency?: InputMaybe<Float_comparison_exp>;
  energy_consumer_technology_type_id?: InputMaybe<energy_consumer_technology_type_enum_comparison_exp>;
  energy_consumer_type_id?: InputMaybe<energy_consumer_type_enum_comparison_exp>;
  energy_effective_consumption?: InputMaybe<numeric_comparison_exp>;
  energy_effective_demand?: InputMaybe<numeric_comparison_exp>;
  energy_final?: InputMaybe<Float_comparison_exp>;
  energy_final_consumption?: InputMaybe<numeric_comparison_exp>;
  energy_final_demand?: InputMaybe<numeric_comparison_exp>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum_comparison_exp>;
  energy_system_consumer_routes?: InputMaybe<energy_system_consumer_route_bool_exp>;
  energy_system_consumer_routes_aggregate?: InputMaybe<energy_system_consumer_route_aggregate_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  source_renovations?: InputMaybe<renovation_energy_consumer_bool_exp>;
  sub_building?: InputMaybe<sub_building_bool_exp>;
  sub_building_id?: InputMaybe<Int_comparison_exp>;
  target_renovations?: InputMaybe<renovation_energy_consumer_bool_exp>;
  target_renovations_aggregate?: InputMaybe<renovation_energy_consumer_aggregate_bool_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

export { energy_consumer_constraint };

/** input type for incrementing numeric columns in table "energy_consumer" */
export type energy_consumer_inc_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  building_model_id?: InputMaybe<Scalars['Int']>;
  construction_year?: InputMaybe<Scalars['Int']>;
  cost_per_m2?: InputMaybe<Scalars['Float']>;
  efficiency?: InputMaybe<Scalars['Float']>;
  energy_effective_consumption?: InputMaybe<Scalars['numeric']>;
  energy_effective_demand?: InputMaybe<Scalars['numeric']>;
  energy_final?: InputMaybe<Scalars['Float']>;
  energy_final_consumption?: InputMaybe<Scalars['numeric']>;
  energy_final_demand?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "energy_consumer" */
export type energy_consumer_insert_input = {
  action?: InputMaybe<action_obj_rel_insert_input>;
  action_id?: InputMaybe<Scalars['Int']>;
  building_model?: InputMaybe<building_model_obj_rel_insert_input>;
  building_model_id?: InputMaybe<Scalars['Int']>;
  construction_year?: InputMaybe<Scalars['Int']>;
  cost_per_m2?: InputMaybe<Scalars['Float']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  efficiency?: InputMaybe<Scalars['Float']>;
  energy_consumer_technology_type_id?: InputMaybe<energy_consumer_technology_type_enum>;
  energy_consumer_type_id?: InputMaybe<energy_consumer_type_enum>;
  energy_effective_consumption?: InputMaybe<Scalars['numeric']>;
  energy_effective_demand?: InputMaybe<Scalars['numeric']>;
  energy_final?: InputMaybe<Scalars['Float']>;
  energy_final_consumption?: InputMaybe<Scalars['numeric']>;
  energy_final_demand?: InputMaybe<Scalars['numeric']>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum>;
  energy_system_consumer_routes?: InputMaybe<energy_system_consumer_route_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  source_renovations?: InputMaybe<renovation_energy_consumer_obj_rel_insert_input>;
  sub_building?: InputMaybe<sub_building_obj_rel_insert_input>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  target_renovations?: InputMaybe<renovation_energy_consumer_arr_rel_insert_input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type energy_consumer_max_fields = {
  action_id?: Maybe<Scalars['Int']>;
  building_model_id?: Maybe<Scalars['Int']>;
  construction_year?: Maybe<Scalars['Int']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  efficiency?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['numeric']>;
  energy_effective_demand?: Maybe<Scalars['numeric']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['numeric']>;
  energy_final_demand?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  sub_building_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "energy_consumer" */
export type energy_consumer_max_order_by = {
  action_id?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  efficiency?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type energy_consumer_min_fields = {
  action_id?: Maybe<Scalars['Int']>;
  building_model_id?: Maybe<Scalars['Int']>;
  construction_year?: Maybe<Scalars['Int']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  efficiency?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['numeric']>;
  energy_effective_demand?: Maybe<Scalars['numeric']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['numeric']>;
  energy_final_demand?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  sub_building_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "energy_consumer" */
export type energy_consumer_min_order_by = {
  action_id?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  efficiency?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "energy_consumer" */
export type energy_consumer_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<energy_consumer>;
};

/** input type for inserting object relation for remote table "energy_consumer" */
export type energy_consumer_obj_rel_insert_input = {
  data: energy_consumer_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<energy_consumer_on_conflict>;
};

/** on_conflict condition type for table "energy_consumer" */
export type energy_consumer_on_conflict = {
  constraint: energy_consumer_constraint;
  update_columns?: Array<energy_consumer_update_column>;
  where?: InputMaybe<energy_consumer_bool_exp>;
};

/** Ordering options when selecting data from "energy_consumer". */
export type energy_consumer_order_by = {
  action?: InputMaybe<action_order_by>;
  action_id?: InputMaybe<order_by>;
  building_model?: InputMaybe<building_model_order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  efficiency?: InputMaybe<order_by>;
  energy_consumer_technology_type_id?: InputMaybe<order_by>;
  energy_consumer_type_id?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_source_type_id?: InputMaybe<order_by>;
  energy_system_consumer_routes_aggregate?: InputMaybe<energy_system_consumer_route_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  source_renovations?: InputMaybe<renovation_energy_consumer_order_by>;
  sub_building?: InputMaybe<sub_building_order_by>;
  sub_building_id?: InputMaybe<order_by>;
  target_renovations_aggregate?: InputMaybe<renovation_energy_consumer_aggregate_order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: energy_consumer */
export type energy_consumer_pk_columns_input = {
  id: Scalars['Int'];
};

export { energy_consumer_select_column };

/** input type for updating data in table "energy_consumer" */
export type energy_consumer_set_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  building_model_id?: InputMaybe<Scalars['Int']>;
  construction_year?: InputMaybe<Scalars['Int']>;
  cost_per_m2?: InputMaybe<Scalars['Float']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  efficiency?: InputMaybe<Scalars['Float']>;
  energy_consumer_technology_type_id?: InputMaybe<energy_consumer_technology_type_enum>;
  energy_consumer_type_id?: InputMaybe<energy_consumer_type_enum>;
  energy_effective_consumption?: InputMaybe<Scalars['numeric']>;
  energy_effective_demand?: InputMaybe<Scalars['numeric']>;
  energy_final?: InputMaybe<Scalars['Float']>;
  energy_final_consumption?: InputMaybe<Scalars['numeric']>;
  energy_final_demand?: InputMaybe<Scalars['numeric']>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type energy_consumer_stddev_fields = {
  action_id?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['Float']>;
  energy_effective_demand?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "energy_consumer" */
export type energy_consumer_stddev_order_by = {
  action_id?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  efficiency?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type energy_consumer_stddev_pop_fields = {
  action_id?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['Float']>;
  energy_effective_demand?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "energy_consumer" */
export type energy_consumer_stddev_pop_order_by = {
  action_id?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  efficiency?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type energy_consumer_stddev_samp_fields = {
  action_id?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['Float']>;
  energy_effective_demand?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "energy_consumer" */
export type energy_consumer_stddev_samp_order_by = {
  action_id?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  efficiency?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "energy_consumer" */
export type energy_consumer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: energy_consumer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type energy_consumer_stream_cursor_value_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  building_model_id?: InputMaybe<Scalars['Int']>;
  construction_year?: InputMaybe<Scalars['Int']>;
  cost_per_m2?: InputMaybe<Scalars['Float']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  efficiency?: InputMaybe<Scalars['Float']>;
  energy_consumer_technology_type_id?: InputMaybe<energy_consumer_technology_type_enum>;
  energy_consumer_type_id?: InputMaybe<energy_consumer_type_enum>;
  energy_effective_consumption?: InputMaybe<Scalars['numeric']>;
  energy_effective_demand?: InputMaybe<Scalars['numeric']>;
  energy_final?: InputMaybe<Scalars['Float']>;
  energy_final_consumption?: InputMaybe<Scalars['numeric']>;
  energy_final_demand?: InputMaybe<Scalars['numeric']>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type energy_consumer_sum_fields = {
  action_id?: Maybe<Scalars['Int']>;
  building_model_id?: Maybe<Scalars['Int']>;
  construction_year?: Maybe<Scalars['Int']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['numeric']>;
  energy_effective_demand?: Maybe<Scalars['numeric']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['numeric']>;
  energy_final_demand?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "energy_consumer" */
export type energy_consumer_sum_order_by = {
  action_id?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  efficiency?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** columns and relationships of "energy_consumer_technology_type" */
export type energy_consumer_technology_type = {
  id: Scalars['String'];
};

/** aggregated selection of "energy_consumer_technology_type" */
export type energy_consumer_technology_type_aggregate = {
  aggregate?: Maybe<energy_consumer_technology_type_aggregate_fields>;
  nodes: Array<energy_consumer_technology_type>;
};

/** aggregate fields of "energy_consumer_technology_type" */
export type energy_consumer_technology_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<energy_consumer_technology_type_max_fields>;
  min?: Maybe<energy_consumer_technology_type_min_fields>;
};

/** aggregate fields of "energy_consumer_technology_type" */
export type energy_consumer_technology_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<energy_consumer_technology_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "energy_consumer_technology_type". All fields are combined with a logical 'AND'. */
export type energy_consumer_technology_type_bool_exp = {
  _and?: InputMaybe<Array<energy_consumer_technology_type_bool_exp>>;
  _not?: InputMaybe<energy_consumer_technology_type_bool_exp>;
  _or?: InputMaybe<Array<energy_consumer_technology_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { energy_consumer_technology_type_constraint };

export { energy_consumer_technology_type_enum };

/** Boolean expression to compare columns of type "energy_consumer_technology_type_enum". All fields are combined with logical 'AND'. */
export type energy_consumer_technology_type_enum_comparison_exp = {
  _eq?: InputMaybe<energy_consumer_technology_type_enum>;
  _in?: InputMaybe<Array<energy_consumer_technology_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<energy_consumer_technology_type_enum>;
  _nin?: InputMaybe<Array<energy_consumer_technology_type_enum>>;
};

/** input type for inserting data into table "energy_consumer_technology_type" */
export type energy_consumer_technology_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type energy_consumer_technology_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type energy_consumer_technology_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "energy_consumer_technology_type" */
export type energy_consumer_technology_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<energy_consumer_technology_type>;
};

/** on_conflict condition type for table "energy_consumer_technology_type" */
export type energy_consumer_technology_type_on_conflict = {
  constraint: energy_consumer_technology_type_constraint;
  update_columns?: Array<energy_consumer_technology_type_update_column>;
  where?: InputMaybe<energy_consumer_technology_type_bool_exp>;
};

/** Ordering options when selecting data from "energy_consumer_technology_type". */
export type energy_consumer_technology_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: energy_consumer_technology_type */
export type energy_consumer_technology_type_pk_columns_input = {
  id: Scalars['String'];
};

export { energy_consumer_technology_type_select_column };

/** input type for updating data in table "energy_consumer_technology_type" */
export type energy_consumer_technology_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "energy_consumer_technology_type" */
export type energy_consumer_technology_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: energy_consumer_technology_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type energy_consumer_technology_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { energy_consumer_technology_type_update_column };

export type energy_consumer_technology_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<energy_consumer_technology_type_set_input>;
  /** filter the rows which have to be updated */
  where: energy_consumer_technology_type_bool_exp;
};

/** columns and relationships of "energy_consumer_type" */
export type energy_consumer_type = {
  id: Scalars['String'];
};

/** aggregated selection of "energy_consumer_type" */
export type energy_consumer_type_aggregate = {
  aggregate?: Maybe<energy_consumer_type_aggregate_fields>;
  nodes: Array<energy_consumer_type>;
};

/** aggregate fields of "energy_consumer_type" */
export type energy_consumer_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<energy_consumer_type_max_fields>;
  min?: Maybe<energy_consumer_type_min_fields>;
};

/** aggregate fields of "energy_consumer_type" */
export type energy_consumer_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<energy_consumer_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "energy_consumer_type". All fields are combined with a logical 'AND'. */
export type energy_consumer_type_bool_exp = {
  _and?: InputMaybe<Array<energy_consumer_type_bool_exp>>;
  _not?: InputMaybe<energy_consumer_type_bool_exp>;
  _or?: InputMaybe<Array<energy_consumer_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { energy_consumer_type_constraint };

export { energy_consumer_type_enum };

/** Boolean expression to compare columns of type "energy_consumer_type_enum". All fields are combined with logical 'AND'. */
export type energy_consumer_type_enum_comparison_exp = {
  _eq?: InputMaybe<energy_consumer_type_enum>;
  _in?: InputMaybe<Array<energy_consumer_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<energy_consumer_type_enum>;
  _nin?: InputMaybe<Array<energy_consumer_type_enum>>;
};

/** input type for inserting data into table "energy_consumer_type" */
export type energy_consumer_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type energy_consumer_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type energy_consumer_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "energy_consumer_type" */
export type energy_consumer_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<energy_consumer_type>;
};

/** on_conflict condition type for table "energy_consumer_type" */
export type energy_consumer_type_on_conflict = {
  constraint: energy_consumer_type_constraint;
  update_columns?: Array<energy_consumer_type_update_column>;
  where?: InputMaybe<energy_consumer_type_bool_exp>;
};

/** Ordering options when selecting data from "energy_consumer_type". */
export type energy_consumer_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: energy_consumer_type */
export type energy_consumer_type_pk_columns_input = {
  id: Scalars['String'];
};

export { energy_consumer_type_select_column };

/** input type for updating data in table "energy_consumer_type" */
export type energy_consumer_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "energy_consumer_type" */
export type energy_consumer_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: energy_consumer_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type energy_consumer_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { energy_consumer_type_update_column };

export type energy_consumer_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<energy_consumer_type_set_input>;
  /** filter the rows which have to be updated */
  where: energy_consumer_type_bool_exp;
};

export { energy_consumer_update_column };

export type energy_consumer_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<energy_consumer_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<energy_consumer_set_input>;
  /** filter the rows which have to be updated */
  where: energy_consumer_bool_exp;
};

/** aggregate var_pop on columns */
export type energy_consumer_var_pop_fields = {
  action_id?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['Float']>;
  energy_effective_demand?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "energy_consumer" */
export type energy_consumer_var_pop_order_by = {
  action_id?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  efficiency?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type energy_consumer_var_samp_fields = {
  action_id?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['Float']>;
  energy_effective_demand?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "energy_consumer" */
export type energy_consumer_var_samp_order_by = {
  action_id?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  efficiency?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type energy_consumer_variance_fields = {
  action_id?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  energy_effective_consumption?: Maybe<Scalars['Float']>;
  energy_effective_demand?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "energy_consumer" */
export type energy_consumer_variance_order_by = {
  action_id?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  efficiency?: InputMaybe<order_by>;
  energy_effective_consumption?: InputMaybe<order_by>;
  energy_effective_demand?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

export { energy_consumption_type_enum };

/** columns and relationships of "energy_distribution" */
export type energy_distribution = {
  balancing_with_components: Scalars['Boolean'];
  /** An object relationship */
  building_model?: Maybe<building_model>;
  building_model_id?: Maybe<Scalars['Int']>;
  construction_year?: Maybe<Scalars['Int']>;
  /** An object relationship */
  energy_distribution_type: energy_distribution_type;
  energy_distribution_type_id: energy_distribution_type_enum;
  /** An array relationship */
  energy_system_consumer_routes: Array<energy_system_consumer_route>;
  /** An aggregate relationship */
  energy_system_consumer_routes_aggregate: energy_system_consumer_route_aggregate;
  heat_distribution_loss: Scalars['Float'];
  heat_transfer_loss: Scalars['Float'];
  id: Scalars['Int'];
  last_balancing_set_to_never: Scalars['Boolean'];
  last_hydraulic_balancing?: Maybe<Scalars['timestamptz']>;
  recoverable_heat_loss?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "energy_distribution" */
export type energy_distributionenergy_system_consumer_routesArgs = {
  distinct_on?: InputMaybe<Array<energy_system_consumer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_consumer_route_order_by>>;
  where?: InputMaybe<energy_system_consumer_route_bool_exp>;
};

/** columns and relationships of "energy_distribution" */
export type energy_distributionenergy_system_consumer_routes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_system_consumer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_consumer_route_order_by>>;
  where?: InputMaybe<energy_system_consumer_route_bool_exp>;
};

/** aggregated selection of "energy_distribution" */
export type energy_distribution_aggregate = {
  aggregate?: Maybe<energy_distribution_aggregate_fields>;
  nodes: Array<energy_distribution>;
};

export type energy_distribution_aggregate_bool_exp = {
  bool_and?: InputMaybe<energy_distribution_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<energy_distribution_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<energy_distribution_aggregate_bool_exp_count>;
};

export type energy_distribution_aggregate_bool_exp_bool_and = {
  arguments: energy_distribution_select_column_energy_distribution_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<energy_distribution_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type energy_distribution_aggregate_bool_exp_bool_or = {
  arguments: energy_distribution_select_column_energy_distribution_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<energy_distribution_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type energy_distribution_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<energy_distribution_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<energy_distribution_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "energy_distribution" */
export type energy_distribution_aggregate_fields = {
  avg?: Maybe<energy_distribution_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<energy_distribution_max_fields>;
  min?: Maybe<energy_distribution_min_fields>;
  stddev?: Maybe<energy_distribution_stddev_fields>;
  stddev_pop?: Maybe<energy_distribution_stddev_pop_fields>;
  stddev_samp?: Maybe<energy_distribution_stddev_samp_fields>;
  sum?: Maybe<energy_distribution_sum_fields>;
  var_pop?: Maybe<energy_distribution_var_pop_fields>;
  var_samp?: Maybe<energy_distribution_var_samp_fields>;
  variance?: Maybe<energy_distribution_variance_fields>;
};

/** aggregate fields of "energy_distribution" */
export type energy_distribution_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<energy_distribution_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "energy_distribution" */
export type energy_distribution_aggregate_order_by = {
  avg?: InputMaybe<energy_distribution_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<energy_distribution_max_order_by>;
  min?: InputMaybe<energy_distribution_min_order_by>;
  stddev?: InputMaybe<energy_distribution_stddev_order_by>;
  stddev_pop?: InputMaybe<energy_distribution_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<energy_distribution_stddev_samp_order_by>;
  sum?: InputMaybe<energy_distribution_sum_order_by>;
  var_pop?: InputMaybe<energy_distribution_var_pop_order_by>;
  var_samp?: InputMaybe<energy_distribution_var_samp_order_by>;
  variance?: InputMaybe<energy_distribution_variance_order_by>;
};

/** input type for inserting array relation for remote table "energy_distribution" */
export type energy_distribution_arr_rel_insert_input = {
  data: Array<energy_distribution_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<energy_distribution_on_conflict>;
};

/** aggregate avg on columns */
export type energy_distribution_avg_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  heat_distribution_loss?: Maybe<Scalars['Float']>;
  heat_transfer_loss?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recoverable_heat_loss?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "energy_distribution" */
export type energy_distribution_avg_order_by = {
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  heat_distribution_loss?: InputMaybe<order_by>;
  heat_transfer_loss?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  recoverable_heat_loss?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "energy_distribution". All fields are combined with a logical 'AND'. */
export type energy_distribution_bool_exp = {
  _and?: InputMaybe<Array<energy_distribution_bool_exp>>;
  _not?: InputMaybe<energy_distribution_bool_exp>;
  _or?: InputMaybe<Array<energy_distribution_bool_exp>>;
  balancing_with_components?: InputMaybe<Boolean_comparison_exp>;
  building_model?: InputMaybe<building_model_bool_exp>;
  building_model_id?: InputMaybe<Int_comparison_exp>;
  construction_year?: InputMaybe<Int_comparison_exp>;
  energy_distribution_type?: InputMaybe<energy_distribution_type_bool_exp>;
  energy_distribution_type_id?: InputMaybe<energy_distribution_type_enum_comparison_exp>;
  energy_system_consumer_routes?: InputMaybe<energy_system_consumer_route_bool_exp>;
  energy_system_consumer_routes_aggregate?: InputMaybe<energy_system_consumer_route_aggregate_bool_exp>;
  heat_distribution_loss?: InputMaybe<Float_comparison_exp>;
  heat_transfer_loss?: InputMaybe<Float_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  last_balancing_set_to_never?: InputMaybe<Boolean_comparison_exp>;
  last_hydraulic_balancing?: InputMaybe<timestamptz_comparison_exp>;
  recoverable_heat_loss?: InputMaybe<Float_comparison_exp>;
};

export { energy_distribution_constraint };

/** input type for incrementing numeric columns in table "energy_distribution" */
export type energy_distribution_inc_input = {
  building_model_id?: InputMaybe<Scalars['Int']>;
  construction_year?: InputMaybe<Scalars['Int']>;
  heat_distribution_loss?: InputMaybe<Scalars['Float']>;
  heat_transfer_loss?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  recoverable_heat_loss?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "energy_distribution" */
export type energy_distribution_insert_input = {
  balancing_with_components?: InputMaybe<Scalars['Boolean']>;
  building_model?: InputMaybe<building_model_obj_rel_insert_input>;
  building_model_id?: InputMaybe<Scalars['Int']>;
  construction_year?: InputMaybe<Scalars['Int']>;
  energy_distribution_type?: InputMaybe<energy_distribution_type_obj_rel_insert_input>;
  energy_distribution_type_id?: InputMaybe<energy_distribution_type_enum>;
  energy_system_consumer_routes?: InputMaybe<energy_system_consumer_route_arr_rel_insert_input>;
  heat_distribution_loss?: InputMaybe<Scalars['Float']>;
  heat_transfer_loss?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  last_balancing_set_to_never?: InputMaybe<Scalars['Boolean']>;
  last_hydraulic_balancing?: InputMaybe<Scalars['timestamptz']>;
  recoverable_heat_loss?: InputMaybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type energy_distribution_max_fields = {
  building_model_id?: Maybe<Scalars['Int']>;
  construction_year?: Maybe<Scalars['Int']>;
  heat_distribution_loss?: Maybe<Scalars['Float']>;
  heat_transfer_loss?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  last_hydraulic_balancing?: Maybe<Scalars['timestamptz']>;
  recoverable_heat_loss?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "energy_distribution" */
export type energy_distribution_max_order_by = {
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  heat_distribution_loss?: InputMaybe<order_by>;
  heat_transfer_loss?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  last_hydraulic_balancing?: InputMaybe<order_by>;
  recoverable_heat_loss?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type energy_distribution_min_fields = {
  building_model_id?: Maybe<Scalars['Int']>;
  construction_year?: Maybe<Scalars['Int']>;
  heat_distribution_loss?: Maybe<Scalars['Float']>;
  heat_transfer_loss?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  last_hydraulic_balancing?: Maybe<Scalars['timestamptz']>;
  recoverable_heat_loss?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "energy_distribution" */
export type energy_distribution_min_order_by = {
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  heat_distribution_loss?: InputMaybe<order_by>;
  heat_transfer_loss?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  last_hydraulic_balancing?: InputMaybe<order_by>;
  recoverable_heat_loss?: InputMaybe<order_by>;
};

/** response of any mutation on the table "energy_distribution" */
export type energy_distribution_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<energy_distribution>;
};

/** input type for inserting object relation for remote table "energy_distribution" */
export type energy_distribution_obj_rel_insert_input = {
  data: energy_distribution_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<energy_distribution_on_conflict>;
};

/** on_conflict condition type for table "energy_distribution" */
export type energy_distribution_on_conflict = {
  constraint: energy_distribution_constraint;
  update_columns?: Array<energy_distribution_update_column>;
  where?: InputMaybe<energy_distribution_bool_exp>;
};

/** Ordering options when selecting data from "energy_distribution". */
export type energy_distribution_order_by = {
  balancing_with_components?: InputMaybe<order_by>;
  building_model?: InputMaybe<building_model_order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  energy_distribution_type?: InputMaybe<energy_distribution_type_order_by>;
  energy_distribution_type_id?: InputMaybe<order_by>;
  energy_system_consumer_routes_aggregate?: InputMaybe<energy_system_consumer_route_aggregate_order_by>;
  heat_distribution_loss?: InputMaybe<order_by>;
  heat_transfer_loss?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  last_balancing_set_to_never?: InputMaybe<order_by>;
  last_hydraulic_balancing?: InputMaybe<order_by>;
  recoverable_heat_loss?: InputMaybe<order_by>;
};

/** primary key columns input for table: energy_distribution */
export type energy_distribution_pk_columns_input = {
  id: Scalars['Int'];
};

export { energy_distribution_select_column };

export { energy_distribution_select_column_energy_distribution_aggregate_bool_exp_bool_and_arguments_columns };

export { energy_distribution_select_column_energy_distribution_aggregate_bool_exp_bool_or_arguments_columns };

/** input type for updating data in table "energy_distribution" */
export type energy_distribution_set_input = {
  balancing_with_components?: InputMaybe<Scalars['Boolean']>;
  building_model_id?: InputMaybe<Scalars['Int']>;
  construction_year?: InputMaybe<Scalars['Int']>;
  energy_distribution_type_id?: InputMaybe<energy_distribution_type_enum>;
  heat_distribution_loss?: InputMaybe<Scalars['Float']>;
  heat_transfer_loss?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  last_balancing_set_to_never?: InputMaybe<Scalars['Boolean']>;
  last_hydraulic_balancing?: InputMaybe<Scalars['timestamptz']>;
  recoverable_heat_loss?: InputMaybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type energy_distribution_stddev_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  heat_distribution_loss?: Maybe<Scalars['Float']>;
  heat_transfer_loss?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recoverable_heat_loss?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "energy_distribution" */
export type energy_distribution_stddev_order_by = {
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  heat_distribution_loss?: InputMaybe<order_by>;
  heat_transfer_loss?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  recoverable_heat_loss?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type energy_distribution_stddev_pop_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  heat_distribution_loss?: Maybe<Scalars['Float']>;
  heat_transfer_loss?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recoverable_heat_loss?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "energy_distribution" */
export type energy_distribution_stddev_pop_order_by = {
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  heat_distribution_loss?: InputMaybe<order_by>;
  heat_transfer_loss?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  recoverable_heat_loss?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type energy_distribution_stddev_samp_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  heat_distribution_loss?: Maybe<Scalars['Float']>;
  heat_transfer_loss?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recoverable_heat_loss?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "energy_distribution" */
export type energy_distribution_stddev_samp_order_by = {
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  heat_distribution_loss?: InputMaybe<order_by>;
  heat_transfer_loss?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  recoverable_heat_loss?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "energy_distribution" */
export type energy_distribution_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: energy_distribution_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type energy_distribution_stream_cursor_value_input = {
  balancing_with_components?: InputMaybe<Scalars['Boolean']>;
  building_model_id?: InputMaybe<Scalars['Int']>;
  construction_year?: InputMaybe<Scalars['Int']>;
  energy_distribution_type_id?: InputMaybe<energy_distribution_type_enum>;
  heat_distribution_loss?: InputMaybe<Scalars['Float']>;
  heat_transfer_loss?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  last_balancing_set_to_never?: InputMaybe<Scalars['Boolean']>;
  last_hydraulic_balancing?: InputMaybe<Scalars['timestamptz']>;
  recoverable_heat_loss?: InputMaybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type energy_distribution_sum_fields = {
  building_model_id?: Maybe<Scalars['Int']>;
  construction_year?: Maybe<Scalars['Int']>;
  heat_distribution_loss?: Maybe<Scalars['Float']>;
  heat_transfer_loss?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  recoverable_heat_loss?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "energy_distribution" */
export type energy_distribution_sum_order_by = {
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  heat_distribution_loss?: InputMaybe<order_by>;
  heat_transfer_loss?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  recoverable_heat_loss?: InputMaybe<order_by>;
};

/** columns and relationships of "energy_distribution_type" */
export type energy_distribution_type = {
  id: Scalars['String'];
};

/** aggregated selection of "energy_distribution_type" */
export type energy_distribution_type_aggregate = {
  aggregate?: Maybe<energy_distribution_type_aggregate_fields>;
  nodes: Array<energy_distribution_type>;
};

/** aggregate fields of "energy_distribution_type" */
export type energy_distribution_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<energy_distribution_type_max_fields>;
  min?: Maybe<energy_distribution_type_min_fields>;
};

/** aggregate fields of "energy_distribution_type" */
export type energy_distribution_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<energy_distribution_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "energy_distribution_type". All fields are combined with a logical 'AND'. */
export type energy_distribution_type_bool_exp = {
  _and?: InputMaybe<Array<energy_distribution_type_bool_exp>>;
  _not?: InputMaybe<energy_distribution_type_bool_exp>;
  _or?: InputMaybe<Array<energy_distribution_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { energy_distribution_type_constraint };

export { energy_distribution_type_enum };

/** Boolean expression to compare columns of type "energy_distribution_type_enum". All fields are combined with logical 'AND'. */
export type energy_distribution_type_enum_comparison_exp = {
  _eq?: InputMaybe<energy_distribution_type_enum>;
  _in?: InputMaybe<Array<energy_distribution_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<energy_distribution_type_enum>;
  _nin?: InputMaybe<Array<energy_distribution_type_enum>>;
};

/** input type for inserting data into table "energy_distribution_type" */
export type energy_distribution_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type energy_distribution_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type energy_distribution_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "energy_distribution_type" */
export type energy_distribution_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<energy_distribution_type>;
};

/** input type for inserting object relation for remote table "energy_distribution_type" */
export type energy_distribution_type_obj_rel_insert_input = {
  data: energy_distribution_type_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<energy_distribution_type_on_conflict>;
};

/** on_conflict condition type for table "energy_distribution_type" */
export type energy_distribution_type_on_conflict = {
  constraint: energy_distribution_type_constraint;
  update_columns?: Array<energy_distribution_type_update_column>;
  where?: InputMaybe<energy_distribution_type_bool_exp>;
};

/** Ordering options when selecting data from "energy_distribution_type". */
export type energy_distribution_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: energy_distribution_type */
export type energy_distribution_type_pk_columns_input = {
  id: Scalars['String'];
};

export { energy_distribution_type_select_column };

/** input type for updating data in table "energy_distribution_type" */
export type energy_distribution_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "energy_distribution_type" */
export type energy_distribution_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: energy_distribution_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type energy_distribution_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { energy_distribution_type_update_column };

export type energy_distribution_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<energy_distribution_type_set_input>;
  /** filter the rows which have to be updated */
  where: energy_distribution_type_bool_exp;
};

export { energy_distribution_update_column };

export type energy_distribution_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<energy_distribution_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<energy_distribution_set_input>;
  /** filter the rows which have to be updated */
  where: energy_distribution_bool_exp;
};

/** aggregate var_pop on columns */
export type energy_distribution_var_pop_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  heat_distribution_loss?: Maybe<Scalars['Float']>;
  heat_transfer_loss?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recoverable_heat_loss?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "energy_distribution" */
export type energy_distribution_var_pop_order_by = {
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  heat_distribution_loss?: InputMaybe<order_by>;
  heat_transfer_loss?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  recoverable_heat_loss?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type energy_distribution_var_samp_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  heat_distribution_loss?: Maybe<Scalars['Float']>;
  heat_transfer_loss?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recoverable_heat_loss?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "energy_distribution" */
export type energy_distribution_var_samp_order_by = {
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  heat_distribution_loss?: InputMaybe<order_by>;
  heat_transfer_loss?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  recoverable_heat_loss?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type energy_distribution_variance_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  heat_distribution_loss?: Maybe<Scalars['Float']>;
  heat_transfer_loss?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recoverable_heat_loss?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "energy_distribution" */
export type energy_distribution_variance_order_by = {
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  heat_distribution_loss?: InputMaybe<order_by>;
  heat_transfer_loss?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  recoverable_heat_loss?: InputMaybe<order_by>;
};

/** columns and relationships of "energy_path" */
export type energy_path = {
  /** An object relationship */
  building_model?: Maybe<building_model>;
  building_model_id?: Maybe<Scalars['Int']>;
  co2_costs: Scalars['Float'];
  co2_costs_total: Scalars['Float'];
  co2_emissions: Scalars['Float'];
  co2_emissions_total: Scalars['Float'];
  /** An object relationship */
  efficiency_class?: Maybe<efficiency_class>;
  efficiency_class_id?: Maybe<efficiency_class_enum>;
  emission_factor?: Maybe<Scalars['Float']>;
  energy_consumption: Scalars['Float'];
  energy_consumption_total: Scalars['Float'];
  energy_costs: Scalars['Float'];
  energy_costs_total: Scalars['Float'];
  /** An object relationship */
  energy_system_consumer_route?: Maybe<energy_system_consumer_route>;
  energy_system_consumer_route_id?: Maybe<Scalars['Int']>;
  eu_taxonomy_compliance_id?: Maybe<eu_taxonomy_compliance_enum>;
  id: Scalars['Int'];
  scope_1_co2_emissions: Scalars['Float'];
  scope_1_co2_emissions_total: Scalars['Float'];
  scope_2_co2_emissions: Scalars['Float'];
  scope_2_co2_emissions_total: Scalars['Float'];
  scope_3_co2_emissions: Scalars['Float'];
  scope_3_co2_emissions_total: Scalars['Float'];
  /** An object relationship */
  tax_bracket?: Maybe<tax_bracket>;
  tax_bracket_id?: Maybe<tax_bracket_enum>;
  year: Scalars['Int'];
};

/** aggregated selection of "energy_path" */
export type energy_path_aggregate = {
  aggregate?: Maybe<energy_path_aggregate_fields>;
  nodes: Array<energy_path>;
};

export type energy_path_aggregate_bool_exp = {
  count?: InputMaybe<energy_path_aggregate_bool_exp_count>;
};

export type energy_path_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<energy_path_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<energy_path_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "energy_path" */
export type energy_path_aggregate_fields = {
  avg?: Maybe<energy_path_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<energy_path_max_fields>;
  min?: Maybe<energy_path_min_fields>;
  stddev?: Maybe<energy_path_stddev_fields>;
  stddev_pop?: Maybe<energy_path_stddev_pop_fields>;
  stddev_samp?: Maybe<energy_path_stddev_samp_fields>;
  sum?: Maybe<energy_path_sum_fields>;
  var_pop?: Maybe<energy_path_var_pop_fields>;
  var_samp?: Maybe<energy_path_var_samp_fields>;
  variance?: Maybe<energy_path_variance_fields>;
};

/** aggregate fields of "energy_path" */
export type energy_path_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<energy_path_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "energy_path" */
export type energy_path_aggregate_order_by = {
  avg?: InputMaybe<energy_path_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<energy_path_max_order_by>;
  min?: InputMaybe<energy_path_min_order_by>;
  stddev?: InputMaybe<energy_path_stddev_order_by>;
  stddev_pop?: InputMaybe<energy_path_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<energy_path_stddev_samp_order_by>;
  sum?: InputMaybe<energy_path_sum_order_by>;
  var_pop?: InputMaybe<energy_path_var_pop_order_by>;
  var_samp?: InputMaybe<energy_path_var_samp_order_by>;
  variance?: InputMaybe<energy_path_variance_order_by>;
};

/** input type for inserting array relation for remote table "energy_path" */
export type energy_path_arr_rel_insert_input = {
  data: Array<energy_path_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<energy_path_on_conflict>;
};

/** aggregate avg on columns */
export type energy_path_avg_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  co2_costs?: Maybe<Scalars['Float']>;
  co2_costs_total?: Maybe<Scalars['Float']>;
  co2_emissions?: Maybe<Scalars['Float']>;
  co2_emissions_total?: Maybe<Scalars['Float']>;
  emission_factor?: Maybe<Scalars['Float']>;
  energy_consumption?: Maybe<Scalars['Float']>;
  energy_consumption_total?: Maybe<Scalars['Float']>;
  energy_costs?: Maybe<Scalars['Float']>;
  energy_costs_total?: Maybe<Scalars['Float']>;
  energy_system_consumer_route_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  scope_1_co2_emissions?: Maybe<Scalars['Float']>;
  scope_1_co2_emissions_total?: Maybe<Scalars['Float']>;
  scope_2_co2_emissions?: Maybe<Scalars['Float']>;
  scope_2_co2_emissions_total?: Maybe<Scalars['Float']>;
  scope_3_co2_emissions?: Maybe<Scalars['Float']>;
  scope_3_co2_emissions_total?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "energy_path" */
export type energy_path_avg_order_by = {
  building_model_id?: InputMaybe<order_by>;
  co2_costs?: InputMaybe<order_by>;
  co2_costs_total?: InputMaybe<order_by>;
  co2_emissions?: InputMaybe<order_by>;
  co2_emissions_total?: InputMaybe<order_by>;
  emission_factor?: InputMaybe<order_by>;
  energy_consumption?: InputMaybe<order_by>;
  energy_consumption_total?: InputMaybe<order_by>;
  energy_costs?: InputMaybe<order_by>;
  energy_costs_total?: InputMaybe<order_by>;
  energy_system_consumer_route_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  scope_1_co2_emissions?: InputMaybe<order_by>;
  scope_1_co2_emissions_total?: InputMaybe<order_by>;
  scope_2_co2_emissions?: InputMaybe<order_by>;
  scope_2_co2_emissions_total?: InputMaybe<order_by>;
  scope_3_co2_emissions?: InputMaybe<order_by>;
  scope_3_co2_emissions_total?: InputMaybe<order_by>;
  year?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "energy_path". All fields are combined with a logical 'AND'. */
export type energy_path_bool_exp = {
  _and?: InputMaybe<Array<energy_path_bool_exp>>;
  _not?: InputMaybe<energy_path_bool_exp>;
  _or?: InputMaybe<Array<energy_path_bool_exp>>;
  building_model?: InputMaybe<building_model_bool_exp>;
  building_model_id?: InputMaybe<Int_comparison_exp>;
  co2_costs?: InputMaybe<Float_comparison_exp>;
  co2_costs_total?: InputMaybe<Float_comparison_exp>;
  co2_emissions?: InputMaybe<Float_comparison_exp>;
  co2_emissions_total?: InputMaybe<Float_comparison_exp>;
  efficiency_class?: InputMaybe<efficiency_class_bool_exp>;
  efficiency_class_id?: InputMaybe<efficiency_class_enum_comparison_exp>;
  emission_factor?: InputMaybe<Float_comparison_exp>;
  energy_consumption?: InputMaybe<Float_comparison_exp>;
  energy_consumption_total?: InputMaybe<Float_comparison_exp>;
  energy_costs?: InputMaybe<Float_comparison_exp>;
  energy_costs_total?: InputMaybe<Float_comparison_exp>;
  energy_system_consumer_route?: InputMaybe<energy_system_consumer_route_bool_exp>;
  energy_system_consumer_route_id?: InputMaybe<Int_comparison_exp>;
  eu_taxonomy_compliance_id?: InputMaybe<eu_taxonomy_compliance_enum_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  scope_1_co2_emissions?: InputMaybe<Float_comparison_exp>;
  scope_1_co2_emissions_total?: InputMaybe<Float_comparison_exp>;
  scope_2_co2_emissions?: InputMaybe<Float_comparison_exp>;
  scope_2_co2_emissions_total?: InputMaybe<Float_comparison_exp>;
  scope_3_co2_emissions?: InputMaybe<Float_comparison_exp>;
  scope_3_co2_emissions_total?: InputMaybe<Float_comparison_exp>;
  tax_bracket?: InputMaybe<tax_bracket_bool_exp>;
  tax_bracket_id?: InputMaybe<tax_bracket_enum_comparison_exp>;
  year?: InputMaybe<Int_comparison_exp>;
};

export { energy_path_constraint };

/** input type for incrementing numeric columns in table "energy_path" */
export type energy_path_inc_input = {
  building_model_id?: InputMaybe<Scalars['Int']>;
  co2_costs?: InputMaybe<Scalars['Float']>;
  co2_costs_total?: InputMaybe<Scalars['Float']>;
  co2_emissions?: InputMaybe<Scalars['Float']>;
  co2_emissions_total?: InputMaybe<Scalars['Float']>;
  emission_factor?: InputMaybe<Scalars['Float']>;
  energy_consumption?: InputMaybe<Scalars['Float']>;
  energy_consumption_total?: InputMaybe<Scalars['Float']>;
  energy_costs?: InputMaybe<Scalars['Float']>;
  energy_costs_total?: InputMaybe<Scalars['Float']>;
  energy_system_consumer_route_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  scope_1_co2_emissions?: InputMaybe<Scalars['Float']>;
  scope_1_co2_emissions_total?: InputMaybe<Scalars['Float']>;
  scope_2_co2_emissions?: InputMaybe<Scalars['Float']>;
  scope_2_co2_emissions_total?: InputMaybe<Scalars['Float']>;
  scope_3_co2_emissions?: InputMaybe<Scalars['Float']>;
  scope_3_co2_emissions_total?: InputMaybe<Scalars['Float']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "energy_path" */
export type energy_path_insert_input = {
  building_model?: InputMaybe<building_model_obj_rel_insert_input>;
  building_model_id?: InputMaybe<Scalars['Int']>;
  co2_costs?: InputMaybe<Scalars['Float']>;
  co2_costs_total?: InputMaybe<Scalars['Float']>;
  co2_emissions?: InputMaybe<Scalars['Float']>;
  co2_emissions_total?: InputMaybe<Scalars['Float']>;
  efficiency_class?: InputMaybe<efficiency_class_obj_rel_insert_input>;
  efficiency_class_id?: InputMaybe<efficiency_class_enum>;
  emission_factor?: InputMaybe<Scalars['Float']>;
  energy_consumption?: InputMaybe<Scalars['Float']>;
  energy_consumption_total?: InputMaybe<Scalars['Float']>;
  energy_costs?: InputMaybe<Scalars['Float']>;
  energy_costs_total?: InputMaybe<Scalars['Float']>;
  energy_system_consumer_route?: InputMaybe<energy_system_consumer_route_obj_rel_insert_input>;
  energy_system_consumer_route_id?: InputMaybe<Scalars['Int']>;
  eu_taxonomy_compliance_id?: InputMaybe<eu_taxonomy_compliance_enum>;
  id?: InputMaybe<Scalars['Int']>;
  scope_1_co2_emissions?: InputMaybe<Scalars['Float']>;
  scope_1_co2_emissions_total?: InputMaybe<Scalars['Float']>;
  scope_2_co2_emissions?: InputMaybe<Scalars['Float']>;
  scope_2_co2_emissions_total?: InputMaybe<Scalars['Float']>;
  scope_3_co2_emissions?: InputMaybe<Scalars['Float']>;
  scope_3_co2_emissions_total?: InputMaybe<Scalars['Float']>;
  tax_bracket?: InputMaybe<tax_bracket_obj_rel_insert_input>;
  tax_bracket_id?: InputMaybe<tax_bracket_enum>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type energy_path_max_fields = {
  building_model_id?: Maybe<Scalars['Int']>;
  co2_costs?: Maybe<Scalars['Float']>;
  co2_costs_total?: Maybe<Scalars['Float']>;
  co2_emissions?: Maybe<Scalars['Float']>;
  co2_emissions_total?: Maybe<Scalars['Float']>;
  emission_factor?: Maybe<Scalars['Float']>;
  energy_consumption?: Maybe<Scalars['Float']>;
  energy_consumption_total?: Maybe<Scalars['Float']>;
  energy_costs?: Maybe<Scalars['Float']>;
  energy_costs_total?: Maybe<Scalars['Float']>;
  energy_system_consumer_route_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  scope_1_co2_emissions?: Maybe<Scalars['Float']>;
  scope_1_co2_emissions_total?: Maybe<Scalars['Float']>;
  scope_2_co2_emissions?: Maybe<Scalars['Float']>;
  scope_2_co2_emissions_total?: Maybe<Scalars['Float']>;
  scope_3_co2_emissions?: Maybe<Scalars['Float']>;
  scope_3_co2_emissions_total?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "energy_path" */
export type energy_path_max_order_by = {
  building_model_id?: InputMaybe<order_by>;
  co2_costs?: InputMaybe<order_by>;
  co2_costs_total?: InputMaybe<order_by>;
  co2_emissions?: InputMaybe<order_by>;
  co2_emissions_total?: InputMaybe<order_by>;
  emission_factor?: InputMaybe<order_by>;
  energy_consumption?: InputMaybe<order_by>;
  energy_consumption_total?: InputMaybe<order_by>;
  energy_costs?: InputMaybe<order_by>;
  energy_costs_total?: InputMaybe<order_by>;
  energy_system_consumer_route_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  scope_1_co2_emissions?: InputMaybe<order_by>;
  scope_1_co2_emissions_total?: InputMaybe<order_by>;
  scope_2_co2_emissions?: InputMaybe<order_by>;
  scope_2_co2_emissions_total?: InputMaybe<order_by>;
  scope_3_co2_emissions?: InputMaybe<order_by>;
  scope_3_co2_emissions_total?: InputMaybe<order_by>;
  year?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type energy_path_min_fields = {
  building_model_id?: Maybe<Scalars['Int']>;
  co2_costs?: Maybe<Scalars['Float']>;
  co2_costs_total?: Maybe<Scalars['Float']>;
  co2_emissions?: Maybe<Scalars['Float']>;
  co2_emissions_total?: Maybe<Scalars['Float']>;
  emission_factor?: Maybe<Scalars['Float']>;
  energy_consumption?: Maybe<Scalars['Float']>;
  energy_consumption_total?: Maybe<Scalars['Float']>;
  energy_costs?: Maybe<Scalars['Float']>;
  energy_costs_total?: Maybe<Scalars['Float']>;
  energy_system_consumer_route_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  scope_1_co2_emissions?: Maybe<Scalars['Float']>;
  scope_1_co2_emissions_total?: Maybe<Scalars['Float']>;
  scope_2_co2_emissions?: Maybe<Scalars['Float']>;
  scope_2_co2_emissions_total?: Maybe<Scalars['Float']>;
  scope_3_co2_emissions?: Maybe<Scalars['Float']>;
  scope_3_co2_emissions_total?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "energy_path" */
export type energy_path_min_order_by = {
  building_model_id?: InputMaybe<order_by>;
  co2_costs?: InputMaybe<order_by>;
  co2_costs_total?: InputMaybe<order_by>;
  co2_emissions?: InputMaybe<order_by>;
  co2_emissions_total?: InputMaybe<order_by>;
  emission_factor?: InputMaybe<order_by>;
  energy_consumption?: InputMaybe<order_by>;
  energy_consumption_total?: InputMaybe<order_by>;
  energy_costs?: InputMaybe<order_by>;
  energy_costs_total?: InputMaybe<order_by>;
  energy_system_consumer_route_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  scope_1_co2_emissions?: InputMaybe<order_by>;
  scope_1_co2_emissions_total?: InputMaybe<order_by>;
  scope_2_co2_emissions?: InputMaybe<order_by>;
  scope_2_co2_emissions_total?: InputMaybe<order_by>;
  scope_3_co2_emissions?: InputMaybe<order_by>;
  scope_3_co2_emissions_total?: InputMaybe<order_by>;
  year?: InputMaybe<order_by>;
};

/** response of any mutation on the table "energy_path" */
export type energy_path_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<energy_path>;
};

/** on_conflict condition type for table "energy_path" */
export type energy_path_on_conflict = {
  constraint: energy_path_constraint;
  update_columns?: Array<energy_path_update_column>;
  where?: InputMaybe<energy_path_bool_exp>;
};

/** Ordering options when selecting data from "energy_path". */
export type energy_path_order_by = {
  building_model?: InputMaybe<building_model_order_by>;
  building_model_id?: InputMaybe<order_by>;
  co2_costs?: InputMaybe<order_by>;
  co2_costs_total?: InputMaybe<order_by>;
  co2_emissions?: InputMaybe<order_by>;
  co2_emissions_total?: InputMaybe<order_by>;
  efficiency_class?: InputMaybe<efficiency_class_order_by>;
  efficiency_class_id?: InputMaybe<order_by>;
  emission_factor?: InputMaybe<order_by>;
  energy_consumption?: InputMaybe<order_by>;
  energy_consumption_total?: InputMaybe<order_by>;
  energy_costs?: InputMaybe<order_by>;
  energy_costs_total?: InputMaybe<order_by>;
  energy_system_consumer_route?: InputMaybe<energy_system_consumer_route_order_by>;
  energy_system_consumer_route_id?: InputMaybe<order_by>;
  eu_taxonomy_compliance_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  scope_1_co2_emissions?: InputMaybe<order_by>;
  scope_1_co2_emissions_total?: InputMaybe<order_by>;
  scope_2_co2_emissions?: InputMaybe<order_by>;
  scope_2_co2_emissions_total?: InputMaybe<order_by>;
  scope_3_co2_emissions?: InputMaybe<order_by>;
  scope_3_co2_emissions_total?: InputMaybe<order_by>;
  tax_bracket?: InputMaybe<tax_bracket_order_by>;
  tax_bracket_id?: InputMaybe<order_by>;
  year?: InputMaybe<order_by>;
};

/** primary key columns input for table: energy_path */
export type energy_path_pk_columns_input = {
  id: Scalars['Int'];
};

export { energy_path_select_column };

/** input type for updating data in table "energy_path" */
export type energy_path_set_input = {
  building_model_id?: InputMaybe<Scalars['Int']>;
  co2_costs?: InputMaybe<Scalars['Float']>;
  co2_costs_total?: InputMaybe<Scalars['Float']>;
  co2_emissions?: InputMaybe<Scalars['Float']>;
  co2_emissions_total?: InputMaybe<Scalars['Float']>;
  efficiency_class_id?: InputMaybe<efficiency_class_enum>;
  emission_factor?: InputMaybe<Scalars['Float']>;
  energy_consumption?: InputMaybe<Scalars['Float']>;
  energy_consumption_total?: InputMaybe<Scalars['Float']>;
  energy_costs?: InputMaybe<Scalars['Float']>;
  energy_costs_total?: InputMaybe<Scalars['Float']>;
  energy_system_consumer_route_id?: InputMaybe<Scalars['Int']>;
  eu_taxonomy_compliance_id?: InputMaybe<eu_taxonomy_compliance_enum>;
  id?: InputMaybe<Scalars['Int']>;
  scope_1_co2_emissions?: InputMaybe<Scalars['Float']>;
  scope_1_co2_emissions_total?: InputMaybe<Scalars['Float']>;
  scope_2_co2_emissions?: InputMaybe<Scalars['Float']>;
  scope_2_co2_emissions_total?: InputMaybe<Scalars['Float']>;
  scope_3_co2_emissions?: InputMaybe<Scalars['Float']>;
  scope_3_co2_emissions_total?: InputMaybe<Scalars['Float']>;
  tax_bracket_id?: InputMaybe<tax_bracket_enum>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type energy_path_stddev_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  co2_costs?: Maybe<Scalars['Float']>;
  co2_costs_total?: Maybe<Scalars['Float']>;
  co2_emissions?: Maybe<Scalars['Float']>;
  co2_emissions_total?: Maybe<Scalars['Float']>;
  emission_factor?: Maybe<Scalars['Float']>;
  energy_consumption?: Maybe<Scalars['Float']>;
  energy_consumption_total?: Maybe<Scalars['Float']>;
  energy_costs?: Maybe<Scalars['Float']>;
  energy_costs_total?: Maybe<Scalars['Float']>;
  energy_system_consumer_route_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  scope_1_co2_emissions?: Maybe<Scalars['Float']>;
  scope_1_co2_emissions_total?: Maybe<Scalars['Float']>;
  scope_2_co2_emissions?: Maybe<Scalars['Float']>;
  scope_2_co2_emissions_total?: Maybe<Scalars['Float']>;
  scope_3_co2_emissions?: Maybe<Scalars['Float']>;
  scope_3_co2_emissions_total?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "energy_path" */
export type energy_path_stddev_order_by = {
  building_model_id?: InputMaybe<order_by>;
  co2_costs?: InputMaybe<order_by>;
  co2_costs_total?: InputMaybe<order_by>;
  co2_emissions?: InputMaybe<order_by>;
  co2_emissions_total?: InputMaybe<order_by>;
  emission_factor?: InputMaybe<order_by>;
  energy_consumption?: InputMaybe<order_by>;
  energy_consumption_total?: InputMaybe<order_by>;
  energy_costs?: InputMaybe<order_by>;
  energy_costs_total?: InputMaybe<order_by>;
  energy_system_consumer_route_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  scope_1_co2_emissions?: InputMaybe<order_by>;
  scope_1_co2_emissions_total?: InputMaybe<order_by>;
  scope_2_co2_emissions?: InputMaybe<order_by>;
  scope_2_co2_emissions_total?: InputMaybe<order_by>;
  scope_3_co2_emissions?: InputMaybe<order_by>;
  scope_3_co2_emissions_total?: InputMaybe<order_by>;
  year?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type energy_path_stddev_pop_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  co2_costs?: Maybe<Scalars['Float']>;
  co2_costs_total?: Maybe<Scalars['Float']>;
  co2_emissions?: Maybe<Scalars['Float']>;
  co2_emissions_total?: Maybe<Scalars['Float']>;
  emission_factor?: Maybe<Scalars['Float']>;
  energy_consumption?: Maybe<Scalars['Float']>;
  energy_consumption_total?: Maybe<Scalars['Float']>;
  energy_costs?: Maybe<Scalars['Float']>;
  energy_costs_total?: Maybe<Scalars['Float']>;
  energy_system_consumer_route_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  scope_1_co2_emissions?: Maybe<Scalars['Float']>;
  scope_1_co2_emissions_total?: Maybe<Scalars['Float']>;
  scope_2_co2_emissions?: Maybe<Scalars['Float']>;
  scope_2_co2_emissions_total?: Maybe<Scalars['Float']>;
  scope_3_co2_emissions?: Maybe<Scalars['Float']>;
  scope_3_co2_emissions_total?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "energy_path" */
export type energy_path_stddev_pop_order_by = {
  building_model_id?: InputMaybe<order_by>;
  co2_costs?: InputMaybe<order_by>;
  co2_costs_total?: InputMaybe<order_by>;
  co2_emissions?: InputMaybe<order_by>;
  co2_emissions_total?: InputMaybe<order_by>;
  emission_factor?: InputMaybe<order_by>;
  energy_consumption?: InputMaybe<order_by>;
  energy_consumption_total?: InputMaybe<order_by>;
  energy_costs?: InputMaybe<order_by>;
  energy_costs_total?: InputMaybe<order_by>;
  energy_system_consumer_route_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  scope_1_co2_emissions?: InputMaybe<order_by>;
  scope_1_co2_emissions_total?: InputMaybe<order_by>;
  scope_2_co2_emissions?: InputMaybe<order_by>;
  scope_2_co2_emissions_total?: InputMaybe<order_by>;
  scope_3_co2_emissions?: InputMaybe<order_by>;
  scope_3_co2_emissions_total?: InputMaybe<order_by>;
  year?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type energy_path_stddev_samp_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  co2_costs?: Maybe<Scalars['Float']>;
  co2_costs_total?: Maybe<Scalars['Float']>;
  co2_emissions?: Maybe<Scalars['Float']>;
  co2_emissions_total?: Maybe<Scalars['Float']>;
  emission_factor?: Maybe<Scalars['Float']>;
  energy_consumption?: Maybe<Scalars['Float']>;
  energy_consumption_total?: Maybe<Scalars['Float']>;
  energy_costs?: Maybe<Scalars['Float']>;
  energy_costs_total?: Maybe<Scalars['Float']>;
  energy_system_consumer_route_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  scope_1_co2_emissions?: Maybe<Scalars['Float']>;
  scope_1_co2_emissions_total?: Maybe<Scalars['Float']>;
  scope_2_co2_emissions?: Maybe<Scalars['Float']>;
  scope_2_co2_emissions_total?: Maybe<Scalars['Float']>;
  scope_3_co2_emissions?: Maybe<Scalars['Float']>;
  scope_3_co2_emissions_total?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "energy_path" */
export type energy_path_stddev_samp_order_by = {
  building_model_id?: InputMaybe<order_by>;
  co2_costs?: InputMaybe<order_by>;
  co2_costs_total?: InputMaybe<order_by>;
  co2_emissions?: InputMaybe<order_by>;
  co2_emissions_total?: InputMaybe<order_by>;
  emission_factor?: InputMaybe<order_by>;
  energy_consumption?: InputMaybe<order_by>;
  energy_consumption_total?: InputMaybe<order_by>;
  energy_costs?: InputMaybe<order_by>;
  energy_costs_total?: InputMaybe<order_by>;
  energy_system_consumer_route_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  scope_1_co2_emissions?: InputMaybe<order_by>;
  scope_1_co2_emissions_total?: InputMaybe<order_by>;
  scope_2_co2_emissions?: InputMaybe<order_by>;
  scope_2_co2_emissions_total?: InputMaybe<order_by>;
  scope_3_co2_emissions?: InputMaybe<order_by>;
  scope_3_co2_emissions_total?: InputMaybe<order_by>;
  year?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "energy_path" */
export type energy_path_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: energy_path_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type energy_path_stream_cursor_value_input = {
  building_model_id?: InputMaybe<Scalars['Int']>;
  co2_costs?: InputMaybe<Scalars['Float']>;
  co2_costs_total?: InputMaybe<Scalars['Float']>;
  co2_emissions?: InputMaybe<Scalars['Float']>;
  co2_emissions_total?: InputMaybe<Scalars['Float']>;
  efficiency_class_id?: InputMaybe<efficiency_class_enum>;
  emission_factor?: InputMaybe<Scalars['Float']>;
  energy_consumption?: InputMaybe<Scalars['Float']>;
  energy_consumption_total?: InputMaybe<Scalars['Float']>;
  energy_costs?: InputMaybe<Scalars['Float']>;
  energy_costs_total?: InputMaybe<Scalars['Float']>;
  energy_system_consumer_route_id?: InputMaybe<Scalars['Int']>;
  eu_taxonomy_compliance_id?: InputMaybe<eu_taxonomy_compliance_enum>;
  id?: InputMaybe<Scalars['Int']>;
  scope_1_co2_emissions?: InputMaybe<Scalars['Float']>;
  scope_1_co2_emissions_total?: InputMaybe<Scalars['Float']>;
  scope_2_co2_emissions?: InputMaybe<Scalars['Float']>;
  scope_2_co2_emissions_total?: InputMaybe<Scalars['Float']>;
  scope_3_co2_emissions?: InputMaybe<Scalars['Float']>;
  scope_3_co2_emissions_total?: InputMaybe<Scalars['Float']>;
  tax_bracket_id?: InputMaybe<tax_bracket_enum>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type energy_path_sum_fields = {
  building_model_id?: Maybe<Scalars['Int']>;
  co2_costs?: Maybe<Scalars['Float']>;
  co2_costs_total?: Maybe<Scalars['Float']>;
  co2_emissions?: Maybe<Scalars['Float']>;
  co2_emissions_total?: Maybe<Scalars['Float']>;
  emission_factor?: Maybe<Scalars['Float']>;
  energy_consumption?: Maybe<Scalars['Float']>;
  energy_consumption_total?: Maybe<Scalars['Float']>;
  energy_costs?: Maybe<Scalars['Float']>;
  energy_costs_total?: Maybe<Scalars['Float']>;
  energy_system_consumer_route_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  scope_1_co2_emissions?: Maybe<Scalars['Float']>;
  scope_1_co2_emissions_total?: Maybe<Scalars['Float']>;
  scope_2_co2_emissions?: Maybe<Scalars['Float']>;
  scope_2_co2_emissions_total?: Maybe<Scalars['Float']>;
  scope_3_co2_emissions?: Maybe<Scalars['Float']>;
  scope_3_co2_emissions_total?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "energy_path" */
export type energy_path_sum_order_by = {
  building_model_id?: InputMaybe<order_by>;
  co2_costs?: InputMaybe<order_by>;
  co2_costs_total?: InputMaybe<order_by>;
  co2_emissions?: InputMaybe<order_by>;
  co2_emissions_total?: InputMaybe<order_by>;
  emission_factor?: InputMaybe<order_by>;
  energy_consumption?: InputMaybe<order_by>;
  energy_consumption_total?: InputMaybe<order_by>;
  energy_costs?: InputMaybe<order_by>;
  energy_costs_total?: InputMaybe<order_by>;
  energy_system_consumer_route_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  scope_1_co2_emissions?: InputMaybe<order_by>;
  scope_1_co2_emissions_total?: InputMaybe<order_by>;
  scope_2_co2_emissions?: InputMaybe<order_by>;
  scope_2_co2_emissions_total?: InputMaybe<order_by>;
  scope_3_co2_emissions?: InputMaybe<order_by>;
  scope_3_co2_emissions_total?: InputMaybe<order_by>;
  year?: InputMaybe<order_by>;
};

export { energy_path_update_column };

export type energy_path_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<energy_path_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<energy_path_set_input>;
  /** filter the rows which have to be updated */
  where: energy_path_bool_exp;
};

/** aggregate var_pop on columns */
export type energy_path_var_pop_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  co2_costs?: Maybe<Scalars['Float']>;
  co2_costs_total?: Maybe<Scalars['Float']>;
  co2_emissions?: Maybe<Scalars['Float']>;
  co2_emissions_total?: Maybe<Scalars['Float']>;
  emission_factor?: Maybe<Scalars['Float']>;
  energy_consumption?: Maybe<Scalars['Float']>;
  energy_consumption_total?: Maybe<Scalars['Float']>;
  energy_costs?: Maybe<Scalars['Float']>;
  energy_costs_total?: Maybe<Scalars['Float']>;
  energy_system_consumer_route_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  scope_1_co2_emissions?: Maybe<Scalars['Float']>;
  scope_1_co2_emissions_total?: Maybe<Scalars['Float']>;
  scope_2_co2_emissions?: Maybe<Scalars['Float']>;
  scope_2_co2_emissions_total?: Maybe<Scalars['Float']>;
  scope_3_co2_emissions?: Maybe<Scalars['Float']>;
  scope_3_co2_emissions_total?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "energy_path" */
export type energy_path_var_pop_order_by = {
  building_model_id?: InputMaybe<order_by>;
  co2_costs?: InputMaybe<order_by>;
  co2_costs_total?: InputMaybe<order_by>;
  co2_emissions?: InputMaybe<order_by>;
  co2_emissions_total?: InputMaybe<order_by>;
  emission_factor?: InputMaybe<order_by>;
  energy_consumption?: InputMaybe<order_by>;
  energy_consumption_total?: InputMaybe<order_by>;
  energy_costs?: InputMaybe<order_by>;
  energy_costs_total?: InputMaybe<order_by>;
  energy_system_consumer_route_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  scope_1_co2_emissions?: InputMaybe<order_by>;
  scope_1_co2_emissions_total?: InputMaybe<order_by>;
  scope_2_co2_emissions?: InputMaybe<order_by>;
  scope_2_co2_emissions_total?: InputMaybe<order_by>;
  scope_3_co2_emissions?: InputMaybe<order_by>;
  scope_3_co2_emissions_total?: InputMaybe<order_by>;
  year?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type energy_path_var_samp_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  co2_costs?: Maybe<Scalars['Float']>;
  co2_costs_total?: Maybe<Scalars['Float']>;
  co2_emissions?: Maybe<Scalars['Float']>;
  co2_emissions_total?: Maybe<Scalars['Float']>;
  emission_factor?: Maybe<Scalars['Float']>;
  energy_consumption?: Maybe<Scalars['Float']>;
  energy_consumption_total?: Maybe<Scalars['Float']>;
  energy_costs?: Maybe<Scalars['Float']>;
  energy_costs_total?: Maybe<Scalars['Float']>;
  energy_system_consumer_route_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  scope_1_co2_emissions?: Maybe<Scalars['Float']>;
  scope_1_co2_emissions_total?: Maybe<Scalars['Float']>;
  scope_2_co2_emissions?: Maybe<Scalars['Float']>;
  scope_2_co2_emissions_total?: Maybe<Scalars['Float']>;
  scope_3_co2_emissions?: Maybe<Scalars['Float']>;
  scope_3_co2_emissions_total?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "energy_path" */
export type energy_path_var_samp_order_by = {
  building_model_id?: InputMaybe<order_by>;
  co2_costs?: InputMaybe<order_by>;
  co2_costs_total?: InputMaybe<order_by>;
  co2_emissions?: InputMaybe<order_by>;
  co2_emissions_total?: InputMaybe<order_by>;
  emission_factor?: InputMaybe<order_by>;
  energy_consumption?: InputMaybe<order_by>;
  energy_consumption_total?: InputMaybe<order_by>;
  energy_costs?: InputMaybe<order_by>;
  energy_costs_total?: InputMaybe<order_by>;
  energy_system_consumer_route_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  scope_1_co2_emissions?: InputMaybe<order_by>;
  scope_1_co2_emissions_total?: InputMaybe<order_by>;
  scope_2_co2_emissions?: InputMaybe<order_by>;
  scope_2_co2_emissions_total?: InputMaybe<order_by>;
  scope_3_co2_emissions?: InputMaybe<order_by>;
  scope_3_co2_emissions_total?: InputMaybe<order_by>;
  year?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type energy_path_variance_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  co2_costs?: Maybe<Scalars['Float']>;
  co2_costs_total?: Maybe<Scalars['Float']>;
  co2_emissions?: Maybe<Scalars['Float']>;
  co2_emissions_total?: Maybe<Scalars['Float']>;
  emission_factor?: Maybe<Scalars['Float']>;
  energy_consumption?: Maybe<Scalars['Float']>;
  energy_consumption_total?: Maybe<Scalars['Float']>;
  energy_costs?: Maybe<Scalars['Float']>;
  energy_costs_total?: Maybe<Scalars['Float']>;
  energy_system_consumer_route_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  scope_1_co2_emissions?: Maybe<Scalars['Float']>;
  scope_1_co2_emissions_total?: Maybe<Scalars['Float']>;
  scope_2_co2_emissions?: Maybe<Scalars['Float']>;
  scope_2_co2_emissions_total?: Maybe<Scalars['Float']>;
  scope_3_co2_emissions?: Maybe<Scalars['Float']>;
  scope_3_co2_emissions_total?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "energy_path" */
export type energy_path_variance_order_by = {
  building_model_id?: InputMaybe<order_by>;
  co2_costs?: InputMaybe<order_by>;
  co2_costs_total?: InputMaybe<order_by>;
  co2_emissions?: InputMaybe<order_by>;
  co2_emissions_total?: InputMaybe<order_by>;
  emission_factor?: InputMaybe<order_by>;
  energy_consumption?: InputMaybe<order_by>;
  energy_consumption_total?: InputMaybe<order_by>;
  energy_costs?: InputMaybe<order_by>;
  energy_costs_total?: InputMaybe<order_by>;
  energy_system_consumer_route_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  scope_1_co2_emissions?: InputMaybe<order_by>;
  scope_1_co2_emissions_total?: InputMaybe<order_by>;
  scope_2_co2_emissions?: InputMaybe<order_by>;
  scope_2_co2_emissions_total?: InputMaybe<order_by>;
  scope_3_co2_emissions?: InputMaybe<order_by>;
  scope_3_co2_emissions_total?: InputMaybe<order_by>;
  year?: InputMaybe<order_by>;
};

/** columns and relationships of "energy_producer" */
export type energy_producer = {
  /** An object relationship */
  building_model?: Maybe<building_model>;
  building_model_id?: Maybe<Scalars['Int']>;
  construction_year?: Maybe<Scalars['Int']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  energy_source_type_id: energy_source_type_enum;
  /** An array relationship */
  energy_system_producer_routes: Array<energy_system_producer_route>;
  /** An aggregate relationship */
  energy_system_producer_routes_aggregate: energy_system_producer_route_aggregate;
  id: Scalars['Int'];
  module_angle?: Maybe<Scalars['Float']>;
  module_peak_power?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  nominal_power?: Maybe<Scalars['Float']>;
  roof_area_ratio?: Maybe<Scalars['Float']>;
  solar_active_area?: Maybe<Scalars['Float']>;
  /** An object relationship */
  sub_building?: Maybe<sub_building>;
  sub_building_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "energy_producer" */
export type energy_producerenergy_system_producer_routesArgs = {
  distinct_on?: InputMaybe<Array<energy_system_producer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_producer_route_order_by>>;
  where?: InputMaybe<energy_system_producer_route_bool_exp>;
};

/** columns and relationships of "energy_producer" */
export type energy_producerenergy_system_producer_routes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_system_producer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_producer_route_order_by>>;
  where?: InputMaybe<energy_system_producer_route_bool_exp>;
};

/** aggregated selection of "energy_producer" */
export type energy_producer_aggregate = {
  aggregate?: Maybe<energy_producer_aggregate_fields>;
  nodes: Array<energy_producer>;
};

export type energy_producer_aggregate_bool_exp = {
  count?: InputMaybe<energy_producer_aggregate_bool_exp_count>;
};

export type energy_producer_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<energy_producer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<energy_producer_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "energy_producer" */
export type energy_producer_aggregate_fields = {
  avg?: Maybe<energy_producer_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<energy_producer_max_fields>;
  min?: Maybe<energy_producer_min_fields>;
  stddev?: Maybe<energy_producer_stddev_fields>;
  stddev_pop?: Maybe<energy_producer_stddev_pop_fields>;
  stddev_samp?: Maybe<energy_producer_stddev_samp_fields>;
  sum?: Maybe<energy_producer_sum_fields>;
  var_pop?: Maybe<energy_producer_var_pop_fields>;
  var_samp?: Maybe<energy_producer_var_samp_fields>;
  variance?: Maybe<energy_producer_variance_fields>;
};

/** aggregate fields of "energy_producer" */
export type energy_producer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<energy_producer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "energy_producer" */
export type energy_producer_aggregate_order_by = {
  avg?: InputMaybe<energy_producer_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<energy_producer_max_order_by>;
  min?: InputMaybe<energy_producer_min_order_by>;
  stddev?: InputMaybe<energy_producer_stddev_order_by>;
  stddev_pop?: InputMaybe<energy_producer_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<energy_producer_stddev_samp_order_by>;
  sum?: InputMaybe<energy_producer_sum_order_by>;
  var_pop?: InputMaybe<energy_producer_var_pop_order_by>;
  var_samp?: InputMaybe<energy_producer_var_samp_order_by>;
  variance?: InputMaybe<energy_producer_variance_order_by>;
};

/** input type for inserting array relation for remote table "energy_producer" */
export type energy_producer_arr_rel_insert_input = {
  data: Array<energy_producer_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<energy_producer_on_conflict>;
};

/** aggregate avg on columns */
export type energy_producer_avg_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  module_angle?: Maybe<Scalars['Float']>;
  module_peak_power?: Maybe<Scalars['Float']>;
  nominal_power?: Maybe<Scalars['Float']>;
  roof_area_ratio?: Maybe<Scalars['Float']>;
  solar_active_area?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "energy_producer" */
export type energy_producer_avg_order_by = {
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  efficiency?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  module_angle?: InputMaybe<order_by>;
  module_peak_power?: InputMaybe<order_by>;
  nominal_power?: InputMaybe<order_by>;
  roof_area_ratio?: InputMaybe<order_by>;
  solar_active_area?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "energy_producer". All fields are combined with a logical 'AND'. */
export type energy_producer_bool_exp = {
  _and?: InputMaybe<Array<energy_producer_bool_exp>>;
  _not?: InputMaybe<energy_producer_bool_exp>;
  _or?: InputMaybe<Array<energy_producer_bool_exp>>;
  building_model?: InputMaybe<building_model_bool_exp>;
  building_model_id?: InputMaybe<Int_comparison_exp>;
  construction_year?: InputMaybe<Int_comparison_exp>;
  cost_per_m2?: InputMaybe<Float_comparison_exp>;
  efficiency?: InputMaybe<Float_comparison_exp>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum_comparison_exp>;
  energy_system_producer_routes?: InputMaybe<energy_system_producer_route_bool_exp>;
  energy_system_producer_routes_aggregate?: InputMaybe<energy_system_producer_route_aggregate_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  module_angle?: InputMaybe<Float_comparison_exp>;
  module_peak_power?: InputMaybe<Float_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  nominal_power?: InputMaybe<Float_comparison_exp>;
  roof_area_ratio?: InputMaybe<Float_comparison_exp>;
  solar_active_area?: InputMaybe<Float_comparison_exp>;
  sub_building?: InputMaybe<sub_building_bool_exp>;
  sub_building_id?: InputMaybe<Int_comparison_exp>;
};

export { energy_producer_constraint };

/** input type for incrementing numeric columns in table "energy_producer" */
export type energy_producer_inc_input = {
  building_model_id?: InputMaybe<Scalars['Int']>;
  construction_year?: InputMaybe<Scalars['Int']>;
  cost_per_m2?: InputMaybe<Scalars['Float']>;
  efficiency?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  module_angle?: InputMaybe<Scalars['Float']>;
  module_peak_power?: InputMaybe<Scalars['Float']>;
  nominal_power?: InputMaybe<Scalars['Float']>;
  roof_area_ratio?: InputMaybe<Scalars['Float']>;
  solar_active_area?: InputMaybe<Scalars['Float']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "energy_producer" */
export type energy_producer_insert_input = {
  building_model?: InputMaybe<building_model_obj_rel_insert_input>;
  building_model_id?: InputMaybe<Scalars['Int']>;
  construction_year?: InputMaybe<Scalars['Int']>;
  cost_per_m2?: InputMaybe<Scalars['Float']>;
  efficiency?: InputMaybe<Scalars['Float']>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum>;
  energy_system_producer_routes?: InputMaybe<energy_system_producer_route_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']>;
  module_angle?: InputMaybe<Scalars['Float']>;
  module_peak_power?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  nominal_power?: InputMaybe<Scalars['Float']>;
  roof_area_ratio?: InputMaybe<Scalars['Float']>;
  solar_active_area?: InputMaybe<Scalars['Float']>;
  sub_building?: InputMaybe<sub_building_obj_rel_insert_input>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type energy_producer_max_fields = {
  building_model_id?: Maybe<Scalars['Int']>;
  construction_year?: Maybe<Scalars['Int']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  module_angle?: Maybe<Scalars['Float']>;
  module_peak_power?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  nominal_power?: Maybe<Scalars['Float']>;
  roof_area_ratio?: Maybe<Scalars['Float']>;
  solar_active_area?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "energy_producer" */
export type energy_producer_max_order_by = {
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  efficiency?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  module_angle?: InputMaybe<order_by>;
  module_peak_power?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  nominal_power?: InputMaybe<order_by>;
  roof_area_ratio?: InputMaybe<order_by>;
  solar_active_area?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type energy_producer_min_fields = {
  building_model_id?: Maybe<Scalars['Int']>;
  construction_year?: Maybe<Scalars['Int']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  module_angle?: Maybe<Scalars['Float']>;
  module_peak_power?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  nominal_power?: Maybe<Scalars['Float']>;
  roof_area_ratio?: Maybe<Scalars['Float']>;
  solar_active_area?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "energy_producer" */
export type energy_producer_min_order_by = {
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  efficiency?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  module_angle?: InputMaybe<order_by>;
  module_peak_power?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  nominal_power?: InputMaybe<order_by>;
  roof_area_ratio?: InputMaybe<order_by>;
  solar_active_area?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "energy_producer" */
export type energy_producer_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<energy_producer>;
};

/** input type for inserting object relation for remote table "energy_producer" */
export type energy_producer_obj_rel_insert_input = {
  data: energy_producer_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<energy_producer_on_conflict>;
};

/** on_conflict condition type for table "energy_producer" */
export type energy_producer_on_conflict = {
  constraint: energy_producer_constraint;
  update_columns?: Array<energy_producer_update_column>;
  where?: InputMaybe<energy_producer_bool_exp>;
};

/** Ordering options when selecting data from "energy_producer". */
export type energy_producer_order_by = {
  building_model?: InputMaybe<building_model_order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  efficiency?: InputMaybe<order_by>;
  energy_source_type_id?: InputMaybe<order_by>;
  energy_system_producer_routes_aggregate?: InputMaybe<energy_system_producer_route_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  module_angle?: InputMaybe<order_by>;
  module_peak_power?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  nominal_power?: InputMaybe<order_by>;
  roof_area_ratio?: InputMaybe<order_by>;
  solar_active_area?: InputMaybe<order_by>;
  sub_building?: InputMaybe<sub_building_order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: energy_producer */
export type energy_producer_pk_columns_input = {
  id: Scalars['Int'];
};

export { energy_producer_select_column };

/** input type for updating data in table "energy_producer" */
export type energy_producer_set_input = {
  building_model_id?: InputMaybe<Scalars['Int']>;
  construction_year?: InputMaybe<Scalars['Int']>;
  cost_per_m2?: InputMaybe<Scalars['Float']>;
  efficiency?: InputMaybe<Scalars['Float']>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  module_angle?: InputMaybe<Scalars['Float']>;
  module_peak_power?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  nominal_power?: InputMaybe<Scalars['Float']>;
  roof_area_ratio?: InputMaybe<Scalars['Float']>;
  solar_active_area?: InputMaybe<Scalars['Float']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type energy_producer_stddev_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  module_angle?: Maybe<Scalars['Float']>;
  module_peak_power?: Maybe<Scalars['Float']>;
  nominal_power?: Maybe<Scalars['Float']>;
  roof_area_ratio?: Maybe<Scalars['Float']>;
  solar_active_area?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "energy_producer" */
export type energy_producer_stddev_order_by = {
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  efficiency?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  module_angle?: InputMaybe<order_by>;
  module_peak_power?: InputMaybe<order_by>;
  nominal_power?: InputMaybe<order_by>;
  roof_area_ratio?: InputMaybe<order_by>;
  solar_active_area?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type energy_producer_stddev_pop_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  module_angle?: Maybe<Scalars['Float']>;
  module_peak_power?: Maybe<Scalars['Float']>;
  nominal_power?: Maybe<Scalars['Float']>;
  roof_area_ratio?: Maybe<Scalars['Float']>;
  solar_active_area?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "energy_producer" */
export type energy_producer_stddev_pop_order_by = {
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  efficiency?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  module_angle?: InputMaybe<order_by>;
  module_peak_power?: InputMaybe<order_by>;
  nominal_power?: InputMaybe<order_by>;
  roof_area_ratio?: InputMaybe<order_by>;
  solar_active_area?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type energy_producer_stddev_samp_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  module_angle?: Maybe<Scalars['Float']>;
  module_peak_power?: Maybe<Scalars['Float']>;
  nominal_power?: Maybe<Scalars['Float']>;
  roof_area_ratio?: Maybe<Scalars['Float']>;
  solar_active_area?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "energy_producer" */
export type energy_producer_stddev_samp_order_by = {
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  efficiency?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  module_angle?: InputMaybe<order_by>;
  module_peak_power?: InputMaybe<order_by>;
  nominal_power?: InputMaybe<order_by>;
  roof_area_ratio?: InputMaybe<order_by>;
  solar_active_area?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "energy_producer" */
export type energy_producer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: energy_producer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type energy_producer_stream_cursor_value_input = {
  building_model_id?: InputMaybe<Scalars['Int']>;
  construction_year?: InputMaybe<Scalars['Int']>;
  cost_per_m2?: InputMaybe<Scalars['Float']>;
  efficiency?: InputMaybe<Scalars['Float']>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  module_angle?: InputMaybe<Scalars['Float']>;
  module_peak_power?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  nominal_power?: InputMaybe<Scalars['Float']>;
  roof_area_ratio?: InputMaybe<Scalars['Float']>;
  solar_active_area?: InputMaybe<Scalars['Float']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type energy_producer_sum_fields = {
  building_model_id?: Maybe<Scalars['Int']>;
  construction_year?: Maybe<Scalars['Int']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  module_angle?: Maybe<Scalars['Float']>;
  module_peak_power?: Maybe<Scalars['Float']>;
  nominal_power?: Maybe<Scalars['Float']>;
  roof_area_ratio?: Maybe<Scalars['Float']>;
  solar_active_area?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "energy_producer" */
export type energy_producer_sum_order_by = {
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  efficiency?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  module_angle?: InputMaybe<order_by>;
  module_peak_power?: InputMaybe<order_by>;
  nominal_power?: InputMaybe<order_by>;
  roof_area_ratio?: InputMaybe<order_by>;
  solar_active_area?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

export { energy_producer_update_column };

export type energy_producer_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<energy_producer_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<energy_producer_set_input>;
  /** filter the rows which have to be updated */
  where: energy_producer_bool_exp;
};

/** aggregate var_pop on columns */
export type energy_producer_var_pop_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  module_angle?: Maybe<Scalars['Float']>;
  module_peak_power?: Maybe<Scalars['Float']>;
  nominal_power?: Maybe<Scalars['Float']>;
  roof_area_ratio?: Maybe<Scalars['Float']>;
  solar_active_area?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "energy_producer" */
export type energy_producer_var_pop_order_by = {
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  efficiency?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  module_angle?: InputMaybe<order_by>;
  module_peak_power?: InputMaybe<order_by>;
  nominal_power?: InputMaybe<order_by>;
  roof_area_ratio?: InputMaybe<order_by>;
  solar_active_area?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type energy_producer_var_samp_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  module_angle?: Maybe<Scalars['Float']>;
  module_peak_power?: Maybe<Scalars['Float']>;
  nominal_power?: Maybe<Scalars['Float']>;
  roof_area_ratio?: Maybe<Scalars['Float']>;
  solar_active_area?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "energy_producer" */
export type energy_producer_var_samp_order_by = {
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  efficiency?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  module_angle?: InputMaybe<order_by>;
  module_peak_power?: InputMaybe<order_by>;
  nominal_power?: InputMaybe<order_by>;
  roof_area_ratio?: InputMaybe<order_by>;
  solar_active_area?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type energy_producer_variance_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  module_angle?: Maybe<Scalars['Float']>;
  module_peak_power?: Maybe<Scalars['Float']>;
  nominal_power?: Maybe<Scalars['Float']>;
  roof_area_ratio?: Maybe<Scalars['Float']>;
  solar_active_area?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "energy_producer" */
export type energy_producer_variance_order_by = {
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  efficiency?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  module_angle?: InputMaybe<order_by>;
  module_peak_power?: InputMaybe<order_by>;
  nominal_power?: InputMaybe<order_by>;
  roof_area_ratio?: InputMaybe<order_by>;
  solar_active_area?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** columns and relationships of "energy_source" */
export type energy_source = {
  /** An object relationship */
  action?: Maybe<action>;
  action_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  energy_certificate_draft?: Maybe<energy_certificate_draft>;
  energy_certificate_draft_id?: Maybe<Scalars['Int']>;
  energy_final_consumption?: Maybe<Scalars['numeric']>;
  energy_final_demand?: Maybe<Scalars['numeric']>;
  energy_primary?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['numeric']>;
  energy_primary_demand?: Maybe<Scalars['numeric']>;
  energy_source_type: energy_source_type_enum;
  id: Scalars['Int'];
  /** An object relationship */
  sub_building?: Maybe<sub_building>;
  sub_building_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "energy_source" */
export type energy_source_aggregate = {
  aggregate?: Maybe<energy_source_aggregate_fields>;
  nodes: Array<energy_source>;
};

export type energy_source_aggregate_bool_exp = {
  count?: InputMaybe<energy_source_aggregate_bool_exp_count>;
};

export type energy_source_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<energy_source_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<energy_source_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "energy_source" */
export type energy_source_aggregate_fields = {
  avg?: Maybe<energy_source_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<energy_source_max_fields>;
  min?: Maybe<energy_source_min_fields>;
  stddev?: Maybe<energy_source_stddev_fields>;
  stddev_pop?: Maybe<energy_source_stddev_pop_fields>;
  stddev_samp?: Maybe<energy_source_stddev_samp_fields>;
  sum?: Maybe<energy_source_sum_fields>;
  var_pop?: Maybe<energy_source_var_pop_fields>;
  var_samp?: Maybe<energy_source_var_samp_fields>;
  variance?: Maybe<energy_source_variance_fields>;
};

/** aggregate fields of "energy_source" */
export type energy_source_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<energy_source_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "energy_source" */
export type energy_source_aggregate_order_by = {
  avg?: InputMaybe<energy_source_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<energy_source_max_order_by>;
  min?: InputMaybe<energy_source_min_order_by>;
  stddev?: InputMaybe<energy_source_stddev_order_by>;
  stddev_pop?: InputMaybe<energy_source_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<energy_source_stddev_samp_order_by>;
  sum?: InputMaybe<energy_source_sum_order_by>;
  var_pop?: InputMaybe<energy_source_var_pop_order_by>;
  var_samp?: InputMaybe<energy_source_var_samp_order_by>;
  variance?: InputMaybe<energy_source_variance_order_by>;
};

/** input type for inserting array relation for remote table "energy_source" */
export type energy_source_arr_rel_insert_input = {
  data: Array<energy_source_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<energy_source_on_conflict>;
};

/** aggregate avg on columns */
export type energy_source_avg_fields = {
  action_id?: Maybe<Scalars['Float']>;
  energy_certificate_draft_id?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['Float']>;
  energy_primary_demand?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "energy_source" */
export type energy_source_avg_order_by = {
  action_id?: InputMaybe<order_by>;
  energy_certificate_draft_id?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "energy_source". All fields are combined with a logical 'AND'. */
export type energy_source_bool_exp = {
  _and?: InputMaybe<Array<energy_source_bool_exp>>;
  _not?: InputMaybe<energy_source_bool_exp>;
  _or?: InputMaybe<Array<energy_source_bool_exp>>;
  action?: InputMaybe<action_bool_exp>;
  action_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  energy_certificate_draft?: InputMaybe<energy_certificate_draft_bool_exp>;
  energy_certificate_draft_id?: InputMaybe<Int_comparison_exp>;
  energy_final_consumption?: InputMaybe<numeric_comparison_exp>;
  energy_final_demand?: InputMaybe<numeric_comparison_exp>;
  energy_primary?: InputMaybe<Float_comparison_exp>;
  energy_primary_consumption?: InputMaybe<numeric_comparison_exp>;
  energy_primary_demand?: InputMaybe<numeric_comparison_exp>;
  energy_source_type?: InputMaybe<energy_source_type_enum_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  sub_building?: InputMaybe<sub_building_bool_exp>;
  sub_building_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

export { energy_source_constraint };

/** input type for incrementing numeric columns in table "energy_source" */
export type energy_source_inc_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  energy_certificate_draft_id?: InputMaybe<Scalars['Int']>;
  energy_final_consumption?: InputMaybe<Scalars['numeric']>;
  energy_final_demand?: InputMaybe<Scalars['numeric']>;
  energy_primary?: InputMaybe<Scalars['Float']>;
  energy_primary_consumption?: InputMaybe<Scalars['numeric']>;
  energy_primary_demand?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "energy_source" */
export type energy_source_insert_input = {
  action?: InputMaybe<action_obj_rel_insert_input>;
  action_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  energy_certificate_draft?: InputMaybe<energy_certificate_draft_obj_rel_insert_input>;
  energy_certificate_draft_id?: InputMaybe<Scalars['Int']>;
  energy_final_consumption?: InputMaybe<Scalars['numeric']>;
  energy_final_demand?: InputMaybe<Scalars['numeric']>;
  energy_primary?: InputMaybe<Scalars['Float']>;
  energy_primary_consumption?: InputMaybe<Scalars['numeric']>;
  energy_primary_demand?: InputMaybe<Scalars['numeric']>;
  energy_source_type?: InputMaybe<energy_source_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  sub_building?: InputMaybe<sub_building_obj_rel_insert_input>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type energy_source_max_fields = {
  action_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  energy_certificate_draft_id?: Maybe<Scalars['Int']>;
  energy_final_consumption?: Maybe<Scalars['numeric']>;
  energy_final_demand?: Maybe<Scalars['numeric']>;
  energy_primary?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['numeric']>;
  energy_primary_demand?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "energy_source" */
export type energy_source_max_order_by = {
  action_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  energy_certificate_draft_id?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type energy_source_min_fields = {
  action_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  energy_certificate_draft_id?: Maybe<Scalars['Int']>;
  energy_final_consumption?: Maybe<Scalars['numeric']>;
  energy_final_demand?: Maybe<Scalars['numeric']>;
  energy_primary?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['numeric']>;
  energy_primary_demand?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "energy_source" */
export type energy_source_min_order_by = {
  action_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  energy_certificate_draft_id?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "energy_source" */
export type energy_source_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<energy_source>;
};

/** on_conflict condition type for table "energy_source" */
export type energy_source_on_conflict = {
  constraint: energy_source_constraint;
  update_columns?: Array<energy_source_update_column>;
  where?: InputMaybe<energy_source_bool_exp>;
};

/** Ordering options when selecting data from "energy_source". */
export type energy_source_order_by = {
  action?: InputMaybe<action_order_by>;
  action_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  energy_certificate_draft?: InputMaybe<energy_certificate_draft_order_by>;
  energy_certificate_draft_id?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  energy_source_type?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building?: InputMaybe<sub_building_order_by>;
  sub_building_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: energy_source */
export type energy_source_pk_columns_input = {
  id: Scalars['Int'];
};

export { energy_source_select_column };

/** input type for updating data in table "energy_source" */
export type energy_source_set_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  energy_certificate_draft_id?: InputMaybe<Scalars['Int']>;
  energy_final_consumption?: InputMaybe<Scalars['numeric']>;
  energy_final_demand?: InputMaybe<Scalars['numeric']>;
  energy_primary?: InputMaybe<Scalars['Float']>;
  energy_primary_consumption?: InputMaybe<Scalars['numeric']>;
  energy_primary_demand?: InputMaybe<Scalars['numeric']>;
  energy_source_type?: InputMaybe<energy_source_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type energy_source_stddev_fields = {
  action_id?: Maybe<Scalars['Float']>;
  energy_certificate_draft_id?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['Float']>;
  energy_primary_demand?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "energy_source" */
export type energy_source_stddev_order_by = {
  action_id?: InputMaybe<order_by>;
  energy_certificate_draft_id?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type energy_source_stddev_pop_fields = {
  action_id?: Maybe<Scalars['Float']>;
  energy_certificate_draft_id?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['Float']>;
  energy_primary_demand?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "energy_source" */
export type energy_source_stddev_pop_order_by = {
  action_id?: InputMaybe<order_by>;
  energy_certificate_draft_id?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type energy_source_stddev_samp_fields = {
  action_id?: Maybe<Scalars['Float']>;
  energy_certificate_draft_id?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['Float']>;
  energy_primary_demand?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "energy_source" */
export type energy_source_stddev_samp_order_by = {
  action_id?: InputMaybe<order_by>;
  energy_certificate_draft_id?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "energy_source" */
export type energy_source_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: energy_source_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type energy_source_stream_cursor_value_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  energy_certificate_draft_id?: InputMaybe<Scalars['Int']>;
  energy_final_consumption?: InputMaybe<Scalars['numeric']>;
  energy_final_demand?: InputMaybe<Scalars['numeric']>;
  energy_primary?: InputMaybe<Scalars['Float']>;
  energy_primary_consumption?: InputMaybe<Scalars['numeric']>;
  energy_primary_demand?: InputMaybe<Scalars['numeric']>;
  energy_source_type?: InputMaybe<energy_source_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type energy_source_sum_fields = {
  action_id?: Maybe<Scalars['Int']>;
  energy_certificate_draft_id?: Maybe<Scalars['Int']>;
  energy_final_consumption?: Maybe<Scalars['numeric']>;
  energy_final_demand?: Maybe<Scalars['numeric']>;
  energy_primary?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['numeric']>;
  energy_primary_demand?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "energy_source" */
export type energy_source_sum_order_by = {
  action_id?: InputMaybe<order_by>;
  energy_certificate_draft_id?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** columns and relationships of "energy_source_type" */
export type energy_source_type = {
  id: Scalars['String'];
};

/** aggregated selection of "energy_source_type" */
export type energy_source_type_aggregate = {
  aggregate?: Maybe<energy_source_type_aggregate_fields>;
  nodes: Array<energy_source_type>;
};

/** aggregate fields of "energy_source_type" */
export type energy_source_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<energy_source_type_max_fields>;
  min?: Maybe<energy_source_type_min_fields>;
};

/** aggregate fields of "energy_source_type" */
export type energy_source_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<energy_source_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "energy_source_type". All fields are combined with a logical 'AND'. */
export type energy_source_type_bool_exp = {
  _and?: InputMaybe<Array<energy_source_type_bool_exp>>;
  _not?: InputMaybe<energy_source_type_bool_exp>;
  _or?: InputMaybe<Array<energy_source_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { energy_source_type_constraint };

export { energy_source_type_enum };

/** Boolean expression to compare columns of type "energy_source_type_enum". All fields are combined with logical 'AND'. */
export type energy_source_type_enum_comparison_exp = {
  _eq?: InputMaybe<energy_source_type_enum>;
  _in?: InputMaybe<Array<energy_source_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<energy_source_type_enum>;
  _nin?: InputMaybe<Array<energy_source_type_enum>>;
};

/** input type for inserting data into table "energy_source_type" */
export type energy_source_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type energy_source_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type energy_source_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "energy_source_type" */
export type energy_source_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<energy_source_type>;
};

/** on_conflict condition type for table "energy_source_type" */
export type energy_source_type_on_conflict = {
  constraint: energy_source_type_constraint;
  update_columns?: Array<energy_source_type_update_column>;
  where?: InputMaybe<energy_source_type_bool_exp>;
};

/** Ordering options when selecting data from "energy_source_type". */
export type energy_source_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: energy_source_type */
export type energy_source_type_pk_columns_input = {
  id: Scalars['String'];
};

export { energy_source_type_select_column };

/** input type for updating data in table "energy_source_type" */
export type energy_source_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "energy_source_type" */
export type energy_source_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: energy_source_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type energy_source_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { energy_source_type_update_column };

export type energy_source_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<energy_source_type_set_input>;
  /** filter the rows which have to be updated */
  where: energy_source_type_bool_exp;
};

export { energy_source_update_column };

export type energy_source_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<energy_source_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<energy_source_set_input>;
  /** filter the rows which have to be updated */
  where: energy_source_bool_exp;
};

/** aggregate var_pop on columns */
export type energy_source_var_pop_fields = {
  action_id?: Maybe<Scalars['Float']>;
  energy_certificate_draft_id?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['Float']>;
  energy_primary_demand?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "energy_source" */
export type energy_source_var_pop_order_by = {
  action_id?: InputMaybe<order_by>;
  energy_certificate_draft_id?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type energy_source_var_samp_fields = {
  action_id?: Maybe<Scalars['Float']>;
  energy_certificate_draft_id?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['Float']>;
  energy_primary_demand?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "energy_source" */
export type energy_source_var_samp_order_by = {
  action_id?: InputMaybe<order_by>;
  energy_certificate_draft_id?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type energy_source_variance_fields = {
  action_id?: Maybe<Scalars['Float']>;
  energy_certificate_draft_id?: Maybe<Scalars['Float']>;
  energy_final_consumption?: Maybe<Scalars['Float']>;
  energy_final_demand?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  energy_primary_consumption?: Maybe<Scalars['Float']>;
  energy_primary_demand?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "energy_source" */
export type energy_source_variance_order_by = {
  action_id?: InputMaybe<order_by>;
  energy_certificate_draft_id?: InputMaybe<order_by>;
  energy_final_consumption?: InputMaybe<order_by>;
  energy_final_demand?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_primary_consumption?: InputMaybe<order_by>;
  energy_primary_demand?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** columns and relationships of "energy_standard" */
export type energy_standard = {
  id: Scalars['String'];
};

/** aggregated selection of "energy_standard" */
export type energy_standard_aggregate = {
  aggregate?: Maybe<energy_standard_aggregate_fields>;
  nodes: Array<energy_standard>;
};

/** aggregate fields of "energy_standard" */
export type energy_standard_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<energy_standard_max_fields>;
  min?: Maybe<energy_standard_min_fields>;
};

/** aggregate fields of "energy_standard" */
export type energy_standard_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<energy_standard_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "energy_standard". All fields are combined with a logical 'AND'. */
export type energy_standard_bool_exp = {
  _and?: InputMaybe<Array<energy_standard_bool_exp>>;
  _not?: InputMaybe<energy_standard_bool_exp>;
  _or?: InputMaybe<Array<energy_standard_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { energy_standard_constraint };

export { energy_standard_enum };

/** Boolean expression to compare columns of type "energy_standard_enum". All fields are combined with logical 'AND'. */
export type energy_standard_enum_comparison_exp = {
  _eq?: InputMaybe<energy_standard_enum>;
  _in?: InputMaybe<Array<energy_standard_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<energy_standard_enum>;
  _nin?: InputMaybe<Array<energy_standard_enum>>;
};

/** input type for inserting data into table "energy_standard" */
export type energy_standard_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type energy_standard_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type energy_standard_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "energy_standard" */
export type energy_standard_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<energy_standard>;
};

/** on_conflict condition type for table "energy_standard" */
export type energy_standard_on_conflict = {
  constraint: energy_standard_constraint;
  update_columns?: Array<energy_standard_update_column>;
  where?: InputMaybe<energy_standard_bool_exp>;
};

/** Ordering options when selecting data from "energy_standard". */
export type energy_standard_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: energy_standard */
export type energy_standard_pk_columns_input = {
  id: Scalars['String'];
};

export { energy_standard_select_column };

/** input type for updating data in table "energy_standard" */
export type energy_standard_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "energy_standard" */
export type energy_standard_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: energy_standard_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type energy_standard_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { energy_standard_update_column };

export type energy_standard_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<energy_standard_set_input>;
  /** filter the rows which have to be updated */
  where: energy_standard_bool_exp;
};

/** columns and relationships of "energy_storage" */
export type energy_storage = {
  annual_heat_loss?: Maybe<Scalars['Float']>;
  /** An object relationship */
  building_model?: Maybe<building_model>;
  building_model_id?: Maybe<Scalars['Int']>;
  construction_year?: Maybe<Scalars['Int']>;
  energy_storage_type_id: energy_storage_type_enum;
  /** An array relationship */
  energy_system_consumer_routes: Array<energy_system_consumer_route>;
  /** An aggregate relationship */
  energy_system_consumer_routes_aggregate: energy_system_consumer_route_aggregate;
  id: Scalars['Int'];
  recoverable_heat_loss?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "energy_storage" */
export type energy_storageenergy_system_consumer_routesArgs = {
  distinct_on?: InputMaybe<Array<energy_system_consumer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_consumer_route_order_by>>;
  where?: InputMaybe<energy_system_consumer_route_bool_exp>;
};

/** columns and relationships of "energy_storage" */
export type energy_storageenergy_system_consumer_routes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_system_consumer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_consumer_route_order_by>>;
  where?: InputMaybe<energy_system_consumer_route_bool_exp>;
};

/** aggregated selection of "energy_storage" */
export type energy_storage_aggregate = {
  aggregate?: Maybe<energy_storage_aggregate_fields>;
  nodes: Array<energy_storage>;
};

export type energy_storage_aggregate_bool_exp = {
  count?: InputMaybe<energy_storage_aggregate_bool_exp_count>;
};

export type energy_storage_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<energy_storage_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<energy_storage_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "energy_storage" */
export type energy_storage_aggregate_fields = {
  avg?: Maybe<energy_storage_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<energy_storage_max_fields>;
  min?: Maybe<energy_storage_min_fields>;
  stddev?: Maybe<energy_storage_stddev_fields>;
  stddev_pop?: Maybe<energy_storage_stddev_pop_fields>;
  stddev_samp?: Maybe<energy_storage_stddev_samp_fields>;
  sum?: Maybe<energy_storage_sum_fields>;
  var_pop?: Maybe<energy_storage_var_pop_fields>;
  var_samp?: Maybe<energy_storage_var_samp_fields>;
  variance?: Maybe<energy_storage_variance_fields>;
};

/** aggregate fields of "energy_storage" */
export type energy_storage_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<energy_storage_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "energy_storage" */
export type energy_storage_aggregate_order_by = {
  avg?: InputMaybe<energy_storage_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<energy_storage_max_order_by>;
  min?: InputMaybe<energy_storage_min_order_by>;
  stddev?: InputMaybe<energy_storage_stddev_order_by>;
  stddev_pop?: InputMaybe<energy_storage_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<energy_storage_stddev_samp_order_by>;
  sum?: InputMaybe<energy_storage_sum_order_by>;
  var_pop?: InputMaybe<energy_storage_var_pop_order_by>;
  var_samp?: InputMaybe<energy_storage_var_samp_order_by>;
  variance?: InputMaybe<energy_storage_variance_order_by>;
};

/** input type for inserting array relation for remote table "energy_storage" */
export type energy_storage_arr_rel_insert_input = {
  data: Array<energy_storage_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<energy_storage_on_conflict>;
};

/** aggregate avg on columns */
export type energy_storage_avg_fields = {
  annual_heat_loss?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recoverable_heat_loss?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "energy_storage" */
export type energy_storage_avg_order_by = {
  annual_heat_loss?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  recoverable_heat_loss?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "energy_storage". All fields are combined with a logical 'AND'. */
export type energy_storage_bool_exp = {
  _and?: InputMaybe<Array<energy_storage_bool_exp>>;
  _not?: InputMaybe<energy_storage_bool_exp>;
  _or?: InputMaybe<Array<energy_storage_bool_exp>>;
  annual_heat_loss?: InputMaybe<Float_comparison_exp>;
  building_model?: InputMaybe<building_model_bool_exp>;
  building_model_id?: InputMaybe<Int_comparison_exp>;
  construction_year?: InputMaybe<Int_comparison_exp>;
  energy_storage_type_id?: InputMaybe<energy_storage_type_enum_comparison_exp>;
  energy_system_consumer_routes?: InputMaybe<energy_system_consumer_route_bool_exp>;
  energy_system_consumer_routes_aggregate?: InputMaybe<energy_system_consumer_route_aggregate_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  recoverable_heat_loss?: InputMaybe<Float_comparison_exp>;
};

export { energy_storage_constraint };

/** input type for incrementing numeric columns in table "energy_storage" */
export type energy_storage_inc_input = {
  annual_heat_loss?: InputMaybe<Scalars['Float']>;
  building_model_id?: InputMaybe<Scalars['Int']>;
  construction_year?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  recoverable_heat_loss?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "energy_storage" */
export type energy_storage_insert_input = {
  annual_heat_loss?: InputMaybe<Scalars['Float']>;
  building_model?: InputMaybe<building_model_obj_rel_insert_input>;
  building_model_id?: InputMaybe<Scalars['Int']>;
  construction_year?: InputMaybe<Scalars['Int']>;
  energy_storage_type_id?: InputMaybe<energy_storage_type_enum>;
  energy_system_consumer_routes?: InputMaybe<energy_system_consumer_route_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']>;
  recoverable_heat_loss?: InputMaybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type energy_storage_max_fields = {
  annual_heat_loss?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Int']>;
  construction_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  recoverable_heat_loss?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "energy_storage" */
export type energy_storage_max_order_by = {
  annual_heat_loss?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  recoverable_heat_loss?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type energy_storage_min_fields = {
  annual_heat_loss?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Int']>;
  construction_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  recoverable_heat_loss?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "energy_storage" */
export type energy_storage_min_order_by = {
  annual_heat_loss?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  recoverable_heat_loss?: InputMaybe<order_by>;
};

/** response of any mutation on the table "energy_storage" */
export type energy_storage_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<energy_storage>;
};

/** input type for inserting object relation for remote table "energy_storage" */
export type energy_storage_obj_rel_insert_input = {
  data: energy_storage_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<energy_storage_on_conflict>;
};

/** on_conflict condition type for table "energy_storage" */
export type energy_storage_on_conflict = {
  constraint: energy_storage_constraint;
  update_columns?: Array<energy_storage_update_column>;
  where?: InputMaybe<energy_storage_bool_exp>;
};

/** Ordering options when selecting data from "energy_storage". */
export type energy_storage_order_by = {
  annual_heat_loss?: InputMaybe<order_by>;
  building_model?: InputMaybe<building_model_order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  energy_storage_type_id?: InputMaybe<order_by>;
  energy_system_consumer_routes_aggregate?: InputMaybe<energy_system_consumer_route_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  recoverable_heat_loss?: InputMaybe<order_by>;
};

/** primary key columns input for table: energy_storage */
export type energy_storage_pk_columns_input = {
  id: Scalars['Int'];
};

export { energy_storage_select_column };

/** input type for updating data in table "energy_storage" */
export type energy_storage_set_input = {
  annual_heat_loss?: InputMaybe<Scalars['Float']>;
  building_model_id?: InputMaybe<Scalars['Int']>;
  construction_year?: InputMaybe<Scalars['Int']>;
  energy_storage_type_id?: InputMaybe<energy_storage_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  recoverable_heat_loss?: InputMaybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type energy_storage_stddev_fields = {
  annual_heat_loss?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recoverable_heat_loss?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "energy_storage" */
export type energy_storage_stddev_order_by = {
  annual_heat_loss?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  recoverable_heat_loss?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type energy_storage_stddev_pop_fields = {
  annual_heat_loss?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recoverable_heat_loss?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "energy_storage" */
export type energy_storage_stddev_pop_order_by = {
  annual_heat_loss?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  recoverable_heat_loss?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type energy_storage_stddev_samp_fields = {
  annual_heat_loss?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recoverable_heat_loss?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "energy_storage" */
export type energy_storage_stddev_samp_order_by = {
  annual_heat_loss?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  recoverable_heat_loss?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "energy_storage" */
export type energy_storage_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: energy_storage_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type energy_storage_stream_cursor_value_input = {
  annual_heat_loss?: InputMaybe<Scalars['Float']>;
  building_model_id?: InputMaybe<Scalars['Int']>;
  construction_year?: InputMaybe<Scalars['Int']>;
  energy_storage_type_id?: InputMaybe<energy_storage_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  recoverable_heat_loss?: InputMaybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type energy_storage_sum_fields = {
  annual_heat_loss?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Int']>;
  construction_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  recoverable_heat_loss?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "energy_storage" */
export type energy_storage_sum_order_by = {
  annual_heat_loss?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  recoverable_heat_loss?: InputMaybe<order_by>;
};

/** columns and relationships of "energy_storage_type" */
export type energy_storage_type = {
  id: Scalars['String'];
};

/** aggregated selection of "energy_storage_type" */
export type energy_storage_type_aggregate = {
  aggregate?: Maybe<energy_storage_type_aggregate_fields>;
  nodes: Array<energy_storage_type>;
};

/** aggregate fields of "energy_storage_type" */
export type energy_storage_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<energy_storage_type_max_fields>;
  min?: Maybe<energy_storage_type_min_fields>;
};

/** aggregate fields of "energy_storage_type" */
export type energy_storage_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<energy_storage_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "energy_storage_type". All fields are combined with a logical 'AND'. */
export type energy_storage_type_bool_exp = {
  _and?: InputMaybe<Array<energy_storage_type_bool_exp>>;
  _not?: InputMaybe<energy_storage_type_bool_exp>;
  _or?: InputMaybe<Array<energy_storage_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { energy_storage_type_constraint };

export { energy_storage_type_enum };

/** Boolean expression to compare columns of type "energy_storage_type_enum". All fields are combined with logical 'AND'. */
export type energy_storage_type_enum_comparison_exp = {
  _eq?: InputMaybe<energy_storage_type_enum>;
  _in?: InputMaybe<Array<energy_storage_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<energy_storage_type_enum>;
  _nin?: InputMaybe<Array<energy_storage_type_enum>>;
};

/** input type for inserting data into table "energy_storage_type" */
export type energy_storage_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type energy_storage_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type energy_storage_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "energy_storage_type" */
export type energy_storage_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<energy_storage_type>;
};

/** on_conflict condition type for table "energy_storage_type" */
export type energy_storage_type_on_conflict = {
  constraint: energy_storage_type_constraint;
  update_columns?: Array<energy_storage_type_update_column>;
  where?: InputMaybe<energy_storage_type_bool_exp>;
};

/** Ordering options when selecting data from "energy_storage_type". */
export type energy_storage_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: energy_storage_type */
export type energy_storage_type_pk_columns_input = {
  id: Scalars['String'];
};

export { energy_storage_type_select_column };

/** input type for updating data in table "energy_storage_type" */
export type energy_storage_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "energy_storage_type" */
export type energy_storage_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: energy_storage_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type energy_storage_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { energy_storage_type_update_column };

export type energy_storage_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<energy_storage_type_set_input>;
  /** filter the rows which have to be updated */
  where: energy_storage_type_bool_exp;
};

export { energy_storage_update_column };

export type energy_storage_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<energy_storage_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<energy_storage_set_input>;
  /** filter the rows which have to be updated */
  where: energy_storage_bool_exp;
};

/** aggregate var_pop on columns */
export type energy_storage_var_pop_fields = {
  annual_heat_loss?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recoverable_heat_loss?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "energy_storage" */
export type energy_storage_var_pop_order_by = {
  annual_heat_loss?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  recoverable_heat_loss?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type energy_storage_var_samp_fields = {
  annual_heat_loss?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recoverable_heat_loss?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "energy_storage" */
export type energy_storage_var_samp_order_by = {
  annual_heat_loss?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  recoverable_heat_loss?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type energy_storage_variance_fields = {
  annual_heat_loss?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Float']>;
  construction_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recoverable_heat_loss?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "energy_storage" */
export type energy_storage_variance_order_by = {
  annual_heat_loss?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  construction_year?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  recoverable_heat_loss?: InputMaybe<order_by>;
};

/** columns and relationships of "energy_system" */
export type energy_system = {
  /** An object relationship */
  building_model: building_model;
  building_model_id: Scalars['Int'];
  data_source_type_id?: Maybe<data_source_type_enum>;
  /** An array relationship */
  energy_system_consumer_routes: Array<energy_system_consumer_route>;
  /** An aggregate relationship */
  energy_system_consumer_routes_aggregate: energy_system_consumer_route_aggregate;
  /** An array relationship */
  energy_system_producer_routes: Array<energy_system_producer_route>;
  /** An aggregate relationship */
  energy_system_producer_routes_aggregate: energy_system_producer_route_aggregate;
  energy_system_type_id: energy_system_type_enum;
  id: Scalars['Int'];
  /** An object relationship */
  sub_building?: Maybe<sub_building>;
  sub_building_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "energy_system" */
export type energy_systemenergy_system_consumer_routesArgs = {
  distinct_on?: InputMaybe<Array<energy_system_consumer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_consumer_route_order_by>>;
  where?: InputMaybe<energy_system_consumer_route_bool_exp>;
};

/** columns and relationships of "energy_system" */
export type energy_systemenergy_system_consumer_routes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_system_consumer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_consumer_route_order_by>>;
  where?: InputMaybe<energy_system_consumer_route_bool_exp>;
};

/** columns and relationships of "energy_system" */
export type energy_systemenergy_system_producer_routesArgs = {
  distinct_on?: InputMaybe<Array<energy_system_producer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_producer_route_order_by>>;
  where?: InputMaybe<energy_system_producer_route_bool_exp>;
};

/** columns and relationships of "energy_system" */
export type energy_systemenergy_system_producer_routes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_system_producer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_producer_route_order_by>>;
  where?: InputMaybe<energy_system_producer_route_bool_exp>;
};

/** aggregated selection of "energy_system" */
export type energy_system_aggregate = {
  aggregate?: Maybe<energy_system_aggregate_fields>;
  nodes: Array<energy_system>;
};

export type energy_system_aggregate_bool_exp = {
  count?: InputMaybe<energy_system_aggregate_bool_exp_count>;
};

export type energy_system_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<energy_system_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<energy_system_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "energy_system" */
export type energy_system_aggregate_fields = {
  avg?: Maybe<energy_system_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<energy_system_max_fields>;
  min?: Maybe<energy_system_min_fields>;
  stddev?: Maybe<energy_system_stddev_fields>;
  stddev_pop?: Maybe<energy_system_stddev_pop_fields>;
  stddev_samp?: Maybe<energy_system_stddev_samp_fields>;
  sum?: Maybe<energy_system_sum_fields>;
  var_pop?: Maybe<energy_system_var_pop_fields>;
  var_samp?: Maybe<energy_system_var_samp_fields>;
  variance?: Maybe<energy_system_variance_fields>;
};

/** aggregate fields of "energy_system" */
export type energy_system_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<energy_system_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "energy_system" */
export type energy_system_aggregate_order_by = {
  avg?: InputMaybe<energy_system_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<energy_system_max_order_by>;
  min?: InputMaybe<energy_system_min_order_by>;
  stddev?: InputMaybe<energy_system_stddev_order_by>;
  stddev_pop?: InputMaybe<energy_system_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<energy_system_stddev_samp_order_by>;
  sum?: InputMaybe<energy_system_sum_order_by>;
  var_pop?: InputMaybe<energy_system_var_pop_order_by>;
  var_samp?: InputMaybe<energy_system_var_samp_order_by>;
  variance?: InputMaybe<energy_system_variance_order_by>;
};

/** input type for inserting array relation for remote table "energy_system" */
export type energy_system_arr_rel_insert_input = {
  data: Array<energy_system_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<energy_system_on_conflict>;
};

/** aggregate avg on columns */
export type energy_system_avg_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "energy_system" */
export type energy_system_avg_order_by = {
  building_model_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "energy_system". All fields are combined with a logical 'AND'. */
export type energy_system_bool_exp = {
  _and?: InputMaybe<Array<energy_system_bool_exp>>;
  _not?: InputMaybe<energy_system_bool_exp>;
  _or?: InputMaybe<Array<energy_system_bool_exp>>;
  building_model?: InputMaybe<building_model_bool_exp>;
  building_model_id?: InputMaybe<Int_comparison_exp>;
  data_source_type_id?: InputMaybe<data_source_type_enum_comparison_exp>;
  energy_system_consumer_routes?: InputMaybe<energy_system_consumer_route_bool_exp>;
  energy_system_consumer_routes_aggregate?: InputMaybe<energy_system_consumer_route_aggregate_bool_exp>;
  energy_system_producer_routes?: InputMaybe<energy_system_producer_route_bool_exp>;
  energy_system_producer_routes_aggregate?: InputMaybe<energy_system_producer_route_aggregate_bool_exp>;
  energy_system_type_id?: InputMaybe<energy_system_type_enum_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  sub_building?: InputMaybe<sub_building_bool_exp>;
  sub_building_id?: InputMaybe<Int_comparison_exp>;
};

export { energy_system_constraint };

/** columns and relationships of "energy_system_consumer_route" */
export type energy_system_consumer_route = {
  /** An object relationship */
  emission_certificate?: Maybe<emission_certificate>;
  emission_certificate_id?: Maybe<Scalars['Int']>;
  emission_factor_origin_id: emission_factor_origin_enum;
  /** An object relationship */
  energy_consumer: energy_consumer;
  energy_consumer_id: Scalars['Int'];
  /** An object relationship */
  energy_distribution?: Maybe<energy_distribution>;
  energy_distribution_id?: Maybe<Scalars['Int']>;
  energy_effective?: Maybe<Scalars['Float']>;
  energy_final: Scalars['Float'];
  /** An array relationship */
  energy_paths: Array<energy_path>;
  /** An aggregate relationship */
  energy_paths_aggregate: energy_path_aggregate;
  energy_primary?: Maybe<Scalars['Float']>;
  energy_source_type_id: energy_source_type_enum;
  /** An object relationship */
  energy_storage?: Maybe<energy_storage>;
  energy_storage_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  energy_system: energy_system;
  energy_system_id: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  primary_energy_factor_emission_certificate?: Maybe<emission_certificate>;
  primary_energy_factor_emission_certificate_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "energy_system_consumer_route" */
export type energy_system_consumer_routeenergy_pathsArgs = {
  distinct_on?: InputMaybe<Array<energy_path_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_path_order_by>>;
  where?: InputMaybe<energy_path_bool_exp>;
};

/** columns and relationships of "energy_system_consumer_route" */
export type energy_system_consumer_routeenergy_paths_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_path_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_path_order_by>>;
  where?: InputMaybe<energy_path_bool_exp>;
};

/** aggregated selection of "energy_system_consumer_route" */
export type energy_system_consumer_route_aggregate = {
  aggregate?: Maybe<energy_system_consumer_route_aggregate_fields>;
  nodes: Array<energy_system_consumer_route>;
};

export type energy_system_consumer_route_aggregate_bool_exp = {
  count?: InputMaybe<energy_system_consumer_route_aggregate_bool_exp_count>;
};

export type energy_system_consumer_route_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<energy_system_consumer_route_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<energy_system_consumer_route_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "energy_system_consumer_route" */
export type energy_system_consumer_route_aggregate_fields = {
  avg?: Maybe<energy_system_consumer_route_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<energy_system_consumer_route_max_fields>;
  min?: Maybe<energy_system_consumer_route_min_fields>;
  stddev?: Maybe<energy_system_consumer_route_stddev_fields>;
  stddev_pop?: Maybe<energy_system_consumer_route_stddev_pop_fields>;
  stddev_samp?: Maybe<energy_system_consumer_route_stddev_samp_fields>;
  sum?: Maybe<energy_system_consumer_route_sum_fields>;
  var_pop?: Maybe<energy_system_consumer_route_var_pop_fields>;
  var_samp?: Maybe<energy_system_consumer_route_var_samp_fields>;
  variance?: Maybe<energy_system_consumer_route_variance_fields>;
};

/** aggregate fields of "energy_system_consumer_route" */
export type energy_system_consumer_route_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<energy_system_consumer_route_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "energy_system_consumer_route" */
export type energy_system_consumer_route_aggregate_order_by = {
  avg?: InputMaybe<energy_system_consumer_route_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<energy_system_consumer_route_max_order_by>;
  min?: InputMaybe<energy_system_consumer_route_min_order_by>;
  stddev?: InputMaybe<energy_system_consumer_route_stddev_order_by>;
  stddev_pop?: InputMaybe<energy_system_consumer_route_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<energy_system_consumer_route_stddev_samp_order_by>;
  sum?: InputMaybe<energy_system_consumer_route_sum_order_by>;
  var_pop?: InputMaybe<energy_system_consumer_route_var_pop_order_by>;
  var_samp?: InputMaybe<energy_system_consumer_route_var_samp_order_by>;
  variance?: InputMaybe<energy_system_consumer_route_variance_order_by>;
};

/** input type for inserting array relation for remote table "energy_system_consumer_route" */
export type energy_system_consumer_route_arr_rel_insert_input = {
  data: Array<energy_system_consumer_route_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<energy_system_consumer_route_on_conflict>;
};

/** aggregate avg on columns */
export type energy_system_consumer_route_avg_fields = {
  emission_certificate_id?: Maybe<Scalars['Float']>;
  energy_consumer_id?: Maybe<Scalars['Float']>;
  energy_distribution_id?: Maybe<Scalars['Float']>;
  energy_effective?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  energy_storage_id?: Maybe<Scalars['Float']>;
  energy_system_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  primary_energy_factor_emission_certificate_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "energy_system_consumer_route" */
export type energy_system_consumer_route_avg_order_by = {
  emission_certificate_id?: InputMaybe<order_by>;
  energy_consumer_id?: InputMaybe<order_by>;
  energy_distribution_id?: InputMaybe<order_by>;
  energy_effective?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_storage_id?: InputMaybe<order_by>;
  energy_system_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  primary_energy_factor_emission_certificate_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "energy_system_consumer_route". All fields are combined with a logical 'AND'. */
export type energy_system_consumer_route_bool_exp = {
  _and?: InputMaybe<Array<energy_system_consumer_route_bool_exp>>;
  _not?: InputMaybe<energy_system_consumer_route_bool_exp>;
  _or?: InputMaybe<Array<energy_system_consumer_route_bool_exp>>;
  emission_certificate?: InputMaybe<emission_certificate_bool_exp>;
  emission_certificate_id?: InputMaybe<Int_comparison_exp>;
  emission_factor_origin_id?: InputMaybe<emission_factor_origin_enum_comparison_exp>;
  energy_consumer?: InputMaybe<energy_consumer_bool_exp>;
  energy_consumer_id?: InputMaybe<Int_comparison_exp>;
  energy_distribution?: InputMaybe<energy_distribution_bool_exp>;
  energy_distribution_id?: InputMaybe<Int_comparison_exp>;
  energy_effective?: InputMaybe<Float_comparison_exp>;
  energy_final?: InputMaybe<Float_comparison_exp>;
  energy_paths?: InputMaybe<energy_path_bool_exp>;
  energy_paths_aggregate?: InputMaybe<energy_path_aggregate_bool_exp>;
  energy_primary?: InputMaybe<Float_comparison_exp>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum_comparison_exp>;
  energy_storage?: InputMaybe<energy_storage_bool_exp>;
  energy_storage_id?: InputMaybe<Int_comparison_exp>;
  energy_system?: InputMaybe<energy_system_bool_exp>;
  energy_system_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  primary_energy_factor_emission_certificate?: InputMaybe<emission_certificate_bool_exp>;
  primary_energy_factor_emission_certificate_id?: InputMaybe<Int_comparison_exp>;
};

export { energy_system_consumer_route_constraint };

/** input type for incrementing numeric columns in table "energy_system_consumer_route" */
export type energy_system_consumer_route_inc_input = {
  emission_certificate_id?: InputMaybe<Scalars['Int']>;
  energy_consumer_id?: InputMaybe<Scalars['Int']>;
  energy_distribution_id?: InputMaybe<Scalars['Int']>;
  energy_effective?: InputMaybe<Scalars['Float']>;
  energy_final?: InputMaybe<Scalars['Float']>;
  energy_primary?: InputMaybe<Scalars['Float']>;
  energy_storage_id?: InputMaybe<Scalars['Int']>;
  energy_system_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  primary_energy_factor_emission_certificate_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "energy_system_consumer_route" */
export type energy_system_consumer_route_insert_input = {
  emission_certificate?: InputMaybe<emission_certificate_obj_rel_insert_input>;
  emission_certificate_id?: InputMaybe<Scalars['Int']>;
  emission_factor_origin_id?: InputMaybe<emission_factor_origin_enum>;
  energy_consumer?: InputMaybe<energy_consumer_obj_rel_insert_input>;
  energy_consumer_id?: InputMaybe<Scalars['Int']>;
  energy_distribution?: InputMaybe<energy_distribution_obj_rel_insert_input>;
  energy_distribution_id?: InputMaybe<Scalars['Int']>;
  energy_effective?: InputMaybe<Scalars['Float']>;
  energy_final?: InputMaybe<Scalars['Float']>;
  energy_paths?: InputMaybe<energy_path_arr_rel_insert_input>;
  energy_primary?: InputMaybe<Scalars['Float']>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum>;
  energy_storage?: InputMaybe<energy_storage_obj_rel_insert_input>;
  energy_storage_id?: InputMaybe<Scalars['Int']>;
  energy_system?: InputMaybe<energy_system_obj_rel_insert_input>;
  energy_system_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  primary_energy_factor_emission_certificate?: InputMaybe<emission_certificate_obj_rel_insert_input>;
  primary_energy_factor_emission_certificate_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type energy_system_consumer_route_max_fields = {
  emission_certificate_id?: Maybe<Scalars['Int']>;
  energy_consumer_id?: Maybe<Scalars['Int']>;
  energy_distribution_id?: Maybe<Scalars['Int']>;
  energy_effective?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  energy_storage_id?: Maybe<Scalars['Int']>;
  energy_system_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  primary_energy_factor_emission_certificate_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "energy_system_consumer_route" */
export type energy_system_consumer_route_max_order_by = {
  emission_certificate_id?: InputMaybe<order_by>;
  energy_consumer_id?: InputMaybe<order_by>;
  energy_distribution_id?: InputMaybe<order_by>;
  energy_effective?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_storage_id?: InputMaybe<order_by>;
  energy_system_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  primary_energy_factor_emission_certificate_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type energy_system_consumer_route_min_fields = {
  emission_certificate_id?: Maybe<Scalars['Int']>;
  energy_consumer_id?: Maybe<Scalars['Int']>;
  energy_distribution_id?: Maybe<Scalars['Int']>;
  energy_effective?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  energy_storage_id?: Maybe<Scalars['Int']>;
  energy_system_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  primary_energy_factor_emission_certificate_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "energy_system_consumer_route" */
export type energy_system_consumer_route_min_order_by = {
  emission_certificate_id?: InputMaybe<order_by>;
  energy_consumer_id?: InputMaybe<order_by>;
  energy_distribution_id?: InputMaybe<order_by>;
  energy_effective?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_storage_id?: InputMaybe<order_by>;
  energy_system_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  primary_energy_factor_emission_certificate_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "energy_system_consumer_route" */
export type energy_system_consumer_route_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<energy_system_consumer_route>;
};

/** input type for inserting object relation for remote table "energy_system_consumer_route" */
export type energy_system_consumer_route_obj_rel_insert_input = {
  data: energy_system_consumer_route_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<energy_system_consumer_route_on_conflict>;
};

/** on_conflict condition type for table "energy_system_consumer_route" */
export type energy_system_consumer_route_on_conflict = {
  constraint: energy_system_consumer_route_constraint;
  update_columns?: Array<energy_system_consumer_route_update_column>;
  where?: InputMaybe<energy_system_consumer_route_bool_exp>;
};

/** Ordering options when selecting data from "energy_system_consumer_route". */
export type energy_system_consumer_route_order_by = {
  emission_certificate?: InputMaybe<emission_certificate_order_by>;
  emission_certificate_id?: InputMaybe<order_by>;
  emission_factor_origin_id?: InputMaybe<order_by>;
  energy_consumer?: InputMaybe<energy_consumer_order_by>;
  energy_consumer_id?: InputMaybe<order_by>;
  energy_distribution?: InputMaybe<energy_distribution_order_by>;
  energy_distribution_id?: InputMaybe<order_by>;
  energy_effective?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_paths_aggregate?: InputMaybe<energy_path_aggregate_order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_source_type_id?: InputMaybe<order_by>;
  energy_storage?: InputMaybe<energy_storage_order_by>;
  energy_storage_id?: InputMaybe<order_by>;
  energy_system?: InputMaybe<energy_system_order_by>;
  energy_system_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  primary_energy_factor_emission_certificate?: InputMaybe<emission_certificate_order_by>;
  primary_energy_factor_emission_certificate_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: energy_system_consumer_route */
export type energy_system_consumer_route_pk_columns_input = {
  id: Scalars['Int'];
};

export { energy_system_consumer_route_select_column };

/** input type for updating data in table "energy_system_consumer_route" */
export type energy_system_consumer_route_set_input = {
  emission_certificate_id?: InputMaybe<Scalars['Int']>;
  emission_factor_origin_id?: InputMaybe<emission_factor_origin_enum>;
  energy_consumer_id?: InputMaybe<Scalars['Int']>;
  energy_distribution_id?: InputMaybe<Scalars['Int']>;
  energy_effective?: InputMaybe<Scalars['Float']>;
  energy_final?: InputMaybe<Scalars['Float']>;
  energy_primary?: InputMaybe<Scalars['Float']>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum>;
  energy_storage_id?: InputMaybe<Scalars['Int']>;
  energy_system_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  primary_energy_factor_emission_certificate_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type energy_system_consumer_route_stddev_fields = {
  emission_certificate_id?: Maybe<Scalars['Float']>;
  energy_consumer_id?: Maybe<Scalars['Float']>;
  energy_distribution_id?: Maybe<Scalars['Float']>;
  energy_effective?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  energy_storage_id?: Maybe<Scalars['Float']>;
  energy_system_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  primary_energy_factor_emission_certificate_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "energy_system_consumer_route" */
export type energy_system_consumer_route_stddev_order_by = {
  emission_certificate_id?: InputMaybe<order_by>;
  energy_consumer_id?: InputMaybe<order_by>;
  energy_distribution_id?: InputMaybe<order_by>;
  energy_effective?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_storage_id?: InputMaybe<order_by>;
  energy_system_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  primary_energy_factor_emission_certificate_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type energy_system_consumer_route_stddev_pop_fields = {
  emission_certificate_id?: Maybe<Scalars['Float']>;
  energy_consumer_id?: Maybe<Scalars['Float']>;
  energy_distribution_id?: Maybe<Scalars['Float']>;
  energy_effective?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  energy_storage_id?: Maybe<Scalars['Float']>;
  energy_system_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  primary_energy_factor_emission_certificate_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "energy_system_consumer_route" */
export type energy_system_consumer_route_stddev_pop_order_by = {
  emission_certificate_id?: InputMaybe<order_by>;
  energy_consumer_id?: InputMaybe<order_by>;
  energy_distribution_id?: InputMaybe<order_by>;
  energy_effective?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_storage_id?: InputMaybe<order_by>;
  energy_system_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  primary_energy_factor_emission_certificate_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type energy_system_consumer_route_stddev_samp_fields = {
  emission_certificate_id?: Maybe<Scalars['Float']>;
  energy_consumer_id?: Maybe<Scalars['Float']>;
  energy_distribution_id?: Maybe<Scalars['Float']>;
  energy_effective?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  energy_storage_id?: Maybe<Scalars['Float']>;
  energy_system_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  primary_energy_factor_emission_certificate_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "energy_system_consumer_route" */
export type energy_system_consumer_route_stddev_samp_order_by = {
  emission_certificate_id?: InputMaybe<order_by>;
  energy_consumer_id?: InputMaybe<order_by>;
  energy_distribution_id?: InputMaybe<order_by>;
  energy_effective?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_storage_id?: InputMaybe<order_by>;
  energy_system_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  primary_energy_factor_emission_certificate_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "energy_system_consumer_route" */
export type energy_system_consumer_route_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: energy_system_consumer_route_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type energy_system_consumer_route_stream_cursor_value_input = {
  emission_certificate_id?: InputMaybe<Scalars['Int']>;
  emission_factor_origin_id?: InputMaybe<emission_factor_origin_enum>;
  energy_consumer_id?: InputMaybe<Scalars['Int']>;
  energy_distribution_id?: InputMaybe<Scalars['Int']>;
  energy_effective?: InputMaybe<Scalars['Float']>;
  energy_final?: InputMaybe<Scalars['Float']>;
  energy_primary?: InputMaybe<Scalars['Float']>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum>;
  energy_storage_id?: InputMaybe<Scalars['Int']>;
  energy_system_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  primary_energy_factor_emission_certificate_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type energy_system_consumer_route_sum_fields = {
  emission_certificate_id?: Maybe<Scalars['Int']>;
  energy_consumer_id?: Maybe<Scalars['Int']>;
  energy_distribution_id?: Maybe<Scalars['Int']>;
  energy_effective?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  energy_storage_id?: Maybe<Scalars['Int']>;
  energy_system_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  primary_energy_factor_emission_certificate_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "energy_system_consumer_route" */
export type energy_system_consumer_route_sum_order_by = {
  emission_certificate_id?: InputMaybe<order_by>;
  energy_consumer_id?: InputMaybe<order_by>;
  energy_distribution_id?: InputMaybe<order_by>;
  energy_effective?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_storage_id?: InputMaybe<order_by>;
  energy_system_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  primary_energy_factor_emission_certificate_id?: InputMaybe<order_by>;
};

export { energy_system_consumer_route_update_column };

export type energy_system_consumer_route_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<energy_system_consumer_route_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<energy_system_consumer_route_set_input>;
  /** filter the rows which have to be updated */
  where: energy_system_consumer_route_bool_exp;
};

/** aggregate var_pop on columns */
export type energy_system_consumer_route_var_pop_fields = {
  emission_certificate_id?: Maybe<Scalars['Float']>;
  energy_consumer_id?: Maybe<Scalars['Float']>;
  energy_distribution_id?: Maybe<Scalars['Float']>;
  energy_effective?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  energy_storage_id?: Maybe<Scalars['Float']>;
  energy_system_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  primary_energy_factor_emission_certificate_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "energy_system_consumer_route" */
export type energy_system_consumer_route_var_pop_order_by = {
  emission_certificate_id?: InputMaybe<order_by>;
  energy_consumer_id?: InputMaybe<order_by>;
  energy_distribution_id?: InputMaybe<order_by>;
  energy_effective?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_storage_id?: InputMaybe<order_by>;
  energy_system_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  primary_energy_factor_emission_certificate_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type energy_system_consumer_route_var_samp_fields = {
  emission_certificate_id?: Maybe<Scalars['Float']>;
  energy_consumer_id?: Maybe<Scalars['Float']>;
  energy_distribution_id?: Maybe<Scalars['Float']>;
  energy_effective?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  energy_storage_id?: Maybe<Scalars['Float']>;
  energy_system_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  primary_energy_factor_emission_certificate_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "energy_system_consumer_route" */
export type energy_system_consumer_route_var_samp_order_by = {
  emission_certificate_id?: InputMaybe<order_by>;
  energy_consumer_id?: InputMaybe<order_by>;
  energy_distribution_id?: InputMaybe<order_by>;
  energy_effective?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_storage_id?: InputMaybe<order_by>;
  energy_system_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  primary_energy_factor_emission_certificate_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type energy_system_consumer_route_variance_fields = {
  emission_certificate_id?: Maybe<Scalars['Float']>;
  energy_consumer_id?: Maybe<Scalars['Float']>;
  energy_distribution_id?: Maybe<Scalars['Float']>;
  energy_effective?: Maybe<Scalars['Float']>;
  energy_final?: Maybe<Scalars['Float']>;
  energy_primary?: Maybe<Scalars['Float']>;
  energy_storage_id?: Maybe<Scalars['Float']>;
  energy_system_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  primary_energy_factor_emission_certificate_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "energy_system_consumer_route" */
export type energy_system_consumer_route_variance_order_by = {
  emission_certificate_id?: InputMaybe<order_by>;
  energy_consumer_id?: InputMaybe<order_by>;
  energy_distribution_id?: InputMaybe<order_by>;
  energy_effective?: InputMaybe<order_by>;
  energy_final?: InputMaybe<order_by>;
  energy_primary?: InputMaybe<order_by>;
  energy_storage_id?: InputMaybe<order_by>;
  energy_system_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  primary_energy_factor_emission_certificate_id?: InputMaybe<order_by>;
};

/** input type for incrementing numeric columns in table "energy_system" */
export type energy_system_inc_input = {
  building_model_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "energy_system" */
export type energy_system_insert_input = {
  building_model?: InputMaybe<building_model_obj_rel_insert_input>;
  building_model_id?: InputMaybe<Scalars['Int']>;
  data_source_type_id?: InputMaybe<data_source_type_enum>;
  energy_system_consumer_routes?: InputMaybe<energy_system_consumer_route_arr_rel_insert_input>;
  energy_system_producer_routes?: InputMaybe<energy_system_producer_route_arr_rel_insert_input>;
  energy_system_type_id?: InputMaybe<energy_system_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  sub_building?: InputMaybe<sub_building_obj_rel_insert_input>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type energy_system_max_fields = {
  building_model_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "energy_system" */
export type energy_system_max_order_by = {
  building_model_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type energy_system_min_fields = {
  building_model_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "energy_system" */
export type energy_system_min_order_by = {
  building_model_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "energy_system" */
export type energy_system_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<energy_system>;
};

/** input type for inserting object relation for remote table "energy_system" */
export type energy_system_obj_rel_insert_input = {
  data: energy_system_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<energy_system_on_conflict>;
};

/** on_conflict condition type for table "energy_system" */
export type energy_system_on_conflict = {
  constraint: energy_system_constraint;
  update_columns?: Array<energy_system_update_column>;
  where?: InputMaybe<energy_system_bool_exp>;
};

/** Ordering options when selecting data from "energy_system". */
export type energy_system_order_by = {
  building_model?: InputMaybe<building_model_order_by>;
  building_model_id?: InputMaybe<order_by>;
  data_source_type_id?: InputMaybe<order_by>;
  energy_system_consumer_routes_aggregate?: InputMaybe<energy_system_consumer_route_aggregate_order_by>;
  energy_system_producer_routes_aggregate?: InputMaybe<energy_system_producer_route_aggregate_order_by>;
  energy_system_type_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building?: InputMaybe<sub_building_order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: energy_system */
export type energy_system_pk_columns_input = {
  id: Scalars['Int'];
};

/** columns and relationships of "energy_system_producer_route" */
export type energy_system_producer_route = {
  energy_effective?: Maybe<Scalars['Float']>;
  energy_produced: Scalars['Float'];
  /** An object relationship */
  energy_producer: energy_producer;
  energy_producer_id: Scalars['Int'];
  energy_source_type_id: energy_source_type_enum;
  /** An object relationship */
  energy_system: energy_system;
  energy_system_id: Scalars['Int'];
  id: Scalars['Int'];
};

/** aggregated selection of "energy_system_producer_route" */
export type energy_system_producer_route_aggregate = {
  aggregate?: Maybe<energy_system_producer_route_aggregate_fields>;
  nodes: Array<energy_system_producer_route>;
};

export type energy_system_producer_route_aggregate_bool_exp = {
  count?: InputMaybe<energy_system_producer_route_aggregate_bool_exp_count>;
};

export type energy_system_producer_route_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<energy_system_producer_route_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<energy_system_producer_route_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "energy_system_producer_route" */
export type energy_system_producer_route_aggregate_fields = {
  avg?: Maybe<energy_system_producer_route_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<energy_system_producer_route_max_fields>;
  min?: Maybe<energy_system_producer_route_min_fields>;
  stddev?: Maybe<energy_system_producer_route_stddev_fields>;
  stddev_pop?: Maybe<energy_system_producer_route_stddev_pop_fields>;
  stddev_samp?: Maybe<energy_system_producer_route_stddev_samp_fields>;
  sum?: Maybe<energy_system_producer_route_sum_fields>;
  var_pop?: Maybe<energy_system_producer_route_var_pop_fields>;
  var_samp?: Maybe<energy_system_producer_route_var_samp_fields>;
  variance?: Maybe<energy_system_producer_route_variance_fields>;
};

/** aggregate fields of "energy_system_producer_route" */
export type energy_system_producer_route_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<energy_system_producer_route_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "energy_system_producer_route" */
export type energy_system_producer_route_aggregate_order_by = {
  avg?: InputMaybe<energy_system_producer_route_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<energy_system_producer_route_max_order_by>;
  min?: InputMaybe<energy_system_producer_route_min_order_by>;
  stddev?: InputMaybe<energy_system_producer_route_stddev_order_by>;
  stddev_pop?: InputMaybe<energy_system_producer_route_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<energy_system_producer_route_stddev_samp_order_by>;
  sum?: InputMaybe<energy_system_producer_route_sum_order_by>;
  var_pop?: InputMaybe<energy_system_producer_route_var_pop_order_by>;
  var_samp?: InputMaybe<energy_system_producer_route_var_samp_order_by>;
  variance?: InputMaybe<energy_system_producer_route_variance_order_by>;
};

/** input type for inserting array relation for remote table "energy_system_producer_route" */
export type energy_system_producer_route_arr_rel_insert_input = {
  data: Array<energy_system_producer_route_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<energy_system_producer_route_on_conflict>;
};

/** aggregate avg on columns */
export type energy_system_producer_route_avg_fields = {
  energy_effective?: Maybe<Scalars['Float']>;
  energy_produced?: Maybe<Scalars['Float']>;
  energy_producer_id?: Maybe<Scalars['Float']>;
  energy_system_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "energy_system_producer_route" */
export type energy_system_producer_route_avg_order_by = {
  energy_effective?: InputMaybe<order_by>;
  energy_produced?: InputMaybe<order_by>;
  energy_producer_id?: InputMaybe<order_by>;
  energy_system_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "energy_system_producer_route". All fields are combined with a logical 'AND'. */
export type energy_system_producer_route_bool_exp = {
  _and?: InputMaybe<Array<energy_system_producer_route_bool_exp>>;
  _not?: InputMaybe<energy_system_producer_route_bool_exp>;
  _or?: InputMaybe<Array<energy_system_producer_route_bool_exp>>;
  energy_effective?: InputMaybe<Float_comparison_exp>;
  energy_produced?: InputMaybe<Float_comparison_exp>;
  energy_producer?: InputMaybe<energy_producer_bool_exp>;
  energy_producer_id?: InputMaybe<Int_comparison_exp>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum_comparison_exp>;
  energy_system?: InputMaybe<energy_system_bool_exp>;
  energy_system_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
};

export { energy_system_producer_route_constraint };

/** input type for incrementing numeric columns in table "energy_system_producer_route" */
export type energy_system_producer_route_inc_input = {
  energy_effective?: InputMaybe<Scalars['Float']>;
  energy_produced?: InputMaybe<Scalars['Float']>;
  energy_producer_id?: InputMaybe<Scalars['Int']>;
  energy_system_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "energy_system_producer_route" */
export type energy_system_producer_route_insert_input = {
  energy_effective?: InputMaybe<Scalars['Float']>;
  energy_produced?: InputMaybe<Scalars['Float']>;
  energy_producer?: InputMaybe<energy_producer_obj_rel_insert_input>;
  energy_producer_id?: InputMaybe<Scalars['Int']>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum>;
  energy_system?: InputMaybe<energy_system_obj_rel_insert_input>;
  energy_system_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type energy_system_producer_route_max_fields = {
  energy_effective?: Maybe<Scalars['Float']>;
  energy_produced?: Maybe<Scalars['Float']>;
  energy_producer_id?: Maybe<Scalars['Int']>;
  energy_system_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "energy_system_producer_route" */
export type energy_system_producer_route_max_order_by = {
  energy_effective?: InputMaybe<order_by>;
  energy_produced?: InputMaybe<order_by>;
  energy_producer_id?: InputMaybe<order_by>;
  energy_system_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type energy_system_producer_route_min_fields = {
  energy_effective?: Maybe<Scalars['Float']>;
  energy_produced?: Maybe<Scalars['Float']>;
  energy_producer_id?: Maybe<Scalars['Int']>;
  energy_system_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "energy_system_producer_route" */
export type energy_system_producer_route_min_order_by = {
  energy_effective?: InputMaybe<order_by>;
  energy_produced?: InputMaybe<order_by>;
  energy_producer_id?: InputMaybe<order_by>;
  energy_system_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "energy_system_producer_route" */
export type energy_system_producer_route_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<energy_system_producer_route>;
};

/** on_conflict condition type for table "energy_system_producer_route" */
export type energy_system_producer_route_on_conflict = {
  constraint: energy_system_producer_route_constraint;
  update_columns?: Array<energy_system_producer_route_update_column>;
  where?: InputMaybe<energy_system_producer_route_bool_exp>;
};

/** Ordering options when selecting data from "energy_system_producer_route". */
export type energy_system_producer_route_order_by = {
  energy_effective?: InputMaybe<order_by>;
  energy_produced?: InputMaybe<order_by>;
  energy_producer?: InputMaybe<energy_producer_order_by>;
  energy_producer_id?: InputMaybe<order_by>;
  energy_source_type_id?: InputMaybe<order_by>;
  energy_system?: InputMaybe<energy_system_order_by>;
  energy_system_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: energy_system_producer_route */
export type energy_system_producer_route_pk_columns_input = {
  id: Scalars['Int'];
};

export { energy_system_producer_route_select_column };

/** input type for updating data in table "energy_system_producer_route" */
export type energy_system_producer_route_set_input = {
  energy_effective?: InputMaybe<Scalars['Float']>;
  energy_produced?: InputMaybe<Scalars['Float']>;
  energy_producer_id?: InputMaybe<Scalars['Int']>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum>;
  energy_system_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type energy_system_producer_route_stddev_fields = {
  energy_effective?: Maybe<Scalars['Float']>;
  energy_produced?: Maybe<Scalars['Float']>;
  energy_producer_id?: Maybe<Scalars['Float']>;
  energy_system_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "energy_system_producer_route" */
export type energy_system_producer_route_stddev_order_by = {
  energy_effective?: InputMaybe<order_by>;
  energy_produced?: InputMaybe<order_by>;
  energy_producer_id?: InputMaybe<order_by>;
  energy_system_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type energy_system_producer_route_stddev_pop_fields = {
  energy_effective?: Maybe<Scalars['Float']>;
  energy_produced?: Maybe<Scalars['Float']>;
  energy_producer_id?: Maybe<Scalars['Float']>;
  energy_system_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "energy_system_producer_route" */
export type energy_system_producer_route_stddev_pop_order_by = {
  energy_effective?: InputMaybe<order_by>;
  energy_produced?: InputMaybe<order_by>;
  energy_producer_id?: InputMaybe<order_by>;
  energy_system_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type energy_system_producer_route_stddev_samp_fields = {
  energy_effective?: Maybe<Scalars['Float']>;
  energy_produced?: Maybe<Scalars['Float']>;
  energy_producer_id?: Maybe<Scalars['Float']>;
  energy_system_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "energy_system_producer_route" */
export type energy_system_producer_route_stddev_samp_order_by = {
  energy_effective?: InputMaybe<order_by>;
  energy_produced?: InputMaybe<order_by>;
  energy_producer_id?: InputMaybe<order_by>;
  energy_system_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "energy_system_producer_route" */
export type energy_system_producer_route_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: energy_system_producer_route_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type energy_system_producer_route_stream_cursor_value_input = {
  energy_effective?: InputMaybe<Scalars['Float']>;
  energy_produced?: InputMaybe<Scalars['Float']>;
  energy_producer_id?: InputMaybe<Scalars['Int']>;
  energy_source_type_id?: InputMaybe<energy_source_type_enum>;
  energy_system_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type energy_system_producer_route_sum_fields = {
  energy_effective?: Maybe<Scalars['Float']>;
  energy_produced?: Maybe<Scalars['Float']>;
  energy_producer_id?: Maybe<Scalars['Int']>;
  energy_system_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "energy_system_producer_route" */
export type energy_system_producer_route_sum_order_by = {
  energy_effective?: InputMaybe<order_by>;
  energy_produced?: InputMaybe<order_by>;
  energy_producer_id?: InputMaybe<order_by>;
  energy_system_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

export { energy_system_producer_route_update_column };

export type energy_system_producer_route_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<energy_system_producer_route_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<energy_system_producer_route_set_input>;
  /** filter the rows which have to be updated */
  where: energy_system_producer_route_bool_exp;
};

/** aggregate var_pop on columns */
export type energy_system_producer_route_var_pop_fields = {
  energy_effective?: Maybe<Scalars['Float']>;
  energy_produced?: Maybe<Scalars['Float']>;
  energy_producer_id?: Maybe<Scalars['Float']>;
  energy_system_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "energy_system_producer_route" */
export type energy_system_producer_route_var_pop_order_by = {
  energy_effective?: InputMaybe<order_by>;
  energy_produced?: InputMaybe<order_by>;
  energy_producer_id?: InputMaybe<order_by>;
  energy_system_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type energy_system_producer_route_var_samp_fields = {
  energy_effective?: Maybe<Scalars['Float']>;
  energy_produced?: Maybe<Scalars['Float']>;
  energy_producer_id?: Maybe<Scalars['Float']>;
  energy_system_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "energy_system_producer_route" */
export type energy_system_producer_route_var_samp_order_by = {
  energy_effective?: InputMaybe<order_by>;
  energy_produced?: InputMaybe<order_by>;
  energy_producer_id?: InputMaybe<order_by>;
  energy_system_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type energy_system_producer_route_variance_fields = {
  energy_effective?: Maybe<Scalars['Float']>;
  energy_produced?: Maybe<Scalars['Float']>;
  energy_producer_id?: Maybe<Scalars['Float']>;
  energy_system_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "energy_system_producer_route" */
export type energy_system_producer_route_variance_order_by = {
  energy_effective?: InputMaybe<order_by>;
  energy_produced?: InputMaybe<order_by>;
  energy_producer_id?: InputMaybe<order_by>;
  energy_system_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

export { energy_system_select_column };

/** input type for updating data in table "energy_system" */
export type energy_system_set_input = {
  building_model_id?: InputMaybe<Scalars['Int']>;
  data_source_type_id?: InputMaybe<data_source_type_enum>;
  energy_system_type_id?: InputMaybe<energy_system_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type energy_system_stddev_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "energy_system" */
export type energy_system_stddev_order_by = {
  building_model_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type energy_system_stddev_pop_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "energy_system" */
export type energy_system_stddev_pop_order_by = {
  building_model_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type energy_system_stddev_samp_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "energy_system" */
export type energy_system_stddev_samp_order_by = {
  building_model_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "energy_system" */
export type energy_system_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: energy_system_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type energy_system_stream_cursor_value_input = {
  building_model_id?: InputMaybe<Scalars['Int']>;
  data_source_type_id?: InputMaybe<data_source_type_enum>;
  energy_system_type_id?: InputMaybe<energy_system_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type energy_system_sum_fields = {
  building_model_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "energy_system" */
export type energy_system_sum_order_by = {
  building_model_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** columns and relationships of "energy_system_type" */
export type energy_system_type = {
  id: Scalars['String'];
};

/** aggregated selection of "energy_system_type" */
export type energy_system_type_aggregate = {
  aggregate?: Maybe<energy_system_type_aggregate_fields>;
  nodes: Array<energy_system_type>;
};

/** aggregate fields of "energy_system_type" */
export type energy_system_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<energy_system_type_max_fields>;
  min?: Maybe<energy_system_type_min_fields>;
};

/** aggregate fields of "energy_system_type" */
export type energy_system_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<energy_system_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "energy_system_type". All fields are combined with a logical 'AND'. */
export type energy_system_type_bool_exp = {
  _and?: InputMaybe<Array<energy_system_type_bool_exp>>;
  _not?: InputMaybe<energy_system_type_bool_exp>;
  _or?: InputMaybe<Array<energy_system_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { energy_system_type_constraint };

export { energy_system_type_enum };

/** Boolean expression to compare columns of type "energy_system_type_enum". All fields are combined with logical 'AND'. */
export type energy_system_type_enum_comparison_exp = {
  _eq?: InputMaybe<energy_system_type_enum>;
  _in?: InputMaybe<Array<energy_system_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<energy_system_type_enum>;
  _nin?: InputMaybe<Array<energy_system_type_enum>>;
};

/** input type for inserting data into table "energy_system_type" */
export type energy_system_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type energy_system_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type energy_system_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "energy_system_type" */
export type energy_system_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<energy_system_type>;
};

/** on_conflict condition type for table "energy_system_type" */
export type energy_system_type_on_conflict = {
  constraint: energy_system_type_constraint;
  update_columns?: Array<energy_system_type_update_column>;
  where?: InputMaybe<energy_system_type_bool_exp>;
};

/** Ordering options when selecting data from "energy_system_type". */
export type energy_system_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: energy_system_type */
export type energy_system_type_pk_columns_input = {
  id: Scalars['String'];
};

export { energy_system_type_select_column };

/** input type for updating data in table "energy_system_type" */
export type energy_system_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "energy_system_type" */
export type energy_system_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: energy_system_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type energy_system_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { energy_system_type_update_column };

export type energy_system_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<energy_system_type_set_input>;
  /** filter the rows which have to be updated */
  where: energy_system_type_bool_exp;
};

export { energy_system_update_column };

export type energy_system_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<energy_system_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<energy_system_set_input>;
  /** filter the rows which have to be updated */
  where: energy_system_bool_exp;
};

/** aggregate var_pop on columns */
export type energy_system_var_pop_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "energy_system" */
export type energy_system_var_pop_order_by = {
  building_model_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type energy_system_var_samp_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "energy_system" */
export type energy_system_var_samp_order_by = {
  building_model_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type energy_system_variance_fields = {
  building_model_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "energy_system" */
export type energy_system_variance_order_by = {
  building_model_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** columns and relationships of "envelope" */
export type envelope = {
  area: Scalars['Float'];
  base_construction_lambda?: Maybe<Scalars['Float']>;
  base_construction_material_name?: Maybe<Scalars['String']>;
  base_construction_thickness?: Maybe<Scalars['Float']>;
  /** An object relationship */
  building_model: building_model;
  building_model_id: Scalars['Int'];
  cost_per_m2?: Maybe<Scalars['Float']>;
  data_source_type_id: data_source_type_enum;
  envelope_type_id: envelope_type_enum;
  id: Scalars['Int'];
  inclination?: Maybe<Scalars['Float']>;
  insulation_lambda?: Maybe<Scalars['Float']>;
  insulation_material_category_id?: Maybe<insulation_material_category_enum>;
  insulation_material_name?: Maybe<Scalars['String']>;
  insulation_method_id?: Maybe<insulation_method_enum>;
  insulation_thickness?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['Float']>;
  u_value?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "envelope" */
export type envelope_aggregate = {
  aggregate?: Maybe<envelope_aggregate_fields>;
  nodes: Array<envelope>;
};

export type envelope_aggregate_bool_exp = {
  count?: InputMaybe<envelope_aggregate_bool_exp_count>;
};

export type envelope_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<envelope_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<envelope_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "envelope" */
export type envelope_aggregate_fields = {
  avg?: Maybe<envelope_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<envelope_max_fields>;
  min?: Maybe<envelope_min_fields>;
  stddev?: Maybe<envelope_stddev_fields>;
  stddev_pop?: Maybe<envelope_stddev_pop_fields>;
  stddev_samp?: Maybe<envelope_stddev_samp_fields>;
  sum?: Maybe<envelope_sum_fields>;
  var_pop?: Maybe<envelope_var_pop_fields>;
  var_samp?: Maybe<envelope_var_samp_fields>;
  variance?: Maybe<envelope_variance_fields>;
};

/** aggregate fields of "envelope" */
export type envelope_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<envelope_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "envelope" */
export type envelope_aggregate_order_by = {
  avg?: InputMaybe<envelope_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<envelope_max_order_by>;
  min?: InputMaybe<envelope_min_order_by>;
  stddev?: InputMaybe<envelope_stddev_order_by>;
  stddev_pop?: InputMaybe<envelope_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<envelope_stddev_samp_order_by>;
  sum?: InputMaybe<envelope_sum_order_by>;
  var_pop?: InputMaybe<envelope_var_pop_order_by>;
  var_samp?: InputMaybe<envelope_var_samp_order_by>;
  variance?: InputMaybe<envelope_variance_order_by>;
};

/** input type for inserting array relation for remote table "envelope" */
export type envelope_arr_rel_insert_input = {
  data: Array<envelope_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<envelope_on_conflict>;
};

/** aggregate avg on columns */
export type envelope_avg_fields = {
  area?: Maybe<Scalars['Float']>;
  base_construction_lambda?: Maybe<Scalars['Float']>;
  base_construction_thickness?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Float']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inclination?: Maybe<Scalars['Float']>;
  insulation_lambda?: Maybe<Scalars['Float']>;
  insulation_thickness?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['Float']>;
  u_value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "envelope" */
export type envelope_avg_order_by = {
  area?: InputMaybe<order_by>;
  base_construction_lambda?: InputMaybe<order_by>;
  base_construction_thickness?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inclination?: InputMaybe<order_by>;
  insulation_lambda?: InputMaybe<order_by>;
  insulation_thickness?: InputMaybe<order_by>;
  orientation?: InputMaybe<order_by>;
  u_value?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "envelope". All fields are combined with a logical 'AND'. */
export type envelope_bool_exp = {
  _and?: InputMaybe<Array<envelope_bool_exp>>;
  _not?: InputMaybe<envelope_bool_exp>;
  _or?: InputMaybe<Array<envelope_bool_exp>>;
  area?: InputMaybe<Float_comparison_exp>;
  base_construction_lambda?: InputMaybe<Float_comparison_exp>;
  base_construction_material_name?: InputMaybe<String_comparison_exp>;
  base_construction_thickness?: InputMaybe<Float_comparison_exp>;
  building_model?: InputMaybe<building_model_bool_exp>;
  building_model_id?: InputMaybe<Int_comparison_exp>;
  cost_per_m2?: InputMaybe<Float_comparison_exp>;
  data_source_type_id?: InputMaybe<data_source_type_enum_comparison_exp>;
  envelope_type_id?: InputMaybe<envelope_type_enum_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  inclination?: InputMaybe<Float_comparison_exp>;
  insulation_lambda?: InputMaybe<Float_comparison_exp>;
  insulation_material_category_id?: InputMaybe<insulation_material_category_enum_comparison_exp>;
  insulation_material_name?: InputMaybe<String_comparison_exp>;
  insulation_method_id?: InputMaybe<insulation_method_enum_comparison_exp>;
  insulation_thickness?: InputMaybe<Float_comparison_exp>;
  orientation?: InputMaybe<Float_comparison_exp>;
  u_value?: InputMaybe<Float_comparison_exp>;
};

export { envelope_constraint };

/** input type for incrementing numeric columns in table "envelope" */
export type envelope_inc_input = {
  area?: InputMaybe<Scalars['Float']>;
  base_construction_lambda?: InputMaybe<Scalars['Float']>;
  base_construction_thickness?: InputMaybe<Scalars['Float']>;
  building_model_id?: InputMaybe<Scalars['Int']>;
  cost_per_m2?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  inclination?: InputMaybe<Scalars['Float']>;
  insulation_lambda?: InputMaybe<Scalars['Float']>;
  insulation_thickness?: InputMaybe<Scalars['Float']>;
  orientation?: InputMaybe<Scalars['Float']>;
  u_value?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "envelope" */
export type envelope_insert_input = {
  area?: InputMaybe<Scalars['Float']>;
  base_construction_lambda?: InputMaybe<Scalars['Float']>;
  base_construction_material_name?: InputMaybe<Scalars['String']>;
  base_construction_thickness?: InputMaybe<Scalars['Float']>;
  building_model?: InputMaybe<building_model_obj_rel_insert_input>;
  building_model_id?: InputMaybe<Scalars['Int']>;
  cost_per_m2?: InputMaybe<Scalars['Float']>;
  data_source_type_id?: InputMaybe<data_source_type_enum>;
  envelope_type_id?: InputMaybe<envelope_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  inclination?: InputMaybe<Scalars['Float']>;
  insulation_lambda?: InputMaybe<Scalars['Float']>;
  insulation_material_category_id?: InputMaybe<insulation_material_category_enum>;
  insulation_material_name?: InputMaybe<Scalars['String']>;
  insulation_method_id?: InputMaybe<insulation_method_enum>;
  insulation_thickness?: InputMaybe<Scalars['Float']>;
  orientation?: InputMaybe<Scalars['Float']>;
  u_value?: InputMaybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type envelope_max_fields = {
  area?: Maybe<Scalars['Float']>;
  base_construction_lambda?: Maybe<Scalars['Float']>;
  base_construction_material_name?: Maybe<Scalars['String']>;
  base_construction_thickness?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Int']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  inclination?: Maybe<Scalars['Float']>;
  insulation_lambda?: Maybe<Scalars['Float']>;
  insulation_material_name?: Maybe<Scalars['String']>;
  insulation_thickness?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['Float']>;
  u_value?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "envelope" */
export type envelope_max_order_by = {
  area?: InputMaybe<order_by>;
  base_construction_lambda?: InputMaybe<order_by>;
  base_construction_material_name?: InputMaybe<order_by>;
  base_construction_thickness?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inclination?: InputMaybe<order_by>;
  insulation_lambda?: InputMaybe<order_by>;
  insulation_material_name?: InputMaybe<order_by>;
  insulation_thickness?: InputMaybe<order_by>;
  orientation?: InputMaybe<order_by>;
  u_value?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type envelope_min_fields = {
  area?: Maybe<Scalars['Float']>;
  base_construction_lambda?: Maybe<Scalars['Float']>;
  base_construction_material_name?: Maybe<Scalars['String']>;
  base_construction_thickness?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Int']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  inclination?: Maybe<Scalars['Float']>;
  insulation_lambda?: Maybe<Scalars['Float']>;
  insulation_material_name?: Maybe<Scalars['String']>;
  insulation_thickness?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['Float']>;
  u_value?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "envelope" */
export type envelope_min_order_by = {
  area?: InputMaybe<order_by>;
  base_construction_lambda?: InputMaybe<order_by>;
  base_construction_material_name?: InputMaybe<order_by>;
  base_construction_thickness?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inclination?: InputMaybe<order_by>;
  insulation_lambda?: InputMaybe<order_by>;
  insulation_material_name?: InputMaybe<order_by>;
  insulation_thickness?: InputMaybe<order_by>;
  orientation?: InputMaybe<order_by>;
  u_value?: InputMaybe<order_by>;
};

/** response of any mutation on the table "envelope" */
export type envelope_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<envelope>;
};

/** input type for inserting object relation for remote table "envelope" */
export type envelope_obj_rel_insert_input = {
  data: envelope_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<envelope_on_conflict>;
};

/** on_conflict condition type for table "envelope" */
export type envelope_on_conflict = {
  constraint: envelope_constraint;
  update_columns?: Array<envelope_update_column>;
  where?: InputMaybe<envelope_bool_exp>;
};

/** Ordering options when selecting data from "envelope". */
export type envelope_order_by = {
  area?: InputMaybe<order_by>;
  base_construction_lambda?: InputMaybe<order_by>;
  base_construction_material_name?: InputMaybe<order_by>;
  base_construction_thickness?: InputMaybe<order_by>;
  building_model?: InputMaybe<building_model_order_by>;
  building_model_id?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  data_source_type_id?: InputMaybe<order_by>;
  envelope_type_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inclination?: InputMaybe<order_by>;
  insulation_lambda?: InputMaybe<order_by>;
  insulation_material_category_id?: InputMaybe<order_by>;
  insulation_material_name?: InputMaybe<order_by>;
  insulation_method_id?: InputMaybe<order_by>;
  insulation_thickness?: InputMaybe<order_by>;
  orientation?: InputMaybe<order_by>;
  u_value?: InputMaybe<order_by>;
};

/** primary key columns input for table: envelope */
export type envelope_pk_columns_input = {
  id: Scalars['Int'];
};

export { envelope_select_column };

/** input type for updating data in table "envelope" */
export type envelope_set_input = {
  area?: InputMaybe<Scalars['Float']>;
  base_construction_lambda?: InputMaybe<Scalars['Float']>;
  base_construction_material_name?: InputMaybe<Scalars['String']>;
  base_construction_thickness?: InputMaybe<Scalars['Float']>;
  building_model_id?: InputMaybe<Scalars['Int']>;
  cost_per_m2?: InputMaybe<Scalars['Float']>;
  data_source_type_id?: InputMaybe<data_source_type_enum>;
  envelope_type_id?: InputMaybe<envelope_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  inclination?: InputMaybe<Scalars['Float']>;
  insulation_lambda?: InputMaybe<Scalars['Float']>;
  insulation_material_category_id?: InputMaybe<insulation_material_category_enum>;
  insulation_material_name?: InputMaybe<Scalars['String']>;
  insulation_method_id?: InputMaybe<insulation_method_enum>;
  insulation_thickness?: InputMaybe<Scalars['Float']>;
  orientation?: InputMaybe<Scalars['Float']>;
  u_value?: InputMaybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type envelope_stddev_fields = {
  area?: Maybe<Scalars['Float']>;
  base_construction_lambda?: Maybe<Scalars['Float']>;
  base_construction_thickness?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Float']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inclination?: Maybe<Scalars['Float']>;
  insulation_lambda?: Maybe<Scalars['Float']>;
  insulation_thickness?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['Float']>;
  u_value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "envelope" */
export type envelope_stddev_order_by = {
  area?: InputMaybe<order_by>;
  base_construction_lambda?: InputMaybe<order_by>;
  base_construction_thickness?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inclination?: InputMaybe<order_by>;
  insulation_lambda?: InputMaybe<order_by>;
  insulation_thickness?: InputMaybe<order_by>;
  orientation?: InputMaybe<order_by>;
  u_value?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type envelope_stddev_pop_fields = {
  area?: Maybe<Scalars['Float']>;
  base_construction_lambda?: Maybe<Scalars['Float']>;
  base_construction_thickness?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Float']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inclination?: Maybe<Scalars['Float']>;
  insulation_lambda?: Maybe<Scalars['Float']>;
  insulation_thickness?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['Float']>;
  u_value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "envelope" */
export type envelope_stddev_pop_order_by = {
  area?: InputMaybe<order_by>;
  base_construction_lambda?: InputMaybe<order_by>;
  base_construction_thickness?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inclination?: InputMaybe<order_by>;
  insulation_lambda?: InputMaybe<order_by>;
  insulation_thickness?: InputMaybe<order_by>;
  orientation?: InputMaybe<order_by>;
  u_value?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type envelope_stddev_samp_fields = {
  area?: Maybe<Scalars['Float']>;
  base_construction_lambda?: Maybe<Scalars['Float']>;
  base_construction_thickness?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Float']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inclination?: Maybe<Scalars['Float']>;
  insulation_lambda?: Maybe<Scalars['Float']>;
  insulation_thickness?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['Float']>;
  u_value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "envelope" */
export type envelope_stddev_samp_order_by = {
  area?: InputMaybe<order_by>;
  base_construction_lambda?: InputMaybe<order_by>;
  base_construction_thickness?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inclination?: InputMaybe<order_by>;
  insulation_lambda?: InputMaybe<order_by>;
  insulation_thickness?: InputMaybe<order_by>;
  orientation?: InputMaybe<order_by>;
  u_value?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "envelope" */
export type envelope_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: envelope_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type envelope_stream_cursor_value_input = {
  area?: InputMaybe<Scalars['Float']>;
  base_construction_lambda?: InputMaybe<Scalars['Float']>;
  base_construction_material_name?: InputMaybe<Scalars['String']>;
  base_construction_thickness?: InputMaybe<Scalars['Float']>;
  building_model_id?: InputMaybe<Scalars['Int']>;
  cost_per_m2?: InputMaybe<Scalars['Float']>;
  data_source_type_id?: InputMaybe<data_source_type_enum>;
  envelope_type_id?: InputMaybe<envelope_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  inclination?: InputMaybe<Scalars['Float']>;
  insulation_lambda?: InputMaybe<Scalars['Float']>;
  insulation_material_category_id?: InputMaybe<insulation_material_category_enum>;
  insulation_material_name?: InputMaybe<Scalars['String']>;
  insulation_method_id?: InputMaybe<insulation_method_enum>;
  insulation_thickness?: InputMaybe<Scalars['Float']>;
  orientation?: InputMaybe<Scalars['Float']>;
  u_value?: InputMaybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type envelope_sum_fields = {
  area?: Maybe<Scalars['Float']>;
  base_construction_lambda?: Maybe<Scalars['Float']>;
  base_construction_thickness?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Int']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  inclination?: Maybe<Scalars['Float']>;
  insulation_lambda?: Maybe<Scalars['Float']>;
  insulation_thickness?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['Float']>;
  u_value?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "envelope" */
export type envelope_sum_order_by = {
  area?: InputMaybe<order_by>;
  base_construction_lambda?: InputMaybe<order_by>;
  base_construction_thickness?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inclination?: InputMaybe<order_by>;
  insulation_lambda?: InputMaybe<order_by>;
  insulation_thickness?: InputMaybe<order_by>;
  orientation?: InputMaybe<order_by>;
  u_value?: InputMaybe<order_by>;
};

/** columns and relationships of "envelope_type" */
export type envelope_type = {
  id: Scalars['String'];
};

/** aggregated selection of "envelope_type" */
export type envelope_type_aggregate = {
  aggregate?: Maybe<envelope_type_aggregate_fields>;
  nodes: Array<envelope_type>;
};

/** aggregate fields of "envelope_type" */
export type envelope_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<envelope_type_max_fields>;
  min?: Maybe<envelope_type_min_fields>;
};

/** aggregate fields of "envelope_type" */
export type envelope_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<envelope_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "envelope_type". All fields are combined with a logical 'AND'. */
export type envelope_type_bool_exp = {
  _and?: InputMaybe<Array<envelope_type_bool_exp>>;
  _not?: InputMaybe<envelope_type_bool_exp>;
  _or?: InputMaybe<Array<envelope_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { envelope_type_constraint };

export { envelope_type_enum };

/** Boolean expression to compare columns of type "envelope_type_enum". All fields are combined with logical 'AND'. */
export type envelope_type_enum_comparison_exp = {
  _eq?: InputMaybe<envelope_type_enum>;
  _in?: InputMaybe<Array<envelope_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<envelope_type_enum>;
  _nin?: InputMaybe<Array<envelope_type_enum>>;
};

/** input type for inserting data into table "envelope_type" */
export type envelope_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type envelope_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type envelope_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "envelope_type" */
export type envelope_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<envelope_type>;
};

/** on_conflict condition type for table "envelope_type" */
export type envelope_type_on_conflict = {
  constraint: envelope_type_constraint;
  update_columns?: Array<envelope_type_update_column>;
  where?: InputMaybe<envelope_type_bool_exp>;
};

/** Ordering options when selecting data from "envelope_type". */
export type envelope_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: envelope_type */
export type envelope_type_pk_columns_input = {
  id: Scalars['String'];
};

export { envelope_type_select_column };

/** input type for updating data in table "envelope_type" */
export type envelope_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "envelope_type" */
export type envelope_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: envelope_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type envelope_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { envelope_type_update_column };

export type envelope_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<envelope_type_set_input>;
  /** filter the rows which have to be updated */
  where: envelope_type_bool_exp;
};

export { envelope_update_column };

export type envelope_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<envelope_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<envelope_set_input>;
  /** filter the rows which have to be updated */
  where: envelope_bool_exp;
};

/** aggregate var_pop on columns */
export type envelope_var_pop_fields = {
  area?: Maybe<Scalars['Float']>;
  base_construction_lambda?: Maybe<Scalars['Float']>;
  base_construction_thickness?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Float']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inclination?: Maybe<Scalars['Float']>;
  insulation_lambda?: Maybe<Scalars['Float']>;
  insulation_thickness?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['Float']>;
  u_value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "envelope" */
export type envelope_var_pop_order_by = {
  area?: InputMaybe<order_by>;
  base_construction_lambda?: InputMaybe<order_by>;
  base_construction_thickness?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inclination?: InputMaybe<order_by>;
  insulation_lambda?: InputMaybe<order_by>;
  insulation_thickness?: InputMaybe<order_by>;
  orientation?: InputMaybe<order_by>;
  u_value?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type envelope_var_samp_fields = {
  area?: Maybe<Scalars['Float']>;
  base_construction_lambda?: Maybe<Scalars['Float']>;
  base_construction_thickness?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Float']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inclination?: Maybe<Scalars['Float']>;
  insulation_lambda?: Maybe<Scalars['Float']>;
  insulation_thickness?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['Float']>;
  u_value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "envelope" */
export type envelope_var_samp_order_by = {
  area?: InputMaybe<order_by>;
  base_construction_lambda?: InputMaybe<order_by>;
  base_construction_thickness?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inclination?: InputMaybe<order_by>;
  insulation_lambda?: InputMaybe<order_by>;
  insulation_thickness?: InputMaybe<order_by>;
  orientation?: InputMaybe<order_by>;
  u_value?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type envelope_variance_fields = {
  area?: Maybe<Scalars['Float']>;
  base_construction_lambda?: Maybe<Scalars['Float']>;
  base_construction_thickness?: Maybe<Scalars['Float']>;
  building_model_id?: Maybe<Scalars['Float']>;
  cost_per_m2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inclination?: Maybe<Scalars['Float']>;
  insulation_lambda?: Maybe<Scalars['Float']>;
  insulation_thickness?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['Float']>;
  u_value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "envelope" */
export type envelope_variance_order_by = {
  area?: InputMaybe<order_by>;
  base_construction_lambda?: InputMaybe<order_by>;
  base_construction_thickness?: InputMaybe<order_by>;
  building_model_id?: InputMaybe<order_by>;
  cost_per_m2?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inclination?: InputMaybe<order_by>;
  insulation_lambda?: InputMaybe<order_by>;
  insulation_thickness?: InputMaybe<order_by>;
  orientation?: InputMaybe<order_by>;
  u_value?: InputMaybe<order_by>;
};

/** columns and relationships of "eu_taxonomy_compliance" */
export type eu_taxonomy_compliance = {
  id: Scalars['String'];
};

/** aggregated selection of "eu_taxonomy_compliance" */
export type eu_taxonomy_compliance_aggregate = {
  aggregate?: Maybe<eu_taxonomy_compliance_aggregate_fields>;
  nodes: Array<eu_taxonomy_compliance>;
};

/** aggregate fields of "eu_taxonomy_compliance" */
export type eu_taxonomy_compliance_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<eu_taxonomy_compliance_max_fields>;
  min?: Maybe<eu_taxonomy_compliance_min_fields>;
};

/** aggregate fields of "eu_taxonomy_compliance" */
export type eu_taxonomy_compliance_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<eu_taxonomy_compliance_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "eu_taxonomy_compliance". All fields are combined with a logical 'AND'. */
export type eu_taxonomy_compliance_bool_exp = {
  _and?: InputMaybe<Array<eu_taxonomy_compliance_bool_exp>>;
  _not?: InputMaybe<eu_taxonomy_compliance_bool_exp>;
  _or?: InputMaybe<Array<eu_taxonomy_compliance_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { eu_taxonomy_compliance_constraint };

export { eu_taxonomy_compliance_enum };

/** Boolean expression to compare columns of type "eu_taxonomy_compliance_enum". All fields are combined with logical 'AND'. */
export type eu_taxonomy_compliance_enum_comparison_exp = {
  _eq?: InputMaybe<eu_taxonomy_compliance_enum>;
  _in?: InputMaybe<Array<eu_taxonomy_compliance_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<eu_taxonomy_compliance_enum>;
  _nin?: InputMaybe<Array<eu_taxonomy_compliance_enum>>;
};

/** input type for inserting data into table "eu_taxonomy_compliance" */
export type eu_taxonomy_compliance_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type eu_taxonomy_compliance_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type eu_taxonomy_compliance_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "eu_taxonomy_compliance" */
export type eu_taxonomy_compliance_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<eu_taxonomy_compliance>;
};

/** on_conflict condition type for table "eu_taxonomy_compliance" */
export type eu_taxonomy_compliance_on_conflict = {
  constraint: eu_taxonomy_compliance_constraint;
  update_columns?: Array<eu_taxonomy_compliance_update_column>;
  where?: InputMaybe<eu_taxonomy_compliance_bool_exp>;
};

/** Ordering options when selecting data from "eu_taxonomy_compliance". */
export type eu_taxonomy_compliance_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: eu_taxonomy_compliance */
export type eu_taxonomy_compliance_pk_columns_input = {
  id: Scalars['String'];
};

export { eu_taxonomy_compliance_select_column };

/** input type for updating data in table "eu_taxonomy_compliance" */
export type eu_taxonomy_compliance_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "eu_taxonomy_compliance" */
export type eu_taxonomy_compliance_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: eu_taxonomy_compliance_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type eu_taxonomy_compliance_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { eu_taxonomy_compliance_update_column };

export type eu_taxonomy_compliance_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<eu_taxonomy_compliance_set_input>;
  /** filter the rows which have to be updated */
  where: eu_taxonomy_compliance_bool_exp;
};

/** columns and relationships of "file" */
export type file = {
  /** An array relationship */
  consumption_invoice_drafts: Array<consumption_invoice_draft>;
  /** An aggregate relationship */
  consumption_invoice_drafts_aggregate: consumption_invoice_draft_aggregate;
  /** An array relationship */
  consumption_invoices: Array<consumption_invoice>;
  /** An aggregate relationship */
  consumption_invoices_aggregate: consumption_invoice_aggregate;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  downloadUrl: Scalars['String'];
  /** An array relationship */
  emission_certificate_drafts: Array<emission_certificate_draft>;
  /** An aggregate relationship */
  emission_certificate_drafts_aggregate: emission_certificate_draft_aggregate;
  /** An array relationship */
  emission_certificates: Array<emission_certificate>;
  /** An aggregate relationship */
  emission_certificates_aggregate: emission_certificate_aggregate;
  /** An array relationship */
  energy_certificate_drafts: Array<energy_certificate_draft>;
  /** An aggregate relationship */
  energy_certificate_drafts_aggregate: energy_certificate_draft_aggregate;
  /** An array relationship */
  energy_certificates: Array<energy_certificate>;
  /** An aggregate relationship */
  energy_certificates_aggregate: energy_certificate_aggregate;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  org?: Maybe<org>;
  /** An array relationship */
  report_evidences: Array<report_evidence>;
  /** An aggregate relationship */
  report_evidences_aggregate: report_evidence_aggregate;
  s3_key: Scalars['uuid'];
  uploading: Scalars['Boolean'];
};

/** columns and relationships of "file" */
export type fileconsumption_invoice_draftsArgs = {
  distinct_on?: InputMaybe<Array<consumption_invoice_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_invoice_draft_order_by>>;
  where?: InputMaybe<consumption_invoice_draft_bool_exp>;
};

/** columns and relationships of "file" */
export type fileconsumption_invoice_drafts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_invoice_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_invoice_draft_order_by>>;
  where?: InputMaybe<consumption_invoice_draft_bool_exp>;
};

/** columns and relationships of "file" */
export type fileconsumption_invoicesArgs = {
  distinct_on?: InputMaybe<Array<consumption_invoice_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_invoice_order_by>>;
  where?: InputMaybe<consumption_invoice_bool_exp>;
};

/** columns and relationships of "file" */
export type fileconsumption_invoices_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_invoice_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_invoice_order_by>>;
  where?: InputMaybe<consumption_invoice_bool_exp>;
};

/** columns and relationships of "file" */
export type fileemission_certificate_draftsArgs = {
  distinct_on?: InputMaybe<Array<emission_certificate_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<emission_certificate_draft_order_by>>;
  where?: InputMaybe<emission_certificate_draft_bool_exp>;
};

/** columns and relationships of "file" */
export type fileemission_certificate_drafts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<emission_certificate_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<emission_certificate_draft_order_by>>;
  where?: InputMaybe<emission_certificate_draft_bool_exp>;
};

/** columns and relationships of "file" */
export type fileemission_certificatesArgs = {
  distinct_on?: InputMaybe<Array<emission_certificate_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<emission_certificate_order_by>>;
  where?: InputMaybe<emission_certificate_bool_exp>;
};

/** columns and relationships of "file" */
export type fileemission_certificates_aggregateArgs = {
  distinct_on?: InputMaybe<Array<emission_certificate_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<emission_certificate_order_by>>;
  where?: InputMaybe<emission_certificate_bool_exp>;
};

/** columns and relationships of "file" */
export type fileenergy_certificate_draftsArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_draft_order_by>>;
  where?: InputMaybe<energy_certificate_draft_bool_exp>;
};

/** columns and relationships of "file" */
export type fileenergy_certificate_drafts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_draft_order_by>>;
  where?: InputMaybe<energy_certificate_draft_bool_exp>;
};

/** columns and relationships of "file" */
export type fileenergy_certificatesArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_order_by>>;
  where?: InputMaybe<energy_certificate_bool_exp>;
};

/** columns and relationships of "file" */
export type fileenergy_certificates_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_order_by>>;
  where?: InputMaybe<energy_certificate_bool_exp>;
};

/** columns and relationships of "file" */
export type filereport_evidencesArgs = {
  distinct_on?: InputMaybe<Array<report_evidence_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_evidence_order_by>>;
  where?: InputMaybe<report_evidence_bool_exp>;
};

/** columns and relationships of "file" */
export type filereport_evidences_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_evidence_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_evidence_order_by>>;
  where?: InputMaybe<report_evidence_bool_exp>;
};

/** aggregated selection of "file" */
export type file_aggregate = {
  aggregate?: Maybe<file_aggregate_fields>;
  nodes: Array<file>;
};

/** aggregate fields of "file" */
export type file_aggregate_fields = {
  avg?: Maybe<file_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<file_max_fields>;
  min?: Maybe<file_min_fields>;
  stddev?: Maybe<file_stddev_fields>;
  stddev_pop?: Maybe<file_stddev_pop_fields>;
  stddev_samp?: Maybe<file_stddev_samp_fields>;
  sum?: Maybe<file_sum_fields>;
  var_pop?: Maybe<file_var_pop_fields>;
  var_samp?: Maybe<file_var_samp_fields>;
  variance?: Maybe<file_variance_fields>;
};

/** aggregate fields of "file" */
export type file_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<file_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type file_avg_fields = {
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "file". All fields are combined with a logical 'AND'. */
export type file_bool_exp = {
  _and?: InputMaybe<Array<file_bool_exp>>;
  _not?: InputMaybe<file_bool_exp>;
  _or?: InputMaybe<Array<file_bool_exp>>;
  consumption_invoice_drafts?: InputMaybe<consumption_invoice_draft_bool_exp>;
  consumption_invoice_drafts_aggregate?: InputMaybe<consumption_invoice_draft_aggregate_bool_exp>;
  consumption_invoices?: InputMaybe<consumption_invoice_bool_exp>;
  consumption_invoices_aggregate?: InputMaybe<consumption_invoice_aggregate_bool_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  description?: InputMaybe<String_comparison_exp>;
  emission_certificate_drafts?: InputMaybe<emission_certificate_draft_bool_exp>;
  emission_certificate_drafts_aggregate?: InputMaybe<emission_certificate_draft_aggregate_bool_exp>;
  emission_certificates?: InputMaybe<emission_certificate_bool_exp>;
  emission_certificates_aggregate?: InputMaybe<emission_certificate_aggregate_bool_exp>;
  energy_certificate_drafts?: InputMaybe<energy_certificate_draft_bool_exp>;
  energy_certificate_drafts_aggregate?: InputMaybe<energy_certificate_draft_aggregate_bool_exp>;
  energy_certificates?: InputMaybe<energy_certificate_bool_exp>;
  energy_certificates_aggregate?: InputMaybe<energy_certificate_aggregate_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  org?: InputMaybe<org_bool_exp>;
  report_evidences?: InputMaybe<report_evidence_bool_exp>;
  report_evidences_aggregate?: InputMaybe<report_evidence_aggregate_bool_exp>;
  s3_key?: InputMaybe<uuid_comparison_exp>;
  uploading?: InputMaybe<Boolean_comparison_exp>;
};

export { file_constraint };

/** input type for incrementing numeric columns in table "file" */
export type file_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "file" */
export type file_insert_input = {
  consumption_invoice_drafts?: InputMaybe<consumption_invoice_draft_arr_rel_insert_input>;
  consumption_invoices?: InputMaybe<consumption_invoice_arr_rel_insert_input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  emission_certificate_drafts?: InputMaybe<emission_certificate_draft_arr_rel_insert_input>;
  emission_certificates?: InputMaybe<emission_certificate_arr_rel_insert_input>;
  energy_certificate_drafts?: InputMaybe<energy_certificate_draft_arr_rel_insert_input>;
  energy_certificates?: InputMaybe<energy_certificate_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  org?: InputMaybe<org_obj_rel_insert_input>;
  report_evidences?: InputMaybe<report_evidence_arr_rel_insert_input>;
  s3_key?: InputMaybe<Scalars['uuid']>;
  uploading?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type file_max_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  s3_key?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type file_min_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  s3_key?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "file" */
export type file_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<file>;
};

/** input type for inserting object relation for remote table "file" */
export type file_obj_rel_insert_input = {
  data: file_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<file_on_conflict>;
};

/** on_conflict condition type for table "file" */
export type file_on_conflict = {
  constraint: file_constraint;
  update_columns?: Array<file_update_column>;
  where?: InputMaybe<file_bool_exp>;
};

/** Ordering options when selecting data from "file". */
export type file_order_by = {
  consumption_invoice_drafts_aggregate?: InputMaybe<consumption_invoice_draft_aggregate_order_by>;
  consumption_invoices_aggregate?: InputMaybe<consumption_invoice_aggregate_order_by>;
  created_at?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  emission_certificate_drafts_aggregate?: InputMaybe<emission_certificate_draft_aggregate_order_by>;
  emission_certificates_aggregate?: InputMaybe<emission_certificate_aggregate_order_by>;
  energy_certificate_drafts_aggregate?: InputMaybe<energy_certificate_draft_aggregate_order_by>;
  energy_certificates_aggregate?: InputMaybe<energy_certificate_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  org?: InputMaybe<org_order_by>;
  report_evidences_aggregate?: InputMaybe<report_evidence_aggregate_order_by>;
  s3_key?: InputMaybe<order_by>;
  uploading?: InputMaybe<order_by>;
};

/** primary key columns input for table: file */
export type file_pk_columns_input = {
  id: Scalars['Int'];
};

export { file_select_column };

/** input type for updating data in table "file" */
export type file_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  s3_key?: InputMaybe<Scalars['uuid']>;
  uploading?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type file_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type file_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type file_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "file" */
export type file_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: file_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type file_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  s3_key?: InputMaybe<Scalars['uuid']>;
  uploading?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type file_sum_fields = {
  id?: Maybe<Scalars['Int']>;
};

export { file_update_column };

export type file_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<file_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<file_set_input>;
  /** filter the rows which have to be updated */
  where: file_bool_exp;
};

/** aggregate var_pop on columns */
export type file_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type file_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type file_variance_fields = {
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type float8_comparison_exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** columns and relationships of "insulation_material_category" */
export type insulation_material_category = {
  id: Scalars['String'];
};

/** aggregated selection of "insulation_material_category" */
export type insulation_material_category_aggregate = {
  aggregate?: Maybe<insulation_material_category_aggregate_fields>;
  nodes: Array<insulation_material_category>;
};

/** aggregate fields of "insulation_material_category" */
export type insulation_material_category_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<insulation_material_category_max_fields>;
  min?: Maybe<insulation_material_category_min_fields>;
};

/** aggregate fields of "insulation_material_category" */
export type insulation_material_category_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<insulation_material_category_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "insulation_material_category". All fields are combined with a logical 'AND'. */
export type insulation_material_category_bool_exp = {
  _and?: InputMaybe<Array<insulation_material_category_bool_exp>>;
  _not?: InputMaybe<insulation_material_category_bool_exp>;
  _or?: InputMaybe<Array<insulation_material_category_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { insulation_material_category_constraint };

export { insulation_material_category_enum };

/** Boolean expression to compare columns of type "insulation_material_category_enum". All fields are combined with logical 'AND'. */
export type insulation_material_category_enum_comparison_exp = {
  _eq?: InputMaybe<insulation_material_category_enum>;
  _in?: InputMaybe<Array<insulation_material_category_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<insulation_material_category_enum>;
  _nin?: InputMaybe<Array<insulation_material_category_enum>>;
};

/** input type for inserting data into table "insulation_material_category" */
export type insulation_material_category_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type insulation_material_category_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type insulation_material_category_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "insulation_material_category" */
export type insulation_material_category_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<insulation_material_category>;
};

/** on_conflict condition type for table "insulation_material_category" */
export type insulation_material_category_on_conflict = {
  constraint: insulation_material_category_constraint;
  update_columns?: Array<insulation_material_category_update_column>;
  where?: InputMaybe<insulation_material_category_bool_exp>;
};

/** Ordering options when selecting data from "insulation_material_category". */
export type insulation_material_category_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: insulation_material_category */
export type insulation_material_category_pk_columns_input = {
  id: Scalars['String'];
};

export { insulation_material_category_select_column };

/** input type for updating data in table "insulation_material_category" */
export type insulation_material_category_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "insulation_material_category" */
export type insulation_material_category_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: insulation_material_category_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type insulation_material_category_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { insulation_material_category_update_column };

export type insulation_material_category_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<insulation_material_category_set_input>;
  /** filter the rows which have to be updated */
  where: insulation_material_category_bool_exp;
};

/** columns and relationships of "insulation_method" */
export type insulation_method = {
  id: Scalars['String'];
};

/** aggregated selection of "insulation_method" */
export type insulation_method_aggregate = {
  aggregate?: Maybe<insulation_method_aggregate_fields>;
  nodes: Array<insulation_method>;
};

/** aggregate fields of "insulation_method" */
export type insulation_method_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<insulation_method_max_fields>;
  min?: Maybe<insulation_method_min_fields>;
};

/** aggregate fields of "insulation_method" */
export type insulation_method_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<insulation_method_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "insulation_method". All fields are combined with a logical 'AND'. */
export type insulation_method_bool_exp = {
  _and?: InputMaybe<Array<insulation_method_bool_exp>>;
  _not?: InputMaybe<insulation_method_bool_exp>;
  _or?: InputMaybe<Array<insulation_method_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { insulation_method_constraint };

export { insulation_method_enum };

/** Boolean expression to compare columns of type "insulation_method_enum". All fields are combined with logical 'AND'. */
export type insulation_method_enum_comparison_exp = {
  _eq?: InputMaybe<insulation_method_enum>;
  _in?: InputMaybe<Array<insulation_method_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<insulation_method_enum>;
  _nin?: InputMaybe<Array<insulation_method_enum>>;
};

/** input type for inserting data into table "insulation_method" */
export type insulation_method_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type insulation_method_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type insulation_method_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "insulation_method" */
export type insulation_method_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<insulation_method>;
};

/** on_conflict condition type for table "insulation_method" */
export type insulation_method_on_conflict = {
  constraint: insulation_method_constraint;
  update_columns?: Array<insulation_method_update_column>;
  where?: InputMaybe<insulation_method_bool_exp>;
};

/** Ordering options when selecting data from "insulation_method". */
export type insulation_method_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: insulation_method */
export type insulation_method_pk_columns_input = {
  id: Scalars['String'];
};

export { insulation_method_select_column };

/** input type for updating data in table "insulation_method" */
export type insulation_method_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "insulation_method" */
export type insulation_method_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: insulation_method_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type insulation_method_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { insulation_method_update_column };

export type insulation_method_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<insulation_method_set_input>;
  /** filter the rows which have to be updated */
  where: insulation_method_bool_exp;
};

/** The means of payment for an invoice */
export type invoice_payer_type = {
  id: Scalars['String'];
};

/** aggregated selection of "invoice_payer_type" */
export type invoice_payer_type_aggregate = {
  aggregate?: Maybe<invoice_payer_type_aggregate_fields>;
  nodes: Array<invoice_payer_type>;
};

/** aggregate fields of "invoice_payer_type" */
export type invoice_payer_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<invoice_payer_type_max_fields>;
  min?: Maybe<invoice_payer_type_min_fields>;
};

/** aggregate fields of "invoice_payer_type" */
export type invoice_payer_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<invoice_payer_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "invoice_payer_type". All fields are combined with a logical 'AND'. */
export type invoice_payer_type_bool_exp = {
  _and?: InputMaybe<Array<invoice_payer_type_bool_exp>>;
  _not?: InputMaybe<invoice_payer_type_bool_exp>;
  _or?: InputMaybe<Array<invoice_payer_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { invoice_payer_type_constraint };

export { invoice_payer_type_enum };

/** Boolean expression to compare columns of type "invoice_payer_type_enum". All fields are combined with logical 'AND'. */
export type invoice_payer_type_enum_comparison_exp = {
  _eq?: InputMaybe<invoice_payer_type_enum>;
  _in?: InputMaybe<Array<invoice_payer_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<invoice_payer_type_enum>;
  _nin?: InputMaybe<Array<invoice_payer_type_enum>>;
};

/** input type for inserting data into table "invoice_payer_type" */
export type invoice_payer_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type invoice_payer_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type invoice_payer_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "invoice_payer_type" */
export type invoice_payer_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<invoice_payer_type>;
};

/** on_conflict condition type for table "invoice_payer_type" */
export type invoice_payer_type_on_conflict = {
  constraint: invoice_payer_type_constraint;
  update_columns?: Array<invoice_payer_type_update_column>;
  where?: InputMaybe<invoice_payer_type_bool_exp>;
};

/** Ordering options when selecting data from "invoice_payer_type". */
export type invoice_payer_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: invoice_payer_type */
export type invoice_payer_type_pk_columns_input = {
  id: Scalars['String'];
};

export { invoice_payer_type_select_column };

/** input type for updating data in table "invoice_payer_type" */
export type invoice_payer_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "invoice_payer_type" */
export type invoice_payer_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: invoice_payer_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type invoice_payer_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { invoice_payer_type_update_column };

export type invoice_payer_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<invoice_payer_type_set_input>;
  /** filter the rows which have to be updated */
  where: invoice_payer_type_bool_exp;
};

export type jsonb_cast_exp = {
  String?: InputMaybe<String_comparison_exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type jsonb_comparison_exp = {
  _cast?: InputMaybe<jsonb_cast_exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** A table to store langfuse datasets temporarly in order to feed the datasets for data team */
export type langfuse_datasets_temporary_item = {
  created_at: Scalars['timestamptz'];
  dataset_name: Scalars['String'];
  entity_id: Scalars['Int'];
  entity_type: Scalars['String'];
  expected_output?: Maybe<Scalars['jsonb']>;
  id: Scalars['Int'];
  input: Scalars['String'];
  org_id: Scalars['Int'];
  output?: Maybe<Scalars['jsonb']>;
  updated_at: Scalars['timestamptz'];
};

/** A table to store langfuse datasets temporarly in order to feed the datasets for data team */
export type langfuse_datasets_temporary_itemexpected_outputArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** A table to store langfuse datasets temporarly in order to feed the datasets for data team */
export type langfuse_datasets_temporary_itemoutputArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "langfuse_datasets_temporary_item" */
export type langfuse_datasets_temporary_item_aggregate = {
  aggregate?: Maybe<langfuse_datasets_temporary_item_aggregate_fields>;
  nodes: Array<langfuse_datasets_temporary_item>;
};

/** aggregate fields of "langfuse_datasets_temporary_item" */
export type langfuse_datasets_temporary_item_aggregate_fields = {
  avg?: Maybe<langfuse_datasets_temporary_item_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<langfuse_datasets_temporary_item_max_fields>;
  min?: Maybe<langfuse_datasets_temporary_item_min_fields>;
  stddev?: Maybe<langfuse_datasets_temporary_item_stddev_fields>;
  stddev_pop?: Maybe<langfuse_datasets_temporary_item_stddev_pop_fields>;
  stddev_samp?: Maybe<langfuse_datasets_temporary_item_stddev_samp_fields>;
  sum?: Maybe<langfuse_datasets_temporary_item_sum_fields>;
  var_pop?: Maybe<langfuse_datasets_temporary_item_var_pop_fields>;
  var_samp?: Maybe<langfuse_datasets_temporary_item_var_samp_fields>;
  variance?: Maybe<langfuse_datasets_temporary_item_variance_fields>;
};

/** aggregate fields of "langfuse_datasets_temporary_item" */
export type langfuse_datasets_temporary_item_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<langfuse_datasets_temporary_item_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type langfuse_datasets_temporary_item_append_input = {
  expected_output?: InputMaybe<Scalars['jsonb']>;
  output?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type langfuse_datasets_temporary_item_avg_fields = {
  entity_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "langfuse_datasets_temporary_item". All fields are combined with a logical 'AND'. */
export type langfuse_datasets_temporary_item_bool_exp = {
  _and?: InputMaybe<Array<langfuse_datasets_temporary_item_bool_exp>>;
  _not?: InputMaybe<langfuse_datasets_temporary_item_bool_exp>;
  _or?: InputMaybe<Array<langfuse_datasets_temporary_item_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  dataset_name?: InputMaybe<String_comparison_exp>;
  entity_id?: InputMaybe<Int_comparison_exp>;
  entity_type?: InputMaybe<String_comparison_exp>;
  expected_output?: InputMaybe<jsonb_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  input?: InputMaybe<String_comparison_exp>;
  org_id?: InputMaybe<Int_comparison_exp>;
  output?: InputMaybe<jsonb_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

export { langfuse_datasets_temporary_item_constraint };

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type langfuse_datasets_temporary_item_delete_at_path_input = {
  expected_output?: InputMaybe<Array<Scalars['String']>>;
  output?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type langfuse_datasets_temporary_item_delete_elem_input = {
  expected_output?: InputMaybe<Scalars['Int']>;
  output?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type langfuse_datasets_temporary_item_delete_key_input = {
  expected_output?: InputMaybe<Scalars['String']>;
  output?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "langfuse_datasets_temporary_item" */
export type langfuse_datasets_temporary_item_inc_input = {
  entity_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "langfuse_datasets_temporary_item" */
export type langfuse_datasets_temporary_item_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dataset_name?: InputMaybe<Scalars['String']>;
  entity_id?: InputMaybe<Scalars['Int']>;
  entity_type?: InputMaybe<Scalars['String']>;
  expected_output?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['Int']>;
  output?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type langfuse_datasets_temporary_item_max_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  dataset_name?: Maybe<Scalars['String']>;
  entity_id?: Maybe<Scalars['Int']>;
  entity_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  input?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type langfuse_datasets_temporary_item_min_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  dataset_name?: Maybe<Scalars['String']>;
  entity_id?: Maybe<Scalars['Int']>;
  entity_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  input?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "langfuse_datasets_temporary_item" */
export type langfuse_datasets_temporary_item_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<langfuse_datasets_temporary_item>;
};

/** on_conflict condition type for table "langfuse_datasets_temporary_item" */
export type langfuse_datasets_temporary_item_on_conflict = {
  constraint: langfuse_datasets_temporary_item_constraint;
  update_columns?: Array<langfuse_datasets_temporary_item_update_column>;
  where?: InputMaybe<langfuse_datasets_temporary_item_bool_exp>;
};

/** Ordering options when selecting data from "langfuse_datasets_temporary_item". */
export type langfuse_datasets_temporary_item_order_by = {
  created_at?: InputMaybe<order_by>;
  dataset_name?: InputMaybe<order_by>;
  entity_id?: InputMaybe<order_by>;
  entity_type?: InputMaybe<order_by>;
  expected_output?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  input?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  output?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: langfuse_datasets_temporary_item */
export type langfuse_datasets_temporary_item_pk_columns_input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type langfuse_datasets_temporary_item_prepend_input = {
  expected_output?: InputMaybe<Scalars['jsonb']>;
  output?: InputMaybe<Scalars['jsonb']>;
};

export { langfuse_datasets_temporary_item_select_column };

/** input type for updating data in table "langfuse_datasets_temporary_item" */
export type langfuse_datasets_temporary_item_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dataset_name?: InputMaybe<Scalars['String']>;
  entity_id?: InputMaybe<Scalars['Int']>;
  entity_type?: InputMaybe<Scalars['String']>;
  expected_output?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['Int']>;
  output?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type langfuse_datasets_temporary_item_stddev_fields = {
  entity_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type langfuse_datasets_temporary_item_stddev_pop_fields = {
  entity_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type langfuse_datasets_temporary_item_stddev_samp_fields = {
  entity_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "langfuse_datasets_temporary_item" */
export type langfuse_datasets_temporary_item_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: langfuse_datasets_temporary_item_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type langfuse_datasets_temporary_item_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dataset_name?: InputMaybe<Scalars['String']>;
  entity_id?: InputMaybe<Scalars['Int']>;
  entity_type?: InputMaybe<Scalars['String']>;
  expected_output?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['Int']>;
  output?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type langfuse_datasets_temporary_item_sum_fields = {
  entity_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
};

export { langfuse_datasets_temporary_item_update_column };

export type langfuse_datasets_temporary_item_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<langfuse_datasets_temporary_item_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<langfuse_datasets_temporary_item_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<langfuse_datasets_temporary_item_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<langfuse_datasets_temporary_item_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<langfuse_datasets_temporary_item_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<langfuse_datasets_temporary_item_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<langfuse_datasets_temporary_item_set_input>;
  /** filter the rows which have to be updated */
  where: langfuse_datasets_temporary_item_bool_exp;
};

/** aggregate var_pop on columns */
export type langfuse_datasets_temporary_item_var_pop_fields = {
  entity_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type langfuse_datasets_temporary_item_var_samp_fields = {
  entity_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type langfuse_datasets_temporary_item_variance_fields = {
  entity_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** All languages that are supported. */
export type language = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

/** aggregated selection of "language" */
export type language_aggregate = {
  aggregate?: Maybe<language_aggregate_fields>;
  nodes: Array<language>;
};

/** aggregate fields of "language" */
export type language_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<language_max_fields>;
  min?: Maybe<language_min_fields>;
};

/** aggregate fields of "language" */
export type language_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<language_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "language". All fields are combined with a logical 'AND'. */
export type language_bool_exp = {
  _and?: InputMaybe<Array<language_bool_exp>>;
  _not?: InputMaybe<language_bool_exp>;
  _or?: InputMaybe<Array<language_bool_exp>>;
  description?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<String_comparison_exp>;
};

export { language_constraint };

export { language_enum };

/** Boolean expression to compare columns of type "language_enum". All fields are combined with logical 'AND'. */
export type language_enum_comparison_exp = {
  _eq?: InputMaybe<language_enum>;
  _in?: InputMaybe<Array<language_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<language_enum>;
  _nin?: InputMaybe<Array<language_enum>>;
};

/** input type for inserting data into table "language" */
export type language_insert_input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type language_max_fields = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type language_min_fields = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "language" */
export type language_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<language>;
};

/** on_conflict condition type for table "language" */
export type language_on_conflict = {
  constraint: language_constraint;
  update_columns?: Array<language_update_column>;
  where?: InputMaybe<language_bool_exp>;
};

/** Ordering options when selecting data from "language". */
export type language_order_by = {
  description?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: language */
export type language_pk_columns_input = {
  id: Scalars['String'];
};

export { language_select_column };

/** input type for updating data in table "language" */
export type language_set_input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "language" */
export type language_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: language_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type language_stream_cursor_value_input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export { language_update_column };

export type language_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<language_set_input>;
  /** filter the rows which have to be updated */
  where: language_bool_exp;
};

/** columns and relationships of "meter" */
export type meter = {
  /** An object relationship */
  area?: Maybe<area>;
  area_id?: Maybe<Scalars['Int']>;
  consumption_frequency_type_id: consumption_frequency_type_enum;
  consumption_type_id: consumption_type_enum;
  consumption_unit_id: consumption_unit_enum;
  /** An array relationship */
  consumptions: Array<consumption>;
  /** An aggregate relationship */
  consumptions_aggregate: consumption_aggregate;
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  external_id: Scalars['String'];
  id: Scalars['Int'];
  last_poll: Scalars['timestamptz'];
  /** An object relationship */
  meter_integration_building: meter_integration_building;
  meter_integration_building_id: Scalars['Int'];
  sync_state_id: sync_state_enum;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "meter" */
export type meterconsumptionsArgs = {
  distinct_on?: InputMaybe<Array<consumption_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_order_by>>;
  where?: InputMaybe<consumption_bool_exp>;
};

/** columns and relationships of "meter" */
export type meterconsumptions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_order_by>>;
  where?: InputMaybe<consumption_bool_exp>;
};

/** aggregated selection of "meter" */
export type meter_aggregate = {
  aggregate?: Maybe<meter_aggregate_fields>;
  nodes: Array<meter>;
};

/** aggregate fields of "meter" */
export type meter_aggregate_fields = {
  avg?: Maybe<meter_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<meter_max_fields>;
  min?: Maybe<meter_min_fields>;
  stddev?: Maybe<meter_stddev_fields>;
  stddev_pop?: Maybe<meter_stddev_pop_fields>;
  stddev_samp?: Maybe<meter_stddev_samp_fields>;
  sum?: Maybe<meter_sum_fields>;
  var_pop?: Maybe<meter_var_pop_fields>;
  var_samp?: Maybe<meter_var_samp_fields>;
  variance?: Maybe<meter_variance_fields>;
};

/** aggregate fields of "meter" */
export type meter_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<meter_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type meter_avg_fields = {
  area_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  meter_integration_building_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "meter". All fields are combined with a logical 'AND'. */
export type meter_bool_exp = {
  _and?: InputMaybe<Array<meter_bool_exp>>;
  _not?: InputMaybe<meter_bool_exp>;
  _or?: InputMaybe<Array<meter_bool_exp>>;
  area?: InputMaybe<area_bool_exp>;
  area_id?: InputMaybe<Int_comparison_exp>;
  consumption_frequency_type_id?: InputMaybe<consumption_frequency_type_enum_comparison_exp>;
  consumption_type_id?: InputMaybe<consumption_type_enum_comparison_exp>;
  consumption_unit_id?: InputMaybe<consumption_unit_enum_comparison_exp>;
  consumptions?: InputMaybe<consumption_bool_exp>;
  consumptions_aggregate?: InputMaybe<consumption_aggregate_bool_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  description?: InputMaybe<String_comparison_exp>;
  external_id?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  last_poll?: InputMaybe<timestamptz_comparison_exp>;
  meter_integration_building?: InputMaybe<meter_integration_building_bool_exp>;
  meter_integration_building_id?: InputMaybe<Int_comparison_exp>;
  sync_state_id?: InputMaybe<sync_state_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

export { meter_constraint };

/** input type for incrementing numeric columns in table "meter" */
export type meter_inc_input = {
  area_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  meter_integration_building_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "meter" */
export type meter_insert_input = {
  area?: InputMaybe<area_obj_rel_insert_input>;
  area_id?: InputMaybe<Scalars['Int']>;
  consumption_frequency_type_id?: InputMaybe<consumption_frequency_type_enum>;
  consumption_type_id?: InputMaybe<consumption_type_enum>;
  consumption_unit_id?: InputMaybe<consumption_unit_enum>;
  consumptions?: InputMaybe<consumption_arr_rel_insert_input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  last_poll?: InputMaybe<Scalars['timestamptz']>;
  meter_integration_building?: InputMaybe<meter_integration_building_obj_rel_insert_input>;
  meter_integration_building_id?: InputMaybe<Scalars['Int']>;
  sync_state_id?: InputMaybe<sync_state_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** represents an integration to an external metering service */
export type meter_integration = {
  api_key: Scalars['String'];
  api_metadata: Scalars['jsonb'];
  api_secret_encrypted: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  last_sync: Scalars['timestamp'];
  meter_integration_type_id: meter_integration_type_enum;
  /** An object relationship */
  org: org;
  org_id: Scalars['Int'];
  sync_state_id: sync_state_enum;
  updated_at: Scalars['timestamptz'];
};

/** represents an integration to an external metering service */
export type meter_integrationapi_metadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "meter_integration" */
export type meter_integration_aggregate = {
  aggregate?: Maybe<meter_integration_aggregate_fields>;
  nodes: Array<meter_integration>;
};

/** aggregate fields of "meter_integration" */
export type meter_integration_aggregate_fields = {
  avg?: Maybe<meter_integration_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<meter_integration_max_fields>;
  min?: Maybe<meter_integration_min_fields>;
  stddev?: Maybe<meter_integration_stddev_fields>;
  stddev_pop?: Maybe<meter_integration_stddev_pop_fields>;
  stddev_samp?: Maybe<meter_integration_stddev_samp_fields>;
  sum?: Maybe<meter_integration_sum_fields>;
  var_pop?: Maybe<meter_integration_var_pop_fields>;
  var_samp?: Maybe<meter_integration_var_samp_fields>;
  variance?: Maybe<meter_integration_variance_fields>;
};

/** aggregate fields of "meter_integration" */
export type meter_integration_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<meter_integration_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type meter_integration_append_input = {
  api_metadata?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type meter_integration_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "meter_integration". All fields are combined with a logical 'AND'. */
export type meter_integration_bool_exp = {
  _and?: InputMaybe<Array<meter_integration_bool_exp>>;
  _not?: InputMaybe<meter_integration_bool_exp>;
  _or?: InputMaybe<Array<meter_integration_bool_exp>>;
  api_key?: InputMaybe<String_comparison_exp>;
  api_metadata?: InputMaybe<jsonb_comparison_exp>;
  api_secret_encrypted?: InputMaybe<String_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  last_sync?: InputMaybe<timestamp_comparison_exp>;
  meter_integration_type_id?: InputMaybe<meter_integration_type_enum_comparison_exp>;
  org?: InputMaybe<org_bool_exp>;
  org_id?: InputMaybe<Int_comparison_exp>;
  sync_state_id?: InputMaybe<sync_state_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** columns and relationships of "meter_integration_building" */
export type meter_integration_building = {
  /** An object relationship */
  building?: Maybe<building>;
  building_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  external_id: Scalars['String'];
  id: Scalars['Int'];
  /** An object relationship */
  meter_integration: meter_integration;
  meter_integration_id: Scalars['Int'];
  properties: Scalars['jsonb'];
  sync_state_id: sync_state_enum;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "meter_integration_building" */
export type meter_integration_buildingpropertiesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "meter_integration_building" */
export type meter_integration_building_aggregate = {
  aggregate?: Maybe<meter_integration_building_aggregate_fields>;
  nodes: Array<meter_integration_building>;
};

/** aggregate fields of "meter_integration_building" */
export type meter_integration_building_aggregate_fields = {
  avg?: Maybe<meter_integration_building_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<meter_integration_building_max_fields>;
  min?: Maybe<meter_integration_building_min_fields>;
  stddev?: Maybe<meter_integration_building_stddev_fields>;
  stddev_pop?: Maybe<meter_integration_building_stddev_pop_fields>;
  stddev_samp?: Maybe<meter_integration_building_stddev_samp_fields>;
  sum?: Maybe<meter_integration_building_sum_fields>;
  var_pop?: Maybe<meter_integration_building_var_pop_fields>;
  var_samp?: Maybe<meter_integration_building_var_samp_fields>;
  variance?: Maybe<meter_integration_building_variance_fields>;
};

/** aggregate fields of "meter_integration_building" */
export type meter_integration_building_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<meter_integration_building_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type meter_integration_building_append_input = {
  properties?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type meter_integration_building_avg_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  meter_integration_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "meter_integration_building". All fields are combined with a logical 'AND'. */
export type meter_integration_building_bool_exp = {
  _and?: InputMaybe<Array<meter_integration_building_bool_exp>>;
  _not?: InputMaybe<meter_integration_building_bool_exp>;
  _or?: InputMaybe<Array<meter_integration_building_bool_exp>>;
  building?: InputMaybe<building_bool_exp>;
  building_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  external_id?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  meter_integration?: InputMaybe<meter_integration_bool_exp>;
  meter_integration_id?: InputMaybe<Int_comparison_exp>;
  properties?: InputMaybe<jsonb_comparison_exp>;
  sync_state_id?: InputMaybe<sync_state_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

export { meter_integration_building_constraint };

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type meter_integration_building_delete_at_path_input = {
  properties?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type meter_integration_building_delete_elem_input = {
  properties?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type meter_integration_building_delete_key_input = {
  properties?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "meter_integration_building" */
export type meter_integration_building_inc_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  meter_integration_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "meter_integration_building" */
export type meter_integration_building_insert_input = {
  building?: InputMaybe<building_obj_rel_insert_input>;
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  meter_integration?: InputMaybe<meter_integration_obj_rel_insert_input>;
  meter_integration_id?: InputMaybe<Scalars['Int']>;
  properties?: InputMaybe<Scalars['jsonb']>;
  sync_state_id?: InputMaybe<sync_state_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type meter_integration_building_max_fields = {
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  meter_integration_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type meter_integration_building_min_fields = {
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  meter_integration_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "meter_integration_building" */
export type meter_integration_building_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<meter_integration_building>;
};

/** input type for inserting object relation for remote table "meter_integration_building" */
export type meter_integration_building_obj_rel_insert_input = {
  data: meter_integration_building_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<meter_integration_building_on_conflict>;
};

/** on_conflict condition type for table "meter_integration_building" */
export type meter_integration_building_on_conflict = {
  constraint: meter_integration_building_constraint;
  update_columns?: Array<meter_integration_building_update_column>;
  where?: InputMaybe<meter_integration_building_bool_exp>;
};

/** Ordering options when selecting data from "meter_integration_building". */
export type meter_integration_building_order_by = {
  building?: InputMaybe<building_order_by>;
  building_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  external_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  meter_integration?: InputMaybe<meter_integration_order_by>;
  meter_integration_id?: InputMaybe<order_by>;
  properties?: InputMaybe<order_by>;
  sync_state_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: meter_integration_building */
export type meter_integration_building_pk_columns_input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type meter_integration_building_prepend_input = {
  properties?: InputMaybe<Scalars['jsonb']>;
};

export { meter_integration_building_select_column };

/** input type for updating data in table "meter_integration_building" */
export type meter_integration_building_set_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  meter_integration_id?: InputMaybe<Scalars['Int']>;
  properties?: InputMaybe<Scalars['jsonb']>;
  sync_state_id?: InputMaybe<sync_state_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type meter_integration_building_stddev_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  meter_integration_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type meter_integration_building_stddev_pop_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  meter_integration_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type meter_integration_building_stddev_samp_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  meter_integration_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "meter_integration_building" */
export type meter_integration_building_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: meter_integration_building_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type meter_integration_building_stream_cursor_value_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  meter_integration_id?: InputMaybe<Scalars['Int']>;
  properties?: InputMaybe<Scalars['jsonb']>;
  sync_state_id?: InputMaybe<sync_state_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type meter_integration_building_sum_fields = {
  building_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  meter_integration_id?: Maybe<Scalars['Int']>;
};

export { meter_integration_building_update_column };

export type meter_integration_building_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<meter_integration_building_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<meter_integration_building_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<meter_integration_building_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<meter_integration_building_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<meter_integration_building_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<meter_integration_building_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<meter_integration_building_set_input>;
  /** filter the rows which have to be updated */
  where: meter_integration_building_bool_exp;
};

/** aggregate var_pop on columns */
export type meter_integration_building_var_pop_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  meter_integration_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type meter_integration_building_var_samp_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  meter_integration_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type meter_integration_building_variance_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  meter_integration_id?: Maybe<Scalars['Float']>;
};

export { meter_integration_constraint };

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type meter_integration_delete_at_path_input = {
  api_metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type meter_integration_delete_elem_input = {
  api_metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type meter_integration_delete_key_input = {
  api_metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "meter_integration" */
export type meter_integration_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "meter_integration" */
export type meter_integration_insert_input = {
  api_key?: InputMaybe<Scalars['String']>;
  api_metadata?: InputMaybe<Scalars['jsonb']>;
  api_secret_encrypted?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  last_sync?: InputMaybe<Scalars['timestamp']>;
  meter_integration_type_id?: InputMaybe<meter_integration_type_enum>;
  org?: InputMaybe<org_obj_rel_insert_input>;
  org_id?: InputMaybe<Scalars['Int']>;
  sync_state_id?: InputMaybe<sync_state_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type meter_integration_max_fields = {
  api_key?: Maybe<Scalars['String']>;
  api_secret_encrypted?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  last_sync?: Maybe<Scalars['timestamp']>;
  org_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type meter_integration_min_fields = {
  api_key?: Maybe<Scalars['String']>;
  api_secret_encrypted?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  last_sync?: Maybe<Scalars['timestamp']>;
  org_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "meter_integration" */
export type meter_integration_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<meter_integration>;
};

/** input type for inserting object relation for remote table "meter_integration" */
export type meter_integration_obj_rel_insert_input = {
  data: meter_integration_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<meter_integration_on_conflict>;
};

/** on_conflict condition type for table "meter_integration" */
export type meter_integration_on_conflict = {
  constraint: meter_integration_constraint;
  update_columns?: Array<meter_integration_update_column>;
  where?: InputMaybe<meter_integration_bool_exp>;
};

/** Ordering options when selecting data from "meter_integration". */
export type meter_integration_order_by = {
  api_key?: InputMaybe<order_by>;
  api_metadata?: InputMaybe<order_by>;
  api_secret_encrypted?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  last_sync?: InputMaybe<order_by>;
  meter_integration_type_id?: InputMaybe<order_by>;
  org?: InputMaybe<org_order_by>;
  org_id?: InputMaybe<order_by>;
  sync_state_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: meter_integration */
export type meter_integration_pk_columns_input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type meter_integration_prepend_input = {
  api_metadata?: InputMaybe<Scalars['jsonb']>;
};

export { meter_integration_select_column };

/** input type for updating data in table "meter_integration" */
export type meter_integration_set_input = {
  api_key?: InputMaybe<Scalars['String']>;
  api_metadata?: InputMaybe<Scalars['jsonb']>;
  api_secret_encrypted?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  last_sync?: InputMaybe<Scalars['timestamp']>;
  meter_integration_type_id?: InputMaybe<meter_integration_type_enum>;
  org_id?: InputMaybe<Scalars['Int']>;
  sync_state_id?: InputMaybe<sync_state_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type meter_integration_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type meter_integration_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type meter_integration_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "meter_integration" */
export type meter_integration_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: meter_integration_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type meter_integration_stream_cursor_value_input = {
  api_key?: InputMaybe<Scalars['String']>;
  api_metadata?: InputMaybe<Scalars['jsonb']>;
  api_secret_encrypted?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  last_sync?: InputMaybe<Scalars['timestamp']>;
  meter_integration_type_id?: InputMaybe<meter_integration_type_enum>;
  org_id?: InputMaybe<Scalars['Int']>;
  sync_state_id?: InputMaybe<sync_state_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type meter_integration_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "meter_integration_type" */
export type meter_integration_type = {
  id: Scalars['String'];
};

/** aggregated selection of "meter_integration_type" */
export type meter_integration_type_aggregate = {
  aggregate?: Maybe<meter_integration_type_aggregate_fields>;
  nodes: Array<meter_integration_type>;
};

/** aggregate fields of "meter_integration_type" */
export type meter_integration_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<meter_integration_type_max_fields>;
  min?: Maybe<meter_integration_type_min_fields>;
};

/** aggregate fields of "meter_integration_type" */
export type meter_integration_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<meter_integration_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "meter_integration_type". All fields are combined with a logical 'AND'. */
export type meter_integration_type_bool_exp = {
  _and?: InputMaybe<Array<meter_integration_type_bool_exp>>;
  _not?: InputMaybe<meter_integration_type_bool_exp>;
  _or?: InputMaybe<Array<meter_integration_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { meter_integration_type_constraint };

export { meter_integration_type_enum };

/** Boolean expression to compare columns of type "meter_integration_type_enum". All fields are combined with logical 'AND'. */
export type meter_integration_type_enum_comparison_exp = {
  _eq?: InputMaybe<meter_integration_type_enum>;
  _in?: InputMaybe<Array<meter_integration_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<meter_integration_type_enum>;
  _nin?: InputMaybe<Array<meter_integration_type_enum>>;
};

/** input type for inserting data into table "meter_integration_type" */
export type meter_integration_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type meter_integration_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type meter_integration_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "meter_integration_type" */
export type meter_integration_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<meter_integration_type>;
};

/** on_conflict condition type for table "meter_integration_type" */
export type meter_integration_type_on_conflict = {
  constraint: meter_integration_type_constraint;
  update_columns?: Array<meter_integration_type_update_column>;
  where?: InputMaybe<meter_integration_type_bool_exp>;
};

/** Ordering options when selecting data from "meter_integration_type". */
export type meter_integration_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: meter_integration_type */
export type meter_integration_type_pk_columns_input = {
  id: Scalars['String'];
};

export { meter_integration_type_select_column };

/** input type for updating data in table "meter_integration_type" */
export type meter_integration_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "meter_integration_type" */
export type meter_integration_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: meter_integration_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type meter_integration_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { meter_integration_type_update_column };

export type meter_integration_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<meter_integration_type_set_input>;
  /** filter the rows which have to be updated */
  where: meter_integration_type_bool_exp;
};

export { meter_integration_update_column };

export type meter_integration_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<meter_integration_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<meter_integration_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<meter_integration_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<meter_integration_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<meter_integration_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<meter_integration_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<meter_integration_set_input>;
  /** filter the rows which have to be updated */
  where: meter_integration_bool_exp;
};

/** aggregate var_pop on columns */
export type meter_integration_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type meter_integration_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type meter_integration_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type meter_max_fields = {
  area_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_poll?: Maybe<Scalars['timestamptz']>;
  meter_integration_building_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type meter_min_fields = {
  area_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_poll?: Maybe<Scalars['timestamptz']>;
  meter_integration_building_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "meter" */
export type meter_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<meter>;
};

/** input type for inserting object relation for remote table "meter" */
export type meter_obj_rel_insert_input = {
  data: meter_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<meter_on_conflict>;
};

/** on_conflict condition type for table "meter" */
export type meter_on_conflict = {
  constraint: meter_constraint;
  update_columns?: Array<meter_update_column>;
  where?: InputMaybe<meter_bool_exp>;
};

/** Ordering options when selecting data from "meter". */
export type meter_order_by = {
  area?: InputMaybe<area_order_by>;
  area_id?: InputMaybe<order_by>;
  consumption_frequency_type_id?: InputMaybe<order_by>;
  consumption_type_id?: InputMaybe<order_by>;
  consumption_unit_id?: InputMaybe<order_by>;
  consumptions_aggregate?: InputMaybe<consumption_aggregate_order_by>;
  created_at?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  external_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  last_poll?: InputMaybe<order_by>;
  meter_integration_building?: InputMaybe<meter_integration_building_order_by>;
  meter_integration_building_id?: InputMaybe<order_by>;
  sync_state_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: meter */
export type meter_pk_columns_input = {
  id: Scalars['Int'];
};

export { meter_select_column };

/** input type for updating data in table "meter" */
export type meter_set_input = {
  area_id?: InputMaybe<Scalars['Int']>;
  consumption_frequency_type_id?: InputMaybe<consumption_frequency_type_enum>;
  consumption_type_id?: InputMaybe<consumption_type_enum>;
  consumption_unit_id?: InputMaybe<consumption_unit_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  last_poll?: InputMaybe<Scalars['timestamptz']>;
  meter_integration_building_id?: InputMaybe<Scalars['Int']>;
  sync_state_id?: InputMaybe<sync_state_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type meter_stddev_fields = {
  area_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  meter_integration_building_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type meter_stddev_pop_fields = {
  area_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  meter_integration_building_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type meter_stddev_samp_fields = {
  area_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  meter_integration_building_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "meter" */
export type meter_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: meter_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type meter_stream_cursor_value_input = {
  area_id?: InputMaybe<Scalars['Int']>;
  consumption_frequency_type_id?: InputMaybe<consumption_frequency_type_enum>;
  consumption_type_id?: InputMaybe<consumption_type_enum>;
  consumption_unit_id?: InputMaybe<consumption_unit_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  last_poll?: InputMaybe<Scalars['timestamptz']>;
  meter_integration_building_id?: InputMaybe<Scalars['Int']>;
  sync_state_id?: InputMaybe<sync_state_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type meter_sum_fields = {
  area_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  meter_integration_building_id?: Maybe<Scalars['Int']>;
};

export { meter_update_column };

export type meter_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<meter_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<meter_set_input>;
  /** filter the rows which have to be updated */
  where: meter_bool_exp;
};

/** aggregate var_pop on columns */
export type meter_var_pop_fields = {
  area_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  meter_integration_building_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type meter_var_samp_fields = {
  area_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  meter_integration_building_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type meter_variance_fields = {
  area_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  meter_integration_building_id?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type mutation_root = {
  acceptAutofillReport: AcceptAutofillReportOutput;
  answerQuestions: AnswerQuestionsOutput;
  applyBuildingModelMutations: ApplyBuildingModelMutationsOutput;
  changeQueueStatus: Array<QueueStatusOutput>;
  createBuildingFromEnergyCertificateDraft: CreateBuildingFromEnergyCertificateDraftOutput;
  createConsumptionInvoiceDraft: CreateConsumptionInvoiceDraftOutput;
  createConsumptionInvoiceFromDraft: CreateConsumptionInvoiceFromDraftOutput;
  createEmissionCertificateDraft: CreateEmissionCertificateDraftOutput;
  createEmissionCertificateFromDraft: CreateEmissionCertificateFromDraftOutput;
  createEnergyCertificateDraft: CreateEnergyCertificateDraftOutput;
  createEnergyCertificateFile: CreateEnergyCertificateFileOutput;
  createEvidenceFromFile: CreateEvidenceOutput;
  createReport: CreateReportOutput;
  createUser: UserId;
  deleteEmissionCertificate: DeleteEmissionCertificateOutput;
  deleteUser: UserId;
  /** delete data from the table: "action" */
  delete_action?: Maybe<action_mutation_response>;
  /** delete single row from the table: "action" */
  delete_action_by_pk?: Maybe<action>;
  /** delete data from the table: "action_plan" */
  delete_action_plan?: Maybe<action_plan_mutation_response>;
  /** delete single row from the table: "action_plan" */
  delete_action_plan_by_pk?: Maybe<action_plan>;
  /** delete data from the table: "action_subsidy" */
  delete_action_subsidy?: Maybe<action_subsidy_mutation_response>;
  /** delete single row from the table: "action_subsidy" */
  delete_action_subsidy_by_pk?: Maybe<action_subsidy>;
  /** delete data from the table: "action_subsidy_value_type" */
  delete_action_subsidy_value_type?: Maybe<action_subsidy_value_type_mutation_response>;
  /** delete single row from the table: "action_subsidy_value_type" */
  delete_action_subsidy_value_type_by_pk?: Maybe<action_subsidy_value_type>;
  /** delete data from the table: "address" */
  delete_address?: Maybe<address_mutation_response>;
  /** delete single row from the table: "address" */
  delete_address_by_pk?: Maybe<address>;
  /** delete data from the table: "api_user" */
  delete_api_user?: Maybe<api_user_mutation_response>;
  /** delete single row from the table: "api_user" */
  delete_api_user_by_pk?: Maybe<api_user>;
  /** delete data from the table: "area" */
  delete_area?: Maybe<area_mutation_response>;
  /** delete single row from the table: "area" */
  delete_area_by_pk?: Maybe<area>;
  /** delete data from the table: "area_system_type" */
  delete_area_system_type?: Maybe<area_system_type_mutation_response>;
  /** delete single row from the table: "area_system_type" */
  delete_area_system_type_by_pk?: Maybe<area_system_type>;
  /** delete data from the table: "area_type" */
  delete_area_type?: Maybe<area_type_mutation_response>;
  /** delete single row from the table: "area_type" */
  delete_area_type_by_pk?: Maybe<area_type>;
  /** delete data from the table: "async_job" */
  delete_async_job?: Maybe<async_job_mutation_response>;
  /** delete single row from the table: "async_job" */
  delete_async_job_by_pk?: Maybe<async_job>;
  /** delete data from the table: "async_job_status_type" */
  delete_async_job_status_type?: Maybe<async_job_status_type_mutation_response>;
  /** delete single row from the table: "async_job_status_type" */
  delete_async_job_status_type_by_pk?: Maybe<async_job_status_type>;
  /** delete data from the table: "building" */
  delete_building?: Maybe<building_mutation_response>;
  /** delete single row from the table: "building" */
  delete_building_by_pk?: Maybe<building>;
  /** delete data from the table: "building_model" */
  delete_building_model?: Maybe<building_model_mutation_response>;
  /** delete single row from the table: "building_model" */
  delete_building_model_by_pk?: Maybe<building_model>;
  /** delete data from the table: "building_state" */
  delete_building_state?: Maybe<building_state_mutation_response>;
  /** delete single row from the table: "building_state" */
  delete_building_state_by_pk?: Maybe<building_state>;
  /** delete data from the table: "climate_region" */
  delete_climate_region?: Maybe<climate_region_mutation_response>;
  /** delete single row from the table: "climate_region" */
  delete_climate_region_by_pk?: Maybe<climate_region>;
  /** delete data from the table: "climate_risk" */
  delete_climate_risk?: Maybe<climate_risk_mutation_response>;
  /** delete single row from the table: "climate_risk" */
  delete_climate_risk_by_pk?: Maybe<climate_risk>;
  /** delete data from the table: "climate_risk_type" */
  delete_climate_risk_type?: Maybe<climate_risk_type_mutation_response>;
  /** delete single row from the table: "climate_risk_type" */
  delete_climate_risk_type_by_pk?: Maybe<climate_risk_type>;
  /** delete data from the table: "consumption" */
  delete_consumption?: Maybe<consumption_mutation_response>;
  /** delete single row from the table: "consumption" */
  delete_consumption_by_pk?: Maybe<consumption>;
  /** delete data from the table: "consumption_draft" */
  delete_consumption_draft?: Maybe<consumption_draft_mutation_response>;
  /** delete single row from the table: "consumption_draft" */
  delete_consumption_draft_by_pk?: Maybe<consumption_draft>;
  /** delete data from the table: "consumption_frequency_type" */
  delete_consumption_frequency_type?: Maybe<consumption_frequency_type_mutation_response>;
  /** delete single row from the table: "consumption_frequency_type" */
  delete_consumption_frequency_type_by_pk?: Maybe<consumption_frequency_type>;
  /** delete data from the table: "consumption_invoice" */
  delete_consumption_invoice?: Maybe<consumption_invoice_mutation_response>;
  /** delete single row from the table: "consumption_invoice" */
  delete_consumption_invoice_by_pk?: Maybe<consumption_invoice>;
  /** delete data from the table: "consumption_invoice_draft" */
  delete_consumption_invoice_draft?: Maybe<consumption_invoice_draft_mutation_response>;
  /** delete single row from the table: "consumption_invoice_draft" */
  delete_consumption_invoice_draft_by_pk?: Maybe<consumption_invoice_draft>;
  /** delete data from the table: "consumption_source_type" */
  delete_consumption_source_type?: Maybe<consumption_source_type_mutation_response>;
  /** delete single row from the table: "consumption_source_type" */
  delete_consumption_source_type_by_pk?: Maybe<consumption_source_type>;
  /** delete data from the table: "consumption_sub_type" */
  delete_consumption_sub_type?: Maybe<consumption_sub_type_mutation_response>;
  /** delete single row from the table: "consumption_sub_type" */
  delete_consumption_sub_type_by_pk?: Maybe<consumption_sub_type>;
  /** delete data from the table: "consumption_type" */
  delete_consumption_type?: Maybe<consumption_type_mutation_response>;
  /** delete single row from the table: "consumption_type" */
  delete_consumption_type_by_pk?: Maybe<consumption_type>;
  /** delete data from the table: "consumption_unit" */
  delete_consumption_unit?: Maybe<consumption_unit_mutation_response>;
  /** delete single row from the table: "consumption_unit" */
  delete_consumption_unit_by_pk?: Maybe<consumption_unit>;
  /** delete data from the table: "consumption_waste_details" */
  delete_consumption_waste_details?: Maybe<consumption_waste_details_mutation_response>;
  /** delete single row from the table: "consumption_waste_details" */
  delete_consumption_waste_details_by_pk?: Maybe<consumption_waste_details>;
  /** delete data from the table: "consumption_waste_details_draft" */
  delete_consumption_waste_details_draft?: Maybe<consumption_waste_details_draft_mutation_response>;
  /** delete single row from the table: "consumption_waste_details_draft" */
  delete_consumption_waste_details_draft_by_pk?: Maybe<consumption_waste_details_draft>;
  /** delete data from the table: "country" */
  delete_country?: Maybe<country_mutation_response>;
  /** delete single row from the table: "country" */
  delete_country_by_pk?: Maybe<country>;
  /** delete data from the table: "country_state" */
  delete_country_state?: Maybe<country_state_mutation_response>;
  /** delete single row from the table: "country_state" */
  delete_country_state_by_pk?: Maybe<country_state>;
  /** delete data from the table: "data_source_type" */
  delete_data_source_type?: Maybe<data_source_type_mutation_response>;
  /** delete single row from the table: "data_source_type" */
  delete_data_source_type_by_pk?: Maybe<data_source_type>;
  /** delete data from the table: "draft_status" */
  delete_draft_status?: Maybe<draft_status_mutation_response>;
  /** delete single row from the table: "draft_status" */
  delete_draft_status_by_pk?: Maybe<draft_status>;
  /** delete data from the table: "economic_unit" */
  delete_economic_unit?: Maybe<economic_unit_mutation_response>;
  /** delete single row from the table: "economic_unit" */
  delete_economic_unit_by_pk?: Maybe<economic_unit>;
  /** delete data from the table: "efficiency_class" */
  delete_efficiency_class?: Maybe<efficiency_class_mutation_response>;
  /** delete single row from the table: "efficiency_class" */
  delete_efficiency_class_by_pk?: Maybe<efficiency_class>;
  /** delete data from the table: "emission_certificate" */
  delete_emission_certificate?: Maybe<emission_certificate_mutation_response>;
  /** delete single row from the table: "emission_certificate" */
  delete_emission_certificate_by_pk?: Maybe<emission_certificate>;
  /** delete data from the table: "emission_certificate_draft" */
  delete_emission_certificate_draft?: Maybe<emission_certificate_draft_mutation_response>;
  /** delete single row from the table: "emission_certificate_draft" */
  delete_emission_certificate_draft_by_pk?: Maybe<emission_certificate_draft>;
  /** delete data from the table: "emission_factor_origin" */
  delete_emission_factor_origin?: Maybe<emission_factor_origin_mutation_response>;
  /** delete single row from the table: "emission_factor_origin" */
  delete_emission_factor_origin_by_pk?: Maybe<emission_factor_origin>;
  /** delete data from the table: "emission_factor_type" */
  delete_emission_factor_type?: Maybe<emission_factor_type_mutation_response>;
  /** delete single row from the table: "emission_factor_type" */
  delete_emission_factor_type_by_pk?: Maybe<emission_factor_type>;
  /** delete data from the table: "energy_certificate" */
  delete_energy_certificate?: Maybe<energy_certificate_mutation_response>;
  /** delete single row from the table: "energy_certificate" */
  delete_energy_certificate_by_pk?: Maybe<energy_certificate>;
  /** delete data from the table: "energy_certificate_consumer" */
  delete_energy_certificate_consumer?: Maybe<energy_certificate_consumer_mutation_response>;
  /** delete single row from the table: "energy_certificate_consumer" */
  delete_energy_certificate_consumer_by_pk?: Maybe<energy_certificate_consumer>;
  /** delete data from the table: "energy_certificate_draft" */
  delete_energy_certificate_draft?: Maybe<energy_certificate_draft_mutation_response>;
  /** delete single row from the table: "energy_certificate_draft" */
  delete_energy_certificate_draft_by_pk?: Maybe<energy_certificate_draft>;
  /** delete data from the table: "energy_certificate_type" */
  delete_energy_certificate_type?: Maybe<energy_certificate_type_mutation_response>;
  /** delete single row from the table: "energy_certificate_type" */
  delete_energy_certificate_type_by_pk?: Maybe<energy_certificate_type>;
  /** delete data from the table: "energy_consumer" */
  delete_energy_consumer?: Maybe<energy_consumer_mutation_response>;
  /** delete single row from the table: "energy_consumer" */
  delete_energy_consumer_by_pk?: Maybe<energy_consumer>;
  /** delete data from the table: "energy_consumer_technology_type" */
  delete_energy_consumer_technology_type?: Maybe<energy_consumer_technology_type_mutation_response>;
  /** delete single row from the table: "energy_consumer_technology_type" */
  delete_energy_consumer_technology_type_by_pk?: Maybe<energy_consumer_technology_type>;
  /** delete data from the table: "energy_consumer_type" */
  delete_energy_consumer_type?: Maybe<energy_consumer_type_mutation_response>;
  /** delete single row from the table: "energy_consumer_type" */
  delete_energy_consumer_type_by_pk?: Maybe<energy_consumer_type>;
  /** delete data from the table: "energy_distribution" */
  delete_energy_distribution?: Maybe<energy_distribution_mutation_response>;
  /** delete single row from the table: "energy_distribution" */
  delete_energy_distribution_by_pk?: Maybe<energy_distribution>;
  /** delete data from the table: "energy_distribution_type" */
  delete_energy_distribution_type?: Maybe<energy_distribution_type_mutation_response>;
  /** delete single row from the table: "energy_distribution_type" */
  delete_energy_distribution_type_by_pk?: Maybe<energy_distribution_type>;
  /** delete data from the table: "energy_path" */
  delete_energy_path?: Maybe<energy_path_mutation_response>;
  /** delete single row from the table: "energy_path" */
  delete_energy_path_by_pk?: Maybe<energy_path>;
  /** delete data from the table: "energy_producer" */
  delete_energy_producer?: Maybe<energy_producer_mutation_response>;
  /** delete single row from the table: "energy_producer" */
  delete_energy_producer_by_pk?: Maybe<energy_producer>;
  /** delete data from the table: "energy_source" */
  delete_energy_source?: Maybe<energy_source_mutation_response>;
  /** delete single row from the table: "energy_source" */
  delete_energy_source_by_pk?: Maybe<energy_source>;
  /** delete data from the table: "energy_source_type" */
  delete_energy_source_type?: Maybe<energy_source_type_mutation_response>;
  /** delete single row from the table: "energy_source_type" */
  delete_energy_source_type_by_pk?: Maybe<energy_source_type>;
  /** delete data from the table: "energy_standard" */
  delete_energy_standard?: Maybe<energy_standard_mutation_response>;
  /** delete single row from the table: "energy_standard" */
  delete_energy_standard_by_pk?: Maybe<energy_standard>;
  /** delete data from the table: "energy_storage" */
  delete_energy_storage?: Maybe<energy_storage_mutation_response>;
  /** delete single row from the table: "energy_storage" */
  delete_energy_storage_by_pk?: Maybe<energy_storage>;
  /** delete data from the table: "energy_storage_type" */
  delete_energy_storage_type?: Maybe<energy_storage_type_mutation_response>;
  /** delete single row from the table: "energy_storage_type" */
  delete_energy_storage_type_by_pk?: Maybe<energy_storage_type>;
  /** delete data from the table: "energy_system" */
  delete_energy_system?: Maybe<energy_system_mutation_response>;
  /** delete single row from the table: "energy_system" */
  delete_energy_system_by_pk?: Maybe<energy_system>;
  /** delete data from the table: "energy_system_consumer_route" */
  delete_energy_system_consumer_route?: Maybe<energy_system_consumer_route_mutation_response>;
  /** delete single row from the table: "energy_system_consumer_route" */
  delete_energy_system_consumer_route_by_pk?: Maybe<energy_system_consumer_route>;
  /** delete data from the table: "energy_system_producer_route" */
  delete_energy_system_producer_route?: Maybe<energy_system_producer_route_mutation_response>;
  /** delete single row from the table: "energy_system_producer_route" */
  delete_energy_system_producer_route_by_pk?: Maybe<energy_system_producer_route>;
  /** delete data from the table: "energy_system_type" */
  delete_energy_system_type?: Maybe<energy_system_type_mutation_response>;
  /** delete single row from the table: "energy_system_type" */
  delete_energy_system_type_by_pk?: Maybe<energy_system_type>;
  /** delete data from the table: "envelope" */
  delete_envelope?: Maybe<envelope_mutation_response>;
  /** delete single row from the table: "envelope" */
  delete_envelope_by_pk?: Maybe<envelope>;
  /** delete data from the table: "envelope_type" */
  delete_envelope_type?: Maybe<envelope_type_mutation_response>;
  /** delete single row from the table: "envelope_type" */
  delete_envelope_type_by_pk?: Maybe<envelope_type>;
  /** delete data from the table: "eu_taxonomy_compliance" */
  delete_eu_taxonomy_compliance?: Maybe<eu_taxonomy_compliance_mutation_response>;
  /** delete single row from the table: "eu_taxonomy_compliance" */
  delete_eu_taxonomy_compliance_by_pk?: Maybe<eu_taxonomy_compliance>;
  /** delete data from the table: "file" */
  delete_file?: Maybe<file_mutation_response>;
  /** delete single row from the table: "file" */
  delete_file_by_pk?: Maybe<file>;
  /** delete data from the table: "insulation_material_category" */
  delete_insulation_material_category?: Maybe<insulation_material_category_mutation_response>;
  /** delete single row from the table: "insulation_material_category" */
  delete_insulation_material_category_by_pk?: Maybe<insulation_material_category>;
  /** delete data from the table: "insulation_method" */
  delete_insulation_method?: Maybe<insulation_method_mutation_response>;
  /** delete single row from the table: "insulation_method" */
  delete_insulation_method_by_pk?: Maybe<insulation_method>;
  /** delete data from the table: "invoice_payer_type" */
  delete_invoice_payer_type?: Maybe<invoice_payer_type_mutation_response>;
  /** delete single row from the table: "invoice_payer_type" */
  delete_invoice_payer_type_by_pk?: Maybe<invoice_payer_type>;
  /** delete data from the table: "langfuse_datasets_temporary_item" */
  delete_langfuse_datasets_temporary_item?: Maybe<langfuse_datasets_temporary_item_mutation_response>;
  /** delete single row from the table: "langfuse_datasets_temporary_item" */
  delete_langfuse_datasets_temporary_item_by_pk?: Maybe<langfuse_datasets_temporary_item>;
  /** delete data from the table: "language" */
  delete_language?: Maybe<language_mutation_response>;
  /** delete single row from the table: "language" */
  delete_language_by_pk?: Maybe<language>;
  /** delete data from the table: "meter" */
  delete_meter?: Maybe<meter_mutation_response>;
  /** delete single row from the table: "meter" */
  delete_meter_by_pk?: Maybe<meter>;
  /** delete data from the table: "meter_integration" */
  delete_meter_integration?: Maybe<meter_integration_mutation_response>;
  /** delete data from the table: "meter_integration_building" */
  delete_meter_integration_building?: Maybe<meter_integration_building_mutation_response>;
  /** delete single row from the table: "meter_integration_building" */
  delete_meter_integration_building_by_pk?: Maybe<meter_integration_building>;
  /** delete single row from the table: "meter_integration" */
  delete_meter_integration_by_pk?: Maybe<meter_integration>;
  /** delete data from the table: "meter_integration_type" */
  delete_meter_integration_type?: Maybe<meter_integration_type_mutation_response>;
  /** delete single row from the table: "meter_integration_type" */
  delete_meter_integration_type_by_pk?: Maybe<meter_integration_type>;
  /** delete data from the table: "notification" */
  delete_notification?: Maybe<notification_mutation_response>;
  /** delete single row from the table: "notification" */
  delete_notification_by_pk?: Maybe<notification>;
  /** delete data from the table: "notification_template" */
  delete_notification_template?: Maybe<notification_template_mutation_response>;
  /** delete single row from the table: "notification_template" */
  delete_notification_template_by_pk?: Maybe<notification_template>;
  /** delete data from the table: "notification_type" */
  delete_notification_type?: Maybe<notification_type_mutation_response>;
  /** delete single row from the table: "notification_type" */
  delete_notification_type_by_pk?: Maybe<notification_type>;
  /** delete data from the table: "operation_model_type" */
  delete_operation_model_type?: Maybe<operation_model_type_mutation_response>;
  /** delete single row from the table: "operation_model_type" */
  delete_operation_model_type_by_pk?: Maybe<operation_model_type>;
  /** delete data from the table: "org" */
  delete_org?: Maybe<org_mutation_response>;
  /** delete single row from the table: "org" */
  delete_org_by_pk?: Maybe<org>;
  /** delete data from the table: "org_preference" */
  delete_org_preference?: Maybe<org_preference_mutation_response>;
  /** delete single row from the table: "org_preference" */
  delete_org_preference_by_pk?: Maybe<org_preference>;
  /** delete data from the table: "org_status_type" */
  delete_org_status_type?: Maybe<org_status_type_mutation_response>;
  /** delete single row from the table: "org_status_type" */
  delete_org_status_type_by_pk?: Maybe<org_status_type>;
  /** delete data from the table: "portfolio" */
  delete_portfolio?: Maybe<portfolio_mutation_response>;
  /** delete single row from the table: "portfolio" */
  delete_portfolio_by_pk?: Maybe<portfolio>;
  /** delete data from the table: "renovation" */
  delete_renovation?: Maybe<renovation_mutation_response>;
  /** delete single row from the table: "renovation" */
  delete_renovation_by_pk?: Maybe<renovation>;
  /** delete data from the table: "renovation_energy_consumer" */
  delete_renovation_energy_consumer?: Maybe<renovation_energy_consumer_mutation_response>;
  /** delete data from the table: "renovation_energy_distribution" */
  delete_renovation_energy_distribution?: Maybe<renovation_energy_distribution_mutation_response>;
  /** delete data from the table: "renovation_energy_producer" */
  delete_renovation_energy_producer?: Maybe<renovation_energy_producer_mutation_response>;
  /** delete data from the table: "renovation_energy_storage" */
  delete_renovation_energy_storage?: Maybe<renovation_energy_storage_mutation_response>;
  /** delete data from the table: "renovation_energy_system_route" */
  delete_renovation_energy_system_route?: Maybe<renovation_energy_system_route_mutation_response>;
  /** delete data from the table: "renovation_envelope" */
  delete_renovation_envelope?: Maybe<renovation_envelope_mutation_response>;
  /** delete data from the table: "renovation_type" */
  delete_renovation_type?: Maybe<renovation_type_mutation_response>;
  /** delete single row from the table: "renovation_type" */
  delete_renovation_type_by_pk?: Maybe<renovation_type>;
  /** delete data from the table: "report" */
  delete_report?: Maybe<report_mutation_response>;
  /** delete data from the table: "report_answer" */
  delete_report_answer?: Maybe<report_answer_mutation_response>;
  /** delete data from the table: "report_answer_autofill_option" */
  delete_report_answer_autofill_option?: Maybe<report_answer_autofill_option_mutation_response>;
  /** delete single row from the table: "report_answer_autofill_option" */
  delete_report_answer_autofill_option_by_pk?: Maybe<report_answer_autofill_option>;
  /** delete single row from the table: "report_answer" */
  delete_report_answer_by_pk?: Maybe<report_answer>;
  /** delete data from the table: "report_answer_chosen_option" */
  delete_report_answer_chosen_option?: Maybe<report_answer_chosen_option_mutation_response>;
  /** delete single row from the table: "report_answer_chosen_option" */
  delete_report_answer_chosen_option_by_pk?: Maybe<report_answer_chosen_option>;
  /** delete data from the table: "report_answer_state" */
  delete_report_answer_state?: Maybe<report_answer_state_mutation_response>;
  /** delete single row from the table: "report_answer_state" */
  delete_report_answer_state_by_pk?: Maybe<report_answer_state>;
  /** delete single row from the table: "report" */
  delete_report_by_pk?: Maybe<report>;
  /** delete data from the table: "report_evidence" */
  delete_report_evidence?: Maybe<report_evidence_mutation_response>;
  /** delete single row from the table: "report_evidence" */
  delete_report_evidence_by_pk?: Maybe<report_evidence>;
  /** delete data from the table: "report_question_evidence" */
  delete_report_question_evidence?: Maybe<report_question_evidence_mutation_response>;
  /** delete single row from the table: "report_question_evidence" */
  delete_report_question_evidence_by_pk?: Maybe<report_question_evidence>;
  /** delete data from the table: "report_score" */
  delete_report_score?: Maybe<report_score_mutation_response>;
  /** delete single row from the table: "report_score" */
  delete_report_score_by_pk?: Maybe<report_score>;
  /** delete data from the table: "report_section_progress" */
  delete_report_section_progress?: Maybe<report_section_progress_mutation_response>;
  /** delete single row from the table: "report_section_progress" */
  delete_report_section_progress_by_pk?: Maybe<report_section_progress>;
  /** delete data from the table: "report_sub_building" */
  delete_report_sub_building?: Maybe<report_sub_building_mutation_response>;
  /** delete single row from the table: "report_sub_building" */
  delete_report_sub_building_by_pk?: Maybe<report_sub_building>;
  /** delete data from the table: "report_template" */
  delete_report_template?: Maybe<report_template_mutation_response>;
  /** delete single row from the table: "report_template" */
  delete_report_template_by_pk?: Maybe<report_template>;
  /** delete data from the table: "report_template_question" */
  delete_report_template_question?: Maybe<report_template_question_mutation_response>;
  /** delete single row from the table: "report_template_question" */
  delete_report_template_question_by_pk?: Maybe<report_template_question>;
  /** delete data from the table: "report_template_question_option" */
  delete_report_template_question_option?: Maybe<report_template_question_option_mutation_response>;
  /** delete single row from the table: "report_template_question_option" */
  delete_report_template_question_option_by_pk?: Maybe<report_template_question_option>;
  /** delete data from the table: "report_template_question_type" */
  delete_report_template_question_type?: Maybe<report_template_question_type_mutation_response>;
  /** delete single row from the table: "report_template_question_type" */
  delete_report_template_question_type_by_pk?: Maybe<report_template_question_type>;
  /** delete data from the table: "report_template_score" */
  delete_report_template_score?: Maybe<report_template_score_mutation_response>;
  /** delete single row from the table: "report_template_score" */
  delete_report_template_score_by_pk?: Maybe<report_template_score>;
  /** delete data from the table: "report_template_score_display_type" */
  delete_report_template_score_display_type?: Maybe<report_template_score_display_type_mutation_response>;
  /** delete single row from the table: "report_template_score_display_type" */
  delete_report_template_score_display_type_by_pk?: Maybe<report_template_score_display_type>;
  /** delete data from the table: "report_template_section" */
  delete_report_template_section?: Maybe<report_template_section_mutation_response>;
  /** delete single row from the table: "report_template_section" */
  delete_report_template_section_by_pk?: Maybe<report_template_section>;
  /** delete data from the table: "report_template_section_target" */
  delete_report_template_section_target?: Maybe<report_template_section_target_mutation_response>;
  /** delete single row from the table: "report_template_section_target" */
  delete_report_template_section_target_by_pk?: Maybe<report_template_section_target>;
  /** delete data from the table: "role" */
  delete_role?: Maybe<role_mutation_response>;
  /** delete single row from the table: "role" */
  delete_role_by_pk?: Maybe<role>;
  /** delete data from the table: "roof_type" */
  delete_roof_type?: Maybe<roof_type_mutation_response>;
  /** delete single row from the table: "roof_type" */
  delete_roof_type_by_pk?: Maybe<roof_type>;
  /** delete data from the table: "scenario" */
  delete_scenario?: Maybe<scenario_mutation_response>;
  /** delete single row from the table: "scenario" */
  delete_scenario_by_pk?: Maybe<scenario>;
  /** delete data from the table: "sub_building" */
  delete_sub_building?: Maybe<sub_building_mutation_response>;
  /** delete single row from the table: "sub_building" */
  delete_sub_building_by_pk?: Maybe<sub_building>;
  /** delete data from the table: "sub_building_class" */
  delete_sub_building_class?: Maybe<sub_building_class_mutation_response>;
  /** delete single row from the table: "sub_building_class" */
  delete_sub_building_class_by_pk?: Maybe<sub_building_class>;
  /** delete data from the table: "sub_building_subsidy" */
  delete_sub_building_subsidy?: Maybe<sub_building_subsidy_mutation_response>;
  /** delete single row from the table: "sub_building_subsidy" */
  delete_sub_building_subsidy_by_pk?: Maybe<sub_building_subsidy>;
  /** delete data from the table: "subsidy" */
  delete_subsidy?: Maybe<subsidy_mutation_response>;
  /** delete single row from the table: "subsidy" */
  delete_subsidy_by_pk?: Maybe<subsidy>;
  /** delete data from the table: "subsidy_category_type" */
  delete_subsidy_category_type?: Maybe<subsidy_category_type_mutation_response>;
  /** delete single row from the table: "subsidy_category_type" */
  delete_subsidy_category_type_by_pk?: Maybe<subsidy_category_type>;
  /** delete data from the table: "subsidy_region_type" */
  delete_subsidy_region_type?: Maybe<subsidy_region_type_mutation_response>;
  /** delete single row from the table: "subsidy_region_type" */
  delete_subsidy_region_type_by_pk?: Maybe<subsidy_region_type>;
  /** delete data from the table: "subsidy_subsidy_category" */
  delete_subsidy_subsidy_category?: Maybe<subsidy_subsidy_category_mutation_response>;
  /** delete single row from the table: "subsidy_subsidy_category" */
  delete_subsidy_subsidy_category_by_pk?: Maybe<subsidy_subsidy_category>;
  /** delete data from the table: "subsidy_type" */
  delete_subsidy_type?: Maybe<subsidy_type_mutation_response>;
  /** delete single row from the table: "subsidy_type" */
  delete_subsidy_type_by_pk?: Maybe<subsidy_type>;
  /** delete data from the table: "sync_state" */
  delete_sync_state?: Maybe<sync_state_mutation_response>;
  /** delete single row from the table: "sync_state" */
  delete_sync_state_by_pk?: Maybe<sync_state>;
  /** delete data from the table: "target_path" */
  delete_target_path?: Maybe<target_path_mutation_response>;
  /** delete single row from the table: "target_path" */
  delete_target_path_by_pk?: Maybe<target_path>;
  /** delete data from the table: "target_path_template" */
  delete_target_path_template?: Maybe<target_path_template_mutation_response>;
  /** delete single row from the table: "target_path_template" */
  delete_target_path_template_by_pk?: Maybe<target_path_template>;
  /** delete data from the table: "target_path_template_type" */
  delete_target_path_template_type?: Maybe<target_path_template_type_mutation_response>;
  /** delete single row from the table: "target_path_template_type" */
  delete_target_path_template_type_by_pk?: Maybe<target_path_template_type>;
  /** delete data from the table: "tax_bracket" */
  delete_tax_bracket?: Maybe<tax_bracket_mutation_response>;
  /** delete single row from the table: "tax_bracket" */
  delete_tax_bracket_by_pk?: Maybe<tax_bracket>;
  /** delete data from the table: "type_of_use" */
  delete_type_of_use?: Maybe<type_of_use_mutation_response>;
  /** delete single row from the table: "type_of_use" */
  delete_type_of_use_by_pk?: Maybe<type_of_use>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<user_mutation_response>;
  /** delete data from the table: "user_building_permission" */
  delete_user_building_permission?: Maybe<user_building_permission_mutation_response>;
  /** delete single row from the table: "user_building_permission" */
  delete_user_building_permission_by_pk?: Maybe<user_building_permission>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<user>;
  /** delete data from the table: "user_portfolio_permission" */
  delete_user_portfolio_permission?: Maybe<user_portfolio_permission_mutation_response>;
  /** delete single row from the table: "user_portfolio_permission" */
  delete_user_portfolio_permission_by_pk?: Maybe<user_portfolio_permission>;
  /** delete data from the table: "user_role" */
  delete_user_role?: Maybe<user_role_mutation_response>;
  /** delete single row from the table: "user_role" */
  delete_user_role_by_pk?: Maybe<user_role>;
  /** delete data from the table: "window_type" */
  delete_window_type?: Maybe<window_type_mutation_response>;
  /** delete single row from the table: "window_type" */
  delete_window_type_by_pk?: Maybe<window_type>;
  exportReportAsExcel: ExcelExportOutput;
  exportReportAsPdf: ExportReportAsPdfOutput;
  exportScenarioAsExcel: ExcelExportOutput;
  exportSubBuildingsAsExcel: ExcelExportOutput;
  implementAction: ImplementActionOutput;
  /** insert data into the table: "action" */
  insert_action?: Maybe<action_mutation_response>;
  /** insert a single row into the table: "action" */
  insert_action_one?: Maybe<action>;
  /** insert data into the table: "action_plan" */
  insert_action_plan?: Maybe<action_plan_mutation_response>;
  /** insert a single row into the table: "action_plan" */
  insert_action_plan_one?: Maybe<action_plan>;
  /** insert data into the table: "action_subsidy" */
  insert_action_subsidy?: Maybe<action_subsidy_mutation_response>;
  /** insert a single row into the table: "action_subsidy" */
  insert_action_subsidy_one?: Maybe<action_subsidy>;
  /** insert data into the table: "action_subsidy_value_type" */
  insert_action_subsidy_value_type?: Maybe<action_subsidy_value_type_mutation_response>;
  /** insert a single row into the table: "action_subsidy_value_type" */
  insert_action_subsidy_value_type_one?: Maybe<action_subsidy_value_type>;
  /** insert data into the table: "address" */
  insert_address?: Maybe<address_mutation_response>;
  /** insert a single row into the table: "address" */
  insert_address_one?: Maybe<address>;
  /** insert data into the table: "api_user" */
  insert_api_user?: Maybe<api_user_mutation_response>;
  /** insert a single row into the table: "api_user" */
  insert_api_user_one?: Maybe<api_user>;
  /** insert data into the table: "area" */
  insert_area?: Maybe<area_mutation_response>;
  /** insert a single row into the table: "area" */
  insert_area_one?: Maybe<area>;
  /** insert data into the table: "area_system_type" */
  insert_area_system_type?: Maybe<area_system_type_mutation_response>;
  /** insert a single row into the table: "area_system_type" */
  insert_area_system_type_one?: Maybe<area_system_type>;
  /** insert data into the table: "area_type" */
  insert_area_type?: Maybe<area_type_mutation_response>;
  /** insert a single row into the table: "area_type" */
  insert_area_type_one?: Maybe<area_type>;
  /** insert data into the table: "async_job" */
  insert_async_job?: Maybe<async_job_mutation_response>;
  /** insert a single row into the table: "async_job" */
  insert_async_job_one?: Maybe<async_job>;
  /** insert data into the table: "async_job_status_type" */
  insert_async_job_status_type?: Maybe<async_job_status_type_mutation_response>;
  /** insert a single row into the table: "async_job_status_type" */
  insert_async_job_status_type_one?: Maybe<async_job_status_type>;
  /** insert data into the table: "building" */
  insert_building?: Maybe<building_mutation_response>;
  /** insert data into the table: "building_model" */
  insert_building_model?: Maybe<building_model_mutation_response>;
  /** insert a single row into the table: "building_model" */
  insert_building_model_one?: Maybe<building_model>;
  /** insert a single row into the table: "building" */
  insert_building_one?: Maybe<building>;
  /** insert data into the table: "building_state" */
  insert_building_state?: Maybe<building_state_mutation_response>;
  /** insert a single row into the table: "building_state" */
  insert_building_state_one?: Maybe<building_state>;
  /** insert data into the table: "climate_region" */
  insert_climate_region?: Maybe<climate_region_mutation_response>;
  /** insert a single row into the table: "climate_region" */
  insert_climate_region_one?: Maybe<climate_region>;
  /** insert data into the table: "climate_risk" */
  insert_climate_risk?: Maybe<climate_risk_mutation_response>;
  /** insert a single row into the table: "climate_risk" */
  insert_climate_risk_one?: Maybe<climate_risk>;
  /** insert data into the table: "climate_risk_type" */
  insert_climate_risk_type?: Maybe<climate_risk_type_mutation_response>;
  /** insert a single row into the table: "climate_risk_type" */
  insert_climate_risk_type_one?: Maybe<climate_risk_type>;
  /** insert data into the table: "consumption" */
  insert_consumption?: Maybe<consumption_mutation_response>;
  /** insert data into the table: "consumption_draft" */
  insert_consumption_draft?: Maybe<consumption_draft_mutation_response>;
  /** insert a single row into the table: "consumption_draft" */
  insert_consumption_draft_one?: Maybe<consumption_draft>;
  /** insert data into the table: "consumption_frequency_type" */
  insert_consumption_frequency_type?: Maybe<consumption_frequency_type_mutation_response>;
  /** insert a single row into the table: "consumption_frequency_type" */
  insert_consumption_frequency_type_one?: Maybe<consumption_frequency_type>;
  /** insert data into the table: "consumption_invoice" */
  insert_consumption_invoice?: Maybe<consumption_invoice_mutation_response>;
  /** insert data into the table: "consumption_invoice_draft" */
  insert_consumption_invoice_draft?: Maybe<consumption_invoice_draft_mutation_response>;
  /** insert a single row into the table: "consumption_invoice_draft" */
  insert_consumption_invoice_draft_one?: Maybe<consumption_invoice_draft>;
  /** insert a single row into the table: "consumption_invoice" */
  insert_consumption_invoice_one?: Maybe<consumption_invoice>;
  /** insert a single row into the table: "consumption" */
  insert_consumption_one?: Maybe<consumption>;
  /** insert data into the table: "consumption_source_type" */
  insert_consumption_source_type?: Maybe<consumption_source_type_mutation_response>;
  /** insert a single row into the table: "consumption_source_type" */
  insert_consumption_source_type_one?: Maybe<consumption_source_type>;
  /** insert data into the table: "consumption_sub_type" */
  insert_consumption_sub_type?: Maybe<consumption_sub_type_mutation_response>;
  /** insert a single row into the table: "consumption_sub_type" */
  insert_consumption_sub_type_one?: Maybe<consumption_sub_type>;
  /** insert data into the table: "consumption_type" */
  insert_consumption_type?: Maybe<consumption_type_mutation_response>;
  /** insert a single row into the table: "consumption_type" */
  insert_consumption_type_one?: Maybe<consumption_type>;
  /** insert data into the table: "consumption_unit" */
  insert_consumption_unit?: Maybe<consumption_unit_mutation_response>;
  /** insert a single row into the table: "consumption_unit" */
  insert_consumption_unit_one?: Maybe<consumption_unit>;
  /** insert data into the table: "consumption_waste_details" */
  insert_consumption_waste_details?: Maybe<consumption_waste_details_mutation_response>;
  /** insert data into the table: "consumption_waste_details_draft" */
  insert_consumption_waste_details_draft?: Maybe<consumption_waste_details_draft_mutation_response>;
  /** insert a single row into the table: "consumption_waste_details_draft" */
  insert_consumption_waste_details_draft_one?: Maybe<consumption_waste_details_draft>;
  /** insert a single row into the table: "consumption_waste_details" */
  insert_consumption_waste_details_one?: Maybe<consumption_waste_details>;
  /** insert data into the table: "country" */
  insert_country?: Maybe<country_mutation_response>;
  /** insert a single row into the table: "country" */
  insert_country_one?: Maybe<country>;
  /** insert data into the table: "country_state" */
  insert_country_state?: Maybe<country_state_mutation_response>;
  /** insert a single row into the table: "country_state" */
  insert_country_state_one?: Maybe<country_state>;
  /** insert data into the table: "data_source_type" */
  insert_data_source_type?: Maybe<data_source_type_mutation_response>;
  /** insert a single row into the table: "data_source_type" */
  insert_data_source_type_one?: Maybe<data_source_type>;
  /** insert data into the table: "draft_status" */
  insert_draft_status?: Maybe<draft_status_mutation_response>;
  /** insert a single row into the table: "draft_status" */
  insert_draft_status_one?: Maybe<draft_status>;
  /** insert data into the table: "economic_unit" */
  insert_economic_unit?: Maybe<economic_unit_mutation_response>;
  /** insert a single row into the table: "economic_unit" */
  insert_economic_unit_one?: Maybe<economic_unit>;
  /** insert data into the table: "efficiency_class" */
  insert_efficiency_class?: Maybe<efficiency_class_mutation_response>;
  /** insert a single row into the table: "efficiency_class" */
  insert_efficiency_class_one?: Maybe<efficiency_class>;
  /** insert data into the table: "emission_certificate" */
  insert_emission_certificate?: Maybe<emission_certificate_mutation_response>;
  /** insert data into the table: "emission_certificate_draft" */
  insert_emission_certificate_draft?: Maybe<emission_certificate_draft_mutation_response>;
  /** insert a single row into the table: "emission_certificate_draft" */
  insert_emission_certificate_draft_one?: Maybe<emission_certificate_draft>;
  /** insert a single row into the table: "emission_certificate" */
  insert_emission_certificate_one?: Maybe<emission_certificate>;
  /** insert data into the table: "emission_factor_origin" */
  insert_emission_factor_origin?: Maybe<emission_factor_origin_mutation_response>;
  /** insert a single row into the table: "emission_factor_origin" */
  insert_emission_factor_origin_one?: Maybe<emission_factor_origin>;
  /** insert data into the table: "emission_factor_type" */
  insert_emission_factor_type?: Maybe<emission_factor_type_mutation_response>;
  /** insert a single row into the table: "emission_factor_type" */
  insert_emission_factor_type_one?: Maybe<emission_factor_type>;
  /** insert data into the table: "energy_certificate" */
  insert_energy_certificate?: Maybe<energy_certificate_mutation_response>;
  /** insert data into the table: "energy_certificate_consumer" */
  insert_energy_certificate_consumer?: Maybe<energy_certificate_consumer_mutation_response>;
  /** insert a single row into the table: "energy_certificate_consumer" */
  insert_energy_certificate_consumer_one?: Maybe<energy_certificate_consumer>;
  /** insert data into the table: "energy_certificate_draft" */
  insert_energy_certificate_draft?: Maybe<energy_certificate_draft_mutation_response>;
  /** insert a single row into the table: "energy_certificate_draft" */
  insert_energy_certificate_draft_one?: Maybe<energy_certificate_draft>;
  /** insert a single row into the table: "energy_certificate" */
  insert_energy_certificate_one?: Maybe<energy_certificate>;
  /** insert data into the table: "energy_certificate_type" */
  insert_energy_certificate_type?: Maybe<energy_certificate_type_mutation_response>;
  /** insert a single row into the table: "energy_certificate_type" */
  insert_energy_certificate_type_one?: Maybe<energy_certificate_type>;
  /** insert data into the table: "energy_consumer" */
  insert_energy_consumer?: Maybe<energy_consumer_mutation_response>;
  /** insert a single row into the table: "energy_consumer" */
  insert_energy_consumer_one?: Maybe<energy_consumer>;
  /** insert data into the table: "energy_consumer_technology_type" */
  insert_energy_consumer_technology_type?: Maybe<energy_consumer_technology_type_mutation_response>;
  /** insert a single row into the table: "energy_consumer_technology_type" */
  insert_energy_consumer_technology_type_one?: Maybe<energy_consumer_technology_type>;
  /** insert data into the table: "energy_consumer_type" */
  insert_energy_consumer_type?: Maybe<energy_consumer_type_mutation_response>;
  /** insert a single row into the table: "energy_consumer_type" */
  insert_energy_consumer_type_one?: Maybe<energy_consumer_type>;
  /** insert data into the table: "energy_distribution" */
  insert_energy_distribution?: Maybe<energy_distribution_mutation_response>;
  /** insert a single row into the table: "energy_distribution" */
  insert_energy_distribution_one?: Maybe<energy_distribution>;
  /** insert data into the table: "energy_distribution_type" */
  insert_energy_distribution_type?: Maybe<energy_distribution_type_mutation_response>;
  /** insert a single row into the table: "energy_distribution_type" */
  insert_energy_distribution_type_one?: Maybe<energy_distribution_type>;
  /** insert data into the table: "energy_path" */
  insert_energy_path?: Maybe<energy_path_mutation_response>;
  /** insert a single row into the table: "energy_path" */
  insert_energy_path_one?: Maybe<energy_path>;
  /** insert data into the table: "energy_producer" */
  insert_energy_producer?: Maybe<energy_producer_mutation_response>;
  /** insert a single row into the table: "energy_producer" */
  insert_energy_producer_one?: Maybe<energy_producer>;
  /** insert data into the table: "energy_source" */
  insert_energy_source?: Maybe<energy_source_mutation_response>;
  /** insert a single row into the table: "energy_source" */
  insert_energy_source_one?: Maybe<energy_source>;
  /** insert data into the table: "energy_source_type" */
  insert_energy_source_type?: Maybe<energy_source_type_mutation_response>;
  /** insert a single row into the table: "energy_source_type" */
  insert_energy_source_type_one?: Maybe<energy_source_type>;
  /** insert data into the table: "energy_standard" */
  insert_energy_standard?: Maybe<energy_standard_mutation_response>;
  /** insert a single row into the table: "energy_standard" */
  insert_energy_standard_one?: Maybe<energy_standard>;
  /** insert data into the table: "energy_storage" */
  insert_energy_storage?: Maybe<energy_storage_mutation_response>;
  /** insert a single row into the table: "energy_storage" */
  insert_energy_storage_one?: Maybe<energy_storage>;
  /** insert data into the table: "energy_storage_type" */
  insert_energy_storage_type?: Maybe<energy_storage_type_mutation_response>;
  /** insert a single row into the table: "energy_storage_type" */
  insert_energy_storage_type_one?: Maybe<energy_storage_type>;
  /** insert data into the table: "energy_system" */
  insert_energy_system?: Maybe<energy_system_mutation_response>;
  /** insert data into the table: "energy_system_consumer_route" */
  insert_energy_system_consumer_route?: Maybe<energy_system_consumer_route_mutation_response>;
  /** insert a single row into the table: "energy_system_consumer_route" */
  insert_energy_system_consumer_route_one?: Maybe<energy_system_consumer_route>;
  /** insert a single row into the table: "energy_system" */
  insert_energy_system_one?: Maybe<energy_system>;
  /** insert data into the table: "energy_system_producer_route" */
  insert_energy_system_producer_route?: Maybe<energy_system_producer_route_mutation_response>;
  /** insert a single row into the table: "energy_system_producer_route" */
  insert_energy_system_producer_route_one?: Maybe<energy_system_producer_route>;
  /** insert data into the table: "energy_system_type" */
  insert_energy_system_type?: Maybe<energy_system_type_mutation_response>;
  /** insert a single row into the table: "energy_system_type" */
  insert_energy_system_type_one?: Maybe<energy_system_type>;
  /** insert data into the table: "envelope" */
  insert_envelope?: Maybe<envelope_mutation_response>;
  /** insert a single row into the table: "envelope" */
  insert_envelope_one?: Maybe<envelope>;
  /** insert data into the table: "envelope_type" */
  insert_envelope_type?: Maybe<envelope_type_mutation_response>;
  /** insert a single row into the table: "envelope_type" */
  insert_envelope_type_one?: Maybe<envelope_type>;
  /** insert data into the table: "eu_taxonomy_compliance" */
  insert_eu_taxonomy_compliance?: Maybe<eu_taxonomy_compliance_mutation_response>;
  /** insert a single row into the table: "eu_taxonomy_compliance" */
  insert_eu_taxonomy_compliance_one?: Maybe<eu_taxonomy_compliance>;
  /** insert data into the table: "file" */
  insert_file?: Maybe<file_mutation_response>;
  /** insert a single row into the table: "file" */
  insert_file_one?: Maybe<file>;
  /** insert data into the table: "insulation_material_category" */
  insert_insulation_material_category?: Maybe<insulation_material_category_mutation_response>;
  /** insert a single row into the table: "insulation_material_category" */
  insert_insulation_material_category_one?: Maybe<insulation_material_category>;
  /** insert data into the table: "insulation_method" */
  insert_insulation_method?: Maybe<insulation_method_mutation_response>;
  /** insert a single row into the table: "insulation_method" */
  insert_insulation_method_one?: Maybe<insulation_method>;
  /** insert data into the table: "invoice_payer_type" */
  insert_invoice_payer_type?: Maybe<invoice_payer_type_mutation_response>;
  /** insert a single row into the table: "invoice_payer_type" */
  insert_invoice_payer_type_one?: Maybe<invoice_payer_type>;
  /** insert data into the table: "langfuse_datasets_temporary_item" */
  insert_langfuse_datasets_temporary_item?: Maybe<langfuse_datasets_temporary_item_mutation_response>;
  /** insert a single row into the table: "langfuse_datasets_temporary_item" */
  insert_langfuse_datasets_temporary_item_one?: Maybe<langfuse_datasets_temporary_item>;
  /** insert data into the table: "language" */
  insert_language?: Maybe<language_mutation_response>;
  /** insert a single row into the table: "language" */
  insert_language_one?: Maybe<language>;
  /** insert data into the table: "meter" */
  insert_meter?: Maybe<meter_mutation_response>;
  /** insert data into the table: "meter_integration" */
  insert_meter_integration?: Maybe<meter_integration_mutation_response>;
  /** insert data into the table: "meter_integration_building" */
  insert_meter_integration_building?: Maybe<meter_integration_building_mutation_response>;
  /** insert a single row into the table: "meter_integration_building" */
  insert_meter_integration_building_one?: Maybe<meter_integration_building>;
  /** insert a single row into the table: "meter_integration" */
  insert_meter_integration_one?: Maybe<meter_integration>;
  /** insert data into the table: "meter_integration_type" */
  insert_meter_integration_type?: Maybe<meter_integration_type_mutation_response>;
  /** insert a single row into the table: "meter_integration_type" */
  insert_meter_integration_type_one?: Maybe<meter_integration_type>;
  /** insert a single row into the table: "meter" */
  insert_meter_one?: Maybe<meter>;
  /** insert data into the table: "notification" */
  insert_notification?: Maybe<notification_mutation_response>;
  /** insert a single row into the table: "notification" */
  insert_notification_one?: Maybe<notification>;
  /** insert data into the table: "notification_template" */
  insert_notification_template?: Maybe<notification_template_mutation_response>;
  /** insert a single row into the table: "notification_template" */
  insert_notification_template_one?: Maybe<notification_template>;
  /** insert data into the table: "notification_type" */
  insert_notification_type?: Maybe<notification_type_mutation_response>;
  /** insert a single row into the table: "notification_type" */
  insert_notification_type_one?: Maybe<notification_type>;
  /** insert data into the table: "operation_model_type" */
  insert_operation_model_type?: Maybe<operation_model_type_mutation_response>;
  /** insert a single row into the table: "operation_model_type" */
  insert_operation_model_type_one?: Maybe<operation_model_type>;
  /** insert data into the table: "org" */
  insert_org?: Maybe<org_mutation_response>;
  /** insert a single row into the table: "org" */
  insert_org_one?: Maybe<org>;
  /** insert data into the table: "org_preference" */
  insert_org_preference?: Maybe<org_preference_mutation_response>;
  /** insert a single row into the table: "org_preference" */
  insert_org_preference_one?: Maybe<org_preference>;
  /** insert data into the table: "org_status_type" */
  insert_org_status_type?: Maybe<org_status_type_mutation_response>;
  /** insert a single row into the table: "org_status_type" */
  insert_org_status_type_one?: Maybe<org_status_type>;
  /** insert data into the table: "portfolio" */
  insert_portfolio?: Maybe<portfolio_mutation_response>;
  /** insert a single row into the table: "portfolio" */
  insert_portfolio_one?: Maybe<portfolio>;
  /** insert data into the table: "renovation" */
  insert_renovation?: Maybe<renovation_mutation_response>;
  /** insert data into the table: "renovation_energy_consumer" */
  insert_renovation_energy_consumer?: Maybe<renovation_energy_consumer_mutation_response>;
  /** insert a single row into the table: "renovation_energy_consumer" */
  insert_renovation_energy_consumer_one?: Maybe<renovation_energy_consumer>;
  /** insert data into the table: "renovation_energy_distribution" */
  insert_renovation_energy_distribution?: Maybe<renovation_energy_distribution_mutation_response>;
  /** insert a single row into the table: "renovation_energy_distribution" */
  insert_renovation_energy_distribution_one?: Maybe<renovation_energy_distribution>;
  /** insert data into the table: "renovation_energy_producer" */
  insert_renovation_energy_producer?: Maybe<renovation_energy_producer_mutation_response>;
  /** insert a single row into the table: "renovation_energy_producer" */
  insert_renovation_energy_producer_one?: Maybe<renovation_energy_producer>;
  /** insert data into the table: "renovation_energy_storage" */
  insert_renovation_energy_storage?: Maybe<renovation_energy_storage_mutation_response>;
  /** insert a single row into the table: "renovation_energy_storage" */
  insert_renovation_energy_storage_one?: Maybe<renovation_energy_storage>;
  /** insert data into the table: "renovation_energy_system_route" */
  insert_renovation_energy_system_route?: Maybe<renovation_energy_system_route_mutation_response>;
  /** insert a single row into the table: "renovation_energy_system_route" */
  insert_renovation_energy_system_route_one?: Maybe<renovation_energy_system_route>;
  /** insert data into the table: "renovation_envelope" */
  insert_renovation_envelope?: Maybe<renovation_envelope_mutation_response>;
  /** insert a single row into the table: "renovation_envelope" */
  insert_renovation_envelope_one?: Maybe<renovation_envelope>;
  /** insert a single row into the table: "renovation" */
  insert_renovation_one?: Maybe<renovation>;
  /** insert data into the table: "renovation_type" */
  insert_renovation_type?: Maybe<renovation_type_mutation_response>;
  /** insert a single row into the table: "renovation_type" */
  insert_renovation_type_one?: Maybe<renovation_type>;
  /** insert data into the table: "report" */
  insert_report?: Maybe<report_mutation_response>;
  /** insert data into the table: "report_answer" */
  insert_report_answer?: Maybe<report_answer_mutation_response>;
  /** insert data into the table: "report_answer_autofill_option" */
  insert_report_answer_autofill_option?: Maybe<report_answer_autofill_option_mutation_response>;
  /** insert a single row into the table: "report_answer_autofill_option" */
  insert_report_answer_autofill_option_one?: Maybe<report_answer_autofill_option>;
  /** insert data into the table: "report_answer_chosen_option" */
  insert_report_answer_chosen_option?: Maybe<report_answer_chosen_option_mutation_response>;
  /** insert a single row into the table: "report_answer_chosen_option" */
  insert_report_answer_chosen_option_one?: Maybe<report_answer_chosen_option>;
  /** insert a single row into the table: "report_answer" */
  insert_report_answer_one?: Maybe<report_answer>;
  /** insert data into the table: "report_answer_state" */
  insert_report_answer_state?: Maybe<report_answer_state_mutation_response>;
  /** insert a single row into the table: "report_answer_state" */
  insert_report_answer_state_one?: Maybe<report_answer_state>;
  /** insert data into the table: "report_evidence" */
  insert_report_evidence?: Maybe<report_evidence_mutation_response>;
  /** insert a single row into the table: "report_evidence" */
  insert_report_evidence_one?: Maybe<report_evidence>;
  /** insert a single row into the table: "report" */
  insert_report_one?: Maybe<report>;
  /** insert data into the table: "report_question_evidence" */
  insert_report_question_evidence?: Maybe<report_question_evidence_mutation_response>;
  /** insert a single row into the table: "report_question_evidence" */
  insert_report_question_evidence_one?: Maybe<report_question_evidence>;
  /** insert data into the table: "report_score" */
  insert_report_score?: Maybe<report_score_mutation_response>;
  /** insert a single row into the table: "report_score" */
  insert_report_score_one?: Maybe<report_score>;
  /** insert data into the table: "report_section_progress" */
  insert_report_section_progress?: Maybe<report_section_progress_mutation_response>;
  /** insert a single row into the table: "report_section_progress" */
  insert_report_section_progress_one?: Maybe<report_section_progress>;
  /** insert data into the table: "report_sub_building" */
  insert_report_sub_building?: Maybe<report_sub_building_mutation_response>;
  /** insert a single row into the table: "report_sub_building" */
  insert_report_sub_building_one?: Maybe<report_sub_building>;
  /** insert data into the table: "report_template" */
  insert_report_template?: Maybe<report_template_mutation_response>;
  /** insert a single row into the table: "report_template" */
  insert_report_template_one?: Maybe<report_template>;
  /** insert data into the table: "report_template_question" */
  insert_report_template_question?: Maybe<report_template_question_mutation_response>;
  /** insert a single row into the table: "report_template_question" */
  insert_report_template_question_one?: Maybe<report_template_question>;
  /** insert data into the table: "report_template_question_option" */
  insert_report_template_question_option?: Maybe<report_template_question_option_mutation_response>;
  /** insert a single row into the table: "report_template_question_option" */
  insert_report_template_question_option_one?: Maybe<report_template_question_option>;
  /** insert data into the table: "report_template_question_type" */
  insert_report_template_question_type?: Maybe<report_template_question_type_mutation_response>;
  /** insert a single row into the table: "report_template_question_type" */
  insert_report_template_question_type_one?: Maybe<report_template_question_type>;
  /** insert data into the table: "report_template_score" */
  insert_report_template_score?: Maybe<report_template_score_mutation_response>;
  /** insert data into the table: "report_template_score_display_type" */
  insert_report_template_score_display_type?: Maybe<report_template_score_display_type_mutation_response>;
  /** insert a single row into the table: "report_template_score_display_type" */
  insert_report_template_score_display_type_one?: Maybe<report_template_score_display_type>;
  /** insert a single row into the table: "report_template_score" */
  insert_report_template_score_one?: Maybe<report_template_score>;
  /** insert data into the table: "report_template_section" */
  insert_report_template_section?: Maybe<report_template_section_mutation_response>;
  /** insert a single row into the table: "report_template_section" */
  insert_report_template_section_one?: Maybe<report_template_section>;
  /** insert data into the table: "report_template_section_target" */
  insert_report_template_section_target?: Maybe<report_template_section_target_mutation_response>;
  /** insert a single row into the table: "report_template_section_target" */
  insert_report_template_section_target_one?: Maybe<report_template_section_target>;
  /** insert data into the table: "role" */
  insert_role?: Maybe<role_mutation_response>;
  /** insert a single row into the table: "role" */
  insert_role_one?: Maybe<role>;
  /** insert data into the table: "roof_type" */
  insert_roof_type?: Maybe<roof_type_mutation_response>;
  /** insert a single row into the table: "roof_type" */
  insert_roof_type_one?: Maybe<roof_type>;
  /** insert data into the table: "scenario" */
  insert_scenario?: Maybe<scenario_mutation_response>;
  /** insert a single row into the table: "scenario" */
  insert_scenario_one?: Maybe<scenario>;
  /** insert data into the table: "sub_building" */
  insert_sub_building?: Maybe<sub_building_mutation_response>;
  /** insert data into the table: "sub_building_class" */
  insert_sub_building_class?: Maybe<sub_building_class_mutation_response>;
  /** insert a single row into the table: "sub_building_class" */
  insert_sub_building_class_one?: Maybe<sub_building_class>;
  /** insert a single row into the table: "sub_building" */
  insert_sub_building_one?: Maybe<sub_building>;
  /** insert data into the table: "sub_building_subsidy" */
  insert_sub_building_subsidy?: Maybe<sub_building_subsidy_mutation_response>;
  /** insert a single row into the table: "sub_building_subsidy" */
  insert_sub_building_subsidy_one?: Maybe<sub_building_subsidy>;
  /** insert data into the table: "subsidy" */
  insert_subsidy?: Maybe<subsidy_mutation_response>;
  /** insert data into the table: "subsidy_category_type" */
  insert_subsidy_category_type?: Maybe<subsidy_category_type_mutation_response>;
  /** insert a single row into the table: "subsidy_category_type" */
  insert_subsidy_category_type_one?: Maybe<subsidy_category_type>;
  /** insert a single row into the table: "subsidy" */
  insert_subsidy_one?: Maybe<subsidy>;
  /** insert data into the table: "subsidy_region_type" */
  insert_subsidy_region_type?: Maybe<subsidy_region_type_mutation_response>;
  /** insert a single row into the table: "subsidy_region_type" */
  insert_subsidy_region_type_one?: Maybe<subsidy_region_type>;
  /** insert data into the table: "subsidy_subsidy_category" */
  insert_subsidy_subsidy_category?: Maybe<subsidy_subsidy_category_mutation_response>;
  /** insert a single row into the table: "subsidy_subsidy_category" */
  insert_subsidy_subsidy_category_one?: Maybe<subsidy_subsidy_category>;
  /** insert data into the table: "subsidy_type" */
  insert_subsidy_type?: Maybe<subsidy_type_mutation_response>;
  /** insert a single row into the table: "subsidy_type" */
  insert_subsidy_type_one?: Maybe<subsidy_type>;
  /** insert data into the table: "sync_state" */
  insert_sync_state?: Maybe<sync_state_mutation_response>;
  /** insert a single row into the table: "sync_state" */
  insert_sync_state_one?: Maybe<sync_state>;
  /** insert data into the table: "target_path" */
  insert_target_path?: Maybe<target_path_mutation_response>;
  /** insert a single row into the table: "target_path" */
  insert_target_path_one?: Maybe<target_path>;
  /** insert data into the table: "target_path_template" */
  insert_target_path_template?: Maybe<target_path_template_mutation_response>;
  /** insert a single row into the table: "target_path_template" */
  insert_target_path_template_one?: Maybe<target_path_template>;
  /** insert data into the table: "target_path_template_type" */
  insert_target_path_template_type?: Maybe<target_path_template_type_mutation_response>;
  /** insert a single row into the table: "target_path_template_type" */
  insert_target_path_template_type_one?: Maybe<target_path_template_type>;
  /** insert data into the table: "tax_bracket" */
  insert_tax_bracket?: Maybe<tax_bracket_mutation_response>;
  /** insert a single row into the table: "tax_bracket" */
  insert_tax_bracket_one?: Maybe<tax_bracket>;
  /** insert data into the table: "type_of_use" */
  insert_type_of_use?: Maybe<type_of_use_mutation_response>;
  /** insert a single row into the table: "type_of_use" */
  insert_type_of_use_one?: Maybe<type_of_use>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<user_mutation_response>;
  /** insert data into the table: "user_building_permission" */
  insert_user_building_permission?: Maybe<user_building_permission_mutation_response>;
  /** insert a single row into the table: "user_building_permission" */
  insert_user_building_permission_one?: Maybe<user_building_permission>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<user>;
  /** insert data into the table: "user_portfolio_permission" */
  insert_user_portfolio_permission?: Maybe<user_portfolio_permission_mutation_response>;
  /** insert a single row into the table: "user_portfolio_permission" */
  insert_user_portfolio_permission_one?: Maybe<user_portfolio_permission>;
  /** insert data into the table: "user_role" */
  insert_user_role?: Maybe<user_role_mutation_response>;
  /** insert a single row into the table: "user_role" */
  insert_user_role_one?: Maybe<user_role>;
  /** insert data into the table: "window_type" */
  insert_window_type?: Maybe<window_type_mutation_response>;
  /** insert a single row into the table: "window_type" */
  insert_window_type_one?: Maybe<window_type>;
  internalApplyApproximationNoFallback: ApplyApproximationOutput;
  internalApplyApproximationNoFallbackIgnoreScaling: ApplyApproximationOutput;
  internalApplyApproximationWithFallback: ApplyApproximationOutput;
  internalApplyApproximationWithFallbackIgnoreScaling: ApplyApproximationOutput;
  internalApproximate: ApplyApproximationOutput;
  internalApproximateRoofWithGoogleSolarAPI: ApplyApproximationOutput;
  internalCreateGlobalNotification: NotificationTemplateId;
  internalCreateNotificationForUsers: NotificationTemplateId;
  internalCreateOrgsNotification: NotificationTemplateId;
  internalExportScenarioAsExcel: ExcelExportOutput;
  internalExportScenarioAsJson: ExcelExportOutput;
  recalculateAreas: AreaRecalculateMutationResponse;
  removeEmptyEconomicUnits: EconomicUnitOutput;
  resetAnswers: ReportQuestionId;
  setupMeterIntegration: SetupMeterIntegrationOutput;
  simulateAction: SimulateActionOutput;
  syncMeterIntegration: SyncMeterIntegrationOutput;
  updateAreas: AreaUpdateMutationResponse;
  updateConsumptionInvoice: UpdateConsumptionInvoiceOutput;
  updateConsumptionInvoiceDraft: UpdateConsumptionInvoiceDraftOutput;
  updateEmissionCertificate: EmissionCertificateUpdateResponse;
  updateOrgLogo: UpdateOrgLogoOutput;
  updateSelectedIds: UpdateSelectedIdsOutput;
  /** update data of the table: "action" */
  update_action?: Maybe<action_mutation_response>;
  /** update single row of the table: "action" */
  update_action_by_pk?: Maybe<action>;
  /** update multiples rows of table: "action" */
  update_action_many?: Maybe<Array<Maybe<action_mutation_response>>>;
  /** update data of the table: "action_plan" */
  update_action_plan?: Maybe<action_plan_mutation_response>;
  /** update single row of the table: "action_plan" */
  update_action_plan_by_pk?: Maybe<action_plan>;
  /** update multiples rows of table: "action_plan" */
  update_action_plan_many?: Maybe<Array<Maybe<action_plan_mutation_response>>>;
  /** update data of the table: "action_subsidy" */
  update_action_subsidy?: Maybe<action_subsidy_mutation_response>;
  /** update single row of the table: "action_subsidy" */
  update_action_subsidy_by_pk?: Maybe<action_subsidy>;
  /** update multiples rows of table: "action_subsidy" */
  update_action_subsidy_many?: Maybe<Array<Maybe<action_subsidy_mutation_response>>>;
  /** update data of the table: "action_subsidy_value_type" */
  update_action_subsidy_value_type?: Maybe<action_subsidy_value_type_mutation_response>;
  /** update single row of the table: "action_subsidy_value_type" */
  update_action_subsidy_value_type_by_pk?: Maybe<action_subsidy_value_type>;
  /** update multiples rows of table: "action_subsidy_value_type" */
  update_action_subsidy_value_type_many?: Maybe<Array<Maybe<action_subsidy_value_type_mutation_response>>>;
  /** update data of the table: "address" */
  update_address?: Maybe<address_mutation_response>;
  /** update single row of the table: "address" */
  update_address_by_pk?: Maybe<address>;
  /** update multiples rows of table: "address" */
  update_address_many?: Maybe<Array<Maybe<address_mutation_response>>>;
  /** update data of the table: "api_user" */
  update_api_user?: Maybe<api_user_mutation_response>;
  /** update single row of the table: "api_user" */
  update_api_user_by_pk?: Maybe<api_user>;
  /** update multiples rows of table: "api_user" */
  update_api_user_many?: Maybe<Array<Maybe<api_user_mutation_response>>>;
  /** update data of the table: "area" */
  update_area?: Maybe<area_mutation_response>;
  /** update single row of the table: "area" */
  update_area_by_pk?: Maybe<area>;
  /** update multiples rows of table: "area" */
  update_area_many?: Maybe<Array<Maybe<area_mutation_response>>>;
  /** update data of the table: "area_system_type" */
  update_area_system_type?: Maybe<area_system_type_mutation_response>;
  /** update single row of the table: "area_system_type" */
  update_area_system_type_by_pk?: Maybe<area_system_type>;
  /** update multiples rows of table: "area_system_type" */
  update_area_system_type_many?: Maybe<Array<Maybe<area_system_type_mutation_response>>>;
  /** update data of the table: "area_type" */
  update_area_type?: Maybe<area_type_mutation_response>;
  /** update single row of the table: "area_type" */
  update_area_type_by_pk?: Maybe<area_type>;
  /** update multiples rows of table: "area_type" */
  update_area_type_many?: Maybe<Array<Maybe<area_type_mutation_response>>>;
  /** update data of the table: "async_job" */
  update_async_job?: Maybe<async_job_mutation_response>;
  /** update single row of the table: "async_job" */
  update_async_job_by_pk?: Maybe<async_job>;
  /** update multiples rows of table: "async_job" */
  update_async_job_many?: Maybe<Array<Maybe<async_job_mutation_response>>>;
  /** update data of the table: "async_job_status_type" */
  update_async_job_status_type?: Maybe<async_job_status_type_mutation_response>;
  /** update single row of the table: "async_job_status_type" */
  update_async_job_status_type_by_pk?: Maybe<async_job_status_type>;
  /** update multiples rows of table: "async_job_status_type" */
  update_async_job_status_type_many?: Maybe<Array<Maybe<async_job_status_type_mutation_response>>>;
  /** update data of the table: "building" */
  update_building?: Maybe<building_mutation_response>;
  /** update single row of the table: "building" */
  update_building_by_pk?: Maybe<building>;
  /** update multiples rows of table: "building" */
  update_building_many?: Maybe<Array<Maybe<building_mutation_response>>>;
  /** update data of the table: "building_model" */
  update_building_model?: Maybe<building_model_mutation_response>;
  /** update single row of the table: "building_model" */
  update_building_model_by_pk?: Maybe<building_model>;
  /** update multiples rows of table: "building_model" */
  update_building_model_many?: Maybe<Array<Maybe<building_model_mutation_response>>>;
  /** update data of the table: "building_state" */
  update_building_state?: Maybe<building_state_mutation_response>;
  /** update single row of the table: "building_state" */
  update_building_state_by_pk?: Maybe<building_state>;
  /** update multiples rows of table: "building_state" */
  update_building_state_many?: Maybe<Array<Maybe<building_state_mutation_response>>>;
  /** update data of the table: "climate_region" */
  update_climate_region?: Maybe<climate_region_mutation_response>;
  /** update single row of the table: "climate_region" */
  update_climate_region_by_pk?: Maybe<climate_region>;
  /** update multiples rows of table: "climate_region" */
  update_climate_region_many?: Maybe<Array<Maybe<climate_region_mutation_response>>>;
  /** update data of the table: "climate_risk" */
  update_climate_risk?: Maybe<climate_risk_mutation_response>;
  /** update single row of the table: "climate_risk" */
  update_climate_risk_by_pk?: Maybe<climate_risk>;
  /** update multiples rows of table: "climate_risk" */
  update_climate_risk_many?: Maybe<Array<Maybe<climate_risk_mutation_response>>>;
  /** update data of the table: "climate_risk_type" */
  update_climate_risk_type?: Maybe<climate_risk_type_mutation_response>;
  /** update single row of the table: "climate_risk_type" */
  update_climate_risk_type_by_pk?: Maybe<climate_risk_type>;
  /** update multiples rows of table: "climate_risk_type" */
  update_climate_risk_type_many?: Maybe<Array<Maybe<climate_risk_type_mutation_response>>>;
  /** update data of the table: "consumption" */
  update_consumption?: Maybe<consumption_mutation_response>;
  /** update single row of the table: "consumption" */
  update_consumption_by_pk?: Maybe<consumption>;
  /** update data of the table: "consumption_draft" */
  update_consumption_draft?: Maybe<consumption_draft_mutation_response>;
  /** update single row of the table: "consumption_draft" */
  update_consumption_draft_by_pk?: Maybe<consumption_draft>;
  /** update multiples rows of table: "consumption_draft" */
  update_consumption_draft_many?: Maybe<Array<Maybe<consumption_draft_mutation_response>>>;
  /** update data of the table: "consumption_frequency_type" */
  update_consumption_frequency_type?: Maybe<consumption_frequency_type_mutation_response>;
  /** update single row of the table: "consumption_frequency_type" */
  update_consumption_frequency_type_by_pk?: Maybe<consumption_frequency_type>;
  /** update multiples rows of table: "consumption_frequency_type" */
  update_consumption_frequency_type_many?: Maybe<Array<Maybe<consumption_frequency_type_mutation_response>>>;
  /** update data of the table: "consumption_invoice" */
  update_consumption_invoice?: Maybe<consumption_invoice_mutation_response>;
  /** update single row of the table: "consumption_invoice" */
  update_consumption_invoice_by_pk?: Maybe<consumption_invoice>;
  /** update data of the table: "consumption_invoice_draft" */
  update_consumption_invoice_draft?: Maybe<consumption_invoice_draft_mutation_response>;
  /** update single row of the table: "consumption_invoice_draft" */
  update_consumption_invoice_draft_by_pk?: Maybe<consumption_invoice_draft>;
  /** update multiples rows of table: "consumption_invoice_draft" */
  update_consumption_invoice_draft_many?: Maybe<Array<Maybe<consumption_invoice_draft_mutation_response>>>;
  /** update multiples rows of table: "consumption_invoice" */
  update_consumption_invoice_many?: Maybe<Array<Maybe<consumption_invoice_mutation_response>>>;
  /** update multiples rows of table: "consumption" */
  update_consumption_many?: Maybe<Array<Maybe<consumption_mutation_response>>>;
  /** update data of the table: "consumption_source_type" */
  update_consumption_source_type?: Maybe<consumption_source_type_mutation_response>;
  /** update single row of the table: "consumption_source_type" */
  update_consumption_source_type_by_pk?: Maybe<consumption_source_type>;
  /** update multiples rows of table: "consumption_source_type" */
  update_consumption_source_type_many?: Maybe<Array<Maybe<consumption_source_type_mutation_response>>>;
  /** update data of the table: "consumption_sub_type" */
  update_consumption_sub_type?: Maybe<consumption_sub_type_mutation_response>;
  /** update single row of the table: "consumption_sub_type" */
  update_consumption_sub_type_by_pk?: Maybe<consumption_sub_type>;
  /** update multiples rows of table: "consumption_sub_type" */
  update_consumption_sub_type_many?: Maybe<Array<Maybe<consumption_sub_type_mutation_response>>>;
  /** update data of the table: "consumption_type" */
  update_consumption_type?: Maybe<consumption_type_mutation_response>;
  /** update single row of the table: "consumption_type" */
  update_consumption_type_by_pk?: Maybe<consumption_type>;
  /** update multiples rows of table: "consumption_type" */
  update_consumption_type_many?: Maybe<Array<Maybe<consumption_type_mutation_response>>>;
  /** update data of the table: "consumption_unit" */
  update_consumption_unit?: Maybe<consumption_unit_mutation_response>;
  /** update single row of the table: "consumption_unit" */
  update_consumption_unit_by_pk?: Maybe<consumption_unit>;
  /** update multiples rows of table: "consumption_unit" */
  update_consumption_unit_many?: Maybe<Array<Maybe<consumption_unit_mutation_response>>>;
  /** update data of the table: "consumption_waste_details" */
  update_consumption_waste_details?: Maybe<consumption_waste_details_mutation_response>;
  /** update single row of the table: "consumption_waste_details" */
  update_consumption_waste_details_by_pk?: Maybe<consumption_waste_details>;
  /** update data of the table: "consumption_waste_details_draft" */
  update_consumption_waste_details_draft?: Maybe<consumption_waste_details_draft_mutation_response>;
  /** update single row of the table: "consumption_waste_details_draft" */
  update_consumption_waste_details_draft_by_pk?: Maybe<consumption_waste_details_draft>;
  /** update multiples rows of table: "consumption_waste_details_draft" */
  update_consumption_waste_details_draft_many?: Maybe<Array<Maybe<consumption_waste_details_draft_mutation_response>>>;
  /** update multiples rows of table: "consumption_waste_details" */
  update_consumption_waste_details_many?: Maybe<Array<Maybe<consumption_waste_details_mutation_response>>>;
  /** update data of the table: "country" */
  update_country?: Maybe<country_mutation_response>;
  /** update single row of the table: "country" */
  update_country_by_pk?: Maybe<country>;
  /** update multiples rows of table: "country" */
  update_country_many?: Maybe<Array<Maybe<country_mutation_response>>>;
  /** update data of the table: "country_state" */
  update_country_state?: Maybe<country_state_mutation_response>;
  /** update single row of the table: "country_state" */
  update_country_state_by_pk?: Maybe<country_state>;
  /** update multiples rows of table: "country_state" */
  update_country_state_many?: Maybe<Array<Maybe<country_state_mutation_response>>>;
  /** update data of the table: "data_source_type" */
  update_data_source_type?: Maybe<data_source_type_mutation_response>;
  /** update single row of the table: "data_source_type" */
  update_data_source_type_by_pk?: Maybe<data_source_type>;
  /** update multiples rows of table: "data_source_type" */
  update_data_source_type_many?: Maybe<Array<Maybe<data_source_type_mutation_response>>>;
  /** update data of the table: "draft_status" */
  update_draft_status?: Maybe<draft_status_mutation_response>;
  /** update single row of the table: "draft_status" */
  update_draft_status_by_pk?: Maybe<draft_status>;
  /** update multiples rows of table: "draft_status" */
  update_draft_status_many?: Maybe<Array<Maybe<draft_status_mutation_response>>>;
  /** update data of the table: "economic_unit" */
  update_economic_unit?: Maybe<economic_unit_mutation_response>;
  /** update single row of the table: "economic_unit" */
  update_economic_unit_by_pk?: Maybe<economic_unit>;
  /** update multiples rows of table: "economic_unit" */
  update_economic_unit_many?: Maybe<Array<Maybe<economic_unit_mutation_response>>>;
  /** update data of the table: "efficiency_class" */
  update_efficiency_class?: Maybe<efficiency_class_mutation_response>;
  /** update single row of the table: "efficiency_class" */
  update_efficiency_class_by_pk?: Maybe<efficiency_class>;
  /** update multiples rows of table: "efficiency_class" */
  update_efficiency_class_many?: Maybe<Array<Maybe<efficiency_class_mutation_response>>>;
  /** update data of the table: "emission_certificate" */
  update_emission_certificate?: Maybe<emission_certificate_mutation_response>;
  /** update single row of the table: "emission_certificate" */
  update_emission_certificate_by_pk?: Maybe<emission_certificate>;
  /** update data of the table: "emission_certificate_draft" */
  update_emission_certificate_draft?: Maybe<emission_certificate_draft_mutation_response>;
  /** update single row of the table: "emission_certificate_draft" */
  update_emission_certificate_draft_by_pk?: Maybe<emission_certificate_draft>;
  /** update multiples rows of table: "emission_certificate_draft" */
  update_emission_certificate_draft_many?: Maybe<Array<Maybe<emission_certificate_draft_mutation_response>>>;
  /** update multiples rows of table: "emission_certificate" */
  update_emission_certificate_many?: Maybe<Array<Maybe<emission_certificate_mutation_response>>>;
  /** update data of the table: "emission_factor_origin" */
  update_emission_factor_origin?: Maybe<emission_factor_origin_mutation_response>;
  /** update single row of the table: "emission_factor_origin" */
  update_emission_factor_origin_by_pk?: Maybe<emission_factor_origin>;
  /** update multiples rows of table: "emission_factor_origin" */
  update_emission_factor_origin_many?: Maybe<Array<Maybe<emission_factor_origin_mutation_response>>>;
  /** update data of the table: "emission_factor_type" */
  update_emission_factor_type?: Maybe<emission_factor_type_mutation_response>;
  /** update single row of the table: "emission_factor_type" */
  update_emission_factor_type_by_pk?: Maybe<emission_factor_type>;
  /** update multiples rows of table: "emission_factor_type" */
  update_emission_factor_type_many?: Maybe<Array<Maybe<emission_factor_type_mutation_response>>>;
  /** update data of the table: "energy_certificate" */
  update_energy_certificate?: Maybe<energy_certificate_mutation_response>;
  /** update single row of the table: "energy_certificate" */
  update_energy_certificate_by_pk?: Maybe<energy_certificate>;
  /** update data of the table: "energy_certificate_consumer" */
  update_energy_certificate_consumer?: Maybe<energy_certificate_consumer_mutation_response>;
  /** update single row of the table: "energy_certificate_consumer" */
  update_energy_certificate_consumer_by_pk?: Maybe<energy_certificate_consumer>;
  /** update multiples rows of table: "energy_certificate_consumer" */
  update_energy_certificate_consumer_many?: Maybe<Array<Maybe<energy_certificate_consumer_mutation_response>>>;
  /** update data of the table: "energy_certificate_draft" */
  update_energy_certificate_draft?: Maybe<energy_certificate_draft_mutation_response>;
  /** update single row of the table: "energy_certificate_draft" */
  update_energy_certificate_draft_by_pk?: Maybe<energy_certificate_draft>;
  /** update multiples rows of table: "energy_certificate_draft" */
  update_energy_certificate_draft_many?: Maybe<Array<Maybe<energy_certificate_draft_mutation_response>>>;
  /** update multiples rows of table: "energy_certificate" */
  update_energy_certificate_many?: Maybe<Array<Maybe<energy_certificate_mutation_response>>>;
  /** update data of the table: "energy_certificate_type" */
  update_energy_certificate_type?: Maybe<energy_certificate_type_mutation_response>;
  /** update single row of the table: "energy_certificate_type" */
  update_energy_certificate_type_by_pk?: Maybe<energy_certificate_type>;
  /** update multiples rows of table: "energy_certificate_type" */
  update_energy_certificate_type_many?: Maybe<Array<Maybe<energy_certificate_type_mutation_response>>>;
  /** update data of the table: "energy_consumer" */
  update_energy_consumer?: Maybe<energy_consumer_mutation_response>;
  /** update single row of the table: "energy_consumer" */
  update_energy_consumer_by_pk?: Maybe<energy_consumer>;
  /** update multiples rows of table: "energy_consumer" */
  update_energy_consumer_many?: Maybe<Array<Maybe<energy_consumer_mutation_response>>>;
  /** update data of the table: "energy_consumer_technology_type" */
  update_energy_consumer_technology_type?: Maybe<energy_consumer_technology_type_mutation_response>;
  /** update single row of the table: "energy_consumer_technology_type" */
  update_energy_consumer_technology_type_by_pk?: Maybe<energy_consumer_technology_type>;
  /** update multiples rows of table: "energy_consumer_technology_type" */
  update_energy_consumer_technology_type_many?: Maybe<Array<Maybe<energy_consumer_technology_type_mutation_response>>>;
  /** update data of the table: "energy_consumer_type" */
  update_energy_consumer_type?: Maybe<energy_consumer_type_mutation_response>;
  /** update single row of the table: "energy_consumer_type" */
  update_energy_consumer_type_by_pk?: Maybe<energy_consumer_type>;
  /** update multiples rows of table: "energy_consumer_type" */
  update_energy_consumer_type_many?: Maybe<Array<Maybe<energy_consumer_type_mutation_response>>>;
  /** update data of the table: "energy_distribution" */
  update_energy_distribution?: Maybe<energy_distribution_mutation_response>;
  /** update single row of the table: "energy_distribution" */
  update_energy_distribution_by_pk?: Maybe<energy_distribution>;
  /** update multiples rows of table: "energy_distribution" */
  update_energy_distribution_many?: Maybe<Array<Maybe<energy_distribution_mutation_response>>>;
  /** update data of the table: "energy_distribution_type" */
  update_energy_distribution_type?: Maybe<energy_distribution_type_mutation_response>;
  /** update single row of the table: "energy_distribution_type" */
  update_energy_distribution_type_by_pk?: Maybe<energy_distribution_type>;
  /** update multiples rows of table: "energy_distribution_type" */
  update_energy_distribution_type_many?: Maybe<Array<Maybe<energy_distribution_type_mutation_response>>>;
  /** update data of the table: "energy_path" */
  update_energy_path?: Maybe<energy_path_mutation_response>;
  /** update single row of the table: "energy_path" */
  update_energy_path_by_pk?: Maybe<energy_path>;
  /** update multiples rows of table: "energy_path" */
  update_energy_path_many?: Maybe<Array<Maybe<energy_path_mutation_response>>>;
  /** update data of the table: "energy_producer" */
  update_energy_producer?: Maybe<energy_producer_mutation_response>;
  /** update single row of the table: "energy_producer" */
  update_energy_producer_by_pk?: Maybe<energy_producer>;
  /** update multiples rows of table: "energy_producer" */
  update_energy_producer_many?: Maybe<Array<Maybe<energy_producer_mutation_response>>>;
  /** update data of the table: "energy_source" */
  update_energy_source?: Maybe<energy_source_mutation_response>;
  /** update single row of the table: "energy_source" */
  update_energy_source_by_pk?: Maybe<energy_source>;
  /** update multiples rows of table: "energy_source" */
  update_energy_source_many?: Maybe<Array<Maybe<energy_source_mutation_response>>>;
  /** update data of the table: "energy_source_type" */
  update_energy_source_type?: Maybe<energy_source_type_mutation_response>;
  /** update single row of the table: "energy_source_type" */
  update_energy_source_type_by_pk?: Maybe<energy_source_type>;
  /** update multiples rows of table: "energy_source_type" */
  update_energy_source_type_many?: Maybe<Array<Maybe<energy_source_type_mutation_response>>>;
  /** update data of the table: "energy_standard" */
  update_energy_standard?: Maybe<energy_standard_mutation_response>;
  /** update single row of the table: "energy_standard" */
  update_energy_standard_by_pk?: Maybe<energy_standard>;
  /** update multiples rows of table: "energy_standard" */
  update_energy_standard_many?: Maybe<Array<Maybe<energy_standard_mutation_response>>>;
  /** update data of the table: "energy_storage" */
  update_energy_storage?: Maybe<energy_storage_mutation_response>;
  /** update single row of the table: "energy_storage" */
  update_energy_storage_by_pk?: Maybe<energy_storage>;
  /** update multiples rows of table: "energy_storage" */
  update_energy_storage_many?: Maybe<Array<Maybe<energy_storage_mutation_response>>>;
  /** update data of the table: "energy_storage_type" */
  update_energy_storage_type?: Maybe<energy_storage_type_mutation_response>;
  /** update single row of the table: "energy_storage_type" */
  update_energy_storage_type_by_pk?: Maybe<energy_storage_type>;
  /** update multiples rows of table: "energy_storage_type" */
  update_energy_storage_type_many?: Maybe<Array<Maybe<energy_storage_type_mutation_response>>>;
  /** update data of the table: "energy_system" */
  update_energy_system?: Maybe<energy_system_mutation_response>;
  /** update single row of the table: "energy_system" */
  update_energy_system_by_pk?: Maybe<energy_system>;
  /** update data of the table: "energy_system_consumer_route" */
  update_energy_system_consumer_route?: Maybe<energy_system_consumer_route_mutation_response>;
  /** update single row of the table: "energy_system_consumer_route" */
  update_energy_system_consumer_route_by_pk?: Maybe<energy_system_consumer_route>;
  /** update multiples rows of table: "energy_system_consumer_route" */
  update_energy_system_consumer_route_many?: Maybe<Array<Maybe<energy_system_consumer_route_mutation_response>>>;
  /** update multiples rows of table: "energy_system" */
  update_energy_system_many?: Maybe<Array<Maybe<energy_system_mutation_response>>>;
  /** update data of the table: "energy_system_producer_route" */
  update_energy_system_producer_route?: Maybe<energy_system_producer_route_mutation_response>;
  /** update single row of the table: "energy_system_producer_route" */
  update_energy_system_producer_route_by_pk?: Maybe<energy_system_producer_route>;
  /** update multiples rows of table: "energy_system_producer_route" */
  update_energy_system_producer_route_many?: Maybe<Array<Maybe<energy_system_producer_route_mutation_response>>>;
  /** update data of the table: "energy_system_type" */
  update_energy_system_type?: Maybe<energy_system_type_mutation_response>;
  /** update single row of the table: "energy_system_type" */
  update_energy_system_type_by_pk?: Maybe<energy_system_type>;
  /** update multiples rows of table: "energy_system_type" */
  update_energy_system_type_many?: Maybe<Array<Maybe<energy_system_type_mutation_response>>>;
  /** update data of the table: "envelope" */
  update_envelope?: Maybe<envelope_mutation_response>;
  /** update single row of the table: "envelope" */
  update_envelope_by_pk?: Maybe<envelope>;
  /** update multiples rows of table: "envelope" */
  update_envelope_many?: Maybe<Array<Maybe<envelope_mutation_response>>>;
  /** update data of the table: "envelope_type" */
  update_envelope_type?: Maybe<envelope_type_mutation_response>;
  /** update single row of the table: "envelope_type" */
  update_envelope_type_by_pk?: Maybe<envelope_type>;
  /** update multiples rows of table: "envelope_type" */
  update_envelope_type_many?: Maybe<Array<Maybe<envelope_type_mutation_response>>>;
  /** update data of the table: "eu_taxonomy_compliance" */
  update_eu_taxonomy_compliance?: Maybe<eu_taxonomy_compliance_mutation_response>;
  /** update single row of the table: "eu_taxonomy_compliance" */
  update_eu_taxonomy_compliance_by_pk?: Maybe<eu_taxonomy_compliance>;
  /** update multiples rows of table: "eu_taxonomy_compliance" */
  update_eu_taxonomy_compliance_many?: Maybe<Array<Maybe<eu_taxonomy_compliance_mutation_response>>>;
  /** update data of the table: "file" */
  update_file?: Maybe<file_mutation_response>;
  /** update single row of the table: "file" */
  update_file_by_pk?: Maybe<file>;
  /** update multiples rows of table: "file" */
  update_file_many?: Maybe<Array<Maybe<file_mutation_response>>>;
  /** update data of the table: "insulation_material_category" */
  update_insulation_material_category?: Maybe<insulation_material_category_mutation_response>;
  /** update single row of the table: "insulation_material_category" */
  update_insulation_material_category_by_pk?: Maybe<insulation_material_category>;
  /** update multiples rows of table: "insulation_material_category" */
  update_insulation_material_category_many?: Maybe<Array<Maybe<insulation_material_category_mutation_response>>>;
  /** update data of the table: "insulation_method" */
  update_insulation_method?: Maybe<insulation_method_mutation_response>;
  /** update single row of the table: "insulation_method" */
  update_insulation_method_by_pk?: Maybe<insulation_method>;
  /** update multiples rows of table: "insulation_method" */
  update_insulation_method_many?: Maybe<Array<Maybe<insulation_method_mutation_response>>>;
  /** update data of the table: "invoice_payer_type" */
  update_invoice_payer_type?: Maybe<invoice_payer_type_mutation_response>;
  /** update single row of the table: "invoice_payer_type" */
  update_invoice_payer_type_by_pk?: Maybe<invoice_payer_type>;
  /** update multiples rows of table: "invoice_payer_type" */
  update_invoice_payer_type_many?: Maybe<Array<Maybe<invoice_payer_type_mutation_response>>>;
  /** update data of the table: "langfuse_datasets_temporary_item" */
  update_langfuse_datasets_temporary_item?: Maybe<langfuse_datasets_temporary_item_mutation_response>;
  /** update single row of the table: "langfuse_datasets_temporary_item" */
  update_langfuse_datasets_temporary_item_by_pk?: Maybe<langfuse_datasets_temporary_item>;
  /** update multiples rows of table: "langfuse_datasets_temporary_item" */
  update_langfuse_datasets_temporary_item_many?: Maybe<
    Array<Maybe<langfuse_datasets_temporary_item_mutation_response>>
  >;
  /** update data of the table: "language" */
  update_language?: Maybe<language_mutation_response>;
  /** update single row of the table: "language" */
  update_language_by_pk?: Maybe<language>;
  /** update multiples rows of table: "language" */
  update_language_many?: Maybe<Array<Maybe<language_mutation_response>>>;
  /** update data of the table: "meter" */
  update_meter?: Maybe<meter_mutation_response>;
  /** update single row of the table: "meter" */
  update_meter_by_pk?: Maybe<meter>;
  /** update data of the table: "meter_integration" */
  update_meter_integration?: Maybe<meter_integration_mutation_response>;
  /** update data of the table: "meter_integration_building" */
  update_meter_integration_building?: Maybe<meter_integration_building_mutation_response>;
  /** update single row of the table: "meter_integration_building" */
  update_meter_integration_building_by_pk?: Maybe<meter_integration_building>;
  /** update multiples rows of table: "meter_integration_building" */
  update_meter_integration_building_many?: Maybe<Array<Maybe<meter_integration_building_mutation_response>>>;
  /** update single row of the table: "meter_integration" */
  update_meter_integration_by_pk?: Maybe<meter_integration>;
  /** update multiples rows of table: "meter_integration" */
  update_meter_integration_many?: Maybe<Array<Maybe<meter_integration_mutation_response>>>;
  /** update data of the table: "meter_integration_type" */
  update_meter_integration_type?: Maybe<meter_integration_type_mutation_response>;
  /** update single row of the table: "meter_integration_type" */
  update_meter_integration_type_by_pk?: Maybe<meter_integration_type>;
  /** update multiples rows of table: "meter_integration_type" */
  update_meter_integration_type_many?: Maybe<Array<Maybe<meter_integration_type_mutation_response>>>;
  /** update multiples rows of table: "meter" */
  update_meter_many?: Maybe<Array<Maybe<meter_mutation_response>>>;
  /** update data of the table: "notification" */
  update_notification?: Maybe<notification_mutation_response>;
  /** update single row of the table: "notification" */
  update_notification_by_pk?: Maybe<notification>;
  /** update multiples rows of table: "notification" */
  update_notification_many?: Maybe<Array<Maybe<notification_mutation_response>>>;
  /** update data of the table: "notification_template" */
  update_notification_template?: Maybe<notification_template_mutation_response>;
  /** update single row of the table: "notification_template" */
  update_notification_template_by_pk?: Maybe<notification_template>;
  /** update multiples rows of table: "notification_template" */
  update_notification_template_many?: Maybe<Array<Maybe<notification_template_mutation_response>>>;
  /** update data of the table: "notification_type" */
  update_notification_type?: Maybe<notification_type_mutation_response>;
  /** update single row of the table: "notification_type" */
  update_notification_type_by_pk?: Maybe<notification_type>;
  /** update multiples rows of table: "notification_type" */
  update_notification_type_many?: Maybe<Array<Maybe<notification_type_mutation_response>>>;
  /** update data of the table: "operation_model_type" */
  update_operation_model_type?: Maybe<operation_model_type_mutation_response>;
  /** update single row of the table: "operation_model_type" */
  update_operation_model_type_by_pk?: Maybe<operation_model_type>;
  /** update multiples rows of table: "operation_model_type" */
  update_operation_model_type_many?: Maybe<Array<Maybe<operation_model_type_mutation_response>>>;
  /** update data of the table: "org" */
  update_org?: Maybe<org_mutation_response>;
  /** update single row of the table: "org" */
  update_org_by_pk?: Maybe<org>;
  /** update multiples rows of table: "org" */
  update_org_many?: Maybe<Array<Maybe<org_mutation_response>>>;
  /** update data of the table: "org_preference" */
  update_org_preference?: Maybe<org_preference_mutation_response>;
  /** update single row of the table: "org_preference" */
  update_org_preference_by_pk?: Maybe<org_preference>;
  /** update multiples rows of table: "org_preference" */
  update_org_preference_many?: Maybe<Array<Maybe<org_preference_mutation_response>>>;
  /** update data of the table: "org_status_type" */
  update_org_status_type?: Maybe<org_status_type_mutation_response>;
  /** update single row of the table: "org_status_type" */
  update_org_status_type_by_pk?: Maybe<org_status_type>;
  /** update multiples rows of table: "org_status_type" */
  update_org_status_type_many?: Maybe<Array<Maybe<org_status_type_mutation_response>>>;
  /** update data of the table: "portfolio" */
  update_portfolio?: Maybe<portfolio_mutation_response>;
  /** update single row of the table: "portfolio" */
  update_portfolio_by_pk?: Maybe<portfolio>;
  /** update multiples rows of table: "portfolio" */
  update_portfolio_many?: Maybe<Array<Maybe<portfolio_mutation_response>>>;
  /** update data of the table: "renovation" */
  update_renovation?: Maybe<renovation_mutation_response>;
  /** update single row of the table: "renovation" */
  update_renovation_by_pk?: Maybe<renovation>;
  /** update data of the table: "renovation_energy_consumer" */
  update_renovation_energy_consumer?: Maybe<renovation_energy_consumer_mutation_response>;
  /** update multiples rows of table: "renovation_energy_consumer" */
  update_renovation_energy_consumer_many?: Maybe<Array<Maybe<renovation_energy_consumer_mutation_response>>>;
  /** update data of the table: "renovation_energy_distribution" */
  update_renovation_energy_distribution?: Maybe<renovation_energy_distribution_mutation_response>;
  /** update multiples rows of table: "renovation_energy_distribution" */
  update_renovation_energy_distribution_many?: Maybe<Array<Maybe<renovation_energy_distribution_mutation_response>>>;
  /** update data of the table: "renovation_energy_producer" */
  update_renovation_energy_producer?: Maybe<renovation_energy_producer_mutation_response>;
  /** update multiples rows of table: "renovation_energy_producer" */
  update_renovation_energy_producer_many?: Maybe<Array<Maybe<renovation_energy_producer_mutation_response>>>;
  /** update data of the table: "renovation_energy_storage" */
  update_renovation_energy_storage?: Maybe<renovation_energy_storage_mutation_response>;
  /** update multiples rows of table: "renovation_energy_storage" */
  update_renovation_energy_storage_many?: Maybe<Array<Maybe<renovation_energy_storage_mutation_response>>>;
  /** update data of the table: "renovation_energy_system_route" */
  update_renovation_energy_system_route?: Maybe<renovation_energy_system_route_mutation_response>;
  /** update multiples rows of table: "renovation_energy_system_route" */
  update_renovation_energy_system_route_many?: Maybe<Array<Maybe<renovation_energy_system_route_mutation_response>>>;
  /** update data of the table: "renovation_envelope" */
  update_renovation_envelope?: Maybe<renovation_envelope_mutation_response>;
  /** update multiples rows of table: "renovation_envelope" */
  update_renovation_envelope_many?: Maybe<Array<Maybe<renovation_envelope_mutation_response>>>;
  /** update multiples rows of table: "renovation" */
  update_renovation_many?: Maybe<Array<Maybe<renovation_mutation_response>>>;
  /** update data of the table: "renovation_type" */
  update_renovation_type?: Maybe<renovation_type_mutation_response>;
  /** update single row of the table: "renovation_type" */
  update_renovation_type_by_pk?: Maybe<renovation_type>;
  /** update multiples rows of table: "renovation_type" */
  update_renovation_type_many?: Maybe<Array<Maybe<renovation_type_mutation_response>>>;
  /** update data of the table: "report" */
  update_report?: Maybe<report_mutation_response>;
  /** update data of the table: "report_answer" */
  update_report_answer?: Maybe<report_answer_mutation_response>;
  /** update data of the table: "report_answer_autofill_option" */
  update_report_answer_autofill_option?: Maybe<report_answer_autofill_option_mutation_response>;
  /** update single row of the table: "report_answer_autofill_option" */
  update_report_answer_autofill_option_by_pk?: Maybe<report_answer_autofill_option>;
  /** update multiples rows of table: "report_answer_autofill_option" */
  update_report_answer_autofill_option_many?: Maybe<Array<Maybe<report_answer_autofill_option_mutation_response>>>;
  /** update single row of the table: "report_answer" */
  update_report_answer_by_pk?: Maybe<report_answer>;
  /** update data of the table: "report_answer_chosen_option" */
  update_report_answer_chosen_option?: Maybe<report_answer_chosen_option_mutation_response>;
  /** update single row of the table: "report_answer_chosen_option" */
  update_report_answer_chosen_option_by_pk?: Maybe<report_answer_chosen_option>;
  /** update multiples rows of table: "report_answer_chosen_option" */
  update_report_answer_chosen_option_many?: Maybe<Array<Maybe<report_answer_chosen_option_mutation_response>>>;
  /** update multiples rows of table: "report_answer" */
  update_report_answer_many?: Maybe<Array<Maybe<report_answer_mutation_response>>>;
  /** update data of the table: "report_answer_state" */
  update_report_answer_state?: Maybe<report_answer_state_mutation_response>;
  /** update single row of the table: "report_answer_state" */
  update_report_answer_state_by_pk?: Maybe<report_answer_state>;
  /** update multiples rows of table: "report_answer_state" */
  update_report_answer_state_many?: Maybe<Array<Maybe<report_answer_state_mutation_response>>>;
  /** update single row of the table: "report" */
  update_report_by_pk?: Maybe<report>;
  /** update data of the table: "report_evidence" */
  update_report_evidence?: Maybe<report_evidence_mutation_response>;
  /** update single row of the table: "report_evidence" */
  update_report_evidence_by_pk?: Maybe<report_evidence>;
  /** update multiples rows of table: "report_evidence" */
  update_report_evidence_many?: Maybe<Array<Maybe<report_evidence_mutation_response>>>;
  /** update multiples rows of table: "report" */
  update_report_many?: Maybe<Array<Maybe<report_mutation_response>>>;
  /** update data of the table: "report_question_evidence" */
  update_report_question_evidence?: Maybe<report_question_evidence_mutation_response>;
  /** update single row of the table: "report_question_evidence" */
  update_report_question_evidence_by_pk?: Maybe<report_question_evidence>;
  /** update multiples rows of table: "report_question_evidence" */
  update_report_question_evidence_many?: Maybe<Array<Maybe<report_question_evidence_mutation_response>>>;
  /** update data of the table: "report_score" */
  update_report_score?: Maybe<report_score_mutation_response>;
  /** update single row of the table: "report_score" */
  update_report_score_by_pk?: Maybe<report_score>;
  /** update multiples rows of table: "report_score" */
  update_report_score_many?: Maybe<Array<Maybe<report_score_mutation_response>>>;
  /** update data of the table: "report_section_progress" */
  update_report_section_progress?: Maybe<report_section_progress_mutation_response>;
  /** update single row of the table: "report_section_progress" */
  update_report_section_progress_by_pk?: Maybe<report_section_progress>;
  /** update multiples rows of table: "report_section_progress" */
  update_report_section_progress_many?: Maybe<Array<Maybe<report_section_progress_mutation_response>>>;
  /** update data of the table: "report_sub_building" */
  update_report_sub_building?: Maybe<report_sub_building_mutation_response>;
  /** update single row of the table: "report_sub_building" */
  update_report_sub_building_by_pk?: Maybe<report_sub_building>;
  /** update multiples rows of table: "report_sub_building" */
  update_report_sub_building_many?: Maybe<Array<Maybe<report_sub_building_mutation_response>>>;
  /** update data of the table: "report_template" */
  update_report_template?: Maybe<report_template_mutation_response>;
  /** update single row of the table: "report_template" */
  update_report_template_by_pk?: Maybe<report_template>;
  /** update multiples rows of table: "report_template" */
  update_report_template_many?: Maybe<Array<Maybe<report_template_mutation_response>>>;
  /** update data of the table: "report_template_question" */
  update_report_template_question?: Maybe<report_template_question_mutation_response>;
  /** update single row of the table: "report_template_question" */
  update_report_template_question_by_pk?: Maybe<report_template_question>;
  /** update multiples rows of table: "report_template_question" */
  update_report_template_question_many?: Maybe<Array<Maybe<report_template_question_mutation_response>>>;
  /** update data of the table: "report_template_question_option" */
  update_report_template_question_option?: Maybe<report_template_question_option_mutation_response>;
  /** update single row of the table: "report_template_question_option" */
  update_report_template_question_option_by_pk?: Maybe<report_template_question_option>;
  /** update multiples rows of table: "report_template_question_option" */
  update_report_template_question_option_many?: Maybe<Array<Maybe<report_template_question_option_mutation_response>>>;
  /** update data of the table: "report_template_question_type" */
  update_report_template_question_type?: Maybe<report_template_question_type_mutation_response>;
  /** update single row of the table: "report_template_question_type" */
  update_report_template_question_type_by_pk?: Maybe<report_template_question_type>;
  /** update multiples rows of table: "report_template_question_type" */
  update_report_template_question_type_many?: Maybe<Array<Maybe<report_template_question_type_mutation_response>>>;
  /** update data of the table: "report_template_score" */
  update_report_template_score?: Maybe<report_template_score_mutation_response>;
  /** update single row of the table: "report_template_score" */
  update_report_template_score_by_pk?: Maybe<report_template_score>;
  /** update data of the table: "report_template_score_display_type" */
  update_report_template_score_display_type?: Maybe<report_template_score_display_type_mutation_response>;
  /** update single row of the table: "report_template_score_display_type" */
  update_report_template_score_display_type_by_pk?: Maybe<report_template_score_display_type>;
  /** update multiples rows of table: "report_template_score_display_type" */
  update_report_template_score_display_type_many?: Maybe<
    Array<Maybe<report_template_score_display_type_mutation_response>>
  >;
  /** update multiples rows of table: "report_template_score" */
  update_report_template_score_many?: Maybe<Array<Maybe<report_template_score_mutation_response>>>;
  /** update data of the table: "report_template_section" */
  update_report_template_section?: Maybe<report_template_section_mutation_response>;
  /** update single row of the table: "report_template_section" */
  update_report_template_section_by_pk?: Maybe<report_template_section>;
  /** update multiples rows of table: "report_template_section" */
  update_report_template_section_many?: Maybe<Array<Maybe<report_template_section_mutation_response>>>;
  /** update data of the table: "report_template_section_target" */
  update_report_template_section_target?: Maybe<report_template_section_target_mutation_response>;
  /** update single row of the table: "report_template_section_target" */
  update_report_template_section_target_by_pk?: Maybe<report_template_section_target>;
  /** update multiples rows of table: "report_template_section_target" */
  update_report_template_section_target_many?: Maybe<Array<Maybe<report_template_section_target_mutation_response>>>;
  /** update data of the table: "role" */
  update_role?: Maybe<role_mutation_response>;
  /** update single row of the table: "role" */
  update_role_by_pk?: Maybe<role>;
  /** update multiples rows of table: "role" */
  update_role_many?: Maybe<Array<Maybe<role_mutation_response>>>;
  /** update data of the table: "roof_type" */
  update_roof_type?: Maybe<roof_type_mutation_response>;
  /** update single row of the table: "roof_type" */
  update_roof_type_by_pk?: Maybe<roof_type>;
  /** update multiples rows of table: "roof_type" */
  update_roof_type_many?: Maybe<Array<Maybe<roof_type_mutation_response>>>;
  /** update data of the table: "scenario" */
  update_scenario?: Maybe<scenario_mutation_response>;
  /** update single row of the table: "scenario" */
  update_scenario_by_pk?: Maybe<scenario>;
  /** update multiples rows of table: "scenario" */
  update_scenario_many?: Maybe<Array<Maybe<scenario_mutation_response>>>;
  /** update data of the table: "sub_building" */
  update_sub_building?: Maybe<sub_building_mutation_response>;
  /** update single row of the table: "sub_building" */
  update_sub_building_by_pk?: Maybe<sub_building>;
  /** update data of the table: "sub_building_class" */
  update_sub_building_class?: Maybe<sub_building_class_mutation_response>;
  /** update single row of the table: "sub_building_class" */
  update_sub_building_class_by_pk?: Maybe<sub_building_class>;
  /** update multiples rows of table: "sub_building_class" */
  update_sub_building_class_many?: Maybe<Array<Maybe<sub_building_class_mutation_response>>>;
  /** update multiples rows of table: "sub_building" */
  update_sub_building_many?: Maybe<Array<Maybe<sub_building_mutation_response>>>;
  /** update data of the table: "sub_building_subsidy" */
  update_sub_building_subsidy?: Maybe<sub_building_subsidy_mutation_response>;
  /** update single row of the table: "sub_building_subsidy" */
  update_sub_building_subsidy_by_pk?: Maybe<sub_building_subsidy>;
  /** update multiples rows of table: "sub_building_subsidy" */
  update_sub_building_subsidy_many?: Maybe<Array<Maybe<sub_building_subsidy_mutation_response>>>;
  /** update data of the table: "subsidy" */
  update_subsidy?: Maybe<subsidy_mutation_response>;
  /** update single row of the table: "subsidy" */
  update_subsidy_by_pk?: Maybe<subsidy>;
  /** update data of the table: "subsidy_category_type" */
  update_subsidy_category_type?: Maybe<subsidy_category_type_mutation_response>;
  /** update single row of the table: "subsidy_category_type" */
  update_subsidy_category_type_by_pk?: Maybe<subsidy_category_type>;
  /** update multiples rows of table: "subsidy_category_type" */
  update_subsidy_category_type_many?: Maybe<Array<Maybe<subsidy_category_type_mutation_response>>>;
  /** update multiples rows of table: "subsidy" */
  update_subsidy_many?: Maybe<Array<Maybe<subsidy_mutation_response>>>;
  /** update data of the table: "subsidy_region_type" */
  update_subsidy_region_type?: Maybe<subsidy_region_type_mutation_response>;
  /** update single row of the table: "subsidy_region_type" */
  update_subsidy_region_type_by_pk?: Maybe<subsidy_region_type>;
  /** update multiples rows of table: "subsidy_region_type" */
  update_subsidy_region_type_many?: Maybe<Array<Maybe<subsidy_region_type_mutation_response>>>;
  /** update data of the table: "subsidy_subsidy_category" */
  update_subsidy_subsidy_category?: Maybe<subsidy_subsidy_category_mutation_response>;
  /** update single row of the table: "subsidy_subsidy_category" */
  update_subsidy_subsidy_category_by_pk?: Maybe<subsidy_subsidy_category>;
  /** update multiples rows of table: "subsidy_subsidy_category" */
  update_subsidy_subsidy_category_many?: Maybe<Array<Maybe<subsidy_subsidy_category_mutation_response>>>;
  /** update data of the table: "subsidy_type" */
  update_subsidy_type?: Maybe<subsidy_type_mutation_response>;
  /** update single row of the table: "subsidy_type" */
  update_subsidy_type_by_pk?: Maybe<subsidy_type>;
  /** update multiples rows of table: "subsidy_type" */
  update_subsidy_type_many?: Maybe<Array<Maybe<subsidy_type_mutation_response>>>;
  /** update data of the table: "sync_state" */
  update_sync_state?: Maybe<sync_state_mutation_response>;
  /** update single row of the table: "sync_state" */
  update_sync_state_by_pk?: Maybe<sync_state>;
  /** update multiples rows of table: "sync_state" */
  update_sync_state_many?: Maybe<Array<Maybe<sync_state_mutation_response>>>;
  /** update data of the table: "target_path" */
  update_target_path?: Maybe<target_path_mutation_response>;
  /** update single row of the table: "target_path" */
  update_target_path_by_pk?: Maybe<target_path>;
  /** update multiples rows of table: "target_path" */
  update_target_path_many?: Maybe<Array<Maybe<target_path_mutation_response>>>;
  /** update data of the table: "target_path_template" */
  update_target_path_template?: Maybe<target_path_template_mutation_response>;
  /** update single row of the table: "target_path_template" */
  update_target_path_template_by_pk?: Maybe<target_path_template>;
  /** update multiples rows of table: "target_path_template" */
  update_target_path_template_many?: Maybe<Array<Maybe<target_path_template_mutation_response>>>;
  /** update data of the table: "target_path_template_type" */
  update_target_path_template_type?: Maybe<target_path_template_type_mutation_response>;
  /** update single row of the table: "target_path_template_type" */
  update_target_path_template_type_by_pk?: Maybe<target_path_template_type>;
  /** update multiples rows of table: "target_path_template_type" */
  update_target_path_template_type_many?: Maybe<Array<Maybe<target_path_template_type_mutation_response>>>;
  /** update data of the table: "tax_bracket" */
  update_tax_bracket?: Maybe<tax_bracket_mutation_response>;
  /** update single row of the table: "tax_bracket" */
  update_tax_bracket_by_pk?: Maybe<tax_bracket>;
  /** update multiples rows of table: "tax_bracket" */
  update_tax_bracket_many?: Maybe<Array<Maybe<tax_bracket_mutation_response>>>;
  /** update data of the table: "type_of_use" */
  update_type_of_use?: Maybe<type_of_use_mutation_response>;
  /** update single row of the table: "type_of_use" */
  update_type_of_use_by_pk?: Maybe<type_of_use>;
  /** update multiples rows of table: "type_of_use" */
  update_type_of_use_many?: Maybe<Array<Maybe<type_of_use_mutation_response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<user_mutation_response>;
  /** update data of the table: "user_building_permission" */
  update_user_building_permission?: Maybe<user_building_permission_mutation_response>;
  /** update single row of the table: "user_building_permission" */
  update_user_building_permission_by_pk?: Maybe<user_building_permission>;
  /** update multiples rows of table: "user_building_permission" */
  update_user_building_permission_many?: Maybe<Array<Maybe<user_building_permission_mutation_response>>>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<user>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<user_mutation_response>>>;
  /** update data of the table: "user_portfolio_permission" */
  update_user_portfolio_permission?: Maybe<user_portfolio_permission_mutation_response>;
  /** update single row of the table: "user_portfolio_permission" */
  update_user_portfolio_permission_by_pk?: Maybe<user_portfolio_permission>;
  /** update multiples rows of table: "user_portfolio_permission" */
  update_user_portfolio_permission_many?: Maybe<Array<Maybe<user_portfolio_permission_mutation_response>>>;
  /** update data of the table: "user_role" */
  update_user_role?: Maybe<user_role_mutation_response>;
  /** update single row of the table: "user_role" */
  update_user_role_by_pk?: Maybe<user_role>;
  /** update multiples rows of table: "user_role" */
  update_user_role_many?: Maybe<Array<Maybe<user_role_mutation_response>>>;
  /** update data of the table: "window_type" */
  update_window_type?: Maybe<window_type_mutation_response>;
  /** update single row of the table: "window_type" */
  update_window_type_by_pk?: Maybe<window_type>;
  /** update multiples rows of table: "window_type" */
  update_window_type_many?: Maybe<Array<Maybe<window_type_mutation_response>>>;
};

/** mutation root */
export type mutation_rootacceptAutofillReportArgs = {
  report_id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootanswerQuestionsArgs = {
  answers: Array<AnswerParamsInput>;
  report_id: Scalars['Int'];
  sub_building_id?: InputMaybe<Scalars['Int']>;
};

/** mutation root */
export type mutation_rootapplyBuildingModelMutationsArgs = {
  building_model_id: Scalars['Int'];
  energy_consumer_mutations?: InputMaybe<Array<EnergyConsumerMutation>>;
  energy_distribution_mutations?: InputMaybe<Array<EnergyDistributionMutation>>;
  energy_storage_mutations?: InputMaybe<Array<EnergyStorageMutation>>;
  energy_system_mutations: Array<EnergySystemMutation>;
  envelope_mutations: Array<EnvelopeMutation>;
  save_invalid_state: Scalars['Boolean'];
};

/** mutation root */
export type mutation_rootchangeQueueStatusArgs = {
  args: QueueStatusParameterInput;
};

/** mutation root */
export type mutation_rootcreateBuildingFromEnergyCertificateDraftArgs = {
  energy_certificate_draft_id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootcreateConsumptionInvoiceDraftArgs = {
  building_id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootcreateConsumptionInvoiceFromDraftArgs = {
  consumption_invoice_draft_id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootcreateEmissionCertificateFromDraftArgs = {
  draft_id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootcreateEnergyCertificateDraftArgs = {
  economic_unit_id?: InputMaybe<Scalars['Int']>;
  portfolio_id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootcreateEnergyCertificateFileArgs = {
  energy_certificate_id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootcreateEvidenceFromFileArgs = {
  report_id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootcreateReportArgs = {
  parameters: ReportInstantiationOptionsInput;
};

/** mutation root */
export type mutation_rootcreateUserArgs = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  org_id?: InputMaybe<Scalars['Int']>;
  role: role_enum;
};

/** mutation root */
export type mutation_rootdeleteEmissionCertificateArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdeleteUserArgs = {
  transfer_ownership_to_user_id?: InputMaybe<Scalars['Int']>;
  user_id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_actionArgs = {
  where: action_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_action_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_action_planArgs = {
  where: action_plan_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_action_plan_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_action_subsidyArgs = {
  where: action_subsidy_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_action_subsidy_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_action_subsidy_value_typeArgs = {
  where: action_subsidy_value_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_action_subsidy_value_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_addressArgs = {
  where: address_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_address_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_api_userArgs = {
  where: api_user_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_api_user_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_areaArgs = {
  where: area_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_area_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_area_system_typeArgs = {
  where: area_system_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_area_system_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_area_typeArgs = {
  where: area_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_area_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_async_jobArgs = {
  where: async_job_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_async_job_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_async_job_status_typeArgs = {
  where: async_job_status_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_async_job_status_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_buildingArgs = {
  where: building_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_building_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_building_modelArgs = {
  where: building_model_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_building_model_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_building_stateArgs = {
  where: building_state_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_building_state_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_climate_regionArgs = {
  where: climate_region_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_climate_region_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_climate_riskArgs = {
  where: climate_risk_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_climate_risk_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_climate_risk_typeArgs = {
  where: climate_risk_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_climate_risk_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_consumptionArgs = {
  where: consumption_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_consumption_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_consumption_draftArgs = {
  where: consumption_draft_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_consumption_draft_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_consumption_frequency_typeArgs = {
  where: consumption_frequency_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_consumption_frequency_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_consumption_invoiceArgs = {
  where: consumption_invoice_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_consumption_invoice_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_consumption_invoice_draftArgs = {
  where: consumption_invoice_draft_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_consumption_invoice_draft_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_consumption_source_typeArgs = {
  where: consumption_source_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_consumption_source_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_consumption_sub_typeArgs = {
  where: consumption_sub_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_consumption_sub_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_consumption_typeArgs = {
  where: consumption_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_consumption_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_consumption_unitArgs = {
  where: consumption_unit_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_consumption_unit_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_consumption_waste_detailsArgs = {
  where: consumption_waste_details_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_consumption_waste_details_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_consumption_waste_details_draftArgs = {
  where: consumption_waste_details_draft_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_consumption_waste_details_draft_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_countryArgs = {
  where: country_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_country_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_country_stateArgs = {
  where: country_state_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_country_state_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_data_source_typeArgs = {
  where: data_source_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_data_source_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_draft_statusArgs = {
  where: draft_status_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_draft_status_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_economic_unitArgs = {
  where: economic_unit_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_economic_unit_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_efficiency_classArgs = {
  where: efficiency_class_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_efficiency_class_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_emission_certificateArgs = {
  where: emission_certificate_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_emission_certificate_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_emission_certificate_draftArgs = {
  where: emission_certificate_draft_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_emission_certificate_draft_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_emission_factor_originArgs = {
  where: emission_factor_origin_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_emission_factor_origin_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_emission_factor_typeArgs = {
  where: emission_factor_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_emission_factor_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_energy_certificateArgs = {
  where: energy_certificate_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_energy_certificate_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_energy_certificate_consumerArgs = {
  where: energy_certificate_consumer_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_energy_certificate_consumer_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_energy_certificate_draftArgs = {
  where: energy_certificate_draft_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_energy_certificate_draft_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_energy_certificate_typeArgs = {
  where: energy_certificate_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_energy_certificate_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_energy_consumerArgs = {
  where: energy_consumer_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_energy_consumer_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_energy_consumer_technology_typeArgs = {
  where: energy_consumer_technology_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_energy_consumer_technology_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_energy_consumer_typeArgs = {
  where: energy_consumer_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_energy_consumer_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_energy_distributionArgs = {
  where: energy_distribution_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_energy_distribution_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_energy_distribution_typeArgs = {
  where: energy_distribution_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_energy_distribution_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_energy_pathArgs = {
  where: energy_path_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_energy_path_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_energy_producerArgs = {
  where: energy_producer_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_energy_producer_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_energy_sourceArgs = {
  where: energy_source_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_energy_source_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_energy_source_typeArgs = {
  where: energy_source_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_energy_source_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_energy_standardArgs = {
  where: energy_standard_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_energy_standard_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_energy_storageArgs = {
  where: energy_storage_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_energy_storage_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_energy_storage_typeArgs = {
  where: energy_storage_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_energy_storage_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_energy_systemArgs = {
  where: energy_system_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_energy_system_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_energy_system_consumer_routeArgs = {
  where: energy_system_consumer_route_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_energy_system_consumer_route_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_energy_system_producer_routeArgs = {
  where: energy_system_producer_route_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_energy_system_producer_route_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_energy_system_typeArgs = {
  where: energy_system_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_energy_system_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_envelopeArgs = {
  where: envelope_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_envelope_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_envelope_typeArgs = {
  where: envelope_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_envelope_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_eu_taxonomy_complianceArgs = {
  where: eu_taxonomy_compliance_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_eu_taxonomy_compliance_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_fileArgs = {
  where: file_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_file_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_insulation_material_categoryArgs = {
  where: insulation_material_category_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_insulation_material_category_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_insulation_methodArgs = {
  where: insulation_method_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_insulation_method_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_invoice_payer_typeArgs = {
  where: invoice_payer_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_invoice_payer_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_langfuse_datasets_temporary_itemArgs = {
  where: langfuse_datasets_temporary_item_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_langfuse_datasets_temporary_item_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_languageArgs = {
  where: language_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_language_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_meterArgs = {
  where: meter_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_meter_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_meter_integrationArgs = {
  where: meter_integration_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_meter_integration_buildingArgs = {
  where: meter_integration_building_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_meter_integration_building_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_meter_integration_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_meter_integration_typeArgs = {
  where: meter_integration_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_meter_integration_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_notificationArgs = {
  where: notification_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_notification_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_notification_templateArgs = {
  where: notification_template_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_notification_template_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_notification_typeArgs = {
  where: notification_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_notification_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_operation_model_typeArgs = {
  where: operation_model_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_operation_model_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_orgArgs = {
  where: org_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_org_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_org_preferenceArgs = {
  where: org_preference_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_org_preference_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_org_status_typeArgs = {
  where: org_status_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_org_status_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_portfolioArgs = {
  where: portfolio_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_portfolio_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_renovationArgs = {
  where: renovation_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_renovation_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_renovation_energy_consumerArgs = {
  where: renovation_energy_consumer_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_renovation_energy_distributionArgs = {
  where: renovation_energy_distribution_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_renovation_energy_producerArgs = {
  where: renovation_energy_producer_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_renovation_energy_storageArgs = {
  where: renovation_energy_storage_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_renovation_energy_system_routeArgs = {
  where: renovation_energy_system_route_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_renovation_envelopeArgs = {
  where: renovation_envelope_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_renovation_typeArgs = {
  where: renovation_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_renovation_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_reportArgs = {
  where: report_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_report_answerArgs = {
  where: report_answer_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_report_answer_autofill_optionArgs = {
  where: report_answer_autofill_option_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_report_answer_autofill_option_by_pkArgs = {
  report_answer_id: Scalars['Int'];
  report_template_question_option_id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_report_answer_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_report_answer_chosen_optionArgs = {
  where: report_answer_chosen_option_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_report_answer_chosen_option_by_pkArgs = {
  report_answer_id: Scalars['Int'];
  report_template_question_option_id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_report_answer_stateArgs = {
  where: report_answer_state_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_report_answer_state_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_report_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_report_evidenceArgs = {
  where: report_evidence_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_report_evidence_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_report_question_evidenceArgs = {
  where: report_question_evidence_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_report_question_evidence_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_report_scoreArgs = {
  where: report_score_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_report_score_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_report_section_progressArgs = {
  where: report_section_progress_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_report_section_progress_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_report_sub_buildingArgs = {
  where: report_sub_building_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_report_sub_building_by_pkArgs = {
  report_id: Scalars['Int'];
  sub_building_id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_report_templateArgs = {
  where: report_template_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_report_template_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_report_template_questionArgs = {
  where: report_template_question_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_report_template_question_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_report_template_question_optionArgs = {
  where: report_template_question_option_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_report_template_question_option_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_report_template_question_typeArgs = {
  where: report_template_question_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_report_template_question_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_report_template_scoreArgs = {
  where: report_template_score_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_report_template_score_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_report_template_score_display_typeArgs = {
  where: report_template_score_display_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_report_template_score_display_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_report_template_sectionArgs = {
  where: report_template_section_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_report_template_section_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_report_template_section_targetArgs = {
  where: report_template_section_target_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_report_template_section_target_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_roleArgs = {
  where: role_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_role_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_roof_typeArgs = {
  where: roof_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_roof_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_scenarioArgs = {
  where: scenario_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_scenario_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_sub_buildingArgs = {
  where: sub_building_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_sub_building_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_sub_building_classArgs = {
  where: sub_building_class_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_sub_building_class_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_sub_building_subsidyArgs = {
  where: sub_building_subsidy_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_sub_building_subsidy_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_subsidyArgs = {
  where: subsidy_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_subsidy_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_subsidy_category_typeArgs = {
  where: subsidy_category_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_subsidy_category_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_subsidy_region_typeArgs = {
  where: subsidy_region_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_subsidy_region_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_subsidy_subsidy_categoryArgs = {
  where: subsidy_subsidy_category_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_subsidy_subsidy_category_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_subsidy_typeArgs = {
  where: subsidy_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_subsidy_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_sync_stateArgs = {
  where: sync_state_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_sync_state_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_target_pathArgs = {
  where: target_path_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_target_path_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_target_path_templateArgs = {
  where: target_path_template_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_target_path_template_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_target_path_template_typeArgs = {
  where: target_path_template_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_target_path_template_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_tax_bracketArgs = {
  where: tax_bracket_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_tax_bracket_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_type_of_useArgs = {
  where: type_of_use_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_type_of_use_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootdelete_userArgs = {
  where: user_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_user_building_permissionArgs = {
  where: user_building_permission_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_user_building_permission_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_user_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_user_portfolio_permissionArgs = {
  where: user_portfolio_permission_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_user_portfolio_permission_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_user_roleArgs = {
  where: user_role_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_user_role_by_pkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootdelete_window_typeArgs = {
  where: window_type_bool_exp;
};

/** mutation root */
export type mutation_rootdelete_window_type_by_pkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type mutation_rootexportReportAsExcelArgs = {
  report_id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootexportReportAsPdfArgs = {
  report_id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootexportScenarioAsExcelArgs = {
  scenario_id?: InputMaybe<Scalars['Int']>;
};

/** mutation root */
export type mutation_rootexportSubBuildingsAsExcelArgs = {
  building_ids?: InputMaybe<Array<Scalars['Int']>>;
  portfolio_id?: InputMaybe<Scalars['Int']>;
  scenario_id?: InputMaybe<Scalars['Int']>;
};

/** mutation root */
export type mutation_rootimplementActionArgs = {
  action_plan_id: Scalars['Int'];
  customCost?: InputMaybe<CustomCostInput>;
  energy_system_route_renovation_parameter?: InputMaybe<EnergySystemRouteRenovationParameterInput>;
  envelope_renovation_parameter?: InputMaybe<EnvelopeRenovationParameterInput>;
  hydraulic_balancing_renovation_parameter?: InputMaybe<HydraulicBalancingRenovationParameterInput>;
  implementation_from: Scalars['DateTime'];
  implementation_to: Scalars['DateTime'];
  renovation_ids: Array<Scalars['Int']>;
  solar_renovation_parameter?: InputMaybe<SolarRenovationParameterInput>;
  subsidies?: Array<SubsidyInput>;
  system_renovation_parameter?: InputMaybe<SystemRenovationParameterInput>;
};

/** mutation root */
export type mutation_rootinsert_actionArgs = {
  objects: Array<action_insert_input>;
  on_conflict?: InputMaybe<action_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_action_oneArgs = {
  object: action_insert_input;
  on_conflict?: InputMaybe<action_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_action_planArgs = {
  objects: Array<action_plan_insert_input>;
  on_conflict?: InputMaybe<action_plan_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_action_plan_oneArgs = {
  object: action_plan_insert_input;
  on_conflict?: InputMaybe<action_plan_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_action_subsidyArgs = {
  objects: Array<action_subsidy_insert_input>;
  on_conflict?: InputMaybe<action_subsidy_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_action_subsidy_oneArgs = {
  object: action_subsidy_insert_input;
  on_conflict?: InputMaybe<action_subsidy_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_action_subsidy_value_typeArgs = {
  objects: Array<action_subsidy_value_type_insert_input>;
  on_conflict?: InputMaybe<action_subsidy_value_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_action_subsidy_value_type_oneArgs = {
  object: action_subsidy_value_type_insert_input;
  on_conflict?: InputMaybe<action_subsidy_value_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_addressArgs = {
  objects: Array<address_insert_input>;
  on_conflict?: InputMaybe<address_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_address_oneArgs = {
  object: address_insert_input;
  on_conflict?: InputMaybe<address_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_api_userArgs = {
  objects: Array<api_user_insert_input>;
  on_conflict?: InputMaybe<api_user_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_api_user_oneArgs = {
  object: api_user_insert_input;
  on_conflict?: InputMaybe<api_user_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_areaArgs = {
  objects: Array<area_insert_input>;
  on_conflict?: InputMaybe<area_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_area_oneArgs = {
  object: area_insert_input;
  on_conflict?: InputMaybe<area_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_area_system_typeArgs = {
  objects: Array<area_system_type_insert_input>;
  on_conflict?: InputMaybe<area_system_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_area_system_type_oneArgs = {
  object: area_system_type_insert_input;
  on_conflict?: InputMaybe<area_system_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_area_typeArgs = {
  objects: Array<area_type_insert_input>;
  on_conflict?: InputMaybe<area_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_area_type_oneArgs = {
  object: area_type_insert_input;
  on_conflict?: InputMaybe<area_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_async_jobArgs = {
  objects: Array<async_job_insert_input>;
  on_conflict?: InputMaybe<async_job_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_async_job_oneArgs = {
  object: async_job_insert_input;
  on_conflict?: InputMaybe<async_job_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_async_job_status_typeArgs = {
  objects: Array<async_job_status_type_insert_input>;
  on_conflict?: InputMaybe<async_job_status_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_async_job_status_type_oneArgs = {
  object: async_job_status_type_insert_input;
  on_conflict?: InputMaybe<async_job_status_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_buildingArgs = {
  objects: Array<building_insert_input>;
  on_conflict?: InputMaybe<building_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_building_modelArgs = {
  objects: Array<building_model_insert_input>;
  on_conflict?: InputMaybe<building_model_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_building_model_oneArgs = {
  object: building_model_insert_input;
  on_conflict?: InputMaybe<building_model_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_building_oneArgs = {
  object: building_insert_input;
  on_conflict?: InputMaybe<building_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_building_stateArgs = {
  objects: Array<building_state_insert_input>;
  on_conflict?: InputMaybe<building_state_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_building_state_oneArgs = {
  object: building_state_insert_input;
  on_conflict?: InputMaybe<building_state_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_climate_regionArgs = {
  objects: Array<climate_region_insert_input>;
  on_conflict?: InputMaybe<climate_region_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_climate_region_oneArgs = {
  object: climate_region_insert_input;
  on_conflict?: InputMaybe<climate_region_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_climate_riskArgs = {
  objects: Array<climate_risk_insert_input>;
  on_conflict?: InputMaybe<climate_risk_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_climate_risk_oneArgs = {
  object: climate_risk_insert_input;
  on_conflict?: InputMaybe<climate_risk_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_climate_risk_typeArgs = {
  objects: Array<climate_risk_type_insert_input>;
  on_conflict?: InputMaybe<climate_risk_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_climate_risk_type_oneArgs = {
  object: climate_risk_type_insert_input;
  on_conflict?: InputMaybe<climate_risk_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_consumptionArgs = {
  objects: Array<consumption_insert_input>;
  on_conflict?: InputMaybe<consumption_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_consumption_draftArgs = {
  objects: Array<consumption_draft_insert_input>;
  on_conflict?: InputMaybe<consumption_draft_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_consumption_draft_oneArgs = {
  object: consumption_draft_insert_input;
  on_conflict?: InputMaybe<consumption_draft_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_consumption_frequency_typeArgs = {
  objects: Array<consumption_frequency_type_insert_input>;
  on_conflict?: InputMaybe<consumption_frequency_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_consumption_frequency_type_oneArgs = {
  object: consumption_frequency_type_insert_input;
  on_conflict?: InputMaybe<consumption_frequency_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_consumption_invoiceArgs = {
  objects: Array<consumption_invoice_insert_input>;
  on_conflict?: InputMaybe<consumption_invoice_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_consumption_invoice_draftArgs = {
  objects: Array<consumption_invoice_draft_insert_input>;
  on_conflict?: InputMaybe<consumption_invoice_draft_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_consumption_invoice_draft_oneArgs = {
  object: consumption_invoice_draft_insert_input;
  on_conflict?: InputMaybe<consumption_invoice_draft_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_consumption_invoice_oneArgs = {
  object: consumption_invoice_insert_input;
  on_conflict?: InputMaybe<consumption_invoice_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_consumption_oneArgs = {
  object: consumption_insert_input;
  on_conflict?: InputMaybe<consumption_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_consumption_source_typeArgs = {
  objects: Array<consumption_source_type_insert_input>;
  on_conflict?: InputMaybe<consumption_source_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_consumption_source_type_oneArgs = {
  object: consumption_source_type_insert_input;
  on_conflict?: InputMaybe<consumption_source_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_consumption_sub_typeArgs = {
  objects: Array<consumption_sub_type_insert_input>;
  on_conflict?: InputMaybe<consumption_sub_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_consumption_sub_type_oneArgs = {
  object: consumption_sub_type_insert_input;
  on_conflict?: InputMaybe<consumption_sub_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_consumption_typeArgs = {
  objects: Array<consumption_type_insert_input>;
  on_conflict?: InputMaybe<consumption_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_consumption_type_oneArgs = {
  object: consumption_type_insert_input;
  on_conflict?: InputMaybe<consumption_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_consumption_unitArgs = {
  objects: Array<consumption_unit_insert_input>;
  on_conflict?: InputMaybe<consumption_unit_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_consumption_unit_oneArgs = {
  object: consumption_unit_insert_input;
  on_conflict?: InputMaybe<consumption_unit_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_consumption_waste_detailsArgs = {
  objects: Array<consumption_waste_details_insert_input>;
  on_conflict?: InputMaybe<consumption_waste_details_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_consumption_waste_details_draftArgs = {
  objects: Array<consumption_waste_details_draft_insert_input>;
  on_conflict?: InputMaybe<consumption_waste_details_draft_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_consumption_waste_details_draft_oneArgs = {
  object: consumption_waste_details_draft_insert_input;
  on_conflict?: InputMaybe<consumption_waste_details_draft_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_consumption_waste_details_oneArgs = {
  object: consumption_waste_details_insert_input;
  on_conflict?: InputMaybe<consumption_waste_details_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_countryArgs = {
  objects: Array<country_insert_input>;
  on_conflict?: InputMaybe<country_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_country_oneArgs = {
  object: country_insert_input;
  on_conflict?: InputMaybe<country_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_country_stateArgs = {
  objects: Array<country_state_insert_input>;
  on_conflict?: InputMaybe<country_state_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_country_state_oneArgs = {
  object: country_state_insert_input;
  on_conflict?: InputMaybe<country_state_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_data_source_typeArgs = {
  objects: Array<data_source_type_insert_input>;
  on_conflict?: InputMaybe<data_source_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_data_source_type_oneArgs = {
  object: data_source_type_insert_input;
  on_conflict?: InputMaybe<data_source_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_draft_statusArgs = {
  objects: Array<draft_status_insert_input>;
  on_conflict?: InputMaybe<draft_status_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_draft_status_oneArgs = {
  object: draft_status_insert_input;
  on_conflict?: InputMaybe<draft_status_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_economic_unitArgs = {
  objects: Array<economic_unit_insert_input>;
  on_conflict?: InputMaybe<economic_unit_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_economic_unit_oneArgs = {
  object: economic_unit_insert_input;
  on_conflict?: InputMaybe<economic_unit_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_efficiency_classArgs = {
  objects: Array<efficiency_class_insert_input>;
  on_conflict?: InputMaybe<efficiency_class_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_efficiency_class_oneArgs = {
  object: efficiency_class_insert_input;
  on_conflict?: InputMaybe<efficiency_class_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_emission_certificateArgs = {
  objects: Array<emission_certificate_insert_input>;
  on_conflict?: InputMaybe<emission_certificate_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_emission_certificate_draftArgs = {
  objects: Array<emission_certificate_draft_insert_input>;
  on_conflict?: InputMaybe<emission_certificate_draft_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_emission_certificate_draft_oneArgs = {
  object: emission_certificate_draft_insert_input;
  on_conflict?: InputMaybe<emission_certificate_draft_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_emission_certificate_oneArgs = {
  object: emission_certificate_insert_input;
  on_conflict?: InputMaybe<emission_certificate_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_emission_factor_originArgs = {
  objects: Array<emission_factor_origin_insert_input>;
  on_conflict?: InputMaybe<emission_factor_origin_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_emission_factor_origin_oneArgs = {
  object: emission_factor_origin_insert_input;
  on_conflict?: InputMaybe<emission_factor_origin_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_emission_factor_typeArgs = {
  objects: Array<emission_factor_type_insert_input>;
  on_conflict?: InputMaybe<emission_factor_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_emission_factor_type_oneArgs = {
  object: emission_factor_type_insert_input;
  on_conflict?: InputMaybe<emission_factor_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_certificateArgs = {
  objects: Array<energy_certificate_insert_input>;
  on_conflict?: InputMaybe<energy_certificate_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_certificate_consumerArgs = {
  objects: Array<energy_certificate_consumer_insert_input>;
  on_conflict?: InputMaybe<energy_certificate_consumer_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_certificate_consumer_oneArgs = {
  object: energy_certificate_consumer_insert_input;
  on_conflict?: InputMaybe<energy_certificate_consumer_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_certificate_draftArgs = {
  objects: Array<energy_certificate_draft_insert_input>;
  on_conflict?: InputMaybe<energy_certificate_draft_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_certificate_draft_oneArgs = {
  object: energy_certificate_draft_insert_input;
  on_conflict?: InputMaybe<energy_certificate_draft_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_certificate_oneArgs = {
  object: energy_certificate_insert_input;
  on_conflict?: InputMaybe<energy_certificate_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_certificate_typeArgs = {
  objects: Array<energy_certificate_type_insert_input>;
  on_conflict?: InputMaybe<energy_certificate_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_certificate_type_oneArgs = {
  object: energy_certificate_type_insert_input;
  on_conflict?: InputMaybe<energy_certificate_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_consumerArgs = {
  objects: Array<energy_consumer_insert_input>;
  on_conflict?: InputMaybe<energy_consumer_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_consumer_oneArgs = {
  object: energy_consumer_insert_input;
  on_conflict?: InputMaybe<energy_consumer_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_consumer_technology_typeArgs = {
  objects: Array<energy_consumer_technology_type_insert_input>;
  on_conflict?: InputMaybe<energy_consumer_technology_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_consumer_technology_type_oneArgs = {
  object: energy_consumer_technology_type_insert_input;
  on_conflict?: InputMaybe<energy_consumer_technology_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_consumer_typeArgs = {
  objects: Array<energy_consumer_type_insert_input>;
  on_conflict?: InputMaybe<energy_consumer_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_consumer_type_oneArgs = {
  object: energy_consumer_type_insert_input;
  on_conflict?: InputMaybe<energy_consumer_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_distributionArgs = {
  objects: Array<energy_distribution_insert_input>;
  on_conflict?: InputMaybe<energy_distribution_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_distribution_oneArgs = {
  object: energy_distribution_insert_input;
  on_conflict?: InputMaybe<energy_distribution_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_distribution_typeArgs = {
  objects: Array<energy_distribution_type_insert_input>;
  on_conflict?: InputMaybe<energy_distribution_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_distribution_type_oneArgs = {
  object: energy_distribution_type_insert_input;
  on_conflict?: InputMaybe<energy_distribution_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_pathArgs = {
  objects: Array<energy_path_insert_input>;
  on_conflict?: InputMaybe<energy_path_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_path_oneArgs = {
  object: energy_path_insert_input;
  on_conflict?: InputMaybe<energy_path_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_producerArgs = {
  objects: Array<energy_producer_insert_input>;
  on_conflict?: InputMaybe<energy_producer_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_producer_oneArgs = {
  object: energy_producer_insert_input;
  on_conflict?: InputMaybe<energy_producer_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_sourceArgs = {
  objects: Array<energy_source_insert_input>;
  on_conflict?: InputMaybe<energy_source_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_source_oneArgs = {
  object: energy_source_insert_input;
  on_conflict?: InputMaybe<energy_source_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_source_typeArgs = {
  objects: Array<energy_source_type_insert_input>;
  on_conflict?: InputMaybe<energy_source_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_source_type_oneArgs = {
  object: energy_source_type_insert_input;
  on_conflict?: InputMaybe<energy_source_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_standardArgs = {
  objects: Array<energy_standard_insert_input>;
  on_conflict?: InputMaybe<energy_standard_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_standard_oneArgs = {
  object: energy_standard_insert_input;
  on_conflict?: InputMaybe<energy_standard_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_storageArgs = {
  objects: Array<energy_storage_insert_input>;
  on_conflict?: InputMaybe<energy_storage_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_storage_oneArgs = {
  object: energy_storage_insert_input;
  on_conflict?: InputMaybe<energy_storage_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_storage_typeArgs = {
  objects: Array<energy_storage_type_insert_input>;
  on_conflict?: InputMaybe<energy_storage_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_storage_type_oneArgs = {
  object: energy_storage_type_insert_input;
  on_conflict?: InputMaybe<energy_storage_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_systemArgs = {
  objects: Array<energy_system_insert_input>;
  on_conflict?: InputMaybe<energy_system_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_system_consumer_routeArgs = {
  objects: Array<energy_system_consumer_route_insert_input>;
  on_conflict?: InputMaybe<energy_system_consumer_route_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_system_consumer_route_oneArgs = {
  object: energy_system_consumer_route_insert_input;
  on_conflict?: InputMaybe<energy_system_consumer_route_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_system_oneArgs = {
  object: energy_system_insert_input;
  on_conflict?: InputMaybe<energy_system_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_system_producer_routeArgs = {
  objects: Array<energy_system_producer_route_insert_input>;
  on_conflict?: InputMaybe<energy_system_producer_route_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_system_producer_route_oneArgs = {
  object: energy_system_producer_route_insert_input;
  on_conflict?: InputMaybe<energy_system_producer_route_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_system_typeArgs = {
  objects: Array<energy_system_type_insert_input>;
  on_conflict?: InputMaybe<energy_system_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_energy_system_type_oneArgs = {
  object: energy_system_type_insert_input;
  on_conflict?: InputMaybe<energy_system_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_envelopeArgs = {
  objects: Array<envelope_insert_input>;
  on_conflict?: InputMaybe<envelope_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_envelope_oneArgs = {
  object: envelope_insert_input;
  on_conflict?: InputMaybe<envelope_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_envelope_typeArgs = {
  objects: Array<envelope_type_insert_input>;
  on_conflict?: InputMaybe<envelope_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_envelope_type_oneArgs = {
  object: envelope_type_insert_input;
  on_conflict?: InputMaybe<envelope_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_eu_taxonomy_complianceArgs = {
  objects: Array<eu_taxonomy_compliance_insert_input>;
  on_conflict?: InputMaybe<eu_taxonomy_compliance_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_eu_taxonomy_compliance_oneArgs = {
  object: eu_taxonomy_compliance_insert_input;
  on_conflict?: InputMaybe<eu_taxonomy_compliance_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_fileArgs = {
  objects: Array<file_insert_input>;
  on_conflict?: InputMaybe<file_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_file_oneArgs = {
  object: file_insert_input;
  on_conflict?: InputMaybe<file_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_insulation_material_categoryArgs = {
  objects: Array<insulation_material_category_insert_input>;
  on_conflict?: InputMaybe<insulation_material_category_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_insulation_material_category_oneArgs = {
  object: insulation_material_category_insert_input;
  on_conflict?: InputMaybe<insulation_material_category_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_insulation_methodArgs = {
  objects: Array<insulation_method_insert_input>;
  on_conflict?: InputMaybe<insulation_method_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_insulation_method_oneArgs = {
  object: insulation_method_insert_input;
  on_conflict?: InputMaybe<insulation_method_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_invoice_payer_typeArgs = {
  objects: Array<invoice_payer_type_insert_input>;
  on_conflict?: InputMaybe<invoice_payer_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_invoice_payer_type_oneArgs = {
  object: invoice_payer_type_insert_input;
  on_conflict?: InputMaybe<invoice_payer_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_langfuse_datasets_temporary_itemArgs = {
  objects: Array<langfuse_datasets_temporary_item_insert_input>;
  on_conflict?: InputMaybe<langfuse_datasets_temporary_item_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_langfuse_datasets_temporary_item_oneArgs = {
  object: langfuse_datasets_temporary_item_insert_input;
  on_conflict?: InputMaybe<langfuse_datasets_temporary_item_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_languageArgs = {
  objects: Array<language_insert_input>;
  on_conflict?: InputMaybe<language_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_language_oneArgs = {
  object: language_insert_input;
  on_conflict?: InputMaybe<language_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_meterArgs = {
  objects: Array<meter_insert_input>;
  on_conflict?: InputMaybe<meter_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_meter_integrationArgs = {
  objects: Array<meter_integration_insert_input>;
  on_conflict?: InputMaybe<meter_integration_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_meter_integration_buildingArgs = {
  objects: Array<meter_integration_building_insert_input>;
  on_conflict?: InputMaybe<meter_integration_building_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_meter_integration_building_oneArgs = {
  object: meter_integration_building_insert_input;
  on_conflict?: InputMaybe<meter_integration_building_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_meter_integration_oneArgs = {
  object: meter_integration_insert_input;
  on_conflict?: InputMaybe<meter_integration_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_meter_integration_typeArgs = {
  objects: Array<meter_integration_type_insert_input>;
  on_conflict?: InputMaybe<meter_integration_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_meter_integration_type_oneArgs = {
  object: meter_integration_type_insert_input;
  on_conflict?: InputMaybe<meter_integration_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_meter_oneArgs = {
  object: meter_insert_input;
  on_conflict?: InputMaybe<meter_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_notificationArgs = {
  objects: Array<notification_insert_input>;
  on_conflict?: InputMaybe<notification_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_notification_oneArgs = {
  object: notification_insert_input;
  on_conflict?: InputMaybe<notification_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_notification_templateArgs = {
  objects: Array<notification_template_insert_input>;
  on_conflict?: InputMaybe<notification_template_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_notification_template_oneArgs = {
  object: notification_template_insert_input;
  on_conflict?: InputMaybe<notification_template_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_notification_typeArgs = {
  objects: Array<notification_type_insert_input>;
  on_conflict?: InputMaybe<notification_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_notification_type_oneArgs = {
  object: notification_type_insert_input;
  on_conflict?: InputMaybe<notification_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_operation_model_typeArgs = {
  objects: Array<operation_model_type_insert_input>;
  on_conflict?: InputMaybe<operation_model_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_operation_model_type_oneArgs = {
  object: operation_model_type_insert_input;
  on_conflict?: InputMaybe<operation_model_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_orgArgs = {
  objects: Array<org_insert_input>;
  on_conflict?: InputMaybe<org_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_org_oneArgs = {
  object: org_insert_input;
  on_conflict?: InputMaybe<org_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_org_preferenceArgs = {
  objects: Array<org_preference_insert_input>;
  on_conflict?: InputMaybe<org_preference_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_org_preference_oneArgs = {
  object: org_preference_insert_input;
  on_conflict?: InputMaybe<org_preference_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_org_status_typeArgs = {
  objects: Array<org_status_type_insert_input>;
  on_conflict?: InputMaybe<org_status_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_org_status_type_oneArgs = {
  object: org_status_type_insert_input;
  on_conflict?: InputMaybe<org_status_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_portfolioArgs = {
  objects: Array<portfolio_insert_input>;
  on_conflict?: InputMaybe<portfolio_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_portfolio_oneArgs = {
  object: portfolio_insert_input;
  on_conflict?: InputMaybe<portfolio_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_renovationArgs = {
  objects: Array<renovation_insert_input>;
  on_conflict?: InputMaybe<renovation_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_renovation_energy_consumerArgs = {
  objects: Array<renovation_energy_consumer_insert_input>;
};

/** mutation root */
export type mutation_rootinsert_renovation_energy_consumer_oneArgs = {
  object: renovation_energy_consumer_insert_input;
};

/** mutation root */
export type mutation_rootinsert_renovation_energy_distributionArgs = {
  objects: Array<renovation_energy_distribution_insert_input>;
};

/** mutation root */
export type mutation_rootinsert_renovation_energy_distribution_oneArgs = {
  object: renovation_energy_distribution_insert_input;
};

/** mutation root */
export type mutation_rootinsert_renovation_energy_producerArgs = {
  objects: Array<renovation_energy_producer_insert_input>;
};

/** mutation root */
export type mutation_rootinsert_renovation_energy_producer_oneArgs = {
  object: renovation_energy_producer_insert_input;
};

/** mutation root */
export type mutation_rootinsert_renovation_energy_storageArgs = {
  objects: Array<renovation_energy_storage_insert_input>;
};

/** mutation root */
export type mutation_rootinsert_renovation_energy_storage_oneArgs = {
  object: renovation_energy_storage_insert_input;
};

/** mutation root */
export type mutation_rootinsert_renovation_energy_system_routeArgs = {
  objects: Array<renovation_energy_system_route_insert_input>;
};

/** mutation root */
export type mutation_rootinsert_renovation_energy_system_route_oneArgs = {
  object: renovation_energy_system_route_insert_input;
};

/** mutation root */
export type mutation_rootinsert_renovation_envelopeArgs = {
  objects: Array<renovation_envelope_insert_input>;
};

/** mutation root */
export type mutation_rootinsert_renovation_envelope_oneArgs = {
  object: renovation_envelope_insert_input;
};

/** mutation root */
export type mutation_rootinsert_renovation_oneArgs = {
  object: renovation_insert_input;
  on_conflict?: InputMaybe<renovation_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_renovation_typeArgs = {
  objects: Array<renovation_type_insert_input>;
  on_conflict?: InputMaybe<renovation_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_renovation_type_oneArgs = {
  object: renovation_type_insert_input;
  on_conflict?: InputMaybe<renovation_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_reportArgs = {
  objects: Array<report_insert_input>;
  on_conflict?: InputMaybe<report_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_answerArgs = {
  objects: Array<report_answer_insert_input>;
  on_conflict?: InputMaybe<report_answer_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_answer_autofill_optionArgs = {
  objects: Array<report_answer_autofill_option_insert_input>;
  on_conflict?: InputMaybe<report_answer_autofill_option_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_answer_autofill_option_oneArgs = {
  object: report_answer_autofill_option_insert_input;
  on_conflict?: InputMaybe<report_answer_autofill_option_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_answer_chosen_optionArgs = {
  objects: Array<report_answer_chosen_option_insert_input>;
  on_conflict?: InputMaybe<report_answer_chosen_option_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_answer_chosen_option_oneArgs = {
  object: report_answer_chosen_option_insert_input;
  on_conflict?: InputMaybe<report_answer_chosen_option_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_answer_oneArgs = {
  object: report_answer_insert_input;
  on_conflict?: InputMaybe<report_answer_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_answer_stateArgs = {
  objects: Array<report_answer_state_insert_input>;
  on_conflict?: InputMaybe<report_answer_state_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_answer_state_oneArgs = {
  object: report_answer_state_insert_input;
  on_conflict?: InputMaybe<report_answer_state_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_evidenceArgs = {
  objects: Array<report_evidence_insert_input>;
  on_conflict?: InputMaybe<report_evidence_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_evidence_oneArgs = {
  object: report_evidence_insert_input;
  on_conflict?: InputMaybe<report_evidence_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_oneArgs = {
  object: report_insert_input;
  on_conflict?: InputMaybe<report_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_question_evidenceArgs = {
  objects: Array<report_question_evidence_insert_input>;
  on_conflict?: InputMaybe<report_question_evidence_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_question_evidence_oneArgs = {
  object: report_question_evidence_insert_input;
  on_conflict?: InputMaybe<report_question_evidence_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_scoreArgs = {
  objects: Array<report_score_insert_input>;
  on_conflict?: InputMaybe<report_score_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_score_oneArgs = {
  object: report_score_insert_input;
  on_conflict?: InputMaybe<report_score_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_section_progressArgs = {
  objects: Array<report_section_progress_insert_input>;
  on_conflict?: InputMaybe<report_section_progress_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_section_progress_oneArgs = {
  object: report_section_progress_insert_input;
  on_conflict?: InputMaybe<report_section_progress_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_sub_buildingArgs = {
  objects: Array<report_sub_building_insert_input>;
  on_conflict?: InputMaybe<report_sub_building_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_sub_building_oneArgs = {
  object: report_sub_building_insert_input;
  on_conflict?: InputMaybe<report_sub_building_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_templateArgs = {
  objects: Array<report_template_insert_input>;
  on_conflict?: InputMaybe<report_template_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_template_oneArgs = {
  object: report_template_insert_input;
  on_conflict?: InputMaybe<report_template_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_template_questionArgs = {
  objects: Array<report_template_question_insert_input>;
  on_conflict?: InputMaybe<report_template_question_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_template_question_oneArgs = {
  object: report_template_question_insert_input;
  on_conflict?: InputMaybe<report_template_question_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_template_question_optionArgs = {
  objects: Array<report_template_question_option_insert_input>;
  on_conflict?: InputMaybe<report_template_question_option_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_template_question_option_oneArgs = {
  object: report_template_question_option_insert_input;
  on_conflict?: InputMaybe<report_template_question_option_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_template_question_typeArgs = {
  objects: Array<report_template_question_type_insert_input>;
  on_conflict?: InputMaybe<report_template_question_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_template_question_type_oneArgs = {
  object: report_template_question_type_insert_input;
  on_conflict?: InputMaybe<report_template_question_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_template_scoreArgs = {
  objects: Array<report_template_score_insert_input>;
  on_conflict?: InputMaybe<report_template_score_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_template_score_display_typeArgs = {
  objects: Array<report_template_score_display_type_insert_input>;
  on_conflict?: InputMaybe<report_template_score_display_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_template_score_display_type_oneArgs = {
  object: report_template_score_display_type_insert_input;
  on_conflict?: InputMaybe<report_template_score_display_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_template_score_oneArgs = {
  object: report_template_score_insert_input;
  on_conflict?: InputMaybe<report_template_score_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_template_sectionArgs = {
  objects: Array<report_template_section_insert_input>;
  on_conflict?: InputMaybe<report_template_section_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_template_section_oneArgs = {
  object: report_template_section_insert_input;
  on_conflict?: InputMaybe<report_template_section_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_template_section_targetArgs = {
  objects: Array<report_template_section_target_insert_input>;
  on_conflict?: InputMaybe<report_template_section_target_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_report_template_section_target_oneArgs = {
  object: report_template_section_target_insert_input;
  on_conflict?: InputMaybe<report_template_section_target_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_roleArgs = {
  objects: Array<role_insert_input>;
  on_conflict?: InputMaybe<role_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_role_oneArgs = {
  object: role_insert_input;
  on_conflict?: InputMaybe<role_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_roof_typeArgs = {
  objects: Array<roof_type_insert_input>;
  on_conflict?: InputMaybe<roof_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_roof_type_oneArgs = {
  object: roof_type_insert_input;
  on_conflict?: InputMaybe<roof_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_scenarioArgs = {
  objects: Array<scenario_insert_input>;
  on_conflict?: InputMaybe<scenario_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_scenario_oneArgs = {
  object: scenario_insert_input;
  on_conflict?: InputMaybe<scenario_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_sub_buildingArgs = {
  objects: Array<sub_building_insert_input>;
  on_conflict?: InputMaybe<sub_building_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_sub_building_classArgs = {
  objects: Array<sub_building_class_insert_input>;
  on_conflict?: InputMaybe<sub_building_class_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_sub_building_class_oneArgs = {
  object: sub_building_class_insert_input;
  on_conflict?: InputMaybe<sub_building_class_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_sub_building_oneArgs = {
  object: sub_building_insert_input;
  on_conflict?: InputMaybe<sub_building_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_sub_building_subsidyArgs = {
  objects: Array<sub_building_subsidy_insert_input>;
  on_conflict?: InputMaybe<sub_building_subsidy_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_sub_building_subsidy_oneArgs = {
  object: sub_building_subsidy_insert_input;
  on_conflict?: InputMaybe<sub_building_subsidy_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_subsidyArgs = {
  objects: Array<subsidy_insert_input>;
  on_conflict?: InputMaybe<subsidy_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_subsidy_category_typeArgs = {
  objects: Array<subsidy_category_type_insert_input>;
  on_conflict?: InputMaybe<subsidy_category_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_subsidy_category_type_oneArgs = {
  object: subsidy_category_type_insert_input;
  on_conflict?: InputMaybe<subsidy_category_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_subsidy_oneArgs = {
  object: subsidy_insert_input;
  on_conflict?: InputMaybe<subsidy_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_subsidy_region_typeArgs = {
  objects: Array<subsidy_region_type_insert_input>;
  on_conflict?: InputMaybe<subsidy_region_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_subsidy_region_type_oneArgs = {
  object: subsidy_region_type_insert_input;
  on_conflict?: InputMaybe<subsidy_region_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_subsidy_subsidy_categoryArgs = {
  objects: Array<subsidy_subsidy_category_insert_input>;
  on_conflict?: InputMaybe<subsidy_subsidy_category_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_subsidy_subsidy_category_oneArgs = {
  object: subsidy_subsidy_category_insert_input;
  on_conflict?: InputMaybe<subsidy_subsidy_category_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_subsidy_typeArgs = {
  objects: Array<subsidy_type_insert_input>;
  on_conflict?: InputMaybe<subsidy_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_subsidy_type_oneArgs = {
  object: subsidy_type_insert_input;
  on_conflict?: InputMaybe<subsidy_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_sync_stateArgs = {
  objects: Array<sync_state_insert_input>;
  on_conflict?: InputMaybe<sync_state_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_sync_state_oneArgs = {
  object: sync_state_insert_input;
  on_conflict?: InputMaybe<sync_state_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_target_pathArgs = {
  objects: Array<target_path_insert_input>;
  on_conflict?: InputMaybe<target_path_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_target_path_oneArgs = {
  object: target_path_insert_input;
  on_conflict?: InputMaybe<target_path_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_target_path_templateArgs = {
  objects: Array<target_path_template_insert_input>;
  on_conflict?: InputMaybe<target_path_template_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_target_path_template_oneArgs = {
  object: target_path_template_insert_input;
  on_conflict?: InputMaybe<target_path_template_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_target_path_template_typeArgs = {
  objects: Array<target_path_template_type_insert_input>;
  on_conflict?: InputMaybe<target_path_template_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_target_path_template_type_oneArgs = {
  object: target_path_template_type_insert_input;
  on_conflict?: InputMaybe<target_path_template_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_tax_bracketArgs = {
  objects: Array<tax_bracket_insert_input>;
  on_conflict?: InputMaybe<tax_bracket_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_tax_bracket_oneArgs = {
  object: tax_bracket_insert_input;
  on_conflict?: InputMaybe<tax_bracket_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_type_of_useArgs = {
  objects: Array<type_of_use_insert_input>;
  on_conflict?: InputMaybe<type_of_use_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_type_of_use_oneArgs = {
  object: type_of_use_insert_input;
  on_conflict?: InputMaybe<type_of_use_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_userArgs = {
  objects: Array<user_insert_input>;
  on_conflict?: InputMaybe<user_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_user_building_permissionArgs = {
  objects: Array<user_building_permission_insert_input>;
  on_conflict?: InputMaybe<user_building_permission_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_user_building_permission_oneArgs = {
  object: user_building_permission_insert_input;
  on_conflict?: InputMaybe<user_building_permission_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_user_oneArgs = {
  object: user_insert_input;
  on_conflict?: InputMaybe<user_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_user_portfolio_permissionArgs = {
  objects: Array<user_portfolio_permission_insert_input>;
  on_conflict?: InputMaybe<user_portfolio_permission_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_user_portfolio_permission_oneArgs = {
  object: user_portfolio_permission_insert_input;
  on_conflict?: InputMaybe<user_portfolio_permission_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_user_roleArgs = {
  objects: Array<user_role_insert_input>;
  on_conflict?: InputMaybe<user_role_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_user_role_oneArgs = {
  object: user_role_insert_input;
  on_conflict?: InputMaybe<user_role_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_window_typeArgs = {
  objects: Array<window_type_insert_input>;
  on_conflict?: InputMaybe<window_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinsert_window_type_oneArgs = {
  object: window_type_insert_input;
  on_conflict?: InputMaybe<window_type_on_conflict>;
};

/** mutation root */
export type mutation_rootinternalApplyApproximationNoFallbackArgs = {
  buildingId: Scalars['Int'];
};

/** mutation root */
export type mutation_rootinternalApplyApproximationNoFallbackIgnoreScalingArgs = {
  buildingId: Scalars['Int'];
};

/** mutation root */
export type mutation_rootinternalApplyApproximationWithFallbackArgs = {
  buildingId: Scalars['Int'];
};

/** mutation root */
export type mutation_rootinternalApplyApproximationWithFallbackIgnoreScalingArgs = {
  buildingId: Scalars['Int'];
};

/** mutation root */
export type mutation_rootinternalApproximateArgs = {
  buildingId: Scalars['Int'];
  fallback: Scalars['Boolean'];
  ignoreScaling: Scalars['Boolean'];
};

/** mutation root */
export type mutation_rootinternalApproximateRoofWithGoogleSolarAPIArgs = {
  buildingId: Scalars['Int'];
};

/** mutation root */
export type mutation_rootinternalCreateGlobalNotificationArgs = {
  globalNotificationInput: GlobalNotificationInput;
};

/** mutation root */
export type mutation_rootinternalCreateNotificationForUsersArgs = {
  usersNotificationInput: UsersNotificationInput;
};

/** mutation root */
export type mutation_rootinternalCreateOrgsNotificationArgs = {
  orgNotificationInput: OrgNotificationInput;
};

/** mutation root */
export type mutation_rootinternalExportScenarioAsExcelArgs = {
  scenario_id?: InputMaybe<Scalars['Int']>;
};

/** mutation root */
export type mutation_rootinternalExportScenarioAsJsonArgs = {
  scenario_id?: InputMaybe<Scalars['Int']>;
};

/** mutation root */
export type mutation_rootrecalculateAreasArgs = {
  area_mutations: Array<AreaRecalculateMutation>;
  building_id: Scalars['Int'];
  dry_run: Scalars['Boolean'];
  save_invalid_state: Scalars['Boolean'];
};

/** mutation root */
export type mutation_rootremoveEmptyEconomicUnitsArgs = {
  ids: Array<Scalars['Int']>;
};

/** mutation root */
export type mutation_rootresetAnswersArgs = {
  report_id: Scalars['Int'];
  root_question_id: Scalars['Int'];
  sub_building_id?: InputMaybe<Scalars['Int']>;
};

/** mutation root */
export type mutation_rootsetupMeterIntegrationArgs = {
  api_key: Scalars['String'];
  api_secret: Scalars['String'];
  integration_type: meter_integration_type_enum;
};

/** mutation root */
export type mutation_rootsimulateActionArgs = {
  action_plan_id: Scalars['Int'];
  energy_system_route_renovation_parameter?: InputMaybe<EnergySystemRouteRenovationParameterInput>;
  envelope_renovation_parameter?: InputMaybe<EnvelopeRenovationParameterInput>;
  hydraulic_balancing_renovation_parameter?: InputMaybe<HydraulicBalancingRenovationParameterInput>;
  implementation_to: Scalars['DateTime'];
  renovation_ids: Array<Scalars['Int']>;
  solar_renovation_parameter?: InputMaybe<SolarRenovationParameterInput>;
  system_renovation_parameter?: InputMaybe<SystemRenovationParameterInput>;
};

/** mutation root */
export type mutation_rootsyncMeterIntegrationArgs = {
  integration_id: Scalars['Int'];
};

/** mutation root */
export type mutation_rootupdateAreasArgs = {
  area_mutations: Array<AreaUpdateMutation>;
  building_id: Scalars['Int'];
  save_invalid_state: Scalars['Boolean'];
};

/** mutation root */
export type mutation_rootupdateConsumptionInvoiceArgs = {
  buildingId: Scalars['Int'];
  consumptions: Array<ConsumptionInput>;
  invoiceDate: Scalars['DateTime'];
  invoiceId: Scalars['Int'];
  invoicePayer: invoice_payer_type_enum;
};

/** mutation root */
export type mutation_rootupdateConsumptionInvoiceDraftArgs = {
  buildingId: Scalars['Int'];
  consumptionDrafts: Array<ConsumptionDraftInput>;
  draftStatus: draft_status_enum;
  invoiceDate?: InputMaybe<Scalars['DateTime']>;
  invoiceDraftId: Scalars['Int'];
  invoicePayer?: InputMaybe<invoice_payer_type_enum>;
};

/** mutation root */
export type mutation_rootupdateEmissionCertificateArgs = {
  emissionCertificate: EmissionCertificateUpdateInput;
};

/** mutation root */
export type mutation_rootupdateSelectedIdsArgs = {
  buildingId: Scalars['Float'];
  coordinates?: InputMaybe<Coordinates>;
  expandSelection?: Scalars['Boolean'];
  selectedIds: Array<Scalars['String']>;
};

/** mutation root */
export type mutation_rootupdate_actionArgs = {
  _inc?: InputMaybe<action_inc_input>;
  _set?: InputMaybe<action_set_input>;
  where: action_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_action_by_pkArgs = {
  _inc?: InputMaybe<action_inc_input>;
  _set?: InputMaybe<action_set_input>;
  pk_columns: action_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_action_manyArgs = {
  updates: Array<action_updates>;
};

/** mutation root */
export type mutation_rootupdate_action_planArgs = {
  _inc?: InputMaybe<action_plan_inc_input>;
  _set?: InputMaybe<action_plan_set_input>;
  where: action_plan_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_action_plan_by_pkArgs = {
  _inc?: InputMaybe<action_plan_inc_input>;
  _set?: InputMaybe<action_plan_set_input>;
  pk_columns: action_plan_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_action_plan_manyArgs = {
  updates: Array<action_plan_updates>;
};

/** mutation root */
export type mutation_rootupdate_action_subsidyArgs = {
  _inc?: InputMaybe<action_subsidy_inc_input>;
  _set?: InputMaybe<action_subsidy_set_input>;
  where: action_subsidy_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_action_subsidy_by_pkArgs = {
  _inc?: InputMaybe<action_subsidy_inc_input>;
  _set?: InputMaybe<action_subsidy_set_input>;
  pk_columns: action_subsidy_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_action_subsidy_manyArgs = {
  updates: Array<action_subsidy_updates>;
};

/** mutation root */
export type mutation_rootupdate_action_subsidy_value_typeArgs = {
  _set?: InputMaybe<action_subsidy_value_type_set_input>;
  where: action_subsidy_value_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_action_subsidy_value_type_by_pkArgs = {
  _set?: InputMaybe<action_subsidy_value_type_set_input>;
  pk_columns: action_subsidy_value_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_action_subsidy_value_type_manyArgs = {
  updates: Array<action_subsidy_value_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_addressArgs = {
  _inc?: InputMaybe<address_inc_input>;
  _set?: InputMaybe<address_set_input>;
  where: address_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_address_by_pkArgs = {
  _inc?: InputMaybe<address_inc_input>;
  _set?: InputMaybe<address_set_input>;
  pk_columns: address_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_address_manyArgs = {
  updates: Array<address_updates>;
};

/** mutation root */
export type mutation_rootupdate_api_userArgs = {
  _inc?: InputMaybe<api_user_inc_input>;
  _set?: InputMaybe<api_user_set_input>;
  where: api_user_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_api_user_by_pkArgs = {
  _inc?: InputMaybe<api_user_inc_input>;
  _set?: InputMaybe<api_user_set_input>;
  pk_columns: api_user_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_api_user_manyArgs = {
  updates: Array<api_user_updates>;
};

/** mutation root */
export type mutation_rootupdate_areaArgs = {
  _inc?: InputMaybe<area_inc_input>;
  _set?: InputMaybe<area_set_input>;
  where: area_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_area_by_pkArgs = {
  _inc?: InputMaybe<area_inc_input>;
  _set?: InputMaybe<area_set_input>;
  pk_columns: area_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_area_manyArgs = {
  updates: Array<area_updates>;
};

/** mutation root */
export type mutation_rootupdate_area_system_typeArgs = {
  _set?: InputMaybe<area_system_type_set_input>;
  where: area_system_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_area_system_type_by_pkArgs = {
  _set?: InputMaybe<area_system_type_set_input>;
  pk_columns: area_system_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_area_system_type_manyArgs = {
  updates: Array<area_system_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_area_typeArgs = {
  _set?: InputMaybe<area_type_set_input>;
  where: area_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_area_type_by_pkArgs = {
  _set?: InputMaybe<area_type_set_input>;
  pk_columns: area_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_area_type_manyArgs = {
  updates: Array<area_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_async_jobArgs = {
  _inc?: InputMaybe<async_job_inc_input>;
  _set?: InputMaybe<async_job_set_input>;
  where: async_job_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_async_job_by_pkArgs = {
  _inc?: InputMaybe<async_job_inc_input>;
  _set?: InputMaybe<async_job_set_input>;
  pk_columns: async_job_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_async_job_manyArgs = {
  updates: Array<async_job_updates>;
};

/** mutation root */
export type mutation_rootupdate_async_job_status_typeArgs = {
  _set?: InputMaybe<async_job_status_type_set_input>;
  where: async_job_status_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_async_job_status_type_by_pkArgs = {
  _set?: InputMaybe<async_job_status_type_set_input>;
  pk_columns: async_job_status_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_async_job_status_type_manyArgs = {
  updates: Array<async_job_status_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_buildingArgs = {
  _inc?: InputMaybe<building_inc_input>;
  _set?: InputMaybe<building_set_input>;
  where: building_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_building_by_pkArgs = {
  _inc?: InputMaybe<building_inc_input>;
  _set?: InputMaybe<building_set_input>;
  pk_columns: building_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_building_manyArgs = {
  updates: Array<building_updates>;
};

/** mutation root */
export type mutation_rootupdate_building_modelArgs = {
  _inc?: InputMaybe<building_model_inc_input>;
  _set?: InputMaybe<building_model_set_input>;
  where: building_model_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_building_model_by_pkArgs = {
  _inc?: InputMaybe<building_model_inc_input>;
  _set?: InputMaybe<building_model_set_input>;
  pk_columns: building_model_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_building_model_manyArgs = {
  updates: Array<building_model_updates>;
};

/** mutation root */
export type mutation_rootupdate_building_stateArgs = {
  _set?: InputMaybe<building_state_set_input>;
  where: building_state_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_building_state_by_pkArgs = {
  _set?: InputMaybe<building_state_set_input>;
  pk_columns: building_state_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_building_state_manyArgs = {
  updates: Array<building_state_updates>;
};

/** mutation root */
export type mutation_rootupdate_climate_regionArgs = {
  _set?: InputMaybe<climate_region_set_input>;
  where: climate_region_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_climate_region_by_pkArgs = {
  _set?: InputMaybe<climate_region_set_input>;
  pk_columns: climate_region_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_climate_region_manyArgs = {
  updates: Array<climate_region_updates>;
};

/** mutation root */
export type mutation_rootupdate_climate_riskArgs = {
  _inc?: InputMaybe<climate_risk_inc_input>;
  _set?: InputMaybe<climate_risk_set_input>;
  where: climate_risk_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_climate_risk_by_pkArgs = {
  _inc?: InputMaybe<climate_risk_inc_input>;
  _set?: InputMaybe<climate_risk_set_input>;
  pk_columns: climate_risk_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_climate_risk_manyArgs = {
  updates: Array<climate_risk_updates>;
};

/** mutation root */
export type mutation_rootupdate_climate_risk_typeArgs = {
  _set?: InputMaybe<climate_risk_type_set_input>;
  where: climate_risk_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_climate_risk_type_by_pkArgs = {
  _set?: InputMaybe<climate_risk_type_set_input>;
  pk_columns: climate_risk_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_climate_risk_type_manyArgs = {
  updates: Array<climate_risk_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_consumptionArgs = {
  _inc?: InputMaybe<consumption_inc_input>;
  _set?: InputMaybe<consumption_set_input>;
  where: consumption_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_consumption_by_pkArgs = {
  _inc?: InputMaybe<consumption_inc_input>;
  _set?: InputMaybe<consumption_set_input>;
  pk_columns: consumption_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_consumption_draftArgs = {
  _inc?: InputMaybe<consumption_draft_inc_input>;
  _set?: InputMaybe<consumption_draft_set_input>;
  where: consumption_draft_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_consumption_draft_by_pkArgs = {
  _inc?: InputMaybe<consumption_draft_inc_input>;
  _set?: InputMaybe<consumption_draft_set_input>;
  pk_columns: consumption_draft_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_consumption_draft_manyArgs = {
  updates: Array<consumption_draft_updates>;
};

/** mutation root */
export type mutation_rootupdate_consumption_frequency_typeArgs = {
  _set?: InputMaybe<consumption_frequency_type_set_input>;
  where: consumption_frequency_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_consumption_frequency_type_by_pkArgs = {
  _set?: InputMaybe<consumption_frequency_type_set_input>;
  pk_columns: consumption_frequency_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_consumption_frequency_type_manyArgs = {
  updates: Array<consumption_frequency_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_consumption_invoiceArgs = {
  _inc?: InputMaybe<consumption_invoice_inc_input>;
  _set?: InputMaybe<consumption_invoice_set_input>;
  where: consumption_invoice_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_consumption_invoice_by_pkArgs = {
  _inc?: InputMaybe<consumption_invoice_inc_input>;
  _set?: InputMaybe<consumption_invoice_set_input>;
  pk_columns: consumption_invoice_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_consumption_invoice_draftArgs = {
  _inc?: InputMaybe<consumption_invoice_draft_inc_input>;
  _set?: InputMaybe<consumption_invoice_draft_set_input>;
  where: consumption_invoice_draft_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_consumption_invoice_draft_by_pkArgs = {
  _inc?: InputMaybe<consumption_invoice_draft_inc_input>;
  _set?: InputMaybe<consumption_invoice_draft_set_input>;
  pk_columns: consumption_invoice_draft_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_consumption_invoice_draft_manyArgs = {
  updates: Array<consumption_invoice_draft_updates>;
};

/** mutation root */
export type mutation_rootupdate_consumption_invoice_manyArgs = {
  updates: Array<consumption_invoice_updates>;
};

/** mutation root */
export type mutation_rootupdate_consumption_manyArgs = {
  updates: Array<consumption_updates>;
};

/** mutation root */
export type mutation_rootupdate_consumption_source_typeArgs = {
  _set?: InputMaybe<consumption_source_type_set_input>;
  where: consumption_source_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_consumption_source_type_by_pkArgs = {
  _set?: InputMaybe<consumption_source_type_set_input>;
  pk_columns: consumption_source_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_consumption_source_type_manyArgs = {
  updates: Array<consumption_source_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_consumption_sub_typeArgs = {
  _set?: InputMaybe<consumption_sub_type_set_input>;
  where: consumption_sub_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_consumption_sub_type_by_pkArgs = {
  _set?: InputMaybe<consumption_sub_type_set_input>;
  pk_columns: consumption_sub_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_consumption_sub_type_manyArgs = {
  updates: Array<consumption_sub_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_consumption_typeArgs = {
  _set?: InputMaybe<consumption_type_set_input>;
  where: consumption_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_consumption_type_by_pkArgs = {
  _set?: InputMaybe<consumption_type_set_input>;
  pk_columns: consumption_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_consumption_type_manyArgs = {
  updates: Array<consumption_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_consumption_unitArgs = {
  _set?: InputMaybe<consumption_unit_set_input>;
  where: consumption_unit_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_consumption_unit_by_pkArgs = {
  _set?: InputMaybe<consumption_unit_set_input>;
  pk_columns: consumption_unit_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_consumption_unit_manyArgs = {
  updates: Array<consumption_unit_updates>;
};

/** mutation root */
export type mutation_rootupdate_consumption_waste_detailsArgs = {
  _inc?: InputMaybe<consumption_waste_details_inc_input>;
  _set?: InputMaybe<consumption_waste_details_set_input>;
  where: consumption_waste_details_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_consumption_waste_details_by_pkArgs = {
  _inc?: InputMaybe<consumption_waste_details_inc_input>;
  _set?: InputMaybe<consumption_waste_details_set_input>;
  pk_columns: consumption_waste_details_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_consumption_waste_details_draftArgs = {
  _inc?: InputMaybe<consumption_waste_details_draft_inc_input>;
  _set?: InputMaybe<consumption_waste_details_draft_set_input>;
  where: consumption_waste_details_draft_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_consumption_waste_details_draft_by_pkArgs = {
  _inc?: InputMaybe<consumption_waste_details_draft_inc_input>;
  _set?: InputMaybe<consumption_waste_details_draft_set_input>;
  pk_columns: consumption_waste_details_draft_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_consumption_waste_details_draft_manyArgs = {
  updates: Array<consumption_waste_details_draft_updates>;
};

/** mutation root */
export type mutation_rootupdate_consumption_waste_details_manyArgs = {
  updates: Array<consumption_waste_details_updates>;
};

/** mutation root */
export type mutation_rootupdate_countryArgs = {
  _set?: InputMaybe<country_set_input>;
  where: country_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_country_by_pkArgs = {
  _set?: InputMaybe<country_set_input>;
  pk_columns: country_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_country_manyArgs = {
  updates: Array<country_updates>;
};

/** mutation root */
export type mutation_rootupdate_country_stateArgs = {
  _set?: InputMaybe<country_state_set_input>;
  where: country_state_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_country_state_by_pkArgs = {
  _set?: InputMaybe<country_state_set_input>;
  pk_columns: country_state_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_country_state_manyArgs = {
  updates: Array<country_state_updates>;
};

/** mutation root */
export type mutation_rootupdate_data_source_typeArgs = {
  _set?: InputMaybe<data_source_type_set_input>;
  where: data_source_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_data_source_type_by_pkArgs = {
  _set?: InputMaybe<data_source_type_set_input>;
  pk_columns: data_source_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_data_source_type_manyArgs = {
  updates: Array<data_source_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_draft_statusArgs = {
  _set?: InputMaybe<draft_status_set_input>;
  where: draft_status_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_draft_status_by_pkArgs = {
  _set?: InputMaybe<draft_status_set_input>;
  pk_columns: draft_status_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_draft_status_manyArgs = {
  updates: Array<draft_status_updates>;
};

/** mutation root */
export type mutation_rootupdate_economic_unitArgs = {
  _inc?: InputMaybe<economic_unit_inc_input>;
  _set?: InputMaybe<economic_unit_set_input>;
  where: economic_unit_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_economic_unit_by_pkArgs = {
  _inc?: InputMaybe<economic_unit_inc_input>;
  _set?: InputMaybe<economic_unit_set_input>;
  pk_columns: economic_unit_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_economic_unit_manyArgs = {
  updates: Array<economic_unit_updates>;
};

/** mutation root */
export type mutation_rootupdate_efficiency_classArgs = {
  _set?: InputMaybe<efficiency_class_set_input>;
  where: efficiency_class_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_efficiency_class_by_pkArgs = {
  _set?: InputMaybe<efficiency_class_set_input>;
  pk_columns: efficiency_class_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_efficiency_class_manyArgs = {
  updates: Array<efficiency_class_updates>;
};

/** mutation root */
export type mutation_rootupdate_emission_certificateArgs = {
  _inc?: InputMaybe<emission_certificate_inc_input>;
  _set?: InputMaybe<emission_certificate_set_input>;
  where: emission_certificate_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_emission_certificate_by_pkArgs = {
  _inc?: InputMaybe<emission_certificate_inc_input>;
  _set?: InputMaybe<emission_certificate_set_input>;
  pk_columns: emission_certificate_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_emission_certificate_draftArgs = {
  _inc?: InputMaybe<emission_certificate_draft_inc_input>;
  _set?: InputMaybe<emission_certificate_draft_set_input>;
  where: emission_certificate_draft_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_emission_certificate_draft_by_pkArgs = {
  _inc?: InputMaybe<emission_certificate_draft_inc_input>;
  _set?: InputMaybe<emission_certificate_draft_set_input>;
  pk_columns: emission_certificate_draft_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_emission_certificate_draft_manyArgs = {
  updates: Array<emission_certificate_draft_updates>;
};

/** mutation root */
export type mutation_rootupdate_emission_certificate_manyArgs = {
  updates: Array<emission_certificate_updates>;
};

/** mutation root */
export type mutation_rootupdate_emission_factor_originArgs = {
  _set?: InputMaybe<emission_factor_origin_set_input>;
  where: emission_factor_origin_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_emission_factor_origin_by_pkArgs = {
  _set?: InputMaybe<emission_factor_origin_set_input>;
  pk_columns: emission_factor_origin_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_emission_factor_origin_manyArgs = {
  updates: Array<emission_factor_origin_updates>;
};

/** mutation root */
export type mutation_rootupdate_emission_factor_typeArgs = {
  _set?: InputMaybe<emission_factor_type_set_input>;
  where: emission_factor_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_emission_factor_type_by_pkArgs = {
  _set?: InputMaybe<emission_factor_type_set_input>;
  pk_columns: emission_factor_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_emission_factor_type_manyArgs = {
  updates: Array<emission_factor_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_energy_certificateArgs = {
  _inc?: InputMaybe<energy_certificate_inc_input>;
  _set?: InputMaybe<energy_certificate_set_input>;
  where: energy_certificate_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_energy_certificate_by_pkArgs = {
  _inc?: InputMaybe<energy_certificate_inc_input>;
  _set?: InputMaybe<energy_certificate_set_input>;
  pk_columns: energy_certificate_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_energy_certificate_consumerArgs = {
  _inc?: InputMaybe<energy_certificate_consumer_inc_input>;
  _set?: InputMaybe<energy_certificate_consumer_set_input>;
  where: energy_certificate_consumer_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_energy_certificate_consumer_by_pkArgs = {
  _inc?: InputMaybe<energy_certificate_consumer_inc_input>;
  _set?: InputMaybe<energy_certificate_consumer_set_input>;
  pk_columns: energy_certificate_consumer_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_energy_certificate_consumer_manyArgs = {
  updates: Array<energy_certificate_consumer_updates>;
};

/** mutation root */
export type mutation_rootupdate_energy_certificate_draftArgs = {
  _inc?: InputMaybe<energy_certificate_draft_inc_input>;
  _set?: InputMaybe<energy_certificate_draft_set_input>;
  where: energy_certificate_draft_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_energy_certificate_draft_by_pkArgs = {
  _inc?: InputMaybe<energy_certificate_draft_inc_input>;
  _set?: InputMaybe<energy_certificate_draft_set_input>;
  pk_columns: energy_certificate_draft_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_energy_certificate_draft_manyArgs = {
  updates: Array<energy_certificate_draft_updates>;
};

/** mutation root */
export type mutation_rootupdate_energy_certificate_manyArgs = {
  updates: Array<energy_certificate_updates>;
};

/** mutation root */
export type mutation_rootupdate_energy_certificate_typeArgs = {
  _set?: InputMaybe<energy_certificate_type_set_input>;
  where: energy_certificate_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_energy_certificate_type_by_pkArgs = {
  _set?: InputMaybe<energy_certificate_type_set_input>;
  pk_columns: energy_certificate_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_energy_certificate_type_manyArgs = {
  updates: Array<energy_certificate_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_energy_consumerArgs = {
  _inc?: InputMaybe<energy_consumer_inc_input>;
  _set?: InputMaybe<energy_consumer_set_input>;
  where: energy_consumer_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_energy_consumer_by_pkArgs = {
  _inc?: InputMaybe<energy_consumer_inc_input>;
  _set?: InputMaybe<energy_consumer_set_input>;
  pk_columns: energy_consumer_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_energy_consumer_manyArgs = {
  updates: Array<energy_consumer_updates>;
};

/** mutation root */
export type mutation_rootupdate_energy_consumer_technology_typeArgs = {
  _set?: InputMaybe<energy_consumer_technology_type_set_input>;
  where: energy_consumer_technology_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_energy_consumer_technology_type_by_pkArgs = {
  _set?: InputMaybe<energy_consumer_technology_type_set_input>;
  pk_columns: energy_consumer_technology_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_energy_consumer_technology_type_manyArgs = {
  updates: Array<energy_consumer_technology_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_energy_consumer_typeArgs = {
  _set?: InputMaybe<energy_consumer_type_set_input>;
  where: energy_consumer_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_energy_consumer_type_by_pkArgs = {
  _set?: InputMaybe<energy_consumer_type_set_input>;
  pk_columns: energy_consumer_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_energy_consumer_type_manyArgs = {
  updates: Array<energy_consumer_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_energy_distributionArgs = {
  _inc?: InputMaybe<energy_distribution_inc_input>;
  _set?: InputMaybe<energy_distribution_set_input>;
  where: energy_distribution_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_energy_distribution_by_pkArgs = {
  _inc?: InputMaybe<energy_distribution_inc_input>;
  _set?: InputMaybe<energy_distribution_set_input>;
  pk_columns: energy_distribution_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_energy_distribution_manyArgs = {
  updates: Array<energy_distribution_updates>;
};

/** mutation root */
export type mutation_rootupdate_energy_distribution_typeArgs = {
  _set?: InputMaybe<energy_distribution_type_set_input>;
  where: energy_distribution_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_energy_distribution_type_by_pkArgs = {
  _set?: InputMaybe<energy_distribution_type_set_input>;
  pk_columns: energy_distribution_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_energy_distribution_type_manyArgs = {
  updates: Array<energy_distribution_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_energy_pathArgs = {
  _inc?: InputMaybe<energy_path_inc_input>;
  _set?: InputMaybe<energy_path_set_input>;
  where: energy_path_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_energy_path_by_pkArgs = {
  _inc?: InputMaybe<energy_path_inc_input>;
  _set?: InputMaybe<energy_path_set_input>;
  pk_columns: energy_path_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_energy_path_manyArgs = {
  updates: Array<energy_path_updates>;
};

/** mutation root */
export type mutation_rootupdate_energy_producerArgs = {
  _inc?: InputMaybe<energy_producer_inc_input>;
  _set?: InputMaybe<energy_producer_set_input>;
  where: energy_producer_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_energy_producer_by_pkArgs = {
  _inc?: InputMaybe<energy_producer_inc_input>;
  _set?: InputMaybe<energy_producer_set_input>;
  pk_columns: energy_producer_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_energy_producer_manyArgs = {
  updates: Array<energy_producer_updates>;
};

/** mutation root */
export type mutation_rootupdate_energy_sourceArgs = {
  _inc?: InputMaybe<energy_source_inc_input>;
  _set?: InputMaybe<energy_source_set_input>;
  where: energy_source_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_energy_source_by_pkArgs = {
  _inc?: InputMaybe<energy_source_inc_input>;
  _set?: InputMaybe<energy_source_set_input>;
  pk_columns: energy_source_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_energy_source_manyArgs = {
  updates: Array<energy_source_updates>;
};

/** mutation root */
export type mutation_rootupdate_energy_source_typeArgs = {
  _set?: InputMaybe<energy_source_type_set_input>;
  where: energy_source_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_energy_source_type_by_pkArgs = {
  _set?: InputMaybe<energy_source_type_set_input>;
  pk_columns: energy_source_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_energy_source_type_manyArgs = {
  updates: Array<energy_source_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_energy_standardArgs = {
  _set?: InputMaybe<energy_standard_set_input>;
  where: energy_standard_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_energy_standard_by_pkArgs = {
  _set?: InputMaybe<energy_standard_set_input>;
  pk_columns: energy_standard_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_energy_standard_manyArgs = {
  updates: Array<energy_standard_updates>;
};

/** mutation root */
export type mutation_rootupdate_energy_storageArgs = {
  _inc?: InputMaybe<energy_storage_inc_input>;
  _set?: InputMaybe<energy_storage_set_input>;
  where: energy_storage_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_energy_storage_by_pkArgs = {
  _inc?: InputMaybe<energy_storage_inc_input>;
  _set?: InputMaybe<energy_storage_set_input>;
  pk_columns: energy_storage_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_energy_storage_manyArgs = {
  updates: Array<energy_storage_updates>;
};

/** mutation root */
export type mutation_rootupdate_energy_storage_typeArgs = {
  _set?: InputMaybe<energy_storage_type_set_input>;
  where: energy_storage_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_energy_storage_type_by_pkArgs = {
  _set?: InputMaybe<energy_storage_type_set_input>;
  pk_columns: energy_storage_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_energy_storage_type_manyArgs = {
  updates: Array<energy_storage_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_energy_systemArgs = {
  _inc?: InputMaybe<energy_system_inc_input>;
  _set?: InputMaybe<energy_system_set_input>;
  where: energy_system_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_energy_system_by_pkArgs = {
  _inc?: InputMaybe<energy_system_inc_input>;
  _set?: InputMaybe<energy_system_set_input>;
  pk_columns: energy_system_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_energy_system_consumer_routeArgs = {
  _inc?: InputMaybe<energy_system_consumer_route_inc_input>;
  _set?: InputMaybe<energy_system_consumer_route_set_input>;
  where: energy_system_consumer_route_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_energy_system_consumer_route_by_pkArgs = {
  _inc?: InputMaybe<energy_system_consumer_route_inc_input>;
  _set?: InputMaybe<energy_system_consumer_route_set_input>;
  pk_columns: energy_system_consumer_route_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_energy_system_consumer_route_manyArgs = {
  updates: Array<energy_system_consumer_route_updates>;
};

/** mutation root */
export type mutation_rootupdate_energy_system_manyArgs = {
  updates: Array<energy_system_updates>;
};

/** mutation root */
export type mutation_rootupdate_energy_system_producer_routeArgs = {
  _inc?: InputMaybe<energy_system_producer_route_inc_input>;
  _set?: InputMaybe<energy_system_producer_route_set_input>;
  where: energy_system_producer_route_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_energy_system_producer_route_by_pkArgs = {
  _inc?: InputMaybe<energy_system_producer_route_inc_input>;
  _set?: InputMaybe<energy_system_producer_route_set_input>;
  pk_columns: energy_system_producer_route_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_energy_system_producer_route_manyArgs = {
  updates: Array<energy_system_producer_route_updates>;
};

/** mutation root */
export type mutation_rootupdate_energy_system_typeArgs = {
  _set?: InputMaybe<energy_system_type_set_input>;
  where: energy_system_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_energy_system_type_by_pkArgs = {
  _set?: InputMaybe<energy_system_type_set_input>;
  pk_columns: energy_system_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_energy_system_type_manyArgs = {
  updates: Array<energy_system_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_envelopeArgs = {
  _inc?: InputMaybe<envelope_inc_input>;
  _set?: InputMaybe<envelope_set_input>;
  where: envelope_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_envelope_by_pkArgs = {
  _inc?: InputMaybe<envelope_inc_input>;
  _set?: InputMaybe<envelope_set_input>;
  pk_columns: envelope_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_envelope_manyArgs = {
  updates: Array<envelope_updates>;
};

/** mutation root */
export type mutation_rootupdate_envelope_typeArgs = {
  _set?: InputMaybe<envelope_type_set_input>;
  where: envelope_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_envelope_type_by_pkArgs = {
  _set?: InputMaybe<envelope_type_set_input>;
  pk_columns: envelope_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_envelope_type_manyArgs = {
  updates: Array<envelope_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_eu_taxonomy_complianceArgs = {
  _set?: InputMaybe<eu_taxonomy_compliance_set_input>;
  where: eu_taxonomy_compliance_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_eu_taxonomy_compliance_by_pkArgs = {
  _set?: InputMaybe<eu_taxonomy_compliance_set_input>;
  pk_columns: eu_taxonomy_compliance_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_eu_taxonomy_compliance_manyArgs = {
  updates: Array<eu_taxonomy_compliance_updates>;
};

/** mutation root */
export type mutation_rootupdate_fileArgs = {
  _inc?: InputMaybe<file_inc_input>;
  _set?: InputMaybe<file_set_input>;
  where: file_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_file_by_pkArgs = {
  _inc?: InputMaybe<file_inc_input>;
  _set?: InputMaybe<file_set_input>;
  pk_columns: file_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_file_manyArgs = {
  updates: Array<file_updates>;
};

/** mutation root */
export type mutation_rootupdate_insulation_material_categoryArgs = {
  _set?: InputMaybe<insulation_material_category_set_input>;
  where: insulation_material_category_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_insulation_material_category_by_pkArgs = {
  _set?: InputMaybe<insulation_material_category_set_input>;
  pk_columns: insulation_material_category_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_insulation_material_category_manyArgs = {
  updates: Array<insulation_material_category_updates>;
};

/** mutation root */
export type mutation_rootupdate_insulation_methodArgs = {
  _set?: InputMaybe<insulation_method_set_input>;
  where: insulation_method_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_insulation_method_by_pkArgs = {
  _set?: InputMaybe<insulation_method_set_input>;
  pk_columns: insulation_method_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_insulation_method_manyArgs = {
  updates: Array<insulation_method_updates>;
};

/** mutation root */
export type mutation_rootupdate_invoice_payer_typeArgs = {
  _set?: InputMaybe<invoice_payer_type_set_input>;
  where: invoice_payer_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_invoice_payer_type_by_pkArgs = {
  _set?: InputMaybe<invoice_payer_type_set_input>;
  pk_columns: invoice_payer_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_invoice_payer_type_manyArgs = {
  updates: Array<invoice_payer_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_langfuse_datasets_temporary_itemArgs = {
  _append?: InputMaybe<langfuse_datasets_temporary_item_append_input>;
  _delete_at_path?: InputMaybe<langfuse_datasets_temporary_item_delete_at_path_input>;
  _delete_elem?: InputMaybe<langfuse_datasets_temporary_item_delete_elem_input>;
  _delete_key?: InputMaybe<langfuse_datasets_temporary_item_delete_key_input>;
  _inc?: InputMaybe<langfuse_datasets_temporary_item_inc_input>;
  _prepend?: InputMaybe<langfuse_datasets_temporary_item_prepend_input>;
  _set?: InputMaybe<langfuse_datasets_temporary_item_set_input>;
  where: langfuse_datasets_temporary_item_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_langfuse_datasets_temporary_item_by_pkArgs = {
  _append?: InputMaybe<langfuse_datasets_temporary_item_append_input>;
  _delete_at_path?: InputMaybe<langfuse_datasets_temporary_item_delete_at_path_input>;
  _delete_elem?: InputMaybe<langfuse_datasets_temporary_item_delete_elem_input>;
  _delete_key?: InputMaybe<langfuse_datasets_temporary_item_delete_key_input>;
  _inc?: InputMaybe<langfuse_datasets_temporary_item_inc_input>;
  _prepend?: InputMaybe<langfuse_datasets_temporary_item_prepend_input>;
  _set?: InputMaybe<langfuse_datasets_temporary_item_set_input>;
  pk_columns: langfuse_datasets_temporary_item_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_langfuse_datasets_temporary_item_manyArgs = {
  updates: Array<langfuse_datasets_temporary_item_updates>;
};

/** mutation root */
export type mutation_rootupdate_languageArgs = {
  _set?: InputMaybe<language_set_input>;
  where: language_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_language_by_pkArgs = {
  _set?: InputMaybe<language_set_input>;
  pk_columns: language_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_language_manyArgs = {
  updates: Array<language_updates>;
};

/** mutation root */
export type mutation_rootupdate_meterArgs = {
  _inc?: InputMaybe<meter_inc_input>;
  _set?: InputMaybe<meter_set_input>;
  where: meter_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_meter_by_pkArgs = {
  _inc?: InputMaybe<meter_inc_input>;
  _set?: InputMaybe<meter_set_input>;
  pk_columns: meter_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_meter_integrationArgs = {
  _append?: InputMaybe<meter_integration_append_input>;
  _delete_at_path?: InputMaybe<meter_integration_delete_at_path_input>;
  _delete_elem?: InputMaybe<meter_integration_delete_elem_input>;
  _delete_key?: InputMaybe<meter_integration_delete_key_input>;
  _inc?: InputMaybe<meter_integration_inc_input>;
  _prepend?: InputMaybe<meter_integration_prepend_input>;
  _set?: InputMaybe<meter_integration_set_input>;
  where: meter_integration_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_meter_integration_buildingArgs = {
  _append?: InputMaybe<meter_integration_building_append_input>;
  _delete_at_path?: InputMaybe<meter_integration_building_delete_at_path_input>;
  _delete_elem?: InputMaybe<meter_integration_building_delete_elem_input>;
  _delete_key?: InputMaybe<meter_integration_building_delete_key_input>;
  _inc?: InputMaybe<meter_integration_building_inc_input>;
  _prepend?: InputMaybe<meter_integration_building_prepend_input>;
  _set?: InputMaybe<meter_integration_building_set_input>;
  where: meter_integration_building_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_meter_integration_building_by_pkArgs = {
  _append?: InputMaybe<meter_integration_building_append_input>;
  _delete_at_path?: InputMaybe<meter_integration_building_delete_at_path_input>;
  _delete_elem?: InputMaybe<meter_integration_building_delete_elem_input>;
  _delete_key?: InputMaybe<meter_integration_building_delete_key_input>;
  _inc?: InputMaybe<meter_integration_building_inc_input>;
  _prepend?: InputMaybe<meter_integration_building_prepend_input>;
  _set?: InputMaybe<meter_integration_building_set_input>;
  pk_columns: meter_integration_building_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_meter_integration_building_manyArgs = {
  updates: Array<meter_integration_building_updates>;
};

/** mutation root */
export type mutation_rootupdate_meter_integration_by_pkArgs = {
  _append?: InputMaybe<meter_integration_append_input>;
  _delete_at_path?: InputMaybe<meter_integration_delete_at_path_input>;
  _delete_elem?: InputMaybe<meter_integration_delete_elem_input>;
  _delete_key?: InputMaybe<meter_integration_delete_key_input>;
  _inc?: InputMaybe<meter_integration_inc_input>;
  _prepend?: InputMaybe<meter_integration_prepend_input>;
  _set?: InputMaybe<meter_integration_set_input>;
  pk_columns: meter_integration_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_meter_integration_manyArgs = {
  updates: Array<meter_integration_updates>;
};

/** mutation root */
export type mutation_rootupdate_meter_integration_typeArgs = {
  _set?: InputMaybe<meter_integration_type_set_input>;
  where: meter_integration_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_meter_integration_type_by_pkArgs = {
  _set?: InputMaybe<meter_integration_type_set_input>;
  pk_columns: meter_integration_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_meter_integration_type_manyArgs = {
  updates: Array<meter_integration_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_meter_manyArgs = {
  updates: Array<meter_updates>;
};

/** mutation root */
export type mutation_rootupdate_notificationArgs = {
  _inc?: InputMaybe<notification_inc_input>;
  _set?: InputMaybe<notification_set_input>;
  where: notification_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_notification_by_pkArgs = {
  _inc?: InputMaybe<notification_inc_input>;
  _set?: InputMaybe<notification_set_input>;
  pk_columns: notification_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_notification_manyArgs = {
  updates: Array<notification_updates>;
};

/** mutation root */
export type mutation_rootupdate_notification_templateArgs = {
  _inc?: InputMaybe<notification_template_inc_input>;
  _set?: InputMaybe<notification_template_set_input>;
  where: notification_template_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_notification_template_by_pkArgs = {
  _inc?: InputMaybe<notification_template_inc_input>;
  _set?: InputMaybe<notification_template_set_input>;
  pk_columns: notification_template_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_notification_template_manyArgs = {
  updates: Array<notification_template_updates>;
};

/** mutation root */
export type mutation_rootupdate_notification_typeArgs = {
  _set?: InputMaybe<notification_type_set_input>;
  where: notification_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_notification_type_by_pkArgs = {
  _set?: InputMaybe<notification_type_set_input>;
  pk_columns: notification_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_notification_type_manyArgs = {
  updates: Array<notification_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_operation_model_typeArgs = {
  _set?: InputMaybe<operation_model_type_set_input>;
  where: operation_model_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_operation_model_type_by_pkArgs = {
  _set?: InputMaybe<operation_model_type_set_input>;
  pk_columns: operation_model_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_operation_model_type_manyArgs = {
  updates: Array<operation_model_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_orgArgs = {
  _inc?: InputMaybe<org_inc_input>;
  _set?: InputMaybe<org_set_input>;
  where: org_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_org_by_pkArgs = {
  _inc?: InputMaybe<org_inc_input>;
  _set?: InputMaybe<org_set_input>;
  pk_columns: org_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_org_manyArgs = {
  updates: Array<org_updates>;
};

/** mutation root */
export type mutation_rootupdate_org_preferenceArgs = {
  _inc?: InputMaybe<org_preference_inc_input>;
  _set?: InputMaybe<org_preference_set_input>;
  where: org_preference_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_org_preference_by_pkArgs = {
  _inc?: InputMaybe<org_preference_inc_input>;
  _set?: InputMaybe<org_preference_set_input>;
  pk_columns: org_preference_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_org_preference_manyArgs = {
  updates: Array<org_preference_updates>;
};

/** mutation root */
export type mutation_rootupdate_org_status_typeArgs = {
  _set?: InputMaybe<org_status_type_set_input>;
  where: org_status_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_org_status_type_by_pkArgs = {
  _set?: InputMaybe<org_status_type_set_input>;
  pk_columns: org_status_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_org_status_type_manyArgs = {
  updates: Array<org_status_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_portfolioArgs = {
  _inc?: InputMaybe<portfolio_inc_input>;
  _set?: InputMaybe<portfolio_set_input>;
  where: portfolio_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_portfolio_by_pkArgs = {
  _inc?: InputMaybe<portfolio_inc_input>;
  _set?: InputMaybe<portfolio_set_input>;
  pk_columns: portfolio_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_portfolio_manyArgs = {
  updates: Array<portfolio_updates>;
};

/** mutation root */
export type mutation_rootupdate_renovationArgs = {
  _inc?: InputMaybe<renovation_inc_input>;
  _set?: InputMaybe<renovation_set_input>;
  where: renovation_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_renovation_by_pkArgs = {
  _inc?: InputMaybe<renovation_inc_input>;
  _set?: InputMaybe<renovation_set_input>;
  pk_columns: renovation_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_renovation_energy_consumerArgs = {
  _inc?: InputMaybe<renovation_energy_consumer_inc_input>;
  _set?: InputMaybe<renovation_energy_consumer_set_input>;
  where: renovation_energy_consumer_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_renovation_energy_consumer_manyArgs = {
  updates: Array<renovation_energy_consumer_updates>;
};

/** mutation root */
export type mutation_rootupdate_renovation_energy_distributionArgs = {
  _inc?: InputMaybe<renovation_energy_distribution_inc_input>;
  _set?: InputMaybe<renovation_energy_distribution_set_input>;
  where: renovation_energy_distribution_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_renovation_energy_distribution_manyArgs = {
  updates: Array<renovation_energy_distribution_updates>;
};

/** mutation root */
export type mutation_rootupdate_renovation_energy_producerArgs = {
  _inc?: InputMaybe<renovation_energy_producer_inc_input>;
  _set?: InputMaybe<renovation_energy_producer_set_input>;
  where: renovation_energy_producer_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_renovation_energy_producer_manyArgs = {
  updates: Array<renovation_energy_producer_updates>;
};

/** mutation root */
export type mutation_rootupdate_renovation_energy_storageArgs = {
  _inc?: InputMaybe<renovation_energy_storage_inc_input>;
  _set?: InputMaybe<renovation_energy_storage_set_input>;
  where: renovation_energy_storage_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_renovation_energy_storage_manyArgs = {
  updates: Array<renovation_energy_storage_updates>;
};

/** mutation root */
export type mutation_rootupdate_renovation_energy_system_routeArgs = {
  _inc?: InputMaybe<renovation_energy_system_route_inc_input>;
  _set?: InputMaybe<renovation_energy_system_route_set_input>;
  where: renovation_energy_system_route_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_renovation_energy_system_route_manyArgs = {
  updates: Array<renovation_energy_system_route_updates>;
};

/** mutation root */
export type mutation_rootupdate_renovation_envelopeArgs = {
  _inc?: InputMaybe<renovation_envelope_inc_input>;
  _set?: InputMaybe<renovation_envelope_set_input>;
  where: renovation_envelope_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_renovation_envelope_manyArgs = {
  updates: Array<renovation_envelope_updates>;
};

/** mutation root */
export type mutation_rootupdate_renovation_manyArgs = {
  updates: Array<renovation_updates>;
};

/** mutation root */
export type mutation_rootupdate_renovation_typeArgs = {
  _set?: InputMaybe<renovation_type_set_input>;
  where: renovation_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_renovation_type_by_pkArgs = {
  _set?: InputMaybe<renovation_type_set_input>;
  pk_columns: renovation_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_renovation_type_manyArgs = {
  updates: Array<renovation_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_reportArgs = {
  _inc?: InputMaybe<report_inc_input>;
  _set?: InputMaybe<report_set_input>;
  where: report_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_report_answerArgs = {
  _inc?: InputMaybe<report_answer_inc_input>;
  _set?: InputMaybe<report_answer_set_input>;
  where: report_answer_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_report_answer_autofill_optionArgs = {
  _inc?: InputMaybe<report_answer_autofill_option_inc_input>;
  _set?: InputMaybe<report_answer_autofill_option_set_input>;
  where: report_answer_autofill_option_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_report_answer_autofill_option_by_pkArgs = {
  _inc?: InputMaybe<report_answer_autofill_option_inc_input>;
  _set?: InputMaybe<report_answer_autofill_option_set_input>;
  pk_columns: report_answer_autofill_option_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_report_answer_autofill_option_manyArgs = {
  updates: Array<report_answer_autofill_option_updates>;
};

/** mutation root */
export type mutation_rootupdate_report_answer_by_pkArgs = {
  _inc?: InputMaybe<report_answer_inc_input>;
  _set?: InputMaybe<report_answer_set_input>;
  pk_columns: report_answer_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_report_answer_chosen_optionArgs = {
  _inc?: InputMaybe<report_answer_chosen_option_inc_input>;
  _set?: InputMaybe<report_answer_chosen_option_set_input>;
  where: report_answer_chosen_option_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_report_answer_chosen_option_by_pkArgs = {
  _inc?: InputMaybe<report_answer_chosen_option_inc_input>;
  _set?: InputMaybe<report_answer_chosen_option_set_input>;
  pk_columns: report_answer_chosen_option_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_report_answer_chosen_option_manyArgs = {
  updates: Array<report_answer_chosen_option_updates>;
};

/** mutation root */
export type mutation_rootupdate_report_answer_manyArgs = {
  updates: Array<report_answer_updates>;
};

/** mutation root */
export type mutation_rootupdate_report_answer_stateArgs = {
  _set?: InputMaybe<report_answer_state_set_input>;
  where: report_answer_state_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_report_answer_state_by_pkArgs = {
  _set?: InputMaybe<report_answer_state_set_input>;
  pk_columns: report_answer_state_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_report_answer_state_manyArgs = {
  updates: Array<report_answer_state_updates>;
};

/** mutation root */
export type mutation_rootupdate_report_by_pkArgs = {
  _inc?: InputMaybe<report_inc_input>;
  _set?: InputMaybe<report_set_input>;
  pk_columns: report_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_report_evidenceArgs = {
  _inc?: InputMaybe<report_evidence_inc_input>;
  _set?: InputMaybe<report_evidence_set_input>;
  where: report_evidence_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_report_evidence_by_pkArgs = {
  _inc?: InputMaybe<report_evidence_inc_input>;
  _set?: InputMaybe<report_evidence_set_input>;
  pk_columns: report_evidence_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_report_evidence_manyArgs = {
  updates: Array<report_evidence_updates>;
};

/** mutation root */
export type mutation_rootupdate_report_manyArgs = {
  updates: Array<report_updates>;
};

/** mutation root */
export type mutation_rootupdate_report_question_evidenceArgs = {
  _inc?: InputMaybe<report_question_evidence_inc_input>;
  _set?: InputMaybe<report_question_evidence_set_input>;
  where: report_question_evidence_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_report_question_evidence_by_pkArgs = {
  _inc?: InputMaybe<report_question_evidence_inc_input>;
  _set?: InputMaybe<report_question_evidence_set_input>;
  pk_columns: report_question_evidence_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_report_question_evidence_manyArgs = {
  updates: Array<report_question_evidence_updates>;
};

/** mutation root */
export type mutation_rootupdate_report_scoreArgs = {
  _inc?: InputMaybe<report_score_inc_input>;
  _set?: InputMaybe<report_score_set_input>;
  where: report_score_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_report_score_by_pkArgs = {
  _inc?: InputMaybe<report_score_inc_input>;
  _set?: InputMaybe<report_score_set_input>;
  pk_columns: report_score_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_report_score_manyArgs = {
  updates: Array<report_score_updates>;
};

/** mutation root */
export type mutation_rootupdate_report_section_progressArgs = {
  _inc?: InputMaybe<report_section_progress_inc_input>;
  _set?: InputMaybe<report_section_progress_set_input>;
  where: report_section_progress_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_report_section_progress_by_pkArgs = {
  _inc?: InputMaybe<report_section_progress_inc_input>;
  _set?: InputMaybe<report_section_progress_set_input>;
  pk_columns: report_section_progress_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_report_section_progress_manyArgs = {
  updates: Array<report_section_progress_updates>;
};

/** mutation root */
export type mutation_rootupdate_report_sub_buildingArgs = {
  _inc?: InputMaybe<report_sub_building_inc_input>;
  _set?: InputMaybe<report_sub_building_set_input>;
  where: report_sub_building_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_report_sub_building_by_pkArgs = {
  _inc?: InputMaybe<report_sub_building_inc_input>;
  _set?: InputMaybe<report_sub_building_set_input>;
  pk_columns: report_sub_building_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_report_sub_building_manyArgs = {
  updates: Array<report_sub_building_updates>;
};

/** mutation root */
export type mutation_rootupdate_report_templateArgs = {
  _inc?: InputMaybe<report_template_inc_input>;
  _set?: InputMaybe<report_template_set_input>;
  where: report_template_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_report_template_by_pkArgs = {
  _inc?: InputMaybe<report_template_inc_input>;
  _set?: InputMaybe<report_template_set_input>;
  pk_columns: report_template_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_report_template_manyArgs = {
  updates: Array<report_template_updates>;
};

/** mutation root */
export type mutation_rootupdate_report_template_questionArgs = {
  _append?: InputMaybe<report_template_question_append_input>;
  _delete_at_path?: InputMaybe<report_template_question_delete_at_path_input>;
  _delete_elem?: InputMaybe<report_template_question_delete_elem_input>;
  _delete_key?: InputMaybe<report_template_question_delete_key_input>;
  _inc?: InputMaybe<report_template_question_inc_input>;
  _prepend?: InputMaybe<report_template_question_prepend_input>;
  _set?: InputMaybe<report_template_question_set_input>;
  where: report_template_question_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_report_template_question_by_pkArgs = {
  _append?: InputMaybe<report_template_question_append_input>;
  _delete_at_path?: InputMaybe<report_template_question_delete_at_path_input>;
  _delete_elem?: InputMaybe<report_template_question_delete_elem_input>;
  _delete_key?: InputMaybe<report_template_question_delete_key_input>;
  _inc?: InputMaybe<report_template_question_inc_input>;
  _prepend?: InputMaybe<report_template_question_prepend_input>;
  _set?: InputMaybe<report_template_question_set_input>;
  pk_columns: report_template_question_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_report_template_question_manyArgs = {
  updates: Array<report_template_question_updates>;
};

/** mutation root */
export type mutation_rootupdate_report_template_question_optionArgs = {
  _inc?: InputMaybe<report_template_question_option_inc_input>;
  _set?: InputMaybe<report_template_question_option_set_input>;
  where: report_template_question_option_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_report_template_question_option_by_pkArgs = {
  _inc?: InputMaybe<report_template_question_option_inc_input>;
  _set?: InputMaybe<report_template_question_option_set_input>;
  pk_columns: report_template_question_option_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_report_template_question_option_manyArgs = {
  updates: Array<report_template_question_option_updates>;
};

/** mutation root */
export type mutation_rootupdate_report_template_question_typeArgs = {
  _set?: InputMaybe<report_template_question_type_set_input>;
  where: report_template_question_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_report_template_question_type_by_pkArgs = {
  _set?: InputMaybe<report_template_question_type_set_input>;
  pk_columns: report_template_question_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_report_template_question_type_manyArgs = {
  updates: Array<report_template_question_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_report_template_scoreArgs = {
  _inc?: InputMaybe<report_template_score_inc_input>;
  _set?: InputMaybe<report_template_score_set_input>;
  where: report_template_score_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_report_template_score_by_pkArgs = {
  _inc?: InputMaybe<report_template_score_inc_input>;
  _set?: InputMaybe<report_template_score_set_input>;
  pk_columns: report_template_score_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_report_template_score_display_typeArgs = {
  _set?: InputMaybe<report_template_score_display_type_set_input>;
  where: report_template_score_display_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_report_template_score_display_type_by_pkArgs = {
  _set?: InputMaybe<report_template_score_display_type_set_input>;
  pk_columns: report_template_score_display_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_report_template_score_display_type_manyArgs = {
  updates: Array<report_template_score_display_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_report_template_score_manyArgs = {
  updates: Array<report_template_score_updates>;
};

/** mutation root */
export type mutation_rootupdate_report_template_sectionArgs = {
  _inc?: InputMaybe<report_template_section_inc_input>;
  _set?: InputMaybe<report_template_section_set_input>;
  where: report_template_section_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_report_template_section_by_pkArgs = {
  _inc?: InputMaybe<report_template_section_inc_input>;
  _set?: InputMaybe<report_template_section_set_input>;
  pk_columns: report_template_section_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_report_template_section_manyArgs = {
  updates: Array<report_template_section_updates>;
};

/** mutation root */
export type mutation_rootupdate_report_template_section_targetArgs = {
  _set?: InputMaybe<report_template_section_target_set_input>;
  where: report_template_section_target_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_report_template_section_target_by_pkArgs = {
  _set?: InputMaybe<report_template_section_target_set_input>;
  pk_columns: report_template_section_target_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_report_template_section_target_manyArgs = {
  updates: Array<report_template_section_target_updates>;
};

/** mutation root */
export type mutation_rootupdate_roleArgs = {
  _set?: InputMaybe<role_set_input>;
  where: role_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_role_by_pkArgs = {
  _set?: InputMaybe<role_set_input>;
  pk_columns: role_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_role_manyArgs = {
  updates: Array<role_updates>;
};

/** mutation root */
export type mutation_rootupdate_roof_typeArgs = {
  _set?: InputMaybe<roof_type_set_input>;
  where: roof_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_roof_type_by_pkArgs = {
  _set?: InputMaybe<roof_type_set_input>;
  pk_columns: roof_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_roof_type_manyArgs = {
  updates: Array<roof_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_scenarioArgs = {
  _inc?: InputMaybe<scenario_inc_input>;
  _set?: InputMaybe<scenario_set_input>;
  where: scenario_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_scenario_by_pkArgs = {
  _inc?: InputMaybe<scenario_inc_input>;
  _set?: InputMaybe<scenario_set_input>;
  pk_columns: scenario_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_scenario_manyArgs = {
  updates: Array<scenario_updates>;
};

/** mutation root */
export type mutation_rootupdate_sub_buildingArgs = {
  _inc?: InputMaybe<sub_building_inc_input>;
  _set?: InputMaybe<sub_building_set_input>;
  where: sub_building_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_sub_building_by_pkArgs = {
  _inc?: InputMaybe<sub_building_inc_input>;
  _set?: InputMaybe<sub_building_set_input>;
  pk_columns: sub_building_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_sub_building_classArgs = {
  _set?: InputMaybe<sub_building_class_set_input>;
  where: sub_building_class_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_sub_building_class_by_pkArgs = {
  _set?: InputMaybe<sub_building_class_set_input>;
  pk_columns: sub_building_class_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_sub_building_class_manyArgs = {
  updates: Array<sub_building_class_updates>;
};

/** mutation root */
export type mutation_rootupdate_sub_building_manyArgs = {
  updates: Array<sub_building_updates>;
};

/** mutation root */
export type mutation_rootupdate_sub_building_subsidyArgs = {
  _inc?: InputMaybe<sub_building_subsidy_inc_input>;
  _set?: InputMaybe<sub_building_subsidy_set_input>;
  where: sub_building_subsidy_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_sub_building_subsidy_by_pkArgs = {
  _inc?: InputMaybe<sub_building_subsidy_inc_input>;
  _set?: InputMaybe<sub_building_subsidy_set_input>;
  pk_columns: sub_building_subsidy_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_sub_building_subsidy_manyArgs = {
  updates: Array<sub_building_subsidy_updates>;
};

/** mutation root */
export type mutation_rootupdate_subsidyArgs = {
  _inc?: InputMaybe<subsidy_inc_input>;
  _set?: InputMaybe<subsidy_set_input>;
  where: subsidy_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_subsidy_by_pkArgs = {
  _inc?: InputMaybe<subsidy_inc_input>;
  _set?: InputMaybe<subsidy_set_input>;
  pk_columns: subsidy_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_subsidy_category_typeArgs = {
  _set?: InputMaybe<subsidy_category_type_set_input>;
  where: subsidy_category_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_subsidy_category_type_by_pkArgs = {
  _set?: InputMaybe<subsidy_category_type_set_input>;
  pk_columns: subsidy_category_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_subsidy_category_type_manyArgs = {
  updates: Array<subsidy_category_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_subsidy_manyArgs = {
  updates: Array<subsidy_updates>;
};

/** mutation root */
export type mutation_rootupdate_subsidy_region_typeArgs = {
  _set?: InputMaybe<subsidy_region_type_set_input>;
  where: subsidy_region_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_subsidy_region_type_by_pkArgs = {
  _set?: InputMaybe<subsidy_region_type_set_input>;
  pk_columns: subsidy_region_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_subsidy_region_type_manyArgs = {
  updates: Array<subsidy_region_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_subsidy_subsidy_categoryArgs = {
  _inc?: InputMaybe<subsidy_subsidy_category_inc_input>;
  _set?: InputMaybe<subsidy_subsidy_category_set_input>;
  where: subsidy_subsidy_category_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_subsidy_subsidy_category_by_pkArgs = {
  _inc?: InputMaybe<subsidy_subsidy_category_inc_input>;
  _set?: InputMaybe<subsidy_subsidy_category_set_input>;
  pk_columns: subsidy_subsidy_category_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_subsidy_subsidy_category_manyArgs = {
  updates: Array<subsidy_subsidy_category_updates>;
};

/** mutation root */
export type mutation_rootupdate_subsidy_typeArgs = {
  _set?: InputMaybe<subsidy_type_set_input>;
  where: subsidy_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_subsidy_type_by_pkArgs = {
  _set?: InputMaybe<subsidy_type_set_input>;
  pk_columns: subsidy_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_subsidy_type_manyArgs = {
  updates: Array<subsidy_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_sync_stateArgs = {
  _set?: InputMaybe<sync_state_set_input>;
  where: sync_state_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_sync_state_by_pkArgs = {
  _set?: InputMaybe<sync_state_set_input>;
  pk_columns: sync_state_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_sync_state_manyArgs = {
  updates: Array<sync_state_updates>;
};

/** mutation root */
export type mutation_rootupdate_target_pathArgs = {
  _inc?: InputMaybe<target_path_inc_input>;
  _set?: InputMaybe<target_path_set_input>;
  where: target_path_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_target_path_by_pkArgs = {
  _inc?: InputMaybe<target_path_inc_input>;
  _set?: InputMaybe<target_path_set_input>;
  pk_columns: target_path_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_target_path_manyArgs = {
  updates: Array<target_path_updates>;
};

/** mutation root */
export type mutation_rootupdate_target_path_templateArgs = {
  _inc?: InputMaybe<target_path_template_inc_input>;
  _set?: InputMaybe<target_path_template_set_input>;
  where: target_path_template_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_target_path_template_by_pkArgs = {
  _inc?: InputMaybe<target_path_template_inc_input>;
  _set?: InputMaybe<target_path_template_set_input>;
  pk_columns: target_path_template_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_target_path_template_manyArgs = {
  updates: Array<target_path_template_updates>;
};

/** mutation root */
export type mutation_rootupdate_target_path_template_typeArgs = {
  _set?: InputMaybe<target_path_template_type_set_input>;
  where: target_path_template_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_target_path_template_type_by_pkArgs = {
  _set?: InputMaybe<target_path_template_type_set_input>;
  pk_columns: target_path_template_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_target_path_template_type_manyArgs = {
  updates: Array<target_path_template_type_updates>;
};

/** mutation root */
export type mutation_rootupdate_tax_bracketArgs = {
  _set?: InputMaybe<tax_bracket_set_input>;
  where: tax_bracket_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_tax_bracket_by_pkArgs = {
  _set?: InputMaybe<tax_bracket_set_input>;
  pk_columns: tax_bracket_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_tax_bracket_manyArgs = {
  updates: Array<tax_bracket_updates>;
};

/** mutation root */
export type mutation_rootupdate_type_of_useArgs = {
  _set?: InputMaybe<type_of_use_set_input>;
  where: type_of_use_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_type_of_use_by_pkArgs = {
  _set?: InputMaybe<type_of_use_set_input>;
  pk_columns: type_of_use_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_type_of_use_manyArgs = {
  updates: Array<type_of_use_updates>;
};

/** mutation root */
export type mutation_rootupdate_userArgs = {
  _inc?: InputMaybe<user_inc_input>;
  _set?: InputMaybe<user_set_input>;
  where: user_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_user_building_permissionArgs = {
  _inc?: InputMaybe<user_building_permission_inc_input>;
  _set?: InputMaybe<user_building_permission_set_input>;
  where: user_building_permission_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_user_building_permission_by_pkArgs = {
  _inc?: InputMaybe<user_building_permission_inc_input>;
  _set?: InputMaybe<user_building_permission_set_input>;
  pk_columns: user_building_permission_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_user_building_permission_manyArgs = {
  updates: Array<user_building_permission_updates>;
};

/** mutation root */
export type mutation_rootupdate_user_by_pkArgs = {
  _inc?: InputMaybe<user_inc_input>;
  _set?: InputMaybe<user_set_input>;
  pk_columns: user_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_user_manyArgs = {
  updates: Array<user_updates>;
};

/** mutation root */
export type mutation_rootupdate_user_portfolio_permissionArgs = {
  _inc?: InputMaybe<user_portfolio_permission_inc_input>;
  _set?: InputMaybe<user_portfolio_permission_set_input>;
  where: user_portfolio_permission_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_user_portfolio_permission_by_pkArgs = {
  _inc?: InputMaybe<user_portfolio_permission_inc_input>;
  _set?: InputMaybe<user_portfolio_permission_set_input>;
  pk_columns: user_portfolio_permission_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_user_portfolio_permission_manyArgs = {
  updates: Array<user_portfolio_permission_updates>;
};

/** mutation root */
export type mutation_rootupdate_user_roleArgs = {
  _inc?: InputMaybe<user_role_inc_input>;
  _set?: InputMaybe<user_role_set_input>;
  where: user_role_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_user_role_by_pkArgs = {
  _inc?: InputMaybe<user_role_inc_input>;
  _set?: InputMaybe<user_role_set_input>;
  pk_columns: user_role_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_user_role_manyArgs = {
  updates: Array<user_role_updates>;
};

/** mutation root */
export type mutation_rootupdate_window_typeArgs = {
  _set?: InputMaybe<window_type_set_input>;
  where: window_type_bool_exp;
};

/** mutation root */
export type mutation_rootupdate_window_type_by_pkArgs = {
  _set?: InputMaybe<window_type_set_input>;
  pk_columns: window_type_pk_columns_input;
};

/** mutation root */
export type mutation_rootupdate_window_type_manyArgs = {
  updates: Array<window_type_updates>;
};

/** user facing notification instance, potentially generated by a template. */
export type notification = {
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  notification_template: notification_template;
  read: Scalars['Boolean'];
  template_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: user;
  user_id: Scalars['Int'];
};

/** aggregated selection of "notification" */
export type notification_aggregate = {
  aggregate?: Maybe<notification_aggregate_fields>;
  nodes: Array<notification>;
};

export type notification_aggregate_bool_exp = {
  bool_and?: InputMaybe<notification_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<notification_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<notification_aggregate_bool_exp_count>;
};

export type notification_aggregate_bool_exp_bool_and = {
  arguments: notification_select_column_notification_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<notification_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type notification_aggregate_bool_exp_bool_or = {
  arguments: notification_select_column_notification_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<notification_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type notification_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<notification_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<notification_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "notification" */
export type notification_aggregate_fields = {
  avg?: Maybe<notification_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<notification_max_fields>;
  min?: Maybe<notification_min_fields>;
  stddev?: Maybe<notification_stddev_fields>;
  stddev_pop?: Maybe<notification_stddev_pop_fields>;
  stddev_samp?: Maybe<notification_stddev_samp_fields>;
  sum?: Maybe<notification_sum_fields>;
  var_pop?: Maybe<notification_var_pop_fields>;
  var_samp?: Maybe<notification_var_samp_fields>;
  variance?: Maybe<notification_variance_fields>;
};

/** aggregate fields of "notification" */
export type notification_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<notification_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "notification" */
export type notification_aggregate_order_by = {
  avg?: InputMaybe<notification_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<notification_max_order_by>;
  min?: InputMaybe<notification_min_order_by>;
  stddev?: InputMaybe<notification_stddev_order_by>;
  stddev_pop?: InputMaybe<notification_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<notification_stddev_samp_order_by>;
  sum?: InputMaybe<notification_sum_order_by>;
  var_pop?: InputMaybe<notification_var_pop_order_by>;
  var_samp?: InputMaybe<notification_var_samp_order_by>;
  variance?: InputMaybe<notification_variance_order_by>;
};

/** input type for inserting array relation for remote table "notification" */
export type notification_arr_rel_insert_input = {
  data: Array<notification_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<notification_on_conflict>;
};

/** aggregate avg on columns */
export type notification_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  template_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "notification" */
export type notification_avg_order_by = {
  id?: InputMaybe<order_by>;
  template_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "notification". All fields are combined with a logical 'AND'. */
export type notification_bool_exp = {
  _and?: InputMaybe<Array<notification_bool_exp>>;
  _not?: InputMaybe<notification_bool_exp>;
  _or?: InputMaybe<Array<notification_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  notification_template?: InputMaybe<notification_template_bool_exp>;
  read?: InputMaybe<Boolean_comparison_exp>;
  template_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  user?: InputMaybe<user_bool_exp>;
  user_id?: InputMaybe<Int_comparison_exp>;
};

export { notification_constraint };

/** input type for incrementing numeric columns in table "notification" */
export type notification_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  template_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "notification" */
export type notification_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  notification_template?: InputMaybe<notification_template_obj_rel_insert_input>;
  read?: InputMaybe<Scalars['Boolean']>;
  template_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<user_obj_rel_insert_input>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type notification_max_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  template_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "notification" */
export type notification_max_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  template_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type notification_min_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  template_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "notification" */
export type notification_min_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  template_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "notification" */
export type notification_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<notification>;
};

/** on_conflict condition type for table "notification" */
export type notification_on_conflict = {
  constraint: notification_constraint;
  update_columns?: Array<notification_update_column>;
  where?: InputMaybe<notification_bool_exp>;
};

/** Ordering options when selecting data from "notification". */
export type notification_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  notification_template?: InputMaybe<notification_template_order_by>;
  read?: InputMaybe<order_by>;
  template_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  user?: InputMaybe<user_order_by>;
  user_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: notification */
export type notification_pk_columns_input = {
  id: Scalars['Int'];
};

export { notification_select_column };

export { notification_select_column_notification_aggregate_bool_exp_bool_and_arguments_columns };

export { notification_select_column_notification_aggregate_bool_exp_bool_or_arguments_columns };

/** input type for updating data in table "notification" */
export type notification_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  read?: InputMaybe<Scalars['Boolean']>;
  template_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type notification_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  template_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "notification" */
export type notification_stddev_order_by = {
  id?: InputMaybe<order_by>;
  template_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type notification_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  template_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "notification" */
export type notification_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  template_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type notification_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  template_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "notification" */
export type notification_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  template_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "notification" */
export type notification_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: notification_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type notification_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  read?: InputMaybe<Scalars['Boolean']>;
  template_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type notification_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  template_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "notification" */
export type notification_sum_order_by = {
  id?: InputMaybe<order_by>;
  template_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** templates for any type of generic notification which is then distributed to each user. */
export type notification_template = {
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  href?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An array relationship */
  notifications: Array<notification>;
  /** An aggregate relationship */
  notifications_aggregate: notification_aggregate;
  org_id?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  type: notification_type_enum;
  updated_at: Scalars['timestamptz'];
};

/** templates for any type of generic notification which is then distributed to each user. */
export type notification_templatenotificationsArgs = {
  distinct_on?: InputMaybe<Array<notification_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<notification_order_by>>;
  where?: InputMaybe<notification_bool_exp>;
};

/** templates for any type of generic notification which is then distributed to each user. */
export type notification_templatenotifications_aggregateArgs = {
  distinct_on?: InputMaybe<Array<notification_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<notification_order_by>>;
  where?: InputMaybe<notification_bool_exp>;
};

/** aggregated selection of "notification_template" */
export type notification_template_aggregate = {
  aggregate?: Maybe<notification_template_aggregate_fields>;
  nodes: Array<notification_template>;
};

/** aggregate fields of "notification_template" */
export type notification_template_aggregate_fields = {
  avg?: Maybe<notification_template_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<notification_template_max_fields>;
  min?: Maybe<notification_template_min_fields>;
  stddev?: Maybe<notification_template_stddev_fields>;
  stddev_pop?: Maybe<notification_template_stddev_pop_fields>;
  stddev_samp?: Maybe<notification_template_stddev_samp_fields>;
  sum?: Maybe<notification_template_sum_fields>;
  var_pop?: Maybe<notification_template_var_pop_fields>;
  var_samp?: Maybe<notification_template_var_samp_fields>;
  variance?: Maybe<notification_template_variance_fields>;
};

/** aggregate fields of "notification_template" */
export type notification_template_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<notification_template_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type notification_template_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "notification_template". All fields are combined with a logical 'AND'. */
export type notification_template_bool_exp = {
  _and?: InputMaybe<Array<notification_template_bool_exp>>;
  _not?: InputMaybe<notification_template_bool_exp>;
  _or?: InputMaybe<Array<notification_template_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  description?: InputMaybe<String_comparison_exp>;
  href?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  notifications?: InputMaybe<notification_bool_exp>;
  notifications_aggregate?: InputMaybe<notification_aggregate_bool_exp>;
  org_id?: InputMaybe<Int_comparison_exp>;
  title?: InputMaybe<String_comparison_exp>;
  type?: InputMaybe<notification_type_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

export { notification_template_constraint };

/** input type for incrementing numeric columns in table "notification_template" */
export type notification_template_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "notification_template" */
export type notification_template_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  href?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  notifications?: InputMaybe<notification_arr_rel_insert_input>;
  org_id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<notification_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type notification_template_max_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type notification_template_min_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "notification_template" */
export type notification_template_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<notification_template>;
};

/** input type for inserting object relation for remote table "notification_template" */
export type notification_template_obj_rel_insert_input = {
  data: notification_template_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<notification_template_on_conflict>;
};

/** on_conflict condition type for table "notification_template" */
export type notification_template_on_conflict = {
  constraint: notification_template_constraint;
  update_columns?: Array<notification_template_update_column>;
  where?: InputMaybe<notification_template_bool_exp>;
};

/** Ordering options when selecting data from "notification_template". */
export type notification_template_order_by = {
  created_at?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  href?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  notifications_aggregate?: InputMaybe<notification_aggregate_order_by>;
  org_id?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  type?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: notification_template */
export type notification_template_pk_columns_input = {
  id: Scalars['Int'];
};

export { notification_template_select_column };

/** input type for updating data in table "notification_template" */
export type notification_template_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  href?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<notification_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type notification_template_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type notification_template_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type notification_template_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "notification_template" */
export type notification_template_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: notification_template_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type notification_template_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  href?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<notification_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type notification_template_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
};

export { notification_template_update_column };

export type notification_template_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<notification_template_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<notification_template_set_input>;
  /** filter the rows which have to be updated */
  where: notification_template_bool_exp;
};

/** aggregate var_pop on columns */
export type notification_template_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type notification_template_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type notification_template_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** enum table for the type of notification */
export type notification_type = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

/** aggregated selection of "notification_type" */
export type notification_type_aggregate = {
  aggregate?: Maybe<notification_type_aggregate_fields>;
  nodes: Array<notification_type>;
};

/** aggregate fields of "notification_type" */
export type notification_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<notification_type_max_fields>;
  min?: Maybe<notification_type_min_fields>;
};

/** aggregate fields of "notification_type" */
export type notification_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<notification_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "notification_type". All fields are combined with a logical 'AND'. */
export type notification_type_bool_exp = {
  _and?: InputMaybe<Array<notification_type_bool_exp>>;
  _not?: InputMaybe<notification_type_bool_exp>;
  _or?: InputMaybe<Array<notification_type_bool_exp>>;
  description?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<String_comparison_exp>;
};

export { notification_type_constraint };

export { notification_type_enum };

/** Boolean expression to compare columns of type "notification_type_enum". All fields are combined with logical 'AND'. */
export type notification_type_enum_comparison_exp = {
  _eq?: InputMaybe<notification_type_enum>;
  _in?: InputMaybe<Array<notification_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<notification_type_enum>;
  _nin?: InputMaybe<Array<notification_type_enum>>;
};

/** input type for inserting data into table "notification_type" */
export type notification_type_insert_input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type notification_type_max_fields = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type notification_type_min_fields = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "notification_type" */
export type notification_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<notification_type>;
};

/** on_conflict condition type for table "notification_type" */
export type notification_type_on_conflict = {
  constraint: notification_type_constraint;
  update_columns?: Array<notification_type_update_column>;
  where?: InputMaybe<notification_type_bool_exp>;
};

/** Ordering options when selecting data from "notification_type". */
export type notification_type_order_by = {
  description?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: notification_type */
export type notification_type_pk_columns_input = {
  id: Scalars['String'];
};

export { notification_type_select_column };

/** input type for updating data in table "notification_type" */
export type notification_type_set_input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "notification_type" */
export type notification_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: notification_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type notification_type_stream_cursor_value_input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export { notification_type_update_column };

export type notification_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<notification_type_set_input>;
  /** filter the rows which have to be updated */
  where: notification_type_bool_exp;
};

export { notification_update_column };

export type notification_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<notification_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<notification_set_input>;
  /** filter the rows which have to be updated */
  where: notification_bool_exp;
};

/** aggregate var_pop on columns */
export type notification_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  template_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "notification" */
export type notification_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  template_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type notification_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  template_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "notification" */
export type notification_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  template_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type notification_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  template_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "notification" */
export type notification_variance_order_by = {
  id?: InputMaybe<order_by>;
  template_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type numeric_comparison_exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** columns and relationships of "operation_model_type" */
export type operation_model_type = {
  id: Scalars['String'];
};

/** aggregated selection of "operation_model_type" */
export type operation_model_type_aggregate = {
  aggregate?: Maybe<operation_model_type_aggregate_fields>;
  nodes: Array<operation_model_type>;
};

/** aggregate fields of "operation_model_type" */
export type operation_model_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<operation_model_type_max_fields>;
  min?: Maybe<operation_model_type_min_fields>;
};

/** aggregate fields of "operation_model_type" */
export type operation_model_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<operation_model_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "operation_model_type". All fields are combined with a logical 'AND'. */
export type operation_model_type_bool_exp = {
  _and?: InputMaybe<Array<operation_model_type_bool_exp>>;
  _not?: InputMaybe<operation_model_type_bool_exp>;
  _or?: InputMaybe<Array<operation_model_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { operation_model_type_constraint };

export { operation_model_type_enum };

/** Boolean expression to compare columns of type "operation_model_type_enum". All fields are combined with logical 'AND'. */
export type operation_model_type_enum_comparison_exp = {
  _eq?: InputMaybe<operation_model_type_enum>;
  _in?: InputMaybe<Array<operation_model_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<operation_model_type_enum>;
  _nin?: InputMaybe<Array<operation_model_type_enum>>;
};

/** input type for inserting data into table "operation_model_type" */
export type operation_model_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type operation_model_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type operation_model_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "operation_model_type" */
export type operation_model_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<operation_model_type>;
};

/** on_conflict condition type for table "operation_model_type" */
export type operation_model_type_on_conflict = {
  constraint: operation_model_type_constraint;
  update_columns?: Array<operation_model_type_update_column>;
  where?: InputMaybe<operation_model_type_bool_exp>;
};

/** Ordering options when selecting data from "operation_model_type". */
export type operation_model_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: operation_model_type */
export type operation_model_type_pk_columns_input = {
  id: Scalars['String'];
};

export { operation_model_type_select_column };

/** input type for updating data in table "operation_model_type" */
export type operation_model_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "operation_model_type" */
export type operation_model_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: operation_model_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type operation_model_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { operation_model_type_update_column };

export type operation_model_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<operation_model_type_set_input>;
  /** filter the rows which have to be updated */
  where: operation_model_type_bool_exp;
};

export { order_by };

/** columns and relationships of "org" */
export type org = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An object relationship */
  logo?: Maybe<file>;
  logo_file_id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  /** An object relationship */
  org_preferences?: Maybe<org_preference>;
  /** An array relationship */
  portfolios: Array<portfolio>;
  /** An aggregate relationship */
  portfolios_aggregate: portfolio_aggregate;
  status_type_id: org_status_type_enum;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  users: Array<user>;
  /** An aggregate relationship */
  users_aggregate: user_aggregate;
};

/** columns and relationships of "org" */
export type orgportfoliosArgs = {
  distinct_on?: InputMaybe<Array<portfolio_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<portfolio_order_by>>;
  where?: InputMaybe<portfolio_bool_exp>;
};

/** columns and relationships of "org" */
export type orgportfolios_aggregateArgs = {
  distinct_on?: InputMaybe<Array<portfolio_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<portfolio_order_by>>;
  where?: InputMaybe<portfolio_bool_exp>;
};

/** columns and relationships of "org" */
export type orgusersArgs = {
  distinct_on?: InputMaybe<Array<user_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_order_by>>;
  where?: InputMaybe<user_bool_exp>;
};

/** columns and relationships of "org" */
export type orgusers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<user_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_order_by>>;
  where?: InputMaybe<user_bool_exp>;
};

/** aggregated selection of "org" */
export type org_aggregate = {
  aggregate?: Maybe<org_aggregate_fields>;
  nodes: Array<org>;
};

/** aggregate fields of "org" */
export type org_aggregate_fields = {
  avg?: Maybe<org_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<org_max_fields>;
  min?: Maybe<org_min_fields>;
  stddev?: Maybe<org_stddev_fields>;
  stddev_pop?: Maybe<org_stddev_pop_fields>;
  stddev_samp?: Maybe<org_stddev_samp_fields>;
  sum?: Maybe<org_sum_fields>;
  var_pop?: Maybe<org_var_pop_fields>;
  var_samp?: Maybe<org_var_samp_fields>;
  variance?: Maybe<org_variance_fields>;
};

/** aggregate fields of "org" */
export type org_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<org_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type org_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  logo_file_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "org". All fields are combined with a logical 'AND'. */
export type org_bool_exp = {
  _and?: InputMaybe<Array<org_bool_exp>>;
  _not?: InputMaybe<org_bool_exp>;
  _or?: InputMaybe<Array<org_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  logo?: InputMaybe<file_bool_exp>;
  logo_file_id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  org_preferences?: InputMaybe<org_preference_bool_exp>;
  portfolios?: InputMaybe<portfolio_bool_exp>;
  portfolios_aggregate?: InputMaybe<portfolio_aggregate_bool_exp>;
  status_type_id?: InputMaybe<org_status_type_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  users?: InputMaybe<user_bool_exp>;
  users_aggregate?: InputMaybe<user_aggregate_bool_exp>;
};

export { org_constraint };

/** input type for incrementing numeric columns in table "org" */
export type org_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  logo_file_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "org" */
export type org_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  logo?: InputMaybe<file_obj_rel_insert_input>;
  logo_file_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  org_preferences?: InputMaybe<org_preference_obj_rel_insert_input>;
  portfolios?: InputMaybe<portfolio_arr_rel_insert_input>;
  status_type_id?: InputMaybe<org_status_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  users?: InputMaybe<user_arr_rel_insert_input>;
};

/** aggregate max on columns */
export type org_max_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  logo_file_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type org_min_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  logo_file_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "org" */
export type org_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<org>;
};

/** input type for inserting object relation for remote table "org" */
export type org_obj_rel_insert_input = {
  data: org_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<org_on_conflict>;
};

/** on_conflict condition type for table "org" */
export type org_on_conflict = {
  constraint: org_constraint;
  update_columns?: Array<org_update_column>;
  where?: InputMaybe<org_bool_exp>;
};

/** Ordering options when selecting data from "org". */
export type org_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  logo?: InputMaybe<file_order_by>;
  logo_file_id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  org_preferences?: InputMaybe<org_preference_order_by>;
  portfolios_aggregate?: InputMaybe<portfolio_aggregate_order_by>;
  status_type_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  users_aggregate?: InputMaybe<user_aggregate_order_by>;
};

/** primary key columns input for table: org */
export type org_pk_columns_input = {
  id: Scalars['Int'];
};

/** columns and relationships of "org_preference" */
export type org_preference = {
  economic_unit_enabled: Scalars['Boolean'];
  emission_factor_type_id: emission_factor_type_enum;
  id: Scalars['Int'];
  /** An object relationship */
  org: org;
  org_id: Scalars['Int'];
};

/** aggregated selection of "org_preference" */
export type org_preference_aggregate = {
  aggregate?: Maybe<org_preference_aggregate_fields>;
  nodes: Array<org_preference>;
};

/** aggregate fields of "org_preference" */
export type org_preference_aggregate_fields = {
  avg?: Maybe<org_preference_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<org_preference_max_fields>;
  min?: Maybe<org_preference_min_fields>;
  stddev?: Maybe<org_preference_stddev_fields>;
  stddev_pop?: Maybe<org_preference_stddev_pop_fields>;
  stddev_samp?: Maybe<org_preference_stddev_samp_fields>;
  sum?: Maybe<org_preference_sum_fields>;
  var_pop?: Maybe<org_preference_var_pop_fields>;
  var_samp?: Maybe<org_preference_var_samp_fields>;
  variance?: Maybe<org_preference_variance_fields>;
};

/** aggregate fields of "org_preference" */
export type org_preference_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<org_preference_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type org_preference_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "org_preference". All fields are combined with a logical 'AND'. */
export type org_preference_bool_exp = {
  _and?: InputMaybe<Array<org_preference_bool_exp>>;
  _not?: InputMaybe<org_preference_bool_exp>;
  _or?: InputMaybe<Array<org_preference_bool_exp>>;
  economic_unit_enabled?: InputMaybe<Boolean_comparison_exp>;
  emission_factor_type_id?: InputMaybe<emission_factor_type_enum_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  org?: InputMaybe<org_bool_exp>;
  org_id?: InputMaybe<Int_comparison_exp>;
};

export { org_preference_constraint };

/** input type for incrementing numeric columns in table "org_preference" */
export type org_preference_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "org_preference" */
export type org_preference_insert_input = {
  economic_unit_enabled?: InputMaybe<Scalars['Boolean']>;
  emission_factor_type_id?: InputMaybe<emission_factor_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  org?: InputMaybe<org_obj_rel_insert_input>;
  org_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type org_preference_max_fields = {
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type org_preference_min_fields = {
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "org_preference" */
export type org_preference_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<org_preference>;
};

/** input type for inserting object relation for remote table "org_preference" */
export type org_preference_obj_rel_insert_input = {
  data: org_preference_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<org_preference_on_conflict>;
};

/** on_conflict condition type for table "org_preference" */
export type org_preference_on_conflict = {
  constraint: org_preference_constraint;
  update_columns?: Array<org_preference_update_column>;
  where?: InputMaybe<org_preference_bool_exp>;
};

/** Ordering options when selecting data from "org_preference". */
export type org_preference_order_by = {
  economic_unit_enabled?: InputMaybe<order_by>;
  emission_factor_type_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org?: InputMaybe<org_order_by>;
  org_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: org_preference */
export type org_preference_pk_columns_input = {
  id: Scalars['Int'];
};

export { org_preference_select_column };

/** input type for updating data in table "org_preference" */
export type org_preference_set_input = {
  economic_unit_enabled?: InputMaybe<Scalars['Boolean']>;
  emission_factor_type_id?: InputMaybe<emission_factor_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type org_preference_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type org_preference_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type org_preference_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "org_preference" */
export type org_preference_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: org_preference_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type org_preference_stream_cursor_value_input = {
  economic_unit_enabled?: InputMaybe<Scalars['Boolean']>;
  emission_factor_type_id?: InputMaybe<emission_factor_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type org_preference_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
};

export { org_preference_update_column };

export type org_preference_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<org_preference_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<org_preference_set_input>;
  /** filter the rows which have to be updated */
  where: org_preference_bool_exp;
};

/** aggregate var_pop on columns */
export type org_preference_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type org_preference_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type org_preference_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

export { org_select_column };

/** input type for updating data in table "org" */
export type org_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  logo_file_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  status_type_id?: InputMaybe<org_status_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "org_status_type" */
export type org_status_type = {
  id: Scalars['String'];
};

/** aggregated selection of "org_status_type" */
export type org_status_type_aggregate = {
  aggregate?: Maybe<org_status_type_aggregate_fields>;
  nodes: Array<org_status_type>;
};

/** aggregate fields of "org_status_type" */
export type org_status_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<org_status_type_max_fields>;
  min?: Maybe<org_status_type_min_fields>;
};

/** aggregate fields of "org_status_type" */
export type org_status_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<org_status_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "org_status_type". All fields are combined with a logical 'AND'. */
export type org_status_type_bool_exp = {
  _and?: InputMaybe<Array<org_status_type_bool_exp>>;
  _not?: InputMaybe<org_status_type_bool_exp>;
  _or?: InputMaybe<Array<org_status_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { org_status_type_constraint };

export { org_status_type_enum };

/** Boolean expression to compare columns of type "org_status_type_enum". All fields are combined with logical 'AND'. */
export type org_status_type_enum_comparison_exp = {
  _eq?: InputMaybe<org_status_type_enum>;
  _in?: InputMaybe<Array<org_status_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<org_status_type_enum>;
  _nin?: InputMaybe<Array<org_status_type_enum>>;
};

/** input type for inserting data into table "org_status_type" */
export type org_status_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type org_status_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type org_status_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "org_status_type" */
export type org_status_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<org_status_type>;
};

/** on_conflict condition type for table "org_status_type" */
export type org_status_type_on_conflict = {
  constraint: org_status_type_constraint;
  update_columns?: Array<org_status_type_update_column>;
  where?: InputMaybe<org_status_type_bool_exp>;
};

/** Ordering options when selecting data from "org_status_type". */
export type org_status_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: org_status_type */
export type org_status_type_pk_columns_input = {
  id: Scalars['String'];
};

export { org_status_type_select_column };

/** input type for updating data in table "org_status_type" */
export type org_status_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "org_status_type" */
export type org_status_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: org_status_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type org_status_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { org_status_type_update_column };

export type org_status_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<org_status_type_set_input>;
  /** filter the rows which have to be updated */
  where: org_status_type_bool_exp;
};

/** aggregate stddev on columns */
export type org_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  logo_file_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type org_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  logo_file_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type org_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  logo_file_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "org" */
export type org_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: org_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type org_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  logo_file_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  status_type_id?: InputMaybe<org_status_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type org_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  logo_file_id?: Maybe<Scalars['Int']>;
};

export { org_update_column };

export type org_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<org_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<org_set_input>;
  /** filter the rows which have to be updated */
  where: org_bool_exp;
};

/** aggregate var_pop on columns */
export type org_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  logo_file_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type org_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  logo_file_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type org_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  logo_file_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "portfolio" */
export type portfolio = {
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  economic_units: Array<economic_unit>;
  /** An aggregate relationship */
  economic_units_aggregate: economic_unit_aggregate;
  emission_factor_type_id?: Maybe<emission_factor_type_enum>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  org: org;
  org_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  user_permissions: Array<user_portfolio_permission>;
  /** An aggregate relationship */
  user_permissions_aggregate: user_portfolio_permission_aggregate;
};

/** columns and relationships of "portfolio" */
export type portfolioeconomic_unitsArgs = {
  distinct_on?: InputMaybe<Array<economic_unit_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<economic_unit_order_by>>;
  where?: InputMaybe<economic_unit_bool_exp>;
};

/** columns and relationships of "portfolio" */
export type portfolioeconomic_units_aggregateArgs = {
  distinct_on?: InputMaybe<Array<economic_unit_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<economic_unit_order_by>>;
  where?: InputMaybe<economic_unit_bool_exp>;
};

/** columns and relationships of "portfolio" */
export type portfoliouser_permissionsArgs = {
  distinct_on?: InputMaybe<Array<user_portfolio_permission_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_portfolio_permission_order_by>>;
  where?: InputMaybe<user_portfolio_permission_bool_exp>;
};

/** columns and relationships of "portfolio" */
export type portfoliouser_permissions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<user_portfolio_permission_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_portfolio_permission_order_by>>;
  where?: InputMaybe<user_portfolio_permission_bool_exp>;
};

/** aggregated selection of "portfolio" */
export type portfolio_aggregate = {
  aggregate?: Maybe<portfolio_aggregate_fields>;
  nodes: Array<portfolio>;
};

export type portfolio_aggregate_bool_exp = {
  count?: InputMaybe<portfolio_aggregate_bool_exp_count>;
};

export type portfolio_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<portfolio_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<portfolio_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "portfolio" */
export type portfolio_aggregate_fields = {
  avg?: Maybe<portfolio_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<portfolio_max_fields>;
  min?: Maybe<portfolio_min_fields>;
  stddev?: Maybe<portfolio_stddev_fields>;
  stddev_pop?: Maybe<portfolio_stddev_pop_fields>;
  stddev_samp?: Maybe<portfolio_stddev_samp_fields>;
  sum?: Maybe<portfolio_sum_fields>;
  var_pop?: Maybe<portfolio_var_pop_fields>;
  var_samp?: Maybe<portfolio_var_samp_fields>;
  variance?: Maybe<portfolio_variance_fields>;
};

/** aggregate fields of "portfolio" */
export type portfolio_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<portfolio_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "portfolio" */
export type portfolio_aggregate_order_by = {
  avg?: InputMaybe<portfolio_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<portfolio_max_order_by>;
  min?: InputMaybe<portfolio_min_order_by>;
  stddev?: InputMaybe<portfolio_stddev_order_by>;
  stddev_pop?: InputMaybe<portfolio_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<portfolio_stddev_samp_order_by>;
  sum?: InputMaybe<portfolio_sum_order_by>;
  var_pop?: InputMaybe<portfolio_var_pop_order_by>;
  var_samp?: InputMaybe<portfolio_var_samp_order_by>;
  variance?: InputMaybe<portfolio_variance_order_by>;
};

/** input type for inserting array relation for remote table "portfolio" */
export type portfolio_arr_rel_insert_input = {
  data: Array<portfolio_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<portfolio_on_conflict>;
};

/** aggregate avg on columns */
export type portfolio_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "portfolio" */
export type portfolio_avg_order_by = {
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "portfolio". All fields are combined with a logical 'AND'. */
export type portfolio_bool_exp = {
  _and?: InputMaybe<Array<portfolio_bool_exp>>;
  _not?: InputMaybe<portfolio_bool_exp>;
  _or?: InputMaybe<Array<portfolio_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  economic_units?: InputMaybe<economic_unit_bool_exp>;
  economic_units_aggregate?: InputMaybe<economic_unit_aggregate_bool_exp>;
  emission_factor_type_id?: InputMaybe<emission_factor_type_enum_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  org?: InputMaybe<org_bool_exp>;
  org_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  user_permissions?: InputMaybe<user_portfolio_permission_bool_exp>;
  user_permissions_aggregate?: InputMaybe<user_portfolio_permission_aggregate_bool_exp>;
};

export { portfolio_constraint };

/** input type for incrementing numeric columns in table "portfolio" */
export type portfolio_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "portfolio" */
export type portfolio_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  economic_units?: InputMaybe<economic_unit_arr_rel_insert_input>;
  emission_factor_type_id?: InputMaybe<emission_factor_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  org?: InputMaybe<org_obj_rel_insert_input>;
  org_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_permissions?: InputMaybe<user_portfolio_permission_arr_rel_insert_input>;
};

/** aggregate max on columns */
export type portfolio_max_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "portfolio" */
export type portfolio_max_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type portfolio_min_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "portfolio" */
export type portfolio_min_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "portfolio" */
export type portfolio_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<portfolio>;
};

/** input type for inserting object relation for remote table "portfolio" */
export type portfolio_obj_rel_insert_input = {
  data: portfolio_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<portfolio_on_conflict>;
};

/** on_conflict condition type for table "portfolio" */
export type portfolio_on_conflict = {
  constraint: portfolio_constraint;
  update_columns?: Array<portfolio_update_column>;
  where?: InputMaybe<portfolio_bool_exp>;
};

/** Ordering options when selecting data from "portfolio". */
export type portfolio_order_by = {
  created_at?: InputMaybe<order_by>;
  economic_units_aggregate?: InputMaybe<economic_unit_aggregate_order_by>;
  emission_factor_type_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  org?: InputMaybe<org_order_by>;
  org_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  user_permissions_aggregate?: InputMaybe<user_portfolio_permission_aggregate_order_by>;
};

/** primary key columns input for table: portfolio */
export type portfolio_pk_columns_input = {
  id: Scalars['Int'];
};

export { portfolio_select_column };

/** input type for updating data in table "portfolio" */
export type portfolio_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  emission_factor_type_id?: InputMaybe<emission_factor_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type portfolio_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "portfolio" */
export type portfolio_stddev_order_by = {
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type portfolio_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "portfolio" */
export type portfolio_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type portfolio_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "portfolio" */
export type portfolio_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "portfolio" */
export type portfolio_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: portfolio_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type portfolio_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  emission_factor_type_id?: InputMaybe<emission_factor_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type portfolio_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "portfolio" */
export type portfolio_sum_order_by = {
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
};

export { portfolio_update_column };

export type portfolio_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<portfolio_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<portfolio_set_input>;
  /** filter the rows which have to be updated */
  where: portfolio_bool_exp;
};

/** aggregate var_pop on columns */
export type portfolio_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "portfolio" */
export type portfolio_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type portfolio_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "portfolio" */
export type portfolio_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type portfolio_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "portfolio" */
export type portfolio_variance_order_by = {
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
};

export type query_root = {
  /** fetch data from the table: "action" */
  action: Array<action>;
  /** fetch aggregated fields from the table: "action" */
  action_aggregate: action_aggregate;
  /** fetch data from the table: "action" using primary key columns */
  action_by_pk?: Maybe<action>;
  /** fetch data from the table: "action_plan" */
  action_plan: Array<action_plan>;
  /** fetch aggregated fields from the table: "action_plan" */
  action_plan_aggregate: action_plan_aggregate;
  /** fetch data from the table: "action_plan" using primary key columns */
  action_plan_by_pk?: Maybe<action_plan>;
  /** fetch data from the table: "action_subsidy" */
  action_subsidy: Array<action_subsidy>;
  /** fetch aggregated fields from the table: "action_subsidy" */
  action_subsidy_aggregate: action_subsidy_aggregate;
  /** fetch data from the table: "action_subsidy" using primary key columns */
  action_subsidy_by_pk?: Maybe<action_subsidy>;
  /** fetch data from the table: "action_subsidy_value_type" */
  action_subsidy_value_type: Array<action_subsidy_value_type>;
  /** fetch aggregated fields from the table: "action_subsidy_value_type" */
  action_subsidy_value_type_aggregate: action_subsidy_value_type_aggregate;
  /** fetch data from the table: "action_subsidy_value_type" using primary key columns */
  action_subsidy_value_type_by_pk?: Maybe<action_subsidy_value_type>;
  /** fetch data from the table: "address" */
  address: Array<address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: address_aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<address>;
  /** fetch data from the table: "api_user" */
  api_user: Array<api_user>;
  /** fetch aggregated fields from the table: "api_user" */
  api_user_aggregate: api_user_aggregate;
  /** fetch data from the table: "api_user" using primary key columns */
  api_user_by_pk?: Maybe<api_user>;
  /** fetch data from the table: "area" */
  area: Array<area>;
  /** fetch aggregated fields from the table: "area" */
  area_aggregate: area_aggregate;
  /** fetch data from the table: "area" using primary key columns */
  area_by_pk?: Maybe<area>;
  /** fetch data from the table: "area_system_type" */
  area_system_type: Array<area_system_type>;
  /** fetch aggregated fields from the table: "area_system_type" */
  area_system_type_aggregate: area_system_type_aggregate;
  /** fetch data from the table: "area_system_type" using primary key columns */
  area_system_type_by_pk?: Maybe<area_system_type>;
  /** fetch data from the table: "area_type" */
  area_type: Array<area_type>;
  /** fetch aggregated fields from the table: "area_type" */
  area_type_aggregate: area_type_aggregate;
  /** fetch data from the table: "area_type" using primary key columns */
  area_type_by_pk?: Maybe<area_type>;
  associatedBuildingsAndDrafts: EconomicUnitAmount;
  /** fetch data from the table: "async_job" */
  async_job: Array<async_job>;
  /** fetch aggregated fields from the table: "async_job" */
  async_job_aggregate: async_job_aggregate;
  /** fetch data from the table: "async_job" using primary key columns */
  async_job_by_pk?: Maybe<async_job>;
  /** fetch data from the table: "async_job_status_type" */
  async_job_status_type: Array<async_job_status_type>;
  /** fetch aggregated fields from the table: "async_job_status_type" */
  async_job_status_type_aggregate: async_job_status_type_aggregate;
  /** fetch data from the table: "async_job_status_type" using primary key columns */
  async_job_status_type_by_pk?: Maybe<async_job_status_type>;
  /** fetch data from the table: "building" */
  building: Array<building>;
  /** fetch aggregated fields from the table: "building" */
  building_aggregate: building_aggregate;
  /** fetch data from the table: "building" using primary key columns */
  building_by_pk?: Maybe<building>;
  /** fetch data from the table: "building_model" */
  building_model: Array<building_model>;
  /** fetch aggregated fields from the table: "building_model" */
  building_model_aggregate: building_model_aggregate;
  /** fetch data from the table: "building_model" using primary key columns */
  building_model_by_pk?: Maybe<building_model>;
  /** fetch data from the table: "building_state" */
  building_state: Array<building_state>;
  /** fetch aggregated fields from the table: "building_state" */
  building_state_aggregate: building_state_aggregate;
  /** fetch data from the table: "building_state" using primary key columns */
  building_state_by_pk?: Maybe<building_state>;
  /** fetch data from the table: "climate_region" */
  climate_region: Array<climate_region>;
  /** fetch aggregated fields from the table: "climate_region" */
  climate_region_aggregate: climate_region_aggregate;
  /** fetch data from the table: "climate_region" using primary key columns */
  climate_region_by_pk?: Maybe<climate_region>;
  /** fetch data from the table: "climate_risk" */
  climate_risk: Array<climate_risk>;
  /** fetch aggregated fields from the table: "climate_risk" */
  climate_risk_aggregate: climate_risk_aggregate;
  /** fetch data from the table: "climate_risk" using primary key columns */
  climate_risk_by_pk?: Maybe<climate_risk>;
  /** fetch data from the table: "climate_risk_type" */
  climate_risk_type: Array<climate_risk_type>;
  /** fetch aggregated fields from the table: "climate_risk_type" */
  climate_risk_type_aggregate: climate_risk_type_aggregate;
  /** fetch data from the table: "climate_risk_type" using primary key columns */
  climate_risk_type_by_pk?: Maybe<climate_risk_type>;
  /** fetch data from the table: "consumption" */
  consumption: Array<consumption>;
  /** fetch aggregated fields from the table: "consumption" */
  consumption_aggregate: consumption_aggregate;
  /** fetch data from the table: "consumption" using primary key columns */
  consumption_by_pk?: Maybe<consumption>;
  /** fetch data from the table: "consumption_draft" */
  consumption_draft: Array<consumption_draft>;
  /** fetch aggregated fields from the table: "consumption_draft" */
  consumption_draft_aggregate: consumption_draft_aggregate;
  /** fetch data from the table: "consumption_draft" using primary key columns */
  consumption_draft_by_pk?: Maybe<consumption_draft>;
  /** fetch data from the table: "consumption_frequency_type" */
  consumption_frequency_type: Array<consumption_frequency_type>;
  /** fetch aggregated fields from the table: "consumption_frequency_type" */
  consumption_frequency_type_aggregate: consumption_frequency_type_aggregate;
  /** fetch data from the table: "consumption_frequency_type" using primary key columns */
  consumption_frequency_type_by_pk?: Maybe<consumption_frequency_type>;
  /** fetch data from the table: "consumption_invoice" */
  consumption_invoice: Array<consumption_invoice>;
  /** fetch aggregated fields from the table: "consumption_invoice" */
  consumption_invoice_aggregate: consumption_invoice_aggregate;
  /** fetch data from the table: "consumption_invoice" using primary key columns */
  consumption_invoice_by_pk?: Maybe<consumption_invoice>;
  /** fetch data from the table: "consumption_invoice_draft" */
  consumption_invoice_draft: Array<consumption_invoice_draft>;
  /** fetch aggregated fields from the table: "consumption_invoice_draft" */
  consumption_invoice_draft_aggregate: consumption_invoice_draft_aggregate;
  /** fetch data from the table: "consumption_invoice_draft" using primary key columns */
  consumption_invoice_draft_by_pk?: Maybe<consumption_invoice_draft>;
  /** fetch data from the table: "consumption_source_type" */
  consumption_source_type: Array<consumption_source_type>;
  /** fetch aggregated fields from the table: "consumption_source_type" */
  consumption_source_type_aggregate: consumption_source_type_aggregate;
  /** fetch data from the table: "consumption_source_type" using primary key columns */
  consumption_source_type_by_pk?: Maybe<consumption_source_type>;
  /** fetch data from the table: "consumption_sub_type" */
  consumption_sub_type: Array<consumption_sub_type>;
  /** fetch aggregated fields from the table: "consumption_sub_type" */
  consumption_sub_type_aggregate: consumption_sub_type_aggregate;
  /** fetch data from the table: "consumption_sub_type" using primary key columns */
  consumption_sub_type_by_pk?: Maybe<consumption_sub_type>;
  /** fetch data from the table: "consumption_type" */
  consumption_type: Array<consumption_type>;
  /** fetch aggregated fields from the table: "consumption_type" */
  consumption_type_aggregate: consumption_type_aggregate;
  /** fetch data from the table: "consumption_type" using primary key columns */
  consumption_type_by_pk?: Maybe<consumption_type>;
  /** fetch data from the table: "consumption_unit" */
  consumption_unit: Array<consumption_unit>;
  /** fetch aggregated fields from the table: "consumption_unit" */
  consumption_unit_aggregate: consumption_unit_aggregate;
  /** fetch data from the table: "consumption_unit" using primary key columns */
  consumption_unit_by_pk?: Maybe<consumption_unit>;
  /** fetch data from the table: "consumption_waste_details" */
  consumption_waste_details: Array<consumption_waste_details>;
  /** fetch aggregated fields from the table: "consumption_waste_details" */
  consumption_waste_details_aggregate: consumption_waste_details_aggregate;
  /** fetch data from the table: "consumption_waste_details" using primary key columns */
  consumption_waste_details_by_pk?: Maybe<consumption_waste_details>;
  /** fetch data from the table: "consumption_waste_details_draft" */
  consumption_waste_details_draft: Array<consumption_waste_details_draft>;
  /** fetch aggregated fields from the table: "consumption_waste_details_draft" */
  consumption_waste_details_draft_aggregate: consumption_waste_details_draft_aggregate;
  /** fetch data from the table: "consumption_waste_details_draft" using primary key columns */
  consumption_waste_details_draft_by_pk?: Maybe<consumption_waste_details_draft>;
  /** fetch data from the table: "country" */
  country: Array<country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: country_aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<country>;
  /** fetch data from the table: "country_state" */
  country_state: Array<country_state>;
  /** fetch aggregated fields from the table: "country_state" */
  country_state_aggregate: country_state_aggregate;
  /** fetch data from the table: "country_state" using primary key columns */
  country_state_by_pk?: Maybe<country_state>;
  /** fetch data from the table: "data_source_type" */
  data_source_type: Array<data_source_type>;
  /** fetch aggregated fields from the table: "data_source_type" */
  data_source_type_aggregate: data_source_type_aggregate;
  /** fetch data from the table: "data_source_type" using primary key columns */
  data_source_type_by_pk?: Maybe<data_source_type>;
  /** fetch data from the table: "draft_status" */
  draft_status: Array<draft_status>;
  /** fetch aggregated fields from the table: "draft_status" */
  draft_status_aggregate: draft_status_aggregate;
  /** fetch data from the table: "draft_status" using primary key columns */
  draft_status_by_pk?: Maybe<draft_status>;
  /** fetch data from the table: "economic_unit" */
  economic_unit: Array<economic_unit>;
  /** fetch aggregated fields from the table: "economic_unit" */
  economic_unit_aggregate: economic_unit_aggregate;
  /** fetch data from the table: "economic_unit" using primary key columns */
  economic_unit_by_pk?: Maybe<economic_unit>;
  /** fetch data from the table: "efficiency_class" */
  efficiency_class: Array<efficiency_class>;
  /** fetch aggregated fields from the table: "efficiency_class" */
  efficiency_class_aggregate: efficiency_class_aggregate;
  /** fetch data from the table: "efficiency_class" using primary key columns */
  efficiency_class_by_pk?: Maybe<efficiency_class>;
  /** fetch data from the table: "emission_certificate" */
  emission_certificate: Array<emission_certificate>;
  /** fetch aggregated fields from the table: "emission_certificate" */
  emission_certificate_aggregate: emission_certificate_aggregate;
  /** fetch data from the table: "emission_certificate" using primary key columns */
  emission_certificate_by_pk?: Maybe<emission_certificate>;
  /** fetch data from the table: "emission_certificate_draft" */
  emission_certificate_draft: Array<emission_certificate_draft>;
  /** fetch aggregated fields from the table: "emission_certificate_draft" */
  emission_certificate_draft_aggregate: emission_certificate_draft_aggregate;
  /** fetch data from the table: "emission_certificate_draft" using primary key columns */
  emission_certificate_draft_by_pk?: Maybe<emission_certificate_draft>;
  /** fetch data from the table: "emission_factor_origin" */
  emission_factor_origin: Array<emission_factor_origin>;
  /** fetch aggregated fields from the table: "emission_factor_origin" */
  emission_factor_origin_aggregate: emission_factor_origin_aggregate;
  /** fetch data from the table: "emission_factor_origin" using primary key columns */
  emission_factor_origin_by_pk?: Maybe<emission_factor_origin>;
  /** fetch data from the table: "emission_factor_type" */
  emission_factor_type: Array<emission_factor_type>;
  /** fetch aggregated fields from the table: "emission_factor_type" */
  emission_factor_type_aggregate: emission_factor_type_aggregate;
  /** fetch data from the table: "emission_factor_type" using primary key columns */
  emission_factor_type_by_pk?: Maybe<emission_factor_type>;
  /** fetch data from the table: "energy_certificate" */
  energy_certificate: Array<energy_certificate>;
  /** fetch aggregated fields from the table: "energy_certificate" */
  energy_certificate_aggregate: energy_certificate_aggregate;
  /** fetch data from the table: "energy_certificate" using primary key columns */
  energy_certificate_by_pk?: Maybe<energy_certificate>;
  /** fetch data from the table: "energy_certificate_consumer" */
  energy_certificate_consumer: Array<energy_certificate_consumer>;
  /** fetch aggregated fields from the table: "energy_certificate_consumer" */
  energy_certificate_consumer_aggregate: energy_certificate_consumer_aggregate;
  /** fetch data from the table: "energy_certificate_consumer" using primary key columns */
  energy_certificate_consumer_by_pk?: Maybe<energy_certificate_consumer>;
  /** fetch data from the table: "energy_certificate_draft" */
  energy_certificate_draft: Array<energy_certificate_draft>;
  /** fetch aggregated fields from the table: "energy_certificate_draft" */
  energy_certificate_draft_aggregate: energy_certificate_draft_aggregate;
  /** fetch data from the table: "energy_certificate_draft" using primary key columns */
  energy_certificate_draft_by_pk?: Maybe<energy_certificate_draft>;
  /** fetch data from the table: "energy_certificate_type" */
  energy_certificate_type: Array<energy_certificate_type>;
  /** fetch aggregated fields from the table: "energy_certificate_type" */
  energy_certificate_type_aggregate: energy_certificate_type_aggregate;
  /** fetch data from the table: "energy_certificate_type" using primary key columns */
  energy_certificate_type_by_pk?: Maybe<energy_certificate_type>;
  /** fetch data from the table: "energy_consumer" */
  energy_consumer: Array<energy_consumer>;
  /** fetch aggregated fields from the table: "energy_consumer" */
  energy_consumer_aggregate: energy_consumer_aggregate;
  /** fetch data from the table: "energy_consumer" using primary key columns */
  energy_consumer_by_pk?: Maybe<energy_consumer>;
  /** fetch data from the table: "energy_consumer_technology_type" */
  energy_consumer_technology_type: Array<energy_consumer_technology_type>;
  /** fetch aggregated fields from the table: "energy_consumer_technology_type" */
  energy_consumer_technology_type_aggregate: energy_consumer_technology_type_aggregate;
  /** fetch data from the table: "energy_consumer_technology_type" using primary key columns */
  energy_consumer_technology_type_by_pk?: Maybe<energy_consumer_technology_type>;
  /** fetch data from the table: "energy_consumer_type" */
  energy_consumer_type: Array<energy_consumer_type>;
  /** fetch aggregated fields from the table: "energy_consumer_type" */
  energy_consumer_type_aggregate: energy_consumer_type_aggregate;
  /** fetch data from the table: "energy_consumer_type" using primary key columns */
  energy_consumer_type_by_pk?: Maybe<energy_consumer_type>;
  /** fetch data from the table: "energy_distribution" */
  energy_distribution: Array<energy_distribution>;
  /** fetch aggregated fields from the table: "energy_distribution" */
  energy_distribution_aggregate: energy_distribution_aggregate;
  /** fetch data from the table: "energy_distribution" using primary key columns */
  energy_distribution_by_pk?: Maybe<energy_distribution>;
  /** fetch data from the table: "energy_distribution_type" */
  energy_distribution_type: Array<energy_distribution_type>;
  /** fetch aggregated fields from the table: "energy_distribution_type" */
  energy_distribution_type_aggregate: energy_distribution_type_aggregate;
  /** fetch data from the table: "energy_distribution_type" using primary key columns */
  energy_distribution_type_by_pk?: Maybe<energy_distribution_type>;
  /** fetch data from the table: "energy_path" */
  energy_path: Array<energy_path>;
  /** fetch aggregated fields from the table: "energy_path" */
  energy_path_aggregate: energy_path_aggregate;
  /** fetch data from the table: "energy_path" using primary key columns */
  energy_path_by_pk?: Maybe<energy_path>;
  /** fetch data from the table: "energy_producer" */
  energy_producer: Array<energy_producer>;
  /** fetch aggregated fields from the table: "energy_producer" */
  energy_producer_aggregate: energy_producer_aggregate;
  /** fetch data from the table: "energy_producer" using primary key columns */
  energy_producer_by_pk?: Maybe<energy_producer>;
  /** fetch data from the table: "energy_source" */
  energy_source: Array<energy_source>;
  /** fetch aggregated fields from the table: "energy_source" */
  energy_source_aggregate: energy_source_aggregate;
  /** fetch data from the table: "energy_source" using primary key columns */
  energy_source_by_pk?: Maybe<energy_source>;
  /** fetch data from the table: "energy_source_type" */
  energy_source_type: Array<energy_source_type>;
  /** fetch aggregated fields from the table: "energy_source_type" */
  energy_source_type_aggregate: energy_source_type_aggregate;
  /** fetch data from the table: "energy_source_type" using primary key columns */
  energy_source_type_by_pk?: Maybe<energy_source_type>;
  /** fetch data from the table: "energy_standard" */
  energy_standard: Array<energy_standard>;
  /** fetch aggregated fields from the table: "energy_standard" */
  energy_standard_aggregate: energy_standard_aggregate;
  /** fetch data from the table: "energy_standard" using primary key columns */
  energy_standard_by_pk?: Maybe<energy_standard>;
  /** fetch data from the table: "energy_storage" */
  energy_storage: Array<energy_storage>;
  /** fetch aggregated fields from the table: "energy_storage" */
  energy_storage_aggregate: energy_storage_aggregate;
  /** fetch data from the table: "energy_storage" using primary key columns */
  energy_storage_by_pk?: Maybe<energy_storage>;
  /** fetch data from the table: "energy_storage_type" */
  energy_storage_type: Array<energy_storage_type>;
  /** fetch aggregated fields from the table: "energy_storage_type" */
  energy_storage_type_aggregate: energy_storage_type_aggregate;
  /** fetch data from the table: "energy_storage_type" using primary key columns */
  energy_storage_type_by_pk?: Maybe<energy_storage_type>;
  /** fetch data from the table: "energy_system" */
  energy_system: Array<energy_system>;
  /** fetch aggregated fields from the table: "energy_system" */
  energy_system_aggregate: energy_system_aggregate;
  /** fetch data from the table: "energy_system" using primary key columns */
  energy_system_by_pk?: Maybe<energy_system>;
  /** fetch data from the table: "energy_system_consumer_route" */
  energy_system_consumer_route: Array<energy_system_consumer_route>;
  /** fetch aggregated fields from the table: "energy_system_consumer_route" */
  energy_system_consumer_route_aggregate: energy_system_consumer_route_aggregate;
  /** fetch data from the table: "energy_system_consumer_route" using primary key columns */
  energy_system_consumer_route_by_pk?: Maybe<energy_system_consumer_route>;
  /** fetch data from the table: "energy_system_producer_route" */
  energy_system_producer_route: Array<energy_system_producer_route>;
  /** fetch aggregated fields from the table: "energy_system_producer_route" */
  energy_system_producer_route_aggregate: energy_system_producer_route_aggregate;
  /** fetch data from the table: "energy_system_producer_route" using primary key columns */
  energy_system_producer_route_by_pk?: Maybe<energy_system_producer_route>;
  /** fetch data from the table: "energy_system_type" */
  energy_system_type: Array<energy_system_type>;
  /** fetch aggregated fields from the table: "energy_system_type" */
  energy_system_type_aggregate: energy_system_type_aggregate;
  /** fetch data from the table: "energy_system_type" using primary key columns */
  energy_system_type_by_pk?: Maybe<energy_system_type>;
  /** fetch data from the table: "envelope" */
  envelope: Array<envelope>;
  /** fetch aggregated fields from the table: "envelope" */
  envelope_aggregate: envelope_aggregate;
  /** fetch data from the table: "envelope" using primary key columns */
  envelope_by_pk?: Maybe<envelope>;
  /** fetch data from the table: "envelope_type" */
  envelope_type: Array<envelope_type>;
  /** fetch aggregated fields from the table: "envelope_type" */
  envelope_type_aggregate: envelope_type_aggregate;
  /** fetch data from the table: "envelope_type" using primary key columns */
  envelope_type_by_pk?: Maybe<envelope_type>;
  /** fetch data from the table: "eu_taxonomy_compliance" */
  eu_taxonomy_compliance: Array<eu_taxonomy_compliance>;
  /** fetch aggregated fields from the table: "eu_taxonomy_compliance" */
  eu_taxonomy_compliance_aggregate: eu_taxonomy_compliance_aggregate;
  /** fetch data from the table: "eu_taxonomy_compliance" using primary key columns */
  eu_taxonomy_compliance_by_pk?: Maybe<eu_taxonomy_compliance>;
  /** fetch data from the table: "file" */
  file: Array<file>;
  /** fetch aggregated fields from the table: "file" */
  file_aggregate: file_aggregate;
  /** fetch data from the table: "file" using primary key columns */
  file_by_pk?: Maybe<file>;
  getActionPlanMetrics: ActionPlanMetricsOutput;
  getActionPlanPdf: ExportActionPlanAsPdfOutput;
  getAllLatestReportTemplates: Array<Maybe<ReportTemplateOutput>>;
  getAnalyzedBuilding: AnalyzeBuildingOutput;
  getAnalyzedBuildings: AnalyzeBuildingsOutput;
  getBenchmark: BenchmarkOutput;
  getBuildingModelMutationAvailability: GetBuildingModelMutationAvailabilityOutput;
  getConsumptionAllocation: ConsumptionAllocation;
  getConsumptionAnalysis: ConsumptionAnalysis;
  getEsgAnalysisPdf: ExportEsgAnalysisAsPdfOutput;
  getEsgConsumptionAnalysis: EsgConsumptionAnalysis;
  getFileDownloadUrl: Scalars['String'];
  getQueueStatus: Array<QueueStatusOutput>;
  getUserFeatureFlags: GetUserFeatureFlagsOutput;
  getYearlyConsumptionSummary: ConsumptionSummary;
  /** The method is an asynchronous function that checks if any economic unit (EU) has either a single building or energy certificate draft, or if all buildings from the economic unit are selected. */
  hasSingleBuildingOrDraft: Scalars['Boolean'];
  /** fetch data from the table: "insulation_material_category" */
  insulation_material_category: Array<insulation_material_category>;
  /** fetch aggregated fields from the table: "insulation_material_category" */
  insulation_material_category_aggregate: insulation_material_category_aggregate;
  /** fetch data from the table: "insulation_material_category" using primary key columns */
  insulation_material_category_by_pk?: Maybe<insulation_material_category>;
  /** fetch data from the table: "insulation_method" */
  insulation_method: Array<insulation_method>;
  /** fetch aggregated fields from the table: "insulation_method" */
  insulation_method_aggregate: insulation_method_aggregate;
  /** fetch data from the table: "insulation_method" using primary key columns */
  insulation_method_by_pk?: Maybe<insulation_method>;
  /** fetch data from the table: "invoice_payer_type" */
  invoice_payer_type: Array<invoice_payer_type>;
  /** fetch aggregated fields from the table: "invoice_payer_type" */
  invoice_payer_type_aggregate: invoice_payer_type_aggregate;
  /** fetch data from the table: "invoice_payer_type" using primary key columns */
  invoice_payer_type_by_pk?: Maybe<invoice_payer_type>;
  /** fetch data from the table: "langfuse_datasets_temporary_item" */
  langfuse_datasets_temporary_item: Array<langfuse_datasets_temporary_item>;
  /** fetch aggregated fields from the table: "langfuse_datasets_temporary_item" */
  langfuse_datasets_temporary_item_aggregate: langfuse_datasets_temporary_item_aggregate;
  /** fetch data from the table: "langfuse_datasets_temporary_item" using primary key columns */
  langfuse_datasets_temporary_item_by_pk?: Maybe<langfuse_datasets_temporary_item>;
  /** fetch data from the table: "language" */
  language: Array<language>;
  /** fetch aggregated fields from the table: "language" */
  language_aggregate: language_aggregate;
  /** fetch data from the table: "language" using primary key columns */
  language_by_pk?: Maybe<language>;
  /** fetch data from the table: "meter" */
  meter: Array<meter>;
  /** fetch aggregated fields from the table: "meter" */
  meter_aggregate: meter_aggregate;
  /** fetch data from the table: "meter" using primary key columns */
  meter_by_pk?: Maybe<meter>;
  /** fetch data from the table: "meter_integration" */
  meter_integration: Array<meter_integration>;
  /** fetch aggregated fields from the table: "meter_integration" */
  meter_integration_aggregate: meter_integration_aggregate;
  /** fetch data from the table: "meter_integration_building" */
  meter_integration_building: Array<meter_integration_building>;
  /** fetch aggregated fields from the table: "meter_integration_building" */
  meter_integration_building_aggregate: meter_integration_building_aggregate;
  /** fetch data from the table: "meter_integration_building" using primary key columns */
  meter_integration_building_by_pk?: Maybe<meter_integration_building>;
  /** fetch data from the table: "meter_integration" using primary key columns */
  meter_integration_by_pk?: Maybe<meter_integration>;
  /** fetch data from the table: "meter_integration_type" */
  meter_integration_type: Array<meter_integration_type>;
  /** fetch aggregated fields from the table: "meter_integration_type" */
  meter_integration_type_aggregate: meter_integration_type_aggregate;
  /** fetch data from the table: "meter_integration_type" using primary key columns */
  meter_integration_type_by_pk?: Maybe<meter_integration_type>;
  /** fetch data from the table: "notification" */
  notification: Array<notification>;
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: notification_aggregate;
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<notification>;
  /** fetch data from the table: "notification_template" */
  notification_template: Array<notification_template>;
  /** fetch aggregated fields from the table: "notification_template" */
  notification_template_aggregate: notification_template_aggregate;
  /** fetch data from the table: "notification_template" using primary key columns */
  notification_template_by_pk?: Maybe<notification_template>;
  /** fetch data from the table: "notification_type" */
  notification_type: Array<notification_type>;
  /** fetch aggregated fields from the table: "notification_type" */
  notification_type_aggregate: notification_type_aggregate;
  /** fetch data from the table: "notification_type" using primary key columns */
  notification_type_by_pk?: Maybe<notification_type>;
  /** fetch data from the table: "operation_model_type" */
  operation_model_type: Array<operation_model_type>;
  /** fetch aggregated fields from the table: "operation_model_type" */
  operation_model_type_aggregate: operation_model_type_aggregate;
  /** fetch data from the table: "operation_model_type" using primary key columns */
  operation_model_type_by_pk?: Maybe<operation_model_type>;
  /** fetch data from the table: "org" */
  org: Array<org>;
  /** fetch aggregated fields from the table: "org" */
  org_aggregate: org_aggregate;
  /** fetch data from the table: "org" using primary key columns */
  org_by_pk?: Maybe<org>;
  /** fetch data from the table: "org_preference" */
  org_preference: Array<org_preference>;
  /** fetch aggregated fields from the table: "org_preference" */
  org_preference_aggregate: org_preference_aggregate;
  /** fetch data from the table: "org_preference" using primary key columns */
  org_preference_by_pk?: Maybe<org_preference>;
  /** fetch data from the table: "org_status_type" */
  org_status_type: Array<org_status_type>;
  /** fetch aggregated fields from the table: "org_status_type" */
  org_status_type_aggregate: org_status_type_aggregate;
  /** fetch data from the table: "org_status_type" using primary key columns */
  org_status_type_by_pk?: Maybe<org_status_type>;
  /** fetch data from the table: "portfolio" */
  portfolio: Array<portfolio>;
  /** fetch aggregated fields from the table: "portfolio" */
  portfolio_aggregate: portfolio_aggregate;
  /** fetch data from the table: "portfolio" using primary key columns */
  portfolio_by_pk?: Maybe<portfolio>;
  /** fetch data from the table: "renovation" */
  renovation: Array<renovation>;
  /** fetch aggregated fields from the table: "renovation" */
  renovation_aggregate: renovation_aggregate;
  /** fetch data from the table: "renovation" using primary key columns */
  renovation_by_pk?: Maybe<renovation>;
  /** fetch data from the table: "renovation_energy_consumer" */
  renovation_energy_consumer: Array<renovation_energy_consumer>;
  /** fetch aggregated fields from the table: "renovation_energy_consumer" */
  renovation_energy_consumer_aggregate: renovation_energy_consumer_aggregate;
  /** fetch data from the table: "renovation_energy_distribution" */
  renovation_energy_distribution: Array<renovation_energy_distribution>;
  /** fetch aggregated fields from the table: "renovation_energy_distribution" */
  renovation_energy_distribution_aggregate: renovation_energy_distribution_aggregate;
  /** fetch data from the table: "renovation_energy_producer" */
  renovation_energy_producer: Array<renovation_energy_producer>;
  /** fetch aggregated fields from the table: "renovation_energy_producer" */
  renovation_energy_producer_aggregate: renovation_energy_producer_aggregate;
  /** fetch data from the table: "renovation_energy_storage" */
  renovation_energy_storage: Array<renovation_energy_storage>;
  /** fetch aggregated fields from the table: "renovation_energy_storage" */
  renovation_energy_storage_aggregate: renovation_energy_storage_aggregate;
  /** fetch data from the table: "renovation_energy_system_route" */
  renovation_energy_system_route: Array<renovation_energy_system_route>;
  /** fetch aggregated fields from the table: "renovation_energy_system_route" */
  renovation_energy_system_route_aggregate: renovation_energy_system_route_aggregate;
  /** fetch data from the table: "renovation_envelope" */
  renovation_envelope: Array<renovation_envelope>;
  /** fetch aggregated fields from the table: "renovation_envelope" */
  renovation_envelope_aggregate: renovation_envelope_aggregate;
  /** fetch data from the table: "renovation_type" */
  renovation_type: Array<renovation_type>;
  /** fetch aggregated fields from the table: "renovation_type" */
  renovation_type_aggregate: renovation_type_aggregate;
  /** fetch data from the table: "renovation_type" using primary key columns */
  renovation_type_by_pk?: Maybe<renovation_type>;
  /** fetch data from the table: "report" */
  report: Array<report>;
  /** fetch aggregated fields from the table: "report" */
  report_aggregate: report_aggregate;
  /** fetch data from the table: "report_answer" */
  report_answer: Array<report_answer>;
  /** fetch aggregated fields from the table: "report_answer" */
  report_answer_aggregate: report_answer_aggregate;
  /** fetch data from the table: "report_answer_autofill_option" */
  report_answer_autofill_option: Array<report_answer_autofill_option>;
  /** fetch aggregated fields from the table: "report_answer_autofill_option" */
  report_answer_autofill_option_aggregate: report_answer_autofill_option_aggregate;
  /** fetch data from the table: "report_answer_autofill_option" using primary key columns */
  report_answer_autofill_option_by_pk?: Maybe<report_answer_autofill_option>;
  /** fetch data from the table: "report_answer" using primary key columns */
  report_answer_by_pk?: Maybe<report_answer>;
  /** fetch data from the table: "report_answer_chosen_option" */
  report_answer_chosen_option: Array<report_answer_chosen_option>;
  /** fetch aggregated fields from the table: "report_answer_chosen_option" */
  report_answer_chosen_option_aggregate: report_answer_chosen_option_aggregate;
  /** fetch data from the table: "report_answer_chosen_option" using primary key columns */
  report_answer_chosen_option_by_pk?: Maybe<report_answer_chosen_option>;
  /** fetch data from the table: "report_answer_state" */
  report_answer_state: Array<report_answer_state>;
  /** fetch aggregated fields from the table: "report_answer_state" */
  report_answer_state_aggregate: report_answer_state_aggregate;
  /** fetch data from the table: "report_answer_state" using primary key columns */
  report_answer_state_by_pk?: Maybe<report_answer_state>;
  /** fetch data from the table: "report" using primary key columns */
  report_by_pk?: Maybe<report>;
  /** fetch data from the table: "report_evidence" */
  report_evidence: Array<report_evidence>;
  /** fetch aggregated fields from the table: "report_evidence" */
  report_evidence_aggregate: report_evidence_aggregate;
  /** fetch data from the table: "report_evidence" using primary key columns */
  report_evidence_by_pk?: Maybe<report_evidence>;
  /** fetch data from the table: "report_question_evidence" */
  report_question_evidence: Array<report_question_evidence>;
  /** fetch aggregated fields from the table: "report_question_evidence" */
  report_question_evidence_aggregate: report_question_evidence_aggregate;
  /** fetch data from the table: "report_question_evidence" using primary key columns */
  report_question_evidence_by_pk?: Maybe<report_question_evidence>;
  /** fetch data from the table: "report_score" */
  report_score: Array<report_score>;
  /** fetch aggregated fields from the table: "report_score" */
  report_score_aggregate: report_score_aggregate;
  /** fetch data from the table: "report_score" using primary key columns */
  report_score_by_pk?: Maybe<report_score>;
  /** fetch data from the table: "report_section_progress" */
  report_section_progress: Array<report_section_progress>;
  /** fetch aggregated fields from the table: "report_section_progress" */
  report_section_progress_aggregate: report_section_progress_aggregate;
  /** fetch data from the table: "report_section_progress" using primary key columns */
  report_section_progress_by_pk?: Maybe<report_section_progress>;
  /** fetch data from the table: "report_sub_building" */
  report_sub_building: Array<report_sub_building>;
  /** fetch aggregated fields from the table: "report_sub_building" */
  report_sub_building_aggregate: report_sub_building_aggregate;
  /** fetch data from the table: "report_sub_building" using primary key columns */
  report_sub_building_by_pk?: Maybe<report_sub_building>;
  /** fetch data from the table: "report_template" */
  report_template: Array<report_template>;
  /** fetch aggregated fields from the table: "report_template" */
  report_template_aggregate: report_template_aggregate;
  /** fetch data from the table: "report_template" using primary key columns */
  report_template_by_pk?: Maybe<report_template>;
  /** fetch data from the table: "report_template_question" */
  report_template_question: Array<report_template_question>;
  /** fetch aggregated fields from the table: "report_template_question" */
  report_template_question_aggregate: report_template_question_aggregate;
  /** fetch data from the table: "report_template_question" using primary key columns */
  report_template_question_by_pk?: Maybe<report_template_question>;
  /** fetch data from the table: "report_template_question_option" */
  report_template_question_option: Array<report_template_question_option>;
  /** fetch aggregated fields from the table: "report_template_question_option" */
  report_template_question_option_aggregate: report_template_question_option_aggregate;
  /** fetch data from the table: "report_template_question_option" using primary key columns */
  report_template_question_option_by_pk?: Maybe<report_template_question_option>;
  /** fetch data from the table: "report_template_question_type" */
  report_template_question_type: Array<report_template_question_type>;
  /** fetch aggregated fields from the table: "report_template_question_type" */
  report_template_question_type_aggregate: report_template_question_type_aggregate;
  /** fetch data from the table: "report_template_question_type" using primary key columns */
  report_template_question_type_by_pk?: Maybe<report_template_question_type>;
  /** fetch data from the table: "report_template_score" */
  report_template_score: Array<report_template_score>;
  /** fetch aggregated fields from the table: "report_template_score" */
  report_template_score_aggregate: report_template_score_aggregate;
  /** fetch data from the table: "report_template_score" using primary key columns */
  report_template_score_by_pk?: Maybe<report_template_score>;
  /** fetch data from the table: "report_template_score_display_type" */
  report_template_score_display_type: Array<report_template_score_display_type>;
  /** fetch aggregated fields from the table: "report_template_score_display_type" */
  report_template_score_display_type_aggregate: report_template_score_display_type_aggregate;
  /** fetch data from the table: "report_template_score_display_type" using primary key columns */
  report_template_score_display_type_by_pk?: Maybe<report_template_score_display_type>;
  /** fetch data from the table: "report_template_section" */
  report_template_section: Array<report_template_section>;
  /** fetch aggregated fields from the table: "report_template_section" */
  report_template_section_aggregate: report_template_section_aggregate;
  /** fetch data from the table: "report_template_section" using primary key columns */
  report_template_section_by_pk?: Maybe<report_template_section>;
  /** fetch data from the table: "report_template_section_target" */
  report_template_section_target: Array<report_template_section_target>;
  /** fetch aggregated fields from the table: "report_template_section_target" */
  report_template_section_target_aggregate: report_template_section_target_aggregate;
  /** fetch data from the table: "report_template_section_target" using primary key columns */
  report_template_section_target_by_pk?: Maybe<report_template_section_target>;
  /** fetch data from the table: "report_template_to_all_questions" */
  report_template_to_all_questions: Array<report_template_to_all_questions>;
  /** fetch aggregated fields from the table: "report_template_to_all_questions" */
  report_template_to_all_questions_aggregate: report_template_to_all_questions_aggregate;
  /** fetch data from the table: "role" */
  role: Array<role>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: role_aggregate;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<role>;
  /** fetch data from the table: "roof_type" */
  roof_type: Array<roof_type>;
  /** fetch aggregated fields from the table: "roof_type" */
  roof_type_aggregate: roof_type_aggregate;
  /** fetch data from the table: "roof_type" using primary key columns */
  roof_type_by_pk?: Maybe<roof_type>;
  /** fetch data from the table: "scenario" */
  scenario: Array<scenario>;
  /** fetch aggregated fields from the table: "scenario" */
  scenario_aggregate: scenario_aggregate;
  /** fetch data from the table: "scenario" using primary key columns */
  scenario_by_pk?: Maybe<scenario>;
  /** fetch data from the table: "sub_building" */
  sub_building: Array<sub_building>;
  /** fetch aggregated fields from the table: "sub_building" */
  sub_building_aggregate: sub_building_aggregate;
  /** fetch data from the table: "sub_building" using primary key columns */
  sub_building_by_pk?: Maybe<sub_building>;
  /** fetch data from the table: "sub_building_class" */
  sub_building_class: Array<sub_building_class>;
  /** fetch aggregated fields from the table: "sub_building_class" */
  sub_building_class_aggregate: sub_building_class_aggregate;
  /** fetch data from the table: "sub_building_class" using primary key columns */
  sub_building_class_by_pk?: Maybe<sub_building_class>;
  /** fetch data from the table: "sub_building_subsidy" */
  sub_building_subsidy: Array<sub_building_subsidy>;
  /** fetch aggregated fields from the table: "sub_building_subsidy" */
  sub_building_subsidy_aggregate: sub_building_subsidy_aggregate;
  /** fetch data from the table: "sub_building_subsidy" using primary key columns */
  sub_building_subsidy_by_pk?: Maybe<sub_building_subsidy>;
  /** fetch data from the table: "subsidy" */
  subsidy: Array<subsidy>;
  /** fetch aggregated fields from the table: "subsidy" */
  subsidy_aggregate: subsidy_aggregate;
  /** fetch data from the table: "subsidy" using primary key columns */
  subsidy_by_pk?: Maybe<subsidy>;
  /** fetch data from the table: "subsidy_category_type" */
  subsidy_category_type: Array<subsidy_category_type>;
  /** fetch aggregated fields from the table: "subsidy_category_type" */
  subsidy_category_type_aggregate: subsidy_category_type_aggregate;
  /** fetch data from the table: "subsidy_category_type" using primary key columns */
  subsidy_category_type_by_pk?: Maybe<subsidy_category_type>;
  /** fetch data from the table: "subsidy_region_type" */
  subsidy_region_type: Array<subsidy_region_type>;
  /** fetch aggregated fields from the table: "subsidy_region_type" */
  subsidy_region_type_aggregate: subsidy_region_type_aggregate;
  /** fetch data from the table: "subsidy_region_type" using primary key columns */
  subsidy_region_type_by_pk?: Maybe<subsidy_region_type>;
  /** fetch data from the table: "subsidy_subsidy_category" */
  subsidy_subsidy_category: Array<subsidy_subsidy_category>;
  /** fetch aggregated fields from the table: "subsidy_subsidy_category" */
  subsidy_subsidy_category_aggregate: subsidy_subsidy_category_aggregate;
  /** fetch data from the table: "subsidy_subsidy_category" using primary key columns */
  subsidy_subsidy_category_by_pk?: Maybe<subsidy_subsidy_category>;
  /** fetch data from the table: "subsidy_type" */
  subsidy_type: Array<subsidy_type>;
  /** fetch aggregated fields from the table: "subsidy_type" */
  subsidy_type_aggregate: subsidy_type_aggregate;
  /** fetch data from the table: "subsidy_type" using primary key columns */
  subsidy_type_by_pk?: Maybe<subsidy_type>;
  /** fetch data from the table: "sync_state" */
  sync_state: Array<sync_state>;
  /** fetch aggregated fields from the table: "sync_state" */
  sync_state_aggregate: sync_state_aggregate;
  /** fetch data from the table: "sync_state" using primary key columns */
  sync_state_by_pk?: Maybe<sync_state>;
  /** fetch data from the table: "target_path" */
  target_path: Array<target_path>;
  /** fetch aggregated fields from the table: "target_path" */
  target_path_aggregate: target_path_aggregate;
  /** fetch data from the table: "target_path" using primary key columns */
  target_path_by_pk?: Maybe<target_path>;
  /** fetch data from the table: "target_path_template" */
  target_path_template: Array<target_path_template>;
  /** fetch aggregated fields from the table: "target_path_template" */
  target_path_template_aggregate: target_path_template_aggregate;
  /** fetch data from the table: "target_path_template" using primary key columns */
  target_path_template_by_pk?: Maybe<target_path_template>;
  /** fetch data from the table: "target_path_template_type" */
  target_path_template_type: Array<target_path_template_type>;
  /** fetch aggregated fields from the table: "target_path_template_type" */
  target_path_template_type_aggregate: target_path_template_type_aggregate;
  /** fetch data from the table: "target_path_template_type" using primary key columns */
  target_path_template_type_by_pk?: Maybe<target_path_template_type>;
  /** fetch data from the table: "tax_bracket" */
  tax_bracket: Array<tax_bracket>;
  /** fetch aggregated fields from the table: "tax_bracket" */
  tax_bracket_aggregate: tax_bracket_aggregate;
  /** fetch data from the table: "tax_bracket" using primary key columns */
  tax_bracket_by_pk?: Maybe<tax_bracket>;
  /** fetch data from the table: "type_of_use" */
  type_of_use: Array<type_of_use>;
  /** fetch aggregated fields from the table: "type_of_use" */
  type_of_use_aggregate: type_of_use_aggregate;
  /** fetch data from the table: "type_of_use" using primary key columns */
  type_of_use_by_pk?: Maybe<type_of_use>;
  /** fetch data from the table: "user" */
  user: Array<user>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: user_aggregate;
  /** fetch data from the table: "user_building_permission" */
  user_building_permission: Array<user_building_permission>;
  /** fetch aggregated fields from the table: "user_building_permission" */
  user_building_permission_aggregate: user_building_permission_aggregate;
  /** fetch data from the table: "user_building_permission" using primary key columns */
  user_building_permission_by_pk?: Maybe<user_building_permission>;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<user>;
  /** fetch data from the table: "user_portfolio_permission" */
  user_portfolio_permission: Array<user_portfolio_permission>;
  /** fetch aggregated fields from the table: "user_portfolio_permission" */
  user_portfolio_permission_aggregate: user_portfolio_permission_aggregate;
  /** fetch data from the table: "user_portfolio_permission" using primary key columns */
  user_portfolio_permission_by_pk?: Maybe<user_portfolio_permission>;
  /** An array relationship */
  user_role: Array<user_role>;
  /** An aggregate relationship */
  user_role_aggregate: user_role_aggregate;
  /** fetch data from the table: "user_role" using primary key columns */
  user_role_by_pk?: Maybe<user_role>;
  /** fetch data from the table: "window_type" */
  window_type: Array<window_type>;
  /** fetch aggregated fields from the table: "window_type" */
  window_type_aggregate: window_type_aggregate;
  /** fetch data from the table: "window_type" using primary key columns */
  window_type_by_pk?: Maybe<window_type>;
};

export type query_rootactionArgs = {
  distinct_on?: InputMaybe<Array<action_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_order_by>>;
  where?: InputMaybe<action_bool_exp>;
};

export type query_rootaction_aggregateArgs = {
  distinct_on?: InputMaybe<Array<action_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_order_by>>;
  where?: InputMaybe<action_bool_exp>;
};

export type query_rootaction_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootaction_planArgs = {
  distinct_on?: InputMaybe<Array<action_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_plan_order_by>>;
  where?: InputMaybe<action_plan_bool_exp>;
};

export type query_rootaction_plan_aggregateArgs = {
  distinct_on?: InputMaybe<Array<action_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_plan_order_by>>;
  where?: InputMaybe<action_plan_bool_exp>;
};

export type query_rootaction_plan_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootaction_subsidyArgs = {
  distinct_on?: InputMaybe<Array<action_subsidy_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_subsidy_order_by>>;
  where?: InputMaybe<action_subsidy_bool_exp>;
};

export type query_rootaction_subsidy_aggregateArgs = {
  distinct_on?: InputMaybe<Array<action_subsidy_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_subsidy_order_by>>;
  where?: InputMaybe<action_subsidy_bool_exp>;
};

export type query_rootaction_subsidy_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootaction_subsidy_value_typeArgs = {
  distinct_on?: InputMaybe<Array<action_subsidy_value_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_subsidy_value_type_order_by>>;
  where?: InputMaybe<action_subsidy_value_type_bool_exp>;
};

export type query_rootaction_subsidy_value_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<action_subsidy_value_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_subsidy_value_type_order_by>>;
  where?: InputMaybe<action_subsidy_value_type_bool_exp>;
};

export type query_rootaction_subsidy_value_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootaddressArgs = {
  distinct_on?: InputMaybe<Array<address_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<address_order_by>>;
  where?: InputMaybe<address_bool_exp>;
};

export type query_rootaddress_aggregateArgs = {
  distinct_on?: InputMaybe<Array<address_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<address_order_by>>;
  where?: InputMaybe<address_bool_exp>;
};

export type query_rootaddress_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootapi_userArgs = {
  distinct_on?: InputMaybe<Array<api_user_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<api_user_order_by>>;
  where?: InputMaybe<api_user_bool_exp>;
};

export type query_rootapi_user_aggregateArgs = {
  distinct_on?: InputMaybe<Array<api_user_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<api_user_order_by>>;
  where?: InputMaybe<api_user_bool_exp>;
};

export type query_rootapi_user_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootareaArgs = {
  distinct_on?: InputMaybe<Array<area_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<area_order_by>>;
  where?: InputMaybe<area_bool_exp>;
};

export type query_rootarea_aggregateArgs = {
  distinct_on?: InputMaybe<Array<area_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<area_order_by>>;
  where?: InputMaybe<area_bool_exp>;
};

export type query_rootarea_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootarea_system_typeArgs = {
  distinct_on?: InputMaybe<Array<area_system_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<area_system_type_order_by>>;
  where?: InputMaybe<area_system_type_bool_exp>;
};

export type query_rootarea_system_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<area_system_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<area_system_type_order_by>>;
  where?: InputMaybe<area_system_type_bool_exp>;
};

export type query_rootarea_system_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootarea_typeArgs = {
  distinct_on?: InputMaybe<Array<area_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<area_type_order_by>>;
  where?: InputMaybe<area_type_bool_exp>;
};

export type query_rootarea_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<area_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<area_type_order_by>>;
  where?: InputMaybe<area_type_bool_exp>;
};

export type query_rootarea_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootassociatedBuildingsAndDraftsArgs = {
  id: Scalars['Int'];
};

export type query_rootasync_jobArgs = {
  distinct_on?: InputMaybe<Array<async_job_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<async_job_order_by>>;
  where?: InputMaybe<async_job_bool_exp>;
};

export type query_rootasync_job_aggregateArgs = {
  distinct_on?: InputMaybe<Array<async_job_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<async_job_order_by>>;
  where?: InputMaybe<async_job_bool_exp>;
};

export type query_rootasync_job_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootasync_job_status_typeArgs = {
  distinct_on?: InputMaybe<Array<async_job_status_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<async_job_status_type_order_by>>;
  where?: InputMaybe<async_job_status_type_bool_exp>;
};

export type query_rootasync_job_status_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<async_job_status_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<async_job_status_type_order_by>>;
  where?: InputMaybe<async_job_status_type_bool_exp>;
};

export type query_rootasync_job_status_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootbuildingArgs = {
  distinct_on?: InputMaybe<Array<building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<building_order_by>>;
  where?: InputMaybe<building_bool_exp>;
};

export type query_rootbuilding_aggregateArgs = {
  distinct_on?: InputMaybe<Array<building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<building_order_by>>;
  where?: InputMaybe<building_bool_exp>;
};

export type query_rootbuilding_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootbuilding_modelArgs = {
  distinct_on?: InputMaybe<Array<building_model_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<building_model_order_by>>;
  where?: InputMaybe<building_model_bool_exp>;
};

export type query_rootbuilding_model_aggregateArgs = {
  distinct_on?: InputMaybe<Array<building_model_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<building_model_order_by>>;
  where?: InputMaybe<building_model_bool_exp>;
};

export type query_rootbuilding_model_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootbuilding_stateArgs = {
  distinct_on?: InputMaybe<Array<building_state_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<building_state_order_by>>;
  where?: InputMaybe<building_state_bool_exp>;
};

export type query_rootbuilding_state_aggregateArgs = {
  distinct_on?: InputMaybe<Array<building_state_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<building_state_order_by>>;
  where?: InputMaybe<building_state_bool_exp>;
};

export type query_rootbuilding_state_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootclimate_regionArgs = {
  distinct_on?: InputMaybe<Array<climate_region_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<climate_region_order_by>>;
  where?: InputMaybe<climate_region_bool_exp>;
};

export type query_rootclimate_region_aggregateArgs = {
  distinct_on?: InputMaybe<Array<climate_region_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<climate_region_order_by>>;
  where?: InputMaybe<climate_region_bool_exp>;
};

export type query_rootclimate_region_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootclimate_riskArgs = {
  distinct_on?: InputMaybe<Array<climate_risk_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<climate_risk_order_by>>;
  where?: InputMaybe<climate_risk_bool_exp>;
};

export type query_rootclimate_risk_aggregateArgs = {
  distinct_on?: InputMaybe<Array<climate_risk_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<climate_risk_order_by>>;
  where?: InputMaybe<climate_risk_bool_exp>;
};

export type query_rootclimate_risk_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootclimate_risk_typeArgs = {
  distinct_on?: InputMaybe<Array<climate_risk_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<climate_risk_type_order_by>>;
  where?: InputMaybe<climate_risk_type_bool_exp>;
};

export type query_rootclimate_risk_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<climate_risk_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<climate_risk_type_order_by>>;
  where?: InputMaybe<climate_risk_type_bool_exp>;
};

export type query_rootclimate_risk_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootconsumptionArgs = {
  distinct_on?: InputMaybe<Array<consumption_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_order_by>>;
  where?: InputMaybe<consumption_bool_exp>;
};

export type query_rootconsumption_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_order_by>>;
  where?: InputMaybe<consumption_bool_exp>;
};

export type query_rootconsumption_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootconsumption_draftArgs = {
  distinct_on?: InputMaybe<Array<consumption_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_draft_order_by>>;
  where?: InputMaybe<consumption_draft_bool_exp>;
};

export type query_rootconsumption_draft_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_draft_order_by>>;
  where?: InputMaybe<consumption_draft_bool_exp>;
};

export type query_rootconsumption_draft_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootconsumption_frequency_typeArgs = {
  distinct_on?: InputMaybe<Array<consumption_frequency_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_frequency_type_order_by>>;
  where?: InputMaybe<consumption_frequency_type_bool_exp>;
};

export type query_rootconsumption_frequency_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_frequency_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_frequency_type_order_by>>;
  where?: InputMaybe<consumption_frequency_type_bool_exp>;
};

export type query_rootconsumption_frequency_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootconsumption_invoiceArgs = {
  distinct_on?: InputMaybe<Array<consumption_invoice_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_invoice_order_by>>;
  where?: InputMaybe<consumption_invoice_bool_exp>;
};

export type query_rootconsumption_invoice_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_invoice_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_invoice_order_by>>;
  where?: InputMaybe<consumption_invoice_bool_exp>;
};

export type query_rootconsumption_invoice_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootconsumption_invoice_draftArgs = {
  distinct_on?: InputMaybe<Array<consumption_invoice_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_invoice_draft_order_by>>;
  where?: InputMaybe<consumption_invoice_draft_bool_exp>;
};

export type query_rootconsumption_invoice_draft_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_invoice_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_invoice_draft_order_by>>;
  where?: InputMaybe<consumption_invoice_draft_bool_exp>;
};

export type query_rootconsumption_invoice_draft_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootconsumption_source_typeArgs = {
  distinct_on?: InputMaybe<Array<consumption_source_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_source_type_order_by>>;
  where?: InputMaybe<consumption_source_type_bool_exp>;
};

export type query_rootconsumption_source_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_source_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_source_type_order_by>>;
  where?: InputMaybe<consumption_source_type_bool_exp>;
};

export type query_rootconsumption_source_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootconsumption_sub_typeArgs = {
  distinct_on?: InputMaybe<Array<consumption_sub_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_sub_type_order_by>>;
  where?: InputMaybe<consumption_sub_type_bool_exp>;
};

export type query_rootconsumption_sub_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_sub_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_sub_type_order_by>>;
  where?: InputMaybe<consumption_sub_type_bool_exp>;
};

export type query_rootconsumption_sub_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootconsumption_typeArgs = {
  distinct_on?: InputMaybe<Array<consumption_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_type_order_by>>;
  where?: InputMaybe<consumption_type_bool_exp>;
};

export type query_rootconsumption_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_type_order_by>>;
  where?: InputMaybe<consumption_type_bool_exp>;
};

export type query_rootconsumption_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootconsumption_unitArgs = {
  distinct_on?: InputMaybe<Array<consumption_unit_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_unit_order_by>>;
  where?: InputMaybe<consumption_unit_bool_exp>;
};

export type query_rootconsumption_unit_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_unit_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_unit_order_by>>;
  where?: InputMaybe<consumption_unit_bool_exp>;
};

export type query_rootconsumption_unit_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootconsumption_waste_detailsArgs = {
  distinct_on?: InputMaybe<Array<consumption_waste_details_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_waste_details_order_by>>;
  where?: InputMaybe<consumption_waste_details_bool_exp>;
};

export type query_rootconsumption_waste_details_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_waste_details_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_waste_details_order_by>>;
  where?: InputMaybe<consumption_waste_details_bool_exp>;
};

export type query_rootconsumption_waste_details_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootconsumption_waste_details_draftArgs = {
  distinct_on?: InputMaybe<Array<consumption_waste_details_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_waste_details_draft_order_by>>;
  where?: InputMaybe<consumption_waste_details_draft_bool_exp>;
};

export type query_rootconsumption_waste_details_draft_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_waste_details_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_waste_details_draft_order_by>>;
  where?: InputMaybe<consumption_waste_details_draft_bool_exp>;
};

export type query_rootconsumption_waste_details_draft_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootcountryArgs = {
  distinct_on?: InputMaybe<Array<country_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<country_order_by>>;
  where?: InputMaybe<country_bool_exp>;
};

export type query_rootcountry_aggregateArgs = {
  distinct_on?: InputMaybe<Array<country_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<country_order_by>>;
  where?: InputMaybe<country_bool_exp>;
};

export type query_rootcountry_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootcountry_stateArgs = {
  distinct_on?: InputMaybe<Array<country_state_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<country_state_order_by>>;
  where?: InputMaybe<country_state_bool_exp>;
};

export type query_rootcountry_state_aggregateArgs = {
  distinct_on?: InputMaybe<Array<country_state_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<country_state_order_by>>;
  where?: InputMaybe<country_state_bool_exp>;
};

export type query_rootcountry_state_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootdata_source_typeArgs = {
  distinct_on?: InputMaybe<Array<data_source_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<data_source_type_order_by>>;
  where?: InputMaybe<data_source_type_bool_exp>;
};

export type query_rootdata_source_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<data_source_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<data_source_type_order_by>>;
  where?: InputMaybe<data_source_type_bool_exp>;
};

export type query_rootdata_source_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootdraft_statusArgs = {
  distinct_on?: InputMaybe<Array<draft_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<draft_status_order_by>>;
  where?: InputMaybe<draft_status_bool_exp>;
};

export type query_rootdraft_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<draft_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<draft_status_order_by>>;
  where?: InputMaybe<draft_status_bool_exp>;
};

export type query_rootdraft_status_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rooteconomic_unitArgs = {
  distinct_on?: InputMaybe<Array<economic_unit_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<economic_unit_order_by>>;
  where?: InputMaybe<economic_unit_bool_exp>;
};

export type query_rooteconomic_unit_aggregateArgs = {
  distinct_on?: InputMaybe<Array<economic_unit_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<economic_unit_order_by>>;
  where?: InputMaybe<economic_unit_bool_exp>;
};

export type query_rooteconomic_unit_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootefficiency_classArgs = {
  distinct_on?: InputMaybe<Array<efficiency_class_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<efficiency_class_order_by>>;
  where?: InputMaybe<efficiency_class_bool_exp>;
};

export type query_rootefficiency_class_aggregateArgs = {
  distinct_on?: InputMaybe<Array<efficiency_class_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<efficiency_class_order_by>>;
  where?: InputMaybe<efficiency_class_bool_exp>;
};

export type query_rootefficiency_class_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootemission_certificateArgs = {
  distinct_on?: InputMaybe<Array<emission_certificate_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<emission_certificate_order_by>>;
  where?: InputMaybe<emission_certificate_bool_exp>;
};

export type query_rootemission_certificate_aggregateArgs = {
  distinct_on?: InputMaybe<Array<emission_certificate_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<emission_certificate_order_by>>;
  where?: InputMaybe<emission_certificate_bool_exp>;
};

export type query_rootemission_certificate_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootemission_certificate_draftArgs = {
  distinct_on?: InputMaybe<Array<emission_certificate_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<emission_certificate_draft_order_by>>;
  where?: InputMaybe<emission_certificate_draft_bool_exp>;
};

export type query_rootemission_certificate_draft_aggregateArgs = {
  distinct_on?: InputMaybe<Array<emission_certificate_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<emission_certificate_draft_order_by>>;
  where?: InputMaybe<emission_certificate_draft_bool_exp>;
};

export type query_rootemission_certificate_draft_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootemission_factor_originArgs = {
  distinct_on?: InputMaybe<Array<emission_factor_origin_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<emission_factor_origin_order_by>>;
  where?: InputMaybe<emission_factor_origin_bool_exp>;
};

export type query_rootemission_factor_origin_aggregateArgs = {
  distinct_on?: InputMaybe<Array<emission_factor_origin_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<emission_factor_origin_order_by>>;
  where?: InputMaybe<emission_factor_origin_bool_exp>;
};

export type query_rootemission_factor_origin_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootemission_factor_typeArgs = {
  distinct_on?: InputMaybe<Array<emission_factor_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<emission_factor_type_order_by>>;
  where?: InputMaybe<emission_factor_type_bool_exp>;
};

export type query_rootemission_factor_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<emission_factor_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<emission_factor_type_order_by>>;
  where?: InputMaybe<emission_factor_type_bool_exp>;
};

export type query_rootemission_factor_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootenergy_certificateArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_order_by>>;
  where?: InputMaybe<energy_certificate_bool_exp>;
};

export type query_rootenergy_certificate_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_order_by>>;
  where?: InputMaybe<energy_certificate_bool_exp>;
};

export type query_rootenergy_certificate_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootenergy_certificate_consumerArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_consumer_order_by>>;
  where?: InputMaybe<energy_certificate_consumer_bool_exp>;
};

export type query_rootenergy_certificate_consumer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_consumer_order_by>>;
  where?: InputMaybe<energy_certificate_consumer_bool_exp>;
};

export type query_rootenergy_certificate_consumer_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootenergy_certificate_draftArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_draft_order_by>>;
  where?: InputMaybe<energy_certificate_draft_bool_exp>;
};

export type query_rootenergy_certificate_draft_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_draft_order_by>>;
  where?: InputMaybe<energy_certificate_draft_bool_exp>;
};

export type query_rootenergy_certificate_draft_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootenergy_certificate_typeArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_type_order_by>>;
  where?: InputMaybe<energy_certificate_type_bool_exp>;
};

export type query_rootenergy_certificate_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_type_order_by>>;
  where?: InputMaybe<energy_certificate_type_bool_exp>;
};

export type query_rootenergy_certificate_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootenergy_consumerArgs = {
  distinct_on?: InputMaybe<Array<energy_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_consumer_order_by>>;
  where?: InputMaybe<energy_consumer_bool_exp>;
};

export type query_rootenergy_consumer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_consumer_order_by>>;
  where?: InputMaybe<energy_consumer_bool_exp>;
};

export type query_rootenergy_consumer_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootenergy_consumer_technology_typeArgs = {
  distinct_on?: InputMaybe<Array<energy_consumer_technology_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_consumer_technology_type_order_by>>;
  where?: InputMaybe<energy_consumer_technology_type_bool_exp>;
};

export type query_rootenergy_consumer_technology_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_consumer_technology_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_consumer_technology_type_order_by>>;
  where?: InputMaybe<energy_consumer_technology_type_bool_exp>;
};

export type query_rootenergy_consumer_technology_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootenergy_consumer_typeArgs = {
  distinct_on?: InputMaybe<Array<energy_consumer_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_consumer_type_order_by>>;
  where?: InputMaybe<energy_consumer_type_bool_exp>;
};

export type query_rootenergy_consumer_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_consumer_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_consumer_type_order_by>>;
  where?: InputMaybe<energy_consumer_type_bool_exp>;
};

export type query_rootenergy_consumer_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootenergy_distributionArgs = {
  distinct_on?: InputMaybe<Array<energy_distribution_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_distribution_order_by>>;
  where?: InputMaybe<energy_distribution_bool_exp>;
};

export type query_rootenergy_distribution_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_distribution_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_distribution_order_by>>;
  where?: InputMaybe<energy_distribution_bool_exp>;
};

export type query_rootenergy_distribution_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootenergy_distribution_typeArgs = {
  distinct_on?: InputMaybe<Array<energy_distribution_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_distribution_type_order_by>>;
  where?: InputMaybe<energy_distribution_type_bool_exp>;
};

export type query_rootenergy_distribution_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_distribution_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_distribution_type_order_by>>;
  where?: InputMaybe<energy_distribution_type_bool_exp>;
};

export type query_rootenergy_distribution_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootenergy_pathArgs = {
  distinct_on?: InputMaybe<Array<energy_path_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_path_order_by>>;
  where?: InputMaybe<energy_path_bool_exp>;
};

export type query_rootenergy_path_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_path_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_path_order_by>>;
  where?: InputMaybe<energy_path_bool_exp>;
};

export type query_rootenergy_path_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootenergy_producerArgs = {
  distinct_on?: InputMaybe<Array<energy_producer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_producer_order_by>>;
  where?: InputMaybe<energy_producer_bool_exp>;
};

export type query_rootenergy_producer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_producer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_producer_order_by>>;
  where?: InputMaybe<energy_producer_bool_exp>;
};

export type query_rootenergy_producer_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootenergy_sourceArgs = {
  distinct_on?: InputMaybe<Array<energy_source_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_source_order_by>>;
  where?: InputMaybe<energy_source_bool_exp>;
};

export type query_rootenergy_source_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_source_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_source_order_by>>;
  where?: InputMaybe<energy_source_bool_exp>;
};

export type query_rootenergy_source_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootenergy_source_typeArgs = {
  distinct_on?: InputMaybe<Array<energy_source_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_source_type_order_by>>;
  where?: InputMaybe<energy_source_type_bool_exp>;
};

export type query_rootenergy_source_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_source_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_source_type_order_by>>;
  where?: InputMaybe<energy_source_type_bool_exp>;
};

export type query_rootenergy_source_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootenergy_standardArgs = {
  distinct_on?: InputMaybe<Array<energy_standard_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_standard_order_by>>;
  where?: InputMaybe<energy_standard_bool_exp>;
};

export type query_rootenergy_standard_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_standard_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_standard_order_by>>;
  where?: InputMaybe<energy_standard_bool_exp>;
};

export type query_rootenergy_standard_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootenergy_storageArgs = {
  distinct_on?: InputMaybe<Array<energy_storage_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_storage_order_by>>;
  where?: InputMaybe<energy_storage_bool_exp>;
};

export type query_rootenergy_storage_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_storage_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_storage_order_by>>;
  where?: InputMaybe<energy_storage_bool_exp>;
};

export type query_rootenergy_storage_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootenergy_storage_typeArgs = {
  distinct_on?: InputMaybe<Array<energy_storage_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_storage_type_order_by>>;
  where?: InputMaybe<energy_storage_type_bool_exp>;
};

export type query_rootenergy_storage_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_storage_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_storage_type_order_by>>;
  where?: InputMaybe<energy_storage_type_bool_exp>;
};

export type query_rootenergy_storage_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootenergy_systemArgs = {
  distinct_on?: InputMaybe<Array<energy_system_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_order_by>>;
  where?: InputMaybe<energy_system_bool_exp>;
};

export type query_rootenergy_system_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_system_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_order_by>>;
  where?: InputMaybe<energy_system_bool_exp>;
};

export type query_rootenergy_system_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootenergy_system_consumer_routeArgs = {
  distinct_on?: InputMaybe<Array<energy_system_consumer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_consumer_route_order_by>>;
  where?: InputMaybe<energy_system_consumer_route_bool_exp>;
};

export type query_rootenergy_system_consumer_route_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_system_consumer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_consumer_route_order_by>>;
  where?: InputMaybe<energy_system_consumer_route_bool_exp>;
};

export type query_rootenergy_system_consumer_route_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootenergy_system_producer_routeArgs = {
  distinct_on?: InputMaybe<Array<energy_system_producer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_producer_route_order_by>>;
  where?: InputMaybe<energy_system_producer_route_bool_exp>;
};

export type query_rootenergy_system_producer_route_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_system_producer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_producer_route_order_by>>;
  where?: InputMaybe<energy_system_producer_route_bool_exp>;
};

export type query_rootenergy_system_producer_route_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootenergy_system_typeArgs = {
  distinct_on?: InputMaybe<Array<energy_system_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_type_order_by>>;
  where?: InputMaybe<energy_system_type_bool_exp>;
};

export type query_rootenergy_system_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_system_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_type_order_by>>;
  where?: InputMaybe<energy_system_type_bool_exp>;
};

export type query_rootenergy_system_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootenvelopeArgs = {
  distinct_on?: InputMaybe<Array<envelope_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<envelope_order_by>>;
  where?: InputMaybe<envelope_bool_exp>;
};

export type query_rootenvelope_aggregateArgs = {
  distinct_on?: InputMaybe<Array<envelope_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<envelope_order_by>>;
  where?: InputMaybe<envelope_bool_exp>;
};

export type query_rootenvelope_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootenvelope_typeArgs = {
  distinct_on?: InputMaybe<Array<envelope_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<envelope_type_order_by>>;
  where?: InputMaybe<envelope_type_bool_exp>;
};

export type query_rootenvelope_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<envelope_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<envelope_type_order_by>>;
  where?: InputMaybe<envelope_type_bool_exp>;
};

export type query_rootenvelope_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rooteu_taxonomy_complianceArgs = {
  distinct_on?: InputMaybe<Array<eu_taxonomy_compliance_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<eu_taxonomy_compliance_order_by>>;
  where?: InputMaybe<eu_taxonomy_compliance_bool_exp>;
};

export type query_rooteu_taxonomy_compliance_aggregateArgs = {
  distinct_on?: InputMaybe<Array<eu_taxonomy_compliance_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<eu_taxonomy_compliance_order_by>>;
  where?: InputMaybe<eu_taxonomy_compliance_bool_exp>;
};

export type query_rooteu_taxonomy_compliance_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootfileArgs = {
  distinct_on?: InputMaybe<Array<file_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<file_order_by>>;
  where?: InputMaybe<file_bool_exp>;
};

export type query_rootfile_aggregateArgs = {
  distinct_on?: InputMaybe<Array<file_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<file_order_by>>;
  where?: InputMaybe<file_bool_exp>;
};

export type query_rootfile_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootgetActionPlanMetricsArgs = {
  id: Scalars['Int'];
};

export type query_rootgetActionPlanPdfArgs = {
  actionPlanId: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type query_rootgetAnalyzedBuildingArgs = {
  building_id: Scalars['Int'];
  year: Scalars['Int'];
};

export type query_rootgetAnalyzedBuildingsArgs = {
  where: BuildingWhere;
};

export type query_rootgetBenchmarkArgs = {
  portfolioId: Scalars['Int'];
};

export type query_rootgetBuildingModelMutationAvailabilityArgs = {
  building_model_id: Scalars['Int'];
};

export type query_rootgetConsumptionAllocationArgs = {
  building_id: Scalars['Int'];
  from: Scalars['Float'];
  to: Scalars['Float'];
};

export type query_rootgetConsumptionAnalysisArgs = {
  building_id: Scalars['Int'];
  from: Scalars['Float'];
  to: Scalars['Float'];
};

export type query_rootgetEsgAnalysisPdfArgs = {
  buildingId: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type query_rootgetEsgConsumptionAnalysisArgs = {
  buildingId: Scalars['Int'];
  year: Scalars['Int'];
};

export type query_rootgetFileDownloadUrlArgs = {
  id: Scalars['Float'];
};

export type query_rootgetQueueStatusArgs = {
  where?: InputMaybe<QueueWhere>;
};

export type query_rootgetYearlyConsumptionSummaryArgs = {
  building_id: Scalars['Int'];
  year: Scalars['Int'];
};

export type query_roothasSingleBuildingOrDraftArgs = {
  ids: Array<Scalars['Int']>;
};

export type query_rootinsulation_material_categoryArgs = {
  distinct_on?: InputMaybe<Array<insulation_material_category_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<insulation_material_category_order_by>>;
  where?: InputMaybe<insulation_material_category_bool_exp>;
};

export type query_rootinsulation_material_category_aggregateArgs = {
  distinct_on?: InputMaybe<Array<insulation_material_category_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<insulation_material_category_order_by>>;
  where?: InputMaybe<insulation_material_category_bool_exp>;
};

export type query_rootinsulation_material_category_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootinsulation_methodArgs = {
  distinct_on?: InputMaybe<Array<insulation_method_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<insulation_method_order_by>>;
  where?: InputMaybe<insulation_method_bool_exp>;
};

export type query_rootinsulation_method_aggregateArgs = {
  distinct_on?: InputMaybe<Array<insulation_method_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<insulation_method_order_by>>;
  where?: InputMaybe<insulation_method_bool_exp>;
};

export type query_rootinsulation_method_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootinvoice_payer_typeArgs = {
  distinct_on?: InputMaybe<Array<invoice_payer_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<invoice_payer_type_order_by>>;
  where?: InputMaybe<invoice_payer_type_bool_exp>;
};

export type query_rootinvoice_payer_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<invoice_payer_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<invoice_payer_type_order_by>>;
  where?: InputMaybe<invoice_payer_type_bool_exp>;
};

export type query_rootinvoice_payer_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootlangfuse_datasets_temporary_itemArgs = {
  distinct_on?: InputMaybe<Array<langfuse_datasets_temporary_item_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<langfuse_datasets_temporary_item_order_by>>;
  where?: InputMaybe<langfuse_datasets_temporary_item_bool_exp>;
};

export type query_rootlangfuse_datasets_temporary_item_aggregateArgs = {
  distinct_on?: InputMaybe<Array<langfuse_datasets_temporary_item_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<langfuse_datasets_temporary_item_order_by>>;
  where?: InputMaybe<langfuse_datasets_temporary_item_bool_exp>;
};

export type query_rootlangfuse_datasets_temporary_item_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootlanguageArgs = {
  distinct_on?: InputMaybe<Array<language_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<language_order_by>>;
  where?: InputMaybe<language_bool_exp>;
};

export type query_rootlanguage_aggregateArgs = {
  distinct_on?: InputMaybe<Array<language_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<language_order_by>>;
  where?: InputMaybe<language_bool_exp>;
};

export type query_rootlanguage_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootmeterArgs = {
  distinct_on?: InputMaybe<Array<meter_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<meter_order_by>>;
  where?: InputMaybe<meter_bool_exp>;
};

export type query_rootmeter_aggregateArgs = {
  distinct_on?: InputMaybe<Array<meter_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<meter_order_by>>;
  where?: InputMaybe<meter_bool_exp>;
};

export type query_rootmeter_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootmeter_integrationArgs = {
  distinct_on?: InputMaybe<Array<meter_integration_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<meter_integration_order_by>>;
  where?: InputMaybe<meter_integration_bool_exp>;
};

export type query_rootmeter_integration_aggregateArgs = {
  distinct_on?: InputMaybe<Array<meter_integration_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<meter_integration_order_by>>;
  where?: InputMaybe<meter_integration_bool_exp>;
};

export type query_rootmeter_integration_buildingArgs = {
  distinct_on?: InputMaybe<Array<meter_integration_building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<meter_integration_building_order_by>>;
  where?: InputMaybe<meter_integration_building_bool_exp>;
};

export type query_rootmeter_integration_building_aggregateArgs = {
  distinct_on?: InputMaybe<Array<meter_integration_building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<meter_integration_building_order_by>>;
  where?: InputMaybe<meter_integration_building_bool_exp>;
};

export type query_rootmeter_integration_building_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootmeter_integration_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootmeter_integration_typeArgs = {
  distinct_on?: InputMaybe<Array<meter_integration_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<meter_integration_type_order_by>>;
  where?: InputMaybe<meter_integration_type_bool_exp>;
};

export type query_rootmeter_integration_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<meter_integration_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<meter_integration_type_order_by>>;
  where?: InputMaybe<meter_integration_type_bool_exp>;
};

export type query_rootmeter_integration_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootnotificationArgs = {
  distinct_on?: InputMaybe<Array<notification_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<notification_order_by>>;
  where?: InputMaybe<notification_bool_exp>;
};

export type query_rootnotification_aggregateArgs = {
  distinct_on?: InputMaybe<Array<notification_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<notification_order_by>>;
  where?: InputMaybe<notification_bool_exp>;
};

export type query_rootnotification_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootnotification_templateArgs = {
  distinct_on?: InputMaybe<Array<notification_template_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<notification_template_order_by>>;
  where?: InputMaybe<notification_template_bool_exp>;
};

export type query_rootnotification_template_aggregateArgs = {
  distinct_on?: InputMaybe<Array<notification_template_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<notification_template_order_by>>;
  where?: InputMaybe<notification_template_bool_exp>;
};

export type query_rootnotification_template_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootnotification_typeArgs = {
  distinct_on?: InputMaybe<Array<notification_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<notification_type_order_by>>;
  where?: InputMaybe<notification_type_bool_exp>;
};

export type query_rootnotification_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<notification_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<notification_type_order_by>>;
  where?: InputMaybe<notification_type_bool_exp>;
};

export type query_rootnotification_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootoperation_model_typeArgs = {
  distinct_on?: InputMaybe<Array<operation_model_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<operation_model_type_order_by>>;
  where?: InputMaybe<operation_model_type_bool_exp>;
};

export type query_rootoperation_model_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<operation_model_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<operation_model_type_order_by>>;
  where?: InputMaybe<operation_model_type_bool_exp>;
};

export type query_rootoperation_model_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootorgArgs = {
  distinct_on?: InputMaybe<Array<org_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<org_order_by>>;
  where?: InputMaybe<org_bool_exp>;
};

export type query_rootorg_aggregateArgs = {
  distinct_on?: InputMaybe<Array<org_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<org_order_by>>;
  where?: InputMaybe<org_bool_exp>;
};

export type query_rootorg_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootorg_preferenceArgs = {
  distinct_on?: InputMaybe<Array<org_preference_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<org_preference_order_by>>;
  where?: InputMaybe<org_preference_bool_exp>;
};

export type query_rootorg_preference_aggregateArgs = {
  distinct_on?: InputMaybe<Array<org_preference_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<org_preference_order_by>>;
  where?: InputMaybe<org_preference_bool_exp>;
};

export type query_rootorg_preference_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootorg_status_typeArgs = {
  distinct_on?: InputMaybe<Array<org_status_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<org_status_type_order_by>>;
  where?: InputMaybe<org_status_type_bool_exp>;
};

export type query_rootorg_status_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<org_status_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<org_status_type_order_by>>;
  where?: InputMaybe<org_status_type_bool_exp>;
};

export type query_rootorg_status_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootportfolioArgs = {
  distinct_on?: InputMaybe<Array<portfolio_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<portfolio_order_by>>;
  where?: InputMaybe<portfolio_bool_exp>;
};

export type query_rootportfolio_aggregateArgs = {
  distinct_on?: InputMaybe<Array<portfolio_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<portfolio_order_by>>;
  where?: InputMaybe<portfolio_bool_exp>;
};

export type query_rootportfolio_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootrenovationArgs = {
  distinct_on?: InputMaybe<Array<renovation_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_order_by>>;
  where?: InputMaybe<renovation_bool_exp>;
};

export type query_rootrenovation_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_order_by>>;
  where?: InputMaybe<renovation_bool_exp>;
};

export type query_rootrenovation_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootrenovation_energy_consumerArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_consumer_order_by>>;
  where?: InputMaybe<renovation_energy_consumer_bool_exp>;
};

export type query_rootrenovation_energy_consumer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_consumer_order_by>>;
  where?: InputMaybe<renovation_energy_consumer_bool_exp>;
};

export type query_rootrenovation_energy_distributionArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_distribution_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_distribution_order_by>>;
  where?: InputMaybe<renovation_energy_distribution_bool_exp>;
};

export type query_rootrenovation_energy_distribution_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_distribution_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_distribution_order_by>>;
  where?: InputMaybe<renovation_energy_distribution_bool_exp>;
};

export type query_rootrenovation_energy_producerArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_producer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_producer_order_by>>;
  where?: InputMaybe<renovation_energy_producer_bool_exp>;
};

export type query_rootrenovation_energy_producer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_producer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_producer_order_by>>;
  where?: InputMaybe<renovation_energy_producer_bool_exp>;
};

export type query_rootrenovation_energy_storageArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_storage_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_storage_order_by>>;
  where?: InputMaybe<renovation_energy_storage_bool_exp>;
};

export type query_rootrenovation_energy_storage_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_storage_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_storage_order_by>>;
  where?: InputMaybe<renovation_energy_storage_bool_exp>;
};

export type query_rootrenovation_energy_system_routeArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_system_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_system_route_order_by>>;
  where?: InputMaybe<renovation_energy_system_route_bool_exp>;
};

export type query_rootrenovation_energy_system_route_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_system_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_system_route_order_by>>;
  where?: InputMaybe<renovation_energy_system_route_bool_exp>;
};

export type query_rootrenovation_envelopeArgs = {
  distinct_on?: InputMaybe<Array<renovation_envelope_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_envelope_order_by>>;
  where?: InputMaybe<renovation_envelope_bool_exp>;
};

export type query_rootrenovation_envelope_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_envelope_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_envelope_order_by>>;
  where?: InputMaybe<renovation_envelope_bool_exp>;
};

export type query_rootrenovation_typeArgs = {
  distinct_on?: InputMaybe<Array<renovation_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_type_order_by>>;
  where?: InputMaybe<renovation_type_bool_exp>;
};

export type query_rootrenovation_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_type_order_by>>;
  where?: InputMaybe<renovation_type_bool_exp>;
};

export type query_rootrenovation_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootreportArgs = {
  distinct_on?: InputMaybe<Array<report_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_order_by>>;
  where?: InputMaybe<report_bool_exp>;
};

export type query_rootreport_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_order_by>>;
  where?: InputMaybe<report_bool_exp>;
};

export type query_rootreport_answerArgs = {
  distinct_on?: InputMaybe<Array<report_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_order_by>>;
  where?: InputMaybe<report_answer_bool_exp>;
};

export type query_rootreport_answer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_order_by>>;
  where?: InputMaybe<report_answer_bool_exp>;
};

export type query_rootreport_answer_autofill_optionArgs = {
  distinct_on?: InputMaybe<Array<report_answer_autofill_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_autofill_option_order_by>>;
  where?: InputMaybe<report_answer_autofill_option_bool_exp>;
};

export type query_rootreport_answer_autofill_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_answer_autofill_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_autofill_option_order_by>>;
  where?: InputMaybe<report_answer_autofill_option_bool_exp>;
};

export type query_rootreport_answer_autofill_option_by_pkArgs = {
  report_answer_id: Scalars['Int'];
  report_template_question_option_id: Scalars['Int'];
};

export type query_rootreport_answer_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootreport_answer_chosen_optionArgs = {
  distinct_on?: InputMaybe<Array<report_answer_chosen_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_chosen_option_order_by>>;
  where?: InputMaybe<report_answer_chosen_option_bool_exp>;
};

export type query_rootreport_answer_chosen_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_answer_chosen_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_chosen_option_order_by>>;
  where?: InputMaybe<report_answer_chosen_option_bool_exp>;
};

export type query_rootreport_answer_chosen_option_by_pkArgs = {
  report_answer_id: Scalars['Int'];
  report_template_question_option_id: Scalars['Int'];
};

export type query_rootreport_answer_stateArgs = {
  distinct_on?: InputMaybe<Array<report_answer_state_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_state_order_by>>;
  where?: InputMaybe<report_answer_state_bool_exp>;
};

export type query_rootreport_answer_state_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_answer_state_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_state_order_by>>;
  where?: InputMaybe<report_answer_state_bool_exp>;
};

export type query_rootreport_answer_state_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootreport_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootreport_evidenceArgs = {
  distinct_on?: InputMaybe<Array<report_evidence_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_evidence_order_by>>;
  where?: InputMaybe<report_evidence_bool_exp>;
};

export type query_rootreport_evidence_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_evidence_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_evidence_order_by>>;
  where?: InputMaybe<report_evidence_bool_exp>;
};

export type query_rootreport_evidence_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootreport_question_evidenceArgs = {
  distinct_on?: InputMaybe<Array<report_question_evidence_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_question_evidence_order_by>>;
  where?: InputMaybe<report_question_evidence_bool_exp>;
};

export type query_rootreport_question_evidence_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_question_evidence_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_question_evidence_order_by>>;
  where?: InputMaybe<report_question_evidence_bool_exp>;
};

export type query_rootreport_question_evidence_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootreport_scoreArgs = {
  distinct_on?: InputMaybe<Array<report_score_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_score_order_by>>;
  where?: InputMaybe<report_score_bool_exp>;
};

export type query_rootreport_score_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_score_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_score_order_by>>;
  where?: InputMaybe<report_score_bool_exp>;
};

export type query_rootreport_score_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootreport_section_progressArgs = {
  distinct_on?: InputMaybe<Array<report_section_progress_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_section_progress_order_by>>;
  where?: InputMaybe<report_section_progress_bool_exp>;
};

export type query_rootreport_section_progress_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_section_progress_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_section_progress_order_by>>;
  where?: InputMaybe<report_section_progress_bool_exp>;
};

export type query_rootreport_section_progress_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootreport_sub_buildingArgs = {
  distinct_on?: InputMaybe<Array<report_sub_building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_sub_building_order_by>>;
  where?: InputMaybe<report_sub_building_bool_exp>;
};

export type query_rootreport_sub_building_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_sub_building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_sub_building_order_by>>;
  where?: InputMaybe<report_sub_building_bool_exp>;
};

export type query_rootreport_sub_building_by_pkArgs = {
  report_id: Scalars['Int'];
  sub_building_id: Scalars['Int'];
};

export type query_rootreport_templateArgs = {
  distinct_on?: InputMaybe<Array<report_template_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_order_by>>;
  where?: InputMaybe<report_template_bool_exp>;
};

export type query_rootreport_template_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_order_by>>;
  where?: InputMaybe<report_template_bool_exp>;
};

export type query_rootreport_template_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootreport_template_questionArgs = {
  distinct_on?: InputMaybe<Array<report_template_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_question_order_by>>;
  where?: InputMaybe<report_template_question_bool_exp>;
};

export type query_rootreport_template_question_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_question_order_by>>;
  where?: InputMaybe<report_template_question_bool_exp>;
};

export type query_rootreport_template_question_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootreport_template_question_optionArgs = {
  distinct_on?: InputMaybe<Array<report_template_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_question_option_order_by>>;
  where?: InputMaybe<report_template_question_option_bool_exp>;
};

export type query_rootreport_template_question_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_question_option_order_by>>;
  where?: InputMaybe<report_template_question_option_bool_exp>;
};

export type query_rootreport_template_question_option_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootreport_template_question_typeArgs = {
  distinct_on?: InputMaybe<Array<report_template_question_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_question_type_order_by>>;
  where?: InputMaybe<report_template_question_type_bool_exp>;
};

export type query_rootreport_template_question_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_question_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_question_type_order_by>>;
  where?: InputMaybe<report_template_question_type_bool_exp>;
};

export type query_rootreport_template_question_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootreport_template_scoreArgs = {
  distinct_on?: InputMaybe<Array<report_template_score_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_score_order_by>>;
  where?: InputMaybe<report_template_score_bool_exp>;
};

export type query_rootreport_template_score_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_score_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_score_order_by>>;
  where?: InputMaybe<report_template_score_bool_exp>;
};

export type query_rootreport_template_score_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootreport_template_score_display_typeArgs = {
  distinct_on?: InputMaybe<Array<report_template_score_display_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_score_display_type_order_by>>;
  where?: InputMaybe<report_template_score_display_type_bool_exp>;
};

export type query_rootreport_template_score_display_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_score_display_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_score_display_type_order_by>>;
  where?: InputMaybe<report_template_score_display_type_bool_exp>;
};

export type query_rootreport_template_score_display_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootreport_template_sectionArgs = {
  distinct_on?: InputMaybe<Array<report_template_section_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_section_order_by>>;
  where?: InputMaybe<report_template_section_bool_exp>;
};

export type query_rootreport_template_section_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_section_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_section_order_by>>;
  where?: InputMaybe<report_template_section_bool_exp>;
};

export type query_rootreport_template_section_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootreport_template_section_targetArgs = {
  distinct_on?: InputMaybe<Array<report_template_section_target_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_section_target_order_by>>;
  where?: InputMaybe<report_template_section_target_bool_exp>;
};

export type query_rootreport_template_section_target_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_section_target_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_section_target_order_by>>;
  where?: InputMaybe<report_template_section_target_bool_exp>;
};

export type query_rootreport_template_section_target_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootreport_template_to_all_questionsArgs = {
  distinct_on?: InputMaybe<Array<report_template_to_all_questions_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_to_all_questions_order_by>>;
  where?: InputMaybe<report_template_to_all_questions_bool_exp>;
};

export type query_rootreport_template_to_all_questions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_to_all_questions_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_to_all_questions_order_by>>;
  where?: InputMaybe<report_template_to_all_questions_bool_exp>;
};

export type query_rootroleArgs = {
  distinct_on?: InputMaybe<Array<role_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<role_order_by>>;
  where?: InputMaybe<role_bool_exp>;
};

export type query_rootrole_aggregateArgs = {
  distinct_on?: InputMaybe<Array<role_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<role_order_by>>;
  where?: InputMaybe<role_bool_exp>;
};

export type query_rootrole_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootroof_typeArgs = {
  distinct_on?: InputMaybe<Array<roof_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<roof_type_order_by>>;
  where?: InputMaybe<roof_type_bool_exp>;
};

export type query_rootroof_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<roof_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<roof_type_order_by>>;
  where?: InputMaybe<roof_type_bool_exp>;
};

export type query_rootroof_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootscenarioArgs = {
  distinct_on?: InputMaybe<Array<scenario_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<scenario_order_by>>;
  where?: InputMaybe<scenario_bool_exp>;
};

export type query_rootscenario_aggregateArgs = {
  distinct_on?: InputMaybe<Array<scenario_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<scenario_order_by>>;
  where?: InputMaybe<scenario_bool_exp>;
};

export type query_rootscenario_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootsub_buildingArgs = {
  distinct_on?: InputMaybe<Array<sub_building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<sub_building_order_by>>;
  where?: InputMaybe<sub_building_bool_exp>;
};

export type query_rootsub_building_aggregateArgs = {
  distinct_on?: InputMaybe<Array<sub_building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<sub_building_order_by>>;
  where?: InputMaybe<sub_building_bool_exp>;
};

export type query_rootsub_building_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootsub_building_classArgs = {
  distinct_on?: InputMaybe<Array<sub_building_class_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<sub_building_class_order_by>>;
  where?: InputMaybe<sub_building_class_bool_exp>;
};

export type query_rootsub_building_class_aggregateArgs = {
  distinct_on?: InputMaybe<Array<sub_building_class_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<sub_building_class_order_by>>;
  where?: InputMaybe<sub_building_class_bool_exp>;
};

export type query_rootsub_building_class_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootsub_building_subsidyArgs = {
  distinct_on?: InputMaybe<Array<sub_building_subsidy_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<sub_building_subsidy_order_by>>;
  where?: InputMaybe<sub_building_subsidy_bool_exp>;
};

export type query_rootsub_building_subsidy_aggregateArgs = {
  distinct_on?: InputMaybe<Array<sub_building_subsidy_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<sub_building_subsidy_order_by>>;
  where?: InputMaybe<sub_building_subsidy_bool_exp>;
};

export type query_rootsub_building_subsidy_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootsubsidyArgs = {
  distinct_on?: InputMaybe<Array<subsidy_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<subsidy_order_by>>;
  where?: InputMaybe<subsidy_bool_exp>;
};

export type query_rootsubsidy_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subsidy_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<subsidy_order_by>>;
  where?: InputMaybe<subsidy_bool_exp>;
};

export type query_rootsubsidy_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootsubsidy_category_typeArgs = {
  distinct_on?: InputMaybe<Array<subsidy_category_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<subsidy_category_type_order_by>>;
  where?: InputMaybe<subsidy_category_type_bool_exp>;
};

export type query_rootsubsidy_category_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subsidy_category_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<subsidy_category_type_order_by>>;
  where?: InputMaybe<subsidy_category_type_bool_exp>;
};

export type query_rootsubsidy_category_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootsubsidy_region_typeArgs = {
  distinct_on?: InputMaybe<Array<subsidy_region_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<subsidy_region_type_order_by>>;
  where?: InputMaybe<subsidy_region_type_bool_exp>;
};

export type query_rootsubsidy_region_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subsidy_region_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<subsidy_region_type_order_by>>;
  where?: InputMaybe<subsidy_region_type_bool_exp>;
};

export type query_rootsubsidy_region_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootsubsidy_subsidy_categoryArgs = {
  distinct_on?: InputMaybe<Array<subsidy_subsidy_category_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<subsidy_subsidy_category_order_by>>;
  where?: InputMaybe<subsidy_subsidy_category_bool_exp>;
};

export type query_rootsubsidy_subsidy_category_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subsidy_subsidy_category_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<subsidy_subsidy_category_order_by>>;
  where?: InputMaybe<subsidy_subsidy_category_bool_exp>;
};

export type query_rootsubsidy_subsidy_category_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootsubsidy_typeArgs = {
  distinct_on?: InputMaybe<Array<subsidy_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<subsidy_type_order_by>>;
  where?: InputMaybe<subsidy_type_bool_exp>;
};

export type query_rootsubsidy_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subsidy_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<subsidy_type_order_by>>;
  where?: InputMaybe<subsidy_type_bool_exp>;
};

export type query_rootsubsidy_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootsync_stateArgs = {
  distinct_on?: InputMaybe<Array<sync_state_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<sync_state_order_by>>;
  where?: InputMaybe<sync_state_bool_exp>;
};

export type query_rootsync_state_aggregateArgs = {
  distinct_on?: InputMaybe<Array<sync_state_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<sync_state_order_by>>;
  where?: InputMaybe<sync_state_bool_exp>;
};

export type query_rootsync_state_by_pkArgs = {
  id: Scalars['String'];
};

export type query_roottarget_pathArgs = {
  distinct_on?: InputMaybe<Array<target_path_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<target_path_order_by>>;
  where?: InputMaybe<target_path_bool_exp>;
};

export type query_roottarget_path_aggregateArgs = {
  distinct_on?: InputMaybe<Array<target_path_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<target_path_order_by>>;
  where?: InputMaybe<target_path_bool_exp>;
};

export type query_roottarget_path_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_roottarget_path_templateArgs = {
  distinct_on?: InputMaybe<Array<target_path_template_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<target_path_template_order_by>>;
  where?: InputMaybe<target_path_template_bool_exp>;
};

export type query_roottarget_path_template_aggregateArgs = {
  distinct_on?: InputMaybe<Array<target_path_template_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<target_path_template_order_by>>;
  where?: InputMaybe<target_path_template_bool_exp>;
};

export type query_roottarget_path_template_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_roottarget_path_template_typeArgs = {
  distinct_on?: InputMaybe<Array<target_path_template_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<target_path_template_type_order_by>>;
  where?: InputMaybe<target_path_template_type_bool_exp>;
};

export type query_roottarget_path_template_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<target_path_template_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<target_path_template_type_order_by>>;
  where?: InputMaybe<target_path_template_type_bool_exp>;
};

export type query_roottarget_path_template_type_by_pkArgs = {
  id: Scalars['String'];
};

export type query_roottax_bracketArgs = {
  distinct_on?: InputMaybe<Array<tax_bracket_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<tax_bracket_order_by>>;
  where?: InputMaybe<tax_bracket_bool_exp>;
};

export type query_roottax_bracket_aggregateArgs = {
  distinct_on?: InputMaybe<Array<tax_bracket_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<tax_bracket_order_by>>;
  where?: InputMaybe<tax_bracket_bool_exp>;
};

export type query_roottax_bracket_by_pkArgs = {
  id: Scalars['String'];
};

export type query_roottype_of_useArgs = {
  distinct_on?: InputMaybe<Array<type_of_use_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<type_of_use_order_by>>;
  where?: InputMaybe<type_of_use_bool_exp>;
};

export type query_roottype_of_use_aggregateArgs = {
  distinct_on?: InputMaybe<Array<type_of_use_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<type_of_use_order_by>>;
  where?: InputMaybe<type_of_use_bool_exp>;
};

export type query_roottype_of_use_by_pkArgs = {
  id: Scalars['String'];
};

export type query_rootuserArgs = {
  distinct_on?: InputMaybe<Array<user_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_order_by>>;
  where?: InputMaybe<user_bool_exp>;
};

export type query_rootuser_aggregateArgs = {
  distinct_on?: InputMaybe<Array<user_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_order_by>>;
  where?: InputMaybe<user_bool_exp>;
};

export type query_rootuser_building_permissionArgs = {
  distinct_on?: InputMaybe<Array<user_building_permission_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_building_permission_order_by>>;
  where?: InputMaybe<user_building_permission_bool_exp>;
};

export type query_rootuser_building_permission_aggregateArgs = {
  distinct_on?: InputMaybe<Array<user_building_permission_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_building_permission_order_by>>;
  where?: InputMaybe<user_building_permission_bool_exp>;
};

export type query_rootuser_building_permission_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootuser_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootuser_portfolio_permissionArgs = {
  distinct_on?: InputMaybe<Array<user_portfolio_permission_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_portfolio_permission_order_by>>;
  where?: InputMaybe<user_portfolio_permission_bool_exp>;
};

export type query_rootuser_portfolio_permission_aggregateArgs = {
  distinct_on?: InputMaybe<Array<user_portfolio_permission_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_portfolio_permission_order_by>>;
  where?: InputMaybe<user_portfolio_permission_bool_exp>;
};

export type query_rootuser_portfolio_permission_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootuser_roleArgs = {
  distinct_on?: InputMaybe<Array<user_role_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_role_order_by>>;
  where?: InputMaybe<user_role_bool_exp>;
};

export type query_rootuser_role_aggregateArgs = {
  distinct_on?: InputMaybe<Array<user_role_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_role_order_by>>;
  where?: InputMaybe<user_role_bool_exp>;
};

export type query_rootuser_role_by_pkArgs = {
  id: Scalars['Int'];
};

export type query_rootwindow_typeArgs = {
  distinct_on?: InputMaybe<Array<window_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<window_type_order_by>>;
  where?: InputMaybe<window_type_bool_exp>;
};

export type query_rootwindow_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<window_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<window_type_order_by>>;
  where?: InputMaybe<window_type_bool_exp>;
};

export type query_rootwindow_type_by_pkArgs = {
  id: Scalars['String'];
};

export { queue_status_enum };

/** this table represents a polymorphic relationship to envelope, energy_consumer, energy_storage and energy_distribution to represent a change from -> to */
export type renovation = {
  /** An object relationship */
  action: action;
  action_id: Scalars['Int'];
  cost_total?: Maybe<Scalars['Float']>;
  cost_total_custom?: Maybe<Scalars['Float']>;
  cost_total_predium?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  new_entity_id?: Maybe<Scalars['Int']>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  renovation_type_id: renovation_type_enum;
  use_custom_cost: Scalars['Boolean'];
};

/** aggregated selection of "renovation" */
export type renovation_aggregate = {
  aggregate?: Maybe<renovation_aggregate_fields>;
  nodes: Array<renovation>;
};

export type renovation_aggregate_bool_exp = {
  bool_and?: InputMaybe<renovation_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<renovation_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<renovation_aggregate_bool_exp_count>;
};

export type renovation_aggregate_bool_exp_bool_and = {
  arguments: renovation_select_column_renovation_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<renovation_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type renovation_aggregate_bool_exp_bool_or = {
  arguments: renovation_select_column_renovation_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<renovation_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type renovation_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<renovation_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<renovation_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "renovation" */
export type renovation_aggregate_fields = {
  avg?: Maybe<renovation_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<renovation_max_fields>;
  min?: Maybe<renovation_min_fields>;
  stddev?: Maybe<renovation_stddev_fields>;
  stddev_pop?: Maybe<renovation_stddev_pop_fields>;
  stddev_samp?: Maybe<renovation_stddev_samp_fields>;
  sum?: Maybe<renovation_sum_fields>;
  var_pop?: Maybe<renovation_var_pop_fields>;
  var_samp?: Maybe<renovation_var_samp_fields>;
  variance?: Maybe<renovation_variance_fields>;
};

/** aggregate fields of "renovation" */
export type renovation_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<renovation_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "renovation" */
export type renovation_aggregate_order_by = {
  avg?: InputMaybe<renovation_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<renovation_max_order_by>;
  min?: InputMaybe<renovation_min_order_by>;
  stddev?: InputMaybe<renovation_stddev_order_by>;
  stddev_pop?: InputMaybe<renovation_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<renovation_stddev_samp_order_by>;
  sum?: InputMaybe<renovation_sum_order_by>;
  var_pop?: InputMaybe<renovation_var_pop_order_by>;
  var_samp?: InputMaybe<renovation_var_samp_order_by>;
  variance?: InputMaybe<renovation_variance_order_by>;
};

/** input type for inserting array relation for remote table "renovation" */
export type renovation_arr_rel_insert_input = {
  data: Array<renovation_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<renovation_on_conflict>;
};

/** aggregate avg on columns */
export type renovation_avg_fields = {
  action_id?: Maybe<Scalars['Float']>;
  cost_total?: Maybe<Scalars['Float']>;
  cost_total_custom?: Maybe<Scalars['Float']>;
  cost_total_predium?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "renovation" */
export type renovation_avg_order_by = {
  action_id?: InputMaybe<order_by>;
  cost_total?: InputMaybe<order_by>;
  cost_total_custom?: InputMaybe<order_by>;
  cost_total_predium?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "renovation". All fields are combined with a logical 'AND'. */
export type renovation_bool_exp = {
  _and?: InputMaybe<Array<renovation_bool_exp>>;
  _not?: InputMaybe<renovation_bool_exp>;
  _or?: InputMaybe<Array<renovation_bool_exp>>;
  action?: InputMaybe<action_bool_exp>;
  action_id?: InputMaybe<Int_comparison_exp>;
  cost_total?: InputMaybe<Float_comparison_exp>;
  cost_total_custom?: InputMaybe<Float_comparison_exp>;
  cost_total_predium?: InputMaybe<Float_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  new_entity_id?: InputMaybe<Int_comparison_exp>;
  old_entity_id?: InputMaybe<Int_comparison_exp>;
  order?: InputMaybe<Int_comparison_exp>;
  renovation_type_id?: InputMaybe<renovation_type_enum_comparison_exp>;
  use_custom_cost?: InputMaybe<Boolean_comparison_exp>;
};

export { renovation_constraint };

/** columns and relationships of "renovation_energy_consumer" */
export type renovation_energy_consumer = {
  /** An object relationship */
  action?: Maybe<action>;
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  new_energy_consumer?: Maybe<energy_consumer>;
  new_entity_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  old_energy_consumer?: Maybe<energy_consumer>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  renovation_type_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "renovation_energy_consumer" */
export type renovation_energy_consumer_aggregate = {
  aggregate?: Maybe<renovation_energy_consumer_aggregate_fields>;
  nodes: Array<renovation_energy_consumer>;
};

export type renovation_energy_consumer_aggregate_bool_exp = {
  count?: InputMaybe<renovation_energy_consumer_aggregate_bool_exp_count>;
};

export type renovation_energy_consumer_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<renovation_energy_consumer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<renovation_energy_consumer_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "renovation_energy_consumer" */
export type renovation_energy_consumer_aggregate_fields = {
  avg?: Maybe<renovation_energy_consumer_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<renovation_energy_consumer_max_fields>;
  min?: Maybe<renovation_energy_consumer_min_fields>;
  stddev?: Maybe<renovation_energy_consumer_stddev_fields>;
  stddev_pop?: Maybe<renovation_energy_consumer_stddev_pop_fields>;
  stddev_samp?: Maybe<renovation_energy_consumer_stddev_samp_fields>;
  sum?: Maybe<renovation_energy_consumer_sum_fields>;
  var_pop?: Maybe<renovation_energy_consumer_var_pop_fields>;
  var_samp?: Maybe<renovation_energy_consumer_var_samp_fields>;
  variance?: Maybe<renovation_energy_consumer_variance_fields>;
};

/** aggregate fields of "renovation_energy_consumer" */
export type renovation_energy_consumer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<renovation_energy_consumer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "renovation_energy_consumer" */
export type renovation_energy_consumer_aggregate_order_by = {
  avg?: InputMaybe<renovation_energy_consumer_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<renovation_energy_consumer_max_order_by>;
  min?: InputMaybe<renovation_energy_consumer_min_order_by>;
  stddev?: InputMaybe<renovation_energy_consumer_stddev_order_by>;
  stddev_pop?: InputMaybe<renovation_energy_consumer_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<renovation_energy_consumer_stddev_samp_order_by>;
  sum?: InputMaybe<renovation_energy_consumer_sum_order_by>;
  var_pop?: InputMaybe<renovation_energy_consumer_var_pop_order_by>;
  var_samp?: InputMaybe<renovation_energy_consumer_var_samp_order_by>;
  variance?: InputMaybe<renovation_energy_consumer_variance_order_by>;
};

/** input type for inserting array relation for remote table "renovation_energy_consumer" */
export type renovation_energy_consumer_arr_rel_insert_input = {
  data: Array<renovation_energy_consumer_insert_input>;
};

/** aggregate avg on columns */
export type renovation_energy_consumer_avg_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "renovation_energy_consumer" */
export type renovation_energy_consumer_avg_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "renovation_energy_consumer". All fields are combined with a logical 'AND'. */
export type renovation_energy_consumer_bool_exp = {
  _and?: InputMaybe<Array<renovation_energy_consumer_bool_exp>>;
  _not?: InputMaybe<renovation_energy_consumer_bool_exp>;
  _or?: InputMaybe<Array<renovation_energy_consumer_bool_exp>>;
  action?: InputMaybe<action_bool_exp>;
  action_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  new_energy_consumer?: InputMaybe<energy_consumer_bool_exp>;
  new_entity_id?: InputMaybe<Int_comparison_exp>;
  old_energy_consumer?: InputMaybe<energy_consumer_bool_exp>;
  old_entity_id?: InputMaybe<Int_comparison_exp>;
  order?: InputMaybe<Int_comparison_exp>;
  renovation_type_id?: InputMaybe<String_comparison_exp>;
};

/** input type for incrementing numeric columns in table "renovation_energy_consumer" */
export type renovation_energy_consumer_inc_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "renovation_energy_consumer" */
export type renovation_energy_consumer_insert_input = {
  action?: InputMaybe<action_obj_rel_insert_input>;
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_energy_consumer?: InputMaybe<energy_consumer_obj_rel_insert_input>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_energy_consumer?: InputMaybe<energy_consumer_obj_rel_insert_input>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  renovation_type_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type renovation_energy_consumer_max_fields = {
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  new_entity_id?: Maybe<Scalars['Int']>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  renovation_type_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "renovation_energy_consumer" */
export type renovation_energy_consumer_max_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  renovation_type_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type renovation_energy_consumer_min_fields = {
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  new_entity_id?: Maybe<Scalars['Int']>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  renovation_type_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "renovation_energy_consumer" */
export type renovation_energy_consumer_min_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  renovation_type_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "renovation_energy_consumer" */
export type renovation_energy_consumer_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<renovation_energy_consumer>;
};

/** input type for inserting object relation for remote table "renovation_energy_consumer" */
export type renovation_energy_consumer_obj_rel_insert_input = {
  data: renovation_energy_consumer_insert_input;
};

/** Ordering options when selecting data from "renovation_energy_consumer". */
export type renovation_energy_consumer_order_by = {
  action?: InputMaybe<action_order_by>;
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_energy_consumer?: InputMaybe<energy_consumer_order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_energy_consumer?: InputMaybe<energy_consumer_order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  renovation_type_id?: InputMaybe<order_by>;
};

export { renovation_energy_consumer_select_column };

/** input type for updating data in table "renovation_energy_consumer" */
export type renovation_energy_consumer_set_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  renovation_type_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type renovation_energy_consumer_stddev_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "renovation_energy_consumer" */
export type renovation_energy_consumer_stddev_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type renovation_energy_consumer_stddev_pop_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "renovation_energy_consumer" */
export type renovation_energy_consumer_stddev_pop_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type renovation_energy_consumer_stddev_samp_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "renovation_energy_consumer" */
export type renovation_energy_consumer_stddev_samp_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "renovation_energy_consumer" */
export type renovation_energy_consumer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: renovation_energy_consumer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type renovation_energy_consumer_stream_cursor_value_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  renovation_type_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type renovation_energy_consumer_sum_fields = {
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  new_entity_id?: Maybe<Scalars['Int']>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "renovation_energy_consumer" */
export type renovation_energy_consumer_sum_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

export type renovation_energy_consumer_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<renovation_energy_consumer_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<renovation_energy_consumer_set_input>;
  /** filter the rows which have to be updated */
  where: renovation_energy_consumer_bool_exp;
};

/** aggregate var_pop on columns */
export type renovation_energy_consumer_var_pop_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "renovation_energy_consumer" */
export type renovation_energy_consumer_var_pop_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type renovation_energy_consumer_var_samp_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "renovation_energy_consumer" */
export type renovation_energy_consumer_var_samp_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type renovation_energy_consumer_variance_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "renovation_energy_consumer" */
export type renovation_energy_consumer_variance_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** columns and relationships of "renovation_energy_distribution" */
export type renovation_energy_distribution = {
  /** An object relationship */
  action?: Maybe<action>;
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  new_energy_distribution?: Maybe<energy_distribution>;
  new_entity_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  old_energy_distribution?: Maybe<energy_distribution>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  renovation_type_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "renovation_energy_distribution" */
export type renovation_energy_distribution_aggregate = {
  aggregate?: Maybe<renovation_energy_distribution_aggregate_fields>;
  nodes: Array<renovation_energy_distribution>;
};

export type renovation_energy_distribution_aggregate_bool_exp = {
  count?: InputMaybe<renovation_energy_distribution_aggregate_bool_exp_count>;
};

export type renovation_energy_distribution_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<renovation_energy_distribution_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<renovation_energy_distribution_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "renovation_energy_distribution" */
export type renovation_energy_distribution_aggregate_fields = {
  avg?: Maybe<renovation_energy_distribution_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<renovation_energy_distribution_max_fields>;
  min?: Maybe<renovation_energy_distribution_min_fields>;
  stddev?: Maybe<renovation_energy_distribution_stddev_fields>;
  stddev_pop?: Maybe<renovation_energy_distribution_stddev_pop_fields>;
  stddev_samp?: Maybe<renovation_energy_distribution_stddev_samp_fields>;
  sum?: Maybe<renovation_energy_distribution_sum_fields>;
  var_pop?: Maybe<renovation_energy_distribution_var_pop_fields>;
  var_samp?: Maybe<renovation_energy_distribution_var_samp_fields>;
  variance?: Maybe<renovation_energy_distribution_variance_fields>;
};

/** aggregate fields of "renovation_energy_distribution" */
export type renovation_energy_distribution_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<renovation_energy_distribution_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "renovation_energy_distribution" */
export type renovation_energy_distribution_aggregate_order_by = {
  avg?: InputMaybe<renovation_energy_distribution_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<renovation_energy_distribution_max_order_by>;
  min?: InputMaybe<renovation_energy_distribution_min_order_by>;
  stddev?: InputMaybe<renovation_energy_distribution_stddev_order_by>;
  stddev_pop?: InputMaybe<renovation_energy_distribution_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<renovation_energy_distribution_stddev_samp_order_by>;
  sum?: InputMaybe<renovation_energy_distribution_sum_order_by>;
  var_pop?: InputMaybe<renovation_energy_distribution_var_pop_order_by>;
  var_samp?: InputMaybe<renovation_energy_distribution_var_samp_order_by>;
  variance?: InputMaybe<renovation_energy_distribution_variance_order_by>;
};

/** input type for inserting array relation for remote table "renovation_energy_distribution" */
export type renovation_energy_distribution_arr_rel_insert_input = {
  data: Array<renovation_energy_distribution_insert_input>;
};

/** aggregate avg on columns */
export type renovation_energy_distribution_avg_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "renovation_energy_distribution" */
export type renovation_energy_distribution_avg_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "renovation_energy_distribution". All fields are combined with a logical 'AND'. */
export type renovation_energy_distribution_bool_exp = {
  _and?: InputMaybe<Array<renovation_energy_distribution_bool_exp>>;
  _not?: InputMaybe<renovation_energy_distribution_bool_exp>;
  _or?: InputMaybe<Array<renovation_energy_distribution_bool_exp>>;
  action?: InputMaybe<action_bool_exp>;
  action_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  new_energy_distribution?: InputMaybe<energy_distribution_bool_exp>;
  new_entity_id?: InputMaybe<Int_comparison_exp>;
  old_energy_distribution?: InputMaybe<energy_distribution_bool_exp>;
  old_entity_id?: InputMaybe<Int_comparison_exp>;
  order?: InputMaybe<Int_comparison_exp>;
  renovation_type_id?: InputMaybe<String_comparison_exp>;
};

/** input type for incrementing numeric columns in table "renovation_energy_distribution" */
export type renovation_energy_distribution_inc_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "renovation_energy_distribution" */
export type renovation_energy_distribution_insert_input = {
  action?: InputMaybe<action_obj_rel_insert_input>;
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_energy_distribution?: InputMaybe<energy_distribution_obj_rel_insert_input>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_energy_distribution?: InputMaybe<energy_distribution_obj_rel_insert_input>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  renovation_type_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type renovation_energy_distribution_max_fields = {
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  new_entity_id?: Maybe<Scalars['Int']>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  renovation_type_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "renovation_energy_distribution" */
export type renovation_energy_distribution_max_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  renovation_type_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type renovation_energy_distribution_min_fields = {
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  new_entity_id?: Maybe<Scalars['Int']>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  renovation_type_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "renovation_energy_distribution" */
export type renovation_energy_distribution_min_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  renovation_type_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "renovation_energy_distribution" */
export type renovation_energy_distribution_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<renovation_energy_distribution>;
};

/** Ordering options when selecting data from "renovation_energy_distribution". */
export type renovation_energy_distribution_order_by = {
  action?: InputMaybe<action_order_by>;
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_energy_distribution?: InputMaybe<energy_distribution_order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_energy_distribution?: InputMaybe<energy_distribution_order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  renovation_type_id?: InputMaybe<order_by>;
};

export { renovation_energy_distribution_select_column };

/** input type for updating data in table "renovation_energy_distribution" */
export type renovation_energy_distribution_set_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  renovation_type_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type renovation_energy_distribution_stddev_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "renovation_energy_distribution" */
export type renovation_energy_distribution_stddev_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type renovation_energy_distribution_stddev_pop_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "renovation_energy_distribution" */
export type renovation_energy_distribution_stddev_pop_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type renovation_energy_distribution_stddev_samp_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "renovation_energy_distribution" */
export type renovation_energy_distribution_stddev_samp_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "renovation_energy_distribution" */
export type renovation_energy_distribution_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: renovation_energy_distribution_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type renovation_energy_distribution_stream_cursor_value_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  renovation_type_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type renovation_energy_distribution_sum_fields = {
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  new_entity_id?: Maybe<Scalars['Int']>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "renovation_energy_distribution" */
export type renovation_energy_distribution_sum_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

export type renovation_energy_distribution_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<renovation_energy_distribution_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<renovation_energy_distribution_set_input>;
  /** filter the rows which have to be updated */
  where: renovation_energy_distribution_bool_exp;
};

/** aggregate var_pop on columns */
export type renovation_energy_distribution_var_pop_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "renovation_energy_distribution" */
export type renovation_energy_distribution_var_pop_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type renovation_energy_distribution_var_samp_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "renovation_energy_distribution" */
export type renovation_energy_distribution_var_samp_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type renovation_energy_distribution_variance_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "renovation_energy_distribution" */
export type renovation_energy_distribution_variance_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** columns and relationships of "renovation_energy_producer" */
export type renovation_energy_producer = {
  /** An object relationship */
  action?: Maybe<action>;
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  new_energy_producer?: Maybe<energy_producer>;
  new_entity_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  old_energy_producer?: Maybe<energy_producer>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  renovation_type_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "renovation_energy_producer" */
export type renovation_energy_producer_aggregate = {
  aggregate?: Maybe<renovation_energy_producer_aggregate_fields>;
  nodes: Array<renovation_energy_producer>;
};

export type renovation_energy_producer_aggregate_bool_exp = {
  count?: InputMaybe<renovation_energy_producer_aggregate_bool_exp_count>;
};

export type renovation_energy_producer_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<renovation_energy_producer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<renovation_energy_producer_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "renovation_energy_producer" */
export type renovation_energy_producer_aggregate_fields = {
  avg?: Maybe<renovation_energy_producer_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<renovation_energy_producer_max_fields>;
  min?: Maybe<renovation_energy_producer_min_fields>;
  stddev?: Maybe<renovation_energy_producer_stddev_fields>;
  stddev_pop?: Maybe<renovation_energy_producer_stddev_pop_fields>;
  stddev_samp?: Maybe<renovation_energy_producer_stddev_samp_fields>;
  sum?: Maybe<renovation_energy_producer_sum_fields>;
  var_pop?: Maybe<renovation_energy_producer_var_pop_fields>;
  var_samp?: Maybe<renovation_energy_producer_var_samp_fields>;
  variance?: Maybe<renovation_energy_producer_variance_fields>;
};

/** aggregate fields of "renovation_energy_producer" */
export type renovation_energy_producer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<renovation_energy_producer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "renovation_energy_producer" */
export type renovation_energy_producer_aggregate_order_by = {
  avg?: InputMaybe<renovation_energy_producer_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<renovation_energy_producer_max_order_by>;
  min?: InputMaybe<renovation_energy_producer_min_order_by>;
  stddev?: InputMaybe<renovation_energy_producer_stddev_order_by>;
  stddev_pop?: InputMaybe<renovation_energy_producer_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<renovation_energy_producer_stddev_samp_order_by>;
  sum?: InputMaybe<renovation_energy_producer_sum_order_by>;
  var_pop?: InputMaybe<renovation_energy_producer_var_pop_order_by>;
  var_samp?: InputMaybe<renovation_energy_producer_var_samp_order_by>;
  variance?: InputMaybe<renovation_energy_producer_variance_order_by>;
};

/** input type for inserting array relation for remote table "renovation_energy_producer" */
export type renovation_energy_producer_arr_rel_insert_input = {
  data: Array<renovation_energy_producer_insert_input>;
};

/** aggregate avg on columns */
export type renovation_energy_producer_avg_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "renovation_energy_producer" */
export type renovation_energy_producer_avg_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "renovation_energy_producer". All fields are combined with a logical 'AND'. */
export type renovation_energy_producer_bool_exp = {
  _and?: InputMaybe<Array<renovation_energy_producer_bool_exp>>;
  _not?: InputMaybe<renovation_energy_producer_bool_exp>;
  _or?: InputMaybe<Array<renovation_energy_producer_bool_exp>>;
  action?: InputMaybe<action_bool_exp>;
  action_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  new_energy_producer?: InputMaybe<energy_producer_bool_exp>;
  new_entity_id?: InputMaybe<Int_comparison_exp>;
  old_energy_producer?: InputMaybe<energy_producer_bool_exp>;
  old_entity_id?: InputMaybe<Int_comparison_exp>;
  order?: InputMaybe<Int_comparison_exp>;
  renovation_type_id?: InputMaybe<String_comparison_exp>;
};

/** input type for incrementing numeric columns in table "renovation_energy_producer" */
export type renovation_energy_producer_inc_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "renovation_energy_producer" */
export type renovation_energy_producer_insert_input = {
  action?: InputMaybe<action_obj_rel_insert_input>;
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_energy_producer?: InputMaybe<energy_producer_obj_rel_insert_input>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_energy_producer?: InputMaybe<energy_producer_obj_rel_insert_input>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  renovation_type_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type renovation_energy_producer_max_fields = {
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  new_entity_id?: Maybe<Scalars['Int']>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  renovation_type_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "renovation_energy_producer" */
export type renovation_energy_producer_max_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  renovation_type_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type renovation_energy_producer_min_fields = {
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  new_entity_id?: Maybe<Scalars['Int']>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  renovation_type_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "renovation_energy_producer" */
export type renovation_energy_producer_min_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  renovation_type_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "renovation_energy_producer" */
export type renovation_energy_producer_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<renovation_energy_producer>;
};

/** Ordering options when selecting data from "renovation_energy_producer". */
export type renovation_energy_producer_order_by = {
  action?: InputMaybe<action_order_by>;
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_energy_producer?: InputMaybe<energy_producer_order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_energy_producer?: InputMaybe<energy_producer_order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  renovation_type_id?: InputMaybe<order_by>;
};

export { renovation_energy_producer_select_column };

/** input type for updating data in table "renovation_energy_producer" */
export type renovation_energy_producer_set_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  renovation_type_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type renovation_energy_producer_stddev_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "renovation_energy_producer" */
export type renovation_energy_producer_stddev_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type renovation_energy_producer_stddev_pop_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "renovation_energy_producer" */
export type renovation_energy_producer_stddev_pop_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type renovation_energy_producer_stddev_samp_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "renovation_energy_producer" */
export type renovation_energy_producer_stddev_samp_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "renovation_energy_producer" */
export type renovation_energy_producer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: renovation_energy_producer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type renovation_energy_producer_stream_cursor_value_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  renovation_type_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type renovation_energy_producer_sum_fields = {
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  new_entity_id?: Maybe<Scalars['Int']>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "renovation_energy_producer" */
export type renovation_energy_producer_sum_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

export type renovation_energy_producer_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<renovation_energy_producer_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<renovation_energy_producer_set_input>;
  /** filter the rows which have to be updated */
  where: renovation_energy_producer_bool_exp;
};

/** aggregate var_pop on columns */
export type renovation_energy_producer_var_pop_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "renovation_energy_producer" */
export type renovation_energy_producer_var_pop_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type renovation_energy_producer_var_samp_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "renovation_energy_producer" */
export type renovation_energy_producer_var_samp_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type renovation_energy_producer_variance_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "renovation_energy_producer" */
export type renovation_energy_producer_variance_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** columns and relationships of "renovation_energy_storage" */
export type renovation_energy_storage = {
  /** An object relationship */
  action?: Maybe<action>;
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  new_energy_storage?: Maybe<energy_storage>;
  new_entity_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  old_energy_storage?: Maybe<energy_storage>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  renovation_type_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "renovation_energy_storage" */
export type renovation_energy_storage_aggregate = {
  aggregate?: Maybe<renovation_energy_storage_aggregate_fields>;
  nodes: Array<renovation_energy_storage>;
};

export type renovation_energy_storage_aggregate_bool_exp = {
  count?: InputMaybe<renovation_energy_storage_aggregate_bool_exp_count>;
};

export type renovation_energy_storage_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<renovation_energy_storage_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<renovation_energy_storage_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "renovation_energy_storage" */
export type renovation_energy_storage_aggregate_fields = {
  avg?: Maybe<renovation_energy_storage_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<renovation_energy_storage_max_fields>;
  min?: Maybe<renovation_energy_storage_min_fields>;
  stddev?: Maybe<renovation_energy_storage_stddev_fields>;
  stddev_pop?: Maybe<renovation_energy_storage_stddev_pop_fields>;
  stddev_samp?: Maybe<renovation_energy_storage_stddev_samp_fields>;
  sum?: Maybe<renovation_energy_storage_sum_fields>;
  var_pop?: Maybe<renovation_energy_storage_var_pop_fields>;
  var_samp?: Maybe<renovation_energy_storage_var_samp_fields>;
  variance?: Maybe<renovation_energy_storage_variance_fields>;
};

/** aggregate fields of "renovation_energy_storage" */
export type renovation_energy_storage_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<renovation_energy_storage_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "renovation_energy_storage" */
export type renovation_energy_storage_aggregate_order_by = {
  avg?: InputMaybe<renovation_energy_storage_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<renovation_energy_storage_max_order_by>;
  min?: InputMaybe<renovation_energy_storage_min_order_by>;
  stddev?: InputMaybe<renovation_energy_storage_stddev_order_by>;
  stddev_pop?: InputMaybe<renovation_energy_storage_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<renovation_energy_storage_stddev_samp_order_by>;
  sum?: InputMaybe<renovation_energy_storage_sum_order_by>;
  var_pop?: InputMaybe<renovation_energy_storage_var_pop_order_by>;
  var_samp?: InputMaybe<renovation_energy_storage_var_samp_order_by>;
  variance?: InputMaybe<renovation_energy_storage_variance_order_by>;
};

/** input type for inserting array relation for remote table "renovation_energy_storage" */
export type renovation_energy_storage_arr_rel_insert_input = {
  data: Array<renovation_energy_storage_insert_input>;
};

/** aggregate avg on columns */
export type renovation_energy_storage_avg_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "renovation_energy_storage" */
export type renovation_energy_storage_avg_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "renovation_energy_storage". All fields are combined with a logical 'AND'. */
export type renovation_energy_storage_bool_exp = {
  _and?: InputMaybe<Array<renovation_energy_storage_bool_exp>>;
  _not?: InputMaybe<renovation_energy_storage_bool_exp>;
  _or?: InputMaybe<Array<renovation_energy_storage_bool_exp>>;
  action?: InputMaybe<action_bool_exp>;
  action_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  new_energy_storage?: InputMaybe<energy_storage_bool_exp>;
  new_entity_id?: InputMaybe<Int_comparison_exp>;
  old_energy_storage?: InputMaybe<energy_storage_bool_exp>;
  old_entity_id?: InputMaybe<Int_comparison_exp>;
  order?: InputMaybe<Int_comparison_exp>;
  renovation_type_id?: InputMaybe<String_comparison_exp>;
};

/** input type for incrementing numeric columns in table "renovation_energy_storage" */
export type renovation_energy_storage_inc_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "renovation_energy_storage" */
export type renovation_energy_storage_insert_input = {
  action?: InputMaybe<action_obj_rel_insert_input>;
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_energy_storage?: InputMaybe<energy_storage_obj_rel_insert_input>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_energy_storage?: InputMaybe<energy_storage_obj_rel_insert_input>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  renovation_type_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type renovation_energy_storage_max_fields = {
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  new_entity_id?: Maybe<Scalars['Int']>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  renovation_type_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "renovation_energy_storage" */
export type renovation_energy_storage_max_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  renovation_type_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type renovation_energy_storage_min_fields = {
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  new_entity_id?: Maybe<Scalars['Int']>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  renovation_type_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "renovation_energy_storage" */
export type renovation_energy_storage_min_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  renovation_type_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "renovation_energy_storage" */
export type renovation_energy_storage_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<renovation_energy_storage>;
};

/** Ordering options when selecting data from "renovation_energy_storage". */
export type renovation_energy_storage_order_by = {
  action?: InputMaybe<action_order_by>;
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_energy_storage?: InputMaybe<energy_storage_order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_energy_storage?: InputMaybe<energy_storage_order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  renovation_type_id?: InputMaybe<order_by>;
};

export { renovation_energy_storage_select_column };

/** input type for updating data in table "renovation_energy_storage" */
export type renovation_energy_storage_set_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  renovation_type_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type renovation_energy_storage_stddev_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "renovation_energy_storage" */
export type renovation_energy_storage_stddev_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type renovation_energy_storage_stddev_pop_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "renovation_energy_storage" */
export type renovation_energy_storage_stddev_pop_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type renovation_energy_storage_stddev_samp_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "renovation_energy_storage" */
export type renovation_energy_storage_stddev_samp_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "renovation_energy_storage" */
export type renovation_energy_storage_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: renovation_energy_storage_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type renovation_energy_storage_stream_cursor_value_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  renovation_type_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type renovation_energy_storage_sum_fields = {
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  new_entity_id?: Maybe<Scalars['Int']>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "renovation_energy_storage" */
export type renovation_energy_storage_sum_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

export type renovation_energy_storage_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<renovation_energy_storage_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<renovation_energy_storage_set_input>;
  /** filter the rows which have to be updated */
  where: renovation_energy_storage_bool_exp;
};

/** aggregate var_pop on columns */
export type renovation_energy_storage_var_pop_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "renovation_energy_storage" */
export type renovation_energy_storage_var_pop_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type renovation_energy_storage_var_samp_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "renovation_energy_storage" */
export type renovation_energy_storage_var_samp_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type renovation_energy_storage_variance_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "renovation_energy_storage" */
export type renovation_energy_storage_variance_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** columns and relationships of "renovation_energy_system_route" */
export type renovation_energy_system_route = {
  /** An object relationship */
  action?: Maybe<action>;
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  new_energy_system_route?: Maybe<energy_system_consumer_route>;
  new_entity_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  old_energy_system_route?: Maybe<energy_system_consumer_route>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  renovation_type_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "renovation_energy_system_route" */
export type renovation_energy_system_route_aggregate = {
  aggregate?: Maybe<renovation_energy_system_route_aggregate_fields>;
  nodes: Array<renovation_energy_system_route>;
};

export type renovation_energy_system_route_aggregate_bool_exp = {
  count?: InputMaybe<renovation_energy_system_route_aggregate_bool_exp_count>;
};

export type renovation_energy_system_route_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<renovation_energy_system_route_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<renovation_energy_system_route_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "renovation_energy_system_route" */
export type renovation_energy_system_route_aggregate_fields = {
  avg?: Maybe<renovation_energy_system_route_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<renovation_energy_system_route_max_fields>;
  min?: Maybe<renovation_energy_system_route_min_fields>;
  stddev?: Maybe<renovation_energy_system_route_stddev_fields>;
  stddev_pop?: Maybe<renovation_energy_system_route_stddev_pop_fields>;
  stddev_samp?: Maybe<renovation_energy_system_route_stddev_samp_fields>;
  sum?: Maybe<renovation_energy_system_route_sum_fields>;
  var_pop?: Maybe<renovation_energy_system_route_var_pop_fields>;
  var_samp?: Maybe<renovation_energy_system_route_var_samp_fields>;
  variance?: Maybe<renovation_energy_system_route_variance_fields>;
};

/** aggregate fields of "renovation_energy_system_route" */
export type renovation_energy_system_route_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<renovation_energy_system_route_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "renovation_energy_system_route" */
export type renovation_energy_system_route_aggregate_order_by = {
  avg?: InputMaybe<renovation_energy_system_route_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<renovation_energy_system_route_max_order_by>;
  min?: InputMaybe<renovation_energy_system_route_min_order_by>;
  stddev?: InputMaybe<renovation_energy_system_route_stddev_order_by>;
  stddev_pop?: InputMaybe<renovation_energy_system_route_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<renovation_energy_system_route_stddev_samp_order_by>;
  sum?: InputMaybe<renovation_energy_system_route_sum_order_by>;
  var_pop?: InputMaybe<renovation_energy_system_route_var_pop_order_by>;
  var_samp?: InputMaybe<renovation_energy_system_route_var_samp_order_by>;
  variance?: InputMaybe<renovation_energy_system_route_variance_order_by>;
};

/** input type for inserting array relation for remote table "renovation_energy_system_route" */
export type renovation_energy_system_route_arr_rel_insert_input = {
  data: Array<renovation_energy_system_route_insert_input>;
};

/** aggregate avg on columns */
export type renovation_energy_system_route_avg_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "renovation_energy_system_route" */
export type renovation_energy_system_route_avg_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "renovation_energy_system_route". All fields are combined with a logical 'AND'. */
export type renovation_energy_system_route_bool_exp = {
  _and?: InputMaybe<Array<renovation_energy_system_route_bool_exp>>;
  _not?: InputMaybe<renovation_energy_system_route_bool_exp>;
  _or?: InputMaybe<Array<renovation_energy_system_route_bool_exp>>;
  action?: InputMaybe<action_bool_exp>;
  action_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  new_energy_system_route?: InputMaybe<energy_system_consumer_route_bool_exp>;
  new_entity_id?: InputMaybe<Int_comparison_exp>;
  old_energy_system_route?: InputMaybe<energy_system_consumer_route_bool_exp>;
  old_entity_id?: InputMaybe<Int_comparison_exp>;
  order?: InputMaybe<Int_comparison_exp>;
  renovation_type_id?: InputMaybe<String_comparison_exp>;
};

/** input type for incrementing numeric columns in table "renovation_energy_system_route" */
export type renovation_energy_system_route_inc_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "renovation_energy_system_route" */
export type renovation_energy_system_route_insert_input = {
  action?: InputMaybe<action_obj_rel_insert_input>;
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_energy_system_route?: InputMaybe<energy_system_consumer_route_obj_rel_insert_input>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_energy_system_route?: InputMaybe<energy_system_consumer_route_obj_rel_insert_input>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  renovation_type_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type renovation_energy_system_route_max_fields = {
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  new_entity_id?: Maybe<Scalars['Int']>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  renovation_type_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "renovation_energy_system_route" */
export type renovation_energy_system_route_max_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  renovation_type_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type renovation_energy_system_route_min_fields = {
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  new_entity_id?: Maybe<Scalars['Int']>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  renovation_type_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "renovation_energy_system_route" */
export type renovation_energy_system_route_min_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  renovation_type_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "renovation_energy_system_route" */
export type renovation_energy_system_route_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<renovation_energy_system_route>;
};

/** Ordering options when selecting data from "renovation_energy_system_route". */
export type renovation_energy_system_route_order_by = {
  action?: InputMaybe<action_order_by>;
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_energy_system_route?: InputMaybe<energy_system_consumer_route_order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_energy_system_route?: InputMaybe<energy_system_consumer_route_order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  renovation_type_id?: InputMaybe<order_by>;
};

export { renovation_energy_system_route_select_column };

/** input type for updating data in table "renovation_energy_system_route" */
export type renovation_energy_system_route_set_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  renovation_type_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type renovation_energy_system_route_stddev_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "renovation_energy_system_route" */
export type renovation_energy_system_route_stddev_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type renovation_energy_system_route_stddev_pop_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "renovation_energy_system_route" */
export type renovation_energy_system_route_stddev_pop_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type renovation_energy_system_route_stddev_samp_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "renovation_energy_system_route" */
export type renovation_energy_system_route_stddev_samp_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "renovation_energy_system_route" */
export type renovation_energy_system_route_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: renovation_energy_system_route_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type renovation_energy_system_route_stream_cursor_value_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  renovation_type_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type renovation_energy_system_route_sum_fields = {
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  new_entity_id?: Maybe<Scalars['Int']>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "renovation_energy_system_route" */
export type renovation_energy_system_route_sum_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

export type renovation_energy_system_route_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<renovation_energy_system_route_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<renovation_energy_system_route_set_input>;
  /** filter the rows which have to be updated */
  where: renovation_energy_system_route_bool_exp;
};

/** aggregate var_pop on columns */
export type renovation_energy_system_route_var_pop_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "renovation_energy_system_route" */
export type renovation_energy_system_route_var_pop_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type renovation_energy_system_route_var_samp_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "renovation_energy_system_route" */
export type renovation_energy_system_route_var_samp_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type renovation_energy_system_route_variance_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "renovation_energy_system_route" */
export type renovation_energy_system_route_variance_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** columns and relationships of "renovation_envelope" */
export type renovation_envelope = {
  /** An object relationship */
  action?: Maybe<action>;
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  new_entity_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  new_envelope?: Maybe<envelope>;
  old_entity_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  old_envelope?: Maybe<envelope>;
  order?: Maybe<Scalars['Int']>;
  renovation_type_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "renovation_envelope" */
export type renovation_envelope_aggregate = {
  aggregate?: Maybe<renovation_envelope_aggregate_fields>;
  nodes: Array<renovation_envelope>;
};

export type renovation_envelope_aggregate_bool_exp = {
  count?: InputMaybe<renovation_envelope_aggregate_bool_exp_count>;
};

export type renovation_envelope_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<renovation_envelope_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<renovation_envelope_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "renovation_envelope" */
export type renovation_envelope_aggregate_fields = {
  avg?: Maybe<renovation_envelope_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<renovation_envelope_max_fields>;
  min?: Maybe<renovation_envelope_min_fields>;
  stddev?: Maybe<renovation_envelope_stddev_fields>;
  stddev_pop?: Maybe<renovation_envelope_stddev_pop_fields>;
  stddev_samp?: Maybe<renovation_envelope_stddev_samp_fields>;
  sum?: Maybe<renovation_envelope_sum_fields>;
  var_pop?: Maybe<renovation_envelope_var_pop_fields>;
  var_samp?: Maybe<renovation_envelope_var_samp_fields>;
  variance?: Maybe<renovation_envelope_variance_fields>;
};

/** aggregate fields of "renovation_envelope" */
export type renovation_envelope_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<renovation_envelope_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "renovation_envelope" */
export type renovation_envelope_aggregate_order_by = {
  avg?: InputMaybe<renovation_envelope_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<renovation_envelope_max_order_by>;
  min?: InputMaybe<renovation_envelope_min_order_by>;
  stddev?: InputMaybe<renovation_envelope_stddev_order_by>;
  stddev_pop?: InputMaybe<renovation_envelope_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<renovation_envelope_stddev_samp_order_by>;
  sum?: InputMaybe<renovation_envelope_sum_order_by>;
  var_pop?: InputMaybe<renovation_envelope_var_pop_order_by>;
  var_samp?: InputMaybe<renovation_envelope_var_samp_order_by>;
  variance?: InputMaybe<renovation_envelope_variance_order_by>;
};

/** input type for inserting array relation for remote table "renovation_envelope" */
export type renovation_envelope_arr_rel_insert_input = {
  data: Array<renovation_envelope_insert_input>;
};

/** aggregate avg on columns */
export type renovation_envelope_avg_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "renovation_envelope" */
export type renovation_envelope_avg_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "renovation_envelope". All fields are combined with a logical 'AND'. */
export type renovation_envelope_bool_exp = {
  _and?: InputMaybe<Array<renovation_envelope_bool_exp>>;
  _not?: InputMaybe<renovation_envelope_bool_exp>;
  _or?: InputMaybe<Array<renovation_envelope_bool_exp>>;
  action?: InputMaybe<action_bool_exp>;
  action_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  new_entity_id?: InputMaybe<Int_comparison_exp>;
  new_envelope?: InputMaybe<envelope_bool_exp>;
  old_entity_id?: InputMaybe<Int_comparison_exp>;
  old_envelope?: InputMaybe<envelope_bool_exp>;
  order?: InputMaybe<Int_comparison_exp>;
  renovation_type_id?: InputMaybe<String_comparison_exp>;
};

/** input type for incrementing numeric columns in table "renovation_envelope" */
export type renovation_envelope_inc_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "renovation_envelope" */
export type renovation_envelope_insert_input = {
  action?: InputMaybe<action_obj_rel_insert_input>;
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  new_envelope?: InputMaybe<envelope_obj_rel_insert_input>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  old_envelope?: InputMaybe<envelope_obj_rel_insert_input>;
  order?: InputMaybe<Scalars['Int']>;
  renovation_type_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type renovation_envelope_max_fields = {
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  new_entity_id?: Maybe<Scalars['Int']>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  renovation_type_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "renovation_envelope" */
export type renovation_envelope_max_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  renovation_type_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type renovation_envelope_min_fields = {
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  new_entity_id?: Maybe<Scalars['Int']>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  renovation_type_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "renovation_envelope" */
export type renovation_envelope_min_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  renovation_type_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "renovation_envelope" */
export type renovation_envelope_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<renovation_envelope>;
};

/** Ordering options when selecting data from "renovation_envelope". */
export type renovation_envelope_order_by = {
  action?: InputMaybe<action_order_by>;
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  new_envelope?: InputMaybe<envelope_order_by>;
  old_entity_id?: InputMaybe<order_by>;
  old_envelope?: InputMaybe<envelope_order_by>;
  order?: InputMaybe<order_by>;
  renovation_type_id?: InputMaybe<order_by>;
};

export { renovation_envelope_select_column };

/** input type for updating data in table "renovation_envelope" */
export type renovation_envelope_set_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  renovation_type_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type renovation_envelope_stddev_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "renovation_envelope" */
export type renovation_envelope_stddev_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type renovation_envelope_stddev_pop_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "renovation_envelope" */
export type renovation_envelope_stddev_pop_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type renovation_envelope_stddev_samp_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "renovation_envelope" */
export type renovation_envelope_stddev_samp_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "renovation_envelope" */
export type renovation_envelope_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: renovation_envelope_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type renovation_envelope_stream_cursor_value_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  renovation_type_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type renovation_envelope_sum_fields = {
  action_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  new_entity_id?: Maybe<Scalars['Int']>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "renovation_envelope" */
export type renovation_envelope_sum_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

export type renovation_envelope_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<renovation_envelope_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<renovation_envelope_set_input>;
  /** filter the rows which have to be updated */
  where: renovation_envelope_bool_exp;
};

/** aggregate var_pop on columns */
export type renovation_envelope_var_pop_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "renovation_envelope" */
export type renovation_envelope_var_pop_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type renovation_envelope_var_samp_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "renovation_envelope" */
export type renovation_envelope_var_samp_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type renovation_envelope_variance_fields = {
  action_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "renovation_envelope" */
export type renovation_envelope_variance_order_by = {
  action_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** input type for incrementing numeric columns in table "renovation" */
export type renovation_inc_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  cost_total_custom?: InputMaybe<Scalars['Float']>;
  cost_total_predium?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "renovation" */
export type renovation_insert_input = {
  action?: InputMaybe<action_obj_rel_insert_input>;
  action_id?: InputMaybe<Scalars['Int']>;
  cost_total_custom?: InputMaybe<Scalars['Float']>;
  cost_total_predium?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  renovation_type_id?: InputMaybe<renovation_type_enum>;
  use_custom_cost?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type renovation_max_fields = {
  action_id?: Maybe<Scalars['Int']>;
  cost_total?: Maybe<Scalars['Float']>;
  cost_total_custom?: Maybe<Scalars['Float']>;
  cost_total_predium?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  new_entity_id?: Maybe<Scalars['Int']>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "renovation" */
export type renovation_max_order_by = {
  action_id?: InputMaybe<order_by>;
  cost_total?: InputMaybe<order_by>;
  cost_total_custom?: InputMaybe<order_by>;
  cost_total_predium?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type renovation_min_fields = {
  action_id?: Maybe<Scalars['Int']>;
  cost_total?: Maybe<Scalars['Float']>;
  cost_total_custom?: Maybe<Scalars['Float']>;
  cost_total_predium?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  new_entity_id?: Maybe<Scalars['Int']>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "renovation" */
export type renovation_min_order_by = {
  action_id?: InputMaybe<order_by>;
  cost_total?: InputMaybe<order_by>;
  cost_total_custom?: InputMaybe<order_by>;
  cost_total_predium?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** response of any mutation on the table "renovation" */
export type renovation_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<renovation>;
};

/** on_conflict condition type for table "renovation" */
export type renovation_on_conflict = {
  constraint: renovation_constraint;
  update_columns?: Array<renovation_update_column>;
  where?: InputMaybe<renovation_bool_exp>;
};

/** Ordering options when selecting data from "renovation". */
export type renovation_order_by = {
  action?: InputMaybe<action_order_by>;
  action_id?: InputMaybe<order_by>;
  cost_total?: InputMaybe<order_by>;
  cost_total_custom?: InputMaybe<order_by>;
  cost_total_predium?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  renovation_type_id?: InputMaybe<order_by>;
  use_custom_cost?: InputMaybe<order_by>;
};

/** primary key columns input for table: renovation */
export type renovation_pk_columns_input = {
  id: Scalars['Int'];
};

export { renovation_select_column };

export { renovation_select_column_renovation_aggregate_bool_exp_bool_and_arguments_columns };

export { renovation_select_column_renovation_aggregate_bool_exp_bool_or_arguments_columns };

/** input type for updating data in table "renovation" */
export type renovation_set_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  cost_total_custom?: InputMaybe<Scalars['Float']>;
  cost_total_predium?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  renovation_type_id?: InputMaybe<renovation_type_enum>;
  use_custom_cost?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type renovation_stddev_fields = {
  action_id?: Maybe<Scalars['Float']>;
  cost_total?: Maybe<Scalars['Float']>;
  cost_total_custom?: Maybe<Scalars['Float']>;
  cost_total_predium?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "renovation" */
export type renovation_stddev_order_by = {
  action_id?: InputMaybe<order_by>;
  cost_total?: InputMaybe<order_by>;
  cost_total_custom?: InputMaybe<order_by>;
  cost_total_predium?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type renovation_stddev_pop_fields = {
  action_id?: Maybe<Scalars['Float']>;
  cost_total?: Maybe<Scalars['Float']>;
  cost_total_custom?: Maybe<Scalars['Float']>;
  cost_total_predium?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "renovation" */
export type renovation_stddev_pop_order_by = {
  action_id?: InputMaybe<order_by>;
  cost_total?: InputMaybe<order_by>;
  cost_total_custom?: InputMaybe<order_by>;
  cost_total_predium?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type renovation_stddev_samp_fields = {
  action_id?: Maybe<Scalars['Float']>;
  cost_total?: Maybe<Scalars['Float']>;
  cost_total_custom?: Maybe<Scalars['Float']>;
  cost_total_predium?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "renovation" */
export type renovation_stddev_samp_order_by = {
  action_id?: InputMaybe<order_by>;
  cost_total?: InputMaybe<order_by>;
  cost_total_custom?: InputMaybe<order_by>;
  cost_total_predium?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "renovation" */
export type renovation_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: renovation_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type renovation_stream_cursor_value_input = {
  action_id?: InputMaybe<Scalars['Int']>;
  cost_total?: InputMaybe<Scalars['Float']>;
  cost_total_custom?: InputMaybe<Scalars['Float']>;
  cost_total_predium?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  new_entity_id?: InputMaybe<Scalars['Int']>;
  old_entity_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  renovation_type_id?: InputMaybe<renovation_type_enum>;
  use_custom_cost?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type renovation_sum_fields = {
  action_id?: Maybe<Scalars['Int']>;
  cost_total?: Maybe<Scalars['Float']>;
  cost_total_custom?: Maybe<Scalars['Float']>;
  cost_total_predium?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  new_entity_id?: Maybe<Scalars['Int']>;
  old_entity_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "renovation" */
export type renovation_sum_order_by = {
  action_id?: InputMaybe<order_by>;
  cost_total?: InputMaybe<order_by>;
  cost_total_custom?: InputMaybe<order_by>;
  cost_total_predium?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** columns and relationships of "renovation_type" */
export type renovation_type = {
  id: Scalars['String'];
};

/** aggregated selection of "renovation_type" */
export type renovation_type_aggregate = {
  aggregate?: Maybe<renovation_type_aggregate_fields>;
  nodes: Array<renovation_type>;
};

/** aggregate fields of "renovation_type" */
export type renovation_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<renovation_type_max_fields>;
  min?: Maybe<renovation_type_min_fields>;
};

/** aggregate fields of "renovation_type" */
export type renovation_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<renovation_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "renovation_type". All fields are combined with a logical 'AND'. */
export type renovation_type_bool_exp = {
  _and?: InputMaybe<Array<renovation_type_bool_exp>>;
  _not?: InputMaybe<renovation_type_bool_exp>;
  _or?: InputMaybe<Array<renovation_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { renovation_type_constraint };

export { renovation_type_enum };

/** Boolean expression to compare columns of type "renovation_type_enum". All fields are combined with logical 'AND'. */
export type renovation_type_enum_comparison_exp = {
  _eq?: InputMaybe<renovation_type_enum>;
  _in?: InputMaybe<Array<renovation_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<renovation_type_enum>;
  _nin?: InputMaybe<Array<renovation_type_enum>>;
};

/** input type for inserting data into table "renovation_type" */
export type renovation_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type renovation_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type renovation_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "renovation_type" */
export type renovation_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<renovation_type>;
};

/** on_conflict condition type for table "renovation_type" */
export type renovation_type_on_conflict = {
  constraint: renovation_type_constraint;
  update_columns?: Array<renovation_type_update_column>;
  where?: InputMaybe<renovation_type_bool_exp>;
};

/** Ordering options when selecting data from "renovation_type". */
export type renovation_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: renovation_type */
export type renovation_type_pk_columns_input = {
  id: Scalars['String'];
};

export { renovation_type_select_column };

/** input type for updating data in table "renovation_type" */
export type renovation_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "renovation_type" */
export type renovation_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: renovation_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type renovation_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { renovation_type_update_column };

export type renovation_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<renovation_type_set_input>;
  /** filter the rows which have to be updated */
  where: renovation_type_bool_exp;
};

export { renovation_update_column };

export type renovation_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<renovation_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<renovation_set_input>;
  /** filter the rows which have to be updated */
  where: renovation_bool_exp;
};

/** aggregate var_pop on columns */
export type renovation_var_pop_fields = {
  action_id?: Maybe<Scalars['Float']>;
  cost_total?: Maybe<Scalars['Float']>;
  cost_total_custom?: Maybe<Scalars['Float']>;
  cost_total_predium?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "renovation" */
export type renovation_var_pop_order_by = {
  action_id?: InputMaybe<order_by>;
  cost_total?: InputMaybe<order_by>;
  cost_total_custom?: InputMaybe<order_by>;
  cost_total_predium?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type renovation_var_samp_fields = {
  action_id?: Maybe<Scalars['Float']>;
  cost_total?: Maybe<Scalars['Float']>;
  cost_total_custom?: Maybe<Scalars['Float']>;
  cost_total_predium?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "renovation" */
export type renovation_var_samp_order_by = {
  action_id?: InputMaybe<order_by>;
  cost_total?: InputMaybe<order_by>;
  cost_total_custom?: InputMaybe<order_by>;
  cost_total_predium?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type renovation_variance_fields = {
  action_id?: Maybe<Scalars['Float']>;
  cost_total?: Maybe<Scalars['Float']>;
  cost_total_custom?: Maybe<Scalars['Float']>;
  cost_total_predium?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_entity_id?: Maybe<Scalars['Float']>;
  old_entity_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "renovation" */
export type renovation_variance_order_by = {
  action_id?: InputMaybe<order_by>;
  cost_total?: InputMaybe<order_by>;
  cost_total_custom?: InputMaybe<order_by>;
  cost_total_predium?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_entity_id?: InputMaybe<order_by>;
  old_entity_id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
};

/** columns and relationships of "report" */
export type report = {
  completed: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  due_date: Scalars['timestamptz'];
  end_date: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  org: org;
  org_id: Scalars['Int'];
  /** A computed field, executes function "get_report_portfolios" */
  portfolios?: Maybe<Array<portfolio>>;
  progress: Scalars['Float'];
  /** An array relationship */
  report_answers: Array<report_answer>;
  /** An aggregate relationship */
  report_answers_aggregate: report_answer_aggregate;
  /** An array relationship */
  report_scores: Array<report_score>;
  /** An aggregate relationship */
  report_scores_aggregate: report_score_aggregate;
  /** An array relationship */
  report_sections_progress: Array<report_section_progress>;
  /** An aggregate relationship */
  report_sections_progress_aggregate: report_section_progress_aggregate;
  /** An array relationship */
  report_sub_buildings: Array<report_sub_building>;
  /** An aggregate relationship */
  report_sub_buildings_aggregate: report_sub_building_aggregate;
  /** An object relationship */
  report_template: report_template;
  report_template_id: Scalars['Int'];
  /** An object relationship */
  responsible_user?: Maybe<user>;
  responsible_user_id?: Maybe<Scalars['Int']>;
  start_date: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  version: Scalars['String'];
};

/** columns and relationships of "report" */
export type reportportfoliosArgs = {
  distinct_on?: InputMaybe<Array<portfolio_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<portfolio_order_by>>;
  where?: InputMaybe<portfolio_bool_exp>;
};

/** columns and relationships of "report" */
export type reportreport_answersArgs = {
  distinct_on?: InputMaybe<Array<report_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_order_by>>;
  where?: InputMaybe<report_answer_bool_exp>;
};

/** columns and relationships of "report" */
export type reportreport_answers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_order_by>>;
  where?: InputMaybe<report_answer_bool_exp>;
};

/** columns and relationships of "report" */
export type reportreport_scoresArgs = {
  distinct_on?: InputMaybe<Array<report_score_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_score_order_by>>;
  where?: InputMaybe<report_score_bool_exp>;
};

/** columns and relationships of "report" */
export type reportreport_scores_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_score_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_score_order_by>>;
  where?: InputMaybe<report_score_bool_exp>;
};

/** columns and relationships of "report" */
export type reportreport_sections_progressArgs = {
  distinct_on?: InputMaybe<Array<report_section_progress_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_section_progress_order_by>>;
  where?: InputMaybe<report_section_progress_bool_exp>;
};

/** columns and relationships of "report" */
export type reportreport_sections_progress_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_section_progress_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_section_progress_order_by>>;
  where?: InputMaybe<report_section_progress_bool_exp>;
};

/** columns and relationships of "report" */
export type reportreport_sub_buildingsArgs = {
  distinct_on?: InputMaybe<Array<report_sub_building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_sub_building_order_by>>;
  where?: InputMaybe<report_sub_building_bool_exp>;
};

/** columns and relationships of "report" */
export type reportreport_sub_buildings_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_sub_building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_sub_building_order_by>>;
  where?: InputMaybe<report_sub_building_bool_exp>;
};

/** aggregated selection of "report" */
export type report_aggregate = {
  aggregate?: Maybe<report_aggregate_fields>;
  nodes: Array<report>;
};

/** aggregate fields of "report" */
export type report_aggregate_fields = {
  avg?: Maybe<report_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<report_max_fields>;
  min?: Maybe<report_min_fields>;
  stddev?: Maybe<report_stddev_fields>;
  stddev_pop?: Maybe<report_stddev_pop_fields>;
  stddev_samp?: Maybe<report_stddev_samp_fields>;
  sum?: Maybe<report_sum_fields>;
  var_pop?: Maybe<report_var_pop_fields>;
  var_samp?: Maybe<report_var_samp_fields>;
  variance?: Maybe<report_variance_fields>;
};

/** aggregate fields of "report" */
export type report_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Each row answers a report question for a particular instance. If the parent section has a target other than org, the answer is linked to a corresponding entity */
export type report_answer = {
  autofill?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  report: report;
  /** An array relationship */
  report_answer_autofill_options: Array<report_answer_autofill_option>;
  /** An aggregate relationship */
  report_answer_autofill_options_aggregate: report_answer_autofill_option_aggregate;
  /** An array relationship */
  report_answer_chosen_options: Array<report_answer_chosen_option>;
  /** An aggregate relationship */
  report_answer_chosen_options_aggregate: report_answer_chosen_option_aggregate;
  report_id: Scalars['Int'];
  report_question_id: Scalars['Int'];
  /** An object relationship */
  report_template_question: report_template_question;
  state: report_answer_state_enum;
  sub_building_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  value?: Maybe<Scalars['String']>;
};

/** Each row answers a report question for a particular instance. If the parent section has a target other than org, the answer is linked to a corresponding entity */
export type report_answerreport_answer_autofill_optionsArgs = {
  distinct_on?: InputMaybe<Array<report_answer_autofill_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_autofill_option_order_by>>;
  where?: InputMaybe<report_answer_autofill_option_bool_exp>;
};

/** Each row answers a report question for a particular instance. If the parent section has a target other than org, the answer is linked to a corresponding entity */
export type report_answerreport_answer_autofill_options_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_answer_autofill_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_autofill_option_order_by>>;
  where?: InputMaybe<report_answer_autofill_option_bool_exp>;
};

/** Each row answers a report question for a particular instance. If the parent section has a target other than org, the answer is linked to a corresponding entity */
export type report_answerreport_answer_chosen_optionsArgs = {
  distinct_on?: InputMaybe<Array<report_answer_chosen_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_chosen_option_order_by>>;
  where?: InputMaybe<report_answer_chosen_option_bool_exp>;
};

/** Each row answers a report question for a particular instance. If the parent section has a target other than org, the answer is linked to a corresponding entity */
export type report_answerreport_answer_chosen_options_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_answer_chosen_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_chosen_option_order_by>>;
  where?: InputMaybe<report_answer_chosen_option_bool_exp>;
};

/** aggregated selection of "report_answer" */
export type report_answer_aggregate = {
  aggregate?: Maybe<report_answer_aggregate_fields>;
  nodes: Array<report_answer>;
};

export type report_answer_aggregate_bool_exp = {
  count?: InputMaybe<report_answer_aggregate_bool_exp_count>;
};

export type report_answer_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<report_answer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_answer_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "report_answer" */
export type report_answer_aggregate_fields = {
  avg?: Maybe<report_answer_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<report_answer_max_fields>;
  min?: Maybe<report_answer_min_fields>;
  stddev?: Maybe<report_answer_stddev_fields>;
  stddev_pop?: Maybe<report_answer_stddev_pop_fields>;
  stddev_samp?: Maybe<report_answer_stddev_samp_fields>;
  sum?: Maybe<report_answer_sum_fields>;
  var_pop?: Maybe<report_answer_var_pop_fields>;
  var_samp?: Maybe<report_answer_var_samp_fields>;
  variance?: Maybe<report_answer_variance_fields>;
};

/** aggregate fields of "report_answer" */
export type report_answer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_answer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "report_answer" */
export type report_answer_aggregate_order_by = {
  avg?: InputMaybe<report_answer_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<report_answer_max_order_by>;
  min?: InputMaybe<report_answer_min_order_by>;
  stddev?: InputMaybe<report_answer_stddev_order_by>;
  stddev_pop?: InputMaybe<report_answer_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<report_answer_stddev_samp_order_by>;
  sum?: InputMaybe<report_answer_sum_order_by>;
  var_pop?: InputMaybe<report_answer_var_pop_order_by>;
  var_samp?: InputMaybe<report_answer_var_samp_order_by>;
  variance?: InputMaybe<report_answer_variance_order_by>;
};

/** input type for inserting array relation for remote table "report_answer" */
export type report_answer_arr_rel_insert_input = {
  data: Array<report_answer_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<report_answer_on_conflict>;
};

/** Autofill suggestions for options */
export type report_answer_autofill_option = {
  /** An object relationship */
  report_answer: report_answer;
  report_answer_id: Scalars['Int'];
  /** An object relationship */
  report_template_question_option: report_template_question_option;
  report_template_question_option_id: Scalars['Int'];
};

/** aggregated selection of "report_answer_autofill_option" */
export type report_answer_autofill_option_aggregate = {
  aggregate?: Maybe<report_answer_autofill_option_aggregate_fields>;
  nodes: Array<report_answer_autofill_option>;
};

export type report_answer_autofill_option_aggregate_bool_exp = {
  count?: InputMaybe<report_answer_autofill_option_aggregate_bool_exp_count>;
};

export type report_answer_autofill_option_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<report_answer_autofill_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_answer_autofill_option_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "report_answer_autofill_option" */
export type report_answer_autofill_option_aggregate_fields = {
  avg?: Maybe<report_answer_autofill_option_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<report_answer_autofill_option_max_fields>;
  min?: Maybe<report_answer_autofill_option_min_fields>;
  stddev?: Maybe<report_answer_autofill_option_stddev_fields>;
  stddev_pop?: Maybe<report_answer_autofill_option_stddev_pop_fields>;
  stddev_samp?: Maybe<report_answer_autofill_option_stddev_samp_fields>;
  sum?: Maybe<report_answer_autofill_option_sum_fields>;
  var_pop?: Maybe<report_answer_autofill_option_var_pop_fields>;
  var_samp?: Maybe<report_answer_autofill_option_var_samp_fields>;
  variance?: Maybe<report_answer_autofill_option_variance_fields>;
};

/** aggregate fields of "report_answer_autofill_option" */
export type report_answer_autofill_option_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_answer_autofill_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "report_answer_autofill_option" */
export type report_answer_autofill_option_aggregate_order_by = {
  avg?: InputMaybe<report_answer_autofill_option_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<report_answer_autofill_option_max_order_by>;
  min?: InputMaybe<report_answer_autofill_option_min_order_by>;
  stddev?: InputMaybe<report_answer_autofill_option_stddev_order_by>;
  stddev_pop?: InputMaybe<report_answer_autofill_option_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<report_answer_autofill_option_stddev_samp_order_by>;
  sum?: InputMaybe<report_answer_autofill_option_sum_order_by>;
  var_pop?: InputMaybe<report_answer_autofill_option_var_pop_order_by>;
  var_samp?: InputMaybe<report_answer_autofill_option_var_samp_order_by>;
  variance?: InputMaybe<report_answer_autofill_option_variance_order_by>;
};

/** input type for inserting array relation for remote table "report_answer_autofill_option" */
export type report_answer_autofill_option_arr_rel_insert_input = {
  data: Array<report_answer_autofill_option_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<report_answer_autofill_option_on_conflict>;
};

/** aggregate avg on columns */
export type report_answer_autofill_option_avg_fields = {
  report_answer_id?: Maybe<Scalars['Float']>;
  report_template_question_option_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "report_answer_autofill_option" */
export type report_answer_autofill_option_avg_order_by = {
  report_answer_id?: InputMaybe<order_by>;
  report_template_question_option_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "report_answer_autofill_option". All fields are combined with a logical 'AND'. */
export type report_answer_autofill_option_bool_exp = {
  _and?: InputMaybe<Array<report_answer_autofill_option_bool_exp>>;
  _not?: InputMaybe<report_answer_autofill_option_bool_exp>;
  _or?: InputMaybe<Array<report_answer_autofill_option_bool_exp>>;
  report_answer?: InputMaybe<report_answer_bool_exp>;
  report_answer_id?: InputMaybe<Int_comparison_exp>;
  report_template_question_option?: InputMaybe<report_template_question_option_bool_exp>;
  report_template_question_option_id?: InputMaybe<Int_comparison_exp>;
};

export { report_answer_autofill_option_constraint };

/** input type for incrementing numeric columns in table "report_answer_autofill_option" */
export type report_answer_autofill_option_inc_input = {
  report_answer_id?: InputMaybe<Scalars['Int']>;
  report_template_question_option_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "report_answer_autofill_option" */
export type report_answer_autofill_option_insert_input = {
  report_answer?: InputMaybe<report_answer_obj_rel_insert_input>;
  report_answer_id?: InputMaybe<Scalars['Int']>;
  report_template_question_option?: InputMaybe<report_template_question_option_obj_rel_insert_input>;
  report_template_question_option_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type report_answer_autofill_option_max_fields = {
  report_answer_id?: Maybe<Scalars['Int']>;
  report_template_question_option_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "report_answer_autofill_option" */
export type report_answer_autofill_option_max_order_by = {
  report_answer_id?: InputMaybe<order_by>;
  report_template_question_option_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type report_answer_autofill_option_min_fields = {
  report_answer_id?: Maybe<Scalars['Int']>;
  report_template_question_option_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "report_answer_autofill_option" */
export type report_answer_autofill_option_min_order_by = {
  report_answer_id?: InputMaybe<order_by>;
  report_template_question_option_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "report_answer_autofill_option" */
export type report_answer_autofill_option_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<report_answer_autofill_option>;
};

/** on_conflict condition type for table "report_answer_autofill_option" */
export type report_answer_autofill_option_on_conflict = {
  constraint: report_answer_autofill_option_constraint;
  update_columns?: Array<report_answer_autofill_option_update_column>;
  where?: InputMaybe<report_answer_autofill_option_bool_exp>;
};

/** Ordering options when selecting data from "report_answer_autofill_option". */
export type report_answer_autofill_option_order_by = {
  report_answer?: InputMaybe<report_answer_order_by>;
  report_answer_id?: InputMaybe<order_by>;
  report_template_question_option?: InputMaybe<report_template_question_option_order_by>;
  report_template_question_option_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: report_answer_autofill_option */
export type report_answer_autofill_option_pk_columns_input = {
  report_answer_id: Scalars['Int'];
  report_template_question_option_id: Scalars['Int'];
};

export { report_answer_autofill_option_select_column };

/** input type for updating data in table "report_answer_autofill_option" */
export type report_answer_autofill_option_set_input = {
  report_answer_id?: InputMaybe<Scalars['Int']>;
  report_template_question_option_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type report_answer_autofill_option_stddev_fields = {
  report_answer_id?: Maybe<Scalars['Float']>;
  report_template_question_option_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "report_answer_autofill_option" */
export type report_answer_autofill_option_stddev_order_by = {
  report_answer_id?: InputMaybe<order_by>;
  report_template_question_option_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type report_answer_autofill_option_stddev_pop_fields = {
  report_answer_id?: Maybe<Scalars['Float']>;
  report_template_question_option_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "report_answer_autofill_option" */
export type report_answer_autofill_option_stddev_pop_order_by = {
  report_answer_id?: InputMaybe<order_by>;
  report_template_question_option_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type report_answer_autofill_option_stddev_samp_fields = {
  report_answer_id?: Maybe<Scalars['Float']>;
  report_template_question_option_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "report_answer_autofill_option" */
export type report_answer_autofill_option_stddev_samp_order_by = {
  report_answer_id?: InputMaybe<order_by>;
  report_template_question_option_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "report_answer_autofill_option" */
export type report_answer_autofill_option_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_answer_autofill_option_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_answer_autofill_option_stream_cursor_value_input = {
  report_answer_id?: InputMaybe<Scalars['Int']>;
  report_template_question_option_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type report_answer_autofill_option_sum_fields = {
  report_answer_id?: Maybe<Scalars['Int']>;
  report_template_question_option_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "report_answer_autofill_option" */
export type report_answer_autofill_option_sum_order_by = {
  report_answer_id?: InputMaybe<order_by>;
  report_template_question_option_id?: InputMaybe<order_by>;
};

export { report_answer_autofill_option_update_column };

export type report_answer_autofill_option_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<report_answer_autofill_option_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<report_answer_autofill_option_set_input>;
  /** filter the rows which have to be updated */
  where: report_answer_autofill_option_bool_exp;
};

/** aggregate var_pop on columns */
export type report_answer_autofill_option_var_pop_fields = {
  report_answer_id?: Maybe<Scalars['Float']>;
  report_template_question_option_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "report_answer_autofill_option" */
export type report_answer_autofill_option_var_pop_order_by = {
  report_answer_id?: InputMaybe<order_by>;
  report_template_question_option_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type report_answer_autofill_option_var_samp_fields = {
  report_answer_id?: Maybe<Scalars['Float']>;
  report_template_question_option_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "report_answer_autofill_option" */
export type report_answer_autofill_option_var_samp_order_by = {
  report_answer_id?: InputMaybe<order_by>;
  report_template_question_option_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type report_answer_autofill_option_variance_fields = {
  report_answer_id?: Maybe<Scalars['Float']>;
  report_template_question_option_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "report_answer_autofill_option" */
export type report_answer_autofill_option_variance_order_by = {
  report_answer_id?: InputMaybe<order_by>;
  report_template_question_option_id?: InputMaybe<order_by>;
};

/** aggregate avg on columns */
export type report_answer_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
  report_question_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "report_answer" */
export type report_answer_avg_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_question_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "report_answer". All fields are combined with a logical 'AND'. */
export type report_answer_bool_exp = {
  _and?: InputMaybe<Array<report_answer_bool_exp>>;
  _not?: InputMaybe<report_answer_bool_exp>;
  _or?: InputMaybe<Array<report_answer_bool_exp>>;
  autofill?: InputMaybe<String_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  report?: InputMaybe<report_bool_exp>;
  report_answer_autofill_options?: InputMaybe<report_answer_autofill_option_bool_exp>;
  report_answer_autofill_options_aggregate?: InputMaybe<report_answer_autofill_option_aggregate_bool_exp>;
  report_answer_chosen_options?: InputMaybe<report_answer_chosen_option_bool_exp>;
  report_answer_chosen_options_aggregate?: InputMaybe<report_answer_chosen_option_aggregate_bool_exp>;
  report_id?: InputMaybe<Int_comparison_exp>;
  report_question_id?: InputMaybe<Int_comparison_exp>;
  report_template_question?: InputMaybe<report_template_question_bool_exp>;
  state?: InputMaybe<report_answer_state_enum_comparison_exp>;
  sub_building_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** this table represents a chosen option for single/multi select answers */
export type report_answer_chosen_option = {
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  report_answer: report_answer;
  report_answer_id: Scalars['Int'];
  /** An object relationship */
  report_template_question_option: report_template_question_option;
  report_template_question_option_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "report_answer_chosen_option" */
export type report_answer_chosen_option_aggregate = {
  aggregate?: Maybe<report_answer_chosen_option_aggregate_fields>;
  nodes: Array<report_answer_chosen_option>;
};

export type report_answer_chosen_option_aggregate_bool_exp = {
  count?: InputMaybe<report_answer_chosen_option_aggregate_bool_exp_count>;
};

export type report_answer_chosen_option_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<report_answer_chosen_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_answer_chosen_option_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "report_answer_chosen_option" */
export type report_answer_chosen_option_aggregate_fields = {
  avg?: Maybe<report_answer_chosen_option_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<report_answer_chosen_option_max_fields>;
  min?: Maybe<report_answer_chosen_option_min_fields>;
  stddev?: Maybe<report_answer_chosen_option_stddev_fields>;
  stddev_pop?: Maybe<report_answer_chosen_option_stddev_pop_fields>;
  stddev_samp?: Maybe<report_answer_chosen_option_stddev_samp_fields>;
  sum?: Maybe<report_answer_chosen_option_sum_fields>;
  var_pop?: Maybe<report_answer_chosen_option_var_pop_fields>;
  var_samp?: Maybe<report_answer_chosen_option_var_samp_fields>;
  variance?: Maybe<report_answer_chosen_option_variance_fields>;
};

/** aggregate fields of "report_answer_chosen_option" */
export type report_answer_chosen_option_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_answer_chosen_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "report_answer_chosen_option" */
export type report_answer_chosen_option_aggregate_order_by = {
  avg?: InputMaybe<report_answer_chosen_option_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<report_answer_chosen_option_max_order_by>;
  min?: InputMaybe<report_answer_chosen_option_min_order_by>;
  stddev?: InputMaybe<report_answer_chosen_option_stddev_order_by>;
  stddev_pop?: InputMaybe<report_answer_chosen_option_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<report_answer_chosen_option_stddev_samp_order_by>;
  sum?: InputMaybe<report_answer_chosen_option_sum_order_by>;
  var_pop?: InputMaybe<report_answer_chosen_option_var_pop_order_by>;
  var_samp?: InputMaybe<report_answer_chosen_option_var_samp_order_by>;
  variance?: InputMaybe<report_answer_chosen_option_variance_order_by>;
};

/** input type for inserting array relation for remote table "report_answer_chosen_option" */
export type report_answer_chosen_option_arr_rel_insert_input = {
  data: Array<report_answer_chosen_option_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<report_answer_chosen_option_on_conflict>;
};

/** aggregate avg on columns */
export type report_answer_chosen_option_avg_fields = {
  report_answer_id?: Maybe<Scalars['Float']>;
  report_template_question_option_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "report_answer_chosen_option" */
export type report_answer_chosen_option_avg_order_by = {
  report_answer_id?: InputMaybe<order_by>;
  report_template_question_option_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "report_answer_chosen_option". All fields are combined with a logical 'AND'. */
export type report_answer_chosen_option_bool_exp = {
  _and?: InputMaybe<Array<report_answer_chosen_option_bool_exp>>;
  _not?: InputMaybe<report_answer_chosen_option_bool_exp>;
  _or?: InputMaybe<Array<report_answer_chosen_option_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  report_answer?: InputMaybe<report_answer_bool_exp>;
  report_answer_id?: InputMaybe<Int_comparison_exp>;
  report_template_question_option?: InputMaybe<report_template_question_option_bool_exp>;
  report_template_question_option_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

export { report_answer_chosen_option_constraint };

/** input type for incrementing numeric columns in table "report_answer_chosen_option" */
export type report_answer_chosen_option_inc_input = {
  report_answer_id?: InputMaybe<Scalars['Int']>;
  report_template_question_option_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "report_answer_chosen_option" */
export type report_answer_chosen_option_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  report_answer?: InputMaybe<report_answer_obj_rel_insert_input>;
  report_answer_id?: InputMaybe<Scalars['Int']>;
  report_template_question_option?: InputMaybe<report_template_question_option_obj_rel_insert_input>;
  report_template_question_option_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type report_answer_chosen_option_max_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  report_answer_id?: Maybe<Scalars['Int']>;
  report_template_question_option_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "report_answer_chosen_option" */
export type report_answer_chosen_option_max_order_by = {
  created_at?: InputMaybe<order_by>;
  report_answer_id?: InputMaybe<order_by>;
  report_template_question_option_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type report_answer_chosen_option_min_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  report_answer_id?: Maybe<Scalars['Int']>;
  report_template_question_option_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "report_answer_chosen_option" */
export type report_answer_chosen_option_min_order_by = {
  created_at?: InputMaybe<order_by>;
  report_answer_id?: InputMaybe<order_by>;
  report_template_question_option_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "report_answer_chosen_option" */
export type report_answer_chosen_option_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<report_answer_chosen_option>;
};

/** on_conflict condition type for table "report_answer_chosen_option" */
export type report_answer_chosen_option_on_conflict = {
  constraint: report_answer_chosen_option_constraint;
  update_columns?: Array<report_answer_chosen_option_update_column>;
  where?: InputMaybe<report_answer_chosen_option_bool_exp>;
};

/** Ordering options when selecting data from "report_answer_chosen_option". */
export type report_answer_chosen_option_order_by = {
  created_at?: InputMaybe<order_by>;
  report_answer?: InputMaybe<report_answer_order_by>;
  report_answer_id?: InputMaybe<order_by>;
  report_template_question_option?: InputMaybe<report_template_question_option_order_by>;
  report_template_question_option_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: report_answer_chosen_option */
export type report_answer_chosen_option_pk_columns_input = {
  report_answer_id: Scalars['Int'];
  report_template_question_option_id: Scalars['Int'];
};

export { report_answer_chosen_option_select_column };

/** input type for updating data in table "report_answer_chosen_option" */
export type report_answer_chosen_option_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  report_answer_id?: InputMaybe<Scalars['Int']>;
  report_template_question_option_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type report_answer_chosen_option_stddev_fields = {
  report_answer_id?: Maybe<Scalars['Float']>;
  report_template_question_option_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "report_answer_chosen_option" */
export type report_answer_chosen_option_stddev_order_by = {
  report_answer_id?: InputMaybe<order_by>;
  report_template_question_option_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type report_answer_chosen_option_stddev_pop_fields = {
  report_answer_id?: Maybe<Scalars['Float']>;
  report_template_question_option_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "report_answer_chosen_option" */
export type report_answer_chosen_option_stddev_pop_order_by = {
  report_answer_id?: InputMaybe<order_by>;
  report_template_question_option_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type report_answer_chosen_option_stddev_samp_fields = {
  report_answer_id?: Maybe<Scalars['Float']>;
  report_template_question_option_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "report_answer_chosen_option" */
export type report_answer_chosen_option_stddev_samp_order_by = {
  report_answer_id?: InputMaybe<order_by>;
  report_template_question_option_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "report_answer_chosen_option" */
export type report_answer_chosen_option_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_answer_chosen_option_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_answer_chosen_option_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  report_answer_id?: InputMaybe<Scalars['Int']>;
  report_template_question_option_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type report_answer_chosen_option_sum_fields = {
  report_answer_id?: Maybe<Scalars['Int']>;
  report_template_question_option_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "report_answer_chosen_option" */
export type report_answer_chosen_option_sum_order_by = {
  report_answer_id?: InputMaybe<order_by>;
  report_template_question_option_id?: InputMaybe<order_by>;
};

export { report_answer_chosen_option_update_column };

export type report_answer_chosen_option_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<report_answer_chosen_option_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<report_answer_chosen_option_set_input>;
  /** filter the rows which have to be updated */
  where: report_answer_chosen_option_bool_exp;
};

/** aggregate var_pop on columns */
export type report_answer_chosen_option_var_pop_fields = {
  report_answer_id?: Maybe<Scalars['Float']>;
  report_template_question_option_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "report_answer_chosen_option" */
export type report_answer_chosen_option_var_pop_order_by = {
  report_answer_id?: InputMaybe<order_by>;
  report_template_question_option_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type report_answer_chosen_option_var_samp_fields = {
  report_answer_id?: Maybe<Scalars['Float']>;
  report_template_question_option_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "report_answer_chosen_option" */
export type report_answer_chosen_option_var_samp_order_by = {
  report_answer_id?: InputMaybe<order_by>;
  report_template_question_option_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type report_answer_chosen_option_variance_fields = {
  report_answer_id?: Maybe<Scalars['Float']>;
  report_template_question_option_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "report_answer_chosen_option" */
export type report_answer_chosen_option_variance_order_by = {
  report_answer_id?: InputMaybe<order_by>;
  report_template_question_option_id?: InputMaybe<order_by>;
};

export { report_answer_constraint };

/** input type for incrementing numeric columns in table "report_answer" */
export type report_answer_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  report_id?: InputMaybe<Scalars['Int']>;
  report_question_id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "report_answer" */
export type report_answer_insert_input = {
  autofill?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  report?: InputMaybe<report_obj_rel_insert_input>;
  report_answer_autofill_options?: InputMaybe<report_answer_autofill_option_arr_rel_insert_input>;
  report_answer_chosen_options?: InputMaybe<report_answer_chosen_option_arr_rel_insert_input>;
  report_id?: InputMaybe<Scalars['Int']>;
  report_question_id?: InputMaybe<Scalars['Int']>;
  report_template_question?: InputMaybe<report_template_question_obj_rel_insert_input>;
  state?: InputMaybe<report_answer_state_enum>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type report_answer_max_fields = {
  autofill?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  report_id?: Maybe<Scalars['Int']>;
  report_question_id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "report_answer" */
export type report_answer_max_order_by = {
  autofill?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_question_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type report_answer_min_fields = {
  autofill?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  report_id?: Maybe<Scalars['Int']>;
  report_question_id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "report_answer" */
export type report_answer_min_order_by = {
  autofill?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_question_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** response of any mutation on the table "report_answer" */
export type report_answer_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<report_answer>;
};

/** input type for inserting object relation for remote table "report_answer" */
export type report_answer_obj_rel_insert_input = {
  data: report_answer_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<report_answer_on_conflict>;
};

/** on_conflict condition type for table "report_answer" */
export type report_answer_on_conflict = {
  constraint: report_answer_constraint;
  update_columns?: Array<report_answer_update_column>;
  where?: InputMaybe<report_answer_bool_exp>;
};

/** Ordering options when selecting data from "report_answer". */
export type report_answer_order_by = {
  autofill?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  report?: InputMaybe<report_order_by>;
  report_answer_autofill_options_aggregate?: InputMaybe<report_answer_autofill_option_aggregate_order_by>;
  report_answer_chosen_options_aggregate?: InputMaybe<report_answer_chosen_option_aggregate_order_by>;
  report_id?: InputMaybe<order_by>;
  report_question_id?: InputMaybe<order_by>;
  report_template_question?: InputMaybe<report_template_question_order_by>;
  state?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: report_answer */
export type report_answer_pk_columns_input = {
  id: Scalars['Int'];
};

export { report_answer_select_column };

/** input type for updating data in table "report_answer" */
export type report_answer_set_input = {
  autofill?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  report_id?: InputMaybe<Scalars['Int']>;
  report_question_id?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<report_answer_state_enum>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** The state for an answer */
export type report_answer_state = {
  id: Scalars['String'];
};

/** aggregated selection of "report_answer_state" */
export type report_answer_state_aggregate = {
  aggregate?: Maybe<report_answer_state_aggregate_fields>;
  nodes: Array<report_answer_state>;
};

/** aggregate fields of "report_answer_state" */
export type report_answer_state_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<report_answer_state_max_fields>;
  min?: Maybe<report_answer_state_min_fields>;
};

/** aggregate fields of "report_answer_state" */
export type report_answer_state_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_answer_state_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "report_answer_state". All fields are combined with a logical 'AND'. */
export type report_answer_state_bool_exp = {
  _and?: InputMaybe<Array<report_answer_state_bool_exp>>;
  _not?: InputMaybe<report_answer_state_bool_exp>;
  _or?: InputMaybe<Array<report_answer_state_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { report_answer_state_constraint };

export { report_answer_state_enum };

/** Boolean expression to compare columns of type "report_answer_state_enum". All fields are combined with logical 'AND'. */
export type report_answer_state_enum_comparison_exp = {
  _eq?: InputMaybe<report_answer_state_enum>;
  _in?: InputMaybe<Array<report_answer_state_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<report_answer_state_enum>;
  _nin?: InputMaybe<Array<report_answer_state_enum>>;
};

/** input type for inserting data into table "report_answer_state" */
export type report_answer_state_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type report_answer_state_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type report_answer_state_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "report_answer_state" */
export type report_answer_state_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<report_answer_state>;
};

/** on_conflict condition type for table "report_answer_state" */
export type report_answer_state_on_conflict = {
  constraint: report_answer_state_constraint;
  update_columns?: Array<report_answer_state_update_column>;
  where?: InputMaybe<report_answer_state_bool_exp>;
};

/** Ordering options when selecting data from "report_answer_state". */
export type report_answer_state_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: report_answer_state */
export type report_answer_state_pk_columns_input = {
  id: Scalars['String'];
};

export { report_answer_state_select_column };

/** input type for updating data in table "report_answer_state" */
export type report_answer_state_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "report_answer_state" */
export type report_answer_state_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_answer_state_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_answer_state_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { report_answer_state_update_column };

export type report_answer_state_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<report_answer_state_set_input>;
  /** filter the rows which have to be updated */
  where: report_answer_state_bool_exp;
};

/** aggregate stddev on columns */
export type report_answer_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
  report_question_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "report_answer" */
export type report_answer_stddev_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_question_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type report_answer_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
  report_question_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "report_answer" */
export type report_answer_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_question_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type report_answer_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
  report_question_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "report_answer" */
export type report_answer_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_question_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "report_answer" */
export type report_answer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_answer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_answer_stream_cursor_value_input = {
  autofill?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  report_id?: InputMaybe<Scalars['Int']>;
  report_question_id?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<report_answer_state_enum>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type report_answer_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  report_id?: Maybe<Scalars['Int']>;
  report_question_id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "report_answer" */
export type report_answer_sum_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_question_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

export { report_answer_update_column };

export type report_answer_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<report_answer_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<report_answer_set_input>;
  /** filter the rows which have to be updated */
  where: report_answer_bool_exp;
};

/** aggregate var_pop on columns */
export type report_answer_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
  report_question_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "report_answer" */
export type report_answer_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_question_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type report_answer_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
  report_question_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "report_answer" */
export type report_answer_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_question_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type report_answer_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
  report_question_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "report_answer" */
export type report_answer_variance_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_question_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate avg on columns */
export type report_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
  responsible_user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "report". All fields are combined with a logical 'AND'. */
export type report_bool_exp = {
  _and?: InputMaybe<Array<report_bool_exp>>;
  _not?: InputMaybe<report_bool_exp>;
  _or?: InputMaybe<Array<report_bool_exp>>;
  completed?: InputMaybe<Boolean_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  due_date?: InputMaybe<timestamptz_comparison_exp>;
  end_date?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  org?: InputMaybe<org_bool_exp>;
  org_id?: InputMaybe<Int_comparison_exp>;
  portfolios?: InputMaybe<portfolio_bool_exp>;
  progress?: InputMaybe<Float_comparison_exp>;
  report_answers?: InputMaybe<report_answer_bool_exp>;
  report_answers_aggregate?: InputMaybe<report_answer_aggregate_bool_exp>;
  report_scores?: InputMaybe<report_score_bool_exp>;
  report_scores_aggregate?: InputMaybe<report_score_aggregate_bool_exp>;
  report_sections_progress?: InputMaybe<report_section_progress_bool_exp>;
  report_sections_progress_aggregate?: InputMaybe<report_section_progress_aggregate_bool_exp>;
  report_sub_buildings?: InputMaybe<report_sub_building_bool_exp>;
  report_sub_buildings_aggregate?: InputMaybe<report_sub_building_aggregate_bool_exp>;
  report_template?: InputMaybe<report_template_bool_exp>;
  report_template_id?: InputMaybe<Int_comparison_exp>;
  responsible_user?: InputMaybe<user_bool_exp>;
  responsible_user_id?: InputMaybe<Int_comparison_exp>;
  start_date?: InputMaybe<timestamptz_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  version?: InputMaybe<String_comparison_exp>;
};

export { report_constraint };

/** evidence to an report answer  */
export type report_evidence = {
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  file?: Maybe<file>;
  file_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** An object relationship */
  report: report;
  report_id: Scalars['Int'];
  /** An array relationship */
  report_question_evidences: Array<report_question_evidence>;
  /** An aggregate relationship */
  report_question_evidences_aggregate: report_question_evidence_aggregate;
  updated_at: Scalars['timestamptz'];
  url?: Maybe<Scalars['String']>;
};

/** evidence to an report answer  */
export type report_evidencereport_question_evidencesArgs = {
  distinct_on?: InputMaybe<Array<report_question_evidence_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_question_evidence_order_by>>;
  where?: InputMaybe<report_question_evidence_bool_exp>;
};

/** evidence to an report answer  */
export type report_evidencereport_question_evidences_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_question_evidence_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_question_evidence_order_by>>;
  where?: InputMaybe<report_question_evidence_bool_exp>;
};

/** aggregated selection of "report_evidence" */
export type report_evidence_aggregate = {
  aggregate?: Maybe<report_evidence_aggregate_fields>;
  nodes: Array<report_evidence>;
};

export type report_evidence_aggregate_bool_exp = {
  count?: InputMaybe<report_evidence_aggregate_bool_exp_count>;
};

export type report_evidence_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<report_evidence_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_evidence_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "report_evidence" */
export type report_evidence_aggregate_fields = {
  avg?: Maybe<report_evidence_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<report_evidence_max_fields>;
  min?: Maybe<report_evidence_min_fields>;
  stddev?: Maybe<report_evidence_stddev_fields>;
  stddev_pop?: Maybe<report_evidence_stddev_pop_fields>;
  stddev_samp?: Maybe<report_evidence_stddev_samp_fields>;
  sum?: Maybe<report_evidence_sum_fields>;
  var_pop?: Maybe<report_evidence_var_pop_fields>;
  var_samp?: Maybe<report_evidence_var_samp_fields>;
  variance?: Maybe<report_evidence_variance_fields>;
};

/** aggregate fields of "report_evidence" */
export type report_evidence_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_evidence_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "report_evidence" */
export type report_evidence_aggregate_order_by = {
  avg?: InputMaybe<report_evidence_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<report_evidence_max_order_by>;
  min?: InputMaybe<report_evidence_min_order_by>;
  stddev?: InputMaybe<report_evidence_stddev_order_by>;
  stddev_pop?: InputMaybe<report_evidence_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<report_evidence_stddev_samp_order_by>;
  sum?: InputMaybe<report_evidence_sum_order_by>;
  var_pop?: InputMaybe<report_evidence_var_pop_order_by>;
  var_samp?: InputMaybe<report_evidence_var_samp_order_by>;
  variance?: InputMaybe<report_evidence_variance_order_by>;
};

/** input type for inserting array relation for remote table "report_evidence" */
export type report_evidence_arr_rel_insert_input = {
  data: Array<report_evidence_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<report_evidence_on_conflict>;
};

/** aggregate avg on columns */
export type report_evidence_avg_fields = {
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "report_evidence" */
export type report_evidence_avg_order_by = {
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "report_evidence". All fields are combined with a logical 'AND'. */
export type report_evidence_bool_exp = {
  _and?: InputMaybe<Array<report_evidence_bool_exp>>;
  _not?: InputMaybe<report_evidence_bool_exp>;
  _or?: InputMaybe<Array<report_evidence_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  file?: InputMaybe<file_bool_exp>;
  file_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  report?: InputMaybe<report_bool_exp>;
  report_id?: InputMaybe<Int_comparison_exp>;
  report_question_evidences?: InputMaybe<report_question_evidence_bool_exp>;
  report_question_evidences_aggregate?: InputMaybe<report_question_evidence_aggregate_bool_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  url?: InputMaybe<String_comparison_exp>;
};

export { report_evidence_constraint };

/** input type for incrementing numeric columns in table "report_evidence" */
export type report_evidence_inc_input = {
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  report_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "report_evidence" */
export type report_evidence_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file?: InputMaybe<file_obj_rel_insert_input>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  report?: InputMaybe<report_obj_rel_insert_input>;
  report_id?: InputMaybe<Scalars['Int']>;
  report_question_evidences?: InputMaybe<report_question_evidence_arr_rel_insert_input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type report_evidence_max_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  report_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "report_evidence" */
export type report_evidence_max_order_by = {
  created_at?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type report_evidence_min_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  report_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "report_evidence" */
export type report_evidence_min_order_by = {
  created_at?: InputMaybe<order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
};

/** response of any mutation on the table "report_evidence" */
export type report_evidence_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<report_evidence>;
};

/** input type for inserting object relation for remote table "report_evidence" */
export type report_evidence_obj_rel_insert_input = {
  data: report_evidence_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<report_evidence_on_conflict>;
};

/** on_conflict condition type for table "report_evidence" */
export type report_evidence_on_conflict = {
  constraint: report_evidence_constraint;
  update_columns?: Array<report_evidence_update_column>;
  where?: InputMaybe<report_evidence_bool_exp>;
};

/** Ordering options when selecting data from "report_evidence". */
export type report_evidence_order_by = {
  created_at?: InputMaybe<order_by>;
  file?: InputMaybe<file_order_by>;
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  report?: InputMaybe<report_order_by>;
  report_id?: InputMaybe<order_by>;
  report_question_evidences_aggregate?: InputMaybe<report_question_evidence_aggregate_order_by>;
  updated_at?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
};

/** primary key columns input for table: report_evidence */
export type report_evidence_pk_columns_input = {
  id: Scalars['Int'];
};

export { report_evidence_select_column };

/** input type for updating data in table "report_evidence" */
export type report_evidence_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  report_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type report_evidence_stddev_fields = {
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "report_evidence" */
export type report_evidence_stddev_order_by = {
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type report_evidence_stddev_pop_fields = {
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "report_evidence" */
export type report_evidence_stddev_pop_order_by = {
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type report_evidence_stddev_samp_fields = {
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "report_evidence" */
export type report_evidence_stddev_samp_order_by = {
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "report_evidence" */
export type report_evidence_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_evidence_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_evidence_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  report_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type report_evidence_sum_fields = {
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  report_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "report_evidence" */
export type report_evidence_sum_order_by = {
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
};

export { report_evidence_update_column };

export type report_evidence_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<report_evidence_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<report_evidence_set_input>;
  /** filter the rows which have to be updated */
  where: report_evidence_bool_exp;
};

/** aggregate var_pop on columns */
export type report_evidence_var_pop_fields = {
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "report_evidence" */
export type report_evidence_var_pop_order_by = {
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type report_evidence_var_samp_fields = {
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "report_evidence" */
export type report_evidence_var_samp_order_by = {
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type report_evidence_variance_fields = {
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "report_evidence" */
export type report_evidence_variance_order_by = {
  file_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
};

/** input type for incrementing numeric columns in table "report" */
export type report_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
  progress?: InputMaybe<Scalars['Float']>;
  report_template_id?: InputMaybe<Scalars['Int']>;
  responsible_user_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "report" */
export type report_insert_input = {
  completed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  due_date?: InputMaybe<Scalars['timestamptz']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  org?: InputMaybe<org_obj_rel_insert_input>;
  org_id?: InputMaybe<Scalars['Int']>;
  progress?: InputMaybe<Scalars['Float']>;
  report_answers?: InputMaybe<report_answer_arr_rel_insert_input>;
  report_scores?: InputMaybe<report_score_arr_rel_insert_input>;
  report_sections_progress?: InputMaybe<report_section_progress_arr_rel_insert_input>;
  report_sub_buildings?: InputMaybe<report_sub_building_arr_rel_insert_input>;
  report_template?: InputMaybe<report_template_obj_rel_insert_input>;
  report_template_id?: InputMaybe<Scalars['Int']>;
  responsible_user?: InputMaybe<user_obj_rel_insert_input>;
  responsible_user_id?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type report_max_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  due_date?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Int']>;
  responsible_user_id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type report_min_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  due_date?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Int']>;
  responsible_user_id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "report" */
export type report_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<report>;
};

/** input type for inserting object relation for remote table "report" */
export type report_obj_rel_insert_input = {
  data: report_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<report_on_conflict>;
};

/** on_conflict condition type for table "report" */
export type report_on_conflict = {
  constraint: report_constraint;
  update_columns?: Array<report_update_column>;
  where?: InputMaybe<report_bool_exp>;
};

/** Ordering options when selecting data from "report". */
export type report_order_by = {
  completed?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  due_date?: InputMaybe<order_by>;
  end_date?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org?: InputMaybe<org_order_by>;
  org_id?: InputMaybe<order_by>;
  portfolios_aggregate?: InputMaybe<portfolio_aggregate_order_by>;
  progress?: InputMaybe<order_by>;
  report_answers_aggregate?: InputMaybe<report_answer_aggregate_order_by>;
  report_scores_aggregate?: InputMaybe<report_score_aggregate_order_by>;
  report_sections_progress_aggregate?: InputMaybe<report_section_progress_aggregate_order_by>;
  report_sub_buildings_aggregate?: InputMaybe<report_sub_building_aggregate_order_by>;
  report_template?: InputMaybe<report_template_order_by>;
  report_template_id?: InputMaybe<order_by>;
  responsible_user?: InputMaybe<user_order_by>;
  responsible_user_id?: InputMaybe<order_by>;
  start_date?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  version?: InputMaybe<order_by>;
};

/** primary key columns input for table: report */
export type report_pk_columns_input = {
  id: Scalars['Int'];
};

/** The relationship between a specific report question and the evidence */
export type report_question_evidence = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object relationship */
  report_evidence: report_evidence;
  report_evidence_id: Scalars['Int'];
  /** An object relationship */
  report_template_question: report_template_question;
  report_template_question_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "report_question_evidence" */
export type report_question_evidence_aggregate = {
  aggregate?: Maybe<report_question_evidence_aggregate_fields>;
  nodes: Array<report_question_evidence>;
};

export type report_question_evidence_aggregate_bool_exp = {
  count?: InputMaybe<report_question_evidence_aggregate_bool_exp_count>;
};

export type report_question_evidence_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<report_question_evidence_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_question_evidence_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "report_question_evidence" */
export type report_question_evidence_aggregate_fields = {
  avg?: Maybe<report_question_evidence_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<report_question_evidence_max_fields>;
  min?: Maybe<report_question_evidence_min_fields>;
  stddev?: Maybe<report_question_evidence_stddev_fields>;
  stddev_pop?: Maybe<report_question_evidence_stddev_pop_fields>;
  stddev_samp?: Maybe<report_question_evidence_stddev_samp_fields>;
  sum?: Maybe<report_question_evidence_sum_fields>;
  var_pop?: Maybe<report_question_evidence_var_pop_fields>;
  var_samp?: Maybe<report_question_evidence_var_samp_fields>;
  variance?: Maybe<report_question_evidence_variance_fields>;
};

/** aggregate fields of "report_question_evidence" */
export type report_question_evidence_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_question_evidence_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "report_question_evidence" */
export type report_question_evidence_aggregate_order_by = {
  avg?: InputMaybe<report_question_evidence_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<report_question_evidence_max_order_by>;
  min?: InputMaybe<report_question_evidence_min_order_by>;
  stddev?: InputMaybe<report_question_evidence_stddev_order_by>;
  stddev_pop?: InputMaybe<report_question_evidence_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<report_question_evidence_stddev_samp_order_by>;
  sum?: InputMaybe<report_question_evidence_sum_order_by>;
  var_pop?: InputMaybe<report_question_evidence_var_pop_order_by>;
  var_samp?: InputMaybe<report_question_evidence_var_samp_order_by>;
  variance?: InputMaybe<report_question_evidence_variance_order_by>;
};

/** input type for inserting array relation for remote table "report_question_evidence" */
export type report_question_evidence_arr_rel_insert_input = {
  data: Array<report_question_evidence_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<report_question_evidence_on_conflict>;
};

/** aggregate avg on columns */
export type report_question_evidence_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  report_evidence_id?: Maybe<Scalars['Float']>;
  report_template_question_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "report_question_evidence" */
export type report_question_evidence_avg_order_by = {
  id?: InputMaybe<order_by>;
  report_evidence_id?: InputMaybe<order_by>;
  report_template_question_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "report_question_evidence". All fields are combined with a logical 'AND'. */
export type report_question_evidence_bool_exp = {
  _and?: InputMaybe<Array<report_question_evidence_bool_exp>>;
  _not?: InputMaybe<report_question_evidence_bool_exp>;
  _or?: InputMaybe<Array<report_question_evidence_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  description?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  report_evidence?: InputMaybe<report_evidence_bool_exp>;
  report_evidence_id?: InputMaybe<Int_comparison_exp>;
  report_template_question?: InputMaybe<report_template_question_bool_exp>;
  report_template_question_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

export { report_question_evidence_constraint };

/** input type for incrementing numeric columns in table "report_question_evidence" */
export type report_question_evidence_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  report_evidence_id?: InputMaybe<Scalars['Int']>;
  report_template_question_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "report_question_evidence" */
export type report_question_evidence_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  report_evidence?: InputMaybe<report_evidence_obj_rel_insert_input>;
  report_evidence_id?: InputMaybe<Scalars['Int']>;
  report_template_question?: InputMaybe<report_template_question_obj_rel_insert_input>;
  report_template_question_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type report_question_evidence_max_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  report_evidence_id?: Maybe<Scalars['Int']>;
  report_template_question_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "report_question_evidence" */
export type report_question_evidence_max_order_by = {
  created_at?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  report_evidence_id?: InputMaybe<order_by>;
  report_template_question_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type report_question_evidence_min_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  report_evidence_id?: Maybe<Scalars['Int']>;
  report_template_question_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "report_question_evidence" */
export type report_question_evidence_min_order_by = {
  created_at?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  report_evidence_id?: InputMaybe<order_by>;
  report_template_question_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "report_question_evidence" */
export type report_question_evidence_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<report_question_evidence>;
};

/** on_conflict condition type for table "report_question_evidence" */
export type report_question_evidence_on_conflict = {
  constraint: report_question_evidence_constraint;
  update_columns?: Array<report_question_evidence_update_column>;
  where?: InputMaybe<report_question_evidence_bool_exp>;
};

/** Ordering options when selecting data from "report_question_evidence". */
export type report_question_evidence_order_by = {
  created_at?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  report_evidence?: InputMaybe<report_evidence_order_by>;
  report_evidence_id?: InputMaybe<order_by>;
  report_template_question?: InputMaybe<report_template_question_order_by>;
  report_template_question_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: report_question_evidence */
export type report_question_evidence_pk_columns_input = {
  id: Scalars['Int'];
};

export { report_question_evidence_select_column };

/** input type for updating data in table "report_question_evidence" */
export type report_question_evidence_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  report_evidence_id?: InputMaybe<Scalars['Int']>;
  report_template_question_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type report_question_evidence_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  report_evidence_id?: Maybe<Scalars['Float']>;
  report_template_question_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "report_question_evidence" */
export type report_question_evidence_stddev_order_by = {
  id?: InputMaybe<order_by>;
  report_evidence_id?: InputMaybe<order_by>;
  report_template_question_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type report_question_evidence_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  report_evidence_id?: Maybe<Scalars['Float']>;
  report_template_question_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "report_question_evidence" */
export type report_question_evidence_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  report_evidence_id?: InputMaybe<order_by>;
  report_template_question_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type report_question_evidence_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  report_evidence_id?: Maybe<Scalars['Float']>;
  report_template_question_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "report_question_evidence" */
export type report_question_evidence_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  report_evidence_id?: InputMaybe<order_by>;
  report_template_question_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "report_question_evidence" */
export type report_question_evidence_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_question_evidence_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_question_evidence_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  report_evidence_id?: InputMaybe<Scalars['Int']>;
  report_template_question_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type report_question_evidence_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  report_evidence_id?: Maybe<Scalars['Int']>;
  report_template_question_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "report_question_evidence" */
export type report_question_evidence_sum_order_by = {
  id?: InputMaybe<order_by>;
  report_evidence_id?: InputMaybe<order_by>;
  report_template_question_id?: InputMaybe<order_by>;
};

export { report_question_evidence_update_column };

export type report_question_evidence_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<report_question_evidence_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<report_question_evidence_set_input>;
  /** filter the rows which have to be updated */
  where: report_question_evidence_bool_exp;
};

/** aggregate var_pop on columns */
export type report_question_evidence_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  report_evidence_id?: Maybe<Scalars['Float']>;
  report_template_question_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "report_question_evidence" */
export type report_question_evidence_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  report_evidence_id?: InputMaybe<order_by>;
  report_template_question_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type report_question_evidence_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  report_evidence_id?: Maybe<Scalars['Float']>;
  report_template_question_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "report_question_evidence" */
export type report_question_evidence_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  report_evidence_id?: InputMaybe<order_by>;
  report_template_question_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type report_question_evidence_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  report_evidence_id?: Maybe<Scalars['Float']>;
  report_template_question_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "report_question_evidence" */
export type report_question_evidence_variance_order_by = {
  id?: InputMaybe<order_by>;
  report_evidence_id?: InputMaybe<order_by>;
  report_template_question_id?: InputMaybe<order_by>;
};

/** A score for the report instance */
export type report_score = {
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  report: report;
  report_id: Scalars['Int'];
  /** An object relationship */
  report_template_score: report_template_score;
  report_template_score_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  value: Scalars['Float'];
};

/** aggregated selection of "report_score" */
export type report_score_aggregate = {
  aggregate?: Maybe<report_score_aggregate_fields>;
  nodes: Array<report_score>;
};

export type report_score_aggregate_bool_exp = {
  count?: InputMaybe<report_score_aggregate_bool_exp_count>;
};

export type report_score_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<report_score_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_score_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "report_score" */
export type report_score_aggregate_fields = {
  avg?: Maybe<report_score_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<report_score_max_fields>;
  min?: Maybe<report_score_min_fields>;
  stddev?: Maybe<report_score_stddev_fields>;
  stddev_pop?: Maybe<report_score_stddev_pop_fields>;
  stddev_samp?: Maybe<report_score_stddev_samp_fields>;
  sum?: Maybe<report_score_sum_fields>;
  var_pop?: Maybe<report_score_var_pop_fields>;
  var_samp?: Maybe<report_score_var_samp_fields>;
  variance?: Maybe<report_score_variance_fields>;
};

/** aggregate fields of "report_score" */
export type report_score_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_score_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "report_score" */
export type report_score_aggregate_order_by = {
  avg?: InputMaybe<report_score_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<report_score_max_order_by>;
  min?: InputMaybe<report_score_min_order_by>;
  stddev?: InputMaybe<report_score_stddev_order_by>;
  stddev_pop?: InputMaybe<report_score_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<report_score_stddev_samp_order_by>;
  sum?: InputMaybe<report_score_sum_order_by>;
  var_pop?: InputMaybe<report_score_var_pop_order_by>;
  var_samp?: InputMaybe<report_score_var_samp_order_by>;
  variance?: InputMaybe<report_score_variance_order_by>;
};

/** input type for inserting array relation for remote table "report_score" */
export type report_score_arr_rel_insert_input = {
  data: Array<report_score_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<report_score_on_conflict>;
};

/** aggregate avg on columns */
export type report_score_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
  report_template_score_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "report_score" */
export type report_score_avg_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_template_score_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "report_score". All fields are combined with a logical 'AND'. */
export type report_score_bool_exp = {
  _and?: InputMaybe<Array<report_score_bool_exp>>;
  _not?: InputMaybe<report_score_bool_exp>;
  _or?: InputMaybe<Array<report_score_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  report?: InputMaybe<report_bool_exp>;
  report_id?: InputMaybe<Int_comparison_exp>;
  report_template_score?: InputMaybe<report_template_score_bool_exp>;
  report_template_score_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  value?: InputMaybe<Float_comparison_exp>;
};

export { report_score_constraint };

/** input type for incrementing numeric columns in table "report_score" */
export type report_score_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  report_id?: InputMaybe<Scalars['Int']>;
  report_template_score_id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "report_score" */
export type report_score_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  report?: InputMaybe<report_obj_rel_insert_input>;
  report_id?: InputMaybe<Scalars['Int']>;
  report_template_score?: InputMaybe<report_template_score_obj_rel_insert_input>;
  report_template_score_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type report_score_max_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  report_id?: Maybe<Scalars['Int']>;
  report_template_score_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "report_score" */
export type report_score_max_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_template_score_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type report_score_min_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  report_id?: Maybe<Scalars['Int']>;
  report_template_score_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "report_score" */
export type report_score_min_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_template_score_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** response of any mutation on the table "report_score" */
export type report_score_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<report_score>;
};

/** on_conflict condition type for table "report_score" */
export type report_score_on_conflict = {
  constraint: report_score_constraint;
  update_columns?: Array<report_score_update_column>;
  where?: InputMaybe<report_score_bool_exp>;
};

/** Ordering options when selecting data from "report_score". */
export type report_score_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  report?: InputMaybe<report_order_by>;
  report_id?: InputMaybe<order_by>;
  report_template_score?: InputMaybe<report_template_score_order_by>;
  report_template_score_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: report_score */
export type report_score_pk_columns_input = {
  id: Scalars['Int'];
};

export { report_score_select_column };

/** input type for updating data in table "report_score" */
export type report_score_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  report_id?: InputMaybe<Scalars['Int']>;
  report_template_score_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type report_score_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
  report_template_score_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "report_score" */
export type report_score_stddev_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_template_score_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type report_score_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
  report_template_score_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "report_score" */
export type report_score_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_template_score_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type report_score_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
  report_template_score_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "report_score" */
export type report_score_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_template_score_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "report_score" */
export type report_score_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_score_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_score_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  report_id?: InputMaybe<Scalars['Int']>;
  report_template_score_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type report_score_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  report_id?: Maybe<Scalars['Int']>;
  report_template_score_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "report_score" */
export type report_score_sum_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_template_score_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

export { report_score_update_column };

export type report_score_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<report_score_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<report_score_set_input>;
  /** filter the rows which have to be updated */
  where: report_score_bool_exp;
};

/** aggregate var_pop on columns */
export type report_score_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
  report_template_score_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "report_score" */
export type report_score_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_template_score_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type report_score_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
  report_template_score_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "report_score" */
export type report_score_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_template_score_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type report_score_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
  report_template_score_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "report_score" */
export type report_score_variance_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_template_score_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** columns and relationships of "report_section_progress" */
export type report_section_progress = {
  id: Scalars['Int'];
  progress: Scalars['Float'];
  /** An object relationship */
  report: report;
  report_id: Scalars['Int'];
  /** An object relationship */
  report_template_section: report_template_section;
  report_template_section_id: Scalars['Int'];
  /** An object relationship */
  sub_building?: Maybe<sub_building>;
  sub_building_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "report_section_progress" */
export type report_section_progress_aggregate = {
  aggregate?: Maybe<report_section_progress_aggregate_fields>;
  nodes: Array<report_section_progress>;
};

export type report_section_progress_aggregate_bool_exp = {
  count?: InputMaybe<report_section_progress_aggregate_bool_exp_count>;
};

export type report_section_progress_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<report_section_progress_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_section_progress_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "report_section_progress" */
export type report_section_progress_aggregate_fields = {
  avg?: Maybe<report_section_progress_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<report_section_progress_max_fields>;
  min?: Maybe<report_section_progress_min_fields>;
  stddev?: Maybe<report_section_progress_stddev_fields>;
  stddev_pop?: Maybe<report_section_progress_stddev_pop_fields>;
  stddev_samp?: Maybe<report_section_progress_stddev_samp_fields>;
  sum?: Maybe<report_section_progress_sum_fields>;
  var_pop?: Maybe<report_section_progress_var_pop_fields>;
  var_samp?: Maybe<report_section_progress_var_samp_fields>;
  variance?: Maybe<report_section_progress_variance_fields>;
};

/** aggregate fields of "report_section_progress" */
export type report_section_progress_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_section_progress_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "report_section_progress" */
export type report_section_progress_aggregate_order_by = {
  avg?: InputMaybe<report_section_progress_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<report_section_progress_max_order_by>;
  min?: InputMaybe<report_section_progress_min_order_by>;
  stddev?: InputMaybe<report_section_progress_stddev_order_by>;
  stddev_pop?: InputMaybe<report_section_progress_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<report_section_progress_stddev_samp_order_by>;
  sum?: InputMaybe<report_section_progress_sum_order_by>;
  var_pop?: InputMaybe<report_section_progress_var_pop_order_by>;
  var_samp?: InputMaybe<report_section_progress_var_samp_order_by>;
  variance?: InputMaybe<report_section_progress_variance_order_by>;
};

/** input type for inserting array relation for remote table "report_section_progress" */
export type report_section_progress_arr_rel_insert_input = {
  data: Array<report_section_progress_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<report_section_progress_on_conflict>;
};

/** aggregate avg on columns */
export type report_section_progress_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
  report_template_section_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "report_section_progress" */
export type report_section_progress_avg_order_by = {
  id?: InputMaybe<order_by>;
  progress?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_template_section_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "report_section_progress". All fields are combined with a logical 'AND'. */
export type report_section_progress_bool_exp = {
  _and?: InputMaybe<Array<report_section_progress_bool_exp>>;
  _not?: InputMaybe<report_section_progress_bool_exp>;
  _or?: InputMaybe<Array<report_section_progress_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  progress?: InputMaybe<Float_comparison_exp>;
  report?: InputMaybe<report_bool_exp>;
  report_id?: InputMaybe<Int_comparison_exp>;
  report_template_section?: InputMaybe<report_template_section_bool_exp>;
  report_template_section_id?: InputMaybe<Int_comparison_exp>;
  sub_building?: InputMaybe<sub_building_bool_exp>;
  sub_building_id?: InputMaybe<Int_comparison_exp>;
};

export { report_section_progress_constraint };

/** input type for incrementing numeric columns in table "report_section_progress" */
export type report_section_progress_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  progress?: InputMaybe<Scalars['Float']>;
  report_id?: InputMaybe<Scalars['Int']>;
  report_template_section_id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "report_section_progress" */
export type report_section_progress_insert_input = {
  id?: InputMaybe<Scalars['Int']>;
  progress?: InputMaybe<Scalars['Float']>;
  report?: InputMaybe<report_obj_rel_insert_input>;
  report_id?: InputMaybe<Scalars['Int']>;
  report_template_section?: InputMaybe<report_template_section_obj_rel_insert_input>;
  report_template_section_id?: InputMaybe<Scalars['Int']>;
  sub_building?: InputMaybe<sub_building_obj_rel_insert_input>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type report_section_progress_max_fields = {
  id?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Int']>;
  report_template_section_id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "report_section_progress" */
export type report_section_progress_max_order_by = {
  id?: InputMaybe<order_by>;
  progress?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_template_section_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type report_section_progress_min_fields = {
  id?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Int']>;
  report_template_section_id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "report_section_progress" */
export type report_section_progress_min_order_by = {
  id?: InputMaybe<order_by>;
  progress?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_template_section_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "report_section_progress" */
export type report_section_progress_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<report_section_progress>;
};

/** on_conflict condition type for table "report_section_progress" */
export type report_section_progress_on_conflict = {
  constraint: report_section_progress_constraint;
  update_columns?: Array<report_section_progress_update_column>;
  where?: InputMaybe<report_section_progress_bool_exp>;
};

/** Ordering options when selecting data from "report_section_progress". */
export type report_section_progress_order_by = {
  id?: InputMaybe<order_by>;
  progress?: InputMaybe<order_by>;
  report?: InputMaybe<report_order_by>;
  report_id?: InputMaybe<order_by>;
  report_template_section?: InputMaybe<report_template_section_order_by>;
  report_template_section_id?: InputMaybe<order_by>;
  sub_building?: InputMaybe<sub_building_order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: report_section_progress */
export type report_section_progress_pk_columns_input = {
  id: Scalars['Int'];
};

export { report_section_progress_select_column };

/** input type for updating data in table "report_section_progress" */
export type report_section_progress_set_input = {
  id?: InputMaybe<Scalars['Int']>;
  progress?: InputMaybe<Scalars['Float']>;
  report_id?: InputMaybe<Scalars['Int']>;
  report_template_section_id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type report_section_progress_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
  report_template_section_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "report_section_progress" */
export type report_section_progress_stddev_order_by = {
  id?: InputMaybe<order_by>;
  progress?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_template_section_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type report_section_progress_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
  report_template_section_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "report_section_progress" */
export type report_section_progress_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  progress?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_template_section_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type report_section_progress_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
  report_template_section_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "report_section_progress" */
export type report_section_progress_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  progress?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_template_section_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "report_section_progress" */
export type report_section_progress_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_section_progress_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_section_progress_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']>;
  progress?: InputMaybe<Scalars['Float']>;
  report_id?: InputMaybe<Scalars['Int']>;
  report_template_section_id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type report_section_progress_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Int']>;
  report_template_section_id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "report_section_progress" */
export type report_section_progress_sum_order_by = {
  id?: InputMaybe<order_by>;
  progress?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_template_section_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

export { report_section_progress_update_column };

export type report_section_progress_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<report_section_progress_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<report_section_progress_set_input>;
  /** filter the rows which have to be updated */
  where: report_section_progress_bool_exp;
};

/** aggregate var_pop on columns */
export type report_section_progress_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
  report_template_section_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "report_section_progress" */
export type report_section_progress_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  progress?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_template_section_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type report_section_progress_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
  report_template_section_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "report_section_progress" */
export type report_section_progress_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  progress?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_template_section_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type report_section_progress_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  report_id?: Maybe<Scalars['Float']>;
  report_template_section_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "report_section_progress" */
export type report_section_progress_variance_order_by = {
  id?: InputMaybe<order_by>;
  progress?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  report_template_section_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

export { report_select_column };

/** input type for updating data in table "report" */
export type report_set_input = {
  completed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  due_date?: InputMaybe<Scalars['timestamptz']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
  progress?: InputMaybe<Scalars['Float']>;
  report_template_id?: InputMaybe<Scalars['Int']>;
  responsible_user_id?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type report_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
  responsible_user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type report_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
  responsible_user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type report_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
  responsible_user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "report" */
export type report_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_stream_cursor_value_input = {
  completed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  due_date?: InputMaybe<Scalars['timestamptz']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
  progress?: InputMaybe<Scalars['Float']>;
  report_template_id?: InputMaybe<Scalars['Int']>;
  responsible_user_id?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['String']>;
};

/** Maps reports to sub_buildings */
export type report_sub_building = {
  /** An object relationship */
  report: report;
  report_id: Scalars['Int'];
  /** An array relationship */
  report_sections_progress: Array<report_section_progress>;
  /** An aggregate relationship */
  report_sections_progress_aggregate: report_section_progress_aggregate;
  /** An object relationship */
  sub_building: sub_building;
  sub_building_id: Scalars['Int'];
};

/** Maps reports to sub_buildings */
export type report_sub_buildingreport_sections_progressArgs = {
  distinct_on?: InputMaybe<Array<report_section_progress_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_section_progress_order_by>>;
  where?: InputMaybe<report_section_progress_bool_exp>;
};

/** Maps reports to sub_buildings */
export type report_sub_buildingreport_sections_progress_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_section_progress_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_section_progress_order_by>>;
  where?: InputMaybe<report_section_progress_bool_exp>;
};

/** aggregated selection of "report_sub_building" */
export type report_sub_building_aggregate = {
  aggregate?: Maybe<report_sub_building_aggregate_fields>;
  nodes: Array<report_sub_building>;
};

export type report_sub_building_aggregate_bool_exp = {
  count?: InputMaybe<report_sub_building_aggregate_bool_exp_count>;
};

export type report_sub_building_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<report_sub_building_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_sub_building_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "report_sub_building" */
export type report_sub_building_aggregate_fields = {
  avg?: Maybe<report_sub_building_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<report_sub_building_max_fields>;
  min?: Maybe<report_sub_building_min_fields>;
  stddev?: Maybe<report_sub_building_stddev_fields>;
  stddev_pop?: Maybe<report_sub_building_stddev_pop_fields>;
  stddev_samp?: Maybe<report_sub_building_stddev_samp_fields>;
  sum?: Maybe<report_sub_building_sum_fields>;
  var_pop?: Maybe<report_sub_building_var_pop_fields>;
  var_samp?: Maybe<report_sub_building_var_samp_fields>;
  variance?: Maybe<report_sub_building_variance_fields>;
};

/** aggregate fields of "report_sub_building" */
export type report_sub_building_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_sub_building_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "report_sub_building" */
export type report_sub_building_aggregate_order_by = {
  avg?: InputMaybe<report_sub_building_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<report_sub_building_max_order_by>;
  min?: InputMaybe<report_sub_building_min_order_by>;
  stddev?: InputMaybe<report_sub_building_stddev_order_by>;
  stddev_pop?: InputMaybe<report_sub_building_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<report_sub_building_stddev_samp_order_by>;
  sum?: InputMaybe<report_sub_building_sum_order_by>;
  var_pop?: InputMaybe<report_sub_building_var_pop_order_by>;
  var_samp?: InputMaybe<report_sub_building_var_samp_order_by>;
  variance?: InputMaybe<report_sub_building_variance_order_by>;
};

/** input type for inserting array relation for remote table "report_sub_building" */
export type report_sub_building_arr_rel_insert_input = {
  data: Array<report_sub_building_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<report_sub_building_on_conflict>;
};

/** aggregate avg on columns */
export type report_sub_building_avg_fields = {
  report_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "report_sub_building" */
export type report_sub_building_avg_order_by = {
  report_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "report_sub_building". All fields are combined with a logical 'AND'. */
export type report_sub_building_bool_exp = {
  _and?: InputMaybe<Array<report_sub_building_bool_exp>>;
  _not?: InputMaybe<report_sub_building_bool_exp>;
  _or?: InputMaybe<Array<report_sub_building_bool_exp>>;
  report?: InputMaybe<report_bool_exp>;
  report_id?: InputMaybe<Int_comparison_exp>;
  report_sections_progress?: InputMaybe<report_section_progress_bool_exp>;
  report_sections_progress_aggregate?: InputMaybe<report_section_progress_aggregate_bool_exp>;
  sub_building?: InputMaybe<sub_building_bool_exp>;
  sub_building_id?: InputMaybe<Int_comparison_exp>;
};

export { report_sub_building_constraint };

/** input type for incrementing numeric columns in table "report_sub_building" */
export type report_sub_building_inc_input = {
  report_id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "report_sub_building" */
export type report_sub_building_insert_input = {
  report?: InputMaybe<report_obj_rel_insert_input>;
  report_id?: InputMaybe<Scalars['Int']>;
  report_sections_progress?: InputMaybe<report_section_progress_arr_rel_insert_input>;
  sub_building?: InputMaybe<sub_building_obj_rel_insert_input>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type report_sub_building_max_fields = {
  report_id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "report_sub_building" */
export type report_sub_building_max_order_by = {
  report_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type report_sub_building_min_fields = {
  report_id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "report_sub_building" */
export type report_sub_building_min_order_by = {
  report_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "report_sub_building" */
export type report_sub_building_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<report_sub_building>;
};

/** on_conflict condition type for table "report_sub_building" */
export type report_sub_building_on_conflict = {
  constraint: report_sub_building_constraint;
  update_columns?: Array<report_sub_building_update_column>;
  where?: InputMaybe<report_sub_building_bool_exp>;
};

/** Ordering options when selecting data from "report_sub_building". */
export type report_sub_building_order_by = {
  report?: InputMaybe<report_order_by>;
  report_id?: InputMaybe<order_by>;
  report_sections_progress_aggregate?: InputMaybe<report_section_progress_aggregate_order_by>;
  sub_building?: InputMaybe<sub_building_order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: report_sub_building */
export type report_sub_building_pk_columns_input = {
  report_id: Scalars['Int'];
  sub_building_id: Scalars['Int'];
};

export { report_sub_building_select_column };

/** input type for updating data in table "report_sub_building" */
export type report_sub_building_set_input = {
  report_id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type report_sub_building_stddev_fields = {
  report_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "report_sub_building" */
export type report_sub_building_stddev_order_by = {
  report_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type report_sub_building_stddev_pop_fields = {
  report_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "report_sub_building" */
export type report_sub_building_stddev_pop_order_by = {
  report_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type report_sub_building_stddev_samp_fields = {
  report_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "report_sub_building" */
export type report_sub_building_stddev_samp_order_by = {
  report_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "report_sub_building" */
export type report_sub_building_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_sub_building_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_sub_building_stream_cursor_value_input = {
  report_id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type report_sub_building_sum_fields = {
  report_id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "report_sub_building" */
export type report_sub_building_sum_order_by = {
  report_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

export { report_sub_building_update_column };

export type report_sub_building_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<report_sub_building_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<report_sub_building_set_input>;
  /** filter the rows which have to be updated */
  where: report_sub_building_bool_exp;
};

/** aggregate var_pop on columns */
export type report_sub_building_var_pop_fields = {
  report_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "report_sub_building" */
export type report_sub_building_var_pop_order_by = {
  report_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type report_sub_building_var_samp_fields = {
  report_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "report_sub_building" */
export type report_sub_building_var_samp_order_by = {
  report_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type report_sub_building_variance_fields = {
  report_id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "report_sub_building" */
export type report_sub_building_variance_order_by = {
  report_id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
};

/** aggregate sum on columns */
export type report_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Int']>;
  responsible_user_id?: Maybe<Scalars['Int']>;
};

/** Defines the metadata in a report. Report structures reference this table. */
export type report_template = {
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  excel_template_path?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  org_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  report_sections: Array<report_template_section>;
  /** An aggregate relationship */
  report_sections_aggregate: report_template_section_aggregate;
  /** An array relationship */
  report_template_scores: Array<report_template_score>;
  /** An aggregate relationship */
  report_template_scores_aggregate: report_template_score_aggregate;
  single_sub_building: Scalars['Boolean'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  version: Scalars['String'];
};

/** Defines the metadata in a report. Report structures reference this table. */
export type report_templatereport_sectionsArgs = {
  distinct_on?: InputMaybe<Array<report_template_section_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_section_order_by>>;
  where?: InputMaybe<report_template_section_bool_exp>;
};

/** Defines the metadata in a report. Report structures reference this table. */
export type report_templatereport_sections_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_section_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_section_order_by>>;
  where?: InputMaybe<report_template_section_bool_exp>;
};

/** Defines the metadata in a report. Report structures reference this table. */
export type report_templatereport_template_scoresArgs = {
  distinct_on?: InputMaybe<Array<report_template_score_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_score_order_by>>;
  where?: InputMaybe<report_template_score_bool_exp>;
};

/** Defines the metadata in a report. Report structures reference this table. */
export type report_templatereport_template_scores_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_score_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_score_order_by>>;
  where?: InputMaybe<report_template_score_bool_exp>;
};

/** aggregated selection of "report_template" */
export type report_template_aggregate = {
  aggregate?: Maybe<report_template_aggregate_fields>;
  nodes: Array<report_template>;
};

/** aggregate fields of "report_template" */
export type report_template_aggregate_fields = {
  avg?: Maybe<report_template_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<report_template_max_fields>;
  min?: Maybe<report_template_min_fields>;
  stddev?: Maybe<report_template_stddev_fields>;
  stddev_pop?: Maybe<report_template_stddev_pop_fields>;
  stddev_samp?: Maybe<report_template_stddev_samp_fields>;
  sum?: Maybe<report_template_sum_fields>;
  var_pop?: Maybe<report_template_var_pop_fields>;
  var_samp?: Maybe<report_template_var_samp_fields>;
  variance?: Maybe<report_template_variance_fields>;
};

/** aggregate fields of "report_template" */
export type report_template_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_template_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type report_template_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "report_template". All fields are combined with a logical 'AND'. */
export type report_template_bool_exp = {
  _and?: InputMaybe<Array<report_template_bool_exp>>;
  _not?: InputMaybe<report_template_bool_exp>;
  _or?: InputMaybe<Array<report_template_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  description?: InputMaybe<String_comparison_exp>;
  excel_template_path?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  org_id?: InputMaybe<Int_comparison_exp>;
  report_sections?: InputMaybe<report_template_section_bool_exp>;
  report_sections_aggregate?: InputMaybe<report_template_section_aggregate_bool_exp>;
  report_template_scores?: InputMaybe<report_template_score_bool_exp>;
  report_template_scores_aggregate?: InputMaybe<report_template_score_aggregate_bool_exp>;
  single_sub_building?: InputMaybe<Boolean_comparison_exp>;
  title?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  version?: InputMaybe<String_comparison_exp>;
};

export { report_template_constraint };

/** input type for incrementing numeric columns in table "report_template" */
export type report_template_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "report_template" */
export type report_template_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  excel_template_path?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
  report_sections?: InputMaybe<report_template_section_arr_rel_insert_input>;
  report_template_scores?: InputMaybe<report_template_score_arr_rel_insert_input>;
  single_sub_building?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type report_template_max_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  excel_template_path?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type report_template_min_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  excel_template_path?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "report_template" */
export type report_template_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<report_template>;
};

/** input type for inserting object relation for remote table "report_template" */
export type report_template_obj_rel_insert_input = {
  data: report_template_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<report_template_on_conflict>;
};

/** on_conflict condition type for table "report_template" */
export type report_template_on_conflict = {
  constraint: report_template_constraint;
  update_columns?: Array<report_template_update_column>;
  where?: InputMaybe<report_template_bool_exp>;
};

/** Ordering options when selecting data from "report_template". */
export type report_template_order_by = {
  created_at?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  excel_template_path?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  report_sections_aggregate?: InputMaybe<report_template_section_aggregate_order_by>;
  report_template_scores_aggregate?: InputMaybe<report_template_score_aggregate_order_by>;
  single_sub_building?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  version?: InputMaybe<order_by>;
};

/** primary key columns input for table: report_template */
export type report_template_pk_columns_input = {
  id: Scalars['Int'];
};

/** A report consists of multiple questions. Questions can either be attached to sections, or result from previous answers. */
export type report_template_question = {
  autofill?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  evidence_required?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  max_score?: Maybe<Scalars['float8']>;
  metadata?: Maybe<Scalars['jsonb']>;
  order: Scalars['Int'];
  parent_option_id?: Maybe<Scalars['Int']>;
  parent_section_id?: Maybe<Scalars['Int']>;
  question: Scalars['String'];
  reference?: Maybe<Scalars['String']>;
  /** An array relationship */
  report_answers: Array<report_answer>;
  /** An aggregate relationship */
  report_answers_aggregate: report_answer_aggregate;
  /** An object relationship */
  report_template_parent_option?: Maybe<report_template_question_option>;
  /** An array relationship */
  report_template_question_options: Array<report_template_question_option>;
  /** An aggregate relationship */
  report_template_question_options_aggregate: report_template_question_option_aggregate;
  /** An object relationship */
  report_template_section?: Maybe<report_template_section>;
  type: report_template_question_type_enum;
  updated_at: Scalars['timestamptz'];
};

/** A report consists of multiple questions. Questions can either be attached to sections, or result from previous answers. */
export type report_template_questionautofillArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** A report consists of multiple questions. Questions can either be attached to sections, or result from previous answers. */
export type report_template_questionmetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** A report consists of multiple questions. Questions can either be attached to sections, or result from previous answers. */
export type report_template_questionreport_answersArgs = {
  distinct_on?: InputMaybe<Array<report_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_order_by>>;
  where?: InputMaybe<report_answer_bool_exp>;
};

/** A report consists of multiple questions. Questions can either be attached to sections, or result from previous answers. */
export type report_template_questionreport_answers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_order_by>>;
  where?: InputMaybe<report_answer_bool_exp>;
};

/** A report consists of multiple questions. Questions can either be attached to sections, or result from previous answers. */
export type report_template_questionreport_template_question_optionsArgs = {
  distinct_on?: InputMaybe<Array<report_template_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_question_option_order_by>>;
  where?: InputMaybe<report_template_question_option_bool_exp>;
};

/** A report consists of multiple questions. Questions can either be attached to sections, or result from previous answers. */
export type report_template_questionreport_template_question_options_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_question_option_order_by>>;
  where?: InputMaybe<report_template_question_option_bool_exp>;
};

/** aggregated selection of "report_template_question" */
export type report_template_question_aggregate = {
  aggregate?: Maybe<report_template_question_aggregate_fields>;
  nodes: Array<report_template_question>;
};

export type report_template_question_aggregate_bool_exp = {
  avg?: InputMaybe<report_template_question_aggregate_bool_exp_avg>;
  bool_and?: InputMaybe<report_template_question_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<report_template_question_aggregate_bool_exp_bool_or>;
  corr?: InputMaybe<report_template_question_aggregate_bool_exp_corr>;
  count?: InputMaybe<report_template_question_aggregate_bool_exp_count>;
  covar_samp?: InputMaybe<report_template_question_aggregate_bool_exp_covar_samp>;
  max?: InputMaybe<report_template_question_aggregate_bool_exp_max>;
  min?: InputMaybe<report_template_question_aggregate_bool_exp_min>;
  stddev_samp?: InputMaybe<report_template_question_aggregate_bool_exp_stddev_samp>;
  sum?: InputMaybe<report_template_question_aggregate_bool_exp_sum>;
  var_samp?: InputMaybe<report_template_question_aggregate_bool_exp_var_samp>;
};

export type report_template_question_aggregate_bool_exp_avg = {
  arguments: report_template_question_select_column_report_template_question_aggregate_bool_exp_avg_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_question_bool_exp>;
  predicate: float8_comparison_exp;
};

export type report_template_question_aggregate_bool_exp_bool_and = {
  arguments: report_template_question_select_column_report_template_question_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_question_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type report_template_question_aggregate_bool_exp_bool_or = {
  arguments: report_template_question_select_column_report_template_question_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_question_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type report_template_question_aggregate_bool_exp_corr = {
  arguments: report_template_question_aggregate_bool_exp_corr_arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_question_bool_exp>;
  predicate: float8_comparison_exp;
};

export type report_template_question_aggregate_bool_exp_corr_arguments = {
  X: report_template_question_select_column_report_template_question_aggregate_bool_exp_corr_arguments_columns;
  Y: report_template_question_select_column_report_template_question_aggregate_bool_exp_corr_arguments_columns;
};

export type report_template_question_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<report_template_question_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_question_bool_exp>;
  predicate: Int_comparison_exp;
};

export type report_template_question_aggregate_bool_exp_covar_samp = {
  arguments: report_template_question_aggregate_bool_exp_covar_samp_arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_question_bool_exp>;
  predicate: float8_comparison_exp;
};

export type report_template_question_aggregate_bool_exp_covar_samp_arguments = {
  X: report_template_question_select_column_report_template_question_aggregate_bool_exp_covar_samp_arguments_columns;
  Y: report_template_question_select_column_report_template_question_aggregate_bool_exp_covar_samp_arguments_columns;
};

export type report_template_question_aggregate_bool_exp_max = {
  arguments: report_template_question_select_column_report_template_question_aggregate_bool_exp_max_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_question_bool_exp>;
  predicate: float8_comparison_exp;
};

export type report_template_question_aggregate_bool_exp_min = {
  arguments: report_template_question_select_column_report_template_question_aggregate_bool_exp_min_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_question_bool_exp>;
  predicate: float8_comparison_exp;
};

export type report_template_question_aggregate_bool_exp_stddev_samp = {
  arguments: report_template_question_select_column_report_template_question_aggregate_bool_exp_stddev_samp_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_question_bool_exp>;
  predicate: float8_comparison_exp;
};

export type report_template_question_aggregate_bool_exp_sum = {
  arguments: report_template_question_select_column_report_template_question_aggregate_bool_exp_sum_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_question_bool_exp>;
  predicate: float8_comparison_exp;
};

export type report_template_question_aggregate_bool_exp_var_samp = {
  arguments: report_template_question_select_column_report_template_question_aggregate_bool_exp_var_samp_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_question_bool_exp>;
  predicate: float8_comparison_exp;
};

/** aggregate fields of "report_template_question" */
export type report_template_question_aggregate_fields = {
  avg?: Maybe<report_template_question_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<report_template_question_max_fields>;
  min?: Maybe<report_template_question_min_fields>;
  stddev?: Maybe<report_template_question_stddev_fields>;
  stddev_pop?: Maybe<report_template_question_stddev_pop_fields>;
  stddev_samp?: Maybe<report_template_question_stddev_samp_fields>;
  sum?: Maybe<report_template_question_sum_fields>;
  var_pop?: Maybe<report_template_question_var_pop_fields>;
  var_samp?: Maybe<report_template_question_var_samp_fields>;
  variance?: Maybe<report_template_question_variance_fields>;
};

/** aggregate fields of "report_template_question" */
export type report_template_question_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_template_question_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "report_template_question" */
export type report_template_question_aggregate_order_by = {
  avg?: InputMaybe<report_template_question_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<report_template_question_max_order_by>;
  min?: InputMaybe<report_template_question_min_order_by>;
  stddev?: InputMaybe<report_template_question_stddev_order_by>;
  stddev_pop?: InputMaybe<report_template_question_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<report_template_question_stddev_samp_order_by>;
  sum?: InputMaybe<report_template_question_sum_order_by>;
  var_pop?: InputMaybe<report_template_question_var_pop_order_by>;
  var_samp?: InputMaybe<report_template_question_var_samp_order_by>;
  variance?: InputMaybe<report_template_question_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type report_template_question_append_input = {
  autofill?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "report_template_question" */
export type report_template_question_arr_rel_insert_input = {
  data: Array<report_template_question_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<report_template_question_on_conflict>;
};

/** aggregate avg on columns */
export type report_template_question_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  max_score?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  parent_option_id?: Maybe<Scalars['Float']>;
  parent_section_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "report_template_question" */
export type report_template_question_avg_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  parent_option_id?: InputMaybe<order_by>;
  parent_section_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "report_template_question". All fields are combined with a logical 'AND'. */
export type report_template_question_bool_exp = {
  _and?: InputMaybe<Array<report_template_question_bool_exp>>;
  _not?: InputMaybe<report_template_question_bool_exp>;
  _or?: InputMaybe<Array<report_template_question_bool_exp>>;
  autofill?: InputMaybe<jsonb_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  description?: InputMaybe<String_comparison_exp>;
  evidence_required?: InputMaybe<Boolean_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  max_score?: InputMaybe<float8_comparison_exp>;
  metadata?: InputMaybe<jsonb_comparison_exp>;
  order?: InputMaybe<Int_comparison_exp>;
  parent_option_id?: InputMaybe<Int_comparison_exp>;
  parent_section_id?: InputMaybe<Int_comparison_exp>;
  question?: InputMaybe<String_comparison_exp>;
  reference?: InputMaybe<String_comparison_exp>;
  report_answers?: InputMaybe<report_answer_bool_exp>;
  report_answers_aggregate?: InputMaybe<report_answer_aggregate_bool_exp>;
  report_template_parent_option?: InputMaybe<report_template_question_option_bool_exp>;
  report_template_question_options?: InputMaybe<report_template_question_option_bool_exp>;
  report_template_question_options_aggregate?: InputMaybe<report_template_question_option_aggregate_bool_exp>;
  report_template_section?: InputMaybe<report_template_section_bool_exp>;
  type?: InputMaybe<report_template_question_type_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

export { report_template_question_constraint };

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type report_template_question_delete_at_path_input = {
  autofill?: InputMaybe<Array<Scalars['String']>>;
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type report_template_question_delete_elem_input = {
  autofill?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type report_template_question_delete_key_input = {
  autofill?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "report_template_question" */
export type report_template_question_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  max_score?: InputMaybe<Scalars['float8']>;
  order?: InputMaybe<Scalars['Int']>;
  parent_option_id?: InputMaybe<Scalars['Int']>;
  parent_section_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "report_template_question" */
export type report_template_question_insert_input = {
  autofill?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  evidence_required?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  max_score?: InputMaybe<Scalars['float8']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  order?: InputMaybe<Scalars['Int']>;
  parent_option_id?: InputMaybe<Scalars['Int']>;
  parent_section_id?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
  report_answers?: InputMaybe<report_answer_arr_rel_insert_input>;
  report_template_parent_option?: InputMaybe<report_template_question_option_obj_rel_insert_input>;
  report_template_question_options?: InputMaybe<report_template_question_option_arr_rel_insert_input>;
  report_template_section?: InputMaybe<report_template_section_obj_rel_insert_input>;
  type?: InputMaybe<report_template_question_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type report_template_question_max_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  max_score?: Maybe<Scalars['float8']>;
  order?: Maybe<Scalars['Int']>;
  parent_option_id?: Maybe<Scalars['Int']>;
  parent_section_id?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "report_template_question" */
export type report_template_question_max_order_by = {
  created_at?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  parent_option_id?: InputMaybe<order_by>;
  parent_section_id?: InputMaybe<order_by>;
  question?: InputMaybe<order_by>;
  reference?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type report_template_question_min_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  max_score?: Maybe<Scalars['float8']>;
  order?: Maybe<Scalars['Int']>;
  parent_option_id?: Maybe<Scalars['Int']>;
  parent_section_id?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "report_template_question" */
export type report_template_question_min_order_by = {
  created_at?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  parent_option_id?: InputMaybe<order_by>;
  parent_section_id?: InputMaybe<order_by>;
  question?: InputMaybe<order_by>;
  reference?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "report_template_question" */
export type report_template_question_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<report_template_question>;
};

/** input type for inserting object relation for remote table "report_template_question" */
export type report_template_question_obj_rel_insert_input = {
  data: report_template_question_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<report_template_question_on_conflict>;
};

/** on_conflict condition type for table "report_template_question" */
export type report_template_question_on_conflict = {
  constraint: report_template_question_constraint;
  update_columns?: Array<report_template_question_update_column>;
  where?: InputMaybe<report_template_question_bool_exp>;
};

/** Options for multi/single choice questions */
export type report_template_question_option = {
  id: Scalars['Int'];
  max_score?: Maybe<Scalars['float8']>;
  order: Scalars['Int'];
  /** An array relationship */
  report_template_child_questions: Array<report_template_question>;
  /** An aggregate relationship */
  report_template_child_questions_aggregate: report_template_question_aggregate;
  /** An object relationship */
  report_template_question: report_template_question;
  report_template_question_id: Scalars['Int'];
  value: Scalars['String'];
};

/** Options for multi/single choice questions */
export type report_template_question_optionreport_template_child_questionsArgs = {
  distinct_on?: InputMaybe<Array<report_template_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_question_order_by>>;
  where?: InputMaybe<report_template_question_bool_exp>;
};

/** Options for multi/single choice questions */
export type report_template_question_optionreport_template_child_questions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_question_order_by>>;
  where?: InputMaybe<report_template_question_bool_exp>;
};

/** aggregated selection of "report_template_question_option" */
export type report_template_question_option_aggregate = {
  aggregate?: Maybe<report_template_question_option_aggregate_fields>;
  nodes: Array<report_template_question_option>;
};

export type report_template_question_option_aggregate_bool_exp = {
  avg?: InputMaybe<report_template_question_option_aggregate_bool_exp_avg>;
  corr?: InputMaybe<report_template_question_option_aggregate_bool_exp_corr>;
  count?: InputMaybe<report_template_question_option_aggregate_bool_exp_count>;
  covar_samp?: InputMaybe<report_template_question_option_aggregate_bool_exp_covar_samp>;
  max?: InputMaybe<report_template_question_option_aggregate_bool_exp_max>;
  min?: InputMaybe<report_template_question_option_aggregate_bool_exp_min>;
  stddev_samp?: InputMaybe<report_template_question_option_aggregate_bool_exp_stddev_samp>;
  sum?: InputMaybe<report_template_question_option_aggregate_bool_exp_sum>;
  var_samp?: InputMaybe<report_template_question_option_aggregate_bool_exp_var_samp>;
};

export type report_template_question_option_aggregate_bool_exp_avg = {
  arguments: report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_avg_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_question_option_bool_exp>;
  predicate: float8_comparison_exp;
};

export type report_template_question_option_aggregate_bool_exp_corr = {
  arguments: report_template_question_option_aggregate_bool_exp_corr_arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_question_option_bool_exp>;
  predicate: float8_comparison_exp;
};

export type report_template_question_option_aggregate_bool_exp_corr_arguments = {
  X: report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_corr_arguments_columns;
  Y: report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_corr_arguments_columns;
};

export type report_template_question_option_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<report_template_question_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_question_option_bool_exp>;
  predicate: Int_comparison_exp;
};

export type report_template_question_option_aggregate_bool_exp_covar_samp = {
  arguments: report_template_question_option_aggregate_bool_exp_covar_samp_arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_question_option_bool_exp>;
  predicate: float8_comparison_exp;
};

export type report_template_question_option_aggregate_bool_exp_covar_samp_arguments = {
  X: report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_covar_samp_arguments_columns;
  Y: report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_covar_samp_arguments_columns;
};

export type report_template_question_option_aggregate_bool_exp_max = {
  arguments: report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_max_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_question_option_bool_exp>;
  predicate: float8_comparison_exp;
};

export type report_template_question_option_aggregate_bool_exp_min = {
  arguments: report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_min_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_question_option_bool_exp>;
  predicate: float8_comparison_exp;
};

export type report_template_question_option_aggregate_bool_exp_stddev_samp = {
  arguments: report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_stddev_samp_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_question_option_bool_exp>;
  predicate: float8_comparison_exp;
};

export type report_template_question_option_aggregate_bool_exp_sum = {
  arguments: report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_sum_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_question_option_bool_exp>;
  predicate: float8_comparison_exp;
};

export type report_template_question_option_aggregate_bool_exp_var_samp = {
  arguments: report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_var_samp_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_question_option_bool_exp>;
  predicate: float8_comparison_exp;
};

/** aggregate fields of "report_template_question_option" */
export type report_template_question_option_aggregate_fields = {
  avg?: Maybe<report_template_question_option_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<report_template_question_option_max_fields>;
  min?: Maybe<report_template_question_option_min_fields>;
  stddev?: Maybe<report_template_question_option_stddev_fields>;
  stddev_pop?: Maybe<report_template_question_option_stddev_pop_fields>;
  stddev_samp?: Maybe<report_template_question_option_stddev_samp_fields>;
  sum?: Maybe<report_template_question_option_sum_fields>;
  var_pop?: Maybe<report_template_question_option_var_pop_fields>;
  var_samp?: Maybe<report_template_question_option_var_samp_fields>;
  variance?: Maybe<report_template_question_option_variance_fields>;
};

/** aggregate fields of "report_template_question_option" */
export type report_template_question_option_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_template_question_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "report_template_question_option" */
export type report_template_question_option_aggregate_order_by = {
  avg?: InputMaybe<report_template_question_option_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<report_template_question_option_max_order_by>;
  min?: InputMaybe<report_template_question_option_min_order_by>;
  stddev?: InputMaybe<report_template_question_option_stddev_order_by>;
  stddev_pop?: InputMaybe<report_template_question_option_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<report_template_question_option_stddev_samp_order_by>;
  sum?: InputMaybe<report_template_question_option_sum_order_by>;
  var_pop?: InputMaybe<report_template_question_option_var_pop_order_by>;
  var_samp?: InputMaybe<report_template_question_option_var_samp_order_by>;
  variance?: InputMaybe<report_template_question_option_variance_order_by>;
};

/** input type for inserting array relation for remote table "report_template_question_option" */
export type report_template_question_option_arr_rel_insert_input = {
  data: Array<report_template_question_option_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<report_template_question_option_on_conflict>;
};

/** aggregate avg on columns */
export type report_template_question_option_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  max_score?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  report_template_question_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "report_template_question_option" */
export type report_template_question_option_avg_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  report_template_question_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "report_template_question_option". All fields are combined with a logical 'AND'. */
export type report_template_question_option_bool_exp = {
  _and?: InputMaybe<Array<report_template_question_option_bool_exp>>;
  _not?: InputMaybe<report_template_question_option_bool_exp>;
  _or?: InputMaybe<Array<report_template_question_option_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  max_score?: InputMaybe<float8_comparison_exp>;
  order?: InputMaybe<Int_comparison_exp>;
  report_template_child_questions?: InputMaybe<report_template_question_bool_exp>;
  report_template_child_questions_aggregate?: InputMaybe<report_template_question_aggregate_bool_exp>;
  report_template_question?: InputMaybe<report_template_question_bool_exp>;
  report_template_question_id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

export { report_template_question_option_constraint };

/** input type for incrementing numeric columns in table "report_template_question_option" */
export type report_template_question_option_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  max_score?: InputMaybe<Scalars['float8']>;
  order?: InputMaybe<Scalars['Int']>;
  report_template_question_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "report_template_question_option" */
export type report_template_question_option_insert_input = {
  id?: InputMaybe<Scalars['Int']>;
  max_score?: InputMaybe<Scalars['float8']>;
  order?: InputMaybe<Scalars['Int']>;
  report_template_child_questions?: InputMaybe<report_template_question_arr_rel_insert_input>;
  report_template_question?: InputMaybe<report_template_question_obj_rel_insert_input>;
  report_template_question_id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type report_template_question_option_max_fields = {
  id?: Maybe<Scalars['Int']>;
  max_score?: Maybe<Scalars['float8']>;
  order?: Maybe<Scalars['Int']>;
  report_template_question_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "report_template_question_option" */
export type report_template_question_option_max_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  report_template_question_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type report_template_question_option_min_fields = {
  id?: Maybe<Scalars['Int']>;
  max_score?: Maybe<Scalars['float8']>;
  order?: Maybe<Scalars['Int']>;
  report_template_question_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "report_template_question_option" */
export type report_template_question_option_min_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  report_template_question_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** response of any mutation on the table "report_template_question_option" */
export type report_template_question_option_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<report_template_question_option>;
};

/** input type for inserting object relation for remote table "report_template_question_option" */
export type report_template_question_option_obj_rel_insert_input = {
  data: report_template_question_option_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<report_template_question_option_on_conflict>;
};

/** on_conflict condition type for table "report_template_question_option" */
export type report_template_question_option_on_conflict = {
  constraint: report_template_question_option_constraint;
  update_columns?: Array<report_template_question_option_update_column>;
  where?: InputMaybe<report_template_question_option_bool_exp>;
};

/** Ordering options when selecting data from "report_template_question_option". */
export type report_template_question_option_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  report_template_child_questions_aggregate?: InputMaybe<report_template_question_aggregate_order_by>;
  report_template_question?: InputMaybe<report_template_question_order_by>;
  report_template_question_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: report_template_question_option */
export type report_template_question_option_pk_columns_input = {
  id: Scalars['Int'];
};

export { report_template_question_option_select_column };

export { report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_avg_arguments_columns };

export { report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_corr_arguments_columns };

export { report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_covar_samp_arguments_columns };

export { report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_max_arguments_columns };

export { report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_min_arguments_columns };

export { report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_stddev_samp_arguments_columns };

export { report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_sum_arguments_columns };

export { report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_var_samp_arguments_columns };

/** input type for updating data in table "report_template_question_option" */
export type report_template_question_option_set_input = {
  id?: InputMaybe<Scalars['Int']>;
  max_score?: InputMaybe<Scalars['float8']>;
  order?: InputMaybe<Scalars['Int']>;
  report_template_question_id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type report_template_question_option_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  max_score?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  report_template_question_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "report_template_question_option" */
export type report_template_question_option_stddev_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  report_template_question_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type report_template_question_option_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  max_score?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  report_template_question_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "report_template_question_option" */
export type report_template_question_option_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  report_template_question_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type report_template_question_option_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  max_score?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  report_template_question_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "report_template_question_option" */
export type report_template_question_option_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  report_template_question_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "report_template_question_option" */
export type report_template_question_option_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_template_question_option_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_template_question_option_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']>;
  max_score?: InputMaybe<Scalars['float8']>;
  order?: InputMaybe<Scalars['Int']>;
  report_template_question_id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type report_template_question_option_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  max_score?: Maybe<Scalars['float8']>;
  order?: Maybe<Scalars['Int']>;
  report_template_question_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "report_template_question_option" */
export type report_template_question_option_sum_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  report_template_question_id?: InputMaybe<order_by>;
};

export { report_template_question_option_update_column };

export type report_template_question_option_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<report_template_question_option_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<report_template_question_option_set_input>;
  /** filter the rows which have to be updated */
  where: report_template_question_option_bool_exp;
};

/** aggregate var_pop on columns */
export type report_template_question_option_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  max_score?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  report_template_question_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "report_template_question_option" */
export type report_template_question_option_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  report_template_question_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type report_template_question_option_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  max_score?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  report_template_question_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "report_template_question_option" */
export type report_template_question_option_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  report_template_question_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type report_template_question_option_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  max_score?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  report_template_question_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "report_template_question_option" */
export type report_template_question_option_variance_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  report_template_question_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "report_template_question". */
export type report_template_question_order_by = {
  autofill?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  evidence_required?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  metadata?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  parent_option_id?: InputMaybe<order_by>;
  parent_section_id?: InputMaybe<order_by>;
  question?: InputMaybe<order_by>;
  reference?: InputMaybe<order_by>;
  report_answers_aggregate?: InputMaybe<report_answer_aggregate_order_by>;
  report_template_parent_option?: InputMaybe<report_template_question_option_order_by>;
  report_template_question_options_aggregate?: InputMaybe<report_template_question_option_aggregate_order_by>;
  report_template_section?: InputMaybe<report_template_section_order_by>;
  type?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: report_template_question */
export type report_template_question_pk_columns_input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type report_template_question_prepend_input = {
  autofill?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

export { report_template_question_select_column };

export { report_template_question_select_column_report_template_question_aggregate_bool_exp_avg_arguments_columns };

export { report_template_question_select_column_report_template_question_aggregate_bool_exp_bool_and_arguments_columns };

export { report_template_question_select_column_report_template_question_aggregate_bool_exp_bool_or_arguments_columns };

export { report_template_question_select_column_report_template_question_aggregate_bool_exp_corr_arguments_columns };

export { report_template_question_select_column_report_template_question_aggregate_bool_exp_covar_samp_arguments_columns };

export { report_template_question_select_column_report_template_question_aggregate_bool_exp_max_arguments_columns };

export { report_template_question_select_column_report_template_question_aggregate_bool_exp_min_arguments_columns };

export { report_template_question_select_column_report_template_question_aggregate_bool_exp_stddev_samp_arguments_columns };

export { report_template_question_select_column_report_template_question_aggregate_bool_exp_sum_arguments_columns };

export { report_template_question_select_column_report_template_question_aggregate_bool_exp_var_samp_arguments_columns };

/** input type for updating data in table "report_template_question" */
export type report_template_question_set_input = {
  autofill?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  evidence_required?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  max_score?: InputMaybe<Scalars['float8']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  order?: InputMaybe<Scalars['Int']>;
  parent_option_id?: InputMaybe<Scalars['Int']>;
  parent_section_id?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<report_template_question_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type report_template_question_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  max_score?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  parent_option_id?: Maybe<Scalars['Float']>;
  parent_section_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "report_template_question" */
export type report_template_question_stddev_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  parent_option_id?: InputMaybe<order_by>;
  parent_section_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type report_template_question_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  max_score?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  parent_option_id?: Maybe<Scalars['Float']>;
  parent_section_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "report_template_question" */
export type report_template_question_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  parent_option_id?: InputMaybe<order_by>;
  parent_section_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type report_template_question_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  max_score?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  parent_option_id?: Maybe<Scalars['Float']>;
  parent_section_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "report_template_question" */
export type report_template_question_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  parent_option_id?: InputMaybe<order_by>;
  parent_section_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "report_template_question" */
export type report_template_question_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_template_question_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_template_question_stream_cursor_value_input = {
  autofill?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  evidence_required?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  max_score?: InputMaybe<Scalars['float8']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  order?: InputMaybe<Scalars['Int']>;
  parent_option_id?: InputMaybe<Scalars['Int']>;
  parent_section_id?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<report_template_question_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type report_template_question_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  max_score?: Maybe<Scalars['float8']>;
  order?: Maybe<Scalars['Int']>;
  parent_option_id?: Maybe<Scalars['Int']>;
  parent_section_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "report_template_question" */
export type report_template_question_sum_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  parent_option_id?: InputMaybe<order_by>;
  parent_section_id?: InputMaybe<order_by>;
};

/** Supported question types */
export type report_template_question_type = {
  id: Scalars['String'];
};

/** aggregated selection of "report_template_question_type" */
export type report_template_question_type_aggregate = {
  aggregate?: Maybe<report_template_question_type_aggregate_fields>;
  nodes: Array<report_template_question_type>;
};

/** aggregate fields of "report_template_question_type" */
export type report_template_question_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<report_template_question_type_max_fields>;
  min?: Maybe<report_template_question_type_min_fields>;
};

/** aggregate fields of "report_template_question_type" */
export type report_template_question_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_template_question_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "report_template_question_type". All fields are combined with a logical 'AND'. */
export type report_template_question_type_bool_exp = {
  _and?: InputMaybe<Array<report_template_question_type_bool_exp>>;
  _not?: InputMaybe<report_template_question_type_bool_exp>;
  _or?: InputMaybe<Array<report_template_question_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { report_template_question_type_constraint };

export { report_template_question_type_enum };

/** Boolean expression to compare columns of type "report_template_question_type_enum". All fields are combined with logical 'AND'. */
export type report_template_question_type_enum_comparison_exp = {
  _eq?: InputMaybe<report_template_question_type_enum>;
  _in?: InputMaybe<Array<report_template_question_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<report_template_question_type_enum>;
  _nin?: InputMaybe<Array<report_template_question_type_enum>>;
};

/** input type for inserting data into table "report_template_question_type" */
export type report_template_question_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type report_template_question_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type report_template_question_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "report_template_question_type" */
export type report_template_question_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<report_template_question_type>;
};

/** on_conflict condition type for table "report_template_question_type" */
export type report_template_question_type_on_conflict = {
  constraint: report_template_question_type_constraint;
  update_columns?: Array<report_template_question_type_update_column>;
  where?: InputMaybe<report_template_question_type_bool_exp>;
};

/** Ordering options when selecting data from "report_template_question_type". */
export type report_template_question_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: report_template_question_type */
export type report_template_question_type_pk_columns_input = {
  id: Scalars['String'];
};

export { report_template_question_type_select_column };

/** input type for updating data in table "report_template_question_type" */
export type report_template_question_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "report_template_question_type" */
export type report_template_question_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_template_question_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_template_question_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { report_template_question_type_update_column };

export type report_template_question_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<report_template_question_type_set_input>;
  /** filter the rows which have to be updated */
  where: report_template_question_type_bool_exp;
};

export { report_template_question_update_column };

export type report_template_question_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<report_template_question_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<report_template_question_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<report_template_question_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<report_template_question_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<report_template_question_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<report_template_question_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<report_template_question_set_input>;
  /** filter the rows which have to be updated */
  where: report_template_question_bool_exp;
};

/** aggregate var_pop on columns */
export type report_template_question_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  max_score?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  parent_option_id?: Maybe<Scalars['Float']>;
  parent_section_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "report_template_question" */
export type report_template_question_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  parent_option_id?: InputMaybe<order_by>;
  parent_section_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type report_template_question_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  max_score?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  parent_option_id?: Maybe<Scalars['Float']>;
  parent_section_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "report_template_question" */
export type report_template_question_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  parent_option_id?: InputMaybe<order_by>;
  parent_section_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type report_template_question_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  max_score?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  parent_option_id?: Maybe<Scalars['Float']>;
  parent_section_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "report_template_question" */
export type report_template_question_variance_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  parent_option_id?: InputMaybe<order_by>;
  parent_section_id?: InputMaybe<order_by>;
};

/** Description of the scores included in a report template */
export type report_template_score = {
  created_at: Scalars['timestamptz'];
  display: Scalars['Boolean'];
  display_type: report_template_score_display_type_enum;
  id: Scalars['Int'];
  max_score?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  reference: Scalars['String'];
  /** An array relationship */
  report_scores: Array<report_score>;
  /** An aggregate relationship */
  report_scores_aggregate: report_score_aggregate;
  /** An object relationship */
  report_template: report_template;
  report_template_id: Scalars['Int'];
  resolver: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** Description of the scores included in a report template */
export type report_template_scorereport_scoresArgs = {
  distinct_on?: InputMaybe<Array<report_score_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_score_order_by>>;
  where?: InputMaybe<report_score_bool_exp>;
};

/** Description of the scores included in a report template */
export type report_template_scorereport_scores_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_score_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_score_order_by>>;
  where?: InputMaybe<report_score_bool_exp>;
};

/** aggregated selection of "report_template_score" */
export type report_template_score_aggregate = {
  aggregate?: Maybe<report_template_score_aggregate_fields>;
  nodes: Array<report_template_score>;
};

export type report_template_score_aggregate_bool_exp = {
  bool_and?: InputMaybe<report_template_score_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<report_template_score_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<report_template_score_aggregate_bool_exp_count>;
};

export type report_template_score_aggregate_bool_exp_bool_and = {
  arguments: report_template_score_select_column_report_template_score_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_score_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type report_template_score_aggregate_bool_exp_bool_or = {
  arguments: report_template_score_select_column_report_template_score_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_score_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type report_template_score_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<report_template_score_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_score_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "report_template_score" */
export type report_template_score_aggregate_fields = {
  avg?: Maybe<report_template_score_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<report_template_score_max_fields>;
  min?: Maybe<report_template_score_min_fields>;
  stddev?: Maybe<report_template_score_stddev_fields>;
  stddev_pop?: Maybe<report_template_score_stddev_pop_fields>;
  stddev_samp?: Maybe<report_template_score_stddev_samp_fields>;
  sum?: Maybe<report_template_score_sum_fields>;
  var_pop?: Maybe<report_template_score_var_pop_fields>;
  var_samp?: Maybe<report_template_score_var_samp_fields>;
  variance?: Maybe<report_template_score_variance_fields>;
};

/** aggregate fields of "report_template_score" */
export type report_template_score_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_template_score_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "report_template_score" */
export type report_template_score_aggregate_order_by = {
  avg?: InputMaybe<report_template_score_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<report_template_score_max_order_by>;
  min?: InputMaybe<report_template_score_min_order_by>;
  stddev?: InputMaybe<report_template_score_stddev_order_by>;
  stddev_pop?: InputMaybe<report_template_score_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<report_template_score_stddev_samp_order_by>;
  sum?: InputMaybe<report_template_score_sum_order_by>;
  var_pop?: InputMaybe<report_template_score_var_pop_order_by>;
  var_samp?: InputMaybe<report_template_score_var_samp_order_by>;
  variance?: InputMaybe<report_template_score_variance_order_by>;
};

/** input type for inserting array relation for remote table "report_template_score" */
export type report_template_score_arr_rel_insert_input = {
  data: Array<report_template_score_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<report_template_score_on_conflict>;
};

/** aggregate avg on columns */
export type report_template_score_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  max_score?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "report_template_score" */
export type report_template_score_avg_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  report_template_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "report_template_score". All fields are combined with a logical 'AND'. */
export type report_template_score_bool_exp = {
  _and?: InputMaybe<Array<report_template_score_bool_exp>>;
  _not?: InputMaybe<report_template_score_bool_exp>;
  _or?: InputMaybe<Array<report_template_score_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  display?: InputMaybe<Boolean_comparison_exp>;
  display_type?: InputMaybe<report_template_score_display_type_enum_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  max_score?: InputMaybe<Float_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  order?: InputMaybe<Int_comparison_exp>;
  reference?: InputMaybe<String_comparison_exp>;
  report_scores?: InputMaybe<report_score_bool_exp>;
  report_scores_aggregate?: InputMaybe<report_score_aggregate_bool_exp>;
  report_template?: InputMaybe<report_template_bool_exp>;
  report_template_id?: InputMaybe<Int_comparison_exp>;
  resolver?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

export { report_template_score_constraint };

/** columns and relationships of "report_template_score_display_type" */
export type report_template_score_display_type = {
  id: Scalars['String'];
};

/** aggregated selection of "report_template_score_display_type" */
export type report_template_score_display_type_aggregate = {
  aggregate?: Maybe<report_template_score_display_type_aggregate_fields>;
  nodes: Array<report_template_score_display_type>;
};

/** aggregate fields of "report_template_score_display_type" */
export type report_template_score_display_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<report_template_score_display_type_max_fields>;
  min?: Maybe<report_template_score_display_type_min_fields>;
};

/** aggregate fields of "report_template_score_display_type" */
export type report_template_score_display_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_template_score_display_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "report_template_score_display_type". All fields are combined with a logical 'AND'. */
export type report_template_score_display_type_bool_exp = {
  _and?: InputMaybe<Array<report_template_score_display_type_bool_exp>>;
  _not?: InputMaybe<report_template_score_display_type_bool_exp>;
  _or?: InputMaybe<Array<report_template_score_display_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { report_template_score_display_type_constraint };

export { report_template_score_display_type_enum };

/** Boolean expression to compare columns of type "report_template_score_display_type_enum". All fields are combined with logical 'AND'. */
export type report_template_score_display_type_enum_comparison_exp = {
  _eq?: InputMaybe<report_template_score_display_type_enum>;
  _in?: InputMaybe<Array<report_template_score_display_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<report_template_score_display_type_enum>;
  _nin?: InputMaybe<Array<report_template_score_display_type_enum>>;
};

/** input type for inserting data into table "report_template_score_display_type" */
export type report_template_score_display_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type report_template_score_display_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type report_template_score_display_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "report_template_score_display_type" */
export type report_template_score_display_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<report_template_score_display_type>;
};

/** on_conflict condition type for table "report_template_score_display_type" */
export type report_template_score_display_type_on_conflict = {
  constraint: report_template_score_display_type_constraint;
  update_columns?: Array<report_template_score_display_type_update_column>;
  where?: InputMaybe<report_template_score_display_type_bool_exp>;
};

/** Ordering options when selecting data from "report_template_score_display_type". */
export type report_template_score_display_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: report_template_score_display_type */
export type report_template_score_display_type_pk_columns_input = {
  id: Scalars['String'];
};

export { report_template_score_display_type_select_column };

/** input type for updating data in table "report_template_score_display_type" */
export type report_template_score_display_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "report_template_score_display_type" */
export type report_template_score_display_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_template_score_display_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_template_score_display_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { report_template_score_display_type_update_column };

export type report_template_score_display_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<report_template_score_display_type_set_input>;
  /** filter the rows which have to be updated */
  where: report_template_score_display_type_bool_exp;
};

/** input type for incrementing numeric columns in table "report_template_score" */
export type report_template_score_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  max_score?: InputMaybe<Scalars['Float']>;
  order?: InputMaybe<Scalars['Int']>;
  report_template_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "report_template_score" */
export type report_template_score_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  display?: InputMaybe<Scalars['Boolean']>;
  display_type?: InputMaybe<report_template_score_display_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  max_score?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  reference?: InputMaybe<Scalars['String']>;
  report_scores?: InputMaybe<report_score_arr_rel_insert_input>;
  report_template?: InputMaybe<report_template_obj_rel_insert_input>;
  report_template_id?: InputMaybe<Scalars['Int']>;
  resolver?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type report_template_score_max_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  max_score?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  report_template_id?: Maybe<Scalars['Int']>;
  resolver?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "report_template_score" */
export type report_template_score_max_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  reference?: InputMaybe<order_by>;
  report_template_id?: InputMaybe<order_by>;
  resolver?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type report_template_score_min_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  max_score?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  report_template_id?: Maybe<Scalars['Int']>;
  resolver?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "report_template_score" */
export type report_template_score_min_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  reference?: InputMaybe<order_by>;
  report_template_id?: InputMaybe<order_by>;
  resolver?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "report_template_score" */
export type report_template_score_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<report_template_score>;
};

/** input type for inserting object relation for remote table "report_template_score" */
export type report_template_score_obj_rel_insert_input = {
  data: report_template_score_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<report_template_score_on_conflict>;
};

/** on_conflict condition type for table "report_template_score" */
export type report_template_score_on_conflict = {
  constraint: report_template_score_constraint;
  update_columns?: Array<report_template_score_update_column>;
  where?: InputMaybe<report_template_score_bool_exp>;
};

/** Ordering options when selecting data from "report_template_score". */
export type report_template_score_order_by = {
  created_at?: InputMaybe<order_by>;
  display?: InputMaybe<order_by>;
  display_type?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  reference?: InputMaybe<order_by>;
  report_scores_aggregate?: InputMaybe<report_score_aggregate_order_by>;
  report_template?: InputMaybe<report_template_order_by>;
  report_template_id?: InputMaybe<order_by>;
  resolver?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: report_template_score */
export type report_template_score_pk_columns_input = {
  id: Scalars['Int'];
};

export { report_template_score_select_column };

export { report_template_score_select_column_report_template_score_aggregate_bool_exp_bool_and_arguments_columns };

export { report_template_score_select_column_report_template_score_aggregate_bool_exp_bool_or_arguments_columns };

/** input type for updating data in table "report_template_score" */
export type report_template_score_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  display?: InputMaybe<Scalars['Boolean']>;
  display_type?: InputMaybe<report_template_score_display_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  max_score?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  reference?: InputMaybe<Scalars['String']>;
  report_template_id?: InputMaybe<Scalars['Int']>;
  resolver?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type report_template_score_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  max_score?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "report_template_score" */
export type report_template_score_stddev_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  report_template_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type report_template_score_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  max_score?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "report_template_score" */
export type report_template_score_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  report_template_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type report_template_score_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  max_score?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "report_template_score" */
export type report_template_score_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  report_template_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "report_template_score" */
export type report_template_score_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_template_score_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_template_score_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  display?: InputMaybe<Scalars['Boolean']>;
  display_type?: InputMaybe<report_template_score_display_type_enum>;
  id?: InputMaybe<Scalars['Int']>;
  max_score?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  reference?: InputMaybe<Scalars['String']>;
  report_template_id?: InputMaybe<Scalars['Int']>;
  resolver?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type report_template_score_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  max_score?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Int']>;
  report_template_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "report_template_score" */
export type report_template_score_sum_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  report_template_id?: InputMaybe<order_by>;
};

export { report_template_score_update_column };

export type report_template_score_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<report_template_score_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<report_template_score_set_input>;
  /** filter the rows which have to be updated */
  where: report_template_score_bool_exp;
};

/** aggregate var_pop on columns */
export type report_template_score_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  max_score?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "report_template_score" */
export type report_template_score_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  report_template_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type report_template_score_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  max_score?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "report_template_score" */
export type report_template_score_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  report_template_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type report_template_score_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  max_score?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "report_template_score" */
export type report_template_score_variance_order_by = {
  id?: InputMaybe<order_by>;
  max_score?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  report_template_id?: InputMaybe<order_by>;
};

/** A report contains multiple sections. A section can have an arbitrary number of subsections with an arbitrary depth. Section structure is a tree (maximum one parent per section) */
export type report_template_section = {
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  order: Scalars['Int'];
  parent_section_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  report_template: report_template;
  report_template_id: Scalars['Int'];
  /** An object relationship */
  report_template_parent_section?: Maybe<report_template_section>;
  /** An array relationship */
  report_template_questions: Array<report_template_question>;
  /** An aggregate relationship */
  report_template_questions_aggregate: report_template_question_aggregate;
  /** An object relationship */
  report_template_section_target?: Maybe<report_template_section_target>;
  target?: Maybe<report_template_section_target_enum>;
  updated_at: Scalars['timestamptz'];
};

/** A report contains multiple sections. A section can have an arbitrary number of subsections with an arbitrary depth. Section structure is a tree (maximum one parent per section) */
export type report_template_sectionreport_template_questionsArgs = {
  distinct_on?: InputMaybe<Array<report_template_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_question_order_by>>;
  where?: InputMaybe<report_template_question_bool_exp>;
};

/** A report contains multiple sections. A section can have an arbitrary number of subsections with an arbitrary depth. Section structure is a tree (maximum one parent per section) */
export type report_template_sectionreport_template_questions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_question_order_by>>;
  where?: InputMaybe<report_template_question_bool_exp>;
};

/** aggregated selection of "report_template_section" */
export type report_template_section_aggregate = {
  aggregate?: Maybe<report_template_section_aggregate_fields>;
  nodes: Array<report_template_section>;
};

export type report_template_section_aggregate_bool_exp = {
  count?: InputMaybe<report_template_section_aggregate_bool_exp_count>;
};

export type report_template_section_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<report_template_section_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<report_template_section_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "report_template_section" */
export type report_template_section_aggregate_fields = {
  avg?: Maybe<report_template_section_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<report_template_section_max_fields>;
  min?: Maybe<report_template_section_min_fields>;
  stddev?: Maybe<report_template_section_stddev_fields>;
  stddev_pop?: Maybe<report_template_section_stddev_pop_fields>;
  stddev_samp?: Maybe<report_template_section_stddev_samp_fields>;
  sum?: Maybe<report_template_section_sum_fields>;
  var_pop?: Maybe<report_template_section_var_pop_fields>;
  var_samp?: Maybe<report_template_section_var_samp_fields>;
  variance?: Maybe<report_template_section_variance_fields>;
};

/** aggregate fields of "report_template_section" */
export type report_template_section_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_template_section_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "report_template_section" */
export type report_template_section_aggregate_order_by = {
  avg?: InputMaybe<report_template_section_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<report_template_section_max_order_by>;
  min?: InputMaybe<report_template_section_min_order_by>;
  stddev?: InputMaybe<report_template_section_stddev_order_by>;
  stddev_pop?: InputMaybe<report_template_section_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<report_template_section_stddev_samp_order_by>;
  sum?: InputMaybe<report_template_section_sum_order_by>;
  var_pop?: InputMaybe<report_template_section_var_pop_order_by>;
  var_samp?: InputMaybe<report_template_section_var_samp_order_by>;
  variance?: InputMaybe<report_template_section_variance_order_by>;
};

/** input type for inserting array relation for remote table "report_template_section" */
export type report_template_section_arr_rel_insert_input = {
  data: Array<report_template_section_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<report_template_section_on_conflict>;
};

/** aggregate avg on columns */
export type report_template_section_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  parent_section_id?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "report_template_section" */
export type report_template_section_avg_order_by = {
  id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  parent_section_id?: InputMaybe<order_by>;
  report_template_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "report_template_section". All fields are combined with a logical 'AND'. */
export type report_template_section_bool_exp = {
  _and?: InputMaybe<Array<report_template_section_bool_exp>>;
  _not?: InputMaybe<report_template_section_bool_exp>;
  _or?: InputMaybe<Array<report_template_section_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  order?: InputMaybe<Int_comparison_exp>;
  parent_section_id?: InputMaybe<Int_comparison_exp>;
  report_template?: InputMaybe<report_template_bool_exp>;
  report_template_id?: InputMaybe<Int_comparison_exp>;
  report_template_parent_section?: InputMaybe<report_template_section_bool_exp>;
  report_template_questions?: InputMaybe<report_template_question_bool_exp>;
  report_template_questions_aggregate?: InputMaybe<report_template_question_aggregate_bool_exp>;
  report_template_section_target?: InputMaybe<report_template_section_target_bool_exp>;
  target?: InputMaybe<report_template_section_target_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

export { report_template_section_constraint };

/** input type for incrementing numeric columns in table "report_template_section" */
export type report_template_section_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  parent_section_id?: InputMaybe<Scalars['Int']>;
  report_template_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "report_template_section" */
export type report_template_section_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  parent_section_id?: InputMaybe<Scalars['Int']>;
  report_template?: InputMaybe<report_template_obj_rel_insert_input>;
  report_template_id?: InputMaybe<Scalars['Int']>;
  report_template_parent_section?: InputMaybe<report_template_section_obj_rel_insert_input>;
  report_template_questions?: InputMaybe<report_template_question_arr_rel_insert_input>;
  report_template_section_target?: InputMaybe<report_template_section_target_obj_rel_insert_input>;
  target?: InputMaybe<report_template_section_target_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type report_template_section_max_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  parent_section_id?: Maybe<Scalars['Int']>;
  report_template_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "report_template_section" */
export type report_template_section_max_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  parent_section_id?: InputMaybe<order_by>;
  report_template_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type report_template_section_min_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  parent_section_id?: Maybe<Scalars['Int']>;
  report_template_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "report_template_section" */
export type report_template_section_min_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  parent_section_id?: InputMaybe<order_by>;
  report_template_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "report_template_section" */
export type report_template_section_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<report_template_section>;
};

/** input type for inserting object relation for remote table "report_template_section" */
export type report_template_section_obj_rel_insert_input = {
  data: report_template_section_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<report_template_section_on_conflict>;
};

/** on_conflict condition type for table "report_template_section" */
export type report_template_section_on_conflict = {
  constraint: report_template_section_constraint;
  update_columns?: Array<report_template_section_update_column>;
  where?: InputMaybe<report_template_section_bool_exp>;
};

/** Ordering options when selecting data from "report_template_section". */
export type report_template_section_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  parent_section_id?: InputMaybe<order_by>;
  report_template?: InputMaybe<report_template_order_by>;
  report_template_id?: InputMaybe<order_by>;
  report_template_parent_section?: InputMaybe<report_template_section_order_by>;
  report_template_questions_aggregate?: InputMaybe<report_template_question_aggregate_order_by>;
  report_template_section_target?: InputMaybe<report_template_section_target_order_by>;
  target?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: report_template_section */
export type report_template_section_pk_columns_input = {
  id: Scalars['Int'];
};

export { report_template_section_select_column };

/** input type for updating data in table "report_template_section" */
export type report_template_section_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  parent_section_id?: InputMaybe<Scalars['Int']>;
  report_template_id?: InputMaybe<Scalars['Int']>;
  target?: InputMaybe<report_template_section_target_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type report_template_section_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  parent_section_id?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "report_template_section" */
export type report_template_section_stddev_order_by = {
  id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  parent_section_id?: InputMaybe<order_by>;
  report_template_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type report_template_section_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  parent_section_id?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "report_template_section" */
export type report_template_section_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  parent_section_id?: InputMaybe<order_by>;
  report_template_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type report_template_section_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  parent_section_id?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "report_template_section" */
export type report_template_section_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  parent_section_id?: InputMaybe<order_by>;
  report_template_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "report_template_section" */
export type report_template_section_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_template_section_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_template_section_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  parent_section_id?: InputMaybe<Scalars['Int']>;
  report_template_id?: InputMaybe<Scalars['Int']>;
  target?: InputMaybe<report_template_section_target_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type report_template_section_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  parent_section_id?: Maybe<Scalars['Int']>;
  report_template_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "report_template_section" */
export type report_template_section_sum_order_by = {
  id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  parent_section_id?: InputMaybe<order_by>;
  report_template_id?: InputMaybe<order_by>;
};

/** A question can be about different entities. This enum table lists the possibilities */
export type report_template_section_target = {
  id: Scalars['String'];
};

/** aggregated selection of "report_template_section_target" */
export type report_template_section_target_aggregate = {
  aggregate?: Maybe<report_template_section_target_aggregate_fields>;
  nodes: Array<report_template_section_target>;
};

/** aggregate fields of "report_template_section_target" */
export type report_template_section_target_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<report_template_section_target_max_fields>;
  min?: Maybe<report_template_section_target_min_fields>;
};

/** aggregate fields of "report_template_section_target" */
export type report_template_section_target_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_template_section_target_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "report_template_section_target". All fields are combined with a logical 'AND'. */
export type report_template_section_target_bool_exp = {
  _and?: InputMaybe<Array<report_template_section_target_bool_exp>>;
  _not?: InputMaybe<report_template_section_target_bool_exp>;
  _or?: InputMaybe<Array<report_template_section_target_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { report_template_section_target_constraint };

export { report_template_section_target_enum };

/** Boolean expression to compare columns of type "report_template_section_target_enum". All fields are combined with logical 'AND'. */
export type report_template_section_target_enum_comparison_exp = {
  _eq?: InputMaybe<report_template_section_target_enum>;
  _in?: InputMaybe<Array<report_template_section_target_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<report_template_section_target_enum>;
  _nin?: InputMaybe<Array<report_template_section_target_enum>>;
};

/** input type for inserting data into table "report_template_section_target" */
export type report_template_section_target_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type report_template_section_target_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type report_template_section_target_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "report_template_section_target" */
export type report_template_section_target_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<report_template_section_target>;
};

/** input type for inserting object relation for remote table "report_template_section_target" */
export type report_template_section_target_obj_rel_insert_input = {
  data: report_template_section_target_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<report_template_section_target_on_conflict>;
};

/** on_conflict condition type for table "report_template_section_target" */
export type report_template_section_target_on_conflict = {
  constraint: report_template_section_target_constraint;
  update_columns?: Array<report_template_section_target_update_column>;
  where?: InputMaybe<report_template_section_target_bool_exp>;
};

/** Ordering options when selecting data from "report_template_section_target". */
export type report_template_section_target_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: report_template_section_target */
export type report_template_section_target_pk_columns_input = {
  id: Scalars['String'];
};

export { report_template_section_target_select_column };

/** input type for updating data in table "report_template_section_target" */
export type report_template_section_target_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "report_template_section_target" */
export type report_template_section_target_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_template_section_target_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_template_section_target_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { report_template_section_target_update_column };

export type report_template_section_target_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<report_template_section_target_set_input>;
  /** filter the rows which have to be updated */
  where: report_template_section_target_bool_exp;
};

export { report_template_section_update_column };

export type report_template_section_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<report_template_section_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<report_template_section_set_input>;
  /** filter the rows which have to be updated */
  where: report_template_section_bool_exp;
};

/** aggregate var_pop on columns */
export type report_template_section_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  parent_section_id?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "report_template_section" */
export type report_template_section_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  parent_section_id?: InputMaybe<order_by>;
  report_template_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type report_template_section_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  parent_section_id?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "report_template_section" */
export type report_template_section_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  parent_section_id?: InputMaybe<order_by>;
  report_template_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type report_template_section_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  parent_section_id?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "report_template_section" */
export type report_template_section_variance_order_by = {
  id?: InputMaybe<order_by>;
  order?: InputMaybe<order_by>;
  parent_section_id?: InputMaybe<order_by>;
  report_template_id?: InputMaybe<order_by>;
};

export { report_template_select_column };

/** input type for updating data in table "report_template" */
export type report_template_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  excel_template_path?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
  single_sub_building?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type report_template_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type report_template_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type report_template_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "report_template" */
export type report_template_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_template_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_template_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  excel_template_path?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
  single_sub_building?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type report_template_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "report_template_to_all_questions" */
export type report_template_to_all_questions = {
  question_id?: Maybe<Scalars['Int']>;
  report_template_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "report_template_to_all_questions" */
export type report_template_to_all_questions_aggregate = {
  aggregate?: Maybe<report_template_to_all_questions_aggregate_fields>;
  nodes: Array<report_template_to_all_questions>;
};

/** aggregate fields of "report_template_to_all_questions" */
export type report_template_to_all_questions_aggregate_fields = {
  avg?: Maybe<report_template_to_all_questions_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<report_template_to_all_questions_max_fields>;
  min?: Maybe<report_template_to_all_questions_min_fields>;
  stddev?: Maybe<report_template_to_all_questions_stddev_fields>;
  stddev_pop?: Maybe<report_template_to_all_questions_stddev_pop_fields>;
  stddev_samp?: Maybe<report_template_to_all_questions_stddev_samp_fields>;
  sum?: Maybe<report_template_to_all_questions_sum_fields>;
  var_pop?: Maybe<report_template_to_all_questions_var_pop_fields>;
  var_samp?: Maybe<report_template_to_all_questions_var_samp_fields>;
  variance?: Maybe<report_template_to_all_questions_variance_fields>;
};

/** aggregate fields of "report_template_to_all_questions" */
export type report_template_to_all_questions_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_template_to_all_questions_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type report_template_to_all_questions_avg_fields = {
  question_id?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "report_template_to_all_questions". All fields are combined with a logical 'AND'. */
export type report_template_to_all_questions_bool_exp = {
  _and?: InputMaybe<Array<report_template_to_all_questions_bool_exp>>;
  _not?: InputMaybe<report_template_to_all_questions_bool_exp>;
  _or?: InputMaybe<Array<report_template_to_all_questions_bool_exp>>;
  question_id?: InputMaybe<Int_comparison_exp>;
  report_template_id?: InputMaybe<Int_comparison_exp>;
};

/** aggregate max on columns */
export type report_template_to_all_questions_max_fields = {
  question_id?: Maybe<Scalars['Int']>;
  report_template_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type report_template_to_all_questions_min_fields = {
  question_id?: Maybe<Scalars['Int']>;
  report_template_id?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "report_template_to_all_questions". */
export type report_template_to_all_questions_order_by = {
  question_id?: InputMaybe<order_by>;
  report_template_id?: InputMaybe<order_by>;
};

export { report_template_to_all_questions_select_column };

/** aggregate stddev on columns */
export type report_template_to_all_questions_stddev_fields = {
  question_id?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type report_template_to_all_questions_stddev_pop_fields = {
  question_id?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type report_template_to_all_questions_stddev_samp_fields = {
  question_id?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "report_template_to_all_questions" */
export type report_template_to_all_questions_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_template_to_all_questions_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_template_to_all_questions_stream_cursor_value_input = {
  question_id?: InputMaybe<Scalars['Int']>;
  report_template_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type report_template_to_all_questions_sum_fields = {
  question_id?: Maybe<Scalars['Int']>;
  report_template_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type report_template_to_all_questions_var_pop_fields = {
  question_id?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type report_template_to_all_questions_var_samp_fields = {
  question_id?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type report_template_to_all_questions_variance_fields = {
  question_id?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
};

export { report_template_update_column };

export type report_template_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<report_template_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<report_template_set_input>;
  /** filter the rows which have to be updated */
  where: report_template_bool_exp;
};

/** aggregate var_pop on columns */
export type report_template_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type report_template_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type report_template_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

export { report_update_column };

export type report_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<report_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<report_set_input>;
  /** filter the rows which have to be updated */
  where: report_bool_exp;
};

/** aggregate var_pop on columns */
export type report_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
  responsible_user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type report_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
  responsible_user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type report_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  report_template_id?: Maybe<Scalars['Float']>;
  responsible_user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "role" */
export type role = {
  id: Scalars['String'];
};

/** aggregated selection of "role" */
export type role_aggregate = {
  aggregate?: Maybe<role_aggregate_fields>;
  nodes: Array<role>;
};

/** aggregate fields of "role" */
export type role_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<role_max_fields>;
  min?: Maybe<role_min_fields>;
};

/** aggregate fields of "role" */
export type role_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<role_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "role". All fields are combined with a logical 'AND'. */
export type role_bool_exp = {
  _and?: InputMaybe<Array<role_bool_exp>>;
  _not?: InputMaybe<role_bool_exp>;
  _or?: InputMaybe<Array<role_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { role_constraint };

export { role_enum };

/** Boolean expression to compare columns of type "role_enum". All fields are combined with logical 'AND'. */
export type role_enum_comparison_exp = {
  _eq?: InputMaybe<role_enum>;
  _in?: InputMaybe<Array<role_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<role_enum>;
  _nin?: InputMaybe<Array<role_enum>>;
};

/** input type for inserting data into table "role" */
export type role_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type role_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type role_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "role" */
export type role_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<role>;
};

/** on_conflict condition type for table "role" */
export type role_on_conflict = {
  constraint: role_constraint;
  update_columns?: Array<role_update_column>;
  where?: InputMaybe<role_bool_exp>;
};

/** Ordering options when selecting data from "role". */
export type role_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: role */
export type role_pk_columns_input = {
  id: Scalars['String'];
};

export { role_select_column };

/** input type for updating data in table "role" */
export type role_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "role" */
export type role_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: role_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type role_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { role_update_column };

export type role_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<role_set_input>;
  /** filter the rows which have to be updated */
  where: role_bool_exp;
};

/** columns and relationships of "roof_type" */
export type roof_type = {
  id: Scalars['String'];
};

/** aggregated selection of "roof_type" */
export type roof_type_aggregate = {
  aggregate?: Maybe<roof_type_aggregate_fields>;
  nodes: Array<roof_type>;
};

/** aggregate fields of "roof_type" */
export type roof_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<roof_type_max_fields>;
  min?: Maybe<roof_type_min_fields>;
};

/** aggregate fields of "roof_type" */
export type roof_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<roof_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "roof_type". All fields are combined with a logical 'AND'. */
export type roof_type_bool_exp = {
  _and?: InputMaybe<Array<roof_type_bool_exp>>;
  _not?: InputMaybe<roof_type_bool_exp>;
  _or?: InputMaybe<Array<roof_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { roof_type_constraint };

/** input type for inserting data into table "roof_type" */
export type roof_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type roof_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type roof_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "roof_type" */
export type roof_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<roof_type>;
};

/** on_conflict condition type for table "roof_type" */
export type roof_type_on_conflict = {
  constraint: roof_type_constraint;
  update_columns?: Array<roof_type_update_column>;
  where?: InputMaybe<roof_type_bool_exp>;
};

/** Ordering options when selecting data from "roof_type". */
export type roof_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: roof_type */
export type roof_type_pk_columns_input = {
  id: Scalars['String'];
};

export { roof_type_select_column };

/** input type for updating data in table "roof_type" */
export type roof_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "roof_type" */
export type roof_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: roof_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type roof_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { roof_type_update_column };

export type roof_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<roof_type_set_input>;
  /** filter the rows which have to be updated */
  where: roof_type_bool_exp;
};

/** columns and relationships of "scenario" */
export type scenario = {
  /** An array relationship */
  action_plans: Array<action_plan>;
  /** An aggregate relationship */
  action_plans_aggregate: action_plan_aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  org: org;
  org_id: Scalars['Int'];
  /** An object relationship */
  owner: user;
  owner_id: Scalars['Int'];
  /** An object relationship */
  portfolio: portfolio;
  portfolio_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "scenario" */
export type scenarioaction_plansArgs = {
  distinct_on?: InputMaybe<Array<action_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_plan_order_by>>;
  where?: InputMaybe<action_plan_bool_exp>;
};

/** columns and relationships of "scenario" */
export type scenarioaction_plans_aggregateArgs = {
  distinct_on?: InputMaybe<Array<action_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_plan_order_by>>;
  where?: InputMaybe<action_plan_bool_exp>;
};

/** aggregated selection of "scenario" */
export type scenario_aggregate = {
  aggregate?: Maybe<scenario_aggregate_fields>;
  nodes: Array<scenario>;
};

/** aggregate fields of "scenario" */
export type scenario_aggregate_fields = {
  avg?: Maybe<scenario_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<scenario_max_fields>;
  min?: Maybe<scenario_min_fields>;
  stddev?: Maybe<scenario_stddev_fields>;
  stddev_pop?: Maybe<scenario_stddev_pop_fields>;
  stddev_samp?: Maybe<scenario_stddev_samp_fields>;
  sum?: Maybe<scenario_sum_fields>;
  var_pop?: Maybe<scenario_var_pop_fields>;
  var_samp?: Maybe<scenario_var_samp_fields>;
  variance?: Maybe<scenario_variance_fields>;
};

/** aggregate fields of "scenario" */
export type scenario_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<scenario_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type scenario_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "scenario". All fields are combined with a logical 'AND'. */
export type scenario_bool_exp = {
  _and?: InputMaybe<Array<scenario_bool_exp>>;
  _not?: InputMaybe<scenario_bool_exp>;
  _or?: InputMaybe<Array<scenario_bool_exp>>;
  action_plans?: InputMaybe<action_plan_bool_exp>;
  action_plans_aggregate?: InputMaybe<action_plan_aggregate_bool_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  description?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  org?: InputMaybe<org_bool_exp>;
  org_id?: InputMaybe<Int_comparison_exp>;
  owner?: InputMaybe<user_bool_exp>;
  owner_id?: InputMaybe<Int_comparison_exp>;
  portfolio?: InputMaybe<portfolio_bool_exp>;
  portfolio_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

export { scenario_constraint };

/** input type for incrementing numeric columns in table "scenario" */
export type scenario_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  portfolio_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "scenario" */
export type scenario_insert_input = {
  action_plans?: InputMaybe<action_plan_arr_rel_insert_input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  org?: InputMaybe<org_obj_rel_insert_input>;
  org_id?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<user_obj_rel_insert_input>;
  owner_id?: InputMaybe<Scalars['Int']>;
  portfolio?: InputMaybe<portfolio_obj_rel_insert_input>;
  portfolio_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type scenario_max_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['Int']>;
  portfolio_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type scenario_min_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['Int']>;
  portfolio_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "scenario" */
export type scenario_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<scenario>;
};

/** input type for inserting object relation for remote table "scenario" */
export type scenario_obj_rel_insert_input = {
  data: scenario_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<scenario_on_conflict>;
};

/** on_conflict condition type for table "scenario" */
export type scenario_on_conflict = {
  constraint: scenario_constraint;
  update_columns?: Array<scenario_update_column>;
  where?: InputMaybe<scenario_bool_exp>;
};

/** Ordering options when selecting data from "scenario". */
export type scenario_order_by = {
  action_plans_aggregate?: InputMaybe<action_plan_aggregate_order_by>;
  created_at?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  org?: InputMaybe<org_order_by>;
  org_id?: InputMaybe<order_by>;
  owner?: InputMaybe<user_order_by>;
  owner_id?: InputMaybe<order_by>;
  portfolio?: InputMaybe<portfolio_order_by>;
  portfolio_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: scenario */
export type scenario_pk_columns_input = {
  id: Scalars['Int'];
};

export { scenario_select_column };

/** input type for updating data in table "scenario" */
export type scenario_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['Int']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  portfolio_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type scenario_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type scenario_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type scenario_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "scenario" */
export type scenario_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: scenario_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type scenario_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['Int']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  portfolio_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type scenario_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['Int']>;
  portfolio_id?: Maybe<Scalars['Int']>;
};

export { scenario_update_column };

export type scenario_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<scenario_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<scenario_set_input>;
  /** filter the rows which have to be updated */
  where: scenario_bool_exp;
};

/** aggregate var_pop on columns */
export type scenario_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type scenario_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type scenario_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "sub_building" */
export type sub_building = {
  /** An object relationship */
  building: building;
  building_id: Scalars['Int'];
  /** An array relationship */
  consumption_drafts: Array<consumption_draft>;
  /** An aggregate relationship */
  consumption_drafts_aggregate: consumption_draft_aggregate;
  /** An array relationship */
  consumptions: Array<consumption>;
  /** An aggregate relationship */
  consumptions_aggregate: consumption_aggregate;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  energy_certificates: Array<energy_certificate>;
  /** An aggregate relationship */
  energy_certificates_aggregate: energy_certificate_aggregate;
  /** An array relationship */
  energy_consumers: Array<energy_consumer>;
  /** An aggregate relationship */
  energy_consumers_aggregate: energy_consumer_aggregate;
  /** An array relationship */
  energy_producers: Array<energy_producer>;
  /** An aggregate relationship */
  energy_producers_aggregate: energy_producer_aggregate;
  /** An array relationship */
  energy_sources: Array<energy_source>;
  /** An aggregate relationship */
  energy_sources_aggregate: energy_source_aggregate;
  energy_standard: energy_standard_enum;
  id: Scalars['Int'];
  operation_model: operation_model_type_enum;
  /** An array relationship */
  report_answers: Array<report_answer>;
  /** An aggregate relationship */
  report_answers_aggregate: report_answer_aggregate;
  /** An array relationship */
  report_sub_buildings: Array<report_sub_building>;
  /** An aggregate relationship */
  report_sub_buildings_aggregate: report_sub_building_aggregate;
  sub_building_class: sub_building_class_enum;
  /** An array relationship */
  subsidies: Array<sub_building_subsidy>;
  /** An aggregate relationship */
  subsidies_aggregate: sub_building_subsidy_aggregate;
  subsidies_updated_at?: Maybe<Scalars['timestamptz']>;
  type_of_use_id: type_of_use_enum;
  units_commercial: Scalars['Int'];
  units_residential: Scalars['Int'];
  updated_at: Scalars['timestamp'];
  year_renovated?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "sub_building" */
export type sub_buildingconsumption_draftsArgs = {
  distinct_on?: InputMaybe<Array<consumption_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_draft_order_by>>;
  where?: InputMaybe<consumption_draft_bool_exp>;
};

/** columns and relationships of "sub_building" */
export type sub_buildingconsumption_drafts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_draft_order_by>>;
  where?: InputMaybe<consumption_draft_bool_exp>;
};

/** columns and relationships of "sub_building" */
export type sub_buildingconsumptionsArgs = {
  distinct_on?: InputMaybe<Array<consumption_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_order_by>>;
  where?: InputMaybe<consumption_bool_exp>;
};

/** columns and relationships of "sub_building" */
export type sub_buildingconsumptions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_order_by>>;
  where?: InputMaybe<consumption_bool_exp>;
};

/** columns and relationships of "sub_building" */
export type sub_buildingenergy_certificatesArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_order_by>>;
  where?: InputMaybe<energy_certificate_bool_exp>;
};

/** columns and relationships of "sub_building" */
export type sub_buildingenergy_certificates_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_order_by>>;
  where?: InputMaybe<energy_certificate_bool_exp>;
};

/** columns and relationships of "sub_building" */
export type sub_buildingenergy_consumersArgs = {
  distinct_on?: InputMaybe<Array<energy_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_consumer_order_by>>;
  where?: InputMaybe<energy_consumer_bool_exp>;
};

/** columns and relationships of "sub_building" */
export type sub_buildingenergy_consumers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_consumer_order_by>>;
  where?: InputMaybe<energy_consumer_bool_exp>;
};

/** columns and relationships of "sub_building" */
export type sub_buildingenergy_producersArgs = {
  distinct_on?: InputMaybe<Array<energy_producer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_producer_order_by>>;
  where?: InputMaybe<energy_producer_bool_exp>;
};

/** columns and relationships of "sub_building" */
export type sub_buildingenergy_producers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_producer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_producer_order_by>>;
  where?: InputMaybe<energy_producer_bool_exp>;
};

/** columns and relationships of "sub_building" */
export type sub_buildingenergy_sourcesArgs = {
  distinct_on?: InputMaybe<Array<energy_source_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_source_order_by>>;
  where?: InputMaybe<energy_source_bool_exp>;
};

/** columns and relationships of "sub_building" */
export type sub_buildingenergy_sources_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_source_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_source_order_by>>;
  where?: InputMaybe<energy_source_bool_exp>;
};

/** columns and relationships of "sub_building" */
export type sub_buildingreport_answersArgs = {
  distinct_on?: InputMaybe<Array<report_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_order_by>>;
  where?: InputMaybe<report_answer_bool_exp>;
};

/** columns and relationships of "sub_building" */
export type sub_buildingreport_answers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_order_by>>;
  where?: InputMaybe<report_answer_bool_exp>;
};

/** columns and relationships of "sub_building" */
export type sub_buildingreport_sub_buildingsArgs = {
  distinct_on?: InputMaybe<Array<report_sub_building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_sub_building_order_by>>;
  where?: InputMaybe<report_sub_building_bool_exp>;
};

/** columns and relationships of "sub_building" */
export type sub_buildingreport_sub_buildings_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_sub_building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_sub_building_order_by>>;
  where?: InputMaybe<report_sub_building_bool_exp>;
};

/** columns and relationships of "sub_building" */
export type sub_buildingsubsidiesArgs = {
  distinct_on?: InputMaybe<Array<sub_building_subsidy_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<sub_building_subsidy_order_by>>;
  where?: InputMaybe<sub_building_subsidy_bool_exp>;
};

/** columns and relationships of "sub_building" */
export type sub_buildingsubsidies_aggregateArgs = {
  distinct_on?: InputMaybe<Array<sub_building_subsidy_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<sub_building_subsidy_order_by>>;
  where?: InputMaybe<sub_building_subsidy_bool_exp>;
};

/** aggregated selection of "sub_building" */
export type sub_building_aggregate = {
  aggregate?: Maybe<sub_building_aggregate_fields>;
  nodes: Array<sub_building>;
};

export type sub_building_aggregate_bool_exp = {
  count?: InputMaybe<sub_building_aggregate_bool_exp_count>;
};

export type sub_building_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<sub_building_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<sub_building_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "sub_building" */
export type sub_building_aggregate_fields = {
  avg?: Maybe<sub_building_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<sub_building_max_fields>;
  min?: Maybe<sub_building_min_fields>;
  stddev?: Maybe<sub_building_stddev_fields>;
  stddev_pop?: Maybe<sub_building_stddev_pop_fields>;
  stddev_samp?: Maybe<sub_building_stddev_samp_fields>;
  sum?: Maybe<sub_building_sum_fields>;
  var_pop?: Maybe<sub_building_var_pop_fields>;
  var_samp?: Maybe<sub_building_var_samp_fields>;
  variance?: Maybe<sub_building_variance_fields>;
};

/** aggregate fields of "sub_building" */
export type sub_building_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<sub_building_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sub_building" */
export type sub_building_aggregate_order_by = {
  avg?: InputMaybe<sub_building_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<sub_building_max_order_by>;
  min?: InputMaybe<sub_building_min_order_by>;
  stddev?: InputMaybe<sub_building_stddev_order_by>;
  stddev_pop?: InputMaybe<sub_building_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<sub_building_stddev_samp_order_by>;
  sum?: InputMaybe<sub_building_sum_order_by>;
  var_pop?: InputMaybe<sub_building_var_pop_order_by>;
  var_samp?: InputMaybe<sub_building_var_samp_order_by>;
  variance?: InputMaybe<sub_building_variance_order_by>;
};

/** input type for inserting array relation for remote table "sub_building" */
export type sub_building_arr_rel_insert_input = {
  data: Array<sub_building_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<sub_building_on_conflict>;
};

/** aggregate avg on columns */
export type sub_building_avg_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  units_commercial?: Maybe<Scalars['Float']>;
  units_residential?: Maybe<Scalars['Float']>;
  year_renovated?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sub_building" */
export type sub_building_avg_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  units_commercial?: InputMaybe<order_by>;
  units_residential?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "sub_building". All fields are combined with a logical 'AND'. */
export type sub_building_bool_exp = {
  _and?: InputMaybe<Array<sub_building_bool_exp>>;
  _not?: InputMaybe<sub_building_bool_exp>;
  _or?: InputMaybe<Array<sub_building_bool_exp>>;
  building?: InputMaybe<building_bool_exp>;
  building_id?: InputMaybe<Int_comparison_exp>;
  consumption_drafts?: InputMaybe<consumption_draft_bool_exp>;
  consumption_drafts_aggregate?: InputMaybe<consumption_draft_aggregate_bool_exp>;
  consumptions?: InputMaybe<consumption_bool_exp>;
  consumptions_aggregate?: InputMaybe<consumption_aggregate_bool_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  energy_certificates?: InputMaybe<energy_certificate_bool_exp>;
  energy_certificates_aggregate?: InputMaybe<energy_certificate_aggregate_bool_exp>;
  energy_consumers?: InputMaybe<energy_consumer_bool_exp>;
  energy_consumers_aggregate?: InputMaybe<energy_consumer_aggregate_bool_exp>;
  energy_producers?: InputMaybe<energy_producer_bool_exp>;
  energy_producers_aggregate?: InputMaybe<energy_producer_aggregate_bool_exp>;
  energy_sources?: InputMaybe<energy_source_bool_exp>;
  energy_sources_aggregate?: InputMaybe<energy_source_aggregate_bool_exp>;
  energy_standard?: InputMaybe<energy_standard_enum_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  operation_model?: InputMaybe<operation_model_type_enum_comparison_exp>;
  report_answers?: InputMaybe<report_answer_bool_exp>;
  report_answers_aggregate?: InputMaybe<report_answer_aggregate_bool_exp>;
  report_sub_buildings?: InputMaybe<report_sub_building_bool_exp>;
  report_sub_buildings_aggregate?: InputMaybe<report_sub_building_aggregate_bool_exp>;
  sub_building_class?: InputMaybe<sub_building_class_enum_comparison_exp>;
  subsidies?: InputMaybe<sub_building_subsidy_bool_exp>;
  subsidies_aggregate?: InputMaybe<sub_building_subsidy_aggregate_bool_exp>;
  subsidies_updated_at?: InputMaybe<timestamptz_comparison_exp>;
  type_of_use_id?: InputMaybe<type_of_use_enum_comparison_exp>;
  units_commercial?: InputMaybe<Int_comparison_exp>;
  units_residential?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamp_comparison_exp>;
  year_renovated?: InputMaybe<Int_comparison_exp>;
};

/** columns and relationships of "sub_building_class" */
export type sub_building_class = {
  id: Scalars['String'];
};

/** aggregated selection of "sub_building_class" */
export type sub_building_class_aggregate = {
  aggregate?: Maybe<sub_building_class_aggregate_fields>;
  nodes: Array<sub_building_class>;
};

/** aggregate fields of "sub_building_class" */
export type sub_building_class_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<sub_building_class_max_fields>;
  min?: Maybe<sub_building_class_min_fields>;
};

/** aggregate fields of "sub_building_class" */
export type sub_building_class_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<sub_building_class_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "sub_building_class". All fields are combined with a logical 'AND'. */
export type sub_building_class_bool_exp = {
  _and?: InputMaybe<Array<sub_building_class_bool_exp>>;
  _not?: InputMaybe<sub_building_class_bool_exp>;
  _or?: InputMaybe<Array<sub_building_class_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { sub_building_class_constraint };

export { sub_building_class_enum };

/** Boolean expression to compare columns of type "sub_building_class_enum". All fields are combined with logical 'AND'. */
export type sub_building_class_enum_comparison_exp = {
  _eq?: InputMaybe<sub_building_class_enum>;
  _in?: InputMaybe<Array<sub_building_class_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<sub_building_class_enum>;
  _nin?: InputMaybe<Array<sub_building_class_enum>>;
};

/** input type for inserting data into table "sub_building_class" */
export type sub_building_class_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type sub_building_class_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type sub_building_class_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "sub_building_class" */
export type sub_building_class_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<sub_building_class>;
};

/** on_conflict condition type for table "sub_building_class" */
export type sub_building_class_on_conflict = {
  constraint: sub_building_class_constraint;
  update_columns?: Array<sub_building_class_update_column>;
  where?: InputMaybe<sub_building_class_bool_exp>;
};

/** Ordering options when selecting data from "sub_building_class". */
export type sub_building_class_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: sub_building_class */
export type sub_building_class_pk_columns_input = {
  id: Scalars['String'];
};

export { sub_building_class_select_column };

/** input type for updating data in table "sub_building_class" */
export type sub_building_class_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "sub_building_class" */
export type sub_building_class_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: sub_building_class_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type sub_building_class_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { sub_building_class_update_column };

export type sub_building_class_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<sub_building_class_set_input>;
  /** filter the rows which have to be updated */
  where: sub_building_class_bool_exp;
};

export { sub_building_constraint };

/** input type for incrementing numeric columns in table "sub_building" */
export type sub_building_inc_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  units_commercial?: InputMaybe<Scalars['Int']>;
  units_residential?: InputMaybe<Scalars['Int']>;
  year_renovated?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "sub_building" */
export type sub_building_insert_input = {
  building?: InputMaybe<building_obj_rel_insert_input>;
  building_id?: InputMaybe<Scalars['Int']>;
  consumption_drafts?: InputMaybe<consumption_draft_arr_rel_insert_input>;
  consumptions?: InputMaybe<consumption_arr_rel_insert_input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  energy_certificates?: InputMaybe<energy_certificate_arr_rel_insert_input>;
  energy_consumers?: InputMaybe<energy_consumer_arr_rel_insert_input>;
  energy_producers?: InputMaybe<energy_producer_arr_rel_insert_input>;
  energy_sources?: InputMaybe<energy_source_arr_rel_insert_input>;
  energy_standard?: InputMaybe<energy_standard_enum>;
  id?: InputMaybe<Scalars['Int']>;
  operation_model?: InputMaybe<operation_model_type_enum>;
  report_answers?: InputMaybe<report_answer_arr_rel_insert_input>;
  report_sub_buildings?: InputMaybe<report_sub_building_arr_rel_insert_input>;
  sub_building_class?: InputMaybe<sub_building_class_enum>;
  subsidies?: InputMaybe<sub_building_subsidy_arr_rel_insert_input>;
  subsidies_updated_at?: InputMaybe<Scalars['timestamptz']>;
  type_of_use_id?: InputMaybe<type_of_use_enum>;
  units_commercial?: InputMaybe<Scalars['Int']>;
  units_residential?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  year_renovated?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type sub_building_max_fields = {
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  subsidies_updated_at?: Maybe<Scalars['timestamptz']>;
  units_commercial?: Maybe<Scalars['Int']>;
  units_residential?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  year_renovated?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "sub_building" */
export type sub_building_max_order_by = {
  building_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  subsidies_updated_at?: InputMaybe<order_by>;
  units_commercial?: InputMaybe<order_by>;
  units_residential?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type sub_building_min_fields = {
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  subsidies_updated_at?: Maybe<Scalars['timestamptz']>;
  units_commercial?: Maybe<Scalars['Int']>;
  units_residential?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  year_renovated?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "sub_building" */
export type sub_building_min_order_by = {
  building_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  subsidies_updated_at?: InputMaybe<order_by>;
  units_commercial?: InputMaybe<order_by>;
  units_residential?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** response of any mutation on the table "sub_building" */
export type sub_building_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<sub_building>;
};

/** input type for inserting object relation for remote table "sub_building" */
export type sub_building_obj_rel_insert_input = {
  data: sub_building_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<sub_building_on_conflict>;
};

/** on_conflict condition type for table "sub_building" */
export type sub_building_on_conflict = {
  constraint: sub_building_constraint;
  update_columns?: Array<sub_building_update_column>;
  where?: InputMaybe<sub_building_bool_exp>;
};

/** Ordering options when selecting data from "sub_building". */
export type sub_building_order_by = {
  building?: InputMaybe<building_order_by>;
  building_id?: InputMaybe<order_by>;
  consumption_drafts_aggregate?: InputMaybe<consumption_draft_aggregate_order_by>;
  consumptions_aggregate?: InputMaybe<consumption_aggregate_order_by>;
  created_at?: InputMaybe<order_by>;
  energy_certificates_aggregate?: InputMaybe<energy_certificate_aggregate_order_by>;
  energy_consumers_aggregate?: InputMaybe<energy_consumer_aggregate_order_by>;
  energy_producers_aggregate?: InputMaybe<energy_producer_aggregate_order_by>;
  energy_sources_aggregate?: InputMaybe<energy_source_aggregate_order_by>;
  energy_standard?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  operation_model?: InputMaybe<order_by>;
  report_answers_aggregate?: InputMaybe<report_answer_aggregate_order_by>;
  report_sub_buildings_aggregate?: InputMaybe<report_sub_building_aggregate_order_by>;
  sub_building_class?: InputMaybe<order_by>;
  subsidies_aggregate?: InputMaybe<sub_building_subsidy_aggregate_order_by>;
  subsidies_updated_at?: InputMaybe<order_by>;
  type_of_use_id?: InputMaybe<order_by>;
  units_commercial?: InputMaybe<order_by>;
  units_residential?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** primary key columns input for table: sub_building */
export type sub_building_pk_columns_input = {
  id: Scalars['Int'];
};

export { sub_building_select_column };

/** input type for updating data in table "sub_building" */
export type sub_building_set_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  energy_standard?: InputMaybe<energy_standard_enum>;
  id?: InputMaybe<Scalars['Int']>;
  operation_model?: InputMaybe<operation_model_type_enum>;
  sub_building_class?: InputMaybe<sub_building_class_enum>;
  subsidies_updated_at?: InputMaybe<Scalars['timestamptz']>;
  type_of_use_id?: InputMaybe<type_of_use_enum>;
  units_commercial?: InputMaybe<Scalars['Int']>;
  units_residential?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  year_renovated?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type sub_building_stddev_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  units_commercial?: Maybe<Scalars['Float']>;
  units_residential?: Maybe<Scalars['Float']>;
  year_renovated?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sub_building" */
export type sub_building_stddev_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  units_commercial?: InputMaybe<order_by>;
  units_residential?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type sub_building_stddev_pop_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  units_commercial?: Maybe<Scalars['Float']>;
  units_residential?: Maybe<Scalars['Float']>;
  year_renovated?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sub_building" */
export type sub_building_stddev_pop_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  units_commercial?: InputMaybe<order_by>;
  units_residential?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type sub_building_stddev_samp_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  units_commercial?: Maybe<Scalars['Float']>;
  units_residential?: Maybe<Scalars['Float']>;
  year_renovated?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sub_building" */
export type sub_building_stddev_samp_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  units_commercial?: InputMaybe<order_by>;
  units_residential?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "sub_building" */
export type sub_building_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: sub_building_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type sub_building_stream_cursor_value_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  energy_standard?: InputMaybe<energy_standard_enum>;
  id?: InputMaybe<Scalars['Int']>;
  operation_model?: InputMaybe<operation_model_type_enum>;
  sub_building_class?: InputMaybe<sub_building_class_enum>;
  subsidies_updated_at?: InputMaybe<Scalars['timestamptz']>;
  type_of_use_id?: InputMaybe<type_of_use_enum>;
  units_commercial?: InputMaybe<Scalars['Int']>;
  units_residential?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  year_renovated?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "sub_building_subsidy" */
export type sub_building_subsidy = {
  id: Scalars['Int'];
  /** An object relationship */
  sub_building: sub_building;
  sub_building_id: Scalars['Int'];
  /** An object relationship */
  subsidy: subsidy;
  subsidy_id: Scalars['Int'];
};

/** aggregated selection of "sub_building_subsidy" */
export type sub_building_subsidy_aggregate = {
  aggregate?: Maybe<sub_building_subsidy_aggregate_fields>;
  nodes: Array<sub_building_subsidy>;
};

export type sub_building_subsidy_aggregate_bool_exp = {
  count?: InputMaybe<sub_building_subsidy_aggregate_bool_exp_count>;
};

export type sub_building_subsidy_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<sub_building_subsidy_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<sub_building_subsidy_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "sub_building_subsidy" */
export type sub_building_subsidy_aggregate_fields = {
  avg?: Maybe<sub_building_subsidy_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<sub_building_subsidy_max_fields>;
  min?: Maybe<sub_building_subsidy_min_fields>;
  stddev?: Maybe<sub_building_subsidy_stddev_fields>;
  stddev_pop?: Maybe<sub_building_subsidy_stddev_pop_fields>;
  stddev_samp?: Maybe<sub_building_subsidy_stddev_samp_fields>;
  sum?: Maybe<sub_building_subsidy_sum_fields>;
  var_pop?: Maybe<sub_building_subsidy_var_pop_fields>;
  var_samp?: Maybe<sub_building_subsidy_var_samp_fields>;
  variance?: Maybe<sub_building_subsidy_variance_fields>;
};

/** aggregate fields of "sub_building_subsidy" */
export type sub_building_subsidy_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<sub_building_subsidy_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sub_building_subsidy" */
export type sub_building_subsidy_aggregate_order_by = {
  avg?: InputMaybe<sub_building_subsidy_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<sub_building_subsidy_max_order_by>;
  min?: InputMaybe<sub_building_subsidy_min_order_by>;
  stddev?: InputMaybe<sub_building_subsidy_stddev_order_by>;
  stddev_pop?: InputMaybe<sub_building_subsidy_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<sub_building_subsidy_stddev_samp_order_by>;
  sum?: InputMaybe<sub_building_subsidy_sum_order_by>;
  var_pop?: InputMaybe<sub_building_subsidy_var_pop_order_by>;
  var_samp?: InputMaybe<sub_building_subsidy_var_samp_order_by>;
  variance?: InputMaybe<sub_building_subsidy_variance_order_by>;
};

/** input type for inserting array relation for remote table "sub_building_subsidy" */
export type sub_building_subsidy_arr_rel_insert_input = {
  data: Array<sub_building_subsidy_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<sub_building_subsidy_on_conflict>;
};

/** aggregate avg on columns */
export type sub_building_subsidy_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  subsidy_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sub_building_subsidy" */
export type sub_building_subsidy_avg_order_by = {
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "sub_building_subsidy". All fields are combined with a logical 'AND'. */
export type sub_building_subsidy_bool_exp = {
  _and?: InputMaybe<Array<sub_building_subsidy_bool_exp>>;
  _not?: InputMaybe<sub_building_subsidy_bool_exp>;
  _or?: InputMaybe<Array<sub_building_subsidy_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  sub_building?: InputMaybe<sub_building_bool_exp>;
  sub_building_id?: InputMaybe<Int_comparison_exp>;
  subsidy?: InputMaybe<subsidy_bool_exp>;
  subsidy_id?: InputMaybe<Int_comparison_exp>;
};

export { sub_building_subsidy_constraint };

/** input type for incrementing numeric columns in table "sub_building_subsidy" */
export type sub_building_subsidy_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  subsidy_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "sub_building_subsidy" */
export type sub_building_subsidy_insert_input = {
  id?: InputMaybe<Scalars['Int']>;
  sub_building?: InputMaybe<sub_building_obj_rel_insert_input>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  subsidy?: InputMaybe<subsidy_obj_rel_insert_input>;
  subsidy_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type sub_building_subsidy_max_fields = {
  id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
  subsidy_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "sub_building_subsidy" */
export type sub_building_subsidy_max_order_by = {
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type sub_building_subsidy_min_fields = {
  id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
  subsidy_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "sub_building_subsidy" */
export type sub_building_subsidy_min_order_by = {
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "sub_building_subsidy" */
export type sub_building_subsidy_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<sub_building_subsidy>;
};

/** on_conflict condition type for table "sub_building_subsidy" */
export type sub_building_subsidy_on_conflict = {
  constraint: sub_building_subsidy_constraint;
  update_columns?: Array<sub_building_subsidy_update_column>;
  where?: InputMaybe<sub_building_subsidy_bool_exp>;
};

/** Ordering options when selecting data from "sub_building_subsidy". */
export type sub_building_subsidy_order_by = {
  id?: InputMaybe<order_by>;
  sub_building?: InputMaybe<sub_building_order_by>;
  sub_building_id?: InputMaybe<order_by>;
  subsidy?: InputMaybe<subsidy_order_by>;
  subsidy_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: sub_building_subsidy */
export type sub_building_subsidy_pk_columns_input = {
  id: Scalars['Int'];
};

export { sub_building_subsidy_select_column };

/** input type for updating data in table "sub_building_subsidy" */
export type sub_building_subsidy_set_input = {
  id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  subsidy_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type sub_building_subsidy_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  subsidy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sub_building_subsidy" */
export type sub_building_subsidy_stddev_order_by = {
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type sub_building_subsidy_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  subsidy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sub_building_subsidy" */
export type sub_building_subsidy_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type sub_building_subsidy_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  subsidy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sub_building_subsidy" */
export type sub_building_subsidy_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "sub_building_subsidy" */
export type sub_building_subsidy_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: sub_building_subsidy_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type sub_building_subsidy_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']>;
  sub_building_id?: InputMaybe<Scalars['Int']>;
  subsidy_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type sub_building_subsidy_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  sub_building_id?: Maybe<Scalars['Int']>;
  subsidy_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "sub_building_subsidy" */
export type sub_building_subsidy_sum_order_by = {
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
};

export { sub_building_subsidy_update_column };

export type sub_building_subsidy_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<sub_building_subsidy_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<sub_building_subsidy_set_input>;
  /** filter the rows which have to be updated */
  where: sub_building_subsidy_bool_exp;
};

/** aggregate var_pop on columns */
export type sub_building_subsidy_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  subsidy_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sub_building_subsidy" */
export type sub_building_subsidy_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type sub_building_subsidy_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  subsidy_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sub_building_subsidy" */
export type sub_building_subsidy_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type sub_building_subsidy_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  sub_building_id?: Maybe<Scalars['Float']>;
  subsidy_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sub_building_subsidy" */
export type sub_building_subsidy_variance_order_by = {
  id?: InputMaybe<order_by>;
  sub_building_id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
};

/** aggregate sum on columns */
export type sub_building_sum_fields = {
  building_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  units_commercial?: Maybe<Scalars['Int']>;
  units_residential?: Maybe<Scalars['Int']>;
  year_renovated?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "sub_building" */
export type sub_building_sum_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  units_commercial?: InputMaybe<order_by>;
  units_residential?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

export { sub_building_update_column };

export type sub_building_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<sub_building_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<sub_building_set_input>;
  /** filter the rows which have to be updated */
  where: sub_building_bool_exp;
};

/** aggregate var_pop on columns */
export type sub_building_var_pop_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  units_commercial?: Maybe<Scalars['Float']>;
  units_residential?: Maybe<Scalars['Float']>;
  year_renovated?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sub_building" */
export type sub_building_var_pop_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  units_commercial?: InputMaybe<order_by>;
  units_residential?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type sub_building_var_samp_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  units_commercial?: Maybe<Scalars['Float']>;
  units_residential?: Maybe<Scalars['Float']>;
  year_renovated?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sub_building" */
export type sub_building_var_samp_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  units_commercial?: InputMaybe<order_by>;
  units_residential?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type sub_building_variance_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  units_commercial?: Maybe<Scalars['Float']>;
  units_residential?: Maybe<Scalars['Float']>;
  year_renovated?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sub_building" */
export type sub_building_variance_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  units_commercial?: InputMaybe<order_by>;
  units_residential?: InputMaybe<order_by>;
  year_renovated?: InputMaybe<order_by>;
};

export type subscription_root = {
  /** fetch data from the table: "action" */
  action: Array<action>;
  /** fetch aggregated fields from the table: "action" */
  action_aggregate: action_aggregate;
  /** fetch data from the table: "action" using primary key columns */
  action_by_pk?: Maybe<action>;
  /** fetch data from the table: "action_plan" */
  action_plan: Array<action_plan>;
  /** fetch aggregated fields from the table: "action_plan" */
  action_plan_aggregate: action_plan_aggregate;
  /** fetch data from the table: "action_plan" using primary key columns */
  action_plan_by_pk?: Maybe<action_plan>;
  /** fetch data from the table in a streaming manner: "action_plan" */
  action_plan_stream: Array<action_plan>;
  /** fetch data from the table in a streaming manner: "action" */
  action_stream: Array<action>;
  /** fetch data from the table: "action_subsidy" */
  action_subsidy: Array<action_subsidy>;
  /** fetch aggregated fields from the table: "action_subsidy" */
  action_subsidy_aggregate: action_subsidy_aggregate;
  /** fetch data from the table: "action_subsidy" using primary key columns */
  action_subsidy_by_pk?: Maybe<action_subsidy>;
  /** fetch data from the table in a streaming manner: "action_subsidy" */
  action_subsidy_stream: Array<action_subsidy>;
  /** fetch data from the table: "action_subsidy_value_type" */
  action_subsidy_value_type: Array<action_subsidy_value_type>;
  /** fetch aggregated fields from the table: "action_subsidy_value_type" */
  action_subsidy_value_type_aggregate: action_subsidy_value_type_aggregate;
  /** fetch data from the table: "action_subsidy_value_type" using primary key columns */
  action_subsidy_value_type_by_pk?: Maybe<action_subsidy_value_type>;
  /** fetch data from the table in a streaming manner: "action_subsidy_value_type" */
  action_subsidy_value_type_stream: Array<action_subsidy_value_type>;
  /** fetch data from the table: "address" */
  address: Array<address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: address_aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<address>;
  /** fetch data from the table in a streaming manner: "address" */
  address_stream: Array<address>;
  /** fetch data from the table: "api_user" */
  api_user: Array<api_user>;
  /** fetch aggregated fields from the table: "api_user" */
  api_user_aggregate: api_user_aggregate;
  /** fetch data from the table: "api_user" using primary key columns */
  api_user_by_pk?: Maybe<api_user>;
  /** fetch data from the table in a streaming manner: "api_user" */
  api_user_stream: Array<api_user>;
  /** fetch data from the table: "area" */
  area: Array<area>;
  /** fetch aggregated fields from the table: "area" */
  area_aggregate: area_aggregate;
  /** fetch data from the table: "area" using primary key columns */
  area_by_pk?: Maybe<area>;
  /** fetch data from the table in a streaming manner: "area" */
  area_stream: Array<area>;
  /** fetch data from the table: "area_system_type" */
  area_system_type: Array<area_system_type>;
  /** fetch aggregated fields from the table: "area_system_type" */
  area_system_type_aggregate: area_system_type_aggregate;
  /** fetch data from the table: "area_system_type" using primary key columns */
  area_system_type_by_pk?: Maybe<area_system_type>;
  /** fetch data from the table in a streaming manner: "area_system_type" */
  area_system_type_stream: Array<area_system_type>;
  /** fetch data from the table: "area_type" */
  area_type: Array<area_type>;
  /** fetch aggregated fields from the table: "area_type" */
  area_type_aggregate: area_type_aggregate;
  /** fetch data from the table: "area_type" using primary key columns */
  area_type_by_pk?: Maybe<area_type>;
  /** fetch data from the table in a streaming manner: "area_type" */
  area_type_stream: Array<area_type>;
  /** fetch data from the table: "async_job" */
  async_job: Array<async_job>;
  /** fetch aggregated fields from the table: "async_job" */
  async_job_aggregate: async_job_aggregate;
  /** fetch data from the table: "async_job" using primary key columns */
  async_job_by_pk?: Maybe<async_job>;
  /** fetch data from the table: "async_job_status_type" */
  async_job_status_type: Array<async_job_status_type>;
  /** fetch aggregated fields from the table: "async_job_status_type" */
  async_job_status_type_aggregate: async_job_status_type_aggregate;
  /** fetch data from the table: "async_job_status_type" using primary key columns */
  async_job_status_type_by_pk?: Maybe<async_job_status_type>;
  /** fetch data from the table in a streaming manner: "async_job_status_type" */
  async_job_status_type_stream: Array<async_job_status_type>;
  /** fetch data from the table in a streaming manner: "async_job" */
  async_job_stream: Array<async_job>;
  /** fetch data from the table: "building" */
  building: Array<building>;
  /** fetch aggregated fields from the table: "building" */
  building_aggregate: building_aggregate;
  /** fetch data from the table: "building" using primary key columns */
  building_by_pk?: Maybe<building>;
  /** fetch data from the table: "building_model" */
  building_model: Array<building_model>;
  /** fetch aggregated fields from the table: "building_model" */
  building_model_aggregate: building_model_aggregate;
  /** fetch data from the table: "building_model" using primary key columns */
  building_model_by_pk?: Maybe<building_model>;
  /** fetch data from the table in a streaming manner: "building_model" */
  building_model_stream: Array<building_model>;
  /** fetch data from the table: "building_state" */
  building_state: Array<building_state>;
  /** fetch aggregated fields from the table: "building_state" */
  building_state_aggregate: building_state_aggregate;
  /** fetch data from the table: "building_state" using primary key columns */
  building_state_by_pk?: Maybe<building_state>;
  /** fetch data from the table in a streaming manner: "building_state" */
  building_state_stream: Array<building_state>;
  /** fetch data from the table in a streaming manner: "building" */
  building_stream: Array<building>;
  /** fetch data from the table: "climate_region" */
  climate_region: Array<climate_region>;
  /** fetch aggregated fields from the table: "climate_region" */
  climate_region_aggregate: climate_region_aggregate;
  /** fetch data from the table: "climate_region" using primary key columns */
  climate_region_by_pk?: Maybe<climate_region>;
  /** fetch data from the table in a streaming manner: "climate_region" */
  climate_region_stream: Array<climate_region>;
  /** fetch data from the table: "climate_risk" */
  climate_risk: Array<climate_risk>;
  /** fetch aggregated fields from the table: "climate_risk" */
  climate_risk_aggregate: climate_risk_aggregate;
  /** fetch data from the table: "climate_risk" using primary key columns */
  climate_risk_by_pk?: Maybe<climate_risk>;
  /** fetch data from the table in a streaming manner: "climate_risk" */
  climate_risk_stream: Array<climate_risk>;
  /** fetch data from the table: "climate_risk_type" */
  climate_risk_type: Array<climate_risk_type>;
  /** fetch aggregated fields from the table: "climate_risk_type" */
  climate_risk_type_aggregate: climate_risk_type_aggregate;
  /** fetch data from the table: "climate_risk_type" using primary key columns */
  climate_risk_type_by_pk?: Maybe<climate_risk_type>;
  /** fetch data from the table in a streaming manner: "climate_risk_type" */
  climate_risk_type_stream: Array<climate_risk_type>;
  /** fetch data from the table: "consumption" */
  consumption: Array<consumption>;
  /** fetch aggregated fields from the table: "consumption" */
  consumption_aggregate: consumption_aggregate;
  /** fetch data from the table: "consumption" using primary key columns */
  consumption_by_pk?: Maybe<consumption>;
  /** fetch data from the table: "consumption_draft" */
  consumption_draft: Array<consumption_draft>;
  /** fetch aggregated fields from the table: "consumption_draft" */
  consumption_draft_aggregate: consumption_draft_aggregate;
  /** fetch data from the table: "consumption_draft" using primary key columns */
  consumption_draft_by_pk?: Maybe<consumption_draft>;
  /** fetch data from the table in a streaming manner: "consumption_draft" */
  consumption_draft_stream: Array<consumption_draft>;
  /** fetch data from the table: "consumption_frequency_type" */
  consumption_frequency_type: Array<consumption_frequency_type>;
  /** fetch aggregated fields from the table: "consumption_frequency_type" */
  consumption_frequency_type_aggregate: consumption_frequency_type_aggregate;
  /** fetch data from the table: "consumption_frequency_type" using primary key columns */
  consumption_frequency_type_by_pk?: Maybe<consumption_frequency_type>;
  /** fetch data from the table in a streaming manner: "consumption_frequency_type" */
  consumption_frequency_type_stream: Array<consumption_frequency_type>;
  /** fetch data from the table: "consumption_invoice" */
  consumption_invoice: Array<consumption_invoice>;
  /** fetch aggregated fields from the table: "consumption_invoice" */
  consumption_invoice_aggregate: consumption_invoice_aggregate;
  /** fetch data from the table: "consumption_invoice" using primary key columns */
  consumption_invoice_by_pk?: Maybe<consumption_invoice>;
  /** fetch data from the table: "consumption_invoice_draft" */
  consumption_invoice_draft: Array<consumption_invoice_draft>;
  /** fetch aggregated fields from the table: "consumption_invoice_draft" */
  consumption_invoice_draft_aggregate: consumption_invoice_draft_aggregate;
  /** fetch data from the table: "consumption_invoice_draft" using primary key columns */
  consumption_invoice_draft_by_pk?: Maybe<consumption_invoice_draft>;
  /** fetch data from the table in a streaming manner: "consumption_invoice_draft" */
  consumption_invoice_draft_stream: Array<consumption_invoice_draft>;
  /** fetch data from the table in a streaming manner: "consumption_invoice" */
  consumption_invoice_stream: Array<consumption_invoice>;
  /** fetch data from the table: "consumption_source_type" */
  consumption_source_type: Array<consumption_source_type>;
  /** fetch aggregated fields from the table: "consumption_source_type" */
  consumption_source_type_aggregate: consumption_source_type_aggregate;
  /** fetch data from the table: "consumption_source_type" using primary key columns */
  consumption_source_type_by_pk?: Maybe<consumption_source_type>;
  /** fetch data from the table in a streaming manner: "consumption_source_type" */
  consumption_source_type_stream: Array<consumption_source_type>;
  /** fetch data from the table in a streaming manner: "consumption" */
  consumption_stream: Array<consumption>;
  /** fetch data from the table: "consumption_sub_type" */
  consumption_sub_type: Array<consumption_sub_type>;
  /** fetch aggregated fields from the table: "consumption_sub_type" */
  consumption_sub_type_aggregate: consumption_sub_type_aggregate;
  /** fetch data from the table: "consumption_sub_type" using primary key columns */
  consumption_sub_type_by_pk?: Maybe<consumption_sub_type>;
  /** fetch data from the table in a streaming manner: "consumption_sub_type" */
  consumption_sub_type_stream: Array<consumption_sub_type>;
  /** fetch data from the table: "consumption_type" */
  consumption_type: Array<consumption_type>;
  /** fetch aggregated fields from the table: "consumption_type" */
  consumption_type_aggregate: consumption_type_aggregate;
  /** fetch data from the table: "consumption_type" using primary key columns */
  consumption_type_by_pk?: Maybe<consumption_type>;
  /** fetch data from the table in a streaming manner: "consumption_type" */
  consumption_type_stream: Array<consumption_type>;
  /** fetch data from the table: "consumption_unit" */
  consumption_unit: Array<consumption_unit>;
  /** fetch aggregated fields from the table: "consumption_unit" */
  consumption_unit_aggregate: consumption_unit_aggregate;
  /** fetch data from the table: "consumption_unit" using primary key columns */
  consumption_unit_by_pk?: Maybe<consumption_unit>;
  /** fetch data from the table in a streaming manner: "consumption_unit" */
  consumption_unit_stream: Array<consumption_unit>;
  /** fetch data from the table: "consumption_waste_details" */
  consumption_waste_details: Array<consumption_waste_details>;
  /** fetch aggregated fields from the table: "consumption_waste_details" */
  consumption_waste_details_aggregate: consumption_waste_details_aggregate;
  /** fetch data from the table: "consumption_waste_details" using primary key columns */
  consumption_waste_details_by_pk?: Maybe<consumption_waste_details>;
  /** fetch data from the table: "consumption_waste_details_draft" */
  consumption_waste_details_draft: Array<consumption_waste_details_draft>;
  /** fetch aggregated fields from the table: "consumption_waste_details_draft" */
  consumption_waste_details_draft_aggregate: consumption_waste_details_draft_aggregate;
  /** fetch data from the table: "consumption_waste_details_draft" using primary key columns */
  consumption_waste_details_draft_by_pk?: Maybe<consumption_waste_details_draft>;
  /** fetch data from the table in a streaming manner: "consumption_waste_details_draft" */
  consumption_waste_details_draft_stream: Array<consumption_waste_details_draft>;
  /** fetch data from the table in a streaming manner: "consumption_waste_details" */
  consumption_waste_details_stream: Array<consumption_waste_details>;
  /** fetch data from the table: "country" */
  country: Array<country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: country_aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<country>;
  /** fetch data from the table: "country_state" */
  country_state: Array<country_state>;
  /** fetch aggregated fields from the table: "country_state" */
  country_state_aggregate: country_state_aggregate;
  /** fetch data from the table: "country_state" using primary key columns */
  country_state_by_pk?: Maybe<country_state>;
  /** fetch data from the table in a streaming manner: "country_state" */
  country_state_stream: Array<country_state>;
  /** fetch data from the table in a streaming manner: "country" */
  country_stream: Array<country>;
  /** fetch data from the table: "data_source_type" */
  data_source_type: Array<data_source_type>;
  /** fetch aggregated fields from the table: "data_source_type" */
  data_source_type_aggregate: data_source_type_aggregate;
  /** fetch data from the table: "data_source_type" using primary key columns */
  data_source_type_by_pk?: Maybe<data_source_type>;
  /** fetch data from the table in a streaming manner: "data_source_type" */
  data_source_type_stream: Array<data_source_type>;
  /** fetch data from the table: "draft_status" */
  draft_status: Array<draft_status>;
  /** fetch aggregated fields from the table: "draft_status" */
  draft_status_aggregate: draft_status_aggregate;
  /** fetch data from the table: "draft_status" using primary key columns */
  draft_status_by_pk?: Maybe<draft_status>;
  /** fetch data from the table in a streaming manner: "draft_status" */
  draft_status_stream: Array<draft_status>;
  /** fetch data from the table: "economic_unit" */
  economic_unit: Array<economic_unit>;
  /** fetch aggregated fields from the table: "economic_unit" */
  economic_unit_aggregate: economic_unit_aggregate;
  /** fetch data from the table: "economic_unit" using primary key columns */
  economic_unit_by_pk?: Maybe<economic_unit>;
  /** fetch data from the table in a streaming manner: "economic_unit" */
  economic_unit_stream: Array<economic_unit>;
  /** fetch data from the table: "efficiency_class" */
  efficiency_class: Array<efficiency_class>;
  /** fetch aggregated fields from the table: "efficiency_class" */
  efficiency_class_aggregate: efficiency_class_aggregate;
  /** fetch data from the table: "efficiency_class" using primary key columns */
  efficiency_class_by_pk?: Maybe<efficiency_class>;
  /** fetch data from the table in a streaming manner: "efficiency_class" */
  efficiency_class_stream: Array<efficiency_class>;
  /** fetch data from the table: "emission_certificate" */
  emission_certificate: Array<emission_certificate>;
  /** fetch aggregated fields from the table: "emission_certificate" */
  emission_certificate_aggregate: emission_certificate_aggregate;
  /** fetch data from the table: "emission_certificate" using primary key columns */
  emission_certificate_by_pk?: Maybe<emission_certificate>;
  /** fetch data from the table: "emission_certificate_draft" */
  emission_certificate_draft: Array<emission_certificate_draft>;
  /** fetch aggregated fields from the table: "emission_certificate_draft" */
  emission_certificate_draft_aggregate: emission_certificate_draft_aggregate;
  /** fetch data from the table: "emission_certificate_draft" using primary key columns */
  emission_certificate_draft_by_pk?: Maybe<emission_certificate_draft>;
  /** fetch data from the table in a streaming manner: "emission_certificate_draft" */
  emission_certificate_draft_stream: Array<emission_certificate_draft>;
  /** fetch data from the table in a streaming manner: "emission_certificate" */
  emission_certificate_stream: Array<emission_certificate>;
  /** fetch data from the table: "emission_factor_origin" */
  emission_factor_origin: Array<emission_factor_origin>;
  /** fetch aggregated fields from the table: "emission_factor_origin" */
  emission_factor_origin_aggregate: emission_factor_origin_aggregate;
  /** fetch data from the table: "emission_factor_origin" using primary key columns */
  emission_factor_origin_by_pk?: Maybe<emission_factor_origin>;
  /** fetch data from the table in a streaming manner: "emission_factor_origin" */
  emission_factor_origin_stream: Array<emission_factor_origin>;
  /** fetch data from the table: "emission_factor_type" */
  emission_factor_type: Array<emission_factor_type>;
  /** fetch aggregated fields from the table: "emission_factor_type" */
  emission_factor_type_aggregate: emission_factor_type_aggregate;
  /** fetch data from the table: "emission_factor_type" using primary key columns */
  emission_factor_type_by_pk?: Maybe<emission_factor_type>;
  /** fetch data from the table in a streaming manner: "emission_factor_type" */
  emission_factor_type_stream: Array<emission_factor_type>;
  /** fetch data from the table: "energy_certificate" */
  energy_certificate: Array<energy_certificate>;
  /** fetch aggregated fields from the table: "energy_certificate" */
  energy_certificate_aggregate: energy_certificate_aggregate;
  /** fetch data from the table: "energy_certificate" using primary key columns */
  energy_certificate_by_pk?: Maybe<energy_certificate>;
  /** fetch data from the table: "energy_certificate_consumer" */
  energy_certificate_consumer: Array<energy_certificate_consumer>;
  /** fetch aggregated fields from the table: "energy_certificate_consumer" */
  energy_certificate_consumer_aggregate: energy_certificate_consumer_aggregate;
  /** fetch data from the table: "energy_certificate_consumer" using primary key columns */
  energy_certificate_consumer_by_pk?: Maybe<energy_certificate_consumer>;
  /** fetch data from the table in a streaming manner: "energy_certificate_consumer" */
  energy_certificate_consumer_stream: Array<energy_certificate_consumer>;
  /** fetch data from the table: "energy_certificate_draft" */
  energy_certificate_draft: Array<energy_certificate_draft>;
  /** fetch aggregated fields from the table: "energy_certificate_draft" */
  energy_certificate_draft_aggregate: energy_certificate_draft_aggregate;
  /** fetch data from the table: "energy_certificate_draft" using primary key columns */
  energy_certificate_draft_by_pk?: Maybe<energy_certificate_draft>;
  /** fetch data from the table in a streaming manner: "energy_certificate_draft" */
  energy_certificate_draft_stream: Array<energy_certificate_draft>;
  /** fetch data from the table in a streaming manner: "energy_certificate" */
  energy_certificate_stream: Array<energy_certificate>;
  /** fetch data from the table: "energy_certificate_type" */
  energy_certificate_type: Array<energy_certificate_type>;
  /** fetch aggregated fields from the table: "energy_certificate_type" */
  energy_certificate_type_aggregate: energy_certificate_type_aggregate;
  /** fetch data from the table: "energy_certificate_type" using primary key columns */
  energy_certificate_type_by_pk?: Maybe<energy_certificate_type>;
  /** fetch data from the table in a streaming manner: "energy_certificate_type" */
  energy_certificate_type_stream: Array<energy_certificate_type>;
  /** fetch data from the table: "energy_consumer" */
  energy_consumer: Array<energy_consumer>;
  /** fetch aggregated fields from the table: "energy_consumer" */
  energy_consumer_aggregate: energy_consumer_aggregate;
  /** fetch data from the table: "energy_consumer" using primary key columns */
  energy_consumer_by_pk?: Maybe<energy_consumer>;
  /** fetch data from the table in a streaming manner: "energy_consumer" */
  energy_consumer_stream: Array<energy_consumer>;
  /** fetch data from the table: "energy_consumer_technology_type" */
  energy_consumer_technology_type: Array<energy_consumer_technology_type>;
  /** fetch aggregated fields from the table: "energy_consumer_technology_type" */
  energy_consumer_technology_type_aggregate: energy_consumer_technology_type_aggregate;
  /** fetch data from the table: "energy_consumer_technology_type" using primary key columns */
  energy_consumer_technology_type_by_pk?: Maybe<energy_consumer_technology_type>;
  /** fetch data from the table in a streaming manner: "energy_consumer_technology_type" */
  energy_consumer_technology_type_stream: Array<energy_consumer_technology_type>;
  /** fetch data from the table: "energy_consumer_type" */
  energy_consumer_type: Array<energy_consumer_type>;
  /** fetch aggregated fields from the table: "energy_consumer_type" */
  energy_consumer_type_aggregate: energy_consumer_type_aggregate;
  /** fetch data from the table: "energy_consumer_type" using primary key columns */
  energy_consumer_type_by_pk?: Maybe<energy_consumer_type>;
  /** fetch data from the table in a streaming manner: "energy_consumer_type" */
  energy_consumer_type_stream: Array<energy_consumer_type>;
  /** fetch data from the table: "energy_distribution" */
  energy_distribution: Array<energy_distribution>;
  /** fetch aggregated fields from the table: "energy_distribution" */
  energy_distribution_aggregate: energy_distribution_aggregate;
  /** fetch data from the table: "energy_distribution" using primary key columns */
  energy_distribution_by_pk?: Maybe<energy_distribution>;
  /** fetch data from the table in a streaming manner: "energy_distribution" */
  energy_distribution_stream: Array<energy_distribution>;
  /** fetch data from the table: "energy_distribution_type" */
  energy_distribution_type: Array<energy_distribution_type>;
  /** fetch aggregated fields from the table: "energy_distribution_type" */
  energy_distribution_type_aggregate: energy_distribution_type_aggregate;
  /** fetch data from the table: "energy_distribution_type" using primary key columns */
  energy_distribution_type_by_pk?: Maybe<energy_distribution_type>;
  /** fetch data from the table in a streaming manner: "energy_distribution_type" */
  energy_distribution_type_stream: Array<energy_distribution_type>;
  /** fetch data from the table: "energy_path" */
  energy_path: Array<energy_path>;
  /** fetch aggregated fields from the table: "energy_path" */
  energy_path_aggregate: energy_path_aggregate;
  /** fetch data from the table: "energy_path" using primary key columns */
  energy_path_by_pk?: Maybe<energy_path>;
  /** fetch data from the table in a streaming manner: "energy_path" */
  energy_path_stream: Array<energy_path>;
  /** fetch data from the table: "energy_producer" */
  energy_producer: Array<energy_producer>;
  /** fetch aggregated fields from the table: "energy_producer" */
  energy_producer_aggregate: energy_producer_aggregate;
  /** fetch data from the table: "energy_producer" using primary key columns */
  energy_producer_by_pk?: Maybe<energy_producer>;
  /** fetch data from the table in a streaming manner: "energy_producer" */
  energy_producer_stream: Array<energy_producer>;
  /** fetch data from the table: "energy_source" */
  energy_source: Array<energy_source>;
  /** fetch aggregated fields from the table: "energy_source" */
  energy_source_aggregate: energy_source_aggregate;
  /** fetch data from the table: "energy_source" using primary key columns */
  energy_source_by_pk?: Maybe<energy_source>;
  /** fetch data from the table in a streaming manner: "energy_source" */
  energy_source_stream: Array<energy_source>;
  /** fetch data from the table: "energy_source_type" */
  energy_source_type: Array<energy_source_type>;
  /** fetch aggregated fields from the table: "energy_source_type" */
  energy_source_type_aggregate: energy_source_type_aggregate;
  /** fetch data from the table: "energy_source_type" using primary key columns */
  energy_source_type_by_pk?: Maybe<energy_source_type>;
  /** fetch data from the table in a streaming manner: "energy_source_type" */
  energy_source_type_stream: Array<energy_source_type>;
  /** fetch data from the table: "energy_standard" */
  energy_standard: Array<energy_standard>;
  /** fetch aggregated fields from the table: "energy_standard" */
  energy_standard_aggregate: energy_standard_aggregate;
  /** fetch data from the table: "energy_standard" using primary key columns */
  energy_standard_by_pk?: Maybe<energy_standard>;
  /** fetch data from the table in a streaming manner: "energy_standard" */
  energy_standard_stream: Array<energy_standard>;
  /** fetch data from the table: "energy_storage" */
  energy_storage: Array<energy_storage>;
  /** fetch aggregated fields from the table: "energy_storage" */
  energy_storage_aggregate: energy_storage_aggregate;
  /** fetch data from the table: "energy_storage" using primary key columns */
  energy_storage_by_pk?: Maybe<energy_storage>;
  /** fetch data from the table in a streaming manner: "energy_storage" */
  energy_storage_stream: Array<energy_storage>;
  /** fetch data from the table: "energy_storage_type" */
  energy_storage_type: Array<energy_storage_type>;
  /** fetch aggregated fields from the table: "energy_storage_type" */
  energy_storage_type_aggregate: energy_storage_type_aggregate;
  /** fetch data from the table: "energy_storage_type" using primary key columns */
  energy_storage_type_by_pk?: Maybe<energy_storage_type>;
  /** fetch data from the table in a streaming manner: "energy_storage_type" */
  energy_storage_type_stream: Array<energy_storage_type>;
  /** fetch data from the table: "energy_system" */
  energy_system: Array<energy_system>;
  /** fetch aggregated fields from the table: "energy_system" */
  energy_system_aggregate: energy_system_aggregate;
  /** fetch data from the table: "energy_system" using primary key columns */
  energy_system_by_pk?: Maybe<energy_system>;
  /** fetch data from the table: "energy_system_consumer_route" */
  energy_system_consumer_route: Array<energy_system_consumer_route>;
  /** fetch aggregated fields from the table: "energy_system_consumer_route" */
  energy_system_consumer_route_aggregate: energy_system_consumer_route_aggregate;
  /** fetch data from the table: "energy_system_consumer_route" using primary key columns */
  energy_system_consumer_route_by_pk?: Maybe<energy_system_consumer_route>;
  /** fetch data from the table in a streaming manner: "energy_system_consumer_route" */
  energy_system_consumer_route_stream: Array<energy_system_consumer_route>;
  /** fetch data from the table: "energy_system_producer_route" */
  energy_system_producer_route: Array<energy_system_producer_route>;
  /** fetch aggregated fields from the table: "energy_system_producer_route" */
  energy_system_producer_route_aggregate: energy_system_producer_route_aggregate;
  /** fetch data from the table: "energy_system_producer_route" using primary key columns */
  energy_system_producer_route_by_pk?: Maybe<energy_system_producer_route>;
  /** fetch data from the table in a streaming manner: "energy_system_producer_route" */
  energy_system_producer_route_stream: Array<energy_system_producer_route>;
  /** fetch data from the table in a streaming manner: "energy_system" */
  energy_system_stream: Array<energy_system>;
  /** fetch data from the table: "energy_system_type" */
  energy_system_type: Array<energy_system_type>;
  /** fetch aggregated fields from the table: "energy_system_type" */
  energy_system_type_aggregate: energy_system_type_aggregate;
  /** fetch data from the table: "energy_system_type" using primary key columns */
  energy_system_type_by_pk?: Maybe<energy_system_type>;
  /** fetch data from the table in a streaming manner: "energy_system_type" */
  energy_system_type_stream: Array<energy_system_type>;
  /** fetch data from the table: "envelope" */
  envelope: Array<envelope>;
  /** fetch aggregated fields from the table: "envelope" */
  envelope_aggregate: envelope_aggregate;
  /** fetch data from the table: "envelope" using primary key columns */
  envelope_by_pk?: Maybe<envelope>;
  /** fetch data from the table in a streaming manner: "envelope" */
  envelope_stream: Array<envelope>;
  /** fetch data from the table: "envelope_type" */
  envelope_type: Array<envelope_type>;
  /** fetch aggregated fields from the table: "envelope_type" */
  envelope_type_aggregate: envelope_type_aggregate;
  /** fetch data from the table: "envelope_type" using primary key columns */
  envelope_type_by_pk?: Maybe<envelope_type>;
  /** fetch data from the table in a streaming manner: "envelope_type" */
  envelope_type_stream: Array<envelope_type>;
  /** fetch data from the table: "eu_taxonomy_compliance" */
  eu_taxonomy_compliance: Array<eu_taxonomy_compliance>;
  /** fetch aggregated fields from the table: "eu_taxonomy_compliance" */
  eu_taxonomy_compliance_aggregate: eu_taxonomy_compliance_aggregate;
  /** fetch data from the table: "eu_taxonomy_compliance" using primary key columns */
  eu_taxonomy_compliance_by_pk?: Maybe<eu_taxonomy_compliance>;
  /** fetch data from the table in a streaming manner: "eu_taxonomy_compliance" */
  eu_taxonomy_compliance_stream: Array<eu_taxonomy_compliance>;
  /** fetch data from the table: "file" */
  file: Array<file>;
  /** fetch aggregated fields from the table: "file" */
  file_aggregate: file_aggregate;
  /** fetch data from the table: "file" using primary key columns */
  file_by_pk?: Maybe<file>;
  /** fetch data from the table in a streaming manner: "file" */
  file_stream: Array<file>;
  /** fetch data from the table: "insulation_material_category" */
  insulation_material_category: Array<insulation_material_category>;
  /** fetch aggregated fields from the table: "insulation_material_category" */
  insulation_material_category_aggregate: insulation_material_category_aggregate;
  /** fetch data from the table: "insulation_material_category" using primary key columns */
  insulation_material_category_by_pk?: Maybe<insulation_material_category>;
  /** fetch data from the table in a streaming manner: "insulation_material_category" */
  insulation_material_category_stream: Array<insulation_material_category>;
  /** fetch data from the table: "insulation_method" */
  insulation_method: Array<insulation_method>;
  /** fetch aggregated fields from the table: "insulation_method" */
  insulation_method_aggregate: insulation_method_aggregate;
  /** fetch data from the table: "insulation_method" using primary key columns */
  insulation_method_by_pk?: Maybe<insulation_method>;
  /** fetch data from the table in a streaming manner: "insulation_method" */
  insulation_method_stream: Array<insulation_method>;
  /** fetch data from the table: "invoice_payer_type" */
  invoice_payer_type: Array<invoice_payer_type>;
  /** fetch aggregated fields from the table: "invoice_payer_type" */
  invoice_payer_type_aggregate: invoice_payer_type_aggregate;
  /** fetch data from the table: "invoice_payer_type" using primary key columns */
  invoice_payer_type_by_pk?: Maybe<invoice_payer_type>;
  /** fetch data from the table in a streaming manner: "invoice_payer_type" */
  invoice_payer_type_stream: Array<invoice_payer_type>;
  /** fetch data from the table: "langfuse_datasets_temporary_item" */
  langfuse_datasets_temporary_item: Array<langfuse_datasets_temporary_item>;
  /** fetch aggregated fields from the table: "langfuse_datasets_temporary_item" */
  langfuse_datasets_temporary_item_aggregate: langfuse_datasets_temporary_item_aggregate;
  /** fetch data from the table: "langfuse_datasets_temporary_item" using primary key columns */
  langfuse_datasets_temporary_item_by_pk?: Maybe<langfuse_datasets_temporary_item>;
  /** fetch data from the table in a streaming manner: "langfuse_datasets_temporary_item" */
  langfuse_datasets_temporary_item_stream: Array<langfuse_datasets_temporary_item>;
  /** fetch data from the table: "language" */
  language: Array<language>;
  /** fetch aggregated fields from the table: "language" */
  language_aggregate: language_aggregate;
  /** fetch data from the table: "language" using primary key columns */
  language_by_pk?: Maybe<language>;
  /** fetch data from the table in a streaming manner: "language" */
  language_stream: Array<language>;
  /** fetch data from the table: "meter" */
  meter: Array<meter>;
  /** fetch aggregated fields from the table: "meter" */
  meter_aggregate: meter_aggregate;
  /** fetch data from the table: "meter" using primary key columns */
  meter_by_pk?: Maybe<meter>;
  /** fetch data from the table: "meter_integration" */
  meter_integration: Array<meter_integration>;
  /** fetch aggregated fields from the table: "meter_integration" */
  meter_integration_aggregate: meter_integration_aggregate;
  /** fetch data from the table: "meter_integration_building" */
  meter_integration_building: Array<meter_integration_building>;
  /** fetch aggregated fields from the table: "meter_integration_building" */
  meter_integration_building_aggregate: meter_integration_building_aggregate;
  /** fetch data from the table: "meter_integration_building" using primary key columns */
  meter_integration_building_by_pk?: Maybe<meter_integration_building>;
  /** fetch data from the table in a streaming manner: "meter_integration_building" */
  meter_integration_building_stream: Array<meter_integration_building>;
  /** fetch data from the table: "meter_integration" using primary key columns */
  meter_integration_by_pk?: Maybe<meter_integration>;
  /** fetch data from the table in a streaming manner: "meter_integration" */
  meter_integration_stream: Array<meter_integration>;
  /** fetch data from the table: "meter_integration_type" */
  meter_integration_type: Array<meter_integration_type>;
  /** fetch aggregated fields from the table: "meter_integration_type" */
  meter_integration_type_aggregate: meter_integration_type_aggregate;
  /** fetch data from the table: "meter_integration_type" using primary key columns */
  meter_integration_type_by_pk?: Maybe<meter_integration_type>;
  /** fetch data from the table in a streaming manner: "meter_integration_type" */
  meter_integration_type_stream: Array<meter_integration_type>;
  /** fetch data from the table in a streaming manner: "meter" */
  meter_stream: Array<meter>;
  /** fetch data from the table: "notification" */
  notification: Array<notification>;
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: notification_aggregate;
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<notification>;
  /** fetch data from the table in a streaming manner: "notification" */
  notification_stream: Array<notification>;
  /** fetch data from the table: "notification_template" */
  notification_template: Array<notification_template>;
  /** fetch aggregated fields from the table: "notification_template" */
  notification_template_aggregate: notification_template_aggregate;
  /** fetch data from the table: "notification_template" using primary key columns */
  notification_template_by_pk?: Maybe<notification_template>;
  /** fetch data from the table in a streaming manner: "notification_template" */
  notification_template_stream: Array<notification_template>;
  /** fetch data from the table: "notification_type" */
  notification_type: Array<notification_type>;
  /** fetch aggregated fields from the table: "notification_type" */
  notification_type_aggregate: notification_type_aggregate;
  /** fetch data from the table: "notification_type" using primary key columns */
  notification_type_by_pk?: Maybe<notification_type>;
  /** fetch data from the table in a streaming manner: "notification_type" */
  notification_type_stream: Array<notification_type>;
  /** fetch data from the table: "operation_model_type" */
  operation_model_type: Array<operation_model_type>;
  /** fetch aggregated fields from the table: "operation_model_type" */
  operation_model_type_aggregate: operation_model_type_aggregate;
  /** fetch data from the table: "operation_model_type" using primary key columns */
  operation_model_type_by_pk?: Maybe<operation_model_type>;
  /** fetch data from the table in a streaming manner: "operation_model_type" */
  operation_model_type_stream: Array<operation_model_type>;
  /** fetch data from the table: "org" */
  org: Array<org>;
  /** fetch aggregated fields from the table: "org" */
  org_aggregate: org_aggregate;
  /** fetch data from the table: "org" using primary key columns */
  org_by_pk?: Maybe<org>;
  /** fetch data from the table: "org_preference" */
  org_preference: Array<org_preference>;
  /** fetch aggregated fields from the table: "org_preference" */
  org_preference_aggregate: org_preference_aggregate;
  /** fetch data from the table: "org_preference" using primary key columns */
  org_preference_by_pk?: Maybe<org_preference>;
  /** fetch data from the table in a streaming manner: "org_preference" */
  org_preference_stream: Array<org_preference>;
  /** fetch data from the table: "org_status_type" */
  org_status_type: Array<org_status_type>;
  /** fetch aggregated fields from the table: "org_status_type" */
  org_status_type_aggregate: org_status_type_aggregate;
  /** fetch data from the table: "org_status_type" using primary key columns */
  org_status_type_by_pk?: Maybe<org_status_type>;
  /** fetch data from the table in a streaming manner: "org_status_type" */
  org_status_type_stream: Array<org_status_type>;
  /** fetch data from the table in a streaming manner: "org" */
  org_stream: Array<org>;
  /** fetch data from the table: "portfolio" */
  portfolio: Array<portfolio>;
  /** fetch aggregated fields from the table: "portfolio" */
  portfolio_aggregate: portfolio_aggregate;
  /** fetch data from the table: "portfolio" using primary key columns */
  portfolio_by_pk?: Maybe<portfolio>;
  /** fetch data from the table in a streaming manner: "portfolio" */
  portfolio_stream: Array<portfolio>;
  /** fetch data from the table: "renovation" */
  renovation: Array<renovation>;
  /** fetch aggregated fields from the table: "renovation" */
  renovation_aggregate: renovation_aggregate;
  /** fetch data from the table: "renovation" using primary key columns */
  renovation_by_pk?: Maybe<renovation>;
  /** fetch data from the table: "renovation_energy_consumer" */
  renovation_energy_consumer: Array<renovation_energy_consumer>;
  /** fetch aggregated fields from the table: "renovation_energy_consumer" */
  renovation_energy_consumer_aggregate: renovation_energy_consumer_aggregate;
  /** fetch data from the table in a streaming manner: "renovation_energy_consumer" */
  renovation_energy_consumer_stream: Array<renovation_energy_consumer>;
  /** fetch data from the table: "renovation_energy_distribution" */
  renovation_energy_distribution: Array<renovation_energy_distribution>;
  /** fetch aggregated fields from the table: "renovation_energy_distribution" */
  renovation_energy_distribution_aggregate: renovation_energy_distribution_aggregate;
  /** fetch data from the table in a streaming manner: "renovation_energy_distribution" */
  renovation_energy_distribution_stream: Array<renovation_energy_distribution>;
  /** fetch data from the table: "renovation_energy_producer" */
  renovation_energy_producer: Array<renovation_energy_producer>;
  /** fetch aggregated fields from the table: "renovation_energy_producer" */
  renovation_energy_producer_aggregate: renovation_energy_producer_aggregate;
  /** fetch data from the table in a streaming manner: "renovation_energy_producer" */
  renovation_energy_producer_stream: Array<renovation_energy_producer>;
  /** fetch data from the table: "renovation_energy_storage" */
  renovation_energy_storage: Array<renovation_energy_storage>;
  /** fetch aggregated fields from the table: "renovation_energy_storage" */
  renovation_energy_storage_aggregate: renovation_energy_storage_aggregate;
  /** fetch data from the table in a streaming manner: "renovation_energy_storage" */
  renovation_energy_storage_stream: Array<renovation_energy_storage>;
  /** fetch data from the table: "renovation_energy_system_route" */
  renovation_energy_system_route: Array<renovation_energy_system_route>;
  /** fetch aggregated fields from the table: "renovation_energy_system_route" */
  renovation_energy_system_route_aggregate: renovation_energy_system_route_aggregate;
  /** fetch data from the table in a streaming manner: "renovation_energy_system_route" */
  renovation_energy_system_route_stream: Array<renovation_energy_system_route>;
  /** fetch data from the table: "renovation_envelope" */
  renovation_envelope: Array<renovation_envelope>;
  /** fetch aggregated fields from the table: "renovation_envelope" */
  renovation_envelope_aggregate: renovation_envelope_aggregate;
  /** fetch data from the table in a streaming manner: "renovation_envelope" */
  renovation_envelope_stream: Array<renovation_envelope>;
  /** fetch data from the table in a streaming manner: "renovation" */
  renovation_stream: Array<renovation>;
  /** fetch data from the table: "renovation_type" */
  renovation_type: Array<renovation_type>;
  /** fetch aggregated fields from the table: "renovation_type" */
  renovation_type_aggregate: renovation_type_aggregate;
  /** fetch data from the table: "renovation_type" using primary key columns */
  renovation_type_by_pk?: Maybe<renovation_type>;
  /** fetch data from the table in a streaming manner: "renovation_type" */
  renovation_type_stream: Array<renovation_type>;
  /** fetch data from the table: "report" */
  report: Array<report>;
  /** fetch aggregated fields from the table: "report" */
  report_aggregate: report_aggregate;
  /** fetch data from the table: "report_answer" */
  report_answer: Array<report_answer>;
  /** fetch aggregated fields from the table: "report_answer" */
  report_answer_aggregate: report_answer_aggregate;
  /** fetch data from the table: "report_answer_autofill_option" */
  report_answer_autofill_option: Array<report_answer_autofill_option>;
  /** fetch aggregated fields from the table: "report_answer_autofill_option" */
  report_answer_autofill_option_aggregate: report_answer_autofill_option_aggregate;
  /** fetch data from the table: "report_answer_autofill_option" using primary key columns */
  report_answer_autofill_option_by_pk?: Maybe<report_answer_autofill_option>;
  /** fetch data from the table in a streaming manner: "report_answer_autofill_option" */
  report_answer_autofill_option_stream: Array<report_answer_autofill_option>;
  /** fetch data from the table: "report_answer" using primary key columns */
  report_answer_by_pk?: Maybe<report_answer>;
  /** fetch data from the table: "report_answer_chosen_option" */
  report_answer_chosen_option: Array<report_answer_chosen_option>;
  /** fetch aggregated fields from the table: "report_answer_chosen_option" */
  report_answer_chosen_option_aggregate: report_answer_chosen_option_aggregate;
  /** fetch data from the table: "report_answer_chosen_option" using primary key columns */
  report_answer_chosen_option_by_pk?: Maybe<report_answer_chosen_option>;
  /** fetch data from the table in a streaming manner: "report_answer_chosen_option" */
  report_answer_chosen_option_stream: Array<report_answer_chosen_option>;
  /** fetch data from the table: "report_answer_state" */
  report_answer_state: Array<report_answer_state>;
  /** fetch aggregated fields from the table: "report_answer_state" */
  report_answer_state_aggregate: report_answer_state_aggregate;
  /** fetch data from the table: "report_answer_state" using primary key columns */
  report_answer_state_by_pk?: Maybe<report_answer_state>;
  /** fetch data from the table in a streaming manner: "report_answer_state" */
  report_answer_state_stream: Array<report_answer_state>;
  /** fetch data from the table in a streaming manner: "report_answer" */
  report_answer_stream: Array<report_answer>;
  /** fetch data from the table: "report" using primary key columns */
  report_by_pk?: Maybe<report>;
  /** fetch data from the table: "report_evidence" */
  report_evidence: Array<report_evidence>;
  /** fetch aggregated fields from the table: "report_evidence" */
  report_evidence_aggregate: report_evidence_aggregate;
  /** fetch data from the table: "report_evidence" using primary key columns */
  report_evidence_by_pk?: Maybe<report_evidence>;
  /** fetch data from the table in a streaming manner: "report_evidence" */
  report_evidence_stream: Array<report_evidence>;
  /** fetch data from the table: "report_question_evidence" */
  report_question_evidence: Array<report_question_evidence>;
  /** fetch aggregated fields from the table: "report_question_evidence" */
  report_question_evidence_aggregate: report_question_evidence_aggregate;
  /** fetch data from the table: "report_question_evidence" using primary key columns */
  report_question_evidence_by_pk?: Maybe<report_question_evidence>;
  /** fetch data from the table in a streaming manner: "report_question_evidence" */
  report_question_evidence_stream: Array<report_question_evidence>;
  /** fetch data from the table: "report_score" */
  report_score: Array<report_score>;
  /** fetch aggregated fields from the table: "report_score" */
  report_score_aggregate: report_score_aggregate;
  /** fetch data from the table: "report_score" using primary key columns */
  report_score_by_pk?: Maybe<report_score>;
  /** fetch data from the table in a streaming manner: "report_score" */
  report_score_stream: Array<report_score>;
  /** fetch data from the table: "report_section_progress" */
  report_section_progress: Array<report_section_progress>;
  /** fetch aggregated fields from the table: "report_section_progress" */
  report_section_progress_aggregate: report_section_progress_aggregate;
  /** fetch data from the table: "report_section_progress" using primary key columns */
  report_section_progress_by_pk?: Maybe<report_section_progress>;
  /** fetch data from the table in a streaming manner: "report_section_progress" */
  report_section_progress_stream: Array<report_section_progress>;
  /** fetch data from the table in a streaming manner: "report" */
  report_stream: Array<report>;
  /** fetch data from the table: "report_sub_building" */
  report_sub_building: Array<report_sub_building>;
  /** fetch aggregated fields from the table: "report_sub_building" */
  report_sub_building_aggregate: report_sub_building_aggregate;
  /** fetch data from the table: "report_sub_building" using primary key columns */
  report_sub_building_by_pk?: Maybe<report_sub_building>;
  /** fetch data from the table in a streaming manner: "report_sub_building" */
  report_sub_building_stream: Array<report_sub_building>;
  /** fetch data from the table: "report_template" */
  report_template: Array<report_template>;
  /** fetch aggregated fields from the table: "report_template" */
  report_template_aggregate: report_template_aggregate;
  /** fetch data from the table: "report_template" using primary key columns */
  report_template_by_pk?: Maybe<report_template>;
  /** fetch data from the table: "report_template_question" */
  report_template_question: Array<report_template_question>;
  /** fetch aggregated fields from the table: "report_template_question" */
  report_template_question_aggregate: report_template_question_aggregate;
  /** fetch data from the table: "report_template_question" using primary key columns */
  report_template_question_by_pk?: Maybe<report_template_question>;
  /** fetch data from the table: "report_template_question_option" */
  report_template_question_option: Array<report_template_question_option>;
  /** fetch aggregated fields from the table: "report_template_question_option" */
  report_template_question_option_aggregate: report_template_question_option_aggregate;
  /** fetch data from the table: "report_template_question_option" using primary key columns */
  report_template_question_option_by_pk?: Maybe<report_template_question_option>;
  /** fetch data from the table in a streaming manner: "report_template_question_option" */
  report_template_question_option_stream: Array<report_template_question_option>;
  /** fetch data from the table in a streaming manner: "report_template_question" */
  report_template_question_stream: Array<report_template_question>;
  /** fetch data from the table: "report_template_question_type" */
  report_template_question_type: Array<report_template_question_type>;
  /** fetch aggregated fields from the table: "report_template_question_type" */
  report_template_question_type_aggregate: report_template_question_type_aggregate;
  /** fetch data from the table: "report_template_question_type" using primary key columns */
  report_template_question_type_by_pk?: Maybe<report_template_question_type>;
  /** fetch data from the table in a streaming manner: "report_template_question_type" */
  report_template_question_type_stream: Array<report_template_question_type>;
  /** fetch data from the table: "report_template_score" */
  report_template_score: Array<report_template_score>;
  /** fetch aggregated fields from the table: "report_template_score" */
  report_template_score_aggregate: report_template_score_aggregate;
  /** fetch data from the table: "report_template_score" using primary key columns */
  report_template_score_by_pk?: Maybe<report_template_score>;
  /** fetch data from the table: "report_template_score_display_type" */
  report_template_score_display_type: Array<report_template_score_display_type>;
  /** fetch aggregated fields from the table: "report_template_score_display_type" */
  report_template_score_display_type_aggregate: report_template_score_display_type_aggregate;
  /** fetch data from the table: "report_template_score_display_type" using primary key columns */
  report_template_score_display_type_by_pk?: Maybe<report_template_score_display_type>;
  /** fetch data from the table in a streaming manner: "report_template_score_display_type" */
  report_template_score_display_type_stream: Array<report_template_score_display_type>;
  /** fetch data from the table in a streaming manner: "report_template_score" */
  report_template_score_stream: Array<report_template_score>;
  /** fetch data from the table: "report_template_section" */
  report_template_section: Array<report_template_section>;
  /** fetch aggregated fields from the table: "report_template_section" */
  report_template_section_aggregate: report_template_section_aggregate;
  /** fetch data from the table: "report_template_section" using primary key columns */
  report_template_section_by_pk?: Maybe<report_template_section>;
  /** fetch data from the table in a streaming manner: "report_template_section" */
  report_template_section_stream: Array<report_template_section>;
  /** fetch data from the table: "report_template_section_target" */
  report_template_section_target: Array<report_template_section_target>;
  /** fetch aggregated fields from the table: "report_template_section_target" */
  report_template_section_target_aggregate: report_template_section_target_aggregate;
  /** fetch data from the table: "report_template_section_target" using primary key columns */
  report_template_section_target_by_pk?: Maybe<report_template_section_target>;
  /** fetch data from the table in a streaming manner: "report_template_section_target" */
  report_template_section_target_stream: Array<report_template_section_target>;
  /** fetch data from the table in a streaming manner: "report_template" */
  report_template_stream: Array<report_template>;
  /** fetch data from the table: "report_template_to_all_questions" */
  report_template_to_all_questions: Array<report_template_to_all_questions>;
  /** fetch aggregated fields from the table: "report_template_to_all_questions" */
  report_template_to_all_questions_aggregate: report_template_to_all_questions_aggregate;
  /** fetch data from the table in a streaming manner: "report_template_to_all_questions" */
  report_template_to_all_questions_stream: Array<report_template_to_all_questions>;
  /** fetch data from the table: "role" */
  role: Array<role>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: role_aggregate;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<role>;
  /** fetch data from the table in a streaming manner: "role" */
  role_stream: Array<role>;
  /** fetch data from the table: "roof_type" */
  roof_type: Array<roof_type>;
  /** fetch aggregated fields from the table: "roof_type" */
  roof_type_aggregate: roof_type_aggregate;
  /** fetch data from the table: "roof_type" using primary key columns */
  roof_type_by_pk?: Maybe<roof_type>;
  /** fetch data from the table in a streaming manner: "roof_type" */
  roof_type_stream: Array<roof_type>;
  /** fetch data from the table: "scenario" */
  scenario: Array<scenario>;
  /** fetch aggregated fields from the table: "scenario" */
  scenario_aggregate: scenario_aggregate;
  /** fetch data from the table: "scenario" using primary key columns */
  scenario_by_pk?: Maybe<scenario>;
  /** fetch data from the table in a streaming manner: "scenario" */
  scenario_stream: Array<scenario>;
  /** fetch data from the table: "sub_building" */
  sub_building: Array<sub_building>;
  /** fetch aggregated fields from the table: "sub_building" */
  sub_building_aggregate: sub_building_aggregate;
  /** fetch data from the table: "sub_building" using primary key columns */
  sub_building_by_pk?: Maybe<sub_building>;
  /** fetch data from the table: "sub_building_class" */
  sub_building_class: Array<sub_building_class>;
  /** fetch aggregated fields from the table: "sub_building_class" */
  sub_building_class_aggregate: sub_building_class_aggregate;
  /** fetch data from the table: "sub_building_class" using primary key columns */
  sub_building_class_by_pk?: Maybe<sub_building_class>;
  /** fetch data from the table in a streaming manner: "sub_building_class" */
  sub_building_class_stream: Array<sub_building_class>;
  /** fetch data from the table in a streaming manner: "sub_building" */
  sub_building_stream: Array<sub_building>;
  /** fetch data from the table: "sub_building_subsidy" */
  sub_building_subsidy: Array<sub_building_subsidy>;
  /** fetch aggregated fields from the table: "sub_building_subsidy" */
  sub_building_subsidy_aggregate: sub_building_subsidy_aggregate;
  /** fetch data from the table: "sub_building_subsidy" using primary key columns */
  sub_building_subsidy_by_pk?: Maybe<sub_building_subsidy>;
  /** fetch data from the table in a streaming manner: "sub_building_subsidy" */
  sub_building_subsidy_stream: Array<sub_building_subsidy>;
  /** fetch data from the table: "subsidy" */
  subsidy: Array<subsidy>;
  /** fetch aggregated fields from the table: "subsidy" */
  subsidy_aggregate: subsidy_aggregate;
  /** fetch data from the table: "subsidy" using primary key columns */
  subsidy_by_pk?: Maybe<subsidy>;
  /** fetch data from the table: "subsidy_category_type" */
  subsidy_category_type: Array<subsidy_category_type>;
  /** fetch aggregated fields from the table: "subsidy_category_type" */
  subsidy_category_type_aggregate: subsidy_category_type_aggregate;
  /** fetch data from the table: "subsidy_category_type" using primary key columns */
  subsidy_category_type_by_pk?: Maybe<subsidy_category_type>;
  /** fetch data from the table in a streaming manner: "subsidy_category_type" */
  subsidy_category_type_stream: Array<subsidy_category_type>;
  /** fetch data from the table: "subsidy_region_type" */
  subsidy_region_type: Array<subsidy_region_type>;
  /** fetch aggregated fields from the table: "subsidy_region_type" */
  subsidy_region_type_aggregate: subsidy_region_type_aggregate;
  /** fetch data from the table: "subsidy_region_type" using primary key columns */
  subsidy_region_type_by_pk?: Maybe<subsidy_region_type>;
  /** fetch data from the table in a streaming manner: "subsidy_region_type" */
  subsidy_region_type_stream: Array<subsidy_region_type>;
  /** fetch data from the table in a streaming manner: "subsidy" */
  subsidy_stream: Array<subsidy>;
  /** fetch data from the table: "subsidy_subsidy_category" */
  subsidy_subsidy_category: Array<subsidy_subsidy_category>;
  /** fetch aggregated fields from the table: "subsidy_subsidy_category" */
  subsidy_subsidy_category_aggregate: subsidy_subsidy_category_aggregate;
  /** fetch data from the table: "subsidy_subsidy_category" using primary key columns */
  subsidy_subsidy_category_by_pk?: Maybe<subsidy_subsidy_category>;
  /** fetch data from the table in a streaming manner: "subsidy_subsidy_category" */
  subsidy_subsidy_category_stream: Array<subsidy_subsidy_category>;
  /** fetch data from the table: "subsidy_type" */
  subsidy_type: Array<subsidy_type>;
  /** fetch aggregated fields from the table: "subsidy_type" */
  subsidy_type_aggregate: subsidy_type_aggregate;
  /** fetch data from the table: "subsidy_type" using primary key columns */
  subsidy_type_by_pk?: Maybe<subsidy_type>;
  /** fetch data from the table in a streaming manner: "subsidy_type" */
  subsidy_type_stream: Array<subsidy_type>;
  /** fetch data from the table: "sync_state" */
  sync_state: Array<sync_state>;
  /** fetch aggregated fields from the table: "sync_state" */
  sync_state_aggregate: sync_state_aggregate;
  /** fetch data from the table: "sync_state" using primary key columns */
  sync_state_by_pk?: Maybe<sync_state>;
  /** fetch data from the table in a streaming manner: "sync_state" */
  sync_state_stream: Array<sync_state>;
  /** fetch data from the table: "target_path" */
  target_path: Array<target_path>;
  /** fetch aggregated fields from the table: "target_path" */
  target_path_aggregate: target_path_aggregate;
  /** fetch data from the table: "target_path" using primary key columns */
  target_path_by_pk?: Maybe<target_path>;
  /** fetch data from the table in a streaming manner: "target_path" */
  target_path_stream: Array<target_path>;
  /** fetch data from the table: "target_path_template" */
  target_path_template: Array<target_path_template>;
  /** fetch aggregated fields from the table: "target_path_template" */
  target_path_template_aggregate: target_path_template_aggregate;
  /** fetch data from the table: "target_path_template" using primary key columns */
  target_path_template_by_pk?: Maybe<target_path_template>;
  /** fetch data from the table in a streaming manner: "target_path_template" */
  target_path_template_stream: Array<target_path_template>;
  /** fetch data from the table: "target_path_template_type" */
  target_path_template_type: Array<target_path_template_type>;
  /** fetch aggregated fields from the table: "target_path_template_type" */
  target_path_template_type_aggregate: target_path_template_type_aggregate;
  /** fetch data from the table: "target_path_template_type" using primary key columns */
  target_path_template_type_by_pk?: Maybe<target_path_template_type>;
  /** fetch data from the table in a streaming manner: "target_path_template_type" */
  target_path_template_type_stream: Array<target_path_template_type>;
  /** fetch data from the table: "tax_bracket" */
  tax_bracket: Array<tax_bracket>;
  /** fetch aggregated fields from the table: "tax_bracket" */
  tax_bracket_aggregate: tax_bracket_aggregate;
  /** fetch data from the table: "tax_bracket" using primary key columns */
  tax_bracket_by_pk?: Maybe<tax_bracket>;
  /** fetch data from the table in a streaming manner: "tax_bracket" */
  tax_bracket_stream: Array<tax_bracket>;
  /** fetch data from the table: "type_of_use" */
  type_of_use: Array<type_of_use>;
  /** fetch aggregated fields from the table: "type_of_use" */
  type_of_use_aggregate: type_of_use_aggregate;
  /** fetch data from the table: "type_of_use" using primary key columns */
  type_of_use_by_pk?: Maybe<type_of_use>;
  /** fetch data from the table in a streaming manner: "type_of_use" */
  type_of_use_stream: Array<type_of_use>;
  /** fetch data from the table: "user" */
  user: Array<user>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: user_aggregate;
  /** fetch data from the table: "user_building_permission" */
  user_building_permission: Array<user_building_permission>;
  /** fetch aggregated fields from the table: "user_building_permission" */
  user_building_permission_aggregate: user_building_permission_aggregate;
  /** fetch data from the table: "user_building_permission" using primary key columns */
  user_building_permission_by_pk?: Maybe<user_building_permission>;
  /** fetch data from the table in a streaming manner: "user_building_permission" */
  user_building_permission_stream: Array<user_building_permission>;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<user>;
  /** fetch data from the table: "user_portfolio_permission" */
  user_portfolio_permission: Array<user_portfolio_permission>;
  /** fetch aggregated fields from the table: "user_portfolio_permission" */
  user_portfolio_permission_aggregate: user_portfolio_permission_aggregate;
  /** fetch data from the table: "user_portfolio_permission" using primary key columns */
  user_portfolio_permission_by_pk?: Maybe<user_portfolio_permission>;
  /** fetch data from the table in a streaming manner: "user_portfolio_permission" */
  user_portfolio_permission_stream: Array<user_portfolio_permission>;
  /** An array relationship */
  user_role: Array<user_role>;
  /** An aggregate relationship */
  user_role_aggregate: user_role_aggregate;
  /** fetch data from the table: "user_role" using primary key columns */
  user_role_by_pk?: Maybe<user_role>;
  /** fetch data from the table in a streaming manner: "user_role" */
  user_role_stream: Array<user_role>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<user>;
  /** fetch data from the table: "window_type" */
  window_type: Array<window_type>;
  /** fetch aggregated fields from the table: "window_type" */
  window_type_aggregate: window_type_aggregate;
  /** fetch data from the table: "window_type" using primary key columns */
  window_type_by_pk?: Maybe<window_type>;
  /** fetch data from the table in a streaming manner: "window_type" */
  window_type_stream: Array<window_type>;
};

export type subscription_rootactionArgs = {
  distinct_on?: InputMaybe<Array<action_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_order_by>>;
  where?: InputMaybe<action_bool_exp>;
};

export type subscription_rootaction_aggregateArgs = {
  distinct_on?: InputMaybe<Array<action_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_order_by>>;
  where?: InputMaybe<action_bool_exp>;
};

export type subscription_rootaction_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootaction_planArgs = {
  distinct_on?: InputMaybe<Array<action_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_plan_order_by>>;
  where?: InputMaybe<action_plan_bool_exp>;
};

export type subscription_rootaction_plan_aggregateArgs = {
  distinct_on?: InputMaybe<Array<action_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_plan_order_by>>;
  where?: InputMaybe<action_plan_bool_exp>;
};

export type subscription_rootaction_plan_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootaction_plan_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<action_plan_stream_cursor_input>>;
  where?: InputMaybe<action_plan_bool_exp>;
};

export type subscription_rootaction_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<action_stream_cursor_input>>;
  where?: InputMaybe<action_bool_exp>;
};

export type subscription_rootaction_subsidyArgs = {
  distinct_on?: InputMaybe<Array<action_subsidy_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_subsidy_order_by>>;
  where?: InputMaybe<action_subsidy_bool_exp>;
};

export type subscription_rootaction_subsidy_aggregateArgs = {
  distinct_on?: InputMaybe<Array<action_subsidy_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_subsidy_order_by>>;
  where?: InputMaybe<action_subsidy_bool_exp>;
};

export type subscription_rootaction_subsidy_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootaction_subsidy_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<action_subsidy_stream_cursor_input>>;
  where?: InputMaybe<action_subsidy_bool_exp>;
};

export type subscription_rootaction_subsidy_value_typeArgs = {
  distinct_on?: InputMaybe<Array<action_subsidy_value_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_subsidy_value_type_order_by>>;
  where?: InputMaybe<action_subsidy_value_type_bool_exp>;
};

export type subscription_rootaction_subsidy_value_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<action_subsidy_value_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_subsidy_value_type_order_by>>;
  where?: InputMaybe<action_subsidy_value_type_bool_exp>;
};

export type subscription_rootaction_subsidy_value_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootaction_subsidy_value_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<action_subsidy_value_type_stream_cursor_input>>;
  where?: InputMaybe<action_subsidy_value_type_bool_exp>;
};

export type subscription_rootaddressArgs = {
  distinct_on?: InputMaybe<Array<address_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<address_order_by>>;
  where?: InputMaybe<address_bool_exp>;
};

export type subscription_rootaddress_aggregateArgs = {
  distinct_on?: InputMaybe<Array<address_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<address_order_by>>;
  where?: InputMaybe<address_bool_exp>;
};

export type subscription_rootaddress_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootaddress_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<address_stream_cursor_input>>;
  where?: InputMaybe<address_bool_exp>;
};

export type subscription_rootapi_userArgs = {
  distinct_on?: InputMaybe<Array<api_user_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<api_user_order_by>>;
  where?: InputMaybe<api_user_bool_exp>;
};

export type subscription_rootapi_user_aggregateArgs = {
  distinct_on?: InputMaybe<Array<api_user_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<api_user_order_by>>;
  where?: InputMaybe<api_user_bool_exp>;
};

export type subscription_rootapi_user_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootapi_user_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<api_user_stream_cursor_input>>;
  where?: InputMaybe<api_user_bool_exp>;
};

export type subscription_rootareaArgs = {
  distinct_on?: InputMaybe<Array<area_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<area_order_by>>;
  where?: InputMaybe<area_bool_exp>;
};

export type subscription_rootarea_aggregateArgs = {
  distinct_on?: InputMaybe<Array<area_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<area_order_by>>;
  where?: InputMaybe<area_bool_exp>;
};

export type subscription_rootarea_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootarea_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<area_stream_cursor_input>>;
  where?: InputMaybe<area_bool_exp>;
};

export type subscription_rootarea_system_typeArgs = {
  distinct_on?: InputMaybe<Array<area_system_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<area_system_type_order_by>>;
  where?: InputMaybe<area_system_type_bool_exp>;
};

export type subscription_rootarea_system_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<area_system_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<area_system_type_order_by>>;
  where?: InputMaybe<area_system_type_bool_exp>;
};

export type subscription_rootarea_system_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootarea_system_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<area_system_type_stream_cursor_input>>;
  where?: InputMaybe<area_system_type_bool_exp>;
};

export type subscription_rootarea_typeArgs = {
  distinct_on?: InputMaybe<Array<area_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<area_type_order_by>>;
  where?: InputMaybe<area_type_bool_exp>;
};

export type subscription_rootarea_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<area_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<area_type_order_by>>;
  where?: InputMaybe<area_type_bool_exp>;
};

export type subscription_rootarea_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootarea_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<area_type_stream_cursor_input>>;
  where?: InputMaybe<area_type_bool_exp>;
};

export type subscription_rootasync_jobArgs = {
  distinct_on?: InputMaybe<Array<async_job_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<async_job_order_by>>;
  where?: InputMaybe<async_job_bool_exp>;
};

export type subscription_rootasync_job_aggregateArgs = {
  distinct_on?: InputMaybe<Array<async_job_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<async_job_order_by>>;
  where?: InputMaybe<async_job_bool_exp>;
};

export type subscription_rootasync_job_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootasync_job_status_typeArgs = {
  distinct_on?: InputMaybe<Array<async_job_status_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<async_job_status_type_order_by>>;
  where?: InputMaybe<async_job_status_type_bool_exp>;
};

export type subscription_rootasync_job_status_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<async_job_status_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<async_job_status_type_order_by>>;
  where?: InputMaybe<async_job_status_type_bool_exp>;
};

export type subscription_rootasync_job_status_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootasync_job_status_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<async_job_status_type_stream_cursor_input>>;
  where?: InputMaybe<async_job_status_type_bool_exp>;
};

export type subscription_rootasync_job_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<async_job_stream_cursor_input>>;
  where?: InputMaybe<async_job_bool_exp>;
};

export type subscription_rootbuildingArgs = {
  distinct_on?: InputMaybe<Array<building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<building_order_by>>;
  where?: InputMaybe<building_bool_exp>;
};

export type subscription_rootbuilding_aggregateArgs = {
  distinct_on?: InputMaybe<Array<building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<building_order_by>>;
  where?: InputMaybe<building_bool_exp>;
};

export type subscription_rootbuilding_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootbuilding_modelArgs = {
  distinct_on?: InputMaybe<Array<building_model_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<building_model_order_by>>;
  where?: InputMaybe<building_model_bool_exp>;
};

export type subscription_rootbuilding_model_aggregateArgs = {
  distinct_on?: InputMaybe<Array<building_model_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<building_model_order_by>>;
  where?: InputMaybe<building_model_bool_exp>;
};

export type subscription_rootbuilding_model_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootbuilding_model_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<building_model_stream_cursor_input>>;
  where?: InputMaybe<building_model_bool_exp>;
};

export type subscription_rootbuilding_stateArgs = {
  distinct_on?: InputMaybe<Array<building_state_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<building_state_order_by>>;
  where?: InputMaybe<building_state_bool_exp>;
};

export type subscription_rootbuilding_state_aggregateArgs = {
  distinct_on?: InputMaybe<Array<building_state_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<building_state_order_by>>;
  where?: InputMaybe<building_state_bool_exp>;
};

export type subscription_rootbuilding_state_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootbuilding_state_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<building_state_stream_cursor_input>>;
  where?: InputMaybe<building_state_bool_exp>;
};

export type subscription_rootbuilding_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<building_stream_cursor_input>>;
  where?: InputMaybe<building_bool_exp>;
};

export type subscription_rootclimate_regionArgs = {
  distinct_on?: InputMaybe<Array<climate_region_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<climate_region_order_by>>;
  where?: InputMaybe<climate_region_bool_exp>;
};

export type subscription_rootclimate_region_aggregateArgs = {
  distinct_on?: InputMaybe<Array<climate_region_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<climate_region_order_by>>;
  where?: InputMaybe<climate_region_bool_exp>;
};

export type subscription_rootclimate_region_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootclimate_region_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<climate_region_stream_cursor_input>>;
  where?: InputMaybe<climate_region_bool_exp>;
};

export type subscription_rootclimate_riskArgs = {
  distinct_on?: InputMaybe<Array<climate_risk_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<climate_risk_order_by>>;
  where?: InputMaybe<climate_risk_bool_exp>;
};

export type subscription_rootclimate_risk_aggregateArgs = {
  distinct_on?: InputMaybe<Array<climate_risk_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<climate_risk_order_by>>;
  where?: InputMaybe<climate_risk_bool_exp>;
};

export type subscription_rootclimate_risk_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootclimate_risk_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<climate_risk_stream_cursor_input>>;
  where?: InputMaybe<climate_risk_bool_exp>;
};

export type subscription_rootclimate_risk_typeArgs = {
  distinct_on?: InputMaybe<Array<climate_risk_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<climate_risk_type_order_by>>;
  where?: InputMaybe<climate_risk_type_bool_exp>;
};

export type subscription_rootclimate_risk_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<climate_risk_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<climate_risk_type_order_by>>;
  where?: InputMaybe<climate_risk_type_bool_exp>;
};

export type subscription_rootclimate_risk_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootclimate_risk_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<climate_risk_type_stream_cursor_input>>;
  where?: InputMaybe<climate_risk_type_bool_exp>;
};

export type subscription_rootconsumptionArgs = {
  distinct_on?: InputMaybe<Array<consumption_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_order_by>>;
  where?: InputMaybe<consumption_bool_exp>;
};

export type subscription_rootconsumption_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_order_by>>;
  where?: InputMaybe<consumption_bool_exp>;
};

export type subscription_rootconsumption_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootconsumption_draftArgs = {
  distinct_on?: InputMaybe<Array<consumption_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_draft_order_by>>;
  where?: InputMaybe<consumption_draft_bool_exp>;
};

export type subscription_rootconsumption_draft_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_draft_order_by>>;
  where?: InputMaybe<consumption_draft_bool_exp>;
};

export type subscription_rootconsumption_draft_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootconsumption_draft_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<consumption_draft_stream_cursor_input>>;
  where?: InputMaybe<consumption_draft_bool_exp>;
};

export type subscription_rootconsumption_frequency_typeArgs = {
  distinct_on?: InputMaybe<Array<consumption_frequency_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_frequency_type_order_by>>;
  where?: InputMaybe<consumption_frequency_type_bool_exp>;
};

export type subscription_rootconsumption_frequency_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_frequency_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_frequency_type_order_by>>;
  where?: InputMaybe<consumption_frequency_type_bool_exp>;
};

export type subscription_rootconsumption_frequency_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootconsumption_frequency_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<consumption_frequency_type_stream_cursor_input>>;
  where?: InputMaybe<consumption_frequency_type_bool_exp>;
};

export type subscription_rootconsumption_invoiceArgs = {
  distinct_on?: InputMaybe<Array<consumption_invoice_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_invoice_order_by>>;
  where?: InputMaybe<consumption_invoice_bool_exp>;
};

export type subscription_rootconsumption_invoice_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_invoice_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_invoice_order_by>>;
  where?: InputMaybe<consumption_invoice_bool_exp>;
};

export type subscription_rootconsumption_invoice_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootconsumption_invoice_draftArgs = {
  distinct_on?: InputMaybe<Array<consumption_invoice_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_invoice_draft_order_by>>;
  where?: InputMaybe<consumption_invoice_draft_bool_exp>;
};

export type subscription_rootconsumption_invoice_draft_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_invoice_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_invoice_draft_order_by>>;
  where?: InputMaybe<consumption_invoice_draft_bool_exp>;
};

export type subscription_rootconsumption_invoice_draft_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootconsumption_invoice_draft_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<consumption_invoice_draft_stream_cursor_input>>;
  where?: InputMaybe<consumption_invoice_draft_bool_exp>;
};

export type subscription_rootconsumption_invoice_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<consumption_invoice_stream_cursor_input>>;
  where?: InputMaybe<consumption_invoice_bool_exp>;
};

export type subscription_rootconsumption_source_typeArgs = {
  distinct_on?: InputMaybe<Array<consumption_source_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_source_type_order_by>>;
  where?: InputMaybe<consumption_source_type_bool_exp>;
};

export type subscription_rootconsumption_source_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_source_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_source_type_order_by>>;
  where?: InputMaybe<consumption_source_type_bool_exp>;
};

export type subscription_rootconsumption_source_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootconsumption_source_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<consumption_source_type_stream_cursor_input>>;
  where?: InputMaybe<consumption_source_type_bool_exp>;
};

export type subscription_rootconsumption_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<consumption_stream_cursor_input>>;
  where?: InputMaybe<consumption_bool_exp>;
};

export type subscription_rootconsumption_sub_typeArgs = {
  distinct_on?: InputMaybe<Array<consumption_sub_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_sub_type_order_by>>;
  where?: InputMaybe<consumption_sub_type_bool_exp>;
};

export type subscription_rootconsumption_sub_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_sub_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_sub_type_order_by>>;
  where?: InputMaybe<consumption_sub_type_bool_exp>;
};

export type subscription_rootconsumption_sub_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootconsumption_sub_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<consumption_sub_type_stream_cursor_input>>;
  where?: InputMaybe<consumption_sub_type_bool_exp>;
};

export type subscription_rootconsumption_typeArgs = {
  distinct_on?: InputMaybe<Array<consumption_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_type_order_by>>;
  where?: InputMaybe<consumption_type_bool_exp>;
};

export type subscription_rootconsumption_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_type_order_by>>;
  where?: InputMaybe<consumption_type_bool_exp>;
};

export type subscription_rootconsumption_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootconsumption_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<consumption_type_stream_cursor_input>>;
  where?: InputMaybe<consumption_type_bool_exp>;
};

export type subscription_rootconsumption_unitArgs = {
  distinct_on?: InputMaybe<Array<consumption_unit_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_unit_order_by>>;
  where?: InputMaybe<consumption_unit_bool_exp>;
};

export type subscription_rootconsumption_unit_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_unit_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_unit_order_by>>;
  where?: InputMaybe<consumption_unit_bool_exp>;
};

export type subscription_rootconsumption_unit_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootconsumption_unit_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<consumption_unit_stream_cursor_input>>;
  where?: InputMaybe<consumption_unit_bool_exp>;
};

export type subscription_rootconsumption_waste_detailsArgs = {
  distinct_on?: InputMaybe<Array<consumption_waste_details_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_waste_details_order_by>>;
  where?: InputMaybe<consumption_waste_details_bool_exp>;
};

export type subscription_rootconsumption_waste_details_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_waste_details_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_waste_details_order_by>>;
  where?: InputMaybe<consumption_waste_details_bool_exp>;
};

export type subscription_rootconsumption_waste_details_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootconsumption_waste_details_draftArgs = {
  distinct_on?: InputMaybe<Array<consumption_waste_details_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_waste_details_draft_order_by>>;
  where?: InputMaybe<consumption_waste_details_draft_bool_exp>;
};

export type subscription_rootconsumption_waste_details_draft_aggregateArgs = {
  distinct_on?: InputMaybe<Array<consumption_waste_details_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<consumption_waste_details_draft_order_by>>;
  where?: InputMaybe<consumption_waste_details_draft_bool_exp>;
};

export type subscription_rootconsumption_waste_details_draft_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootconsumption_waste_details_draft_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<consumption_waste_details_draft_stream_cursor_input>>;
  where?: InputMaybe<consumption_waste_details_draft_bool_exp>;
};

export type subscription_rootconsumption_waste_details_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<consumption_waste_details_stream_cursor_input>>;
  where?: InputMaybe<consumption_waste_details_bool_exp>;
};

export type subscription_rootcountryArgs = {
  distinct_on?: InputMaybe<Array<country_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<country_order_by>>;
  where?: InputMaybe<country_bool_exp>;
};

export type subscription_rootcountry_aggregateArgs = {
  distinct_on?: InputMaybe<Array<country_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<country_order_by>>;
  where?: InputMaybe<country_bool_exp>;
};

export type subscription_rootcountry_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootcountry_stateArgs = {
  distinct_on?: InputMaybe<Array<country_state_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<country_state_order_by>>;
  where?: InputMaybe<country_state_bool_exp>;
};

export type subscription_rootcountry_state_aggregateArgs = {
  distinct_on?: InputMaybe<Array<country_state_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<country_state_order_by>>;
  where?: InputMaybe<country_state_bool_exp>;
};

export type subscription_rootcountry_state_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootcountry_state_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<country_state_stream_cursor_input>>;
  where?: InputMaybe<country_state_bool_exp>;
};

export type subscription_rootcountry_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<country_stream_cursor_input>>;
  where?: InputMaybe<country_bool_exp>;
};

export type subscription_rootdata_source_typeArgs = {
  distinct_on?: InputMaybe<Array<data_source_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<data_source_type_order_by>>;
  where?: InputMaybe<data_source_type_bool_exp>;
};

export type subscription_rootdata_source_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<data_source_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<data_source_type_order_by>>;
  where?: InputMaybe<data_source_type_bool_exp>;
};

export type subscription_rootdata_source_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootdata_source_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<data_source_type_stream_cursor_input>>;
  where?: InputMaybe<data_source_type_bool_exp>;
};

export type subscription_rootdraft_statusArgs = {
  distinct_on?: InputMaybe<Array<draft_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<draft_status_order_by>>;
  where?: InputMaybe<draft_status_bool_exp>;
};

export type subscription_rootdraft_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<draft_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<draft_status_order_by>>;
  where?: InputMaybe<draft_status_bool_exp>;
};

export type subscription_rootdraft_status_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootdraft_status_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<draft_status_stream_cursor_input>>;
  where?: InputMaybe<draft_status_bool_exp>;
};

export type subscription_rooteconomic_unitArgs = {
  distinct_on?: InputMaybe<Array<economic_unit_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<economic_unit_order_by>>;
  where?: InputMaybe<economic_unit_bool_exp>;
};

export type subscription_rooteconomic_unit_aggregateArgs = {
  distinct_on?: InputMaybe<Array<economic_unit_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<economic_unit_order_by>>;
  where?: InputMaybe<economic_unit_bool_exp>;
};

export type subscription_rooteconomic_unit_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rooteconomic_unit_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<economic_unit_stream_cursor_input>>;
  where?: InputMaybe<economic_unit_bool_exp>;
};

export type subscription_rootefficiency_classArgs = {
  distinct_on?: InputMaybe<Array<efficiency_class_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<efficiency_class_order_by>>;
  where?: InputMaybe<efficiency_class_bool_exp>;
};

export type subscription_rootefficiency_class_aggregateArgs = {
  distinct_on?: InputMaybe<Array<efficiency_class_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<efficiency_class_order_by>>;
  where?: InputMaybe<efficiency_class_bool_exp>;
};

export type subscription_rootefficiency_class_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootefficiency_class_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<efficiency_class_stream_cursor_input>>;
  where?: InputMaybe<efficiency_class_bool_exp>;
};

export type subscription_rootemission_certificateArgs = {
  distinct_on?: InputMaybe<Array<emission_certificate_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<emission_certificate_order_by>>;
  where?: InputMaybe<emission_certificate_bool_exp>;
};

export type subscription_rootemission_certificate_aggregateArgs = {
  distinct_on?: InputMaybe<Array<emission_certificate_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<emission_certificate_order_by>>;
  where?: InputMaybe<emission_certificate_bool_exp>;
};

export type subscription_rootemission_certificate_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootemission_certificate_draftArgs = {
  distinct_on?: InputMaybe<Array<emission_certificate_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<emission_certificate_draft_order_by>>;
  where?: InputMaybe<emission_certificate_draft_bool_exp>;
};

export type subscription_rootemission_certificate_draft_aggregateArgs = {
  distinct_on?: InputMaybe<Array<emission_certificate_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<emission_certificate_draft_order_by>>;
  where?: InputMaybe<emission_certificate_draft_bool_exp>;
};

export type subscription_rootemission_certificate_draft_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootemission_certificate_draft_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<emission_certificate_draft_stream_cursor_input>>;
  where?: InputMaybe<emission_certificate_draft_bool_exp>;
};

export type subscription_rootemission_certificate_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<emission_certificate_stream_cursor_input>>;
  where?: InputMaybe<emission_certificate_bool_exp>;
};

export type subscription_rootemission_factor_originArgs = {
  distinct_on?: InputMaybe<Array<emission_factor_origin_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<emission_factor_origin_order_by>>;
  where?: InputMaybe<emission_factor_origin_bool_exp>;
};

export type subscription_rootemission_factor_origin_aggregateArgs = {
  distinct_on?: InputMaybe<Array<emission_factor_origin_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<emission_factor_origin_order_by>>;
  where?: InputMaybe<emission_factor_origin_bool_exp>;
};

export type subscription_rootemission_factor_origin_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootemission_factor_origin_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<emission_factor_origin_stream_cursor_input>>;
  where?: InputMaybe<emission_factor_origin_bool_exp>;
};

export type subscription_rootemission_factor_typeArgs = {
  distinct_on?: InputMaybe<Array<emission_factor_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<emission_factor_type_order_by>>;
  where?: InputMaybe<emission_factor_type_bool_exp>;
};

export type subscription_rootemission_factor_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<emission_factor_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<emission_factor_type_order_by>>;
  where?: InputMaybe<emission_factor_type_bool_exp>;
};

export type subscription_rootemission_factor_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootemission_factor_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<emission_factor_type_stream_cursor_input>>;
  where?: InputMaybe<emission_factor_type_bool_exp>;
};

export type subscription_rootenergy_certificateArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_order_by>>;
  where?: InputMaybe<energy_certificate_bool_exp>;
};

export type subscription_rootenergy_certificate_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_order_by>>;
  where?: InputMaybe<energy_certificate_bool_exp>;
};

export type subscription_rootenergy_certificate_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootenergy_certificate_consumerArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_consumer_order_by>>;
  where?: InputMaybe<energy_certificate_consumer_bool_exp>;
};

export type subscription_rootenergy_certificate_consumer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_consumer_order_by>>;
  where?: InputMaybe<energy_certificate_consumer_bool_exp>;
};

export type subscription_rootenergy_certificate_consumer_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootenergy_certificate_consumer_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<energy_certificate_consumer_stream_cursor_input>>;
  where?: InputMaybe<energy_certificate_consumer_bool_exp>;
};

export type subscription_rootenergy_certificate_draftArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_draft_order_by>>;
  where?: InputMaybe<energy_certificate_draft_bool_exp>;
};

export type subscription_rootenergy_certificate_draft_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_draft_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_draft_order_by>>;
  where?: InputMaybe<energy_certificate_draft_bool_exp>;
};

export type subscription_rootenergy_certificate_draft_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootenergy_certificate_draft_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<energy_certificate_draft_stream_cursor_input>>;
  where?: InputMaybe<energy_certificate_draft_bool_exp>;
};

export type subscription_rootenergy_certificate_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<energy_certificate_stream_cursor_input>>;
  where?: InputMaybe<energy_certificate_bool_exp>;
};

export type subscription_rootenergy_certificate_typeArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_type_order_by>>;
  where?: InputMaybe<energy_certificate_type_bool_exp>;
};

export type subscription_rootenergy_certificate_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_certificate_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_certificate_type_order_by>>;
  where?: InputMaybe<energy_certificate_type_bool_exp>;
};

export type subscription_rootenergy_certificate_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootenergy_certificate_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<energy_certificate_type_stream_cursor_input>>;
  where?: InputMaybe<energy_certificate_type_bool_exp>;
};

export type subscription_rootenergy_consumerArgs = {
  distinct_on?: InputMaybe<Array<energy_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_consumer_order_by>>;
  where?: InputMaybe<energy_consumer_bool_exp>;
};

export type subscription_rootenergy_consumer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_consumer_order_by>>;
  where?: InputMaybe<energy_consumer_bool_exp>;
};

export type subscription_rootenergy_consumer_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootenergy_consumer_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<energy_consumer_stream_cursor_input>>;
  where?: InputMaybe<energy_consumer_bool_exp>;
};

export type subscription_rootenergy_consumer_technology_typeArgs = {
  distinct_on?: InputMaybe<Array<energy_consumer_technology_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_consumer_technology_type_order_by>>;
  where?: InputMaybe<energy_consumer_technology_type_bool_exp>;
};

export type subscription_rootenergy_consumer_technology_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_consumer_technology_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_consumer_technology_type_order_by>>;
  where?: InputMaybe<energy_consumer_technology_type_bool_exp>;
};

export type subscription_rootenergy_consumer_technology_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootenergy_consumer_technology_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<energy_consumer_technology_type_stream_cursor_input>>;
  where?: InputMaybe<energy_consumer_technology_type_bool_exp>;
};

export type subscription_rootenergy_consumer_typeArgs = {
  distinct_on?: InputMaybe<Array<energy_consumer_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_consumer_type_order_by>>;
  where?: InputMaybe<energy_consumer_type_bool_exp>;
};

export type subscription_rootenergy_consumer_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_consumer_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_consumer_type_order_by>>;
  where?: InputMaybe<energy_consumer_type_bool_exp>;
};

export type subscription_rootenergy_consumer_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootenergy_consumer_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<energy_consumer_type_stream_cursor_input>>;
  where?: InputMaybe<energy_consumer_type_bool_exp>;
};

export type subscription_rootenergy_distributionArgs = {
  distinct_on?: InputMaybe<Array<energy_distribution_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_distribution_order_by>>;
  where?: InputMaybe<energy_distribution_bool_exp>;
};

export type subscription_rootenergy_distribution_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_distribution_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_distribution_order_by>>;
  where?: InputMaybe<energy_distribution_bool_exp>;
};

export type subscription_rootenergy_distribution_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootenergy_distribution_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<energy_distribution_stream_cursor_input>>;
  where?: InputMaybe<energy_distribution_bool_exp>;
};

export type subscription_rootenergy_distribution_typeArgs = {
  distinct_on?: InputMaybe<Array<energy_distribution_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_distribution_type_order_by>>;
  where?: InputMaybe<energy_distribution_type_bool_exp>;
};

export type subscription_rootenergy_distribution_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_distribution_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_distribution_type_order_by>>;
  where?: InputMaybe<energy_distribution_type_bool_exp>;
};

export type subscription_rootenergy_distribution_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootenergy_distribution_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<energy_distribution_type_stream_cursor_input>>;
  where?: InputMaybe<energy_distribution_type_bool_exp>;
};

export type subscription_rootenergy_pathArgs = {
  distinct_on?: InputMaybe<Array<energy_path_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_path_order_by>>;
  where?: InputMaybe<energy_path_bool_exp>;
};

export type subscription_rootenergy_path_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_path_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_path_order_by>>;
  where?: InputMaybe<energy_path_bool_exp>;
};

export type subscription_rootenergy_path_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootenergy_path_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<energy_path_stream_cursor_input>>;
  where?: InputMaybe<energy_path_bool_exp>;
};

export type subscription_rootenergy_producerArgs = {
  distinct_on?: InputMaybe<Array<energy_producer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_producer_order_by>>;
  where?: InputMaybe<energy_producer_bool_exp>;
};

export type subscription_rootenergy_producer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_producer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_producer_order_by>>;
  where?: InputMaybe<energy_producer_bool_exp>;
};

export type subscription_rootenergy_producer_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootenergy_producer_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<energy_producer_stream_cursor_input>>;
  where?: InputMaybe<energy_producer_bool_exp>;
};

export type subscription_rootenergy_sourceArgs = {
  distinct_on?: InputMaybe<Array<energy_source_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_source_order_by>>;
  where?: InputMaybe<energy_source_bool_exp>;
};

export type subscription_rootenergy_source_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_source_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_source_order_by>>;
  where?: InputMaybe<energy_source_bool_exp>;
};

export type subscription_rootenergy_source_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootenergy_source_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<energy_source_stream_cursor_input>>;
  where?: InputMaybe<energy_source_bool_exp>;
};

export type subscription_rootenergy_source_typeArgs = {
  distinct_on?: InputMaybe<Array<energy_source_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_source_type_order_by>>;
  where?: InputMaybe<energy_source_type_bool_exp>;
};

export type subscription_rootenergy_source_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_source_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_source_type_order_by>>;
  where?: InputMaybe<energy_source_type_bool_exp>;
};

export type subscription_rootenergy_source_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootenergy_source_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<energy_source_type_stream_cursor_input>>;
  where?: InputMaybe<energy_source_type_bool_exp>;
};

export type subscription_rootenergy_standardArgs = {
  distinct_on?: InputMaybe<Array<energy_standard_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_standard_order_by>>;
  where?: InputMaybe<energy_standard_bool_exp>;
};

export type subscription_rootenergy_standard_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_standard_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_standard_order_by>>;
  where?: InputMaybe<energy_standard_bool_exp>;
};

export type subscription_rootenergy_standard_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootenergy_standard_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<energy_standard_stream_cursor_input>>;
  where?: InputMaybe<energy_standard_bool_exp>;
};

export type subscription_rootenergy_storageArgs = {
  distinct_on?: InputMaybe<Array<energy_storage_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_storage_order_by>>;
  where?: InputMaybe<energy_storage_bool_exp>;
};

export type subscription_rootenergy_storage_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_storage_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_storage_order_by>>;
  where?: InputMaybe<energy_storage_bool_exp>;
};

export type subscription_rootenergy_storage_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootenergy_storage_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<energy_storage_stream_cursor_input>>;
  where?: InputMaybe<energy_storage_bool_exp>;
};

export type subscription_rootenergy_storage_typeArgs = {
  distinct_on?: InputMaybe<Array<energy_storage_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_storage_type_order_by>>;
  where?: InputMaybe<energy_storage_type_bool_exp>;
};

export type subscription_rootenergy_storage_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_storage_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_storage_type_order_by>>;
  where?: InputMaybe<energy_storage_type_bool_exp>;
};

export type subscription_rootenergy_storage_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootenergy_storage_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<energy_storage_type_stream_cursor_input>>;
  where?: InputMaybe<energy_storage_type_bool_exp>;
};

export type subscription_rootenergy_systemArgs = {
  distinct_on?: InputMaybe<Array<energy_system_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_order_by>>;
  where?: InputMaybe<energy_system_bool_exp>;
};

export type subscription_rootenergy_system_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_system_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_order_by>>;
  where?: InputMaybe<energy_system_bool_exp>;
};

export type subscription_rootenergy_system_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootenergy_system_consumer_routeArgs = {
  distinct_on?: InputMaybe<Array<energy_system_consumer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_consumer_route_order_by>>;
  where?: InputMaybe<energy_system_consumer_route_bool_exp>;
};

export type subscription_rootenergy_system_consumer_route_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_system_consumer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_consumer_route_order_by>>;
  where?: InputMaybe<energy_system_consumer_route_bool_exp>;
};

export type subscription_rootenergy_system_consumer_route_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootenergy_system_consumer_route_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<energy_system_consumer_route_stream_cursor_input>>;
  where?: InputMaybe<energy_system_consumer_route_bool_exp>;
};

export type subscription_rootenergy_system_producer_routeArgs = {
  distinct_on?: InputMaybe<Array<energy_system_producer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_producer_route_order_by>>;
  where?: InputMaybe<energy_system_producer_route_bool_exp>;
};

export type subscription_rootenergy_system_producer_route_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_system_producer_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_producer_route_order_by>>;
  where?: InputMaybe<energy_system_producer_route_bool_exp>;
};

export type subscription_rootenergy_system_producer_route_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootenergy_system_producer_route_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<energy_system_producer_route_stream_cursor_input>>;
  where?: InputMaybe<energy_system_producer_route_bool_exp>;
};

export type subscription_rootenergy_system_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<energy_system_stream_cursor_input>>;
  where?: InputMaybe<energy_system_bool_exp>;
};

export type subscription_rootenergy_system_typeArgs = {
  distinct_on?: InputMaybe<Array<energy_system_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_type_order_by>>;
  where?: InputMaybe<energy_system_type_bool_exp>;
};

export type subscription_rootenergy_system_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<energy_system_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<energy_system_type_order_by>>;
  where?: InputMaybe<energy_system_type_bool_exp>;
};

export type subscription_rootenergy_system_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootenergy_system_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<energy_system_type_stream_cursor_input>>;
  where?: InputMaybe<energy_system_type_bool_exp>;
};

export type subscription_rootenvelopeArgs = {
  distinct_on?: InputMaybe<Array<envelope_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<envelope_order_by>>;
  where?: InputMaybe<envelope_bool_exp>;
};

export type subscription_rootenvelope_aggregateArgs = {
  distinct_on?: InputMaybe<Array<envelope_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<envelope_order_by>>;
  where?: InputMaybe<envelope_bool_exp>;
};

export type subscription_rootenvelope_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootenvelope_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<envelope_stream_cursor_input>>;
  where?: InputMaybe<envelope_bool_exp>;
};

export type subscription_rootenvelope_typeArgs = {
  distinct_on?: InputMaybe<Array<envelope_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<envelope_type_order_by>>;
  where?: InputMaybe<envelope_type_bool_exp>;
};

export type subscription_rootenvelope_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<envelope_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<envelope_type_order_by>>;
  where?: InputMaybe<envelope_type_bool_exp>;
};

export type subscription_rootenvelope_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootenvelope_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<envelope_type_stream_cursor_input>>;
  where?: InputMaybe<envelope_type_bool_exp>;
};

export type subscription_rooteu_taxonomy_complianceArgs = {
  distinct_on?: InputMaybe<Array<eu_taxonomy_compliance_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<eu_taxonomy_compliance_order_by>>;
  where?: InputMaybe<eu_taxonomy_compliance_bool_exp>;
};

export type subscription_rooteu_taxonomy_compliance_aggregateArgs = {
  distinct_on?: InputMaybe<Array<eu_taxonomy_compliance_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<eu_taxonomy_compliance_order_by>>;
  where?: InputMaybe<eu_taxonomy_compliance_bool_exp>;
};

export type subscription_rooteu_taxonomy_compliance_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rooteu_taxonomy_compliance_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<eu_taxonomy_compliance_stream_cursor_input>>;
  where?: InputMaybe<eu_taxonomy_compliance_bool_exp>;
};

export type subscription_rootfileArgs = {
  distinct_on?: InputMaybe<Array<file_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<file_order_by>>;
  where?: InputMaybe<file_bool_exp>;
};

export type subscription_rootfile_aggregateArgs = {
  distinct_on?: InputMaybe<Array<file_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<file_order_by>>;
  where?: InputMaybe<file_bool_exp>;
};

export type subscription_rootfile_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootfile_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<file_stream_cursor_input>>;
  where?: InputMaybe<file_bool_exp>;
};

export type subscription_rootinsulation_material_categoryArgs = {
  distinct_on?: InputMaybe<Array<insulation_material_category_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<insulation_material_category_order_by>>;
  where?: InputMaybe<insulation_material_category_bool_exp>;
};

export type subscription_rootinsulation_material_category_aggregateArgs = {
  distinct_on?: InputMaybe<Array<insulation_material_category_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<insulation_material_category_order_by>>;
  where?: InputMaybe<insulation_material_category_bool_exp>;
};

export type subscription_rootinsulation_material_category_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootinsulation_material_category_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<insulation_material_category_stream_cursor_input>>;
  where?: InputMaybe<insulation_material_category_bool_exp>;
};

export type subscription_rootinsulation_methodArgs = {
  distinct_on?: InputMaybe<Array<insulation_method_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<insulation_method_order_by>>;
  where?: InputMaybe<insulation_method_bool_exp>;
};

export type subscription_rootinsulation_method_aggregateArgs = {
  distinct_on?: InputMaybe<Array<insulation_method_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<insulation_method_order_by>>;
  where?: InputMaybe<insulation_method_bool_exp>;
};

export type subscription_rootinsulation_method_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootinsulation_method_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<insulation_method_stream_cursor_input>>;
  where?: InputMaybe<insulation_method_bool_exp>;
};

export type subscription_rootinvoice_payer_typeArgs = {
  distinct_on?: InputMaybe<Array<invoice_payer_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<invoice_payer_type_order_by>>;
  where?: InputMaybe<invoice_payer_type_bool_exp>;
};

export type subscription_rootinvoice_payer_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<invoice_payer_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<invoice_payer_type_order_by>>;
  where?: InputMaybe<invoice_payer_type_bool_exp>;
};

export type subscription_rootinvoice_payer_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootinvoice_payer_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<invoice_payer_type_stream_cursor_input>>;
  where?: InputMaybe<invoice_payer_type_bool_exp>;
};

export type subscription_rootlangfuse_datasets_temporary_itemArgs = {
  distinct_on?: InputMaybe<Array<langfuse_datasets_temporary_item_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<langfuse_datasets_temporary_item_order_by>>;
  where?: InputMaybe<langfuse_datasets_temporary_item_bool_exp>;
};

export type subscription_rootlangfuse_datasets_temporary_item_aggregateArgs = {
  distinct_on?: InputMaybe<Array<langfuse_datasets_temporary_item_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<langfuse_datasets_temporary_item_order_by>>;
  where?: InputMaybe<langfuse_datasets_temporary_item_bool_exp>;
};

export type subscription_rootlangfuse_datasets_temporary_item_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootlangfuse_datasets_temporary_item_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<langfuse_datasets_temporary_item_stream_cursor_input>>;
  where?: InputMaybe<langfuse_datasets_temporary_item_bool_exp>;
};

export type subscription_rootlanguageArgs = {
  distinct_on?: InputMaybe<Array<language_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<language_order_by>>;
  where?: InputMaybe<language_bool_exp>;
};

export type subscription_rootlanguage_aggregateArgs = {
  distinct_on?: InputMaybe<Array<language_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<language_order_by>>;
  where?: InputMaybe<language_bool_exp>;
};

export type subscription_rootlanguage_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootlanguage_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<language_stream_cursor_input>>;
  where?: InputMaybe<language_bool_exp>;
};

export type subscription_rootmeterArgs = {
  distinct_on?: InputMaybe<Array<meter_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<meter_order_by>>;
  where?: InputMaybe<meter_bool_exp>;
};

export type subscription_rootmeter_aggregateArgs = {
  distinct_on?: InputMaybe<Array<meter_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<meter_order_by>>;
  where?: InputMaybe<meter_bool_exp>;
};

export type subscription_rootmeter_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootmeter_integrationArgs = {
  distinct_on?: InputMaybe<Array<meter_integration_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<meter_integration_order_by>>;
  where?: InputMaybe<meter_integration_bool_exp>;
};

export type subscription_rootmeter_integration_aggregateArgs = {
  distinct_on?: InputMaybe<Array<meter_integration_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<meter_integration_order_by>>;
  where?: InputMaybe<meter_integration_bool_exp>;
};

export type subscription_rootmeter_integration_buildingArgs = {
  distinct_on?: InputMaybe<Array<meter_integration_building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<meter_integration_building_order_by>>;
  where?: InputMaybe<meter_integration_building_bool_exp>;
};

export type subscription_rootmeter_integration_building_aggregateArgs = {
  distinct_on?: InputMaybe<Array<meter_integration_building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<meter_integration_building_order_by>>;
  where?: InputMaybe<meter_integration_building_bool_exp>;
};

export type subscription_rootmeter_integration_building_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootmeter_integration_building_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<meter_integration_building_stream_cursor_input>>;
  where?: InputMaybe<meter_integration_building_bool_exp>;
};

export type subscription_rootmeter_integration_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootmeter_integration_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<meter_integration_stream_cursor_input>>;
  where?: InputMaybe<meter_integration_bool_exp>;
};

export type subscription_rootmeter_integration_typeArgs = {
  distinct_on?: InputMaybe<Array<meter_integration_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<meter_integration_type_order_by>>;
  where?: InputMaybe<meter_integration_type_bool_exp>;
};

export type subscription_rootmeter_integration_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<meter_integration_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<meter_integration_type_order_by>>;
  where?: InputMaybe<meter_integration_type_bool_exp>;
};

export type subscription_rootmeter_integration_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootmeter_integration_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<meter_integration_type_stream_cursor_input>>;
  where?: InputMaybe<meter_integration_type_bool_exp>;
};

export type subscription_rootmeter_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<meter_stream_cursor_input>>;
  where?: InputMaybe<meter_bool_exp>;
};

export type subscription_rootnotificationArgs = {
  distinct_on?: InputMaybe<Array<notification_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<notification_order_by>>;
  where?: InputMaybe<notification_bool_exp>;
};

export type subscription_rootnotification_aggregateArgs = {
  distinct_on?: InputMaybe<Array<notification_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<notification_order_by>>;
  where?: InputMaybe<notification_bool_exp>;
};

export type subscription_rootnotification_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootnotification_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<notification_stream_cursor_input>>;
  where?: InputMaybe<notification_bool_exp>;
};

export type subscription_rootnotification_templateArgs = {
  distinct_on?: InputMaybe<Array<notification_template_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<notification_template_order_by>>;
  where?: InputMaybe<notification_template_bool_exp>;
};

export type subscription_rootnotification_template_aggregateArgs = {
  distinct_on?: InputMaybe<Array<notification_template_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<notification_template_order_by>>;
  where?: InputMaybe<notification_template_bool_exp>;
};

export type subscription_rootnotification_template_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootnotification_template_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<notification_template_stream_cursor_input>>;
  where?: InputMaybe<notification_template_bool_exp>;
};

export type subscription_rootnotification_typeArgs = {
  distinct_on?: InputMaybe<Array<notification_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<notification_type_order_by>>;
  where?: InputMaybe<notification_type_bool_exp>;
};

export type subscription_rootnotification_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<notification_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<notification_type_order_by>>;
  where?: InputMaybe<notification_type_bool_exp>;
};

export type subscription_rootnotification_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootnotification_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<notification_type_stream_cursor_input>>;
  where?: InputMaybe<notification_type_bool_exp>;
};

export type subscription_rootoperation_model_typeArgs = {
  distinct_on?: InputMaybe<Array<operation_model_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<operation_model_type_order_by>>;
  where?: InputMaybe<operation_model_type_bool_exp>;
};

export type subscription_rootoperation_model_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<operation_model_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<operation_model_type_order_by>>;
  where?: InputMaybe<operation_model_type_bool_exp>;
};

export type subscription_rootoperation_model_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootoperation_model_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<operation_model_type_stream_cursor_input>>;
  where?: InputMaybe<operation_model_type_bool_exp>;
};

export type subscription_rootorgArgs = {
  distinct_on?: InputMaybe<Array<org_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<org_order_by>>;
  where?: InputMaybe<org_bool_exp>;
};

export type subscription_rootorg_aggregateArgs = {
  distinct_on?: InputMaybe<Array<org_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<org_order_by>>;
  where?: InputMaybe<org_bool_exp>;
};

export type subscription_rootorg_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootorg_preferenceArgs = {
  distinct_on?: InputMaybe<Array<org_preference_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<org_preference_order_by>>;
  where?: InputMaybe<org_preference_bool_exp>;
};

export type subscription_rootorg_preference_aggregateArgs = {
  distinct_on?: InputMaybe<Array<org_preference_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<org_preference_order_by>>;
  where?: InputMaybe<org_preference_bool_exp>;
};

export type subscription_rootorg_preference_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootorg_preference_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<org_preference_stream_cursor_input>>;
  where?: InputMaybe<org_preference_bool_exp>;
};

export type subscription_rootorg_status_typeArgs = {
  distinct_on?: InputMaybe<Array<org_status_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<org_status_type_order_by>>;
  where?: InputMaybe<org_status_type_bool_exp>;
};

export type subscription_rootorg_status_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<org_status_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<org_status_type_order_by>>;
  where?: InputMaybe<org_status_type_bool_exp>;
};

export type subscription_rootorg_status_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootorg_status_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<org_status_type_stream_cursor_input>>;
  where?: InputMaybe<org_status_type_bool_exp>;
};

export type subscription_rootorg_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<org_stream_cursor_input>>;
  where?: InputMaybe<org_bool_exp>;
};

export type subscription_rootportfolioArgs = {
  distinct_on?: InputMaybe<Array<portfolio_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<portfolio_order_by>>;
  where?: InputMaybe<portfolio_bool_exp>;
};

export type subscription_rootportfolio_aggregateArgs = {
  distinct_on?: InputMaybe<Array<portfolio_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<portfolio_order_by>>;
  where?: InputMaybe<portfolio_bool_exp>;
};

export type subscription_rootportfolio_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootportfolio_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<portfolio_stream_cursor_input>>;
  where?: InputMaybe<portfolio_bool_exp>;
};

export type subscription_rootrenovationArgs = {
  distinct_on?: InputMaybe<Array<renovation_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_order_by>>;
  where?: InputMaybe<renovation_bool_exp>;
};

export type subscription_rootrenovation_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_order_by>>;
  where?: InputMaybe<renovation_bool_exp>;
};

export type subscription_rootrenovation_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootrenovation_energy_consumerArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_consumer_order_by>>;
  where?: InputMaybe<renovation_energy_consumer_bool_exp>;
};

export type subscription_rootrenovation_energy_consumer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_consumer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_consumer_order_by>>;
  where?: InputMaybe<renovation_energy_consumer_bool_exp>;
};

export type subscription_rootrenovation_energy_consumer_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<renovation_energy_consumer_stream_cursor_input>>;
  where?: InputMaybe<renovation_energy_consumer_bool_exp>;
};

export type subscription_rootrenovation_energy_distributionArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_distribution_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_distribution_order_by>>;
  where?: InputMaybe<renovation_energy_distribution_bool_exp>;
};

export type subscription_rootrenovation_energy_distribution_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_distribution_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_distribution_order_by>>;
  where?: InputMaybe<renovation_energy_distribution_bool_exp>;
};

export type subscription_rootrenovation_energy_distribution_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<renovation_energy_distribution_stream_cursor_input>>;
  where?: InputMaybe<renovation_energy_distribution_bool_exp>;
};

export type subscription_rootrenovation_energy_producerArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_producer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_producer_order_by>>;
  where?: InputMaybe<renovation_energy_producer_bool_exp>;
};

export type subscription_rootrenovation_energy_producer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_producer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_producer_order_by>>;
  where?: InputMaybe<renovation_energy_producer_bool_exp>;
};

export type subscription_rootrenovation_energy_producer_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<renovation_energy_producer_stream_cursor_input>>;
  where?: InputMaybe<renovation_energy_producer_bool_exp>;
};

export type subscription_rootrenovation_energy_storageArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_storage_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_storage_order_by>>;
  where?: InputMaybe<renovation_energy_storage_bool_exp>;
};

export type subscription_rootrenovation_energy_storage_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_storage_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_storage_order_by>>;
  where?: InputMaybe<renovation_energy_storage_bool_exp>;
};

export type subscription_rootrenovation_energy_storage_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<renovation_energy_storage_stream_cursor_input>>;
  where?: InputMaybe<renovation_energy_storage_bool_exp>;
};

export type subscription_rootrenovation_energy_system_routeArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_system_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_system_route_order_by>>;
  where?: InputMaybe<renovation_energy_system_route_bool_exp>;
};

export type subscription_rootrenovation_energy_system_route_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_energy_system_route_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_energy_system_route_order_by>>;
  where?: InputMaybe<renovation_energy_system_route_bool_exp>;
};

export type subscription_rootrenovation_energy_system_route_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<renovation_energy_system_route_stream_cursor_input>>;
  where?: InputMaybe<renovation_energy_system_route_bool_exp>;
};

export type subscription_rootrenovation_envelopeArgs = {
  distinct_on?: InputMaybe<Array<renovation_envelope_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_envelope_order_by>>;
  where?: InputMaybe<renovation_envelope_bool_exp>;
};

export type subscription_rootrenovation_envelope_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_envelope_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_envelope_order_by>>;
  where?: InputMaybe<renovation_envelope_bool_exp>;
};

export type subscription_rootrenovation_envelope_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<renovation_envelope_stream_cursor_input>>;
  where?: InputMaybe<renovation_envelope_bool_exp>;
};

export type subscription_rootrenovation_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<renovation_stream_cursor_input>>;
  where?: InputMaybe<renovation_bool_exp>;
};

export type subscription_rootrenovation_typeArgs = {
  distinct_on?: InputMaybe<Array<renovation_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_type_order_by>>;
  where?: InputMaybe<renovation_type_bool_exp>;
};

export type subscription_rootrenovation_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<renovation_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<renovation_type_order_by>>;
  where?: InputMaybe<renovation_type_bool_exp>;
};

export type subscription_rootrenovation_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootrenovation_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<renovation_type_stream_cursor_input>>;
  where?: InputMaybe<renovation_type_bool_exp>;
};

export type subscription_rootreportArgs = {
  distinct_on?: InputMaybe<Array<report_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_order_by>>;
  where?: InputMaybe<report_bool_exp>;
};

export type subscription_rootreport_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_order_by>>;
  where?: InputMaybe<report_bool_exp>;
};

export type subscription_rootreport_answerArgs = {
  distinct_on?: InputMaybe<Array<report_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_order_by>>;
  where?: InputMaybe<report_answer_bool_exp>;
};

export type subscription_rootreport_answer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_order_by>>;
  where?: InputMaybe<report_answer_bool_exp>;
};

export type subscription_rootreport_answer_autofill_optionArgs = {
  distinct_on?: InputMaybe<Array<report_answer_autofill_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_autofill_option_order_by>>;
  where?: InputMaybe<report_answer_autofill_option_bool_exp>;
};

export type subscription_rootreport_answer_autofill_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_answer_autofill_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_autofill_option_order_by>>;
  where?: InputMaybe<report_answer_autofill_option_bool_exp>;
};

export type subscription_rootreport_answer_autofill_option_by_pkArgs = {
  report_answer_id: Scalars['Int'];
  report_template_question_option_id: Scalars['Int'];
};

export type subscription_rootreport_answer_autofill_option_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<report_answer_autofill_option_stream_cursor_input>>;
  where?: InputMaybe<report_answer_autofill_option_bool_exp>;
};

export type subscription_rootreport_answer_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootreport_answer_chosen_optionArgs = {
  distinct_on?: InputMaybe<Array<report_answer_chosen_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_chosen_option_order_by>>;
  where?: InputMaybe<report_answer_chosen_option_bool_exp>;
};

export type subscription_rootreport_answer_chosen_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_answer_chosen_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_chosen_option_order_by>>;
  where?: InputMaybe<report_answer_chosen_option_bool_exp>;
};

export type subscription_rootreport_answer_chosen_option_by_pkArgs = {
  report_answer_id: Scalars['Int'];
  report_template_question_option_id: Scalars['Int'];
};

export type subscription_rootreport_answer_chosen_option_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<report_answer_chosen_option_stream_cursor_input>>;
  where?: InputMaybe<report_answer_chosen_option_bool_exp>;
};

export type subscription_rootreport_answer_stateArgs = {
  distinct_on?: InputMaybe<Array<report_answer_state_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_state_order_by>>;
  where?: InputMaybe<report_answer_state_bool_exp>;
};

export type subscription_rootreport_answer_state_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_answer_state_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_answer_state_order_by>>;
  where?: InputMaybe<report_answer_state_bool_exp>;
};

export type subscription_rootreport_answer_state_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootreport_answer_state_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<report_answer_state_stream_cursor_input>>;
  where?: InputMaybe<report_answer_state_bool_exp>;
};

export type subscription_rootreport_answer_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<report_answer_stream_cursor_input>>;
  where?: InputMaybe<report_answer_bool_exp>;
};

export type subscription_rootreport_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootreport_evidenceArgs = {
  distinct_on?: InputMaybe<Array<report_evidence_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_evidence_order_by>>;
  where?: InputMaybe<report_evidence_bool_exp>;
};

export type subscription_rootreport_evidence_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_evidence_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_evidence_order_by>>;
  where?: InputMaybe<report_evidence_bool_exp>;
};

export type subscription_rootreport_evidence_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootreport_evidence_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<report_evidence_stream_cursor_input>>;
  where?: InputMaybe<report_evidence_bool_exp>;
};

export type subscription_rootreport_question_evidenceArgs = {
  distinct_on?: InputMaybe<Array<report_question_evidence_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_question_evidence_order_by>>;
  where?: InputMaybe<report_question_evidence_bool_exp>;
};

export type subscription_rootreport_question_evidence_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_question_evidence_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_question_evidence_order_by>>;
  where?: InputMaybe<report_question_evidence_bool_exp>;
};

export type subscription_rootreport_question_evidence_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootreport_question_evidence_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<report_question_evidence_stream_cursor_input>>;
  where?: InputMaybe<report_question_evidence_bool_exp>;
};

export type subscription_rootreport_scoreArgs = {
  distinct_on?: InputMaybe<Array<report_score_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_score_order_by>>;
  where?: InputMaybe<report_score_bool_exp>;
};

export type subscription_rootreport_score_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_score_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_score_order_by>>;
  where?: InputMaybe<report_score_bool_exp>;
};

export type subscription_rootreport_score_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootreport_score_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<report_score_stream_cursor_input>>;
  where?: InputMaybe<report_score_bool_exp>;
};

export type subscription_rootreport_section_progressArgs = {
  distinct_on?: InputMaybe<Array<report_section_progress_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_section_progress_order_by>>;
  where?: InputMaybe<report_section_progress_bool_exp>;
};

export type subscription_rootreport_section_progress_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_section_progress_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_section_progress_order_by>>;
  where?: InputMaybe<report_section_progress_bool_exp>;
};

export type subscription_rootreport_section_progress_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootreport_section_progress_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<report_section_progress_stream_cursor_input>>;
  where?: InputMaybe<report_section_progress_bool_exp>;
};

export type subscription_rootreport_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<report_stream_cursor_input>>;
  where?: InputMaybe<report_bool_exp>;
};

export type subscription_rootreport_sub_buildingArgs = {
  distinct_on?: InputMaybe<Array<report_sub_building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_sub_building_order_by>>;
  where?: InputMaybe<report_sub_building_bool_exp>;
};

export type subscription_rootreport_sub_building_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_sub_building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_sub_building_order_by>>;
  where?: InputMaybe<report_sub_building_bool_exp>;
};

export type subscription_rootreport_sub_building_by_pkArgs = {
  report_id: Scalars['Int'];
  sub_building_id: Scalars['Int'];
};

export type subscription_rootreport_sub_building_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<report_sub_building_stream_cursor_input>>;
  where?: InputMaybe<report_sub_building_bool_exp>;
};

export type subscription_rootreport_templateArgs = {
  distinct_on?: InputMaybe<Array<report_template_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_order_by>>;
  where?: InputMaybe<report_template_bool_exp>;
};

export type subscription_rootreport_template_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_order_by>>;
  where?: InputMaybe<report_template_bool_exp>;
};

export type subscription_rootreport_template_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootreport_template_questionArgs = {
  distinct_on?: InputMaybe<Array<report_template_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_question_order_by>>;
  where?: InputMaybe<report_template_question_bool_exp>;
};

export type subscription_rootreport_template_question_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_question_order_by>>;
  where?: InputMaybe<report_template_question_bool_exp>;
};

export type subscription_rootreport_template_question_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootreport_template_question_optionArgs = {
  distinct_on?: InputMaybe<Array<report_template_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_question_option_order_by>>;
  where?: InputMaybe<report_template_question_option_bool_exp>;
};

export type subscription_rootreport_template_question_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_question_option_order_by>>;
  where?: InputMaybe<report_template_question_option_bool_exp>;
};

export type subscription_rootreport_template_question_option_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootreport_template_question_option_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<report_template_question_option_stream_cursor_input>>;
  where?: InputMaybe<report_template_question_option_bool_exp>;
};

export type subscription_rootreport_template_question_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<report_template_question_stream_cursor_input>>;
  where?: InputMaybe<report_template_question_bool_exp>;
};

export type subscription_rootreport_template_question_typeArgs = {
  distinct_on?: InputMaybe<Array<report_template_question_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_question_type_order_by>>;
  where?: InputMaybe<report_template_question_type_bool_exp>;
};

export type subscription_rootreport_template_question_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_question_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_question_type_order_by>>;
  where?: InputMaybe<report_template_question_type_bool_exp>;
};

export type subscription_rootreport_template_question_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootreport_template_question_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<report_template_question_type_stream_cursor_input>>;
  where?: InputMaybe<report_template_question_type_bool_exp>;
};

export type subscription_rootreport_template_scoreArgs = {
  distinct_on?: InputMaybe<Array<report_template_score_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_score_order_by>>;
  where?: InputMaybe<report_template_score_bool_exp>;
};

export type subscription_rootreport_template_score_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_score_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_score_order_by>>;
  where?: InputMaybe<report_template_score_bool_exp>;
};

export type subscription_rootreport_template_score_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootreport_template_score_display_typeArgs = {
  distinct_on?: InputMaybe<Array<report_template_score_display_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_score_display_type_order_by>>;
  where?: InputMaybe<report_template_score_display_type_bool_exp>;
};

export type subscription_rootreport_template_score_display_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_score_display_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_score_display_type_order_by>>;
  where?: InputMaybe<report_template_score_display_type_bool_exp>;
};

export type subscription_rootreport_template_score_display_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootreport_template_score_display_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<report_template_score_display_type_stream_cursor_input>>;
  where?: InputMaybe<report_template_score_display_type_bool_exp>;
};

export type subscription_rootreport_template_score_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<report_template_score_stream_cursor_input>>;
  where?: InputMaybe<report_template_score_bool_exp>;
};

export type subscription_rootreport_template_sectionArgs = {
  distinct_on?: InputMaybe<Array<report_template_section_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_section_order_by>>;
  where?: InputMaybe<report_template_section_bool_exp>;
};

export type subscription_rootreport_template_section_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_section_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_section_order_by>>;
  where?: InputMaybe<report_template_section_bool_exp>;
};

export type subscription_rootreport_template_section_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootreport_template_section_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<report_template_section_stream_cursor_input>>;
  where?: InputMaybe<report_template_section_bool_exp>;
};

export type subscription_rootreport_template_section_targetArgs = {
  distinct_on?: InputMaybe<Array<report_template_section_target_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_section_target_order_by>>;
  where?: InputMaybe<report_template_section_target_bool_exp>;
};

export type subscription_rootreport_template_section_target_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_section_target_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_section_target_order_by>>;
  where?: InputMaybe<report_template_section_target_bool_exp>;
};

export type subscription_rootreport_template_section_target_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootreport_template_section_target_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<report_template_section_target_stream_cursor_input>>;
  where?: InputMaybe<report_template_section_target_bool_exp>;
};

export type subscription_rootreport_template_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<report_template_stream_cursor_input>>;
  where?: InputMaybe<report_template_bool_exp>;
};

export type subscription_rootreport_template_to_all_questionsArgs = {
  distinct_on?: InputMaybe<Array<report_template_to_all_questions_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_to_all_questions_order_by>>;
  where?: InputMaybe<report_template_to_all_questions_bool_exp>;
};

export type subscription_rootreport_template_to_all_questions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_template_to_all_questions_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<report_template_to_all_questions_order_by>>;
  where?: InputMaybe<report_template_to_all_questions_bool_exp>;
};

export type subscription_rootreport_template_to_all_questions_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<report_template_to_all_questions_stream_cursor_input>>;
  where?: InputMaybe<report_template_to_all_questions_bool_exp>;
};

export type subscription_rootroleArgs = {
  distinct_on?: InputMaybe<Array<role_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<role_order_by>>;
  where?: InputMaybe<role_bool_exp>;
};

export type subscription_rootrole_aggregateArgs = {
  distinct_on?: InputMaybe<Array<role_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<role_order_by>>;
  where?: InputMaybe<role_bool_exp>;
};

export type subscription_rootrole_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootrole_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<role_stream_cursor_input>>;
  where?: InputMaybe<role_bool_exp>;
};

export type subscription_rootroof_typeArgs = {
  distinct_on?: InputMaybe<Array<roof_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<roof_type_order_by>>;
  where?: InputMaybe<roof_type_bool_exp>;
};

export type subscription_rootroof_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<roof_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<roof_type_order_by>>;
  where?: InputMaybe<roof_type_bool_exp>;
};

export type subscription_rootroof_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootroof_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<roof_type_stream_cursor_input>>;
  where?: InputMaybe<roof_type_bool_exp>;
};

export type subscription_rootscenarioArgs = {
  distinct_on?: InputMaybe<Array<scenario_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<scenario_order_by>>;
  where?: InputMaybe<scenario_bool_exp>;
};

export type subscription_rootscenario_aggregateArgs = {
  distinct_on?: InputMaybe<Array<scenario_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<scenario_order_by>>;
  where?: InputMaybe<scenario_bool_exp>;
};

export type subscription_rootscenario_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootscenario_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<scenario_stream_cursor_input>>;
  where?: InputMaybe<scenario_bool_exp>;
};

export type subscription_rootsub_buildingArgs = {
  distinct_on?: InputMaybe<Array<sub_building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<sub_building_order_by>>;
  where?: InputMaybe<sub_building_bool_exp>;
};

export type subscription_rootsub_building_aggregateArgs = {
  distinct_on?: InputMaybe<Array<sub_building_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<sub_building_order_by>>;
  where?: InputMaybe<sub_building_bool_exp>;
};

export type subscription_rootsub_building_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootsub_building_classArgs = {
  distinct_on?: InputMaybe<Array<sub_building_class_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<sub_building_class_order_by>>;
  where?: InputMaybe<sub_building_class_bool_exp>;
};

export type subscription_rootsub_building_class_aggregateArgs = {
  distinct_on?: InputMaybe<Array<sub_building_class_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<sub_building_class_order_by>>;
  where?: InputMaybe<sub_building_class_bool_exp>;
};

export type subscription_rootsub_building_class_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootsub_building_class_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<sub_building_class_stream_cursor_input>>;
  where?: InputMaybe<sub_building_class_bool_exp>;
};

export type subscription_rootsub_building_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<sub_building_stream_cursor_input>>;
  where?: InputMaybe<sub_building_bool_exp>;
};

export type subscription_rootsub_building_subsidyArgs = {
  distinct_on?: InputMaybe<Array<sub_building_subsidy_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<sub_building_subsidy_order_by>>;
  where?: InputMaybe<sub_building_subsidy_bool_exp>;
};

export type subscription_rootsub_building_subsidy_aggregateArgs = {
  distinct_on?: InputMaybe<Array<sub_building_subsidy_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<sub_building_subsidy_order_by>>;
  where?: InputMaybe<sub_building_subsidy_bool_exp>;
};

export type subscription_rootsub_building_subsidy_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootsub_building_subsidy_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<sub_building_subsidy_stream_cursor_input>>;
  where?: InputMaybe<sub_building_subsidy_bool_exp>;
};

export type subscription_rootsubsidyArgs = {
  distinct_on?: InputMaybe<Array<subsidy_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<subsidy_order_by>>;
  where?: InputMaybe<subsidy_bool_exp>;
};

export type subscription_rootsubsidy_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subsidy_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<subsidy_order_by>>;
  where?: InputMaybe<subsidy_bool_exp>;
};

export type subscription_rootsubsidy_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootsubsidy_category_typeArgs = {
  distinct_on?: InputMaybe<Array<subsidy_category_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<subsidy_category_type_order_by>>;
  where?: InputMaybe<subsidy_category_type_bool_exp>;
};

export type subscription_rootsubsidy_category_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subsidy_category_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<subsidy_category_type_order_by>>;
  where?: InputMaybe<subsidy_category_type_bool_exp>;
};

export type subscription_rootsubsidy_category_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootsubsidy_category_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<subsidy_category_type_stream_cursor_input>>;
  where?: InputMaybe<subsidy_category_type_bool_exp>;
};

export type subscription_rootsubsidy_region_typeArgs = {
  distinct_on?: InputMaybe<Array<subsidy_region_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<subsidy_region_type_order_by>>;
  where?: InputMaybe<subsidy_region_type_bool_exp>;
};

export type subscription_rootsubsidy_region_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subsidy_region_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<subsidy_region_type_order_by>>;
  where?: InputMaybe<subsidy_region_type_bool_exp>;
};

export type subscription_rootsubsidy_region_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootsubsidy_region_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<subsidy_region_type_stream_cursor_input>>;
  where?: InputMaybe<subsidy_region_type_bool_exp>;
};

export type subscription_rootsubsidy_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<subsidy_stream_cursor_input>>;
  where?: InputMaybe<subsidy_bool_exp>;
};

export type subscription_rootsubsidy_subsidy_categoryArgs = {
  distinct_on?: InputMaybe<Array<subsidy_subsidy_category_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<subsidy_subsidy_category_order_by>>;
  where?: InputMaybe<subsidy_subsidy_category_bool_exp>;
};

export type subscription_rootsubsidy_subsidy_category_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subsidy_subsidy_category_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<subsidy_subsidy_category_order_by>>;
  where?: InputMaybe<subsidy_subsidy_category_bool_exp>;
};

export type subscription_rootsubsidy_subsidy_category_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootsubsidy_subsidy_category_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<subsidy_subsidy_category_stream_cursor_input>>;
  where?: InputMaybe<subsidy_subsidy_category_bool_exp>;
};

export type subscription_rootsubsidy_typeArgs = {
  distinct_on?: InputMaybe<Array<subsidy_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<subsidy_type_order_by>>;
  where?: InputMaybe<subsidy_type_bool_exp>;
};

export type subscription_rootsubsidy_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subsidy_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<subsidy_type_order_by>>;
  where?: InputMaybe<subsidy_type_bool_exp>;
};

export type subscription_rootsubsidy_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootsubsidy_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<subsidy_type_stream_cursor_input>>;
  where?: InputMaybe<subsidy_type_bool_exp>;
};

export type subscription_rootsync_stateArgs = {
  distinct_on?: InputMaybe<Array<sync_state_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<sync_state_order_by>>;
  where?: InputMaybe<sync_state_bool_exp>;
};

export type subscription_rootsync_state_aggregateArgs = {
  distinct_on?: InputMaybe<Array<sync_state_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<sync_state_order_by>>;
  where?: InputMaybe<sync_state_bool_exp>;
};

export type subscription_rootsync_state_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootsync_state_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<sync_state_stream_cursor_input>>;
  where?: InputMaybe<sync_state_bool_exp>;
};

export type subscription_roottarget_pathArgs = {
  distinct_on?: InputMaybe<Array<target_path_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<target_path_order_by>>;
  where?: InputMaybe<target_path_bool_exp>;
};

export type subscription_roottarget_path_aggregateArgs = {
  distinct_on?: InputMaybe<Array<target_path_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<target_path_order_by>>;
  where?: InputMaybe<target_path_bool_exp>;
};

export type subscription_roottarget_path_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_roottarget_path_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<target_path_stream_cursor_input>>;
  where?: InputMaybe<target_path_bool_exp>;
};

export type subscription_roottarget_path_templateArgs = {
  distinct_on?: InputMaybe<Array<target_path_template_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<target_path_template_order_by>>;
  where?: InputMaybe<target_path_template_bool_exp>;
};

export type subscription_roottarget_path_template_aggregateArgs = {
  distinct_on?: InputMaybe<Array<target_path_template_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<target_path_template_order_by>>;
  where?: InputMaybe<target_path_template_bool_exp>;
};

export type subscription_roottarget_path_template_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_roottarget_path_template_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<target_path_template_stream_cursor_input>>;
  where?: InputMaybe<target_path_template_bool_exp>;
};

export type subscription_roottarget_path_template_typeArgs = {
  distinct_on?: InputMaybe<Array<target_path_template_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<target_path_template_type_order_by>>;
  where?: InputMaybe<target_path_template_type_bool_exp>;
};

export type subscription_roottarget_path_template_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<target_path_template_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<target_path_template_type_order_by>>;
  where?: InputMaybe<target_path_template_type_bool_exp>;
};

export type subscription_roottarget_path_template_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_roottarget_path_template_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<target_path_template_type_stream_cursor_input>>;
  where?: InputMaybe<target_path_template_type_bool_exp>;
};

export type subscription_roottax_bracketArgs = {
  distinct_on?: InputMaybe<Array<tax_bracket_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<tax_bracket_order_by>>;
  where?: InputMaybe<tax_bracket_bool_exp>;
};

export type subscription_roottax_bracket_aggregateArgs = {
  distinct_on?: InputMaybe<Array<tax_bracket_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<tax_bracket_order_by>>;
  where?: InputMaybe<tax_bracket_bool_exp>;
};

export type subscription_roottax_bracket_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_roottax_bracket_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<tax_bracket_stream_cursor_input>>;
  where?: InputMaybe<tax_bracket_bool_exp>;
};

export type subscription_roottype_of_useArgs = {
  distinct_on?: InputMaybe<Array<type_of_use_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<type_of_use_order_by>>;
  where?: InputMaybe<type_of_use_bool_exp>;
};

export type subscription_roottype_of_use_aggregateArgs = {
  distinct_on?: InputMaybe<Array<type_of_use_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<type_of_use_order_by>>;
  where?: InputMaybe<type_of_use_bool_exp>;
};

export type subscription_roottype_of_use_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_roottype_of_use_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<type_of_use_stream_cursor_input>>;
  where?: InputMaybe<type_of_use_bool_exp>;
};

export type subscription_rootuserArgs = {
  distinct_on?: InputMaybe<Array<user_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_order_by>>;
  where?: InputMaybe<user_bool_exp>;
};

export type subscription_rootuser_aggregateArgs = {
  distinct_on?: InputMaybe<Array<user_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_order_by>>;
  where?: InputMaybe<user_bool_exp>;
};

export type subscription_rootuser_building_permissionArgs = {
  distinct_on?: InputMaybe<Array<user_building_permission_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_building_permission_order_by>>;
  where?: InputMaybe<user_building_permission_bool_exp>;
};

export type subscription_rootuser_building_permission_aggregateArgs = {
  distinct_on?: InputMaybe<Array<user_building_permission_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_building_permission_order_by>>;
  where?: InputMaybe<user_building_permission_bool_exp>;
};

export type subscription_rootuser_building_permission_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootuser_building_permission_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<user_building_permission_stream_cursor_input>>;
  where?: InputMaybe<user_building_permission_bool_exp>;
};

export type subscription_rootuser_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootuser_portfolio_permissionArgs = {
  distinct_on?: InputMaybe<Array<user_portfolio_permission_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_portfolio_permission_order_by>>;
  where?: InputMaybe<user_portfolio_permission_bool_exp>;
};

export type subscription_rootuser_portfolio_permission_aggregateArgs = {
  distinct_on?: InputMaybe<Array<user_portfolio_permission_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_portfolio_permission_order_by>>;
  where?: InputMaybe<user_portfolio_permission_bool_exp>;
};

export type subscription_rootuser_portfolio_permission_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootuser_portfolio_permission_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<user_portfolio_permission_stream_cursor_input>>;
  where?: InputMaybe<user_portfolio_permission_bool_exp>;
};

export type subscription_rootuser_roleArgs = {
  distinct_on?: InputMaybe<Array<user_role_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_role_order_by>>;
  where?: InputMaybe<user_role_bool_exp>;
};

export type subscription_rootuser_role_aggregateArgs = {
  distinct_on?: InputMaybe<Array<user_role_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_role_order_by>>;
  where?: InputMaybe<user_role_bool_exp>;
};

export type subscription_rootuser_role_by_pkArgs = {
  id: Scalars['Int'];
};

export type subscription_rootuser_role_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<user_role_stream_cursor_input>>;
  where?: InputMaybe<user_role_bool_exp>;
};

export type subscription_rootuser_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<user_stream_cursor_input>>;
  where?: InputMaybe<user_bool_exp>;
};

export type subscription_rootwindow_typeArgs = {
  distinct_on?: InputMaybe<Array<window_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<window_type_order_by>>;
  where?: InputMaybe<window_type_bool_exp>;
};

export type subscription_rootwindow_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<window_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<window_type_order_by>>;
  where?: InputMaybe<window_type_bool_exp>;
};

export type subscription_rootwindow_type_by_pkArgs = {
  id: Scalars['String'];
};

export type subscription_rootwindow_type_streamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<window_type_stream_cursor_input>>;
  where?: InputMaybe<window_type_bool_exp>;
};

/** columns and relationships of "subsidy" */
export type subsidy = {
  /** An array relationship */
  actions: Array<action_subsidy>;
  /** An aggregate relationship */
  actions_aggregate: action_subsidy_aggregate;
  amount_description: Scalars['String'];
  /** An array relationship */
  categories: Array<subsidy_subsidy_category>;
  /** An aggregate relationship */
  categories_aggregate: subsidy_subsidy_category_aggregate;
  created_at: Scalars['timestamptz'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  provider: Scalars['String'];
  provider_id: Scalars['String'];
  provider_updated_at: Scalars['timestamptz'];
  region_type: subsidy_region_type_enum;
  /** An array relationship */
  sub_building_subsidies: Array<sub_building_subsidy>;
  /** An aggregate relationship */
  sub_building_subsidies_aggregate: sub_building_subsidy_aggregate;
  title: Scalars['String'];
  type: subsidy_type_enum;
  updated_at: Scalars['timestamptz'];
  url?: Maybe<Scalars['String']>;
};

/** columns and relationships of "subsidy" */
export type subsidyactionsArgs = {
  distinct_on?: InputMaybe<Array<action_subsidy_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_subsidy_order_by>>;
  where?: InputMaybe<action_subsidy_bool_exp>;
};

/** columns and relationships of "subsidy" */
export type subsidyactions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<action_subsidy_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<action_subsidy_order_by>>;
  where?: InputMaybe<action_subsidy_bool_exp>;
};

/** columns and relationships of "subsidy" */
export type subsidycategoriesArgs = {
  distinct_on?: InputMaybe<Array<subsidy_subsidy_category_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<subsidy_subsidy_category_order_by>>;
  where?: InputMaybe<subsidy_subsidy_category_bool_exp>;
};

/** columns and relationships of "subsidy" */
export type subsidycategories_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subsidy_subsidy_category_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<subsidy_subsidy_category_order_by>>;
  where?: InputMaybe<subsidy_subsidy_category_bool_exp>;
};

/** columns and relationships of "subsidy" */
export type subsidysub_building_subsidiesArgs = {
  distinct_on?: InputMaybe<Array<sub_building_subsidy_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<sub_building_subsidy_order_by>>;
  where?: InputMaybe<sub_building_subsidy_bool_exp>;
};

/** columns and relationships of "subsidy" */
export type subsidysub_building_subsidies_aggregateArgs = {
  distinct_on?: InputMaybe<Array<sub_building_subsidy_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<sub_building_subsidy_order_by>>;
  where?: InputMaybe<sub_building_subsidy_bool_exp>;
};

/** aggregated selection of "subsidy" */
export type subsidy_aggregate = {
  aggregate?: Maybe<subsidy_aggregate_fields>;
  nodes: Array<subsidy>;
};

/** aggregate fields of "subsidy" */
export type subsidy_aggregate_fields = {
  avg?: Maybe<subsidy_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<subsidy_max_fields>;
  min?: Maybe<subsidy_min_fields>;
  stddev?: Maybe<subsidy_stddev_fields>;
  stddev_pop?: Maybe<subsidy_stddev_pop_fields>;
  stddev_samp?: Maybe<subsidy_stddev_samp_fields>;
  sum?: Maybe<subsidy_sum_fields>;
  var_pop?: Maybe<subsidy_var_pop_fields>;
  var_samp?: Maybe<subsidy_var_samp_fields>;
  variance?: Maybe<subsidy_variance_fields>;
};

/** aggregate fields of "subsidy" */
export type subsidy_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subsidy_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type subsidy_avg_fields = {
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "subsidy". All fields are combined with a logical 'AND'. */
export type subsidy_bool_exp = {
  _and?: InputMaybe<Array<subsidy_bool_exp>>;
  _not?: InputMaybe<subsidy_bool_exp>;
  _or?: InputMaybe<Array<subsidy_bool_exp>>;
  actions?: InputMaybe<action_subsidy_bool_exp>;
  actions_aggregate?: InputMaybe<action_subsidy_aggregate_bool_exp>;
  amount_description?: InputMaybe<String_comparison_exp>;
  categories?: InputMaybe<subsidy_subsidy_category_bool_exp>;
  categories_aggregate?: InputMaybe<subsidy_subsidy_category_aggregate_bool_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  email?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  provider?: InputMaybe<String_comparison_exp>;
  provider_id?: InputMaybe<String_comparison_exp>;
  provider_updated_at?: InputMaybe<timestamptz_comparison_exp>;
  region_type?: InputMaybe<subsidy_region_type_enum_comparison_exp>;
  sub_building_subsidies?: InputMaybe<sub_building_subsidy_bool_exp>;
  sub_building_subsidies_aggregate?: InputMaybe<sub_building_subsidy_aggregate_bool_exp>;
  title?: InputMaybe<String_comparison_exp>;
  type?: InputMaybe<subsidy_type_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  url?: InputMaybe<String_comparison_exp>;
};

/** columns and relationships of "subsidy_category_type" */
export type subsidy_category_type = {
  id: Scalars['String'];
};

/** aggregated selection of "subsidy_category_type" */
export type subsidy_category_type_aggregate = {
  aggregate?: Maybe<subsidy_category_type_aggregate_fields>;
  nodes: Array<subsidy_category_type>;
};

/** aggregate fields of "subsidy_category_type" */
export type subsidy_category_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<subsidy_category_type_max_fields>;
  min?: Maybe<subsidy_category_type_min_fields>;
};

/** aggregate fields of "subsidy_category_type" */
export type subsidy_category_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subsidy_category_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "subsidy_category_type". All fields are combined with a logical 'AND'. */
export type subsidy_category_type_bool_exp = {
  _and?: InputMaybe<Array<subsidy_category_type_bool_exp>>;
  _not?: InputMaybe<subsidy_category_type_bool_exp>;
  _or?: InputMaybe<Array<subsidy_category_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { subsidy_category_type_constraint };

export { subsidy_category_type_enum };

/** Boolean expression to compare columns of type "subsidy_category_type_enum". All fields are combined with logical 'AND'. */
export type subsidy_category_type_enum_comparison_exp = {
  _eq?: InputMaybe<subsidy_category_type_enum>;
  _in?: InputMaybe<Array<subsidy_category_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<subsidy_category_type_enum>;
  _nin?: InputMaybe<Array<subsidy_category_type_enum>>;
};

/** input type for inserting data into table "subsidy_category_type" */
export type subsidy_category_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type subsidy_category_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type subsidy_category_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "subsidy_category_type" */
export type subsidy_category_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<subsidy_category_type>;
};

/** input type for inserting object relation for remote table "subsidy_category_type" */
export type subsidy_category_type_obj_rel_insert_input = {
  data: subsidy_category_type_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<subsidy_category_type_on_conflict>;
};

/** on_conflict condition type for table "subsidy_category_type" */
export type subsidy_category_type_on_conflict = {
  constraint: subsidy_category_type_constraint;
  update_columns?: Array<subsidy_category_type_update_column>;
  where?: InputMaybe<subsidy_category_type_bool_exp>;
};

/** Ordering options when selecting data from "subsidy_category_type". */
export type subsidy_category_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: subsidy_category_type */
export type subsidy_category_type_pk_columns_input = {
  id: Scalars['String'];
};

export { subsidy_category_type_select_column };

/** input type for updating data in table "subsidy_category_type" */
export type subsidy_category_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "subsidy_category_type" */
export type subsidy_category_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subsidy_category_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subsidy_category_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { subsidy_category_type_update_column };

export type subsidy_category_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subsidy_category_type_set_input>;
  /** filter the rows which have to be updated */
  where: subsidy_category_type_bool_exp;
};

export { subsidy_constraint };

/** input type for incrementing numeric columns in table "subsidy" */
export type subsidy_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "subsidy" */
export type subsidy_insert_input = {
  actions?: InputMaybe<action_subsidy_arr_rel_insert_input>;
  amount_description?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<subsidy_subsidy_category_arr_rel_insert_input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['String']>;
  provider_updated_at?: InputMaybe<Scalars['timestamptz']>;
  region_type?: InputMaybe<subsidy_region_type_enum>;
  sub_building_subsidies?: InputMaybe<sub_building_subsidy_arr_rel_insert_input>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<subsidy_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type subsidy_max_fields = {
  amount_description?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  provider_updated_at?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type subsidy_min_fields = {
  amount_description?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  provider_updated_at?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "subsidy" */
export type subsidy_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<subsidy>;
};

/** input type for inserting object relation for remote table "subsidy" */
export type subsidy_obj_rel_insert_input = {
  data: subsidy_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<subsidy_on_conflict>;
};

/** on_conflict condition type for table "subsidy" */
export type subsidy_on_conflict = {
  constraint: subsidy_constraint;
  update_columns?: Array<subsidy_update_column>;
  where?: InputMaybe<subsidy_bool_exp>;
};

/** Ordering options when selecting data from "subsidy". */
export type subsidy_order_by = {
  actions_aggregate?: InputMaybe<action_subsidy_aggregate_order_by>;
  amount_description?: InputMaybe<order_by>;
  categories_aggregate?: InputMaybe<subsidy_subsidy_category_aggregate_order_by>;
  created_at?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  provider?: InputMaybe<order_by>;
  provider_id?: InputMaybe<order_by>;
  provider_updated_at?: InputMaybe<order_by>;
  region_type?: InputMaybe<order_by>;
  sub_building_subsidies_aggregate?: InputMaybe<sub_building_subsidy_aggregate_order_by>;
  title?: InputMaybe<order_by>;
  type?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
};

/** primary key columns input for table: subsidy */
export type subsidy_pk_columns_input = {
  id: Scalars['Int'];
};

/** columns and relationships of "subsidy_region_type" */
export type subsidy_region_type = {
  id: Scalars['String'];
};

/** aggregated selection of "subsidy_region_type" */
export type subsidy_region_type_aggregate = {
  aggregate?: Maybe<subsidy_region_type_aggregate_fields>;
  nodes: Array<subsidy_region_type>;
};

/** aggregate fields of "subsidy_region_type" */
export type subsidy_region_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<subsidy_region_type_max_fields>;
  min?: Maybe<subsidy_region_type_min_fields>;
};

/** aggregate fields of "subsidy_region_type" */
export type subsidy_region_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subsidy_region_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "subsidy_region_type". All fields are combined with a logical 'AND'. */
export type subsidy_region_type_bool_exp = {
  _and?: InputMaybe<Array<subsidy_region_type_bool_exp>>;
  _not?: InputMaybe<subsidy_region_type_bool_exp>;
  _or?: InputMaybe<Array<subsidy_region_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { subsidy_region_type_constraint };

export { subsidy_region_type_enum };

/** Boolean expression to compare columns of type "subsidy_region_type_enum". All fields are combined with logical 'AND'. */
export type subsidy_region_type_enum_comparison_exp = {
  _eq?: InputMaybe<subsidy_region_type_enum>;
  _in?: InputMaybe<Array<subsidy_region_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<subsidy_region_type_enum>;
  _nin?: InputMaybe<Array<subsidy_region_type_enum>>;
};

/** input type for inserting data into table "subsidy_region_type" */
export type subsidy_region_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type subsidy_region_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type subsidy_region_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "subsidy_region_type" */
export type subsidy_region_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<subsidy_region_type>;
};

/** on_conflict condition type for table "subsidy_region_type" */
export type subsidy_region_type_on_conflict = {
  constraint: subsidy_region_type_constraint;
  update_columns?: Array<subsidy_region_type_update_column>;
  where?: InputMaybe<subsidy_region_type_bool_exp>;
};

/** Ordering options when selecting data from "subsidy_region_type". */
export type subsidy_region_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: subsidy_region_type */
export type subsidy_region_type_pk_columns_input = {
  id: Scalars['String'];
};

export { subsidy_region_type_select_column };

/** input type for updating data in table "subsidy_region_type" */
export type subsidy_region_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "subsidy_region_type" */
export type subsidy_region_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subsidy_region_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subsidy_region_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { subsidy_region_type_update_column };

export type subsidy_region_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subsidy_region_type_set_input>;
  /** filter the rows which have to be updated */
  where: subsidy_region_type_bool_exp;
};

export { subsidy_select_column };

/** input type for updating data in table "subsidy" */
export type subsidy_set_input = {
  amount_description?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['String']>;
  provider_updated_at?: InputMaybe<Scalars['timestamptz']>;
  region_type?: InputMaybe<subsidy_region_type_enum>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<subsidy_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type subsidy_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type subsidy_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type subsidy_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "subsidy" */
export type subsidy_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subsidy_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subsidy_stream_cursor_value_input = {
  amount_description?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['String']>;
  provider_updated_at?: InputMaybe<Scalars['timestamptz']>;
  region_type?: InputMaybe<subsidy_region_type_enum>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<subsidy_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "subsidy_subsidy_category" */
export type subsidy_subsidy_category = {
  /** An object relationship */
  category: subsidy_category_type;
  id: Scalars['Int'];
  /** An object relationship */
  subsidy: subsidy;
  subsidy_category_type: subsidy_category_type_enum;
  subsidy_id: Scalars['Int'];
};

/** aggregated selection of "subsidy_subsidy_category" */
export type subsidy_subsidy_category_aggregate = {
  aggregate?: Maybe<subsidy_subsidy_category_aggregate_fields>;
  nodes: Array<subsidy_subsidy_category>;
};

export type subsidy_subsidy_category_aggregate_bool_exp = {
  count?: InputMaybe<subsidy_subsidy_category_aggregate_bool_exp_count>;
};

export type subsidy_subsidy_category_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subsidy_subsidy_category_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<subsidy_subsidy_category_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subsidy_subsidy_category" */
export type subsidy_subsidy_category_aggregate_fields = {
  avg?: Maybe<subsidy_subsidy_category_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<subsidy_subsidy_category_max_fields>;
  min?: Maybe<subsidy_subsidy_category_min_fields>;
  stddev?: Maybe<subsidy_subsidy_category_stddev_fields>;
  stddev_pop?: Maybe<subsidy_subsidy_category_stddev_pop_fields>;
  stddev_samp?: Maybe<subsidy_subsidy_category_stddev_samp_fields>;
  sum?: Maybe<subsidy_subsidy_category_sum_fields>;
  var_pop?: Maybe<subsidy_subsidy_category_var_pop_fields>;
  var_samp?: Maybe<subsidy_subsidy_category_var_samp_fields>;
  variance?: Maybe<subsidy_subsidy_category_variance_fields>;
};

/** aggregate fields of "subsidy_subsidy_category" */
export type subsidy_subsidy_category_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subsidy_subsidy_category_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "subsidy_subsidy_category" */
export type subsidy_subsidy_category_aggregate_order_by = {
  avg?: InputMaybe<subsidy_subsidy_category_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subsidy_subsidy_category_max_order_by>;
  min?: InputMaybe<subsidy_subsidy_category_min_order_by>;
  stddev?: InputMaybe<subsidy_subsidy_category_stddev_order_by>;
  stddev_pop?: InputMaybe<subsidy_subsidy_category_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subsidy_subsidy_category_stddev_samp_order_by>;
  sum?: InputMaybe<subsidy_subsidy_category_sum_order_by>;
  var_pop?: InputMaybe<subsidy_subsidy_category_var_pop_order_by>;
  var_samp?: InputMaybe<subsidy_subsidy_category_var_samp_order_by>;
  variance?: InputMaybe<subsidy_subsidy_category_variance_order_by>;
};

/** input type for inserting array relation for remote table "subsidy_subsidy_category" */
export type subsidy_subsidy_category_arr_rel_insert_input = {
  data: Array<subsidy_subsidy_category_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subsidy_subsidy_category_on_conflict>;
};

/** aggregate avg on columns */
export type subsidy_subsidy_category_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  subsidy_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "subsidy_subsidy_category" */
export type subsidy_subsidy_category_avg_order_by = {
  id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subsidy_subsidy_category". All fields are combined with a logical 'AND'. */
export type subsidy_subsidy_category_bool_exp = {
  _and?: InputMaybe<Array<subsidy_subsidy_category_bool_exp>>;
  _not?: InputMaybe<subsidy_subsidy_category_bool_exp>;
  _or?: InputMaybe<Array<subsidy_subsidy_category_bool_exp>>;
  category?: InputMaybe<subsidy_category_type_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  subsidy?: InputMaybe<subsidy_bool_exp>;
  subsidy_category_type?: InputMaybe<subsidy_category_type_enum_comparison_exp>;
  subsidy_id?: InputMaybe<Int_comparison_exp>;
};

export { subsidy_subsidy_category_constraint };

/** input type for incrementing numeric columns in table "subsidy_subsidy_category" */
export type subsidy_subsidy_category_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  subsidy_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "subsidy_subsidy_category" */
export type subsidy_subsidy_category_insert_input = {
  category?: InputMaybe<subsidy_category_type_obj_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']>;
  subsidy?: InputMaybe<subsidy_obj_rel_insert_input>;
  subsidy_category_type?: InputMaybe<subsidy_category_type_enum>;
  subsidy_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type subsidy_subsidy_category_max_fields = {
  id?: Maybe<Scalars['Int']>;
  subsidy_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "subsidy_subsidy_category" */
export type subsidy_subsidy_category_max_order_by = {
  id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subsidy_subsidy_category_min_fields = {
  id?: Maybe<Scalars['Int']>;
  subsidy_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "subsidy_subsidy_category" */
export type subsidy_subsidy_category_min_order_by = {
  id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subsidy_subsidy_category" */
export type subsidy_subsidy_category_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<subsidy_subsidy_category>;
};

/** on_conflict condition type for table "subsidy_subsidy_category" */
export type subsidy_subsidy_category_on_conflict = {
  constraint: subsidy_subsidy_category_constraint;
  update_columns?: Array<subsidy_subsidy_category_update_column>;
  where?: InputMaybe<subsidy_subsidy_category_bool_exp>;
};

/** Ordering options when selecting data from "subsidy_subsidy_category". */
export type subsidy_subsidy_category_order_by = {
  category?: InputMaybe<subsidy_category_type_order_by>;
  id?: InputMaybe<order_by>;
  subsidy?: InputMaybe<subsidy_order_by>;
  subsidy_category_type?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: subsidy_subsidy_category */
export type subsidy_subsidy_category_pk_columns_input = {
  id: Scalars['Int'];
};

export { subsidy_subsidy_category_select_column };

/** input type for updating data in table "subsidy_subsidy_category" */
export type subsidy_subsidy_category_set_input = {
  id?: InputMaybe<Scalars['Int']>;
  subsidy_category_type?: InputMaybe<subsidy_category_type_enum>;
  subsidy_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type subsidy_subsidy_category_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  subsidy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "subsidy_subsidy_category" */
export type subsidy_subsidy_category_stddev_order_by = {
  id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subsidy_subsidy_category_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  subsidy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "subsidy_subsidy_category" */
export type subsidy_subsidy_category_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subsidy_subsidy_category_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  subsidy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "subsidy_subsidy_category" */
export type subsidy_subsidy_category_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subsidy_subsidy_category" */
export type subsidy_subsidy_category_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subsidy_subsidy_category_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subsidy_subsidy_category_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']>;
  subsidy_category_type?: InputMaybe<subsidy_category_type_enum>;
  subsidy_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type subsidy_subsidy_category_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  subsidy_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "subsidy_subsidy_category" */
export type subsidy_subsidy_category_sum_order_by = {
  id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
};

export { subsidy_subsidy_category_update_column };

export type subsidy_subsidy_category_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subsidy_subsidy_category_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subsidy_subsidy_category_set_input>;
  /** filter the rows which have to be updated */
  where: subsidy_subsidy_category_bool_exp;
};

/** aggregate var_pop on columns */
export type subsidy_subsidy_category_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  subsidy_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "subsidy_subsidy_category" */
export type subsidy_subsidy_category_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subsidy_subsidy_category_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  subsidy_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "subsidy_subsidy_category" */
export type subsidy_subsidy_category_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subsidy_subsidy_category_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  subsidy_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "subsidy_subsidy_category" */
export type subsidy_subsidy_category_variance_order_by = {
  id?: InputMaybe<order_by>;
  subsidy_id?: InputMaybe<order_by>;
};

/** aggregate sum on columns */
export type subsidy_sum_fields = {
  id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "subsidy_type" */
export type subsidy_type = {
  id: Scalars['String'];
};

/** aggregated selection of "subsidy_type" */
export type subsidy_type_aggregate = {
  aggregate?: Maybe<subsidy_type_aggregate_fields>;
  nodes: Array<subsidy_type>;
};

/** aggregate fields of "subsidy_type" */
export type subsidy_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<subsidy_type_max_fields>;
  min?: Maybe<subsidy_type_min_fields>;
};

/** aggregate fields of "subsidy_type" */
export type subsidy_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subsidy_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "subsidy_type". All fields are combined with a logical 'AND'. */
export type subsidy_type_bool_exp = {
  _and?: InputMaybe<Array<subsidy_type_bool_exp>>;
  _not?: InputMaybe<subsidy_type_bool_exp>;
  _or?: InputMaybe<Array<subsidy_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { subsidy_type_constraint };

export { subsidy_type_enum };

/** Boolean expression to compare columns of type "subsidy_type_enum". All fields are combined with logical 'AND'. */
export type subsidy_type_enum_comparison_exp = {
  _eq?: InputMaybe<subsidy_type_enum>;
  _in?: InputMaybe<Array<subsidy_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<subsidy_type_enum>;
  _nin?: InputMaybe<Array<subsidy_type_enum>>;
};

/** input type for inserting data into table "subsidy_type" */
export type subsidy_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type subsidy_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type subsidy_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "subsidy_type" */
export type subsidy_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<subsidy_type>;
};

/** on_conflict condition type for table "subsidy_type" */
export type subsidy_type_on_conflict = {
  constraint: subsidy_type_constraint;
  update_columns?: Array<subsidy_type_update_column>;
  where?: InputMaybe<subsidy_type_bool_exp>;
};

/** Ordering options when selecting data from "subsidy_type". */
export type subsidy_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: subsidy_type */
export type subsidy_type_pk_columns_input = {
  id: Scalars['String'];
};

export { subsidy_type_select_column };

/** input type for updating data in table "subsidy_type" */
export type subsidy_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "subsidy_type" */
export type subsidy_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subsidy_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subsidy_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { subsidy_type_update_column };

export type subsidy_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subsidy_type_set_input>;
  /** filter the rows which have to be updated */
  where: subsidy_type_bool_exp;
};

export { subsidy_update_column };

export type subsidy_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subsidy_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subsidy_set_input>;
  /** filter the rows which have to be updated */
  where: subsidy_bool_exp;
};

/** aggregate var_pop on columns */
export type subsidy_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type subsidy_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type subsidy_variance_fields = {
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "sync_state" */
export type sync_state = {
  id: Scalars['String'];
};

/** aggregated selection of "sync_state" */
export type sync_state_aggregate = {
  aggregate?: Maybe<sync_state_aggregate_fields>;
  nodes: Array<sync_state>;
};

/** aggregate fields of "sync_state" */
export type sync_state_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<sync_state_max_fields>;
  min?: Maybe<sync_state_min_fields>;
};

/** aggregate fields of "sync_state" */
export type sync_state_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<sync_state_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "sync_state". All fields are combined with a logical 'AND'. */
export type sync_state_bool_exp = {
  _and?: InputMaybe<Array<sync_state_bool_exp>>;
  _not?: InputMaybe<sync_state_bool_exp>;
  _or?: InputMaybe<Array<sync_state_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { sync_state_constraint };

export { sync_state_enum };

/** Boolean expression to compare columns of type "sync_state_enum". All fields are combined with logical 'AND'. */
export type sync_state_enum_comparison_exp = {
  _eq?: InputMaybe<sync_state_enum>;
  _in?: InputMaybe<Array<sync_state_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<sync_state_enum>;
  _nin?: InputMaybe<Array<sync_state_enum>>;
};

/** input type for inserting data into table "sync_state" */
export type sync_state_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type sync_state_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type sync_state_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "sync_state" */
export type sync_state_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<sync_state>;
};

/** on_conflict condition type for table "sync_state" */
export type sync_state_on_conflict = {
  constraint: sync_state_constraint;
  update_columns?: Array<sync_state_update_column>;
  where?: InputMaybe<sync_state_bool_exp>;
};

/** Ordering options when selecting data from "sync_state". */
export type sync_state_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: sync_state */
export type sync_state_pk_columns_input = {
  id: Scalars['String'];
};

export { sync_state_select_column };

/** input type for updating data in table "sync_state" */
export type sync_state_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "sync_state" */
export type sync_state_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: sync_state_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type sync_state_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { sync_state_update_column };

export type sync_state_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<sync_state_set_input>;
  /** filter the rows which have to be updated */
  where: sync_state_bool_exp;
};

/** columns and relationships of "target_path" */
export type target_path = {
  /** An object relationship */
  building: building;
  building_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  target_path_template: target_path_template;
  target_path_template_id: Scalars['Int'];
  value: Scalars['Float'];
  value_total: Scalars['Float'];
  year: Scalars['Int'];
};

/** aggregated selection of "target_path" */
export type target_path_aggregate = {
  aggregate?: Maybe<target_path_aggregate_fields>;
  nodes: Array<target_path>;
};

export type target_path_aggregate_bool_exp = {
  count?: InputMaybe<target_path_aggregate_bool_exp_count>;
};

export type target_path_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<target_path_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<target_path_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "target_path" */
export type target_path_aggregate_fields = {
  avg?: Maybe<target_path_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<target_path_max_fields>;
  min?: Maybe<target_path_min_fields>;
  stddev?: Maybe<target_path_stddev_fields>;
  stddev_pop?: Maybe<target_path_stddev_pop_fields>;
  stddev_samp?: Maybe<target_path_stddev_samp_fields>;
  sum?: Maybe<target_path_sum_fields>;
  var_pop?: Maybe<target_path_var_pop_fields>;
  var_samp?: Maybe<target_path_var_samp_fields>;
  variance?: Maybe<target_path_variance_fields>;
};

/** aggregate fields of "target_path" */
export type target_path_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<target_path_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "target_path" */
export type target_path_aggregate_order_by = {
  avg?: InputMaybe<target_path_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<target_path_max_order_by>;
  min?: InputMaybe<target_path_min_order_by>;
  stddev?: InputMaybe<target_path_stddev_order_by>;
  stddev_pop?: InputMaybe<target_path_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<target_path_stddev_samp_order_by>;
  sum?: InputMaybe<target_path_sum_order_by>;
  var_pop?: InputMaybe<target_path_var_pop_order_by>;
  var_samp?: InputMaybe<target_path_var_samp_order_by>;
  variance?: InputMaybe<target_path_variance_order_by>;
};

/** input type for inserting array relation for remote table "target_path" */
export type target_path_arr_rel_insert_input = {
  data: Array<target_path_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<target_path_on_conflict>;
};

/** aggregate avg on columns */
export type target_path_avg_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  target_path_template_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
  value_total?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "target_path" */
export type target_path_avg_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  target_path_template_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
  value_total?: InputMaybe<order_by>;
  year?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "target_path". All fields are combined with a logical 'AND'. */
export type target_path_bool_exp = {
  _and?: InputMaybe<Array<target_path_bool_exp>>;
  _not?: InputMaybe<target_path_bool_exp>;
  _or?: InputMaybe<Array<target_path_bool_exp>>;
  building?: InputMaybe<building_bool_exp>;
  building_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  target_path_template?: InputMaybe<target_path_template_bool_exp>;
  target_path_template_id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<Float_comparison_exp>;
  value_total?: InputMaybe<Float_comparison_exp>;
  year?: InputMaybe<Int_comparison_exp>;
};

export { target_path_constraint };

/** input type for incrementing numeric columns in table "target_path" */
export type target_path_inc_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  target_path_template_id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Float']>;
  value_total?: InputMaybe<Scalars['Float']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "target_path" */
export type target_path_insert_input = {
  building?: InputMaybe<building_obj_rel_insert_input>;
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  target_path_template?: InputMaybe<target_path_template_obj_rel_insert_input>;
  target_path_template_id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Float']>;
  value_total?: InputMaybe<Scalars['Float']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type target_path_max_fields = {
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  target_path_template_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
  value_total?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "target_path" */
export type target_path_max_order_by = {
  building_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  target_path_template_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
  value_total?: InputMaybe<order_by>;
  year?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type target_path_min_fields = {
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  target_path_template_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
  value_total?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "target_path" */
export type target_path_min_order_by = {
  building_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  target_path_template_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
  value_total?: InputMaybe<order_by>;
  year?: InputMaybe<order_by>;
};

/** response of any mutation on the table "target_path" */
export type target_path_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<target_path>;
};

/** on_conflict condition type for table "target_path" */
export type target_path_on_conflict = {
  constraint: target_path_constraint;
  update_columns?: Array<target_path_update_column>;
  where?: InputMaybe<target_path_bool_exp>;
};

/** Ordering options when selecting data from "target_path". */
export type target_path_order_by = {
  building?: InputMaybe<building_order_by>;
  building_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  target_path_template?: InputMaybe<target_path_template_order_by>;
  target_path_template_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
  value_total?: InputMaybe<order_by>;
  year?: InputMaybe<order_by>;
};

/** primary key columns input for table: target_path */
export type target_path_pk_columns_input = {
  id: Scalars['Int'];
};

export { target_path_select_column };

/** input type for updating data in table "target_path" */
export type target_path_set_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  target_path_template_id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Float']>;
  value_total?: InputMaybe<Scalars['Float']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type target_path_stddev_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  target_path_template_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
  value_total?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "target_path" */
export type target_path_stddev_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  target_path_template_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
  value_total?: InputMaybe<order_by>;
  year?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type target_path_stddev_pop_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  target_path_template_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
  value_total?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "target_path" */
export type target_path_stddev_pop_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  target_path_template_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
  value_total?: InputMaybe<order_by>;
  year?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type target_path_stddev_samp_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  target_path_template_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
  value_total?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "target_path" */
export type target_path_stddev_samp_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  target_path_template_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
  value_total?: InputMaybe<order_by>;
  year?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "target_path" */
export type target_path_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: target_path_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type target_path_stream_cursor_value_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  target_path_template_id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Float']>;
  value_total?: InputMaybe<Scalars['Float']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type target_path_sum_fields = {
  building_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  target_path_template_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
  value_total?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "target_path" */
export type target_path_sum_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  target_path_template_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
  value_total?: InputMaybe<order_by>;
  year?: InputMaybe<order_by>;
};

/** columns and relationships of "target_path_template" */
export type target_path_template = {
  created_at: Scalars['timestamp'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  org_id?: Maybe<Scalars['Int']>;
  target_path_template_type_id: target_path_template_type_enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "target_path_template" */
export type target_path_template_aggregate = {
  aggregate?: Maybe<target_path_template_aggregate_fields>;
  nodes: Array<target_path_template>;
};

/** aggregate fields of "target_path_template" */
export type target_path_template_aggregate_fields = {
  avg?: Maybe<target_path_template_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<target_path_template_max_fields>;
  min?: Maybe<target_path_template_min_fields>;
  stddev?: Maybe<target_path_template_stddev_fields>;
  stddev_pop?: Maybe<target_path_template_stddev_pop_fields>;
  stddev_samp?: Maybe<target_path_template_stddev_samp_fields>;
  sum?: Maybe<target_path_template_sum_fields>;
  var_pop?: Maybe<target_path_template_var_pop_fields>;
  var_samp?: Maybe<target_path_template_var_samp_fields>;
  variance?: Maybe<target_path_template_variance_fields>;
};

/** aggregate fields of "target_path_template" */
export type target_path_template_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<target_path_template_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type target_path_template_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "target_path_template". All fields are combined with a logical 'AND'. */
export type target_path_template_bool_exp = {
  _and?: InputMaybe<Array<target_path_template_bool_exp>>;
  _not?: InputMaybe<target_path_template_bool_exp>;
  _or?: InputMaybe<Array<target_path_template_bool_exp>>;
  created_at?: InputMaybe<timestamp_comparison_exp>;
  description?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  org_id?: InputMaybe<Int_comparison_exp>;
  target_path_template_type_id?: InputMaybe<target_path_template_type_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

export { target_path_template_constraint };

/** input type for incrementing numeric columns in table "target_path_template" */
export type target_path_template_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "target_path_template" */
export type target_path_template_insert_input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['Int']>;
  target_path_template_type_id?: InputMaybe<target_path_template_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type target_path_template_max_fields = {
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type target_path_template_min_fields = {
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "target_path_template" */
export type target_path_template_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<target_path_template>;
};

/** input type for inserting object relation for remote table "target_path_template" */
export type target_path_template_obj_rel_insert_input = {
  data: target_path_template_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<target_path_template_on_conflict>;
};

/** on_conflict condition type for table "target_path_template" */
export type target_path_template_on_conflict = {
  constraint: target_path_template_constraint;
  update_columns?: Array<target_path_template_update_column>;
  where?: InputMaybe<target_path_template_bool_exp>;
};

/** Ordering options when selecting data from "target_path_template". */
export type target_path_template_order_by = {
  created_at?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  target_path_template_type_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: target_path_template */
export type target_path_template_pk_columns_input = {
  id: Scalars['Int'];
};

export { target_path_template_select_column };

/** input type for updating data in table "target_path_template" */
export type target_path_template_set_input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['Int']>;
  target_path_template_type_id?: InputMaybe<target_path_template_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type target_path_template_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type target_path_template_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type target_path_template_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "target_path_template" */
export type target_path_template_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: target_path_template_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type target_path_template_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['Int']>;
  target_path_template_type_id?: InputMaybe<target_path_template_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type target_path_template_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "target_path_template_type" */
export type target_path_template_type = {
  id: Scalars['String'];
};

/** aggregated selection of "target_path_template_type" */
export type target_path_template_type_aggregate = {
  aggregate?: Maybe<target_path_template_type_aggregate_fields>;
  nodes: Array<target_path_template_type>;
};

/** aggregate fields of "target_path_template_type" */
export type target_path_template_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<target_path_template_type_max_fields>;
  min?: Maybe<target_path_template_type_min_fields>;
};

/** aggregate fields of "target_path_template_type" */
export type target_path_template_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<target_path_template_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "target_path_template_type". All fields are combined with a logical 'AND'. */
export type target_path_template_type_bool_exp = {
  _and?: InputMaybe<Array<target_path_template_type_bool_exp>>;
  _not?: InputMaybe<target_path_template_type_bool_exp>;
  _or?: InputMaybe<Array<target_path_template_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { target_path_template_type_constraint };

export { target_path_template_type_enum };

/** Boolean expression to compare columns of type "target_path_template_type_enum". All fields are combined with logical 'AND'. */
export type target_path_template_type_enum_comparison_exp = {
  _eq?: InputMaybe<target_path_template_type_enum>;
  _in?: InputMaybe<Array<target_path_template_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<target_path_template_type_enum>;
  _nin?: InputMaybe<Array<target_path_template_type_enum>>;
};

/** input type for inserting data into table "target_path_template_type" */
export type target_path_template_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type target_path_template_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type target_path_template_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "target_path_template_type" */
export type target_path_template_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<target_path_template_type>;
};

/** on_conflict condition type for table "target_path_template_type" */
export type target_path_template_type_on_conflict = {
  constraint: target_path_template_type_constraint;
  update_columns?: Array<target_path_template_type_update_column>;
  where?: InputMaybe<target_path_template_type_bool_exp>;
};

/** Ordering options when selecting data from "target_path_template_type". */
export type target_path_template_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: target_path_template_type */
export type target_path_template_type_pk_columns_input = {
  id: Scalars['String'];
};

export { target_path_template_type_select_column };

/** input type for updating data in table "target_path_template_type" */
export type target_path_template_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "target_path_template_type" */
export type target_path_template_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: target_path_template_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type target_path_template_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { target_path_template_type_update_column };

export type target_path_template_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<target_path_template_type_set_input>;
  /** filter the rows which have to be updated */
  where: target_path_template_type_bool_exp;
};

export { target_path_template_update_column };

export type target_path_template_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<target_path_template_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<target_path_template_set_input>;
  /** filter the rows which have to be updated */
  where: target_path_template_bool_exp;
};

/** aggregate var_pop on columns */
export type target_path_template_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type target_path_template_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type target_path_template_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

export { target_path_update_column };

export type target_path_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<target_path_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<target_path_set_input>;
  /** filter the rows which have to be updated */
  where: target_path_bool_exp;
};

/** aggregate var_pop on columns */
export type target_path_var_pop_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  target_path_template_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
  value_total?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "target_path" */
export type target_path_var_pop_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  target_path_template_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
  value_total?: InputMaybe<order_by>;
  year?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type target_path_var_samp_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  target_path_template_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
  value_total?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "target_path" */
export type target_path_var_samp_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  target_path_template_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
  value_total?: InputMaybe<order_by>;
  year?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type target_path_variance_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  target_path_template_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
  value_total?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "target_path" */
export type target_path_variance_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  target_path_template_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
  value_total?: InputMaybe<order_by>;
  year?: InputMaybe<order_by>;
};

/** Enum: Tax bracket for CO2 Emissions, in the format "_TENANT_LANDLORD" */
export type tax_bracket = {
  id: Scalars['String'];
};

/** aggregated selection of "tax_bracket" */
export type tax_bracket_aggregate = {
  aggregate?: Maybe<tax_bracket_aggregate_fields>;
  nodes: Array<tax_bracket>;
};

/** aggregate fields of "tax_bracket" */
export type tax_bracket_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<tax_bracket_max_fields>;
  min?: Maybe<tax_bracket_min_fields>;
};

/** aggregate fields of "tax_bracket" */
export type tax_bracket_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<tax_bracket_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "tax_bracket". All fields are combined with a logical 'AND'. */
export type tax_bracket_bool_exp = {
  _and?: InputMaybe<Array<tax_bracket_bool_exp>>;
  _not?: InputMaybe<tax_bracket_bool_exp>;
  _or?: InputMaybe<Array<tax_bracket_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { tax_bracket_constraint };

export { tax_bracket_enum };

/** Boolean expression to compare columns of type "tax_bracket_enum". All fields are combined with logical 'AND'. */
export type tax_bracket_enum_comparison_exp = {
  _eq?: InputMaybe<tax_bracket_enum>;
  _in?: InputMaybe<Array<tax_bracket_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<tax_bracket_enum>;
  _nin?: InputMaybe<Array<tax_bracket_enum>>;
};

/** input type for inserting data into table "tax_bracket" */
export type tax_bracket_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type tax_bracket_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type tax_bracket_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "tax_bracket" */
export type tax_bracket_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<tax_bracket>;
};

/** input type for inserting object relation for remote table "tax_bracket" */
export type tax_bracket_obj_rel_insert_input = {
  data: tax_bracket_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<tax_bracket_on_conflict>;
};

/** on_conflict condition type for table "tax_bracket" */
export type tax_bracket_on_conflict = {
  constraint: tax_bracket_constraint;
  update_columns?: Array<tax_bracket_update_column>;
  where?: InputMaybe<tax_bracket_bool_exp>;
};

/** Ordering options when selecting data from "tax_bracket". */
export type tax_bracket_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: tax_bracket */
export type tax_bracket_pk_columns_input = {
  id: Scalars['String'];
};

export { tax_bracket_select_column };

/** input type for updating data in table "tax_bracket" */
export type tax_bracket_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "tax_bracket" */
export type tax_bracket_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: tax_bracket_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type tax_bracket_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { tax_bracket_update_column };

export type tax_bracket_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<tax_bracket_set_input>;
  /** filter the rows which have to be updated */
  where: tax_bracket_bool_exp;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type timestamp_comparison_exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type timestamptz_comparison_exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "type_of_use" */
export type type_of_use = {
  id: Scalars['String'];
};

/** aggregated selection of "type_of_use" */
export type type_of_use_aggregate = {
  aggregate?: Maybe<type_of_use_aggregate_fields>;
  nodes: Array<type_of_use>;
};

/** aggregate fields of "type_of_use" */
export type type_of_use_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<type_of_use_max_fields>;
  min?: Maybe<type_of_use_min_fields>;
};

/** aggregate fields of "type_of_use" */
export type type_of_use_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<type_of_use_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "type_of_use". All fields are combined with a logical 'AND'. */
export type type_of_use_bool_exp = {
  _and?: InputMaybe<Array<type_of_use_bool_exp>>;
  _not?: InputMaybe<type_of_use_bool_exp>;
  _or?: InputMaybe<Array<type_of_use_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { type_of_use_constraint };

export { type_of_use_enum };

/** Boolean expression to compare columns of type "type_of_use_enum". All fields are combined with logical 'AND'. */
export type type_of_use_enum_comparison_exp = {
  _eq?: InputMaybe<type_of_use_enum>;
  _in?: InputMaybe<Array<type_of_use_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<type_of_use_enum>;
  _nin?: InputMaybe<Array<type_of_use_enum>>;
};

/** input type for inserting data into table "type_of_use" */
export type type_of_use_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type type_of_use_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type type_of_use_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "type_of_use" */
export type type_of_use_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<type_of_use>;
};

/** on_conflict condition type for table "type_of_use" */
export type type_of_use_on_conflict = {
  constraint: type_of_use_constraint;
  update_columns?: Array<type_of_use_update_column>;
  where?: InputMaybe<type_of_use_bool_exp>;
};

/** Ordering options when selecting data from "type_of_use". */
export type type_of_use_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: type_of_use */
export type type_of_use_pk_columns_input = {
  id: Scalars['String'];
};

export { type_of_use_select_column };

/** input type for updating data in table "type_of_use" */
export type type_of_use_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "type_of_use" */
export type type_of_use_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: type_of_use_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type type_of_use_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { type_of_use_update_column };

export type type_of_use_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<type_of_use_set_input>;
  /** filter the rows which have to be updated */
  where: type_of_use_bool_exp;
};

/** columns and relationships of "user" */
export type user = {
  auth0_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  email?: Maybe<Scalars['String']>;
  featureFlags: GetUserFeatureFlagsOutput;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  language: language_enum;
  last_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  org: org;
  org_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  user_role: Array<user_role>;
  /** An aggregate relationship */
  user_role_aggregate: user_role_aggregate;
};

/** columns and relationships of "user" */
export type useruser_roleArgs = {
  distinct_on?: InputMaybe<Array<user_role_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_role_order_by>>;
  where?: InputMaybe<user_role_bool_exp>;
};

/** columns and relationships of "user" */
export type useruser_role_aggregateArgs = {
  distinct_on?: InputMaybe<Array<user_role_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<user_role_order_by>>;
  where?: InputMaybe<user_role_bool_exp>;
};

/** aggregated selection of "user" */
export type user_aggregate = {
  aggregate?: Maybe<user_aggregate_fields>;
  nodes: Array<user>;
};

export type user_aggregate_bool_exp = {
  count?: InputMaybe<user_aggregate_bool_exp_count>;
};

export type user_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<user_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<user_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "user" */
export type user_aggregate_fields = {
  avg?: Maybe<user_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<user_max_fields>;
  min?: Maybe<user_min_fields>;
  stddev?: Maybe<user_stddev_fields>;
  stddev_pop?: Maybe<user_stddev_pop_fields>;
  stddev_samp?: Maybe<user_stddev_samp_fields>;
  sum?: Maybe<user_sum_fields>;
  var_pop?: Maybe<user_var_pop_fields>;
  var_samp?: Maybe<user_var_samp_fields>;
  variance?: Maybe<user_variance_fields>;
};

/** aggregate fields of "user" */
export type user_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<user_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user" */
export type user_aggregate_order_by = {
  avg?: InputMaybe<user_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<user_max_order_by>;
  min?: InputMaybe<user_min_order_by>;
  stddev?: InputMaybe<user_stddev_order_by>;
  stddev_pop?: InputMaybe<user_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<user_stddev_samp_order_by>;
  sum?: InputMaybe<user_sum_order_by>;
  var_pop?: InputMaybe<user_var_pop_order_by>;
  var_samp?: InputMaybe<user_var_samp_order_by>;
  variance?: InputMaybe<user_variance_order_by>;
};

/** input type for inserting array relation for remote table "user" */
export type user_arr_rel_insert_input = {
  data: Array<user_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<user_on_conflict>;
};

/** aggregate avg on columns */
export type user_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user" */
export type user_avg_order_by = {
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type user_bool_exp = {
  _and?: InputMaybe<Array<user_bool_exp>>;
  _not?: InputMaybe<user_bool_exp>;
  _or?: InputMaybe<Array<user_bool_exp>>;
  auth0_id?: InputMaybe<String_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  email?: InputMaybe<String_comparison_exp>;
  first_name?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  language?: InputMaybe<language_enum_comparison_exp>;
  last_name?: InputMaybe<String_comparison_exp>;
  org?: InputMaybe<org_bool_exp>;
  org_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  user_role?: InputMaybe<user_role_bool_exp>;
  user_role_aggregate?: InputMaybe<user_role_aggregate_bool_exp>;
};

/** Contains information about whether a user has permission to perform operations on buildings. */
export type user_building_permission = {
  /** An object relationship */
  building: building;
  building_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  read: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: user;
  user_id: Scalars['Int'];
  write: Scalars['Boolean'];
};

/** aggregated selection of "user_building_permission" */
export type user_building_permission_aggregate = {
  aggregate?: Maybe<user_building_permission_aggregate_fields>;
  nodes: Array<user_building_permission>;
};

export type user_building_permission_aggregate_bool_exp = {
  bool_and?: InputMaybe<user_building_permission_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<user_building_permission_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<user_building_permission_aggregate_bool_exp_count>;
};

export type user_building_permission_aggregate_bool_exp_bool_and = {
  arguments: user_building_permission_select_column_user_building_permission_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<user_building_permission_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type user_building_permission_aggregate_bool_exp_bool_or = {
  arguments: user_building_permission_select_column_user_building_permission_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<user_building_permission_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type user_building_permission_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<user_building_permission_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<user_building_permission_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "user_building_permission" */
export type user_building_permission_aggregate_fields = {
  avg?: Maybe<user_building_permission_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<user_building_permission_max_fields>;
  min?: Maybe<user_building_permission_min_fields>;
  stddev?: Maybe<user_building_permission_stddev_fields>;
  stddev_pop?: Maybe<user_building_permission_stddev_pop_fields>;
  stddev_samp?: Maybe<user_building_permission_stddev_samp_fields>;
  sum?: Maybe<user_building_permission_sum_fields>;
  var_pop?: Maybe<user_building_permission_var_pop_fields>;
  var_samp?: Maybe<user_building_permission_var_samp_fields>;
  variance?: Maybe<user_building_permission_variance_fields>;
};

/** aggregate fields of "user_building_permission" */
export type user_building_permission_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<user_building_permission_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_building_permission" */
export type user_building_permission_aggregate_order_by = {
  avg?: InputMaybe<user_building_permission_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<user_building_permission_max_order_by>;
  min?: InputMaybe<user_building_permission_min_order_by>;
  stddev?: InputMaybe<user_building_permission_stddev_order_by>;
  stddev_pop?: InputMaybe<user_building_permission_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<user_building_permission_stddev_samp_order_by>;
  sum?: InputMaybe<user_building_permission_sum_order_by>;
  var_pop?: InputMaybe<user_building_permission_var_pop_order_by>;
  var_samp?: InputMaybe<user_building_permission_var_samp_order_by>;
  variance?: InputMaybe<user_building_permission_variance_order_by>;
};

/** input type for inserting array relation for remote table "user_building_permission" */
export type user_building_permission_arr_rel_insert_input = {
  data: Array<user_building_permission_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<user_building_permission_on_conflict>;
};

/** aggregate avg on columns */
export type user_building_permission_avg_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_building_permission" */
export type user_building_permission_avg_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "user_building_permission". All fields are combined with a logical 'AND'. */
export type user_building_permission_bool_exp = {
  _and?: InputMaybe<Array<user_building_permission_bool_exp>>;
  _not?: InputMaybe<user_building_permission_bool_exp>;
  _or?: InputMaybe<Array<user_building_permission_bool_exp>>;
  building?: InputMaybe<building_bool_exp>;
  building_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  read?: InputMaybe<Boolean_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  user?: InputMaybe<user_bool_exp>;
  user_id?: InputMaybe<Int_comparison_exp>;
  write?: InputMaybe<Boolean_comparison_exp>;
};

export { user_building_permission_constraint };

/** input type for incrementing numeric columns in table "user_building_permission" */
export type user_building_permission_inc_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_building_permission" */
export type user_building_permission_insert_input = {
  building?: InputMaybe<building_obj_rel_insert_input>;
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  read?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<user_obj_rel_insert_input>;
  user_id?: InputMaybe<Scalars['Int']>;
  write?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type user_building_permission_max_fields = {
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "user_building_permission" */
export type user_building_permission_max_order_by = {
  building_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type user_building_permission_min_fields = {
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "user_building_permission" */
export type user_building_permission_min_order_by = {
  building_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "user_building_permission" */
export type user_building_permission_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<user_building_permission>;
};

/** on_conflict condition type for table "user_building_permission" */
export type user_building_permission_on_conflict = {
  constraint: user_building_permission_constraint;
  update_columns?: Array<user_building_permission_update_column>;
  where?: InputMaybe<user_building_permission_bool_exp>;
};

/** Ordering options when selecting data from "user_building_permission". */
export type user_building_permission_order_by = {
  building?: InputMaybe<building_order_by>;
  building_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  read?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  user?: InputMaybe<user_order_by>;
  user_id?: InputMaybe<order_by>;
  write?: InputMaybe<order_by>;
};

/** primary key columns input for table: user_building_permission */
export type user_building_permission_pk_columns_input = {
  id: Scalars['Int'];
};

export { user_building_permission_select_column };

export { user_building_permission_select_column_user_building_permission_aggregate_bool_exp_bool_and_arguments_columns };

export { user_building_permission_select_column_user_building_permission_aggregate_bool_exp_bool_or_arguments_columns };

/** input type for updating data in table "user_building_permission" */
export type user_building_permission_set_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  read?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['Int']>;
  write?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type user_building_permission_stddev_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_building_permission" */
export type user_building_permission_stddev_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type user_building_permission_stddev_pop_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_building_permission" */
export type user_building_permission_stddev_pop_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type user_building_permission_stddev_samp_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_building_permission" */
export type user_building_permission_stddev_samp_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "user_building_permission" */
export type user_building_permission_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: user_building_permission_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type user_building_permission_stream_cursor_value_input = {
  building_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  read?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['Int']>;
  write?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type user_building_permission_sum_fields = {
  building_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_building_permission" */
export type user_building_permission_sum_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

export { user_building_permission_update_column };

export type user_building_permission_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<user_building_permission_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<user_building_permission_set_input>;
  /** filter the rows which have to be updated */
  where: user_building_permission_bool_exp;
};

/** aggregate var_pop on columns */
export type user_building_permission_var_pop_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_building_permission" */
export type user_building_permission_var_pop_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type user_building_permission_var_samp_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_building_permission" */
export type user_building_permission_var_samp_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type user_building_permission_variance_fields = {
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_building_permission" */
export type user_building_permission_variance_order_by = {
  building_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

export { user_constraint };

/** input type for incrementing numeric columns in table "user" */
export type user_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  org_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user" */
export type user_insert_input = {
  auth0_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  language?: InputMaybe<language_enum>;
  last_name?: InputMaybe<Scalars['String']>;
  org?: InputMaybe<org_obj_rel_insert_input>;
  org_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_role?: InputMaybe<user_role_arr_rel_insert_input>;
};

/** aggregate max on columns */
export type user_max_fields = {
  auth0_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "user" */
export type user_max_order_by = {
  auth0_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  first_name?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  last_name?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type user_min_fields = {
  auth0_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "user" */
export type user_min_order_by = {
  auth0_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  first_name?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  last_name?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "user" */
export type user_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<user>;
};

/** input type for inserting object relation for remote table "user" */
export type user_obj_rel_insert_input = {
  data: user_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<user_on_conflict>;
};

/** on_conflict condition type for table "user" */
export type user_on_conflict = {
  constraint: user_constraint;
  update_columns?: Array<user_update_column>;
  where?: InputMaybe<user_bool_exp>;
};

/** Ordering options when selecting data from "user". */
export type user_order_by = {
  auth0_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  first_name?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language?: InputMaybe<order_by>;
  last_name?: InputMaybe<order_by>;
  org?: InputMaybe<org_order_by>;
  org_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  user_role_aggregate?: InputMaybe<user_role_aggregate_order_by>;
};

/** primary key columns input for table: user */
export type user_pk_columns_input = {
  id: Scalars['Int'];
};

/** Contains information about whether a user has permission to perform operations on all buildings inside a portfolio. */
export type user_portfolio_permission = {
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  portfolio: portfolio;
  portfolio_id: Scalars['Int'];
  read: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: user;
  user_id: Scalars['Int'];
  write: Scalars['Boolean'];
};

/** aggregated selection of "user_portfolio_permission" */
export type user_portfolio_permission_aggregate = {
  aggregate?: Maybe<user_portfolio_permission_aggregate_fields>;
  nodes: Array<user_portfolio_permission>;
};

export type user_portfolio_permission_aggregate_bool_exp = {
  bool_and?: InputMaybe<user_portfolio_permission_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<user_portfolio_permission_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<user_portfolio_permission_aggregate_bool_exp_count>;
};

export type user_portfolio_permission_aggregate_bool_exp_bool_and = {
  arguments: user_portfolio_permission_select_column_user_portfolio_permission_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<user_portfolio_permission_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type user_portfolio_permission_aggregate_bool_exp_bool_or = {
  arguments: user_portfolio_permission_select_column_user_portfolio_permission_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<user_portfolio_permission_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type user_portfolio_permission_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<user_portfolio_permission_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<user_portfolio_permission_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "user_portfolio_permission" */
export type user_portfolio_permission_aggregate_fields = {
  avg?: Maybe<user_portfolio_permission_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<user_portfolio_permission_max_fields>;
  min?: Maybe<user_portfolio_permission_min_fields>;
  stddev?: Maybe<user_portfolio_permission_stddev_fields>;
  stddev_pop?: Maybe<user_portfolio_permission_stddev_pop_fields>;
  stddev_samp?: Maybe<user_portfolio_permission_stddev_samp_fields>;
  sum?: Maybe<user_portfolio_permission_sum_fields>;
  var_pop?: Maybe<user_portfolio_permission_var_pop_fields>;
  var_samp?: Maybe<user_portfolio_permission_var_samp_fields>;
  variance?: Maybe<user_portfolio_permission_variance_fields>;
};

/** aggregate fields of "user_portfolio_permission" */
export type user_portfolio_permission_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<user_portfolio_permission_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_portfolio_permission" */
export type user_portfolio_permission_aggregate_order_by = {
  avg?: InputMaybe<user_portfolio_permission_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<user_portfolio_permission_max_order_by>;
  min?: InputMaybe<user_portfolio_permission_min_order_by>;
  stddev?: InputMaybe<user_portfolio_permission_stddev_order_by>;
  stddev_pop?: InputMaybe<user_portfolio_permission_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<user_portfolio_permission_stddev_samp_order_by>;
  sum?: InputMaybe<user_portfolio_permission_sum_order_by>;
  var_pop?: InputMaybe<user_portfolio_permission_var_pop_order_by>;
  var_samp?: InputMaybe<user_portfolio_permission_var_samp_order_by>;
  variance?: InputMaybe<user_portfolio_permission_variance_order_by>;
};

/** input type for inserting array relation for remote table "user_portfolio_permission" */
export type user_portfolio_permission_arr_rel_insert_input = {
  data: Array<user_portfolio_permission_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<user_portfolio_permission_on_conflict>;
};

/** aggregate avg on columns */
export type user_portfolio_permission_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_portfolio_permission" */
export type user_portfolio_permission_avg_order_by = {
  id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "user_portfolio_permission". All fields are combined with a logical 'AND'. */
export type user_portfolio_permission_bool_exp = {
  _and?: InputMaybe<Array<user_portfolio_permission_bool_exp>>;
  _not?: InputMaybe<user_portfolio_permission_bool_exp>;
  _or?: InputMaybe<Array<user_portfolio_permission_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  portfolio?: InputMaybe<portfolio_bool_exp>;
  portfolio_id?: InputMaybe<Int_comparison_exp>;
  read?: InputMaybe<Boolean_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  user?: InputMaybe<user_bool_exp>;
  user_id?: InputMaybe<Int_comparison_exp>;
  write?: InputMaybe<Boolean_comparison_exp>;
};

export { user_portfolio_permission_constraint };

/** input type for incrementing numeric columns in table "user_portfolio_permission" */
export type user_portfolio_permission_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  portfolio_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_portfolio_permission" */
export type user_portfolio_permission_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  portfolio?: InputMaybe<portfolio_obj_rel_insert_input>;
  portfolio_id?: InputMaybe<Scalars['Int']>;
  read?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<user_obj_rel_insert_input>;
  user_id?: InputMaybe<Scalars['Int']>;
  write?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type user_portfolio_permission_max_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  portfolio_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "user_portfolio_permission" */
export type user_portfolio_permission_max_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type user_portfolio_permission_min_fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  portfolio_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "user_portfolio_permission" */
export type user_portfolio_permission_min_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "user_portfolio_permission" */
export type user_portfolio_permission_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<user_portfolio_permission>;
};

/** on_conflict condition type for table "user_portfolio_permission" */
export type user_portfolio_permission_on_conflict = {
  constraint: user_portfolio_permission_constraint;
  update_columns?: Array<user_portfolio_permission_update_column>;
  where?: InputMaybe<user_portfolio_permission_bool_exp>;
};

/** Ordering options when selecting data from "user_portfolio_permission". */
export type user_portfolio_permission_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  portfolio?: InputMaybe<portfolio_order_by>;
  portfolio_id?: InputMaybe<order_by>;
  read?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  user?: InputMaybe<user_order_by>;
  user_id?: InputMaybe<order_by>;
  write?: InputMaybe<order_by>;
};

/** primary key columns input for table: user_portfolio_permission */
export type user_portfolio_permission_pk_columns_input = {
  id: Scalars['Int'];
};

export { user_portfolio_permission_select_column };

export { user_portfolio_permission_select_column_user_portfolio_permission_aggregate_bool_exp_bool_and_arguments_columns };

export { user_portfolio_permission_select_column_user_portfolio_permission_aggregate_bool_exp_bool_or_arguments_columns };

/** input type for updating data in table "user_portfolio_permission" */
export type user_portfolio_permission_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  portfolio_id?: InputMaybe<Scalars['Int']>;
  read?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['Int']>;
  write?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type user_portfolio_permission_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_portfolio_permission" */
export type user_portfolio_permission_stddev_order_by = {
  id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type user_portfolio_permission_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_portfolio_permission" */
export type user_portfolio_permission_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type user_portfolio_permission_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_portfolio_permission" */
export type user_portfolio_permission_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "user_portfolio_permission" */
export type user_portfolio_permission_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: user_portfolio_permission_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type user_portfolio_permission_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  portfolio_id?: InputMaybe<Scalars['Int']>;
  read?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['Int']>;
  write?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type user_portfolio_permission_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  portfolio_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_portfolio_permission" */
export type user_portfolio_permission_sum_order_by = {
  id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

export { user_portfolio_permission_update_column };

export type user_portfolio_permission_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<user_portfolio_permission_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<user_portfolio_permission_set_input>;
  /** filter the rows which have to be updated */
  where: user_portfolio_permission_bool_exp;
};

/** aggregate var_pop on columns */
export type user_portfolio_permission_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_portfolio_permission" */
export type user_portfolio_permission_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type user_portfolio_permission_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_portfolio_permission" */
export type user_portfolio_permission_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type user_portfolio_permission_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  portfolio_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_portfolio_permission" */
export type user_portfolio_permission_variance_order_by = {
  id?: InputMaybe<order_by>;
  portfolio_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** columns and relationships of "user_role" */
export type user_role = {
  id: Scalars['Int'];
  role: role_enum;
  /** An object relationship */
  user: user;
  user_id: Scalars['Int'];
};

/** aggregated selection of "user_role" */
export type user_role_aggregate = {
  aggregate?: Maybe<user_role_aggregate_fields>;
  nodes: Array<user_role>;
};

export type user_role_aggregate_bool_exp = {
  count?: InputMaybe<user_role_aggregate_bool_exp_count>;
};

export type user_role_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<user_role_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<user_role_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "user_role" */
export type user_role_aggregate_fields = {
  avg?: Maybe<user_role_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<user_role_max_fields>;
  min?: Maybe<user_role_min_fields>;
  stddev?: Maybe<user_role_stddev_fields>;
  stddev_pop?: Maybe<user_role_stddev_pop_fields>;
  stddev_samp?: Maybe<user_role_stddev_samp_fields>;
  sum?: Maybe<user_role_sum_fields>;
  var_pop?: Maybe<user_role_var_pop_fields>;
  var_samp?: Maybe<user_role_var_samp_fields>;
  variance?: Maybe<user_role_variance_fields>;
};

/** aggregate fields of "user_role" */
export type user_role_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<user_role_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_role" */
export type user_role_aggregate_order_by = {
  avg?: InputMaybe<user_role_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<user_role_max_order_by>;
  min?: InputMaybe<user_role_min_order_by>;
  stddev?: InputMaybe<user_role_stddev_order_by>;
  stddev_pop?: InputMaybe<user_role_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<user_role_stddev_samp_order_by>;
  sum?: InputMaybe<user_role_sum_order_by>;
  var_pop?: InputMaybe<user_role_var_pop_order_by>;
  var_samp?: InputMaybe<user_role_var_samp_order_by>;
  variance?: InputMaybe<user_role_variance_order_by>;
};

/** input type for inserting array relation for remote table "user_role" */
export type user_role_arr_rel_insert_input = {
  data: Array<user_role_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<user_role_on_conflict>;
};

/** aggregate avg on columns */
export type user_role_avg_fields = {
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_role" */
export type user_role_avg_order_by = {
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "user_role". All fields are combined with a logical 'AND'. */
export type user_role_bool_exp = {
  _and?: InputMaybe<Array<user_role_bool_exp>>;
  _not?: InputMaybe<user_role_bool_exp>;
  _or?: InputMaybe<Array<user_role_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  role?: InputMaybe<role_enum_comparison_exp>;
  user?: InputMaybe<user_bool_exp>;
  user_id?: InputMaybe<Int_comparison_exp>;
};

export { user_role_constraint };

/** input type for incrementing numeric columns in table "user_role" */
export type user_role_inc_input = {
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_role" */
export type user_role_insert_input = {
  id?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<role_enum>;
  user?: InputMaybe<user_obj_rel_insert_input>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type user_role_max_fields = {
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "user_role" */
export type user_role_max_order_by = {
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type user_role_min_fields = {
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "user_role" */
export type user_role_min_order_by = {
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "user_role" */
export type user_role_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<user_role>;
};

/** on_conflict condition type for table "user_role" */
export type user_role_on_conflict = {
  constraint: user_role_constraint;
  update_columns?: Array<user_role_update_column>;
  where?: InputMaybe<user_role_bool_exp>;
};

/** Ordering options when selecting data from "user_role". */
export type user_role_order_by = {
  id?: InputMaybe<order_by>;
  role?: InputMaybe<order_by>;
  user?: InputMaybe<user_order_by>;
  user_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: user_role */
export type user_role_pk_columns_input = {
  id: Scalars['Int'];
};

export { user_role_select_column };

/** input type for updating data in table "user_role" */
export type user_role_set_input = {
  id?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<role_enum>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type user_role_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_role" */
export type user_role_stddev_order_by = {
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type user_role_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_role" */
export type user_role_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type user_role_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_role" */
export type user_role_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "user_role" */
export type user_role_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: user_role_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type user_role_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<role_enum>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type user_role_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_role" */
export type user_role_sum_order_by = {
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

export { user_role_update_column };

export type user_role_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<user_role_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<user_role_set_input>;
  /** filter the rows which have to be updated */
  where: user_role_bool_exp;
};

/** aggregate var_pop on columns */
export type user_role_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_role" */
export type user_role_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type user_role_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_role" */
export type user_role_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type user_role_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_role" */
export type user_role_variance_order_by = {
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

export { user_select_column };

/** input type for updating data in table "user" */
export type user_set_input = {
  auth0_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  language?: InputMaybe<language_enum>;
  last_name?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type user_stddev_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user" */
export type user_stddev_order_by = {
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type user_stddev_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user" */
export type user_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type user_stddev_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user" */
export type user_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "user" */
export type user_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: user_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type user_stream_cursor_value_input = {
  auth0_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  language?: InputMaybe<language_enum>;
  last_name?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type user_sum_fields = {
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user" */
export type user_sum_order_by = {
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
};

export { user_update_column };

export type user_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<user_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<user_set_input>;
  /** filter the rows which have to be updated */
  where: user_bool_exp;
};

/** aggregate var_pop on columns */
export type user_var_pop_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user" */
export type user_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type user_var_samp_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user" */
export type user_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type user_variance_fields = {
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user" */
export type user_variance_order_by = {
  id?: InputMaybe<order_by>;
  org_id?: InputMaybe<order_by>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type uuid_comparison_exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "window_type" */
export type window_type = {
  id: Scalars['String'];
};

/** aggregated selection of "window_type" */
export type window_type_aggregate = {
  aggregate?: Maybe<window_type_aggregate_fields>;
  nodes: Array<window_type>;
};

/** aggregate fields of "window_type" */
export type window_type_aggregate_fields = {
  count: Scalars['Int'];
  max?: Maybe<window_type_max_fields>;
  min?: Maybe<window_type_min_fields>;
};

/** aggregate fields of "window_type" */
export type window_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<window_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "window_type". All fields are combined with a logical 'AND'. */
export type window_type_bool_exp = {
  _and?: InputMaybe<Array<window_type_bool_exp>>;
  _not?: InputMaybe<window_type_bool_exp>;
  _or?: InputMaybe<Array<window_type_bool_exp>>;
  id?: InputMaybe<String_comparison_exp>;
};

export { window_type_constraint };

/** input type for inserting data into table "window_type" */
export type window_type_insert_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type window_type_max_fields = {
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type window_type_min_fields = {
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "window_type" */
export type window_type_mutation_response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<window_type>;
};

/** on_conflict condition type for table "window_type" */
export type window_type_on_conflict = {
  constraint: window_type_constraint;
  update_columns?: Array<window_type_update_column>;
  where?: InputMaybe<window_type_bool_exp>;
};

/** Ordering options when selecting data from "window_type". */
export type window_type_order_by = {
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: window_type */
export type window_type_pk_columns_input = {
  id: Scalars['String'];
};

export { window_type_select_column };

/** input type for updating data in table "window_type" */
export type window_type_set_input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "window_type" */
export type window_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: window_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type window_type_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['String']>;
};

export { window_type_update_column };

export type window_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<window_type_set_input>;
  /** filter the rows which have to be updated */
  where: window_type_bool_exp;
};

export type ReadNotificationsMutationVariables = Exact<{
  notificationIds: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type ReadNotificationsMutation = {
  update_notification?: { returning: Array<{ __typename: 'notification'; id: number; read: boolean }> } | null;
};

export type ActionPlanningActionDeleteActionPlanMutationVariables = Exact<{
  actionPlanId: Scalars['Int'];
}>;

export type ActionPlanningActionDeleteActionPlanMutation = {
  delete_action_plan_by_pk?: { __typename: 'action_plan'; id: number } | null;
};

export type ExportScenarioAsExcelMutationVariables = Exact<{
  scenarioId: Scalars['Int'];
}>;

export type ExportScenarioAsExcelMutation = { exportScenarioAsExcel: { body: string } };

export type ActionPlanDeleteActionMutationVariables = Exact<{
  actionId: Scalars['Int'];
}>;

export type ActionPlanDeleteActionMutation = { delete_action_by_pk?: { __typename: 'action'; id: number } | null };

export type SimulateActionOutputResponseFragment = {
  energy_final: number;
  energy_primary: number;
  co2_emissions: number;
  stranding_date: DateTime;
  efficiency_class: efficiency_class_enum;
  renovation_costs?: number | null;
  energy_final_cost: number;
  co2_tax_cost: number;
};

export type SimulateTechologyActionMutationVariables = Exact<{
  action_plan_id: Scalars['Int'];
  renovation_ids: Array<Scalars['Int']> | Scalars['Int'];
  envelope_renovation_parameter?: InputMaybe<EnvelopeRenovationParameterInput>;
  system_renovation_parameter?: InputMaybe<SystemRenovationParameterInput>;
  energy_system_route_renovation_parameter?: InputMaybe<EnergySystemRouteRenovationParameterInput>;
  hydraulic_balancing_renovation_parameter?: InputMaybe<HydraulicBalancingRenovationParameterInput>;
  solar_renovation_parameter?: InputMaybe<SolarRenovationParameterInput>;
  implementation_to: Scalars['DateTime'];
}>;

export type SimulateTechologyActionMutation = {
  simulateAction: {
    energy_final: number;
    energy_primary: number;
    co2_emissions: number;
    stranding_date: DateTime;
    efficiency_class: efficiency_class_enum;
    renovation_costs?: number | null;
    energy_final_cost: number;
    co2_tax_cost: number;
  };
};

export type ImplementActionMutationVariables = Exact<{
  action_plan_id: Scalars['Int'];
  renovation_ids: Array<Scalars['Int']> | Scalars['Int'];
  implementation_from: Scalars['DateTime'];
  implementation_to: Scalars['DateTime'];
  envelope_renovation_parameter?: InputMaybe<EnvelopeRenovationParameterInput>;
  system_renovation_parameter?: InputMaybe<SystemRenovationParameterInput>;
  energy_system_route_renovation_parameter?: InputMaybe<EnergySystemRouteRenovationParameterInput>;
  hydraulic_balancing_renovation_parameter?: InputMaybe<HydraulicBalancingRenovationParameterInput>;
  solar_renovation_parameter?: InputMaybe<SolarRenovationParameterInput>;
  subsidies: Array<SubsidyInput> | SubsidyInput;
  customCost?: InputMaybe<CustomCostInput>;
}>;

export type ImplementActionMutation = { implementAction: { action_id: number } };

export type UpdateCustomCostMutationVariables = Exact<{
  action_id: Scalars['Int'];
  custom_cost?: InputMaybe<renovation_set_input>;
}>;

export type UpdateCustomCostMutation = { update_renovation?: { returning: Array<{ id: number }> } | null };

export type CreateActionPlanModalCreateActionPlanMutationVariables = Exact<{
  scenario_id: Scalars['Int'];
  building_id: Scalars['Int'];
}>;

export type CreateActionPlanModalCreateActionPlanMutation = {
  insert_action_plan?: { returning: Array<{ id: number }> } | null;
};

export type CreateScenarioModalCreateScenarioMutationVariables = Exact<{
  description: Scalars['String'];
  name: Scalars['String'];
  portfolio_id: Scalars['Int'];
}>;

export type CreateScenarioModalCreateScenarioMutation = {
  insert_scenario?: { returning: Array<{ id: number; name: string }> } | null;
};

export type ActionPlanningScenarioDeleteScenarioMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ActionPlanningScenarioDeleteScenarioMutation = {
  delete_scenario_by_pk?: { __typename: 'scenario'; id: number } | null;
};

export type EnvelopeUnitFragment = {
  id: number;
  area: number;
  envelope_type_id: envelope_type_enum;
  insulation_material_name?: string | null;
  u_value?: number | null;
  insulation_thickness?: number | null;
  orientation?: number | null;
  inclination?: number | null;
};

export type EnergyConsumerFragment = {
  energy_consumer_type_id: energy_consumer_type_enum;
  energy_source_type_id?: energy_source_type_enum | null;
  energy_consumer_technology_type_id?: energy_consumer_technology_type_enum | null;
};

export type EnergyPathsFragment = {
  year: number;
  co2_emissions: number;
  energy_consumption: number;
  energy_costs_total: number;
  co2_costs_total: number;
  efficiency_class_id?: efficiency_class_enum | null;
};

export type BuildingModelFragment = {
  energy_final?: number | null;
  energy_primary?: number | null;
  building?: { id: number; year_constructed: number } | null;
  energy_paths: Array<{
    year: number;
    co2_emissions: number;
    energy_consumption: number;
    energy_costs_total: number;
    co2_costs_total: number;
    efficiency_class_id?: efficiency_class_enum | null;
  }>;
  envelope_units: Array<{
    id: number;
    area: number;
    envelope_type_id: envelope_type_enum;
    insulation_material_name?: string | null;
    u_value?: number | null;
    insulation_thickness?: number | null;
    orientation?: number | null;
    inclination?: number | null;
  }>;
  energy_consumers: Array<{
    id: number;
    energy_consumer_type_id: energy_consumer_type_enum;
    energy_consumer_technology_type_id?: energy_consumer_technology_type_enum | null;
    efficiency?: number | null;
  }>;
  energy_distributions: Array<{
    id: number;
    heat_transfer_loss: number;
    last_hydraulic_balancing?: string | null;
    last_balancing_set_to_never: boolean;
    balancing_with_components: boolean;
  }>;
  energy_systems: Array<{
    id: number;
    energy_system_type_id: energy_system_type_enum;
    energy_system_consumer_routes: Array<{
      id: number;
      energy_final: number;
      energy_source_type_id: energy_source_type_enum;
      energy_consumer: {
        energy_consumer_type_id: energy_consumer_type_enum;
        energy_source_type_id?: energy_source_type_enum | null;
        energy_consumer_technology_type_id?: energy_consumer_technology_type_enum | null;
      };
      energy_distribution?: {
        id: number;
        last_hydraulic_balancing?: string | null;
        last_balancing_set_to_never: boolean;
        heat_transfer_loss: number;
        balancing_with_components: boolean;
      } | null;
    }>;
    energy_system_producer_routes: Array<{
      energy_source_type_id: energy_source_type_enum;
      energy_producer: { nominal_power?: number | null };
    }>;
  }>;
};

export type EnvelopeRenovationFragment = {
  old_entity_id?: number | null;
  new_envelope?: {
    id: number;
    area: number;
    envelope_type_id: envelope_type_enum;
    insulation_material_name?: string | null;
    u_value?: number | null;
    cost_per_m2?: number | null;
    insulation_lambda?: number | null;
    insulation_method_id?: insulation_method_enum | null;
    insulation_thickness?: number | null;
    insulation_material_category_id?: insulation_material_category_enum | null;
  } | null;
  old_envelope?: {
    id: number;
    building_model: {
      envelope_units: Array<{
        id: number;
        area: number;
        envelope_type_id: envelope_type_enum;
        insulation_material_name?: string | null;
        u_value?: number | null;
        insulation_thickness?: number | null;
        orientation?: number | null;
        inclination?: number | null;
      }>;
    };
  } | null;
};

export type ActionFragment = {
  id: number;
  created_at: string;
  implementation_to: string;
  order: number;
  renovations: Array<{
    renovation_type_id: renovation_type_enum;
    cost_total?: number | null;
    cost_total_predium?: number | null;
    cost_total_custom?: number | null;
    use_custom_cost: boolean;
  }>;
  renovations_energy_consumer: Array<{
    new_energy_consumer?: {
      name?: string | null;
      energy_consumer_technology_type_id?: energy_consumer_technology_type_enum | null;
      cost_per_m2?: number | null;
      efficiency?: number | null;
      energy_consumer_type_id: energy_consumer_type_enum;
      energy_system_consumer_routes: Array<{ energy_source_type_id: energy_source_type_enum }>;
    } | null;
  }>;
  renovations_envelope: Array<{
    old_entity_id?: number | null;
    new_envelope?: {
      id: number;
      area: number;
      envelope_type_id: envelope_type_enum;
      insulation_material_name?: string | null;
      u_value?: number | null;
      cost_per_m2?: number | null;
      insulation_lambda?: number | null;
      insulation_method_id?: insulation_method_enum | null;
      insulation_thickness?: number | null;
      insulation_material_category_id?: insulation_material_category_enum | null;
    } | null;
    old_envelope?: {
      id: number;
      building_model: {
        envelope_units: Array<{
          id: number;
          area: number;
          envelope_type_id: envelope_type_enum;
          insulation_material_name?: string | null;
          u_value?: number | null;
          insulation_thickness?: number | null;
          orientation?: number | null;
          inclination?: number | null;
        }>;
      };
    } | null;
  }>;
  renovations_energy_routes: Array<{
    new_energy_system_route?: { energy_source_type_id: energy_source_type_enum } | null;
  }>;
  renovations_energy_producer: Array<{
    new_energy_producer?: {
      name?: string | null;
      nominal_power?: number | null;
      module_peak_power?: number | null;
      module_angle?: number | null;
      solar_active_area?: number | null;
      roof_area_ratio?: number | null;
      cost_per_m2?: number | null;
    } | null;
  }>;
  building_models: Array<{
    energy_final?: number | null;
    energy_primary?: number | null;
    building?: { id: number; year_constructed: number } | null;
    energy_paths: Array<{
      year: number;
      co2_emissions: number;
      energy_consumption: number;
      energy_costs_total: number;
      co2_costs_total: number;
      efficiency_class_id?: efficiency_class_enum | null;
    }>;
    envelope_units: Array<{
      id: number;
      area: number;
      envelope_type_id: envelope_type_enum;
      insulation_material_name?: string | null;
      u_value?: number | null;
      insulation_thickness?: number | null;
      orientation?: number | null;
      inclination?: number | null;
    }>;
    energy_consumers: Array<{
      id: number;
      energy_consumer_type_id: energy_consumer_type_enum;
      energy_consumer_technology_type_id?: energy_consumer_technology_type_enum | null;
      efficiency?: number | null;
    }>;
    energy_distributions: Array<{
      id: number;
      heat_transfer_loss: number;
      last_hydraulic_balancing?: string | null;
      last_balancing_set_to_never: boolean;
      balancing_with_components: boolean;
    }>;
    energy_systems: Array<{
      id: number;
      energy_system_type_id: energy_system_type_enum;
      energy_system_consumer_routes: Array<{
        id: number;
        energy_final: number;
        energy_source_type_id: energy_source_type_enum;
        energy_consumer: {
          energy_consumer_type_id: energy_consumer_type_enum;
          energy_source_type_id?: energy_source_type_enum | null;
          energy_consumer_technology_type_id?: energy_consumer_technology_type_enum | null;
        };
        energy_distribution?: {
          id: number;
          last_hydraulic_balancing?: string | null;
          last_balancing_set_to_never: boolean;
          heat_transfer_loss: number;
          balancing_with_components: boolean;
        } | null;
      }>;
      energy_system_producer_routes: Array<{
        energy_source_type_id: energy_source_type_enum;
        energy_producer: { nominal_power?: number | null };
      }>;
    }>;
  }>;
};

export type ActionPlanningActionSubsidyFragment = {
  id: number;
  type: action_subsidy_value_type_enum;
  value: number;
  subsidy: { id: number; title: string; categories: Array<{ subsidy_category_type: subsidy_category_type_enum }> };
  action: {
    id: number;
    renovations: Array<{
      cost_total?: number | null;
      cost_total_predium?: number | null;
      renovation_type_id: renovation_type_enum;
    }>;
  };
};

export type ModificationImpactsFragment = {
  co2_emissions: { after?: number | null; before?: number | null; impact?: number | null };
  final_energy: { after?: number | null; before?: number | null; impact?: number | null };
  primary_energy: { after?: number | null; before?: number | null; impact?: number | null };
  efficiency_class: { before?: efficiency_class_enum | null; after?: efficiency_class_enum | null };
  stranding_date: { before: DateTime; after?: DateTime | null; impact?: number | null };
  energy_cost_total: { after?: number | null; before?: number | null; impact?: number | null };
  co2_cost_total: { after?: number | null; before?: number | null; impact?: number | null };
};

export type ActionImpactDataOutputFragment = {
  action_id: number;
  total_renovation_cost: number;
  total_subsidy_cost: number;
  total_estimated_cost: number;
  modification_impact: {
    co2_emissions: { after?: number | null; before?: number | null; impact?: number | null };
    final_energy: { after?: number | null; before?: number | null; impact?: number | null };
    primary_energy: { after?: number | null; before?: number | null; impact?: number | null };
    efficiency_class: { before?: efficiency_class_enum | null; after?: efficiency_class_enum | null };
    stranding_date: { before: DateTime; after?: DateTime | null; impact?: number | null };
    energy_cost_total: { after?: number | null; before?: number | null; impact?: number | null };
    co2_cost_total: { after?: number | null; before?: number | null; impact?: number | null };
  };
};

export type ActionPlanImpactDataOutputFragment = {
  total_renovation_cost: number;
  total_subsidy_cost: number;
  total_estimated_cost: number;
  modification_impact: {
    co2_emissions: { after?: number | null; before?: number | null; impact?: number | null };
    final_energy: { after?: number | null; before?: number | null; impact?: number | null };
    primary_energy: { after?: number | null; before?: number | null; impact?: number | null };
    efficiency_class: { before?: efficiency_class_enum | null; after?: efficiency_class_enum | null };
    stranding_date: { before: DateTime; after?: DateTime | null; impact?: number | null };
    energy_cost_total: { after?: number | null; before?: number | null; impact?: number | null };
    co2_cost_total: { after?: number | null; before?: number | null; impact?: number | null };
  };
};

export type ActionPlanMetricsFragment = {
  action_plan: {
    total_renovation_cost: number;
    total_subsidy_cost: number;
    total_estimated_cost: number;
    modification_impact: {
      co2_emissions: { after?: number | null; before?: number | null; impact?: number | null };
      final_energy: { after?: number | null; before?: number | null; impact?: number | null };
      primary_energy: { after?: number | null; before?: number | null; impact?: number | null };
      efficiency_class: { before?: efficiency_class_enum | null; after?: efficiency_class_enum | null };
      stranding_date: { before: DateTime; after?: DateTime | null; impact?: number | null };
      energy_cost_total: { after?: number | null; before?: number | null; impact?: number | null };
      co2_cost_total: { after?: number | null; before?: number | null; impact?: number | null };
    };
  };
  actions: Array<{
    action_id: number;
    total_renovation_cost: number;
    total_subsidy_cost: number;
    total_estimated_cost: number;
    modification_impact: {
      co2_emissions: { after?: number | null; before?: number | null; impact?: number | null };
      final_energy: { after?: number | null; before?: number | null; impact?: number | null };
      primary_energy: { after?: number | null; before?: number | null; impact?: number | null };
      efficiency_class: { before?: efficiency_class_enum | null; after?: efficiency_class_enum | null };
      stranding_date: { before: DateTime; after?: DateTime | null; impact?: number | null };
      energy_cost_total: { after?: number | null; before?: number | null; impact?: number | null };
      co2_cost_total: { after?: number | null; before?: number | null; impact?: number | null };
    };
  }>;
};

export type TargetPathsFragment = {
  value: number;
  year: number;
  target_path_template: { name: string; target_path_template_type_id: target_path_template_type_enum };
};

export type ActionPlanningActionPlanActionPlanFragment = {
  id: number;
  created_at: string;
  scenario: {
    name: string;
    id: number;
    created_at?: string | null;
    portfolio: { name?: string | null };
    owner: { first_name?: string | null; last_name?: string | null; id: number };
  };
  building: {
    id: number;
    monument_protection: boolean;
    year_constructed: number;
    address: { street: string; city: string; country_id: country_enum; postal_code: string };
    areas: Array<{
      value: number;
      type_of_use_id: type_of_use_enum;
      area_type_id: area_type_enum;
      class_of_use_id: sub_building_class_enum;
    }>;
    active_building_model?: {
      energy_final?: number | null;
      energy_primary?: number | null;
      building?: { id: number; year_constructed: number } | null;
      energy_paths: Array<{
        year: number;
        co2_emissions: number;
        energy_consumption: number;
        energy_costs_total: number;
        co2_costs_total: number;
        efficiency_class_id?: efficiency_class_enum | null;
      }>;
      envelope_units: Array<{
        id: number;
        area: number;
        envelope_type_id: envelope_type_enum;
        insulation_material_name?: string | null;
        u_value?: number | null;
        insulation_thickness?: number | null;
        orientation?: number | null;
        inclination?: number | null;
      }>;
      energy_consumers: Array<{
        id: number;
        energy_consumer_type_id: energy_consumer_type_enum;
        energy_consumer_technology_type_id?: energy_consumer_technology_type_enum | null;
        efficiency?: number | null;
      }>;
      energy_distributions: Array<{
        id: number;
        heat_transfer_loss: number;
        last_hydraulic_balancing?: string | null;
        last_balancing_set_to_never: boolean;
        balancing_with_components: boolean;
      }>;
      energy_systems: Array<{
        id: number;
        energy_system_type_id: energy_system_type_enum;
        energy_system_consumer_routes: Array<{
          id: number;
          energy_final: number;
          energy_source_type_id: energy_source_type_enum;
          energy_consumer: {
            energy_consumer_type_id: energy_consumer_type_enum;
            energy_source_type_id?: energy_source_type_enum | null;
            energy_consumer_technology_type_id?: energy_consumer_technology_type_enum | null;
          };
          energy_distribution?: {
            id: number;
            last_hydraulic_balancing?: string | null;
            last_balancing_set_to_never: boolean;
            heat_transfer_loss: number;
            balancing_with_components: boolean;
          } | null;
        }>;
        energy_system_producer_routes: Array<{
          energy_source_type_id: energy_source_type_enum;
          energy_producer: { nominal_power?: number | null };
        }>;
      }>;
    } | null;
    sub_buildings: Array<{
      __typename: 'sub_building';
      subsidies: Array<{
        subsidy: {
          __typename: 'subsidy';
          id: number;
          email?: string | null;
          amount_description: string;
          provider_updated_at: string;
          region_type: subsidy_region_type_enum;
          title: string;
          type: subsidy_type_enum;
          url?: string | null;
          categories: Array<{ subsidy_category_type: subsidy_category_type_enum }>;
        };
      }>;
    }>;
    target_paths: Array<{
      value: number;
      year: number;
      target_path_template: { name: string; target_path_template_type_id: target_path_template_type_enum };
    }>;
  };
};

export type ActionPlanningScenarioActionPlanFragment = {
  id: number;
  created_at: string;
  building: {
    address: { street: string; city: string; postal_code: string };
    active_building_model?: {
      energy_paths: Array<{
        year: number;
        co2_emissions: number;
        energy_consumption: number;
        energy_costs_total: number;
        co2_costs_total: number;
        efficiency_class_id?: efficiency_class_enum | null;
      }>;
    } | null;
  };
  actions_aggregate: { aggregate?: { count: number; max?: { updated_at?: string | null } | null } | null };
  actions: Array<{
    building_models: Array<{ energy_paths: Array<{ efficiency_class_id?: efficiency_class_enum | null }> }>;
  }>;
};

export type ScenarioActionPlansFragment = {
  id: number;
  actions_aggregate: { aggregate?: { count: number; max?: { updated_at?: string | null } | null } | null };
};

export type ActionPlanningScenariosScenarioFragment = {
  description: string;
  name: string;
  id: number;
  created_at?: string | null;
  portfolio: { id: number; name?: string | null };
  owner: { id: number; first_name?: string | null; last_name?: string | null };
  action_plans: Array<{
    id: number;
    actions_aggregate: { aggregate?: { count: number; max?: { updated_at?: string | null } | null } | null };
  }>;
};

export type ActionPlanningScenarioScenarioFragment = {
  description: string;
  name: string;
  id: number;
  created_at?: string | null;
  action_plans: Array<{
    id: number;
    created_at: string;
    building: {
      address: { street: string; city: string; postal_code: string };
      active_building_model?: {
        energy_paths: Array<{
          year: number;
          co2_emissions: number;
          energy_consumption: number;
          energy_costs_total: number;
          co2_costs_total: number;
          efficiency_class_id?: efficiency_class_enum | null;
        }>;
      } | null;
    };
    actions_aggregate: { aggregate?: { count: number; max?: { updated_at?: string | null } | null } | null };
    actions: Array<{
      building_models: Array<{ energy_paths: Array<{ efficiency_class_id?: efficiency_class_enum | null }> }>;
    }>;
  }>;
  portfolio: { id: number; name?: string | null };
  owner: { id: number; first_name?: string | null; last_name?: string | null };
};

export type CreateActionPlanModalBuildingFragment = {
  id: number;
  address: { street: string; city: string; postal_code: string };
  active_building_model?: {
    action_planning_available: boolean;
    energy_paths: Array<{
      year: number;
      co2_emissions: number;
      energy_consumption: number;
      energy_costs_total: number;
      co2_costs_total: number;
      efficiency_class_id?: efficiency_class_enum | null;
    }>;
  } | null;
};

export type ActionPlanningActionPlanGetActionPlanQueryVariables = Exact<{
  actionPlanId: Scalars['Int'];
}>;

export type ActionPlanningActionPlanGetActionPlanQuery = {
  action_plan_by_pk?: {
    id: number;
    created_at: string;
    scenario: {
      name: string;
      id: number;
      created_at?: string | null;
      portfolio: { name?: string | null };
      owner: { first_name?: string | null; last_name?: string | null; id: number };
    };
    building: {
      id: number;
      monument_protection: boolean;
      year_constructed: number;
      address: { street: string; city: string; country_id: country_enum; postal_code: string };
      areas: Array<{
        value: number;
        type_of_use_id: type_of_use_enum;
        area_type_id: area_type_enum;
        class_of_use_id: sub_building_class_enum;
      }>;
      active_building_model?: {
        energy_final?: number | null;
        energy_primary?: number | null;
        building?: { id: number; year_constructed: number } | null;
        energy_paths: Array<{
          year: number;
          co2_emissions: number;
          energy_consumption: number;
          energy_costs_total: number;
          co2_costs_total: number;
          efficiency_class_id?: efficiency_class_enum | null;
        }>;
        envelope_units: Array<{
          id: number;
          area: number;
          envelope_type_id: envelope_type_enum;
          insulation_material_name?: string | null;
          u_value?: number | null;
          insulation_thickness?: number | null;
          orientation?: number | null;
          inclination?: number | null;
        }>;
        energy_consumers: Array<{
          id: number;
          energy_consumer_type_id: energy_consumer_type_enum;
          energy_consumer_technology_type_id?: energy_consumer_technology_type_enum | null;
          efficiency?: number | null;
        }>;
        energy_distributions: Array<{
          id: number;
          heat_transfer_loss: number;
          last_hydraulic_balancing?: string | null;
          last_balancing_set_to_never: boolean;
          balancing_with_components: boolean;
        }>;
        energy_systems: Array<{
          id: number;
          energy_system_type_id: energy_system_type_enum;
          energy_system_consumer_routes: Array<{
            id: number;
            energy_final: number;
            energy_source_type_id: energy_source_type_enum;
            energy_consumer: {
              energy_consumer_type_id: energy_consumer_type_enum;
              energy_source_type_id?: energy_source_type_enum | null;
              energy_consumer_technology_type_id?: energy_consumer_technology_type_enum | null;
            };
            energy_distribution?: {
              id: number;
              last_hydraulic_balancing?: string | null;
              last_balancing_set_to_never: boolean;
              heat_transfer_loss: number;
              balancing_with_components: boolean;
            } | null;
          }>;
          energy_system_producer_routes: Array<{
            energy_source_type_id: energy_source_type_enum;
            energy_producer: { nominal_power?: number | null };
          }>;
        }>;
      } | null;
      sub_buildings: Array<{
        __typename: 'sub_building';
        subsidies: Array<{
          subsidy: {
            __typename: 'subsidy';
            id: number;
            email?: string | null;
            amount_description: string;
            provider_updated_at: string;
            region_type: subsidy_region_type_enum;
            title: string;
            type: subsidy_type_enum;
            url?: string | null;
            categories: Array<{ subsidy_category_type: subsidy_category_type_enum }>;
          };
        }>;
      }>;
      target_paths: Array<{
        value: number;
        year: number;
        target_path_template: { name: string; target_path_template_type_id: target_path_template_type_enum };
      }>;
    };
  } | null;
};

export type ActionPlanningGetSubsidiesQueryVariables = Exact<{
  actionPlanId: Scalars['Int'];
}>;

export type ActionPlanningGetSubsidiesQuery = {
  action_subsidy: Array<{
    id: number;
    type: action_subsidy_value_type_enum;
    value: number;
    subsidy: { id: number; title: string; categories: Array<{ subsidy_category_type: subsidy_category_type_enum }> };
    action: {
      id: number;
      renovations: Array<{
        cost_total?: number | null;
        cost_total_predium?: number | null;
        renovation_type_id: renovation_type_enum;
      }>;
    };
  }>;
};

export type ActionPlanningScenarioGetScenarioQueryVariables = Exact<{
  scenarioId: Scalars['Int'];
  year: Scalars['Int'];
}>;

export type ActionPlanningScenarioGetScenarioQuery = {
  scenario_by_pk?: {
    description: string;
    name: string;
    id: number;
    created_at?: string | null;
    action_plans: Array<{
      id: number;
      created_at: string;
      building: {
        address: { street: string; city: string; postal_code: string };
        active_building_model?: {
          energy_paths: Array<{
            year: number;
            co2_emissions: number;
            energy_consumption: number;
            energy_costs_total: number;
            co2_costs_total: number;
            efficiency_class_id?: efficiency_class_enum | null;
          }>;
        } | null;
      };
      actions_aggregate: { aggregate?: { count: number; max?: { updated_at?: string | null } | null } | null };
      actions: Array<{
        building_models: Array<{ energy_paths: Array<{ efficiency_class_id?: efficiency_class_enum | null }> }>;
      }>;
    }>;
    portfolio: { id: number; name?: string | null };
    owner: { id: number; first_name?: string | null; last_name?: string | null };
  } | null;
};

export type ActionPlanningScenariosAllScenariosQueryVariables = Exact<{ [key: string]: never }>;

export type ActionPlanningScenariosAllScenariosQuery = {
  scenario: Array<{
    description: string;
    name: string;
    id: number;
    created_at?: string | null;
    portfolio: { id: number; name?: string | null };
    owner: { id: number; first_name?: string | null; last_name?: string | null };
    action_plans: Array<{
      id: number;
      actions_aggregate: { aggregate?: { count: number; max?: { updated_at?: string | null } | null } | null };
    }>;
  }>;
};

export type CreateActionPlanModalGetBuildingsQueryVariables = Exact<{
  where: building_bool_exp;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<Array<building_order_by> | building_order_by>;
  year: Scalars['Int'];
}>;

export type CreateActionPlanModalGetBuildingsQuery = {
  building: Array<{
    __typename: 'building';
    id: number;
    address: { __typename: 'address'; id: number; street: string; city: string; postal_code: string };
    active_building_model?: {
      id: number;
      action_planning_available: boolean;
      energy_paths: Array<{
        year: number;
        co2_emissions: number;
        energy_consumption: number;
        energy_costs_total: number;
        co2_costs_total: number;
        efficiency_class_id?: efficiency_class_enum | null;
      }>;
    } | null;
  }>;
  building_aggregate: { aggregate?: { count: number } | null };
};

export type ExportActionPlanPdfQueryVariables = Exact<{
  actionPlanId: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
}>;

export type ExportActionPlanPdfQuery = { getActionPlanPdf: { body: string } };

export type GetActionsPlanMetricsQueryVariables = Exact<{
  actionPlanId: Scalars['Int'];
}>;

export type GetActionsPlanMetricsQuery = {
  getActionPlanMetrics: {
    action_plan: {
      total_renovation_cost: number;
      total_subsidy_cost: number;
      total_estimated_cost: number;
      modification_impact: {
        co2_emissions: { after?: number | null; before?: number | null; impact?: number | null };
        final_energy: { after?: number | null; before?: number | null; impact?: number | null };
        primary_energy: { after?: number | null; before?: number | null; impact?: number | null };
        efficiency_class: { before?: efficiency_class_enum | null; after?: efficiency_class_enum | null };
        stranding_date: { before: DateTime; after?: DateTime | null; impact?: number | null };
        energy_cost_total: { after?: number | null; before?: number | null; impact?: number | null };
        co2_cost_total: { after?: number | null; before?: number | null; impact?: number | null };
      };
    };
    actions: Array<{
      action_id: number;
      total_renovation_cost: number;
      total_subsidy_cost: number;
      total_estimated_cost: number;
      modification_impact: {
        co2_emissions: { after?: number | null; before?: number | null; impact?: number | null };
        final_energy: { after?: number | null; before?: number | null; impact?: number | null };
        primary_energy: { after?: number | null; before?: number | null; impact?: number | null };
        efficiency_class: { before?: efficiency_class_enum | null; after?: efficiency_class_enum | null };
        stranding_date: { before: DateTime; after?: DateTime | null; impact?: number | null };
        energy_cost_total: { after?: number | null; before?: number | null; impact?: number | null };
        co2_cost_total: { after?: number | null; before?: number | null; impact?: number | null };
      };
    }>;
  };
};

export type GetActionsForActionPlanQueryVariables = Exact<{
  actionPlanId: Scalars['Int'];
}>;

export type GetActionsForActionPlanQuery = {
  action: Array<{
    id: number;
    created_at: string;
    implementation_to: string;
    order: number;
    renovations: Array<{
      renovation_type_id: renovation_type_enum;
      cost_total?: number | null;
      cost_total_predium?: number | null;
      cost_total_custom?: number | null;
      use_custom_cost: boolean;
    }>;
    renovations_energy_consumer: Array<{
      new_energy_consumer?: {
        name?: string | null;
        energy_consumer_technology_type_id?: energy_consumer_technology_type_enum | null;
        cost_per_m2?: number | null;
        efficiency?: number | null;
        energy_consumer_type_id: energy_consumer_type_enum;
        energy_system_consumer_routes: Array<{ energy_source_type_id: energy_source_type_enum }>;
      } | null;
    }>;
    renovations_envelope: Array<{
      old_entity_id?: number | null;
      new_envelope?: {
        id: number;
        area: number;
        envelope_type_id: envelope_type_enum;
        insulation_material_name?: string | null;
        u_value?: number | null;
        cost_per_m2?: number | null;
        insulation_lambda?: number | null;
        insulation_method_id?: insulation_method_enum | null;
        insulation_thickness?: number | null;
        insulation_material_category_id?: insulation_material_category_enum | null;
      } | null;
      old_envelope?: {
        id: number;
        building_model: {
          envelope_units: Array<{
            id: number;
            area: number;
            envelope_type_id: envelope_type_enum;
            insulation_material_name?: string | null;
            u_value?: number | null;
            insulation_thickness?: number | null;
            orientation?: number | null;
            inclination?: number | null;
          }>;
        };
      } | null;
    }>;
    renovations_energy_routes: Array<{
      new_energy_system_route?: { energy_source_type_id: energy_source_type_enum } | null;
    }>;
    renovations_energy_producer: Array<{
      new_energy_producer?: {
        name?: string | null;
        nominal_power?: number | null;
        module_peak_power?: number | null;
        module_angle?: number | null;
        solar_active_area?: number | null;
        roof_area_ratio?: number | null;
        cost_per_m2?: number | null;
      } | null;
    }>;
    building_models: Array<{
      energy_final?: number | null;
      energy_primary?: number | null;
      building?: { id: number; year_constructed: number } | null;
      energy_paths: Array<{
        year: number;
        co2_emissions: number;
        energy_consumption: number;
        energy_costs_total: number;
        co2_costs_total: number;
        efficiency_class_id?: efficiency_class_enum | null;
      }>;
      envelope_units: Array<{
        id: number;
        area: number;
        envelope_type_id: envelope_type_enum;
        insulation_material_name?: string | null;
        u_value?: number | null;
        insulation_thickness?: number | null;
        orientation?: number | null;
        inclination?: number | null;
      }>;
      energy_consumers: Array<{
        id: number;
        energy_consumer_type_id: energy_consumer_type_enum;
        energy_consumer_technology_type_id?: energy_consumer_technology_type_enum | null;
        efficiency?: number | null;
      }>;
      energy_distributions: Array<{
        id: number;
        heat_transfer_loss: number;
        last_hydraulic_balancing?: string | null;
        last_balancing_set_to_never: boolean;
        balancing_with_components: boolean;
      }>;
      energy_systems: Array<{
        id: number;
        energy_system_type_id: energy_system_type_enum;
        energy_system_consumer_routes: Array<{
          id: number;
          energy_final: number;
          energy_source_type_id: energy_source_type_enum;
          energy_consumer: {
            energy_consumer_type_id: energy_consumer_type_enum;
            energy_source_type_id?: energy_source_type_enum | null;
            energy_consumer_technology_type_id?: energy_consumer_technology_type_enum | null;
          };
          energy_distribution?: {
            id: number;
            last_hydraulic_balancing?: string | null;
            last_balancing_set_to_never: boolean;
            heat_transfer_loss: number;
            balancing_with_components: boolean;
          } | null;
        }>;
        energy_system_producer_routes: Array<{
          energy_source_type_id: energy_source_type_enum;
          energy_producer: { nominal_power?: number | null };
        }>;
      }>;
    }>;
  }>;
};

export type DataCollectionAddEconomicUnitMutationVariables = Exact<{
  portfolioId: Scalars['Int'];
  name: Scalars['String'];
  customerEconomicUnitId: Scalars['String'];
}>;

export type DataCollectionAddEconomicUnitMutation = {
  insert_economic_unit_one?: { __typename: 'economic_unit'; id: number } | null;
};

export type DataCollectionEditEconomicUnitMutationVariables = Exact<{
  economicUnitId: Scalars['Int'];
  economicUnitName: Scalars['String'];
  customerEconomicUnitId?: InputMaybe<Scalars['String']>;
  portfolioId: Scalars['Int'];
}>;

export type DataCollectionEditEconomicUnitMutation = {
  update_economic_unit_by_pk?: { id: number; name: string; portfolio_id: number } | null;
};

export type DataCollectionDeleteEconomicUnitMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DataCollectionDeleteEconomicUnitMutation = {
  delete_economic_unit_by_pk?: { __typename: 'economic_unit'; id: number } | null;
};

export type DataCollectionDeleteEconomicUnitsMutationVariables = Exact<{
  economicUnitIds: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type DataCollectionDeleteEconomicUnitsMutation = {
  delete_economic_unit?: {
    affected_rows: number;
    returning: Array<{ __typename: 'economic_unit'; id: number }>;
  } | null;
};

export type DataCollectionDeleteBuildingMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DataCollectionDeleteBuildingMutation = {
  delete_building_by_pk?: {
    __typename: 'building';
    id: number;
    economic_unit: { id: number; associatedBuildingsAndDrafts: { amount: number } };
  } | null;
};

export type DataCollectionDeleteBuildingsMutationVariables = Exact<{
  buildingIds: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type DataCollectionDeleteBuildingsMutation = {
  delete_building?: {
    affected_rows: number;
    returning: Array<{
      __typename: 'building';
      id: number;
      economic_unit: { id: number; associatedBuildingsAndDrafts: { amount: number } };
    }>;
  } | null;
};

export type DataCollectionMoveBuildingMutationVariables = Exact<{
  id: Scalars['Int'];
  economicUnitId: Scalars['Int'];
}>;

export type DataCollectionMoveBuildingMutation = { update_building_by_pk?: { id: number } | null };

export type DataCollectionMoveBuildingsMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
  economicUnitId: Scalars['Int'];
}>;

export type DataCollectionMoveBuildingsMutation = { update_building?: { affected_rows: number } | null };

export type SubBuildingExcelExportMutationVariables = Exact<{
  buildingIds: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type SubBuildingExcelExportMutation = { exportSubBuildingsAsExcel: { body: string } };

export type DataCollectionEnergyCertificateDraftsListDeleteEnergyCertificateDraftsMutationVariables = Exact<{
  energyCertificateDraftIds: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type DataCollectionEnergyCertificateDraftsListDeleteEnergyCertificateDraftsMutation = {
  delete_energy_certificate_draft?: {
    affected_rows: number;
    returning: Array<{ __typename: 'energy_certificate_draft'; id: number }>;
  } | null;
};

export type RemoveEmptiesEuAfterMoveBuildingsMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type RemoveEmptiesEuAfterMoveBuildingsMutation = { removeEmptyEconomicUnits: { ids: Array<number> } };

export type DataCollectionEnergyCertificateDraftCreateBuildingFromEnergyCertificateDraftMutationVariables = Exact<{
  energyCertificateDraftId: Scalars['Int'];
}>;

export type DataCollectionEnergyCertificateDraftCreateBuildingFromEnergyCertificateDraftMutation = {
  createBuildingFromEnergyCertificateDraft: { building_id: number };
};

export type CreatedEnergyCertificateDraftFragment = { id: number; energy_certificate_file_id: number };

export type UploadEnergyCertificateDraftModalCreateEnergyCertificateDraftMutationVariables = Exact<{
  portfolioId: Scalars['Int'];
  economicUnitId?: InputMaybe<Scalars['Int']>;
}>;

export type UploadEnergyCertificateDraftModalCreateEnergyCertificateDraftMutation = {
  createEnergyCertificateDraft: {
    upload_url: string;
    CreateEnergyCertificateDraftOutput?: { id: number; energy_certificate_file_id: number } | null;
  };
};

export type DataCollectionEnergyCertificateDraftSaveEnergyCertificateDraftMutationVariables = Exact<{
  energy_certificate_draft_id: Scalars['Int'];
  street?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  postal_code?: InputMaybe<Scalars['String']>;
  country_state_id?: InputMaybe<country_state_enum>;
  country_id?: InputMaybe<country_enum>;
  building_state_id?: InputMaybe<building_state_enum>;
  portfolio_id: Scalars['Int'];
  monument_protection?: InputMaybe<Scalars['Boolean']>;
  leasehold?: InputMaybe<Scalars['Boolean']>;
  heritage_district?: InputMaybe<Scalars['Boolean']>;
  milieu_protection?: InputMaybe<Scalars['Boolean']>;
  economic_unit_id?: InputMaybe<Scalars['Int']>;
  responsible_user_id?: InputMaybe<Scalars['Int']>;
  year_constructed?: InputMaybe<Scalars['Int']>;
  area_usable?: InputMaybe<Scalars['numeric']>;
  sub_building_class_id?: InputMaybe<sub_building_class_enum>;
  type_of_use_id?: InputMaybe<type_of_use_enum>;
  energy_certificate_type_id?: InputMaybe<energy_certificate_type_enum>;
  draft_status_id?: InputMaybe<draft_status_enum>;
  expiry_date?: InputMaybe<Scalars['timestamptz']>;
  units?: InputMaybe<Scalars['Int']>;
  energy_certificate_consumers:
    | Array<energy_certificate_consumer_insert_input>
    | energy_certificate_consumer_insert_input;
}>;

export type DataCollectionEnergyCertificateDraftSaveEnergyCertificateDraftMutation = {
  update_energy_certificate_draft_by_pk?: { id: number } | null;
  delete_energy_certificate_consumer?: { affected_rows: number } | null;
  insert_energy_certificate_consumer?: { affected_rows: number } | null;
};

export type DataCollectionEnergyCertificateDraftDeleteEnergyCertificateDraftMutationVariables = Exact<{
  energyCertificateDraftId: Scalars['Int'];
}>;

export type DataCollectionEnergyCertificateDraftDeleteEnergyCertificateDraftMutation = {
  delete_energy_certificate_draft_by_pk?: { __typename: 'energy_certificate_draft'; id: number } | null;
};

export type DataCollectionEnergyCertificateDraftSetOcrFailedMutationVariables = Exact<{
  energyCertificateDraftId: Scalars['Int'];
}>;

export type DataCollectionEnergyCertificateDraftSetOcrFailedMutation = {
  update_energy_certificate_draft?: { affected_rows: number } | null;
};

export type DataCollectionsTabsEnergyCertificateDraftCountSubscriptionSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type DataCollectionsTabsEnergyCertificateDraftCountSubscriptionSubscription = {
  energy_certificate_draft_aggregate: { aggregate?: { count: number } | null };
};

export type DataCollectionSaveConsumptionInvoiceDraftMutationVariables = Exact<{
  buildingId: Scalars['Int'];
  consumptionInvoiceDraftId: Scalars['Int'];
  invoicePayer?: InputMaybe<invoice_payer_type_enum>;
  invoiceDate?: InputMaybe<Scalars['DateTime']>;
  draftStatus: draft_status_enum;
  consumptionDrafts: Array<ConsumptionDraftInput> | ConsumptionDraftInput;
}>;

export type DataCollectionSaveConsumptionInvoiceDraftMutation = {
  updateConsumptionInvoiceDraft: {
    UpdateConsumptionInvoiceDraftOutput?: {
      id: number;
      date?: string | null;
      invoice_payer?: invoice_payer_type_enum | null;
      draft_status: draft_status_enum;
      building: { id: number };
      consumption_drafts: Array<{
        id: number;
        area_id?: number | null;
        from?: string | null;
        to?: string | null;
        provider?: string | null;
        value?: number | null;
        display_unit_value?: consumption_unit_enum | null;
        cost?: number | null;
        consumption_type_id?: consumption_type_enum | null;
        energy_source_type_id?: energy_source_type_enum | null;
        sub_type_id?: consumption_sub_type_enum | null;
        consumption_waste_details_draft?: {
          id: number;
          volume?: number | null;
          display_unit_volume?: consumption_unit_enum | null;
          frequency?: number | null;
          display_unit_frequency?: consumption_unit_enum | null;
          amount?: number | null;
          price?: number | null;
        } | null;
      }>;
    } | null;
  };
};

export type DataCollectionSubBuildingConsumptionDraftCreateConsumptionFromDraftMutationVariables = Exact<{
  consumptionInvoiceDraftId: Scalars['Int'];
}>;

export type DataCollectionSubBuildingConsumptionDraftCreateConsumptionFromDraftMutation = {
  createConsumptionInvoiceFromDraft: { consumption_invoice_id: number };
};

export type DataCollectionConsumptionDraftStatusSubscriptionSubscriptionVariables = Exact<{
  consumptionInvoiceDraftId: Scalars['Int'];
}>;

export type DataCollectionConsumptionDraftStatusSubscriptionSubscription = {
  consumption_invoice_draft_by_pk?: { id: number; draft_status: draft_status_enum; updated_at: string } | null;
};

export type DataCollectionSubBuildingConsumptionInvoiceDraftSetOcrFailedMutationVariables = Exact<{
  consumptionInvoiceDraftId: Scalars['Int'];
}>;

export type DataCollectionSubBuildingConsumptionInvoiceDraftSetOcrFailedMutation = {
  update_consumption_invoice_draft_by_pk?: { id: number } | null;
};

export type SubBuildingConsumptionTabsConsumptionDraftCountSubscriptionSubscriptionVariables = Exact<{
  subBuildingId: Scalars['Int'];
}>;

export type SubBuildingConsumptionTabsConsumptionDraftCountSubscriptionSubscription = {
  consumption_draft_aggregate: { aggregate?: { count: number } | null };
};

export type UploadConsumptionDraftModalCreateConsumptionDraftMutationVariables = Exact<{
  buildingId: Scalars['Int'];
}>;

export type UploadConsumptionDraftModalCreateConsumptionDraftMutation = {
  createConsumptionInvoiceDraft: {
    upload_url: string;
    CreateConsumptionInvoiceDraftOutput?: { id: number; file_id?: number | null; created_at: string } | null;
  };
};

export type DeleteEnergyCertificateFileMutationVariables = Exact<{
  fileId: Scalars['Int'];
}>;

export type DeleteEnergyCertificateFileMutation = { delete_file_by_pk?: { __typename: 'file'; id: number } | null };

export type CreateEnergyCertificateFileMutationVariables = Exact<{
  energyCertificateId: Scalars['Int'];
}>;

export type CreateEnergyCertificateFileMutation = {
  createEnergyCertificateFile: { file_id: number; upload_url: string };
};

export type ApplyBuildingModelMutationsMutationVariables = Exact<{
  buildingModelId: Scalars['Int'];
  envelopeMutations: Array<EnvelopeMutation> | EnvelopeMutation;
  energySystemMutations: Array<EnergySystemMutation> | EnergySystemMutation;
  energySystemConsumerMutations?: InputMaybe<Array<EnergyConsumerMutation> | EnergyConsumerMutation>;
  energySystemStorageMutations?: InputMaybe<Array<EnergyStorageMutation> | EnergyStorageMutation>;
  energySystemDistributionMutations?: InputMaybe<Array<EnergyDistributionMutation> | EnergyDistributionMutation>;
  saveInvalidState: Scalars['Boolean'];
}>;

export type ApplyBuildingModelMutationsMutation = {
  applyBuildingModelMutations: { scaling_successful: boolean; final_energy: number; final_energy_scaled: number };
};

export type DataCollectionSaveConsumptionInvoiceMutationVariables = Exact<{
  buildingId: Scalars['Int'];
  consumptionInvoiceId: Scalars['Int'];
  invoicePayer: invoice_payer_type_enum;
  invoiceDate: Scalars['DateTime'];
  consumptions: Array<ConsumptionInput> | ConsumptionInput;
}>;

export type DataCollectionSaveConsumptionInvoiceMutation = {
  updateConsumptionInvoice: {
    UpdateConsumptionInvoiceOutput?: {
      id: number;
      date: string;
      invoice_payer: invoice_payer_type_enum;
      building: { id: number };
      consumptions: Array<{
        id: number;
        area_id: number;
        from: string;
        to: string;
        provider?: string | null;
        value?: number | null;
        display_unit_value?: consumption_unit_enum | null;
        cost?: number | null;
        consumption_type_id: consumption_type_enum;
        energy_source_type_id?: energy_source_type_enum | null;
        sub_type_id?: consumption_sub_type_enum | null;
        consumption_waste_detail?: {
          id: number;
          volume: number;
          display_unit_volume?: consumption_unit_enum | null;
          frequency: number;
          display_unit_frequency?: consumption_unit_enum | null;
          amount: number;
          price: number;
        } | null;
      }>;
    } | null;
  };
};

export type DataCollectionInsertConsumptionDraftMutationVariables = Exact<{
  subBuildingId: Scalars['Int'];
  areaId?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['timestamptz']>;
  to?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['numeric']>;
  displayUnitValue?: InputMaybe<consumption_unit_enum>;
  cost?: InputMaybe<Scalars['numeric']>;
  provider?: InputMaybe<Scalars['String']>;
  sourceTypeId: consumption_source_type_enum;
  consumptionTypeId?: InputMaybe<consumption_type_enum>;
  energySourceTypeId?: InputMaybe<energy_source_type_enum>;
  subTypeId?: InputMaybe<consumption_sub_type_enum>;
  userId: Scalars['Int'];
}>;

export type DataCollectionInsertConsumptionDraftMutation = {
  insert_consumption_draft_one?: {
    id: number;
    area_id?: number | null;
    consumption_type_id?: consumption_type_enum | null;
    cost?: number | null;
    created_by_user_id?: number | null;
    energy_source_type_id?: energy_source_type_enum | null;
    from?: string | null;
    provider?: string | null;
    source_type_id: consumption_source_type_enum;
    sub_building_id: number;
    sub_type_id?: consumption_sub_type_enum | null;
    to?: string | null;
    value?: number | null;
    display_unit_value?: consumption_unit_enum | null;
  } | null;
};

export type DataCollectionInsertConsumptionWasteDetailsDraftMutationVariables = Exact<{
  consumptionDraftId: Scalars['Int'];
  amount?: InputMaybe<Scalars['numeric']>;
  frequency?: InputMaybe<Scalars['numeric']>;
  displayUnitFrequency?: InputMaybe<consumption_unit_enum>;
  price?: InputMaybe<Scalars['numeric']>;
  volume?: InputMaybe<Scalars['numeric']>;
  displayUnitVolume?: InputMaybe<consumption_unit_enum>;
}>;

export type DataCollectionInsertConsumptionWasteDetailsDraftMutation = {
  insert_consumption_waste_details_draft_one?: {
    id: number;
    amount?: number | null;
    frequency?: number | null;
    display_unit_frequency?: consumption_unit_enum | null;
    price?: number | null;
    volume?: number | null;
    display_unit_volume?: consumption_unit_enum | null;
  } | null;
};

export type DataCollectionSaveConsumptionDraftMutationVariables = Exact<{
  consumptionDraftId: Scalars['Int'];
  areaId?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['timestamptz']>;
  to?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['numeric']>;
  displayUnitValue?: InputMaybe<consumption_unit_enum>;
  cost?: InputMaybe<Scalars['numeric']>;
  provider?: InputMaybe<Scalars['String']>;
  consumptionTypeId?: InputMaybe<consumption_type_enum>;
  energySourceTypeId?: InputMaybe<energy_source_type_enum>;
  subTypeId?: InputMaybe<consumption_sub_type_enum>;
}>;

export type DataCollectionSaveConsumptionDraftMutation = {
  update_consumption_draft_by_pk?: {
    id: number;
    area_id?: number | null;
    consumption_type_id?: consumption_type_enum | null;
    cost?: number | null;
    created_by_user_id?: number | null;
    energy_source_type_id?: energy_source_type_enum | null;
    from?: string | null;
    provider?: string | null;
    source_type_id: consumption_source_type_enum;
    sub_building_id: number;
    sub_type_id?: consumption_sub_type_enum | null;
    to?: string | null;
    value?: number | null;
    display_unit_value?: consumption_unit_enum | null;
    consumption_waste_details_draft?: { id: number } | null;
  } | null;
};

export type DataCollectionUpdateConsumptionWasteDetailsDraftMutationVariables = Exact<{
  id: Scalars['Int'];
  amount?: InputMaybe<Scalars['numeric']>;
  frequency?: InputMaybe<Scalars['numeric']>;
  displayUnitFrequency?: InputMaybe<consumption_unit_enum>;
  price?: InputMaybe<Scalars['numeric']>;
  volume?: InputMaybe<Scalars['numeric']>;
  displayUnitVolume?: InputMaybe<consumption_unit_enum>;
}>;

export type DataCollectionUpdateConsumptionWasteDetailsDraftMutation = {
  update_consumption_waste_details_draft_by_pk?: {
    id: number;
    amount?: number | null;
    frequency?: number | null;
    display_unit_frequency?: consumption_unit_enum | null;
    price?: number | null;
    volume?: number | null;
    display_unit_volume?: consumption_unit_enum | null;
  } | null;
};

export type DataCollectionDeleteConsumptionWasteDetailsDraftMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DataCollectionDeleteConsumptionWasteDetailsDraftMutation = {
  delete_consumption_waste_details_draft_by_pk?: { id: number } | null;
};

export type DataCollectionInsertConsumptionMutationVariables = Exact<{
  subBuildingId: Scalars['Int'];
  areaId: Scalars['Int'];
  from: Scalars['timestamptz'];
  to: Scalars['timestamptz'];
  value?: InputMaybe<Scalars['numeric']>;
  displayUnitValue?: InputMaybe<consumption_unit_enum>;
  cost?: InputMaybe<Scalars['numeric']>;
  provider: Scalars['String'];
  sourceTypeId: consumption_source_type_enum;
  consumptionTypeId: consumption_type_enum;
  energySourceTypeId?: InputMaybe<energy_source_type_enum>;
  subTypeId?: InputMaybe<consumption_sub_type_enum>;
  userId: Scalars['Int'];
}>;

export type DataCollectionInsertConsumptionMutation = { insert_consumption_one?: { id: number } | null };

export type DataCollectionInsertConsumptionWasteDetailsMutationVariables = Exact<{
  consumptionId: Scalars['Int'];
  amount: Scalars['numeric'];
  frequency: Scalars['numeric'];
  displayUnitFrequency?: InputMaybe<consumption_unit_enum>;
  price: Scalars['numeric'];
  volume: Scalars['numeric'];
  displayUnitVolume?: InputMaybe<consumption_unit_enum>;
}>;

export type DataCollectionInsertConsumptionWasteDetailsMutation = {
  insert_consumption_waste_details_one?: {
    id: number;
    amount: number;
    frequency: number;
    display_unit_frequency?: consumption_unit_enum | null;
    price: number;
    volume: number;
    display_unit_volume?: consumption_unit_enum | null;
  } | null;
};

export type DataCollectionSaveConsumptionMutationVariables = Exact<{
  consumptionId: Scalars['Int'];
  areaId?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['timestamptz']>;
  to?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['numeric']>;
  displayUnitValue?: InputMaybe<consumption_unit_enum>;
  cost?: InputMaybe<Scalars['numeric']>;
  provider?: InputMaybe<Scalars['String']>;
  consumptionTypeId?: InputMaybe<consumption_type_enum>;
  energySourceTypeId?: InputMaybe<energy_source_type_enum>;
  subTypeId?: InputMaybe<consumption_sub_type_enum>;
}>;

export type DataCollectionSaveConsumptionMutation = {
  update_consumption_by_pk?: {
    id: number;
    area_id: number;
    consumption_type_id: consumption_type_enum;
    cost?: number | null;
    created_by_user_id?: number | null;
    energy_source_type_id?: energy_source_type_enum | null;
    from: string;
    provider?: string | null;
    source_type_id: consumption_source_type_enum;
    sub_building_id: number;
    sub_type_id?: consumption_sub_type_enum | null;
    to: string;
    value?: number | null;
    display_unit_value?: consumption_unit_enum | null;
    consumption_waste_detail?: { id: number } | null;
  } | null;
};

export type DataCollectionUpdateConsumptionWasteDetailsMutationVariables = Exact<{
  id: Scalars['Int'];
  amount: Scalars['numeric'];
  frequency?: InputMaybe<Scalars['numeric']>;
  displayUnitFrequency?: InputMaybe<consumption_unit_enum>;
  price: Scalars['numeric'];
  volume: Scalars['numeric'];
  displayUnitVolume?: InputMaybe<consumption_unit_enum>;
}>;

export type DataCollectionUpdateConsumptionWasteDetailsMutation = {
  update_consumption_waste_details_by_pk?: {
    id: number;
    amount: number;
    frequency: number;
    display_unit_frequency?: consumption_unit_enum | null;
    price: number;
    volume: number;
    display_unit_volume?: consumption_unit_enum | null;
  } | null;
};

export type DataCollectionDeleteConsumptionWasteDetailsMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DataCollectionDeleteConsumptionWasteDetailsMutation = {
  delete_consumption_waste_details_by_pk?: { id: number } | null;
};

export type DeleteConsumptionInvoiceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteConsumptionInvoiceMutation = {
  delete_consumption_invoice_by_pk?: { __typename: 'consumption_invoice'; id: number } | null;
};

export type DeleteConsumptionMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteConsumptionMutation = { delete_consumption_by_pk?: { __typename: 'consumption'; id: number } | null };

export type DeleteConsumptionInvoiceDraftMutationVariables = Exact<{
  invoiceDraftId: Scalars['Int'];
}>;

export type DeleteConsumptionInvoiceDraftMutation = {
  delete_consumption_invoice_draft_by_pk?: {
    __typename: 'consumption_invoice_draft';
    id: number;
    consumption_drafts: Array<{ __typename: 'consumption_draft'; id: number }>;
  } | null;
};

export type DataCollectionSubBuildingConsumptionDraftDeleteConsumptionDraftMutationVariables = Exact<{
  consumptionDraftId: Scalars['Int'];
}>;

export type DataCollectionSubBuildingConsumptionDraftDeleteConsumptionDraftMutation = {
  delete_consumption_draft_by_pk?: { __typename: 'consumption_draft'; id: number } | null;
};

export type CreateEmissionCertificateDraftMutationVariables = Exact<{ [key: string]: never }>;

export type CreateEmissionCertificateDraftMutation = {
  createEmissionCertificateDraft: {
    emission_certificate_draft_id: number;
    upload_url: string;
    CreateEmissionCertificateDraftOutput?: { file_id: number } | null;
  };
};

export type UpdateEmissionCertificateDraftMutationVariables = Exact<{
  id: Scalars['Int'];
  emission_factor?: InputMaybe<Scalars['float8']>;
  issuer?: InputMaybe<Scalars['String']>;
  valid_from?: InputMaybe<Scalars['timestamptz']>;
  valid_until?: InputMaybe<Scalars['timestamptz']>;
  primary_energy_factor?: InputMaybe<Scalars['float8']>;
}>;

export type UpdateEmissionCertificateDraftMutation = {
  update_emission_certificate_draft_by_pk?: {
    __typename: 'emission_certificate_draft';
    created_at: string;
    issuer?: string | null;
    emission_factor?: number | null;
    primary_energy_factor?: number | null;
    file_id: number;
    org_id: number;
    id: number;
    valid_from?: string | null;
    valid_until?: string | null;
    file: { downloadUrl: string; id: number; name?: string | null; created_at: string };
    org: { name: string };
  } | null;
};

export type CreateEmissionCertificateFromDraftMutationVariables = Exact<{
  draft_id: Scalars['Int'];
}>;

export type CreateEmissionCertificateFromDraftMutation = {
  createEmissionCertificateFromDraft: {
    CreateEmissionCertificateFromDraftOutput?: {
      __typename: 'emission_certificate';
      created_at: string;
      issuer: string;
      emission_factor?: number | null;
      primary_energy_factor?: number | null;
      file_id: number;
      org_id: number;
      id: number;
      valid_from: string;
      valid_until: string;
      file: { downloadUrl: string; id: number; name?: string | null; created_at: string };
      org: { name: string };
      energy_system_consumer_routes: Array<{ energy_system: { building_model: { building_id?: number | null } } }>;
      primary_energy_factor_energy_system_consumer_routes: Array<{
        energy_system: { building_model: { building_id?: number | null } };
      }>;
    } | null;
  };
};

export type UpdateEmissionCertificateMutationVariables = Exact<{
  emissionCertificate: EmissionCertificateUpdateInput;
}>;

export type UpdateEmissionCertificateMutation = { updateEmissionCertificate: { success: boolean } };

export type DeleteEmissionCertificateMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteEmissionCertificateMutation = { deleteEmissionCertificate: { success: boolean } };

export type DeleteEmissionCertificateDraftMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteEmissionCertificateDraftMutation = {
  delete_emission_certificate_draft_by_pk?: { __typename: 'emission_certificate_draft'; id: number } | null;
};

export type UpdateAreasMutationVariables = Exact<{
  buildingId: Scalars['Int'];
  areaMutations: Array<AreaUpdateMutation> | AreaUpdateMutation;
  saveInvalidState: Scalars['Boolean'];
}>;

export type UpdateAreasMutation = {
  updateAreas: {
    success: boolean;
    scalingResult?: { final_energy: number; final_energy_scaled: number; scaling_successful: boolean } | null;
  };
};

export type RecalculateAreasMutationVariables = Exact<{
  buildingId: Scalars['Int'];
  areaMutations: Array<AreaRecalculateMutation> | AreaRecalculateMutation;
  saveInvalidState: Scalars['Boolean'];
}>;

export type RecalculateAreasMutation = {
  recalculateAreas: {
    success: boolean;
    errorMessage?: string | null;
    scalingResult?: { final_energy: number; final_energy_scaled: number; scaling_successful: boolean } | null;
  };
};

export type RecalculateAreasDryMutationVariables = Exact<{
  buildingId: Scalars['Int'];
  areaMutations: Array<AreaRecalculateMutation> | AreaRecalculateMutation;
}>;

export type RecalculateAreasDryMutation = {
  recalculateAreas: { success: boolean; areas?: Array<{ id?: number | null; value: number }> | null };
};

export type DataCollectionSubBuildingConsumptionFragment = {
  id: number;
  area_id: number;
  provider?: string | null;
  to: string;
  from: string;
  cost?: number | null;
  display_unit_value?: consumption_unit_enum | null;
  value?: number | null;
  created_at?: string | null;
  consumption_type_id: consumption_type_enum;
  source_type_id: consumption_source_type_enum;
  invoice_id?: number | null;
  energy_source_type_id?: energy_source_type_enum | null;
  sub_type_id?: consumption_sub_type_enum | null;
  created_by_user?: { first_name?: string | null; last_name?: string | null } | null;
  consumption_waste_detail?: {
    id: number;
    volume: number;
    display_unit_volume?: consumption_unit_enum | null;
    frequency: number;
    display_unit_frequency?: consumption_unit_enum | null;
    amount: number;
    price: number;
  } | null;
};

export type DataCollectionSubBuildingConsumptionDraftFragment = {
  id: number;
  area_id?: number | null;
  provider?: string | null;
  to?: string | null;
  from?: string | null;
  cost?: number | null;
  display_unit_value?: consumption_unit_enum | null;
  value?: number | null;
  created_at?: string | null;
  consumption_type_id?: consumption_type_enum | null;
  source_type_id: consumption_source_type_enum;
  energy_source_type_id?: energy_source_type_enum | null;
  sub_type_id?: consumption_sub_type_enum | null;
  created_by_user?: { first_name?: string | null; last_name?: string | null } | null;
  consumption_invoice_draft?: { id: number; draft_status: draft_status_enum } | null;
  consumption_waste_details_draft?: {
    id: number;
    volume?: number | null;
    display_unit_volume?: consumption_unit_enum | null;
    frequency?: number | null;
    display_unit_frequency?: consumption_unit_enum | null;
    amount?: number | null;
    price?: number | null;
  } | null;
};

export type DataCollectionSubBuildingSubBuildingSubsidyFragment = {
  __typename: 'subsidy';
  id: number;
  email?: string | null;
  amount_description: string;
  provider_updated_at: string;
  region_type: subsidy_region_type_enum;
  title: string;
  type: subsidy_type_enum;
  url?: string | null;
  categories: Array<{ subsidy_category_type: subsidy_category_type_enum }>;
};

export type DataCollectionBuildingEnvelopeFragment = {
  id: number;
  data_source_type_id: data_source_type_enum;
  inclination?: number | null;
  orientation?: number | null;
  envelope_type_id: envelope_type_enum;
  insulation_thickness?: number | null;
  insulation_material_name?: string | null;
  insulation_material_category_id?: insulation_material_category_enum | null;
  insulation_method_id?: insulation_method_enum | null;
  insulation_lambda?: number | null;
  base_construction_thickness?: number | null;
  base_construction_material_name?: string | null;
  base_construction_lambda?: number | null;
  area: number;
  u_value?: number | null;
};

export type DataCollectionBuildingEnergySystemConsumerRouteFragment = {
  id: number;
  energy_final: number;
  energy_effective?: number | null;
  energy_source_type_id: energy_source_type_enum;
  energy_primary?: number | null;
  emission_factor_origin_id: emission_factor_origin_enum;
  emission_certificate_id?: number | null;
  primary_energy_factor_emission_certificate_id?: number | null;
  emission_certificate?: { emission_factor?: number | null } | null;
  primary_energy_factor_emission_certificate?: { primary_energy_factor?: number | null } | null;
  energy_consumer: {
    id: number;
    energy_consumer_technology_type_id?: energy_consumer_technology_type_enum | null;
    energy_consumer_type_id: energy_consumer_type_enum;
    efficiency?: number | null;
    construction_year?: number | null;
  };
  energy_distribution?: {
    id: number;
    energy_distribution_type_id: energy_distribution_type_enum;
    heat_distribution_loss: number;
    heat_transfer_loss: number;
    last_hydraulic_balancing?: string | null;
    last_balancing_set_to_never: boolean;
    construction_year?: number | null;
  } | null;
  energy_storage?: {
    id: number;
    energy_storage_type_id: energy_storage_type_enum;
    annual_heat_loss?: number | null;
    construction_year?: number | null;
  } | null;
};

export type DataCollectionBuildingEnergySystemProducerRouteFragment = {
  energy_source_type_id: energy_source_type_enum;
  energy_producer: { name?: string | null };
};

export type DataCollectionBuildingEnergySystemFragment = {
  id: number;
  energy_system_type_id: energy_system_type_enum;
  data_source_type_id?: data_source_type_enum | null;
  energy_system_consumer_routes: Array<{
    id: number;
    energy_final: number;
    energy_effective?: number | null;
    energy_source_type_id: energy_source_type_enum;
    energy_primary?: number | null;
    emission_factor_origin_id: emission_factor_origin_enum;
    emission_certificate_id?: number | null;
    primary_energy_factor_emission_certificate_id?: number | null;
    emission_certificate?: { emission_factor?: number | null } | null;
    primary_energy_factor_emission_certificate?: { primary_energy_factor?: number | null } | null;
    energy_consumer: {
      id: number;
      energy_consumer_technology_type_id?: energy_consumer_technology_type_enum | null;
      energy_consumer_type_id: energy_consumer_type_enum;
      efficiency?: number | null;
      construction_year?: number | null;
    };
    energy_distribution?: {
      id: number;
      energy_distribution_type_id: energy_distribution_type_enum;
      heat_distribution_loss: number;
      heat_transfer_loss: number;
      last_hydraulic_balancing?: string | null;
      last_balancing_set_to_never: boolean;
      construction_year?: number | null;
    } | null;
    energy_storage?: {
      id: number;
      energy_storage_type_id: energy_storage_type_enum;
      annual_heat_loss?: number | null;
      construction_year?: number | null;
    } | null;
  }>;
  energy_system_producer_routes: Array<{
    energy_source_type_id: energy_source_type_enum;
    energy_producer: { name?: string | null };
  }>;
};

export type DataCollectionBuildingModelFragment = {
  id: number;
  energy_final?: number | null;
  action_planning_available: boolean;
  envelope_units: Array<{
    id: number;
    data_source_type_id: data_source_type_enum;
    inclination?: number | null;
    orientation?: number | null;
    envelope_type_id: envelope_type_enum;
    insulation_thickness?: number | null;
    insulation_material_name?: string | null;
    insulation_material_category_id?: insulation_material_category_enum | null;
    insulation_method_id?: insulation_method_enum | null;
    insulation_lambda?: number | null;
    base_construction_thickness?: number | null;
    base_construction_material_name?: string | null;
    base_construction_lambda?: number | null;
    area: number;
    u_value?: number | null;
  }>;
  energy_systems: Array<{
    id: number;
    energy_system_type_id: energy_system_type_enum;
    data_source_type_id?: data_source_type_enum | null;
    energy_system_consumer_routes: Array<{
      id: number;
      energy_final: number;
      energy_effective?: number | null;
      energy_source_type_id: energy_source_type_enum;
      energy_primary?: number | null;
      emission_factor_origin_id: emission_factor_origin_enum;
      emission_certificate_id?: number | null;
      primary_energy_factor_emission_certificate_id?: number | null;
      emission_certificate?: { emission_factor?: number | null } | null;
      primary_energy_factor_emission_certificate?: { primary_energy_factor?: number | null } | null;
      energy_consumer: {
        id: number;
        energy_consumer_technology_type_id?: energy_consumer_technology_type_enum | null;
        energy_consumer_type_id: energy_consumer_type_enum;
        efficiency?: number | null;
        construction_year?: number | null;
      };
      energy_distribution?: {
        id: number;
        energy_distribution_type_id: energy_distribution_type_enum;
        heat_distribution_loss: number;
        heat_transfer_loss: number;
        last_hydraulic_balancing?: string | null;
        last_balancing_set_to_never: boolean;
        construction_year?: number | null;
      } | null;
      energy_storage?: {
        id: number;
        energy_storage_type_id: energy_storage_type_enum;
        annual_heat_loss?: number | null;
        construction_year?: number | null;
      } | null;
    }>;
    energy_system_producer_routes: Array<{
      energy_source_type_id: energy_source_type_enum;
      energy_producer: { name?: string | null };
    }>;
  }>;
  energy_paths: Array<{ efficiency_class_id?: efficiency_class_enum | null }>;
};

export type DataCollectionBuildingSubBuildingFragment = {
  id: number;
  energy_standard: energy_standard_enum;
  sub_building_class: sub_building_class_enum;
  units_residential: number;
  units_commercial: number;
  building_id: number;
  subsidies_updated_at?: string | null;
  consumptions: Array<{
    id: number;
    area_id: number;
    provider?: string | null;
    to: string;
    from: string;
    cost?: number | null;
    display_unit_value?: consumption_unit_enum | null;
    value?: number | null;
    created_at?: string | null;
    consumption_type_id: consumption_type_enum;
    source_type_id: consumption_source_type_enum;
    invoice_id?: number | null;
    energy_source_type_id?: energy_source_type_enum | null;
    sub_type_id?: consumption_sub_type_enum | null;
    created_by_user?: { first_name?: string | null; last_name?: string | null } | null;
    consumption_waste_detail?: {
      id: number;
      volume: number;
      display_unit_volume?: consumption_unit_enum | null;
      frequency: number;
      display_unit_frequency?: consumption_unit_enum | null;
      amount: number;
      price: number;
    } | null;
  }>;
  consumption_drafts: Array<{
    id: number;
    area_id?: number | null;
    provider?: string | null;
    to?: string | null;
    from?: string | null;
    cost?: number | null;
    display_unit_value?: consumption_unit_enum | null;
    value?: number | null;
    created_at?: string | null;
    consumption_type_id?: consumption_type_enum | null;
    source_type_id: consumption_source_type_enum;
    energy_source_type_id?: energy_source_type_enum | null;
    sub_type_id?: consumption_sub_type_enum | null;
    created_by_user?: { first_name?: string | null; last_name?: string | null } | null;
    consumption_invoice_draft?: { id: number; draft_status: draft_status_enum } | null;
    consumption_waste_details_draft?: {
      id: number;
      volume?: number | null;
      display_unit_volume?: consumption_unit_enum | null;
      frequency?: number | null;
      display_unit_frequency?: consumption_unit_enum | null;
      amount?: number | null;
      price?: number | null;
    } | null;
  }>;
  subsidies: Array<{
    subsidy: {
      __typename: 'subsidy';
      id: number;
      email?: string | null;
      amount_description: string;
      provider_updated_at: string;
      region_type: subsidy_region_type_enum;
      title: string;
      type: subsidy_type_enum;
      url?: string | null;
      categories: Array<{ subsidy_category_type: subsidy_category_type_enum }>;
    };
  }>;
};

export type DataCollectionBuildingWithSubBuildingsFragment = {
  __typename: 'building';
  id: number;
  year_constructed: number;
  building_state_id: building_state_enum;
  monument_protection: boolean;
  leasehold: boolean;
  milieu_protection: boolean;
  heritage_district: boolean;
  address: {
    street: string;
    city: string;
    postal_code: string;
    country_state_id: country_state_enum;
    country_id: country_enum;
    mapbox_building_ids: Array<string>;
    mapbox_pitch?: number | null;
    mapbox_bearing?: number | null;
    mapbox_zoom?: number | null;
    latitude?: number | null;
    longitude?: number | null;
  };
  areas: Array<{
    id: number;
    area_type_id: area_type_enum;
    area_system_id: area_system_type_enum;
    is_rented: boolean;
    class_of_use_id: sub_building_class_enum;
    data_source_id?: data_source_type_enum | null;
    value: number;
    type_of_use_id: type_of_use_enum;
    parent_id?: number | null;
    description: string;
    name: string;
  }>;
  responsible_user: { first_name?: string | null; last_name?: string | null };
  sub_buildings: Array<{
    id: number;
    energy_standard: energy_standard_enum;
    sub_building_class: sub_building_class_enum;
    units_residential: number;
    units_commercial: number;
    building_id: number;
    subsidies_updated_at?: string | null;
    consumptions: Array<{
      id: number;
      area_id: number;
      provider?: string | null;
      to: string;
      from: string;
      cost?: number | null;
      display_unit_value?: consumption_unit_enum | null;
      value?: number | null;
      created_at?: string | null;
      consumption_type_id: consumption_type_enum;
      source_type_id: consumption_source_type_enum;
      invoice_id?: number | null;
      energy_source_type_id?: energy_source_type_enum | null;
      sub_type_id?: consumption_sub_type_enum | null;
      created_by_user?: { first_name?: string | null; last_name?: string | null } | null;
      consumption_waste_detail?: {
        id: number;
        volume: number;
        display_unit_volume?: consumption_unit_enum | null;
        frequency: number;
        display_unit_frequency?: consumption_unit_enum | null;
        amount: number;
        price: number;
      } | null;
    }>;
    consumption_drafts: Array<{
      id: number;
      area_id?: number | null;
      provider?: string | null;
      to?: string | null;
      from?: string | null;
      cost?: number | null;
      display_unit_value?: consumption_unit_enum | null;
      value?: number | null;
      created_at?: string | null;
      consumption_type_id?: consumption_type_enum | null;
      source_type_id: consumption_source_type_enum;
      energy_source_type_id?: energy_source_type_enum | null;
      sub_type_id?: consumption_sub_type_enum | null;
      created_by_user?: { first_name?: string | null; last_name?: string | null } | null;
      consumption_invoice_draft?: { id: number; draft_status: draft_status_enum } | null;
      consumption_waste_details_draft?: {
        id: number;
        volume?: number | null;
        display_unit_volume?: consumption_unit_enum | null;
        frequency?: number | null;
        display_unit_frequency?: consumption_unit_enum | null;
        amount?: number | null;
        price?: number | null;
      } | null;
    }>;
    subsidies: Array<{
      subsidy: {
        __typename: 'subsidy';
        id: number;
        email?: string | null;
        amount_description: string;
        provider_updated_at: string;
        region_type: subsidy_region_type_enum;
        title: string;
        type: subsidy_type_enum;
        url?: string | null;
        categories: Array<{ subsidy_category_type: subsidy_category_type_enum }>;
      };
    }>;
  }>;
  economic_unit: {
    __typename: 'economic_unit';
    id: number;
    name: string;
    associatedBuildingsAndDrafts: { amount: number };
    portfolio: {
      __typename: 'portfolio';
      id: number;
      name?: string | null;
      emission_factor_type_id?: emission_factor_type_enum | null;
    };
  };
  active_building_model?: {
    id: number;
    energy_final?: number | null;
    action_planning_available: boolean;
    envelope_units: Array<{
      id: number;
      data_source_type_id: data_source_type_enum;
      inclination?: number | null;
      orientation?: number | null;
      envelope_type_id: envelope_type_enum;
      insulation_thickness?: number | null;
      insulation_material_name?: string | null;
      insulation_material_category_id?: insulation_material_category_enum | null;
      insulation_method_id?: insulation_method_enum | null;
      insulation_lambda?: number | null;
      base_construction_thickness?: number | null;
      base_construction_material_name?: string | null;
      base_construction_lambda?: number | null;
      area: number;
      u_value?: number | null;
    }>;
    energy_systems: Array<{
      id: number;
      energy_system_type_id: energy_system_type_enum;
      data_source_type_id?: data_source_type_enum | null;
      energy_system_consumer_routes: Array<{
        id: number;
        energy_final: number;
        energy_effective?: number | null;
        energy_source_type_id: energy_source_type_enum;
        energy_primary?: number | null;
        emission_factor_origin_id: emission_factor_origin_enum;
        emission_certificate_id?: number | null;
        primary_energy_factor_emission_certificate_id?: number | null;
        emission_certificate?: { emission_factor?: number | null } | null;
        primary_energy_factor_emission_certificate?: { primary_energy_factor?: number | null } | null;
        energy_consumer: {
          id: number;
          energy_consumer_technology_type_id?: energy_consumer_technology_type_enum | null;
          energy_consumer_type_id: energy_consumer_type_enum;
          efficiency?: number | null;
          construction_year?: number | null;
        };
        energy_distribution?: {
          id: number;
          energy_distribution_type_id: energy_distribution_type_enum;
          heat_distribution_loss: number;
          heat_transfer_loss: number;
          last_hydraulic_balancing?: string | null;
          last_balancing_set_to_never: boolean;
          construction_year?: number | null;
        } | null;
        energy_storage?: {
          id: number;
          energy_storage_type_id: energy_storage_type_enum;
          annual_heat_loss?: number | null;
          construction_year?: number | null;
        } | null;
      }>;
      energy_system_producer_routes: Array<{
        energy_source_type_id: energy_source_type_enum;
        energy_producer: { name?: string | null };
      }>;
    }>;
    energy_paths: Array<{ efficiency_class_id?: efficiency_class_enum | null }>;
  } | null;
  climate_risks: Array<{
    value: number;
    uncertainty: number;
    type: climate_risk_type_enum;
    timespan?: string | null;
    created_at?: string | null;
  }>;
  climate_risks_aggregate: { aggregate?: { max?: { created_at?: string | null } | null } | null };
};

export type DataCollectionYearlyConsumptionFragment = {
  value: number;
  providers: Array<string>;
  cost: number;
  consumption_type: consumption_type_enum;
};

export type DataCollectionConsumptionConsumptionFragment = {
  id: number;
  from: string;
  to: string;
  value?: number | null;
  display_unit_value?: consumption_unit_enum | null;
  cost?: number | null;
  consumption_type_id: consumption_type_enum;
  energy_source_type_id?: energy_source_type_enum | null;
  sub_type_id?: consumption_sub_type_enum | null;
  provider?: string | null;
  area_id: number;
  consumption_waste_detail?: {
    id: number;
    frequency: number;
    display_unit_frequency?: consumption_unit_enum | null;
    volume: number;
    display_unit_volume?: consumption_unit_enum | null;
    amount: number;
    price: number;
  } | null;
};

export type DataCollectionConsumptionDraftConsumptionDraftFragment = {
  id: number;
  from?: string | null;
  to?: string | null;
  value?: number | null;
  cost?: number | null;
  consumption_type_id?: consumption_type_enum | null;
  display_unit_value?: consumption_unit_enum | null;
  energy_source_type_id?: energy_source_type_enum | null;
  sub_type_id?: consumption_sub_type_enum | null;
  provider?: string | null;
  area_id?: number | null;
  sub_building_id: number;
  consumption_waste_details_draft?: {
    id: number;
    frequency?: number | null;
    display_unit_frequency?: consumption_unit_enum | null;
    volume?: number | null;
    display_unit_volume?: consumption_unit_enum | null;
    amount?: number | null;
    price?: number | null;
  } | null;
};

export type DataCollectionConsumptionDraftConsumptionDraftSubBuildingAreasFragment = {
  id: number;
  building: {
    id: number;
    address: { street: string; postal_code: string; city: string };
    economic_unit: { id: number; portfolio: { id: number; name?: string | null } };
    areas: Array<{
      id: number;
      area_type_id: area_type_enum;
      area_system_id: area_system_type_enum;
      name: string;
      description: string;
      value: number;
    }>;
  };
};

export type DataCollectionEnergyCertificateDraftEnergyCertificateDraftFragment = {
  id: number;
  street?: string | null;
  postal_code?: string | null;
  city?: string | null;
  country_state_id?: country_state_enum | null;
  country_id: country_enum;
  building_state_id?: building_state_enum | null;
  portfolio_id?: number | null;
  economic_unit_id?: number | null;
  responsible_user_id?: number | null;
  year_constructed?: number | null;
  area_usable?: number | null;
  sub_building_class_id?: sub_building_class_enum | null;
  type_of_use_id?: type_of_use_enum | null;
  draft_status_id: draft_status_enum;
  energy_certificate_type_id?: energy_certificate_type_enum | null;
  expiry_date?: string | null;
  units?: number | null;
  monument_protection: boolean;
  leasehold: boolean;
  milieu_protection: boolean;
  heritage_district: boolean;
  energy_certificate_consumers: Array<{
    energy_system_type_id: energy_system_type_enum;
    energy_source_type_id: energy_source_type_enum;
    energy_final: number;
  }>;
};

export type DataCollectionEnergyCertificateDraftUserFragment = {
  id: number;
  first_name?: string | null;
  last_name?: string | null;
};

export type SubBuildingDocumentsFileFragment = {
  __typename: 'file';
  id: number;
  name?: string | null;
  created_at: string;
  downloadUrl: string;
};

export type DataCollectionEnergyCertificateDraftsListEnergyCertificateDraftFragment = {
  id: number;
  created_at: string;
  updated_at: string;
  street?: string | null;
  city?: string | null;
  draft_status_id: draft_status_enum;
  user?: { id: number; first_name?: string | null; last_name?: string | null } | null;
  file: { name?: string | null; downloadUrl: string };
  portfolio?: { name?: string | null } | null;
};

export type DataCollectionEnergyCertificateDraftsListEnergyCertificateDraftSubscriptionFragment = {
  id: number;
  updated_at: string;
  created_at: string;
  street?: string | null;
  city?: string | null;
  draft_status_id: draft_status_enum;
  user?: { id: number; first_name?: string | null; last_name?: string | null } | null;
  portfolio?: { name?: string | null } | null;
};

export type DataCollectionSubBuildingFragment = {
  __typename: 'sub_building';
  id: number;
  building: {
    year_constructed: number;
    building_state_id: building_state_enum;
    economic_unit: { portfolio: { id: number; name?: string | null } };
  };
};

export type DataCollectionEconomicUnitFragment = {
  __typename: 'economic_unit';
  id: number;
  name: string;
  customer_economic_unit_id?: string | null;
};

export type DataCollectionEconomicUnitPortfoliosFragment = {
  __typename: 'economic_unit';
  id: number;
  name: string;
  customer_economic_unit_id?: string | null;
  portfolio: { __typename: 'portfolio'; id: number; name?: string | null };
  associatedBuildingsAndDrafts: { amount: number };
};

export type DataCollectionBuildingsListBuildingFragment = {
  __typename: 'building';
  year_constructed: number;
  id: number;
  building_state_id: building_state_enum;
  created_at: string;
  updated_at: string;
  address: { __typename: 'address'; id: number; street: string; city: string; postal_code: string };
  areas: Array<{ type_of_use_id: type_of_use_enum; value: number; area_type_id: area_type_enum }>;
  sub_buildings: Array<{
    __typename: 'sub_building';
    id: number;
    building: {
      year_constructed: number;
      building_state_id: building_state_enum;
      economic_unit: { portfolio: { id: number; name?: string | null } };
    };
  }>;
  economic_unit: {
    __typename: 'economic_unit';
    id: number;
    portfolio: { id: number; name?: string | null };
    associatedBuildingsAndDrafts: { amount: number };
  };
  active_building_model?: { action_planning_available: boolean; processing: boolean } | null;
};

export type DataCollectionGetEconomicUnitWithBuildingsFragment = {
  __typename: 'economic_unit';
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  customer_economic_unit_id?: string | null;
  associatedBuildingsAndDrafts: { amount: number };
  buildings: Array<{
    __typename: 'building';
    year_constructed: number;
    id: number;
    building_state_id: building_state_enum;
    created_at: string;
    updated_at: string;
    address: { __typename: 'address'; id: number; street: string; city: string; postal_code: string };
    areas: Array<{ type_of_use_id: type_of_use_enum; value: number; area_type_id: area_type_enum }>;
    sub_buildings: Array<{
      __typename: 'sub_building';
      id: number;
      building: {
        year_constructed: number;
        building_state_id: building_state_enum;
        economic_unit: { portfolio: { id: number; name?: string | null } };
      };
    }>;
    economic_unit: {
      __typename: 'economic_unit';
      id: number;
      portfolio: { id: number; name?: string | null };
      associatedBuildingsAndDrafts: { amount: number };
    };
    active_building_model?: { action_planning_available: boolean; processing: boolean } | null;
  }>;
  portfolio: { __typename: 'portfolio'; id: number; name?: string | null };
};

export type BuildingModelMutationAvailabilityFragment = {
  action_deletion_necessary: boolean;
  affected_action_plan_count: number;
  affected_users: Array<{ id: number; name: string }>;
};

export type DataCollectionEmissionCertificateDraftFragment = {
  __typename: 'emission_certificate_draft';
  created_at: string;
  issuer?: string | null;
  emission_factor?: number | null;
  primary_energy_factor?: number | null;
  file_id: number;
  org_id: number;
  id: number;
  valid_from?: string | null;
  valid_until?: string | null;
  file: { downloadUrl: string; id: number; name?: string | null; created_at: string };
  org: { name: string };
};

export type DataCollectionEmissionCertificateFragment = {
  __typename: 'emission_certificate';
  created_at: string;
  issuer: string;
  emission_factor?: number | null;
  primary_energy_factor?: number | null;
  file_id: number;
  org_id: number;
  id: number;
  valid_from: string;
  valid_until: string;
  file: { downloadUrl: string; id: number; name?: string | null; created_at: string };
  org: { name: string };
  energy_system_consumer_routes: Array<{ energy_system: { building_model: { building_id?: number | null } } }>;
  primary_energy_factor_energy_system_consumer_routes: Array<{
    energy_system: { building_model: { building_id?: number | null } };
  }>;
};

export type DataCollectionDocumentDraftFragment = {
  downloadUrl: string;
  id: number;
  name?: string | null;
  created_at: string;
};

export type DataCollectionGetEconomicUnitsListQueryVariables = Exact<{ [key: string]: never }>;

export type DataCollectionGetEconomicUnitsListQuery = {
  economic_unit: Array<{
    __typename: 'economic_unit';
    id: number;
    name: string;
    customer_economic_unit_id?: string | null;
  }>;
};

export type DataCollectionGetEconomicUnitsWithPortfoliosQueryVariables = Exact<{ [key: string]: never }>;

export type DataCollectionGetEconomicUnitsWithPortfoliosQuery = {
  economic_unit: Array<{
    __typename: 'economic_unit';
    id: number;
    name: string;
    customer_economic_unit_id?: string | null;
    portfolio: { __typename: 'portfolio'; id: number; name?: string | null };
    associatedBuildingsAndDrafts: { amount: number };
  }>;
};

export type DataCollectionGetEconomicUnitsListWithBuildingsQueryVariables = Exact<{ [key: string]: never }>;

export type DataCollectionGetEconomicUnitsListWithBuildingsQuery = {
  economic_unit: Array<{
    __typename: 'economic_unit';
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    customer_economic_unit_id?: string | null;
    associatedBuildingsAndDrafts: { amount: number };
    buildings: Array<{
      __typename: 'building';
      year_constructed: number;
      id: number;
      building_state_id: building_state_enum;
      created_at: string;
      updated_at: string;
      address: { __typename: 'address'; id: number; street: string; city: string; postal_code: string };
      areas: Array<{ type_of_use_id: type_of_use_enum; value: number; area_type_id: area_type_enum }>;
      sub_buildings: Array<{
        __typename: 'sub_building';
        id: number;
        building: {
          year_constructed: number;
          building_state_id: building_state_enum;
          economic_unit: { portfolio: { id: number; name?: string | null } };
        };
      }>;
      economic_unit: {
        __typename: 'economic_unit';
        id: number;
        portfolio: { id: number; name?: string | null };
        associatedBuildingsAndDrafts: { amount: number };
      };
      active_building_model?: { action_planning_available: boolean; processing: boolean } | null;
    }>;
    portfolio: { __typename: 'portfolio'; id: number; name?: string | null };
  }>;
};

export type DataCollectionBuildingGetBuildingQueryVariables = Exact<{
  buildingId: Scalars['Int'];
  year: Scalars['Int'];
}>;

export type DataCollectionBuildingGetBuildingQuery = {
  building_by_pk?: {
    __typename: 'building';
    id: number;
    year_constructed: number;
    building_state_id: building_state_enum;
    monument_protection: boolean;
    leasehold: boolean;
    milieu_protection: boolean;
    heritage_district: boolean;
    address: {
      street: string;
      city: string;
      postal_code: string;
      country_state_id: country_state_enum;
      country_id: country_enum;
      mapbox_building_ids: Array<string>;
      mapbox_pitch?: number | null;
      mapbox_bearing?: number | null;
      mapbox_zoom?: number | null;
      latitude?: number | null;
      longitude?: number | null;
    };
    areas: Array<{
      id: number;
      area_type_id: area_type_enum;
      area_system_id: area_system_type_enum;
      is_rented: boolean;
      class_of_use_id: sub_building_class_enum;
      data_source_id?: data_source_type_enum | null;
      value: number;
      type_of_use_id: type_of_use_enum;
      parent_id?: number | null;
      description: string;
      name: string;
    }>;
    responsible_user: { first_name?: string | null; last_name?: string | null };
    sub_buildings: Array<{
      id: number;
      energy_standard: energy_standard_enum;
      sub_building_class: sub_building_class_enum;
      units_residential: number;
      units_commercial: number;
      building_id: number;
      subsidies_updated_at?: string | null;
      consumptions: Array<{
        id: number;
        area_id: number;
        provider?: string | null;
        to: string;
        from: string;
        cost?: number | null;
        display_unit_value?: consumption_unit_enum | null;
        value?: number | null;
        created_at?: string | null;
        consumption_type_id: consumption_type_enum;
        source_type_id: consumption_source_type_enum;
        invoice_id?: number | null;
        energy_source_type_id?: energy_source_type_enum | null;
        sub_type_id?: consumption_sub_type_enum | null;
        created_by_user?: { first_name?: string | null; last_name?: string | null } | null;
        consumption_waste_detail?: {
          id: number;
          volume: number;
          display_unit_volume?: consumption_unit_enum | null;
          frequency: number;
          display_unit_frequency?: consumption_unit_enum | null;
          amount: number;
          price: number;
        } | null;
      }>;
      consumption_drafts: Array<{
        id: number;
        area_id?: number | null;
        provider?: string | null;
        to?: string | null;
        from?: string | null;
        cost?: number | null;
        display_unit_value?: consumption_unit_enum | null;
        value?: number | null;
        created_at?: string | null;
        consumption_type_id?: consumption_type_enum | null;
        source_type_id: consumption_source_type_enum;
        energy_source_type_id?: energy_source_type_enum | null;
        sub_type_id?: consumption_sub_type_enum | null;
        created_by_user?: { first_name?: string | null; last_name?: string | null } | null;
        consumption_invoice_draft?: { id: number; draft_status: draft_status_enum } | null;
        consumption_waste_details_draft?: {
          id: number;
          volume?: number | null;
          display_unit_volume?: consumption_unit_enum | null;
          frequency?: number | null;
          display_unit_frequency?: consumption_unit_enum | null;
          amount?: number | null;
          price?: number | null;
        } | null;
      }>;
      subsidies: Array<{
        subsidy: {
          __typename: 'subsidy';
          id: number;
          email?: string | null;
          amount_description: string;
          provider_updated_at: string;
          region_type: subsidy_region_type_enum;
          title: string;
          type: subsidy_type_enum;
          url?: string | null;
          categories: Array<{ subsidy_category_type: subsidy_category_type_enum }>;
        };
      }>;
    }>;
    economic_unit: {
      __typename: 'economic_unit';
      id: number;
      name: string;
      associatedBuildingsAndDrafts: { amount: number };
      portfolio: {
        __typename: 'portfolio';
        id: number;
        name?: string | null;
        emission_factor_type_id?: emission_factor_type_enum | null;
      };
    };
    active_building_model?: {
      id: number;
      energy_final?: number | null;
      action_planning_available: boolean;
      envelope_units: Array<{
        id: number;
        data_source_type_id: data_source_type_enum;
        inclination?: number | null;
        orientation?: number | null;
        envelope_type_id: envelope_type_enum;
        insulation_thickness?: number | null;
        insulation_material_name?: string | null;
        insulation_material_category_id?: insulation_material_category_enum | null;
        insulation_method_id?: insulation_method_enum | null;
        insulation_lambda?: number | null;
        base_construction_thickness?: number | null;
        base_construction_material_name?: string | null;
        base_construction_lambda?: number | null;
        area: number;
        u_value?: number | null;
      }>;
      energy_systems: Array<{
        id: number;
        energy_system_type_id: energy_system_type_enum;
        data_source_type_id?: data_source_type_enum | null;
        energy_system_consumer_routes: Array<{
          id: number;
          energy_final: number;
          energy_effective?: number | null;
          energy_source_type_id: energy_source_type_enum;
          energy_primary?: number | null;
          emission_factor_origin_id: emission_factor_origin_enum;
          emission_certificate_id?: number | null;
          primary_energy_factor_emission_certificate_id?: number | null;
          emission_certificate?: { emission_factor?: number | null } | null;
          primary_energy_factor_emission_certificate?: { primary_energy_factor?: number | null } | null;
          energy_consumer: {
            id: number;
            energy_consumer_technology_type_id?: energy_consumer_technology_type_enum | null;
            energy_consumer_type_id: energy_consumer_type_enum;
            efficiency?: number | null;
            construction_year?: number | null;
          };
          energy_distribution?: {
            id: number;
            energy_distribution_type_id: energy_distribution_type_enum;
            heat_distribution_loss: number;
            heat_transfer_loss: number;
            last_hydraulic_balancing?: string | null;
            last_balancing_set_to_never: boolean;
            construction_year?: number | null;
          } | null;
          energy_storage?: {
            id: number;
            energy_storage_type_id: energy_storage_type_enum;
            annual_heat_loss?: number | null;
            construction_year?: number | null;
          } | null;
        }>;
        energy_system_producer_routes: Array<{
          energy_source_type_id: energy_source_type_enum;
          energy_producer: { name?: string | null };
        }>;
      }>;
      energy_paths: Array<{ efficiency_class_id?: efficiency_class_enum | null }>;
    } | null;
    climate_risks: Array<{
      value: number;
      uncertainty: number;
      type: climate_risk_type_enum;
      timespan?: string | null;
      created_at?: string | null;
    }>;
    climate_risks_aggregate: { aggregate?: { max?: { created_at?: string | null } | null } | null };
  } | null;
};

export type DataCollectionGetAllBuildingsQueryVariables = Exact<{ [key: string]: never }>;

export type DataCollectionGetAllBuildingsQuery = {
  building: Array<{
    __typename: 'building';
    year_constructed: number;
    id: number;
    building_state_id: building_state_enum;
    created_at: string;
    updated_at: string;
    address: { __typename: 'address'; id: number; street: string; city: string; postal_code: string };
    areas: Array<{ type_of_use_id: type_of_use_enum; value: number; area_type_id: area_type_enum }>;
    sub_buildings: Array<{
      __typename: 'sub_building';
      id: number;
      building: {
        year_constructed: number;
        building_state_id: building_state_enum;
        economic_unit: { portfolio: { id: number; name?: string | null } };
      };
    }>;
    economic_unit: {
      __typename: 'economic_unit';
      id: number;
      portfolio: { id: number; name?: string | null };
      associatedBuildingsAndDrafts: { amount: number };
    };
    active_building_model?: { action_planning_available: boolean; processing: boolean } | null;
  }>;
};

export type GET_ECONOMIC_UNIT_IDSQueryVariables = Exact<{
  buildingIds: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type GET_ECONOMIC_UNIT_IDSQuery = { economic_unit: Array<{ id: number }> };

export type DataCollectionGetAllBuildingsTableQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<building_bool_exp>;
  orderBy?: InputMaybe<Array<building_order_by> | building_order_by>;
}>;

export type DataCollectionGetAllBuildingsTableQuery = {
  building: Array<{
    __typename: 'building';
    year_constructed: number;
    id: number;
    building_state_id: building_state_enum;
    created_at: string;
    updated_at: string;
    address: { __typename: 'address'; id: number; street: string; city: string; postal_code: string };
    areas: Array<{ type_of_use_id: type_of_use_enum; value: number; area_type_id: area_type_enum }>;
    sub_buildings: Array<{
      __typename: 'sub_building';
      id: number;
      building: {
        year_constructed: number;
        building_state_id: building_state_enum;
        economic_unit: { portfolio: { id: number; name?: string | null } };
      };
    }>;
    economic_unit: {
      __typename: 'economic_unit';
      id: number;
      portfolio: { id: number; name?: string | null };
      associatedBuildingsAndDrafts: { amount: number };
    };
    active_building_model?: { action_planning_available: boolean; processing: boolean } | null;
  }>;
  building_aggregate: { aggregate?: { count: number } | null };
};

export type DataCollectionGetAllEmissionCertificatesDropdownQueryVariables = Exact<{ [key: string]: never }>;

export type DataCollectionGetAllEmissionCertificatesDropdownQuery = {
  emission_factor_emission_certificates: Array<{ id: number; issuer: string; emission_factor?: number | null }>;
  primary_energy_factor_emission_certificates: Array<{
    id: number;
    issuer: string;
    primary_energy_factor?: number | null;
  }>;
};

export type ReportAndActionsSubBuildingsQueryVariables = Exact<{
  buildingIds: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type ReportAndActionsSubBuildingsQuery = {
  report_sub_building: Array<{
    report: {
      id: number;
      progress: number;
      report_sub_buildings: Array<{ sub_building_id: number }>;
      report_template: { title: string };
    };
  }>;
  action_plan: Array<{ id: number; actions_aggregate: { aggregate?: { count: number } | null } }>;
};

export type DataCollectionGetConsumptionSummariesQueryVariables = Exact<{
  buildingId: Scalars['Int'];
  year: Scalars['Int'];
}>;

export type DataCollectionGetConsumptionSummariesQuery = {
  getYearlyConsumptionSummary: {
    consumptionCategories: Array<{
      value: number;
      providers: Array<string>;
      cost: number;
      consumption_type: consumption_type_enum;
    }>;
  };
};

export type DataCollectionEnergyCertificateDraftGetEnergyCertificateDraftQueryVariables = Exact<{
  energyCertificateDraftId: Scalars['Int'];
}>;

export type DataCollectionEnergyCertificateDraftGetEnergyCertificateDraftQuery = {
  energy_certificate_draft_by_pk?: {
    portfolio_id?: number | null;
    id: number;
    street?: string | null;
    postal_code?: string | null;
    city?: string | null;
    country_state_id?: country_state_enum | null;
    country_id: country_enum;
    building_state_id?: building_state_enum | null;
    economic_unit_id?: number | null;
    responsible_user_id?: number | null;
    year_constructed?: number | null;
    area_usable?: number | null;
    sub_building_class_id?: sub_building_class_enum | null;
    type_of_use_id?: type_of_use_enum | null;
    draft_status_id: draft_status_enum;
    energy_certificate_type_id?: energy_certificate_type_enum | null;
    expiry_date?: string | null;
    units?: number | null;
    monument_protection: boolean;
    leasehold: boolean;
    milieu_protection: boolean;
    heritage_district: boolean;
    file: { downloadUrl: string };
    energy_certificate_consumers: Array<{
      energy_system_type_id: energy_system_type_enum;
      energy_source_type_id: energy_source_type_enum;
      energy_final: number;
    }>;
  } | null;
  economic_unit: Array<{
    __typename: 'economic_unit';
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    customer_economic_unit_id?: string | null;
    associatedBuildingsAndDrafts: { amount: number };
    buildings: Array<{
      __typename: 'building';
      year_constructed: number;
      id: number;
      building_state_id: building_state_enum;
      created_at: string;
      updated_at: string;
      address: { __typename: 'address'; id: number; street: string; city: string; postal_code: string };
      areas: Array<{ type_of_use_id: type_of_use_enum; value: number; area_type_id: area_type_enum }>;
      sub_buildings: Array<{
        __typename: 'sub_building';
        id: number;
        building: {
          year_constructed: number;
          building_state_id: building_state_enum;
          economic_unit: { portfolio: { id: number; name?: string | null } };
        };
      }>;
      economic_unit: {
        __typename: 'economic_unit';
        id: number;
        portfolio: { id: number; name?: string | null };
        associatedBuildingsAndDrafts: { amount: number };
      };
      active_building_model?: { action_planning_available: boolean; processing: boolean } | null;
    }>;
    portfolio: { __typename: 'portfolio'; id: number; name?: string | null };
  }>;
  user: Array<{ id: number; first_name?: string | null; last_name?: string | null }>;
};

export type DataCollectionEnergyCertificateDraftsListGetAllEnergyCertificateDraftsSubscriptionSubscriptionVariables =
  Exact<{ [key: string]: never }>;

export type DataCollectionEnergyCertificateDraftsListGetAllEnergyCertificateDraftsSubscriptionSubscription = {
  energy_certificate_draft: Array<{
    id: number;
    updated_at: string;
    created_at: string;
    street?: string | null;
    city?: string | null;
    draft_status_id: draft_status_enum;
    user?: { id: number; first_name?: string | null; last_name?: string | null } | null;
    portfolio?: { name?: string | null } | null;
  }>;
};

export type DataCollectionEnergyCertificateDraftsListGetAllEnergyCertificateDraftsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type DataCollectionEnergyCertificateDraftsListGetAllEnergyCertificateDraftsQuery = {
  energy_certificate_draft: Array<{
    id: number;
    created_at: string;
    updated_at: string;
    street?: string | null;
    city?: string | null;
    draft_status_id: draft_status_enum;
    user?: { id: number; first_name?: string | null; last_name?: string | null } | null;
    file: { name?: string | null; downloadUrl: string };
    portfolio?: { name?: string | null } | null;
  }>;
  portfolio: Array<{ id: number; name?: string | null }>;
};

export type EnergyCertificateDraftStatusSubscriptionVariables = Exact<{
  energyCertificateDraftId: Scalars['Int'];
}>;

export type EnergyCertificateDraftStatusSubscription = {
  energy_certificate_draft_by_pk?: { draft_status_id: draft_status_enum; updated_at: string } | null;
};

export type DataCollectionGetConsumptionInvoiceQueryVariables = Exact<{
  consumptionInvoiceId: Scalars['Int'];
}>;

export type DataCollectionGetConsumptionInvoiceQuery = {
  consumption_invoice_by_pk?: {
    id: number;
    date: string;
    invoice_payer: invoice_payer_type_enum;
    file?: { downloadUrl: string; name?: string | null } | null;
    building: {
      id: number;
      address: { id: number; street: string; city: string; postal_code: string };
      areas: Array<{ id: number; name: string; description: string; area_type_id: area_type_enum }>;
    };
    consumptions: Array<{
      id: number;
      from: string;
      to: string;
      value?: number | null;
      display_unit_value?: consumption_unit_enum | null;
      cost?: number | null;
      consumption_type_id: consumption_type_enum;
      energy_source_type_id?: energy_source_type_enum | null;
      sub_type_id?: consumption_sub_type_enum | null;
      provider?: string | null;
      area_id: number;
      consumption_waste_detail?: {
        id: number;
        frequency: number;
        display_unit_frequency?: consumption_unit_enum | null;
        volume: number;
        display_unit_volume?: consumption_unit_enum | null;
        amount: number;
        price: number;
      } | null;
    }>;
  } | null;
};

export type DataCollectionSubBuildingConsumptionGetConsumptionsQueryVariables = Exact<{
  buildingId: Scalars['Int'];
}>;

export type DataCollectionSubBuildingConsumptionGetConsumptionsQuery = {
  consumption_invoice: Array<{ __typename: 'consumption_invoice'; id: number }>;
};

export type DataCollectionGetBuildingConsumptionPreconditionQueryVariables = Exact<{
  orgId: Scalars['Int'];
}>;

export type DataCollectionGetBuildingConsumptionPreconditionQuery = {
  building: Array<{
    id: number;
    address: { id: number; city: string; street: string; postal_code: string };
    sub_buildings: Array<{ id: number }>;
    building_models: Array<{
      id: number;
      energy_systems: Array<{ id: number; energy_system_type_id: energy_system_type_enum }>;
    }>;
    areas: Array<{ id: number; name: string; description: string; area_type_id: area_type_enum }>;
    economic_unit: { id: number; portfolio: { id: number } };
  }>;
};

export type DataCollectionGetConsumptionDraftsQueryVariables = Exact<{ [key: string]: never }>;

export type DataCollectionGetConsumptionDraftsQuery = {
  consumption_draft: Array<{
    id: number;
    from?: string | null;
    to?: string | null;
    value?: number | null;
    cost?: number | null;
    consumption_type_id?: consumption_type_enum | null;
    display_unit_value?: consumption_unit_enum | null;
    energy_source_type_id?: energy_source_type_enum | null;
    sub_type_id?: consumption_sub_type_enum | null;
    provider?: string | null;
    area_id?: number | null;
    sub_building_id: number;
    consumption_waste_details_draft?: {
      id: number;
      frequency?: number | null;
      display_unit_frequency?: consumption_unit_enum | null;
      volume?: number | null;
      display_unit_volume?: consumption_unit_enum | null;
      amount?: number | null;
      price?: number | null;
    } | null;
  }>;
};

export type DataCollectionGetConsumptionInvoiceDraftQueryVariables = Exact<{
  consumptionInvoiceDraftId: Scalars['Int'];
}>;

export type DataCollectionGetConsumptionInvoiceDraftQuery = {
  consumption_invoice_draft_by_pk?: {
    id: number;
    date?: string | null;
    draft_status: draft_status_enum;
    invoice_payer?: invoice_payer_type_enum | null;
    file?: { downloadUrl: string; name?: string | null } | null;
    building: {
      id: number;
      address: { id: number; street: string; city: string; postal_code: string };
      areas: Array<{ id: number; name: string; description: string; area_type_id: area_type_enum }>;
    };
    consumption_drafts: Array<{
      id: number;
      from?: string | null;
      to?: string | null;
      value?: number | null;
      cost?: number | null;
      consumption_type_id?: consumption_type_enum | null;
      display_unit_value?: consumption_unit_enum | null;
      energy_source_type_id?: energy_source_type_enum | null;
      sub_type_id?: consumption_sub_type_enum | null;
      provider?: string | null;
      area_id?: number | null;
      sub_building_id: number;
      consumption_waste_details_draft?: {
        id: number;
        frequency?: number | null;
        display_unit_frequency?: consumption_unit_enum | null;
        volume?: number | null;
        display_unit_volume?: consumption_unit_enum | null;
        amount?: number | null;
        price?: number | null;
      } | null;
    }>;
  } | null;
};

export type DataCollectionSubBuildingGetConsumptionDraftsQueryVariables = Exact<{
  buildingId: Scalars['Int'];
}>;

export type DataCollectionSubBuildingGetConsumptionDraftsQuery = { consumption_invoice_draft: Array<{ id: number }> };

export type getMissingEnergyCertificateFilesQueryVariables = Exact<{
  subBuildingId: Scalars['Int'];
}>;

export type getMissingEnergyCertificateFilesQuery = {
  sub_building: Array<{
    __typename: 'sub_building';
    id: number;
    energy_certificates: Array<{
      __typename: 'energy_certificate';
      id: number;
      file?: { __typename: 'file'; id: number } | null;
    }>;
  }>;
};

export type getAllEnergyCertificatesForSubBuildingQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type getAllEnergyCertificatesForSubBuildingQuery = {
  sub_building_by_pk?: {
    __typename: 'sub_building';
    id: number;
    energy_certificates: Array<{
      __typename: 'energy_certificate';
      id: number;
      file?: { __typename: 'file'; id: number; name?: string | null; created_at: string; downloadUrl: string } | null;
    }>;
    consumptions: Array<{
      id: number;
      consumption_invoice?: {
        id: number;
        file?: { __typename: 'file'; id: number; name?: string | null; created_at: string; downloadUrl: string } | null;
      } | null;
    }>;
  } | null;
};

export type getAllEnergyCertificatesForSubBuildingWithAreaQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type getAllEnergyCertificatesForSubBuildingWithAreaQuery = {
  sub_building_by_pk?: {
    __typename: 'sub_building';
    id: number;
    type_of_use_id: type_of_use_enum;
    energy_certificates: Array<{
      __typename: 'energy_certificate';
      id: number;
      area_usable?: number | null;
      file?: { __typename: 'file'; id: number; name?: string | null; created_at: string; downloadUrl: string } | null;
    }>;
  } | null;
};

export type getBuildingModelMutationAvailabilityQueryVariables = Exact<{
  buildingModelId: Scalars['Int'];
}>;

export type getBuildingModelMutationAvailabilityQuery = {
  getBuildingModelMutationAvailability: {
    action_deletion_necessary: boolean;
    affected_action_plan_count: number;
    affected_users: Array<{ id: number; name: string }>;
  };
};

export type getEmissionCertificatesQueryVariables = Exact<{ [key: string]: never }>;

export type getEmissionCertificatesQuery = {
  emission_certificate: Array<{
    __typename: 'emission_certificate';
    created_at: string;
    issuer: string;
    emission_factor?: number | null;
    primary_energy_factor?: number | null;
    file_id: number;
    org_id: number;
    id: number;
    valid_from: string;
    valid_until: string;
    file: { downloadUrl: string; id: number; name?: string | null; created_at: string };
    org: { name: string };
    energy_system_consumer_routes: Array<{ energy_system: { building_model: { building_id?: number | null } } }>;
    primary_energy_factor_energy_system_consumer_routes: Array<{
      energy_system: { building_model: { building_id?: number | null } };
    }>;
  }>;
};

export type getEmissionCertificateQueryVariables = Exact<{
  emissionCertificateId: Scalars['Int'];
}>;

export type getEmissionCertificateQuery = {
  emission_certificate_by_pk?: {
    __typename: 'emission_certificate';
    created_at: string;
    issuer: string;
    emission_factor?: number | null;
    primary_energy_factor?: number | null;
    file_id: number;
    org_id: number;
    id: number;
    valid_from: string;
    valid_until: string;
    file: { downloadUrl: string; id: number; name?: string | null; created_at: string };
    org: { name: string };
    energy_system_consumer_routes: Array<{ energy_system: { building_model: { building_id?: number | null } } }>;
    primary_energy_factor_energy_system_consumer_routes: Array<{
      energy_system: { building_model: { building_id?: number | null } };
    }>;
  } | null;
};

export type getEmissionCertificateDraftsQueryVariables = Exact<{ [key: string]: never }>;

export type getEmissionCertificateDraftsQuery = {
  emission_certificate_draft: Array<{
    __typename: 'emission_certificate_draft';
    created_at: string;
    issuer?: string | null;
    emission_factor?: number | null;
    primary_energy_factor?: number | null;
    file_id: number;
    org_id: number;
    id: number;
    valid_from?: string | null;
    valid_until?: string | null;
    file: { downloadUrl: string; id: number; name?: string | null; created_at: string };
    org: { name: string };
  }>;
};

export type getEmissionCertificateDraftQueryVariables = Exact<{
  emissionCertificateDraftId: Scalars['Int'];
}>;

export type getEmissionCertificateDraftQuery = {
  emission_certificate_draft_by_pk?: {
    __typename: 'emission_certificate_draft';
    created_at: string;
    issuer?: string | null;
    emission_factor?: number | null;
    primary_energy_factor?: number | null;
    file_id: number;
    org_id: number;
    id: number;
    valid_from?: string | null;
    valid_until?: string | null;
    file: { downloadUrl: string; id: number; name?: string | null; created_at: string };
    org: { name: string };
  } | null;
};

export type getEmissionCertificateDraftByFileQueryVariables = Exact<{
  emissionCertificateDraftFileId: Scalars['Int'];
}>;

export type getEmissionCertificateDraftByFileQuery = { emission_certificate_draft: Array<{ id: number }> };

export type getAssociatedActionPlansAndReportsFromEmissionCertificateQueryVariables = Exact<{
  emissionCertificateId: Scalars['Int'];
}>;

export type getAssociatedActionPlansAndReportsFromEmissionCertificateQuery = {
  emission_factor_buildings: Array<{
    id: number;
    economic_unit: { portfolio: { emission_factor_type_id?: emission_factor_type_enum | null } };
    action_plans_aggregate: { aggregate?: { count: number } | null };
    sub_buildings: Array<{ report_sub_buildings: Array<{ report_id: number }> }>;
  }>;
  primary_energy_factor_buildings: Array<{
    id: number;
    economic_unit: { portfolio: { emission_factor_type_id?: emission_factor_type_enum | null } };
    action_plans_aggregate: { aggregate?: { count: number } | null };
    sub_buildings: Array<{ report_sub_buildings: Array<{ report_id: number }> }>;
  }>;
};

export type ALLEnergyCertificateFilesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type ALLEnergyCertificateFilesQuery = {
  energy_certificate_draft: Array<{
    id: number;
    portfolio_id?: number | null;
    file: { id: number; downloadUrl: string; name?: string | null };
  }>;
};

export type BuildingProcessingSubscriptionSubscriptionVariables = Exact<{
  buildingModelId: Scalars['Int'];
}>;

export type BuildingProcessingSubscriptionSubscription = { building_model_by_pk?: { processing: boolean } | null };

export type SubBuildingConsumptionTabsConsumptionDraftCountQueryVariables = Exact<{
  subBuildingId: Scalars['Int'];
}>;

export type SubBuildingConsumptionTabsConsumptionDraftCountQuery = {
  consumption_draft_aggregate: { aggregate?: { count: number } | null };
};

export type hasSingleBuildingOrDraftQueryVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type hasSingleBuildingOrDraftQuery = { hasSingleBuildingOrDraft: boolean };

export type EsgAnalysisPortfolioFragment = { id: number; name?: string | null };

export type EsgAnalysisGetBuildingsFragment = {
  id: number;
  year_constructed: number;
  economic_unit: { portfolio: { id: number; name?: string | null } };
  areas: Array<{
    value: number;
    type_of_use_id: type_of_use_enum;
    class_of_use_id: sub_building_class_enum;
    area_type_id: area_type_enum;
  }>;
  address: { street: string; city: string; postal_code: string; country_id: country_enum };
  active_building_model?: {
    energy_primary?: number | null;
    energy_final?: number | null;
    energy_paths: Array<{
      co2_emissions: number;
      co2_costs: number;
      efficiency_class_id?: efficiency_class_enum | null;
      eu_taxonomy_compliance_id?: eu_taxonomy_compliance_enum | null;
    }>;
  } | null;
};

export type EsgAnalysisGetBuildingFragment = {
  id: number;
  year_constructed: number;
  address: {
    street: string;
    postal_code: string;
    city: string;
    country_id: country_enum;
    mapbox_building_ids: Array<string>;
  };
  economic_unit: { portfolio: { id: number; name?: string | null } };
  responsible_user: { first_name?: string | null; last_name?: string | null };
  active_building_model?: {
    energy_paths: Array<{
      co2_emissions: number;
      co2_costs: number;
      efficiency_class_id?: efficiency_class_enum | null;
      eu_taxonomy_compliance_id?: eu_taxonomy_compliance_enum | null;
    }>;
  } | null;
  areas: Array<{
    value: number;
    area_type_id: area_type_enum;
    type_of_use_id: type_of_use_enum;
    class_of_use_id: sub_building_class_enum;
  }>;
};

export type EsgAnalysisGetBenchmarkBuildingsFragment = {
  averagePrimaryEnergy: number;
  co2Paths: { pathM2: Array<number>; costsM2: Array<number> };
  energyPaths: { pathM2: Array<number>; costsM2: Array<number> };
  strandingDates: { co2: { onePointFive: Array<{ startYear: number; endYear: number }> } };
};

export type EsgAnalysisGetBuildingsPortfolioAndAddressFragment = {
  id: number;
  economic_unit: { portfolio: { id: number; name?: string | null } };
  address: { street: string };
};

export type EsgAnalysisGetBuildingsForSingleBuildingFragment = {
  id: number;
  year_constructed: number;
  address: {
    street: string;
    postal_code: string;
    city: string;
    country_id: country_enum;
    mapbox_building_ids: Array<string>;
  };
  economic_unit: { portfolio: { id: number; name?: string | null } };
  responsible_user: { first_name?: string | null; last_name?: string | null };
  areas: Array<{
    value: number;
    area_type_id: area_type_enum;
    type_of_use_id: type_of_use_enum;
    class_of_use_id: sub_building_class_enum;
  }>;
};

export type EsgAnalysisGetFilteredBuildingsQueryVariables = Exact<{
  buildingWhere: building_bool_exp;
  currentYear: Scalars['Int'];
}>;

export type EsgAnalysisGetFilteredBuildingsQuery = {
  building: Array<{
    id: number;
    year_constructed: number;
    economic_unit: { portfolio: { id: number; name?: string | null } };
    areas: Array<{
      value: number;
      type_of_use_id: type_of_use_enum;
      class_of_use_id: sub_building_class_enum;
      area_type_id: area_type_enum;
    }>;
    address: { street: string; city: string; postal_code: string; country_id: country_enum };
    active_building_model?: {
      energy_primary?: number | null;
      energy_final?: number | null;
      energy_paths: Array<{
        co2_emissions: number;
        co2_costs: number;
        efficiency_class_id?: efficiency_class_enum | null;
        eu_taxonomy_compliance_id?: eu_taxonomy_compliance_enum | null;
      }>;
    } | null;
  }>;
};

export type EsgAnalysisGetAllBuildingFilterValuesQueryVariables = Exact<{
  currentYear: Scalars['Int'];
}>;

export type EsgAnalysisGetAllBuildingFilterValuesQuery = {
  energy_system_consumer_route: Array<{ energy_source_type_id: energy_source_type_enum }>;
  energy_path_tax_bracket: Array<{ tax_bracket_id?: tax_bracket_enum | null }>;
  energy_path_efficiency_class: Array<{ efficiency_class_id?: efficiency_class_enum | null }>;
  energy_path_eu_taxonomy_compliance: Array<{ eu_taxonomy_compliance_id?: eu_taxonomy_compliance_enum | null }>;
  sub_building_class: Array<{ class_of_use_id: sub_building_class_enum }>;
  building_state_id: Array<{ building_state_id: building_state_enum }>;
  monument_protection: Array<{ monument_protection: boolean }>;
  heritage_district: Array<{ heritage_district: boolean }>;
  milieu_protection: Array<{ milieu_protection: boolean }>;
  leasehold: Array<{ leasehold: boolean }>;
};

export type EsgAnalysisGetAllBuildingListQueryVariables = Exact<{ [key: string]: never }>;

export type EsgAnalysisGetAllBuildingListQuery = {
  building: Array<{
    id: number;
    economic_unit: { portfolio: { id: number; name?: string | null } };
    address: { street: string };
  }>;
};

export type EsgAnalysisGetAllBuildingsQueryVariables = Exact<{ [key: string]: never }>;

export type EsgAnalysisGetAllBuildingsQuery = {
  building: Array<{
    id: number;
    year_constructed: number;
    address: {
      street: string;
      postal_code: string;
      city: string;
      country_id: country_enum;
      mapbox_building_ids: Array<string>;
    };
    economic_unit: { portfolio: { id: number; name?: string | null } };
    responsible_user: { first_name?: string | null; last_name?: string | null };
    areas: Array<{
      value: number;
      area_type_id: area_type_enum;
      type_of_use_id: type_of_use_enum;
      class_of_use_id: sub_building_class_enum;
    }>;
  }>;
};

export type EsgAnalysisGetBenchmarkQueryVariables = Exact<{
  portfolioId: Scalars['Int'];
}>;

export type EsgAnalysisGetBenchmarkQuery = {
  getBenchmark: {
    averagePrimaryEnergy: number;
    co2Paths: { pathM2: Array<number>; costsM2: Array<number> };
    energyPaths: { pathM2: Array<number>; costsM2: Array<number> };
    strandingDates: { co2: { onePointFive: Array<{ startYear: number; endYear: number }> } };
  };
};

export type EsgAnalysisGetPortfolioQueryVariables = Exact<{
  buildingWhere: BuildingWhere;
}>;

export type EsgAnalysisGetPortfolioQuery = {
  getAnalyzedBuildings: {
    analyzedBuildingsCount: number;
    averageEfficiencyClass: efficiency_class_enum;
    averagePrimaryEnergy: number;
    buildingsByEfficiencyClass: Array<{ count: number; efficiencyClass: efficiency_class_enum }>;
    buildingsByTaxBracket: Array<{
      count: number;
      taxBracket: { taxBracket: tax_bracket_enum; landlordProportion: number };
    }>;
    buildingsByEUTaxonomyCompliance: Array<{ euTaxonomyCompliance: eu_taxonomy_compliance_enum; count: number }>;
    averageTaxBracket: { landlordProportion: number };
    climateRisks: Array<{ type: climate_risk_type_enum; timespan?: string | null; value: number }>;
    co2Paths: { pathM2: Array<number>; costsM2: Array<number>; costsTotal: Array<number>; pathTotal: Array<number> };
    co2Targets: { onePointFive: Array<number> };
    energyDataBySourceType: Array<{
      energySourceType: energy_source_type_enum;
      energyConsumptionTotal: number;
      energyConsumptionM2: number;
      co2EmissionsTotal: number;
      co2EmissionsM2: number;
    }>;
    energyPaths: { pathM2: Array<number>; pathTotal: Array<number>; costsM2: Array<number>; costsTotal: Array<number> };
    energyTargets: { onePointFive: Array<number> };
    strandingDates: { co2: { onePointFive: Array<{ startYear: number; endYear: number }> } };
    floorAreaBySubBuildingClass: Array<{ subBuildingClass: sub_building_class_enum; area: number }>;
    scope123Co2Emissions: Array<{
      year: string;
      data: {
        scope1: { co2Emissions: number; co2EmissionsTotal: number };
        scope2: { co2Emissions: number; co2EmissionsTotal: number };
        scope3: { co2Emissions: number; co2EmissionsTotal: number };
      };
    }>;
    buildingsByEnergySourceType: Array<{ count: number; energySourceType: energy_source_type_enum }>;
    buildingsGeneralData: Array<{
      area: number;
      address: string;
      buildingId: number;
      co2Intensity: number;
      typeOfUse: Array<type_of_use_enum>;
      finalEnergy: number;
      year: number;
      mainEnergySourceType: energy_source_type_enum;
    }>;
  };
};

export type EsgAnalysisBuildingGetBuildingQueryVariables = Exact<{
  buildingId: Scalars['Int'];
  year: Scalars['Int'];
}>;

export type EsgAnalysisBuildingGetBuildingQuery = {
  getAnalyzedBuilding: {
    efficiencyClass: efficiency_class_enum;
    euTaxonomyCompliance: eu_taxonomy_compliance_enum;
    primaryEnergy: number;
    co2Targets: { onePointFive: Array<number> };
    energyTargets: { onePointFive: Array<number> };
    co2Paths: { pathM2: Array<number>; pathTotal: Array<number>; costsM2: Array<number>; costsTotal: Array<number> };
    energyPaths: { pathM2: Array<number>; pathTotal: Array<number>; costsM2: Array<number>; costsTotal: Array<number> };
    co2StrandingDates: { onePointFive: Array<{ startYear: number; endYear: number }> };
    energyConsumers: Array<{
      energySourceType: energy_source_type_enum;
      co2EmissionsM2: number;
      co2EmissionsTotal: number;
      energyConsumptionM2: number;
      energyConsumptionTotal: number;
    }>;
    taxBracket: { landlordProportion: number };
    floorAreaBySubBuildingClass: Array<{ subBuildingClass: sub_building_class_enum; area: number }>;
    climateRisks: Array<{ timespan?: string | null; type: climate_risk_type_enum; value: number }>;
    scope123Co2Emissions: {
      scope1: { co2Emissions: number; co2EmissionsTotal: number };
      scope2: { co2Emissions: number; co2EmissionsTotal: number };
      scope3: { co2Emissions: number; co2EmissionsTotal: number };
    };
  };
};

export type EsgAnalysisExportPdfQueryVariables = Exact<{
  buildingId: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
}>;

export type EsgAnalysisExportPdfQuery = { getEsgAnalysisPdf: { body: string } };

export type EsgAnalysisConsumptionAnalysisQueryVariables = Exact<{
  buildingId: Scalars['Int'];
  from: Scalars['Float'];
  to: Scalars['Float'];
}>;

export type EsgAnalysisConsumptionAnalysisQuery = {
  getConsumptionAnalysis: {
    data: Array<{
      average: number;
      type: consumption_type_enum;
      series: Array<{ count: number; from: number; to: number; sum: number }>;
    }>;
  };
};

export type EsgAnalysisConsumptionAllocationQueryVariables = Exact<{
  buildingId: Scalars['Int'];
  from: Scalars['Float'];
  to: Scalars['Float'];
}>;

export type EsgAnalysisConsumptionAllocationQuery = {
  getConsumptionAllocation: {
    carbonEmissions: {
      intensity: number;
      intensityAbsolute: number;
      scope1: { percentage: number; value: number };
      scope2: { percentage: number; value: number };
    };
    co2Tax: {
      costShare: number;
      total: number;
      totalAbsolute: number;
      forecastItems: Array<{
        landlordShare: number;
        landlordSharePercentage: number;
        tenantShare: number;
        tenantSharePercentage: number;
        year: number;
      }>;
    };
    energyConsumptions: Array<{
      total: number;
      totalAbsolute: number;
      type: energy_consumption_type_enum;
      items: Array<{ percentage: number; type: string; value: number; valueAbsolute: number }>;
    }>;
  };
};

export type GetPortfolioAndBuildingPermissionsQueryVariables = Exact<{
  user_id: Scalars['Int'];
}>;

export type GetPortfolioAndBuildingPermissionsQuery = {
  user_portfolio_permission: Array<{ portfolio_id: number; read: boolean; write: boolean }>;
  user_building_permission: Array<{ building_id: number; read: boolean; write: boolean }>;
  building: Array<{ id: number; economic_unit: { portfolio_id: number } }>;
};

export type PortfolioManagementCreatePortfolioMutationVariables = Exact<{
  name: Scalars['String'];
  emissionFactorType?: InputMaybe<emission_factor_type_enum>;
}>;

export type PortfolioManagementCreatePortfolioMutation = { insert_portfolio_one?: { id: number } | null };

export type PortfolioManagementDeletePortfolioMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type PortfolioManagementDeletePortfolioMutation = {
  delete_portfolio_by_pk?: { __typename: 'portfolio'; id: number } | null;
};

export type PortfolioManagementUpdatePortfolioMutationVariables = Exact<{
  name: Scalars['String'];
  emissionFactorType?: InputMaybe<emission_factor_type_enum>;
  id: Scalars['Int'];
}>;

export type PortfolioManagementUpdatePortfolioMutation = { update_portfolio_by_pk?: { id: number } | null };

export type PortfolioManagementPortfolioFragment = {
  __typename: 'portfolio';
  id: number;
  name?: string | null;
  emission_factor_type_id?: emission_factor_type_enum | null;
  created_at?: string | null;
  updated_at?: string | null;
  economic_units_with_buildings: { aggregate?: { count: number } | null };
};

export type PortfolioManagementGetAllPortfoliosQueryVariables = Exact<{ [key: string]: never }>;

export type PortfolioManagementGetAllPortfoliosQuery = {
  portfolio: Array<{
    __typename: 'portfolio';
    id: number;
    name?: string | null;
    emission_factor_type_id?: emission_factor_type_enum | null;
    created_at?: string | null;
    updated_at?: string | null;
    economic_units_with_buildings: { aggregate?: { count: number } | null };
  }>;
};

export type PortfolioManagementGetAllPortfoliosWithWritePermissionsQueryVariables = Exact<{
  user_id: Scalars['Int'];
}>;

export type PortfolioManagementGetAllPortfoliosWithWritePermissionsQuery = {
  portfolio: Array<{
    __typename: 'portfolio';
    id: number;
    name?: string | null;
    emission_factor_type_id?: emission_factor_type_enum | null;
    created_at?: string | null;
    updated_at?: string | null;
    economic_units_with_buildings: { aggregate?: { count: number } | null };
  }>;
};

export type PortfolioObjectsAggregateQueryVariables = Exact<{
  portfolioId: Scalars['Int'];
}>;

export type PortfolioObjectsAggregateQuery = {
  building_aggregate: { aggregate?: { count: number } | null };
  report_aggregate: { aggregate?: { count: number } | null };
  scenario_aggregate: { aggregate?: { count: number } | null };
  energy_certificate_draft_aggregate: { aggregate?: { count: number } | null };
  economic_unit_aggregate: { aggregate?: { count: number } | null };
  action_plan_aggregate: { aggregate?: { count: number } | null };
  action_aggregate: { aggregate?: { count: number } | null };
};

export type ReportDeleteReportMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ReportDeleteReportMutation = { delete_report_by_pk?: { __typename: 'report'; id: number } | null };

export type CreateReportMutationVariables = Exact<{
  sub_buildings: Array<Scalars['Int']> | Scalars['Int'];
  due_date: Scalars['DateTime'];
  end_date: Scalars['DateTime'];
  report_template_id: Scalars['Int'];
  responsible_user_id?: InputMaybe<Scalars['Int']>;
  start_date: Scalars['DateTime'];
}>;

export type CreateReportMutation = { createReport: { report_id: number } };

export type ResetReportAnswerMutationVariables = Exact<{
  reportId: Scalars['Int'];
  subBuildingId?: InputMaybe<Scalars['Int']>;
  rootQuestionId: Scalars['Int'];
}>;

export type ResetReportAnswerMutation = { resetAnswers: { id: number } };

export type completeReportMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type completeReportMutation = {
  update_report_by_pk?: { __typename: 'report'; id: number; completed: boolean } | null;
};

export type AcceptAutofillReportMutationVariables = Exact<{
  reportId: Scalars['Int'];
}>;

export type AcceptAutofillReportMutation = { acceptAutofillReport: { report_question_ids: Array<{ id: number }> } };

export type AnswerQuestionsMutationVariables = Exact<{
  answers: Array<AnswerParamsInput> | AnswerParamsInput;
  report_id: Scalars['Int'];
  sub_building_id?: InputMaybe<Scalars['Int']>;
}>;

export type AnswerQuestionsMutation = {
  answerQuestions: {
    report_answer_ids: Array<{ ReportAnswerId?: { __typename: 'report_answer'; id: number } | null }>;
  };
};

export type createEvidenceFromFileMutationVariables = Exact<{
  reportId: Scalars['Int'];
}>;

export type createEvidenceFromFileMutation = {
  createEvidenceFromFile: {
    upload_url: string;
    CreateEvidenceOutput: Array<{ __typename: 'report_evidence'; id: number; file?: { id: number } | null }>;
  };
};

export type createEvidenceMutationVariables = Exact<{
  reportId: Scalars['Int'];
  url: Scalars['String'];
}>;

export type createEvidenceMutation = {
  insert_report_evidence_one?: { __typename: 'report_evidence'; id: number; url?: string | null } | null;
};

export type deleteEvidenceMutationVariables = Exact<{
  evidenceId: Scalars['Int'];
}>;

export type deleteEvidenceMutation = {
  delete_report_evidence_by_pk?: { __typename: 'report_evidence'; id: number } | null;
};

export type ExportReportAsPdfMutationVariables = Exact<{
  reportId: Scalars['Int'];
}>;

export type ExportReportAsPdfMutation = { exportReportAsPdf: { body: string } };

export type ExportReportAsExcelMutationVariables = Exact<{
  reportId: Scalars['Int'];
}>;

export type ExportReportAsExcelMutation = { exportReportAsExcel: { body: string } };

export type ReportGetReportByPkReportTemplateSectionsFragment = {
  __typename: 'report_template_section';
  id: number;
  name: string;
  order: number;
  parent_section_id?: number | null;
  target?: report_template_section_target_enum | null;
};

export type ReportGetReportByPkReportTemplateFragment = {
  single_sub_building: boolean;
  title: string;
  excel_template_path?: string | null;
  report_sections: Array<{
    __typename: 'report_template_section';
    id: number;
    name: string;
    order: number;
    parent_section_id?: number | null;
    target?: report_template_section_target_enum | null;
  }>;
};

export type ReportGetReportByPkResponsibleUserFragment = {
  __typename: 'user';
  id: number;
  last_name?: string | null;
  first_name?: string | null;
};

export type ReportGetReportByPkSubBuildingFragment = {
  __typename: 'sub_building';
  id: number;
  building: { address: { street: string }; economic_unit: { portfolio: { id: number; name?: string | null } } };
};

export type ReportGetReportByPkFragment = {
  __typename: 'report';
  id: number;
  end_date: string;
  start_date: string;
  due_date: string;
  created_at: string;
  completed: boolean;
  report_template: {
    single_sub_building: boolean;
    title: string;
    excel_template_path?: string | null;
    report_sections: Array<{
      __typename: 'report_template_section';
      id: number;
      name: string;
      order: number;
      parent_section_id?: number | null;
      target?: report_template_section_target_enum | null;
    }>;
  };
  responsible_user?: { __typename: 'user'; id: number; last_name?: string | null; first_name?: string | null } | null;
  report_sub_buildings: Array<{
    sub_building: {
      __typename: 'sub_building';
      id: number;
      building: { address: { street: string }; economic_unit: { portfolio: { id: number; name?: string | null } } };
    };
  }>;
  report_sub_buildings_aggregate: { aggregate?: { count: number } | null };
  report_answers: Array<{
    __typename: 'report_answer';
    id: number;
    report_template_question: { evidence_required?: boolean | null };
  }>;
};

export type ReportSectionGetRootQuestionsAndAnswersAnswersFragment = {
  __typename: 'report_answer';
  id: number;
  report_question_id: number;
  value?: string | null;
  state: report_answer_state_enum;
  autofill?: string | null;
  report_template_question: {
    __typename: 'report_template_question';
    id: number;
    question: string;
    type: report_template_question_type_enum;
    evidence_required?: boolean | null;
  };
  report_answer_autofill_options: Array<{
    report_template_question_option: { __typename: 'report_template_question_option'; id: number; value: string };
  }>;
  report_answer_chosen_options: Array<{
    updated_at: string;
    report_template_question_option: { id: number; value: string };
  }>;
};

export type CreateReportModalGetAllTemplatesTemplateFragment = {
  __typename: 'ReportTemplateOutput';
  id: number;
  description: string;
  org_id?: number | null;
  title: string;
  single_sub_building: boolean;
  version: string;
};

export type ReportGetReportsSubBuildingsFragment = {
  sub_building: {
    __typename: 'sub_building';
    id: number;
    building: { address: { street: string; city: string; postal_code: string } };
  };
};

export type ReportGetReportQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ReportGetReportQuery = {
  report_by_pk?: {
    __typename: 'report';
    id: number;
    end_date: string;
    start_date: string;
    due_date: string;
    created_at: string;
    completed: boolean;
    report_template: {
      single_sub_building: boolean;
      title: string;
      excel_template_path?: string | null;
      report_sections: Array<{
        __typename: 'report_template_section';
        id: number;
        name: string;
        order: number;
        parent_section_id?: number | null;
        target?: report_template_section_target_enum | null;
      }>;
    };
    responsible_user?: { __typename: 'user'; id: number; last_name?: string | null; first_name?: string | null } | null;
    report_sub_buildings: Array<{
      sub_building: {
        __typename: 'sub_building';
        id: number;
        building: { address: { street: string }; economic_unit: { portfolio: { id: number; name?: string | null } } };
      };
    }>;
    report_sub_buildings_aggregate: { aggregate?: { count: number } | null };
    report_answers: Array<{
      __typename: 'report_answer';
      id: number;
      report_template_question: { evidence_required?: boolean | null };
    }>;
  } | null;
};

export type ReportingReportsQueryVariables = Exact<{ [key: string]: never }>;

export type ReportingReportsQuery = {
  report: Array<{
    __typename: 'report';
    id: number;
    progress: number;
    completed: boolean;
    created_at: string;
    report_template: { __typename: 'report_template'; id: number; title: string; excel_template_path?: string | null };
    responsible_user?: { __typename: 'user'; id: number; first_name?: string | null; last_name?: string | null } | null;
    report_sub_buildings: Array<{
      sub_building: {
        __typename: 'sub_building';
        id: number;
        building: { address: { street: string; city: string; postal_code: string } };
      };
    }>;
  }>;
};

export type CreateReportGetAllTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type CreateReportGetAllTemplatesQuery = {
  report_template: Array<{
    __typename: 'ReportTemplateOutput';
    id: number;
    description: string;
    org_id?: number | null;
    title: string;
    single_sub_building: boolean;
    version: string;
  } | null>;
};

export type CreateReportModalAllUsersQueryVariables = Exact<{ [key: string]: never }>;

export type CreateReportModalAllUsersQuery = {
  user: Array<{ __typename: 'user'; id: number; last_name?: string | null; first_name?: string | null }>;
};

export type CreateReportModalSubBuildingsInPortfolioQueryVariables = Exact<{
  portfolio_id: Scalars['Int'];
}>;

export type CreateReportModalSubBuildingsInPortfolioQuery = {
  sub_building: Array<{
    __typename: 'sub_building';
    id: number;
    building: {
      address: { street: string; city: string; postal_code: string };
      areas: Array<{
        id: number;
        area_type_id: area_type_enum;
        class_of_use_id: sub_building_class_enum;
        type_of_use_id: type_of_use_enum;
      }>;
    };
  }>;
};

export type ReportAnswerForAutofillFragment = {
  sub_building_id?: number | null;
  autofill?: string | null;
  report_question_id: number;
  report_answer_autofill_options: Array<{ report_template_question_option: { id: number } }>;
};

export type AllReportAutofillQuestionsQueryVariables = Exact<{
  reportId: Scalars['Int'];
}>;

export type AllReportAutofillQuestionsQuery = {
  report_by_pk?: {
    report_answers: Array<{
      sub_building_id?: number | null;
      autofill?: string | null;
      report_question_id: number;
      report_answer_autofill_options: Array<{ report_template_question_option: { id: number } }>;
    }>;
  } | null;
};

export type GetReportCompletionQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetReportCompletionQuery = {
  report_by_pk?: { __typename: 'report'; id: number; progress: number; completed: boolean } | null;
};

export type ReportAnswersStateFragment = {
  id: number;
  sub_building_id?: number | null;
  state: report_answer_state_enum;
  report_template_question: { report_template_section?: { id: number } | null };
};

export type GetSectionsAnswerCountQueryVariables = Exact<{
  reportId: Scalars['Int'];
}>;

export type GetSectionsAnswerCountQuery = {
  report_answer: Array<{
    id: number;
    sub_building_id?: number | null;
    state: report_answer_state_enum;
    report_template_question: { report_template_section?: { id: number } | null };
  }>;
};

export type ReportSectionsProgressFragment = {
  id: number;
  report_template_section_id: number;
  sub_building_id?: number | null;
  progress: number;
};

export type GetSectionsProgressQueryVariables = Exact<{
  reportId: Scalars['Int'];
}>;

export type GetSectionsProgressQuery = {
  report_section_progress: Array<{
    id: number;
    report_template_section_id: number;
    sub_building_id?: number | null;
    progress: number;
  }>;
};

export type ReportQuestionGetOptionsWithSubquestionsQueryVariables = Exact<{
  questionId: Scalars['Int'];
}>;

export type ReportQuestionGetOptionsWithSubquestionsQuery = {
  report_template_question_by_pk?: {
    id: number;
    report_template_question_options: Array<{
      __typename: 'report_template_question_option';
      id: number;
      order: number;
      value: string;
      report_template_child_questions: Array<{
        __typename: 'report_template_question';
        id: number;
        type: report_template_question_type_enum;
        question: string;
        order: number;
        parent_option_id?: number | null;
      }>;
    }>;
  } | null;
};

export type SubsectionProgressFragment = {
  __typename: 'report_section_progress';
  progress: number;
  report_template_section: { id: number; target?: report_template_section_target_enum | null };
};

export type SubsectionsProgressQueryVariables = Exact<{
  reportId: Scalars['Int'];
}>;

export type SubsectionsProgressQuery = {
  report_by_pk?: {
    __typename: 'report';
    id: number;
    report_sub_buildings: Array<{
      sub_building_id: number;
      report_sections_progress: Array<{
        __typename: 'report_section_progress';
        progress: number;
        report_template_section: { id: number; target?: report_template_section_target_enum | null };
      }>;
    }>;
    report_sections_progress: Array<{
      __typename: 'report_section_progress';
      progress: number;
      report_template_section: { id: number; target?: report_template_section_target_enum | null };
    }>;
  } | null;
};

export type ReportSectionGetRootQuestionsAndAnswersQueryVariables = Exact<{
  sectionId: Scalars['Int'];
  reportId: Scalars['Int'];
  subBuildingWhere?: InputMaybe<Int_comparison_exp>;
}>;

export type ReportSectionGetRootQuestionsAndAnswersQuery = {
  report_by_pk?: {
    report_answers: Array<{
      __typename: 'report_answer';
      id: number;
      report_question_id: number;
      value?: string | null;
      state: report_answer_state_enum;
      autofill?: string | null;
      report_template_question: {
        __typename: 'report_template_question';
        id: number;
        question: string;
        type: report_template_question_type_enum;
        evidence_required?: boolean | null;
      };
      report_answer_autofill_options: Array<{
        report_template_question_option: { __typename: 'report_template_question_option'; id: number; value: string };
      }>;
      report_answer_chosen_options: Array<{
        updated_at: string;
        report_template_question_option: { id: number; value: string };
      }>;
    }>;
  } | null;
};

export type ReportSectionGetRootQuestionAndAnswerQueryVariables = Exact<{
  questionId: Scalars['Int'];
  reportId: Scalars['Int'];
}>;

export type ReportSectionGetRootQuestionAndAnswerQuery = {
  report_template_question_by_pk?: {
    report_answers: Array<{
      __typename: 'report_answer';
      id: number;
      report_question_id: number;
      value?: string | null;
      state: report_answer_state_enum;
      autofill?: string | null;
      report_template_question: {
        __typename: 'report_template_question';
        id: number;
        question: string;
        type: report_template_question_type_enum;
        evidence_required?: boolean | null;
      };
      report_answer_autofill_options: Array<{
        report_template_question_option: { __typename: 'report_template_question_option'; id: number; value: string };
      }>;
      report_answer_chosen_options: Array<{
        updated_at: string;
        report_template_question_option: { id: number; value: string };
      }>;
    }>;
  } | null;
};

export type GET_NESTED_QUESTION_ANSWERQueryVariables = Exact<{
  reportId: Scalars['Int'];
  questionId: Scalars['Int'];
}>;

export type GET_NESTED_QUESTION_ANSWERQuery = {
  report_by_pk?: {
    report_answers: Array<{
      __typename: 'report_answer';
      id: number;
      report_question_id: number;
      value?: string | null;
      state: report_answer_state_enum;
      autofill?: string | null;
      report_template_question: {
        __typename: 'report_template_question';
        id: number;
        question: string;
        type: report_template_question_type_enum;
        evidence_required?: boolean | null;
      };
      report_answer_autofill_options: Array<{
        report_template_question_option: { __typename: 'report_template_question_option'; id: number; value: string };
      }>;
      report_answer_chosen_options: Array<{
        updated_at: string;
        report_template_question_option: { id: number; value: string };
      }>;
    }>;
  } | null;
};

export type ReportHeadGetReportScoresSubscriptionVariables = Exact<{
  reportId: Scalars['Int'];
}>;

export type ReportHeadGetReportScoresSubscription = {
  report_by_pk?: {
    report_template: {
      report_template_scores: Array<{
        __typename: 'report_template_score';
        id: number;
        name: string;
        display_type: report_template_score_display_type_enum;
        max_score?: number | null;
        report_scores: Array<{ value: number }>;
      }>;
    };
  } | null;
};

export type ReportHeadGetReportProgressQueryVariables = Exact<{
  reportId: Scalars['Int'];
}>;

export type ReportHeadGetReportProgressQuery = {
  report_by_pk?: {
    __typename: 'report';
    id: number;
    progress: number;
    report_sections_progress: Array<{ progress: number; report_template_section: { id: number; name: string } }>;
  } | null;
};

export type GET_ALL_EVIDENCESQueryVariables = Exact<{
  reportId: Scalars['Int'];
}>;

export type GET_ALL_EVIDENCESQuery = {
  report_evidence: Array<{
    __typename: 'report_evidence';
    id: number;
    url?: string | null;
    file?: { __typename: 'file'; id: number; name?: string | null; downloadUrl: string } | null;
  }>;
};

export type GET_QUESTION_EVIDENCESQueryVariables = Exact<{
  reportId: Scalars['Int'];
  questionId: Scalars['Int'];
}>;

export type GET_QUESTION_EVIDENCESQuery = {
  report_question_evidence: Array<{
    __typename: 'report_question_evidence';
    id: number;
    description?: string | null;
    report_evidence: {
      __typename: 'report_evidence';
      id: number;
      url?: string | null;
      file?: { id: number; name?: string | null; downloadUrl: string } | null;
    };
  }>;
};

export type GET_All_EVIDENCESQueryVariables = Exact<{
  reportId: Scalars['Int'];
}>;

export type GET_All_EVIDENCESQuery = {
  report_evidence: Array<{
    __typename: 'report_evidence';
    id: number;
    url?: string | null;
    file?: { id: number; name?: string | null; downloadUrl: string } | null;
    report_question_evidences: Array<{ id: number; report_template_question: { id: number; question: string } }>;
  }>;
};

export type CreateUserMutationVariables = Exact<{
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  role: role_enum;
}>;

export type CreateUserMutation = { createUser: { user_id?: number | null } };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['Int'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
}>;

export type UpdateUserMutation = { update_user_by_pk?: { created_at: string } | null };

export type UpdateUserRoleMutationVariables = Exact<{
  user_id: Scalars['Int'];
  role?: InputMaybe<role_enum>;
}>;

export type UpdateUserRoleMutation = {
  update_user_role?: { affected_rows: number; returning: Array<{ user_id: number }> } | null;
};

export type ChangeUserLanguageMutationVariables = Exact<{
  id: Scalars['Int'];
  language: language_enum;
}>;

export type ChangeUserLanguageMutation = { update_user_by_pk?: { id: number; language: language_enum } | null };

export type DeleteUserAndtransferOwnershipMutationVariables = Exact<{
  userId: Scalars['Int'];
  transferOwnershipToUserId?: InputMaybe<Scalars['Int']>;
}>;

export type DeleteUserAndtransferOwnershipMutation = {
  deleteUser: { UserId?: { __typename: 'user'; id: number } | null };
};

export type UpsertBuildingPermissionsMutationVariables = Exact<{
  objects?: InputMaybe<Array<user_building_permission_insert_input> | user_building_permission_insert_input>;
}>;

export type UpsertBuildingPermissionsMutation = { insert_user_building_permission?: { affected_rows: number } | null };

export type UpsertPortfolioPermissionsMutationVariables = Exact<{
  objects?: InputMaybe<Array<user_portfolio_permission_insert_input> | user_portfolio_permission_insert_input>;
}>;

export type UpsertPortfolioPermissionsMutation = {
  insert_user_portfolio_permission?: { affected_rows: number } | null;
};

export type GetSessionDataOrgFragment = { id: number; name: string };

export type ServerSideFeatureFlagsFragment = {
  BUILDING_CREATION_CONSUMER_EXTENSION: boolean;
  CONFIGMANAGER_EMISSIONFACTORTYPE_DISABLED: boolean;
  AREA_MANAGEMENT: boolean;
  CONSUMPTION_ANALYSIS: boolean;
};

export type GetSessionDataUserFragment = {
  id: number;
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  created_at: string;
  language: language_enum;
  org: { id: number; name: string };
  user_role: Array<{ role: role_enum }>;
  featureFlags: {
    BUILDING_CREATION_CONSUMER_EXTENSION: boolean;
    CONFIGMANAGER_EMISSIONFACTORTYPE_DISABLED: boolean;
    AREA_MANAGEMENT: boolean;
    CONSUMPTION_ANALYSIS: boolean;
  };
};

export type GetSessionDataQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;

export type GetSessionDataQuery = {
  user_by_pk?: {
    id: number;
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    created_at: string;
    language: language_enum;
    org: { id: number; name: string };
    user_role: Array<{ role: role_enum }>;
    featureFlags: {
      BUILDING_CREATION_CONSUMER_EXTENSION: boolean;
      CONFIGMANAGER_EMISSIONFACTORTYPE_DISABLED: boolean;
      AREA_MANAGEMENT: boolean;
      CONSUMPTION_ANALYSIS: boolean;
    };
  } | null;
};

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllUsersQuery = {
  user: Array<{
    id: number;
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    created_at: string;
    language: language_enum;
    org: { id: number; name: string };
    user_role: Array<{ role: role_enum }>;
    featureFlags: {
      BUILDING_CREATION_CONSUMER_EXTENSION: boolean;
      CONFIGMANAGER_EMISSIONFACTORTYPE_DISABLED: boolean;
      AREA_MANAGEMENT: boolean;
      CONSUMPTION_ANALYSIS: boolean;
    };
  }>;
};

export type GetUserByIdQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;

export type GetUserByIdQuery = {
  user_by_pk?: {
    id: number;
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    created_at: string;
    language: language_enum;
    org: { id: number; name: string };
    user_role: Array<{ role: role_enum }>;
    featureFlags: {
      BUILDING_CREATION_CONSUMER_EXTENSION: boolean;
      CONFIGMANAGER_EMISSIONFACTORTYPE_DISABLED: boolean;
      AREA_MANAGEMENT: boolean;
      CONSUMPTION_ANALYSIS: boolean;
    };
  } | null;
};

export type GetAllUserObjectsAggregateQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;

export type GetAllUserObjectsAggregateQuery = {
  report_aggregate: { aggregate?: { count: number } | null };
  scenario_aggregate: { aggregate?: { count: number } | null };
  building_aggregate: { aggregate?: { count: number } | null };
  energy_certificate_draft_aggregate: { aggregate?: { count: number } | null };
  action_plan_aggregate: { aggregate?: { count: number } | null };
  action_aggregate: { aggregate?: { count: number } | null };
  consumption_aggregate: { aggregate?: { count: number } | null };
  consumption_draft_aggregate: { aggregate?: { count: number } | null };
};

export type GetNotificationsQueryVariables = Exact<{
  user_id: Scalars['Int'];
}>;

export type GetNotificationsQuery = {
  notification: Array<{
    __typename: 'notification';
    id: number;
    read: boolean;
    created_at: string;
    notification_template: {
      __typename: 'notification_template';
      id: number;
      title: string;
      description: string;
      href?: string | null;
    };
  }>;
};

export type UpdateOrgePreferenceForEconomicUnitMutationVariables = Exact<{
  orgId: Scalars['Int'];
  economicUnitEnabled: Scalars['Boolean'];
}>;

export type UpdateOrgePreferenceForEconomicUnitMutation = {
  update_org_preference?: {
    returning: Array<{ id: number; emission_factor_type_id: emission_factor_type_enum }>;
  } | null;
};

export type UpdateOrgPreferenceMutationVariables = Exact<{
  orgId?: InputMaybe<Scalars['Int']>;
  emissionFactorTypeId?: InputMaybe<emission_factor_type_enum>;
}>;

export type UpdateOrgPreferenceMutation = {
  update_org_preference?: {
    returning: Array<{ id: number; emission_factor_type_id: emission_factor_type_enum }>;
  } | null;
};

export type AsyncJobFragment = {
  description?: string | null;
  title: string;
  entity_id?: number | null;
  status_id: async_job_status_type_enum;
  user_id?: number | null;
  org_id?: number | null;
};

export type AccessRightsPortfolioFragment = { created_at?: string | null; id: number; name?: string | null };

export type AccessRightsBuildingFragment = {
  created_at: string;
  id: number;
  address: { city: string; country_id: country_enum; house_number: string; postal_code: string; street: string };
  areas: Array<{ __typename: 'area'; value: number; type_of_use_id: type_of_use_enum; area_type_id: area_type_enum }>;
  economic_unit: { portfolio: { name?: string | null; id: number } };
};

export type GetAllPortfoliosAndBuildingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPortfoliosAndBuildingsQuery = {
  portfolio: Array<{ created_at?: string | null; id: number; name?: string | null }>;
  building: Array<{
    created_at: string;
    id: number;
    address: { city: string; country_id: country_enum; house_number: string; postal_code: string; street: string };
    areas: Array<{ __typename: 'area'; value: number; type_of_use_id: type_of_use_enum; area_type_id: area_type_enum }>;
    economic_unit: { portfolio: { name?: string | null; id: number } };
  }>;
};

export type GetOrgPreferencesQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrgPreferencesQuery = {
  org: Array<{
    __typename: 'org';
    org_preferences?: {
      __typename: 'org_preference';
      economic_unit_enabled: boolean;
      emission_factor_type_id: emission_factor_type_enum;
    } | null;
  }>;
};

export type AsyncJobSubscriptionOrgSubscriptionVariables = Exact<{
  title: Scalars['String'];
  orgId?: InputMaybe<Scalars['Int']>;
}>;

export type AsyncJobSubscriptionOrgSubscription = {
  async_job: Array<{
    description?: string | null;
    title: string;
    entity_id?: number | null;
    status_id: async_job_status_type_enum;
    user_id?: number | null;
    org_id?: number | null;
  }>;
};

export type AsyncJobSubscriptionEntityOrgSubscriptionVariables = Exact<{
  title: Scalars['String'];
  orgId?: InputMaybe<Scalars['Int']>;
  entityId?: InputMaybe<Scalars['Int']>;
}>;

export type AsyncJobSubscriptionEntityOrgSubscription = {
  async_job: Array<{
    description?: string | null;
    title: string;
    entity_id?: number | null;
    status_id: async_job_status_type_enum;
    user_id?: number | null;
    org_id?: number | null;
  }>;
};

export type AsyncJobSubscriptionSubscriptionVariables = Exact<{
  title: Scalars['String'];
  entityId?: InputMaybe<Scalars['Int']>;
}>;

export type AsyncJobSubscriptionSubscription = {
  async_job: Array<{
    description?: string | null;
    title: string;
    entity_id?: number | null;
    status_id: async_job_status_type_enum;
    user_id?: number | null;
    org_id?: number | null;
  }>;
};

export type UpdateFileNameMutationVariables = Exact<{
  fileId: Scalars['Int'];
  fileName: Scalars['String'];
}>;

export type UpdateFileNameMutation = { update_file_by_pk?: { name?: string | null } | null };

export const SimulateActionOutputResponseFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimulateActionOutputResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SimulateActionOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_primary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_emissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stranding_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'efficiency_class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'renovation_costs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_final_cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_tax_cost' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SimulateActionOutputResponseFragment, unknown>;
export const EnvelopeUnitFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnvelopeUnit' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'envelope' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'envelope_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_material_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'u_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_thickness' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orientation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inclination' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EnvelopeUnitFragment, unknown>;
export const EnvelopeRenovationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnvelopeRenovation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'renovation_envelope' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'new_envelope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'envelope_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insulation_material_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'u_value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cost_per_m2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insulation_lambda' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insulation_method_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insulation_thickness' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insulation_material_category_id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'old_entity_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'old_envelope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'building_model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'envelope_units' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'id' },
                                  value: { kind: 'EnumValue', value: 'asc' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnvelopeUnit' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnvelopeUnit' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'envelope' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'envelope_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_material_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'u_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_thickness' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orientation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inclination' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EnvelopeRenovationFragment, unknown>;
export const EnergyPathsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnergyPaths' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_path' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_emissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_costs_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_costs_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'efficiency_class_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EnergyPathsFragment, unknown>;
export const EnergyConsumerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnergyConsumer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_consumer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_technology_type_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EnergyConsumerFragment, unknown>;
export const BuildingModelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuildingModel' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building_model' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_primary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_paths' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'year' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnergyPaths' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'envelope_units' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnvelopeUnit' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_consumers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_technology_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'efficiency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_distributions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heat_transfer_loss' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_hydraulic_balancing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_balancing_set_to_never' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balancing_with_components' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_systems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_system_type_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_system_consumer_routes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_consumer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnergyConsumer' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_distribution' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'last_hydraulic_balancing' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'last_balancing_set_to_never' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'heat_transfer_loss' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'balancing_with_components' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_system_producer_routes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_producer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'nominal_power' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnergyPaths' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_path' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_emissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_costs_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_costs_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'efficiency_class_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnvelopeUnit' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'envelope' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'envelope_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_material_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'u_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_thickness' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orientation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inclination' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnergyConsumer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_consumer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_technology_type_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BuildingModelFragment, unknown>;
export const ActionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Action' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'action' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'implementation_to' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renovations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'renovation_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cost_total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cost_total_predium' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cost_total_custom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'use_custom_cost' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renovations_energy_consumer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'new_energy_consumer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_technology_type_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cost_per_m2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'efficiency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_type_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_system_consumer_routes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renovations_envelope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnvelopeRenovation' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renovations_energy_routes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'new_energy_system_route' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renovations_energy_producer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'new_energy_producer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nominal_power' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'module_peak_power' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'module_angle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'solar_active_area' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roof_area_ratio' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cost_per_m2' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building_models' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BuildingModel' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnvelopeUnit' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'envelope' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'envelope_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_material_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'u_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_thickness' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orientation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inclination' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnergyPaths' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_path' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_emissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_costs_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_costs_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'efficiency_class_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnergyConsumer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_consumer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_technology_type_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnvelopeRenovation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'renovation_envelope' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'new_envelope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'envelope_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insulation_material_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'u_value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cost_per_m2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insulation_lambda' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insulation_method_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insulation_thickness' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insulation_material_category_id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'old_entity_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'old_envelope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'building_model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'envelope_units' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'id' },
                                  value: { kind: 'EnumValue', value: 'asc' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnvelopeUnit' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuildingModel' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building_model' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_primary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_paths' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'year' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnergyPaths' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'envelope_units' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnvelopeUnit' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_consumers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_technology_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'efficiency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_distributions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heat_transfer_loss' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_hydraulic_balancing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_balancing_set_to_never' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balancing_with_components' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_systems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_system_type_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_system_consumer_routes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_consumer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnergyConsumer' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_distribution' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'last_hydraulic_balancing' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'last_balancing_set_to_never' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'heat_transfer_loss' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'balancing_with_components' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_system_producer_routes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_producer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'nominal_power' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActionFragment, unknown>;
export const ActionPlanningActionSubsidyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionPlanningActionSubsidy' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'action_subsidy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subsidy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'subsidy_category_type' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'renovations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cost_total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cost_total_predium' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'renovation_type_id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActionPlanningActionSubsidyFragment, unknown>;
export const ModificationImpactsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModificationImpacts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModificationImpact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2_emissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'final_energy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primary_energy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'efficiency_class' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stranding_date' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_cost_total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2_cost_total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModificationImpactsFragment, unknown>;
export const ActionPlanImpactDataOutputFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionPlanImpactDataOutput' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ActionPlanImpactDataOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_renovation_cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_subsidy_cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_estimated_cost' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modification_impact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ModificationImpacts' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModificationImpacts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModificationImpact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2_emissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'final_energy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primary_energy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'efficiency_class' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stranding_date' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_cost_total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2_cost_total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActionPlanImpactDataOutputFragment, unknown>;
export const ActionImpactDataOutputFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionImpactDataOutput' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ActionImpactDataOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'action_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_renovation_cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_subsidy_cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_estimated_cost' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modification_impact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ModificationImpacts' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModificationImpacts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModificationImpact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2_emissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'final_energy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primary_energy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'efficiency_class' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stranding_date' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_cost_total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2_cost_total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActionImpactDataOutputFragment, unknown>;
export const ActionPlanMetricsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionPlanMetrics' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ActionPlanMetricsOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action_plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionPlanImpactDataOutput' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'actions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionImpactDataOutput' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModificationImpacts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModificationImpact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2_emissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'final_energy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primary_energy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'efficiency_class' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stranding_date' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_cost_total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2_cost_total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionPlanImpactDataOutput' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ActionPlanImpactDataOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_renovation_cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_subsidy_cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_estimated_cost' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modification_impact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ModificationImpacts' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionImpactDataOutput' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ActionImpactDataOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'action_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_renovation_cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_subsidy_cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_estimated_cost' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modification_impact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ModificationImpacts' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActionPlanMetricsFragment, unknown>;
export const DataCollectionSubBuildingSubBuildingSubsidyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionSubBuildingSubBuildingSubsidy' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'subsidy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount_description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider_updated_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'subsidy_category_type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionSubBuildingSubBuildingSubsidyFragment, unknown>;
export const TargetPathsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TargetPaths' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'target_path' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'target_path_template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target_path_template_type_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TargetPathsFragment, unknown>;
export const ActionPlanningActionPlanActionPlanFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionPlanningActionPlanActionPlan' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'action_plan' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scenario' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monument_protection' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'areas' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'area_type_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'NUF' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type_of_use_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'area_type_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'class_of_use_id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'active_building_model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BuildingModel' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_buildings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subsidies' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subsidy' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'DataCollectionSubBuildingSubBuildingSubsidy' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'target_paths' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'year' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TargetPaths' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnergyPaths' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_path' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_emissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_costs_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_costs_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'efficiency_class_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnvelopeUnit' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'envelope' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'envelope_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_material_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'u_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_thickness' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orientation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inclination' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnergyConsumer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_consumer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_technology_type_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuildingModel' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building_model' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_primary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_paths' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'year' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnergyPaths' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'envelope_units' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnvelopeUnit' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_consumers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_technology_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'efficiency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_distributions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heat_transfer_loss' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_hydraulic_balancing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_balancing_set_to_never' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balancing_with_components' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_systems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_system_type_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_system_consumer_routes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_consumer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnergyConsumer' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_distribution' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'last_hydraulic_balancing' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'last_balancing_set_to_never' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'heat_transfer_loss' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'balancing_with_components' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_system_producer_routes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_producer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'nominal_power' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionSubBuildingSubBuildingSubsidy' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'subsidy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount_description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider_updated_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'subsidy_category_type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TargetPaths' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'target_path' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'target_path_template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target_path_template_type_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActionPlanningActionPlanActionPlanFragment, unknown>;
export const ScenarioActionPlansFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ScenarioActionPlans' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'action_plan' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'actions_aggregate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'max' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'updated_at' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ScenarioActionPlansFragment, unknown>;
export const ActionPlanningScenariosScenarioFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionPlanningScenariosScenario' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'scenario' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action_plans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ScenarioActionPlans' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ScenarioActionPlans' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'action_plan' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'actions_aggregate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'max' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'updated_at' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActionPlanningScenariosScenarioFragment, unknown>;
export const ActionPlanningScenarioActionPlanFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionPlanningScenarioActionPlan' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'action_plan' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'active_building_model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_paths' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'year' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnergyPaths' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'actions_aggregate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'max' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'updated_at' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'actions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'order' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'building_models' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_paths' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'year' },
                                  value: { kind: 'EnumValue', value: 'asc' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'efficiency_class_id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnergyPaths' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_path' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_emissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_costs_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_costs_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'efficiency_class_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActionPlanningScenarioActionPlanFragment, unknown>;
export const ActionPlanningScenarioScenarioFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionPlanningScenarioScenario' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'scenario' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionPlanningScenariosScenario' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action_plans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionPlanningScenarioActionPlan' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ScenarioActionPlans' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'action_plan' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'actions_aggregate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'max' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'updated_at' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnergyPaths' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_path' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_emissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_costs_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_costs_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'efficiency_class_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionPlanningScenariosScenario' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'scenario' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action_plans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ScenarioActionPlans' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionPlanningScenarioActionPlan' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'action_plan' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'active_building_model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_paths' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'year' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnergyPaths' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'actions_aggregate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'max' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'updated_at' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'actions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'order' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'building_models' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_paths' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'year' },
                                  value: { kind: 'EnumValue', value: 'asc' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'efficiency_class_id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActionPlanningScenarioScenarioFragment, unknown>;
export const CreateActionPlanModalBuildingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreateActionPlanModalBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'active_building_model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action_planning_available' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_paths' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'year' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnergyPaths' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnergyPaths' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_path' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_emissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_costs_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_costs_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'efficiency_class_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateActionPlanModalBuildingFragment, unknown>;
export const CreatedEnergyCertificateDraftFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreatedEnergyCertificateDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_certificate_draft' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_certificate_file_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatedEnergyCertificateDraftFragment, unknown>;
export const DataCollectionSubBuildingConsumptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionSubBuildingConsumption' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'display_unit_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'created_by_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumption_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoice_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_type_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_waste_detail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionSubBuildingConsumptionFragment, unknown>;
export const DataCollectionSubBuildingConsumptionDraftFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionSubBuildingConsumptionDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_draft' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'display_unit_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'created_by_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumption_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_type_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_invoice_draft' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'draft_status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_waste_details_draft' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionSubBuildingConsumptionDraftFragment, unknown>;
export const DataCollectionBuildingSubBuildingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingSubBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'sub_building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_standard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_building_class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'units_residential' } },
          { kind: 'Field', name: { kind: 'Name', value: 'units_commercial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'building_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionSubBuildingConsumption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_drafts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionSubBuildingConsumptionDraft' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subsidies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subsidy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'DataCollectionSubBuildingSubBuildingSubsidy' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'subsidies_updated_at' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionSubBuildingConsumption' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'display_unit_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'created_by_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumption_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoice_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_type_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_waste_detail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionSubBuildingConsumptionDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_draft' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'display_unit_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'created_by_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumption_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_type_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_invoice_draft' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'draft_status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_waste_details_draft' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionSubBuildingSubBuildingSubsidy' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'subsidy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount_description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider_updated_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'subsidy_category_type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionBuildingSubBuildingFragment, unknown>;
export const DataCollectionBuildingEnvelopeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingEnvelope' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'envelope' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inclination' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orientation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'envelope_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_thickness' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_material_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_material_category_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_method_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_lambda' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base_construction_thickness' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base_construction_material_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base_construction_lambda' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'u_value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionBuildingEnvelopeFragment, unknown>;
export const DataCollectionBuildingEnergySystemConsumerRouteFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystemConsumerRoute' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_system_consumer_route' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_effective' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_primary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_factor_origin_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_certificate_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_certificate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'emission_factor' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'primary_energy_factor_emission_certificate_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primary_energy_factor_emission_certificate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'primary_energy_factor' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_consumer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_technology_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'efficiency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'construction_year' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_distribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_distribution_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heat_distribution_loss' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heat_transfer_loss' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_hydraulic_balancing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_balancing_set_to_never' } },
                { kind: 'Field', name: { kind: 'Name', value: 'construction_year' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_storage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_storage_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'annual_heat_loss' } },
                { kind: 'Field', name: { kind: 'Name', value: 'construction_year' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionBuildingEnergySystemConsumerRouteFragment, unknown>;
export const DataCollectionBuildingEnergySystemProducerRouteFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystemProducerRoute' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_system_producer_route' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_producer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionBuildingEnergySystemProducerRouteFragment, unknown>;
export const DataCollectionBuildingEnergySystemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_system' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_system_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data_source_type_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_system_consumer_routes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystemConsumerRoute' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_system_producer_routes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystemProducerRoute' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystemConsumerRoute' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_system_consumer_route' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_effective' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_primary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_factor_origin_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_certificate_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_certificate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'emission_factor' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'primary_energy_factor_emission_certificate_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primary_energy_factor_emission_certificate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'primary_energy_factor' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_consumer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_technology_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'efficiency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'construction_year' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_distribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_distribution_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heat_distribution_loss' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heat_transfer_loss' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_hydraulic_balancing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_balancing_set_to_never' } },
                { kind: 'Field', name: { kind: 'Name', value: 'construction_year' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_storage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_storage_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'annual_heat_loss' } },
                { kind: 'Field', name: { kind: 'Name', value: 'construction_year' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystemProducerRoute' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_system_producer_route' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_producer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionBuildingEnergySystemFragment, unknown>;
export const DataCollectionBuildingModelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingModel' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building_model' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action_planning_available' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'envelope_units' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionBuildingEnvelope' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_systems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'energy_system_type_id' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystem' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_paths' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'year' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'efficiency_class_id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystemConsumerRoute' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_system_consumer_route' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_effective' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_primary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_factor_origin_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_certificate_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_certificate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'emission_factor' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'primary_energy_factor_emission_certificate_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primary_energy_factor_emission_certificate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'primary_energy_factor' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_consumer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_technology_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'efficiency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'construction_year' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_distribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_distribution_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heat_distribution_loss' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heat_transfer_loss' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_hydraulic_balancing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_balancing_set_to_never' } },
                { kind: 'Field', name: { kind: 'Name', value: 'construction_year' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_storage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_storage_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'annual_heat_loss' } },
                { kind: 'Field', name: { kind: 'Name', value: 'construction_year' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystemProducerRoute' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_system_producer_route' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_producer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingEnvelope' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'envelope' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inclination' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orientation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'envelope_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_thickness' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_material_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_material_category_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_method_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_lambda' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base_construction_thickness' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base_construction_material_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base_construction_lambda' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'u_value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_system' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_system_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data_source_type_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_system_consumer_routes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystemConsumerRoute' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_system_producer_routes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystemProducerRoute' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionBuildingModelFragment, unknown>;
export const DataCollectionBuildingWithSubBuildingsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingWithSubBuildings' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'building_state_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monument_protection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leasehold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'milieu_protection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heritage_district' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_state_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mapbox_building_ids' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mapbox_pitch' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mapbox_bearing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mapbox_zoom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'areas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area_system_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_rented' } },
                { kind: 'Field', name: { kind: 'Name', value: 'class_of_use_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data_source_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type_of_use_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parent_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'responsible_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sub_buildings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionBuildingSubBuilding' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'associatedBuildingsAndDrafts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emission_factor_type_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'active_building_model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionBuildingModel' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climate_risks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uncertainty' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timespan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climate_risks_aggregate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'max' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'created_at' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionSubBuildingConsumption' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'display_unit_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'created_by_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumption_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoice_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_type_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_waste_detail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionSubBuildingConsumptionDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_draft' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'display_unit_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'created_by_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumption_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_type_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_invoice_draft' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'draft_status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_waste_details_draft' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionSubBuildingSubBuildingSubsidy' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'subsidy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount_description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider_updated_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'subsidy_category_type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingEnvelope' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'envelope' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inclination' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orientation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'envelope_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_thickness' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_material_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_material_category_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_method_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_lambda' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base_construction_thickness' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base_construction_material_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base_construction_lambda' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'u_value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystemConsumerRoute' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_system_consumer_route' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_effective' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_primary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_factor_origin_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_certificate_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_certificate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'emission_factor' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'primary_energy_factor_emission_certificate_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primary_energy_factor_emission_certificate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'primary_energy_factor' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_consumer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_technology_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'efficiency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'construction_year' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_distribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_distribution_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heat_distribution_loss' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heat_transfer_loss' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_hydraulic_balancing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_balancing_set_to_never' } },
                { kind: 'Field', name: { kind: 'Name', value: 'construction_year' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_storage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_storage_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'annual_heat_loss' } },
                { kind: 'Field', name: { kind: 'Name', value: 'construction_year' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystemProducerRoute' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_system_producer_route' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_producer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_system' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_system_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data_source_type_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_system_consumer_routes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystemConsumerRoute' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_system_producer_routes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystemProducerRoute' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingSubBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'sub_building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_standard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_building_class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'units_residential' } },
          { kind: 'Field', name: { kind: 'Name', value: 'units_commercial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'building_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionSubBuildingConsumption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_drafts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionSubBuildingConsumptionDraft' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subsidies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subsidy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'DataCollectionSubBuildingSubBuildingSubsidy' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'subsidies_updated_at' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingModel' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building_model' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action_planning_available' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'envelope_units' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionBuildingEnvelope' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_systems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'energy_system_type_id' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystem' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_paths' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'year' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'efficiency_class_id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionBuildingWithSubBuildingsFragment, unknown>;
export const DataCollectionYearlyConsumptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionYearlyConsumption' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'YearlyConsumption' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'providers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumption_type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionYearlyConsumptionFragment, unknown>;
export const DataCollectionConsumptionConsumptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionConsumptionConsumption' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'display_unit_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumption_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_waste_detail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionConsumptionConsumptionFragment, unknown>;
export const DataCollectionConsumptionDraftConsumptionDraftFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionConsumptionDraftConsumptionDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_draft' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumption_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'display_unit_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_building_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_waste_details_draft' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionConsumptionDraftConsumptionDraftFragment, unknown>;
export const DataCollectionConsumptionDraftConsumptionDraftSubBuildingAreasFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionConsumptionDraftConsumptionDraftSubBuildingAreas' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'sub_building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'economic_unit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'areas' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'area_system_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'GIF2017_DE' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'area_type_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_nin' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      { kind: 'EnumValue', value: 'MFG_0' },
                                      { kind: 'EnumValue', value: 'BGF' },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'area_type_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'area_system_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionConsumptionDraftConsumptionDraftSubBuildingAreasFragment, unknown>;
export const DataCollectionEnergyCertificateDraftEnergyCertificateDraftFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionEnergyCertificateDraftEnergyCertificateDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_certificate_draft' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country_state_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'building_state_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portfolio_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'economic_unit_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responsible_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area_usable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_building_class_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type_of_use_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draft_status_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_certificate_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiry_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'units' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monument_protection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leasehold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'milieu_protection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heritage_district' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_certificate_consumers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'energy_system_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionEnergyCertificateDraftEnergyCertificateDraftFragment, unknown>;
export const DataCollectionEnergyCertificateDraftUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionEnergyCertificateDraftUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'user' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionEnergyCertificateDraftUserFragment, unknown>;
export const SubBuildingDocumentsFileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubBuildingDocumentsFile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'file' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubBuildingDocumentsFileFragment, unknown>;
export const DataCollectionEnergyCertificateDraftsListEnergyCertificateDraftFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionEnergyCertificateDraftsListEnergyCertificateDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_certificate_draft' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draft_status_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionEnergyCertificateDraftsListEnergyCertificateDraftFragment, unknown>;
export const DataCollectionEnergyCertificateDraftsListEnergyCertificateDraftSubscriptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionEnergyCertificateDraftsListEnergyCertificateDraftSubscription' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_certificate_draft' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draft_status_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionEnergyCertificateDraftsListEnergyCertificateDraftSubscriptionFragment,
  unknown
>;
export const DataCollectionEconomicUnitFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionEconomicUnit' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'economic_unit' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer_economic_unit_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionEconomicUnitFragment, unknown>;
export const DataCollectionEconomicUnitPortfoliosFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionEconomicUnitPortfolios' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'economic_unit' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer_economic_unit_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'associatedBuildingsAndDrafts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionEconomicUnitPortfoliosFragment, unknown>;
export const DataCollectionSubBuildingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionSubBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'sub_building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'building_state_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'economic_unit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionSubBuildingFragment, unknown>;
export const DataCollectionBuildingsListBuildingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingsListBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'building_state_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'areas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'area_type_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'NUF' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type_of_use_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area_type_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sub_buildings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionSubBuilding' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'associatedBuildingsAndDrafts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'active_building_model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action_planning_available' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processing' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionSubBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'sub_building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'building_state_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'economic_unit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionBuildingsListBuildingFragment, unknown>;
export const DataCollectionGetEconomicUnitWithBuildingsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionGetEconomicUnitWithBuildings' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'economic_unit' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'associatedBuildingsAndDrafts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer_economic_unit_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buildings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionBuildingsListBuilding' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionSubBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'sub_building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'building_state_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'economic_unit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingsListBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'building_state_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'areas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'area_type_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'NUF' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type_of_use_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area_type_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sub_buildings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionSubBuilding' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'associatedBuildingsAndDrafts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'active_building_model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action_planning_available' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processing' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionGetEconomicUnitWithBuildingsFragment, unknown>;
export const BuildingModelMutationAvailabilityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuildingModelMutationAvailability' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GetBuildingModelMutationAvailabilityOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'action_deletion_necessary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affected_action_plan_count' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'affected_users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BuildingModelMutationAvailabilityFragment, unknown>;
export const DataCollectionDocumentDraftFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionDocumentDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'file' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionDocumentDraftFragment, unknown>;
export const DataCollectionEmissionCertificateDraftFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionEmissionCertificateDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'emission_certificate_draft' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primary_energy_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'file_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionDocumentDraft' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'org' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid_from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid_until' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionDocumentDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'file' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionEmissionCertificateDraftFragment, unknown>;
export const DataCollectionEmissionCertificateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionEmissionCertificate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'emission_certificate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primary_energy_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'file_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionDocumentDraft' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'org' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid_from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid_until' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_system_consumer_routes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_system' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'building_model' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'building_id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primary_energy_factor_energy_system_consumer_routes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_system' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'building_model' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'building_id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionDocumentDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'file' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionEmissionCertificateFragment, unknown>;
export const EsgAnalysisPortfolioFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EsgAnalysisPortfolio' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EsgAnalysisPortfolioFragment, unknown>;
export const EsgAnalysisGetBuildingsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EsgAnalysisGetBuildings' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EsgAnalysisPortfolio' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'areas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'area_type_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'NUF' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type_of_use_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'class_of_use_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area_type_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'active_building_model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_paths' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'year' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'currentYear' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'co2_emissions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'co2_costs' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'efficiency_class_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eu_taxonomy_compliance_id' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_primary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EsgAnalysisPortfolio' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EsgAnalysisGetBuildingsFragment, unknown>;
export const EsgAnalysisGetBuildingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EsgAnalysisGetBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mapbox_building_ids' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EsgAnalysisPortfolio' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'responsible_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'active_building_model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_paths' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'year' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'currentYear' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'co2_emissions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'co2_costs' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'efficiency_class_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eu_taxonomy_compliance_id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'areas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'area_type_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'NUF' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type_of_use_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'class_of_use_id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EsgAnalysisPortfolio' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EsgAnalysisGetBuildingFragment, unknown>;
export const EsgAnalysisGetBenchmarkBuildingsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EsgAnalysisGetBenchmarkBuildings' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BenchmarkOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2Paths' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pathM2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'costsM2' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energyPaths' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pathM2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'costsM2' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'strandingDates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'co2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'onePointFive' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'startYear' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endYear' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averagePrimaryEnergy' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EsgAnalysisGetBenchmarkBuildingsFragment, unknown>;
export const EsgAnalysisGetBuildingsPortfolioAndAddressFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EsgAnalysisGetBuildingsPortfolioAndAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EsgAnalysisPortfolio' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'street' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EsgAnalysisPortfolio' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EsgAnalysisGetBuildingsPortfolioAndAddressFragment, unknown>;
export const EsgAnalysisGetBuildingsForSingleBuildingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EsgAnalysisGetBuildingsForSingleBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mapbox_building_ids' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EsgAnalysisPortfolio' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'responsible_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'areas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'area_type_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'NUF' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type_of_use_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'class_of_use_id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EsgAnalysisPortfolio' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EsgAnalysisGetBuildingsForSingleBuildingFragment, unknown>;
export const PortfolioManagementPortfolioFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioManagementPortfolio' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_factor_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'economic_units_with_buildings' },
            name: { kind: 'Name', value: 'economic_units_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'buildings_aggregate' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'count' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'predicate' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_gt' },
                                        value: { kind: 'IntValue', value: '0' },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PortfolioManagementPortfolioFragment, unknown>;
export const ReportGetReportByPkReportTemplateSectionsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportGetReportByPkReportTemplateSections' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report_template_section' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_section_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportGetReportByPkReportTemplateSectionsFragment, unknown>;
export const ReportGetReportByPkReportTemplateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportGetReportByPkReportTemplate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report_template' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'single_sub_building' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excel_template_path' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_sections' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'order' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReportGetReportByPkReportTemplateSections' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportGetReportByPkReportTemplateSections' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report_template_section' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_section_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportGetReportByPkReportTemplateFragment, unknown>;
export const ReportGetReportByPkResponsibleUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportGetReportByPkResponsibleUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'user' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportGetReportByPkResponsibleUserFragment, unknown>;
export const ReportGetReportByPkSubBuildingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportGetReportByPkSubBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'sub_building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'street' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'economic_unit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportGetReportByPkSubBuildingFragment, unknown>;
export const ReportGetReportByPkFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportGetReportByPk' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'due_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReportGetReportByPkReportTemplate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'responsible_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReportGetReportByPkResponsibleUser' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_sub_buildings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_building' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReportGetReportByPkSubBuilding' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_sub_buildings_aggregate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_answers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'state' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: { kind: 'EnumValue', value: 'UNANSWERED' },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: '_or' },
                                value: {
                                  kind: 'ListValue',
                                  values: [
                                    {
                                      kind: 'ObjectValue',
                                      fields: [
                                        {
                                          kind: 'ObjectField',
                                          name: { kind: 'Name', value: 'autofill' },
                                          value: {
                                            kind: 'ObjectValue',
                                            fields: [
                                              {
                                                kind: 'ObjectField',
                                                name: { kind: 'Name', value: '_is_null' },
                                                value: { kind: 'BooleanValue', value: false },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                    {
                                      kind: 'ObjectValue',
                                      fields: [
                                        {
                                          kind: 'ObjectField',
                                          name: { kind: 'Name', value: 'report_answer_autofill_options' },
                                          value: {
                                            kind: 'ObjectValue',
                                            fields: [
                                              {
                                                kind: 'ObjectField',
                                                name: { kind: 'Name', value: 'report_template_question_option' },
                                                value: {
                                                  kind: 'ObjectValue',
                                                  fields: [
                                                    {
                                                      kind: 'ObjectField',
                                                      name: { kind: 'Name', value: 'value' },
                                                      value: {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                          {
                                                            kind: 'ObjectField',
                                                            name: { kind: 'Name', value: '_is_null' },
                                                            value: { kind: 'BooleanValue', value: false },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_template_question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'evidence_required' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportGetReportByPkReportTemplateSections' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report_template_section' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_section_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportGetReportByPkReportTemplate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report_template' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'single_sub_building' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excel_template_path' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_sections' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'order' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReportGetReportByPkReportTemplateSections' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportGetReportByPkResponsibleUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'user' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportGetReportByPkSubBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'sub_building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'street' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'economic_unit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportGetReportByPkFragment, unknown>;
export const ReportSectionGetRootQuestionsAndAnswersAnswersFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportSectionGetRootQuestionsAndAnswersAnswers' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report_answer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'report_question_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autofill' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_template_question' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'evidence_required' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_answer_autofill_options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_template_question_option' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_answer_chosen_options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_template_question_option' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportSectionGetRootQuestionsAndAnswersAnswersFragment, unknown>;
export const CreateReportModalGetAllTemplatesTemplateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreateReportModalGetAllTemplatesTemplate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReportTemplateOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'single_sub_building' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateReportModalGetAllTemplatesTemplateFragment, unknown>;
export const ReportGetReportsSubBuildingsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportGetReportsSubBuildings' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report_sub_building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sub_building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'building' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportGetReportsSubBuildingsFragment, unknown>;
export const ReportAnswerForAutofillFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportAnswerForAutofill' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report_answer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sub_building_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autofill' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_answer_autofill_options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_template_question_option' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'report_question_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportAnswerForAutofillFragment, unknown>;
export const ReportAnswersStateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportAnswersState' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report_answer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_building_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_template_question' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_template_section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportAnswersStateFragment, unknown>;
export const ReportSectionsProgressFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportSectionsProgress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report_section_progress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'report_template_section_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_building_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportSectionsProgressFragment, unknown>;
export const SubsectionProgressFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubsectionProgress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report_section_progress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_template_section' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubsectionProgressFragment, unknown>;
export const GetSessionDataOrgFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetSessionDataOrg' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'org' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSessionDataOrgFragment, unknown>;
export const ServerSideFeatureFlagsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServerSideFeatureFlags' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GetUserFeatureFlagsOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'BUILDING_CREATION_CONSUMER_EXTENSION' } },
          { kind: 'Field', name: { kind: 'Name', value: 'CONFIGMANAGER_EMISSIONFACTORTYPE_DISABLED' } },
          { kind: 'Field', name: { kind: 'Name', value: 'AREA_MANAGEMENT' } },
          { kind: 'Field', name: { kind: 'Name', value: 'CONSUMPTION_ANALYSIS' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ServerSideFeatureFlagsFragment, unknown>;
export const GetSessionDataUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetSessionDataUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'user' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'org' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetSessionDataOrg' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'role' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featureFlags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServerSideFeatureFlags' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetSessionDataOrg' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'org' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServerSideFeatureFlags' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GetUserFeatureFlagsOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'BUILDING_CREATION_CONSUMER_EXTENSION' } },
          { kind: 'Field', name: { kind: 'Name', value: 'CONFIGMANAGER_EMISSIONFACTORTYPE_DISABLED' } },
          { kind: 'Field', name: { kind: 'Name', value: 'AREA_MANAGEMENT' } },
          { kind: 'Field', name: { kind: 'Name', value: 'CONSUMPTION_ANALYSIS' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSessionDataUserFragment, unknown>;
export const AsyncJobFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AsyncJob' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'async_job' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entity_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AsyncJobFragment, unknown>;
export const AccessRightsPortfolioFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccessRightsPortfolio' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccessRightsPortfolioFragment, unknown>;
export const AccessRightsBuildingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccessRightsBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'house_number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'areas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'area_type_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'NUF' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type_of_use_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area_type_id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccessRightsBuildingFragment, unknown>;
export const ReadNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReadNotifications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'notificationIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_notification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'notificationIds' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'read' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'read' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReadNotificationsMutation, ReadNotificationsMutationVariables>;
export const ActionPlanningActionDeleteActionPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ActionPlanningActionDeleteActionPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'actionPlanId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_action_plan_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'actionPlanId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActionPlanningActionDeleteActionPlanMutation,
  ActionPlanningActionDeleteActionPlanMutationVariables
>;
export const ExportScenarioAsExcelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ExportScenarioAsExcel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scenarioId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportScenarioAsExcel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scenario_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'scenarioId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'body' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExportScenarioAsExcelMutation, ExportScenarioAsExcelMutationVariables>;
export const ActionPlanDeleteActionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ActionPlanDeleteAction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'actionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_action_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'actionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActionPlanDeleteActionMutation, ActionPlanDeleteActionMutationVariables>;
export const SimulateTechologyActionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SimulateTechologyAction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'action_plan_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'renovation_ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'envelope_renovation_parameter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'EnvelopeRenovationParameterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'system_renovation_parameter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SystemRenovationParameterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'energy_system_route_renovation_parameter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'EnergySystemRouteRenovationParameterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hydraulic_balancing_renovation_parameter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HydraulicBalancingRenovationParameterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'solar_renovation_parameter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SolarRenovationParameterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'implementation_to' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'simulateAction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'action_plan_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'action_plan_id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'renovation_ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'renovation_ids' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'envelope_renovation_parameter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'envelope_renovation_parameter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'system_renovation_parameter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'system_renovation_parameter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'energy_system_route_renovation_parameter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'energy_system_route_renovation_parameter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hydraulic_balancing_renovation_parameter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'hydraulic_balancing_renovation_parameter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'solar_renovation_parameter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'solar_renovation_parameter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'implementation_to' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'implementation_to' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SimulateActionOutputResponse' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimulateActionOutputResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SimulateActionOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_primary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_emissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stranding_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'efficiency_class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'renovation_costs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_final_cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_tax_cost' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SimulateTechologyActionMutation, SimulateTechologyActionMutationVariables>;
export const ImplementActionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ImplementAction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'action_plan_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'renovation_ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'implementation_from' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'implementation_to' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'envelope_renovation_parameter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'EnvelopeRenovationParameterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'system_renovation_parameter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SystemRenovationParameterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'energy_system_route_renovation_parameter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'EnergySystemRouteRenovationParameterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hydraulic_balancing_renovation_parameter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HydraulicBalancingRenovationParameterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'solar_renovation_parameter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SolarRenovationParameterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subsidies' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SubsidyInput' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customCost' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomCostInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'implementAction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'action_plan_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'action_plan_id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'renovation_ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'renovation_ids' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'implementation_from' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'implementation_from' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'implementation_to' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'implementation_to' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'envelope_renovation_parameter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'envelope_renovation_parameter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'system_renovation_parameter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'system_renovation_parameter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'energy_system_route_renovation_parameter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'energy_system_route_renovation_parameter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hydraulic_balancing_renovation_parameter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'hydraulic_balancing_renovation_parameter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'solar_renovation_parameter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'solar_renovation_parameter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subsidies' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subsidies' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customCost' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customCost' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'action_id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImplementActionMutation, ImplementActionMutationVariables>;
export const UpdateCustomCostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCustomCost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'action_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'custom_cost' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'renovation_set_input' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_renovation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'action_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'action_id' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'custom_cost' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCustomCostMutation, UpdateCustomCostMutationVariables>;
export const CreateActionPlanModalCreateActionPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateActionPlanModalCreateActionPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scenario_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'building_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_action_plan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'building_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'building_id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'scenario_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'scenario_id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateActionPlanModalCreateActionPlanMutation,
  CreateActionPlanModalCreateActionPlanMutationVariables
>;
export const CreateScenarioModalCreateScenarioDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateScenarioModalCreateScenario' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'portfolio_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_scenario' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'portfolio_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'portfolio_id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateScenarioModalCreateScenarioMutation,
  CreateScenarioModalCreateScenarioMutationVariables
>;
export const ActionPlanningScenarioDeleteScenarioDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ActionPlanningScenarioDeleteScenario' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_scenario_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActionPlanningScenarioDeleteScenarioMutation,
  ActionPlanningScenarioDeleteScenarioMutationVariables
>;
export const ActionPlanningActionPlanGetActionPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ActionPlanningActionPlanGetActionPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'actionPlanId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action_plan_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'actionPlanId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionPlanningActionPlanActionPlan' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnergyPaths' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_path' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_emissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_costs_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_costs_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'efficiency_class_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnvelopeUnit' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'envelope' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'envelope_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_material_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'u_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_thickness' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orientation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inclination' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnergyConsumer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_consumer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_technology_type_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuildingModel' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building_model' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_primary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_paths' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'year' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnergyPaths' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'envelope_units' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnvelopeUnit' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_consumers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_technology_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'efficiency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_distributions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heat_transfer_loss' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_hydraulic_balancing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_balancing_set_to_never' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balancing_with_components' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_systems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_system_type_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_system_consumer_routes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_consumer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnergyConsumer' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_distribution' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'last_hydraulic_balancing' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'last_balancing_set_to_never' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'heat_transfer_loss' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'balancing_with_components' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_system_producer_routes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_producer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'nominal_power' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionSubBuildingSubBuildingSubsidy' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'subsidy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount_description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider_updated_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'subsidy_category_type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TargetPaths' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'target_path' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'target_path_template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target_path_template_type_id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionPlanningActionPlanActionPlan' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'action_plan' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scenario' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monument_protection' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'areas' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'area_type_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'NUF' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type_of_use_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'area_type_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'class_of_use_id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'active_building_model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BuildingModel' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_buildings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subsidies' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subsidy' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'DataCollectionSubBuildingSubBuildingSubsidy' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'target_paths' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'year' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TargetPaths' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActionPlanningActionPlanGetActionPlanQuery,
  ActionPlanningActionPlanGetActionPlanQueryVariables
>;
export const ActionPlanningGetSubsidiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ActionPlanningGetSubsidies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'actionPlanId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action_subsidy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'action' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'action_plan_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'actionPlanId' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionPlanningActionSubsidy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionPlanningActionSubsidy' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'action_subsidy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subsidy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'subsidy_category_type' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'renovations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cost_total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cost_total_predium' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'renovation_type_id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActionPlanningGetSubsidiesQuery, ActionPlanningGetSubsidiesQueryVariables>;
export const ActionPlanningScenarioGetScenarioDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ActionPlanningScenarioGetScenario' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scenarioId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scenario_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'scenarioId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionPlanningScenarioScenario' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ScenarioActionPlans' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'action_plan' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'actions_aggregate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'max' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'updated_at' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionPlanningScenariosScenario' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'scenario' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action_plans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ScenarioActionPlans' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnergyPaths' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_path' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_emissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_costs_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_costs_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'efficiency_class_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionPlanningScenarioActionPlan' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'action_plan' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'active_building_model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_paths' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'year' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnergyPaths' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'actions_aggregate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'max' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'updated_at' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'actions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'order' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'building_models' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_paths' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'year' },
                                  value: { kind: 'EnumValue', value: 'asc' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'efficiency_class_id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionPlanningScenarioScenario' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'scenario' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionPlanningScenariosScenario' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action_plans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionPlanningScenarioActionPlan' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActionPlanningScenarioGetScenarioQuery, ActionPlanningScenarioGetScenarioQueryVariables>;
export const ActionPlanningScenariosAllScenariosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ActionPlanningScenariosAllScenarios' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scenario' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionPlanningScenariosScenario' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ScenarioActionPlans' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'action_plan' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'actions_aggregate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'max' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'updated_at' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionPlanningScenariosScenario' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'scenario' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action_plans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ScenarioActionPlans' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActionPlanningScenariosAllScenariosQuery,
  ActionPlanningScenariosAllScenariosQueryVariables
>;
export const CreateActionPlanModalGetBuildingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CreateActionPlanModalGetBuildings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'building_bool_exp' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'building_order_by' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'active_building_model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'action_planning_available' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_paths' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'year' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnergyPaths' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnergyPaths' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_path' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_emissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_costs_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_costs_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'efficiency_class_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateActionPlanModalGetBuildingsQuery, CreateActionPlanModalGetBuildingsQueryVariables>;
export const ExportActionPlanPdfDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ExportActionPlanPdf' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'actionPlanId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getActionPlanPdf' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'actionPlanId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'actionPlanId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'body' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExportActionPlanPdfQuery, ExportActionPlanPdfQueryVariables>;
export const GetActionsPlanMetricsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActionsPlanMetrics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'actionPlanId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getActionPlanMetrics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'actionPlanId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionPlanMetrics' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModificationImpacts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModificationImpact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2_emissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'final_energy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primary_energy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'efficiency_class' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stranding_date' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_cost_total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2_cost_total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'after' } },
                { kind: 'Field', name: { kind: 'Name', value: 'before' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impact' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionPlanImpactDataOutput' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ActionPlanImpactDataOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_renovation_cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_subsidy_cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_estimated_cost' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modification_impact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ModificationImpacts' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionImpactDataOutput' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ActionImpactDataOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'action_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_renovation_cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_subsidy_cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_estimated_cost' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modification_impact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ModificationImpacts' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionPlanMetrics' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ActionPlanMetricsOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action_plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionPlanImpactDataOutput' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'actions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionImpactDataOutput' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetActionsPlanMetricsQuery, GetActionsPlanMetricsQueryVariables>;
export const GetActionsForActionPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActionsForActionPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'actionPlanId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'action_plan_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'actionPlanId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'order' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Action' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnvelopeUnit' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'envelope' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'envelope_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_material_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'u_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_thickness' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orientation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inclination' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnvelopeRenovation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'renovation_envelope' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'new_envelope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'envelope_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insulation_material_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'u_value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cost_per_m2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insulation_lambda' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insulation_method_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insulation_thickness' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insulation_material_category_id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'old_entity_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'old_envelope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'building_model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'envelope_units' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'id' },
                                  value: { kind: 'EnumValue', value: 'asc' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnvelopeUnit' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnergyPaths' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_path' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_emissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_costs_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2_costs_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'efficiency_class_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EnergyConsumer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_consumer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_technology_type_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuildingModel' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building_model' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_primary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_paths' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'year' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnergyPaths' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'envelope_units' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnvelopeUnit' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_consumers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_technology_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'efficiency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_distributions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heat_transfer_loss' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_hydraulic_balancing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_balancing_set_to_never' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balancing_with_components' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_systems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_system_type_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_system_consumer_routes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_consumer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnergyConsumer' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_distribution' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'last_hydraulic_balancing' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'last_balancing_set_to_never' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'heat_transfer_loss' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'balancing_with_components' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_system_producer_routes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_producer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'nominal_power' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Action' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'action' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'implementation_to' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renovations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'renovation_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cost_total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cost_total_predium' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cost_total_custom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'use_custom_cost' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renovations_energy_consumer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'new_energy_consumer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_technology_type_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cost_per_m2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'efficiency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_type_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_system_consumer_routes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renovations_envelope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EnvelopeRenovation' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renovations_energy_routes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'new_energy_system_route' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renovations_energy_producer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'new_energy_producer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nominal_power' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'module_peak_power' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'module_angle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'solar_active_area' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roof_area_ratio' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cost_per_m2' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building_models' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BuildingModel' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetActionsForActionPlanQuery, GetActionsForActionPlanQueryVariables>;
export const DataCollectionAddEconomicUnitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionAddEconomicUnit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerEconomicUnitId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_economic_unit_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'portfolio_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'customer_economic_unit_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'customerEconomicUnitId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionAddEconomicUnitMutation, DataCollectionAddEconomicUnitMutationVariables>;
export const DataCollectionEditEconomicUnitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionEditEconomicUnit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'economicUnitId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'economicUnitName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerEconomicUnitId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_economic_unit_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'economicUnitId' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'economicUnitName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'customer_economic_unit_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'customerEconomicUnitId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'portfolio_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'portfolio_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionEditEconomicUnitMutation, DataCollectionEditEconomicUnitMutationVariables>;
export const DataCollectionDeleteEconomicUnitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionDeleteEconomicUnit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_economic_unit_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionDeleteEconomicUnitMutation,
  DataCollectionDeleteEconomicUnitMutationVariables
>;
export const DataCollectionDeleteEconomicUnitsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionDeleteEconomicUnits' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'economicUnitIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_economic_unit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'economicUnitIds' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'affected_rows' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionDeleteEconomicUnitsMutation,
  DataCollectionDeleteEconomicUnitsMutationVariables
>;
export const DataCollectionDeleteBuildingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionDeleteBuilding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_building_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'economic_unit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'associatedBuildingsAndDrafts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionDeleteBuildingMutation, DataCollectionDeleteBuildingMutationVariables>;
export const DataCollectionDeleteBuildingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionDeleteBuildings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_building' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingIds' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'affected_rows' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'economic_unit' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'associatedBuildingsAndDrafts' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionDeleteBuildingsMutation, DataCollectionDeleteBuildingsMutationVariables>;
export const DataCollectionMoveBuildingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionMoveBuilding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'economicUnitId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_building_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'economic_unit_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'economicUnitId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionMoveBuildingMutation, DataCollectionMoveBuildingMutationVariables>;
export const DataCollectionMoveBuildingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionMoveBuildings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'economicUnitId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_building' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'economic_unit_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'economicUnitId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'affected_rows' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionMoveBuildingsMutation, DataCollectionMoveBuildingsMutationVariables>;
export const SubBuildingExcelExportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubBuildingExcelExport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportSubBuildingsAsExcel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'building_ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'body' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubBuildingExcelExportMutation, SubBuildingExcelExportMutationVariables>;
export const DataCollectionEnergyCertificateDraftsListDeleteEnergyCertificateDraftsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionEnergyCertificateDraftsListDeleteEnergyCertificateDrafts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'energyCertificateDraftIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_energy_certificate_draft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'energyCertificateDraftIds' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'affected_rows' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionEnergyCertificateDraftsListDeleteEnergyCertificateDraftsMutation,
  DataCollectionEnergyCertificateDraftsListDeleteEnergyCertificateDraftsMutationVariables
>;
export const RemoveEmptiesEuAfterMoveBuildingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveEmptiesEuAfterMoveBuildings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeEmptyEconomicUnits' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'ids' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveEmptiesEuAfterMoveBuildingsMutation,
  RemoveEmptiesEuAfterMoveBuildingsMutationVariables
>;
export const DataCollectionEnergyCertificateDraftCreateBuildingFromEnergyCertificateDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionEnergyCertificateDraftCreateBuildingFromEnergyCertificateDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'energyCertificateDraftId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBuildingFromEnergyCertificateDraft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'energy_certificate_draft_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'energyCertificateDraftId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'building_id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionEnergyCertificateDraftCreateBuildingFromEnergyCertificateDraftMutation,
  DataCollectionEnergyCertificateDraftCreateBuildingFromEnergyCertificateDraftMutationVariables
>;
export const UploadEnergyCertificateDraftModalCreateEnergyCertificateDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UploadEnergyCertificateDraftModalCreateEnergyCertificateDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'economicUnitId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEnergyCertificateDraft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'portfolio_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'economic_unit_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'economicUnitId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'CreateEnergyCertificateDraftOutput' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CreatedEnergyCertificateDraft' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'upload_url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreatedEnergyCertificateDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_certificate_draft' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_certificate_file_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UploadEnergyCertificateDraftModalCreateEnergyCertificateDraftMutation,
  UploadEnergyCertificateDraftModalCreateEnergyCertificateDraftMutationVariables
>;
export const DataCollectionEnergyCertificateDraftSaveEnergyCertificateDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionEnergyCertificateDraftSaveEnergyCertificateDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'energy_certificate_draft_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'street' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'postal_code' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'country_state_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'country_state_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'country_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'country_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'building_state_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'building_state_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'portfolio_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'monument_protection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'leasehold' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'heritage_district' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'milieu_protection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'economic_unit_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'responsible_user_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'year_constructed' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'area_usable' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sub_building_class_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'sub_building_class_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type_of_use_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'type_of_use_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'energy_certificate_type_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_certificate_type_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'draft_status_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'draft_status_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'expiry_date' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamptz' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'units' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'energy_certificate_consumers' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_certificate_consumer_insert_input' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_energy_certificate_draft_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'energy_certificate_draft_id' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'street' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'street' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'city' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'postal_code' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'postal_code' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'country_state_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'country_state_id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'country_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'country_id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'building_state_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'building_state_id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'portfolio_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'portfolio_id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'monument_protection' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'monument_protection' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leasehold' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'leasehold' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'heritage_district' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'heritage_district' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'milieu_protection' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'milieu_protection' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'economic_unit_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'economic_unit_id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'responsible_user_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'responsible_user_id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'year_constructed' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'year_constructed' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'area_usable' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'area_usable' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sub_building_class_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'sub_building_class_id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type_of_use_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'type_of_use_id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'energy_certificate_type_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'energy_certificate_type_id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'expiry_date' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'expiry_date' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'units' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'units' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'draft_status_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'draft_status_id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_energy_certificate_consumer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'energy_certificate_draft_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'energy_certificate_draft_id' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'affected_rows' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_energy_certificate_consumer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'energy_certificate_consumers' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'affected_rows' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionEnergyCertificateDraftSaveEnergyCertificateDraftMutation,
  DataCollectionEnergyCertificateDraftSaveEnergyCertificateDraftMutationVariables
>;
export const DataCollectionEnergyCertificateDraftDeleteEnergyCertificateDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionEnergyCertificateDraftDeleteEnergyCertificateDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'energyCertificateDraftId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_energy_certificate_draft_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'energyCertificateDraftId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionEnergyCertificateDraftDeleteEnergyCertificateDraftMutation,
  DataCollectionEnergyCertificateDraftDeleteEnergyCertificateDraftMutationVariables
>;
export const DataCollectionEnergyCertificateDraftSetOcrFailedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionEnergyCertificateDraftSetOcrFailed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'energyCertificateDraftId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_energy_certificate_draft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'energyCertificateDraftId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'draft_status_id' },
                      value: { kind: 'EnumValue', value: 'OCR_FAILED' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'affected_rows' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionEnergyCertificateDraftSetOcrFailedMutation,
  DataCollectionEnergyCertificateDraftSetOcrFailedMutationVariables
>;
export const DataCollectionsTabsEnergyCertificateDraftCountSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'DataCollectionsTabsEnergyCertificateDraftCountSubscription' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_certificate_draft_aggregate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionsTabsEnergyCertificateDraftCountSubscriptionSubscription,
  DataCollectionsTabsEnergyCertificateDraftCountSubscriptionSubscriptionVariables
>;
export const DataCollectionSaveConsumptionInvoiceDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionSaveConsumptionInvoiceDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionInvoiceDraftId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoicePayer' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'invoice_payer_type_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'draftStatus' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'draft_status_enum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionDrafts' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ConsumptionDraftInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateConsumptionInvoiceDraft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'buildingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceDraftId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionInvoiceDraftId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoicePayer' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoicePayer' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'draftStatus' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'draftStatus' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'consumptionDrafts' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionDrafts' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'UpdateConsumptionInvoiceDraftOutput' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invoice_payer' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'building' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'draft_status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'consumption_drafts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'area_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'to' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'display_unit_value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'consumption_type_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sub_type_id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'consumption_waste_details_draft' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'display_unit_volume' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'display_unit_frequency' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionSaveConsumptionInvoiceDraftMutation,
  DataCollectionSaveConsumptionInvoiceDraftMutationVariables
>;
export const DataCollectionSubBuildingConsumptionDraftCreateConsumptionFromDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionSubBuildingConsumptionDraftCreateConsumptionFromDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionInvoiceDraftId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createConsumptionInvoiceFromDraft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'consumption_invoice_draft_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionInvoiceDraftId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'consumption_invoice_id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionSubBuildingConsumptionDraftCreateConsumptionFromDraftMutation,
  DataCollectionSubBuildingConsumptionDraftCreateConsumptionFromDraftMutationVariables
>;
export const DataCollectionConsumptionDraftStatusSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'DataCollectionConsumptionDraftStatusSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionInvoiceDraftId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_invoice_draft_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionInvoiceDraftId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'draft_status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionConsumptionDraftStatusSubscriptionSubscription,
  DataCollectionConsumptionDraftStatusSubscriptionSubscriptionVariables
>;
export const DataCollectionSubBuildingConsumptionInvoiceDraftSetOcrFailedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionSubBuildingConsumptionInvoiceDraftSetOcrFailed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionInvoiceDraftId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_consumption_invoice_draft_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionInvoiceDraftId' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'draft_status' },
                      value: { kind: 'EnumValue', value: 'OCR_FAILED' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionSubBuildingConsumptionInvoiceDraftSetOcrFailedMutation,
  DataCollectionSubBuildingConsumptionInvoiceDraftSetOcrFailedMutationVariables
>;
export const SubBuildingConsumptionTabsConsumptionDraftCountSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'SubBuildingConsumptionTabsConsumptionDraftCountSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subBuildingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_draft_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sub_building_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'subBuildingId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubBuildingConsumptionTabsConsumptionDraftCountSubscriptionSubscription,
  SubBuildingConsumptionTabsConsumptionDraftCountSubscriptionSubscriptionVariables
>;
export const UploadConsumptionDraftModalCreateConsumptionDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UploadConsumptionDraftModalCreateConsumptionDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createConsumptionInvoiceDraft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'building_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'CreateConsumptionInvoiceDraftOutput' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'file_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'upload_url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UploadConsumptionDraftModalCreateConsumptionDraftMutation,
  UploadConsumptionDraftModalCreateConsumptionDraftMutationVariables
>;
export const DeleteEnergyCertificateFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteEnergyCertificateFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_file_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteEnergyCertificateFileMutation, DeleteEnergyCertificateFileMutationVariables>;
export const CreateEnergyCertificateFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateEnergyCertificateFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'energyCertificateId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEnergyCertificateFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'energy_certificate_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'energyCertificateId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'file_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'upload_url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateEnergyCertificateFileMutation, CreateEnergyCertificateFileMutationVariables>;
export const ApplyBuildingModelMutationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApplyBuildingModelMutations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingModelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'envelopeMutations' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'EnvelopeMutation' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'energySystemMutations' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'EnergySystemMutation' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'energySystemConsumerMutations' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'EnergyConsumerMutation' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'energySystemStorageMutations' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'EnergyStorageMutation' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'energySystemDistributionMutations' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'EnergyDistributionMutation' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'saveInvalidState' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applyBuildingModelMutations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'building_model_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingModelId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'envelope_mutations' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'envelopeMutations' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'energy_system_mutations' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'energySystemMutations' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'energy_consumer_mutations' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'energySystemConsumerMutations' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'energy_storage_mutations' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'energySystemStorageMutations' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'energy_distribution_mutations' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'energySystemDistributionMutations' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'save_invalid_state' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'saveInvalidState' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'scaling_successful' } },
                { kind: 'Field', name: { kind: 'Name', value: 'final_energy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'final_energy_scaled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApplyBuildingModelMutationsMutation, ApplyBuildingModelMutationsMutationVariables>;
export const DataCollectionSaveConsumptionInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionSaveConsumptionInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionInvoiceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoicePayer' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'invoice_payer_type_enum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'consumptions' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ConsumptionInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateConsumptionInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'buildingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionInvoiceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoicePayer' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoicePayer' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'consumptions' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'consumptions' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'UpdateConsumptionInvoiceOutput' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invoice_payer' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'building' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'consumptions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'area_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'to' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'display_unit_value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'consumption_type_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sub_type_id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'consumption_waste_detail' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'display_unit_volume' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'display_unit_frequency' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionSaveConsumptionInvoiceMutation,
  DataCollectionSaveConsumptionInvoiceMutationVariables
>;
export const DataCollectionInsertConsumptionDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionInsertConsumptionDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subBuildingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'areaId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamptz' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamptz' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitValue' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_unit_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cost' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'provider' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sourceTypeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_source_type_enum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionTypeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_type_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'energySourceTypeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_source_type_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subTypeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_sub_type_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_consumption_draft_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'area_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'areaId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'consumption_type_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionTypeId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cost' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cost' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by_user_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'energy_source_type_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'energySourceTypeId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'from' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'provider' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'provider' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'source_type_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'sourceTypeId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sub_building_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'subBuildingId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sub_type_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'subTypeId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'to' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'value' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'display_unit_value' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitValue' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'consumption_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by_user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sub_building_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sub_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionInsertConsumptionDraftMutation,
  DataCollectionInsertConsumptionDraftMutationVariables
>;
export const DataCollectionInsertConsumptionWasteDetailsDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionInsertConsumptionWasteDetailsDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionDraftId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'frequency' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitFrequency' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_unit_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'price' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'volume' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitVolume' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_unit_enum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_consumption_waste_details_draft_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'consumption_draft_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionDraftId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'amount' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'frequency' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'frequency' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'display_unit_frequency' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitFrequency' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'price' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'price' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'volume' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'volume' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'display_unit_volume' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitVolume' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_volume' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionInsertConsumptionWasteDetailsDraftMutation,
  DataCollectionInsertConsumptionWasteDetailsDraftMutationVariables
>;
export const DataCollectionSaveConsumptionDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionSaveConsumptionDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionDraftId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'areaId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamptz' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamptz' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitValue' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_unit_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cost' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'provider' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionTypeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_type_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'energySourceTypeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_source_type_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subTypeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_sub_type_enum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_consumption_draft_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionDraftId' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'area_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'areaId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'from' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'to' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'value' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'display_unit_value' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitValue' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cost' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cost' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'provider' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'provider' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'consumption_type_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionTypeId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'energy_source_type_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'energySourceTypeId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sub_type_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'subTypeId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'consumption_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by_user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sub_building_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sub_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'consumption_waste_details_draft' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionSaveConsumptionDraftMutation,
  DataCollectionSaveConsumptionDraftMutationVariables
>;
export const DataCollectionUpdateConsumptionWasteDetailsDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionUpdateConsumptionWasteDetailsDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'frequency' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitFrequency' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_unit_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'price' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'volume' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitVolume' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_unit_enum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_consumption_waste_details_draft_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'amount' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'frequency' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'frequency' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'display_unit_frequency' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitFrequency' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'price' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'price' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'volume' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'volume' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'display_unit_volume' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitVolume' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_volume' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionUpdateConsumptionWasteDetailsDraftMutation,
  DataCollectionUpdateConsumptionWasteDetailsDraftMutationVariables
>;
export const DataCollectionDeleteConsumptionWasteDetailsDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionDeleteConsumptionWasteDetailsDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_consumption_waste_details_draft_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionDeleteConsumptionWasteDetailsDraftMutation,
  DataCollectionDeleteConsumptionWasteDetailsDraftMutationVariables
>;
export const DataCollectionInsertConsumptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionInsertConsumption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subBuildingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'areaId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamptz' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamptz' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitValue' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_unit_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cost' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'provider' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sourceTypeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_source_type_enum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionTypeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_type_enum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'energySourceTypeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_source_type_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subTypeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_sub_type_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_consumption_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'area_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'areaId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'consumption_type_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionTypeId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cost' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cost' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by_user_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'energy_source_type_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'energySourceTypeId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'from' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'provider' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'provider' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'source_type_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'sourceTypeId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sub_building_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'subBuildingId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sub_type_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'subTypeId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'to' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'value' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'display_unit_value' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitValue' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionInsertConsumptionMutation, DataCollectionInsertConsumptionMutationVariables>;
export const DataCollectionInsertConsumptionWasteDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionInsertConsumptionWasteDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'frequency' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitFrequency' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_unit_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'price' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'volume' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitVolume' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_unit_enum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_consumption_waste_details_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'consumption_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'amount' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'frequency' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'frequency' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'display_unit_frequency' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitFrequency' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'price' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'price' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'volume' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'volume' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'display_unit_volume' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitVolume' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_volume' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionInsertConsumptionWasteDetailsMutation,
  DataCollectionInsertConsumptionWasteDetailsMutationVariables
>;
export const DataCollectionSaveConsumptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionSaveConsumption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'areaId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamptz' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamptz' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitValue' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_unit_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cost' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'provider' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionTypeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_type_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'energySourceTypeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_source_type_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subTypeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_sub_type_enum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_consumption_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionId' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'area_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'areaId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'from' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'to' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'value' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'display_unit_value' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitValue' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cost' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cost' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'provider' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'provider' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'consumption_type_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionTypeId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'energy_source_type_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'energySourceTypeId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sub_type_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'subTypeId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'consumption_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_by_user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sub_building_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sub_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'consumption_waste_detail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionSaveConsumptionMutation, DataCollectionSaveConsumptionMutationVariables>;
export const DataCollectionUpdateConsumptionWasteDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionUpdateConsumptionWasteDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'frequency' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitFrequency' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_unit_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'price' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'volume' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'numeric' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitVolume' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_unit_enum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_consumption_waste_details_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'amount' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'frequency' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'frequency' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'display_unit_frequency' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitFrequency' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'price' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'price' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'volume' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'volume' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'display_unit_volume' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'displayUnitVolume' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_volume' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionUpdateConsumptionWasteDetailsMutation,
  DataCollectionUpdateConsumptionWasteDetailsMutationVariables
>;
export const DataCollectionDeleteConsumptionWasteDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionDeleteConsumptionWasteDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_consumption_waste_details_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionDeleteConsumptionWasteDetailsMutation,
  DataCollectionDeleteConsumptionWasteDetailsMutationVariables
>;
export const DeleteConsumptionInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteConsumptionInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_consumption_invoice_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteConsumptionInvoiceMutation, DeleteConsumptionInvoiceMutationVariables>;
export const DeleteConsumptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteConsumption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_consumption_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteConsumptionMutation, DeleteConsumptionMutationVariables>;
export const DeleteConsumptionInvoiceDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteConsumptionInvoiceDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceDraftId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_consumption_invoice_draft_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceDraftId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'consumption_drafts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteConsumptionInvoiceDraftMutation, DeleteConsumptionInvoiceDraftMutationVariables>;
export const DataCollectionSubBuildingConsumptionDraftDeleteConsumptionDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataCollectionSubBuildingConsumptionDraftDeleteConsumptionDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionDraftId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_consumption_draft_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionDraftId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionSubBuildingConsumptionDraftDeleteConsumptionDraftMutation,
  DataCollectionSubBuildingConsumptionDraftDeleteConsumptionDraftMutationVariables
>;
export const CreateEmissionCertificateDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateEmissionCertificateDraft' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEmissionCertificateDraft' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'emission_certificate_draft_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'upload_url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'CreateEmissionCertificateDraftOutput' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'file_id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateEmissionCertificateDraftMutation, CreateEmissionCertificateDraftMutationVariables>;
export const UpdateEmissionCertificateDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateEmissionCertificateDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emission_factor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'float8' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'issuer' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'valid_from' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamptz' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'valid_until' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamptz' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'primary_energy_factor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'float8' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_emission_certificate_draft_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'emission_factor' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'emission_factor' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'issuer' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'issuer' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'valid_from' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'valid_from' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'valid_until' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'valid_until' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'primary_energy_factor' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'primary_energy_factor' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionEmissionCertificateDraft' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionDocumentDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'file' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionEmissionCertificateDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'emission_certificate_draft' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primary_energy_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'file_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionDocumentDraft' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'org' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid_from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid_until' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEmissionCertificateDraftMutation, UpdateEmissionCertificateDraftMutationVariables>;
export const CreateEmissionCertificateFromDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateEmissionCertificateFromDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'draft_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEmissionCertificateFromDraft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'draft_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'draft_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'CreateEmissionCertificateFromDraftOutput' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionEmissionCertificate' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionDocumentDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'file' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionEmissionCertificate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'emission_certificate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primary_energy_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'file_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionDocumentDraft' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'org' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid_from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid_until' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_system_consumer_routes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_system' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'building_model' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'building_id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primary_energy_factor_energy_system_consumer_routes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_system' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'building_model' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'building_id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateEmissionCertificateFromDraftMutation,
  CreateEmissionCertificateFromDraftMutationVariables
>;
export const UpdateEmissionCertificateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateEmissionCertificate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emissionCertificate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionCertificateUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEmissionCertificate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emissionCertificate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emissionCertificate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEmissionCertificateMutation, UpdateEmissionCertificateMutationVariables>;
export const DeleteEmissionCertificateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteEmissionCertificate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteEmissionCertificate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteEmissionCertificateMutation, DeleteEmissionCertificateMutationVariables>;
export const DeleteEmissionCertificateDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteEmissionCertificateDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_emission_certificate_draft_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteEmissionCertificateDraftMutation, DeleteEmissionCertificateDraftMutationVariables>;
export const UpdateAreasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAreas' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'areaMutations' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'AreaUpdateMutation' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'saveInvalidState' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAreas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'building_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'area_mutations' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'areaMutations' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'save_invalid_state' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'saveInvalidState' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scalingResult' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'final_energy' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'final_energy_scaled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'scaling_successful' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAreasMutation, UpdateAreasMutationVariables>;
export const RecalculateAreasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RecalculateAreas' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'areaMutations' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'AreaRecalculateMutation' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'saveInvalidState' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recalculateAreas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'building_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'area_mutations' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'areaMutations' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'save_invalid_state' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'saveInvalidState' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dry_run' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scalingResult' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'final_energy' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'final_energy_scaled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'scaling_successful' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecalculateAreasMutation, RecalculateAreasMutationVariables>;
export const RecalculateAreasDryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RecalculateAreasDry' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'areaMutations' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'AreaRecalculateMutation' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recalculateAreas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'building_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'area_mutations' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'areaMutations' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'save_invalid_state' },
                value: { kind: 'BooleanValue', value: false },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dry_run' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'areas' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecalculateAreasDryMutation, RecalculateAreasDryMutationVariables>;
export const DataCollectionGetEconomicUnitsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataCollectionGetEconomicUnitsList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionEconomicUnit' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionEconomicUnit' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'economic_unit' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer_economic_unit_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionGetEconomicUnitsListQuery, DataCollectionGetEconomicUnitsListQueryVariables>;
export const DataCollectionGetEconomicUnitsWithPortfoliosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataCollectionGetEconomicUnitsWithPortfolios' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionEconomicUnitPortfolios' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionEconomicUnitPortfolios' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'economic_unit' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer_economic_unit_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'associatedBuildingsAndDrafts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionGetEconomicUnitsWithPortfoliosQuery,
  DataCollectionGetEconomicUnitsWithPortfoliosQueryVariables
>;
export const DataCollectionGetEconomicUnitsListWithBuildingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataCollectionGetEconomicUnitsListWithBuildings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionGetEconomicUnitWithBuildings' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionSubBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'sub_building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'building_state_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'economic_unit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingsListBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'building_state_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'areas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'area_type_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'NUF' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type_of_use_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area_type_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sub_buildings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionSubBuilding' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'associatedBuildingsAndDrafts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'active_building_model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action_planning_available' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processing' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionGetEconomicUnitWithBuildings' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'economic_unit' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'associatedBuildingsAndDrafts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer_economic_unit_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buildings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionBuildingsListBuilding' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionGetEconomicUnitsListWithBuildingsQuery,
  DataCollectionGetEconomicUnitsListWithBuildingsQueryVariables
>;
export const DataCollectionBuildingGetBuildingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataCollectionBuildingGetBuilding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionBuildingWithSubBuildings' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionSubBuildingConsumption' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'display_unit_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'created_by_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumption_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoice_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_type_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_waste_detail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionSubBuildingConsumptionDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_draft' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'display_unit_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'created_by_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumption_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_type_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_invoice_draft' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'draft_status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_waste_details_draft' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionSubBuildingSubBuildingSubsidy' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'subsidy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount_description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider_updated_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'subsidy_category_type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingSubBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'sub_building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_standard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_building_class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'units_residential' } },
          { kind: 'Field', name: { kind: 'Name', value: 'units_commercial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'building_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionSubBuildingConsumption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_drafts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionSubBuildingConsumptionDraft' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subsidies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subsidy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'DataCollectionSubBuildingSubBuildingSubsidy' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'subsidies_updated_at' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingEnvelope' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'envelope' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inclination' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orientation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'envelope_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_thickness' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_material_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_material_category_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_method_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insulation_lambda' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base_construction_thickness' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base_construction_material_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base_construction_lambda' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'u_value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystemConsumerRoute' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_system_consumer_route' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_effective' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_primary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_factor_origin_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_certificate_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_certificate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'emission_factor' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'primary_energy_factor_emission_certificate_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primary_energy_factor_emission_certificate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'primary_energy_factor' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_consumer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_technology_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_consumer_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'efficiency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'construction_year' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_distribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_distribution_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heat_distribution_loss' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heat_transfer_loss' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_hydraulic_balancing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_balancing_set_to_never' } },
                { kind: 'Field', name: { kind: 'Name', value: 'construction_year' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_storage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_storage_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'annual_heat_loss' } },
                { kind: 'Field', name: { kind: 'Name', value: 'construction_year' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystemProducerRoute' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_system_producer_route' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_producer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_system' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_system_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data_source_type_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_system_consumer_routes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystemConsumerRoute' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_system_producer_routes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystemProducerRoute' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingModel' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building_model' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action_planning_available' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'envelope_units' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionBuildingEnvelope' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_systems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'energy_system_type_id' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionBuildingEnergySystem' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_paths' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'year' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'efficiency_class_id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingWithSubBuildings' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'building_state_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monument_protection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leasehold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'milieu_protection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heritage_district' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_state_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mapbox_building_ids' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mapbox_pitch' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mapbox_bearing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mapbox_zoom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'areas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area_system_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_rented' } },
                { kind: 'Field', name: { kind: 'Name', value: 'class_of_use_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data_source_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type_of_use_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parent_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'responsible_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sub_buildings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionBuildingSubBuilding' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'associatedBuildingsAndDrafts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emission_factor_type_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'active_building_model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionBuildingModel' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climate_risks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uncertainty' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timespan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climate_risks_aggregate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'max' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'created_at' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionBuildingGetBuildingQuery, DataCollectionBuildingGetBuildingQueryVariables>;
export const DataCollectionGetAllBuildingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataCollectionGetAllBuildings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionBuildingsListBuilding' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionSubBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'sub_building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'building_state_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'economic_unit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingsListBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'building_state_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'areas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'area_type_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'NUF' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type_of_use_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area_type_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sub_buildings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionSubBuilding' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'associatedBuildingsAndDrafts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'active_building_model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action_planning_available' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processing' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionGetAllBuildingsQuery, DataCollectionGetAllBuildingsQueryVariables>;
export const GET_ECONOMIC_UNIT_IDSDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GET_ECONOMIC_UNIT_IDS' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'buildings' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingIds' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GET_ECONOMIC_UNIT_IDSQuery, GET_ECONOMIC_UNIT_IDSQueryVariables>;
export const DataCollectionGetAllBuildingsTableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataCollectionGetAllBuildingsTable' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'building_bool_exp' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'building_order_by' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionBuildingsListBuilding' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionSubBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'sub_building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'building_state_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'economic_unit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingsListBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'building_state_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'areas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'area_type_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'NUF' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type_of_use_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area_type_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sub_buildings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionSubBuilding' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'associatedBuildingsAndDrafts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'active_building_model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action_planning_available' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processing' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionGetAllBuildingsTableQuery, DataCollectionGetAllBuildingsTableQueryVariables>;
export const DataCollectionGetAllEmissionCertificatesDropdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataCollectionGetAllEmissionCertificatesDropdown' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'emission_factor_emission_certificates' },
            name: { kind: 'Name', value: 'emission_certificate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'emission_factor' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'issuer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emission_factor' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'primary_energy_factor_emission_certificates' },
            name: { kind: 'Name', value: 'emission_certificate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'primary_energy_factor' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'issuer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primary_energy_factor' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionGetAllEmissionCertificatesDropdownQuery,
  DataCollectionGetAllEmissionCertificatesDropdownQueryVariables
>;
export const ReportAndActionsSubBuildingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReportAndActionsSubBuildings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_sub_building' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sub_building' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'building_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingIds' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'report_sub_buildings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'sub_building_id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'report_template' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'title' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action_plan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'building_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingIds' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'actions_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportAndActionsSubBuildingsQuery, ReportAndActionsSubBuildingsQueryVariables>;
export const DataCollectionGetConsumptionSummariesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataCollectionGetConsumptionSummaries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getYearlyConsumptionSummary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'building_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'year' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'consumptionCategories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionYearlyConsumption' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionYearlyConsumption' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'YearlyConsumption' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'providers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumption_type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionGetConsumptionSummariesQuery,
  DataCollectionGetConsumptionSummariesQueryVariables
>;
export const DataCollectionEnergyCertificateDraftGetEnergyCertificateDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataCollectionEnergyCertificateDraftGetEnergyCertificateDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'energyCertificateDraftId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_certificate_draft_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'energyCertificateDraftId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionEnergyCertificateDraftEnergyCertificateDraft' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'portfolio_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionGetEconomicUnitWithBuildings' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionEnergyCertificateDraftUser' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionSubBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'sub_building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'building_state_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'economic_unit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionBuildingsListBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'building_state_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'areas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'area_type_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'NUF' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type_of_use_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area_type_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sub_buildings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionSubBuilding' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'associatedBuildingsAndDrafts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'active_building_model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action_planning_available' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processing' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionEnergyCertificateDraftEnergyCertificateDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_certificate_draft' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country_state_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'building_state_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portfolio_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'economic_unit_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responsible_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area_usable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_building_class_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type_of_use_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draft_status_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_certificate_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiry_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'units' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monument_protection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leasehold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'milieu_protection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heritage_district' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_certificate_consumers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'energy_system_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionGetEconomicUnitWithBuildings' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'economic_unit' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'associatedBuildingsAndDrafts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer_economic_unit_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buildings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionBuildingsListBuilding' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionEnergyCertificateDraftUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'user' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionEnergyCertificateDraftGetEnergyCertificateDraftQuery,
  DataCollectionEnergyCertificateDraftGetEnergyCertificateDraftQueryVariables
>;
export const DataCollectionEnergyCertificateDraftsListGetAllEnergyCertificateDraftsSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: {
        kind: 'Name',
        value: 'DataCollectionEnergyCertificateDraftsListGetAllEnergyCertificateDraftsSubscription',
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_certificate_draft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DataCollectionEnergyCertificateDraftsListEnergyCertificateDraftSubscription',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionEnergyCertificateDraftsListEnergyCertificateDraftSubscription' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_certificate_draft' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draft_status_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionEnergyCertificateDraftsListGetAllEnergyCertificateDraftsSubscriptionSubscription,
  DataCollectionEnergyCertificateDraftsListGetAllEnergyCertificateDraftsSubscriptionSubscriptionVariables
>;
export const DataCollectionEnergyCertificateDraftsListGetAllEnergyCertificateDraftsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataCollectionEnergyCertificateDraftsListGetAllEnergyCertificateDrafts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_certificate_draft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionEnergyCertificateDraftsListEnergyCertificateDraft' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionEnergyCertificateDraftsListEnergyCertificateDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'energy_certificate_draft' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draft_status_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionEnergyCertificateDraftsListGetAllEnergyCertificateDraftsQuery,
  DataCollectionEnergyCertificateDraftsListGetAllEnergyCertificateDraftsQueryVariables
>;
export const EnergyCertificateDraftStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'EnergyCertificateDraftStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'energyCertificateDraftId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_certificate_draft_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'energyCertificateDraftId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'draft_status_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EnergyCertificateDraftStatusSubscription,
  EnergyCertificateDraftStatusSubscriptionVariables
>;
export const DataCollectionGetConsumptionInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataCollectionGetConsumptionInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionInvoiceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_invoice_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionInvoiceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoice_payer' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'building' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'areas' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'area_type_id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'consumptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionConsumptionConsumption' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionConsumptionConsumption' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'display_unit_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumption_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_waste_detail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionGetConsumptionInvoiceQuery,
  DataCollectionGetConsumptionInvoiceQueryVariables
>;
export const DataCollectionSubBuildingConsumptionGetConsumptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataCollectionSubBuildingConsumptionGetConsumptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_invoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'building_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionSubBuildingConsumptionGetConsumptionsQuery,
  DataCollectionSubBuildingConsumptionGetConsumptionsQueryVariables
>;
export const DataCollectionGetBuildingConsumptionPreconditionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataCollectionGetBuildingConsumptionPrecondition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orgId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'economic_unit' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'portfolio' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'org' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'id' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: '_eq' },
                                              value: { kind: 'Variable', name: { kind: 'Name', value: 'orgId' } },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_buildings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'building_models' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'energy_systems' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'energy_system_type_id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'areas' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'area_type_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      { kind: 'EnumValue', value: 'MFG_1' },
                                      { kind: 'EnumValue', value: 'MFG' },
                                      { kind: 'EnumValue', value: 'MFG_2' },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'area_type_id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'economic_unit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionGetBuildingConsumptionPreconditionQuery,
  DataCollectionGetBuildingConsumptionPreconditionQueryVariables
>;
export const DataCollectionGetConsumptionDraftsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataCollectionGetConsumptionDrafts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_draft' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionConsumptionDraftConsumptionDraft' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionConsumptionDraftConsumptionDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_draft' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumption_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'display_unit_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_building_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_waste_details_draft' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionGetConsumptionDraftsQuery, DataCollectionGetConsumptionDraftsQueryVariables>;
export const DataCollectionGetConsumptionInvoiceDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataCollectionGetConsumptionInvoiceDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionInvoiceDraftId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_invoice_draft_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'consumptionInvoiceDraftId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'draft_status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoice_payer' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'building' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'areas' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'area_type_id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'consumption_drafts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'DataCollectionConsumptionDraftConsumptionDraft' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionConsumptionDraftConsumptionDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'consumption_draft' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumption_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'display_unit_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_building_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_waste_details_draft' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_unit_volume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionGetConsumptionInvoiceDraftQuery,
  DataCollectionGetConsumptionInvoiceDraftQueryVariables
>;
export const DataCollectionSubBuildingGetConsumptionDraftsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataCollectionSubBuildingGetConsumptionDrafts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_invoice_draft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'building_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionSubBuildingGetConsumptionDraftsQuery,
  DataCollectionSubBuildingGetConsumptionDraftsQueryVariables
>;
export const getMissingEnergyCertificateFilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMissingEnergyCertificateFiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subBuildingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sub_building' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'subBuildingId' } },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'energy_certificates' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'file_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_is_null' },
                                        value: { kind: 'BooleanValue', value: true },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_certificates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<getMissingEnergyCertificateFilesQuery, getMissingEnergyCertificateFilesQueryVariables>;
export const getAllEnergyCertificatesForSubBuildingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllEnergyCertificatesForSubBuilding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sub_building_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_certificates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubBuildingDocumentsFile' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'consumptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'consumption_invoice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubBuildingDocumentsFile' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubBuildingDocumentsFile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'file' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  getAllEnergyCertificatesForSubBuildingQuery,
  getAllEnergyCertificatesForSubBuildingQueryVariables
>;
export const getAllEnergyCertificatesForSubBuildingWithAreaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllEnergyCertificatesForSubBuildingWithArea' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sub_building_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type_of_use_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_certificates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'area_usable' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubBuildingDocumentsFile' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubBuildingDocumentsFile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'file' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  getAllEnergyCertificatesForSubBuildingWithAreaQuery,
  getAllEnergyCertificatesForSubBuildingWithAreaQueryVariables
>;
export const getBuildingModelMutationAvailabilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBuildingModelMutationAvailability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingModelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBuildingModelMutationAvailability' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'building_model_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingModelId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BuildingModelMutationAvailability' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuildingModelMutationAvailability' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GetBuildingModelMutationAvailabilityOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'action_deletion_necessary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affected_action_plan_count' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'affected_users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  getBuildingModelMutationAvailabilityQuery,
  getBuildingModelMutationAvailabilityQueryVariables
>;
export const getEmissionCertificatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmissionCertificates' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_certificate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionEmissionCertificate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionDocumentDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'file' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionEmissionCertificate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'emission_certificate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primary_energy_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'file_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionDocumentDraft' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'org' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid_from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid_until' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_system_consumer_routes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_system' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'building_model' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'building_id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primary_energy_factor_energy_system_consumer_routes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_system' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'building_model' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'building_id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<getEmissionCertificatesQuery, getEmissionCertificatesQueryVariables>;
export const getEmissionCertificateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmissionCertificate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emissionCertificateId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_certificate_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emissionCertificateId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionEmissionCertificate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionDocumentDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'file' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionEmissionCertificate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'emission_certificate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primary_energy_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'file_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionDocumentDraft' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'org' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid_from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid_until' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_system_consumer_routes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_system' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'building_model' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'building_id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primary_energy_factor_energy_system_consumer_routes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_system' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'building_model' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'building_id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<getEmissionCertificateQuery, getEmissionCertificateQueryVariables>;
export const getEmissionCertificateDraftsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmissionCertificateDrafts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_certificate_draft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionEmissionCertificateDraft' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionDocumentDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'file' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionEmissionCertificateDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'emission_certificate_draft' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primary_energy_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'file_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionDocumentDraft' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'org' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid_from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid_until' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<getEmissionCertificateDraftsQuery, getEmissionCertificateDraftsQueryVariables>;
export const getEmissionCertificateDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmissionCertificateDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emissionCertificateDraftId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_certificate_draft_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emissionCertificateDraftId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionEmissionCertificateDraft' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionDocumentDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'file' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionEmissionCertificateDraft' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'emission_certificate_draft' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primary_energy_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'file_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollectionDocumentDraft' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'org' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid_from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid_until' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<getEmissionCertificateDraftQuery, getEmissionCertificateDraftQueryVariables>;
export const getEmissionCertificateDraftByFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmissionCertificateDraftByFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emissionCertificateDraftFileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_certificate_draft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'file_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'emissionCertificateDraftFileId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<getEmissionCertificateDraftByFileQuery, getEmissionCertificateDraftByFileQueryVariables>;
export const getAssociatedActionPlansAndReportsFromEmissionCertificateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAssociatedActionPlansAndReportsFromEmissionCertificate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emissionCertificateId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'emission_factor_buildings' },
            name: { kind: 'Name', value: 'building' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'building_models' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'energy_systems' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'energy_system_consumer_routes' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'emission_certificate_id' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: '_eq' },
                                              value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'emissionCertificateId' },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'economic_unit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'emission_factor_type_id' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'action_plans_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_buildings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'report_sub_buildings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'report_id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'primary_energy_factor_buildings' },
            name: { kind: 'Name', value: 'building' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'building_models' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'energy_systems' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'energy_system_consumer_routes' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'primary_energy_factor_emission_certificate_id' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: '_eq' },
                                              value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'emissionCertificateId' },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'economic_unit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'emission_factor_type_id' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'action_plans_aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_buildings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'report_sub_buildings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'report_id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  getAssociatedActionPlansAndReportsFromEmissionCertificateQuery,
  getAssociatedActionPlansAndReportsFromEmissionCertificateQueryVariables
>;
export const ALLEnergyCertificateFilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ALLEnergyCertificateFiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_certificate_draft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'portfolio_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ALLEnergyCertificateFilesQuery, ALLEnergyCertificateFilesQueryVariables>;
export const BuildingProcessingSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'BuildingProcessingSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingModelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building_model_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingModelId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'processing' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BuildingProcessingSubscriptionSubscription,
  BuildingProcessingSubscriptionSubscriptionVariables
>;
export const SubBuildingConsumptionTabsConsumptionDraftCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubBuildingConsumptionTabsConsumptionDraftCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subBuildingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_draft_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sub_building_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'subBuildingId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubBuildingConsumptionTabsConsumptionDraftCountQuery,
  SubBuildingConsumptionTabsConsumptionDraftCountQueryVariables
>;
export const hasSingleBuildingOrDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'hasSingleBuildingOrDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasSingleBuildingOrDraft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<hasSingleBuildingOrDraftQuery, hasSingleBuildingOrDraftQueryVariables>;
export const EsgAnalysisGetFilteredBuildingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EsgAnalysisGetFilteredBuildings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingWhere' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'building_bool_exp' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currentYear' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingWhere' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EsgAnalysisGetBuildings' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EsgAnalysisPortfolio' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EsgAnalysisGetBuildings' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EsgAnalysisPortfolio' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'areas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'area_type_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'NUF' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type_of_use_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'class_of_use_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area_type_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'active_building_model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energy_paths' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'year' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'currentYear' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'co2_emissions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'co2_costs' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'efficiency_class_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eu_taxonomy_compliance_id' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_primary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energy_final' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EsgAnalysisGetFilteredBuildingsQuery, EsgAnalysisGetFilteredBuildingsQueryVariables>;
export const EsgAnalysisGetAllBuildingFilterValuesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EsgAnalysisGetAllBuildingFilterValues' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currentYear' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_system_consumer_route' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'energy_source_type_id' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'energy_source_type_id' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'energy_path_tax_bracket' },
            name: { kind: 'Name', value: 'energy_path' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'year' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'currentYear' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'building_model' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'active_for_building' },
                            value: { kind: 'ObjectValue', fields: [] },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'ListValue', values: [{ kind: 'EnumValue', value: 'tax_bracket_id' }] },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'tax_bracket_id' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'energy_path_efficiency_class' },
            name: { kind: 'Name', value: 'energy_path' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'year' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'currentYear' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'building_model' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'active_for_building' },
                            value: { kind: 'ObjectValue', fields: [] },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'ListValue', values: [{ kind: 'EnumValue', value: 'efficiency_class_id' }] },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'efficiency_class_id' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'energy_path_eu_taxonomy_compliance' },
            name: { kind: 'Name', value: 'energy_path' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'year' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'currentYear' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'building_model' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'active_for_building' },
                            value: { kind: 'ObjectValue', fields: [] },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'ListValue', values: [{ kind: 'EnumValue', value: 'eu_taxonomy_compliance_id' }] },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'eu_taxonomy_compliance_id' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'sub_building_class' },
            name: { kind: 'Name', value: 'area' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'class_of_use_id' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'class_of_use_id' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'building_state_id' },
            name: { kind: 'Name', value: 'building' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'building_state_id' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'building_state_id' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'monument_protection' },
            name: { kind: 'Name', value: 'building' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'monument_protection' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'monument_protection' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'heritage_district' },
            name: { kind: 'Name', value: 'building' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'heritage_district' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'heritage_district' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'milieu_protection' },
            name: { kind: 'Name', value: 'building' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'milieu_protection' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'milieu_protection' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'leasehold' },
            name: { kind: 'Name', value: 'building' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinct_on' },
                value: { kind: 'EnumValue', value: 'leasehold' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leasehold' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EsgAnalysisGetAllBuildingFilterValuesQuery,
  EsgAnalysisGetAllBuildingFilterValuesQueryVariables
>;
export const EsgAnalysisGetAllBuildingListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EsgAnalysisGetAllBuildingList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'address' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'street' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EsgAnalysisGetBuildingsPortfolioAndAddress' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EsgAnalysisPortfolio' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EsgAnalysisGetBuildingsPortfolioAndAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EsgAnalysisPortfolio' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'street' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EsgAnalysisGetAllBuildingListQuery, EsgAnalysisGetAllBuildingListQueryVariables>;
export const EsgAnalysisGetAllBuildingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EsgAnalysisGetAllBuildings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'address' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'street' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EsgAnalysisGetBuildingsForSingleBuilding' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EsgAnalysisPortfolio' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EsgAnalysisGetBuildingsForSingleBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year_constructed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mapbox_building_ids' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EsgAnalysisPortfolio' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'responsible_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'areas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'area_type_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'NUF' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type_of_use_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'class_of_use_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EsgAnalysisGetAllBuildingsQuery, EsgAnalysisGetAllBuildingsQueryVariables>;
export const EsgAnalysisGetBenchmarkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EsgAnalysisGetBenchmark' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBenchmark' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'portfolioId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EsgAnalysisGetBenchmarkBuildings' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EsgAnalysisGetBenchmarkBuildings' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BenchmarkOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2Paths' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pathM2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'costsM2' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energyPaths' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pathM2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'costsM2' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'strandingDates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'co2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'onePointFive' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'startYear' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endYear' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averagePrimaryEnergy' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EsgAnalysisGetBenchmarkQuery, EsgAnalysisGetBenchmarkQueryVariables>;
export const EsgAnalysisGetPortfolioDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EsgAnalysisGetPortfolio' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingWhere' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BuildingWhere' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAnalyzedBuildings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingWhere' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'analyzedBuildingsCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'buildingsByEfficiencyClass' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'efficiencyClass' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'buildingsByTaxBracket' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxBracket' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'taxBracket' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'landlordProportion' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'buildingsByEUTaxonomyCompliance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'euTaxonomyCompliance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageTaxBracket' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'landlordProportion' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'climateRisks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timespan' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'co2Paths' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'pathM2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costsM2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costsTotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pathTotal' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'co2Targets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'onePointFive' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energyDataBySourceType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'energySourceType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'energyConsumptionTotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'energyConsumptionM2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'co2EmissionsTotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'co2EmissionsM2' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energyPaths' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'pathM2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pathTotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costsM2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costsTotal' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energyTargets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'onePointFive' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'strandingDates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'co2' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'onePointFive' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'startYear' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'endYear' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'averageEfficiencyClass' } },
                { kind: 'Field', name: { kind: 'Name', value: 'averagePrimaryEnergy' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'floorAreaBySubBuildingClass' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subBuildingClass' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope123Co2Emissions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'scope1' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'co2Emissions' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'co2EmissionsTotal' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'scope2' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'co2Emissions' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'co2EmissionsTotal' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'scope3' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'co2Emissions' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'co2EmissionsTotal' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'buildingsByEnergySourceType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'energySourceType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'buildingsGeneralData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'buildingId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'co2Intensity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'typeOfUse' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'finalEnergy' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainEnergySourceType' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EsgAnalysisGetPortfolioQuery, EsgAnalysisGetPortfolioQueryVariables>;
export const EsgAnalysisBuildingGetBuildingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EsgAnalysisBuildingGetBuilding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAnalyzedBuilding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'building_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'year' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'co2Targets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'onePointFive' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energyTargets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'onePointFive' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'co2Paths' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'pathM2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pathTotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costsM2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costsTotal' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energyPaths' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'pathM2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pathTotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costsM2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costsTotal' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'co2StrandingDates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'onePointFive' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'startYear' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endYear' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energyConsumers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'energySourceType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'co2EmissionsM2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'co2EmissionsTotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'energyConsumptionM2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'energyConsumptionTotal' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taxBracket' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'landlordProportion' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'efficiencyClass' } },
                { kind: 'Field', name: { kind: 'Name', value: 'euTaxonomyCompliance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryEnergy' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'floorAreaBySubBuildingClass' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subBuildingClass' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'climateRisks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timespan' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope123Co2Emissions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scope1' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'co2Emissions' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'co2EmissionsTotal' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scope2' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'co2Emissions' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'co2EmissionsTotal' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scope3' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'co2Emissions' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'co2EmissionsTotal' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EsgAnalysisBuildingGetBuildingQuery, EsgAnalysisBuildingGetBuildingQueryVariables>;
export const EsgAnalysisExportPdfDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EsgAnalysisExportPdf' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getEsgAnalysisPdf' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'buildingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'body' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EsgAnalysisExportPdfQuery, EsgAnalysisExportPdfQueryVariables>;
export const EsgAnalysisConsumptionAnalysisDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EsgAnalysisConsumptionAnalysis' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getConsumptionAnalysis' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'building_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'from' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'to' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'average' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'series' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'to' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sum' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EsgAnalysisConsumptionAnalysisQuery, EsgAnalysisConsumptionAnalysisQueryVariables>;
export const EsgAnalysisConsumptionAllocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EsgAnalysisConsumptionAllocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getConsumptionAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'building_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'buildingId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'from' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'to' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'carbonEmissions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'intensityAbsolute' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scope1' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scope2' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'co2Tax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'costShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalAbsolute' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'forecastItems' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'landlordShare' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'landlordSharePercentage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tenantShare' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tenantSharePercentage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'energyConsumptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'valueAbsolute' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalAbsolute' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EsgAnalysisConsumptionAllocationQuery, EsgAnalysisConsumptionAllocationQueryVariables>;
export const GetPortfolioAndBuildingPermissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPortfolioAndBuildingPermissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'user_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_portfolio_permission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'user_id' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'portfolio_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'read' } },
                { kind: 'Field', name: { kind: 'Name', value: 'write' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_building_permission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'user_id' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'building_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'read' } },
                { kind: 'Field', name: { kind: 'Name', value: 'write' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'economic_unit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'portfolio_id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPortfolioAndBuildingPermissionsQuery, GetPortfolioAndBuildingPermissionsQueryVariables>;
export const PortfolioManagementCreatePortfolioDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PortfolioManagementCreatePortfolio' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emissionFactorType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'emission_factor_type_enum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_portfolio_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'emission_factor_type_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'emissionFactorType' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PortfolioManagementCreatePortfolioMutation,
  PortfolioManagementCreatePortfolioMutationVariables
>;
export const PortfolioManagementDeletePortfolioDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PortfolioManagementDeletePortfolio' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_portfolio_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PortfolioManagementDeletePortfolioMutation,
  PortfolioManagementDeletePortfolioMutationVariables
>;
export const PortfolioManagementUpdatePortfolioDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PortfolioManagementUpdatePortfolio' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emissionFactorType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'emission_factor_type_enum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_portfolio_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'emission_factor_type_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'emissionFactorType' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PortfolioManagementUpdatePortfolioMutation,
  PortfolioManagementUpdatePortfolioMutationVariables
>;
export const PortfolioManagementGetAllPortfoliosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PortfolioManagementGetAllPortfolios' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PortfolioManagementPortfolio' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioManagementPortfolio' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_factor_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'economic_units_with_buildings' },
            name: { kind: 'Name', value: 'economic_units_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'buildings_aggregate' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'count' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'predicate' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_gt' },
                                        value: { kind: 'IntValue', value: '0' },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PortfolioManagementGetAllPortfoliosQuery,
  PortfolioManagementGetAllPortfoliosQueryVariables
>;
export const PortfolioManagementGetAllPortfoliosWithWritePermissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PortfolioManagementGetAllPortfoliosWithWritePermissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'user_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'org' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'users' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: '_and' },
                                            value: {
                                              kind: 'ListValue',
                                              values: [
                                                {
                                                  kind: 'ObjectValue',
                                                  fields: [
                                                    {
                                                      kind: 'ObjectField',
                                                      name: { kind: 'Name', value: 'id' },
                                                      value: {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                          {
                                                            kind: 'ObjectField',
                                                            name: { kind: 'Name', value: '_eq' },
                                                            value: {
                                                              kind: 'Variable',
                                                              name: { kind: 'Name', value: 'user_id' },
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                                {
                                                  kind: 'ObjectValue',
                                                  fields: [
                                                    {
                                                      kind: 'ObjectField',
                                                      name: { kind: 'Name', value: 'user_role' },
                                                      value: {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                          {
                                                            kind: 'ObjectField',
                                                            name: { kind: 'Name', value: 'role' },
                                                            value: {
                                                              kind: 'ObjectValue',
                                                              fields: [
                                                                {
                                                                  kind: 'ObjectField',
                                                                  name: { kind: 'Name', value: '_eq' },
                                                                  value: { kind: 'EnumValue', value: 'ORG_ADMIN' },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'user_permissions' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_and' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'write' },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: { kind: 'Name', value: '_eq' },
                                                  value: { kind: 'BooleanValue', value: true },
                                                },
                                              ],
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'user_id' },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: { kind: 'Name', value: '_eq' },
                                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'user_id' } },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PortfolioManagementPortfolio' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioManagementPortfolio' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_factor_type_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'economic_units_with_buildings' },
            name: { kind: 'Name', value: 'economic_units_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'buildings_aggregate' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'count' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'predicate' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_gt' },
                                        value: { kind: 'IntValue', value: '0' },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PortfolioManagementGetAllPortfoliosWithWritePermissionsQuery,
  PortfolioManagementGetAllPortfoliosWithWritePermissionsQueryVariables
>;
export const PortfolioObjectsAggregateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PortfolioObjectsAggregate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'economic_unit' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'portfolio_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioId' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'report_sub_buildings' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'sub_building' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'building' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'economic_unit' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: 'portfolio_id' },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: '_eq' },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: { kind: 'Name', value: 'portfolioId' },
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scenario_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'portfolio_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_certificate_draft_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'portfolio_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'portfolio_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action_plan_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'scenario' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'portfolio_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioId' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'action_plan' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'scenario' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'portfolio_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioId' } },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PortfolioObjectsAggregateQuery, PortfolioObjectsAggregateQueryVariables>;
export const ReportDeleteReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReportDeleteReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_report_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportDeleteReportMutation, ReportDeleteReportMutationVariables>;
export const CreateReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sub_buildings' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'due_date' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end_date' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'report_template_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'responsible_user_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'start_date' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parameters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sub_buildings' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'sub_buildings' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'due_date' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'due_date' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'end_date' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'end_date' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'report_template_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'report_template_id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'start_date' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'start_date' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'responsible_user_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'responsible_user_id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'report_id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateReportMutation, CreateReportMutationVariables>;
export const ResetReportAnswerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetReportAnswer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subBuildingId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rootQuestionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetAnswers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sub_building_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subBuildingId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'report_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'root_question_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rootQuestionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResetReportAnswerMutation, ResetReportAnswerMutationVariables>;
export const completeReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'completeReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_report_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'completed' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<completeReportMutation, completeReportMutationVariables>;
export const AcceptAutofillReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AcceptAutofillReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptAutofillReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'report_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_question_ids' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AcceptAutofillReportMutation, AcceptAutofillReportMutationVariables>;
export const AnswerQuestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AnswerQuestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'answers' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'AnswerParamsInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'report_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sub_building_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'answerQuestions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'report_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'report_id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'answers' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'answers' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sub_building_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sub_building_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_answer_ids' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ReportAnswerId' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AnswerQuestionsMutation, AnswerQuestionsMutationVariables>;
export const createEvidenceFromFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createEvidenceFromFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEvidenceFromFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'report_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'CreateEvidenceOutput' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'upload_url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<createEvidenceFromFileMutation, createEvidenceFromFileMutationVariables>;
export const createEvidenceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createEvidence' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'url' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_report_evidence_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'report_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'url' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'url' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<createEvidenceMutation, createEvidenceMutationVariables>;
export const deleteEvidenceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteEvidence' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'evidenceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_report_evidence_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'evidenceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<deleteEvidenceMutation, deleteEvidenceMutationVariables>;
export const ExportReportAsPdfDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ExportReportAsPdf' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportReportAsPdf' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'report_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'body' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExportReportAsPdfMutation, ExportReportAsPdfMutationVariables>;
export const ExportReportAsExcelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ExportReportAsExcel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportReportAsExcel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'report_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'body' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExportReportAsExcelMutation, ExportReportAsExcelMutationVariables>;
export const ReportGetReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReportGetReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReportGetReportByPk' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportGetReportByPkReportTemplateSections' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report_template_section' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_section_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportGetReportByPkReportTemplate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report_template' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'single_sub_building' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excel_template_path' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_sections' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'order' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReportGetReportByPkReportTemplateSections' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportGetReportByPkResponsibleUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'user' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportGetReportByPkSubBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'sub_building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'street' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'economic_unit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportGetReportByPk' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'due_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReportGetReportByPkReportTemplate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'responsible_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReportGetReportByPkResponsibleUser' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_sub_buildings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sub_building' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReportGetReportByPkSubBuilding' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_sub_buildings_aggregate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_answers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'state' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: { kind: 'EnumValue', value: 'UNANSWERED' },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: '_or' },
                                value: {
                                  kind: 'ListValue',
                                  values: [
                                    {
                                      kind: 'ObjectValue',
                                      fields: [
                                        {
                                          kind: 'ObjectField',
                                          name: { kind: 'Name', value: 'autofill' },
                                          value: {
                                            kind: 'ObjectValue',
                                            fields: [
                                              {
                                                kind: 'ObjectField',
                                                name: { kind: 'Name', value: '_is_null' },
                                                value: { kind: 'BooleanValue', value: false },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                    {
                                      kind: 'ObjectValue',
                                      fields: [
                                        {
                                          kind: 'ObjectField',
                                          name: { kind: 'Name', value: 'report_answer_autofill_options' },
                                          value: {
                                            kind: 'ObjectValue',
                                            fields: [
                                              {
                                                kind: 'ObjectField',
                                                name: { kind: 'Name', value: 'report_template_question_option' },
                                                value: {
                                                  kind: 'ObjectValue',
                                                  fields: [
                                                    {
                                                      kind: 'ObjectField',
                                                      name: { kind: 'Name', value: 'value' },
                                                      value: {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                          {
                                                            kind: 'ObjectField',
                                                            name: { kind: 'Name', value: '_is_null' },
                                                            value: { kind: 'BooleanValue', value: false },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_template_question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'evidence_required' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportGetReportQuery, ReportGetReportQueryVariables>;
export const ReportingReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReportingReports' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_template' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'excel_template_path' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsible_user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_sub_buildings' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'sub_building' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'building' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'address' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: 'street' },
                                              value: { kind: 'EnumValue', value: 'asc' },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReportGetReportsSubBuildings' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportGetReportsSubBuildings' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report_sub_building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sub_building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'building' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportingReportsQuery, ReportingReportsQueryVariables>;
export const CreateReportGetAllTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CreateReportGetAllTemplates' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'report_template' },
            name: { kind: 'Name', value: 'getAllLatestReportTemplates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CreateReportModalGetAllTemplatesTemplate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreateReportModalGetAllTemplatesTemplate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReportTemplateOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'single_sub_building' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateReportGetAllTemplatesQuery, CreateReportGetAllTemplatesQueryVariables>;
export const CreateReportModalAllUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CreateReportModalAllUsers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReportGetReportByPkResponsibleUser' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportGetReportByPkResponsibleUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'user' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateReportModalAllUsersQuery, CreateReportModalAllUsersQueryVariables>;
export const CreateReportModalSubBuildingsInPortfolioDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CreateReportModalSubBuildingsInPortfolio' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'portfolio_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sub_building' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'building' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'economic_unit' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'portfolio_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'portfolio_id' } },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'building' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'areas' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'area_type_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: { kind: 'EnumValue', value: 'NUF' },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'area_type_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'class_of_use_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type_of_use_id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateReportModalSubBuildingsInPortfolioQuery,
  CreateReportModalSubBuildingsInPortfolioQueryVariables
>;
export const AllReportAutofillQuestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllReportAutofillQuestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_answers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_and' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'state' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: '_eq' },
                                            value: { kind: 'EnumValue', value: 'UNANSWERED' },
                                          },
                                        ],
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'report_template_question' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'evidence_required' },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: { kind: 'Name', value: '_neq' },
                                                  value: { kind: 'BooleanValue', value: true },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_or' },
                                      value: {
                                        kind: 'ListValue',
                                        values: [
                                          {
                                            kind: 'ObjectValue',
                                            fields: [
                                              {
                                                kind: 'ObjectField',
                                                name: { kind: 'Name', value: 'autofill' },
                                                value: {
                                                  kind: 'ObjectValue',
                                                  fields: [
                                                    {
                                                      kind: 'ObjectField',
                                                      name: { kind: 'Name', value: '_is_null' },
                                                      value: { kind: 'BooleanValue', value: false },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                          {
                                            kind: 'ObjectValue',
                                            fields: [
                                              {
                                                kind: 'ObjectField',
                                                name: { kind: 'Name', value: 'report_answer_autofill_options' },
                                                value: {
                                                  kind: 'ObjectValue',
                                                  fields: [
                                                    {
                                                      kind: 'ObjectField',
                                                      name: { kind: 'Name', value: 'report_template_question_option' },
                                                      value: {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                          {
                                                            kind: 'ObjectField',
                                                            name: { kind: 'Name', value: 'value' },
                                                            value: {
                                                              kind: 'ObjectValue',
                                                              fields: [
                                                                {
                                                                  kind: 'ObjectField',
                                                                  name: { kind: 'Name', value: '_is_null' },
                                                                  value: { kind: 'BooleanValue', value: false },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReportAnswerForAutofill' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportAnswerForAutofill' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report_answer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sub_building_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autofill' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_answer_autofill_options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_template_question_option' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'report_question_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllReportAutofillQuestionsQuery, AllReportAutofillQuestionsQueryVariables>;
export const GetReportCompletionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReportCompletion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetReportCompletionQuery, GetReportCompletionQueryVariables>;
export const GetSectionsAnswerCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSectionsAnswerCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_answer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'report_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReportAnswersState' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportAnswersState' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report_answer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_building_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_template_question' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_template_section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSectionsAnswerCountQuery, GetSectionsAnswerCountQueryVariables>;
export const GetSectionsProgressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSectionsProgress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_section_progress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'report_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReportSectionsProgress' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportSectionsProgress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report_section_progress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'report_template_section_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sub_building_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSectionsProgressQuery, GetSectionsProgressQueryVariables>;
export const ReportQuestionGetOptionsWithSubquestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReportQuestionGetOptionsWithSubquestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'questionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_template_question_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'questionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_template_question_options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'report_template_child_questions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'question' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'parent_option_id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReportQuestionGetOptionsWithSubquestionsQuery,
  ReportQuestionGetOptionsWithSubquestionsQueryVariables
>;
export const SubsectionsProgressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubsectionsProgress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_sub_buildings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sub_building_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'report_sections_progress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubsectionProgress' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_sections_progress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubsectionProgress' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubsectionProgress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report_section_progress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_template_section' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubsectionsProgressQuery, SubsectionsProgressQueryVariables>;
export const ReportSectionGetRootQuestionsAndAnswersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReportSectionGetRootQuestionsAndAnswers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sectionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subBuildingWhere' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int_comparison_exp' } },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_answers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'report_template_question' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'parent_section_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'sectionId' } },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'sub_building_id' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'subBuildingWhere' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'report_template_question' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'order' },
                                  value: { kind: 'EnumValue', value: 'asc' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReportSectionGetRootQuestionsAndAnswersAnswers' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportSectionGetRootQuestionsAndAnswersAnswers' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report_answer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'report_question_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autofill' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_template_question' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'evidence_required' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_answer_autofill_options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_template_question_option' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_answer_chosen_options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_template_question_option' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReportSectionGetRootQuestionsAndAnswersQuery,
  ReportSectionGetRootQuestionsAndAnswersQueryVariables
>;
export const ReportSectionGetRootQuestionAndAnswerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReportSectionGetRootQuestionAndAnswer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'questionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_template_question_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'questionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_answers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'report_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'report_template_question' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'order' },
                                  value: { kind: 'EnumValue', value: 'asc' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReportSectionGetRootQuestionsAndAnswersAnswers' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportSectionGetRootQuestionsAndAnswersAnswers' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report_answer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'report_question_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autofill' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_template_question' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'evidence_required' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_answer_autofill_options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_template_question_option' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_answer_chosen_options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_template_question_option' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReportSectionGetRootQuestionAndAnswerQuery,
  ReportSectionGetRootQuestionAndAnswerQueryVariables
>;
export const GET_NESTED_QUESTION_ANSWERDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GET_NESTED_QUESTION_ANSWER' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'questionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_answers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'report_question_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'questionId' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReportSectionGetRootQuestionsAndAnswersAnswers' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportSectionGetRootQuestionsAndAnswersAnswers' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'report_answer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'report_question_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autofill' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_template_question' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'evidence_required' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_answer_autofill_options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_template_question_option' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_answer_chosen_options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_template_question_option' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GET_NESTED_QUESTION_ANSWERQuery, GET_NESTED_QUESTION_ANSWERQueryVariables>;
export const ReportHeadGetReportScoresDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'ReportHeadGetReportScores' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_template' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'report_template_scores' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'display' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: { kind: 'BooleanValue', value: true },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'order' },
                                  value: { kind: 'EnumValue', value: 'asc' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'display_type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max_score' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'report_scores' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'where' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'report_id' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: '_eq' },
                                              value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportHeadGetReportScoresSubscription, ReportHeadGetReportScoresSubscriptionVariables>;
export const ReportHeadGetReportProgressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReportHeadGetReportProgress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_sections_progress' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_and' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'report_template_section' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'parent_section_id' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: '_is_null' },
                                              value: { kind: 'BooleanValue', value: true },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'sub_building_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_is_null' },
                                        value: { kind: 'BooleanValue', value: true },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'report_template_section' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'order' },
                                  value: { kind: 'EnumValue', value: 'asc' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'report_template_section' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportHeadGetReportProgressQuery, ReportHeadGetReportProgressQueryVariables>;
export const GET_ALL_EVIDENCESDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GET_ALL_EVIDENCES' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_evidence' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'report_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GET_ALL_EVIDENCESQuery, GET_ALL_EVIDENCESQueryVariables>;
export const GET_QUESTION_EVIDENCESDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GET_QUESTION_EVIDENCES' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'questionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_question_evidence' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'report_evidence' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'report_id' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: '_eq' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'report_template_question_id' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: { kind: 'Variable', name: { kind: 'Name', value: 'questionId' } },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_evidence' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GET_QUESTION_EVIDENCESQuery, GET_QUESTION_EVIDENCESQueryVariables>;
export const GET_All_EVIDENCESDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GET_All_EVIDENCES' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_evidence' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'report_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_question_evidences' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'report_template_question' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'question' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GET_All_EVIDENCESQuery, GET_All_EVIDENCESQueryVariables>;
export const CreateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first_name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last_name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'role_enum' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first_name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first_name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last_name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'last_name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'role' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'user_id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first_name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last_name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_user_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'first_name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'first_name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'last_name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'last_name' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'created_at' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'user_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'role_enum' } },
          defaultValue: { kind: 'EnumValue', value: 'USER' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_user_role' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'user_id' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'affected_rows' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'user_id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;
export const ChangeUserLanguageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeUserLanguage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'language_enum' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_user_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'language' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChangeUserLanguageMutation, ChangeUserLanguageMutationVariables>;
export const DeleteUserAndtransferOwnershipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUserAndtransferOwnership' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'transferOwnershipToUserId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'transfer_ownership_to_user_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'transferOwnershipToUserId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'UserId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUserAndtransferOwnershipMutation, DeleteUserAndtransferOwnershipMutationVariables>;
export const UpsertBuildingPermissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertBuildingPermissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'objects' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'user_building_permission_insert_input' } },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_user_building_permission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'objects' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: { kind: 'EnumValue', value: 'user_building_permission_user_id_building_id_key' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'update_columns' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'EnumValue', value: 'read' },
                          { kind: 'EnumValue', value: 'write' },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'affected_rows' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertBuildingPermissionsMutation, UpsertBuildingPermissionsMutationVariables>;
export const UpsertPortfolioPermissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertPortfolioPermissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'objects' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'user_portfolio_permission_insert_input' } },
            },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_user_portfolio_permission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'objects' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: { kind: 'EnumValue', value: 'user_portfolio_permission_user_id_portfolio_id_key' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'update_columns' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'EnumValue', value: 'read' },
                          { kind: 'EnumValue', value: 'write' },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'affected_rows' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertPortfolioPermissionsMutation, UpsertPortfolioPermissionsMutationVariables>;
export const GetSessionDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSessionData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetSessionDataUser' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetSessionDataOrg' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'org' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServerSideFeatureFlags' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GetUserFeatureFlagsOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'BUILDING_CREATION_CONSUMER_EXTENSION' } },
          { kind: 'Field', name: { kind: 'Name', value: 'CONFIGMANAGER_EMISSIONFACTORTYPE_DISABLED' } },
          { kind: 'Field', name: { kind: 'Name', value: 'AREA_MANAGEMENT' } },
          { kind: 'Field', name: { kind: 'Name', value: 'CONSUMPTION_ANALYSIS' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetSessionDataUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'user' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'org' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetSessionDataOrg' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'role' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featureFlags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServerSideFeatureFlags' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSessionDataQuery, GetSessionDataQueryVariables>;
export const GetAllUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllUsers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetSessionDataUser' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetSessionDataOrg' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'org' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServerSideFeatureFlags' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GetUserFeatureFlagsOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'BUILDING_CREATION_CONSUMER_EXTENSION' } },
          { kind: 'Field', name: { kind: 'Name', value: 'CONFIGMANAGER_EMISSIONFACTORTYPE_DISABLED' } },
          { kind: 'Field', name: { kind: 'Name', value: 'AREA_MANAGEMENT' } },
          { kind: 'Field', name: { kind: 'Name', value: 'CONSUMPTION_ANALYSIS' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetSessionDataUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'user' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'org' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetSessionDataOrg' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'role' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featureFlags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServerSideFeatureFlags' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllUsersQuery, GetAllUsersQueryVariables>;
export const GetUserByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetSessionDataUser' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetSessionDataOrg' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'org' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServerSideFeatureFlags' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GetUserFeatureFlagsOutput' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'BUILDING_CREATION_CONSUMER_EXTENSION' } },
          { kind: 'Field', name: { kind: 'Name', value: 'CONFIGMANAGER_EMISSIONFACTORTYPE_DISABLED' } },
          { kind: 'Field', name: { kind: 'Name', value: 'AREA_MANAGEMENT' } },
          { kind: 'Field', name: { kind: 'Name', value: 'CONSUMPTION_ANALYSIS' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetSessionDataUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'user' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'org' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetSessionDataOrg' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'role' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featureFlags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServerSideFeatureFlags' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserByIdQuery, GetUserByIdQueryVariables>;
export const GetAllUserObjectsAggregateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllUserObjectsAggregate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'responsible_user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scenario_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'owner_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'responsible_user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energy_certificate_draft_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'responsible_user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action_plan_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'scenario' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'owner_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'action_plan' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'scenario' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'owner_id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by_user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consumption_draft_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_by_user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllUserObjectsAggregateQuery, GetAllUserObjectsAggregateQueryVariables>;
export const GetNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNotifications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'user_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'user_id' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notification_template' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'read' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const UpdateOrgePreferenceForEconomicUnitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOrgePreferenceForEconomicUnit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orgId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'economicUnitEnabled' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_org_preference' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'orgId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'economic_unit_enabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'economicUnitEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emission_factor_type_id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateOrgePreferenceForEconomicUnitMutation,
  UpdateOrgePreferenceForEconomicUnitMutationVariables
>;
export const UpdateOrgPreferenceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOrgPreference' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orgId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emissionFactorTypeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'emission_factor_type_enum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_org_preference' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'orgId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'emission_factor_type_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'emissionFactorTypeId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emission_factor_type_id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOrgPreferenceMutation, UpdateOrgPreferenceMutationVariables>;
export const GetAllPortfoliosAndBuildingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllPortfoliosAndBuildings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccessRightsPortfolio' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'building' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccessRightsBuilding' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccessRightsPortfolio' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccessRightsBuilding' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'building' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'house_number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postal_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'areas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'area_type_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'NUF' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type_of_use_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area_type_id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'economic_unit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllPortfoliosAndBuildingsQuery, GetAllPortfoliosAndBuildingsQueryVariables>;
export const GetOrgPreferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrgPreferences' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'org' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'org_preferences' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'economic_unit_enabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emission_factor_type_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrgPreferencesQuery, GetOrgPreferencesQueryVariables>;
export const AsyncJobSubscriptionOrgDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'AsyncJobSubscriptionOrg' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orgId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'async_job' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'orgId' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AsyncJob' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AsyncJob' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'async_job' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entity_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AsyncJobSubscriptionOrgSubscription, AsyncJobSubscriptionOrgSubscriptionVariables>;
export const AsyncJobSubscriptionEntityOrgDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'AsyncJobSubscriptionEntityOrg' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orgId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'async_job' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'orgId' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entity_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AsyncJob' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AsyncJob' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'async_job' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entity_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AsyncJobSubscriptionEntityOrgSubscription,
  AsyncJobSubscriptionEntityOrgSubscriptionVariables
>;
export const AsyncJobSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'AsyncJobSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'async_job' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entity_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AsyncJob' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AsyncJob' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'async_job' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entity_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AsyncJobSubscriptionSubscription, AsyncJobSubscriptionSubscriptionVariables>;
export const UpdateFileNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateFileName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_file_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'uploading' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFileNameMutation, UpdateFileNameMutationVariables>;
