/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button, ButtonProps, DialogContentText } from '@mui/material';
import { useRef } from 'react';
// TODO: replace with common useBlocker once a stable version has been released after 6.8.2
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { unstable_useBlocker } from 'react-router-dom';
import Iconify from '../Iconify';
import PreDialog from '../presentations/PreDialog/PreDialog';

type Props = {
  message: string;
  title: string;
  /**
   * Alternative action that is presented to the user instead of leaving the current navigation route.
   * The action must return a boolean that will triggers the initiated route navigation or lets the user stay on the current route.
   * If this actions returns false any upcoming route navigation will be successful.
   */
  primaryActionCallback: () => boolean | Promise<boolean>;
  primaryActionText?: string;
  secondaryActionText?: string;
  /**
   * boolean or getter to determine if the modal is displayed.
   */
  shouldBlock: (() => boolean) | boolean;

  /**
   * If true only one navigation will be blocked. If false multiple navigations can be blocked.
   */
  singleBlock?: boolean;

  /**
   * If true the search params of the current route will be blocked when they change.
   */
  blockOnSearchParamChange?: boolean;

  /** reverse the order of the actions */
  reverseActions?: boolean;

  primaryActionProps?: ButtonProps;
  secondaryActionProps?: ButtonProps;
};

/**
 * This modal is opened on router navigation when the the passed condition is true.
 * The user has the choice to perform the navigation regardlessly or to trigger and alternative action. The result of the alternative action determines if the route navigation takes place.
 * If the alternative action fails no navigation will be done but then any upcoming user navigation will be successful.
 */
function NavigationModal({
  message,
  title,
  primaryActionCallback,
  primaryActionText,
  secondaryActionText,
  shouldBlock,
  singleBlock = true,
  blockOnSearchParamChange = true,
  reverseActions,
  primaryActionProps,
  secondaryActionProps,
}: Props) {
  const { t } = useTranslation();
  const blocker = unstable_useBlocker((history) => {
    const { currentLocation, nextLocation } = history;

    const shouldBlockNavigation = typeof shouldBlock === 'function' ? shouldBlock() : shouldBlock;

    if (shouldBlockNavigation) {
      const isSearchParamsChanged =
        currentLocation.pathname === nextLocation.pathname && currentLocation.search !== nextLocation.search;

      if (!blockOnSearchParamChange && isSearchParamsChanged) {
        return false;
      }

      return true;
    }

    return false;
  });

  const errorHasStoppedNavigation = useRef(false);

  return (
    <PreDialog
      fullWidth
      open={blocker.state === 'blocked'}
      //@ts-ignore
      onClose={() => blocker.reset()}
      type="definedByProperty"
      dialogtitle={title}
      content={<DialogContentText>{message}</DialogContentText>}
      actions={
        <Stack direction={reverseActions ? 'row-reverse' : 'row'} spacing={1.5}>
          <Button
            variant="outlined"
            startIcon={<Iconify icon={'pepicons-pop:leave'} width={20} height={20} />}
            onClick={() => {
              //@ts-ignore
              blocker.proceed();
            }}
            {...secondaryActionProps}
          >
            {secondaryActionText ?? t('General_Leave')}
          </Button>
          <Button
            variant="contained"
            onClick={async () => {
              // Execute any actions that have to happen before navigation or that influence navigation.
              if (primaryActionCallback) {
                const success = await primaryActionCallback();

                if (!success && errorHasStoppedNavigation.current === false) {
                  //@ts-ignore
                  blocker.reset();

                  if (singleBlock) {
                    errorHasStoppedNavigation.current = true;
                  }
                } else {
                  //@ts-ignore
                  blocker.proceed();
                }
              } else {
                //@ts-ignore
                blocker.proceed();
              }
            }}
            autoFocus
            {...primaryActionProps}
          >
            {primaryActionText ?? t('General_ExecuteAndLeave')}
          </Button>
        </Stack>
      }
    />
  );
}

export default NavigationModal;
