import { InputAdornment, TextField, Toolbar, styled } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../components/Iconify';

//TODO Move this to common place.
export const TableToolbar = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
  pl: 3,
}));

type Props = {
  filterName: string;
  onFilterName: (value: string) => void;
};

export default function PortfolioTableToolbar({ filterName, onFilterName, children }: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  return (
    <TableToolbar>
      <TextField
        fullWidth
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder={t('General_SearchPortfolioAction')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          ),
        }}
        sx={{ mr: 3 }}
      />
      {children}
    </TableToolbar>
  );
}
