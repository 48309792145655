/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Alert, Button, Divider, Stack, Typography, alpha, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { ValidDocumentFragments } from '../../../components/documents/forms/useCustomDocumentForm';
import PreDialog from '../../../components/presentations/PreDialog/PreDialog';
import { GET_ASSOCIATED_ACTION_PLANS_AND_REPORTS_FROM_BUILDING } from '../../../graphql/DataCollection.queries';
import { AssociatedItem } from './EmissionCertificateAlertAssociatedItem';
import { getConflictsCount } from './utils';

type Props = {
  open: boolean;
  onClose: () => void;
  onSave?: (data: any) => void;
  emissionCertificate: ValidDocumentFragments;
};

export default function EmissionCertificateEditWarningModal({ open, onClose, onSave, emissionCertificate }: Props) {
  const theme = useTheme();
  const { t } = useTranslation();

  const { getValues } = useFormContext();

  const { data: associatedActionPlansAndReport, loading } = useQuery(
    GET_ASSOCIATED_ACTION_PLANS_AND_REPORTS_FROM_BUILDING,
    {
      variables: {
        emissionCertificateId: emissionCertificate.id,
      },
      // we don't want to prefetch this query for every modal, so we only fetch it when the modal is open
      skip: !open,
      fetchPolicy: 'cache-and-network',
    },
  );

  const {
    associatedActionPlans,
    associatedBuildings,
    associatedReports,
    hasEmissionFactorAssociated,
    hasPrimaryEnergyFactorAssociated,
  } = getConflictsCount(associatedActionPlansAndReport);

  const values = getValues();

  const emissionCertificateName = values.issuer;

  const isEmissionFactorDisabled = emissionCertificate.emission_factor !== null && !values.has_emission_factor;
  const isEmissionFactorChanged =
    values.emission_factor_value !== emissionCertificate.emission_factor || isEmissionFactorDisabled;

  const isPrimaryEnergyFactorDisabled =
    emissionCertificate.primary_energy_factor !== null && !values.has_primary_energy_factor;
  const isPrimaryEnergyFactorChanged =
    values.primary_energy_factor !== emissionCertificate.primary_energy_factor || isPrimaryEnergyFactorDisabled;

  const getFirstInfoBoxTranslation = () => {
    const bothHasLinkedBuildings = hasEmissionFactorAssociated && hasPrimaryEnergyFactorAssociated;

    switch (true) {
      case isEmissionFactorChanged && isPrimaryEnergyFactorChanged && bothHasLinkedBuildings:
        return t('DataCollection_EmissionCertificates_EditEmissionFactorAndPrimaryEnergyFactorChanged');
      case isEmissionFactorChanged && hasEmissionFactorAssociated:
        return t('DataCollection_EmissionCertificates_EditEmissionFactorChanged');
      case isPrimaryEnergyFactorChanged && hasPrimaryEnergyFactorAssociated:
        return t('DataCollection_EmissionCertificates_EditPrimaryEnergyFactorChanged');
      default:
        return '';
    }
  };

  const getSecondInfoBoxTranslation = () => {
    if (!open) return;

    switch (true) {
      case isEmissionFactorDisabled && isPrimaryEnergyFactorDisabled:
        throw new Error('Cannot have both factors disabled');
      case isEmissionFactorDisabled && hasEmissionFactorAssociated:
        return t('DataCollection_EmissionCertificates_EmissionFactorDisabled');
      case isPrimaryEnergyFactorDisabled && hasPrimaryEnergyFactorAssociated:
        return t('DataCollection_EmissionCertificates_PrimaryEnergyFactorDisabled');
      default:
        return '';
    }
  };

  const firstInfoBoxTranslation = getFirstInfoBoxTranslation();
  const secondInfoBoxTranslation = getSecondInfoBoxTranslation();

  return (
    <PreDialog
      type="definedByProperty"
      open={open}
      onClose={() => {
        onClose();
      }}
      dialogtitle={t('DataCollection_EmissionCertificates_EditWarningModal-title')}
      content={
        <Stack gap={2}>
          <Typography>{firstInfoBoxTranslation}</Typography>
          {secondInfoBoxTranslation && <Typography>{secondInfoBoxTranslation}</Typography>}
          <Alert
            severity={'warning'}
            sx={{
              backgroundColor: alpha(theme.palette.warning.main, 0.08),
              color: theme.palette.warning.dark,
              border: `1px solid ${alpha(theme.palette.warning.main, 0.16)}`,
            }}
          >
            {t('DataCollection_EmissionCertificates_FallbackWarning')}
          </Alert>

          <Typography variant="body1">
            <Trans
              i18nKey={'DataCollection_EmissionCertificates_DeleteEmissionCertificate-isAssociated'}
              components={{
                b: <b />,
              }}
              values={{
                name: emissionCertificateName,
              }}
            />
          </Typography>
          <Stack spacing={1}>
            <AssociatedItem label={t('General_Building_other')} count={associatedBuildings} loading={loading} />
            <Divider />
            <AssociatedItem label={t('General_ActionPlan_other')} count={associatedActionPlans} loading={loading} />
            <Divider />
            <AssociatedItem label={t('General_Report_other')} count={associatedReports} loading={loading} />
          </Stack>
        </Stack>
      }
      actions={
        <>
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
            }}
          >
            {t('General_Cancel')}
          </Button>
          <Button onClick={onSave} variant="contained" autoFocus>
            {t('General_Save')}
          </Button>
        </>
      }
    />
  );
}
