/* eslint-disable @typescript-eslint/ban-ts-comment */
import { PropsWithChildren, createContext, useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useSearchParams } from 'react-router-dom';
import {
  EsgAnalysisFiltersKeys,
  EsgAnalysisFiltersSearchParams,
  FindEsgAnalysisFilterValue,
} from '../sections/EsgAnalysis/EsgAnalysisFilters/EsgAnalysisFilters';
import { filtersToSearchParams, searchParamsToFilters } from '../sections/EsgAnalysis/EsgAnalysisUtil';

type TContext = {
  filters: EsgAnalysisFiltersSearchParams;
  setFilter: <Key extends EsgAnalysisFiltersKeys>(filter: Key, value: FindEsgAnalysisFilterValue<Key>) => void;
  replaceFilter: <Key extends EsgAnalysisFiltersKeys>(filter: Key, value: FindEsgAnalysisFilterValue<Key>[]) => void;
  resetFilter: (filter: EsgAnalysisFiltersKeys) => void;
  resetAllFilters: VoidFunction;
};

const EsgAnalysisFilterContext = createContext<TContext | null>(null);

function EsgAnalysisFilterContextProvider({ children }: PropsWithChildren) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<EsgAnalysisFiltersSearchParams>(() => searchParamsToFilters(searchParams));

  useEffect(() => {
    setSearchParams(filtersToSearchParams(filters));
  }, [filters, setSearchParams]);

  const addOrExcludeIfExist = useCallback(<T,>(currentValues: T[], newValue: T): T[] => {
    if (currentValues.includes(newValue)) {
      return currentValues.filter((value) => value !== newValue);
    }

    return [...currentValues, newValue];
  }, []);

  const setFilter = useCallback(
    <Key extends EsgAnalysisFiltersKeys>(key: Key, value: FindEsgAnalysisFilterValue<Key>) => {
      //@ts-ignore
      setFilters((prevState) => ({ ...prevState, [key]: addOrExcludeIfExist(prevState[key], value) }));
    },
    [addOrExcludeIfExist],
  );

  const replaceFilter = useCallback(
    <Key extends EsgAnalysisFiltersKeys>(key: Key, value: FindEsgAnalysisFilterValue<Key>[]) => {
      setFilters((prevState) => ({ ...prevState, [key]: value }));
    },
    [],
  );

  const resetAllFilters = useCallback(() => {
    setFilters(searchParamsToFilters(new URLSearchParams()));
  }, []);

  const resetFilter = useCallback(
    (filter: EsgAnalysisFiltersKeys) => setFilters((prevState) => ({ ...prevState, [filter]: [] })),
    [],
  );

  return (
    <EsgAnalysisFilterContext.Provider value={{ filters, setFilter, replaceFilter, resetAllFilters, resetFilter }}>
      {children}
    </EsgAnalysisFilterContext.Provider>
  );
}

export { EsgAnalysisFilterContext, EsgAnalysisFilterContextProvider };
