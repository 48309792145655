import { envelope_type_enum } from '@predium/enums';
import { FieldErrors, FieldNamesMarkedBoolean } from 'react-hook-form';
import { EnvelopeFormValues } from '../sections/DataCollection/Building/BuildingEnvelope';
import { EnergySystemFormValues } from '../sections/DataCollection/Building/BuildingTechnology';

/**
 * Calculate the total count of fields or errors.
 *
 * @param { FieldErrors<EnergySystemFormValues | EnvelopeFormValues>} fields - The fields or errors object.
 * @returns {number} The total count.
 */
export const calculateTotalCount = (
  fields:
    | FieldErrors<EnergySystemFormValues | EnvelopeFormValues>
    | FieldNamesMarkedBoolean<EnergySystemFormValues | EnvelopeFormValues>,
  isErrors: boolean = false,
  ignoreFields: string[] = [],
): number => {
  return Object.values(fields).reduce((count: number, array: any[]) => {
    array.forEach((object) => {
      for (const key in object) {
        if (object.hasOwnProperty(key) && (isErrors || object[key] === true) && !ignoreFields.includes(key)) {
          count++;
        }
      }
    });
    return count;
  }, 0);
};

//extraFields which are not show in the UI. so will never be edited. they only used for UI manipulations and
//will be removed later from the code during the updating envelopes UI with new designs
let extraFields = [
  'envelope_id',
  'data_source_type_id',
  'insulation_material_custom',
  'insulation_deleted',
  'has_insulation',
  'id',
  'delete',
  'create',
  'envelope_type',
  'u_value',
];

/**
 * Calculate the total count of envelope edited fields
 *
 * @param {FieldNamesMarkedBoolean<EnergySystemFormValues | EnvelopeFormValues>} fields - The dirty fields object.
 * @param {EnvelopeFormValues} values - The current form data object.
 * @returns {number} The total count.
 */
export const calculateTotalEnvelopeEditCount = (
  dirtyFields: FieldNamesMarkedBoolean<EnergySystemFormValues | EnvelopeFormValues>,
  values?: EnvelopeFormValues,
): number => {
  return Object.keys(dirtyFields).reduce((total, key) => {
    // @ts-ignore - Its too confusing to add types afterwards...
    if (values[key]) {
      if (key === envelope_type_enum.DOOR || key === envelope_type_enum.WINDOW) {
        extraFields = extraFields.filter((item) => item !== 'u_value');
      } else {
        extraFields.push('u_value');
      }

      // @ts-ignore - Its too confusing to add types afterwards...
      const editedFields = dirtyFields[key].flatMap((obj, index) =>
        Object.keys(obj).filter(
          (nestedKey) =>
            !extraFields.includes(nestedKey) &&
            // @ts-ignore - Its too confusing to add types afterwards...
            obj[nestedKey] !== values[key][index]?.[nestedKey] &&
            // @ts-ignore - Its too confusing to add types afterwards...
            values[key][index]?.[nestedKey] !== '',
        ),
      );

      total += editedFields.length;
    }
    return total;
  }, 0);
};
