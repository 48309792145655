import { graphql } from '@predium/client-graphql';

graphql(`
  fragment PortfolioManagementPortfolio on portfolio {
    id
    name
    emission_factor_type_id
    created_at
    updated_at
    __typename
    economic_units_with_buildings: economic_units_aggregate(
      where: { buildings_aggregate: { count: { predicate: { _gt: 0 } } } }
    ) {
      aggregate {
        count
      }
    }
  }
`);

export const GET_PORTFOLIOS = graphql(`
  query PortfolioManagementGetAllPortfolios {
    portfolio(order_by: { name: asc }) {
      ...PortfolioManagementPortfolio
    }
  }
`);

export const GET_PORTFOLIOS_WITH_WRITE_PERMISSIONS = graphql(`
  query PortfolioManagementGetAllPortfoliosWithWritePermissions($user_id: Int!) {
    portfolio(
      order_by: { name: asc }
      where: {
        _or: [
          { org: { users: { _and: [{ id: { _eq: $user_id } }, { user_role: { role: { _eq: ORG_ADMIN } } }] } } }
          { user_permissions: { _and: { write: { _eq: true }, user_id: { _eq: $user_id } } } }
        ]
      }
    ) {
      ...PortfolioManagementPortfolio
    }
  }
`);

export const GET_PORTFOLIO_OBJECTS_AGGREGATE = graphql(`
  query PortfolioObjectsAggregate($portfolioId: Int!) {
    building_aggregate(where: { economic_unit: { portfolio_id: { _eq: $portfolioId } } }) {
      aggregate {
        count
      }
    }

    report_aggregate(
      where: {
        report_sub_buildings: { sub_building: { building: { economic_unit: { portfolio_id: { _eq: $portfolioId } } } } }
      }
    ) {
      aggregate {
        count
      }
    }

    scenario_aggregate(where: { portfolio_id: { _eq: $portfolioId } }) {
      aggregate {
        count
      }
    }

    energy_certificate_draft_aggregate(where: { portfolio_id: { _eq: $portfolioId } }) {
      aggregate {
        count
      }
    }

    economic_unit_aggregate(where: { portfolio_id: { _eq: $portfolioId } }) {
      aggregate {
        count
      }
    }

    action_plan_aggregate(where: { scenario: { portfolio_id: { _eq: $portfolioId } } }) {
      aggregate {
        count
      }
    }

    action_aggregate(where: { action_plan: { scenario: { portfolio_id: { _eq: $portfolioId } } } }) {
      aggregate {
        count
      }
    }
  }
`);
