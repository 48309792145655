/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import {
  CreateReportModalGetAllTemplatesTemplateFragment,
  CreateReportMutationVariables,
} from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DelayedLoading } from '../../../components/Loading';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { CREATE_REPORT } from '../../../graphql/Report.mutations';
import { GET_REPORTS, GET_TEMPLATES } from '../../../graphql/Report.queries';
import usePosthogTracking from '../../../hooks/usePosthogTracking';
import { PATHS } from '../../../routes';
import CreateReportModalParameter from './CreateReportModalParameter';
import CreateReportModalTemplateSelection from './CreateReportModalTemplateSelection';

export default function CreateReport() {
  const { trackEvent } = usePosthogTracking();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);

  //@ts-ignore
  const [selectedTemplate, setSelectedTemplate] = useState<CreateReportModalGetAllTemplatesTemplateFragment>(null);

  const { data, loading } = useQuery(GET_TEMPLATES);

  const [createReportMutation] = useMutation(CREATE_REPORT, {
    refetchQueries: [GET_REPORTS],
    onError: () => {
      enqueueSnackbar(t('Reporting_CreateReport-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
    onCompleted: ({ createReport }) => {
      enqueueSnackbar(t('Reporting_CreateReport-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      navigate(PATHS.reporting.report({ id: createReport.report_id }, { init: true }));
    },
  });

  if (loading) return <DelayedLoading />;

  const handleTemplateSelection = (template: CreateReportModalGetAllTemplatesTemplateFragment) => {
    setSelectedTemplate(template);
    setActiveStep(1);
  };

  const handleParameterSelection = async (parameter: CreateReportMutationVariables) => {
    const result = await createReportMutation({
      variables: parameter,
    });
    trackEvent('REPORT_CREATED', {
      template: parameter.report_template_id,
    });
    return result;
  };

  //check if data is empty and return error message

  //@ts-ignore
  if (!data.report_template.length) {
    return (
      <Box sx={{ p: 4 }}>
        <p>{t('Reporting_CreateReportNoReportTemplates')}</p>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4 }}>
      <Stepper activeStep={activeStep}>
        <Step>
          <StepLabel>{t('Reporting_CreateReportStepperChooseTemplate')}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{t('Reporting_CreateReportStepperSetDetails')}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{t('Reporting_CreateReportStepperCreateReport')}</StepLabel>
        </Step>
      </Stepper>

      {activeStep === 0 && (
        <CreateReportModalTemplateSelection
          //@ts-ignore

          //@ts-ignore
          templates={data.report_template}
          onTemplateSelection={handleTemplateSelection}
        />
      )}

      {activeStep === 1 && (
        <CreateReportModalParameter template={selectedTemplate} onParameterSelection={handleParameterSelection} />
      )}
    </Box>
  );
}
