import { CircularProgress, Stack, StackProps, Tooltip, Typography } from '@mui/material';
import { data_source_type_enum } from '@predium/client-graphql';
import { useTranslation } from 'react-i18next';
import Iconify from '../Iconify';

type Props = {
  value: number | string;
  isEdited?: boolean;
  source?: data_source_type_enum;
  suffix?: string;
  showTooltip?: boolean;
  isLoading?: boolean;
} & StackProps;

const Value = ({ value, source, isEdited, suffix, showTooltip, isLoading = false, ...props }: Props) => {
  const { t } = useTranslation();
  const getPrintedValue = () => {
    if (value === undefined) {
      return '–';
    }
    return `${value} ${suffix}`;
  };

  const tooltipTitle =
    source === data_source_type_enum.APPROXIMATED ? t('General_ApproximatedValue') : t('General_CustomValue');

  return (
    <Tooltip title={showTooltip ? tooltipTitle : undefined} arrow placement="top">
      <Stack direction="row" px={0.25} py={0.25} alignItems="center" {...props}>
        {isLoading ? (
          <CircularProgress color="info" size={16} sx={{ mr: 1 }} />
        ) : (
          <>
            {!isEdited && source === data_source_type_enum.APPROXIMATED && (
              <Iconify width={20} height={20} color="info.main" icon="mdi:chart-timeline-variant-shimmer" mr={1} />
            )}
            {(isEdited || source === data_source_type_enum.MANUAL) && (
              <Iconify color="success.main" icon="mdi:pencil-box" mr={1} />
            )}
          </>
        )}

        <Typography
          variant="body2"
          {...(isLoading && {
            sx: {
              color: 'info.main',
            },
          })}
        >
          {getPrintedValue()}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export default Value;
