/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Card, Grid, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../components/Iconify';
import InlineUser from '../../../components/InlineUser';
import { useLanguage } from '../../../provider/LanguageProvider';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '../../../utils/formatTime';
import { ReportDC } from '../ReportingDataClasses/Report.dc';

type Props = {
  report: ReportDC;
};

function ReportInfo({
  report: { report_template, end_date, start_date, due_date, responsible_user, report_sub_buildings },
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { language } = useLanguage();

  return (
    <Card sx={{ height: '100%' }}>
      <Box
        sx={{
          backgroundColor: report_template.color,
          width: '100%',
          height: 8,
        }}
      />
      {report_template.logo && (
        <Box sx={{ height: 60 }}>
          <Box
            component="img"
            src={report_template.logo}
            alt={report_template.title}
            sx={{ maxHeight: 60, mx: 3, mt: 3 }}
          />
        </Box>
      )}
      <Stack
        direction="column"
        justifyContent="space-between"
        style={{
          // full height - logo - colored border - margin
          height: 'calc(100% - 60px - 8px - 24px)',
        }}
      >
        <Grid container sx={{ mt: 3, mx: 3, flexGrow: 1 }}>
          <Grid item xs={6}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Iconify icon="ph:folder" color="text.secondary" />
              <Typography variant="body2" color="text.secondary">
                {t('General_Portfolio')}
              </Typography>
            </Stack>

            <Typography variant="subtitle1">{report_sub_buildings[0].building.economic_unit.portfolio.name}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Iconify icon="mdi:stopwatch-outline" color="text.secondary" />
              <Typography variant="body2" color="text.secondary">
                {t('General_Deadline')}
              </Typography>
            </Stack>

            <Typography variant="subtitle1">
              {formatDateToLocale(due_date, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language)}
            </Typography>
          </Grid>

          <Grid item xs={6} marginTop={2}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Iconify icon="carbon:calendar" color="text.secondary" />
              <Typography variant="body2" color="text.secondary">
                {t('General_ReportingPeriod')}
              </Typography>
            </Stack>

            <Typography variant="subtitle1">
              {`${formatDateToLocale(start_date, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language)} - ${formatDateToLocale(
                end_date,
                COMMON_DATE_FORMATS.DAY_MONTH_YEAR,
                language,
              )}`}
            </Typography>
          </Grid>
        </Grid>

        <Box sx={{ borderTop: `1px solid ${theme.palette.grey[500]}`, py: 2 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" color="text.secondary" sx={{ ml: 6 }}>
              {t('General_CreatedBy')}
            </Typography>
            {/*@ts-ignore */}
            <InlineUser firstName={responsible_user.first_name} lastName={responsible_user.last_name} sx={{ mr: 6 }} />
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
}

export default ReportInfo;
