import { Box, Stack, Typography, styled, useTheme } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import Iconify from '../Iconify';
import { SemanticButton } from '../Tile';

type Props = {
  name: string;
  icon?: string;
  title: string;
  subtitle?: string;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
};

// TODO replace that with the MUI Radio once it works again
// see PRE-2906

export default function RHFRadioButton({ name, icon, title, subtitle, onChange, disabled }: Props) {
  const theme = useTheme();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={false}
      render={({ field: { ref, ...field } }) => (
        <Box sx={{ mb: 2 }}>
          <SemanticButton
            type="button"
            disabled={disabled}
            onClick={() => {
              field.onChange(!field.value);
              onChange?.(!field.value);
            }}
            sx={{
              display: 'block',
              width: '100%',
              border: `1px solid ${theme.palette.grey[500_24]}`,
              '&:focus': {
                borderColor: theme.palette.grey[500],
              },
              '&:hover': { cursor: 'pointer' },
              borderRadius: '8px',
              opacity: disabled ? 0.5 : 1,
            }}
          >
            <Box
              sx={{
                borderRadius: 'inherit',
                py: 2,
                px: 4,
                textAlign: 'left',
                backgroundColor: !!field.value ? theme.palette.grey[500_12] : 'inherit',

                ':hover': {
                  backgroundColor: theme.palette.grey[500_24],
                },
              }}
            >
              <Stack direction="row" alignItems={'center'} alignContent={'center'} spacing={4}>
                <RadioButtonStyled tabIndex={-1} type="radio" checked={field.value} readOnly />
                {!!icon && <Iconify icon={icon} width={48} height={48} />}
                <Box>
                  <Typography variant="subtitle2" fontWeight={700} fontSize={14}>
                    {title}
                  </Typography>
                  <Typography color="text.disabled">{subtitle}</Typography>
                </Box>
              </Stack>
            </Box>
          </SemanticButton>
        </Box>
      )}
    />
  );
}

const RadioButtonStyled = styled('input')(({ theme }) => ({
  accentColor: theme.palette.primary.main,
  width: '20px',
  height: '20px',
}));
