import { Checkbox } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

// ----------------------------------------------------------------------

export type HeadLabel = {
  id: string;
  label: string;
  sortingDisabled?: boolean;
  minWidth?: number;
};

type Props = {
  order: 'asc' | 'desc';
  orderBy: string;
  rowCount: number;
  headLabel: HeadLabel[];
  onRequestSort: (id: string) => void;
  numSelected: number;
  onSelectAllClick: (checked: boolean) => void;
};

export default function EconomicUnitTableHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  onRequestSort,
  numSelected,
  onSelectAllClick,
}: Props) {
  return (
    <TableHead>
      <TableRow>
        <TableCell width={20}></TableCell>
        {onSelectAllClick && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onSelectAllClick ? onSelectAllClick(event.target.checked) : () => {}
              }
            />
          </TableCell>
        )}
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ minWidth: headCell.minWidth }}
          >
            {!headCell.sortingDisabled ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => onRequestSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
