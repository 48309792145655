/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Card, Grid, Tab, Tabs } from '@mui/material';
import { report_template_section_target_enum } from '@predium/client-graphql';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportDC } from '../ReportingDataClasses/Report.dc';
import { ReportSectionDC, ReportTemplateDC } from '../ReportingDataClasses/ReportTemplate.dc';
import ReportSectionContent from './ReportSectionContent';
import ReportSectionsEntity from './ReportSectionsEntity';
import ReportSectionsSubBuildings from './ReportSectionsSubBuildings';

// ----------------------------------------------------------------------

type Props = {
  report: ReportDC;
};

export default function ReportRootSectionOrigin({ report }: Props) {
  const { t } = useTranslation();
  const entityRootSections = useMemo(
    () =>
      report.report_template.rootSections.filter((rootSection) => {
        const lastSection = rootSection.children.length > 0 ? getLastSection(rootSection.children) : rootSection;
        return lastSection.target === report_template_section_target_enum.ORG;
      }),
    [report],
  );

  const subBuildingRootSections = useMemo(
    () =>
      report.report_template.rootSections.filter((rootSection) => {
        const lastSection = rootSection.children.length > 0 ? getLastSection(rootSection.children) : rootSection;
        return lastSection.target === report_template_section_target_enum.SUB_BUILDING;
      }),
    [report],
  );

  const [currentTab, setCurrentTab] = useState(entityRootSections.length > 0 ? 0 : 1);
  const [currentSubBuildingId, setCurrentSubBuildingId] = useState<number | null>(null);
  const [activeSection, setActiveSection] = useState<ReportSectionDC | null>(null);
  const [triggerSkipToNextIncompleteSection, setTriggerSkipToNextIncompleteSection] = useState(false);

  const handleTabChange = (value: number) => {
    if (value === 0) {
      setCurrentSubBuildingId(null);
    }

    setActiveSection(null);
    setCurrentTab(value);
  };

  return (
    <Card sx={{ mt: 4, mb: 1 }}>
      <Grid container>
        <Grid item xs={4} sx={{ p: 1 }}>
          <Tabs
            sx={{ ml: 2, mb: 1 }}
            value={currentTab}
            scrollButtons="auto"
            variant="scrollable"
            allowScrollButtonsMobile
            onChange={(_, value) => handleTabChange(value)}
          >
            <Tab
              value={0}
              label={t('ReportRootSectionOrigin_PortfolioLevel')}
              disabled={entityRootSections.length === 0}
            />
            <Tab
              value={1}
              label={t('ReportRootSectionOrigin_BuildingLevel')}
              disabled={subBuildingRootSections.length === 0}
            />
          </Tabs>

          {currentTab === 0 && (
            <ReportSectionsEntity
              entityRootSections={entityRootSections}
              report={report}
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              triggerSkipToNextIncompleteSection={triggerSkipToNextIncompleteSection}
              skipToNextSectionCompleted={() => setTriggerSkipToNextIncompleteSection(false)}
            />
          )}
          {currentTab === 1 && (
            <ReportSectionsSubBuildings
              report={report}
              subBuildingRootSections={subBuildingRootSections}
              currentSubBuildingId={currentSubBuildingId}
              setCurrentSubBuildingId={setCurrentSubBuildingId}
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              triggerSkipToNextIncompleteSection={triggerSkipToNextIncompleteSection}
              skipToNextSectionCompleted={() => setTriggerSkipToNextIncompleteSection(false)}
            />
          )}
        </Grid>

        <Grid item xs={8}>
          {activeSection && (
            <ReportSectionContent
              reportId={report.id}
              section={activeSection}
              subBuildingId={currentSubBuildingId}
              goToNextIncompleteSection={() => setTriggerSkipToNextIncompleteSection(true)}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
}

/**
 * provide any section array and get the very first sub section that has no children (has questions). Traverses the tree downwards in a recursive manner.
 */

//@ts-ignore
export const getLastSection = (sections: ReportSectionDC[]): ReportSectionDC => {
  for (let i = 0; i < sections.length; i++) {
    if (sections[i].children.length === 0) {
      return sections[i];
    }

    return getLastSection(sections[i].children);
  }
};

/**
 *
 * @param lookupSection the section you want to know if it contains the target section
 * @param targetSection the current active section
 * @returns boolean indicating if the section contains the target section
 */
export const doesSectionContainTargetSection = (
  lookupSectionId: number,
  targetSection: ReportSectionDC,
  reportSections: ReportTemplateDC['report_sections'],
): boolean => {
  if (lookupSectionId === targetSection.id) return true;

  const parentSection = reportSections.find((section) => section.id === lookupSectionId);

  //@ts-ignore
  if (parentSection.parent_section_id === targetSection.id) return true;

  //@ts-ignore
  if (parentSection.parent_section_id === null) return false;

  //@ts-ignore

  //@ts-ignore
  return doesSectionContainTargetSection(parentSection.parent_section_id, targetSection, reportSections);
};
