// Source: https://docs.google.com/spreadsheets/d/16W9uJFLP5pBg-8xPM-VRAwG_BdGjrxeA
import { country_enum } from '@predium/enums';
import { TargetPath } from './TargetPathTypes';

//
//ATTENTION: ALL of these paths have to match TARGET_PATH_YEARS_RANGE.length !!
//
export const Co2TargetPaths: TargetPath = {
  //GERMANY
  [country_enum.DE]: {
    onePointFive: {
      MFH: [
        33.42507176, 31.24771073, 28.77232834, 26.38860401, 24.05612869, 21.96047276, 19.81525533, 17.83396452,
        15.98649421, 14.24603127, 12.54782966, 11.11557881, 9.718440362, 8.412229153, 7.189508732, 6.050346118,
        4.959165669, 3.969067528, 3.12672323, 2.451522861, 1.909485126, 1.609601942, 1.382703894, 1.18376801,
        1.010933721, 0.8497844602, 0.7336258017, 0.6226235849, 0.5245909198, 0.4346517927, 0.3489624909,
      ],
      SFH: [
        42.92694638, 39.93696869, 36.57875727, 33.37054152, 30.27245937, 27.4892079, 24.67239016, 22.09349697,
        19.69982115, 17.46183777, 15.30061332, 13.48201413, 11.72512807, 10.09472352, 8.58081617, 7.181796421,
        5.857069386, 4.661657459, 3.651525018, 2.84645085, 2.203944422, 1.847126635, 1.577645122, 1.342812867,
        1.13999904, 0.9524533713, 0.8173394242, 0.6893717337, 0.5771272309, 0.4749826155, 0.3785660603,
      ],
      OFFICE: [
        55.26532331, 51.6865314, 47.85979313, 44.14787616, 40.27128332, 36.96641857, 33.55085846, 30.26740148,
        27.2898626, 24.46663213, 21.6426881, 19.29326117, 16.9682583, 14.79624393, 12.74984964, 10.83101135,
        8.891809615, 7.200719395, 5.754778536, 4.591143709, 3.652082851, 3.136969309, 2.746292295, 2.402387522,
        2.102530559, 1.822482106, 1.619311076, 1.425192671, 1.253397022, 1.095408091, 0.9442307829,
      ],
      RETAIL: [
        72.69937739, 68.08608949, 63.02076058, 58.10684601, 53.07102153, 48.69593729, 44.17328102, 39.87493578,
        35.93249151, 32.19418636, 28.47609889, 25.36543508, 22.29399711, 19.41775105, 16.70778378, 14.16668121,
        11.6368469, 9.397582365, 7.482832391, 5.94177956, 4.698406401, 4.015410802, 3.497546733, 3.041615145,
        2.643998472, 2.272251099, 2.002934472, 1.745210746, 1.516966344, 1.306899468, 1.105810932,
      ],
      SHOPPING_CENTER: [
        74.76747112, 70.02547811, 64.806052, 59.74259288, 54.56254452, 50.05431069, 45.39391689, 40.96930601,
        36.90681463, 33.05466198, 29.22533114, 26.0199593, 22.85565484, 19.89179296, 17.09926836, 14.4807478,
        11.87743093, 9.569966191, 7.596890108, 6.008881441, 4.72764777, 4.023765404, 3.490077567, 3.020208349,
        2.610429064, 2.227273121, 1.9497271, 1.684090023, 1.448822643, 1.232276575, 1.024978416,
      ],
      RETAIL_WAREHOUSE: [
        48.48301194, 45.30625069, 41.96085929, 38.71606823, 35.28998139, 32.40103034, 29.4157151, 26.52666872,
        23.92411587, 21.45650599, 18.98004815, 16.92645665, 14.89153686, 12.99320431, 11.20469083, 9.527679793,
        7.818012439, 6.339956278, 5.076206203, 4.05924532, 3.238447727, 2.788577294, 2.447328306, 2.146959916,
        1.885094787, 1.640682796, 1.463217576, 1.293817768, 1.143959496, 1.006209339, 0.8744287651,
      ],
      HOTEL: [
        61.62437728, 57.6685987, 53.39049844, 49.24055456, 44.9415194, 41.24664977, 37.42764633, 33.77434804,
        30.4451824, 27.28848427, 24.13870024, 21.51186657, 18.91485096, 16.48620344, 14.19799268, 12.05239146,
        9.897953884, 8.007100285, 6.39031798, 5.089146072, 4.039189005, 3.462896591, 3.025870605, 2.641141491,
        2.305659346, 1.992194358, 1.764918828, 1.54762164, 1.355255173, 1.178288217, 1.008922469,
      ],
      DISTRIBUTION_WAREHOUSE_COLD: [
        41.60108386, 38.54183512, 35.43678838, 32.46091153, 29.32684978, 26.72875969, 24.08821742, 21.53520248,
        19.27642241, 17.15747724, 15.0472738, 13.31417759, 11.6146307, 10.04953405, 8.590819201, 7.237676913,
        5.849903279, 4.683256879, 3.694456325, 2.904601758, 2.27161852, 1.923692873, 1.660285316, 1.430261831,
        1.231270583, 1.047741573, 0.9142877356, 0.7886045365, 0.6784782888, 0.5783855677, 0.4837688493,
      ],
      DISTRIBUTION_WAREHOUSE_WARM: [
        16.71301303, 15.419405, 14.32869215, 13.27196721, 11.95609714, 11.01532886, 10.04532513, 9.003738853,
        8.157659383, 7.355813166, 6.507016615, 5.839148884, 5.163008293, 4.546629647, 3.966056632, 3.42178946,
        2.787284911, 2.307102335, 1.89675275, 1.566827805, 1.299987589, 1.155722626, 1.045999629, 0.9495601644,
        0.8656527187, 0.7881656042, 0.7311090596, 0.6774937182, 0.6303923925, 0.587442747, 0.5465184836,
      ],
      HEALTH_FACILITY: [
        62.48609284, 58.48593544, 54.15470091, 49.95319257, 45.60442757, 41.86364662, 37.9971499, 34.30031395,
        30.92975485, 27.73380096, 24.54565911, 21.88616915, 19.25713224, 16.79827391, 14.48159655, 12.30930083,
        10.12952573, 8.215156016, 6.578262283, 5.260899531, 4.197887779, 3.614393397, 3.171911217, 2.782376269,
        2.442700332, 2.125301555, 1.895188447, 1.675162656, 1.480374596, 1.301173549, 1.129666661,
      ],
    },
  },

  //AUSTRIA
  [country_enum.AT]: {
    onePointFive: {
      MFH: [
        20.77988167, 19.40271476, 17.94441374, 16.53500715, 15.07050886, 13.8240927, 12.53534968, 11.30391269,
        10.18571615, 9.127023996, 8.070435439, 7.194510497, 6.328313242, 5.519677346, 4.759369783, 4.047873309,
        3.339430959, 2.715878783, 2.183504416, 1.755483279, 1.411151064, 1.220447769, 1.076271739, 0.9494212348,
        0.8388270775, 0.7350572045, 0.660458876, 0.5886492662, 0.5249715873, 0.4662907588, 0.4101477894,
      ],
      SFH: [
        27.66489034, 25.70277925, 23.60896466, 21.605849, 19.58946049, 17.84727012, 16.07266936, 14.40872829,
        12.89470892, 11.47509298, 10.08182114, 8.925674839, 7.798033149, 6.753815709, 5.781996312, 4.881863585,
        4.004146038, 3.231971233, 2.578223798, 2.056242955, 1.639255109, 1.406845438, 1.231322653, 1.077979082,
        0.9451780912, 0.8218185509, 0.7329565266, 0.6483448924, 0.5738639637, 0.5058558966, 0.4414995759,
      ],
      OFFICE: [
        45.26201443, 40.38361325, 37.78363447, 35.28685431, 30.82509417, 28.62852207, 26.36375882, 23.24737903,
        21.28665915, 19.43457568, 17.17003258, 15.64310062, 13.99419474, 12.59037061, 11.27427631, 10.04662351,
        8.091996508, 7.01706675, 6.103801206, 5.374778113, 4.793397332, 4.481219888, 4.250785254, 4.050895782,
        3.87952589, 3.720193298, 3.611858995, 3.508903814, 3.421197009, 3.344230482, 3.275153151,
      ],
      RETAIL: [
        54.55837737, 49.11236607, 45.84172158, 42.69759035, 37.61406525, 34.84578203, 31.99090351, 28.33542308,
        25.86357349, 23.52813702, 20.79191541, 18.86581546, 16.8260013, 15.05459363, 13.39330615, 11.84301696,
        9.58337101, 8.226274056, 7.072368072, 6.150081061, 5.413402267, 5.015298828, 4.71998225, 4.463082675,
        4.242077752, 4.036235256, 3.89453615, 3.759505522, 3.643404122, 3.540312694, 3.446275297,
      ],
      SHOPPING_CENTER: [
        46.86058306, 41.88806667, 39.17688509, 36.5727679, 32.00791317, 29.71654587, 27.35393585, 24.148168,
        22.10269791, 20.17046987, 17.82771795, 16.2346001, 14.52087788, 13.05610487, 11.68277423, 10.40162522,
        8.396423146, 7.274707359, 6.32154132, 5.560473214, 4.953342532, 4.626917734, 4.385725051, 4.176382757,
        3.9967829, 3.82973852, 3.715872012, 3.607598414, 3.515183512, 3.433885226, 3.360669277,
      ],
      RETAIL_WAREHOUSE: [
        40.19349261, 35.62576642, 33.39286896, 31.25040912, 27.12896502, 25.24531255, 23.30354226, 20.48223311,
        18.80127433, 17.21374867, 15.20737349, 13.89892163, 12.46397499, 11.26134356, 10.13418686, 9.083125452,
        7.295447137, 6.374958413, 5.593404701, 4.970162436, 4.473782272, 4.208635453, 4.01371322, 3.845027585,
        3.700824868, 3.566948948, 3.476876494, 3.391477447, 3.319312103, 3.256644747, 3.201229026,
      ],
      HOTEL: [
        47.46312037, 42.45206713, 39.69535193, 37.04728404, 32.44021572, 30.11003315, 27.70735834, 24.46498924,
        22.38481694, 20.41977254, 18.04499687, 16.42477825, 14.68451603, 13.19478661, 11.79801971, 10.4949659,
        8.46905513, 7.32818212, 6.358678241, 5.584488838, 4.966814483, 4.634555897, 4.388958174, 4.175745284,
        3.992775549, 3.822573464, 3.706441715, 3.595990413, 3.501647712, 3.418575725, 3.343665157,
      ],
      DISTRIBUTION_WAREHOUSE_COLD: [
        36.54477243, 32.17826977, 30.18313008, 28.27016274, 24.36914061, 22.68816551, 20.95559705, 18.32540453,
        16.82564639, 15.40947427, 13.57031388, 12.40337112, 11.10700541, 10.03468606, 9.029903836, 8.093220289,
        6.413674276, 5.593216676, 4.896972434, 4.342244181, 3.900918733, 3.666233549, 3.494312621, 3.345837644,
        3.219231047, 3.101843359, 3.023599064, 2.949571089, 2.887471914, 2.834066101, 2.787498976,
      ],
      DISTRIBUTION_WAREHOUSE_WARM: [
        19.71240662, 16.39704792, 15.64389846, 14.9297455, 12.18015543, 11.55791884, 10.91817114, 9.286326541,
        8.733117614, 8.21205567, 7.246385742, 6.818652894, 6.24620112, 5.854601168, 5.489074168, 5.149873442,
        4.035184928, 3.737266192, 3.486658381, 3.289831081, 3.136098409, 3.060537577, 3.008770011, 2.965870716,
        2.931182078, 2.899925, 2.883468207, 2.868840007, 2.85932412, 2.854298404, 2.853954244,
      ],
      HEALTH_FACILITY: [
        57.49741475, 51.87016035, 48.38536617, 45.03452981, 39.75246948, 36.80163367, 33.75832594, 29.93070574,
        27.29564776, 24.80587225, 21.91903938, 19.86548143, 17.70084669, 15.81206624, 14.04055541, 12.38724547,
        10.03020418, 8.583008658, 7.352263979, 6.368266031, 5.581999161, 5.156458765, 4.840424789, 4.565321367,
        4.328467524, 4.10777399, 3.955421198, 3.810148585, 3.684980532, 3.573547183, 3.471539809,
      ],
    },
  },

  //POLAND
  [country_enum.PL]: {
    onePointFive: {
      MFH: [
        66.60604006, 62.68570628, 58.01681988, 53.46872627, 49.0268316, 44.9517477, 40.70748852, 36.79239652,
        33.07756675, 29.54764018, 26.07894337, 23.14259554, 20.25327594, 17.53029714, 14.96607275, 12.56346695,
        10.27436082, 8.16407154, 6.361405125, 4.911530157, 3.744799841, 3.098354421, 2.609443768, 2.179202629,
        1.804047099, 1.452057705, 1.198892419, 0.9552113895, 0.7390785099, 0.5398431538, 0.3491296238,
      ],
      SFH: [
        82.23591143, 77.10966977, 71.09166121, 65.26591551, 59.62080918, 54.45413791, 49.12190603, 44.2291701,
        39.60939035, 35.24504943, 30.98785851, 27.39175553, 23.87878896, 20.58746165, 17.50696577, 14.63836782,
        11.92550375, 9.438218421, 7.324498709, 5.631971276, 4.276207091, 3.523447422, 2.955191465, 2.457600764,
        2.025845644, 1.623393313, 1.3344235, 1.058237696, 0.8147193377, 0.591744137, 0.3798756641,
      ],
      OFFICE: [
        157.2009649, 145.1766659, 133.8814565, 123.0106542, 110.756599, 101.1981607, 91.40806034, 81.55454664,
        73.14374289, 65.23870228, 57.17769947, 50.71909093, 44.31534275, 38.47345312, 33.03758173, 28.00593285,
        22.58419253, 18.27130458, 14.62714728, 11.72555945, 9.415661138, 8.139623248, 7.183136426, 6.352189639,
        5.637347472, 4.97678327, 4.509232741, 4.067114199, 3.683425435, 3.338727522, 3.018728246,
      ],
      RETAIL: [
        193.3923509, 179.0227773, 164.9833946, 151.469042, 136.6824263, 124.7983185, 112.625693, 100.6018329,
        90.14404129, 80.31459285, 70.39075508, 62.35920853, 54.42933218, 47.16408535, 40.40321735, 34.14448428,
        27.57445265, 22.21000263, 17.67648763, 14.06569469, 11.19013874, 9.599378338, 8.405706895, 7.368035504,
        6.474651752, 5.648709647, 5.062587088, 4.507925513, 4.025581077, 3.591157502, 3.18654415,
      ],
      SHOPPING_CENTER: [
        185.6771197, 171.8043721, 158.3463186, 145.391979, 131.1418642, 119.7504292, 108.0825209, 96.51842968,
        86.49427464, 77.07249521, 67.54328279, 59.84494943, 52.23837003, 45.27464901, 38.79446998, 32.795684,
        26.46890787, 21.3272221, 16.98210978, 13.52155575, 10.76582944, 9.241740258, 8.098314308, 7.104435722,
        6.248874448, 5.457966209, 4.8969621, 4.366141785, 3.904696693, 3.489280717, 3.102592119,
      ],
      RETAIL_WAREHOUSE: [
        150.444869, 138.8556984, 128.0696266, 117.689293, 105.9051141, 96.77816677, 87.43010604, 77.97926558,
        69.94824784, 62.40025047, 54.68486704, 48.51809212, 42.39749273, 36.81968436, 31.62964501, 26.82566206,
        21.61696892, 17.49918157, 14.02003044, 11.25002433, 9.045074792, 7.827428655, 6.914947664, 6.122355336,
        5.440638719, 4.810757755, 4.365206379, 3.943968406, 3.578583631, 3.250532886, 2.946232405,
      ],
      HOTEL: [
        179.8928548, 166.3955997, 153.3769048, 140.8458135, 127.0011767, 115.9820953, 104.6956436, 93.47903785,
        83.78261782, 74.6689476, 65.43798621, 57.99148965, 50.6292525, 43.8934195, 37.62538036, 31.8230579, 25.68013009,
        20.70680571, 16.50408487, 13.15707883, 10.4919177, 9.018217474, 7.912766202, 6.951985463, 6.125009766,
        5.360579519, 4.818559503, 4.305758001, 3.860108273, 3.459057913, 3.085916535,
      ],
      DISTRIBUTION_WAREHOUSE_COLD: [
        119.3929522, 110.3714604, 102.4036371, 94.67204209, 85.36284039, 78.46858805, 71.31667034, 63.82597548,
        57.59218148, 51.68462581, 45.49938182, 40.60648039, 35.67229563, 31.16100112, 26.92527181, 22.96859948,
        18.51577895, 15.05690972, 12.11160587, 9.751089865, 7.859199809, 6.819160339, 6.038194062, 5.354795164,
        4.762669091, 4.209867308, 3.818448391, 3.444141435, 3.11656306, 2.81938729, 2.54049601,
      ],
      DISTRIBUTION_WAREHOUSE_WARM: [
        50.70307695, 45.58531324, 42.37134444, 39.28546128, 34.48947418, 31.77983667, 29.00596942, 25.54384967,
        23.16116437, 20.92325978, 18.34806872, 16.52156235, 14.6120673, 12.96178332, 11.42785828, 10.00981605,
        7.969647443, 6.753475865, 5.728363734, 4.915294662, 4.271186073, 3.921965909, 3.663957594, 3.44178844,
        3.252728508, 3.079181172, 2.960810175, 2.850116535, 2.756956161, 2.676488899, 2.6056673,
      ],
      HEALTH_FACILITY: [
        189.5343945, 175.417602, 161.6739948, 148.4445723, 133.9309239, 122.2974839, 110.3816545, 98.59172103,
        88.3545602, 78.73253043, 69.00947483, 61.14746444, 53.38207895, 46.270246, 39.65219026, 33.52571616,
        27.07945094, 21.82834157, 17.39068432, 13.85633171, 11.04174411, 9.484905481, 8.316798536, 7.301408568,
        6.427268585, 5.619151426, 5.04580893, 4.503277594, 4.031566574, 3.606813911, 3.211320528,
      ],
    },
  },
};
