import { Stack, Typography, useTheme } from '@mui/material';
import Link from '@mui/material/Link';
import { ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Iconify from '../Iconify';

type TLink = {
  href?: string;
  name: string;
  icon?: ReactElement;
  external?: boolean;
};

type PreLinkProps = {
  link: TLink;
};

export function PreLink({ link }: PreLinkProps) {
  const { href, name, icon, external } = link;
  const theme = useTheme();
  return (
    <Link
      key={name}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        ml: 0.5,
        color: theme.palette.link,
      }}
      component={RouterLink}
      to={href || '#'}
      target={external ? '_blank' : undefined}
      rel="noopener noreferrer"
      underline="hover"
    >
      <Stack>
        <Typography variant="body1">{name}</Typography>
      </Stack>
      {!icon ? (
        <Stack alignItems="center">
          <Iconify icon={'mdi:open-in-new'} width={18} />
        </Stack>
      ) : (
        icon
      )}
    </Link>
  );
}
