import { Box, Stack, Typography, useTheme } from '@mui/material';
import { EsgAnalysisConsumptionAllocationQuery } from '@predium/client-graphql';
import { fShortenNumber } from '@predium/utils';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

type Props = {
  co2Tax: EsgAnalysisConsumptionAllocationQuery['getConsumptionAllocation']['co2Tax'];
  hasData: boolean;
  isAbsolute: boolean;
};

const CO2Share = ({ co2Tax, hasData, isAbsolute }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const series = [
    {
      name: t('General_PaidByOwner'),
      data: co2Tax.forecastItems.map((item) => item.landlordShare),
      percentage: co2Tax.forecastItems.map((item) => item.landlordSharePercentage),
    },
    {
      name: t('General_PaidByTenant'),
      data: co2Tax.forecastItems.map((item) => item.tenantShare),
      percentage: co2Tax.forecastItems.map((item) => item.tenantSharePercentage),
    },
  ];

  return (
    <Box position="relative">
      <ReactApexChart
        type="bar"
        height="100%"
        series={series}
        options={{
          chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              horizontal: true,
              borderRadius: 4,
            },
          },
          stroke: {
            width: 0,
            colors: ['#fff'],
          },
          grid: {
            padding: {
              left: 0,
              top: 0,
            },
            xaxis: { lines: { show: false } },
            yaxis: { lines: { show: false } },
          },
          xaxis: {
            axisTicks: {
              show: false,
            },
            axisBorder: { show: false },
            categories: co2Tax.forecastItems.map((item) => item.year.toString()),
            tickAmount: 5,
            stepSize: hasData ? undefined : 50,
          },
          yaxis: {
            axisBorder: { show: false },
            axisTicks: {
              show: false,
            },
            title: {
              text: undefined,
            },
          },
          tooltip: {
            custom: ({
              series,
              seriesIndex,
              dataPointIndex,
              w,
            }: {
              series: number[][];
              seriesIndex: number;
              dataPointIndex: number;
              w: { config: { series: { name: string; percentage: number[] }[] } };
            }) => {
              const value = series[seriesIndex][dataPointIndex];
              const { year } = co2Tax.forecastItems[dataPointIndex];

              const seriesName = w.config.series[seriesIndex].name;
              const percentage = w.config.series[seriesIndex].percentage[dataPointIndex];

              return /* html */ `<div
      style="
        background-color: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        padding: 10px; 
        font-size: 12px; 
        color: #000
      "
    >
      <span style="font-weight: 600">
        ${seriesName} - ${year}
      </span>
      <br/>
      <p>${fShortenNumber(value)}€${isAbsolute ? '' : '/m²'}</p>
      <p>${percentage}%</p>
    </div>`;
            },
            style: {
              fontFamily: theme.typography.fontFamily,
            },
          },
          fill: {
            opacity: 1,
            colors: ['#23A6DE', '#A7DBF1'],
          },
          legend: {
            show: false,
          },
        }}
      />
      {!hasData && (
        <Stack
          position="absolute"
          top="40%"
          right="50%"
          bgcolor="white"
          borderRadius={1}
          p={1}
          alignItems="center"
          spacing={1}
          sx={{
            transform: 'translate(50%, -50%)',
          }}
        >
          <Typography variant="caption" color="grey.600">
            {t('General_NoData')}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

export default CO2Share;
