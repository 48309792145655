/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Grid, ListItemIcon, MenuItem, useTheme } from '@mui/material';
import { envelope_type_enum } from '@predium/client-graphql';
import { translateEnvelopeTypeEnum_insulation } from '@predium/i18n/client';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormProvider, RHFDatePicker, RHFNumberField, RHFSelect } from '../../../../../../components/hook-form';
import InfoTable, { InfoTableData } from '../../../../../../components/presentations/InfoTable';
import { getActionIcon } from '../../../../../../utils/icons';
import { createEnvelopeInfoTable } from '../../../../Scenario/scenarios.util';
import { NumberInputSx, SelectSx, StyledListItemText } from '../../../Components/ActionCustomStyles';
import AffectedPartsTable from '../../../PartialRenovations/AffectedPartsTable';
import { PartialRenovationOption } from '../../CreateAction';
import { ActionProps } from '../../ViewAction';

type WindowActionForm = {
  envelope_type_id: envelope_type_enum;
  insulation_material_name: string;
  insulation_lambda: number;
  cost_per_m2: number;
  implementation_to: string;
  u_value: number;
  affected_parts: PartialRenovationOption[];
};

export default function WindowAction({ action, previousActionBuildingModel, affectedParts }: ActionProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const envelopeData = action.renovations_envelope[0].new_envelope;
  const {
    //@ts-ignore
    envelope_type_id,

    //@ts-ignore
    insulation_material_name: materialName,

    //@ts-ignore
    u_value,

    //@ts-ignore
    cost_per_m2: costPerM2,

    //@ts-ignore
    insulation_lambda,
  } = envelopeData;

  const envelopes = previousActionBuildingModel.envelope_units;
  const buildingId = previousActionBuildingModel.building!.id;

  const infoTableData: InfoTableData[] | null = createEnvelopeInfoTable(envelope_type_id, envelopes, t);

  const defaultValues = {
    envelope_type_id,
    insulation_material_name: materialName,
    insulation_lambda,
    cost_per_m2: costPerM2,
    implementation_to: action.implementation_to,
    u_value,
  };

  const formMethods = useForm<WindowActionForm>({
    defaultValues: defaultValues,
  });

  const actionType = action.renovations_envelope?.[0]?.new_envelope?.envelope_type_id;

  return (
    <FormProvider methods={formMethods}>
      <Box my={3}>
        <RHFSelect
          name="envelope_type_id"
          label={t('General_Category')}
          size={'small'}
          sx={{ ...SelectSx, mb: 3 }}
          disabled
        >
          <MenuItem value={envelope_type_id}>
            <ListItemIcon style={{ minWidth: 18 }}>
              <Box component="img" src={getActionIcon(envelope_type_id)} />
            </ListItemIcon>
            <StyledListItemText action={translateEnvelopeTypeEnum_insulation(envelope_type_id)} />
          </MenuItem>
        </RHFSelect>

        {infoTableData && (
          <Box mb={3}>
            <InfoTable buildingId={buildingId} data={infoTableData} title={t('General_StateBeforeRenovation')} />
          </Box>
        )}

        {affectedParts.length > 0 && !!actionType && (
          <AffectedPartsTable affectedParts={affectedParts} envelopes={envelopes} action={actionType} />
        )}

        <Box
          sx={{
            p: 2,
            border: `1px solid ${theme.palette.grey[500_32]}`,
            borderRadius: '8px',
            mb: 3,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <RHFSelect
                name="insulation_material_name"
                label={t('General_Window_one')}
                size={'small'}
                sx={SelectSx}
                disabled
              >
                <MenuItem key={materialName} value={materialName}>
                  <StyledListItemText action={materialName} secondary={'€'} />
                </MenuItem>
              </RHFSelect>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <RHFNumberField
                name="insulation_lambda"
                label={`${t('General_GValue')} [W/(m·K)]`}
                size={'small'}
                sx={NumberInputSx}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <RHFNumberField
                name="u_value"
                label={`${t('General_UwValue')}
                   [W/(m²·K)]`}
                size={'small'}
                sx={SelectSx}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} mt={0}>
              <RHFNumberField
                name="cost_per_m2"
                label={t('General_PricePerM2Part')}
                size={'small'}
                sx={NumberInputSx}
                disabled
              />
            </Grid>
          </Grid>
        </Box>
        <RHFDatePicker
          label={t('General_PlannedFor')}
          name="implementation_to"
          size={'small'}
          sx={NumberInputSx}
          disabled
        />
      </Box>
    </FormProvider>
  );
}
