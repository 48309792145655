import { getClassOfUse } from '@predium/client-lookup';
import { sub_building_class_enum, type_of_use_enum } from '@predium/enums';
import { translateTypeOfUseEnum } from '@predium/i18n/client';
import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';
import { FormState } from 'react-hook-form';
import { AreaFormValues, AreaMutation } from './types';

export const getSortedTypesOfUse = () =>
  sortBy(
    Object.values(type_of_use_enum).map((value) => ({
      value,
      label: translateTypeOfUseEnum(value),
      isCommercial: getClassOfUse(value) === sub_building_class_enum.COMMERCIAL,
    })),
    ['isCommercial', 'label'],
  );

export const getAreaMutationsFromForm = (areas: AreaFormValues['areas'], formState: FormState<AreaFormValues>) => {
  const requiredFields = ['id', 'value'];

  const dirtyAreas = formState.dirtyFields.areas;

  const dirtyAreaFields = dirtyAreas?.map((area) => Object.keys(area ?? {})) ?? [];

  const areaMutations = areas
    .map((area, index) => {
      const dirtyFields = dirtyAreaFields[index] ?? [];
      if (dirtyFields.length === 0) {
        return null;
      }

      // we only send the values that have changed otherwise
      // the backend will consider them as changed by user even if they are the same
      return pick(area, [...requiredFields, ...dirtyFields]) as AreaMutation;
    })
    .filter(Boolean) as AreaMutation[];

  return areaMutations;
};
