/* eslint-disable @typescript-eslint/ban-ts-comment */
import { TFunction } from 'i18next';
import Iconify from '../../../components/Iconify';
import { NavConfigProps } from '../../../components/nav-section/type';
import { PATHS } from '../../../routes';

const ICONS = {
  esgAnalysis: Iconify({ icon: 'mdi:chart-bar-stacked' }),
  actionPlanning: Iconify({ icon: 'mdi:chart-timeline' }),
  dataCollection: Iconify({ icon: 'mdi:database-outline' }),
  reporting: Iconify({ icon: 'octicon:checklist-16' }),
};

const getNavConfig = (t: TFunction<'translation', undefined>, economicUnitsToggled: boolean): NavConfigProps => {
  return [
    {
      subheader: '',
      items: [
        {
          title: t('General_ESGAnalysis'),
          path: 'ROUTE NOT USED',
          rootPathname: PATHS.esgAnalysis.root(),
          icon: ICONS.esgAnalysis,
          children: [
            { title: t('General_Building'), path: PATHS.esgAnalysis.buildings() },
            ...(economicUnitsToggled
              ? [{ title: t('General_EconomicUnit'), path: PATHS.esgAnalysis.economicUnits() }]
              : []),
            { title: t('General_Portfolio'), path: PATHS.esgAnalysis.portfolio() },
          ],
        },
        {
          title: t('General_ActionPlanning'),
          rootPathname: PATHS.actionPlanning.root(),
          path: PATHS.actionPlanning.scenarios(),
          icon: ICONS.actionPlanning,
        },
        {
          title: t('General_Reporting'),
          rootPathname: PATHS.reporting.root(),
          path: PATHS.reporting.reports(),
          icon: ICONS.reporting,
        },
        {
          title: t('General_DataCollection'),
          rootPathname: PATHS.dataCollection.root(),
          path: 'ROUTE NOT USED',
          icon: ICONS.dataCollection,
          children: [
            {
              title: t('General_Building', { count: 2 }),
              path: PATHS.dataCollection.buildings(),
            },
            {
              title: t('General_EmissionCertificate', { count: 2 }),
              path: PATHS.dataCollection.emissionCertificates(),
            },
          ],
        },
      ],
    },
  ];
};

export default getNavConfig;
