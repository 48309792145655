import { Box, MenuItem, Stack } from '@mui/material';
import { data_source_type_enum, energy_system_type_enum } from '@predium/enums';
import { translateEnergySystemTypeEnum } from '@predium/i18n/client';
import { t } from 'i18next';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import ActionMoreMenu from '../../../../components/common/ActionMoreMenu';
import { getEnergySystemIcon } from '../BuildingTechnology';
import {
  generateSystemFieldNames,
  generateSystemRouteFieldNames,
  generateUniqueNegativeId,
} from '../Common/building.util';

export type UpdatedEnergySystems = Exclude<
  Exclude<energy_system_type_enum, energy_system_type_enum.GENERAL>,
  energy_system_type_enum.SOLAR
>;

//currently, UpdatedEnergySystems exclude the SOLAR and GENERAL, which is not currently shown in the UI
const systemMenuOrder: Record<UpdatedEnergySystems, null> = {
  [energy_system_type_enum.HEATING]: null,
  [energy_system_type_enum.HOT_WATER]: null,
  [energy_system_type_enum.COOLING]: null,
  [energy_system_type_enum.LIGHTING]: null,
  [energy_system_type_enum.VENTILATION]: null,
  [energy_system_type_enum.ELECTRICITY]: null,
};

export const systemMenuOrderArray = Object.keys(systemMenuOrder) as UpdatedEnergySystems[];

const TechnologyAddMenu: React.FC = () => {
  const { getValues, setValue, setFocus } = useFormContext();

  const onAddSystem = (energySystem: energy_system_type_enum) => {
    const currentSystem = getValues()[energySystem];
    const systemId = generateUniqueNegativeId(getValues());
    const { systemIdFieldname, energySystemTypeFieldname, dataSourceTypeFieldname } = generateSystemFieldNames(
      energySystem,
      currentSystem?.length ?? 0,
    );

    const {
      energyRouteIdFieldname,
      energyFinalFieldname,
      energySystemTypeFieldname: routeSystemTypeFieldName,
      energyConsumerIdFieldname,
    } = generateSystemRouteFieldNames(energySystem, currentSystem?.length ?? 0, 0);

    //System fields
    setValue(systemIdFieldname, systemId, {
      shouldDirty: true,
    });
    setValue(energySystemTypeFieldname, energySystem, {
      shouldDirty: true,
    });
    setValue(dataSourceTypeFieldname, data_source_type_enum.MANUAL, {
      shouldDirty: true,
    });

    //System route fields
    setValue(energyRouteIdFieldname, systemId - 1, {
      shouldDirty: true,
    });
    setValue(routeSystemTypeFieldName, energySystem, {
      shouldDirty: true, //TODO : remove this later
    });
    setValue(energyFinalFieldname, undefined, {
      shouldDirty: true,
    });
    setValue(energyConsumerIdFieldname, systemId - 2, {
      shouldDirty: true,
    });

    // Slight delay in setting a Focus on the energy final field as it is not yet rendered
    setTimeout(() => {
      setFocus(energyFinalFieldname);
    }, 10);
  };

  return (
    <Stack direction={'row'} justifyContent={'end'}>
      <ActionMoreMenu
        variant="button"
        icon="mdi:plus"
        buttonText={t('DataCollection_Technology_AddSystem')}
        actions={
          <>
            {systemMenuOrderArray.map((energySystem: UpdatedEnergySystems, index: number) => {
              return (
                <MenuItem onClick={() => onAddSystem(energySystem)} key={index}>
                  <Box component="img" src={getEnergySystemIcon(energySystem)} width={18} height={18} mr={1} />
                  {translateEnergySystemTypeEnum(energySystem)}
                </MenuItem>
              );
            })}
          </>
        }
        sx={{ mr: 1, mb: 1 }}
      />
    </Stack>
  );
};

export default TechnologyAddMenu;
