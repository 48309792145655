/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Button, Divider, MenuItem, Stack, Tooltip } from '@mui/material';
import { area_type_enum, data_source_type_enum, sub_building_class_enum } from '@predium/enums';
import { translateTypeOfUseEnum_dynamic } from '@predium/i18n/client';
import find from 'lodash/find';
import keyBy from 'lodash/keyBy';
import sumBy from 'lodash/sumBy';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommercialIcon from '../../../../../assets/Icons/CommercialIcon';
import { ICONS } from '../../../../../assets/icons';
import Iconify from '../../../../../components/Iconify';
import PreDialog, { PreDialogBody, PreDialogTitle } from '../../../../../components/presentations/PreDialog/PreDialog';
import SquareMeterRow from '../Components/SquareMeterRow';
import System from '../Components/System';
import MFG1Form from '../forms/MFG1Form';
import { useArea } from '../hooks';
import { AreaMutation } from '../types';

const CLASS_ICONS = {
  [sub_building_class_enum.COMMERCIAL]: <CommercialIcon sx={{ color: 'grey.600' }} />,
  [sub_building_class_enum.RESIDENTIAL]: <Iconify color="grey.600" icon="mdi:home-outline" height={24} width={24} />,
};

const GIFSystem = () => {
  const { addArea, getPropsForEditableArea, removeArea, updateArea, fields } = useArea();

  const [isAddMFG1DialogOpen, setIsAddMFG1DialogOpen] = useState(false);
  const [editMFG1Id, setEditMFG1Id] = useState<string | null>(null);

  const { t } = useTranslation();

  const handleOpenAddMFG1Dialog = () => setIsAddMFG1DialogOpen(true);

  const handleCloseAddMFG1Dialog = () => setIsAddMFG1DialogOpen(false);

  const handleCloseEditMFG1Dialog = () => setEditMFG1Id(null);

  const gifAreas = keyBy(fields, 'area_type_id');

  const handleAddMFG1 = (values: AreaMutation) => {
    setIsAddMFG1DialogOpen(false);
    addArea({
      ...values,
      parent_id: gifAreas.MFG.id,
      data_source_id: data_source_type_enum.MANUAL,
    });
  };

  const handleEditGIF = (values: AreaMutation) => {
    setEditMFG1Id(null);

    if (editMFG1Id === null) {
      throw new Error('editMFG1Id is null');
    }

    updateArea(editMFG1Id, {
      ...values,
      data_source_id: data_source_type_enum.MANUAL,
    });
  };

  const mfg1 = fields.filter((area) => area.area_type_id === area_type_enum.MFG_1 && !area.delete);
  const mfg1Sum = sumBy(mfg1, 'value');

  const getNUFSubtitle = (area: AreaMutation) => {
    if (!area.name && !area.description) {
      return translateTypeOfUseEnum_dynamic(area.type_of_use_id, t);
    }

    const id = area.name ? `ID ${area.name || t('General_Unavailable-Short')}` : null;
    const rented = area.is_rented ? t('DataCollectionAreas_Rented') : t('DataCollectionAreas_OwnUse');
    const type = translateTypeOfUseEnum_dynamic(area.type_of_use_id, t);

    const separator = ' • ';

    return [id, rented, type].filter(Boolean).join(separator);
  };

  return (
    <>
      <System
        title={t('DataCollectionAreas_GIFTitle')}
        subtitle={t('DataCollectionAreas_GIFSubtitle')}
        areaTitle={t('DataCollectionAreas_ExclusiveRentalArea')}
        areaTitleSuffix="MFG-1"
        areaTooltip={t('DataCollectionAreas_ExclusiveRentalAreaTooltip')}
        addAreaLabel={t('DataCollectionAreas_AddRentalArea')}
        onAddAreaClick={handleOpenAddMFG1Dialog}
        areaSum={mfg1Sum}
        listAreaType={area_type_enum.MFG_1}
        areas={
          <Stack spacing={1}>
            {mfg1.map((area) => (
              <SquareMeterRow
                key={area.uid}
                {...getPropsForEditableArea(area.uid)}
                //@ts-ignore
                areaType={null} // we don't want to show equation error here
                labels={{
                  title: area.description || t('DataCollectionAreas_UnassignedRentalArea'),
                  subtitle: getNUFSubtitle(area),
                }}
                titleProps={{ variant: 'subtitle2' }}
                icon={CLASS_ICONS[area.class_of_use_id]}
                actions={
                  <>
                    <MenuItem onClick={() => setEditMFG1Id(area.uid)}>
                      <Iconify icon={'fa-regular:edit'} />
                      {t('General_Edit')}
                    </MenuItem>
                    <Tooltip title={mfg1.length === 1 ? t('DataCollectionAreas_LastItemDeleteTooltip') : null}>
                      <Box component="span">
                        <MenuItem
                          sx={{ color: 'error.main' }}
                          disabled={mfg1.length === 1}
                          onClick={() => removeArea(area.uid)}
                        >
                          <Iconify icon={ICONS.TRASH} />
                          {t('General_Delete')}
                        </MenuItem>
                      </Box>
                    </Tooltip>
                  </>
                }
              />
            ))}
          </Stack>
        }
        summary={
          <Stack spacing={1}>
            <SquareMeterRow
              labels={{
                title: t('DataCollectionAreas_GeneralArea'),
                tooltip: t('DataCollectionAreas_GeneralAreaTooltip'),
                titleSuffix: gifAreas.MFG_2.name,
              }}
              {...getPropsForEditableArea(gifAreas.MFG_2.uid)}
            />
            <Box height={40} />
            <Divider />
            <SquareMeterRow
              labels={{
                title: t('DataCollectionAreas_TotalRentalArea'),
                tooltip: t('DataCollectionAreas_TotalRentalAreaTooltip'),
                titleSuffix: gifAreas.MFG.name,
              }}
              {...getPropsForEditableArea(gifAreas.MFG.uid)}
            />
            <SquareMeterRow
              labels={{
                title: t('DataCollectionAreas_NonRentableArea'),
                tooltip: t('DataCollectionAreas_NonRentableAreaTooltip'),
                titleSuffix: gifAreas.MFG_0.name,
              }}
              {...getPropsForEditableArea(gifAreas.MFG_0.uid)}
            />
          </Stack>
        }
      />
      <PreDialog open={isAddMFG1DialogOpen} onClose={handleCloseAddMFG1Dialog} fullWidth type="definedByChildren">
        {isAddMFG1DialogOpen && (
          <PreDialogBody
            dialogtitle={<PreDialogTitle title={t('DataCollectionAreas_AddRentalAreaTitle')} />}
            content={<MFG1Form onSubmit={handleAddMFG1} />}
            actions={
              <>
                <Button variant="outlined" onClick={handleCloseAddMFG1Dialog}>
                  {t('General_Cancel')}
                </Button>
                <Button type="submit" variant="contained" form="mfg1-form">
                  {t('General_Accept')}
                </Button>
              </>
            }
          />
        )}
      </PreDialog>

      <PreDialog open={Boolean(editMFG1Id)} onClose={handleCloseEditMFG1Dialog} fullWidth type="definedByChildren">
        {editMFG1Id !== null && (
          <PreDialogBody
            dialogtitle={<PreDialogTitle title={t('DataCollectionAreas_EditRentalAreaTitle')} />}
            content={<MFG1Form onSubmit={handleEditGIF} defaultValue={find(mfg1, { uid: editMFG1Id })} />}
            actions={
              <>
                <Button variant="outlined" onClick={handleCloseEditMFG1Dialog}>
                  {t('General_Cancel')}
                </Button>
                <Button type="submit" variant="contained" form="mfg1-form">
                  {t('General_Accept')}
                </Button>
              </>
            }
          />
        )}
      </PreDialog>
    </>
  );
};

export default GIFSystem;
