/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Stack, TextFieldProps, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  defaultValue?: string;
  disabled?: boolean;
  /**
   * Setting this will show the character count at the end of the helper text section and the character limit. If you use this you can not set the helperText prop yourself.
   */
  characterLimit?: number;
  /**
   * If true, the input value will be trimmed while typing.
   */
  trim?: boolean;
};

/**
 * Minimal@4 component.
 */
export default function RHFTextField({
  name,
  helperText,
  defaultValue,
  disabled = false,
  characterLimit = 0,
  trim = false,
  ...other
}: Props) {
  const { control, getValues } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          error={!!error}
          disabled={disabled}
          inputRef={ref}
          helperText={
            (helperText || error?.message || characterLimit > 0) && (
              <Stack spacing={1} direction="row" justifyContent="space-between">
                {helperText && !error && (
                  <Typography variant="caption" color="text.secondary">
                    {helperText}
                  </Typography>
                )}
                {error?.message && (
                  <Typography variant="caption" color="error">
                    {error.message}
                  </Typography>
                )}
                {characterLimit > 0 && (
                  <Typography variant="caption">{`${getValues(name)?.length ?? 0}/${characterLimit}`}</Typography>
                )}
              </Stack>
            )
          }
          onChange={(event) => {
            const value = trim ? event.target.value.trim() : event.target.value;
            onChange(value);
          }}
          {...other}
        />
      )}
    />
  );
}
