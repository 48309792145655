import { Card, CardHeader, useTheme } from '@mui/material';
import { energy_source_type_enum, type_of_use_enum } from '@predium/enums';
import { translateEnergySourceTypeEnum_dynamic, translateTypeOfUseEnum_dynamic } from '@predium/i18n/client';
import { t } from 'i18next';
import groupBy from 'lodash/groupBy';
import merge from 'lodash/merge';
import { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../routes/paths';
import BaseOptionChart from '../../../theme/apexcharts';

type Props = {
  buildings: {
    area: number;
    co2Intensity: number;
    finalEnergy: number;
    buildingId: number;
    address: string;
    typeOfUse: type_of_use_enum[];
    year: number;
    mainEnergySourceType: energy_source_type_enum;
  }[];
};

const AreaBubbleChart = ({ buildings }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { maxCO2Intensity, maxFinalEnergy, seriesData } = useMemo(() => {
    let maxCO2Intensity = 0;
    let maxFinalEnergy = 0;

    buildings.forEach((building) => {
      if (building.co2Intensity > maxCO2Intensity) {
        maxCO2Intensity = Math.ceil(building.co2Intensity / 10) * 10;
      }
      if (building.finalEnergy > maxFinalEnergy) {
        maxFinalEnergy = Math.ceil(building.finalEnergy / 10) * 10;
      }
    });

    const groupedByEnergySource = groupBy(buildings, 'mainEnergySourceType');

    const seriesData = Object.keys(groupedByEnergySource).map((energySource) => ({
      name: energySource,
      data: groupedByEnergySource[energySource].map((item) => ({
        x: item.co2Intensity,
        y: item.finalEnergy,
        z: item.area,
        buildingId: item.buildingId,
        address: item.address,
        year: item.year,
        area: item.area,
        typeOfUse: item.typeOfUse.map((type) => translateTypeOfUseEnum_dynamic(type, t)).join(', '),
      })),
      color: theme.palette.energySourceTypeColors[energySource as energy_source_type_enum].main,
    }));

    return { maxCO2Intensity, maxFinalEnergy, seriesData };
  }, [buildings, theme.palette.energySourceTypeColors]);

  const chartState: ApexCharts.ApexOptions = merge(BaseOptionChart(), {
    chart: {
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: true,
      },
      events: {
        dataPointSelection: (__: any, chartContext: any, config: any) => {
          const seriesIndex = config.seriesIndex;
          const seriesData = chartContext.w.config.series[seriesIndex];
          const dataPoint = seriesData.data[config.dataPointIndex];
          navigate(PATHS.esgAnalysis.building({ id: dataPoint.buildingId }));
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    toolbar: {
      show: true,
    },
    fill: {
      opacity: 0.8,
    },
    xaxis: {
      min: 0,
      type: 'numeric',
      tickAmount: 10,
      max: maxCO2Intensity,
      labels: {
        formatter: function (val: number) {
          return Math.round(val);
        },
      },
      title: {
        text: t('General_CO2Intensity') + ` (kg CO₂e/m²a)`,
        style: {
          fontSize: '12px',
          color: theme.palette.text.secondary,
          fontWeight: 500,
        },
        offsetY: -2,
      },
    },
    yaxis: {
      min: 0,
      tickAmount: 5,
      max: maxFinalEnergy,
      labels: {
        formatter: function (val: number) {
          return Math.round(val);
        },
      },
      title: {
        text: t('General_FinalEnergy') + ` (kWh/m²a)`,
      },
    },
    legend: {
      showForSingleSeries: true,
      position: 'right',
      formatter: (legendEntry: energy_source_type_enum) => {
        return `${translateEnergySourceTypeEnum_dynamic(legendEntry, t)}
         `;
      },
      fontSize: '16px',
      fontWeight: 700,
      fontFamily: theme.typography.fontFamily,
      offsetX: -40,
      offsetY: 20,
      markers: {
        offsetX: -5,
      },
      itemMargin: {
        vertical: 5,
      },
      width: 300,
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    plotOptions: {
      bubble: {
        expandOnClick: false,
        zScaling: true,
        minBubbleRadius: 5,
        maxBubbleRadius: 50, // this doesn't do anything . will be exploring it later
      },
    },
    tooltip: {
      enabled: true,

      custom: ({ seriesIndex, dataPointIndex, w }: { seriesIndex: number; dataPointIndex: number; w: any }) => {
        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

        return `
        <div class="apexcharts-custom-tooltip" style="font-size:12px;">
            <div style="font-weight:600;">${data.address}</div>
            <div>
                <span>${t('General_TypeOfUse')}: ${data.typeOfUse}</span>
            </div>
            <div>
                <span>${t('General_UsableArea')}: ${data.area} m²</span>
            </div>
            <div>
                <span>${t('General_ConstructionYear')}: ${data.year}</span>
            </div>
        </div>
        `;
      },
    },
  });

  return (
    <Card
      sx={{
        p: 3,
        pb: 1,
        '.apexcharts-series-markers': {
          cursor: 'pointer',
        },
        '.apexcharts-legend-series': {
          display: 'flex',
          flexDirection: 'row',
        },
        '.apexcharts-legend-marker': {
          marginTop: 0.5,
          minWidth: 12,
        },
      }}
    >
      <CardHeader
        title={t('EsgAnalysis_AreaBubbleChart-Title')}
        sx={{ p: 0, pl: 3 }}
        subheader={t('EsgAnalysis_AreaBubbleChart-SubTitle')}
      />

      <Chart options={chartState} series={seriesData} type="bubble" height={400} />
    </Card>
  );
};

export default AreaBubbleChart;
