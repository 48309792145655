import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { emission_factor_type_enum, energy_system_type_enum } from '@predium/enums';
import { useFormContext } from 'react-hook-form';
import { SystemRoute } from '../../../BuildingTechnology';
import { FieldIcon, IconState } from '../../../Common/FieldIcon';
import TechnologyRouteHead from './TechnologyRouteHead';
import ConsumerCard from './TechnologyUnit/Consumer/ConsumerCard';
import DistributionCard from './TechnologyUnit/Distribution/DistributionCard';
import EnergySourceCard from './TechnologyUnit/EnergySource/EnergySourceCard';
import StorageUnit from './TechnologyUnit/Storage/StorageCard';

type TechnologyUnitTitleProps = {
  subtitle: string | JSX.Element;
  isDirty?: boolean;
  isDeleted?: boolean;
};

export function TechnologyUnitSubTitle({ subtitle, isDirty, isDeleted }: TechnologyUnitTitleProps) {
  return (
    <Typography
      px={3}
      py={1}
      fontWeight={700}
      display={'flex'}
      textAlign={'left'}
      sx={{ textDecoration: isDeleted ? 'line-through' : 'none' }}
    >
      {isDirty && !isDeleted && <FieldIcon state={IconState.ManualEdit} />}
      {isDeleted && <FieldIcon state={IconState.Deleted} />}

      {subtitle}
    </Typography>
  );
}

export const fieldIcon = (
  <Box pt={0.5}>
    <FieldIcon state={IconState.ManualEdit} />
  </Box>
);

type Props = {
  portfolioEmissionFactorType: emission_factor_type_enum;
  systemIndex: number;
  energySystem: energy_system_type_enum;
};

export default function TechnologyConsumerRoute({ energySystem, portfolioEmissionFactorType, systemIndex }: Props) {
  const { watch } = useFormContext();

  const fieldArrayName = `${energySystem}[${systemIndex}].routes`;

  const routeFieldsArray = watch(fieldArrayName);

  const showAllUnits =
    energySystem === energy_system_type_enum.HEATING || energySystem === energy_system_type_enum.HOT_WATER;

  const totalRoutes = routeFieldsArray.length;

  return (
    <>
      {routeFieldsArray.map((energySystemRoute: SystemRoute, index: number) => (
        <Box
          sx={{
            ':not(:last-child)': { mb: 4 },
          }}
          key={index}
        >
          <TechnologyRouteHead
            totalRoutes={totalRoutes}
            index={index}
            systemIndex={systemIndex}
            energySystemRoute={energySystemRoute}
          />

          {!energySystemRoute.routeDeleted && (
            <Grid container sx={{ px: 1 }} spacing={5}>
              <Grid item xs={3}>
                <EnergySourceCard
                  energySystemRoute={energySystemRoute}
                  routeIndex={index}
                  systemIndex={systemIndex}
                  portfolioEmissionFactorType={portfolioEmissionFactorType}
                />
              </Grid>

              {energySystemRoute.energySystemType !== energy_system_type_enum.ELECTRICITY && (
                <Grid item xs={3}>
                  <ConsumerCard
                    energySystemRoute={energySystemRoute}
                    systemIndex={systemIndex}
                    routeIndex={index}
                    showArrow={showAllUnits}
                  />
                </Grid>
              )}

              {showAllUnits && (
                <>
                  <Grid item xs={3}>
                    <StorageUnit energySystemRoute={energySystemRoute} systemIndex={systemIndex} routeIndex={index} />
                  </Grid>

                  <Grid item xs={3}>
                    <DistributionCard
                      energySystemRoute={energySystemRoute}
                      systemIndex={systemIndex}
                      routeIndex={index}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Box>
      ))}
    </>
  );
}
