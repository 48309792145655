import { TextField, TextFieldProps } from '@mui/material';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  name: string;
  label: string;
  type?: 'text' | 'number';
  options: string[] | number[];
} & Omit<AutocompleteProps<string | number, false, false, false>, 'renderInput'> &
  TextFieldProps; //TODO Should be extended later as per need e.g multi select (option set to false for now)

export default function RHFAutocomplete({ name, label, type = 'text', options, ...other }: Props) {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          options={options}
          onChange={(_, values) => onChange(values)}
          getOptionLabel={(option) => String(option)} // MUI requires string option by default
          value={value}
          renderInput={(params) => (
            <TextField
              {...other}
              {...params}
              label={label}
              variant="outlined"
              type={type}
              onChange={(e) => onChange(e.target.value)}
              error={!!error}
              helperText={error ? error.message : ''}
              InputProps={{
                ...params.InputProps,
                ...other?.InputProps,
              }}
              fullWidth
            />
          )}
        />
      )}
    />
  );
}
