import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import useAccessToken from '../hooks/useAccessToken';
import Login from '../pages/authentication/Login';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isLoading } = useAuth0();
  const { tokenIsExpired, invalidateAccess, accessToken } = useAccessToken();

  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (import.meta.env.VITE_API_MOCKING !== 'true') {
    if (isLoading) {
      return <LoadingScreen />;
    }

    if (accessToken && tokenIsExpired()) {
      invalidateAccess();
    }

    if (!isAuthenticated) {
      if (pathname !== requestedLocation) {
        setRequestedLocation(pathname);
      }
      return <Login />;
    }
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
