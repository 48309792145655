import { Box, Grid, Tab, Tabs } from '@mui/material';
import Card from '@mui/material/Card';
import { StrandingRangeOutput } from '@predium/client-graphql';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import InfoTooltip from '../../../components/InfoTooltip';
import { PreLink } from '../../../components/presentations/PreLink';
import { Co2PathGraph } from './EsgPathGraphs/Co2PathGraph';
import { EnergyPathGraph } from './EsgPathGraphs/EnergyPathGraph';

export const esgPathGraphOptions = {
  chart: {
    toolbar: {
      offsetY: -40,
    },
  },
  stroke: {
    curve: 'straight',
    dashArray: [0, 4],
    width: [4, 2],
  },
  legend: {
    show: false,
  },
} as ApexCharts.ApexOptions;

type Props = {
  co2Path: number[];
  co2Targets: {
    onePointFive: number[];
  };
  energyPath: number[];
  energyTargets: {
    onePointFive: number[];
  };
  buildingLegendName: string;
  strandingYears: StrandingRangeOutput[];
  constructionYear: number;
};

type PathGraphType = 'co2-graph' | 'energy-graph';

export function EsgPathGraph({
  co2Path,
  co2Targets,
  energyPath,
  energyTargets,
  buildingLegendName,
  strandingYears,
  constructionYear,
}: Props) {
  const { t } = useTranslation();
  const [pathGraphType, setPathGraphType] = useState<PathGraphType>('co2-graph');

  const link = (
    <PreLink
      link={{
        href: 'https://support.predium.de/artikel/crrem-pfad-und-stranding-zeitpunkt-verstehen',
        name: t('General_LearnMore'),
        external: true,
      }}
    />
  );

  return (
    <Card sx={{ p: 3 }}>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Tabs
            value={pathGraphType}
            onChange={(_, newValue) => setPathGraphType(newValue)}
            sx={{
              '& .MuiTab-root': {
                color: 'text.secondary',
                mr: 4,
              },
              borderBottom: 1,
              borderColor: 'divider',
              ml: 3,
              '.MuiTab-root.Mui-selected': {
                color: 'text.primary',
                borderBottom: 3,
              },
              '.MuiTabs-indicator': {
                height: 3,
              },
            }}
          >
            <Tab
              label={
                <Box display="flex" alignItems="center">
                  {t('General_CO2Path')}
                  <InfoTooltip
                    text={
                      <Trans
                        i18nKey={t('General_1_5C02PathGraph-tooltip')}
                        components={{
                          articleLink: link,
                        }}
                      />
                    }
                    maxwidth={600}
                  />
                </Box>
              }
              value="co2-graph"
            />
            <Tab
              label={
                <Box display="flex" alignItems="center">
                  {t('General_EnergyPath')}
                  <InfoTooltip
                    text={
                      <Trans
                        i18nKey={t('General_1_5EnergyPathGraph-tooltip')}
                        components={{
                          articleLink: link,
                        }}
                      />
                    }
                    maxwidth={600}
                    sx={{ mr: -1 }}
                  />
                </Box>
              }
              value="energy-graph"
            />
          </Tabs>
        </Grid>
      </Grid>

      {pathGraphType === 'co2-graph' ? (
        <Co2PathGraph
          co2Path={co2Path}
          co2Targets={co2Targets}
          buildingLegendName={buildingLegendName}
          strandingYears={strandingYears}
          constructionYear={constructionYear}
        />
      ) : (
        <EnergyPathGraph
          energyPath={energyPath}
          energyTargets={energyTargets}
          buildingLegendName={buildingLegendName}
          constructionYear={constructionYear}
        />
      )}
    </Card>
  );
}
