import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal';
import LoadingScreen from '../../../components/LoadingScreen';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import ProcessDocumentFocusPage from '../../../components/documents/ProcessDocumentFocusPage';
import { DELETE_EMISSION_CERTIFICATE } from '../../../graphql/DataCollection.mutations';
import { GET_EMISSION_CERTIFICATE } from '../../../graphql/DataCollection.queries';
import { PATHS } from '../../../routes/paths';

export default function EmissionCertificatePage() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id: emissionCertificateId } = useParams();

  const [deleteCertificateOpen, setDeleteCertificateOpen] = useState(false);

  const { data } = useQuery(GET_EMISSION_CERTIFICATE, {
    variables: { emissionCertificateId: parseInt(emissionCertificateId!) },
  });

  const [deleteEmissionCertificate, { loading }] = useMutation(DELETE_EMISSION_CERTIFICATE, {
    variables: {
      id: parseInt(emissionCertificateId!),
    },
    update: (cache, { data }) => {
      if (data?.deleteEmissionCertificate.success) {
        const normalizedId = cache.identify({
          id: emissionCertificateId,
          __typename: 'emission_certificate',
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
    onCompleted: () => {
      enqueueSnackbar(t('DataCollection_EmissionCertificateDelete-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      setDeleteCertificateOpen(false);
      navigate(PATHS.dataCollection.emissionCertificates());
    },
  });

  if (!data?.emission_certificate_by_pk) return <LoadingScreen />;

  return (
    <>
      <ProcessDocumentFocusPage
        emissionCertificateData={data.emission_certificate_by_pk}
        draftMode={false}
        title={t('DataCollection_EmissionCertificatesProcessDocumentTitle')}
        handleCloseCallback={() => {
          navigate(PATHS.dataCollection.emissionCertificates());
        }}
        onDeleteCallback={() => setDeleteCertificateOpen(true)}
      />

      <DeleteConfirmationModal
        open={deleteCertificateOpen}
        loading={loading}
        title={t('General_DeleteModalTitle-EmissionCertificateDraft', { count: 1 })}
        description={
          <Trans
            i18nKey={'General_DeleteModalDescription-EmissionCertificateDraft'}
            values={{ count: 1, name: data.emission_certificate_by_pk.file.name }}
            components={{ bold: <strong /> }}
          />
        }
        onClose={() => {
          setDeleteCertificateOpen(false);
        }}
        onDelete={() => {
          deleteEmissionCertificate();
        }}
      />
    </>
  );
}
