/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Chip, List, ListItem, Popover } from '@mui/material';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { ReportGetReportsSubBuildingsFragment, ReportingReportsQuery } from '@predium/client-graphql';
import uniqBy from 'lodash/uniqBy';
import { ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import InlineUser from '../../components/InlineUser';
import Label from '../../components/Label';
import { DelayedLoading } from '../../components/Loading';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import Unavailable, { UnavailablePageForMissingPortfolios } from '../../components/Unavailable';
import LinearProgressBar from '../../components/data-visialization/LinearProgressBar';
import PreDialog from '../../components/presentations/PreDialog/PreDialog';
import TablePaginationStandard from '../../components/table/TablePaginationStandard';
import { GET_REPORTS } from '../../graphql/Report.queries';
import useTable, { applySortFilter } from '../../hooks/useTable';
import { useLanguage } from '../../provider/LanguageProvider';
import { PATHS } from '../../routes';
import CreateReport from '../../sections/Reporting/CreateReportModal/CreateReportModal';
import DeleteReport from '../../sections/Reporting/Report/DeleteReport';
import ReportActionMenu from '../../sections/Reporting/Report/ReportActionMenu';
import { getTemplateMetadataForTemplateTitle } from '../../sections/Reporting/ReportingDataClasses/ReportTemplate.dc';
import ReportingReportsTableHead, { HeadLabel } from '../../sections/Reporting/ReportingReportsTableHead';
import ReportingTableToolbar from '../../sections/Reporting/ReportingTableToolbar';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '../../utils/formatTime';

export default function ReportingReports() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { language } = useLanguage();

  const columns: HeadLabel[] = [
    { id: 'name', label: t('General_ReportingTemplate'), minWidth: 300 },
    { id: 'target', label: t('Reporting_Target'), minWidth: 180 },
    { id: 'created_at', label: t('General_CreatedAt'), minWidth: 180 },
    { id: 'owner', label: t('General_Owner'), minWidth: 180 },
    { id: 'progress', label: t('General_Progress'), minWidth: 180 },
    { id: 'actions', label: '' },
  ];

  const { page, setPage, order, orderBy, rowsPerPage, setRowsPerPage, handleRequestSort } = useTable({
    defaultRowsPerPage: 25,
    defaultOrderBy: 'created_at',
    defaultOrder: 'desc',
  });

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [reportToDelete, setReportToDelete] = useState<ReportingReportsQuery['report'][number] | null>(null);
  const [selectedTemplateFilter, setSelectedTemplateFilter] = useState('Reporting_AllTemplates');
  const [selectedOwnerIdFilter, setSelectedOwnerIdFilter] = useState<number | 'General_AllOwners'>('General_AllOwners');

  const { data, loading } = useQuery(GET_REPORTS);

  if (loading) {
    return <DelayedLoading />;
  }

  //@ts-ignore
  const reports = data.report;

  const pageLayout = (children: ReactNode) => (
    <UnavailablePageForMissingPortfolios title={t('Reporting_Reports')} heading={t('Reporting_Reports')}>
      <Page title={t('Reporting_Reports')}>
        <Container maxWidth={'lg'}>
          <HeaderBreadcrumbs heading={t('Reporting_Reports')} links={[]} />

          <Card>
            <ReportingTableToolbar
              availableTemplates={uniqBy(
                reports.map(({ report_template }) => report_template),
                'title',
              )}
              availableOwners={uniqBy(
                reports.map(({ responsible_user }) => ({
                  //@ts-ignore

                  //@ts-ignore
                  label: `${responsible_user.first_name} ${responsible_user.last_name}`,

                  //@ts-ignore
                  value: responsible_user.id,
                })),
                'value',
              )}
              selectedOwnerIdFilter={selectedOwnerIdFilter}
              //@ts-ignore
              setSelectedOwnerIdFilter={setSelectedOwnerIdFilter}
              selectedTemplateFilter={selectedTemplateFilter}
              setSelectedTemplateFilter={setSelectedTemplateFilter}
              onCreateReport={() => {
                setIsCreateModalOpen(true);
              }}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ReportingReportsTableHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={columns}
                    onRequestSort={handleRequestSort}
                  />

                  {children}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePaginationStandard
              count={reports.length}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </Card>
        </Container>

        <PreDialog
          type="definedByChildren"
          open={isCreateModalOpen}
          fullWidth
          maxWidth="lg"
          onClose={() => {
            setIsCreateModalOpen(false);
          }}
        >
          <CreateReport />
        </PreDialog>
      </Page>
    </UnavailablePageForMissingPortfolios>
  );

  if (reports.length === 0) {
    return pageLayout(
      <TableBody>
        <TableRow>
          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
            <Unavailable
              iconString="gridicons:create"
              title={t('Reporting_NoReportAvailable')}
              subTitle={t('Reporting_CreateFirstReport')}
              onClick={() => {
                setIsCreateModalOpen(true);
              }}
            />
          </TableCell>
        </TableRow>
      </TableBody>,
    );
  }

  const anyFiltersActive =
    selectedTemplateFilter !== 'Reporting_AllTemplates' || selectedOwnerIdFilter !== 'General_AllOwners';

  const filteredRows = applySortFilter({
    data: reports,
    orderOptions: {
      order,

      //@ts-ignore
      orderBy: (item) => {
        switch (orderBy) {
          case 'name':
            return getTemplateMetadataForTemplateTitle(item.report_template.title).readableTitle;

          case 'owner':
            //@ts-ignore

            //@ts-ignore
            return `${item.responsible_user.first_name} ${item.responsible_user.last_name}`;

          case 'created_at':
            return new Date(item.created_at).getTime();

          case 'target':
            return item.report_sub_buildings.length;

          default:
            return item[orderBy as keyof typeof item] as string | number;
        }
      },
    },

    //@ts-ignore
    dataFilters: anyFiltersActive
      ? [
          selectedTemplateFilter !== 'Reporting_AllTemplates'
            ? (report: (typeof reports)[number]) => report.report_template.title === selectedTemplateFilter
            : undefined,
          selectedOwnerIdFilter !== 'General_AllOwners'
            ? //@ts-ignore
              (report: (typeof reports)[number]) => report.responsible_user.id === selectedOwnerIdFilter
            : undefined,
        ].filter(Boolean)
      : undefined,
  });

  const isNotFound = !filteredRows.length && anyFiltersActive;
  if (isNotFound) {
    return pageLayout(
      <TableBody>
        <SearchNotFound searchQuery={t('Reporting_SearchNotFound')} />
      </TableBody>,
    );
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - reports.length) : 0;
  return pageLayout(
    <TableBody>
      {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
        const { id, responsible_user, progress, completed, created_at, report_sub_buildings } = row;

        const type = row.report_template.title;
        const exportTemplates = [row.report_template.excel_template_path ? 'excel' : null];

        return (
          <TableRow hover key={id} role="checkbox" sx={{ cursor: 'pointer' }}>
            <TableCell onClick={() => navigate(PATHS.reporting.report({ id }))}>
              {getTemplateMetadataForTemplateTitle(row.report_template.title).readableTitle}
            </TableCell>
            <TableCell
              onClick={(event) => {
                // A tagName should be set for every html element. It is probably only abstracted away by the React event handler.
                // We are doing this to avoid navigation when clicking the chip that should display the popover.
                // @ts-ignore
                if (event.target.tagName === 'TD') {
                  navigate(PATHS.reporting.report({ id }));
                }
              }}
            >
              {report_sub_buildings[0].sub_building.building.address.street}
              <ChipListPopover report_sub_buildings={report_sub_buildings} />
            </TableCell>
            <TableCell onClick={() => navigate(PATHS.reporting.report({ id }))}>
              {formatDateToLocale(created_at, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language)}
            </TableCell>
            <TableCell onClick={() => navigate(PATHS.reporting.report({ id }))}>
              {/*@ts-ignore */}
              <InlineUser firstName={responsible_user.first_name} lastName={responsible_user.last_name} size={25} />
            </TableCell>
            <TableCell onClick={() => navigate(PATHS.reporting.report({ id }))}>
              {completed ? (
                <Label color="success" sx={{ textTransform: 'capitalize' }}>
                  {t('General_Completed')}
                </Label>
              ) : (
                <LinearProgressBar progress={progress} />
              )}
            </TableCell>
            <TableCell align="right">
              <ReportActionMenu
                reportId={id}
                showDeleteReport={() => {
                  setReportToDelete(row);
                }}
                reportTemplateType={type}
                //@ts-ignore
                exportTemplates={exportTemplates}
                completed={row.completed}
              />
            </TableCell>
          </TableRow>
        );
      })}

      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}

      <DeleteReport report={reportToDelete} closeDeleteReportModal={() => setReportToDelete(null)} />
    </TableBody>,
  );
}

type ChipListPopoverProps = {
  report_sub_buildings: ReportGetReportsSubBuildingsFragment[];
};

function ChipListPopover({ report_sub_buildings }: ChipListPopoverProps) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const chipPopoverRef = useRef<HTMLDivElement>();

  const listEntriesWithoutScrolling = 8;

  return report_sub_buildings.length > 1 ? (
    <>
      {/*@ts-ignore */}
      <Chip
        label={`+${report_sub_buildings.length - 1}`}
        onClick={() => setPopoverOpen(true)}
        style={{ marginLeft: '8px', fontWeight: 800 }}
        ref={chipPopoverRef}
        size="small"
      />
      <Popover
        open={popoverOpen}
        anchorEl={chipPopoverRef.current}
        onClose={() => setPopoverOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {/* Can not add the <Scrollbar> component because the display in an absolute position messes with it. This is an issue with the scrollbar component. */}
        <List style={{ maxHeight: 16 + listEntriesWithoutScrolling * 40 }}>
          {report_sub_buildings.map(({ sub_building: { id, building } }) => (
            <ListItem key={id}>{building.address.street}</ListItem>
          ))}
        </List>
      </Popover>
    </>
  ) : null;
}
ChipListPopover.displayName = 'ChipListPopover';
