import {
  Autocomplete,
  Button,
  Checkbox,
  List,
  ListItem,
  SelectProps,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { EnvelopeRenovationFragment, EnvelopeUnitFragment } from '@predium/client-graphql';
import { envelope_type_enum } from '@predium/enums';
import { translateOrientationEnum_short_dynamic } from '@predium/i18n/client';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getOrientationEnum } from '../../../../../../../libs/lookup/client-lookup/src/lib/Orientation';
import { ICONS } from '../../../../assets/icons';
import Iconify from '../../../../components/Iconify';
import Scrollbar from '../../../../components/Scrollbar';
import { getEnvelopeLabel, getPartialRenovationOptions } from '../ActionPlan.utils';
import { PartialRenovationOption } from '../Actions/CreateAction';
import { DoorActionForm } from '../Actions/CreateActions/EnvelopeActions/DoorAction';
import { NonWindowDoorActionForm } from '../Actions/CreateActions/EnvelopeActions/NonWindowDoorAction';
import { WindowActionForm } from '../Actions/CreateActions/EnvelopeActions/WindowAction';
import PartialRenovationOptionSubtext from './PartialRenovationOptionSubtext';

type Props = {
  action: envelope_type_enum;
  envelopes: EnvelopeUnitFragment[];
  envelopeRenovations: EnvelopeRenovationFragment[];
  sx: SelectProps['sx'];
  setValue: (value: PartialRenovationOption[]) => void;
  disabled?: boolean;
};

export default function PartialRenovationsAutocomplete({
  action,
  envelopes,
  envelopeRenovations,
  sx,
  setValue,
  disabled,
}: Props) {
  const { t } = useTranslation();

  const { control } = useFormContext<DoorActionForm | NonWindowDoorActionForm | WindowActionForm>();

  //needs to be controlled because we want to manually close it when user clicks on "select all"
  const [open, setOpen] = useState(false);
  const autocompleteRef = useRef<HTMLDivElement>(null);

  const unsortedOptions: PartialRenovationOption[] = getPartialRenovationOptions(envelopes, action);

  const changedParts = useMemo(() => {
    if (!envelopeRenovations || envelopeRenovations.length === 0) return [];
    return (
      envelopeRenovations[0].old_envelope?.building_model.envelope_units
        .filter((envelope) => envelope.envelope_type_id === action)
        .flatMap((envelope, idx) => {
          return { idx, ...envelope };
        })
        .filter((oldEnvelope) =>
          envelopeRenovations.find((newEnvelope) => {
            return oldEnvelope.id === newEnvelope.old_entity_id;
          }),
        )
        .flatMap((envelope) => {
          return { idx: envelope.idx, id: envelope.id };
        }) ?? []
    );
  }, [action, envelopeRenovations]);

  //the changed parts will have new ids (higher ones) and thus be the last elements of the array
  //so we loop through them and insert them into the position specified in oldEnvelopes[x].idx
  for (let i = 0; i < changedParts.length; i++) {
    const e1 = unsortedOptions.indexOf(unsortedOptions[changedParts[i].idx]);
    const e2 = unsortedOptions.indexOf(unsortedOptions[unsortedOptions.length - i - 1]);
    const temp = unsortedOptions[e1];
    unsortedOptions[e1] = unsortedOptions[e2];
    unsortedOptions[e2] = temp;
  }

  const sortedOptions = getPartialRenovationOptions(unsortedOptions, action).flatMap((option, index) => {
    return { ...option, index };
  });

  const handleSelectAllClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setValue(getPartialRenovationOptions(envelopes, action));
    setOpen(false);

    //take out of focus so the tags get shortened
    const input = document.activeElement as HTMLElement;
    input?.blur();
  };

  const handleDeselectAllClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setValue([]);
  };

  //default value is all options selected
  useEffect(() => {
    setValue(getPartialRenovationOptions(envelopes, action));
    // we only want this on the first render so we don't have to worry about the value changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  return (
    <Controller
      control={control}
      name={'affected_parts'}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const allSelected = value.length === getPartialRenovationOptions(envelopes, action).length;
        return (
          <Autocomplete
            ref={autocompleteRef}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            multiple
            disableClearable
            limitTags={3}
            options={sortedOptions}
            size="small"
            onChange={(_, newValue) => onChange(newValue)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={value}
            sx={sx}
            disableCloseOnSelect
            disabled={disabled}
            popupIcon={<Iconify icon={ICONS.CHEVRON_DOWN} width={20} height={20} />}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  '& .MuiInputBase-root': { flexWrap: 'nowrap' },
                  '& .Mui-focused': {
                    flexWrap: 'wrap',
                  },
                }}
                label={t('General_AffectedParts')}
                error={!!error}
                helperText={error ? error.message : ''}
              />
            )}
            getOptionLabel={(option) => getEnvelopeLabel(option, t)}
            slotProps={{
              paper: {
                sx: {
                  mt: 1,
                },
              },
            }}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: 'preventOverflow',
                    enabled: false,
                    options: {
                      altBoundary: true,
                    },
                  },
                  {
                    name: 'flip',
                    enabled: true,
                    options: {
                      fallbackPlacements: ['top-start'],
                    },
                  },
                ],
              },
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Typography variant="body2" {...getTagProps({ index })} whiteSpace="nowrap">
                  {getEnvelopeLabel(option, t)}
                  {index !== value.length - 1 ? ', ' : ''}
                </Typography>
              ))
            }
            ListboxComponent={(props) => (
              <List
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                sx={{
                  '& li': {
                    '&:hover': {
                      backgroundColor: 'action.hover',
                    },
                  },
                }}
                {...props}
              >
                <Scrollbar sx={{ maxHeight: 340 }}>{props.children}</Scrollbar>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} px={2} py={1}>
                  {!allSelected ? (
                    <Button type="button" variant="text" onClick={handleSelectAllClick}>
                      {t('General_SelectAll')}
                    </Button>
                  ) : (
                    <Button type="button" variant="text" disabled={value.length === 0} onClick={handleDeselectAllClick}>
                      {t('General_DeselectAll')}
                    </Button>
                  )}
                </Stack>
              </List>
            )}
            renderOption={(props, option, { selected }) => {
              const label = getEnvelopeLabel(option, t);

              const showOrientation =
                action === envelope_type_enum.WALL ||
                action === envelope_type_enum.WINDOW ||
                action === envelope_type_enum.DOOR;

              const showInsulationThickness = action !== envelope_type_enum.DOOR;

              const windowThickness = option.insulation_material_name
                ?.match(/\d+/g)
                ?.map((number) => number)
                ?.join(' ');

              return (
                <ListItem
                  {...props}
                  style={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Checkbox checked={selected} />
                  <Stack direction={'column'} width={'100%'}>
                    <Typography>{label}</Typography>
                    <Stack direction={'row'} alignItems={'center'} gap={1}>
                      {showOrientation && (
                        <PartialRenovationOptionSubtext
                          title={t('General_Orientation')}
                          icon={ICONS.ORIENTATION}
                          value={translateOrientationEnum_short_dynamic(
                            getOrientationEnum(option.orientation ?? 0, option.inclination ?? -1),
                            t,
                          )}
                        />
                      )}
                      <PartialRenovationOptionSubtext
                        title={t('General_Area')}
                        icon={ICONS.AREA}
                        value={`${option.area.toFixed(1)} m²`}
                      />
                      {action === envelope_type_enum.WINDOW ? (
                        <PartialRenovationOptionSubtext
                          title={t('General_Glazing')}
                          icon={ICONS.INSULATION_THICKNESS}
                          value={`${windowThickness}x`}
                        />
                      ) : (
                        showInsulationThickness && (
                          <PartialRenovationOptionSubtext
                            title={t('General_InsulationThickness')}
                            icon={ICONS.INSULATION_THICKNESS}
                            value={`${((option?.insulation_thickness ?? 0) * 100).toFixed(0)} cm`}
                          />
                        )
                      )}
                      <PartialRenovationOptionSubtext
                        title={t('General_UValue')}
                        icon={ICONS.U_VALUE}
                        value={`${option?.u_value?.toString()} W/(m²K)`}
                      />
                    </Stack>
                  </Stack>
                </ListItem>
              );
            }}
          />
        );
      }}
    />
  );
}
