import { Popover, Stack, Typography, useTheme } from '@mui/material';
import { EUTaxonomyComplianceBenchmarks } from '@predium/client-lookup';
import { country_enum, eu_taxonomy_compliance_enum, type_of_use_enum } from '@predium/enums';
import { fShortenNumber } from '@predium/utils';
import { PropsWithChildren, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Iconify from '../../components/Iconify';
import { SemanticButton } from '../../components/Tile';
import { PreLink } from '../../components/presentations/PreLink';

type Props = {
  compliance: eu_taxonomy_compliance_enum;
  toBeSaved: number;
};

export default function EUTaxonomyTooltip({ compliance, toBeSaved, children }: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);

  const title = {
    COMPLIANT: t('ESGAnalysis_EUTaxonomyCompliance-compliantTitle'),
    NON_COMPLIANT: t('ESGAnalysis_EUTaxonomyCompliance-nonCompliantTitle'),
    MEETS_DNSH_CRITERIA: t('ESGAnalysis_EUTaxonomyCompliance-partiallyCompliantTitle'),
    UNKNOWN: t('ESGAnalysis_EUTaxonomyCompliance-unknownTitle'),
  };

  /* TODO */
  const articleLink = (
    <PreLink
      link={{
        href: 'https://support.predium.de/artikel/eu-taxonomie-einfach-erklrt',
        name: t('General_LearnMore'),
        external: true,
      }}
    />
  );

  const subtitle = {
    COMPLIANT: (
      <Trans
        i18nKey={'ESGAnalysis_EUTaxonomyCompliance-compliantSubtitle'}
        components={{
          articleLink,
          br: <br />,
          bold: <strong />,
        }}
      />
    ),
    NON_COMPLIANT: (
      <Trans
        i18nKey={'ESGAnalysis_EUTaxonomyCompliance-nonCompliantSubtitle'}
        values={{ toBeSaved: fShortenNumber(toBeSaved, 1) }}
        components={{
          articleLink,
          br: <br />,
          bold: <strong />,
        }}
      />
    ),
    MEETS_DNSH_CRITERIA: (
      <Trans
        i18nKey={'ESGAnalysis_EUTaxonomyCompliance-partiallyCompliantSubtitle'}
        values={{ toBeSaved: fShortenNumber(toBeSaved, 1) }}
        components={{
          articleLink,
          br: <br />,
          bold: <strong />,
        }}
      />
    ),
    UNKNOWN: t('ESGAnalysis_EUTaxonomyCompliance-unknownSubtitle'),
  };

  return (
    <>
      <SemanticButton
        onClick={() => setOpen(true)}
        ref={buttonRef}
        sx={{
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        {children}
      </SemanticButton>
      <Popover
        open={open}
        anchorEl={buttonRef.current}
        onClose={() => setOpen(false)}
        disableRestoreFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ mt: 2, ml: -6 }}
        /**
         * Arrow styling
         * (has to be done manually, because the Popover component does not use popper.js, but the Modal API instead)
         */
        PaperProps={{
          sx: {
            overflowX: 'unset',
            overflowY: 'unset',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: '50%',
              width: 15,
              height: 15,
              backgroundColor: theme.palette.background.paper,
              boxShadow: theme.shadows[1],
              transform: 'translate(0%, -50%) rotate(-45deg)',
              clipPath: 'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))',
            },
          },
        }}
      >
        <Stack
          sx={{
            py: 2,
            px: 3,
            minWidth: '450px',
            maxWidth: '650px',
            gap: 2,
          }}
        >
          <Stack direction={'row'} alignItems={'center'} gap={0.5}>
            <Iconify icon={icon[compliance]} width={20} height={20} color={iconColor[compliance]} />
            <Typography variant="subtitle1">{title[compliance]}</Typography>
          </Stack>
          <Typography variant="body2" color="text.secondary">
            {subtitle[compliance]}
          </Typography>
        </Stack>
      </Popover>
    </>
  );
}

const complianceColor: Record<eu_taxonomy_compliance_enum, string> = {
  COMPLIANT: 'success.main',
  NON_COMPLIANT: 'error.main',
  MEETS_DNSH_CRITERIA: 'warning.main',
  UNKNOWN: 'grey.600',
};

const icon: Record<eu_taxonomy_compliance_enum, string> = {
  COMPLIANT: 'ic:round-check-circle',
  NON_COMPLIANT: 'ph:warning-octagon-fill',
  MEETS_DNSH_CRITERIA: 'material-symbols:warning',
  UNKNOWN: 'ic:round-help',
};

const iconColor: Record<eu_taxonomy_compliance_enum, string> = {
  COMPLIANT: 'success.main',
  NON_COMPLIANT: 'error.main',
  MEETS_DNSH_CRITERIA: 'warning.main',
  UNKNOWN: 'grey.600',
};

/**
 * Colors for the EU Taxonomy Compliance Tooltip, based on the enum value
 */
export const euTaxonomyColors = {
  complianceColor,
  icon,
  iconColor,
};

/**
 * Calculate the primary energy that needs to be saved to reach compliance with the EU Taxonomy
 * @param currentPrimaryEnergy The current primary energy value
 * @param compliance The current compliance tier
 * @param country The country of the building
 * @param typeOfUse The type of use of the building
 * @returns The primary energy that needs to be saved
 */
export const calculateEUTaxonomyToBeSaved = (
  currentPrimaryEnergy: number,
  compliance: eu_taxonomy_compliance_enum,
  country: country_enum,
  typeOfUse: type_of_use_enum,
) => {
  if (compliance === eu_taxonomy_compliance_enum.COMPLIANT) {
    return 0;
  }

  return currentPrimaryEnergy - EUTaxonomyComplianceBenchmarks[country][typeOfUse].COMPLIANT;
};
