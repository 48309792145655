/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ReportQuestionDC } from './ReportQuestion.dc';

export class ReportQuestionOptionDC {
  //@ts-ignore
  public id: number;

  //@ts-ignore
  public order: number;

  //@ts-ignore
  public value: string;
  public report_template_child_questions?: ReportQuestionDC[];

  constructor(unstructuredReportQuestionOption: Partial<ReportQuestionOptionDC>) {
    // Copying all the properties from the unstructured report to this class
    Object.assign(this, unstructuredReportQuestionOption);
  }
}
