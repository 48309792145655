/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FormControl, Select, SelectChangeEvent, SelectProps, TextFieldProps, useTheme } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import RHFNumberField, { RHFNumberFieldProps } from './RHFNumberField';

type Props = {
  /**
   * Props applied to the Select element.
   */
  SelectProps?: SelectProps;
  /**
   * Name of the select field (as per react-hook-form)
   */
  selectName: string;
  /**
   * Name of the input field (as per react-hook-form)
   */
  inputName: string;
  /**
   * Whether to disable the select field but not the input field.
   */
  selectDisabled?: boolean;
  helperText?: string;

  /**
   * A node to be rendered additionally before the select control.
   */
  endAdornmentPrePosition?: ReactNode;
} & Omit<RHFNumberFieldProps, 'name'>;

export default function RHFInputWithSelect({
  selectName,
  inputName,
  children,
  SelectProps,
  InputProps,
  selectDisabled,
  helperText,
  endAdornmentPrePosition,
  ...extendNumberFieldProps
}: PropsWithChildren<Props> & Pick<TextFieldProps, 'InputProps'> & Pick<TextFieldProps, 'InputLabelProps'>) {
  const { control } = useFormContext();
  const theme = useTheme();

  return (
    <RHFNumberField
      helperText={helperText}
      {...extendNumberFieldProps}
      sx={{
        '& .MuiOutlinedInput-input': {
          p: 0,
        },
        '& .MuiInputLabel-root': {
          // need to find a way to fix the vertical alignment here (the label is not centered)
        },
        '& .MuiInputBase-root ': {
          p: 1,
          pl: 1.5,
          '& .MuiOutlinedInput-root': {
            pr: 1.5,
          },
        },
        ...extendNumberFieldProps.sx,
      }}
      name={inputName}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <>
            {endAdornmentPrePosition}
            <Controller
              name={selectName}
              control={control}
              render={({ field: { ref, ...field } }) => {
                const onSelectChange = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
                  const value = event.target.value;
                  field.onChange(value);
                  SelectProps?.onChange?.(event, child);
                };

                return (
                  <FormControl>
                    <Select
                      {...SelectProps}
                      {...field}
                      name={selectName}
                      disabled={extendNumberFieldProps.disabled || selectDisabled}
                      inputRef={ref}
                      onChange={onSelectChange}
                      sx={{
                        backgroundColor: theme.palette.grey[500_8],
                        '&:hover':
                          !extendNumberFieldProps.disabled && !selectDisabled
                            ? {
                                backgroundColor: theme.palette.grey[500_24],
                              }
                            : undefined,
                        '&& .MuiSelect-select': {
                          textOverflow: 'unset',
                        },
                        // Increasing specificity by repeating the class names
                        '&& .MuiOutlinedInput-notchedOutline': {
                          borderColor: theme.palette.grey[500_24], // Normal border color
                        },
                        '&&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: theme.palette.grey[500], // Custom color when the Select is focused
                        },
                      }}
                    >
                      {children}
                    </Select>
                  </FormControl>
                );
              }}
            />
          </>
        ),
      }}
    />
  );
}
