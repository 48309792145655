import { orientation_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateOrientationEnum_short = (enumValue: orientation_enum) => {
  switch (enumValue) {
    case orientation_enum.NORTH:
      return <Trans i18nKey="Enum_OrientationEnum-short-NORTH" />;
    case orientation_enum.NORTH_EAST:
      return <Trans i18nKey="Enum_OrientationEnum-short-NORTH_EAST" />;
    case orientation_enum.EAST:
      return <Trans i18nKey="Enum_OrientationEnum-short-EAST" />;
    case orientation_enum.SOUTH_EAST:
      return <Trans i18nKey="Enum_OrientationEnum-short-SOUTH_EAST" />;
    case orientation_enum.SOUTH:
      return <Trans i18nKey="Enum_OrientationEnum-short-SOUTH" />;
    case orientation_enum.SOUTH_WEST:
      return <Trans i18nKey="Enum_OrientationEnum-short-SOUTH_WEST" />;
    case orientation_enum.WEST:
      return <Trans i18nKey="Enum_OrientationEnum-short-WEST" />;
    case orientation_enum.NORTH_WEST:
      return <Trans i18nKey="Enum_OrientationEnum-short-NORTH_WEST" />;
    case orientation_enum.HORIZONTAL:
      return <Trans i18nKey="Enum_OrientationEnum-short-HORIZONTAL" />;
  }
};

export const translateOrientationEnum_short_dynamic = (
  enumValue: orientation_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case orientation_enum.NORTH:
      return t('Enum_OrientationEnum-short-NORTH');
    case orientation_enum.NORTH_EAST:
      return t('Enum_OrientationEnum-short-NORTH_EAST');
    case orientation_enum.EAST:
      return t('Enum_OrientationEnum-short-EAST');
    case orientation_enum.SOUTH_EAST:
      return t('Enum_OrientationEnum-short-SOUTH_EAST');
    case orientation_enum.SOUTH:
      return t('Enum_OrientationEnum-short-SOUTH');
    case orientation_enum.SOUTH_WEST:
      return t('Enum_OrientationEnum-short-SOUTH_WEST');
    case orientation_enum.WEST:
      return t('Enum_OrientationEnum-short-WEST');
    case orientation_enum.NORTH_WEST:
      return t('Enum_OrientationEnum-short-NORTH_WEST');
    case orientation_enum.HORIZONTAL:
      return t('Enum_OrientationEnum-short-HORIZONTAL');
  }
};
