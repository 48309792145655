import { Box, Button, Chip, Tooltip, alpha, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { data_source_type_enum } from '@predium/enums';
import { translateDataSourceTypeEnum, translateEnergySystemTypeEnum } from '@predium/i18n/client';
import { fShortenToTwoDecimal } from '@predium/utils';
import { t } from 'i18next';
import React from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import Iconify from '../../../../../components/Iconify';
import { EnergySystemFormValues, SystemRoute, getEnergySystemIcon } from '../../BuildingTechnology';
import DataSourceTypeChip from '../../Common/DataSourceTypeChip';
import useBuilding from '../../Context/useBuilding';

type Props = {
  dataSourceType: data_source_type_enum | null;
  totalFinalEnergy: number;
  energySystemType: keyof EnergySystemFormValues;
  resetSystem: () => void;
  onAddRouteClick: () => void;
  systemIndex: number;
  isExpanded: boolean;
};

const TechnologySectionHead: React.FC<Props> = ({
  dataSourceType,
  totalFinalEnergy,
  energySystemType,
  resetSystem,
  onAddRouteClick,
  systemIndex,
  isExpanded,
}) => {
  const theme = useTheme();

  const { control, getValues } = useFormContext();
  const { dirtyFields } = useFormState({
    control,
  });
  const { hasEditAccess } = useBuilding();

  const currentSystemData = getValues(energySystemType);

  const systemDirtyData = dirtyFields[energySystemType];
  const dirtyRoutesData = systemDirtyData && systemDirtyData[systemIndex] && systemDirtyData[systemIndex].routes;
  const isDirty =
    dirtyRoutesData && dirtyRoutesData.some((route: SystemRoute) => route && Object.keys(route).length > 0);

  const allRoutesDeleted =
    currentSystemData &&
    currentSystemData[systemIndex] &&
    currentSystemData[systemIndex].routes.every((route: SystemRoute) => route.routeDeleted);

  return (
    <Stack direction="row" sx={{ alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
      <Stack direction={'row'} alignItems="center">
        <Box component="img" src={getEnergySystemIcon(energySystemType)} width={48} height={48} mr={1} />
        <Typography variant="h4" style={{ textDecoration: allRoutesDeleted ? 'line-through' : 'none' }}>
          {translateEnergySystemTypeEnum(energySystemType)}
          {currentSystemData.length > 1 ? ` ${systemIndex + 1}` : ''}
        </Typography>
        <Stack direction={'row'} alignItems="center" mt={0.5}>
          <Tooltip title={t('DataCollection_Technology_SystemEnergyFinalTooltip')}>
            <Typography ml={2} variant="h5" fontWeight={400}>
              {`${fShortenToTwoDecimal(totalFinalEnergy)} kWh/m²a`}
            </Typography>
          </Tooltip>
          {dataSourceType &&
            !allRoutesDeleted &&
            (dataSourceType !== data_source_type_enum.MANUAL ? (
              <Tooltip title={t('DataCollection_ApproximationTooltip')} placement="top">
                <Box ml={1}>
                  <DataSourceTypeChip value={translateDataSourceTypeEnum(dataSourceType)} type={dataSourceType} />
                </Box>
              </Tooltip>
            ) : (
              <Tooltip title={t('DataCollection_ManualEditTooltip')}>
                <Box ml={1}>
                  <DataSourceTypeChip value={translateDataSourceTypeEnum(dataSourceType)} type={dataSourceType} />
                </Box>
              </Tooltip>
            ))}
          {allRoutesDeleted && (
            <Chip
              label={t('General_Deleted')}
              color="error"
              variant="soft"
              size="small"
              icon={<Iconify icon="mdi:trash-can-outline" color="error" />}
              sx={{ ml: 2, px: 1 }}
            />
          )}
        </Stack>
      </Stack>
      {isExpanded && hasEditAccess && (
        <Stack direction={'row'}>
          <Button
            variant="text"
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              onAddRouteClick();
            }}
            startIcon={<Iconify icon={'mdi:plus'} />}
            sx={{
              borderColor: alpha(theme.palette.grey[500], 0.24),
              mr: 1,
            }}
          >
            {t('DataCollection_Technology-AddEnergyFlow')}
          </Button>

          {isDirty && (
            <Button
              onClick={(event: React.MouseEvent) => {
                // Prevent the click event from reaching the parent Accordian summary so it doesnt expand according and perform actual action
                event.stopPropagation();
                resetSystem();
              }}
              sx={{ mr: 1 }}
              startIcon={<Iconify icon={'mdi:undo-variant'} width={20} height={20} />}
            >
              {t('General_UndoChanges')}
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default TechnologySectionHead;
