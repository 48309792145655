/* eslint-disable @typescript-eslint/ban-ts-comment */
// @mui
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { useState } from 'react';
import OverflowText from '../../../components/OverflowText';
import useSessionData from '../../../hooks/useSessionData';
import OrgMenu from './OrgMenu';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  width: 'calc(100% - 24px)',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
  marginBottom: '12px',
  alignSelf: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey[500_8],
  },
}));

// ----------------------------------------------------------------------

type Props = {
  isCollapse: boolean | undefined;
};

export default function NavbarOrg({ isCollapse }: Props) {
  const { org, user } = useSessionData();
  const name = (user?.first_name ?? '') + ' ' + (user?.last_name ?? '');

  //@ts-ignore

  //@ts-ignore
  const initials = (user?.first_name[0] ?? '') + (user?.last_name[0] ?? '');
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  return (
    <>
      <OrgMenu handleClosePopover={handleClosePopover} openPopover={openPopover} />
      <RootStyle
        onClick={handleOpenPopover}
        sx={{ backgroundColor: openPopover && 'rgba(145, 158, 171, 0.16)', p: 1.5 }}
      >
        <Avatar
          sx={{ bgcolor: '#ffab00', color: 'white', width: 48, height: 48, fontWeight: 700, fontSize: '14px' }}
          alt={name}
        >
          {initials}
        </Avatar>
        <Box
          sx={{
            ml: 1.5,
            width: isCollapse ? 'auto' : '100%',
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
          }}
        >
          {!isCollapse && (
            <>
              <OverflowText
                text={name}
                maxWidth={'150px'}
                color={'text.primary'}
                variant="subtitle1"
                TooltipProps={{
                  arrow: true,
                  placement: 'top-start',
                }}
              />
              {org?.name ? (
                <OverflowText
                  text={org?.name}
                  maxWidth={'150px'}
                  color={'text.secondary'}
                  TooltipProps={{
                    arrow: true,
                    placement: 'top-start',
                  }}
                />
              ) : (
                '-'
              )}
            </>
          )}
        </Box>
      </RootStyle>
    </>
  );
}
