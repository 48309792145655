import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { EnvelopeUnitFragment } from '@predium/client-graphql';
import { getOrientationEnum } from '@predium/client-lookup';
import { envelope_type_enum } from '@predium/enums';
import { translateOrientationEnum_short_dynamic } from '@predium/i18n/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/icons';
import Iconify from '../../../../components/Iconify';
import { getEnvelopeLabel } from '../ActionPlan.utils';
import { PartialRenovationOption } from '../Actions/CreateAction';
import PartialRenovationOptionSubtext from './PartialRenovationOptionSubtext';

type Props = {
  affectedParts: PartialRenovationOption[];
  action: envelope_type_enum;
  envelopes: EnvelopeUnitFragment[];
};

export default function AffectedPartsTable({ affectedParts, action }: Props) {
  const MAX_AFFECTED_PARTS_TO_SHOW = 3;
  const disabled = affectedParts.length <= MAX_AFFECTED_PARTS_TO_SHOW;
  const theme = useTheme();
  const [expanded, setExpanded] = useState(affectedParts.length <= MAX_AFFECTED_PARTS_TO_SHOW);
  const { t } = useTranslation();

  return (
    <Accordion
      sx={{
        background: theme.palette.grey[200],
        borderRadius: 1,
        mb: 3,
        cursor: disabled ? 'default' : 'pointer',
        '&.MuiAccordion-root:before': {
          backgroundColor: 'white',
        },
        '&.MuiAccordion-root ': {
          boxShadow: 'none',
          mb: 3,
        },

        //target .MuiAccordionSummary-root but only when expanded
        '&.MuiAccordion-root': {
          '& .MuiAccordionSummary-root': {
            '&.Mui-expanded': {
              minHeight: 0,
              height: 48,
            },
            '&.Mui-disabled': {
              color: 'text.primary',
            },
          },
        },
      }}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      disabled={disabled}
    >
      <AccordionSummary
        sx={{
          background: theme.palette.grey[200],
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          borderBottomRightRadius: !expanded ? 8 : 0,
          borderBottomLeftRadius: !expanded ? 8 : 0,
          boxShadow: 'none',
          px: 2,
        }}
      >
        <Stack sx={{ width: '100%' }}>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant="subtitle1">{t('General_AffectedParts')}</Typography>
            {!disabled && (
              <Iconify
                icon={ICONS.CHEVRON_DOWN}
                width={16}
                height={16}
                sx={{ color: 'text.secondary', transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
              />
            )}
          </Stack>
          {!expanded && (
            <Typography variant="body2" mt={2}>
              {affectedParts
                ?.slice(0, MAX_AFFECTED_PARTS_TO_SHOW)
                .map((part) => {
                  if (!part || !part?.id) {
                    return '';
                  }
                  return `${getEnvelopeLabel(part, t)}`;
                })
                .join(', ')}
              {affectedParts.length > MAX_AFFECTED_PARTS_TO_SHOW && (
                <Tooltip
                  placement="top"
                  arrow
                  title={
                    <List dense sx={{ p: 0 }}>
                      {affectedParts.slice(MAX_AFFECTED_PARTS_TO_SHOW).map((part) => {
                        if (!part || !part?.id) {
                          return '';
                        }
                        return <ListItem sx={{ listStyleType: 'none' }}>{getEnvelopeLabel(part, t)}</ListItem>;
                      })}
                    </List>
                  }
                >
                  <Box component={'span'}>{`, + ${affectedParts.length - MAX_AFFECTED_PARTS_TO_SHOW}`}</Box>
                </Tooltip>
              )}
            </Typography>
          )}
        </Stack>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          background: theme.palette.grey[200],
          boxShadow: 'none',
          border: 'none',
          borderBottomRightRadius: 8,
          borderBottomLeftRadius: 8,
          cursor: 'default',
          pt: 0,
        }}
      >
        {affectedParts.map((part) => {
          const label = getEnvelopeLabel(part, t);

          const showOrientation =
            action === envelope_type_enum.WALL ||
            action === envelope_type_enum.WINDOW ||
            action === envelope_type_enum.DOOR;

          const showInsulationThickness = action !== envelope_type_enum.DOOR;

          const windowThickness = part.insulation_material_name
            ?.match(/\d+/g)
            ?.map((number) => number)
            ?.join(' ');

          return (
            <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} sx={{ py: 0.5 }}>
              <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                {label}
              </Typography>
              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} gap={1}>
                {showOrientation && (
                  <PartialRenovationOptionSubtext
                    title={t('General_Orientation')}
                    icon={ICONS.ORIENTATION}
                    value={translateOrientationEnum_short_dynamic(
                      getOrientationEnum(part.orientation ?? 0, part.inclination ?? -1),
                      t,
                    )}
                  />
                )}
                <PartialRenovationOptionSubtext
                  title={t('General_Area')}
                  icon={ICONS.AREA}
                  value={`${part.area.toFixed(1)} m²`}
                />
                {action === envelope_type_enum.WINDOW ? (
                  <PartialRenovationOptionSubtext
                    title={t('General_Glazing')}
                    icon={ICONS.INSULATION_THICKNESS}
                    value={`${windowThickness}x`}
                  />
                ) : (
                  showInsulationThickness && (
                    <PartialRenovationOptionSubtext
                      title={t('General_InsulationThickness')}
                      icon={ICONS.INSULATION_THICKNESS}
                      value={`${((part?.insulation_thickness ?? 0) * 100).toFixed(0)} cm`}
                    />
                  )
                )}
                <PartialRenovationOptionSubtext
                  title={t('General_UValue')}
                  icon={ICONS.U_VALUE}
                  value={`${part?.u_value?.toString()} W/(m²K)`}
                />
              </Stack>
            </Stack>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}
