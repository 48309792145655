// material
import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';

// ----------------------------------------------------------------------

export default function LogoSmall({ sx }: BoxProps) {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 25, height: 30, ...sx }}>
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 343.64 343.86">
        <path d="M172,343.86H0v-172C0,77,77.16-.14,172-.14H344v172C344,266.7,266.84,343.86,172,343.86Zm-114-58H172a114.13,114.13,0,0,0,114-114v-114H172a114.13,114.13,0,0,0-114,114Z" />
      </svg>
    </Box>
  );
}
