import { Stack, Tooltip, Typography, alpha } from '@mui/material';
import { area_type_enum } from '@predium/enums';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../../components/Iconify';
import { useValidation } from '../Validation/ValidationProvider';

type Props = {
  areaType: area_type_enum;
};

const SquareMeterRowError = ({ areaType }: Props) => {
  const { t } = useTranslation();

  const { getAreaError } = useValidation();

  const errorMessage = getAreaError(areaType);

  if (!errorMessage) {
    return null;
  }

  return (
    <Tooltip title={errorMessage}>
      <Stack
        direction="row"
        alignItems="center"
        px={1}
        py={0.5}
        borderRadius={0.75}
        spacing={0.5}
        sx={(theme) => ({ bgcolor: alpha(theme.palette.warning.main, 0.16) })}
      >
        <Iconify color="warning.dark" icon="mdi:alert" />
        <Typography fontSize={12} fontWeight="bold" color="warning.dark">
          {t('DataCollectionAreas_EquationErrorLabel')}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export default SquareMeterRowError;
