import { FC } from 'react';
import { useLanguage } from '../../../provider/LanguageProvider';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '../../../utils/formatTime';
import { IBaseColumn } from './BaseColumn';
import { textColumn } from './TextColumn';

export const dateColumn = {
  ...textColumn,
  type: 'date',
  getValueLabel(value) {
    return <ValueLabel value={value} />;
  },
} satisfies IBaseColumn;

const ValueLabel: FC<{ value: unknown }> = ({ value }) => {
  const { language } = useLanguage();

  if (typeof value !== 'string') {
    throw new Error('Invalid date value');
  }

  return <>{formatDateToLocale(value, COMMON_DATE_FORMATS.DAY_MONTH_YEAR_TIME, language)}</>;
};
