import { MenuItem, TextField, TextFieldProps, useTheme } from '@mui/material';

interface Props {
  values: {
    id: number | string;
    name: string;
  }[];
  initialId: number | string;
  onChange: (newId: number | string) => void;
  selectType?: 'string' | 'number';
}

export default function SimpleSelect({
  values,
  initialId,
  onChange,
  selectType = 'number',
  ...props
}: Props & Omit<TextFieldProps, 'onChange'>) {
  const theme = useTheme();

  return (
    <TextField
      variant="outlined"
      select
      value={initialId}
      sx={{
        width: '100%',
        '& .MuiFormLabel-root': {
          backgroundColor: theme.palette.common.white,
          px: 0.5,
        },
        ...props.sx,
      }}
      {...props}
      onChange={(event) => {
        const value = event.target.value;
        onChange(selectType === 'number' ? parseInt(value) : value);
      }}
    >
      {values.map((value) => (
        <MenuItem key={value.id} value={value.id}>
          {value.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
