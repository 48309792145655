import { graphql } from '@predium/client-graphql';

graphql(`
  fragment GetSessionDataOrg on org {
    id
    name
  }
`);

graphql(`
  fragment ServerSideFeatureFlags on GetUserFeatureFlagsOutput {
    BUILDING_CREATION_CONSUMER_EXTENSION
    CONFIGMANAGER_EMISSIONFACTORTYPE_DISABLED
    AREA_MANAGEMENT
    CONSUMPTION_ANALYSIS
  }
`);

graphql(`
  fragment GetSessionDataUser on user {
    id
    first_name
    last_name
    email
    created_at
    language
    org {
      ...GetSessionDataOrg
    }
    user_role {
      role
    }
    featureFlags {
      ...ServerSideFeatureFlags
    }
  }
`);

export const GET_SESSION_DATA = graphql(`
  query GetSessionData($userId: Int!) {
    user_by_pk(id: $userId) {
      ...GetSessionDataUser
    }
  }
`);

export const GET_USERS = graphql(`
  query GetAllUsers {
    user {
      ...GetSessionDataUser
    }
  }
`);

export const GET_USER_BY_ID = graphql(`
  query GetUserById($userId: Int!) {
    user_by_pk(id: $userId) {
      ...GetSessionDataUser
    }
  }
`);

export const GET_USER_OBJECTS_AGGREGATE = graphql(`
  query GetAllUserObjectsAggregate($userId: Int!) {
    report_aggregate(where: { responsible_user_id: { _eq: $userId } }) {
      aggregate {
        count
      }
    }

    scenario_aggregate(where: { owner_id: { _eq: $userId } }) {
      aggregate {
        count
      }
    }

    building_aggregate(where: { responsible_user_id: { _eq: $userId } }) {
      aggregate {
        count
      }
    }

    energy_certificate_draft_aggregate(where: { responsible_user_id: { _eq: $userId } }) {
      aggregate {
        count
      }
    }

    action_plan_aggregate(where: { scenario: { owner_id: { _eq: $userId } } }) {
      aggregate {
        count
      }
    }

    action_aggregate(where: { action_plan: { scenario: { owner_id: { _eq: $userId } } } }) {
      aggregate {
        count
      }
    }

    consumption_aggregate(where: { created_by_user_id: { _eq: $userId } }) {
      aggregate {
        count
      }
    }

    consumption_draft_aggregate(where: { created_by_user_id: { _eq: $userId } }) {
      aggregate {
        count
      }
    }
  }
`);

/**
 * Queries all relevant notifications of a user that are specific to him, his organization or generally.
 * Results are ordered by recent notifications first.
 */
export const GET_NOTIFICATIONS = graphql(`
  query GetNotifications($user_id: Int!) {
    notification(where: { user_id: { _eq: $user_id } }, order_by: { created_at: desc }) {
      id
      __typename
      notification_template {
        id
        __typename
        title
        description
        href
      }
      read
      created_at
    }
  }
`);
