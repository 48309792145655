import { graphql } from '@predium/client-graphql';

export const GET_PORTFOLIO_AND_BUILDING_PERMISSIONS = graphql(`
  query GetPortfolioAndBuildingPermissions($user_id: Int!) {
    user_portfolio_permission(where: { user_id: { _eq: $user_id } }) {
      portfolio_id
      read
      write
    }
    user_building_permission(where: { user_id: { _eq: $user_id } }) {
      building_id
      read
      write
    }
    building {
      id
      economic_unit {
        portfolio_id
      }
    }
  }
`);
