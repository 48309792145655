/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Divider, Grid, ListItemIcon, MenuItem, useTheme } from '@mui/material';
import { envelope_type_enum, insulation_material_category_enum, insulation_method_enum } from '@predium/client-graphql';
import {
  translateEnvelopeTypeEnum_insulation,
  translateInsulationMaterialCategoryEnum,
  translateInsulationMethodEnum,
} from '@predium/i18n/client';
import { mToCm } from '@predium/utils';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormProvider, RHFDatePicker, RHFNumberField, RHFSelect } from '../../../../../../components/hook-form';
import InfoTable, { InfoTableData } from '../../../../../../components/presentations/InfoTable';
import { getActionIcon } from '../../../../../../utils/icons';
import { createEnvelopeInfoTable } from '../../../../Scenario/scenarios.util';
import { NumberInputSx, SelectSx, StyledListItemText } from '../../../Components/ActionCustomStyles';
import AffectedPartsTable from '../../../PartialRenovations/AffectedPartsTable';
import { ActionProps } from '../../ViewAction';

type NonWindowDoorActionForm = {
  envelope_type_id: envelope_type_enum;
  insulation_method_id: insulation_method_enum | string;
  insulation_material_name: string;
  insulation_material_category_id: insulation_material_category_enum | string;
  insulation_lambda: number;
  insulation_thickness: number;
  cost_per_m2: number;
  implementation_to: string;
};

//Non window and door envelope actions
export default function NonWindowDoorAction({ action, previousActionBuildingModel, affectedParts }: ActionProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const envelopeData = action.renovations_envelope[0].new_envelope;
  const {
    //@ts-ignore
    envelope_type_id,

    //@ts-ignore
    insulation_material_name,

    //@ts-ignore
    cost_per_m2: costPerM2,

    //@ts-ignore
    insulation_lambda,

    //@ts-ignore
    insulation_method_id,

    //@ts-ignore
    insulation_thickness,

    //@ts-ignore
    insulation_material_category_id,
  } = envelopeData;

  const envelopes = previousActionBuildingModel.envelope_units;
  const buildingId = previousActionBuildingModel.building!.id;

  const infoTableData: InfoTableData[] | null = createEnvelopeInfoTable(envelope_type_id, envelopes, t);

  const defaultValues = {
    envelope_type_id,
    insulation_method_id: insulation_method_id ?? '',
    insulation_material_name: insulation_material_name ?? '',
    insulation_material_category_id: insulation_material_category_id ?? '',
    insulation_lambda,
    insulation_thickness: mToCm(insulation_thickness, 2),
    cost_per_m2: costPerM2,
    implementation_to: action.implementation_to,
  };

  const formMethods = useForm<NonWindowDoorActionForm>({
    defaultValues: defaultValues,
  });

  const actionType = action.renovations_envelope?.[0]?.new_envelope?.envelope_type_id;

  return (
    <FormProvider methods={formMethods}>
      <Box my={3}>
        <RHFSelect
          name="envelope_type_id"
          label={t('General_Category')}
          size={'small'}
          sx={{ ...SelectSx, mb: 3 }}
          disabled
        >
          <MenuItem value={envelope_type_id}>
            <ListItemIcon style={{ minWidth: 18 }}>
              <Box component="img" src={getActionIcon(envelope_type_id)} />
            </ListItemIcon>
            <StyledListItemText action={translateEnvelopeTypeEnum_insulation(envelope_type_id)} />
          </MenuItem>
        </RHFSelect>

        {infoTableData && (
          <Box mb={3}>
            <InfoTable buildingId={buildingId} data={infoTableData} title={t('General_StateBeforeRenovation')} />
          </Box>
        )}

        {affectedParts.length > 0 && !!actionType && (
          <AffectedPartsTable affectedParts={affectedParts} action={actionType} envelopes={envelopes} />
        )}
        <RHFSelect
          name="insulation_method_id"
          label={t('General_Selection')}
          size={'small'}
          sx={{ ...SelectSx, mb: 3 }}
          disabled
        >
          <MenuItem value={insulation_method_id}>
            <StyledListItemText action={translateInsulationMethodEnum(insulation_method_id)} />
          </MenuItem>
        </RHFSelect>

        <Box
          sx={{
            p: 2,
            border: `1px solid ${theme.palette.grey[500_32]}`,
            borderRadius: '8px',
            mb: 3,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <RHFSelect
                name="insulation_material_name"
                label={t('General_InsulationMaterial')}
                size={'small'}
                sx={SelectSx}
                disabled
              >
                <MenuItem value={insulation_material_name}>
                  <StyledListItemText action={insulation_material_name} secondary={'€'} />
                </MenuItem>
                <Divider />
              </RHFSelect>
            </Grid>
          </Grid>

          <RHFSelect
            name="insulation_material_category_id"
            label={t('CreateNewEnvelopeAction_InsulationMaterialCategory')}
            size={'small'}
            sx={{ ...SelectSx, mb: 2 }}
            disabled
          >
            <MenuItem value={insulation_material_category_id}>
              <StyledListItemText action={translateInsulationMaterialCategoryEnum(insulation_material_category_id)} />
            </MenuItem>
          </RHFSelect>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <RHFNumberField
                name="insulation_lambda"
                label={`${t('General_ThermalConductivity')} [W/(m·K)]`}
                size={'small'}
                sx={NumberInputSx}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <RHFNumberField
                name="insulation_thickness"
                label={`${t('General_InsulationThickness')} [cm]`}
                size={'small'}
                sx={NumberInputSx}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} mt={2}>
              <RHFNumberField
                name="cost_per_m2"
                label={t('General_PricePerM2Insulation')}
                size={'small'}
                sx={NumberInputSx}
                disabled
              />
            </Grid>
          </Grid>
        </Box>
        <RHFDatePicker
          label={t('General_PlannedFor')}
          name="implementation_to"
          size={'small'}
          sx={NumberInputSx}
          disabled
        />
      </Box>
    </FormProvider>
  );
}
