import { Button, InputAdornment, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SnackbarTimeouts } from '../../components/NotistackProvider';
import Iconify from '../Iconify';
import PreDialog, { PreDialogBody } from '../presentations/PreDialog/PreDialog';

type HelpCenterModalProps = {
  onClose: () => void;
  open: boolean;
};
const PASSWORD = 'hkr59Txky3';

function HelpCenterModalContent() {
  const { t } = useTranslation();
  const [copyOnCooldown, setCopyOnCooldown] = useState(false);

  const copyPassword = async () => {
    navigator.clipboard.writeText(PASSWORD);
    setCopyOnCooldown(true);
    setTimeout(() => {
      setCopyOnCooldown(false);
    }, 5000);
  };

  return (
    <>
      <Typography variant="body1">{t('HelpCenter_Content')}</Typography>
      <Typography variant="body1" sx={{ mt: 3 }}>
        {t('HelpCenter_UseThisPassword')}
      </Typography>
      <TextField
        name="password"
        value={PASSWORD}
        fullWidth
        variant="outlined"
        InputProps={{
          readOnly: true,
          sx: {
            // Needed to add !important to disable color change on focus
            // Already tried the Mui-focused class but it didn't work as expected
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(145, 158, 171, 0.2) !important',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(145, 158, 171, 0.2)',
            },
            '& .MuiOutlinedInput-input': {
              p: 1.5,
            },
          },
          endAdornment: (
            <InputAdornment position="end">
              {!copyOnCooldown ? (
                <Button
                  variant="text"
                  onClick={copyPassword}
                  startIcon={<Iconify icon="mdi:content-copy" color="action.active" width={18} height={18} />}
                >
                  {t('HelpCenter_CopyPassword')}
                </Button>
              ) : (
                <>
                  <Iconify icon="mdi:tick-circle" color="success.main" width={18} height={18} sx={{ mr: 1 }} />
                  <Typography
                    color="success.main"
                    sx={{ fontWeight: 700, fontSize: '14px', lineHeight: '22px', pr: 1 }}
                  >
                    {t('HelpCenter_PasswordCopied')}
                  </Typography>
                </>
              )}
            </InputAdornment>
          ),
        }}
        sx={{
          px: 0,
          mt: 1,
        }}
      />
    </>
  );
}

type HelpCenterModalActionsProps = {
  onClose: () => void;
};
function HelpCenterModalActions({ onClose }: HelpCenterModalActionsProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const copyPasswordAndProceed = async () => {
    try {
      const url = 'https://support.predium.de/';
      window.open(url, '_blank', 'noopener,noreferrer');
      onClose();
    } catch (error) {
      enqueueSnackbar(t('General_CopyToClipboardError'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    }
  };
  return (
    <>
      <Button
        type="submit"
        variant="contained"
        onClick={copyPasswordAndProceed}
        endIcon={<Iconify icon="mdi:open-in-new" />}
      >
        {t('HelpCenter_OpenAcademy')}
      </Button>
    </>
  );
}

export default function HelpCenterModal({ onClose, open }: HelpCenterModalProps) {
  return (
    <PreDialog open={open} onClose={onClose} type="definedByChildren" maxWidth="xs">
      <PreDialogBody
        dialogtitle={t('HelpCenter_Title')}
        content={<HelpCenterModalContent />}
        actions={<HelpCenterModalActions onClose={onClose} />}
      />
    </PreDialog>
  );
}
