/* eslint-disable @typescript-eslint/ban-ts-comment */
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { consumption_type_enum } from '@predium/enums';
import { translateConsumptionTypeEnum } from '@predium/i18n/client';
import { useTranslation } from 'react-i18next';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

type Props = {
  availableYearsWithConsumption: number[];
  fromToYearFilter: number | 'all';
  setFromToYearFilter: (year: number | 'all') => void;
  consumptionTypeFilter: consumption_type_enum | 'all';
  setConsumptionTypeFilter: (value: consumption_type_enum | 'all') => void;
};

export default function ConsumptionListToolbar({
  availableYearsWithConsumption,
  consumptionTypeFilter,
  fromToYearFilter,
  setConsumptionTypeFilter,
  setFromToYearFilter,
}: Props) {
  const { t } = useTranslation();

  return (
    <RootStyle>
      <Stack direction="row" sx={{ width: '100%' }}>
        <TextField
          select
          label={t('General_Year')}
          value={fromToYearFilter}
          onChange={(event) => setFromToYearFilter(event.target.value === 'all' ? 'all' : parseInt(event.target.value))}
          sx={{
            minWidth: 250,
            mr: 3,
          }}
        >
          <MenuItem key="all" value="all" sx={{ typography: 'body2', mx: 1, my: 0.5, borderRadius: 0.75 }}>
            {t('General_All')}
          </MenuItem>
          {availableYearsWithConsumption.map((option, index) => (
            <MenuItem key={index} value={option} sx={{ typography: 'body2', mx: 1, my: 0.5, borderRadius: 0.75 }}>
              {option}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          label={t('General_Category')}
          value={consumptionTypeFilter}
          onChange={(event) => setConsumptionTypeFilter(event.target.value as consumption_type_enum)}
          sx={{
            minWidth: 200,
            mr: 3,
          }}
        >
          <MenuItem key="all" value="all" sx={{ typography: 'body2', mx: 1, my: 0.5, borderRadius: 0.75 }}>
            {t('General_All')}
          </MenuItem>
          {Object.values(consumption_type_enum).map((consumptionType) => (
            <MenuItem
              key={consumptionType}
              value={consumptionType}
              sx={{ typography: 'body2', mx: 1, my: 0.5, borderRadius: 0.75 }}
            >
              {translateConsumptionTypeEnum(consumptionType)}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    </RootStyle>
  );
}
