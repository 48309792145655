import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { CreateReportModalGetAllTemplatesTemplateFragment } from '@predium/client-graphql';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../components/Iconify';
import { getTemplateMetadataForTemplateTitle } from '../ReportingDataClasses/ReportTemplate.dc';

type Props = {
  templates: CreateReportModalGetAllTemplatesTemplateFragment[];
  onTemplateSelection: (template: CreateReportModalGetAllTemplatesTemplateFragment) => void;
};

type TemplateButtonProps = {
  template: CreateReportModalGetAllTemplatesTemplateFragment;
  isLast: boolean;
};

export default function CreateReportModalTemplateSelection({ templates, onTemplateSelection }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [activeTemplate, setActiveTemplate] = useState<CreateReportModalGetAllTemplatesTemplateFragment>(templates[0]);

  const publicTemplates = templates.filter((t) => t.org_id === null);
  const privateTemplates = templates.filter((t) => t.org_id !== null);

  const templateButton = ({ template, isLast }: TemplateButtonProps) => {
    const templateMetadata = getTemplateMetadataForTemplateTitle(template.title);

    return (
      <Button
        sx={{
          boxShadow: theme.customShadows.card,
          borderRadius: 2,
          width: '100%',
          p: 3,
          mb: isLast ? 6 : 3,

          display: 'flex',
          justifyContent: 'flex-start',
          textAlign: 'left',
          border: '2px solid',
          borderColor: activeTemplate.id === template.id ? 'primary' : 'transparent',
          overflow: 'hidden',
        }}
        key={template.id}
        onClick={() => setActiveTemplate(template)}
      >
        {templateMetadata.logo ? (
          <img src={templateMetadata.logo} alt={template.title} style={{ maxHeight: 48 }} />
        ) : (
          <Typography variant="subtitle1">{templateMetadata.readableTitle}</Typography>
        )}

        <Box
          sx={{
            backgroundColor: templateMetadata.color,
            height: '100%',
            width: 35,
            position: 'absolute',
            right: 0,
          }}
        />
      </Button>
    );
  };

  return (
    <Grid container sx={{ mt: 3 }} spacing={3}>
      <Grid item xs={4}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {t('General_MarketStandards')}
        </Typography>
        {publicTemplates.map((template, index) => {
          return templateButton({ template, isLast: index === publicTemplates.length - 1 });
        })}
        <Typography variant="h6" sx={{ mb: 2 }}>
          {t('General_OwnTemplates')}
        </Typography>
        {privateTemplates.map((template, index) => {
          return templateButton({ template, isLast: index === privateTemplates.length - 1 });
        })}
      </Grid>

      <Grid item xs={8}>
        <Card
          sx={{
            ml: 3,
            p: 4,
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ mb: 3 }}>
            {getTemplateMetadataForTemplateTitle(activeTemplate.title).logo ? (
              <img
                src={getTemplateMetadataForTemplateTitle(activeTemplate.title).logo}
                alt={activeTemplate.title}
                style={{ maxHeight: 70 }}
              />
            ) : (
              <Typography variant="h4">
                {getTemplateMetadataForTemplateTitle(activeTemplate.title).readableTitle}
              </Typography>
            )}
          </Box>
          {activeTemplate.single_sub_building && (
            <Box sx={{ mb: 3, p: 2, backgroundColor: theme.palette.grey[200], borderRadius: 2 }}>
              <Typography variant="subtitle1">{t('Reporting_CreateReportSingleBuildingAlert-title')}</Typography>
              <Typography variant="subtitle1">{t('Reporting_CreateReportSingleBuildingAlert-subtitle')}</Typography>
            </Box>
          )}

          <Box sx={{ mb: 3, px: 2 }}>
            <Typography variant="subtitle1">{t('General_Description')}</Typography>
            <Typography>{activeTemplate.description}</Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
              sx={{ py: 1.5, width: '100%' }}
              onClick={() => {
                onTemplateSelection(activeTemplate);
              }}
            >
              {t('Reporting_CreateReportFromTemplateButton', {
                template: getTemplateMetadataForTemplateTitle(activeTemplate.title).readableTitle,
              })}
            </Button>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}
