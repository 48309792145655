import { Alert, Divider, FormHelperText, FormLabel, MenuItem, Stack, alpha, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RHFDatePicker, RHFNumberField, RHFSelect, RHFSwitch, RHFTextField } from '../../../components/hook-form';
import RHFInputWithSelect from '../../../components/hook-form/RHFInputWithSelect';
import useSessionData from '../../../hooks/useSessionData';
import { EmissionCertificateFormValuesProps } from './useCustomDocumentForm';

//const SUPPORTED_EMISSION_CERTIFICATE_TYPES = [emission_certificate_type_enum.DISTRICT_HEATING];

export default function EmissionCertificateForm() {
  const { t } = useTranslation();
  const { isAdmin } = useSessionData();
  const methods = useFormContext<EmissionCertificateFormValuesProps>();
  const theme = useTheme();

  const { trigger, getFieldState } = methods;

  const [hasEmissionFactor, hasPrimaryEnergyFactor] = methods.watch([
    'has_emission_factor',
    'has_primary_energy_factor',
  ]);

  const { errors } = useFormState();
  /**
   * Error message incase both emission factor and primary energy factor are not provided
   */
  const emissionFactorValueError = errors.emission_factor_value;

  useEffect(() => {
    const emissionFactorState = getFieldState('emission_factor_value');
    const primaryEnergyFactorState = getFieldState('primary_energy_factor');

    const emissionFactorIsDirtyOrError = emissionFactorState.isDirty || emissionFactorState.error;
    const primaryEnergyFactorIsDirtyOrError = primaryEnergyFactorState.error || primaryEnergyFactorState.isDirty;

    if (emissionFactorIsDirtyOrError || primaryEnergyFactorIsDirtyOrError) {
      trigger('emission_factor_value');
      trigger('primary_energy_factor');
    }
  }, [getFieldState, hasEmissionFactor, hasPrimaryEnergyFactor, trigger]);

  return (
    <FormProvider {...methods}>
      <Divider sx={{ my: 1 }} />
      <Alert
        severity="info"
        sx={{
          mb: 2,
          backgroundColor: alpha(theme.palette.info.main, 0.08),
          border: `1px solid ${alpha(theme.palette.info.main, 0.16)}`,
        }}
      >
        {t('DataCollection_ProcessDocument_EmissionCertificateInfo')}
      </Alert>
      <RHFSelect
        value="district_heating"
        disabled
        name="field_of_application"
        label={t('General_EnergySource')}
        sx={{
          '& .Mui-disabled': { backgroundColor: theme.palette.grey[200], color: 'text.secondary', borderRadius: 1 },
          '& .Mui-disabled .MuiOutlinedInput-input': {
            WebkitTextFillColor: theme.palette.text.primary,
          },
        }}
      >
        <MenuItem value="district_heating">{t('EmissionCertificateForm_DistrictHeating')}</MenuItem>
      </RHFSelect>
      <RHFTextField name="issuer" label={t('General_Issuer')} disabled={!isAdmin} />
      {(isAdmin || hasEmissionFactor) && (
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <FormLabel sx={{ color: 'text.primary', fontWeight: 700, fontSize: 14 }}>
              {t('General_EmissionFactor')}
            </FormLabel>
            {isAdmin && (
              <RHFSwitch
                name="has_emission_factor"
                label=""
                size="small"
                sx={{
                  marginRight: -3,
                }}
              />
            )}
          </Stack>
          {hasEmissionFactor && (
            <RHFInputWithSelect
              selectName={`emission_factor_unit`}
              inputName={`emission_factor_value`}
              variant="outlined"
              disabled={!isAdmin}
              placeholder={t('General_EmissionFactor')}
              nullable
              autoFocus
              sx={{
                '[disabled]': {
                  pointerEvents: 'none',
                },
                '& .MuiOutlinedInput-input': {
                  p: 0,
                },
                '& .MuiInputLabel-root': {
                  // need to find a way to fix the vertical alignment here (the label is not centered)
                },
                '& .MuiInputBase-root ': {
                  p: 0.75,
                  pl: 1.5,
                  '& .MuiOutlinedInput-root': {
                    pr: 1.5,
                  },
                },
              }}
              InputProps={{
                autoComplete: 'off',
                sx: {},
              }}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                  MenuListProps: {
                    sx: {
                      minWidth: '200px',
                    },
                  },
                  sx: {
                    minWidth: '400px',
                  },
                },
                SelectDisplayProps: {
                  style: {
                    //otherwise we get a truncate
                    overflow: 'visible',
                    //match the padding of the select, to get the selected element centered
                    paddingRight: '148px',
                  },
                },
              }}
            >
              <MenuItem value={'gco2e_kwh'}>{t('General_Units_GCO2KWH')}</MenuItem>
              <MenuItem value={'kgco2e_kwh'}>{t('General_Units_KGCO2KWH')}</MenuItem>
              <MenuItem value={'kgco2e_mwh'}>{t('General_Units_KGCO2MWH')}</MenuItem>
            </RHFInputWithSelect>
          )}
        </Stack>
      )}
      {(isAdmin || hasPrimaryEnergyFactor) && (
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <FormLabel sx={{ color: 'text.primary', fontWeight: 700, fontSize: 14 }}>
              {t('General_PrimaryEnergyFactor')}
            </FormLabel>
            {isAdmin && (
              <RHFSwitch
                name="has_primary_energy_factor"
                label=""
                size="small"
                sx={{
                  marginRight: -3,
                }}
              />
            )}
          </Stack>
          {hasPrimaryEnergyFactor && (
            <RHFNumberField
              nullable
              autoFocus
              name="primary_energy_factor"
              placeholder={t('General_PrimaryEnergyFactor')}
              disabled={!isAdmin}
              sx={{
                '[disabled]': {
                  pointerEvents: 'none',
                },
                '& .MuiOutlinedInput-input': {
                  p: 1.5,
                },
              }}
            />
          )}
          {!hasPrimaryEnergyFactor && !hasEmissionFactor && emissionFactorValueError && (
            <FormHelperText error>{emissionFactorValueError?.message?.toString()}</FormHelperText>
          )}
        </Stack>
      )}
      <Stack direction={'column'} gap={2}>
        <RHFDatePicker name="valid_from" label={t('General_IssueDate')} disabled={!isAdmin} />
        <RHFDatePicker name="valid_until" label={t('General_ValidUntil')} disabled={!isAdmin} />
      </Stack>
    </FormProvider>
  );
}
