import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { DataCollectionSubBuildingSubBuildingSubsidyFragment } from '@predium/client-graphql';
import { subsidy_region_type_enum } from '@predium/enums';
import { translateSubsidyCategoryTypeEnum, translateSubsidyRegionTypeEnum } from '@predium/i18n/client';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../../../../components/Iconify';
import TableMoreMenu from '../../../../../../../components/table/TableMoreMenu';
import { useLanguage } from '../../../../../../../provider/LanguageProvider';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '../../../../../../../utils/formatTime';
import ExportSubsidyData from '../../../../../../DataCollection/Building/General/SubBuildingSubsidyListExport';
import { SubsidyTypeBadge } from './SubsidyTypeBadge';

type RegionType = 'LOCAL' | 'FEDERAL' | 'STATE' | 'OTHER';

export type SubsidyData = {
  [P in RegionType]: DataCollectionSubBuildingSubBuildingSubsidyFragment[];
};

type SubsidyListProps = {
  data: SubsidyData;
};

export function SubsidyList(props: SubsidyListProps) {
  const { data } = props;

  return (
    <Box sx={{ mb: 2, mt: 1 }}>
      {Object.keys(data).map((key) => {
        const dataProperty = key as keyof SubsidyData;

        return (
          <Accordion
            key={key}
            style={{ boxShadow: 'none' }}
            sx={{
              '&:before': {
                backgroundColor: 'transparent',
              },
              '.MuiAccordionSummary-content.Mui-expanded': { minHeight: 0, m: 0 },
              pr: 2,
              pl: 1,
            }}
          >
            <AccordionSummary
              sx={{
                '&.Mui-expanded': { minHeight: 0, m: 0 },
                '.MuiAccordionSummary-content': {
                  p: 0,
                },
              }}
              expandIcon={<Iconify icon="ic:outline-arrow-forward-ios" style={{ transform: 'rotate(90deg' }} />}
            >
              <Stack direction="row">
                <Typography variant="subtitle1">
                  {translateSubsidyRegionTypeEnum(key as subsidy_region_type_enum)} {`(${data[dataProperty].length})`}
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ pb: 0 }}>
              {data[dataProperty].map((item) => {
                return <SubsidyListItem item={item} key={item.id} />;
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}

type SubsidyListItemProps = {
  item: DataCollectionSubBuildingSubBuildingSubsidyFragment;
};

function SubsidyListItem({ item }: SubsidyListItemProps) {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const theme = useTheme();

  const updatedDateString = t('General_UpdatedOnWithDate', {
    date: formatDateToLocale(item.provider_updated_at, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language),
  });

  return (
    <Stack
      direction="row"
      alignItems="start"
      justifyContent={'space-between'}
      spacing={2}
      sx={{
        border: `1px solid ${theme.palette.grey[500_24]}`,
        my: 2,
        borderRadius: 1.5,
        px: 3,
        py: 2,
      }}
    >
      <Stack sx={{ mt: 0.5, overflow: 'hidden', width: '100%' }}>
        <Stack
          direction="row"
          sx={{
            mb: 1,
          }}
        >
          <SubsidyTypeBadge type={item.type} size={'small'} />
          <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '80%' }}>
            <Tooltip title={<Typography variant="body2">{item.title}</Typography>} placement={'top'}>
              <Typography variant="subtitle1" fontWeight={500} component={'span'}>
                {item.title}
              </Typography>
            </Tooltip>
          </Box>
        </Stack>
        <Typography variant="body2" color="textSecondary">
          {updatedDateString}
        </Typography>
        <Box
          sx={{
            mt: 1,
          }}
        >
          {item.categories.map((categoryData) => (
            <Chip
              key={categoryData.subsidy_category_type}
              label={translateSubsidyCategoryTypeEnum(categoryData.subsidy_category_type)}
              size="small"
              variant="outlined"
              sx={{
                mr: 1,
                color: 'textSecondary',
                mb: 1,
              }}
            />
          ))}
        </Box>

        <Typography
          variant="body2"
          color="text.primary"
          dangerouslySetInnerHTML={{ __html: item.amount_description }}
        />
      </Stack>

      <Stack alignItems="flex-end">
        <TableMoreMenu
          actions={
            <>
              {item.url && (
                <MenuItem component="a" href={item.url} target={'_blank'}>
                  <Iconify icon={'ci:external-link'} />
                  {t('General_AdditionalInformation')}
                </MenuItem>
              )}
              {item.email && (
                <MenuItem component="a" href={`mailto:${item.email}`}>
                  <Iconify icon={'material-symbols:mail-outline'} />
                  {t('General_EmailForInformation')}
                </MenuItem>
              )}
              <MenuItem
                sx={{
                  a: {
                    textDecoration: 'none',
                    color: theme.palette.primary.main,
                  },
                }}
              >
                <Iconify icon={'eva:cloud-download-outline'} />
                <ExportSubsidyData data={[item]} title={t('DataCollectionSubBuildingsSubsidyList_ExportButtonText')} />
              </MenuItem>
            </>
          }
        />
      </Stack>
    </Stack>
  );
}
