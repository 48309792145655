import { useMutation } from '@apollo/client';
import { AreaRecalculateMutation } from '@predium/client-graphql';
import { area_type_enum, data_source_type_enum } from '@predium/enums';
import pick from 'lodash/pick';
import { useSnackbar } from 'notistack';
import { PropsWithChildren, createContext, useCallback, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import { RECALCULATE_AREAS } from '../../../../graphql/DataCollection.mutations';
import { useDummyLoading } from '../../../../hooks/useDummyLoading';
import useBuilding from '../Context/useBuilding';
import { useValidation } from './Validation/ValidationProvider';
import { AreaFormValues } from './types';

type RecalculateContextType = {
  recalculate: () => Promise<void>;
  getIsOnlyNUFChanged: () => boolean;
  getNUFsForRecalculate: () => AreaRecalculateMutation[];
  isRecalculationLoading: boolean;
};

const RecalculateContext = createContext<RecalculateContextType | null>(null);

const RecalculateProvider = ({ children }: PropsWithChildren) => {
  const { getValues, setValue } = useFormContext<AreaFormValues>();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { building } = useBuilding();
  const { triggerValidation } = useValidation();

  const { dummyLoading, startLoading } = useDummyLoading();

  const [recalculateAreas] = useMutation(RECALCULATE_AREAS);

  const getIsOnlyNUFChanged = useCallback(() => {
    const areas = getValues('areas');

    const isAnyNonNUFAreaChanged = areas.some(
      (area) => area.area_type_id !== area_type_enum.NUF && area.data_source_id !== data_source_type_enum.APPROXIMATED,
    );

    return !isAnyNonNUFAreaChanged;
  }, [getValues]);

  const getNUFsForRecalculate = useCallback(() => {
    return getValues('areas')
      .filter((area) => area.area_type_id === area_type_enum.NUF && !area.delete)
      .map((area) => pick(area, ['id', 'value', 'area_type_id', 'area_system_id', 'type_of_use_id']));
  }, [getValues]);

  const recalculate = useCallback(async () => {
    if (!getIsOnlyNUFChanged()) {
      return triggerValidation();
    }

    const request = recalculateAreas({
      variables: {
        buildingId: building.id,
        areaMutations: getNUFsForRecalculate(),
      },
    });

    /**
     * Dummy loading state to show loading state when recalculation is triggered for a short period of time
     */
    const [response] = await startLoading([request]);

    const data = response.data;

    if (!data || !data.recalculateAreas.success) {
      enqueueSnackbar(t('General_AnErrorHasOccurred'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });

      return;
    }

    getValues('areas').forEach((area, areaIndex) => {
      if (area.area_type_id === area_type_enum.NUF) {
        return;
      }

      const recalculatedArea = data.recalculateAreas.areas?.find(({ id }) => id === area.id);

      if (!recalculatedArea) {
        return;
      }

      setValue(`areas.${areaIndex}.value`, recalculatedArea.value, {
        shouldDirty: true,
      });
    });

    triggerValidation();
  }, [
    building.id,
    enqueueSnackbar,
    getIsOnlyNUFChanged,
    getNUFsForRecalculate,
    getValues,
    recalculateAreas,
    setValue,
    startLoading,
    t,
    triggerValidation,
  ]);

  return (
    <RecalculateContext.Provider
      value={{
        recalculate,
        getIsOnlyNUFChanged,
        getNUFsForRecalculate,
        isRecalculationLoading: dummyLoading,
      }}
    >
      {children}
    </RecalculateContext.Provider>
  );
};

export const useRecalculate = () => {
  const context = useContext(RecalculateContext);

  if (!context) {
    throw new Error('useRecalculate must be used within a RecalculateProvider');
  }

  return context;
};

export default RecalculateProvider;
