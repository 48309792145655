/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Alert } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_ASSOCIATED_ACTION_PLANS_AND_REPORTS_FROM_BUILDING } from '../../graphql/DataCollection.queries';
import useSessionData from '../../hooks/useSessionData';
import { getConflictsCount } from '../../pages/DataCollection/EmissionCertificates/utils';
import FocusViewPage from '../FocusViewPage';
import PdfViewer from '../PdfViewer';
import { FormProvider } from '../hook-form';
import ProcessDocumentForm from './ProcessDocumentForm';
import useCustomDocumentForm, {
  EmissionCertificateFormValuesProps,
  ValidDocumentFragments,
} from './forms/useCustomDocumentForm';

type Props = {
  /**
   * If true, the dialog will be in draft mode and the save button will be labeled "Approve"
   */
  draftMode: boolean;
  /**
   * The document to be processed in the form of a fragment, must be supported by `ProcessDocumentForm`
   */
  emissionCertificateData: ValidDocumentFragments;
  /**
   * The title that will be shown at the top of the dialog
   */
  title: string;
  /**
   * callback when the view is closed or has been submitted successfully
   */
  handleCloseCallback: () => void;

  /**
   * Callback when the delete button is pressed.
   */
  onDeleteCallback: () => void;
};

/**
 * A custom fullscreen dialog for processing a pdf document, used for both drafts and final documents.
 */
export default function ProcessDocumentFocusPage({
  draftMode,
  emissionCertificateData: document,
  title,
  handleCloseCallback,
  onDeleteCallback,
}: Props) {
  const { t } = useTranslation();
  const { isAdmin } = useSessionData();
  const disableSave = !isAdmin;

  const { data: associatedActionPlansAndReport } = useQuery(GET_ASSOCIATED_ACTION_PLANS_AND_REPORTS_FROM_BUILDING, {
    variables: {
      emissionCertificateId: document.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { methods, onContinueLater, onCreate, onSave, resetToDocumentValues, warningModal, setWarningModalOpen } =
    useCustomDocumentForm({
      documentType: 'emission_certificate',
      document,
      closeDialog: () => {
        handleClose();
      },
    });

  const isActuallyDirty = methods.formState.isDirty || Object.values(methods.formState.dirtyFields).length > 0;

  const handleOnContinueLater = async () => {
    if (!disableSave) {
      const data = methods.getValues();
      await onContinueLater(data);
      // we reset the form the the new values to avoid refetching the data
      methods.reset(data);
    }
    handleClose();
  };

  const handleOnSave = async (data: EmissionCertificateFormValuesProps) => {
    if (draftMode) {
      // we need to write the form data to the draft before saving
      await onContinueLater(data);
      await onCreate(data.id);
      handleClose();
      return;
    }
    //check if any changes were made
    if (isActuallyDirty) {
      const { hasEmissionFactorAssociated, hasPrimaryEnergyFactorAssociated } =
        getConflictsCount(associatedActionPlansAndReport);

      const dirtyFields = methods.formState.dirtyFields;

      if (
        (hasEmissionFactorAssociated && (dirtyFields.has_emission_factor || dirtyFields.emission_factor_value)) ||
        (hasPrimaryEnergyFactorAssociated &&
          (dirtyFields.has_primary_energy_factor || dirtyFields.primary_energy_factor))
      ) {
        setWarningModalOpen(true);
        return;
      }
      await onSave(data);
      handleClose();
      return;
    }
    handleClose();
  };

  const handleClose = () => {
    methods.reset();
    handleCloseCallback();
  };

  useEffect(() => {
    resetToDocumentValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps --- this causes an infinite loop when uploading a new file
  }, [document]);

  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(handleOnSave)}>
      <FocusViewPage
        title={title}
        subTitle={document.file.name ?? undefined}
        majorContent={
          <>
            {disableSave && (
              <Alert severity="info" sx={{ mb: 3 }}>
                {t('DataCollection_EmissionCertificateEditAccessRequired')}
              </Alert>
            )}
            <PdfViewer fileURL={document.file.downloadUrl} compact />
          </>
        }
        minorContent={<ProcessDocumentForm fileId={document?.file.id} />}
        onCloseCallback={handleClose}
        onDeleteCallback={onDeleteCallback}
        primaryAction={{
          label: draftMode ? t('General_Approve') : t('General_Save'),
          isDisabled: disableSave,
        }}
        secondaryAction={{
          label: t('General_ContinueLater'),
          onClick: handleOnContinueLater,
        }}
      />

      {warningModal}
    </FormProvider>
  );
}
