import { building_state_enum } from '@predium/enums';
import { PropsWithChildren, createContext, useEffect, useState } from 'react';
import useOrgPreferences from '../hooks/useOrgPreferences';
import { DataCollectionTabActionsEnum, DataCollectionTabEnum } from '../sections/DataCollection/Buildings/TabsEnums';
import { ALL_PORTFOLIOS } from '../sections/EsgAnalysis/EsgAnalysisFilterBarPortfolio';

export type DataCollectionBuildingTabState = { value?: DataCollectionTabEnum; action?: DataCollectionTabActionsEnum };

type DataCollectionTab = {
  currentTab: DataCollectionBuildingTabState;
  setCurrentTab: (value: DataCollectionBuildingTabState) => void;
};

type DataCollectionFilters = {
  selectedPortfolio: string;
  selectedStatus: building_state_enum | 'all';
  filterName: string;
};

type AllDataCollectionFilters = {
  dataCollectionBuildingFilters: DataCollectionFilters;
  dataCollectionDraftsFilters: Pick<DataCollectionFilters, 'selectedPortfolio'>;
  dataCollectionEconomicUnitsFilters: Omit<DataCollectionFilters, 'selectedStatus'>;
};

type DataCollectionFiltersContextProps = {
  filters: AllDataCollectionFilters;
  setSelectedPortfolioBuilding: (selectedTemplateFilter: string) => void;
  setSelectedStatusBuilding: (selectedStatus: building_state_enum | 'all') => void;
  setFilterNameBuilding: (name: string) => void;
  setSelectedPortfolioDrafts: (selectedTemplateFilter: string) => void;
  setSelectedPortfolioEconomicUnits: (selectedTemplateFilter: string) => void;
  setFilterNameEconomicUnit: (economicUnit: string) => void;
  resetAllFilters: () => void;
};

const initialFilters: AllDataCollectionFilters = {
  dataCollectionBuildingFilters: {
    selectedPortfolio: ALL_PORTFOLIOS,
    selectedStatus: 'all',
    filterName: '',
  },
  dataCollectionDraftsFilters: {
    selectedPortfolio: ALL_PORTFOLIOS,
  },
  dataCollectionEconomicUnitsFilters: {
    selectedPortfolio: ALL_PORTFOLIOS,
    filterName: '',
  },
};

const DataCollectionFiltersContext = createContext<(DataCollectionFiltersContextProps & DataCollectionTab) | null>(
  null,
);

function DataCollectionFilterContextProvider({ children }: PropsWithChildren) {
  const { economicUnitsToggled } = useOrgPreferences();

  useEffect(() => {
    setCurrentTab({
      value: economicUnitsToggled ? DataCollectionTabEnum.ECONOMIC_UNITS : DataCollectionTabEnum.BUILDINGS,
    });
  }, [economicUnitsToggled]);

  const [filters, setFilters] = useState<AllDataCollectionFilters>(initialFilters);

  const [currentTab, setCurrentTab] = useState<DataCollectionBuildingTabState>({
    value: economicUnitsToggled ? DataCollectionTabEnum.ECONOMIC_UNITS : DataCollectionTabEnum.BUILDINGS,
    action: undefined,
  });

  // TODO PRE-2034
  const resetAllFilters = () => {
    setFilters(initialFilters);
  };

  const setSelectedPortfolioBuilding = (selectedPortfolio: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      dataCollectionBuildingFilters: {
        ...prevFilters.dataCollectionBuildingFilters,
        selectedPortfolio,
      },
    }));
  };

  const setSelectedPortfolioDrafts = (selectedPortfolio: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      dataCollectionDraftsFilters: {
        ...prevFilters.dataCollectionDraftsFilters,
        selectedPortfolio,
      },
    }));
  };

  const setSelectedPortfolioEconomicUnits = (selectedPortfolio: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      dataCollectionEconomicUnitsFilters: {
        ...prevFilters.dataCollectionEconomicUnitsFilters,
        selectedPortfolio,
      },
    }));
  };

  const setSelectedStatusBuilding = (selectedStatus: building_state_enum | 'all') => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      dataCollectionBuildingFilters: {
        ...prevFilters.dataCollectionBuildingFilters,
        selectedStatus,
      },
    }));
  };

  const setFilterNameBuilding = (filterName: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      dataCollectionBuildingFilters: {
        ...prevFilters.dataCollectionBuildingFilters,
        filterName,
      },
    }));
  };

  const setFilterNameEconomicUnit = (filterName: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      dataCollectionEconomicUnitsFilters: {
        ...prevFilters.dataCollectionEconomicUnitsFilters,
        filterName,
      },
    }));
  };
  return (
    <DataCollectionFiltersContext.Provider
      value={{
        filters,
        currentTab,
        setSelectedPortfolioBuilding,
        setSelectedStatusBuilding,
        setFilterNameBuilding,
        setSelectedPortfolioDrafts,
        setFilterNameEconomicUnit,
        setSelectedPortfolioEconomicUnits,
        resetAllFilters,
        setCurrentTab,
      }}
    >
      {children}
    </DataCollectionFiltersContext.Provider>
  );
}

export { DataCollectionFilterContextProvider, DataCollectionFiltersContext };
