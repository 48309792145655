/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { Button, DialogActions, DialogContent, DialogTitle, Stack, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../components/Iconify';
import { DelayedLoading } from '../../../components/Loading';
import { createStandardErrorSnackbar } from '../../../components/NotistackProvider';
import { BUILDING_EXCEL_EXPORT } from '../../../graphql/DataCollection.mutations';
import useSessionData from '../../../hooks/useSessionData';
import { useLanguage } from '../../../provider/LanguageProvider';
import { downloadFile } from '../../../utils/createDownloadLink';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '../../../utils/formatTime';

type Props = {
  buildingIds: number[];
  onActionCompleted?: () => void;
};

export default function DataCollectionBuildingsDownloadModal({ buildingIds, onActionCompleted }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { language } = useLanguage();

  const displayStandardMutationQueryErrorSnackbar = createStandardErrorSnackbar(t);

  const [buildingExcelExportMutation, { data }] = useMutation(BUILDING_EXCEL_EXPORT, {
    onError: () => displayStandardMutationQueryErrorSnackbar(enqueueSnackbar),
  });
  const [delayed, setDelayed] = useState(false);
  const { org } = useSessionData();

  //TODO refactor this and get rid of useEffect.
  useEffect(() => {
    buildingExcelExportMutation({
      variables: {
        buildingIds,
      },
    });

    // This is used to make sure the loading screen is shown at least 2 seconds
    setTimeout(() => {
      setDelayed(true);
    }, 2000);
  }, [buildingIds, buildingExcelExportMutation]);

  if (data?.exportSubBuildingsAsExcel?.body && delayed) {
    const currentDate = formatDateToLocale(new Date(), COMMON_DATE_FORMATS.DAY_MONTH_YEAR_TIME, language);

    return (
      <>
        <DialogTitle>{t('General_DataExportCompleted')}</DialogTitle>
        <Stack direction="column" alignItems="center">
          <Iconify icon="bi:check" width={90} height={90} sx={{ mt: 3, mb: 3 }} color={theme.typography.h4.color} />
        </Stack>

        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            startIcon={<Iconify icon={'eva:cloud-download-outline'} />}
            onClick={() => {
              //@ts-ignore
              const fileName = `${currentDate}-Predium-Datenexport-${org.name.replace(' ', '-')}.xlsx`;
              downloadFile(data.exportSubBuildingsAsExcel.body, 'xlsx', fileName);
              if (onActionCompleted) {
                onActionCompleted();
              }
            }}
          >
            {t('General_DownloadDataExport')}
          </Button>
        </DialogActions>
      </>
    );
  }

  return (
    <>
      <DialogTitle>{t('DataCollection_BuildingDataExportIsCreated', { count: buildingIds.length })}</DialogTitle>
      <DialogContent sx={{ height: 250 }}>
        <DelayedLoading delay={0} />
      </DialogContent>
    </>
  );
}
