/* eslint-disable @typescript-eslint/ban-ts-comment */
import { MenuItem } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { GetSessionDataUserFragment } from '@predium/client-graphql';
import { translateRoleEnum } from '@predium/i18n/client';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { ICONS } from '../../../assets/icons';
import Iconify from '../../../components/Iconify';
import TableMoreMenu from '../../../components/table/TableMoreMenu';
import TableRowWithHighlight, { hasRecentlyChanged } from '../../../components/table/TableRowWithHighlight';
import { ICON } from '../../../config';
import useSessionData from '../../../hooks/useSessionData';
import { useLanguage } from '../../../provider/LanguageProvider';
import { PATHS } from '../../../routes';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '../../../utils/formatTime';

type Props = {
  row: GetSessionDataUserFragment;
  openUserDeleteDialog: () => void;
};

export default function UserTableRow({
  row: { first_name, last_name, user_role, email, created_at, id },
  openUserDeleteDialog,
}: Props) {
  const { language } = useLanguage();
  const { user } = useSessionData();
  const navigate = useNavigate();

  const navigateToUser = () => {
    navigate(PATHS.org.user({ id }));
  };

  return (
    <TableRowWithHighlight
      sx={{ cursor: 'pointer', height: 68 }}
      hover
      background={hasRecentlyChanged(created_at) ? 'success' : 'default'}
      onClick={navigateToUser}
    >
      <TableCell>
        <Typography variant="subtitle2" noWrap>
          {first_name + ' ' + last_name}
        </Typography>
      </TableCell>
      <TableCell sx={{ textTransform: 'capitalize' }}>{translateRoleEnum(user_role[0]?.role)}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{formatDateToLocale(created_at, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language)}</TableCell>
      <TableCell align="right" onClick={(e) => e.stopPropagation()}>
        {/*@ts-ignore */}
        {user.id !== id && (
          <TableMoreMenu
            actions={
              <>
                <MenuItem onClick={openUserDeleteDialog} sx={{ color: 'error.main' }}>
                  <Iconify icon={ICONS.TRASH} sx={{ ...ICON }} />
                  {t('General_Delete')}
                </MenuItem>
                <MenuItem onClick={navigateToUser}>
                  <Iconify icon={ICONS.EDIT} sx={{ ...ICON }} />
                  {t('General_Edit')}
                </MenuItem>
              </>
            }
          />
        )}
      </TableCell>
    </TableRowWithHighlight>
  );
}
