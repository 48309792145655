/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Box, Card, CardHeader, Stack, Typography } from '@mui/material';
import { fPercent } from '@predium/utils';
import { useTranslation } from 'react-i18next';
import { DelayedLoading } from '../../../components/Loading';
import LinearProgressBar from '../../../components/data-visialization/LinearProgressBar';
import { REPORT_HEAD_GET_PROGRESS } from '../../../graphql/Report.queries';

type Props = {
  id: number;
};

function ReportProgress({ id }: Props) {
  const { t } = useTranslation();
  const { data: progressData, loading: progressLoading } = useQuery(REPORT_HEAD_GET_PROGRESS, {
    variables: {
      reportId: id,
    },
  });

  if (progressLoading) return <DelayedLoading />;

  //@ts-ignore

  //@ts-ignore
  const progress = progressData.report_by_pk.report_sections_progress.map(({ progress, report_template_section }) => ({
    section_name: report_template_section.name,
    section_progress: progress,
  }));

  return (
    <Card sx={{ p: 3, height: '100%' }}>
      <CardHeader
        title={t('General_Progress')}
        sx={{ p: 0, pr: 1 }}
        //@ts-ignore

        //@ts-ignore
        action={fPercent(progressData.report_by_pk.progress * 100, true)}
      />

      {progress.map(({ section_name, section_progress }, index) => (
        <Box key={index} sx={{ mt: 3 }}>
          <Stack direction="row" justifyContent="space-between" sx={{ mb: 1 }}>
            <Typography variant="body1">{section_name}</Typography>

            <Typography align="right" fontWeight={800}>
              {fPercent(section_progress * 100, true)}
            </Typography>
          </Stack>

          <LinearProgressBar progress={section_progress} />
        </Box>
      ))}
    </Card>
  );
}

export default ReportProgress;
