import { createSvgIcon } from '@mui/material';
import palette from '../../../theme/palette';

export const IconConsumptionElectricity = createSvgIcon(
  <svg width="20" height="20" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.03334 12.134L10.4833 8.00065H7.81667L8.3 4.21732L5.21667 8.66732H7.53334L7.03334 12.134ZM5.33334 14.6673L6 10.0007H2.66667L8.66667 1.33398H10L9.33334 6.66732H13.3333L6.66667 14.6673H5.33334Z"
      fill="#637381"
    />
  </svg>,
  'IconConsumptionElectricity',
);

const IconConsumptionElectricity_Colored = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.03334 12.134L10.4833 8.00065H7.81667L8.3 4.21732L5.21667 8.66732H7.53334L7.03334 12.134ZM5.33334 14.6673L6 10.0007H2.66667L8.66667 1.33398H10L9.33334 6.66732H13.3333L6.66667 14.6673H5.33334Z"
      fill={palette.light.warning.dark}
    />
  </svg>,
  'IconConsumptionElectricity',
);

export default IconConsumptionElectricity_Colored;
