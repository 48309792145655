import { Button, SxProps, Theme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import Iconify from '../Iconify';
import MenuPopover, { MenuPopoverArrowValue } from '../MenuPopover';

type BaseProps = {
  actions: React.ReactNode;
  sx?: SxProps<Theme>;
  arrow?: MenuPopoverArrowValue;
};
type IconProps = BaseProps & {
  variant: 'icon';
  icon?: string;
  buttonText?: string;
};

type ButtonProps = BaseProps & {
  variant: 'button';
  icon: string;
  buttonText?: string;
};

type Props = IconProps | ButtonProps;

export default function ActionMoreMenu({
  actions,
  variant = 'icon',
  icon = 'eva:more-vertical-fill',
  buttonText,
  sx,
  arrow,
}: Props) {
  const actionIcon = <Iconify icon={icon} width={20} height={20} />;

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      {variant === 'icon' ? (
        <IconButton onClick={handleOpen} sx={sx}>
          {actionIcon}
        </IconButton>
      ) : (
        <Button onClick={handleOpen} startIcon={actionIcon} sx={sx}>
          {buttonText}
        </Button>
      )}

      <MenuPopover
        open={open}
        anchorEl={open}
        onClose={handleClose}
        handleClick={handleClose}
        arrow={arrow}
        sx={{
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            '& svg': { mr: 2, width: 20, height: 20 },
          },
        }}
      >
        {actions}
      </MenuPopover>
    </>
  );
}
