import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../assets/icons';
import Iconify from '../../../components/Iconify';
import TableMoreMenu from '../../../components/table/TableMoreMenu';
import ExportReport from './ExportReport';

type Props = {
  reportId: number;
  reportTemplateType: string;
  showDeleteReport: () => void;
  exportTemplates: string[];
  completed: boolean;
};

export default function ReportActionMenu({
  reportId,
  reportTemplateType,
  showDeleteReport,
  exportTemplates,
  completed,
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      <TableMoreMenu
        actions={
          <>
            <ExportReport
              reportId={reportId}
              completed={completed}
              reportTemplateType={reportTemplateType}
              exportTemplates={exportTemplates}
            />

            <MenuItem onClick={showDeleteReport} sx={{ color: 'error.main' }}>
              <Iconify icon={ICONS.TRASH} />
              {t('General_Delete')}
            </MenuItem>
          </>
        }
      />
    </>
  );
}
