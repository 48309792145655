/**
 * Creates a filename for graph export.
 *
 * The filename is in the format "DD-MM-YYYY entities", where:
 * - DD-MM-YYYY is the current date.
 * - entities is a string created by joining the elements of the input array with a hyphen (-).
 *
 * @param {string[]} entities - The entities to include in the filename.
 * @returns {string} The filename for graph export.
 */
export function createGraphExportFileName(...entities: string[]): string {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = String(date.getFullYear());
  const dayToStr = `${day}-${month}-${year}`;
  return `${dayToStr} ${entities.join('-')}`;
}
