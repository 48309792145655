/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Box, Button, Container, Grid, Stack, Tooltip } from '@mui/material';
import { EsgAnalysisGetBuildingsForSingleBuildingFragment } from '@predium/client-graphql';
import uniqBy from 'lodash/uniqBy';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ICONS } from '../../assets/icons';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import LoadingScreen from '../../components/LoadingScreen';
import { createStandardErrorSnackbar } from '../../components/NotistackProvider';
import Page from '../../components/Page';
import { SelectWithSearchValue } from '../../components/SelectMultiWithSearch';
import SelectWithSearch from '../../components/SelectWithSearch';
import Unavailable, { UnavailablePageForMissingPortfolios } from '../../components/Unavailable';
import StickyBox from '../../components/common/StickyBox';
import { PermissionType } from '../../contexts/PermissionContext';
import { GET_ALL_BUILDINGS, GET_BENCHMARK } from '../../graphql/EsgAnalysis.queries';
import usePermissions from '../../hooks/usePermissions';
import usePosthogTracking from '../../hooks/usePosthogTracking';
import { PATHS } from '../../routes';
import { DataCollectionTabActionsEnum } from '../../sections/DataCollection/Buildings/TabsEnums';
import EsgAnalysisBenchmark, { ENTIRE_COMPANY } from '../../sections/EsgAnalysis/EsgAnalysisBenchmark';
import EsgAnalysisBuildingSection from '../../sections/EsgAnalysis/EsgAnalysisBuildingSection';
import EsgAnalysisUnitSwitcher from '../../sections/EsgAnalysis/EsgAnalysisUnitSwitcher';
import EsgPdfExport from '../../sections/EsgAnalysis/EsgPdfExport';

export default function EsgAnalysisBuilding() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { trackEvent } = usePosthogTracking();
  const [selectedBuilding, setSelectedBuilding] = useState<EsgAnalysisGetBuildingsForSingleBuildingFragment | null>(
    null,
  );
  const [benchmarkId, setBenchmarkId] = useState<number>(ENTIRE_COMPANY);
  const [showTotal, setShowTotal] = useState<boolean>(false);
  const [buildingToExportAsPdf, setBuildingToExportAsPdf] = useState<{
    id: number;
    street: string;
    city: string;
    postalCode: string;
  } | null>(null);

  const { id } = useParams();
  const { checkPortfolioPermission } = usePermissions();
  const navigate = useNavigate();
  const displayStandardMutationQueryErrorSnackbar = createStandardErrorSnackbar(t);

  const {
    error: allBuildingsError,
    data: allBuildingsData,
    loading,
  } = useQuery(GET_ALL_BUILDINGS, {
    onError: () => displayStandardMutationQueryErrorSnackbar(enqueueSnackbar),
  });

  const getBenchmarkFilterVariables = () => {
    if (benchmarkId === ENTIRE_COMPANY) {
      return { portfolioId: -1 };
    }
    return { portfolioId: benchmarkId };
  };
  const {
    error: benchmarkError,
    data: benchmarkData,
    loading: benchmarkLoading,
  } = useQuery(GET_BENCHMARK, {
    variables: {
      ...getBenchmarkFilterVariables(),
    },
    onError: () => displayStandardMutationQueryErrorSnackbar(enqueueSnackbar),
  });

  useEffect(() => {
    if (!selectedBuilding) {
      return;
    }
    navigate(PATHS.esgAnalysis.building({ id: selectedBuilding.id }));
  }, [navigate, selectedBuilding]);

  if (loading || benchmarkLoading) {
    return <LoadingScreen />;
  }

  if (allBuildingsError || benchmarkError) {
    return <Unavailable title={t('EsgAnalysisBuilding_BuildingsDoNotExists')} />;
  }

  //set selected building to building from url param or choose the first if none is specified

  //@ts-ignore
  if (!selectedBuilding && allBuildingsData.building.length > 0) {
    //@ts-ignore
    setSelectedBuilding(allBuildingsData.building.find((a) => a.id === parseInt(id)) ?? allBuildingsData.building[0]);
  }

  const onBuildingSelected = (building: SelectWithSearchValue) => {
    //@ts-ignore
    const buildingSelected = allBuildingsData.building.find((a) => a.id === building.value);

    //@ts-ignore
    setSelectedBuilding(buildingSelected);

    trackEvent('BUILDING_SELECTED', {
      building_id: buildingSelected?.id ?? null,
    });
  };

  /**
   * Creates each option for the building select
   */
  const mapToBuildingSelectOption = (
    building: EsgAnalysisGetBuildingsForSingleBuildingFragment,
  ): { value: number; label: string } => {
    return {
      value: building.id,
      label: `${building.address.street}, ${building.address.postal_code}, ${building.address.city}`,
    };
  };

  const portfolios = uniqBy(
    //@ts-ignore
    allBuildingsData.building.map(({ economic_unit }) => ({
      id: economic_unit.portfolio.id,
      name: economic_unit.portfolio.name,
    })),
    'id',
  ).filter((portfolio) => checkPortfolioPermission(portfolio.id, PermissionType.READ));

  if (!selectedBuilding) {
    return (
      <UnavailablePageForMissingPortfolios
        title={t('General_Building')}
        heading={t('PageName_ESGAnalysisBuildingView')}
      >
        <Page title={t('General_Building')}>
          <Container sx={{ mb: 3 }} maxWidth="lg">
            <Grid container>
              <Grid item xs={6}>
                <HeaderBreadcrumbs heading={t('PageName_ESGAnalysisBuildingView')} links={[]} />
              </Grid>
              <Unavailable
                title={t('General_BuildingsUnavailable-title')}
                subTitle={t('General_BuildingsUnavailable-subtitle')}
                onClick={() =>
                  navigate(
                    PATHS.dataCollection.buildings(null, {
                      action: DataCollectionTabActionsEnum.CREATE_ENERGY_CERTIFICATE_DRAFT,
                    }),
                  )
                }
              />
            </Grid>
          </Container>
        </Page>
      </UnavailablePageForMissingPortfolios>
    );
  }

  const onDisplayBaseChange = (newDisplayBase: 'per_square_meter' | 'absolut') => {
    const doShowTotal = newDisplayBase === 'absolut';
    setShowTotal(doShowTotal);
    trackEvent('DISPLAY_BASE_CHANGED', {
      display_base: newDisplayBase,
    });
  };

  const buildingDataMissing = selectedBuilding.address.mapbox_building_ids?.length < 1;
  const onePagerButton = (
    <Button
      variant="outlined"
      disabled={buildingDataMissing}
      onClick={() =>
        setBuildingToExportAsPdf({
          id: selectedBuilding.id,
          street: selectedBuilding.address.street,
          city: selectedBuilding.address.city,
          postalCode: selectedBuilding.address.postal_code,
        })
      }
      startIcon={<Iconify icon={ICONS.PDF_EXPORT} width={20} height={20} />}
    >
      {t('EsgAnalysis_ExportDialogOpen-ButtonContained')}
    </Button>
  );

  return (
    <Page title={t('General_Building')}>
      <Container sx={{ mb: 3 }} maxWidth="lg">
        <Grid container mb={3}>
          <Grid item xs={6}>
            <HeaderBreadcrumbs heading={t('PageName_ESGAnalysisBuildingView')} links={[]} />
          </Grid>
          <Grid container item xs={6} justifyContent="end" alignContent="start">
            <Button
              sx={{ mr: 2, alignSelf: 'start' }}
              variant="outlined"
              onClick={() => {
                navigate(PATHS.dataCollection.building({ id: selectedBuilding.id }));
              }}
              startIcon={<Iconify icon={'mdi:database-outline'} width={20} height={20} />}
            >
              {t('ESGAnalysisBuilding_ShowGeneralData')}
            </Button>
            {buildingDataMissing ? (
              <Tooltip title={t('General_BuildingInformationNotAvailableMessage')}>
                <Box component="span">{onePagerButton}</Box>
              </Tooltip>
            ) : (
              onePagerButton
            )}
            {/*@ts-ignore*/}
            <EsgPdfExport building={buildingToExportAsPdf} handleClose={() => setBuildingToExportAsPdf(null)} />
          </Grid>
        </Grid>
        <StickyBox>
          <Grid container spacing={3} justifyContent={'space-between'}>
            <Grid item xs={12} md={3} lg={3}>
              <SelectWithSearch
                label={t('ESGAnalysisBuilding_BuildingSelection')}
                //@ts-ignore
                values={allBuildingsData.building.map(mapToBuildingSelectOption)}
                onSelect={onBuildingSelected}
                defaultValue={mapToBuildingSelectOption(selectedBuilding)}
                sx={{ flexGrow: 1 }}
                alwaysFocused
              />
            </Grid>

            <Grid item xs={12} md={2} lg={2}>
              <EsgAnalysisBenchmark portfolios={portfolios} initialId={benchmarkId} setBenchmarkId={setBenchmarkId} />
            </Grid>
          </Grid>
        </StickyBox>

        <Grid item xs={12} md={12} lg={12}>
          <Stack alignItems="end" my={3}>
            <EsgAnalysisUnitSwitcher onDisplayBaseChange={onDisplayBaseChange} />
          </Stack>
        </Grid>

        <Grid container spacing={3}>
          <EsgAnalysisBuildingSection
            building={selectedBuilding}
            benchmark={benchmarkData?.getBenchmark}
            portfolios={portfolios}
            benchmarkId={benchmarkId}
            showTotal={showTotal}
          />
        </Grid>
      </Container>
    </Page>
  );
}
