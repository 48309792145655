/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReportingReportsQuery } from '../../../../../../libs/generated/client-graphql/src';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { DELETE_REPORT } from '../../../graphql/Report.mutations';
import { PATHS } from '../../../routes';
import { ReportDC } from '../ReportingDataClasses/Report.dc';
import { getTemplateMetadataForTemplateTitle } from '../ReportingDataClasses/ReportTemplate.dc';

type Props = {
  report: ReportingReportsQuery['report'][number] | ReportDC | null;
  closeDeleteReportModal: () => void;
};

function DeleteReport({ report, closeDeleteReportModal }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  //@ts-ignore
  const reportReadableTitle = getTemplateMetadataForTemplateTitle(report?.report_template.title).readableTitle;

  const [deleteReport] = useMutation(DELETE_REPORT, {
    update: (cache, { data }) => {
      //@ts-ignore
      if (data.delete_report_by_pk) {
        const normalizedId = cache.identify({
          //@ts-ignore
          id: data.delete_report_by_pk.id,

          //@ts-ignore
          __typename: data.delete_report_by_pk.__typename,
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
    onCompleted: () => {
      enqueueSnackbar(t('DeleteReport_DeleteReport-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      navigate(PATHS.reporting.reports());
    },
    onError: () =>
      enqueueSnackbar(t('DeleteReport_DeleteReport-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
  });

  return (
    <>
      <DeleteConfirmationModal
        open={Boolean(report)}
        title={t('General_DeleteModalTitle-Report', { count: 1 })}
        description={
          <Trans
            i18nKey={'General_DeleteModalDescription-Report'}
            values={{
              name: reportReadableTitle,
              count: 1,
            }}
            components={{ bold: <strong /> }}
          />
        }
        onClose={closeDeleteReportModal}
        onDelete={() => {
          closeDeleteReportModal();
          deleteReport({
            variables: {
              //@ts-ignore
              id: report?.id,
            },
          });
        }}
      />
    </>
  );
}

export default DeleteReport;
