import { MenuItem, Stack, Typography } from '@mui/material';
import { data_source_type_enum, envelope_type_enum } from '@predium/enums';
import { useMemo } from 'react';
import { useFieldArray, useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../../components/Iconify';
import { createEnvelopeDefaultValues } from '../../BuildingEnvelope';
import {
  areFieldArraysDirty,
  countApproximatedEnvelopes,
  duplicateComponent,
  generateFieldName,
  getEnvelopeIcon,
} from '../Envelope.util';
import { EmptyEnvelopeUnit } from '../EnvelopeUnit/EmptyEnvelopeUnit';
import { EnvelopeOtherUnit } from '../EnvelopeUnit/EnvelopeOtherUnit';
import { EnvelopePitchedRoof } from '../EnvelopeUnit/EnvelopePitchedRoof';
import { EnvelopeProps, EnvelopeSectionWrapper } from './EnvelopeSectionWrapper';

export function TopEnvelopesSection({ title, icon }: EnvelopeProps) {
  const { t } = useTranslation();
  const { control, watch, resetField, setValue } = useFormContext();
  const { dirtyFields } = useFormState({
    control,
  });

  const { fields: pitchedRoofFields, append: appendPitchedRoof } = useFieldArray({
    control,
    name: envelope_type_enum.PITCHED_ROOF,
  });

  const { fields: topFloorCeilingFields, append: appendTopFloorCeiling } = useFieldArray({
    control,
    name: envelope_type_enum.TOP_FLOOR_CEILING,
  });

  const { fields: flatRoofFields, append: appendFlatRoof } = useFieldArray({
    control,
    name: envelope_type_enum.FLAT_ROOF,
  });

  const totalTabulaEnvelopes = useMemo(() => {
    return (
      countApproximatedEnvelopes(pitchedRoofFields) +
      countApproximatedEnvelopes(topFloorCeilingFields) +
      countApproximatedEnvelopes(flatRoofFields)
    );
  }, [pitchedRoofFields, topFloorCeilingFields, flatRoofFields]);

  const totalEnvelopes = useMemo(() => {
    return pitchedRoofFields.length + topFloorCeilingFields.length + flatRoofFields.length;
  }, [pitchedRoofFields, topFloorCeilingFields, flatRoofFields]);

  const watchPitchedRoofFieldArray = watch(envelope_type_enum.PITCHED_ROOF);
  const controlledPitchedRoofFields = pitchedRoofFields.map((field, index) => ({
    ...field,
    ...watchPitchedRoofFieldArray[index],
  }));

  const watchTopCeilingFieldArray = watch(envelope_type_enum.TOP_FLOOR_CEILING);
  const controlledTopCeilingFields = topFloorCeilingFields.map((field, index) => ({
    ...field,
    ...watchTopCeilingFieldArray[index],
  }));

  const watchFlatRoofFieldArray = watch(envelope_type_enum.FLAT_ROOF);
  const controlledFlatRoofFields = flatRoofFields.map((field, index) => ({
    ...field,
    ...watchFlatRoofFieldArray[index],
  }));

  const resetFieldArray = () => {
    resetField(envelope_type_enum.PITCHED_ROOF);
    resetField(envelope_type_enum.TOP_FLOOR_CEILING);
    resetField(envelope_type_enum.FLAT_ROOF);
  };

  const isFieldArrayDirty = areFieldArraysDirty(dirtyFields, [
    envelope_type_enum.PITCHED_ROOF,
    envelope_type_enum.TOP_FLOOR_CEILING,
    envelope_type_enum.FLAT_ROOF,
  ]);

  const totalPitchedRoof = pitchedRoofFields.length;
  const totalTopFloorCeiling = topFloorCeilingFields.length;
  const totalFlatRoof = flatRoofFields.length;

  const addPitchedRoofEnvelope = () => {
    appendPitchedRoof(createEnvelopeDefaultValues(envelope_type_enum.PITCHED_ROOF), {
      focusName: generateFieldName(envelope_type_enum.PITCHED_ROOF, totalPitchedRoof).areaFieldName,
    });
  };

  const addTopCeilingEnvelope = () => {
    appendTopFloorCeiling(createEnvelopeDefaultValues(envelope_type_enum.TOP_FLOOR_CEILING), {
      focusName: generateFieldName(envelope_type_enum.TOP_FLOOR_CEILING, totalTopFloorCeiling).areaFieldName,
    });
  };

  const addFlatRoofEnvelope = () => {
    appendFlatRoof(createEnvelopeDefaultValues(envelope_type_enum.FLAT_ROOF), {
      focusName: generateFieldName(envelope_type_enum.FLAT_ROOF, totalFlatRoof).areaFieldName,
    });
  };

  const removeEnvelope = (index: number, type: envelope_type_enum) => {
    setValue(generateFieldName(type, index).deleteFieldName, true, {
      shouldDirty: true,
    });
    setValue(generateFieldName(type, index).dataSourceTypeFieldName, data_source_type_enum.MANUAL);
  };

  return (
    <EnvelopeSectionWrapper
      title={title}
      icon={icon}
      totalTabulaEnvelopes={totalTabulaEnvelopes}
      totalEnvelopes={totalEnvelopes}
      isSectionDirty={isFieldArrayDirty}
      resetSectionEnvelopes={resetFieldArray}
      actions={
        <>
          <MenuItem onClick={addPitchedRoofEnvelope}>
            <Iconify icon={getEnvelopeIcon(envelope_type_enum.PITCHED_ROOF)} />
            {t('General_PitchedRoof')}
          </MenuItem>
          <MenuItem onClick={addTopCeilingEnvelope}>
            <Iconify icon={getEnvelopeIcon(envelope_type_enum.TOP_FLOOR_CEILING)} />
            {t('General_TopFloorCeiling_one')}
          </MenuItem>
          <MenuItem onClick={addFlatRoofEnvelope}>
            <Iconify icon={getEnvelopeIcon(envelope_type_enum.FLAT_ROOF)} />
            {t('General_FlatRoof')}
          </MenuItem>
        </>
      }
    >
      <>
        {controlledPitchedRoofFields.map((data, index) => {
          return (
            <EnvelopePitchedRoof
              data={data}
              title={t('General_PitchedRoof-WithIndex', {
                index: controlledPitchedRoofFields.length === 1 ? '' : index + 1,
              })}
              key={index}
              index={index}
              handleCopy={() =>
                appendPitchedRoof(duplicateComponent(controlledPitchedRoofFields[index]), {
                  focusName: generateFieldName(envelope_type_enum.PITCHED_ROOF, totalPitchedRoof).areaFieldName,
                })
              }
              handleRemove={() => removeEnvelope(index, envelope_type_enum.PITCHED_ROOF)}
            />
          );
        })}

        {controlledTopCeilingFields.map((data, index) => {
          return (
            <EnvelopeOtherUnit
              data={data}
              type={envelope_type_enum.TOP_FLOOR_CEILING}
              title={t('General_TopFloorCeiling-WithIndex', {
                index: controlledTopCeilingFields.length === 1 ? '' : index + 1,
              })}
              key={index}
              index={index}
              handleCopy={() =>
                appendTopFloorCeiling(duplicateComponent(controlledTopCeilingFields[index]), {
                  focusName: generateFieldName(envelope_type_enum.TOP_FLOOR_CEILING, totalTopFloorCeiling)
                    .areaFieldName,
                })
              }
              handleRemove={() => removeEnvelope(index, envelope_type_enum.TOP_FLOOR_CEILING)}
            />
          );
        })}

        {controlledFlatRoofFields.map((data, index) => {
          return (
            <EnvelopeOtherUnit
              data={data}
              type={envelope_type_enum.FLAT_ROOF}
              title={t('General_FlatRoof-WithIndex', {
                index: controlledFlatRoofFields.length === 1 ? '' : index + 1,
              })}
              key={index}
              index={index}
              handleCopy={() =>
                appendFlatRoof(
                  duplicateComponent({
                    ...controlledFlatRoofFields[index],
                    orientation: null,
                  }),
                  {
                    focusName: generateFieldName(envelope_type_enum.FLAT_ROOF, totalFlatRoof).areaFieldName,
                  },
                )
              }
              handleRemove={() => removeEnvelope(index, envelope_type_enum.FLAT_ROOF)}
            />
          );
        })}

        {totalEnvelopes === 0 && (
          <Stack direction={'row'} alignItems={'center'} mt={3} mb={1}>
            <EmptyEnvelopeUnit
              title={t('General_PitchedRoof')}
              icon={getEnvelopeIcon(envelope_type_enum.PITCHED_ROOF)}
              handleClick={addPitchedRoofEnvelope}
            />
            <Typography sx={{ mx: 1 }}>{t('General_Or')}</Typography>
            <EmptyEnvelopeUnit
              title={t('General_TopFloorCeiling_one')}
              icon={getEnvelopeIcon(envelope_type_enum.TOP_FLOOR_CEILING)}
              handleClick={addTopCeilingEnvelope}
            />
            <Typography sx={{ mx: 1 }}>{t('General_Or')}</Typography>
            <EmptyEnvelopeUnit
              title={t('General_FlatRoof')}
              icon={getEnvelopeIcon(envelope_type_enum.FLAT_ROOF)}
              handleClick={addFlatRoofEnvelope}
            />
          </Stack>
        )}
      </>
    </EnvelopeSectionWrapper>
  );
}
