import { data_source_type_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateDataSourceTypeEnum = (enumValue: data_source_type_enum) => {
  switch (enumValue) {
    case data_source_type_enum.APPROXIMATED:
      return <Trans i18nKey="Enum_DataSourceTypeEnum-APPROXIMATED" />;
    case data_source_type_enum.GEOSPATIAL:
      return <Trans i18nKey="Enum_DataSourceTypeEnum-GEOSPATIAL" />;
    case data_source_type_enum.GOOGLE_SOLAR_API:
      return <Trans i18nKey="Enum_DataSourceTypeEnum-GOOGLE_SOLAR_API" />;
    case data_source_type_enum.MANUAL:
      return <Trans i18nKey="Enum_DataSourceTypeEnum-MANUAL" />;
    case data_source_type_enum.TABULA:
      return <Trans i18nKey="Enum_DataSourceTypeEnum-TABULA" />;
  }
};

export const translateDataSourceTypeEnum_dynamic = (
  enumValue: data_source_type_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case data_source_type_enum.APPROXIMATED:
      return t('Enum_DataSourceTypeEnum-APPROXIMATED');
    case data_source_type_enum.GEOSPATIAL:
      return t('Enum_DataSourceTypeEnum-GEOSPATIAL');
    case data_source_type_enum.GOOGLE_SOLAR_API:
      return t('Enum_DataSourceTypeEnum-GOOGLE_SOLAR_API');
    case data_source_type_enum.MANUAL:
      return t('Enum_DataSourceTypeEnum-MANUAL');
    case data_source_type_enum.TABULA:
      return t('Enum_DataSourceTypeEnum-TABULA');
  }
};
