import { Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import Iconify from '../Iconify';
import MenuPopover from '../MenuPopover';

// ----------------------------------------------------------------------

type Props = {
  actions: React.ReactNode;
  /**
   * If `true`, the menu will close after a menu item is clicked.
   */
  closeOnClick?: boolean;
  disabled?: boolean;
};

/**
 * Minimal@4 component.
 */
export default function TableMoreMenu({ actions, closeOnClick, disabled = false }: Props) {
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleClickOnAction = () => {
    if (closeOnClick) {
      setOpen(null);
    }
  };

  return (
    <>
      <IconButton onClick={handleOpen} disabled={disabled}>
        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        sx={{
          mt: -1,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            minWidth: '150px',
            '& svg': { mr: 2, width: 20, height: 20 },
          },
        }}
      >
        <Stack onClick={handleClickOnAction}>{actions}</Stack>
      </MenuPopover>
    </>
  );
}
