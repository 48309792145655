import { FormControl, FormHelperText, InputLabel, MenuItem, MenuList, Select, Tooltip, useTheme } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { getSubtypesForConsumptionType, isEnergySourceType } from '@predium/client-lookup';
import { consumption_type_enum, consumption_unit_enum, energy_source_type_enum } from '@predium/enums';
import {
  translateConsumptionSubTypeEnum,
  translateConsumptionSubTypeEnum_dynamic,
  translateConsumptionTypeEnum,
  translateEnergySourceTypeEnum,
  translateEnergySourceTypeEnum_dynamic,
} from '@predium/i18n/client';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IconConsumptionElectricity } from '../../../../assets/Icons/consumption/electricity';
import { IconConsumptionHeating } from '../../../../assets/Icons/consumption/heating';
import { IconConsumptionWaste } from '../../../../assets/Icons/consumption/waste';
import { IconConsumptionWater } from '../../../../assets/Icons/consumption/water';
import Iconify from '../../../../components/Iconify';
import { ConsumptionInvoiceDraftFormData } from '../Drafts/SubBuildingConsumptionDraftForm';

type Props = {
  index: number;
  isEmpty: boolean;
  disabled?: boolean;
  isSingleConsumption?: boolean;
  limitEnergySourcesBy: energy_source_type_enum[];
  remove: () => void;
};

export default function SubBuildingConsumptionDraftConsumptionTypeSelect({
  index,
  isEmpty,
  disabled = false,
  isSingleConsumption = false,
  limitEnergySourcesBy,
  remove,
}: Props) {
  const theme = useTheme();
  const { t } = useTranslation();

  // Only initialize open when not SingleConsumptionModal and not the first consumption, because that never is removed and behaves different...
  const [selectOpen, setSelectOpen] = useState(isEmpty && !isSingleConsumption && index !== 0);

  const { setValue, getValues, getFieldState, watch, unregister } = useFormContext<ConsumptionInvoiceDraftFormData>();

  const errorMessage =
    getFieldState(`consumptionDrafts.${index}.energySourceTypeId`)?.error?.message ??
    getFieldState(`consumptionDrafts.${index}.consumptionTypeId`)?.error?.message ??
    getFieldState(`consumptionDrafts.${index}.subTypeId`)?.error?.message;

  const selectedConsumptionType = watch(`consumptionDrafts.${index}.consumptionTypeId`);
  const selectedEnergySourceType = watch(`consumptionDrafts.${index}.energySourceTypeId`);
  const selectedSubType = watch(`consumptionDrafts.${index}.subTypeId`);

  return (
    <FormControl fullWidth={isEmpty} error={Boolean(errorMessage)}>
      {isEmpty && (
        <InputLabel id={`consumptionDrafts.${index}.energySourceTypeId-label`}>
          {t('DataCollection_ConsumptionTypeSelect-label')}
        </InputLabel>
      )}

      <Select
        id={`consumptionDrafts.${index}.energySourceTypeId-select`}
        autoFocus={isEmpty && !isSingleConsumption && index !== 0}
        labelId={isEmpty ? `consumptionDrafts.${index}.energySourceTypeId-label` : undefined}
        label={isEmpty ? t('DataCollection_ConsumptionTypeSelect-label') : undefined}
        open={selectOpen}
        // Need to handle open and close manually
        onOpen={() => {
          setSelectOpen(true);
        }}
        onClose={() => {
          setSelectOpen(false);

          // Remove the select again if nothing has been chosen. Must get runtime values, cant wait for state change.
          if (!getValues(`consumptionDrafts.${index}.consumptionTypeId`) && index !== 0) {
            remove();
          }
        }}
        variant={isEmpty ? 'outlined' : 'standard'}
        disabled={disabled}
        // Required to render any custom value.
        // This select is controlled in order to render a mixed value. All setters control the forms values manually.
        displayEmpty
        {...(isEmpty ? {} : { disableUnderline: true })}
        // We need to set this in order to trigger "renderValue" when initialized
        value=""
        renderValue={() => {
          const consumptionDraftData = getValues(`consumptionDrafts.${index}`);

          if (!consumptionDraftData) return null;
          return (
            // Hide custom styles of MenuItem when clicked or interacted with.
            <MenuItem
              sx={{
                // Need to override the hover and focused styles. There is no way to know if the parent is focused.
                backgroundColor: 'transparent !important',
                fontWeight: 700,
                display: 'flex',
                alignItems: 'center',
                pr: 0,
              }}
              disableRipple
            >
              {consumptionDraftData.consumptionTypeId && (
                <>
                  {getIconForConsumptionType(consumptionDraftData.consumptionTypeId)}
                  {consumptionDraftData.energySourceTypeId
                    ? translateEnergySourceTypeEnum_dynamic(consumptionDraftData.energySourceTypeId, t)
                    : translateConsumptionSubTypeEnum_dynamic(consumptionDraftData.subTypeId!, t)}
                </>
              )}
            </MenuItem>
          );
        }}
        sx={{
          backgroundColor: 'transparent !important',
          '& .MuiSelect-select:focus': {
            backgroundColor: 'grey.300',
            borderRadius: 1,
          },
          '& .MuiSelect-icon': {
            right: 4,
          },
        }}
        // make select dropdown the same size for both variants
        MenuProps={{
          PaperProps: {
            style: {
              width: '520px',
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {Object.values(consumption_type_enum).map((option) => (
          <Tooltip
            key={option}
            title={
              <MenuList>
                {getSubtypesForConsumptionType(option, limitEnergySourcesBy).map((consumptionSubType) => (
                  <MenuItem
                    key={consumptionSubType}
                    selected={selectedEnergySourceType === consumptionSubType || selectedSubType === consumptionSubType}
                    value={consumptionSubType}
                    onClick={(event) => {
                      setValue(`consumptionDrafts.${index}.consumptionTypeId`, option, { shouldValidate: true });
                      if (isEnergySourceType(consumptionSubType)) {
                        setValue(`consumptionDrafts.${index}.energySourceTypeId`, consumptionSubType);
                        setValue(`consumptionDrafts.${index}.subTypeId`, undefined);
                      } else {
                        setValue(`consumptionDrafts.${index}.energySourceTypeId`, undefined);
                        setValue(`consumptionDrafts.${index}.subTypeId`, consumptionSubType);
                      }

                      // Set default unit value after change
                      const defaultUnits = {
                        [consumption_type_enum.HEATING]: consumption_unit_enum.KWH,
                        [consumption_type_enum.ELECTRICITY]: consumption_unit_enum.KWH,
                        [consumption_type_enum.WATER]: consumption_unit_enum.M3,
                        [consumption_type_enum.WASTE]: consumption_unit_enum.kg,
                      } satisfies Record<consumption_type_enum, consumption_unit_enum>;

                      setValue(`consumptionDrafts.${index}.displayUnitValue`, defaultUnits[option]);

                      // Always reset waste data when changing type
                      unregister(`consumptionDrafts.${index}.waste`);

                      // click event will reach select otherwise
                      event.stopPropagation();
                      setSelectOpen(false);
                    }}
                  >
                    {isEnergySourceType(consumptionSubType)
                      ? translateEnergySourceTypeEnum(consumptionSubType)
                      : translateConsumptionSubTypeEnum(consumptionSubType)}
                  </MenuItem>
                ))}
              </MenuList>
            }
            componentsProps={{
              popper: {
                sx: {
                  [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]: {
                    marginRight: '0px',
                  },
                  [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: {
                    marginLeft: '0px',
                  },
                },
              },
              tooltip: {
                sx: {
                  // fit any menu items
                  width: 'auto',
                  maxWidth: 'none',
                  bgcolor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  p: 0,
                  borderRadius: 1.5,
                  boxShadow: '-20px 20px 40px -4px rgba(145, 158, 171, 0.24)',
                },
              },
            }}
            placement={isSingleConsumption ? 'right-start' : 'left-start'}
            disableFocusListener
          >
            <MenuItem
              key={index}
              selected={selectedConsumptionType === option}
              disableRipple
              sx={{
                ':hover': { cursor: 'default' },
                display: 'flex',
                justifyContent: 'space-between',
                pl: 2,
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {getIconForConsumptionType(option)}
                {translateConsumptionTypeEnum(option)}
              </div>

              <Iconify icon="mdi:chevron-right" width={20} height={20} color="action.active" />
            </MenuItem>
          </Tooltip>
        ))}
      </Select>

      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}

function getIconForConsumptionType(consumptionType: consumption_type_enum) {
  return (
    {
      [consumption_type_enum.HEATING]: <IconConsumptionHeating />,
      [consumption_type_enum.ELECTRICITY]: <IconConsumptionElectricity />,
      [consumption_type_enum.WATER]: <IconConsumptionWater />,
      [consumption_type_enum.WASTE]: <IconConsumptionWaste />,
    } satisfies Record<consumption_type_enum, JSX.Element>
  )[consumptionType];
}
