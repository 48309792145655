import { sub_building_class_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateSubBuildingClassEnum = (enumValue: sub_building_class_enum) => {
  switch (enumValue) {
    case sub_building_class_enum.COMMERCIAL:
      return <Trans i18nKey="Enum_SubBuildingClassEnum-COMMERCIAL" />;
    case sub_building_class_enum.RESIDENTIAL:
      return <Trans i18nKey="Enum_SubBuildingClassEnum-RESIDENTIAL" />;
  }
};

export const translateSubBuildingClassEnum_dynamic = (
  enumValue: sub_building_class_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case sub_building_class_enum.COMMERCIAL:
      return t('Enum_SubBuildingClassEnum-COMMERCIAL');
    case sub_building_class_enum.RESIDENTIAL:
      return t('Enum_SubBuildingClassEnum-RESIDENTIAL');
  }
};
