import {
  Box,
  Card,
  CardHeader,
  Grid,
  Paper,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import {
  ActionImpactDataOutputFragment,
  ActionPlanImpactDataOutputFragment,
  ActionPlanningActionSubsidyFragment,
  SubsidyInput,
} from '@predium/client-graphql';
import { country_enum, renovation_type_enum } from '@predium/enums';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import AustriaEnergyAnalysis from '../Visualization/EnergyAnalysis/AustriaEnergyAnalysis';
import GeneralEnergyAnalysis from '../Visualization/EnergyAnalysis/GeneralEnergyAnalysis';
import ActionPricingTotalCost from './ActionDetails/ActionPricing/ActionPricingTotalCost';
import ActionPricingTotalSaving from './ActionDetails/ActionPricing/ActionPricingTotalSaving';

type Props = {
  setSubsidyData?: (data: SubsidyInput[]) => void;
  actionSubsidies: Omit<ActionPlanningActionSubsidyFragment, 'id'>[];
  renovationArea?: number;
  renovationType?: renovation_type_enum;
  currentView: 'overview' | 'action_modal';
  simulated?: boolean;
  showAddSubsidyButton?: boolean;
  setIsCustomCostLowerThanSubsidies?: (value: boolean) => void;
  metricData: ActionPlanImpactDataOutputFragment | ActionImpactDataOutputFragment | null;
  country: country_enum;
};

export default function ActionDetails({
  currentView,
  setSubsidyData,
  actionSubsidies,
  renovationType,
  simulated = false,
  renovationArea,
  showAddSubsidyButton = false,
  metricData,
  setIsCustomCostLowerThanSubsidies,
  country,
}: Props) {
  const { t } = useTranslation();

  const isOverview = currentView === 'overview';
  if (!metricData) {
    return null;
  }

  const { modification_impact: modificationImpact } = metricData;

  const totalRenovationCost = metricData.total_renovation_cost ?? 0;
  const alwaysRenovationCost = isOverview; // will show renovation costs instead of estimated costs
  const totalCostEstimated = alwaysRenovationCost ? totalRenovationCost : metricData.total_estimated_cost ?? 0;
  const showUseOwnCostsButton = !isOverview && simulated;

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Stack my={2}>
        <Typography variant="subtitle2">{t('ActionDetails_EnergeticKPIs')}</Typography>
        <Typography variant="caption" color={'text.secondary'}>
          {t('ActionDetails_EnergeticKPIs_Subtitle')}
        </Typography>
      </Stack>

      {country === country_enum.AT ? (
        <AustriaEnergyAnalysis
          modificationImpact={modificationImpact}
          isOverview={isOverview}
          isSimulated={simulated}
        />
      ) : (
        <GeneralEnergyAnalysis
          modificationImpact={modificationImpact}
          isOverview={isOverview}
          isSimulated={simulated}
        />
      )}

      <Typography mt={4} variant="subtitle2">
        {t('ActionDetails_FinancialKPIs')}
      </Typography>
      <Box my={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            {simulated || isOverview ? (
              <ActionPricingTotalCost
                title={t('ActionDetails_InvestmentCosts')}
                totalRenovationCost={totalRenovationCost}
                totalCostEstimated={totalCostEstimated}
                showAddSubsidyButton={showAddSubsidyButton}
                onAddSubsidy={setSubsidyData}
                actionSubsidies={actionSubsidies}
                renovationType={renovationType}
                showUseOwnCostsButton={showUseOwnCostsButton}
                renovationArea={renovationArea}
                setIsCustomCostLowerThanSubsidies={setIsCustomCostLowerThanSubsidies}
              />
            ) : (
              // empty card to keep the layout
              <EmptyActionPricing />
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <ActionPricingTotalSaving
              modificationImpact={modificationImpact}
              title={t('ActionDetails_OperationalCosts')}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}

function EmptyActionPricing() {
  return (
    <Card sx={{ mb: 2, height: '100%' }} key={t('ActionDetails_InvestmentCosts')}>
      <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('ActionDetails_InvestmentCosts')} />
      <TableContainer component={Paper} sx={{ p: 3, minHeight: 40, pb: 0 }}>
        <Table
          sx={{
            '.MuiTableCell-root': {
              p: 'unset',
              pb: 1,
            },
          }}
        >
          <TableRow sx={{ '& > *': { borderBottom: 'unset', whiteSpace: 'nowrap', width: '100%' } }}>
            <TableCell component="th" scope="row">
              <Typography variant="subtitle2" fontWeight={700}>
                {t('ActionPricingTotalCost_EstimatedCosts')}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="subtitle2" fontWeight={700}>
                -
              </Typography>
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </Card>
  );
}
