import { useQuery } from '@apollo/client';
import { Tooltip, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../components/Iconify';
import { GET_QUESTION_EVIDENCES } from '../../../../graphql/Report.queries';

type Props = {
  reportId: number;
  questionId: number;
};

function CountEvidence({ questionId, reportId }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const { data } = useQuery(GET_QUESTION_EVIDENCES, {
    variables: {
      questionId,
      reportId,
    },
  });

  if (!data) return null;

  const questionEvidenceCount = data.report_question_evidence.length;

  return (
    <Tooltip title={t('CountEvidence_Tooltip-title', { count: questionEvidenceCount })}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Iconify icon="mdi:paperclip" color={theme.palette.grey[600]} />
        <Typography variant="body1" color={theme.palette.grey[600]} fontSize={16}>
          {questionEvidenceCount}
        </Typography>
      </div>
    </Tooltip>
  );
}

export default CountEvidence;
