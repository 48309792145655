import {
  building_state_enum,
  efficiency_class_enum,
  energy_source_type_enum,
  eu_taxonomy_compliance_enum,
  sub_building_class_enum,
  tax_bracket_enum,
} from '@predium/client-graphql';
import { Trans } from 'react-i18next';
import { arrayParam, booleanParam, enumParam, InferParamType, numberParam } from '../../../routes';

export enum EsgAnalysisFiltersKeys {
  portfolioIds = 'portfolioIds',
  economicUnitIds = 'economicUnitIds',
  buildingIds = 'buildingIds',
  subBuildingClasses = 'subBuildingClasses',
  efficiencyClasses = 'efficiencyClasses',
  energySourceTypes = 'energySourceTypes',
  taxBrackets = 'taxBrackets',
  euTaxonomyCompliances = 'euTaxonomyCompliances',
  monumentProtections = 'monumentProtections',
  heritageDistrict = 'heritageDistrict',
  milieuProtection = 'milieuProtection',
  leasehold = 'leasehold',
  buildingStates = 'buildingStates',
}

export const esgAnalysisFilters = [
  {
    key: EsgAnalysisFiltersKeys.portfolioIds,
    type: arrayParam(numberParam),
    paramKey: 'portfolio-id',
    title: <Trans i18nKey="General_Portfolio" />,
    showInSidebar: false,
    showAsChip: false,
  },
  {
    key: EsgAnalysisFiltersKeys.economicUnitIds,
    type: arrayParam(numberParam),
    paramKey: 'economic-unit-id',
    title: <Trans i18nKey="General_EconomicUnit" />,
    showInSidebar: false,
    showAsChip: false,
  },
  {
    key: EsgAnalysisFiltersKeys.buildingIds,
    type: arrayParam(numberParam),
    // TODO PRE-1450: change to 'building-id'
    paramKey: 'asset-id',
    title: <Trans i18nKey="General_Building" />,
    showInSidebar: false,
    showAsChip: false,
  },
  {
    key: EsgAnalysisFiltersKeys.subBuildingClasses,
    type: arrayParam(enumParam(sub_building_class_enum)),
    paramKey: 'sub-building-class',
    title: <Trans i18nKey="General_SubBuildingClass" />,
    showInSidebar: true,
    showAsChip: true,
  },
  {
    key: EsgAnalysisFiltersKeys.efficiencyClasses,
    type: arrayParam(enumParam(efficiency_class_enum)),
    paramKey: 'efficiency-class',
    title: <Trans i18nKey="General_EfficiencyClass" />,
    showInSidebar: true,
    showAsChip: true,
  },
  {
    key: EsgAnalysisFiltersKeys.energySourceTypes,
    type: arrayParam(enumParam(energy_source_type_enum)),
    paramKey: 'energy-source',
    title: <Trans i18nKey="General_EnergySource" />,
    showInSidebar: true,
    showAsChip: true,
  },
  {
    key: EsgAnalysisFiltersKeys.taxBrackets,
    type: arrayParam(enumParam(tax_bracket_enum)),
    paramKey: 'tax-bracket',
    title: <Trans i18nKey="General_TaxBracket" />,
    showInSidebar: true,
    showAsChip: true,
  },
  {
    key: EsgAnalysisFiltersKeys.euTaxonomyCompliances,
    type: arrayParam(enumParam(eu_taxonomy_compliance_enum)),
    paramKey: 'eu-taxonomy-compliance',
    title: <Trans i18nKey="General_EUTaxonomy" />,
    showInSidebar: true,
    showAsChip: true,
  },
  {
    key: EsgAnalysisFiltersKeys.monumentProtections,
    type: arrayParam(booleanParam),
    paramKey: 'monument-protection',
    title: <Trans i18nKey="General_MonumentProtection" />,
    showInSidebar: true,
    showAsChip: true,
  },
  {
    key: EsgAnalysisFiltersKeys.heritageDistrict,
    type: arrayParam(booleanParam),
    paramKey: 'heritage-district',
    title: <Trans i18nKey="General_HeritageDistrict" />,
    showInSidebar: true,
    showAsChip: true,
  },
  {
    key: EsgAnalysisFiltersKeys.milieuProtection,
    type: arrayParam(booleanParam),
    paramKey: 'milieu-protection',
    title: <Trans i18nKey="General_MilieuProtection" />,
    showInSidebar: true,
    showAsChip: true,
  },
  {
    key: EsgAnalysisFiltersKeys.leasehold,
    type: arrayParam(booleanParam),
    paramKey: 'leasehold',
    title: <Trans i18nKey="General_Leasehold" />,
    showInSidebar: true,
    showAsChip: true,
  },
  {
    key: EsgAnalysisFiltersKeys.buildingStates,
    type: arrayParam(enumParam(building_state_enum)),
    paramKey: 'building-state',
    title: <Trans i18nKey="General_State" />,
    showInSidebar: true,
    showAsChip: true,
  },
] as const;

export type EsgAnalysisFiltersValues = InferParamType<(typeof esgAnalysisFilters)[number]['type']>;

/**
 * Get the filter value type for a given filter key.
 *
 * @example
 * type SubBuildingClasses = FindESGFilterValue<ESGFiltersKeys.subBuildingClasses>;
 *
 */
export type FindEsgAnalysisFilterValue<Key> = InferParamType<
  Extract<(typeof esgAnalysisFilters)[number], { key: Key }>['type']
>[number];

/**
 * Search params type for ESG filters.
 */
export type EsgAnalysisFiltersSearchParams = {
  [key in EsgAnalysisFiltersKeys]?: FindEsgAnalysisFilterValue<key>[];
};
