import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import Iconify from '../Iconify';

type NavigateActionProps = {
  snackBarKey: number;
  path: string;
  title: string;
};

export function NavigateAction({ path, snackBarKey, title }: NavigateActionProps) {
  const navigate = useNavigate();
  const { closeSnackbar } = useSnackbar();

  return (
    <Button
      variant="outlined"
      size="small"
      onClick={() => {
        navigate(path);
        closeSnackbar(snackBarKey);
      }}
      endIcon={<Iconify icon={'material-symbols:arrow-right-alt'} width={20} height={20} />}
    >
      {title}
    </Button>
  );
}
