/* eslint-disable @typescript-eslint/ban-ts-comment */
import { emission_factor_type_enum, energy_system_type_enum } from '@predium/enums';
import { translateEnergySourceTypeEnum } from '@predium/i18n/client';
import isNil from 'lodash/isNil';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TechnologyIcons } from '../../../../../../../../assets/images';
import { SystemRoute } from '../../../../../BuildingTechnology';
import { generateSystemRouteFieldNames } from '../../../../../Common/building.util';
import useBuilding from '../../../../../Context/useBuilding';
import { TechnologyUnitSubTitle } from '../../TechnologyConsumerRoute';
import TechnologyUnitTable, { TableData } from '../../TechnologyUnitTable';
import EmptyTechnologyUnitCard from '../EmptyTechnologyUnit';
import TechnologyUnitCard from '../TechnologyUnit';
import EnergySourceAddModal from './EnergySourceAddModal';
import EnergySourceEditModal from './EnergySourceEditModal';

interface EnergySourceCardProps {
  energySystemRoute: SystemRoute;
  routeIndex: number;
  portfolioEmissionFactorType: emission_factor_type_enum;
  systemIndex: number;
}

const EnergySourceCard: React.FC<EnergySourceCardProps> = ({
  energySystemRoute,
  routeIndex,
  portfolioEmissionFactorType,
  systemIndex,
}) => {
  const { t } = useTranslation();
  const { hasEditAccess } = useBuilding();

  const REQUIRED_MESSAGE = t('General_Required');

  const [editEnergySourceModalOpen, setEditEnergySourceModalOpen] = useState(false);
  const [addEnergySourceModalOpen, setAddEnergySourceModalOpen] = useState(false);

  const { energyRouteId, emissionFactor, energySource, primaryEnergyFactor, pricePerKwh, energySystemType } =
    energySystemRoute;

  const { getFieldState } = useFormContext();
  const { energySourcefieldname, emissionCertificateIdFieldname, primaryEnergyFactorEmissionCertificateIdFieldname } =
    generateSystemRouteFieldNames(
      //@ts-ignore
      energySystemType,
      systemIndex,
      routeIndex,
    );
  const isEnergySourceDirty = getFieldState(energySourcefieldname).isDirty;
  const energySourceError = getFieldState(energySourcefieldname).error;
  const isEmissionFactorDirty = getFieldState(emissionCertificateIdFieldname).isDirty;
  const isPrimaryFactorDirty = getFieldState(primaryEnergyFactorEmissionCertificateIdFieldname).isDirty;

  const getFixedValue = (value: number | null | undefined) => {
    if (isNil(value)) {
      return '-';
    }

    return +value.toFixed(3);
  };

  const energySourceTableData: TableData[] = [
    {
      label: t('General_EmissionFactor'),
      value: getFixedValue(emissionFactor),
      isDirty: isEmissionFactorDirty,
    },
    {
      label: t('General_PrimaryEnergyFactor'),
      value: getFixedValue(primaryEnergyFactor),
      isDirty: isPrimaryFactorDirty,
    },
    {
      label: t('General_PricePerKwh'),
      value: pricePerKwh,
    },
  ];

  const title = t('General_EnergySource');
  const icon = TechnologyIcons.energySource;

  const showArrow = energySystemType !== energy_system_type_enum.ELECTRICITY;
  return (
    <>
      {energyRouteId && energySource ? (
        <TechnologyUnitCard
          icon={icon}
          title={title}
          subtitle={
            <TechnologyUnitSubTitle
              subtitle={translateEnergySourceTypeEnum(energySource)}
              isDirty={isEnergySourceDirty}
            />
          }
          body={<TechnologyUnitTable data={energySourceTableData} />}
          onSemanticButtonClick={() => {
            hasEditAccess && setEditEnergySourceModalOpen(true);
          }}
          showArrow={showArrow}
        />
      ) : (
        <EmptyTechnologyUnitCard
          icon={icon}
          title={title}
          onButtonClick={() => {
            hasEditAccess && setAddEnergySourceModalOpen(true);
          }}
          noDataText={t('DataCollection_Technology_EnergySourceNotAvailable')}
          requiredMessage={energySourceError && REQUIRED_MESSAGE}
          showArrow={showArrow}
        />
      )}
      <EnergySourceAddModal
        open={addEnergySourceModalOpen}
        onClose={() => setAddEnergySourceModalOpen(false)}
        portfolioEmissionFactorType={portfolioEmissionFactorType}
        energySystemRoute={energySystemRoute}
        index={routeIndex}
        systemIndex={systemIndex}
      />

      <EnergySourceEditModal
        open={editEnergySourceModalOpen}
        onClose={() => setEditEnergySourceModalOpen(false)}
        portfolioEmissionFactorType={portfolioEmissionFactorType}
        energySystemRoute={energySystemRoute}
        index={routeIndex}
        systemIndex={systemIndex}
      />
    </>
  );
};

export default EnergySourceCard;
