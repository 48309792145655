/* eslint-disable @typescript-eslint/ban-ts-comment */
import mapboxgl from 'mapbox-gl';
class CustomNavigationControl extends mapboxgl.NavigationControl {
  private onRecenterFunction: () => void;

  //@ts-ignore
  private customButton: HTMLButtonElement;
  private title: string;
  public centerOff: boolean;

  constructor(
    options: { showCompass?: boolean; showZoom?: boolean; visualizePitch?: boolean },
    title: string,
    onRecenter?: () => void,
    centerOff?: boolean,
  ) {
    super(options);

    //@ts-ignore
    this.onRecenterFunction = onRecenter;

    //@ts-ignore
    this.centerOff = centerOff;
    this.title = title;
  }

  onAdd(map: mapboxgl.Map) {
    const container = super.onAdd(map);

    // Add custom recenter button
    this.customButton = document.createElement('button');
    this.customButton.type = 'button';
    this.customButton.title = this.title;
    this.customButton.onclick = () => {
      this.onRecenterFunction?.();
    };
    this.updateButton();
    container.appendChild(this.customButton);

    return container;
  }

  updateButton() {
    if (this.centerOff) {
      this.customButton.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 29 29" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.40728 14.7139C7.16174 14.6185 7 14.382 7 14.1186C7 13.861 7.15467 13.6286 7.39228 13.5292L20.749 7.94168C21.5742 7.59646 22.4029 8.4255 22.0573 9.25057L16.4603 22.6119C16.3619 22.847 16.1319 23 15.877 23C15.6162 23 15.3822 22.8399 15.2877 22.5968L13.2395 17.3301C13.1379 17.0688 12.9312 16.8621 12.6699 16.7605L7.40728 14.7139ZM11.2759 14.0725L13.3948 14.8965C14.1787 15.2014 14.7986 15.8213 15.1035 16.6052L15.9241 18.7155L19.2698 10.7284L11.2759 14.0725Z" fill="#333333"/>
      </svg>`;
    } else {
      this.customButton.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 29 29" fill="none">
        <path d="M22.0573 9.25057C22.4029 8.4255 21.5742 7.59646 20.749 7.94168L7.39228 13.5292C7.15467 13.6286 7 13.861 7 14.1186V14.1186C7 14.382 7.16174 14.6185 7.40728 14.7139L12.6699 16.7605C12.9312 16.8621 13.1379 17.0688 13.2395 17.3301L15.2877 22.5968C15.3822 22.8399 15.6162 23 15.877 23V23C16.1319 23 16.3619 22.847 16.4603 22.6119L22.0573 9.25057Z" fill="#333333"/>
      </svg>`;
    }
  }
}

export default CustomNavigationControl;
