import { DataCollectionSubBuildingSubBuildingSubsidyFragment } from '@predium/client-graphql';
import { translateSubsidyRegionTypeEnum_dynamic, translateSubsidyTypeEnum_dynamic } from '@predium/i18n/client';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

interface Props {
  data: DataCollectionSubBuildingSubBuildingSubsidyFragment[];
  title: string;
}

export default function ExportSubsidyData({ data, title }: Props) {
  const { t } = useTranslation();

  const CSV_HEADERS = [
    { label: t('General_Title'), key: 'title' },
    { label: t('General_SubsidyAmountDescription'), key: 'amount_description' },
    { label: t('General_Email'), key: 'email' },
    { label: t('General_URL'), key: 'url' },
    { label: t('General_Date'), key: 'provider_updated_at' },
    { label: t('General_RegionType'), key: 'region_type' },
    { label: t('General_Type'), key: 'type' },
  ];

  const csvData = data.map(({ title, amount_description, email, url, provider_updated_at, region_type, type }) => ({
    title,
    amount_description,
    email,
    url,
    provider_updated_at,
    region_type: translateSubsidyRegionTypeEnum_dynamic(region_type, t),
    type: translateSubsidyTypeEnum_dynamic(type, t),
  }));

  return (
    <CSVLink data={csvData} headers={CSV_HEADERS} filename={`${t('General_Data')}.csv`}>
      {title}
    </CSVLink>
  );
}
