/* eslint-disable @typescript-eslint/ban-ts-comment */
import Grid from '@mui/material/Grid';
import { envelope_type_enum } from '@predium/enums';
import { useTranslation } from 'react-i18next';
import { OtherFormValuesProps } from '../../BuildingEnvelope';
import { getEnvelopeIcon } from '../Envelope.util';
import { EnvelopeConstructionDetails } from './EnvelopeConstructionDetails';
import { EnvelopeInsulation } from './EnvelopeInsulation';
import { EnvelopeUnitField } from './EnvelopeUnitField';
import { EnvelopeUnitWrapper, FieldSectionTitle } from './EnvelopeUnitWrapper';

//used for envelopes other than window, door and pitched roof as they share same properties
export function EnvelopeOtherUnit({
  data,
  type,
  title,
  index,
  handleCopy,
  handleRemove,
}: {
  data: OtherFormValuesProps;
  type: envelope_type_enum;
  title: string;
  index: number;
  handleCopy: (index: number) => void;
  handleRemove: (index: number) => void;
}) {
  const { t } = useTranslation();

  return (
    <EnvelopeUnitWrapper
      title={title}
      icon={getEnvelopeIcon(type)}
      index={index}
      handleCopy={handleCopy}
      handleRemove={handleRemove}
      data={data}
    >
      <>
        {/* general information */}
        <Grid item container md={4}>
          <Grid item xs={12}>
            <FieldSectionTitle title={t('General_BasicData')} />
          </Grid>
          <Grid item xs={12}>
            <EnvelopeUnitField
              title={t('General_ComponentArea')}
              unit={'m²'}
              name={`${type}[${index}].area`}
              //@ts-ignore
              id={data.id}
              fieldType="number"
            ></EnvelopeUnitField>
          </Grid>

          <Grid item xs={6}>
            <EnvelopeUnitField
              title={t('General_UValue')}
              unit="W/(m²K)"
              name={`${type}[${index}].u_value`}
              //@ts-ignore
              id={data.id}
              fieldType="number"
              isFieldEditable={false}
            />
          </Grid>
          {type === envelope_type_enum.WALL && (
            <Grid item xs={6}>
              <EnvelopeUnitField
                title={t('General_Orientation')}
                name={`${type}[${index}].orientation`}
                //@ts-ignore
                id={data.id}
                fieldType="orientation"
              />
            </Grid>
          )}
        </Grid>

        {/* base construction */}
        <Grid item container md={4}>
          <EnvelopeConstructionDetails data={data} index={index} />
        </Grid>

        <Grid item container md={4} pl={3}>
          <EnvelopeInsulation data={data} index={index} />
        </Grid>
      </>
    </EnvelopeUnitWrapper>
  );
}
