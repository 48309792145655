/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { DialogContent, DialogTitle, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import { PortfolioManagementGetAllPortfoliosQuery } from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { DelayedLoading } from '../../../components/Loading';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { UnavailablePortfolioMessage } from '../../../components/Unavailable';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';
import { PermissionType } from '../../../contexts/PermissionContext';
import { CREATE_SCENARIO } from '../../../graphql/ActionPlanning.mutations';
import { GET_SCENARIOS } from '../../../graphql/ActionPlanning.queries';
import { GET_PORTFOLIOS } from '../../../graphql/Portfolio.queries';
import usePermissions from '../../../hooks/usePermissions';
import useSessionData from '../../../hooks/useSessionData';
import { PATHS } from '../../../routes';

export default function CreateScenarioModal({ onClose }: { onClose: VoidFunction }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isAdmin } = useSessionData();
  const navigate = useNavigate();
  const { checkPortfolioPermission } = usePermissions();

  //QUERIES
  const [mutateFunction, { loading, called }] = useMutation(CREATE_SCENARIO, {
    refetchQueries: [GET_SCENARIOS],
    onError: () => {
      enqueueSnackbar(t('CreateScenarioModal_CreateScenario-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
      onClose();
    },
    onCompleted: (data) => {
      enqueueSnackbar(t('CreateScenarioModal_CreateScenario-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });

      //@ts-ignore
      navigate(PATHS.actionPlanning.scenario({ id: data.insert_scenario.returning[0].id }));
    },
  });

  const { data: portfoliosData, loading: portfoliosLoading } = useQuery<PortfolioManagementGetAllPortfoliosQuery>(
    GET_PORTFOLIOS,
    {
      onError: () => {
        enqueueSnackbar(t('CreateScenarioModal_GetAllScenarios-error'), {
          variant: 'error',
          autoHideDuration: SnackbarTimeouts.Error,
        });
        onClose();
      },
      onCompleted: (data) => {
        if (data?.portfolio === undefined) {
          enqueueSnackbar(t('CreateScenarioModal_GetAllScenarios-error'), {
            variant: 'error',
            autoHideDuration: SnackbarTimeouts.Error,
          });
          onClose();
        }
      },
    },
  );

  const portfoliosWithReadPermissions = (() => {
    if (!portfoliosData) return [];
    if (isAdmin) return portfoliosData.portfolio;
    return portfoliosData.portfolio.filter((portfolio) => checkPortfolioPermission(portfolio.id, PermissionType.READ));
  })();

  //form functions
  type FormValuesProps = {
    name: string;
    description: string;
    portfolioId: number | '';
  };

  const onSubmit = async ({ description, name, portfolioId }: FormValuesProps) => {
    !called &&
      mutateFunction({
        variables: {
          name: name,
          description: description,

          //@ts-ignore
          portfolio_id: portfolioId !== '' ? portfolioId : null,
        },
      });
  };

  //setup yup form
  const EventSchema = Yup.object().shape({
    name: Yup.string()
      .max(255)
      .min(3, t('CreateScenarioModal_MinThreeCharactersRequired'))
      .required(t('CreateScenarioModal_NameIsRequired')),
    description: Yup.string().max(500).optional(),
    portfolioId: Yup.number()
      .typeError(t('CreateScenarioModal_SelectPortfolio'))
      .required(t('CreateScenarioModal_SelectPortfolio')),
  });
  const defaultValues = useMemo<FormValuesProps>(
    () => ({
      name: '',
      description: '',
      portfolioId: '',
    }),
    [],
  );
  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  if (loading || portfoliosLoading) {
    return <DelayedLoading />;
  }

  if (portfoliosWithReadPermissions.length === 0) {
    return <UnavailablePortfolioMessage subTitle={t('CreateScenarioModal_NoPortfolioAvailableMessage')} />;
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>{t('General_NewScenario')}</DialogTitle>

      <DialogContent>
        <Stack spacing={3}>
          <RHFTextField name="name" label={t('General_Label')} sx={{ mt: 1 }} />

          <RHFTextField name="description" label={t('General_Description')} multiline rows={4} />

          <RHFSelect
            name="portfolioId"
            label={t('General_Portfolio')}
            placeholder={t('CreateScenarioModal_SelectPortfolioPlaceholder')}
          >
            {Object.values(portfoliosWithReadPermissions).map((portfolio) => (
              <MenuItem key={portfolio.id} value={portfolio.id}>
                {portfolio.name}
              </MenuItem>
            ))}
          </RHFSelect>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          {t('General_Cancel')}
        </Button>

        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          {t('CreateScenarioModal_CreateScenario')}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
