/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ReportSectionGetRootQuestionsAndAnswersAnswersFragment } from '@predium/client-graphql';
import { report_template_question_type_enum } from '@predium/enums';
import { ReportQuestionOptionDC } from './ReportQuestionOption.dc';

export class ReportQuestionDC {
  //@ts-ignore
  public id: number;

  //@ts-ignore
  public reportId: number;

  //@ts-ignore
  public question: string;

  //@ts-ignore
  public type: report_template_question_type_enum;

  //@ts-ignore
  public evidence_required: boolean | null;

  //
  public subBuildingId?: number;
  public options?: ReportQuestionOptionDC[];
  public parent_option_id?: number;
  public answer?: ReportSectionGetRootQuestionsAndAnswersAnswersFragment;

  constructor(unstructuredReportQuestion: Partial<ReportQuestionDC>) {
    // Copying all the properties from the unstructured report to this class
    Object.assign(this, unstructuredReportQuestion);
  }
}
