import { Box, TypographyVariant } from '@mui/material';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { ReactNode, useEffect, useRef, useState } from 'react';

type Props = {
  text: ReactNode;
  /**
   * The maximum width of the container after which ellipsis and tooltip should be shown.
   */
  maxWidth: string | number;
  variant?: TypographyVariant;
  color?: string;
  /**
   * Props to pass to the Tooltip component. Children and title will be overwritten by this component.
   */
  TooltipProps?: Omit<TooltipProps, 'children' | 'title'>;
  TypographyProps?: TypographyProps;
};

/**
 * Component that shows a text with ellipsis and tooltip if the text is overflowing.
 * @returns A `Typography` component with ellipsis and and a `Tooltip`component if the text is overflowing.
 */
const OverflowText = ({
  text,
  maxWidth,
  variant = 'body2',
  color = 'text.primary',
  TooltipProps,
  TypographyProps,
}: Props) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const textElement = textRef.current;
    if (textElement) {
      setIsOverflowing(textElement.scrollWidth > textElement.clientWidth);
    }
  }, [text]);

  const typography = (
    <Typography component={'span'} variant={variant} sx={{ color: color, ...TypographyProps?.sx }} {...TypographyProps}>
      {text}
    </Typography>
  );

  return (
    <Box
      ref={textRef}
      sx={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: maxWidth,
      }}
    >
      {isOverflowing ? (
        <Tooltip {...TooltipProps} title={text}>
          {typography}
        </Tooltip>
      ) : (
        typography
      )}
    </Box>
  );
};

export default OverflowText;
