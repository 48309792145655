import { building_state_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateBuildingStateEnum = (enumValue: building_state_enum) => {
  switch (enumValue) {
    case building_state_enum.ACQUISITION:
      return <Trans i18nKey="Enum_BuildingStateEnum-ACQUISITION" />;
    case building_state_enum.INVENTORY:
      return <Trans i18nKey="Enum_BuildingStateEnum-INVENTORY" />;
  }
};

export const translateBuildingStateEnum_dynamic = (
  enumValue: building_state_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case building_state_enum.ACQUISITION:
      return t('Enum_BuildingStateEnum-ACQUISITION');
    case building_state_enum.INVENTORY:
      return t('Enum_BuildingStateEnum-INVENTORY');
  }
};
