import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
type ChartAndClassYearContainerProps = {
  before: ReactNode;
  after: ReactNode;
  title: string;
};
export function EfficiencyClassAndYearContainer({ title, before, after }: ChartAndClassYearContainerProps) {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="subtitle2" sx={{ fontSize: '12px', pl: 2 }}>
        {title}
      </Typography>

      <TableContainer
        component={Paper}
        sx={{
          p: 2,
          pl: 0,
          pr: 1,
        }}
      >
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell sx={{ pl: 2, pr: 1 }}>
                <Typography variant="caption" color="text.secondary">
                  {t('General_PreModificationShort')}
                </Typography>
              </TableCell>
              <TableCell sx={{ pl: 0 }}>{before}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ pl: 2, mt: 2, pr: 1 }}>
                <Typography variant="caption" color="text.secondary">
                  {t('General_PostModificationShort')}
                </Typography>
              </TableCell>
              <TableCell sx={{ pl: 0 }}>{after}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
