/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useApolloClient } from '@apollo/client';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { CreatedEnergyCertificateDraftFragment } from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import DropzoneComponent, { UploadingFile } from '../../../../components/DropzoneComponent';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import { CREATE_ENERGY_CERTIFICATE_DRAFT } from '../../../../graphql/DataCollection.mutations';
import useUpdateFileName from '../../../../hooks/useUpdateFileName';

type Props = {
  onEnergyCertificateDraftsAdded: (energyCertificateDraftIds: number[]) => void;
  createdEnergyCertificateDrafts: React.MutableRefObject<CreatedEnergyCertificateDraftFragment[]>;
  economicUnitId: number;
  portfolioId: number;
};

/* A component that is used when a user clicks on "Add building" from a table row
   and we already information about a portfolio and economic unit */
export default function UploadEnergyCertificateDraftModal({
  onEnergyCertificateDraftsAdded,
  createdEnergyCertificateDrafts,
  economicUnitId,
  portfolioId,
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const apollo = useApolloClient();
  const { updateFileName } = useUpdateFileName();
  const [uploadInProgress, setUploadInProgress] = useState(false);

  //@ts-ignore
  const asyncCreateUploadUrl = async (): Promise<UploadingFile> => {
    const { data, errors } = await apollo.mutate({
      mutation: CREATE_ENERGY_CERTIFICATE_DRAFT,
      variables: {
        portfolioId: portfolioId,
        economicUnitId: economicUnitId,
      },
    });

    if (errors) {
      enqueueSnackbar(t('DataCollection_EnergyCertificateUpload_ErrorMessage'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    } else {
      //@ts-ignore

      //@ts-ignore
      createdEnergyCertificateDrafts.current.push(data.createEnergyCertificateDraft.CreateEnergyCertificateDraftOutput);

      return {
        //@ts-ignore
        fileUrl: data.createEnergyCertificateDraft.upload_url,

        //@ts-ignore

        //@ts-ignore
        fileId: data.createEnergyCertificateDraft.CreateEnergyCertificateDraftOutput.energy_certificate_file_id,
      };
    }
  };

  return (
    <>
      <DialogTitle>{t('General_CreateNewBuildings')}</DialogTitle>
      <DialogContent>
        <DropzoneComponent
          fileDisplayName={t('General_EnergyCertificate')}
          maxFiles={100}
          createUploadUrl={asyncCreateUploadUrl}
          description={
            <Typography variant="body1">
              <Trans
                i18nKey="DataCollection_AddNewEnergyCertificate_Description"
                components={{ underline: <span style={{ textDecoration: 'underline' }} /> }}
              />
              {
                //Delete document/draft option if possible : PRE-737
              }
            </Typography>
          }
          onUploadSuccess={(file) => {
            updateFileName(file.fileId, file.name);
          }}
          setUploadInProgress={setUploadInProgress}
        />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => onEnergyCertificateDraftsAdded(createdEnergyCertificateDrafts.current.map((d) => d.id))}
          variant="contained"
          disabled={uploadInProgress || createdEnergyCertificateDrafts.current.length === 0}
          fullWidth
        >
          {t('General_ReviewData')}
        </Button>
      </DialogActions>
    </>
  );
}
