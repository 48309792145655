import { Button, Card, CardHeader, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { DataCollectionSubBuildingSubBuildingSubsidyFragment } from '@predium/client-graphql';
import { subsidy_category_type_enum } from '@predium/enums';
import { translateSubsidyCategoryTypeEnum, translateSubsidyTypeEnum } from '@predium/i18n/client';
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SubsidyData,
  SubsidyList,
} from '../../../Scenarios/ActionPlan/Actions/ActionDetails/AddSubsidy/Subsidy/SubsidyList';
import useBuilding from '../Context/useBuilding';
import ExportSubsidyData from './SubBuildingSubsidyListExport';

type Props = {
  /**
   * Whether to show the filter options (by type and category)
   * @default true
   */
  showFilters?: boolean;
};

export default function SubBuildingSubsidy({ showFilters = true }: Props) {
  const { t } = useTranslation();
  const { building } = useBuilding();

  const subsidies = building.sub_buildings
    .flatMap((subBuilding) => subBuilding.subsidies)
    .flatMap((subsidy) => subsidy.subsidy);
  const [typeFilter, setTypeFilter] = useState<string>('All');
  const [categoryFilter, setCategoryFilter] = useState<string>('All');

  const handleTypeFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypeFilter(event.target.value);
  };

  const handleCategoryFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCategoryFilter(event.target.value);
  };

  const filteredData = subsidies.filter(
    (item) =>
      (typeFilter === 'All' || item.type === typeFilter) &&
      (categoryFilter === 'All' || item.categories.some((cat) => cat.subsidy_category_type === categoryFilter)),
  );
  const categorizedSubsidies = groupBy(filteredData, 'region_type') as SubsidyData;

  const subsidyTypes = uniq(
    subsidies.map((item) => {
      return item.type;
    }),
  );

  const getCategoryIds = (obj: DataCollectionSubBuildingSubBuildingSubsidyFragment) =>
    obj.categories ? obj.categories.map((cat) => cat.subsidy_category_type) : [];

  const categoryIds = uniq(subsidies.flatMap(getCategoryIds));

  const subsidyCount = subsidies.length;
  const subsidyCountText = subsidyCount > 0 ? `(${subsidyCount})` : '';

  return (
    <Card sx={{ mt: showFilters ? 3 : 0, p: 3 }}>
      {showFilters && (
        <>
          <CardHeader
            titleTypographyProps={{ variant: 'h6' }}
            title={t('DataCollectionBuildingSubsidyList_Title', { amount: subsidyCountText })}
            sx={{ mb: 1 }}
            action={
              subsidyCount > 0 ? (
                <Button
                  sx={{
                    a: {
                      textDecoration: 'none',
                      color: 'primary.main',
                    },
                    mr: 1,
                  }}
                  variant={'outlined'}
                >
                  <ExportSubsidyData data={subsidies} title={t('General_ExportList')} />
                </Button>
              ) : null
            }
          />
          {subsidyCount > 0 && (
            <Stack direction="row" sx={{ p: 3 }}>
              <TextField
                select
                label={t('General_TypeOfSubsidy')}
                value={typeFilter}
                onChange={handleTypeFilterChange}
                sx={{
                  minWidth: 150,
                  mr: 3,
                }}
              >
                <MenuItem value="All" sx={{ typography: 'body2' }}>
                  {t('General_All')}
                </MenuItem>

                {subsidyTypes.map((type, index) => (
                  <MenuItem key={index} value={type} sx={{ typography: 'body2' }}>
                    {translateSubsidyTypeEnum(type)}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                label={t('General_Action')}
                value={categoryFilter}
                onChange={handleCategoryFilterChange}
                sx={{
                  minWidth: 200,
                  mr: 3,
                }}
              >
                <MenuItem value="All" sx={{ typography: 'body2' }}>
                  {t('General_All')}
                </MenuItem>

                {categoryIds.map((type, index) => (
                  <MenuItem key={index} value={type} sx={{ typography: 'body2' }}>
                    {translateSubsidyCategoryTypeEnum(type as subsidy_category_type_enum)}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          )}
        </>
      )}
      {filteredData.length > 0 ? (
        <SubsidyList data={categorizedSubsidies} />
      ) : building.sub_buildings.some((subBuilding) => subBuilding.subsidies_updated_at) ? (
        <Stack direction="row" sx={{ px: 3, py: 2 }}>
          <Typography variant="body2">{t('General_NoResults')}</Typography>
        </Stack>
      ) : (
        <Stack direction="row" sx={{ px: 3, py: 2 }}>
          <Typography variant="body2">{t('General_SubsidiesLoadingText')}</Typography>
        </Stack>
      )}
    </Card>
  );
}
