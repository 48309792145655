/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Button, IconButton, Popover, Typography, useTheme } from '@mui/material';
import { useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../Iconify';

export type Step = {
  title: string;
  description: string;
};

export type State = {
  currentStep: Step;
  maxSteps: number;
  currentStepIndex: number;
  currentElementId: string;
};

type Action = { type: 'NEXT_STEP' } | { type: 'PREVIOUS_STEP' };

/**
 * curry function to create a reducer for any kind of intro popover dialog. The dialog behavior is abstracted by the resolver and just an array of states/initialState must be provided.
 * You will also have to attach the correct ids at the elements you want the Intro to be displayed at. The rest is done by the @see {@link IntroductionPopover} component.
 *
 * @param elementId the prefix for all tagged elements where the Intro should be displayed: e.g. 'report-intro-autofill' results in the query for 'report-intro-autofill-0' for the first step.
 * @param steps the state array for all steps.
 * @param handleClose optional callback to be called when the Intro is closed or completed.
 * @returns reducer to create the state for any kind of intro stepper.
 */
export const createIntroReducer =
  (elementId: string, steps: Step[], handleClose?: () => void) =>
  (state: State, action: Action): State => {
    switch (action.type) {
      case 'NEXT_STEP':
        if (state.currentStepIndex < steps.length - 1) {
          return {
            ...state,
            currentStep: steps[state.currentStepIndex + 1],
            currentStepIndex: state.currentStepIndex + 1,
            currentElementId: `${elementId}-${state.currentStepIndex + 1}`,
          };
        } else {
          handleClose?.();

          return state;
        }
      case 'PREVIOUS_STEP':
        if (state.currentStepIndex > 0) {
          return {
            ...state,
            currentStep: steps[state.currentStepIndex - 1],
            currentStepIndex: state.currentStepIndex - 1,
            currentElementId: `${elementId}-${state.currentStepIndex - 1}`,
          };
        }
        return state;
      default:
        return state;
    }
  };

type Props = {
  /**
   * state reducer created by @see {@link createIntroReducer}
   */
  reducer: (state: State, action: Action) => State;
  initialState: State;
  handleClose?: () => void;
};

/**
 * Reusable Intro component. Provided a state reducer the user can step through Popovers attached to tagged elements.
 */
function IntroductionPopover({ reducer, initialState, handleClose }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentElementId, currentStep, currentStepIndex, maxSteps } = state;

  const [popoverOpen, setPopoverOpen] = useState(true);
  const [anchorElementRef, setAnchorElementRef] = useState<HTMLElement | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const timeout = setTimeout(() => {
      //@ts-ignore
      const element: HTMLElement = document.getElementById(currentElementId);
      if (element) {
        element.scrollIntoView({ block: 'center' });
        setAnchorElementRef(element);
        setPopoverOpen(true);
      }
    }, 750);

    timeoutRef.current = timeout;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentElementId]);

  useEffect(() => {
    if (anchorElementRef) {
      //@ts-ignore
      clearTimeout(timeoutRef.current);
    }
  }, [anchorElementRef]);

  if (anchorElementRef === null) return null;

  const handleClosePopover = () => {
    setPopoverOpen(false);
    handleClose?.();
  };

  return (
    <Popover
      open={popoverOpen}
      anchorEl={anchorElementRef}
      onClose={() => setPopoverOpen(false)}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      sx={{ mt: 0.5 }}
    >
      <Box sx={{ p: 2, width: 350 }}>
        <Typography variant="h6">{currentStep.title}</Typography>
        <IconButton sx={{ position: 'absolute', top: 3, right: 3 }} onClick={handleClosePopover}>
          <Iconify icon={'charm:cross'} width={20} height={20} />
        </IconButton>
        <Typography>{currentStep.description}</Typography>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
          <Typography sx={{ color: theme.palette.grey[500] }}>
            {t('Components_IntroductionPopover_StepOverview', { currentStepIndex: currentStepIndex + 1, maxSteps })}
          </Typography>

          <div>
            {currentStepIndex > 0 && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  setAnchorElementRef(null);
                  setPopoverOpen(false);
                  dispatch({ type: 'PREVIOUS_STEP' });
                }}
              >
                {t('General_Back')}
              </Button>
            )}

            <Button
              sx={{ ml: 2 }}
              size="small"
              variant="contained"
              onClick={() => {
                setAnchorElementRef(null);
                setPopoverOpen(false);
                dispatch({ type: 'NEXT_STEP' });
              }}
            >
              {currentStepIndex < maxSteps - 1 ? t('General_Next') : t('General_Done')}
            </Button>
          </div>
        </Box>
      </Box>
    </Popover>
  );
}

export default IntroductionPopover;
