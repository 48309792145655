import { country_enum, eu_taxonomy_compliance_enum, type_of_use_enum } from '@predium/enums';

// first number: upper bound for primary energy to satisfy eu taxonomy compliance (top 15%)
// second number: upper bound for primary energy to satisfy DNSH critera (top 30%)
// All standard values are taken from https://index-esg.com/de/
export const EUTaxonomyComplianceBenchmarks: Record<
  country_enum,
  Record<
    type_of_use_enum,
    { [eu_taxonomy_compliance_enum.COMPLIANT]: number; [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: number }
  >
> = {
  [country_enum.DE]: {
    [type_of_use_enum.MFH]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 88,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 114,
    },
    [type_of_use_enum.SFH]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 88,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 114,
    },
    [type_of_use_enum.OFFICE]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 129,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 165,
    },
    [type_of_use_enum.RETAIL]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 108,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 145,
    },
    [type_of_use_enum.SHOPPING_CENTER]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 108,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 145,
    },
    [type_of_use_enum.HOTEL]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 165,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 214,
    },
    [type_of_use_enum.HEALTH_FACILITY]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 147,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 184,
    },
    [type_of_use_enum.RETAIL_WAREHOUSE]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 97,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 136,
    },
    [type_of_use_enum.DISTRIBUTION_WAREHOUSE_COLD]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 97,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 136,
    },
    [type_of_use_enum.DISTRIBUTION_WAREHOUSE_WARM]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 97,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 136,
    },
  },
  // No exact values for Austria, fallback to values from Europe
  [country_enum.AT]: {
    [type_of_use_enum.MFH]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 98,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 134,
    },
    [type_of_use_enum.SFH]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 98,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 134,
    },
    [type_of_use_enum.OFFICE]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 140,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 182,
    },
    [type_of_use_enum.RETAIL]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 122,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 173,
    },
    [type_of_use_enum.SHOPPING_CENTER]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 122,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 173,
    },
    [type_of_use_enum.HOTEL]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 172,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 229,
    },
    [type_of_use_enum.HEALTH_FACILITY]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 176,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 225,
    },
    [type_of_use_enum.RETAIL_WAREHOUSE]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 68,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 105,
    },
    [type_of_use_enum.DISTRIBUTION_WAREHOUSE_COLD]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 68,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 105,
    },
    [type_of_use_enum.DISTRIBUTION_WAREHOUSE_WARM]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 68,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 105,
    },
  },
  // No exact values for Poland, fallback to values from Europe
  [country_enum.PL]: {
    [type_of_use_enum.MFH]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 98,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 134,
    },
    [type_of_use_enum.SFH]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 98,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 134,
    },
    [type_of_use_enum.OFFICE]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 140,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 182,
    },
    [type_of_use_enum.RETAIL]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 122,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 173,
    },
    [type_of_use_enum.SHOPPING_CENTER]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 122,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 173,
    },
    [type_of_use_enum.HOTEL]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 172,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 229,
    },
    [type_of_use_enum.HEALTH_FACILITY]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 176,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 225,
    },
    [type_of_use_enum.RETAIL_WAREHOUSE]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 68,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 105,
    },
    [type_of_use_enum.DISTRIBUTION_WAREHOUSE_COLD]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 68,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 105,
    },
    [type_of_use_enum.DISTRIBUTION_WAREHOUSE_WARM]: {
      [eu_taxonomy_compliance_enum.COMPLIANT]: 68,
      [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: 105,
    },
  },
};
