/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  ReportAnswersStateFragment,
  ReportGetReportByPkFragment,
  ReportGetReportByPkResponsibleUserFragment,
  ReportGetReportByPkSubBuildingFragment,
  ReportSectionsProgressFragment,
  report_answer_state_enum,
} from '@predium/client-graphql';
import { ReportTemplateDC } from './ReportTemplate.dc';

/**
 * This class provides abstraction to the report data structure coming from GraphQL. It builds a tree of sections and provides a way to traverse the tree.
 *
 * @export
 * @class Report
 */
export class ReportDC
  implements
    Omit<
      ReportGetReportByPkFragment,
      'report_template' | 'report_sub_buildings_aggregate' | '__typename' | 'report_answers' | 'report_sub_buildings'
    >
{
  //@ts-ignore
  public id: number;
  public start_date: any;
  public end_date: any;
  public due_date: any;
  public created_at: any;
  public report_template: ReportTemplateDC;

  //@ts-ignore
  public responsible_user: ReportGetReportByPkResponsibleUserFragment | null;
  public report_sub_buildings_count: number;

  //@ts-ignore
  public completed: boolean;
  public hasAutofillQuestions: boolean;
  public report_sub_buildings: ReportGetReportByPkSubBuildingFragment[];

  constructor(
    unstructuredReport: ReportGetReportByPkFragment,
    sectionsAnswerData: ReportAnswersStateFragment[],
    sectionsProgressData: ReportSectionsProgressFragment[],
  ) {
    // Copying all the properties from the unstructured report to this class
    Object.assign(this, unstructuredReport);

    this.report_template = new ReportTemplateDC(unstructuredReport.report_template);

    //@ts-ignore
    this.report_sub_buildings_count = unstructuredReport.report_sub_buildings_aggregate.aggregate.count;

    this.hasAutofillQuestions =
      unstructuredReport.report_answers.filter(
        ({ report_template_question }) => report_template_question.evidence_required !== true,
      ).length > 0;
    this.report_sub_buildings = unstructuredReport.report_sub_buildings.map((building) => building.sub_building);

    // populate the questionCount meta object from all the answers of a report
    this.report_template.linearSectionStructure.forEach((section) => {
      const answers = sectionsAnswerData.filter(
        (answer) => answer.report_template_question.report_template_section?.id === section.id,
      );

      const answeredCount = answers.filter((answer) => answer.state === report_answer_state_enum.ANSWERED).length;
      section.progressMeta[-1] = {
        //@ts-ignore
        progress: sectionsProgressData.find(
          ({ report_template_section_id }) => report_template_section_id === section.id,
        ).progress,
        answeredCount,
        unansweredCount: answers.length - answeredCount,
      };

      this.report_sub_buildings.forEach((subBuilding) => {
        const subBuildingQuestions = answers.filter((answer) => answer.sub_building_id === subBuilding.id);
        const answeredCount = subBuildingQuestions.filter(
          (answer) => answer.state === report_answer_state_enum.ANSWERED,
        ).length;
        section.progressMeta[subBuilding.id] = {
          progress:
            sectionsProgressData.find(
              ({ report_template_section_id, sub_building_id }) =>
                report_template_section_id === section.id && sub_building_id === subBuilding.id,
              // FIXME: There is an issue in the backend that structural sections dont have specific progress on subBuilding level. Therefore for these sections there is no progress.
              // Fixed with: https://linear.app/predium/issue/PRE-1168/add-missing-progress-data-for-structural-asset-sections
            )?.progress ?? 0,
          answeredCount,
          unansweredCount: subBuildingQuestions.length - answeredCount,
        };
      });
    });
  }
}
