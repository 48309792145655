/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  useTheme,
} from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { DelayedLoading } from '../../../components/Loading';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import TableHeadCustom, { HeadCell } from '../../../components/table/TableHeadCustom';
import TablePaginationStandard from '../../../components/table/TablePaginationStandard';
import { PermissionType } from '../../../contexts/PermissionContext';
import { GET_ECONOMIC_UNITS_WITH_PORTFOLIOS } from '../../../graphql/DataCollection.queries';
import usePermissions from '../../../hooks/usePermissions';
import useTable, { applySortFilter } from '../../../hooks/useTable';
import SearchTableToolbar from '../../SearchTableToolbar';

type Props = {
  sx: SxProps<Theme>;
  selectedEconomicUnitId: number | null;
  setSelectedEconomicUnitId: (id: number) => void;
  onCancel: () => void;
  onMove: () => void;
};

export default function EconomicUnitsListToMove({
  selectedEconomicUnitId,
  setSelectedEconomicUnitId,
  onCancel,
  onMove,
  sx,
  children,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const theme = useTheme();

  const columns: HeadCell[] = [
    { id: 'name', label: t('General_EconomicUnit') },
    { id: 'action', label: '' },
  ];

  const {
    page,
    setPage,
    order,
    orderBy,
    filterName,
    handleFilterByName,
    handleRequestSort,
    setRowsPerPage,
    rowsPerPage,
  } = useTable({
    defaultOrderBy: 'name',
  });
  const { checkPortfolioPermission } = usePermissions();
  const { loading, data } = useQuery(GET_ECONOMIC_UNITS_WITH_PORTFOLIOS, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <DelayedLoading />;
  }

  const economicUnits = data?.economic_unit.filter(
    (eu) => eu.portfolio?.id && checkPortfolioPermission(eu.portfolio.id, PermissionType.WRITE),
  );

  const economicUnitLength = economicUnits?.length || 0;
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - economicUnitLength) : 0;

  const filteredRows = applySortFilter({
    data: economicUnits || [],
    nameFilter: {
      fieldName: 'name',
      filterValue: filterName,
    },
    orderOptions: {
      order,
      orderBy,
    },
  });

  const isNotFound = !filteredRows.length && Boolean(filterName);

  return (
    <>
      <DialogContent>
        <SearchTableToolbar
          numSelected={0}
          filterName={filterName}
          onFilterName={handleFilterByName}
          placeholder={t('DataCollection_Buildings_EconomicUnitsToMove_SearchPlaceholder')}
          style={{ padding: 0 }}
        />
        <Scrollbar>
          <TableContainer
            sx={{
              border: `1px solid ${theme.palette.grey[500_32]}`,
              borderRadius: '8px',
              width: 'auto',
            }}
          >
            <Table>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={columns}
                rowCount={filteredRows.length}
                onSort={handleRequestSort}
              />

              <TableBody>
                {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell align="right">
                        <>
                          {selectedEconomicUnitId === row.id ? (
                            <Button
                              size="small"
                              variant="outlined"
                              color="error"
                              //@ts-ignore
                              onClick={() => setSelectedEconomicUnitId(null)}
                            >
                              {t('General_RemoveSelection')}
                            </Button>
                          ) : (
                            <Button size="small" variant="outlined" onClick={() => setSelectedEconomicUnitId(row.id)}>
                              {t('General_Select')}
                            </Button>
                          )}
                        </>
                      </TableCell>
                    </TableRow>
                  );
                })}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}

                {isNotFound && <SearchNotFound searchQuery={filterName} />}
              </TableBody>
            </Table>
            <TablePaginationStandard
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </TableContainer>
        </Scrollbar>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" alignItems="center" sx={{ width: '100%', ...sx }}>
          {children}
          <Stack direction="row" alignItems="center" gap={2}>
            <Button variant="outlined" onClick={onCancel}>
              {t('General_Cancel')}
            </Button>
            <Button onClick={onMove} variant="contained" disabled={!selectedEconomicUnitId}>
              {t('General_Move')}
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </>
  );
}
