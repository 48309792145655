/* eslint-disable @typescript-eslint/ban-ts-comment */
import { type useSubscription } from '@apollo/client';
import { Badge, Box, Stack, Tab, Tabs } from '@mui/material';
import Container from '@mui/material/Container';
import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { DelayedLoading } from '../../components/Loading';
import Page from '../../components/Page';
import { UnavailablePageForMissingPortfolios } from '../../components/Unavailable';
import { ENERGY_CERTIFICATE_DRAFT_COUNT_SUBSCRIPTION } from '../../graphql/DataCollection.mutations';
import { GET_ALL_ENERGY_CERTIFICATE_DRAFTS_SUBSCRIPTION } from '../../graphql/DataCollection.queries';
import useDataCollectionFilters from '../../hooks/useDataCollectionFilters';
import useOrgPreferences from '../../hooks/useOrgPreferences';
import usePermissions from '../../hooks/usePermissions';
import { SEARCH_PARAMS, useTypeSafeSearchParams } from '../../routes';
import DataCollectionBuildingList from '../../sections/DataCollection/Buildings/DataCollectionBuildingList';
import DataCollectionEnergyCertificateDraftsList from '../../sections/DataCollection/Buildings/DataCollectionEnergyCertificateDraftsList';
import { DataCollectionTabActionsEnum, DataCollectionTabEnum } from '../../sections/DataCollection/Buildings/TabsEnums';
import DataCollectionEconomicUnitList from '../../sections/DataCollection/EconomicUnits/EconomicUnitsList';

type Props = {
  useSubscription: typeof useSubscription;
};

type PageTab = {
  label: string;
  value: DataCollectionTabEnum;
  component: ReactNode;
};

export default function DataCollectionTabs({ useSubscription }: Props) {
  const { t } = useTranslation();
  const { hasPortfolioWithWriteAccess } = usePermissions();

  const { searchParams } = useTypeSafeSearchParams(SEARCH_PARAMS.dataCollection.buildings);
  const { economicUnitsToggled } = useOrgPreferences();

  const subscription = useSubscription(GET_ALL_ENERGY_CERTIFICATE_DRAFTS_SUBSCRIPTION);

  const { currentTab, setCurrentTab } = useDataCollectionFilters();

  // opens the tab and create building screen if dynamically routed towards (e.g. Unavailable Page)
  useEffect(() => {
    if (searchParams.action === DataCollectionTabActionsEnum.CREATE_ENERGY_CERTIFICATE_DRAFT) {
      setCurrentTab({
        value: DataCollectionTabEnum.ENERGY_CERTIFICATE_DRAFTS,
        action: DataCollectionTabActionsEnum.CREATE_ENERGY_CERTIFICATE_DRAFT,
      });
    }
  }, [searchParams, setCurrentTab]);

  if (!subscription.data) {
    return <DelayedLoading />;
  }

  const buildingTabs = [
    economicUnitsToggled
      ? {
          label: t('General_EconomicUnit_other'),
          value: DataCollectionTabEnum.ECONOMIC_UNITS,
          component: <DataCollectionEconomicUnitList />,
        }
      : undefined,
    {
      label: t('General_Building'),
      value: DataCollectionTabEnum.BUILDINGS,
      component: <DataCollectionBuildingList setCurrentTab={setCurrentTab} />,
    },
    hasPortfolioWithWriteAccess
      ? {
          label: t('General_Drafts'),
          value: DataCollectionTabEnum.ENERGY_CERTIFICATE_DRAFTS,
          component: (
            <DataCollectionEnergyCertificateDraftsList
              shouldOpenCreateModal={currentTab.action === DataCollectionTabActionsEnum.CREATE_ENERGY_CERTIFICATE_DRAFT}
              setCurrentTab={setCurrentTab}
              data={subscription.data}
            />
          ),
        }
      : undefined,
  ].filter((item) => typeof item !== 'undefined') as PageTab[];

  return (
    <UnavailablePageForMissingPortfolios title={t('General_DataCollection')} heading={t('General_DataCollection')}>
      <Page title={t('General_DataCollection')}>
        <Container maxWidth="lg" sx={{ mb: 4 }}>
          <HeaderBreadcrumbs heading={t('General_DataCollection')} links={[]} />
          <Tabs
            value={currentTab.value}
            scrollButtons="auto"
            variant="scrollable"
            allowScrollButtonsMobile
            onChange={(_, value) => setCurrentTab({ value })}
          >
            {buildingTabs.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={
                  <EnergyCertificateDraftCount useSubscription={useSubscription} value={tab.value} label={tab.label} />
                }
                value={tab.value}
              />
            ))}
          </Tabs>

          <Box sx={{ mb: 5 }} />

          {buildingTabs.map((tab) => {
            if (tab) {
              const isMatched = tab.value === currentTab.value;
              return isMatched ? <Box key={tab.value}>{tab.component}</Box> : null;
            }
            return null;
          })}
        </Container>
      </Page>
    </UnavailablePageForMissingPortfolios>
  );
}

type EnergyCertificateDraftCountProps = {
  value: DataCollectionTabEnum;
  label: string;
  useSubscription: typeof useSubscription;
};

function EnergyCertificateDraftCount({ value, label, useSubscription }: EnergyCertificateDraftCountProps) {
  const subscription = useSubscription(ENERGY_CERTIFICATE_DRAFT_COUNT_SUBSCRIPTION);

  const energyCertificateDraftCount = subscription.data?.energy_certificate_draft_aggregate?.aggregate?.count ?? 0;

  return (
    <>
      {value !== DataCollectionTabEnum.ENERGY_CERTIFICATE_DRAFTS ? (
        <>{label}</>
      ) : (
        <Stack direction="row" alignItems="center" sx={{ pr: 2 }}>
          <div>{label}</div>
          {energyCertificateDraftCount > 0 && (
            <Badge badgeContent={energyCertificateDraftCount} color="error" sx={{ pl: 2 }} />
          )}
        </Stack>
      )}
    </>
  );
}
