import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EsgAnalysisPortfolioFragment } from '../../../../../libs/generated/client-graphql/src/generated/graphql';
import SimpleSelect from '../../components/SimpleSelect';

// ----------------------------------------------------------------------

export const ENTIRE_COMPANY = -1;

type Props = {
  portfolios: EsgAnalysisPortfolioFragment[];
  initialId: number;
  additionalBenchmarks?: { id: number; name: string }[];
  setBenchmarkId: (portfolioId: number) => void;
};

export default function EsgAnalysisBenchmark({ portfolios, initialId, additionalBenchmarks, setBenchmarkId }: Props) {
  const { t } = useTranslation();

  const benchmarks = [
    { id: ENTIRE_COMPANY, name: t('EsgAnalysisBenchmark_EntireCompany') },
    ...portfolios.map((portfolio) => ({
      id: portfolio.id,
      name: `Portfolio: ${portfolio.name}`,
    })),
    ...(additionalBenchmarks?.length ? additionalBenchmarks : []),
  ];

  const onBenchmarkIdChange = (newBenchmarkId: number) => {
    setBenchmarkId(newBenchmarkId);
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1.5}>
      <SimpleSelect
        label={t('General_Benchmark')}
        values={benchmarks}
        initialId={initialId}
        onChange={(value) => onBenchmarkIdChange(value as number)}
      />
    </Stack>
  );
}
