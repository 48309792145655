import { IconifyIcon } from '@iconify/react';
import { GlobalStyles, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha, useTheme } from '@mui/material/styles';
import { TFunction } from 'i18next';
import { OptionsObject, SnackbarKey, SnackbarMessage, SnackbarProvider } from 'notistack';
import { ReactNode, useRef } from 'react';
import { ColorSchema } from '../theme/palette';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

function SnackbarStyles() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <GlobalStyles
      styles={{
        '#root': {
          '.SnackbarContent-root': {
            width: '100%',
            padding: theme.spacing(1),
            margin: theme.spacing(0.25, 0),
            boxShadow: theme.customShadows.z8,
            borderRadius: theme.shape.borderRadius,
            color: isLight ? theme.palette.common.white : theme.palette.grey[800],
            backgroundColor: isLight ? theme.palette.grey[900] : theme.palette.common.white,
            '&.SnackbarItem-variantSuccess, &.SnackbarItem-variantError, &.SnackbarItem-variantWarning, &.SnackbarItem-variantInfo':
              {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
              },
            [theme.breakpoints.up('md')]: {
              minWidth: 240,
            },
          },
          '.SnackbarItem-message': {
            padding: '0 !important',
            fontWeight: theme.typography.fontWeightMedium,

            '& svg': {
              width: 24,
              height: 24,
            },
          },
          '.SnackbarItem-action': {
            marginRight: 0,
            color: theme.palette.action.active,

            '& svg': {
              width: 20,
              height: 20,
            },
          },
        },
      }}
    />
  );
}

// ----------------------------------------------------------------------

// Snackbars in our application. (see https://www.figma.com/file/rSFNnw8I6PfJrZM0u3cjRS/App?node-id=5399%3A9960&t=rDvUSEbe1dshVqEx-4)
//
// VARIANTS
// Default: Indicates generic information or a message with no severity. => 10 seconds
// Informational: when a user completes an action, but there are some additional info to be shown. =>  30 seconds (if requires action) and 15 seconds if not.
// Success: when a user completes/executes an action. => 10 seconds
// Warning: a warning snackbar is an advanced notice of a potential change that may result in loss of data or an error state. => 15 seconds
// Error: an error snackbar alerts people of a problem that has occurred and informs them what to do next. => 30 seconds
export enum SnackbarTimeouts {
  Default = 10000,
  Info = 30000,
  Success = 10000,
  Warning = 15000,
  Error = 30000,
}
// WHEN IS IT USED? (add conventions when applicable)
// - Mutations: Whenever the user takes an active action the error case must be handled. It is also advised to handle the success case for more immediate user feedback.
//
// WHAT TO DISPLAY?
// - Providing a more personalized message is encouraged
// - Make the message helpful and actionable ("X happened, do Y now")
// - Use a full sentece with precise punctioation

type Props = {
  children: ReactNode;
};

/**
 * Minimal@4 component.
 */
export default function NotistackProvider({ children }: Props) {
  const notistackRef = useRef<any>(null);

  const onClose = (key: SnackbarKey) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <>
      <SnackbarStyles />

      <SnackbarProvider
        ref={notistackRef}
        dense
        maxSnack={5}
        preventDuplicate
        autoHideDuration={SnackbarTimeouts.Default}
        variant="info"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        iconVariant={{
          info: <SnackbarIcon icon="eva:info-fill" color="info" />,
          success: <SnackbarIcon icon="eva:checkmark-circle-2-fill" color="success" />,
          warning: <SnackbarIcon icon="eva:alert-triangle-fill" color="warning" />,
          error: <SnackbarIcon icon="eva:alert-circle-fill" color="error" />,
        }}
        // With close as default
        action={(key) => (
          <IconButton size="small" onClick={onClose(key)} sx={{ p: 0.5 }}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        )}
      >
        {children}
      </SnackbarProvider>
    </>
  );
}

// ----------------------------------------------------------------------

type SnackbarIconProps = {
  icon: IconifyIcon | string;
  color: ColorSchema;
};

function SnackbarIcon({ icon, color }: SnackbarIconProps) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      <Iconify icon={icon} width={24} />
    </Box>
  );
}

// The key can be used to manually dismiss a snackbar before its expiration time.
const standardMutationQueryErrorSnackbarKey = 'predium-standard-mutation-error-snackbar';
/**
 * Shows a default snackbar suited for mutation errors.
 * Use this snackbar whenever you dont want to show a custom error message in a snackbar.
 *
 * @param enqueueSnackbar callback from notistack provided by the hook
 */
export const createStandardErrorSnackbar =
  (t: TFunction<'translation', undefined>) =>
  (enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey) =>
    enqueueSnackbar(t('Snackbar_DefaultErrorMessage'), {
      variant: 'error',
      preventDuplicate: true,
      key: standardMutationQueryErrorSnackbarKey,
      autoHideDuration: SnackbarTimeouts.Error,
    });
