import { Box, MenuItem, Typography } from '@mui/material';
import { energy_system_type_enum } from '@predium/enums';
import { forwardRef, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { FormProvider, RHFDatePicker, RHFSelect } from '../../../../../../components/hook-form';
import InfoTable from '../../../../../../components/presentations/InfoTable';
import { BuildingTabEnum } from '../../../../../../pages/DataCollection/DataCollectionBuilding';
import { createHydraulicBalancingInfoTable } from '../../../../Scenario/scenarios.util';
import { NumberInputSx, SelectSx, StyledListItemText } from '../../../Components/ActionCustomStyles';
import { ActionProps } from '../../ViewAction';

export type HydraulicBalancingForm = {
  implementation_to: DateTime | '';
  renovation_ids: never[];
  hydraulic_balancing_renovation_parameter: {
    last_hydraulic_balancing: DateTime;
  };
};

const HydraulicBalancingAction = forwardRef(
  ({ action, currentActionBuildingModel, previousActionBuildingModel }: ActionProps, ref) => {
    const { t } = useTranslation();
    //currently, hydraulic balancing is available only for heating systems
    const distributions = previousActionBuildingModel.energy_systems
      .filter((energy_system) => energy_system.energy_system_type_id === energy_system_type_enum.HEATING)
      .flatMap((energy_system) => energy_system.energy_system_consumer_routes)
      .flatMap((route) => route.energy_distribution)
      //filter the energy distributions where no hydraulic balancing has been carried out
      .filter((energy_distribution) => energy_distribution && !energy_distribution.last_hydraulic_balancing);

    const buildingId = previousActionBuildingModel.building!.id;

    const sumOfDistributionTransferLosses = distributions.reduce((acc, distribution) => {
      return acc + distribution!.heat_transfer_loss;
    }, 0);
    const sumOfDistributionTransferLossesRounded = Math.round(sumOfDistributionTransferLosses * 100) / 100;

    const setToNever = previousActionBuildingModel.energy_distributions.some(
      (distribution) => distribution.last_balancing_set_to_never,
    );
    const costs_with_components = currentActionBuildingModel.energy_distributions.some(
      (distribution) => distribution.balancing_with_components,
    );
    const infoTableData = createHydraulicBalancingInfoTable(sumOfDistributionTransferLossesRounded, setToNever, t);

    const defaultValues = {
      implementation_to: action.implementation_to,
      costs_with_components: costs_with_components ? 1 : 0,
    };

    const formMethods = useForm<HydraulicBalancingForm>({
      defaultValues: defaultValues,
    });

    useImperativeHandle(ref, () => ({
      methods: formMethods,
    }));

    return (
      <FormProvider methods={formMethods}>
        <Box my={3}>
          {infoTableData && (
            <Box mb={3}>
              <InfoTable
                buildingId={buildingId}
                showTab={BuildingTabEnum.technology}
                data={infoTableData}
                title={t('General_StateBeforeRenovation')}
              />
            </Box>
          )}
        </Box>

        <Box mb={3} px={1}>
          <Typography variant="body2" color="text.secondary">
            <Trans i18nKey="ActionPlanning_HydraulicBalancing_Info" components={{ bold: <strong /> }} />
          </Typography>
        </Box>

        <RHFSelect
          name="costs_with_components"
          label={t('General_Selection')}
          size={'small'}
          sx={{ ...SelectSx, mb: 1 }}
          disabled
        >
          <MenuItem key="withComponents" value={1}>
            <StyledListItemText action={t('ActionPlanning_HydraulicBalancing_CostsWithComponents')} secondary="€" />
          </MenuItem>
          <MenuItem key="withoutComponents" value={0}>
            <StyledListItemText action={t('ActionPlanning_HydraulicBalancing_CostsWithoutComponents')} secondary="€" />
          </MenuItem>
        </RHFSelect>

        <Box mb={3} px={1}>
          <Typography variant="body2" color="text.secondary">
            <Trans i18nKey="ActionPlanning_HydraulicBalancing_ComponentsInfo" />
          </Typography>
        </Box>

        <RHFDatePicker
          label={t('General_PlannedFor')}
          name="implementation_to"
          size={'small'}
          sx={NumberInputSx}
          disabled
        />
      </FormProvider>
    );
  },
);

export default HydraulicBalancingAction;
