/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Alert, Box, Button, Divider, Typography, useTheme } from '@mui/material';
import { ReportAnswerForAutofillFragment } from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IMAGES } from '../../../assets';
import FullScreenLoader from '../../../components/FullScreenLoader';
import Iconify from '../../../components/Iconify';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import PreDialog from '../../../components/presentations/PreDialog/PreDialog';
import { ACCEPT_AUTOFILL_REPORT } from '../../../graphql/Report.mutations';
import {
  GET_ALL_REPORT_AUTOFILL_QUESTIONS,
  GET_REPORT_COMPLETION,
  GET_ROOT_QUESTIONS_AND_ANSWERS,
} from '../../../graphql/Report.queries';
import { ReportIntroVariants } from './ReportIntroduction';

type Props = {
  initialOpen?: boolean;
  title: string;
  description: string;
  cancelButtonText: string;
  displayIntroDialog?: (introVariantToShow: ReportIntroVariants) => void;
  reportId: number;
};

function AcceptAllAutofill({
  initialOpen = false,
  reportId,
  description,
  title,
  cancelButtonText,
  displayIntroDialog,
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const apollo = useApolloClient();
  const theme = useTheme();

  const [dialogOpen, setDialogOpen] = useState(initialOpen);

  const [acceptAutofillReport, { loading: autofillLoading }] = useMutation(ACCEPT_AUTOFILL_REPORT, {
    onError: () =>
      enqueueSnackbar(t('AcceptAllAutofill_AcceptAutofill-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
    onCompleted: () =>
      enqueueSnackbar(t('AcceptAllAutofill_AcceptAutofill-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      }),
  });

  const { data } = useQuery(GET_ALL_REPORT_AUTOFILL_QUESTIONS, {
    variables: {
      reportId: reportId,
    },
  });

  if (!data) return null;

  // group answers by subBuildingId because `answerQuestions` accepts only and array of answers for a single subBuilding

  //@ts-ignore
  const subBuildingAnswers: Record<number, ReportAnswerForAutofillFragment[]> = data.report_by_pk.report_answers.reduce(
    (acc: Record<number, ReportAnswerForAutofillFragment[]>, answer: ReportAnswerForAutofillFragment) => {
      const subBuildingId: number = answer.sub_building_id ?? -1;
      if (!acc[subBuildingId]) {
        acc[subBuildingId] = [];
      }
      acc[subBuildingId].push(answer);
      return acc;
    },
    {} as Record<number, ReportAnswerForAutofillFragment[]>,
  );

  const acceptAllAutofillQuestions = async () => {
    await acceptAutofillReport({
      variables: {
        reportId,
      },
    });

    setDialogOpen(false);

    //@ts-ignore
    displayIntroDialog('common');

    apollo.refetchQueries({
      include: [GET_ALL_REPORT_AUTOFILL_QUESTIONS, GET_ROOT_QUESTIONS_AND_ANSWERS, GET_REPORT_COMPLETION],
    });
  };

  const handleDismiss = () => {
    setDialogOpen(false);

    //@ts-ignore
    displayIntroDialog('autofill');
  };

  return (
    <>
      <Button
        id="report-intro-autofill-step-0"
        variant="contained"
        size="large"
        onClick={() => setDialogOpen(true)}
        disabled={Object.values(subBuildingAnswers).length === 0}
        sx={{ width: 160 }}
      >
        {t('AcceptAllAutofill_AutofillReport')}
      </Button>

      <PreDialog
        type="definedByProperty"
        maxWidth={false}
        open={dialogOpen}
        onClose={handleDismiss}
        dialogtitle={title}
        content={
          <>
            <Typography sx={{ mb: 2 }}>{description}</Typography>

            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr auto 1fr',
                gap: 3,
                p: 3,
                mt: 3,
                mb: 4,
                borderRadius: 4,
                backgroundColor: theme.palette.grey[100],
              }}
            >
              <div style={{ display: 'grid', placeItems: 'center' }}>
                <img
                  style={{ height: 40, width: 40 }}
                  src={IMAGES.AutofillAvailable}
                  alt="half circle with a yellow checkmark"
                />
                <Typography variant="h4">{t('AcceptAllAutofill_ExplanationDialogAutofillAvailable-title')}</Typography>
                <Typography sx={{ color: theme.palette.grey[600], textAlign: 'center' }}>
                  {t('AcceptAllAutofill_ExplanationDialogAutofillAvailable-description')}
                </Typography>
              </div>

              <Divider orientation="vertical" style={{ height: '200px' }} />

              <div style={{ display: 'grid', placeItems: 'center' }}>
                <img
                  style={{ height: 40, width: 40 }}
                  src={IMAGES.AutofilledAnswer}
                  alt="full circle with a yellow checkmark"
                />
                <Typography variant="h4">{t('AcceptAllAutofill_ExplanationDialogAutofillAccepted-title')}</Typography>
                <Typography sx={{ color: theme.palette.grey[600], textAlign: 'center' }}>
                  {t('AcceptAllAutofill_ExplanationDialogAutofillAccepted-description')}
                </Typography>
              </div>
            </Box>

            <Alert severity="warning" icon={<Iconify icon="ion:warning" />}>
              {t('AcceptAllAutofill_ExplanationDialog-warning')}
            </Alert>
          </>
        }
        actions={
          <>
            <Button variant="outlined" onClick={handleDismiss}>
              {cancelButtonText}
            </Button>
            <Button variant="contained" autoFocus onClick={acceptAllAutofillQuestions}>
              {t('General_Autofill')}
            </Button>
          </>
        }
      />
      <FullScreenLoader
        text={t('AcceptAllAutofill_Autofill-loading')}
        open={autofillLoading && dialogOpen}
        disableCloseAction
      />
    </>
  );
}

export default AcceptAllAutofill;
