import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Trans, useTranslation } from 'react-i18next';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { DELETE_EMISSION_CERTIFICATE_DRAFT } from '../../../graphql/DataCollection.mutations';

type Props = {
  open: boolean;
  filename: string;
  onClose: () => void;
  onSuccessfulDelete?: () => void;
  emissionCertificateDraftId: number;
};

export default function EmissionCertificateDraftDeleteModal({
  open,
  filename,
  onClose,
  emissionCertificateDraftId,
  onSuccessfulDelete,
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteEmissionCertificateDraft] = useMutation(DELETE_EMISSION_CERTIFICATE_DRAFT, {
    variables: {
      id: emissionCertificateDraftId,
    },
    update: (cache, { data }) => {
      if (data?.delete_emission_certificate_draft_by_pk) {
        const normalizedId = cache.identify({
          id: data.delete_emission_certificate_draft_by_pk.id,
          __typename: data.delete_emission_certificate_draft_by_pk.__typename,
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
    onCompleted: () => {
      onSuccessfulDelete?.();
      onClose();
      enqueueSnackbar(t('DataCollection_EmissionCertificateDraftDelete-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
  });

  return (
    <DeleteConfirmationModal
      open={open}
      title={t('General_DeleteModalTitle-EmissionCertificateDraft', { count: 1 })}
      description={
        <Trans
          i18nKey={'General_DeleteModalDescription-EmissionCertificateDraft'}
          values={{ count: 1, name: filename }}
          components={{ bold: <strong /> }}
        />
      }
      onClose={() => {
        onClose();
      }}
      onDelete={async () => {
        await deleteEmissionCertificateDraft();
        onSuccessfulDelete?.();
      }}
    />
  );
}
