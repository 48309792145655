import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import FocusViewPage from '../../components/FocusViewPage';
import { DelayedLoading } from '../../components/Loading';
import { SnackbarTimeouts } from '../../components/NotistackProvider';
import PdfViewer from '../../components/PdfViewer';
import { PermissionType } from '../../contexts/PermissionContext';
import { DELETE_CONSUMPTION_INVOICE } from '../../graphql/DataCollection.mutations';
import {
  GET_BUILDING,
  GET_CONSUMPTIONS_INVOICES,
  GET_CONSUMPTION_INVOICE,
  GET_CONSUMPTION_SUMMARIES,
} from '../../graphql/DataCollection.queries';
import usePermissions from '../../hooks/usePermissions';
import { PATHS } from '../../routes';
import SubBuildingConsumptionInvoiceDraft from '../../sections/DataCollection/Building/Consumption/SubBuildingConsumptionInvoiceDraft';
import DataCollectionDraft from '../../sections/DataCollection/Building/Drafts/DataCollectionDraft';
import SubBuildingConsumptionForm from '../../sections/DataCollection/Building/Drafts/SubBuildingConsumptionForm';
import { BuildingTabEnum } from './DataCollectionBuilding';

export default function DataCollectionSubBuildingConsumption() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { checkBuildingPermission } = usePermissions();
  const { consumptionInvoiceId: consumptionInvoiceIdString, id: buildingIdString } = useParams();

  const buildingId = parseInt(buildingIdString!);
  const consumptionInvoiceId = parseInt(consumptionInvoiceIdString!);

  const [steps, setSteps] = useState<number[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [deleteConsumptionInvoice] = useMutation(DELETE_CONSUMPTION_INVOICE, {
    variables: {
      id: consumptionInvoiceId,
    },
    update: (cache, { data }) => {
      if (data && data.delete_consumption_invoice_by_pk) {
        const normalizedId = cache.identify({
          id: data.delete_consumption_invoice_by_pk.id,
          __typename: data.delete_consumption_invoice_by_pk.__typename,
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
    onCompleted: (data) => {
      if (data.delete_consumption_invoice_by_pk) {
        enqueueSnackbar(t('DataCollectionSubBuildingConsumption_DeleteConsumption-success'), {
          variant: 'success',
          autoHideDuration: SnackbarTimeouts.Success,
        });
        navigate(PATHS.dataCollection.building({ id: buildingId }, { tab: BuildingTabEnum.consumption }));
      }
    },
    onError: () => {
      enqueueSnackbar(t('DataCollectionSubBuildingConsumption_DeleteConsumption-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
    refetchQueries: [GET_BUILDING, GET_CONSUMPTION_SUMMARIES],
  });

  const { data: consumptionInvoiceData, loading } = useQuery(GET_CONSUMPTION_INVOICE, {
    variables: { consumptionInvoiceId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data.consumption_invoice_by_pk) {
        return navigate(PATHS.notFound());
      }
    },
  });
  const { loading: consumptionsLoading } = useQuery(GET_CONSUMPTIONS_INVOICES, {
    variables: { buildingId },
    onCompleted: (data) => {
      if (data.consumption_invoice) {
        const ids = data.consumption_invoice.map((item) => item.id);
        setSteps(ids);
      }
    },
  });

  const maxSteps = steps.length;
  const activeStep = steps.indexOf(consumptionInvoiceId) ?? 0;
  const handleStep = (direction: 'increase' | 'decrease') => () => {
    const prevStep = activeStep + (direction === 'increase' ? 1 : -1);
    navigate(
      PATHS.dataCollection.buildingConsumptionInvoice({ id: buildingId, consumptionInvoiceId: steps[prevStep] }),
    );
  };
  const stepperProps = useMemo(
    () => ({
      backStepTitle: t('DataCollectionSubBuildingConsumption_PreviousInvoice'),
      nextStepTitle: t('DataCollectionSubBuildingConsumption_NextInvoice'),
      maxSteps: maxSteps,
      handleNext: handleStep('increase'),
      handleBack: handleStep('decrease'),
      activeStep,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeStep, maxSteps],
  );

  if (
    loading ||
    consumptionsLoading ||
    !consumptionInvoiceData ||
    !consumptionInvoiceData.consumption_invoice_by_pk ||
    steps.length === 0
  ) {
    return <DelayedLoading />;
  }

  const hasEditAccess = checkBuildingPermission(buildingId, PermissionType.WRITE);

  const navigateToNextStep = () => {
    if (activeStep === maxSteps - 1) {
      navigate(PATHS.dataCollection.buildingConsumptionInvoice({ id: buildingId, consumptionInvoiceId: steps[0] }));
    } else {
      navigate(
        PATHS.dataCollection.buildingConsumptionInvoice({
          id: buildingId,
          consumptionInvoiceId: steps[activeStep + 1],
        }),
      );
    }
  };

  const goToNextAfterDelete = () => {
    if (maxSteps === 1) {
      navigate(PATHS.dataCollection.building({ id: buildingId }, { tab: BuildingTabEnum.consumption }));
    } else {
      navigateToNextStep();
    }
  };

  const deleteConsumption = async () => {
    await deleteConsumptionInvoice();
    goToNextAfterDelete();
  };

  return (
    <>
      <SubBuildingConsumptionForm
        consumptionInvoiceData={consumptionInvoiceData.consumption_invoice_by_pk}
        goToNextInvoice={navigateToNextStep}
        renderChildren={(submitForm, errors) => (
          <FocusViewPage
            primaryAction={{
              onClick: () => {},
              label: t('General_SaveInvoice'),
              isDisabled: Object.keys(errors).length > 0 || !hasEditAccess,
            }}
            secondaryAction={{
              onClick: () => {
                navigate(PATHS.dataCollection.building({ id: buildingId }, { tab: BuildingTabEnum.consumption }));
              },
              label: t('General_Back'),
              isDisabled: !hasEditAccess,
            }}
            onDeleteCallback={() => setDeleteModalOpen(true)}
            navigationStepperProps={stepperProps}
            minorContent={
              <DataCollectionDraft submitForm={submitForm} deleteModalOpen={deleteModalOpen}>
                <SubBuildingConsumptionInvoiceDraft disabled={!hasEditAccess} />
              </DataCollectionDraft>
            }
            majorContent={
              consumptionInvoiceData.consumption_invoice_by_pk!.file ? (
                <PdfViewer fileURL={consumptionInvoiceData.consumption_invoice_by_pk!.file.downloadUrl} compact />
              ) : null
            }
            onCloseCallback={() => {
              navigate(PATHS.dataCollection.building({ id: buildingId }, { tab: BuildingTabEnum.consumption }));
            }}
            title={t('PageName_DataCollection_ConsumptionInvoice')}
            subTitle={consumptionInvoiceData.consumption_invoice_by_pk!.file!.name!}
          />
        )}
      />

      <DeleteConfirmationModal
        open={deleteModalOpen}
        title={t('DataCollection_DeleteConsumptionInvoiceModal-title')}
        description={t('DataCollection_DeleteConsumptionInvoiceModal-description')}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        onDelete={async () => {
          await deleteConsumption();
          setDeleteModalOpen(false);
        }}
      />
    </>
  );
}
