/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Card, CardProps } from '@mui/material';
import { pathValuesFromYear } from '@predium/client-lookup';
import { fShortenNumber } from '@predium/utils';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import BaseOptionChart from '../../../theme/apexcharts';

interface Props extends CardProps {
  energyCosts: number[];
  energyCostsTotal: number[];
  showTotal: boolean;
  animated?: boolean;
}

export default function EnergieCostDevelopmentGraph({ energyCosts, energyCostsTotal, showTotal, animated, sx }: Props) {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const energyCostsFromCurrentYear = pathValuesFromYear(showTotal ? energyCostsTotal : energyCosts, currentYear);
  const data: [number, number][] = energyCostsFromCurrentYear.map((costs, idx) => [currentYear + idx, costs]);

  const series = [
    {
      name: t('General_EnergyCost'),
      data,
    },
  ];

  const chartState: ApexCharts.ApexOptions = merge(BaseOptionChart(), {
    chart: {
      animations: {
        enabled: !!animated,
      },
      type: 'area',
    },
    colors: ['#22609B'],
    title: {
      text: t('EnergyCostDevelopmentGraph_Title'),
    },

    yaxis: {
      labels: {
        formatter: (value: number) => `${fShortenNumber(value, 2)}`,
      },

      title: {
        text: t('General_EnergyCost') + ' ' + (showTotal ? '(€/a)' : '(€/m²a)'),
      },
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      labels: {
        formatter: (value: number, timestamp: number) => (timestamp % 5 === 0 ? value : ''),
      },
    },
    stroke: {
      width: 3,
    },
    tooltip: {
      y: {
        formatter: (value: number) => `${fShortenNumber(value, 2)} €`,
      },
    },
  });

  return (
    <Card sx={{ p: 3, pb: 1, ...sx }}>
      {/*@ts-ignore */}
      <ReactApexChart type={chartState.chart.type} options={chartState} series={series} height={350} />
    </Card>
  );
}
