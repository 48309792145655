import { MenuItem, TextField } from '@mui/material';
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileId } from '../../../../../components/DropzoneComponent';
import { EvidenceListEntry } from '../RootQuestionEvidences';
import AddDocumentEvidenceForm from './AddDocumentEvidenceForm';
import AddURLEvidenceForm, { AddURLEvidenceFormHandles } from './AddURLEvidenceForm';

type Props = {
  reportId: number;
  onEvidencesAdded: (evidences: EvidenceListEntry[]) => void;
  /**
   * TODO: Default value for the file Upload. We want to persist the uploaded file over component changes.
   * Hopefully the UploadComponent is someday refactored to handle cases like these.
   */
  persistedUploadFileRef: React.MutableRefObject<(Dropzone.DropzoneFile & FileId) | null>;
};

const NewEvidence = forwardRef<AddURLEvidenceFormHandles, Props>(
  ({ persistedUploadFileRef, onEvidencesAdded, reportId }, ref) => {
    const { t } = useTranslation();
    const [currentType, setCurrentType] = useState<'url' | 'file'>('url');

    return (
      <>
        <TextField
          select
          fullWidth
          label={t('General_EvidenceType')}
          value={currentType}
          onChange={(event) => setCurrentType(event.target.value as 'url' | 'file')}
        >
          <MenuItem value="url">{t('General_Link')}</MenuItem>
          <MenuItem value="file">{t('General_Document_one')}</MenuItem>
        </TextField>

        {currentType === 'url' && (
          <AddURLEvidenceForm reportId={reportId} onEvidencesAdded={onEvidencesAdded} ref={ref} />
        )}

        {currentType === 'file' && (
          <AddDocumentEvidenceForm
            reportId={reportId}
            onEvidencesAdded={onEvidencesAdded}
            ref={ref}
            persistedUploadFileRef={persistedUploadFileRef}
          />
        )}
      </>
    );
  },
);

export default NewEvidence;
