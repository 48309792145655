/**
 * Use this function when you _know_ that a value is defined but TS disagrees.
 * Only use it for values that are coming from the database.
 *
 * The goal is to remove this function by fixing the types.
 * */
export function ensureDefined<T>(value: T | null | undefined): T {
  if (value === null || value === undefined) {
    throw new Error('(╯°□°)╯︵ ┻━┻ this is supposed to be always defined');
  }
  return value;
}
