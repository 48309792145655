import { Box, Button, MenuItem, Tooltip } from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import { ICONS } from '../../../../assets/icons';
import Iconify from '../../../../components/Iconify';
import ActionMoreMenu from '../../../../components/common/ActionMoreMenu';
import ActionPlanExportPdf from './ActionPlanExportPdf';
import DeleteActionPlanModal from './DeleteActionPlanModal';

export type ActionPlanType = {
  id: number;
  buildingAddress: {
    street: string;
    postalCode: string;
    city: string;
  };
  totalActions: number;
  scenarioId: number;
};

type Props = {
  hasActionPlanDeletePermission: boolean;
  actionPlan: ActionPlanType;
  viewType: 'table' | 'page';
};

const ActionPlanActionMenu: React.FC<Props> = ({ actionPlan, hasActionPlanDeletePermission, viewType }) => {
  const [deleteActionPlanModalOpen, setDeleteActionPlanModalOpen] = useState(false);
  const [exportActionPlanModalOpen, setExportActionPlanModalOpen] = useState(false);

  const totalActions = actionPlan.totalActions;

  const ExportButton = () => (
    <Button
      onClick={() => {
        setExportActionPlanModalOpen(true);
      }}
      variant={'outlined'}
      sx={{ mr: 1 }}
      size="small"
      disabled={totalActions === 0}
    >
      <Iconify icon={ICONS.PDF_EXPORT} width={18} height={18} sx={{ mr: 1 }} />
      {t('ActionPlanning_ActionPlan-ExportAsPdfButton')}
    </Button>
  );

  const ExportMenuItem = () => (
    <MenuItem
      onClick={() => {
        setExportActionPlanModalOpen(true);
      }}
      disabled={totalActions === 0}
    >
      <Iconify icon={ICONS.PDF_EXPORT} width={18} height={18} />
      {t('ActionPlanning_ActionPlan-ExportAsPdfButton')}
    </MenuItem>
  );

  return (
    <>
      {viewType === 'page' && (
        <>
          {totalActions === 0 ? (
            <Tooltip title={t('ActionPlanning_ActionPlan-ExportPdfButtonTooltip')}>
              <Box component="span">
                <ExportButton />
              </Box>
            </Tooltip>
          ) : (
            <ExportButton />
          )}
        </>
      )}

      <ActionMoreMenu
        variant="icon"
        actions={
          <>
            {viewType === 'table' &&
              (totalActions === 0 ? (
                <Tooltip title={t('ActionPlanning_ActionPlan-ExportPdfButtonTooltip')}>
                  <Box component="span">
                    <ExportMenuItem />
                  </Box>
                </Tooltip>
              ) : (
                <ExportMenuItem />
              ))}
            {hasActionPlanDeletePermission && (
              <MenuItem
                onClick={() => {
                  setDeleteActionPlanModalOpen(true);
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={ICONS.TRASH} width={18} height={18} />
                {t('General_Delete')}
              </MenuItem>
            )}
          </>
        }
      />
      {exportActionPlanModalOpen && (
        <ActionPlanExportPdf
          open={exportActionPlanModalOpen}
          handleClose={() => setExportActionPlanModalOpen(false)}
          actionPlan={actionPlan}
        />
      )}

      <DeleteActionPlanModal
        deleteModalOpen={deleteActionPlanModalOpen}
        setDeleteModalOpen={() => setDeleteActionPlanModalOpen(false)}
        actionPlan={actionPlan}
      />
    </>
  );
};

export default ActionPlanActionMenu;
