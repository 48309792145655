/* eslint-disable @typescript-eslint/ban-ts-comment */
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

export type HeadLabel = {
  id: string;
  label: string;
  sortingDisabled?: Boolean;
  minWidth?: number;
};

type Props = {
  order: 'asc' | 'desc';
  orderBy: string;
  headLabel: HeadLabel[];
  numSelected: number;
  onRequestSort: (id: string) => void;
};

export default function ScenariosTableHead({ order, orderBy, headLabel, onRequestSort }: Props) {
  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            //@ts-ignore
            sx={{ ...(!isNaN(headCell.minWidth) && { minWidth: headCell.minWidth }) }}
          >
            {!headCell.sortingDisabled ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => onRequestSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
