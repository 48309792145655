import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import useSubscriptionWithFallback from '../../../hooks/useSubscriptionFallback';
import { ReportDC } from '../ReportingDataClasses/Report.dc';
import { getTemplateMetadataForTemplateTitle } from '../ReportingDataClasses/ReportTemplate.dc';
import ReportInfo from './ReportInfo';
import ReportProgress from './ReportProgress';
import ReportScores from './ReportScores';

type Props = {
  sx?: SxProps<Theme>;
  report: ReportDC;
};

export default function ReportHead({ sx, report }: Props) {
  return (
    <Box sx={sx}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <ReportInfo report={report} />
        </Grid>

        <Grid item xs={4}>
          <ReportProgress id={report.id} />
        </Grid>

        <Grid item xs={4}>
          <ReportScores
            useSubscription={useSubscriptionWithFallback}
            id={report.id}
            isGresbReport={getTemplateMetadataForTemplateTitle(report.report_template.title).readableTitle === 'GRESB'}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
