import PdfViewer from '../../../../../components/PdfViewer';
import PreDialog from '../../../../../components/presentations/PreDialog/PreDialog';

type Props = {
  open: boolean;
  onClose: () => void;
  documentUrl: string;
};

export default function EvidenceDocumentViewer({ open, onClose, documentUrl }: Props) {
  return (
    <PreDialog type="definedByChildren" fullWidth open={open} onClose={onClose}>
      <PdfViewer fileURL={documentUrl} />
    </PreDialog>
  );
}
