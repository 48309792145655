import { CardProps, Stack, useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { efficiency_class_enum } from '@predium/enums';
import { fShortenNumber } from '@predium/utils';
import { useTranslation } from 'react-i18next';
import EfficiencyClassLabelLarge from '../../EfficiencyClassLabelLarge';

interface Props extends CardProps {
  efficiencyClass: efficiency_class_enum;
  landlordProportion: number;
  change: number | null | undefined;
  showResidentialLabel?: boolean;
  isAverage?: boolean;
}

export default function BuildingEfficiencyCard({
  efficiencyClass,
  landlordProportion,
  showResidentialLabel = false,
  isAverage = false,
  sx,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const { bgColor, color } =
    landlordProportion > 70 // TODO: which thresholds
      ? {
          bgColor: theme.palette.error.lighter,
          color: theme.palette.error.main,
        }
      : landlordProportion < 30
      ? {
          bgColor: theme.palette.success.lighter,
          color: theme.palette.success.main,
        }
      : {
          bgColor: theme.palette.warning.lighter,
          color: theme.palette.warning.main,
        };

  return (
    <Card sx={{ p: 3, ...sx }}>
      <Stack direction="column" justifyContent="space-between" alignItems="baseline" sx={{ height: 1 }}>
        <div style={{ width: '100%' }}>
          <Stack direction="row" alignItems="center" flexWrap="wrap">
            <Typography sx={{ typography: 'h5', padding: '2px 0', marginRight: 'auto' }}>
              {t('General_ESGEfficiency')}
            </Typography>
          </Stack>
        </div>
        <div style={{ width: '100%' }}>
          <Typography variant="body2" color="text.secondary">
            {isAverage ? t('General_AverageEfficiencyClass') : t('General_EfficiencyClass')}{' '}
            {showResidentialLabel && t('General_Residence')}
          </Typography>
          <EfficiencyClassLabelLarge
            efficiencyClass={efficiencyClass}
            variant="ghost"
            sx={{ mt: 0.5, p: 1, width: '100%' }}
          />
        </div>
        <div style={{ width: '100%' }}>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            {isAverage ? t('General_AverageCO2CostShare') : t('General_CO2CostShare')}
          </Typography>
          <Typography
            variant="h4"
            align="center"
            color={color}
            sx={{
              backgroundColor: bgColor,
              mt: 0.5,
              p: 1,
              borderRadius: '10px',
              height: '50px',
            }}
          >
            {fShortenNumber(landlordProportion)}%
          </Typography>
        </div>
      </Stack>
    </Card>
  );
}
