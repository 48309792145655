import { insulation_material_category_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateInsulationMaterialCategoryEnum = (enumValue: insulation_material_category_enum) => {
  switch (enumValue) {
    case insulation_material_category_enum.MINERAL:
      return <Trans i18nKey="Enum_InsulationMaterialCategoryEnum-MINERAL" />;
    case insulation_material_category_enum.MIXED:
      return <Trans i18nKey="Enum_InsulationMaterialCategoryEnum-MIXED" />;
    case insulation_material_category_enum.RENEWABLE:
      return <Trans i18nKey="Enum_InsulationMaterialCategoryEnum-RENEWABLE" />;
    case insulation_material_category_enum.SYNTHETIC:
      return <Trans i18nKey="Enum_InsulationMaterialCategoryEnum-SYNTHETIC" />;
  }
};

export const translateInsulationMaterialCategoryEnum_dynamic = (
  enumValue: insulation_material_category_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case insulation_material_category_enum.MINERAL:
      return t('Enum_InsulationMaterialCategoryEnum-MINERAL');
    case insulation_material_category_enum.MIXED:
      return t('Enum_InsulationMaterialCategoryEnum-MIXED');
    case insulation_material_category_enum.RENEWABLE:
      return t('Enum_InsulationMaterialCategoryEnum-RENEWABLE');
    case insulation_material_category_enum.SYNTHETIC:
      return t('Enum_InsulationMaterialCategoryEnum-SYNTHETIC');
  }
};
