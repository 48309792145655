import { usePostHog } from 'posthog-js/react';
import { useLocation } from 'react-router-dom';
import { POSTHOG_PAGES_TO_TRACK, PosthogEvent } from '../utils/posthogEvents';

const usePosthogTracking = () => {
  const posthog = usePostHog();
  const location = useLocation();

  /**
   * track an event with posthog
   * @param event the event name, following our event naming convention
   * @param properties optional properties to be added to the event
   */
  const trackEvent = (
    event: PosthogEvent,
    properties?: Record<string, string | number | boolean | Array<number | string | boolean> | null>,
  ) => {
    posthog?.capture(event, properties);
  };

  const trackPageView = () => {
    const currentPath = location.pathname;

    if (!POSTHOG_PAGES_TO_TRACK.includes(currentPath)) {
      return;
    }

    posthog?.capture('page_view', {
      page_url: currentPath,
    });
  };

  return {
    trackEvent,
    trackPageView,
  };
};

export default usePosthogTracking;
