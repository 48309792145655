/* eslint-disable @typescript-eslint/ban-ts-comment */
import { PropsWithChildren, createContext, useCallback } from 'react';
import useAccessToken from '../hooks/useAccessToken';
import useSessionData from '../hooks/useSessionData';
import { useGetPermissionsFromQuery } from '../sections/Org/User/access-control/utils';

export interface ObjectPermission {
  id: number;
  read: boolean;
  write: boolean;
}

enum PermissionType {
  READ = 'READ',
  WRITE = 'WRITE',
}

interface PermissionData {
  /** An array of the ids of portfolios that the user has read access to with a boolean for read and write access respectively.
   * This is basically the content of the user_portfolio_permission table.
   */
  portfolios: ObjectPermission[];
  /** An array of the ids of buildings that the user has read access to with a boolean for read and write access respectively.
   * This takes into account the user_building_permission table as well as the related portfolio permissions inside user_portfolio_permission.
   */
  buildings: ObjectPermission[];
  /**
   * A boolean that is true if the user has write access to at least one portfolio.
   */
  hasPortfolioWithWriteAccess: boolean;
  /** A convenience function that looks up the building id in the buildings permission table. */
  checkBuildingPermission: (building_id: number, permissionType: PermissionType) => boolean;
  /** A convenience function that looks up the portfolio id in the portfolios permission table. */
  checkPortfolioPermission: (portfolio_id: number, permissionType: PermissionType) => boolean;
}

const initialState: PermissionData = {
  portfolios: [],
  buildings: [],
  hasPortfolioWithWriteAccess: false,
  checkBuildingPermission: () => false,
  checkPortfolioPermission: () => false,
};

const PermissionContext = createContext(initialState);

/**
 * Provides a context with the user's permissions for portfolios and buildings.
 * These permissions should not be used if the user is an admin as they should have access to everything inside of their own ORG.
 *
 */
function PermissionProvider({ children }: PropsWithChildren) {
  const { idTokenClaims } = useAccessToken();
  const { isAdmin } = useSessionData();

  const user_id = idTokenClaims?.['x-hasura-user-id'];

  //@ts-ignore
  const { buildings, portfolios } = useGetPermissionsFromQuery(user_id);

  const checkBuildingPermission = useCallback(
    (building_id: number, permissionType: PermissionType) => {
      if (isAdmin) return true;
      if (!buildings) return false; // If buildings have not been loaded yet - we get an error otherwise
      const permissionProp = permissionType === PermissionType.READ ? 'read' : 'write';
      return buildings.some((b) => b.id === building_id && b[permissionProp]);
    },
    [buildings, isAdmin],
  );

  const checkPortfolioPermission = useCallback(
    (portfolio_id: number, permissionType: PermissionType) => {
      if (isAdmin) return true;
      if (!portfolios) return false; // If portfolios have not been loaded yet - we get an error otherwise
      const permissionProp = permissionType === PermissionType.READ ? 'read' : 'write';
      return portfolios.some((p) => p.id === portfolio_id && p[permissionProp]);
    },
    [portfolios, isAdmin],
  );

  const hasPortfolioWithWriteAccess = isAdmin || (portfolios && portfolios.some((p) => p.write));

  return (
    <PermissionContext.Provider
      value={{
        portfolios,
        buildings,
        hasPortfolioWithWriteAccess,
        checkBuildingPermission,
        checkPortfolioPermission,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
}

export { PermissionContext, PermissionProvider, PermissionType };
