import { sub_building_class_enum, type_of_use_enum } from '@predium/enums';

const classOfUseLookup: Record<type_of_use_enum, sub_building_class_enum> = {
  [type_of_use_enum.MFH]: sub_building_class_enum.RESIDENTIAL,
  [type_of_use_enum.SFH]: sub_building_class_enum.RESIDENTIAL,
  [type_of_use_enum.DISTRIBUTION_WAREHOUSE_COLD]: sub_building_class_enum.COMMERCIAL,
  [type_of_use_enum.DISTRIBUTION_WAREHOUSE_WARM]: sub_building_class_enum.COMMERCIAL,
  [type_of_use_enum.OFFICE]: sub_building_class_enum.COMMERCIAL,
  [type_of_use_enum.RETAIL]: sub_building_class_enum.COMMERCIAL,
  [type_of_use_enum.HEALTH_FACILITY]: sub_building_class_enum.COMMERCIAL,
  [type_of_use_enum.HOTEL]: sub_building_class_enum.COMMERCIAL,
  [type_of_use_enum.RETAIL_WAREHOUSE]: sub_building_class_enum.COMMERCIAL,
  [type_of_use_enum.SHOPPING_CENTER]: sub_building_class_enum.COMMERCIAL,
};

export const getClassOfUse = (typeOfUse: type_of_use_enum): sub_building_class_enum => {
  return classOfUseLookup[typeOfUse];
};
