import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, useTheme } from '@mui/material';
import { data_source_type_enum, energy_system_type_enum } from '@predium/enums';
import { PropsWithChildren, useState } from 'react';
import Iconify from '../../../../../components/Iconify';
import { SystemRoute } from '../../BuildingTechnology';
import TechnologySectionHead from './TechnologySectionHead';

type Props = {
  dataSourceType: data_source_type_enum;
  resetSystem: () => void;
  energySystemType: energy_system_type_enum;
  onAddRouteClick: () => void;
  routes: SystemRoute[];
  systemIndex: number;
};

export function TechnologySectionWrapper({
  children,
  dataSourceType,
  energySystemType,
  resetSystem,
  onAddRouteClick,
  routes,
  systemIndex,
}: PropsWithChildren<Props>) {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(true);

  const totalFinalEnergy = routes.reduce((total: number, route: SystemRoute) => {
    return typeof route.energyFinal === 'number' && !route.routeDeleted ? total + route.energyFinal : total;
  }, 0);

  return (
    <Accordion
      style={{ boxShadow: 'none', borderRadius: '24px' }}
      disableGutters
      defaultExpanded
      expanded={isExpanded}
      onChange={(_, expanded) => setIsExpanded(expanded)}
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        '&.Mui-expanded': {
          background: theme.palette.grey[200],
        },
        '&.MuiAccordion-root:before': {
          backgroundColor: 'unset',
        },
        background: theme.palette.grey[200],
        p: 1,
        mb: 3,
      }}
    >
      <AccordionSummary
        sx={{
          '.MuiAccordionSummary-expandIconWrapper': {
            width: 36,
            height: 36,
            mr: 1,
          },
        }}
        expandIcon={
          <IconButton>
            <Iconify
              icon="ic:outline-arrow-forward-ios"
              style={{ transform: 'rotate(90deg)' }}
              width={18}
              height={18}
            />
          </IconButton>
        }
      >
        <TechnologySectionHead
          dataSourceType={dataSourceType}
          totalFinalEnergy={totalFinalEnergy}
          resetSystem={resetSystem}
          energySystemType={energySystemType}
          onAddRouteClick={onAddRouteClick}
          systemIndex={systemIndex}
          isExpanded={isExpanded}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            {children}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
