import { energy_consumer_technology_type_enum, energy_system_type_enum } from '@predium/enums';

//
// TechnologyTypesForSystemType - a mapping of supported technology types for a given system type
//

export const TechnologyTypesForSystemType: Record<energy_system_type_enum, energy_consumer_technology_type_enum[]> = {
  [energy_system_type_enum.COOLING]: [
    energy_consumer_technology_type_enum.REVERSIBLE_BRINE_WATER_HEAT_PUMP,
    energy_consumer_technology_type_enum.REVERSIBLE_AIR_WATER_HEAT_PUMP,
    energy_consumer_technology_type_enum.CENTRAL_AIR_CONDITIONING,
    energy_consumer_technology_type_enum.ROOM_AIR_CONDITIONING,
  ],
  [energy_system_type_enum.ELECTRICITY]: [energy_consumer_technology_type_enum.ELECTRICITY_MISCELLANEOUS],
  [energy_system_type_enum.GENERAL]: [],
  [energy_system_type_enum.HEATING]: [
    energy_consumer_technology_type_enum.COAL_FURNACE,
    energy_consumer_technology_type_enum.CONDENSING_BOILER,
    energy_consumer_technology_type_enum.DIRECT_ELECTRICITY_HEATING,
    energy_consumer_technology_type_enum.DISTRICT_HEATING_WITHOUT_KWK,
    energy_consumer_technology_type_enum.DISTRICT_HEATING_WITH_KWK,
    energy_consumer_technology_type_enum.ELECTRIC_HEAT_PUMP_AIR,
    energy_consumer_technology_type_enum.ELECTRIC_HEAT_PUMP_GEO,
    energy_consumer_technology_type_enum.GAS_CONDENSING_BOILER,
    energy_consumer_technology_type_enum.GAS_NON_CONDENSING_BOILER,
    energy_consumer_technology_type_enum.GAS_ROOM_HEATER,
    energy_consumer_technology_type_enum.GAS_FLOOR_HEATING,
    energy_consumer_technology_type_enum.LOW_TEMPERATURE_BOILER,
    energy_consumer_technology_type_enum.OIL_FURNACE,
    energy_consumer_technology_type_enum.STANDARD_BOILER,
    energy_consumer_technology_type_enum.WOOD_BOILER,
    energy_consumer_technology_type_enum.WOOD_FURNACE,
  ],
  [energy_system_type_enum.HOT_WATER]: [
    energy_consumer_technology_type_enum.COAL_FURNACE,
    energy_consumer_technology_type_enum.CONDENSING_BOILER,
    energy_consumer_technology_type_enum.DIRECT_ELECTRICITY_HEATING,
    energy_consumer_technology_type_enum.DISTRICT_HEATING_WITHOUT_KWK,
    energy_consumer_technology_type_enum.DISTRICT_HEATING_WITH_KWK,
    energy_consumer_technology_type_enum.ELECTRIC_HEAT_PUMP_AIR,
    energy_consumer_technology_type_enum.ELECTRIC_HEAT_PUMP_GEO,
    energy_consumer_technology_type_enum.GAS_CONDENSING_BOILER,
    energy_consumer_technology_type_enum.GAS_FLOOR_HEATING,
    energy_consumer_technology_type_enum.GAS_NON_CONDENSING_BOILER,
    energy_consumer_technology_type_enum.LOW_TEMPERATURE_BOILER,
    energy_consumer_technology_type_enum.OIL_FURNACE,
    energy_consumer_technology_type_enum.STANDARD_BOILER,
    energy_consumer_technology_type_enum.WOOD_BOILER,
    energy_consumer_technology_type_enum.WOOD_FURNACE,
    energy_consumer_technology_type_enum.ELECTRIC_FLOW_HEATER,
    energy_consumer_technology_type_enum.GAS_FLOW_HEATER,
  ],
  [energy_system_type_enum.LIGHTING]: [
    energy_consumer_technology_type_enum.LED,
    energy_consumer_technology_type_enum.INCANDESCENT_LAMPS,
    energy_consumer_technology_type_enum.FLUORESCENT_LAMPS,
    energy_consumer_technology_type_enum.HALOGEN_LAMPS,
  ],
  [energy_system_type_enum.SOLAR]: [],
  [energy_system_type_enum.VENTILATION]: [
    energy_consumer_technology_type_enum.CENTRAL_EXHAUST,
    energy_consumer_technology_type_enum.CENTRAL_SUPPLY_EXHAUST_WITH_HRU,
    energy_consumer_technology_type_enum.CENTRAL_SUPPLY_EXHAUST_WITHOUT_HRU,
    energy_consumer_technology_type_enum.DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITH_HRU,
    energy_consumer_technology_type_enum.DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITHOUT_HRU,
  ],
};
