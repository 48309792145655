import { Grid } from '@mui/material';
import React from 'react';

import { ModificationImpactsFragment } from '@predium/client-graphql';
import { useTranslation } from 'react-i18next';
import { CO2Emissions } from '../CO2Emissions';
import { EnergyWithEfficiencyClass } from './EnergyWithEfficiencyClass';
import { EnergyWithoutEfficiencyClass } from './EnergyWithoutEfficiencyClass';

type Props = {
  modificationImpact: ModificationImpactsFragment;
  isOverview: boolean;
  isSimulated: boolean;
};

//energy analysis for all countries (DE, PL etc) except Austria
const GeneralEnergyAnalysis: React.FC<Props> = ({ modificationImpact, isOverview, isSimulated }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <EnergyWithEfficiencyClass
            modificationImpact={modificationImpact}
            isOverview={isOverview}
            isSimulated={isSimulated}
            title={t('General_FinalEnergy')}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <CO2Emissions modificationImpact={modificationImpact} isOverview={isOverview} isSimulated={isSimulated} />
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} lg={12} mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <EnergyWithoutEfficiencyClass
              modificationImpact={modificationImpact}
              isOverview={isOverview}
              isSimulated={isSimulated}
              usePrimaryEnergy
              title={t('General_PrimaryEnergy')}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default GeneralEnergyAnalysis;
