import { getAssociatedActionPlansAndReportsFromEmissionCertificateQuery } from '@predium/client-graphql';
import sumBy from 'lodash/sumBy';
import uniqBy from 'lodash/uniqBy';

export const getConflictsCount = (
  associatedActionPlansAndReport: getAssociatedActionPlansAndReportsFromEmissionCertificateQuery | undefined,
) => {
  const emissionFactorAssociated = associatedActionPlansAndReport?.emission_factor_buildings ?? [];
  const primaryEnergyFactorAssociated = associatedActionPlansAndReport?.primary_energy_factor_buildings ?? [];

  const buildings = uniqBy([...emissionFactorAssociated, ...primaryEnergyFactorAssociated], 'id');

  const associatedBuildings = buildings.length;

  const associatedActionPlans = sumBy(buildings, (building) => building.action_plans_aggregate.aggregate?.count ?? 0);

  const associatedReports = new Set(
    buildings.flatMap((building) =>
      building.sub_buildings.flatMap((subBuilding) =>
        subBuilding.report_sub_buildings.flatMap((reportSubBuilding) => reportSubBuilding.report_id),
      ),
    ),
  ).size;

  return {
    associatedActionPlans,
    associatedBuildings,
    associatedReports,
    hasEmissionFactorAssociated: emissionFactorAssociated.length > 0,
    hasPrimaryEnergyFactorAssociated: primaryEnergyFactorAssociated.length > 0,
  };
};
