import { Dialog, DialogContent, IconButton } from '@mui/material';
import Iconify from './Iconify';
import { DelayedLoading } from './Loading';

type Props =
  | {
      text: string;
      open: boolean;
      disableCloseAction: true;
      onClose?: () => void;
    }
  | {
      text: string;
      open: boolean;
      disableCloseAction: false;
      onClose: () => void;
    };

export default function FullScreenLoader({ text, open, disableCloseAction, onClose }: Props) {
  return (
    <Dialog open={open} disableEscapeKeyDown={disableCloseAction}>
      {!disableCloseAction && (
        <IconButton sx={{ position: 'absolute', top: 3, right: 3 }} onClick={onClose}>
          <Iconify icon={'charm:cross'} width={20} height={20} />
        </IconButton>
      )}
      <DialogContent sx={{ py: 4, px: 6 }}>
        <DelayedLoading delay={0} text={text} />
      </DialogContent>
    </Dialog>
  );
}
