import { emission_factor_type_enum } from '@predium/enums';
import { GetSessionDataOrgFragment } from '../../../../../../libs/generated/client-graphql/src';

const emissionFactorTypes = Object.values(emission_factor_type_enum);

// Remove CO2TAX if it's not the selected emission factor type
const removeCO2Tax = (types: emission_factor_type_enum[], selectedType: emission_factor_type_enum) => {
  if (selectedType !== emission_factor_type_enum.CO2TAX) {
    return types.filter((type) => type !== emission_factor_type_enum.CO2TAX);
  }
  return types;
};

const PRODUCTION_ORG_IDS = {
  BAHNERS_SCHMITZ: 9,
  WOHR_BAUER: 28,
  WUEST_PARTNER: 29,
  POLIS: 34,
  GOLDBECK: 83,
  GROSS_PARTNER: 97,
};

// Production only: Remove all custom emission factor types if the org doesn't match
const filterNonCustomEmissionFactorTypes = (types: emission_factor_type_enum[], orgId: number) => {
  if (import.meta.env.VITE_ENV !== 'production' || !orgId || orgId === 1) {
    // Don't filter if env is not production or orgId is not provided or orgId is 1 (Predium org)
    return types;
  }
  const toRemove: emission_factor_type_enum[] = [];

  if (orgId !== PRODUCTION_ORG_IDS.BAHNERS_SCHMITZ) {
    toRemove.push(emission_factor_type_enum.BAHNERS_SCHMITZ);
  }
  if (orgId !== PRODUCTION_ORG_IDS.WUEST_PARTNER) {
    toRemove.push(emission_factor_type_enum.WUEST_PARTNER);
  }

  return types.filter((type) => !toRemove.includes(type));
};

// Get the updated emission factor types
export const updatedEmissionFactorTypes = (
  emissionFactorType: emission_factor_type_enum,
  org: GetSessionDataOrgFragment,
) => {
  let types = [...emissionFactorTypes];

  types = removeCO2Tax(types, emissionFactorType);
  types = filterNonCustomEmissionFactorTypes(types, org?.id);

  return types;
};
