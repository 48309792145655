/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQuery } from '@apollo/client';
import { emission_factor_type_enum } from '@predium/enums';
import { useSnackbar } from 'notistack';
import { PropsWithChildren, createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SnackbarTimeouts } from '../components/NotistackProvider';
import { UPDATE_ORG_PREFERENCE_FOR_ECONOMIC_UNIT } from '../graphql/common.mutations';
import { GET_ORG_PREFERENCES } from '../graphql/common.queries';
import useSessionData from '../hooks/useSessionData';

type OrgPreferencesContextProps = {
  economicUnitsToggled: boolean;
  orgEmissionFactorType: emission_factor_type_enum | undefined;
  toggleEconomicUnits: () => void;
};

const initialState: OrgPreferencesContextProps = {
  economicUnitsToggled: false,
  toggleEconomicUnits: () => {},
  orgEmissionFactorType: undefined,
};

const OrgPreferencesContext = createContext<OrgPreferencesContextProps>(initialState);

type Props = PropsWithChildren<{}>;

function OrgPreferencesContextProvider({ children }: Props) {
  const { t } = useTranslation();
  const [economicUnitsToggled, setEconomicUnitsToggled] = useState<boolean>(false);
  const [orgEmissionFactorType, setOrgEmissionFactorType] = useState<emission_factor_type_enum | undefined>(undefined);
  const { org } = useSessionData();
  const { enqueueSnackbar } = useSnackbar();

  useQuery(GET_ORG_PREFERENCES, {
    onCompleted: (data) => {
      const orgPreferences = data.org[0].org_preferences;

      //@ts-ignore
      const economicUnitEnabled = orgPreferences.economic_unit_enabled;

      //@ts-ignore
      const orgEmissionFactorType = orgPreferences.emission_factor_type_id;

      setOrgEmissionFactorType(orgEmissionFactorType);
      setEconomicUnitsToggled(economicUnitEnabled);
    },
  });

  const [toggleEconomicUnit] = useMutation(UPDATE_ORG_PREFERENCE_FOR_ECONOMIC_UNIT, {
    refetchQueries: [GET_ORG_PREFERENCES],
    onError: () => {
      enqueueSnackbar(t('General_FailedToSaveChanges'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
    onCompleted: () => {
      enqueueSnackbar(t('General_ChangesSavedSuccessfully'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
  });

  const toggleEconomicUnits = () => {
    toggleEconomicUnit({
      variables: {
        economicUnitEnabled: !economicUnitsToggled,

        //@ts-ignore
        orgId: org?.id,
      },
    });
  };

  return (
    <OrgPreferencesContext.Provider
      value={{
        economicUnitsToggled,
        toggleEconomicUnits,
        orgEmissionFactorType,
      }}
    >
      {children}
    </OrgPreferencesContext.Provider>
  );
}

export { OrgPreferencesContext, OrgPreferencesContextProvider };
