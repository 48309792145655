import { MenuItem } from '@mui/material';
import { PortfolioManagementPortfolioFragment } from '@predium/client-graphql';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../assets/icons';
import Iconify from '../../../components/Iconify';

type Props = {
  portfolio: PortfolioManagementPortfolioFragment;
  setEditPortfolio: (portfolio: PortfolioManagementPortfolioFragment) => void;
  setDeletePortfolio: (portfolio: PortfolioManagementPortfolioFragment) => void;
};

export default function PortfolioActionMenu({ setDeletePortfolio, setEditPortfolio, portfolio }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <MenuItem onClick={() => setEditPortfolio(portfolio)}>
        <Iconify icon={'eva:edit-fill'} />
        {t('General_Edit')}
      </MenuItem>
      <MenuItem onClick={() => setDeletePortfolio(portfolio)} sx={{ color: 'error.main' }}>
        <Iconify icon={ICONS.TRASH} />
        {t('General_Delete')}
      </MenuItem>
    </>
  );
}
