/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, ListItemIcon, MenuItem } from '@mui/material';
import { energy_source_type_enum, renovation_type_enum } from '@predium/client-graphql';
import { translateRenovationTypeEnum } from '@predium/i18n/client';
import i18next from 'i18next';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormProvider, RHFDatePicker, RHFSelect } from '../../../../../../components/hook-form';
import InfoTable from '../../../../../../components/presentations/InfoTable';
import { BuildingTabEnum } from '../../../../../../pages/DataCollection/DataCollectionBuilding';
import { getActionIcon } from '../../../../../../utils/icons';
import { createEnergyInfoTable } from '../../../../Scenario/scenarios.util';
import { NumberInputSx, SelectSx, StyledListItemText } from '../../../Components/ActionCustomStyles';
import { ActionProps } from '../../ViewAction';

type EnergyRouteActionForm = {
  action: renovation_type_enum;
  energy_source_type: energy_source_type_enum;
  implementation_to: string;
};

const ENERGY_ROUTE_TYPE = renovation_type_enum.ENERGY_SYSTEM_ROUTE;

export type SupportedEnergySources =
  | energy_source_type_enum.ELECTRICITY_GREEN
  | energy_source_type_enum.BIO_GAS
  | energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE
  | energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE;

export const getEnergyRouteTypeEnumTitle = (routeType: SupportedEnergySources, t: typeof i18next.t) => {
  switch (routeType) {
    case energy_source_type_enum.ELECTRICITY_GREEN:
      return t('ActionPlanning_NewEnergySystemRoute_ElectricityGreen');

    case energy_source_type_enum.BIO_GAS:
      return t('ActionPlanning_NewEnergySystemRoute_BioGas');

    case energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE:
      return t('ActionPlanning_NewEnergySystemRoute_DistrictHeatingPlantsRenewable');

    case energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE:
      return t('ActionPlanning_NewEnergySystemRoute_DistrictHeatingCHPRenewable');

    default:
      const exhaustiveCheck = routeType;
      throw new Error(`Unhandled energy_source_type_enum ${routeType}: ${exhaustiveCheck}`);
  }
};

export default function EnergyRouteAction({ action, previousActionBuildingModel }: ActionProps) {
  const { t } = useTranslation();

  const energyRouteData = action.renovations_energy_routes[0].new_energy_system_route;

  //@ts-ignore
  const { energy_source_type_id: routeType } = energyRouteData;
  const infoTableData = createEnergyInfoTable(routeType, previousActionBuildingModel, t);
  const buildingId = previousActionBuildingModel.building!.id;

  const defaultValues = {
    action: ENERGY_ROUTE_TYPE,
    energy_source_type: routeType,
    implementation_to: action.implementation_to,
  };

  const formMethods = useForm<EnergyRouteActionForm>({
    defaultValues: defaultValues,
  });

  return (
    <FormProvider methods={formMethods}>
      <Box my={3}>
        <RHFSelect name="action" label={t('General_Category')} size={'small'} sx={{ ...SelectSx, mb: 3 }} disabled>
          <MenuItem value={ENERGY_ROUTE_TYPE}>
            <ListItemIcon style={{ minWidth: 24 }}>
              <Box component="img" src={getActionIcon(ENERGY_ROUTE_TYPE)} />
            </ListItemIcon>
            <StyledListItemText action={translateRenovationTypeEnum(ENERGY_ROUTE_TYPE)} />
          </MenuItem>
        </RHFSelect>

        <RHFSelect
          name="energy_source_type"
          label={t('General_EnergySource')}
          size={'small'}
          sx={{ ...SelectSx, mb: 3 }}
          disabled
        >
          <MenuItem value={routeType as SupportedEnergySources}>
            <ListItemIcon style={{ minWidth: '16px' }}>
              <Box component="img" src={getActionIcon(routeType)} />
            </ListItemIcon>
            <StyledListItemText action={getEnergyRouteTypeEnumTitle(routeType as SupportedEnergySources, t)} />
          </MenuItem>
        </RHFSelect>

        {infoTableData && (
          <Box mb={3}>
            <InfoTable
              buildingId={buildingId}
              showTab={BuildingTabEnum.technology}
              data={infoTableData}
              title={t('General_StateBeforeRenovation')}
            />
          </Box>
        )}

        <RHFDatePicker
          label={t('General_PlannedFor')}
          name="implementation_to"
          size={'small'}
          sx={NumberInputSx}
          disabled
        />
      </Box>
    </FormProvider>
  );
}
