import { useTheme } from '@mui/material/styles';
import { pathValuesWithYearFromYear } from '@predium/client-lookup';
import { useTranslation } from 'react-i18next';
import {
  LegendKeys,
  PathGraph,
  PathGraphLegend,
  PathGraphSeries,
} from '../../../../components/data-visialization/PathGraph';
import { createGraphExportFileName } from '../../../../utils/createGraphExportFileName';
import { determineStartYear } from '../../../Scenarios/ActionPlan/ActionPlan.utils';
import { esgPathGraphOptions } from '../EsgPathGraph';

type Props = {
  energyPath: number[];
  energyTargets: {
    onePointFive: number[];
  };
  buildingLegendName: string;
  constructionYear: number;
};

export function EnergyPathGraph({ energyPath, energyTargets, buildingLegendName, constructionYear }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const startYear: number = determineStartYear(constructionYear);

  const energyDataSeries: [number, number][] = pathValuesWithYearFromYear(energyPath, startYear);
  const targetSeries: [number, number][] = pathValuesWithYearFromYear(energyTargets.onePointFive, startYear);

  const energySeries: PathGraphSeries[] = [
    {
      data: energyDataSeries,
      color: theme.palette.common.black,
      name: buildingLegendName,
    },
    {
      data: targetSeries,
      color: theme.palette.energyEfficiencyClassColors.AAA.light,
      name: t('General_1_5CTargetPath'),
    },
  ];

  const unit = 'kWh/m²a';

  const legends: { key: LegendKeys; color: string; text: string }[] = [
    { key: 'object', color: theme.palette.common.black, text: t('General_Object') },
    {
      key: '1_5CTargetPath',
      color: theme.palette.energyEfficiencyClassColors.AAA.light,
      text: t('General_1_5CTargetPath'),
    },
  ];

  const dataToExport = {
    actualPath: energyDataSeries,
    targetPath: targetSeries,
  };

  return (
    <>
      <PathGraph
        fileName={createGraphExportFileName(t('General_EnergyPath'))}
        xAxisLabel={t('General_EnergyConsumption') + ` (${unit})`}
        series={energySeries}
        options={esgPathGraphOptions}
        unit={unit}
        dataToExport={dataToExport}
      />
      <PathGraphLegend legends={legends} />
    </>
  );
}
