import { useMutation } from '@apollo/client';
import { MenuItem, Typography } from '@mui/material';
import { DataCollectionEmissionCertificateFragment } from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../assets/icons';
import Iconify from '../../../components/Iconify';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import TableMoreMenu from '../../../components/table/TableMoreMenu';
import { DELETE_EMISSION_CERTIFICATE } from '../../../graphql/DataCollection.mutations';
import { GET_EMISSION_CERTIFICATES } from '../../../graphql/DataCollection.queries';
import useSessionData from '../../../hooks/useSessionData';
import EmissionCertificateDeleteModal from './EmissionCertificateDeleteModal';

type Props = {
  openEditModal: () => void;
  emissionCertificate: DataCollectionEmissionCertificateFragment;
  linkedBuildings: number;
};

export default function EmissionCertificateActionMenu({ emissionCertificate, linkedBuildings, openEditModal }: Props) {
  const { t } = useTranslation();
  const { isAdmin } = useSessionData();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteEmissionCertificate] = useMutation(DELETE_EMISSION_CERTIFICATE, {
    variables: {
      id: emissionCertificate.id,
    },
    onCompleted: () => {
      enqueueSnackbar(t('DataCollection_EmissionCertificateDelete-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
    refetchQueries: [GET_EMISSION_CERTIFICATES],
  });

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleOpenDeleteModal = () => {
    if (linkedBuildings > 0) {
      setDeleteModalOpen(true);
    } else {
      deleteEmissionCertificate();
    }
  };

  return (
    <>
      <TableMoreMenu
        closeOnClick
        actions={
          <>
            <MenuItem onClick={openEditModal}>
              <Iconify icon={'fa-regular:edit'} />
              {t('General_Edit')}
            </MenuItem>
            {!isAdmin || (
              <MenuItem onClick={handleOpenDeleteModal}>
                <Iconify icon={ICONS.TRASH} color="error.main" width={16} height={16} />
                <Typography variant="body2" color="error.main">
                  {t('General_Delete')}
                </Typography>
              </MenuItem>
            )}
          </>
        }
      />
      <EmissionCertificateDeleteModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        emissionCertificate={emissionCertificate}
      />
    </>
  );
}
