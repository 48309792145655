import { Stack, Typography, alpha, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { PropsWithChildren, ReactNode, useState } from 'react';
import {
  DoorFormValuesProps,
  OtherFormValuesProps,
  PitchedRoofFormValuesProps,
  WindowFormValuesProps,
} from '../../BuildingEnvelope';
import { EnvelopeUnitHead } from './EnvelopeUnitHead';

type Props = {
  title: string;
  icon: string;
  index: number;
  handleCopy: (index: number) => void;
  handleRemove: (index: number) => void;
  data: OtherFormValuesProps | WindowFormValuesProps | DoorFormValuesProps | PitchedRoofFormValuesProps;
};

export function EnvelopeUnitWrapper({
  title,
  icon,
  children,
  index,
  handleCopy,
  handleRemove,
  data,
}: PropsWithChildren<Props>) {
  const theme = useTheme();

  const isCreating = data?.create;
  const [newlyAdded, setNewlyAdded] = useState(isCreating);

  const handleMouseEnter = () => {
    if (isCreating) {
      setNewlyAdded(false);
    }
  };

  return (
    <Box
      sx={{
        ml: 2,
        mb: 3,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 2,
        background: newlyAdded ? alpha(theme.palette.success.main, 0.12) : theme.palette.common.white,
        ':last-child': {
          mb: 0,
        },
        width: '100%',
        transition: 'background 0.3s ease',
        p: 3,
      }}
      onMouseEnter={handleMouseEnter}
    >
      <EnvelopeUnitHead
        title={title}
        icon={icon}
        data={data}
        index={index}
        handleCopy={handleCopy}
        handleRemove={handleRemove}
      />

      {!data?.delete && (
        <Grid container alignItems={'flex-start'}>
          {children}
        </Grid>
      )}
    </Box>
  );
}

export function FieldSectionTitle({ title, action }: { title: string; action?: ReactNode }) {
  const theme = useTheme();

  return (
    <Stack sx={{ height: 30, mb: 2 }} direction={'row'} alignItems={'center'}>
      <Typography
        variant="body2"
        textTransform="uppercase"
        color={theme.palette.text.secondary}
        fontWeight={700}
        mr={1}
      >
        {title}
      </Typography>
      {action}
    </Stack>
  );
}
