import { Box, CardProps, Stack, useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { fShortenNumber } from '@predium/utils';
import { ReactNode } from 'react';
import OverflowText from './OverflowText';
import WidgetSummaryLabel from './data-visialization/WidgetSummaryLabel';

interface Props extends CardProps {
  title: string;
  value: number;
  change: number | null;
  /**
   * Hide the change label if the change is 0
   */
  hideNoChange?: boolean;
  unit: string;
  totalArea: number;
  showTotal: boolean;
  titleEndAdornment?: ReactNode;
  changeEndAdornment?: ReactNode;
}

export default function SimpleComparisonMetric({
  title,
  value,
  change,
  hideNoChange,
  unit,
  totalArea,
  showTotal,
  titleEndAdornment,
  changeEndAdornment,
  sx,
}: Props) {
  const theme = useTheme();
  const hideChangeLabel = (change === 0 || !change || !isFinite(change) || isNaN(change)) && hideNoChange;
  return (
    <Card sx={{ p: 2, height: '100%', ...sx }}>
      <Stack direction="column" sx={{ height: '100%' }}>
        <Stack direction="row" alignItems="center" flexWrap="wrap">
          <Typography variant="subtitle2" sx={{ padding: '2px 0', marginRight: 'auto' }}>
            {title}
          </Typography>
          {titleEndAdornment}
        </Stack>
        <Stack direction="row" alignItems="baseline" whiteSpace={'nowrap'}>
          <Typography variant="h3">{fShortenNumber(showTotal ? value * totalArea : value)}</Typography>
          <Typography variant="subtitle2" color={theme.palette.grey[500]}>
            &nbsp;{unit}
            {showTotal ? '/a' : '/m²a'}
          </Typography>
        </Stack>
        {!hideChangeLabel && (
          <Stack direction="row" spacing={0.5} sx={{ whiteSpace: 'nowrap' }}>
            <Box>
              <WidgetSummaryLabel percent={Math.round((change ?? 0) * 100)} />
            </Box>
            {changeEndAdornment && (
              <OverflowText variant="caption" maxWidth="80%" color="text.secondary" text={changeEndAdornment} />
            )}
          </Stack>
        )}
      </Stack>
    </Card>
  );
}
