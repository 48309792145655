import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { DataCollectionSubBuildingSubBuildingSubsidyFragment } from '@predium/client-graphql';
import { subsidy_region_type_enum } from '@predium/enums';
import { translateSubsidyRegionTypeEnum } from '@predium/i18n/client';
import { useFormContext } from 'react-hook-form';
import Iconify from '../../../../../../../components/Iconify';
import { SemanticButton } from '../../../../../../../components/Tile';
import { AddSubsidyFormValueProps } from '../AddSubsidyModal';
import { SubsidyData } from './SubsidyList';
import { SubsidyTypeBadge } from './SubsidyTypeBadge';

type SubsidyListAsCheckboxProps = {
  data: SubsidyData;
  onSelect: (subsidyId: number) => void;
};

export function SubsidyListWithCheckboxes(props: SubsidyListAsCheckboxProps) {
  const { data } = props;

  return (
    <Box sx={{ mt: 2, pr: 2 }}>
      {Object.keys(data).map((key) => {
        const dataProperty = key as keyof SubsidyData;

        return (
          <Accordion
            key={key}
            style={{ boxShadow: 'none' }}
            sx={{
              '&:before': {
                backgroundColor: 'transparent',
              },
              '.MuiAccordionSummary-content.Mui-expanded': { minHeight: 0, m: 0 },
              '.MuiAccordionSummary-root': {
                px: 0,
              },
            }}
          >
            <AccordionSummary
              sx={{
                '&.Mui-expanded': { minHeight: 0, m: 0 },
                '.MuiAccordionSummary-content': {
                  p: 0,
                },
              }}
              expandIcon={<Iconify icon="ic:outline-arrow-forward-ios" style={{ transform: 'rotate(90deg' }} />}
            >
              <Stack direction="row">
                <Typography variant="subtitle1" fontWeight={700}>
                  {translateSubsidyRegionTypeEnum(key as subsidy_region_type_enum)} {`(${data[dataProperty].length})`}
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ pb: 0, px: 0 }}>
              {data[dataProperty].map((item) => {
                return (
                  <Box sx={{ py: 1 }} key={item.id}>
                    <SubsidyListItemCheckbox item={item} onSelect={props.onSelect} />
                  </Box>
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}

type Props = {
  item: DataCollectionSubBuildingSubBuildingSubsidyFragment;
  onSelect: (subsidyId: number) => void;
};

function SubsidyListItemCheckbox({ item, onSelect }: Props) {
  const theme = useTheme();
  const methods = useFormContext<AddSubsidyFormValueProps>();

  const isSelected = methods.watch('selectedSubsidies').some((subsidy) => subsidy.subsidyId === item.id);

  const onSelectHandler = () => {
    onSelect(item.id);
  };

  return (
    <SemanticButton
      sx={{
        width: '100%',
        border: `1px solid ${theme.palette.grey[500_24]}`,
        borderRadius: '8px',
        '&:focus': {
          borderColor: theme.palette.grey[500],
        },
      }}
      type="button"
      onClick={onSelectHandler}
    >
      <Stack
        spacing={4}
        direction={'row'}
        sx={{
          borderRadius: 'inherit',
          px: 3,
          py: 4,
          ':hover': {
            backgroundColor: theme.palette.grey[500_24],
          },
          textAlign: 'left',
          // better ux because the whole card is clickable
          userSelect: 'none',
          // to match the shadow from the <Card /> component next to it
          boxShadow: '0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)',
        }}
      >
        <Checkbox
          tabIndex={-1}
          checked={isSelected}
          sx={{
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        />
        <Stack spacing={1}>
          <Typography variant={'subtitle1'} fontWeight={700}>
            {item.title}
          </Typography>
          <Stack direction={'row'} alignItems={'center'}>
            <SubsidyTypeBadge size="medium" type={item.type} />
            <Typography>{item.amount_description}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </SemanticButton>
  );
}
