/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, CardHeader, Chip, InputAdornment, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { data_source_type_enum, energy_system_type_enum } from '@predium/enums';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Iconify from '../../../../../../components/Iconify';
import ActionMoreMenu from '../../../../../../components/common/ActionMoreMenu';
import RHFNumberField from '../../../../../../components/hook-form/RHFNumberField';
import { System, SystemRoute } from '../../../BuildingTechnology';
import { FieldIcon, IconState } from '../../../Common/FieldIcon';
import { generateSystemFieldNames, generateSystemRouteFieldNames } from '../../../Common/building.util';
import useBuilding from '../../../Context/useBuilding';

interface Props {
  totalRoutes: number;
  index: number;
  energySystemRoute: SystemRoute;
  systemIndex: number;
}

const TechnologyRouteHead = ({ totalRoutes, index, energySystemRoute, systemIndex }: Props) => {
  const REQUIRED_MESSAGE = t('General_Required');
  const FINAL_ENERGY_MIN_RANGE_MESSAGE = t('DataCollection_TechnologyEditForm_FinalEnergyMinMessage');
  const FINAL_ENERGY_MAX_RANGE_MESSAGE = t('DataCollection_TechnologyEditForm_FinalEnergyMaxMessage');

  const ref = useRef(null);
  const [isClicked, setIsClicked] = useState(false);
  const { getFieldState, getValues, setValue, resetField } = useFormContext();
  const { hasEditAccess } = useBuilding();

  const { energySystemType, energyFinal, energyRouteId, routeDeleted } = energySystemRoute;

  const { energyFinalFieldname, energyRouteDeletedFieldname } = generateSystemRouteFieldNames(
    //@ts-ignore
    energySystemType,
    systemIndex,
    index,
  );

  const isDirty = getFieldState(energyFinalFieldname).isDirty;
  const error = getFieldState(energyFinalFieldname).error;
  const value = getValues(energyFinalFieldname) ?? energyFinal;

  useEffect(() => {
    if (isDirty) {
      //@ts-ignore
      const { dataSourceTypeFieldname } = generateSystemFieldNames(energySystemType, systemIndex);

      setValue(dataSourceTypeFieldname, data_source_type_enum.MANUAL, {
        shouldDirty: true,
      });
    }
  }, [energySystemType, isDirty, setValue, systemIndex]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      //@ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        setIsClicked(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const checkIfLastRoute = () => {
    if (energySystemType !== energy_system_type_enum.HEATING) {
      return false;
    }

    const allHeatingSystems = getValues()[energySystemType];
    const allRoutes = allHeatingSystems.flatMap((system: System) => system.routes);
    const notDeletedRoutes = allRoutes && allRoutes.filter((route: SystemRoute) => !route.routeDeleted);

    return notDeletedRoutes && notDeletedRoutes.length === 1 && notDeletedRoutes[0].energyRouteId === energyRouteId;
  };

  let deleteNotAllowed = !hasEditAccess || checkIfLastRoute();

  const deleteMenuItem = (
    <MenuItem
      onClick={() => {
        setValue(energyRouteDeletedFieldname, true, {
          shouldDirty: true,
        });
        resetField(energyFinalFieldname);
      }}
      disabled={deleteNotAllowed}
    >
      <Iconify icon="mdi:trash-can-outline" width={16} height={16} style={{ marginRight: 1 }} />
      {t('General_Delete')}
    </MenuItem>
  );

  return (
    <CardHeader
      sx={{ mb: 2, p: 0, pl: 1 }}
      title={
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Stack direction={'row'} alignItems={'center'}>
            <Box>
              <Typography variant="h6" style={{ textDecoration: routeDeleted ? 'line-through' : 'none' }}>
                {t('DataCollection_Technology_EnergyRouteTitle', {
                  index: totalRoutes === 1 ? '' : index + 1,
                })}
              </Typography>
            </Box>

            <Box
              sx={{
                '.MuiFormHelperText-root': {
                  display: 'none',
                },
              }}
            >
              {isClicked || error || energyRouteId < 0 ? (
                <Stack direction={'row'} alignItems={'center'}>
                  <Box ref={ref}>
                    <RHFNumberField
                      size="small"
                      label={t('General_FinalEnergy')}
                      name={energyFinalFieldname}
                      sx={{ ml: 2, maxWidth: 200 }}
                      onBlur={() => {
                        !error && setIsClicked(false);
                      }}
                      onChange={(event) => {
                        const value = event.target.value;
                        setValue(energyFinalFieldname, value ? parseFloat(value) : 0, {
                          shouldValidate: true,
                        });
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{'kWh/m²a'}</InputAdornment>,
                        startAdornment: isDirty ? (
                          <Box mt={1}>
                            <FieldIcon state={IconState.ManualEdit} />
                          </Box>
                        ) : null,
                        autoComplete: 'off',
                      }}
                    />
                  </Box>
                  <Box ml={1}>
                    <Typography variant="caption" color={'error'}>
                      {error && error.type === 'required' && REQUIRED_MESSAGE}
                      {error && error.type === 'min' && FINAL_ENERGY_MIN_RANGE_MESSAGE}
                      {error && error.type === 'max' && FINAL_ENERGY_MAX_RANGE_MESSAGE}
                    </Typography>
                  </Box>
                </Stack>
              ) : (
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  onClick={() => {
                    if (!routeDeleted && hasEditAccess) {
                      setIsClicked(true);
                    }
                  }}
                  sx={{ ml: 2, mt: 0.25 }}
                >
                  <Stack direction={'row'} alignItems={'center'}>
                    {isDirty && (
                      <Box mt={0.5}>
                        <FieldIcon state={IconState.ManualEdit} />
                      </Box>
                    )}
                    <Box
                      sx={{
                        cursor: routeDeleted || !hasEditAccess ? 'default' : 'pointer',
                        zIndex: 1,
                        textDecoration: routeDeleted ? 'line-through' : 'none',
                      }}
                    >
                      <Tooltip title={t('DataCollection_Technology_RouteEnergyFinalTooltip')}>
                        <Typography fontWeight={400}>{value && value + ' kWh/m²a'}</Typography>
                      </Tooltip>
                    </Box>
                    {routeDeleted && (
                      <Chip
                        label={t('General_Deleted')}
                        color="error"
                        variant="soft"
                        size="small"
                        icon={<Iconify icon="mdi:trash-can-outline" color="error" />}
                        sx={{ ml: 2, fontWeight: 400, px: 1 }}
                      />
                    )}
                  </Stack>
                </Stack>
              )}
            </Box>
          </Stack>

          {energyRouteId > 0 && !routeDeleted && hasEditAccess && (
            <ActionMoreMenu
              variant="icon"
              actions={
                <>
                  {deleteNotAllowed ? (
                    <Tooltip title={t('DataCollection_Technology-LastRouteDeleteMessage')}>
                      <Box component={'span'}>{deleteMenuItem}</Box>
                    </Tooltip>
                  ) : (
                    deleteMenuItem
                  )}
                </>
              }
            />
          )}
        </Stack>
      }
    />
  );
};

export default TechnologyRouteHead;
