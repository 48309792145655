import {
  energy_consumer_technology_type_enum,
  energy_distribution_type_enum,
  energy_source_type_enum,
  energy_storage_type_enum,
  energy_system_type_enum,
} from '@predium/enums';
import intersection from 'lodash/intersection';
import { DistributionTypesForSystemType } from './DistributionTypesForSystemType';
import { SourceTypesForSystemType } from './SourceTypesForSystemType';
import { SourceTypesForTechnologyType } from './SourceTypesForTechnologyType';
import { StorageTypesForSystemType } from './StorageTypesForSystemType';
import { TechnologyTypesForSystemType } from './TechnologyTypesForSystemType';

//
// Getters on the lookups
//

/**
 * Returns a list of supported source types for a given system type
 *
 * @export
 * @param {energy_system_type_enum} systemType
 * @return {*}  {energy_source_type_enum[]}
 */
export function getSupportedSourceTypesForSystemType(systemType?: energy_system_type_enum): energy_source_type_enum[] {
  if (!systemType) return [];
  return SourceTypesForSystemType[systemType];
}

/**
 * Returns a list of supported technology types for a given system type
 *
 * @export
 * @param {energy_consumer_technology_type_enum} technologyType
 * @return {*}  {energy_source_type_enum[]}
 */
export function getSupportedSourceTypesForTechnologyType(
  technologyType: energy_consumer_technology_type_enum,
): energy_source_type_enum[] {
  return SourceTypesForTechnologyType[technologyType];
}

/**
 * Returns a list of supported technology types for a given system type
 *
 * @export
 * @param {energy_system_type_enum} systemType
 * @return {*}  {energy_consumer_technology_type_enum[]}
 */
export function getSupportedTechnologyTypesForSystemType(
  systemType?: energy_system_type_enum,
): energy_consumer_technology_type_enum[] {
  if (!systemType) return [];
  return TechnologyTypesForSystemType[systemType];
}

/**
 * Returns a list of supported distribution types for a given system type
 *
 * @export
 * @param {energy_system_type_enum} systemType
 * @return {*}  {energy_distribution_type_enum[]}
 */
export function getSupportedDistributionTypesForSystemType(
  systemType?: energy_system_type_enum,
): energy_distribution_type_enum[] {
  if (!systemType) return [];
  return DistributionTypesForSystemType[systemType];
}

/**
 * Returns a list of supported storage types for a given system type
 *
 * @export
 * @param {energy_system_type_enum} systemType
 * @return {*}  {energy_storage_type_enum[]}
 */
export function getSupportedStorageTypesForSystemType(
  systemType?: energy_system_type_enum,
): energy_storage_type_enum[] {
  if (!systemType) return [];
  return StorageTypesForSystemType[systemType];
}

/**
 * Returns a list of supported source types for a given technology type
 *
 * @export
 * @param {energy_source_type_enum} sourceType
 * @return {*}  {energy_consumer_technology_type_enum[]}
 */
export function getSupportedTechnologyTypesForSourceType(
  sourceType: energy_source_type_enum,
): energy_consumer_technology_type_enum[] {
  return Object.keys(SourceTypesForTechnologyType)
    .map((technologyType) => {
      const enumTechnologyType = technologyType as energy_consumer_technology_type_enum;

      const supportedSourceTypesForTechnologyType = SourceTypesForTechnologyType[enumTechnologyType];
      if (supportedSourceTypesForTechnologyType.includes(sourceType)) {
        return [enumTechnologyType];
      }
      return [];
    })
    .flat();
}

//
// Combined getters on the lookups
//

/**
 * Returns a list of supported source types for a given system type
 *
 * @export
 * @param {energy_system_type_enum} systemType
 * @param {energy_consumer_technology_type_enum} technologyType
 * @return {*}  {energy_source_type_enum[]}
 */
export function getSupportedSourceTypesForSystemAndTechnologyType(
  systemType: energy_system_type_enum,
  technologyType: energy_consumer_technology_type_enum,
): energy_source_type_enum[] {
  const supportedSourceTypesForSystemType = SourceTypesForSystemType[systemType] ?? [];
  const supportedSourceTypesForTechnologyType = SourceTypesForTechnologyType[technologyType] ?? [];

  return intersection(supportedSourceTypesForSystemType, supportedSourceTypesForTechnologyType);
}

/**
 * Returns a list of supported technology types for a given source type
 *
 * @export
 * @param {energy_system_type_enum} systemType
 * @param {energy_source_type_enum} sourceType
 * @return {*}  {energy_consumer_technology_type_enum[]}
 */
export function getSupportedTechnologyTypesForSystemAndSourceType(
  systemType: energy_system_type_enum,
  sourceType: energy_source_type_enum,
): energy_consumer_technology_type_enum[] {
  const supportedTechnologyTypesForSystemType = TechnologyTypesForSystemType[systemType] ?? [];
  const supportedTechnologyTypesForSourceType = getSupportedTechnologyTypesForSourceType(sourceType) ?? [];
  return intersection(supportedTechnologyTypesForSystemType, supportedTechnologyTypesForSourceType);
}
