/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Stack, Tooltip } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { PortfolioManagementPortfolioFragment } from '@predium/client-graphql';
import { translateEmissionFactorTypeEnum } from '@predium/i18n/client';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Label from '../../../components/Label';
import TableMoreMenu from '../../../components/table/TableMoreMenu';
import TableRowWithHighlight, { hasRecentlyChanged } from '../../../components/table/TableRowWithHighlight';
import useOrgPreferences from '../../../hooks/useOrgPreferences';
import { useLanguage } from '../../../provider/LanguageProvider';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '../../../utils/formatTime';

type Props = {
  row: PortfolioManagementPortfolioFragment;
  actions: ReactNode;
};

export default function PortfolioTableRow({ actions, row }: Props) {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { orgEmissionFactorType } = useOrgPreferences();

  const { name, emission_factor_type_id, created_at } = row;

  return (
    <TableRowWithHighlight
      sx={{ cursor: 'pointer' }}
      hover
      background={
        //@ts-ignore

        //@ts-ignore
        hasRecentlyChanged(row.created_at) ? 'success' : hasRecentlyChanged(row.updated_at) ? 'warning' : 'default'
      }
    >
      <TableCell>{name}</TableCell>
      <TableCell>
        {emission_factor_type_id ? (
          translateEmissionFactorTypeEnum(emission_factor_type_id)
        ) : (
          <Stack direction="row" alignItems={'center'}>
            {/*@ts-ignore */}
            {translateEmissionFactorTypeEnum(orgEmissionFactorType)}
            <Tooltip title={t('PortfolioTable_EmissionFactorTypeTitle')} arrow placement="top">
              <Label ml={1}>{t('General_Standard')}</Label>
            </Tooltip>
          </Stack>
        )}
      </TableCell>
      {/*@ts-ignore */}
      <TableCell>{formatDateToLocale(created_at, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language)}</TableCell>
      <TableCell align="right">
        <TableMoreMenu actions={actions} />
      </TableCell>
    </TableRowWithHighlight>
  );
}
