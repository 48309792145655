import { getLanguageLocale } from '@predium/i18n/client';
import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { language_enum } from '../../../../libs/generated/enums/src';
import i18n, { DEFAULT_LANGUAGE } from '../../i18n';

const LanguageContext = createContext<{
  language: language_enum;
  setLanguage: (lang: language_enum) => void;
}>({
  language: DEFAULT_LANGUAGE,
  setLanguage: () => {},
});

export const LanguageProvider = ({ children }: PropsWithChildren<{}>) => {
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE);

  useEffect(() => {
    const locale = getLanguageLocale(language);

    i18n.changeLanguage(locale);
    document.documentElement.lang = locale;
  }, [language]);

  return <LanguageContext.Provider value={{ language, setLanguage }}>{children}</LanguageContext.Provider>;
};

export const useLanguage = () => useContext(LanguageContext);
