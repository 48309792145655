/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Button, Divider, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { area_system_type_enum, area_type_enum, data_source_type_enum, sub_building_class_enum } from '@predium/enums';
import { translateSubBuildingClassEnum_dynamic, translateTypeOfUseEnum_dynamic } from '@predium/i18n/client';
import { getNetArea } from '@predium/utils';
import filter from 'lodash/filter';
import find from 'lodash/find';
import keyBy from 'lodash/keyBy';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommercialIcon from '../../../../../assets/Icons/CommercialIcon';
import { ICONS } from '../../../../../assets/icons';
import Iconify from '../../../../../components/Iconify';
import PreDialog, { PreDialogBody, PreDialogTitle } from '../../../../../components/presentations/PreDialog/PreDialog';
import SquareMeterRow, { EditableRowProps } from '../Components/SquareMeterRow';
import System from '../Components/System';
import { useRecalculate } from '../RecalculationProvider';
import NUFForm, { NUFFormSubmitValues } from '../forms/NUFForm';
import { useArea } from '../hooks';

const CLASS_ICONS = {
  [sub_building_class_enum.COMMERCIAL]: <CommercialIcon sx={{ color: 'grey.600' }} />,
  [sub_building_class_enum.RESIDENTIAL]: <Iconify color="grey.600" icon="mdi:home-outline" height={24} width={24} />,
};

const DINSystem = () => {
  const { getPropsForEditableArea, updateArea, fields, addArea, removeArea } = useArea();
  const { recalculate } = useRecalculate();

  const [addNUF, setAddNUF] = useState(false);
  const [editNUFId, setEditNUFId] = useState<string | null>(null);

  const { t } = useTranslation();

  const dinAreas = keyBy(fields, 'area_type_id');

  const nuf = fields.filter((area) => area.area_type_id === area_type_enum.NUF && !area.delete);
  const nufSum = getNetArea(nuf);

  const handleAddNUF = (values: NUFFormSubmitValues) => {
    setAddNUF(false);
    addArea(
      {
        ...values,
        area_system_id: area_system_type_enum.DIN227_DE,
        area_type_id: area_type_enum.NUF,
        name: area_type_enum.NUF,
        is_rented: false,
        description: '',
        parent_id: dinAreas.NRF.id,
        data_source_id: data_source_type_enum.MANUAL,
      },
      {
        skipValidation: true,
        recalculate: true,
      },
    );

    recalculate();
  };

  const handleEditNUF = (values: NUFFormSubmitValues) => {
    if (editNUFId === null) {
      throw new Error('editNUFId is null');
    }

    const currentNUF = find(nuf, { uid: editNUFId });

    if (!currentNUF) {
      throw new Error('currentNUF is null');
    }

    setEditNUFId(null);

    updateArea(editNUFId, values, { skipValidation: true, recalculate: true });

    // update all mfg1 that have the same class of use and type of use
    // to have the updated class of use and type of use
    const mfg1s = filter(fields, {
      area_type_id: area_type_enum.MFG_1,
      class_of_use_id: currentNUF.class_of_use_id,
      type_of_use_id: currentNUF.type_of_use_id,
    });

    mfg1s.forEach((mfg1) => {
      updateArea(
        mfg1.uid,
        {
          class_of_use_id: values.class_of_use_id,
          type_of_use_id: values.type_of_use_id,
        },
        { skipValidation: true },
      );
    });
  };

  const handleRemoveNUF = (areaUid: string) => () => {
    removeArea(areaUid, { skipValidation: true, recalculate: true });
  };

  const handleNUFClickAway: EditableRowProps['onClickAway'] = ({ hasChangedByUser }) => {
    if (hasChangedByUser) {
      recalculate();
    }
  };

  return (
    <>
      <System
        title={t('DataCollectionAreas_DINTitle')}
        subtitle={t('DataCollectionAreas_DINSubtitle')}
        areaTitle={t('DataCollectionAreas_UsableArea')}
        areaTitleSuffix="NUF"
        areaTooltip={t('DataCollectionAreas_UsableAreaTooltip')}
        addAreaLabel={t('DataCollectionAreas_AddUsableArea')}
        onAddAreaClick={() => setAddNUF(true)}
        areaSum={nufSum}
        listAreaType={area_type_enum.NUF}
        areas={
          <Stack spacing={1}>
            {nuf.map((area) => (
              <SquareMeterRow
                key={area.id}
                {...getPropsForEditableArea(area.uid)}
                hideEquationError
                onClickAway={handleNUFClickAway}
                icon={CLASS_ICONS[area.class_of_use_id]}
                labels={{
                  title: translateSubBuildingClassEnum_dynamic(area.class_of_use_id, t),
                  subtitle: translateTypeOfUseEnum_dynamic(area.type_of_use_id, t),
                }}
                titleProps={{ variant: 'subtitle2' }}
                actions={
                  <>
                    <MenuItem onClick={() => setEditNUFId(area.uid)}>
                      <Iconify icon={'fa-regular:edit'} />
                      {t('General_Edit')}
                    </MenuItem>
                    <Tooltip title={nuf.length === 1 ? t('DataCollectionAreas_LastItemDeleteTooltip') : ''}>
                      <Box component="span">
                        <MenuItem
                          sx={{ color: 'error.main' }}
                          disabled={nuf.length === 1}
                          onClick={handleRemoveNUF(area.uid)}
                        >
                          <Iconify icon={ICONS.TRASH} />
                          {t('General_Delete')}
                        </MenuItem>
                      </Box>
                    </Tooltip>
                  </>
                }
              />
            ))}
          </Stack>
        }
        summary={
          <Stack spacing={1}>
            <SquareMeterRow
              labels={{
                title: t('DataCollectionAreas_CirculationArea'),
                tooltip: t('DataCollectionAreas_CirculationAreaTooltip'),
                titleSuffix: dinAreas.VF.name,
              }}
              {...getPropsForEditableArea(dinAreas.VF.uid)}
            />
            <SquareMeterRow
              labels={{
                title: t('DataCollectionAreas_FunctionalArea'),
                tooltip: t('DataCollectionAreas_FunctionalAreaTooltip'),
                titleSuffix: dinAreas.TF.name,
              }}
              {...getPropsForEditableArea(dinAreas.TF.uid)}
            />
            <Divider />
            <SquareMeterRow
              labels={{
                title: t('DataCollectionAreas_NetRoomArea'),
                tooltip: t('DataCollectionAreas_NetRoomAreaTooltip'),
                titleSuffix: dinAreas.NRF.name,
              }}
              {...getPropsForEditableArea(dinAreas.NRF.uid)}
            />
            <SquareMeterRow
              labels={{
                title: t('DataCollectionAreas_ConstructionFloorArea'),
                tooltip: t('DataCollectionAreas_ConstructionFloorAreaTooltip'),
                titleSuffix: dinAreas.KGF.name,
              }}
              {...getPropsForEditableArea(dinAreas.KGF.uid)}
            />
          </Stack>
        }
      />
      <PreDialog open={addNUF} onClose={() => setAddNUF(false)} fullWidth type="definedByChildren">
        {addNUF && (
          <PreDialogBody
            dialogtitle={
              <PreDialogTitle icon="mdi:account-circle-outline" title={t('DataCollectionAreas_AddUsableAreaTitle')} />
            }
            content={<NUFForm onSubmit={handleAddNUF} />}
            actions={
              <>
                <Button variant="outlined" onClick={() => setAddNUF(false)}>
                  {t('General_Cancel')}
                </Button>
                <Button type="submit" variant="contained" form="nuf-form">
                  {t('General_Accept')}
                </Button>
              </>
            }
          />
        )}
      </PreDialog>
      <PreDialog open={Boolean(editNUFId)} onClose={() => setEditNUFId(null)} fullWidth type="definedByChildren">
        {editNUFId !== null && (
          <PreDialogBody
            dialogtitle={
              <PreDialogTitle icon="mdi:account-circle-outline" title={t('DataCollectionAreas_EditUsableAreaTitle')} />
            }
            content={
              <Stack spacing={2}>
                <Typography>{t('DataCollectionAreas_EditUsableAreaDescription')}</Typography>
                <NUFForm defaultValue={find(nuf, { uid: editNUFId })} onSubmit={handleEditNUF} />
              </Stack>
            }
            actions={
              <>
                <Button variant="outlined" onClick={() => setEditNUFId(null)}>
                  {t('General_Cancel')}
                </Button>
                <Button type="submit" variant="contained" form="nuf-form">
                  {t('General_Accept')}
                </Button>
              </>
            }
          />
        )}
      </PreDialog>
    </>
  );
};

export default DINSystem;
