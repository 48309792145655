import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

/**
 * Minimal@4 component.
 */
export default function TableSearchNotFound() {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell align="center" colSpan={9} sx={{ py: 10, typography: 'h6' }}>
        {t('General_NoDataAvailable')}
      </TableCell>
    </TableRow>
  );
}
