import { useTheme } from '@mui/material';
import { draft_status_enum } from '@predium/enums';
import { translateDraftStatusEnum } from '@predium/i18n/client';
import Label from './Label';

type Props = {
  status: draft_status_enum;
};

export default function DraftStatusLabel({ status }: Props) {
  const theme = useTheme();
  return (
    <Label
      variant={theme.palette.mode === 'light' ? 'soft' : 'filled'}
      color={
        (status === draft_status_enum.COMPLETE && 'success') ||
        ([
          draft_status_enum.UPLOADING,
          draft_status_enum.OCR_IN_PROGRESS,
          draft_status_enum.INCOMPLETE,
          draft_status_enum.OCR_REVIEW,
        ].includes(status) &&
          'warning') ||
        (status === draft_status_enum.OCR_FAILED && 'error') ||
        'default'
      }
      sx={{ textTransform: 'capitalize' }}
    >
      {translateDraftStatusEnum(status)}
    </Label>
  );
}
