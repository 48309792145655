export enum DataCollectionTabActionsEnum {
  CREATE_ENERGY_CERTIFICATE_DRAFT = 'CREATE_ENERGY_CERTIFICATE_DRAFT',
  CREATE_ECONOMIC_UNIT = 'CREATE_ECONOMIC_UNIT',
} // ----------------------------------------------------------------------

export enum DataCollectionTabEnum {
  BUILDINGS = 'BUILDINGS',
  ECONOMIC_UNITS = 'ECONOMIC_UNITS',
  ENERGY_CERTIFICATE_DRAFTS = 'ENERGY_CERTIFICATE_DRAFTS',
}
