/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Card, Grid, Typography } from '@mui/material';
import { EsgAnalysisBuildingGetBuildingQuery, EsgAnalysisGetBuildingFragment } from '@predium/client-graphql';
import { useTranslation } from 'react-i18next';
import InlineUser from '../../components/InlineUser';
import SubBuildingClassDistributionGraph from '../../components/data-visialization/esg-analysis/SubBuildingClassDistributionGraph';

type Props = {
  building: EsgAnalysisGetBuildingFragment;
  analyzedBuilding: EsgAnalysisBuildingGetBuildingQuery['getAnalyzedBuilding'];
};

export default function EsgAnalysisBuildingMixedSection({ building, analyzedBuilding }: Props) {
  const { t } = useTranslation();

  return (
    <Grid container item xs={6}>
      <Card sx={{ p: 3, pb: 1, width: '100%' }}>
        <Grid container spacing={3}>
          <Grid container item xs={6} spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12}>
              <Typography sx={{ pb: 1 }} variant="body2" color="text.secondary">
                {t('General_Portfolio')}
              </Typography>
              <Typography variant="subtitle1">{building.economic_unit.portfolio.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ pb: 1 }} variant="body2" color="text.secondary">
                {t('General_ConstructionYear')}
              </Typography>
              <Typography variant="subtitle1">{building.year_constructed}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ pb: 1 }} variant="body2" color="text.secondary">
                {t('General_ResponsibleUser')}
              </Typography>
              <InlineUser
                firstName={building.responsible_user.first_name}
                lastName={building.responsible_user.last_name}
                size={25}
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ pb: 1 }} variant="body2" color="text.secondary">
              {t('ESGAnalysisSection_AreaDistributionBySubBuildingClass')}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <SubBuildingClassDistributionGraph
                floorAreaBySubBuildingClass={analyzedBuilding.floorAreaBySubBuildingClass}
              />
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
