/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Card, CardHeader, Collapse } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { ModificationImpactsFragment } from '@predium/client-graphql';
import { fCurrencyTrimmed } from '@predium/utils';
import isFinite from 'lodash/isFinite';
import isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../../../components/Iconify';

function Row(props: { row: any }) {
  const { t } = useTranslation();
  const { row } = props;
  const [open, setOpen] = useState(true);
  const totalSaving = row.data.totalSaving;
  const costBefore = row.data.costBefore;
  const afterData = row.data.costAfter;

  useEffect(() => {
    setOpen(isFinite(costBefore) && isFinite(afterData));
  }, [costBefore, afterData]);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row">
          {row.title}
        </TableCell>
        <TableCell align="right">
          {isFinite(totalSaving) ? fCurrencyTrimmed(totalSaving, { unit: ' €/a' }) : '–'}
        </TableCell>
        <TableCell sx={{ width: '40px' }}>
          <>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              <Iconify
                icon="ic:outline-arrow-forward-ios"
                style={{
                  transform: `rotate(${open ? '270' : '90'}deg)`,
                  width: 14,
                  height: 12,
                }}
              />
            </IconButton>
          </>
        </TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell colSpan={3}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box>
                <Table
                  sx={{
                    '.MuiTableCell-root': {
                      pl: 2,
                    },
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ p: 'unset !important' }}>
                        <Table>
                          <TableBody>
                            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                              <TableCell component="th" scope="row">
                                {`${row.description} (${t('General_PreModificationShort')})`}
                              </TableCell>
                              <TableCell align="right">
                                {isFinite(costBefore) ? fCurrencyTrimmed(costBefore, { unit: ' €/a' }) : '–'}
                              </TableCell>
                              <TableCell sx={{ width: '40px' }}></TableCell>
                            </TableRow>
                            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                              <TableCell component="th" scope="row">
                                {`${row.description} (${t('General_PostModificationShort')})`}
                              </TableCell>
                              <TableCell align="right">
                                {isFinite(afterData) ? fCurrencyTrimmed(afterData, { unit: ' €/a' }) : '–'}
                              </TableCell>
                              <TableCell sx={{ width: '40px' }}></TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

type Props = {
  modificationImpact: ModificationImpactsFragment;
  title: string;
};

export default function ActionPricingTotalSaving({ title, modificationImpact }: Props) {
  const { t } = useTranslation();

  const { co2_cost_total, energy_cost_total } = modificationImpact;

  const data = [
    {
      id: 'ENERGY_SAVINGS',
      title: t('ActionPricingTotalSaving_AnnualEnergyCostSavings'),
      description: t('General_EnergyCost'),
      data: {
        totalSaving: !isNil(energy_cost_total?.impact) ? Math.abs(energy_cost_total.impact) : '–',
        costBefore: energy_cost_total.before,
        costAfter: energy_cost_total.after,
      },
    },
    {
      id: 'TAX_SAVINGS',
      title: t('ActionPricingTotalSaving_AnnualC02CostSavings'),
      description: t('General_CO2Cost'),
      data: {
        totalSaving: !isNil(co2_cost_total?.impact) ? Math.abs(co2_cost_total.impact) : '–',
        costBefore: co2_cost_total.before,
        costAfter: co2_cost_total.after,
      },
    },
  ];

  return (
    <Card sx={{ mb: 2, height: '100%' }} key={title}>
      <CardHeader titleTypographyProps={{ variant: 'h6' }} title={title} />
      <TableContainer component={Paper} sx={{ p: 2, minHeight: 80, pb: 0 }}>
        <Table
          sx={{
            '.MuiTableCell-root': {
              p: 'unset',
              pl: 1,
              pb: 1,
            },
          }}
        >
          <TableBody>
            {data.map((row) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
