import { Button, InputAdornment, MenuItem, Stack, TextField, Toolbar, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../components/Iconify';
import InputStyle from '../../../components/InputStyle';
import usePermissions from '../../../hooks/usePermissions';
import useSessionData from '../../../hooks/useSessionData';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

// ----------------------------------------------------------------------

type Props = {
  numSelected: number;
  filterName: string;
  onFilterName: (value: string) => void;
  onCreateScenario: VoidFunction;
  handlePortfolioChange: (portfolioId: number) => void;
  selectedPortfolioId: number;
  portfolioOptions: { key: number; label: string }[];
};

export default function ScenariosTableToolbar({
  numSelected,
  filterName,
  onFilterName,
  onCreateScenario,
  handlePortfolioChange,
  selectedPortfolioId,
  portfolioOptions,
}: Props) {
  const { t } = useTranslation();
  const { isAdmin } = useSessionData();
  const { portfolios: portfolioPermissions } = usePermissions();
  const theme = useTheme();

  const isLight = theme.palette.mode === 'light';
  const displayCreateButton = isAdmin || portfolioPermissions?.some((permission) => permission.read);

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: isLight ? 'primary.main' : 'text.primary',
          bgcolor: isLight ? 'primary.lighter' : 'primary.dark',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {t('ScenariosTableToolbar_BuildingsSelectedCount', { count: numSelected })}
        </Typography>
      ) : (
        <Stack direction="row">
          <TextField
            select
            label={t('General_Portfolio')}
            // size="small"
            value={selectedPortfolioId}
            onChange={(event) => {
              handlePortfolioChange(event.target.value as unknown as number);
            }}
            sx={{
              // margin: '24px',
              minWidth: 300,
              mr: 3,
            }}
          >
            {portfolioOptions.map((portfolio) => (
              <MenuItem
                key={portfolio.key}
                value={portfolio.key}
                sx={{ typography: 'body2', mx: 1, my: 0.5, borderRadius: 0.75 }}
              >
                {portfolio.label}
              </MenuItem>
            ))}
          </TextField>
          <InputStyle
            stretchStart={300}
            value={filterName}
            onChange={(event) => onFilterName(event.target.value)}
            placeholder={t('ScenariosTableToolbar_SearchPortfolioPlaceholder')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      )}

      {displayCreateButton && (
        <Button
          variant="contained"
          startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
          sx={{ py: 1.5 }}
          onClick={onCreateScenario}
        >
          {t('General_NewScenario')}
        </Button>
      )}
    </RootStyle>
  );
}
