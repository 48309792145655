import { createSvgIcon } from '@mui/material';
import palette from '../../../theme/palette';

export const IconConsumptionWater = createSvgIcon(
  <svg width="20" height="20" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 2.51367L7.5 3.07367C7.5 3.07367 6.64667 4.04034 5.78667 5.29367C4.92667 6.54701 4 8.047 4 9.487C4 10.5479 4.42143 11.5653 5.17157 12.3154C5.92172 13.0656 6.93913 13.487 8 13.487C9.06087 13.487 10.0783 13.0656 10.8284 12.3154C11.5786 11.5653 12 10.5479 12 9.487C12 8.047 11.0733 6.54701 10.2133 5.29367C9.35333 4.04034 8.5 3.07367 8.5 3.07367L8 2.51367ZM8 4.60034C8.29333 4.94701 8.56 5.23367 9.12 6.047C9.92667 7.22034 10.6667 8.71367 10.6667 9.487C10.6667 10.967 9.48 12.1537 8 12.1537C6.52 12.1537 5.33333 10.967 5.33333 9.487C5.33333 8.71367 6.07333 7.22034 6.88 6.047C7.44 5.23367 7.70667 4.94701 8 4.60034Z"
      fill="#637381"
    />
  </svg>,
  'IconConsumptionWater',
);

const IconConsumptionWater_Colored = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 2.51367L7.5 3.07367C7.5 3.07367 6.64667 4.04034 5.78667 5.29367C4.92667 6.54701 4 8.047 4 9.487C4 10.5479 4.42143 11.5653 5.17157 12.3154C5.92172 13.0656 6.93913 13.487 8 13.487C9.06087 13.487 10.0783 13.0656 10.8284 12.3154C11.5786 11.5653 12 10.5479 12 9.487C12 8.047 11.0733 6.54701 10.2133 5.29367C9.35333 4.04034 8.5 3.07367 8.5 3.07367L8 2.51367ZM8 4.60034C8.29333 4.94701 8.56 5.23367 9.12 6.047C9.92667 7.22034 10.6667 8.71367 10.6667 9.487C10.6667 10.967 9.48 12.1537 8 12.1537C6.52 12.1537 5.33333 10.967 5.33333 9.487C5.33333 8.71367 6.07333 7.22034 6.88 6.047C7.44 5.23367 7.70667 4.94701 8 4.60034Z"
      fill={palette.light.info.dark}
    />
  </svg>,
  'IconConsumptionWater',
);

export default IconConsumptionWater_Colored;
