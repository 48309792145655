import { Card, Grid, Typography, useTheme } from '@mui/material';
import { ActionPlanningActionPlanActionPlanFragment } from '@predium/client-graphql';
import { translateEnergySourceTypeEnum, translateTypeOfUseEnum_dynamic } from '@predium/i18n/client';
import {
  ensureDefined,
  getFirstStrandingDate,
  getNetArea,
  getNufTypesOfUse,
  getOnePointFiveTargetPath,
  primaryEnergySourceFromEnergySystems,
} from '@predium/utils';
import { t } from 'i18next';
import { ICONS } from '../../../../assets/icons';
import InlineUser from '../../../../components/InlineUser';
import { PermissionType } from '../../../../contexts/PermissionContext';
import usePermissions from '../../../../hooks/usePermissions';
import ActionPlanSectionTitle from '../Components/ActionPlanSectionTitle';

type Props = {
  actionPlan: ActionPlanningActionPlanActionPlanFragment;
};

const BuildingProfileStatusQuo = ({ actionPlan }: Props) => {
  const theme = useTheme();
  const { checkBuildingPermission } = usePermissions();

  const { building } = actionPlan;
  const hasActionPlanDeletePermission = building ? checkBuildingPermission(building.id, PermissionType.READ) : false;
  const activeBuildingModel = ensureDefined(building.active_building_model);
  const constructionYear = building.year_constructed;
  const targetPath = getOnePointFiveTargetPath(building.target_paths).map((target_path) => target_path.value);
  const typesOfUse = getNufTypesOfUse(building.areas);
  const strandingDate = getFirstStrandingDate({
    targetPath,
    path: activeBuildingModel.energy_paths.map((energy_path) => energy_path.co2_emissions),
    constructionYear,
  });
  const primaryEnergySourceType = primaryEnergySourceFromEnergySystems(
    activeBuildingModel.energy_systems.flatMap((system) => system.energy_system_consumer_routes),
  );
  const primaryEnergySource = translateEnergySourceTypeEnum(primaryEnergySourceType);

  return (
    <>
      <ActionPlanSectionTitle title={t('ActionPlanningActionPlan_BuildingProfile')} icon={ICONS.PAGE_SEARCH} />
      <Card sx={{ p: 3, mb: 3 }}>
        <Grid container>
          <Grid container item xs={hasActionPlanDeletePermission ? 11 : 12}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography sx={{ pb: 1 }} variant="body2" color="text.secondary">
                  {t('General_Portfolio')}
                </Typography>
                <Typography variant="subtitle1">{actionPlan.scenario.portfolio.name}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ pb: 1 }} variant="body2" color="text.secondary">
                  {t('General_CreatedBy')}
                </Typography>
                <InlineUser
                  firstName={actionPlan.scenario.owner.first_name}
                  lastName={actionPlan.scenario.owner.last_name}
                  size={25}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ pb: 1 }} variant="body2" color="text.secondary">
                  {t('ActionPlanningActionPlan_StrandingDateBeforeActions')}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color={
                    new Date(strandingDate).getFullYear() === new Date().getFullYear()
                      ? theme.palette.error.main
                      : theme.palette.primary.main
                  }
                >
                  {new Date(strandingDate).getFullYear()}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ pb: 1 }} variant="body2" color="text.secondary">
                  {t('General_UsableArea')}
                </Typography>
                <Typography variant="subtitle1">
                  {Math.floor(getNetArea(building.areas))} m<sup>2</sup>
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ mt: 3 }}>
              <Grid item xs={3}>
                <Typography sx={{ pb: 1 }} variant="body2" color="text.secondary">
                  {t('General_MonumentProtection')}
                </Typography>
                <Typography variant="subtitle1">
                  {building.monument_protection ? t('General_Yes') : t('General_No')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ pb: 1 }} variant="body2" color="text.secondary">
                  {t('General_TypeOfUse', { count: typesOfUse.length })}
                </Typography>
                <Typography variant="subtitle1">
                  {typesOfUse.map((type) => translateTypeOfUseEnum_dynamic(type, t)).join(', ')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ pb: 1 }} variant="body2" color="text.secondary">
                  {t('General_MainEnergySource')}
                </Typography>
                <Typography variant="subtitle1">{primaryEnergySource}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ pb: 1 }} variant="body2" color="text.secondary">
                  {t('General_ConstructionYear')}
                </Typography>
                <Typography variant="subtitle1">{constructionYear}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default BuildingProfileStatusQuo;
