import { useMutation } from '@apollo/client';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DeleteConfirmationModal, { AssociatedDataType } from '../../../components/DeleteConfirmationModal';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { DELETE_SCENARIO } from '../../../graphql/ActionPlanning.mutations';
import { PATHS } from '../../../routes';
import { ScenarioType } from './ScenarioActionMenu';

type Props = {
  deleteModalOpen: boolean;
  setDeleteModalOpen: (open: boolean) => void;
  scenario: ScenarioType;
};

const DeleteScenarioModal: React.FC<Props> = ({ deleteModalOpen, setDeleteModalOpen, scenario }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  //DELETE MUTATION
  const [deleteScenarioMutation, { loading: mutationLoading }] = useMutation(DELETE_SCENARIO, {
    update: (cache, { data }) => {
      //@ts-ignore
      if (data.delete_scenario_by_pk) {
        const normalizedId = cache.identify({
          //@ts-ignore
          id: data.delete_scenario_by_pk.id,

          //@ts-ignore
          __typename: data.delete_scenario_by_pk.__typename,
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
    onCompleted: () => {
      navigate(PATHS.actionPlanning.scenarios());
      enqueueSnackbar(t('ActionPlanningScenario_ScenarioWasDeleted'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
    onError: () => {
      enqueueSnackbar(t('ActionPlanningScenario_ScenarioCouldNotBeDeleted'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
  });

  const associatedDataForDeletion = new Map();
  scenario.totalActionPlans > 0 ??
    associatedDataForDeletion.set(AssociatedDataType.ACTION_PLAN, scenario.totalActionPlans);
  scenario.totalActions > 0 ?? associatedDataForDeletion.set(AssociatedDataType.ACTION, scenario.totalActions);

  return (
    <>
      <DeleteConfirmationModal
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        onDelete={() => {
          setDeleteModalOpen(false);
          deleteScenarioMutation({
            variables: {
              id: scenario.id,
            },
          });
        }}
        title={t('General_DeleteModalTitle-Scenario', { count: 1 })}
        description={
          <Trans
            i18nKey={'General_DeleteModalDescription-Scenario'}
            values={{
              name: scenario.name,
              count: 1,
              alongWithAssociatedData:
                associatedDataForDeletion.size > 0 ? t('General_DeleteWarningAssociatedDataText', { count: 1 }) : '',
            }}
            components={{ bold: <strong /> }}
          />
        }
        associatedData={associatedDataForDeletion}
        loading={mutationLoading}
      />
    </>
  );
};

export default DeleteScenarioModal;
