import { BoxProps } from '@mui/material';
import { Theme, alpha, styled, useTheme } from '@mui/material/styles';
import { efficiency_class_enum } from '@predium/enums';
import { translateEfficiencyClassEnum } from '@predium/i18n/client';

type LabelVariant = 'filled' | 'outlined' | 'ghost';

const RootStyle = styled('span')(
  ({
    theme,
    ownerState,
  }: {
    theme: Theme;
    ownerState: {
      efficiencyClass: efficiency_class_enum;
      variant: LabelVariant;
    };
  }) => {
    const isLight = theme.palette.mode === 'light';
    const { efficiencyClass, variant } = ownerState;
    const styleFilled = (color: efficiency_class_enum) => ({
      color: theme.palette.energyEfficiencyClassColors[color].darker,
      backgroundColor: theme.palette.energyEfficiencyClassColors[color].light,
    });

    const styleOutlined = (color: efficiency_class_enum) => ({
      color: theme.palette.energyEfficiencyClassColors[color].main,
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.energyEfficiencyClassColors[color].main}`,
    });

    const styleGhost = (color: efficiency_class_enum) => ({
      color: theme.palette.energyEfficiencyClassColors[color][isLight ? 'dark' : 'light'],
      backgroundColor: alpha(theme.palette.energyEfficiencyClassColors[color].main, 0.2),
    });

    return {
      height: 50,
      minWidth: 90,
      lineHeight: 0,
      borderRadius: 10,
      cursor: 'default',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      display: 'inline-flex',
      justifyContent: 'center',
      padding: theme.spacing(0, 1),
      color: theme.palette.grey[800],
      fontSize: theme.typography.pxToRem(25),
      fontFamily: theme.typography.fontFamily,
      backgroundColor: theme.palette.grey[300],
      fontWeight: theme.typography.fontWeightBold,

      ...(variant === 'filled' && { ...styleFilled(efficiencyClass) }),
      ...(variant === 'outlined' && { ...styleOutlined(efficiencyClass) }),
      ...(variant === 'ghost' && { ...styleGhost(efficiencyClass) }),
    };
  },
);

interface EfficiencyClassLabelProps extends BoxProps {
  efficiencyClass: efficiency_class_enum;
  variant?: LabelVariant;
  shortLabel?: boolean;
}

export default function EfficiencyClassLabelLarge({
  efficiencyClass,
  variant = 'filled',
  children,
  shortLabel = false,
  sx,
}: EfficiencyClassLabelProps) {
  const theme = useTheme();

  const efficiencyClassName =
    (shortLabel && efficiencyClass === efficiency_class_enum.UNKNOWN) ||
    efficiencyClass === efficiency_class_enum.NOT_APPLICABLE
      ? '-'
      : translateEfficiencyClassEnum(efficiencyClass);

  return (
    <RootStyle ownerState={{ efficiencyClass, variant }} sx={sx} theme={theme}>
      <div>
        {efficiencyClassName}
        {children}
      </div>
    </RootStyle>
  );
}
