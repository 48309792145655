import { draft_status_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateDraftStatusEnum = (enumValue: draft_status_enum) => {
  switch (enumValue) {
    case draft_status_enum.COMPLETE:
      return <Trans i18nKey="Enum_DraftStatusEnum-COMPLETE" />;
    case draft_status_enum.INCOMPLETE:
      return <Trans i18nKey="Enum_DraftStatusEnum-INCOMPLETE" />;
    case draft_status_enum.OCR_FAILED:
      return <Trans i18nKey="Enum_DraftStatusEnum-OCR_FAILED" />;
    case draft_status_enum.OCR_IN_PROGRESS:
      return <Trans i18nKey="Enum_DraftStatusEnum-OCR_IN_PROGRESS" />;
    case draft_status_enum.OCR_REVIEW:
      return <Trans i18nKey="Enum_DraftStatusEnum-OCR_REVIEW" />;
    case draft_status_enum.UPLOADING:
      return <Trans i18nKey="Enum_DraftStatusEnum-UPLOADING" />;
  }
};

export const translateDraftStatusEnum_dynamic = (
  enumValue: draft_status_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case draft_status_enum.COMPLETE:
      return t('Enum_DraftStatusEnum-COMPLETE');
    case draft_status_enum.INCOMPLETE:
      return t('Enum_DraftStatusEnum-INCOMPLETE');
    case draft_status_enum.OCR_FAILED:
      return t('Enum_DraftStatusEnum-OCR_FAILED');
    case draft_status_enum.OCR_IN_PROGRESS:
      return t('Enum_DraftStatusEnum-OCR_IN_PROGRESS');
    case draft_status_enum.OCR_REVIEW:
      return t('Enum_DraftStatusEnum-OCR_REVIEW');
    case draft_status_enum.UPLOADING:
      return t('Enum_DraftStatusEnum-UPLOADING');
  }
};
