/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useAuth0 } from '@auth0/auth0-react';
import { jwtDecode } from 'jwt-decode';
import { usePostHog } from 'posthog-js/react';
import { ReactNode, createContext, useEffect, useState } from 'react';

interface JWTClaims {
  'x-hasura-allowed-roles': string[];
  'x-hasura-default-role': string;
  'x-hasura-user-id': number;
}

interface initialStateType {
  accessToken: string | null;
  idTokenClaims: JWTClaims | null;
  tokenIsExpired: () => boolean;
  invalidateAccess: () => void;
}

const initialState: initialStateType = {
  accessToken: null,
  idTokenClaims: null,
  tokenIsExpired: () => true,
  invalidateAccess: () => {},
};

const AccessTokenContext = createContext(initialState);

type AccessTokenProviderProps = {
  children: ReactNode;
};

function AccessTokenProvider({ children }: AccessTokenProviderProps) {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [idTokenClaims, setIdTokenClaims] = useState<JWTClaims | null>(null);
  const { getAccessTokenSilently, isAuthenticated, getIdTokenClaims, logout } = useAuth0();
  const posthog = usePostHog();

  const tokenIsExpired = () => {
    //@ts-ignore
    const decodedToken = jwtDecode(accessToken);

    //@ts-ignore
    const isExpired = new Date(decodedToken.exp * 1000) < new Date();
    return isExpired;
  };

  const invalidateAccess = () => {
    setAccessToken(null);
    setIdTokenClaims(null);
    logout();
    if (posthog) {
      posthog.reset();
    }
  };
  //get the access token
  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const receivedAccessToken = await getAccessTokenSilently({
          audience: import.meta.env.VITE_AUTH0_AUDIENCE ?? '',
          scope: import.meta.env.VITE_AUTH0_SCOPE ?? '',
        });
        const claims = await getIdTokenClaims();
        claims && setIdTokenClaims(claims['https://hasura.io/jwt/claims']);
        setAccessToken(receivedAccessToken);
      } catch (e) {
        //@ts-ignore
        console.error(e.message);
      }
    };

    isAuthenticated && getAccessToken();
    !isAuthenticated && accessToken && setAccessToken(null);
  }, [getAccessTokenSilently, isAuthenticated, getIdTokenClaims, accessToken]);

  return (
    <AccessTokenContext.Provider
      value={{
        accessToken,
        idTokenClaims,
        tokenIsExpired,
        invalidateAccess,
      }}
    >
      {children}
    </AccessTokenContext.Provider>
  );
}

export { AccessTokenContext, AccessTokenProvider };
