/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Card, CardProps, useTheme } from '@mui/material';
import { efficiency_class_enum, getTaxBracketLandlordEnumTitle, tax_bracket_enum } from '@predium/enums';
import { translateEfficiencyClassEnum_dynamic } from '@predium/i18n/client';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import useEsgAnalysisFilters from '../../../hooks/useEsgAnalysisFilters';
import { EsgAnalysisFiltersKeys } from '../../../sections/EsgAnalysis/EsgAnalysisFilters/EsgAnalysisFilters';
import BaseOptionChart from '../../../theme/apexcharts';

type chartType = 'taxBracket' | 'efficiencyClass';

interface PortfolioCO2CostDistributionGraphProps extends CardProps {
  buildingsByTaxBracket: {
    count: number;
    taxBracket: {
      taxBracket?: tax_bracket_enum;
      landlordProportion?: number;
    };
  }[];
}

export function PortfolioCO2CostDistributionGraph({
  buildingsByTaxBracket,
  sx,
}: PortfolioCO2CostDistributionGraphProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const series = buildingsByTaxBracket.map((group) => group.count).reverse();
  const categories = buildingsByTaxBracket.map((group) => group.taxBracket.taxBracket).reverse();
  const colors = buildingsByTaxBracket

    //@ts-ignore
    .map((group) => theme.palette.taxBracketColors[group.taxBracket.taxBracket].light)
    .reverse();

  return (
    <PortfolioBuildingDistributionGraph
      title={t('General_DistributionByCO2CostShare')}
      series={series}
      //@ts-ignore
      categories={categories}
      colors={colors}
      sx={sx}
      type="taxBracket"
    />
  );
}

interface PortfolioEfficiencyClassDistributionGraphProps extends CardProps {
  buildingsByEfficiencyClass: {
    count: number;
    efficiencyClass: efficiency_class_enum;
  }[];
}

export function PortfolioEfficiencyClassDistributionGraph({
  buildingsByEfficiencyClass,
  sx,
}: PortfolioEfficiencyClassDistributionGraphProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  // Sum counts of NOT_APPLICABLE and UNKNOWN classes to UNKNOWN (this is a business requirement)
  const unknownGroup = buildingsByEfficiencyClass
    .filter(
      (group) =>
        group.efficiencyClass === efficiency_class_enum.NOT_APPLICABLE ||
        group.efficiencyClass === efficiency_class_enum.UNKNOWN,
    )
    .reduce((acc, group) => ({ count: acc.count + group.count, efficiencyClass: efficiency_class_enum.UNKNOWN }));

  // Remove all NOT_APPLICABLE and UNKNOWN classes
  const filteredBuildingsByEfficiencyClass = buildingsByEfficiencyClass.filter(
    (group) =>
      !(
        group.efficiencyClass === efficiency_class_enum.NOT_APPLICABLE ||
        group.efficiencyClass === efficiency_class_enum.UNKNOWN
      ),
  );

  // Add the new single UNKNOWN class
  filteredBuildingsByEfficiencyClass.push(unknownGroup);

  const series = filteredBuildingsByEfficiencyClass.map((group) => group.count);
  const categories = filteredBuildingsByEfficiencyClass.map((group) => efficiency_class_enum[group.efficiencyClass]);

  const colors = categories.map((category) => {
    const color = theme.palette.energyEfficiencyClassColors[category];
    if (color) {
      return color.light;
    }
    return theme.palette.grey[300];
  });

  return (
    <PortfolioBuildingDistributionGraph
      title={t('General_DistributionByEnergyEfficiencyClass')}
      series={series}
      categories={categories}
      colors={colors}
      sx={sx}
      type="efficiencyClass"
    />
  );
}

interface Props extends CardProps {
  title: string;
  series: number[];
  categories: string[];
  colors: string[];
  animated?: boolean;
  type: chartType;
}

function PortfolioBuildingDistributionGraph({ title, series, categories, type, colors, animated, sx }: Props) {
  const { t } = useTranslation();

  const { setFilter } = useEsgAnalysisFilters();

  function isEfficiencyClass(value: string): value is efficiency_class_enum {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = value;
    return type === 'efficiencyClass';
  }

  const chartState: ApexCharts.ApexOptions = {
    colors,
    chart: {
      animations: {
        enabled: !!animated,
      },
      type: 'bar',
      events: {
        dataPointSelection: (_, __, config) => {
          const value = config.w.config.xaxis.categories[config.dataPointIndex];

          if (isEfficiencyClass(value)) {
            setFilter(EsgAnalysisFiltersKeys.efficiencyClasses, value);
          } else {
            setFilter(EsgAnalysisFiltersKeys.taxBrackets, value);
          }
        },
        dataPointMouseEnter: function (event) {
          event.target.style.cursor = 'pointer';
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -18,
      formatter: function (val: number) {
        return val > 0 ? val : '';
      },
    },
    tooltip: {
      enabled: false,
    },
    title: {
      text: title,
    },
    xaxis: {
      categories,
      labels: {
        formatter: function (value: tax_bracket_enum | efficiency_class_enum) {
          if (isEfficiencyClass(value)) {
            return translateEfficiencyClassEnum_dynamic(value, t);
          } else {
            return getTaxBracketLandlordEnumTitle(value);
          }
        },
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        distributed: true,
        columnWidth: '50%',
        barHeight: '100%',
        borderRadius: 10,
        dataLabels: {
          position: 'top',
          orientation: 'horizontal',
        },
      },
    },
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'darken',
          value: 0.5,
        },
      },
    },
  };

  return (
    <Card sx={{ p: 3, pb: 1, ...sx }}>
      <ReactApexChart
        //@ts-ignore
        type={chartState.chart.type}
        options={merge(BaseOptionChart(), chartState)}
        series={[{ data: series }]}
        height={350}
      />
    </Card>
  );
}
