import { Link as RouterLink } from 'react-router-dom';
// @mui
import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { PATHS } from '../routes';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 160, height: 42, ...sx }}>
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2554.64 370.86">
        <path d="M654.72,26.39c86.25,0,135.75,42.5,135.75,116.77,0,77.2-49.5,122.16-135.75,122.16H575.83V368.41H518v-342ZM575.83,213.52h76.44c52.93,0,82.82-22.47,82.82-68.89,0-44.95-29.89-66.45-82.82-66.45H575.83Z" />
        <path d="M900.73,160.26c17.64-35.18,49-53.74,90.17-53.74v53.74c-52.44-2.93-85.76,27.86-90.17,72.32V368.41H844.37V108.47h56.36Z" />
        <path d="M1266.8,259.94h-197c8.82,40.07,39.7,65,80.86,65,28.43,0,54.4-10.75,73.51-30.3l29.89,31.76c-25.48,27.85-62.72,44-107.81,44-80.37,0-133.29-53.26-133.29-131.44,0-78.67,54.39-131.92,131.82-132.41C1236.41,106.52,1273.66,166.13,1266.8,259.94Zm-49-41c-2-42-29.89-67.43-72.53-67.43-41.16,0-70.08,25.9-76.45,67.43Z" />
        <path d="M1574.06,368.41H1517.7V324c-18.62,30.29-50,46.91-91.64,46.91-75,0-125-54.24-125-133.39,0-78.18,50.47-131.44,124-131.44,42.15,0,73.51,17.1,92.62,47.39V5.86h56.36Zm-56.36-129c0-50.33-32.83-85.51-79.88-85.51s-79.88,35.67-80.37,85.51c.49,49.35,32.84,84.53,80.37,84.53C1484.87,324,1517.7,288.77,1517.7,239.42Z" />
        <path d="M1720.58,34.2c0,19.55-14.21,33.72-32.83,33.72s-32.84-14.17-32.84-33.72c0-20,14.21-34.2,32.84-34.2S1720.58,14.17,1720.58,34.2Zm-60.77,334.21V108.47h55.87V368.41Z" />
        <path d="M2035.68,368.41h-56.35V317.6c-17.65,36.16-50.48,52.28-93.11,52.77-59.79,0-95.56-38.11-95.56-100.17V108.47H1847v146.1c0,39.09,23,63,60.77,63,45.08-1,71.55-35.67,71.55-81.11v-128h56.35Z" />
        <path d="M2554.65,206.68V368.41h-56.36V222.32c0-39.58-23.52-63.52-61.74-63.52-44.6,1.46-71.55,35.67-71.55,81.11v128.5h-56.36V222.32c0-39.58-23-63.52-61.25-63.52-45.09,1.46-72.53,35.67-72.53,81.11v128.5H2119V108.47h55.87V158.8c18.13-36.65,51.45-52.28,94.58-52.28,46.55,0,78.41,23,90.66,62.54,16.66-43.49,51.45-62.54,99-62.54C2518.88,106.52,2554.65,144.63,2554.65,206.68Z" />
        <path d="M172,370.86H0v-172c0-94.84,77.16-172,172-172H344v172C344,293.7,266.84,370.86,172,370.86Zm-114-58H172a114.13,114.13,0,0,0,114-114v-114H172a114.13,114.13,0,0,0-114,114Z" />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to={PATHS.root()}>{logo}</RouterLink>;
}
