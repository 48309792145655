import { energy_storage_type_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateEnergyStorageTypeEnum = (enumValue: energy_storage_type_enum) => {
  switch (enumValue) {
    case energy_storage_type_enum.BUFFER_STORAGE:
      return <Trans i18nKey="Enum_EnergyStorageTypeEnum-BUFFER_STORAGE" />;
    case energy_storage_type_enum.BUFFER_STORAGE_ELECTRIC:
      return <Trans i18nKey="Enum_EnergyStorageTypeEnum-BUFFER_STORAGE_ELECTRIC" />;
    case energy_storage_type_enum.CENTRAL_INSIDE:
      return <Trans i18nKey="Enum_EnergyStorageTypeEnum-CENTRAL_INSIDE" />;
    case energy_storage_type_enum.CENTRAL_OUTSIDE:
      return <Trans i18nKey="Enum_EnergyStorageTypeEnum-CENTRAL_OUTSIDE" />;
  }
};

export const translateEnergyStorageTypeEnum_dynamic = (
  enumValue: energy_storage_type_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case energy_storage_type_enum.BUFFER_STORAGE:
      return t('Enum_EnergyStorageTypeEnum-BUFFER_STORAGE');
    case energy_storage_type_enum.BUFFER_STORAGE_ELECTRIC:
      return t('Enum_EnergyStorageTypeEnum-BUFFER_STORAGE_ELECTRIC');
    case energy_storage_type_enum.CENTRAL_INSIDE:
      return t('Enum_EnergyStorageTypeEnum-CENTRAL_INSIDE');
    case energy_storage_type_enum.CENTRAL_OUTSIDE:
      return t('Enum_EnergyStorageTypeEnum-CENTRAL_OUTSIDE');
  }
};
