import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StrandingRangeOutput } from '@predium/client-graphql';
import { TARGET_PATH_LAST_YEAR, pathValuesWithYearFromYear } from '@predium/client-lookup';
import { useTranslation } from 'react-i18next';
import {
  LegendKeys,
  PathGraph,
  PathGraphLegend,
  PathGraphSeries,
} from '../../../../components/data-visialization/PathGraph';
import { createGraphExportFileName } from '../../../../utils/createGraphExportFileName';
import { determineStartYear, filterSeriesByStartYear } from '../../../Scenarios/ActionPlan/ActionPlan.utils';
import { esgPathGraphOptions } from '../EsgPathGraph';

type Props = {
  co2Path: number[];
  co2Targets: {
    onePointFive: number[];
  };
  buildingLegendName: string;
  strandingYears: StrandingRangeOutput[];
  constructionYear: number;
};

export function Co2PathGraph({ co2Path, co2Targets, buildingLegendName, strandingYears, constructionYear }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const startYear: number = determineStartYear(constructionYear);

  const co2Series = pathValuesWithYearFromYear(co2Path, startYear);
  const targetSeries = pathValuesWithYearFromYear(co2Targets.onePointFive, startYear);
  const newActionPlanSeries = filterSeriesByStartYear(co2Series, startYear);

  const generateMultilineSeries = () => {
    const years = [
      startYear,
      strandingYears[0]?.startYear ?? 2050,
      strandingYears[0]?.endYear ?? 2050,
      TARGET_PATH_LAST_YEAR,
    ];

    return years
      .map((year, index): PathGraphSeries => {
        return {
          name: buildingLegendName,
          data: newActionPlanSeries.map(([y, value]) => (y >= year && y <= years[index + 1] ? [y, value] : [y, null])),
          color: strandingYears.find(
            (strandingYear) => year >= strandingYear.startYear && year + 1 <= strandingYear.endYear,
          )
            ? theme.palette.error.main
            : theme.palette.common.black,
        };
      })
      .filter(Boolean);
  };

  let series: PathGraphSeries[] = generateMultilineSeries();

  series.push({
    data: targetSeries,
    color: theme.palette.energyEfficiencyClassColors.AAA.light,
    name: t('General_1_5CTargetPath'),
  });

  const pointsAnnotations: PointAnnotations[] = [];
  strandingYears.forEach(({ startYear }) => {
    //@ts-ignore
    const yValue = series[0].data.find(([year]) => year === startYear)[1];

    pointsAnnotations.push({
      x: startYear,
      y: yValue,
      marker: {
        size: 8,
        strokeWidth: 0,
        fillColor: theme.palette.error.main,
      },
    });
  });

  const annotations: ApexAnnotations = {
    points: pointsAnnotations ?? [],
    xaxis: strandingYears
      ? strandingYears.map((strandingYear: { startYear: number; endYear: number }) => ({
          x: strandingYear.startYear,
          x2: strandingYear.endYear,
          fillColor: theme.palette.error.lighter,
        }))
      : [],
  };

  const unit = 'kg CO₂e/m²a';

  const legends: { key: LegendKeys; color: string; text: string }[] = [
    { key: 'strandingWithoutActions', color: theme.palette.error.main, text: t('General_StrandingBeforeActions') },
    { key: 'strandingPeriod', color: theme.palette.error.lighter, text: t('General_StrandingPeriod') },
    { key: 'object', color: theme.palette.common.black, text: t('General_Object') },
    {
      key: '1_5CTargetPath',
      color: theme.palette.energyEfficiencyClassColors.AAA.light,
      text: t('General_1_5CTargetPath'),
    },
  ];

  const co2GraphOptions = {
    ...esgPathGraphOptions,
    stroke: {
      curve: 'straight',
      dashArray: [0, 0, 0, 0, 4],
      width: [4, 4, 4, 4, 2],
    },
  } as ApexCharts.ApexOptions;

  const dataToExport = {
    actualPath: newActionPlanSeries,
    targetPath: targetSeries,
  };

  return (
    <Box
      sx={{
        '.apexcharts-marker': {
          display: 'none', //using prop from apexchart doesn't work for some reason so we hide it here
        },
      }}
    >
      <PathGraph
        fileName={createGraphExportFileName(t('General_CO2Path'))}
        xAxisLabel={t('General_CO2Intensity') + ` (${unit})`}
        series={series}
        annotations={annotations}
        options={{
          legend: {
            show: false,
          },
          ...co2GraphOptions,
        }}
        unit={unit}
        dataToExport={dataToExport}
      />
      <PathGraphLegend legends={legends} />
    </Box>
  );
}
