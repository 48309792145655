import { Checkbox, Divider, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  currentAmountBuildingsInEu?: number;
  economicUnitsToggled?: boolean;
  isBulkOperation?: boolean;
  setKeepEmptyEu: (value: boolean) => void;
};

export function DeleteEmptyEconomicUnit({
  currentAmountBuildingsInEu,
  economicUnitsToggled,
  isBulkOperation = false,
  setKeepEmptyEu,
}: Props) {
  const { t } = useTranslation();
  const methods = useForm<{
    keepEmptyEconomicUnit: boolean;
  }>({
    defaultValues: {
      keepEmptyEconomicUnit: false,
    },
  });

  const { watch, setValue } = methods;
  const keepEmptyEconomicUnit = watch('keepEmptyEconomicUnit');
  // If the EU is enabled, the eu has current 1 building associated and the function to control the state of keepEmptyEu is provided.
  const isEUEligibleForDeletion =
    (isBulkOperation && economicUnitsToggled) || (economicUnitsToggled && currentAmountBuildingsInEu === 1);

  return (
    <>
      {isEUEligibleForDeletion ? (
        <>
          <Stack direction="row" alignItems="center" mt={3} mb={3}>
            <Checkbox
              checked={keepEmptyEconomicUnit}
              onChange={(event) => {
                setValue('keepEmptyEconomicUnit', event.target.checked);
                setKeepEmptyEu(event.target.checked);
              }}
            />
            <Typography
              onClick={() => {
                setValue('keepEmptyEconomicUnit', !keepEmptyEconomicUnit);
                setKeepEmptyEu(!keepEmptyEconomicUnit);
              }}
              sx={{ userSelect: 'none', cursor: 'pointer' }}
            >
              {isBulkOperation
                ? t('DataCollection_DeleteEconomicUnit-empty_bulk')
                : t('DataCollection_DeleteEconomicUnit-empty_eu')}
            </Typography>
          </Stack>
          <Divider sx={{ my: 2 }} />
        </>
      ) : null}
    </>
  );
}
