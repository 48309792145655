/* eslint-disable @typescript-eslint/ban-ts-comment */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, IconButton, InputAdornment, MenuItem, Stack, useTheme } from '@mui/material';
import { getSupportedDistributionTypesForSystemType } from '@predium/client-lookup';
import { data_source_type_enum } from '@predium/enums';
import { translateEnergyDistributionTypeEnum } from '@predium/i18n/client';
import { fShortenNumber } from '@predium/utils';
import { useCallback, useMemo, useState } from 'react';
import { useForm, useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { date, number, object, string } from 'yup';
import { ICONS } from '../../../../../../../../assets/icons';
import { TechnologyIcons } from '../../../../../../../../assets/images';
import Iconify from '../../../../../../../../components/Iconify';
import OverflowText from '../../../../../../../../components/OverflowText';
import {
  FormProvider,
  RHFAutocomplete,
  RHFDatePicker,
  RHFNumberField,
  RHFSelect,
} from '../../../../../../../../components/hook-form';
import PreDialog, {
  PreDialogBody,
  PreDialogTitle,
} from '../../../../../../../../components/presentations/PreDialog/PreDialog';
import { SystemRoute } from '../../../../../BuildingTechnology';
import {
  generateSystemFieldNames,
  generateSystemRouteFieldNames,
  generateUniqueNegativeId,
  generateYearRange,
} from '../../../../../Common/building.util';
import useBuilding from '../../../../../Context/useBuilding';
import { fieldIcon } from '../../TechnologyConsumerRoute';
import { getSelectStyles } from '../EnergySource/EnergySourceEditModal';
import { DistributionFormType } from './DistributionEditModal';

type Props = {
  open: boolean;
  onClose: () => void;
  energySystemRoute: SystemRoute;
  index: number;
  systemIndex: number;
  isHeatingSystem: boolean;
};

const DistributionAddModal = ({ open, onClose, energySystemRoute, index, systemIndex, isHeatingSystem }: Props) => {
  const { t } = useTranslation();
  const { building } = useBuilding();
  const theme = useTheme();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const endYear = new Date().getFullYear();
  const buildingConstructionYear = building.year_constructed;
  const minHydraulicBalancingDate = new Date(buildingConstructionYear, 0, 1);
  const maxHydraulicBalancingDate = new Date();
  const energyFinal = energySystemRoute.energyFinal;
  const maxTransferHeatLoss = energyFinal ? energyFinal * 0.2 : 99999; // 20% of energy final
  const editButtonColor = theme.palette.success.dark;

  const REQUIRED_MESSAGE = t('General_Required');
  const INVALID_DATE_MESSAGE = t('General_InvalidDate');
  const CONSTRUCTION_YEAR_MESSAGE = t('DataCollection_TechnologyEditForm_ConstructionYearMessage', {
    start: buildingConstructionYear,
    end: endYear,
  });
  const LAST_HYDRAULIC_BALANCING_DATE_MESSAGE = t(
    'DataCollection_TechnologyEditForm_LastHydraulicBalancingDateMessage',
    {
      start: buildingConstructionYear,
      end: new Date(),
    },
  );
  //reusing the final energy range message . will be updated with validation ticket
  const MIN_RANGE_MESSAGE = t('DataCollection_TechnologyEditForm_FinalEnergyMinMessage');
  const MAX_RANGE_MESSAGE = t('DataCollection_TechnologyEditForm_FinalEnergyMaxMessage');
  const RANGE_TRANSFER_HEAT_LOSS_MESSAGE = t('DataCollection_TechnologyEditForm_RangeTransferHeatLossMessage', {
    min: 0,
    max: fShortenNumber(maxTransferHeatLoss, 2),
  });

  const { energySystemType } = energySystemRoute;

  //@ts-ignore
  const distributionTypes = getSupportedDistributionTypesForSystemType(energySystemType);

  //Main system form context
  const { setValue, getValues } = useFormContext();

  const {
    distributionHeatLoss,
    transferHeatLoss,
    lastHydraulicBalancing,
    lastBalancingSetToNever,
    energyDistributionConstructionYear,
  } = energySystemRoute;

  const defaultValues: DistributionFormType = {
    distributionType: '',
    distributionHeatLoss: null,
    transferHeatLoss: isHeatingSystem ? null : 0,
    lastHydraulicBalancing: null,
    lastBalancingSetToNever: false,
    constructionYear: null,
  };

  const distributionValidationSchema = object().shape({
    distributionType: string().required(REQUIRED_MESSAGE).oneOf(distributionTypes, REQUIRED_MESSAGE),
    distributionHeatLoss: number()
      .typeError(REQUIRED_MESSAGE)
      .required(REQUIRED_MESSAGE)
      .min(0, MIN_RANGE_MESSAGE)
      .max(99999, MAX_RANGE_MESSAGE),
    transferHeatLoss: number()
      .typeError(REQUIRED_MESSAGE)
      .required(REQUIRED_MESSAGE)
      .min(0, RANGE_TRANSFER_HEAT_LOSS_MESSAGE)
      .when('lastHydraulicBalancing', {
        is: (lastHydraulicBalancing: Date) => !!lastHydraulicBalancing,
        then: number().typeError(REQUIRED_MESSAGE).equals([0]),
        otherwise: number().typeError(REQUIRED_MESSAGE).max(maxTransferHeatLoss, RANGE_TRANSFER_HEAT_LOSS_MESSAGE),
      }),
    lastHydraulicBalancing: date()
      .typeError(INVALID_DATE_MESSAGE)
      .nullable()
      .min(new Date(buildingConstructionYear), LAST_HYDRAULIC_BALANCING_DATE_MESSAGE)
      .max(new Date(), LAST_HYDRAULIC_BALANCING_DATE_MESSAGE),
    constructionYear: number()
      .nullable()
      .min(buildingConstructionYear, CONSTRUCTION_YEAR_MESSAGE)
      .max(endYear, CONSTRUCTION_YEAR_MESSAGE),
  });

  const methods = useForm<DistributionFormType>({
    resolver: yupResolver(distributionValidationSchema),
    defaultValues: defaultValues,
    mode: 'onChange',
  });

  const { handleSubmit, reset, getFieldState, watch, setValue: setFormValue, control } = methods;

  const { isDirty } = useFormState({
    control,
  });

  const handleClose = () => {
    onClose();
    reset(defaultValues);
  };

  const isDistributionTypeDirty = getFieldState('distributionType').isDirty;
  const isConstructionYearDirty = getFieldState('constructionYear').isDirty;
  const isDistributionHeatLossDirty = getFieldState('distributionHeatLoss').isDirty;
  const isTransferHeatLossDirty = getFieldState('transferHeatLoss').isDirty;
  const isLastHydraulicBalancingDirty =
    getFieldState('lastHydraulicBalancing').isDirty || getFieldState('lastBalancingSetToNever').isDirty;

  const newDistributionType = watch('distributionType');
  const newConstructionYear = watch('constructionYear');
  const newDistributionHeatLoss = watch('distributionHeatLoss');
  const newTransferHeatLoss = watch('transferHeatLoss');
  const newLastHydraulicBalancing = watch('lastHydraulicBalancing');
  const newBalancingSetToNever = watch('lastBalancingSetToNever');

  const onLastHydraulicBalancingChange = useCallback(
    (lastHydraulicBalancingSet: boolean) => {
      if (lastHydraulicBalancingSet) {
        setFormValue('distributionHeatLoss', (newDistributionHeatLoss ?? 0) + (newTransferHeatLoss ?? 0), {
          shouldDirty: true,
        });
        setFormValue('transferHeatLoss', 0, { shouldDirty: true });
        setFormValue('lastBalancingSetToNever', false, { shouldDirty: true });
      } else {
        setFormValue('distributionHeatLoss', distributionHeatLoss, { shouldDirty: true });
        setFormValue('transferHeatLoss', transferHeatLoss, { shouldDirty: true });
      }
    },
    [newDistributionHeatLoss, newTransferHeatLoss, distributionHeatLoss, transferHeatLoss, setFormValue],
  );

  const setLastHydraulicBalancing = (val: any) => {
    setFormValue('lastHydraulicBalancing', val, { shouldDirty: true });
    onLastHydraulicBalancingChange(!!val);
  };

  const onSelectLastHydraulicBalancingNever = () => {
    setFormValue('lastBalancingSetToNever', true, { shouldDirty: true });
    setLastHydraulicBalancing(null);
    setIsDatePickerOpen(false);
  };
  const overrideValue = useMemo(() => {
    if (newLastHydraulicBalancing) return null;
    return newBalancingSetToNever ? t('General_Never') : t('General_Unspecified');
  }, [newLastHydraulicBalancing, newBalancingSetToNever, t]);

  const onSubmit = (data: DistributionFormType) => {
    handleClose();

    const {
      distributionHeatLossFieldname,
      transferHeatLossFieldname,
      lastHydraulicBalancingFieldname,
      lastBalancingSetToNeverFieldname,
      energyDistributionConstructionYearFieldname,
      energyDistributionTypeFieldname,
      energyDistributionIdFieldname,

      //@ts-ignore
    } = generateSystemRouteFieldNames(energySystemType, systemIndex, index);

    setValue(energyDistributionIdFieldname, generateUniqueNegativeId(getValues()), { shouldDirty: true });

    if (isDistributionTypeDirty) {
      setValue(energyDistributionTypeFieldname, data.distributionType, {
        shouldDirty: true,
      });
    }
    if (isConstructionYearDirty) {
      setValue(energyDistributionConstructionYearFieldname, data.constructionYear ? data.constructionYear : null, {
        shouldDirty: true,
      });
    }
    if (isDistributionHeatLossDirty) {
      setValue(distributionHeatLossFieldname, data.distributionHeatLoss, {
        shouldDirty: true,
      });
    }
    if (isTransferHeatLossDirty) {
      setValue(transferHeatLossFieldname, data.transferHeatLoss, {
        shouldDirty: true,
      });
    }
    if (isLastHydraulicBalancingDirty) {
      setValue(lastHydraulicBalancingFieldname, data.lastHydraulicBalancing, {
        shouldDirty: true,
      });
      setValue(lastBalancingSetToNeverFieldname, data.lastBalancingSetToNever, {
        shouldDirty: true,
      });
    }
    if (isDirty) {
      //@ts-ignore
      const { dataSourceTypeFieldname } = generateSystemFieldNames(energySystemType, systemIndex);

      setValue(dataSourceTypeFieldname, data_source_type_enum.MANUAL, {
        shouldDirty: true,
      });
    }
  };

  const autocompleteOptions = generateYearRange(buildingConstructionYear);

  const NeverButton = () => (
    <Stack p={2} mt={-5}>
      <Button variant="outlined" onClick={onSelectLastHydraulicBalancingNever} sx={{ alignSelf: 'flex-end' }}>
        {t('General_Never')}
      </Button>
    </Stack>
  );

  return (
    <PreDialog open={open} onClose={onClose} fullWidth type="definedByChildren">
      <FormProvider
        methods={methods}
        onSubmit={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <PreDialogBody
          dialogtitle={
            <PreDialogTitle
              icon={<Box component="img" src={TechnologyIcons.distribution} mt={0.25} mr={1} width={24} height={24} />}
              title={t('DataCollection_Technology_AddDistributionTitle')}
            />
          }
          content={
            <Box my={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} mb={2}>
                  <RHFSelect
                    select
                    label={t('DataCollection_Technology_DistributionTitle')}
                    name="distributionType"
                    sx={getSelectStyles(isDistributionTypeDirty)}
                  >
                    {distributionTypes.map((type) => (
                      <MenuItem sx={{ height: 40 }} key={type} value={type}>
                        {isDistributionTypeDirty && newDistributionType === type && fieldIcon}
                        <OverflowText
                          text={translateEnergyDistributionTypeEnum(type)}
                          maxWidth={'500px'}
                          variant="body1"
                        />
                      </MenuItem>
                    ))}
                  </RHFSelect>
                </Grid>

                <Grid item xs={12} mb={2}>
                  <RHFAutocomplete
                    name="constructionYear"
                    label={t('General_YearOfInstallation')}
                    options={autocompleteOptions}
                    type="number"
                    InputProps={{
                      startAdornment:
                        isConstructionYearDirty &&
                        newConstructionYear !== energyDistributionConstructionYear?.toString()
                          ? fieldIcon
                          : null,
                    }}
                  />
                </Grid>

                {isHeatingSystem && (
                  <Grid item xs={12} mb={2}>
                    <RHFDatePicker
                      name={'lastHydraulicBalancing'}
                      label={t('DataCollection_Technology_LastHydraulicBalancingTitle')}
                      onChange={(value) => onLastHydraulicBalancingChange(!!value)}
                      startAdornment={
                        isLastHydraulicBalancingDirty &&
                        (newLastHydraulicBalancing !== lastHydraulicBalancing ||
                          newBalancingSetToNever !== lastBalancingSetToNever)
                          ? fieldIcon
                          : null
                      }
                      endAdornment={
                        newLastHydraulicBalancing ? (
                          <IconButton onClick={() => setLastHydraulicBalancing(null)}>
                            <Iconify icon={ICONS.CLOSE} />
                          </IconButton>
                        ) : null
                      }
                      minDate={minHydraulicBalancingDate}
                      maxDate={maxHydraulicBalancingDate}
                      overrideValue={overrideValue}
                      sx={{
                        '.Mui-disabled button svg': { color: 'unset' },
                        '.Mui-disabled span svg': { color: editButtonColor },
                      }}
                      customButton={<NeverButton />}
                      open={isDatePickerOpen}
                      setOpen={(op) => setIsDatePickerOpen(op)}
                    />
                  </Grid>
                )}

                <Grid item xs={12} mb={2}>
                  <RHFNumberField
                    name={'distributionHeatLoss'}
                    label={t('DataCollection_Technology_DistributionLossTitle')}
                    variant="outlined"
                    InputProps={{
                      startAdornment:
                        isDistributionHeatLossDirty && newDistributionHeatLoss !== distributionHeatLoss
                          ? fieldIcon
                          : null,
                      endAdornment: <InputAdornment position="end">kWh/m²a</InputAdornment>,
                      autoComplete: 'off',
                    }}
                  />
                </Grid>

                {isHeatingSystem && (
                  <Grid item xs={12} mb={2}>
                    <RHFNumberField
                      name={'transferHeatLoss'}
                      disabled={!!newLastHydraulicBalancing}
                      label={t('DataCollection_Technology_TransferLossTitle')}
                      variant="outlined"
                      InputProps={{
                        startAdornment:
                          isTransferHeatLossDirty && newTransferHeatLoss !== transferHeatLoss ? fieldIcon : null,
                        endAdornment: <InputAdornment position="end">kWh/m²a</InputAdornment>,
                        autoComplete: 'off',
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          }
          actions={
            <>
              <Button variant="outlined" onClick={handleClose}>
                {t('General_Cancel')}
              </Button>
              <Button type="submit" variant="contained">
                {t('DataCollection_Technology_EnergySourceEditDone')}
              </Button>
            </>
          }
        ></PreDialogBody>
      </FormProvider>
    </PreDialog>
  );
};

export default DistributionAddModal;
