import { List, ListItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AssociatedDataType } from '../components/DeleteConfirmationModal';

export default function useTooltipConstants() {
  const { t } = useTranslation();

  const tooltips = new Map([
    [
      AssociatedDataType.REPORT,
      <List sx={{ listStyleType: 'disc', px: 2, py: 1 }}>
        <ListItem sx={{ display: 'list-item', p: 0 }}>{t('General_Report_Tooltip1')}</ListItem>
        <ListItem sx={{ display: 'list-item', p: 0 }}>{t('General_Report_Tooltip2')}</ListItem>
      </List>,
    ],
  ]);

  return { tooltips };
}
