/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Checkbox } from '@mui/material';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { ReactNode } from 'react';

// ----------------------------------------------------------------------

export type HeadLabel = {
  id: string;
  label: string | ReactNode;
  sortingDisabled?: Boolean;
  minWidth?: number;
  alignment?: TableCellProps['align'];
};

type Props = {
  order: 'asc' | 'desc';
  orderBy: string;
  rowCount: number;
  headLabel: HeadLabel[];
  onRequestSort: (id: string) => void;
  numSelected?: number;
  onSelectAllClick?: (checked: boolean) => void;
};

export default function DataCollectionHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  onRequestSort,
  numSelected,
  onSelectAllClick,
}: Props) {
  return (
    <TableHead>
      <TableRow>
        {onSelectAllClick && (
          <TableCell padding="checkbox">
            <Checkbox
              //@ts-ignore

              //@ts-ignore
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onSelectAllClick ? onSelectAllClick(event.target.checked) : () => {}
              }
            />
          </TableCell>
        )}
        {headLabel.map((headCell) => (
          <TableCell
            align={headCell.alignment}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            //@ts-ignore
            sx={{ ...(!isNaN(headCell.minWidth) && { minWidth: headCell.minWidth }) }}
          >
            {!headCell.sortingDisabled ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => onRequestSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
