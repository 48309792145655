/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import {
  Box,
  Card,
  Checkbox,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  AccessRightsPortfolioFragment,
  user_portfolio_permission_insert_input,
} from '../../../../../../../libs/generated/client-graphql/src/generated/graphql';
import Iconify from '../../../../components/Iconify';
import { DelayedLoading } from '../../../../components/Loading';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import Scrollbar from '../../../../components/Scrollbar';
import { TableSelectedActions } from '../../../../components/table';
import TablePaginationStandard from '../../../../components/table/TablePaginationStandard';
import TableRowWithHighlight, { hasRecentlyChanged } from '../../../../components/table/TableRowWithHighlight';
import { GET_PORTFOLIO_AND_BUILDING_PERMISSIONS } from '../../../../graphql/Permissions.queries';
import { UPSERT_USER_PORTFOLIO_PERMISSION } from '../../../../graphql/Users.mutations';
import usePosthogTracking from '../../../../hooks/usePosthogTracking';
import useTable, { applySortFilter } from '../../../../hooks/useTable';
import DataCollectionHead, { HeadLabel } from '../../../DataCollection/Buildings/DataCollectionHead';
import AccessControlRoleSelector from './AccessControlRoleSelector';
import { AccessRole, getRoleByPermissions, getRoleFromSelection, useGetPermissionsFromQuery } from './utils';

type Props = {
  portfolios: AccessRightsPortfolioFragment[];
};

export default function AccessControlPortfolioList({ portfolios }: Props) {
  const { trackEvent } = usePosthogTracking();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [searchInput, setSearchInput] = useState('');
  const [selectedPortfolioIds, setSelectedPortfolioIds] = useState<number[]>([]);

  const { id } = useParams();

  //@ts-ignore
  const { portfolios: portfolioPermissions, loading } = useGetPermissionsFromQuery(parseInt(id));

  const [upsertUserPortfolioPermissions] = useMutation(UPSERT_USER_PORTFOLIO_PERMISSION, {
    onCompleted: (data) => {
      if (data?.insert_user_portfolio_permission?.affected_rows === 0) {
        enqueueSnackbar(t('OrgSettings_AccessRights_MassUpdateError'), {
          variant: 'error',
          autoHideDuration: SnackbarTimeouts.Error,
        });
      }
      enqueueSnackbar(t('OrgSettings_AccessRights_UpsertSuccess'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      trackEvent('ACCESS_RIGHTS_UPDATED', {
        type: 'portfolio',
      });
    },
    refetchQueries: [GET_PORTFOLIO_AND_BUILDING_PERMISSIONS],
  });

  const COLUMNS: HeadLabel[] = [
    { id: 'portfolio', label: t('General_Portfolio'), minWidth: 800 },
    { id: 'permissions', label: t('General_Permission', { count: 2 }), minWidth: 230 },
  ];

  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    order,
    orderBy,
    // filterName,
    // handleFilterByName,
    handleRequestSort,
  } = useTable({ defaultOrderBy: 'portfolio', defaultOrder: 'asc', defaultRowsPerPage: 10 });

  const sortedRows = applySortFilter({
    data: portfolios,
    nameFilter: {
      //@ts-ignore
      fieldName: (portfolio) => portfolio.name,
      filterValue: searchInput,
    },
    orderOptions: {
      order,

      //@ts-ignore
      orderBy: (item) => {
        switch (orderBy) {
          case 'portfolio':
            return item.name;
          case 'permissions':
            return getRoleByPermissions(
              portfolioPermissions.find((p) => p.id === item.id) || {
                read: false,
                write: false,
                id: item.id,
              },
            );
          default:
            return item[orderBy as keyof typeof item] as string | number;
        }
      },
    },
  });

  // tracking the empty rows in the next page, to prevent the table from shifting
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sortedRows.length) : 0;

  const handleCheckboxClick = (id: number) => {
    const selectedIndex = selectedPortfolioIds.indexOf(id);

    if (selectedIndex === -1) {
      setSelectedPortfolioIds([...selectedPortfolioIds, id]);
    } else {
      // removes the index from the array in a more efficient way than filter
      setSelectedPortfolioIds(
        //@ts-ignore
        [].concat(selectedPortfolioIds.slice(0, selectedIndex), selectedPortfolioIds.slice(selectedIndex + 1)),
      );
    }
  };

  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      const newSelectedIds = sortedRows.map((n) => n.id);
      setSelectedPortfolioIds(newSelectedIds);
    } else {
      setSelectedPortfolioIds([]);
    }
  };

  const onRoleChange = (role: AccessRole, portfolioId: number) => {
    const objects: user_portfolio_permission_insert_input = {
      //@ts-ignore
      user_id: parseInt(id),
      portfolio_id: portfolioId,
      read: role !== AccessRole.NO_ACCESS,
      write: role === AccessRole.MANAGER,
    };

    upsertUserPortfolioPermissions({
      variables: {
        objects: [objects],
      },
    });
  };

  const onRoleChangeAll = (role: AccessRole) => {
    const objects: user_portfolio_permission_insert_input[] = selectedPortfolioIds.map((portfolioId) => ({
      //@ts-ignore
      user_id: parseInt(id),
      portfolio_id: portfolioId,
      read: role !== AccessRole.NO_ACCESS,
      write: role === AccessRole.MANAGER,
    }));

    upsertUserPortfolioPermissions({
      variables: {
        objects,
      },
    });
  };

  if (loading) {
    return <DelayedLoading />;
  }

  return (
    <>
      <Card>
        <Scrollbar>
          <Box sx={{ px: 4, py: 3, width: '100%' }}>
            <TextField
              autoComplete="off"
              value={searchInput}
              onChange={(event) => setSearchInput(event.target.value)}
              placeholder={t('General_SearchPortfolioPlaceholder')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: 500,
                '.MuiOutlinedInput-input': {
                  py: 1.5,
                },
              }}
            />
          </Box>
          <TableContainer sx={{ minWidth: '100%' }}>
            {selectedPortfolioIds.length > 0 && (
              <TableSelectedActions
                selectedText={t('General_PortfolioSelected', { count: selectedPortfolioIds.length })}
                numSelected={selectedPortfolioIds.length}
                rowCount={sortedRows.length}
                onSelectAllRows={handleSelectAllClick}
                action={
                  <AccessControlRoleSelector
                    onRoleChange={onRoleChangeAll}
                    value={getRoleFromSelection(selectedPortfolioIds, portfolioPermissions)}
                    type="portfolio"
                  />
                }
              />
            )}
            <Table>
              <DataCollectionHead
                order={order}
                orderBy={orderBy}
                headLabel={COLUMNS}
                rowCount={sortedRows.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
                numSelected={selectedPortfolioIds.length}
              />
              <TableBody>
                {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  const currentPermissions = portfolioPermissions.find((p) => p.id === row.id) || {
                    read: false,
                    write: false,
                    id: row.id,
                  };

                  return (
                    <Row
                      key={row.id}
                      row={row}
                      handleCheckboxClick={handleCheckboxClick}
                      isItemSelected={selectedPortfolioIds.includes(row.id)}
                      currentRole={getRoleByPermissions(currentPermissions)}
                      onRoleChange={onRoleChange}
                    />
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePaginationStandard
          count={sortedRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Card>
    </>
  );
}

type RowProps = {
  row: AccessRightsPortfolioFragment;
  handleCheckboxClick: (id: number) => void;
  isItemSelected: boolean;
  currentRole: AccessRole;
  onRoleChange: (role: AccessRole, buildingId: number) => void;
};

function Row({ row, handleCheckboxClick, isItemSelected, currentRole, onRoleChange }: RowProps) {
  return (
    <>
      <TableRowWithHighlight
        //@ts-ignore
        background={hasRecentlyChanged(row.created_at) ? 'success' : 'default'}
        sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }}
        selected={isItemSelected}
        hover
      >
        <TableCell padding="checkbox">
          <Checkbox checked={isItemSelected} onClick={() => handleCheckboxClick(row.id)} />
        </TableCell>
        <TableCell>
          <Typography variant="body2">{row.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2">
            <AccessControlRoleSelector
              onRoleChange={(role) => onRoleChange(role, row.id)}
              value={currentRole}
              allValues={[
                {
                  role: currentRole,
                  portfolioRole: AccessRole.NO_ACCESS,
                },
              ]}
              type="portfolio"
            />
          </Typography>
        </TableCell>
      </TableRowWithHighlight>
    </>
  );
}
