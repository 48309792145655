import { useCallback, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useSearchParams } from 'react-router-dom';
import { convertFromSearchParams, convertToSearchParams, InferParamsConfigType, ParamsConfig } from './utils';

/**
 * This hook offers type-safe access to a routes search params if defined in the PATHS object.
 * Pass the accessor function for the path as a generic parameter and access the search params object directly.
 *
 * @example
 * const { searchParams, setSearchParams } = useTypeSafeSearchParams(SEARCH_PARAMS.esgAnalysis, { ids: [1, 2, 3] });
 */
export function useTypeSafeSearchParams<
  TParamsConfig extends ParamsConfig,
  DefaultValue extends InferParamsConfigType<TParamsConfig>,
>(paramsConfig: TParamsConfig, defaultValues?: DefaultValue) {
  type AllSearchParams = InferParamsConfigType<TParamsConfig>;

  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamObject = useMemo(
    () => ({ ...defaultValues, ...convertFromSearchParams(paramsConfig, searchParams) }),
    [defaultValues, searchParams, paramsConfig],
  );

  const setSearchParamsWithObject = useCallback(
    (params: AllSearchParams) => {
      setSearchParams(convertToSearchParams(paramsConfig, params));
    },
    [paramsConfig, setSearchParams],
  );

  // Merge the search params with the default values to ensure that all keys are present
  type MergedSearchParams = Omit<AllSearchParams, keyof DefaultValue> & {
    [Key in keyof DefaultValue]: Key extends keyof AllSearchParams ? Exclude<AllSearchParams[Key], undefined> : never;
  };

  return {
    searchParams: searchParamObject as MergedSearchParams,
    setSearchParams: setSearchParamsWithObject,
  };
}
