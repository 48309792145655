import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePlanhat } from '../hooks/usePlanhat';
import usePosthogTracking from '../hooks/usePosthogTracking';

const TrackPageView = () => {
  const location = useLocation();
  const { trackPageView } = usePlanhat();
  const { trackPageView: trackPageViewPosthog } = usePosthogTracking();

  useEffect(() => {
    trackPageView(location.pathname);
    trackPageViewPosthog();
  }, [location.pathname, trackPageView, trackPageViewPosthog]);

  return null;
};

export default TrackPageView;
