import { useMutation } from '@apollo/client';
import { MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import FullScreenLoader from '../../../components/FullScreenLoader';
import Iconify from '../../../components/Iconify';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { EXPORT_REPORT_AS_EXCEL, EXPORT_REPORT_AS_PDF } from '../../../graphql/Report.mutations';
import { useLanguage } from '../../../provider/LanguageProvider';
import { onExportCompleted } from '../../../utils/createDownloadLink';

type Props = {
  reportId: number;
  reportTemplateType: string;
  completed: boolean;
  exportTemplates: string[];
};

export default function ExportReport({ reportId, completed, reportTemplateType, exportTemplates }: Props) {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { enqueueSnackbar } = useSnackbar();

  const onExportError = () => {
    enqueueSnackbar(t('ExportReport_ExportReport-error'), {
      variant: 'error',
      autoHideDuration: SnackbarTimeouts.Error,
    });
  };

  const [exportReportAsPdf, { loading: loadingPdf }] = useMutation(EXPORT_REPORT_AS_PDF, {
    variables: {
      reportId: reportId,
    },
    onCompleted: (data) => {
      onExportCompleted(
        data.exportReportAsPdf.body,
        'pdf',
        `Predium-${reportTemplateType.toLowerCase()}-report`,
        language,
      );
      enqueueSnackbar(t('ExportReport_ExportReport-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
    onError: onExportError,
  });

  const [exportReportAsExcel, { loading: loadingExcel }] = useMutation(EXPORT_REPORT_AS_EXCEL, {
    variables: {
      reportId: reportId,
    },
    onCompleted: (data) => {
      onExportCompleted(
        data.exportReportAsExcel.body,
        'xlsx',
        `Predium-${reportTemplateType.toLowerCase()}-report`,
        language,
      );
      enqueueSnackbar(t('ExportReport_ExportReport-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
    onError: onExportError,
  });

  return (
    <>
      {completed && reportTemplateType === 'ECOREQUICKCHECK' ? (
        <MenuItem onClick={() => exportReportAsPdf()}>
          <Iconify icon={'ant-design:file-pdf-outlined'} />
          {t('General_ExportPdf')}
        </MenuItem>
      ) : null}
      {completed && exportTemplates.includes('excel') ? (
        <MenuItem onClick={() => exportReportAsExcel()}>
          <Iconify icon={'ant-design:file-excel-outlined'} />
          {t('General_ExportExcel')}
        </MenuItem>
      ) : null}
      {
        //setting disableEscapeKeyDown option to true as its not possible to cancel the pdf export for now
      }
      <FullScreenLoader open={loadingPdf || loadingExcel} disableCloseAction text={t('General_InProgress')} />
    </>
  );
}
