/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Container, Grid } from '@mui/material';
import { getBuildingsWhere } from '@predium/graphql-where-client';
import uniqBy from 'lodash/uniqBy';
import { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import LoadingScreen from '../../components/LoadingScreen';
import { UnavailablePageForEconomicUnits } from '../../components/Unavailable';
import StickyBox from '../../components/common/StickyBox';
import { PermissionType } from '../../contexts/PermissionContext';
import { GET_ECONOMIC_UNITS_LIST } from '../../graphql/DataCollection.queries';
import {
  GET_ALL_BUILDINGS_LIST,
  GET_BUILDING_FILTER_VALUES,
  GET_FILTERED_BUILDINGS,
} from '../../graphql/EsgAnalysis.queries';
import useEsgAnalysisFilters from '../../hooks/useEsgAnalysisFilters';
import useOrgPreferences from '../../hooks/useOrgPreferences';
import usePermissions from '../../hooks/usePermissions';
import usePosthogTracking from '../../hooks/usePosthogTracking';
import EsgAnalysisBenchmark, { ENTIRE_COMPANY } from '../../sections/EsgAnalysis/EsgAnalysisBenchmark';
import EsgAnalysisFilterBarEconomicUnits from '../../sections/EsgAnalysis/EsgAnalysisFilterBarEconomicUnits';
import EsgAnalysisTabs from '../../sections/EsgAnalysis/EsgAnalysisTabs';
import EsgAnalysisUnitSwitcher from '../../sections/EsgAnalysis/EsgAnalysisUnitSwitcher';
import { getBuildingWhereFilters } from '../../utils/getWhereFilters';

export default function EsgAnalysisEconomicUnits() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const { trackEvent } = usePosthogTracking();
  const [showTotal, setShowTotal] = useState<boolean>(false);
  const [benchmarkId, setBenchmarkId] = useState<number>(ENTIRE_COMPANY);

  const { filters } = useEsgAnalysisFilters();
  const { checkPortfolioPermission } = usePermissions();

  const { data: economicUnits, loading: economicUnitsLoading } = useQuery(GET_ECONOMIC_UNITS_LIST);

  const { data: buildings, loading: buildingsLoading } = useQuery(GET_ALL_BUILDINGS_LIST, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const { loading: filtersListLoading, data: filtersList } = useQuery(GET_BUILDING_FILTER_VALUES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      currentYear,
    },
  });

  const {
    loading: filteredBuildingsLoading,
    data: filteredBuildingsData,
    previousData: filteredBuildingsPreviousData,
  } = useQuery(GET_FILTERED_BUILDINGS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      buildingWhere: getBuildingsWhere(getBuildingWhereFilters(filters)),
      currentYear,
    },
  });

  const filteredBuildings = filteredBuildingsData ?? filteredBuildingsPreviousData;

  if (
    economicUnitsLoading ||
    buildingsLoading ||
    (filteredBuildingsLoading && !filteredBuildings) ||
    filtersListLoading
  ) {
    return <LoadingScreen />;
  }

  const portfolios = uniqBy(
    //@ts-ignore
    buildings.building.map(({ economic_unit }) => ({
      id: economic_unit.portfolio.id,
      name: economic_unit.portfolio.name,
    })),
    'id',
  ).filter((portfolio) => checkPortfolioPermission(portfolio.id, PermissionType.READ));

  const onDisplayBaseChange = (newDisplayBase: 'per_square_meter' | 'absolut') => {
    const doShowTotal = newDisplayBase === 'absolut';
    setShowTotal(doShowTotal);
    trackEvent('DISPLAY_BASE_CHANGED', {
      display_base: newDisplayBase,
    });
  };

  return (
    <Container sx={{ mb: 3 }} maxWidth="lg">
      <Grid container>
        <Grid item xs={6}>
          <HeaderBreadcrumbs heading={t('PageName_ESGAnalysisEconomicUnitView')} links={[]} />
        </Grid>
      </Grid>

      <StickyBox>
        <Grid container alignItems={'center'}>
          <Grid item xs={12} md={10} lg={10}>
            <EsgAnalysisFilterBarEconomicUnits
              //@ts-ignore
              economicUnits={economicUnits.economic_unit}
              //@ts-ignore
              buildings={buildings.building}
              //@ts-ignore
              filtersList={filtersList}
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <EsgAnalysisBenchmark portfolios={portfolios} initialId={benchmarkId} setBenchmarkId={setBenchmarkId} />
          </Grid>
        </Grid>
      </StickyBox>

      <EsgAnalysisTabs
        benchmarkId={benchmarkId}
        showTotal={showTotal}
        //@ts-ignore
        buildings={filteredBuildings}
        portfolios={portfolios}
        unitSwitcher={<EsgAnalysisUnitSwitcher onDisplayBaseChange={onDisplayBaseChange} />}
        currentView="esg-economics-units"
      />
    </Container>
  );
}

export function EsgAnalysisEconomicUnitsNotActivated({ children }: PropsWithChildren<{}>) {
  const { economicUnitsToggled } = useOrgPreferences();

  if (economicUnitsToggled) {
    return <>{children}</>;
  }

  return <UnavailablePageForEconomicUnits />;
}
