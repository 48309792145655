import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { OperationDefinitionNode } from 'graphql';
import { createClient } from 'graphql-ws';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import NetworkRetrySnackbarAction from '../components/snackbars/NetworkRetrySnackbarAction';
import { useLanguage } from './LanguageProvider';

type Props = {
  authToken: string;
  children?: React.ReactNode;
};

// For reference see: https://www.apollographql.com/docs/react/data/subscriptions
export default function HasuraApiProvider({ authToken, children }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { language } = useLanguage();

  useEffect(() => {
    if (!authToken && import.meta.env.VITE_DEVELOP_OFFLINE !== 'true') {
      enqueueSnackbar(t('HasuraApiProvider_AuthToken-Error'), {
        variant: 'error',
      });
    }
  }, [authToken, enqueueSnackbar, t]);

  /**
   * If a mutation fails due to a network error, we give the user the chance to retry it once the network is back.
   */
  const retryLink = useMemo(
    () =>
      new RetryLink({
        attempts: {
          retryIf(error, operation) {
            const isMutation = operation.query.definitions.some(
              (def) => def.kind === 'OperationDefinition' && def.operation === 'mutation',
            );

            if (!error.message.includes('NetworkError') || !isMutation) {
              return false;
            }

            const retryPromise = new Promise<boolean>((resolve) => {
              enqueueSnackbar(t('HasuraApiProvider_RetryLinkSnackbar-Error'), {
                variant: 'warning',
                autoHideDuration: 45000,
                action: (snackbarKey) => <NetworkRetrySnackbarAction resolve={resolve} snackbarKey={snackbarKey} />,
                onExited: () => resolve(false),
              });
            });

            return retryPromise;
          },
        },
      }),
    [enqueueSnackbar, t],
  );

  const errorLink = useMemo(
    () =>
      import.meta.env.VITE_ENV !== 'development'
        ? //production/staging
          [
            onError(({ graphQLErrors, networkError }) => {
              if (graphQLErrors) {
                graphQLErrors.map(async ({ message }) => {
                  try {
                    // TODO PRE-1834: report error to Appsignal
                    console.error(message);
                  } catch (error) {
                    console.error(error);
                  }
                });
              }

              if (networkError) {
                // TODO PRE-1834: report error to Appsignal
                console.error(networkError.message);
              }
            }),
          ]
        : //development
          [
            onError(({ graphQLErrors, networkError }) => {
              if (graphQLErrors) {
                graphQLErrors.map(({ message }) => {
                  // TODO PRE-1834: use logger
                  console.error(message);
                  return message;
                });
              }

              if (networkError) {
                // TODO PRE-1834: use logger
                console.error(networkError.message);
              }
            }),
          ],
    [],
  );

  const client = useMemo(() => {
    const authHeaders = {
      Authorization: `Bearer ${authToken}`,
    };

    const i18nHeaders = {
      'x-lang': language,
    };

    const httpLink = new HttpLink({
      uri: import.meta.env.VITE_HASURA_ENDPOINT,

      headers: {
        ...authHeaders,
        ...i18nHeaders,
      },
    });

    const getIsWebsocketDisabled = () => {
      try {
        return localStorage.getItem('disableWebsocket') === 'true';
      } catch (error) {
        return false;
      }
    };

    // it is a dummy search param that breaks the websocket connection
    const searchParam = getIsWebsocketDisabled() ? '?dummyQueryString' : '';

    const wsLink = new GraphQLWsLink(
      createClient({
        url:
          import.meta.env.VITE_SANDBOX === 'true'
            ? `${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}//${window.location.host}/api/ws${searchParam}`
            : import.meta.env.VITE_HASURA_ENDPOINT_WS + searchParam,
        connectionParams: () => ({
          headers: {
            ...authHeaders,
          },
        }),
      }),
    );

    const link = split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      wsLink,
      httpLink,
    );

    return new ApolloClient({
      link: from([...errorLink, retryLink, link]),
      cache: new InMemoryCache(),
    });
  }, [authToken, errorLink, retryLink, language]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
