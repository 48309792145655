import { Stack, Typography } from '@mui/material';
import Iconify from '../../../components/Iconify';

type AssociatedItemProps = {
  label: string;
  count: number;
  loading: boolean;
};

export function AssociatedItem({ label, count, loading }: AssociatedItemProps) {
  return (
    <Stack direction="row" alignItems="center" justifyContent={'space-between'} spacing={1} width={'100%'}>
      <Typography variant="body2" color="text.secondary">
        {label}
      </Typography>
      <Typography variant="subtitle2" color="text.primary">
        {!loading ? (
          count
        ) : (
          <Iconify
            icon="mingcute:loading-fill"
            width={16}
            height={16}
            sx={{
              animation: 'spin 1s linear infinite',
              color: 'text.secondary',
              '@keyframes spin': {
                from: { transform: 'rotate(0deg)' },
                to: { transform: 'rotate(360deg)' },
              },
            }}
          />
        )}
      </Typography>
    </Stack>
  );
}
