/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { GET_NOTIFICATIONS } from '../../graphql/Users.queries';
import useSessionData from '../../hooks/useSessionData';
import NotificationsPopover from './NotificationsPopover';

function Notifications() {
  const { user } = useSessionData();

  const { data } = useQuery(GET_NOTIFICATIONS, {
    skip: user?.id === undefined,
    variables: {
      //@ts-ignore
      user_id: user?.id,
    },
    pollInterval: 5 * 60 * 1000, // 5 minutes
  });

  if (!data) return null;

  return <NotificationsPopover notifications={data.notification} />;
}

export default Notifications;
