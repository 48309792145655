/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Alert, AlertTitle, IconButton, Stack } from '@mui/material';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Iconify from '../../../../../components/Iconify';
import { useValidation } from './ValidationProvider';

const ValidationAlert = () => {
  const { t } = useTranslation();

  const {
    errorsCount,
    activeError,
    activeErrorIndex,
    hasNextError,
    hasPreviousError,
    nextError,
    previousError,
    getEquationValueLabel,
  } = useValidation();

  const equationValueLabel = useMemo(
    () => activeError && getEquationValueLabel(activeError?.equation),
    [activeError, getEquationValueLabel],
  );

  if (errorsCount === 0) {
    return null;
  }

  return (
    <Alert
      severity="warning"
      action={
        errorsCount > 1 ? (
          <Stack direction="row" spacing={1}>
            <IconButton onClick={previousError} disabled={!hasPreviousError}>
              <Iconify icon="mdi:chevron-left" />
            </IconButton>
            <IconButton onClick={nextError} disabled={!hasNextError}>
              <Iconify icon="mdi:chevron-right" />
            </IconButton>
          </Stack>
        ) : null
      }
    >
      <AlertTitle>
        {/*@ts-ignore */}
        {t('DataCollectionAreas_ValidationAlertTitle', { active: activeErrorIndex + 1, count: errorsCount })}
      </AlertTitle>
      <Trans
        i18nKey={'DataCollectionAreas_ValidationAlertDescription'}
        values={{
          //@ts-ignore
          equation: activeError.equationLabel,

          //@ts-ignore
          offValue: Math.abs(activeError.fulfillValue),
          equationValues: equationValueLabel,
        }}
        components={{
          bold: <strong />,
          sup: <sup />,
        }}
      />
    </Alert>
  );
};

export default ValidationAlert;
