import { country_enum, country_state_enum, energy_source_type_enum } from '@predium/enums';
import { Year, electricityEF } from './crrem-normalized/crrem-normalized.lookups';
import { EmissionFactorProvider } from './emission-factor-provider.type';

export const CRREMEmissionFactorProvider: EmissionFactorProvider = {
  getEmissionFactor({
    energySourceType,
    year,
    country,
  }: {
    energySourceType: energy_source_type_enum;
    year: number;
    country: country_enum;
    countryState: country_state_enum;
    postalCode: string;
  }): number {
    const CRREM_lookup: Record<energy_source_type_enum, number> = {
      [energy_source_type_enum.FUEL_OIL]: 0.24677,
      [energy_source_type_enum.NATURAL_GAS]: 0.18316,
      [energy_source_type_enum.LPG]: 0.21419,
      [energy_source_type_enum.COAL]: 0.34473,
      [energy_source_type_enum.LIGNITE]: 0.34473,
      [energy_source_type_enum.WOODEN_PELLETS]: 0.01513,
      [energy_source_type_enum.WOOD]: 0.01513,
      [energy_source_type_enum.UNSPECIFIED]: 0.015333333,
      [energy_source_type_enum.ELECTRICITY_MIX]: electricityEF[country][year as unknown as Year],
      [energy_source_type_enum.ELECTRICITY_GREEN]: electricityEF[country]['2050'],
      [energy_source_type_enum.SOLAR]: 0.03,
      [energy_source_type_enum.BIO_GAS]: 0.00022,

      [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]: 0.20431,
      [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]: 0.20431,
      [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: 0.20431,

      [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]: 0.20431,
      [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]: 0.20431,
      [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]: 0.20431,
    };

    return CRREM_lookup[energySourceType];
  },
};
