declare global {
  interface Window {
    plantrack: any;
  }
}

export const usePlanhat = () => {
  const { plantrack } = window;

  const checkAvailability = () => {
    if (import.meta.env.VITE_ENV !== 'production') {
      return false;
    }
    if (typeof window === 'undefined' || !plantrack) {
      if (localStorage.getItem('PLANHAT_WARNING') !== 'true') {
        console.warn('Planhat is not available');
        localStorage.setItem('PLANHAT_WARNING', 'true');
      }
      return false;
    }

    if (localStorage.getItem('PLANHAT_WARNING') === 'true') {
      localStorage.removeItem('PLANHAT_WARNING');
    }
    return true;
  };

  const withAvailabilityCheck = (fn: (...args: any[]) => void) => {
    return (...args: any[]) => {
      if (!checkAvailability()) {
        return;
      }
      fn(...args);
    };
  };

  const identify = withAvailabilityCheck((userId: number, userData: Record<string, any>) => {
    plantrack.identify(userId, userData);
  });

  const trackPageView = withAvailabilityCheck((pathName: string) => {
    plantrack.track('page_view', { url: pathName });
  });

  const trackEvent = withAvailabilityCheck((eventName: string, eventData: Record<string, any>) => {
    plantrack.track(eventName, eventData);
  });

  return { identify, trackEvent, trackPageView };
};
