import { Box, Stack, TableCell, Typography } from '@mui/material';
import { ReactNode } from 'react';
import Iconify from '../../../../components/Iconify';
import WidgetSummaryLabel from '../../../../components/data-visialization/WidgetSummaryLabel';

type Props = {
  before: ReactNode;
  after: ReactNode;
  comparisonPercent?: number;
  hideComparisonLabel?: boolean;
};

export function ComparisonTableCell({ before, after, comparisonPercent, hideComparisonLabel }: Props) {
  return (
    <TableCell>
      <Stack display="flex" flexDirection={'row'} gap={2}>
        <Box display="flex" flexDirection="row" alignItems={'center'} gap={0.5} sx={{ minWidth: '65px' }}>
          <Typography variant="body2">{before}</Typography>
          <Iconify icon={'mdi:arrow-right'} width={16} height={16} />
          <Typography variant="body2">{after}</Typography>
        </Box>
        {!hideComparisonLabel && <WidgetSummaryLabel percent={comparisonPercent ?? 0} />}
      </Stack>
    </TableCell>
  );
}
