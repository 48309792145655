/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQuery } from '@apollo/client';
import { Alert, Button, Divider, Stack, Typography, alpha, useTheme } from '@mui/material';
import { DataCollectionEmissionCertificateFragment } from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { Trans, useTranslation } from 'react-i18next';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import PreDialog from '../../../components/presentations/PreDialog/PreDialog';
import { DELETE_EMISSION_CERTIFICATE } from '../../../graphql/DataCollection.mutations';
import { GET_ASSOCIATED_ACTION_PLANS_AND_REPORTS_FROM_BUILDING } from '../../../graphql/DataCollection.queries';
import { AssociatedItem } from './EmissionCertificateAlertAssociatedItem';
import { getConflictsCount } from './utils';

type Props = {
  open: boolean;
  onClose: () => void;
  onSuccessfulDelete?: () => void;
  emissionCertificate: DataCollectionEmissionCertificateFragment;
};

export default function EmissionCertificateDeleteModal({
  open,
  onClose,
  onSuccessfulDelete,
  emissionCertificate,
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const { data: associatedActionPlansAndReport, loading } = useQuery(
    GET_ASSOCIATED_ACTION_PLANS_AND_REPORTS_FROM_BUILDING,
    {
      variables: {
        emissionCertificateId: emissionCertificate.id,
      },
      // we don't want to prefetch this query for every modal, so we only fetch it when the modal is open
      skip: !open,
      fetchPolicy: 'cache-and-network',
    },
  );

  const [deleteEmissionCertificate] = useMutation(DELETE_EMISSION_CERTIFICATE, {
    variables: {
      id: emissionCertificate.id,
    },
    update: (cache, { data }) => {
      if (data?.deleteEmissionCertificate.success) {
        const normalizedId = cache.identify({
          id: emissionCertificate.id,
          __typename: 'emission_certificate',
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
    onCompleted: () => {
      onSuccessfulDelete?.();
      onClose();
      enqueueSnackbar(t('DataCollection_EmissionCertificateDelete-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
  });

  const {
    associatedActionPlans,
    associatedBuildings,
    associatedReports,
    hasEmissionFactorAssociated,
    hasPrimaryEnergyFactorAssociated,
  } = getConflictsCount(associatedActionPlansAndReport);

  const getInfoBoxTranslation = () => {
    switch (true) {
      case hasEmissionFactorAssociated && hasPrimaryEnergyFactorAssociated:
        return (
          <Trans
            i18nKey="DataCollection_EmissionCertificates_EmissionFactorAndPrimaryEnergyFactorRemoved"
            components={{
              b: <b />,
            }}
          />
        );
      case hasEmissionFactorAssociated:
        return (
          <Trans
            i18nKey="DataCollection_EmissionCertificates_EmissionFactorRemoved"
            components={{
              b: <b />,
            }}
          />
        );
      case hasPrimaryEnergyFactorAssociated:
        return (
          <Trans
            i18nKey="DataCollection_EmissionCertificates_PrimaryEnergyFactorRemoved"
            components={{
              b: <b />,
            }}
          />
        );
      default:
        return '';
    }
  };

  return (
    <PreDialog
      type="definedByProperty"
      open={open}
      onClose={() => {
        onClose();
      }}
      dialogtitle={t('DataCollection_EmissionCertificates_DeleteEmissionCertificate-title')}
      content={
        <Stack spacing={2}>
          <Typography>{getInfoBoxTranslation()}</Typography>
          <Alert
            severity="warning"
            sx={{
              backgroundColor: alpha(theme.palette.warning.main, 0.08),
              color: theme.palette.warning.dark,
              border: `1px solid ${alpha(theme.palette.warning.main, 0.16)}`,
            }}
          >
            {t('DataCollection_EmissionCertificates_FallbackWarning')}
          </Alert>
          <Typography variant="body1" sx={{ mt: 2 }}>
            <Trans
              i18nKey={'DataCollection_EmissionCertificates_DeleteEmissionCertificate-isAssociated'}
              components={{
                b: <b />,
              }}
              values={{
                name: emissionCertificate.issuer,
              }}
            />
          </Typography>
          <Stack spacing={1}>
            <AssociatedItem label={t('General_Building_other')} count={associatedBuildings} loading={loading} />
            <Divider />
            <AssociatedItem label={t('General_ActionPlan_other')} count={associatedActionPlans} loading={loading} />
            <Divider />
            <AssociatedItem label={t('General_Report_other')} count={associatedReports} loading={loading} />
          </Stack>
        </Stack>
      }
      actions={
        <>
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
            }}
          >
            {t('General_Cancel')}
          </Button>
          <Button onClick={async () => await deleteEmissionCertificate()} variant="contained" color="error" autoFocus>
            {t('General_ConfirmAndDelete')}
          </Button>
        </>
      }
    />
  );
}
