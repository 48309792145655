/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Grid, Stack, Typography } from '@mui/material';
import {
  EsgAnalysisGetBenchmarkBuildingsFragment,
  EsgAnalysisGetBuildingsForSingleBuildingFragment,
  EsgAnalysisPortfolioFragment,
} from '@predium/client-graphql';
import { pathValueAtYear } from '@predium/client-lookup';
import {
  climate_risk_type_enum,
  country_enum,
  efficiency_class_enum,
  energy_source_type_enum,
  eu_taxonomy_compliance_enum,
} from '@predium/enums';
import { getBuildingIsMixed, getNetArea, getNufTypesOfUse, isEuTaxonomyAvailable } from '@predium/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../components/Iconify';
import LoadingScreen from '../../components/LoadingScreen';
import SimpleComparisonMetric from '../../components/SimpleComparisonMetric';
import Unavailable from '../../components/Unavailable';
import BuildingEfficiencyCard from '../../components/data-visialization/esg-analysis/BuildingEfficiencyCard';
import CO2CostDevelopmentGraph from '../../components/data-visialization/esg-analysis/CO2CostDevelopmentGraph';
import { ClimateRiskGraph } from '../../components/data-visialization/esg-analysis/ClimateRiskChart';
import EnergyCostDevelopmentGraph from '../../components/data-visialization/esg-analysis/EnergyCostDevelopmentGraph';
import {
  EnergySourceCO2Graph,
  EnergySourceConsumptionGraph,
} from '../../components/data-visialization/esg-analysis/EnergySourceGraph';
import Scope123Graph from '../../components/data-visialization/esg-analysis/Scope123Graph';
import StrandingRiskCard from '../../components/data-visialization/esg-analysis/StrandingRiskCard';
import { GET_ANALYZED_BUILDING } from '../../graphql/EsgAnalysis.queries';
import useSessionData from '../../hooks/useSessionData';
import ConsumptionAllocation from './ConsumptionAllocation';
import ConsumptionOverTime from './ConsumptionOverTime';
import EUTaxonomyTooltip, { calculateEUTaxonomyToBeSaved, euTaxonomyColors } from './EUTaxonomyTooltip';
import { ENTIRE_COMPANY } from './EsgAnalysisBenchmark';
import EsgAnalysisBuildingMixedSection from './EsgAnalysisBuildingMixedSection';
import EsgAnalysisBuildingSingleSection from './EsgAnalysisBuildingSingleSection';
import { EsgPathGraph } from './Visualization/EsgPathGraph';

// ----------------------------------------------------------------------

type Props = {
  building: EsgAnalysisGetBuildingsForSingleBuildingFragment;
  // Optional, because switching the benchmark re-loads the gql query and leaves it loading for a short time
  benchmark?: EsgAnalysisGetBenchmarkBuildingsFragment;
  portfolios: EsgAnalysisPortfolioFragment[];
  benchmarkId: number;
  showTotal: boolean;
};

export default function EsgAnalysisBuildingSection({ building, benchmark, portfolios, benchmarkId, showTotal }: Props) {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const { data, loading } = useQuery(GET_ANALYZED_BUILDING, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { buildingId: building.id, year: currentYear },
  });
  const { serverSideFeatureFlags } = useSessionData();

  const benchmarks = useMemo(
    () => [
      { id: ENTIRE_COMPANY, name: t('EsgAnalysisBenchmark_EntireCompany') },
      ...portfolios.map((portfolio) => ({
        id: portfolio.id,
        name: `Portfolio ${portfolio.name}`,
      })),
    ],
    [portfolios, t],
  );

  if (loading) {
    return <LoadingScreen />;
  }

  if (!data) {
    return (
      <Unavailable
        title={t('EsgAnalysisBuildingSection_AnalysisNotAvailable', { buildingName: building.address.street })}
      />
    );
  }

  const calculateBenchmarkChange = (value: number, benchmarkPath?: number[]) => {
    if (!benchmarkPath) return null;

    //@ts-ignore
    return (1 - value / pathValueAtYear(benchmarkPath, currentYear)) * -1;
  };

  const { getAnalyzedBuilding: analyzedBuilding } = data;

  const efficiencyClass = efficiency_class_enum[analyzedBuilding.efficiencyClass];
  const compliance = eu_taxonomy_compliance_enum[analyzedBuilding.euTaxonomyCompliance];

  let { onePointFive: strandingYearOnePointFive } = analyzedBuilding.co2StrandingDates;
  const strandingYearOnePointFiveChange =
    //@ts-ignore
    strandingYearOnePointFive?.length > 0 && benchmark?.strandingDates?.co2.onePointFive.length > 0
      ? //@ts-ignore
        strandingYearOnePointFive[0].startYear - benchmark.strandingDates.co2.onePointFive[0].startYear
      : null;

  const co2Emissions = pathValueAtYear(analyzedBuilding.co2Paths.pathM2, currentYear);

  //@ts-ignore
  const co2EmissionsChange = calculateBenchmarkChange(co2Emissions, benchmark?.co2Paths.pathM2);

  const co2Costs = pathValueAtYear(analyzedBuilding.co2Paths.costsM2, currentYear);

  //@ts-ignore
  const co2CostsChange = calculateBenchmarkChange(co2Costs, benchmark?.co2Paths.costsM2);

  const energyDemand = pathValueAtYear(analyzedBuilding.energyPaths.pathM2, currentYear);

  //@ts-ignore
  const energyDemandChange = calculateBenchmarkChange(energyDemand, benchmark?.energyPaths.pathM2);

  const energyFinalCost = pathValueAtYear(analyzedBuilding.energyPaths.costsM2, currentYear);

  //@ts-ignore
  const energyFinalCostChange = calculateBenchmarkChange(energyFinalCost, benchmark?.energyPaths.costsM2);

  const primaryEnergy = analyzedBuilding.primaryEnergy;

  //@ts-ignore
  const primaryEnergyChange = (1 - primaryEnergy / benchmark?.averagePrimaryEnergy) * -1;
  const hasPrimaryEnergyChange = !isNaN(primaryEnergyChange) && isFinite(primaryEnergyChange);

  const typesOfUse = getNufTypesOfUse(building.areas);
  const typeOfUse = typesOfUse[0];

  const primaryEnergyToBeSaved = calculateEUTaxonomyToBeSaved(
    primaryEnergy,
    compliance,
    building.address.country_id,
    typeOfUse,
  );

  const energyConsumers = analyzedBuilding.energyConsumers.map((energySource) => ({
    energy_source_type: energy_source_type_enum[energySource.energySourceType],
    co2_emissions: energySource.co2EmissionsM2,
    energy_consumption: energySource.energyConsumptionM2,
    energy_consumption_total: energySource.energyConsumptionTotal,
    co2_emissions_total: energySource.co2EmissionsTotal,
  }));

  const climateRisks = analyzedBuilding.climateRisks.map((climateRisk) => ({
    ...climateRisk,
    type: climate_risk_type_enum[climateRisk.type],
  }));

  const totalArea = getNetArea(building.areas);

  //@ts-ignore
  const selectedBenchmarkName = benchmarks.find((benchmark) => benchmark.id === benchmarkId).name;

  const changeEndAdornment = `${t('General_Vs')} ${selectedBenchmarkName}`;

  const isInGermany = building.address.country_id === country_enum.DE;

  return (
    <>
      {getBuildingIsMixed(building.areas) ? (
        <EsgAnalysisBuildingMixedSection building={building} analyzedBuilding={analyzedBuilding} />
      ) : (
        <EsgAnalysisBuildingSingleSection building={building} />
      )}
      <Grid container item xs={6} spacing={3}>
        <Grid item xs={6}>
          <BuildingEfficiencyCard
            efficiencyClass={efficiencyClass}
            landlordProportion={analyzedBuilding.taxBracket.landlordProportion}
            change={energyDemandChange}
            // we don't show "Average" for mixed buildings of Germany
            isAverage={!isInGermany && getBuildingIsMixed(building.areas)}
            // we show "Residential" for only mixed buildings of Germany
            showResidentialLabel={isInGermany && getBuildingIsMixed(building.areas)}
            sx={{ height: '100%' }}
          />
        </Grid>
        <Grid item xs={6}>
          <StrandingRiskCard
            strandingYearOnePointFive={strandingYearOnePointFive[0]?.startYear ?? 2050}
            change={strandingYearOnePointFiveChange}
            sx={{ height: '100%' }}
            selectedBenchmarkName={selectedBenchmarkName}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={3} sx={{ pt: 3 }}>
        <Grid container item xs wrap="nowrap" gap={2}>
          <Grid item xs zeroMinWidth>
            <SimpleComparisonMetric
              title={t('General_CO2Intensity')}
              //@ts-ignore
              value={co2Emissions}
              change={co2EmissionsChange}
              changeEndAdornment={changeEndAdornment}
              unit="kg CO₂e"
              showTotal={showTotal}
              totalArea={totalArea}
            />
          </Grid>
          <Grid item xs zeroMinWidth>
            <SimpleComparisonMetric
              title={t('General_CO2Taxes')}
              //@ts-ignore
              value={co2Costs}
              change={co2CostsChange}
              changeEndAdornment={changeEndAdornment}
              unit="€"
              showTotal={showTotal}
              totalArea={totalArea}
            />
          </Grid>
          <Grid item xs zeroMinWidth>
            <SimpleComparisonMetric
              title={t('General_FinalEnergy')}
              //@ts-ignore
              value={energyDemand}
              change={energyDemandChange}
              changeEndAdornment={changeEndAdornment}
              unit="kWh"
              showTotal={showTotal}
              totalArea={totalArea}
            />
          </Grid>
          <Grid item xs zeroMinWidth>
            <SimpleComparisonMetric
              title={t('General_EnergyCost')}
              //@ts-ignore
              value={energyFinalCost}
              change={energyFinalCostChange}
              changeEndAdornment={changeEndAdornment}
              unit="€"
              showTotal={showTotal}
              totalArea={totalArea}
            />
          </Grid>
          <Grid item xs zeroMinWidth>
            <SimpleComparisonMetric
              title={t('General_PrimaryEnergy')}
              value={primaryEnergy}
              change={primaryEnergyChange}
              changeEndAdornment={changeEndAdornment}
              hideNoChange={!hasPrimaryEnergyChange}
              unit="kWh"
              showTotal={showTotal}
              totalArea={totalArea}
              titleEndAdornment={
                isEuTaxonomyAvailable(building.areas) && (
                  <EUTaxonomyTooltip compliance={compliance} toBeSaved={primaryEnergyToBeSaved}>
                    <Stack direction={'row'} alignItems={'center'} gap={0.5}>
                      <Iconify
                        icon={euTaxonomyColors.icon[compliance]}
                        width={16}
                        height={16}
                        color={euTaxonomyColors.iconColor[compliance]}
                      />
                      <Typography
                        color={euTaxonomyColors.complianceColor[compliance]}
                        sx={{ textDecoration: 'underline dotted', textUnderlinePosition: 'under' }}
                        variant="subtitle2"
                      >
                        {t('General_EUTaxonomy')}
                      </Typography>
                    </Stack>
                  </EUTaxonomyTooltip>
                )
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <EsgPathGraph
            co2Path={analyzedBuilding.co2Paths.pathM2}
            co2Targets={analyzedBuilding.co2Targets}
            energyPath={analyzedBuilding.energyPaths.pathM2}
            energyTargets={analyzedBuilding.energyTargets}
            buildingLegendName={t('General_Object')}
            strandingYears={analyzedBuilding.co2StrandingDates.onePointFive}
            constructionYear={building.year_constructed}
          />
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={6}>
            <EnergySourceCO2Graph energySources={energyConsumers} showTotal={showTotal} sx={{ minHeight: '100%' }} />
          </Grid>
          <Grid item xs={6}>
            <EnergySourceConsumptionGraph
              energySources={energyConsumers}
              showTotal={showTotal}
              totalArea={totalArea}
              sx={{ minHeight: '100%' }}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={6}>
            <CO2CostDevelopmentGraph
              co2Costs={analyzedBuilding.co2Paths.costsM2}
              co2CostsTotal={analyzedBuilding.co2Paths.costsTotal}
              landlordProportion={analyzedBuilding.taxBracket.landlordProportion}
              showTotal={showTotal}
              sx={{ minHeight: '100%' }}
            />
          </Grid>
          <Grid item xs={6}>
            <EnergyCostDevelopmentGraph
              energyCosts={analyzedBuilding.energyPaths.costsM2}
              energyCostsTotal={analyzedBuilding.energyPaths.costsTotal}
              showTotal={showTotal}
              sx={{ minHeight: '100%' }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ClimateRiskGraph climateRisks={climateRisks} />
        </Grid>
        <Grid item xs={6}>
          <Scope123Graph scopeData={analyzedBuilding.scope123Co2Emissions} showTotal={showTotal} />
        </Grid>
        {serverSideFeatureFlags.CONSUMPTION_ANALYSIS && (
          <>
            <Grid item xs={12}>
              <ConsumptionOverTime buildingId={building.id} />
            </Grid>
            <Grid item xs={12}>
              <ConsumptionAllocation buildingId={building.id} year={currentYear} isAbsolute={showTotal} />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
