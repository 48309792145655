import { efficiency_class_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateEfficiencyClassEnum = (enumValue: efficiency_class_enum) => {
  switch (enumValue) {
    case efficiency_class_enum.A:
      return <Trans i18nKey="Enum_EfficiencyClassEnum-A" />;
    case efficiency_class_enum.AA:
      return <Trans i18nKey="Enum_EfficiencyClassEnum-AA" />;
    case efficiency_class_enum.AAA:
      return <Trans i18nKey="Enum_EfficiencyClassEnum-AAA" />;
    case efficiency_class_enum.B:
      return <Trans i18nKey="Enum_EfficiencyClassEnum-B" />;
    case efficiency_class_enum.C:
      return <Trans i18nKey="Enum_EfficiencyClassEnum-C" />;
    case efficiency_class_enum.D:
      return <Trans i18nKey="Enum_EfficiencyClassEnum-D" />;
    case efficiency_class_enum.E:
      return <Trans i18nKey="Enum_EfficiencyClassEnum-E" />;
    case efficiency_class_enum.F:
      return <Trans i18nKey="Enum_EfficiencyClassEnum-F" />;
    case efficiency_class_enum.G:
      return <Trans i18nKey="Enum_EfficiencyClassEnum-G" />;
    case efficiency_class_enum.H:
      return <Trans i18nKey="Enum_EfficiencyClassEnum-H" />;
    case efficiency_class_enum.NOT_APPLICABLE:
      return <Trans i18nKey="Enum_EfficiencyClassEnum-NOT_APPLICABLE" />;
    case efficiency_class_enum.UNKNOWN:
      return <Trans i18nKey="Enum_EfficiencyClassEnum-UNKNOWN" />;
  }
};

export const translateEfficiencyClassEnum_dynamic = (
  enumValue: efficiency_class_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case efficiency_class_enum.A:
      return t('Enum_EfficiencyClassEnum-A');
    case efficiency_class_enum.AA:
      return t('Enum_EfficiencyClassEnum-AA');
    case efficiency_class_enum.AAA:
      return t('Enum_EfficiencyClassEnum-AAA');
    case efficiency_class_enum.B:
      return t('Enum_EfficiencyClassEnum-B');
    case efficiency_class_enum.C:
      return t('Enum_EfficiencyClassEnum-C');
    case efficiency_class_enum.D:
      return t('Enum_EfficiencyClassEnum-D');
    case efficiency_class_enum.E:
      return t('Enum_EfficiencyClassEnum-E');
    case efficiency_class_enum.F:
      return t('Enum_EfficiencyClassEnum-F');
    case efficiency_class_enum.G:
      return t('Enum_EfficiencyClassEnum-G');
    case efficiency_class_enum.H:
      return t('Enum_EfficiencyClassEnum-H');
    case efficiency_class_enum.NOT_APPLICABLE:
      return t('Enum_EfficiencyClassEnum-NOT_APPLICABLE');
    case efficiency_class_enum.UNKNOWN:
      return t('Enum_EfficiencyClassEnum-UNKNOWN');
  }
};
