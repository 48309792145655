/* eslint-disable @typescript-eslint/ban-ts-comment */
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { efficiency_class_enum } from '@predium/enums';
import {
  translateBuildingStateEnum,
  translateCountryEnum,
  translateCountryStateEnum,
  translateEfficiencyClassEnum,
  translateEnergySourceTypeEnum,
  translateTypeOfUseEnum_dynamic,
} from '@predium/i18n/client';
import { getNetArea, getNufTypesOfUse, primaryEnergySourceFromEnergySystems } from '@predium/utils';
import { useTranslation } from 'react-i18next';
import InlineUser from '../../../components/InlineUser';
import BuildingMap from '../../../components/map/BuildingMap';
import useOrgPreferences from '../../../hooks/useOrgPreferences';
import useBuilding from './Context/useBuilding';

export default function BuildingGeneral() {
  const { t } = useTranslation();
  const { economicUnitsToggled } = useOrgPreferences();
  const { building } = useBuilding();
  const subBuilding = building.sub_buildings[0];
  const typesOfUse = getNufTypesOfUse(building.areas);

  const SUB_BUILDING_VALUES: { label: string; key: keyof typeof subBuilding; unit: string }[] = [
    {
      label: t('DataCollectionSubBuildingValues_UnitsResidential'),
      key: 'units_residential',
      unit: '',
    },
    {
      label: t('DataCollectionSubBuildingValues_UnitsCommercial'),
      key: 'units_commercial',
      unit: '',
    },
  ];

  const primaryEnergySourceType = primaryEnergySourceFromEnergySystems(
    //@ts-ignore
    building.active_building_model.energy_systems.flatMap((system) => system.energy_system_consumer_routes),
  );
  const primaryEnergySource = translateEnergySourceTypeEnum(primaryEnergySourceType);

  const buildingState = translateBuildingStateEnum(building.building_state_id);
  const cityAndPostalCode = `${building.address.postal_code ?? ''} ${building.address.city ?? ''}`;
  const countryState = building.address.country_state_id
    ? translateCountryStateEnum(building.address.country_state_id)
    : '—';
  const country = building.address.country_id ? translateCountryEnum(building.address.country_id) : '—';
  const portfolioName = building.economic_unit?.portfolio?.name ?? '';
  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={4}>
          <Card sx={{ height: '100%', p: 1, textAlign: 'center' }}>{<BuildingMap />}</Card>
        </Grid>
        <Grid item md={8}>
          <Card sx={{ p: 3, height: '100%' }}>
            <CardHeader sx={{ mb: 2 }} title={t('General_GeneralData')} />

            <Grid container spacing={3} sx={{ px: 3, py: 1 }}>
              <Grid item xs={12} md={4}>
                <Typography>{t('General_Address')}</Typography>
              </Grid>
              <Grid textAlign="right" item xs={12} md={8}>
                <Typography align="right">{building.address.street ?? '—'}</Typography>
              </Grid>
            </Grid>

            {economicUnitsToggled && (
              <Grid container spacing={3} sx={{ px: 3, py: 1 }}>
                <Grid item xs={12} md={4}>
                  <Typography>{t('General_EconomicUnit_one')}</Typography>
                </Grid>
                <Grid textAlign="right" item xs={12} md={8}>
                  <Typography align="right">{building.economic_unit.name}</Typography>
                </Grid>
              </Grid>
            )}

            <Grid container spacing={3} sx={{ px: 3, py: 1 }}>
              <Grid item xs={12} md={4}>
                <Typography>{t('Portfolio')}</Typography>
              </Grid>
              <Grid textAlign="right" item xs={12} md={8}>
                <Typography align="right">{portfolioName.trim() || '—'}</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ px: 3, py: 1 }}>
              <Grid item xs={12} md={4}>
                <Typography>{t('General_City')}</Typography>
              </Grid>
              <Grid textAlign="right" item xs={12} md={8}>
                <Typography align="right">{cityAndPostalCode.trim() || '—'}</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ px: 3, py: 1 }}>
              <Grid item xs={12} md={4}>
                <Typography>{t('General_CountryState')}</Typography>
              </Grid>
              <Grid textAlign="right" item xs={12} md={8}>
                <Typography align="right">{countryState}</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ px: 3, py: 1 }}>
              <Grid item xs={12} md={4}>
                <Typography>{t('General_Country')}</Typography>
              </Grid>
              <Grid textAlign="right" item xs={12} md={8}>
                <Typography align="right">{country}</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ px: 3, py: 1 }}>
              <Grid item xs={12} md={4}>
                <Typography>{t('General_State')}</Typography>
              </Grid>
              <Grid textAlign="right" item xs={12} md={8}>
                <Typography align="right">{buildingState}</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ px: 3, py: 1 }}>
              <Grid item xs={12} md={4}>
                <Typography>{t('General_MonumentProtection')}</Typography>
              </Grid>
              <Grid textAlign="right" item xs={12} md={8}>
                <Typography align="right">
                  {building.monument_protection ? t('General_Yes') : t('General_No')}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ px: 3, py: 1 }}>
              <Grid item xs={12} md={4}>
                <Typography>{t('General_HeritageDistrict')}</Typography>
              </Grid>
              <Grid textAlign="right" item xs={12} md={8}>
                <Typography align="right">{building.heritage_district ? t('General_Yes') : t('General_No')}</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ px: 3, py: 1 }}>
              <Grid item xs={12} md={4}>
                <Typography>{t('General_MilieuProtection')}</Typography>
              </Grid>
              <Grid textAlign="right" item xs={12} md={8}>
                <Typography align="right">{building.milieu_protection ? t('General_Yes') : t('General_No')}</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ px: 3, py: 1 }}>
              <Grid item xs={12} md={4}>
                <Typography>{t('General_Leasehold')}</Typography>
              </Grid>
              <Grid textAlign="right" item xs={12} md={8}>
                <Typography align="right">{building.leasehold ? t('General_Yes') : t('General_No')}</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ px: 3, py: 1 }}>
              <Grid item xs={12} md={4}>
                <Typography>{t('General_TypeOfUse', { count: typesOfUse.length })}</Typography>
              </Grid>
              <Grid textAlign="right" item xs={12} md={8}>
                <Typography align="right">
                  {typesOfUse.map((type) => translateTypeOfUseEnum_dynamic(type, t)).join(', ')}
                </Typography>
              </Grid>
            </Grid>

            <Grid key="" container spacing={3} sx={{ px: 3, py: 1 }}>
              <Grid item xs={12} md={4}>
                <Typography>{t('General_UsableArea')}</Typography>
              </Grid>
              <Grid textAlign="right" item xs={12} md={8}>
                <Typography align="right">{getNetArea(building.areas)} m²</Typography>
              </Grid>
            </Grid>

            {SUB_BUILDING_VALUES.map((value) =>
              subBuilding[value.key] ? (
                <Grid key={value.key} container spacing={3} sx={{ px: 3, py: 1 }}>
                  <Grid item xs={12} md={4}>
                    <Typography>{value.label}</Typography>
                  </Grid>
                  <Grid textAlign="right" item xs={12} md={8}>
                    <Typography align="right">{subBuilding[value.key] + ' ' + value.unit ?? '—'}</Typography>
                  </Grid>
                </Grid>
              ) : null,
            )}

            <Grid key={'year_constructed'} container spacing={3} sx={{ px: 3, py: 1 }}>
              <Grid item xs={12} md={4}>
                <Typography>{t('General_ConstructionYear')}</Typography>
              </Grid>
              <Grid textAlign="right" item xs={12} md={8}>
                <Typography align="right">{building.year_constructed}</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ px: 3, py: 1 }}>
              <Grid item xs={12} md={4}>
                <Typography>{t('General_MainEnergySource')}</Typography>
              </Grid>
              <Grid textAlign="right" item xs={12} md={8}>
                <Typography align="right">{primaryEnergySource ?? '—'}</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ px: 3, py: 1 }}>
              <Grid item xs={12} md={4}>
                <Typography>{t('General_EnergyDemand')}</Typography>
              </Grid>
              <Grid textAlign="right" item xs={12} md={8}>
                {/*@ts-ignore */}
                <Typography align="right">{building.active_building_model.energy_final + ' kWh/m²a'}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ px: 3, py: 1 }}>
              <Grid item xs={12} md={4}>
                <Typography>{t('General_EfficiencyClass')}</Typography>
              </Grid>
              <Grid textAlign="right" item xs={12} md={8}>
                <Typography align="right">
                  {translateEfficiencyClassEnum(
                    //@ts-ignore
                    building.active_building_model.energy_paths[0].efficiency_class_id ?? efficiency_class_enum.UNKNOWN,
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={4}>
          <Card sx={{ p: 2, pb: 4, textAlign: 'center', mt: 3 }}>
            <CardHeader sx={{ textAlign: 'left', mb: 2 }} title={t('General_ResponsibleUser')} />

            {building.responsible_user ? (
              <InlineUser
                //@ts-ignore
                firstName={building.responsible_user.first_name}
                //@ts-ignore
                lastName={building.responsible_user.last_name}
                sx={{ pl: 2 }}
              />
            ) : null}
          </Card>
        </Grid>

        {/* <Grid item xs={12} md={8} lg={8}>
          <SubBuildingList building={subBuilding} />
        </Grid> */}
      </Grid>
    </>
  );
}
