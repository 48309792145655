import { graphql } from '@predium/client-graphql';

//Economic unit ------------------------------------

export const ADD_ECONOMIC_UNIT = graphql(`
  mutation DataCollectionAddEconomicUnit($portfolioId: Int!, $name: String!, $customerEconomicUnitId: String!) {
    insert_economic_unit_one(
      object: { name: $name, portfolio_id: $portfolioId, customer_economic_unit_id: $customerEconomicUnitId }
    ) {
      __typename
      id
    }
  }
`);

export const EDIT_ECONOMIC_UNIT = graphql(`
  mutation DataCollectionEditEconomicUnit(
    $economicUnitId: Int!
    $economicUnitName: String!
    $customerEconomicUnitId: String
    $portfolioId: Int!
  ) {
    update_economic_unit_by_pk(
      pk_columns: { id: $economicUnitId }
      _set: { name: $economicUnitName, customer_economic_unit_id: $customerEconomicUnitId, portfolio_id: $portfolioId }
    ) {
      id
      name
      portfolio_id
    }
  }
`);

export const DELETE_ECONOMIC_UNIT = graphql(`
  mutation DataCollectionDeleteEconomicUnit($id: Int!) {
    delete_economic_unit_by_pk(id: $id) {
      __typename
      id
    }
  }
`);

export const DELETE_ECONOMIC_UNITS = graphql(`
  mutation DataCollectionDeleteEconomicUnits($economicUnitIds: [Int!]!) {
    delete_economic_unit(where: { id: { _in: $economicUnitIds } }) {
      affected_rows
      returning {
        __typename
        id
      }
    }
  }
`);

//Building ------------------------------------

export const DELETE_BUILDING = graphql(`
  mutation DataCollectionDeleteBuilding($id: Int!) {
    delete_building_by_pk(id: $id) {
      __typename
      id
      economic_unit {
        id
        associatedBuildingsAndDrafts {
          amount
        }
      }
    }
  }
`);

export const DELETE_BUILDINGS = graphql(`
  mutation DataCollectionDeleteBuildings($buildingIds: [Int!]!) {
    delete_building(where: { id: { _in: $buildingIds } }) {
      affected_rows
      returning {
        __typename
        id
        economic_unit {
          id
          associatedBuildingsAndDrafts {
            amount
          }
        }
      }
    }
  }
`);

export const MOVE_BUILDING_TO_ECONOMIC_UNIT = graphql(`
  mutation DataCollectionMoveBuilding($id: Int!, $economicUnitId: Int!) {
    update_building_by_pk(pk_columns: { id: $id }, _set: { economic_unit_id: $economicUnitId }) {
      id
    }
  }
`);

export const MOVE_BUILDINGS_TO_ECONOMIC_UNIT = graphql(`
  mutation DataCollectionMoveBuildings($ids: [Int!]!, $economicUnitId: Int!) {
    update_building(where: { id: { _in: $ids } }, _set: { economic_unit_id: $economicUnitId }) {
      affected_rows
    }
  }
`);

export const BUILDING_EXCEL_EXPORT = graphql(`
  mutation SubBuildingExcelExport($buildingIds: [Int!]!) {
    exportSubBuildingsAsExcel(building_ids: $buildingIds) {
      body
    }
  }
`);

// Energy certificate draft ------------------------------------

export const DELETE_ENERGY_CERTIFICATE_DRAFTS = graphql(`
  mutation DataCollectionEnergyCertificateDraftsListDeleteEnergyCertificateDrafts($energyCertificateDraftIds: [Int!]!) {
    delete_energy_certificate_draft(where: { id: { _in: $energyCertificateDraftIds } }) {
      affected_rows
      returning {
        __typename
        id
      }
    }
  }
`);
export const DELETE_EMPTY_EU_AFTER_MOVE_BUILDINGS = graphql(`
  mutation RemoveEmptiesEuAfterMoveBuildings($ids: [Int!]!) {
    removeEmptyEconomicUnits(ids: $ids) {
      ids
    }
  }
`);

export const CREATE_BUILDING_FROM_ENERGY_CERTIFICATE_DRAFT = graphql(`
  mutation DataCollectionEnergyCertificateDraftCreateBuildingFromEnergyCertificateDraft(
    $energyCertificateDraftId: Int!
  ) {
    createBuildingFromEnergyCertificateDraft(energy_certificate_draft_id: $energyCertificateDraftId) {
      building_id
    }
  }
`);

graphql(`
  fragment CreatedEnergyCertificateDraft on energy_certificate_draft {
    id
    energy_certificate_file_id
  }
`);

export const CREATE_ENERGY_CERTIFICATE_DRAFT = graphql(`
  mutation UploadEnergyCertificateDraftModalCreateEnergyCertificateDraft($portfolioId: Int!, $economicUnitId: Int) {
    createEnergyCertificateDraft(portfolio_id: $portfolioId, economic_unit_id: $economicUnitId) {
      CreateEnergyCertificateDraftOutput {
        ...CreatedEnergyCertificateDraft
      }
      upload_url
    }
  }
`);

export const SAVE_ENERGY_CERTIFICATE_DRAFT = graphql(`
  mutation DataCollectionEnergyCertificateDraftSaveEnergyCertificateDraft(
    $energy_certificate_draft_id: Int!
    $street: String
    $city: String
    $postal_code: String
    $country_state_id: country_state_enum
    $country_id: country_enum
    $building_state_id: building_state_enum
    $portfolio_id: Int!
    $monument_protection: Boolean
    $leasehold: Boolean
    $heritage_district: Boolean
    $milieu_protection: Boolean
    $economic_unit_id: Int
    $responsible_user_id: Int
    $year_constructed: Int
    $area_usable: numeric
    $sub_building_class_id: sub_building_class_enum
    $type_of_use_id: type_of_use_enum
    $energy_certificate_type_id: energy_certificate_type_enum
    $draft_status_id: draft_status_enum
    $expiry_date: timestamptz
    $units: Int
    $energy_certificate_consumers: [energy_certificate_consumer_insert_input!]!
  ) {
    update_energy_certificate_draft_by_pk(
      pk_columns: { id: $energy_certificate_draft_id }
      _set: {
        street: $street
        city: $city
        postal_code: $postal_code
        country_state_id: $country_state_id
        country_id: $country_id
        building_state_id: $building_state_id
        portfolio_id: $portfolio_id
        monument_protection: $monument_protection
        leasehold: $leasehold
        heritage_district: $heritage_district
        milieu_protection: $milieu_protection
        economic_unit_id: $economic_unit_id
        responsible_user_id: $responsible_user_id
        year_constructed: $year_constructed
        area_usable: $area_usable
        sub_building_class_id: $sub_building_class_id
        type_of_use_id: $type_of_use_id
        energy_certificate_type_id: $energy_certificate_type_id
        expiry_date: $expiry_date
        units: $units
        draft_status_id: $draft_status_id
      }
    ) {
      id
    }
    delete_energy_certificate_consumer(where: { energy_certificate_draft_id: { _eq: $energy_certificate_draft_id } }) {
      affected_rows
    }
    insert_energy_certificate_consumer(objects: $energy_certificate_consumers) {
      affected_rows
    }
  }
`);

export const DELETE_ENERGY_CERTIFICATE_DRAFT = graphql(`
  mutation DataCollectionEnergyCertificateDraftDeleteEnergyCertificateDraft($energyCertificateDraftId: Int!) {
    delete_energy_certificate_draft_by_pk(id: $energyCertificateDraftId) {
      __typename
      id
    }
  }
`);

export const SET_ENERGY_CERTIFICATE_DRAFT_OCR_FAILED = graphql(`
  mutation DataCollectionEnergyCertificateDraftSetOcrFailed($energyCertificateDraftId: Int!) {
    update_energy_certificate_draft(
      where: { id: { _eq: $energyCertificateDraftId } }
      _set: { draft_status_id: OCR_FAILED }
    ) {
      affected_rows
    }
  }
`);

export const ENERGY_CERTIFICATE_DRAFT_COUNT_SUBSCRIPTION = graphql(`
  subscription DataCollectionsTabsEnergyCertificateDraftCountSubscription {
    energy_certificate_draft_aggregate {
      aggregate {
        count
      }
    }
  }
`);

// Consumption draft ------------------------------------

export const UPDATE_CONSUMPTION_INVOICE_DRAFT = graphql(`
  mutation DataCollectionSaveConsumptionInvoiceDraft(
    $buildingId: Int!
    $consumptionInvoiceDraftId: Int!
    $invoicePayer: invoice_payer_type_enum
    $invoiceDate: DateTime
    $draftStatus: draft_status_enum!
    $consumptionDrafts: [ConsumptionDraftInput!]!
  ) {
    updateConsumptionInvoiceDraft(
      buildingId: $buildingId
      invoiceDraftId: $consumptionInvoiceDraftId
      invoiceDate: $invoiceDate
      invoicePayer: $invoicePayer
      draftStatus: $draftStatus
      consumptionDrafts: $consumptionDrafts
    ) {
      UpdateConsumptionInvoiceDraftOutput {
        id
        date
        invoice_payer
        building {
          id
        }
        draft_status
        consumption_drafts {
          id
          # shared
          area_id
          from
          to
          provider

          value
          display_unit_value
          cost
          consumption_type_id
          energy_source_type_id
          sub_type_id
          consumption_waste_details_draft {
            id
            volume
            display_unit_volume
            frequency
            display_unit_frequency
            amount
            price
          }
        }
      }
    }
  }
`);

export const CREATE_CONSUMPTION_INVOICE_FROM_DRAFT = graphql(`
  mutation DataCollectionSubBuildingConsumptionDraftCreateConsumptionFromDraft($consumptionInvoiceDraftId: Int!) {
    createConsumptionInvoiceFromDraft(consumption_invoice_draft_id: $consumptionInvoiceDraftId) {
      consumption_invoice_id
    }
  }
`);

export const CONSUMPTION_DRAFT_STATUS_SUBSCRIPTION = graphql(`
  subscription DataCollectionConsumptionDraftStatusSubscription($consumptionInvoiceDraftId: Int!) {
    consumption_invoice_draft_by_pk(id: $consumptionInvoiceDraftId) {
      id
      draft_status
      updated_at
    }
  }
`);

export const SET_CONSUMPTION_INVOICE_DRAFT_OCR_FAILED = graphql(`
  mutation DataCollectionSubBuildingConsumptionInvoiceDraftSetOcrFailed($consumptionInvoiceDraftId: Int!) {
    update_consumption_invoice_draft_by_pk(
      pk_columns: { id: $consumptionInvoiceDraftId }
      _set: { draft_status: OCR_FAILED }
    ) {
      id
    }
  }
`);

export const CONSUMPTION_DRAFT_COUNT_SUBSCRIPTION = graphql(`
  subscription SubBuildingConsumptionTabsConsumptionDraftCountSubscription($subBuildingId: Int!) {
    consumption_draft_aggregate(where: { sub_building_id: { _eq: $subBuildingId } }) {
      aggregate {
        count
      }
    }
  }
`);

export const CREATE_CONSUMPTION_INVOICE_DRAFT = graphql(`
  mutation UploadConsumptionDraftModalCreateConsumptionDraft($buildingId: Int!) {
    createConsumptionInvoiceDraft(building_id: $buildingId) {
      CreateConsumptionInvoiceDraftOutput {
        id
        file_id
        created_at
      }
      upload_url
    }
  }
`);

//Energy certificate -----------------------------------

/**
 * Deleted a file and removes the file relationship from any associated energy certificates
 */
export const DELETE_ENERGY_CERTIFICATE_FILE = graphql(`
  mutation DeleteEnergyCertificateFile($fileId: Int!) {
    delete_file_by_pk(id: $fileId) {
      __typename
      id
    }
  }
`);

/**
 * Creates an empty file for the certificate and returns a signed upload url for the file.
 */
export const CREATE_ENERGY_CERTIFICATE_FILE = graphql(`
  mutation CreateEnergyCertificateFile($energyCertificateId: Int!) {
    createEnergyCertificateFile(energy_certificate_id: $energyCertificateId) {
      file_id
      upload_url
    }
  }
`);

//Building model ------------------------------------

export const APPLY_BUILDING_MODEL_MUTATIONS = graphql(`
  mutation ApplyBuildingModelMutations(
    $buildingModelId: Int!
    $envelopeMutations: [EnvelopeMutation!]!
    $energySystemMutations: [EnergySystemMutation!]!
    $energySystemConsumerMutations: [EnergyConsumerMutation!]
    $energySystemStorageMutations: [EnergyStorageMutation!]
    $energySystemDistributionMutations: [EnergyDistributionMutation!]
    $saveInvalidState: Boolean!
  ) {
    applyBuildingModelMutations(
      building_model_id: $buildingModelId
      envelope_mutations: $envelopeMutations
      energy_system_mutations: $energySystemMutations
      energy_consumer_mutations: $energySystemConsumerMutations
      energy_storage_mutations: $energySystemStorageMutations
      energy_distribution_mutations: $energySystemDistributionMutations
      save_invalid_state: $saveInvalidState
    ) {
      scaling_successful
      final_energy
      final_energy_scaled
    }
  }
`);

export const UPDATE_CONSUMPTION_INVOICE = graphql(`
  mutation DataCollectionSaveConsumptionInvoice(
    $buildingId: Int!
    $consumptionInvoiceId: Int!
    $invoicePayer: invoice_payer_type_enum!
    $invoiceDate: DateTime!
    $consumptions: [ConsumptionInput!]!
  ) {
    updateConsumptionInvoice(
      buildingId: $buildingId
      invoiceId: $consumptionInvoiceId
      invoiceDate: $invoiceDate
      invoicePayer: $invoicePayer
      consumptions: $consumptions
    ) {
      UpdateConsumptionInvoiceOutput {
        id
        date
        invoice_payer
        building {
          id
        }
        consumptions {
          id
          # shared
          area_id
          from
          to
          provider

          value
          display_unit_value
          cost
          consumption_type_id
          energy_source_type_id
          sub_type_id
          consumption_waste_detail {
            id
            volume
            display_unit_volume
            frequency
            display_unit_frequency
            amount
            price
          }
        }
      }
    }
  }
`);

export const INSERT_SINGLE_CONSUMPTION_DRAFT = graphql(`
  mutation DataCollectionInsertConsumptionDraft(
    $subBuildingId: Int!
    $areaId: Int
    $from: timestamptz
    $to: timestamptz
    $value: numeric
    $displayUnitValue: consumption_unit_enum
    $cost: numeric
    $provider: String
    $sourceTypeId: consumption_source_type_enum!
    $consumptionTypeId: consumption_type_enum
    $energySourceTypeId: energy_source_type_enum
    $subTypeId: consumption_sub_type_enum
    $userId: Int!
  ) {
    insert_consumption_draft_one(
      object: {
        area_id: $areaId
        consumption_type_id: $consumptionTypeId
        cost: $cost
        created_by_user_id: $userId
        energy_source_type_id: $energySourceTypeId
        from: $from
        provider: $provider
        source_type_id: $sourceTypeId
        sub_building_id: $subBuildingId
        sub_type_id: $subTypeId
        to: $to
        value: $value
        display_unit_value: $displayUnitValue
      }
    ) {
      id
      area_id
      consumption_type_id
      cost
      created_by_user_id
      energy_source_type_id
      from
      provider
      source_type_id
      sub_building_id
      sub_type_id
      to
      value
      display_unit_value
    }
  }
`);

export const INSERT_SINGLE_WASTE_DETAILS_DRAFT = graphql(`
  mutation DataCollectionInsertConsumptionWasteDetailsDraft(
    $consumptionDraftId: Int!
    $amount: numeric
    $frequency: numeric
    $displayUnitFrequency: consumption_unit_enum
    $price: numeric
    $volume: numeric
    $displayUnitVolume: consumption_unit_enum
  ) {
    insert_consumption_waste_details_draft_one(
      object: {
        consumption_draft_id: $consumptionDraftId
        amount: $amount
        frequency: $frequency
        display_unit_frequency: $displayUnitFrequency
        price: $price
        volume: $volume
        display_unit_volume: $displayUnitVolume
      }
    ) {
      id
      amount
      frequency
      display_unit_frequency
      price
      volume
      display_unit_volume
    }
  }
`);

export const UPDATE_SINGLE_CONSUMPTION_DRAFT = graphql(`
  mutation DataCollectionSaveConsumptionDraft(
    $consumptionDraftId: Int!
    $areaId: Int
    $from: timestamptz
    $to: timestamptz
    $value: numeric
    $displayUnitValue: consumption_unit_enum
    $cost: numeric
    $provider: String
    $consumptionTypeId: consumption_type_enum
    $energySourceTypeId: energy_source_type_enum
    $subTypeId: consumption_sub_type_enum
  ) {
    update_consumption_draft_by_pk(
      pk_columns: { id: $consumptionDraftId }
      _set: {
        area_id: $areaId
        from: $from
        to: $to
        value: $value
        display_unit_value: $displayUnitValue
        cost: $cost
        provider: $provider
        consumption_type_id: $consumptionTypeId
        energy_source_type_id: $energySourceTypeId
        sub_type_id: $subTypeId
      }
    ) {
      id
      area_id
      consumption_type_id
      cost
      created_by_user_id
      energy_source_type_id
      from
      provider
      source_type_id
      sub_building_id
      sub_type_id
      to
      value
      display_unit_value
      consumption_waste_details_draft {
        id
      }
    }
  }
`);

export const UPDATE_SINGLE_WASTE_DETAILS_DRAFT = graphql(`
  mutation DataCollectionUpdateConsumptionWasteDetailsDraft(
    $id: Int!
    $amount: numeric
    $frequency: numeric
    $displayUnitFrequency: consumption_unit_enum
    $price: numeric
    $volume: numeric
    $displayUnitVolume: consumption_unit_enum
  ) {
    update_consumption_waste_details_draft_by_pk(
      pk_columns: { id: $id }
      _set: {
        amount: $amount
        frequency: $frequency
        display_unit_frequency: $displayUnitFrequency
        price: $price
        volume: $volume
        display_unit_volume: $displayUnitVolume
      }
    ) {
      id
      amount
      frequency
      display_unit_frequency
      price
      volume
      display_unit_volume
    }
  }
`);

export const DELETE_SINGLE_WASTE_DETAILS_DRAFT = graphql(`
  mutation DataCollectionDeleteConsumptionWasteDetailsDraft($id: Int!) {
    delete_consumption_waste_details_draft_by_pk(id: $id) {
      id
    }
  }
`);

export const INSERT_SINGLE_CONSUMPTION_FROM_DRAFT = graphql(`
  mutation DataCollectionInsertConsumption(
    $subBuildingId: Int!
    $areaId: Int!
    $from: timestamptz!
    $to: timestamptz!
    $value: numeric
    $displayUnitValue: consumption_unit_enum
    $cost: numeric
    $provider: String!
    $sourceTypeId: consumption_source_type_enum!
    $consumptionTypeId: consumption_type_enum!
    $energySourceTypeId: energy_source_type_enum
    $subTypeId: consumption_sub_type_enum
    $userId: Int!
  ) {
    insert_consumption_one(
      object: {
        area_id: $areaId
        consumption_type_id: $consumptionTypeId
        cost: $cost
        created_by_user_id: $userId
        energy_source_type_id: $energySourceTypeId
        from: $from
        provider: $provider
        source_type_id: $sourceTypeId
        sub_building_id: $subBuildingId
        sub_type_id: $subTypeId
        to: $to
        value: $value
        display_unit_value: $displayUnitValue
      }
    ) {
      id
    }
  }
`);

export const INSERT_SINGLE_WASTE_DETAILS = graphql(`
  mutation DataCollectionInsertConsumptionWasteDetails(
    $consumptionId: Int!
    $amount: numeric!
    $frequency: numeric!
    $displayUnitFrequency: consumption_unit_enum
    $price: numeric!
    $volume: numeric!
    $displayUnitVolume: consumption_unit_enum
  ) {
    insert_consumption_waste_details_one(
      object: {
        consumption_id: $consumptionId
        amount: $amount
        frequency: $frequency
        display_unit_frequency: $displayUnitFrequency
        price: $price
        volume: $volume
        display_unit_volume: $displayUnitVolume
      }
    ) {
      id
      amount
      frequency
      display_unit_frequency
      price
      volume
      display_unit_volume
    }
  }
`);

export const UPDATE_SINGLE_CONSUMPTION = graphql(`
  mutation DataCollectionSaveConsumption(
    $consumptionId: Int!
    $areaId: Int
    $from: timestamptz
    $to: timestamptz
    $value: numeric
    $displayUnitValue: consumption_unit_enum
    $cost: numeric
    $provider: String
    $consumptionTypeId: consumption_type_enum
    $energySourceTypeId: energy_source_type_enum
    $subTypeId: consumption_sub_type_enum
  ) {
    update_consumption_by_pk(
      pk_columns: { id: $consumptionId }
      _set: {
        area_id: $areaId
        from: $from
        to: $to
        value: $value
        display_unit_value: $displayUnitValue
        cost: $cost
        provider: $provider
        consumption_type_id: $consumptionTypeId
        energy_source_type_id: $energySourceTypeId
        sub_type_id: $subTypeId
      }
    ) {
      id
      area_id
      consumption_type_id
      cost
      created_by_user_id
      energy_source_type_id
      from
      provider
      source_type_id
      sub_building_id
      sub_type_id
      to
      value
      display_unit_value
      consumption_waste_detail {
        id
      }
    }
  }
`);

export const UPDATE_SINGLE_WASTE_DETAILS = graphql(`
  mutation DataCollectionUpdateConsumptionWasteDetails(
    $id: Int!
    $amount: numeric!
    $frequency: numeric
    $displayUnitFrequency: consumption_unit_enum
    $price: numeric!
    $volume: numeric!
    $displayUnitVolume: consumption_unit_enum
  ) {
    update_consumption_waste_details_by_pk(
      pk_columns: { id: $id }
      _set: {
        amount: $amount
        frequency: $frequency
        display_unit_frequency: $displayUnitFrequency
        price: $price
        volume: $volume
        display_unit_volume: $displayUnitVolume
      }
    ) {
      id
      amount
      frequency
      display_unit_frequency
      price
      volume
      display_unit_volume
    }
  }
`);

export const DELETE_SINGLE_WASTE_DETAILS = graphql(`
  mutation DataCollectionDeleteConsumptionWasteDetails($id: Int!) {
    delete_consumption_waste_details_by_pk(id: $id) {
      id
    }
  }
`);

/**
 * Delete a processed bill by PK
 */
export const DELETE_CONSUMPTION_INVOICE = graphql(`
  mutation DeleteConsumptionInvoice($id: Int!) {
    delete_consumption_invoice_by_pk(id: $id) {
      id
      __typename
    }
  }
`);

export const DELETE_CONSUMPTION = graphql(`
  mutation DeleteConsumption($id: Int!) {
    delete_consumption_by_pk(id: $id) {
      id
      __typename
    }
  }
`);

/**
 * Delete a processed bill draft by PK
 */
export const DELETE_CONSUMPTION_INVOICE_DRAFT = graphql(`
  mutation DeleteConsumptionInvoiceDraft($invoiceDraftId: Int!) {
    delete_consumption_invoice_draft_by_pk(id: $invoiceDraftId) {
      id
      __typename
      consumption_drafts {
        id
        __typename
      }
    }
  }
`);

export const DELETE_CONSUMPTION_DRAFT = graphql(`
  mutation DataCollectionSubBuildingConsumptionDraftDeleteConsumptionDraft($consumptionDraftId: Int!) {
    delete_consumption_draft_by_pk(id: $consumptionDraftId) {
      __typename
      id
    }
  }
`);

// Custom emission certificates ------------------------------------

export const CREATE_EMISSION_CERTIFICATE_DRAFT = graphql(`
  mutation CreateEmissionCertificateDraft {
    createEmissionCertificateDraft {
      emission_certificate_draft_id
      upload_url
      CreateEmissionCertificateDraftOutput {
        file_id
      }
    }
  }
`);

export const UPDATE_EMISSION_CERTIFICATE_DRAFT = graphql(`
  mutation UpdateEmissionCertificateDraft(
    $id: Int!
    $emission_factor: float8
    $issuer: String
    $valid_from: timestamptz
    $valid_until: timestamptz
    $primary_energy_factor: float8
  ) {
    update_emission_certificate_draft_by_pk(
      pk_columns: { id: $id }
      _set: {
        emission_factor: $emission_factor
        issuer: $issuer
        valid_from: $valid_from
        valid_until: $valid_until
        primary_energy_factor: $primary_energy_factor
      }
    ) {
      ...DataCollectionEmissionCertificateDraft
    }
  }
`);

export const CREATE_EMISSION_CERTIFICATE_FROM_DRAFT = graphql(`
  mutation CreateEmissionCertificateFromDraft($draft_id: Int!) {
    createEmissionCertificateFromDraft(draft_id: $draft_id) {
      CreateEmissionCertificateFromDraftOutput {
        ...DataCollectionEmissionCertificate
      }
    }
  }
`);

export const UPDATE_EMISSION_CERTIFICATE = graphql(`
  mutation UpdateEmissionCertificate($emissionCertificate: EmissionCertificateUpdateInput!) {
    updateEmissionCertificate(emissionCertificate: $emissionCertificate) {
      success
    }
  }
`);

export const DELETE_EMISSION_CERTIFICATE = graphql(`
  mutation DeleteEmissionCertificate($id: Int!) {
    deleteEmissionCertificate(id: $id) {
      success
    }
  }
`);

export const DELETE_EMISSION_CERTIFICATE_DRAFT = graphql(`
  mutation DeleteEmissionCertificateDraft($id: Int!) {
    delete_emission_certificate_draft_by_pk(id: $id) {
      __typename
      id
    }
  }
`);

export const UPDATE_AREAS = graphql(`
  mutation UpdateAreas($buildingId: Int!, $areaMutations: [AreaUpdateMutation!]!, $saveInvalidState: Boolean!) {
    updateAreas(building_id: $buildingId, area_mutations: $areaMutations, save_invalid_state: $saveInvalidState) {
      success
      scalingResult {
        final_energy
        final_energy_scaled
        scaling_successful
      }
    }
  }
`);

export const UPDATE_AREAS_WITH_RECALCULATION = graphql(`
  mutation RecalculateAreas(
    $buildingId: Int!
    $areaMutations: [AreaRecalculateMutation!]!
    $saveInvalidState: Boolean!
  ) {
    recalculateAreas(
      building_id: $buildingId
      area_mutations: $areaMutations
      save_invalid_state: $saveInvalidState
      dry_run: false
    ) {
      success
      errorMessage
      scalingResult {
        final_energy
        final_energy_scaled
        scaling_successful
      }
    }
  }
`);

export const RECALCULATE_AREAS = graphql(`
  mutation RecalculateAreasDry($buildingId: Int!, $areaMutations: [AreaRecalculateMutation!]!) {
    recalculateAreas(
      building_id: $buildingId
      area_mutations: $areaMutations
      save_invalid_state: false
      dry_run: true
    ) {
      success
      areas {
        id
        value
      }
    }
  }
`);
