import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * @returns A function that returns the not available label for the x-axis.
 *
 * @example
 *
 * ```tsx
 * const getNotAvailableLabel = useGetNotAvailableLabel();
 *
 * <ReactApexChart
 *   height={150}
 *   type="bar"
 *   options={{
 *    annotations: {
 *      xaxis: [getNotAvailableLabel("some_value_of_xaxis")],
 *    },
 *   }}
 * />
 * ```
 */
export const useGetNotAvailableLabel = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (year: number): XAxisAnnotations => ({
    x: year,
    borderColor: 'transparent',
    label: {
      borderColor: 'white',
      style: {
        color: theme.palette.grey[600],
        background: 'white',
        padding: {
          left: 16,
          right: 16,
          top: 5,
          bottom: 5,
        },
      },
      text: t('General_NoData'),
      orientation: 'horizontal',
      position: 'center',
    },
  });
};
