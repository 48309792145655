/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Grid, Stack } from '@mui/material';
import {
  EsgAnalysisGetAllBuildingFilterValuesQuery,
  EsgAnalysisGetBuildingsFragment,
  EsgAnalysisPortfolioFragment,
} from '@predium/client-graphql';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Scrollbar from '../../components/Scrollbar';
import SelectMultiWithSearch, { SelectWithSearchValue } from '../../components/SelectMultiWithSearch';
import useEsgAnalysisFilters from '../../hooks/useEsgAnalysisFilters';
import usePosthogTracking from './../../hooks/usePosthogTracking';
import EsgAnalysisFilterBarBuildingFilter from './EsgAnalysisFilterBarBuildingFilter';
import { EsgAnalysisFiltersKeys } from './EsgAnalysisFilters/EsgAnalysisFilters';
import EsgAnalysisFiltersSidebar from './EsgAnalysisFilters/EsgAnalysisFiltersSidebar';
import EsgAnalysisFiltersTags from './EsgAnalysisFilters/EsgAnalysisFiltersTags';

type Props = {
  portfolios: EsgAnalysisPortfolioFragment[];
  benchmarkId: number;
  buildings: EsgAnalysisGetBuildingsFragment[];
  filtersList: EsgAnalysisGetAllBuildingFilterValuesQuery;
};

export const ALL_PORTFOLIOS = 'allPortfolios';

export default function EsgAnalysisFilterBarPortfolio({ portfolios, buildings, filtersList }: Props) {
  const { t } = useTranslation();
  const { trackEvent } = usePosthogTracking();
  const [openFilter, setOpenFilter] = useState(false);
  const defaultPortfolioValue: SelectWithSearchValue = {
    label: t('General_AllPortfolios'),
    value: ALL_PORTFOLIOS,
    isFixed: true,
  };

  const { filters, resetAllFilters, resetFilter, setFilter } = useEsgAnalysisFilters();

  /**
   * Creates each option for the portfolio select
   */
  const mapToPortfolioSelectOption = (portfolio: EsgAnalysisPortfolioFragment): { value: number; label: string } => {
    return {
      value: portfolio.id,
      label: portfolio.name ?? t('EsgAnalysisFilterBarPortfolio_NoName_WithId', { portfolioId: portfolio.id }),
    };
  };

  const getPortfoliosFromIds = (ids: number[]) => {
    return portfolios.filter((portfolio) => ids.includes(portfolio.id)).map(mapToPortfolioSelectOption);
  };

  const onPortfolioSelected = (selectedPortfolio: SelectWithSearchValue) => {
    if (selectedPortfolio.value === ALL_PORTFOLIOS) {
      resetFilter(EsgAnalysisFiltersKeys.portfolioIds);
    } else {
      const foundPortfolio = portfolios.find((portfolio) => portfolio.id === selectedPortfolio.value);
      if (!foundPortfolio) {
        // Don't change portfolio filters if the selected portfolio is not found
        return;
      }

      setFilter(EsgAnalysisFiltersKeys.portfolioIds, foundPortfolio.id);
      resetFilter(EsgAnalysisFiltersKeys.buildingIds);
    }

    trackEvent('PORTFOLIO_SELECTED', {
      portfolio_id: selectedPortfolio.value,
    });
  };

  return (
    <Grid container alignItems={'center'}>
      <Grid item xs={12} md={10} lg={10}>
        <Scrollbar>
          <Stack direction="row" height={72} alignItems={'center'} my={1}>
            <SelectMultiWithSearch
              name={t('EsgAnalysisFilterBarPortfolio_PortfolioSelection')}
              options={[defaultPortfolioValue, ...portfolios.map(mapToPortfolioSelectOption)]}
              onSelect={onPortfolioSelected}
              defaultValue={defaultPortfolioValue}
              //@ts-ignore
              selectedValues={getPortfoliosFromIds(filters.portfolioIds)}
              sx={{ minWidth: 400, my: 1, pl: 0.2 }}
              onResetAll={() => resetFilter(EsgAnalysisFiltersKeys.portfolioIds)}
              noDataText={t('EsgAnalysisFilterBarPortfolio_NoPortfoliosFound')}
            />
            <EsgAnalysisFilterBarBuildingFilter buildings={buildings} />
            <EsgAnalysisFiltersTags />
          </Stack>
        </Scrollbar>
      </Grid>

      <Grid item xs={12} md={2} lg={2}>
        <Stack direction="row" justifyContent={'end'}>
          <EsgAnalysisFiltersSidebar
            onResetAll={resetAllFilters}
            isOpen={openFilter}
            onOpen={() => setOpenFilter(true)}
            onClose={() => setOpenFilter(false)}
            portfolios={portfolios}
            filtersList={filtersList}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}
