import { TableCell, TableSortLabel } from '@mui/material';
import { FC } from 'react';
import { IBaseColumn } from '../Columns/BaseColumn';
import { useDataTableAPI } from '../DataTableAPIContext';

type Props = Pick<IBaseColumn, 'id' | 'minWidth' | 'sortable'> & { label: ReturnType<IBaseColumn['getLabel']> };

const DataTableColumnHead: FC<Props> = ({ id, sortable, label, minWidth }) => {
  const { sortBy, sortDirection, onSortChange } = useDataTableAPI();

  if (!sortable) {
    return <TableCell sx={{ minWidth }}>{label}</TableCell>;
  }

  return (
    <TableCell sx={{ minWidth }}>
      <TableSortLabel
        hideSortIcon
        active={sortBy === id}
        direction={sortBy === id ? sortDirection : 'asc'}
        onClick={() => onSortChange(id)}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );
};

export default DataTableColumnHead;
