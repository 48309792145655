import { energy_consumer_technology_type_enum, energy_source_type_enum } from '@predium/enums';

//
// SourceTypesForTechnologyType - a mapping of supported source types for a given technology type
//

export const SourceTypesForTechnologyType: Record<energy_consumer_technology_type_enum, energy_source_type_enum[]> = {
  CENTRAL_ELECTRIC_STORAGE: [
    energy_source_type_enum.ELECTRICITY_MIX,
    energy_source_type_enum.ELECTRICITY_GREEN,
    energy_source_type_enum.SOLAR,
  ],
  SMALL_ELECTRIC_STORAGE: [
    energy_source_type_enum.ELECTRICITY_MIX,
    energy_source_type_enum.ELECTRICITY_GREEN,
    energy_source_type_enum.SOLAR,
  ],
  ELECTRIC_FLOW_HEATER: [
    energy_source_type_enum.ELECTRICITY_MIX,
    energy_source_type_enum.ELECTRICITY_GREEN,
    energy_source_type_enum.SOLAR,
  ],
  ELECTRIC_HEAT_PUMP_AIR: [
    energy_source_type_enum.ELECTRICITY_MIX,
    energy_source_type_enum.ELECTRICITY_GREEN,
    energy_source_type_enum.SOLAR,
  ],
  ELECTRIC_HEAT_PUMP_GEO: [
    energy_source_type_enum.ELECTRICITY_MIX,
    energy_source_type_enum.ELECTRICITY_GREEN,
    energy_source_type_enum.SOLAR,
  ],
  DIRECT_ELECTRICITY_HEATING: [
    energy_source_type_enum.ELECTRICITY_MIX,
    energy_source_type_enum.ELECTRICITY_GREEN,
    energy_source_type_enum.SOLAR,
  ],
  ELECTRIC_IMMERSION_HEATER: [
    energy_source_type_enum.ELECTRICITY_MIX,
    energy_source_type_enum.ELECTRICITY_GREEN,
    energy_source_type_enum.SOLAR,
  ],
  WOOD_BOILER: [energy_source_type_enum.WOOD, energy_source_type_enum.WOODEN_PELLETS],
  WOOD_FURNACE: [energy_source_type_enum.WOOD, energy_source_type_enum.WOODEN_PELLETS],
  SOLAR_PLANT: [energy_source_type_enum.SOLAR],
  DISTRICT_HEATING_WITH_KWK: [
    energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE,
    energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS,
    energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL,
  ],
  DISTRICT_HEATING_WITHOUT_KWK: [
    energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL,
    energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS,
    energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE,
  ],
  GAS_FLOOR_HEATING: [
    energy_source_type_enum.BIO_GAS,
    energy_source_type_enum.NATURAL_GAS,
    energy_source_type_enum.LPG,
  ],
  GAS_FLOW_HEATER: [energy_source_type_enum.BIO_GAS, energy_source_type_enum.NATURAL_GAS, energy_source_type_enum.LPG],
  GAS_NON_CONDENSING_BOILER: [
    energy_source_type_enum.BIO_GAS,
    energy_source_type_enum.NATURAL_GAS,
    energy_source_type_enum.LPG,
  ],
  GAS_CONDENSING_BOILER: [
    energy_source_type_enum.BIO_GAS,
    energy_source_type_enum.NATURAL_GAS,
    energy_source_type_enum.LPG,
  ],
  GAS_ROOM_HEATER: [energy_source_type_enum.BIO_GAS, energy_source_type_enum.NATURAL_GAS, energy_source_type_enum.LPG],
  LOW_TEMPERATURE_BOILER: [energy_source_type_enum.FUEL_OIL],
  STANDARD_BOILER: [energy_source_type_enum.FUEL_OIL],
  OIL_FURNACE: [energy_source_type_enum.FUEL_OIL],
  COAL_FURNACE: [energy_source_type_enum.COAL, energy_source_type_enum.LIGNITE],
  CONDENSING_BOILER: [energy_source_type_enum.FUEL_OIL],

  INCANDESCENT_LAMPS: [energy_source_type_enum.ELECTRICITY_MIX, energy_source_type_enum.ELECTRICITY_GREEN],
  FLUORESCENT_LAMPS: [energy_source_type_enum.ELECTRICITY_MIX, energy_source_type_enum.ELECTRICITY_GREEN],
  LED: [energy_source_type_enum.ELECTRICITY_MIX, energy_source_type_enum.ELECTRICITY_GREEN],
  HALOGEN_LAMPS: [energy_source_type_enum.ELECTRICITY_MIX, energy_source_type_enum.ELECTRICITY_GREEN],

  CENTRAL_AIR_CONDITIONING: [energy_source_type_enum.ELECTRICITY_MIX, energy_source_type_enum.ELECTRICITY_GREEN],
  CENTRAL_EXHAUST: [energy_source_type_enum.ELECTRICITY_MIX, energy_source_type_enum.ELECTRICITY_GREEN],
  CENTRAL_SUPPLY_EXHAUST_WITH_HRU: [energy_source_type_enum.ELECTRICITY_MIX, energy_source_type_enum.ELECTRICITY_GREEN],
  CENTRAL_SUPPLY_EXHAUST_WITHOUT_HRU: [
    energy_source_type_enum.ELECTRICITY_MIX,
    energy_source_type_enum.ELECTRICITY_GREEN,
  ],
  DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITH_HRU: [
    energy_source_type_enum.ELECTRICITY_MIX,
    energy_source_type_enum.ELECTRICITY_GREEN,
  ],
  DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITHOUT_HRU: [
    energy_source_type_enum.ELECTRICITY_MIX,
    energy_source_type_enum.ELECTRICITY_GREEN,
  ],
  REVERSIBLE_AIR_WATER_HEAT_PUMP: [energy_source_type_enum.ELECTRICITY_MIX, energy_source_type_enum.ELECTRICITY_GREEN],
  REVERSIBLE_BRINE_WATER_HEAT_PUMP: [
    energy_source_type_enum.ELECTRICITY_MIX,
    energy_source_type_enum.ELECTRICITY_GREEN,
  ],
  ROOM_AIR_CONDITIONING: [energy_source_type_enum.ELECTRICITY_MIX, energy_source_type_enum.ELECTRICITY_GREEN],
  ELECTRICITY_MISCELLANEOUS: [energy_source_type_enum.ELECTRICITY_MIX, energy_source_type_enum.ELECTRICITY_GREEN],
};
