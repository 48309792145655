import { Link } from 'react-router-dom';
import TableRowWithHighlight, { TableRowWithHighlightProps } from '../../table/TableRowWithHighlight';

export type DataTableRowProps = TableRowWithHighlightProps & {
  to?: string;
};

const DataTableRow = ({ to, sx, ...props }: DataTableRowProps) => {
  const hasLink = to !== undefined;

  return (
    <TableRowWithHighlight
      component="div"
      {...(hasLink && {
        component: Link,
        to,
        hover: true,
        sx: [
          {
            textDecoration: 'none',
            '& > *': { borderBottom: 'unset' },
            cursor: 'pointer',
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ],
      })}
      {...props}
    />
  );
};

export default DataTableRow;
