/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Card, CardProps } from '@mui/material';
import { pathValuesFromYear } from '@predium/client-lookup';
import { fShortenNumber } from '@predium/utils';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import BaseOptionChart from '../../../theme/apexcharts';
import { disableCurserPointerForMarkers } from '../PathGraph';

interface Props extends CardProps {
  co2Costs: number[];
  co2CostsTotal: number[];
  landlordProportion: number;
  showTotal: boolean;
  animated?: boolean;
}

export default function CO2CostDevelopmentGraph({
  co2Costs,
  co2CostsTotal,
  landlordProportion,
  showTotal,
  animated,
  sx,
}: Props) {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const co2CostsFromCurrentYear = pathValuesFromYear(showTotal ? co2CostsTotal : co2Costs, currentYear);

  const tenantCosts: [number, number][] = co2CostsFromCurrentYear.map((costs, idx) => [
    currentYear + idx,
    (costs * (100 - landlordProportion)) / 100,
  ]);
  const landlordCosts: [number, number][] = co2CostsFromCurrentYear.map((costs, idx) => [
    currentYear + idx,
    costs * (landlordProportion / 100),
  ]);

  const series = [
    {
      name: t('General_OwnersShare'),
      data: landlordCosts,
      color: '#22609B',
    },
    {
      name: t('General_TenantsShare'),
      data: tenantCosts,
      color: '#A7C0D7',
    },
  ];

  const chartState: ApexCharts.ApexOptions = merge(BaseOptionChart(), {
    chart: {
      animations: {
        enabled: !!animated,
      },
      type: 'area',
      stacked: true,
      events: {
        mounted: function (chart: any) {
          disableCurserPointerForMarkers(chart.el.id);
        },
        updated: function (chart: any) {
          disableCurserPointerForMarkers(chart.el.id);
        },
      },
    },
    title: {
      text: t('CO2CostDevelopmentGraph_Title'),
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    yaxis: {
      labels: {
        formatter: (value: number) => (value === Infinity ? 0 : `${fShortenNumber(value, 2)}`),
      },
      title: {
        text: t('General_CO2Taxes') + ' ' + (showTotal ? '(€/a)' : '(€/m²a)'),
      },
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      labels: {
        formatter: (value: number, timestamp: number) => (timestamp % 5 === 0 ? value : ''),
      },
    },
    stroke: {
      width: 3,
    },
    markers: {
      // Weird issue which is described and "resolved" here: https://github.com/apexcharts/apexcharts.js/issues/1427#issuecomment-603893948
      size: 0.001,
    },
    tooltip: {
      y: {
        formatter: (value: number) => `${fShortenNumber(value, 2)} €`,
      },
      inverseOrder: true,
    },
  });

  return (
    <Card sx={{ p: 3, pb: 1, ...sx }}>
      <ReactApexChart
        id="co2_cost_dev_graph"
        //@ts-ignore
        type={chartState.chart.type}
        options={chartState}
        series={series}
        height={350}
      />
    </Card>
  );
}
