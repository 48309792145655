import { Box, Chip, ChipProps, alpha, useTheme } from '@mui/material';
import { subsidy_type_enum } from '@predium/enums';
import { translateSubsidyTypeEnum } from '@predium/i18n/client';

type SubsidyTypeBadgeProps = {
  type: subsidy_type_enum;
  size: ChipProps['size'];
};

export function SubsidyTypeBadge({ type, size }: SubsidyTypeBadgeProps) {
  const theme = useTheme();
  return (
    <Box>
      <Chip
        label={translateSubsidyTypeEnum(type)}
        size={size}
        sx={{
          bgcolor: alpha(theme.palette.subsidyTypeColors[type].lighter, 0.2),
          color: theme.palette.subsidyTypeColors[type].main,
          mr: 1,
          fontWeight: 500,
        }}
      />
    </Box>
  );
}
