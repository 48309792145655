import { useContext } from 'react';
import { BuildingContext } from './BuildingContext';

const useBuilding = () => {
  const value = useContext(BuildingContext);

  if (!value) {
    throw new Error('useBuilding must be used within a BuildingProvider');
  }

  return value;
};

export default useBuilding;
