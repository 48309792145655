import { Icon, IconProps, IconifyIcon } from '@iconify/react';
import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  icon: IconifyIcon | string;
}

/**
 * Minimal@4 component.
 */
export default function Iconify({ icon, sx, ...other }: Props & IconProps) {
  return <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />;
}
