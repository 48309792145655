import { Box, Button, CardContent, Stack, alpha, useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import Iconify from '../../../../../../../components/Iconify';
import useBuilding from '../../../../Context/useBuilding';
import { TechnologyArrow, TechnologyCardHeader } from './TechnologyUnit';

type Props = {
  icon: string;
  title: string;
  showArrow?: boolean;
  onButtonClick: () => void;
  noDataText: string;
  requiredMessage?: string;
};

const EmptyTechnologyUnitCard = ({
  title,
  icon,
  showArrow = true,
  noDataText,
  onButtonClick,
  requiredMessage,
}: Props) => {
  const theme = useTheme();
  const { hasEditAccess } = useBuilding();

  return (
    <Stack direction={'row'} position={'relative'}>
      <Card
        sx={{
          '.MuiCardContent-root:last-child': {
            p: 'unset',
          },
          background: theme.palette.grey[100],
          width: '100%',
        }}
      >
        <TechnologyCardHeader icon={icon} title={title} />

        <CardContent sx={{ p: 'unset' }}>
          <Box px={3} py={2} textAlign={'left'} mb={1}>
            <Typography variant="caption" color={'text.secondary'} component={'div'}>
              {noDataText}
            </Typography>
            {requiredMessage && (
              <Typography variant="caption" color={'error'} component={'div'}>
                {requiredMessage}
              </Typography>
            )}
            {hasEditAccess && (
              <Button
                variant="outlined"
                onClick={() => {
                  onButtonClick();
                }}
                size="small"
                startIcon={<Iconify icon={'mdi:plus'} />}
                sx={{
                  borderColor: alpha(theme.palette.grey[500], 0.24),
                  mt: 1,
                }}
              >
                {t('General_Add')}
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>

      {showArrow && <TechnologyArrow />}
    </Stack>
  );
};

export default EmptyTechnologyUnitCard;
