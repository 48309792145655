import { useMutation, useQuery } from '@apollo/client';
import { Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { COMPLETE_REPORT } from '../../../graphql/Report.mutations';
import { GET_REPORT_COMPLETION } from '../../../graphql/Report.queries';
import usePosthogTracking from '../../../hooks/usePosthogTracking';
import { PATHS } from '../../../routes';
import { ReportDC } from '../ReportingDataClasses/Report.dc';

type Props = {
  title: string;
  report: ReportDC;
};

function ReportComplete({ title, report }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { trackEvent } = usePosthogTracking();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState(false);

  const [completeReport] = useMutation(COMPLETE_REPORT, {
    onCompleted: () => {
      enqueueSnackbar(t('ReportComplete_CompleteReport-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      navigate(PATHS.reporting.reports());
    },
    onError: () =>
      enqueueSnackbar(t('ReportComplete_CompleteReport-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
  });
  const { data, loading } = useQuery(GET_REPORT_COMPLETION, {
    variables: { id: report.id },
  });

  const onSubmit = async () => {
    setDialogOpen(false);
    const result = await completeReport({
      variables: {
        id: report.id,
      },
    });
    if (result.data?.update_report_by_pk?.completed) {
      trackEvent('REPORT_COMPLETED', {
        template: report.report_template.title,
      });
    }
  };

  return (
    <>
      {report.completed ? (
        <Typography color="success.main" variant="h6">
          {t('Reporting_ReportCompleteSuccessMessage')}
        </Typography>
      ) : (
        <Button
          style={{ width: 200 }}
          fullWidth
          size="large"
          variant="contained"
          disabled={loading || data?.report_by_pk?.progress !== 1}
          onClick={() => setDialogOpen(true)}
        >
          {t('ReportComplete_ReportComplete-button')}
        </Button>
      )}

      <DeleteConfirmationModal
        open={dialogOpen}
        title={t('ReportComplete_CompleteReportConfirmation-title')}
        description={t('ReportComplete_CompleteReportConfirmation-description', { title })}
        onClose={() => {
          setDialogOpen(false);
        }}
        onDelete={() => {}}
        overrideDeleteButton={
          <Button type="submit" variant="contained" onClick={onSubmit}>
            {t('ReportComplete_CompleteReportConfirmation-button')}
          </Button>
        }
      />
    </>
  );
}

export default ReportComplete;
