import { MenuItem, TextFieldProps } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useController, useFormContext } from 'react-hook-form';
import { DropdownIcon } from '../../sections/Scenarios/ActionPlan/Components/ActionCustomStyles';

type Props = {
  name: string;
  children: any;
  /**
   * Select doesnt have a readonly attribute. We display disabled instead because rendering a Textfield instead might have question for form handling.
   */
  disabled?: boolean;
  /**
   * Beware native is a workaround for un-refactorable old code. Do not use native selects as the style is weird and behavior inconsistent.
   */
  native?: boolean;
  onValueChange?: (value: any) => void;
  /**
   * If true an empty option will be included in the select with an empty string as value. (MUI requirement for a select without a value)
   * This option should only be set if a nullable value is a valid user input on the form. It does not make sense to provide a nullable input on a required form field.
   * Make sure to handle this null value both in your submit function and form schema validation properly via a check for an empty string with `.when()` Yup modifier.
   */
  includeEmptyOption?: boolean;
};

/**
 * Minimal@4 component.
 */

export default function RHFSelect({
  name,
  children,
  disabled = false,
  native = false,
  onValueChange,
  helperText,
  includeEmptyOption = false,
  ...other
}: Props & TextFieldProps) {
  const { control } = useFormContext();
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({ name, control });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    field.onChange(event.target.value);
    if (onValueChange) {
      onValueChange(event.target.value);
    }
  };

  return (
    <TextField
      {...field}
      fullWidth
      select
      error={!!error}
      helperText={error ? error.message : helperText}
      disabled={disabled}
      inputRef={ref}
      onChange={handleChange}
      // Beware native is a workaround for un-refactorable old code. Do not use native selects as the style is weird and behavior inconsistent.
      SelectProps={{ native, IconComponent: DropdownIcon }}
      {...other}
    >
      {includeEmptyOption && <MenuItem value="" style={{ height: 36 }}></MenuItem>}
      {children}
    </TextField>
  );
}
