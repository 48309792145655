import { insulation_method_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateInsulationMethodEnum = (enumValue: insulation_method_enum) => {
  switch (enumValue) {
    case insulation_method_enum.ABOVE_RAFTER:
      return <Trans i18nKey="Enum_InsulationMethodEnum-ABOVE_RAFTER" />;
    case insulation_method_enum.BELOW_RAFTER:
      return <Trans i18nKey="Enum_InsulationMethodEnum-BELOW_RAFTER" />;
    case insulation_method_enum.BETWEEN_RAFTER:
      return <Trans i18nKey="Enum_InsulationMethodEnum-BETWEEN_RAFTER" />;
    case insulation_method_enum.BLOW_IN_INSULATION:
      return <Trans i18nKey="Enum_InsulationMethodEnum-BLOW_IN_INSULATION" />;
    case insulation_method_enum.COLD_ROOF_BETWEEN_RAFTER:
      return <Trans i18nKey="Enum_InsulationMethodEnum-COLD_ROOF_BETWEEN_RAFTER" />;
    case insulation_method_enum.FACING_SHELL:
      return <Trans i18nKey="Enum_InsulationMethodEnum-FACING_SHELL" />;
    case insulation_method_enum.FROM_BELOW:
      return <Trans i18nKey="Enum_InsulationMethodEnum-FROM_BELOW" />;
    case insulation_method_enum.INSULATION_BOARDS:
      return <Trans i18nKey="Enum_InsulationMethodEnum-INSULATION_BOARDS" />;
    case insulation_method_enum.INSULATION_BOARDS_WALKABLE:
      return <Trans i18nKey="Enum_InsulationMethodEnum-INSULATION_BOARDS_WALKABLE" />;
    case insulation_method_enum.INSULATION_BOARDS_WITH_CLADDING:
      return <Trans i18nKey="Enum_InsulationMethodEnum-INSULATION_BOARDS_WITH_CLADDING" />;
    case insulation_method_enum.INSULATION_GRADIENT:
      return <Trans i18nKey="Enum_InsulationMethodEnum-INSULATION_GRADIENT" />;
    case insulation_method_enum.INSULATION_MATS:
      return <Trans i18nKey="Enum_InsulationMethodEnum-INSULATION_MATS" />;
    case insulation_method_enum.INTERIOR_PLASTER_SYSTEM:
      return <Trans i18nKey="Enum_InsulationMethodEnum-INTERIOR_PLASTER_SYSTEM" />;
    case insulation_method_enum.INVERTED_ROOF:
      return <Trans i18nKey="Enum_InsulationMethodEnum-INVERTED_ROOF" />;
    case insulation_method_enum.ON_RAFTER:
      return <Trans i18nKey="Enum_InsulationMethodEnum-ON_RAFTER" />;
    case insulation_method_enum.VHF:
      return <Trans i18nKey="Enum_InsulationMethodEnum-VHF" />;
    case insulation_method_enum.WARM_ROOF:
      return <Trans i18nKey="Enum_InsulationMethodEnum-WARM_ROOF" />;
    case insulation_method_enum.WDVS:
      return <Trans i18nKey="Enum_InsulationMethodEnum-WDVS" />;
  }
};

export const translateInsulationMethodEnum_dynamic = (
  enumValue: insulation_method_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case insulation_method_enum.ABOVE_RAFTER:
      return t('Enum_InsulationMethodEnum-ABOVE_RAFTER');
    case insulation_method_enum.BELOW_RAFTER:
      return t('Enum_InsulationMethodEnum-BELOW_RAFTER');
    case insulation_method_enum.BETWEEN_RAFTER:
      return t('Enum_InsulationMethodEnum-BETWEEN_RAFTER');
    case insulation_method_enum.BLOW_IN_INSULATION:
      return t('Enum_InsulationMethodEnum-BLOW_IN_INSULATION');
    case insulation_method_enum.COLD_ROOF_BETWEEN_RAFTER:
      return t('Enum_InsulationMethodEnum-COLD_ROOF_BETWEEN_RAFTER');
    case insulation_method_enum.FACING_SHELL:
      return t('Enum_InsulationMethodEnum-FACING_SHELL');
    case insulation_method_enum.FROM_BELOW:
      return t('Enum_InsulationMethodEnum-FROM_BELOW');
    case insulation_method_enum.INSULATION_BOARDS:
      return t('Enum_InsulationMethodEnum-INSULATION_BOARDS');
    case insulation_method_enum.INSULATION_BOARDS_WALKABLE:
      return t('Enum_InsulationMethodEnum-INSULATION_BOARDS_WALKABLE');
    case insulation_method_enum.INSULATION_BOARDS_WITH_CLADDING:
      return t('Enum_InsulationMethodEnum-INSULATION_BOARDS_WITH_CLADDING');
    case insulation_method_enum.INSULATION_GRADIENT:
      return t('Enum_InsulationMethodEnum-INSULATION_GRADIENT');
    case insulation_method_enum.INSULATION_MATS:
      return t('Enum_InsulationMethodEnum-INSULATION_MATS');
    case insulation_method_enum.INTERIOR_PLASTER_SYSTEM:
      return t('Enum_InsulationMethodEnum-INTERIOR_PLASTER_SYSTEM');
    case insulation_method_enum.INVERTED_ROOF:
      return t('Enum_InsulationMethodEnum-INVERTED_ROOF');
    case insulation_method_enum.ON_RAFTER:
      return t('Enum_InsulationMethodEnum-ON_RAFTER');
    case insulation_method_enum.VHF:
      return t('Enum_InsulationMethodEnum-VHF');
    case insulation_method_enum.WARM_ROOF:
      return t('Enum_InsulationMethodEnum-WARM_ROOF');
    case insulation_method_enum.WDVS:
      return t('Enum_InsulationMethodEnum-WDVS');
  }
};
