import { Box, CardContent, Stack, alpha, useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
import Iconify from '../../../../../../../components/Iconify';
import { SemanticButton } from '../../../../../../../components/Tile';
import useBuilding from '../../../../Context/useBuilding';

type Props = {
  icon: string;
  title: string;
  subtitle: ReactNode;
  showArrow?: boolean;
  body: ReactNode;
  onSemanticButtonClick: () => void;
  isDeleted?: boolean;
};

const TechnologyUnit = ({ title, subtitle, icon, showArrow = true, body, onSemanticButtonClick, isDeleted }: Props) => {
  const theme = useTheme();
  const { hasEditAccess } = useBuilding();

  return (
    <Stack direction={'row'} position={'relative'}>
      <SemanticButton
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          !isDeleted && onSemanticButtonClick();
        }}
        disabled={!hasEditAccess}
        sx={{
          width: '100%',
          '&:focus': {
            borderColor: theme.palette.grey[500],
          },
          borderRadius: '16px',
          cursor: !isDeleted ? 'pointer' : 'auto',
          border: `1px solid`,
          borderColor: alpha(theme.palette.grey[500], 0.2),
          '&:hover': {
            borderColor: theme.palette.grey[500],
          },
        }}
      >
        <Card
          sx={{
            '.MuiCardContent-root:last-child': {
              p: 'unset',
            },
          }}
        >
          <TechnologyCardHeader icon={icon} title={title} />
          <CardContent sx={{ p: 'unset' }}>
            <>
              {subtitle}
              <Box sx={{ background: theme.palette.grey[100] }}>
                <Box px={3} py={2}>
                  {body}
                </Box>
              </Box>
            </>
          </CardContent>
        </Card>
      </SemanticButton>

      {showArrow && <TechnologyArrow />}
    </Stack>
  );
};

export default TechnologyUnit;

type TechnologyCardHeaderProps = {
  icon: string;
  title: string;
};

export const TechnologyCardHeader: React.FC<TechnologyCardHeaderProps> = ({ icon, title }) => {
  return (
    <CardHeader
      avatar={<Box component="img" src={icon} width={18} height={18} />}
      title={
        <Typography variant="subtitle2" fontWeight={700} textTransform={'uppercase'} color={'text.secondary'} ml={-1}>
          {title}
        </Typography>
      }
      sx={{ textAlign: 'start' }}
    />
  );
};

export const TechnologyArrow: React.FC = () => {
  const theme = useTheme();

  return (
    <Box px={1} mt={3} position={'absolute'} right={-40} top={0}>
      <Iconify icon="mdi:arrow-right" color={theme.palette.text.secondary} width={24} height={24} />
    </Box>
  );
};
