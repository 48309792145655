/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Stack } from '@mui/material';
import { area_type_enum, data_source_type_enum } from '@predium/enums';
import { ComponentProps, ReactNode } from 'react';
import CustomDataSourceInput, { CustomDataSourceInputProps } from '../../../../../components/custom-data-source-input';
import useBuilding from '../../Context/useBuilding';
import { useRecalculate } from '../RecalculationProvider';
import SquareMeterRowError from './SquareMeterRowError';
import SquareMeterRowLabel from './SquareMeterRowLabel';

type BaseProps = {
  actions?: ReactNode;
  source?: data_source_type_enum;
  areaType: area_type_enum;
  onClickAway?: CustomDataSourceInputProps['onClickAway'];
  hideEquationError?: boolean;
} & ComponentProps<typeof SquareMeterRowLabel>;

export type EditableRowProps = {
  name: string;
} & BaseProps;

export type StaticRowProps = {
  value: number;
} & BaseProps;

type Props = EditableRowProps | StaticRowProps;

const SquareMeterRow = ({
  icon,
  actions,
  labels,
  source,
  titleProps,
  areaType,
  onClickAway,
  hideEquationError = false,
  ...props
}: Props) => {
  const { hasEditAccess } = useBuilding();

  const { isRecalculationLoading } = useRecalculate();

  const isNUF = areaType === area_type_enum.NUF;

  return (
    <Stack direction="row" justifyContent="space-between" height={40}>
      <SquareMeterRowLabel icon={icon} labels={labels} titleProps={titleProps} />
      <Stack direction="row" alignItems="center" spacing={1}>
        {!hideEquationError && <SquareMeterRowError areaType={areaType} />}
        <CustomDataSourceInput
          {...props}
          suffix="m²"
          source={source}
          actions={hasEditAccess && actions}
          onClickAway={onClickAway}
          isLoading={!isNUF && isRecalculationLoading}
          ignoreIsDirty
        />
      </Stack>
    </Stack>
  );
};

export default SquareMeterRow;
