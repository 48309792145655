import { language_enum } from '@predium/enums';
import dayjs from 'dayjs';

// ----------------------------------------------------------------------

/**
 * German date formats that are commonly used.
 * TODO: Should be made shareable between backend and frontend.
 */
export enum COMMON_DATE_FORMATS {
  DAY_MONTH_YEAR,
  DAY_MONTH_YEAR_TIME,
}

const DAYJS_LOCALE: Record<language_enum, string> = {
  [language_enum.deDE]: 'de',
  [language_enum.enGB]: 'en-gb',
};

export function formatDateToLocale(
  date: string | Date,
  formatEnum: COMMON_DATE_FORMATS,
  language: language_enum,
): string {
  const dateFormatString = formatEnum === COMMON_DATE_FORMATS.DAY_MONTH_YEAR ? 'L' : 'L LT';
  const locale = DAYJS_LOCALE[language];

  return dayjs(date).locale(locale).format(dateFormatString);
}

export function durationFromNow(date: string, language: language_enum) {
  const locale = DAYJS_LOCALE[language];

  return dayjs(date).locale(locale).fromNow();
}
