import { type_of_use_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateTypeOfUseEnum = (enumValue: type_of_use_enum) => {
  switch (enumValue) {
    case type_of_use_enum.DISTRIBUTION_WAREHOUSE_COLD:
      return <Trans i18nKey="Enum_TypeOfUseEnum-DISTRIBUTION_WAREHOUSE_COLD" />;
    case type_of_use_enum.DISTRIBUTION_WAREHOUSE_WARM:
      return <Trans i18nKey="Enum_TypeOfUseEnum-DISTRIBUTION_WAREHOUSE_WARM" />;
    case type_of_use_enum.HEALTH_FACILITY:
      return <Trans i18nKey="Enum_TypeOfUseEnum-HEALTH_FACILITY" />;
    case type_of_use_enum.HOTEL:
      return <Trans i18nKey="Enum_TypeOfUseEnum-HOTEL" />;
    case type_of_use_enum.MFH:
      return <Trans i18nKey="Enum_TypeOfUseEnum-MFH" />;
    case type_of_use_enum.OFFICE:
      return <Trans i18nKey="Enum_TypeOfUseEnum-OFFICE" />;
    case type_of_use_enum.RETAIL:
      return <Trans i18nKey="Enum_TypeOfUseEnum-RETAIL" />;
    case type_of_use_enum.RETAIL_WAREHOUSE:
      return <Trans i18nKey="Enum_TypeOfUseEnum-RETAIL_WAREHOUSE" />;
    case type_of_use_enum.SFH:
      return <Trans i18nKey="Enum_TypeOfUseEnum-SFH" />;
    case type_of_use_enum.SHOPPING_CENTER:
      return <Trans i18nKey="Enum_TypeOfUseEnum-SHOPPING_CENTER" />;
  }
};

export const translateTypeOfUseEnum_dynamic = (enumValue: type_of_use_enum, t: TFunction<'translation', undefined>) => {
  switch (enumValue) {
    case type_of_use_enum.DISTRIBUTION_WAREHOUSE_COLD:
      return t('Enum_TypeOfUseEnum-DISTRIBUTION_WAREHOUSE_COLD');
    case type_of_use_enum.DISTRIBUTION_WAREHOUSE_WARM:
      return t('Enum_TypeOfUseEnum-DISTRIBUTION_WAREHOUSE_WARM');
    case type_of_use_enum.HEALTH_FACILITY:
      return t('Enum_TypeOfUseEnum-HEALTH_FACILITY');
    case type_of_use_enum.HOTEL:
      return t('Enum_TypeOfUseEnum-HOTEL');
    case type_of_use_enum.MFH:
      return t('Enum_TypeOfUseEnum-MFH');
    case type_of_use_enum.OFFICE:
      return t('Enum_TypeOfUseEnum-OFFICE');
    case type_of_use_enum.RETAIL:
      return t('Enum_TypeOfUseEnum-RETAIL');
    case type_of_use_enum.RETAIL_WAREHOUSE:
      return t('Enum_TypeOfUseEnum-RETAIL_WAREHOUSE');
    case type_of_use_enum.SFH:
      return t('Enum_TypeOfUseEnum-SFH');
    case type_of_use_enum.SHOPPING_CENTER:
      return t('Enum_TypeOfUseEnum-SHOPPING_CENTER');
  }
};
