import { Stack, Tooltip, Typography } from '@mui/material';
import Iconify from '../../../../components/Iconify';

export default function PartialRenovationOptionSubtext({
  value,
  icon,
  title,
}: {
  value?: string;
  icon: string;
  title: string;
}) {
  if (!value && value === 'undefined') return null;
  return (
    <Tooltip title={title} arrow placement="top" enterDelay={200}>
      <Stack flexDirection={'row'} alignItems={'center'} gap={0.25}>
        <Iconify icon={icon} width="16px" height="16px" color="text.secondary" />
        <Typography variant="body2" color="text.secondary" whiteSpace={'nowrap'}>
          {value}
        </Typography>
      </Stack>
    </Tooltip>
  );
}
