import { deDE, enUS } from '@mui/material/locale';
import { ThemeProvider, alpha, createTheme, useTheme } from '@mui/material/styles';
import { language_enum } from '@predium/enums';
import merge from 'lodash/merge';
import { ReactNode, useMemo } from 'react';
import { useLanguage } from '../provider/LanguageProvider';
import { defaultPreset } from '../utils/getColorPresets';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

/**
 * Minimal@4 component.
 */
export default function ThemeColorPresets({ children }: Props) {
  const outerTheme = useTheme();
  const { language } = useLanguage();

  const getMuiLocale = (language: language_enum) => {
    switch (language) {
      case language_enum.deDE:
        return deDE;
      case language_enum.enGB:
        return enUS;
      default:
        const exhaustiveCheck: never = language;
        throw new Error(`Unhandled MUI locale: ${exhaustiveCheck}`);
    }
  };

  const themeOptions = useMemo(
    () => ({
      palette: {
        primary: defaultPreset,
      },
      customShadows: {
        primary: `0 8px 16px 0 ${alpha(defaultPreset.main, 0.24)}`,
      },
    }),
    [],
  );

  const theme = createTheme(merge(outerTheme, themeOptions), getMuiLocale(language));

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
