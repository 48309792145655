import { Alert, Box, CircularProgress, Grid, LinearProgress, Stack, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { fData } from '@predium/utils';
import Dropzone from 'dropzone';
import union from 'lodash/union';
import { useSnackbar } from 'notistack';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from './Iconify';
import { SnackbarTimeouts } from './NotistackProvider';
import Scrollbar from './Scrollbar';

export type UploadingFile = {
  fileId: number;
  fileUrl: string;
};

export type FileId = {
  fileId: number;
};

type Props = {
  fileDisplayName: string;
  /**
   * Comma separated list of accepted file types.
   * Non accepted files will be rejected with an error message.
   */
  fileType?: string;
  title?: string;
  description: ReactNode;
  maxFiles: number;
  defaultValue?: Dropzone.DropzoneFile[];
  createUploadUrl: () => Promise<UploadingFile>;
  onUploadSuccess: (file: Dropzone.DropzoneFile & FileId) => void;
  setUploadInProgress: (uploadInProgress: boolean) => void;
};

export default function DropzoneComponent({
  fileDisplayName,
  fileType = 'application/pdf',
  title,
  description,
  maxFiles,
  defaultValue = [],
  createUploadUrl,
  onUploadSuccess,
  setUploadInProgress,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const dropzoneNode = useRef<HTMLDivElement | null>(null);
  const dropzone = useRef<Dropzone | null>(null);
  const [maxFilesReached, setMaxFilesReached] = useState<boolean>(false);
  const [handledFiles, setHandledFiles] = useState<Dropzone.DropzoneFile[]>(defaultValue);
  const [dropzoneInitialized, setDropzoneInitialized] = useState<boolean>(false);
  const [totalFiles, setTotalFiles] = useState(0);
  const [succeedCount, setSucceedCount] = useState(0);

  const [totalUploadProgress, setTotalUploadProgress] = useState(0);

  const updateHandledFiles = (file: Dropzone.DropzoneFile) => {
    setHandledFiles((prevFiles) => union(prevFiles, [file]));
  };

  useEffect(() => {
    async function uploadFiles() {
      if (!dropzoneInitialized && dropzoneNode.current) {
        Dropzone.autoDiscover = false;

        dropzone.current = new Dropzone(dropzoneNode.current, {
          method: 'PUT',
          error(file, message) {
            if (!file.accepted) {
              if (file.type !== fileType) {
                enqueueSnackbar(
                  t('DropzoneComponent_UploadFileSnackbar-FileTypeError', {
                    fileName: file.name,
                    fileType: fileType,
                  }),
                  { variant: 'error', autoHideDuration: SnackbarTimeouts.Error },
                );
              } else {
                enqueueSnackbar(
                  t('DropzoneComponent_UploadFileSnackbar-Error', { fileName: file.name, message: message }),
                  {
                    variant: 'error',
                    autoHideDuration: SnackbarTimeouts.Error,
                  },
                );
              }

              // If the file is not accepted, remove it from the dropzone to allow further valid requests.
              if (dropzone.current) dropzone.current.removeFile(file);
            }
          },
          removedfile() {
            updateTotalUploadProgress();
          },
          accept: async (file: any, done) => {
            if (!dropzone.current) {
              console.error('Dropzone not initialized');
              return;
            }

            setTotalFiles(dropzone.current.files.length);

            if (dropzone.current.files.length > maxFiles) {
              setMaxFilesReached(true);
              return;
            }
            // Create S3 url only if we're not exceeding the max files
            createUploadUrl().then((uploadedFile: UploadingFile) => {
              file.dynamicUploadUrl = uploadedFile.fileUrl;
              file.fileId = uploadedFile.fileId;
              done();
            });

            if (dropzone.current.files.length === maxFiles) {
              setMaxFilesReached(true);
            }
          },
          addedfiles: () => {
            setUploadInProgress(true);
          },
          url: (files) => {
            const file: any = files[0];
            return file.dynamicUploadUrl;
          },
          acceptedFiles: fileType,
          headers: {
            'Content-Type': 'application/pdf',
          },
          maxFiles: maxFiles,
          maxFilesize: 10485760,
          maxfilesexceeded: (file) => {
            if (!dropzone.current) {
              console.error('Dropzone not initialized');
              return;
            }

            // Delete file if greater max files
            dropzone.current.removeFile(file);
          },
          success(file: Dropzone.DropzoneFile & FileId) {
            onUploadSuccess(file);
            setSucceedCount((prevCount) => prevCount + 1);
          },
          previewsContainer: false,
          complete(file) {
            updateHandledFiles(file);
            updateTotalUploadProgress();
          },
          parallelUploads: 1,
          queuecomplete() {
            // Beware, this is actually not called when everything is done...
            setUploadInProgress(false);
          },
        });
        setDropzoneInitialized(true);
      }
      return () => {
        if (dropzone.current) {
          dropzone.current.off();
          dropzone.current.destroy();
        }
      };
    }
    uploadFiles();
  }, [
    createUploadUrl,
    maxFiles,
    onUploadSuccess,
    fileType,
    enqueueSnackbar,
    dropzoneInitialized,
    setUploadInProgress,
    t,
  ]);

  useEffect(() => {
    if (succeedCount === maxFiles) {
      setUploadInProgress(false);
    }
  }, [succeedCount, maxFiles, setUploadInProgress]);

  const updateTotalUploadProgress = () => {
    if (dropzone.current) {
      const totalProgress =
        dropzone.current.files.reduce((total, file) => total + (file.upload?.progress ?? 0), 0) /
        dropzone.current.files.length;
      setTotalUploadProgress(totalProgress);
    }
  };

  return (
    <Stack>
      {title && (
        <Typography variant="h5" sx={{ pt: 0, pb: 4 }}>
          {title}
        </Typography>
      )}

      <Grid
        className="dropzone"
        ref={dropzoneNode}
        style={{
          border: '1px dashed rgba(145, 158, 171, 0.20)',
          borderRadius: '8px',
          minHeight: '200px',
          backgroundColor: '#F4F6F8',
          cursor: 'pointer',
          ...(maxFilesReached && {
            cursor: 'not-allowed',
            pointerEvents: 'none',
            opacity: 0.7,
          }),
        }}
        alignItems="center"
        justifyContent="center"
        direction={'column'}
        container
        px={4}
        item
      >
        <Stack
          direction="row"
          alignItems="center"
          className="images-empty-messages-holder dz-message dz-default"
          sx={{ maxWidth: '500px', gap: '1rem' }}
        >
          <Iconify
            icon="fa-solid:file-upload"
            width={80}
            height={80}
            color={theme.palette.text.disabled}
            sx={{ pr: 2 }}
          />
          <Stack>
            <Typography variant="h4">{t('General_UploadFileName', { fileName: fileDisplayName })}</Typography>

            {description}
            <Typography>{t('DropzoneComponent_MaxFileWarning', { count: maxFiles })}</Typography>
          </Stack>
        </Stack>
      </Grid>

      {handledFiles.length > 0 && (
        <Box mr={-2}>
          <Scrollbar sx={{ maxHeight: 240 }}>
            {handledFiles.reverse().map((handledFile, idx) => (
              <DropzoneUploadProgress key={idx} file={handledFile} />
            ))}
          </Scrollbar>
        </Box>
      )}

      {totalUploadProgress > 0 && totalFiles > 1 && (
        <Stack mt={2}>
          <Stack direction={'row'} justifyContent={'end'}>
            <Typography variant="subtitle1">
              {succeedCount}/{totalFiles}
            </Typography>
          </Stack>
          <Stack>
            <LinearProgress variant="determinate" value={totalUploadProgress} />
          </Stack>
        </Stack>
      )}

      {maxFilesReached && maxFiles > 1 && (
        <Alert severity="warning" sx={{ mx: 2, mt: 2 }}>
          {t('DropzoneComponent_MaxNumberOfFilesWarning')}
        </Alert>
      )}
    </Stack>
  );
}

function DropzoneUploadProgress({ file }: { file: Dropzone.DropzoneFile }) {
  const fileStatus =
    file.upload && file.upload.progress >= 100
      ? 'done'
      : file.upload && file.upload.progress > 0
      ? 'progress'
      : 'waiting';
  const theme = useTheme();
  return (
    <Box sx={{ border: '1px solid rgba(57, 62, 67, 32%)', borderRadius: '8px', my: 2, mr: 2 }}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" sx={{ p: 1, pl: 2 }} alignItems="center" spacing={1}>
          <Iconify icon="bi:file-earmark-fill" width={30} height={30} />
          <Stack width={350}>
            <Tooltip title={file.name}>
              <Typography variant="h6" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {file.name}
              </Typography>
            </Tooltip>
            <Typography variant="caption">{fData(file.size)}</Typography>
          </Stack>
        </Stack>
        <Stack direction="row" sx={{ p: 2 }} alignItems="center" spacing={1}>
          {fileStatus === 'waiting' && (
            <>
              <Iconify icon="mdi:timer-sand-empty" width={30} height={28} />
            </>
          )}
          {fileStatus === 'progress' && (
            <>
              <Stack>
                <CircularProgress
                  variant="determinate"
                  key={file.upload?.progress ?? 0}
                  value={file.upload?.progress ?? 0}
                  size={26}
                  thickness={5}
                />
              </Stack>
            </>
          )}
          {fileStatus === 'done' && (
            <>
              <Iconify icon="material-symbols:check-circle" width={30} height={30} color={theme.palette.success.main} />
            </>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
