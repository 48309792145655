import { createContext, useContext } from 'react';

interface TContext {
  sortBy: string;
  sortDirection: 'asc' | 'desc';
  rowsPerPage: number;
  page: number;
  selectedItems: number[];
  onSelectItem: (item: number) => void;
  onTogglePageItemSelect: VoidFunction;
  setPage: (page: number) => void;
  onSortChange: (sortBy: string) => void;
}

const DataTableAPIContext = createContext<TContext | null>(null);

const useDataTableAPI = () => {
  const value = useContext(DataTableAPIContext);

  if (!value) {
    throw new Error('"useDataTableAPI" must be used inside "DataTableAPIProvider"!');
  }

  return value;
};

export { DataTableAPIContext, useDataTableAPI };
