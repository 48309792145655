import { Box, Button, Divider, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Iconify from './Iconify';
import Scrollbar from './Scrollbar';
import StepperNavigation, { CommonStepperNavigationProps } from './StepperNavigation';
import AccessRightsWrapper from './permission-tooltips/AccessRightsWrapper';

export type Props = {
  title: string;
  subTitle?: string;

  /**
   * The main content of the page. Left-aligned, taking up 75% of the width, usually a PDF viewer or similar display.
   */
  majorContent: React.ReactNode;
  /**
   * The minor content of the page. Right-aligned, taking up 25% of the width, usually a form.
   */
  minorContent: React.ReactNode;
  /**
   * Called when closing the view via the close button or "back" button.
   */
  onCloseCallback: () => void;
  /**
   * If set the navigation is shown. The provided props handle the navigation.
   */
  navigationStepperProps?: CommonStepperNavigationProps & {
    getDisplayText?: (params: { currentStep: number; maxSteps: number }) => string;
  };

  /**
   * If set the delete button is shown and the onDeleteCallback is called when the delete button is clicked
   */
  onDeleteCallback?: () => void;

  primaryAction: {
    /**
     * The label for the primary button. If not set, the default label is "Approve".
     */
    label?: string;
    /**
     * Called when the primary button is clicked.
     */
    onClick?: () => void;
    /**
     * If true, the primary button is disabled.
     * Also disables the delete button as user is likely missing write permissions.
     */
    isDisabled?: boolean;
  };

  secondaryAction: {
    /**
     * The label for the secondary button.
     */
    label: string;
    /**
     * Called when the secondary button is clicked.
     */
    onClick: () => void;
    /**
     * If true, the secondary button is disabled.
     */
    isDisabled?: boolean;
  };
};

export default function FocusViewPage({
  primaryAction,
  secondaryAction,
  majorContent,
  minorContent,
  navigationStepperProps,
  onDeleteCallback,
  onCloseCallback,
  subTitle,
  title,
}: Props) {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', marginX: 3 }}>
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginY: 3 }}>
        <Box style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
          <IconButton onClick={onCloseCallback} data-cy="cy-focusviewpage-back-button">
            <Iconify icon="mdi:chevron-left" width={20} height={20} />
          </IconButton>

          <Typography variant="h6">{title}</Typography>
          {subTitle && <Typography>{subTitle}</Typography>}
        </Box>

        <Box style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          {navigationStepperProps && (
            <StepperNavigation
              variant="vertical"
              {...navigationStepperProps}
              displayText={
                navigationStepperProps.getDisplayText
                  ? navigationStepperProps.getDisplayText({
                      currentStep: navigationStepperProps.activeStep + 1,
                      maxSteps: navigationStepperProps.maxSteps,
                    })
                  : t('General_InvoiceNumberOfTotal', {
                      current: navigationStepperProps.activeStep + 1,
                      total: navigationStepperProps.maxSteps,
                    })
              }
            />
          )}

          <Divider orientation="vertical" flexItem />

          <IconButton onClick={onCloseCallback} data-cy="cy-focusviewpage-close-button">
            <Iconify icon={'charm:cross'} width={20} height={20} />
          </IconButton>
        </Box>
      </Box>

      {/* Content */}
      <Box style={{ flexGrow: 1, display: 'flex', gap: 24 }}>
        <Box sx={{ width: '70%', maxHeight: 'calc(100vh - 40px - 16px - 16px - 36px - 36px - 16px)' }}>
          {majorContent}
        </Box>
        {/* content - header - footer */}
        <Scrollbar
          sx={{
            // to show scrollbar fully
            marginRight: 2,
            overflow: 'visible',
            width: 'calc(30vw - 24px)',
            maxHeight: 'calc(100vh - 40px - 24px - 24px - 36px - 24px - 24px)',
          }}
        >
          {minorContent}
        </Scrollbar>
      </Box>

      {/* Actions */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: onDeleteCallback ? 'row' : 'row-reverse',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginY: 3,
        }}
      >
        {onDeleteCallback && (
          <AccessRightsWrapper hasAccess={!primaryAction.isDisabled}>
            <Button variant="text" color="error" onClick={onDeleteCallback} data-cy="cy-focusviewpage-button-delete">
              {t('General_Delete')}
            </Button>
          </AccessRightsWrapper>
        )}

        <Box>
          <AccessRightsWrapper hasAccess={!secondaryAction.isDisabled}>
            <Button variant="outlined" onClick={secondaryAction.onClick}>
              {secondaryAction.label}
            </Button>
          </AccessRightsWrapper>

          <AccessRightsWrapper hasAccess={!primaryAction.isDisabled}>
            <Button sx={{ marginLeft: 1 }} variant="contained" onClick={() => primaryAction.onClick?.()} type="submit">
              {primaryAction.label ? primaryAction.label : t('General_Approve')}
            </Button>
          </AccessRightsWrapper>
        </Box>
      </Box>
    </Box>
  );
}
