import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import Iconify from './Iconify';

export type CommonStepperNavigationProps = {
  maxSteps: number;
  activeStep: number;
  handleBack: () => void;
  handleNext: () => void;
  backStepTitle: string;
  nextStepTitle: string;
};

type VerticalStepperNavigationProps = CommonStepperNavigationProps & {
  variant: 'vertical';
  displayText: string;
};

type HorizontalStepperNavigationProps = CommonStepperNavigationProps & {
  variant: 'horizontal';
};

type Props = VerticalStepperNavigationProps | HorizontalStepperNavigationProps;

export default function StepperNavigation(props: Props) {
  const { maxSteps, activeStep, handleBack, handleNext, backStepTitle, nextStepTitle, variant } = props;

  if (variant === 'horizontal') {
    return (
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Tooltip title={nextStepTitle}>
            <span>
              <IconButton onClick={handleNext} disabled={activeStep === maxSteps - 1} color="primary">
                <Iconify icon={'material-symbols:arrow-forward-ios'} width={20} height={20} />
              </IconButton>
            </span>
          </Tooltip>
        }
        backButton={
          <Tooltip title={backStepTitle}>
            <span>
              <IconButton onClick={handleBack} disabled={activeStep === 0} color="primary">
                <Iconify icon={'material-symbols:arrow-back-ios'} width={20} height={20} />
              </IconButton>
            </span>
          </Tooltip>
        }
      />
    );
  } else {
    const { displayText } = props;

    return (
      <Stack data-cy="cy-navigation-stepper-vertical" direction="row" alignItems="center">
        <Typography display="inline">{displayText}</Typography>

        <Tooltip title={nextStepTitle}>
          <span>
            <IconButton onClick={handleNext} disabled={activeStep === maxSteps - 1}>
              <Iconify icon="mdi:chevron-up" />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title={backStepTitle}>
          <span>
            <IconButton onClick={handleBack} disabled={activeStep === 0}>
              <Iconify icon="mdi:chevron-down" />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    );
  }
}
