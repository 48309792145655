import { useContext } from 'react';
import { EsgAnalysisFilterContext } from '../contexts/EsgAnalysisFilterContext';

const useEsgAnalysisFilters = () => {
  const context = useContext(EsgAnalysisFilterContext);

  if (!context) {
    throw new Error('useEsgAnalysisFilters must be used within a EsgAnalysisFilterContextProvider');
  }

  return context;
};

export default useEsgAnalysisFilters;
