import { graphql } from '@predium/client-graphql';

export const CREATE_PORTFOLIO = graphql(`
  mutation PortfolioManagementCreatePortfolio($name: String!, $emissionFactorType: emission_factor_type_enum) {
    insert_portfolio_one(object: { name: $name, emission_factor_type_id: $emissionFactorType }) {
      id
    }
  }
`);

export const DELETE_PORTFOLIO = graphql(`
  mutation PortfolioManagementDeletePortfolio($id: Int!) {
    delete_portfolio_by_pk(id: $id) {
      __typename
      id
    }
  }
`);

export const UPDATE_PORTFOLIO = graphql(`
  mutation PortfolioManagementUpdatePortfolio(
    $name: String!
    $emissionFactorType: emission_factor_type_enum
    $id: Int!
  ) {
    update_portfolio_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, emission_factor_type_id: $emissionFactorType }
    ) {
      id
    }
  }
`);
