import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { DataCollectionSubBuildingConsumptionFragment } from '@predium/client-graphql';
import { convertStandardUnitsToUserInput, convertUserInputToStandardUnits } from '@predium/client-lookup';
import {
  consumption_sub_type_enum,
  consumption_type_enum,
  consumption_unit_enum,
  energy_source_type_enum,
} from '@predium/enums';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import { FieldErrorsImpl, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import { FormProvider } from '../../../../components/hook-form';
import {
  DELETE_SINGLE_WASTE_DETAILS,
  INSERT_SINGLE_WASTE_DETAILS,
  UPDATE_SINGLE_CONSUMPTION,
  UPDATE_SINGLE_WASTE_DETAILS,
} from '../../../../graphql/DataCollection.mutations';
import { GET_BUILDING, GET_CONSUMPTION_SUMMARIES } from '../../../../graphql/DataCollection.queries';

export type ConsumptionWasteDetailsFormData = {
  id?: number;
  volume: number;
  displayUnitVolume?: consumption_unit_enum;
  frequency: number;
  displayUnitFrequency?: consumption_unit_enum;
  amount: number;
  price: number;
};

export type SingleConsumptionFormData = {
  id: number;
  buildingId: number;
  // subBuildingId: number;

  provider: string;
  from: string;
  to: string;
  areaId: number;

  consumptionDrafts: {
    id?: number;
    consumptionTypeId: consumption_type_enum;
    energySourceTypeId?: energy_source_type_enum;
    subTypeId?: consumption_sub_type_enum;
    value?: number;
    displayUnitValue?: consumption_unit_enum;
    cost?: number;

    // TRASH CONSUMPTION ONLY
    waste?: ConsumptionWasteDetailsFormData;
  }[];
};

type Props = {
  buildingId: number;
  subBuildingId: number;
  consumptionData: DataCollectionSubBuildingConsumptionFragment;
  renderChildren: (
    submitForm: () => Promise<void>,
    errors: Partial<FieldErrorsImpl<SingleConsumptionFormData>>,
  ) => JSX.Element;
  handleClose: () => void;
};

export default function SubBuildingSingleConsumptionForm({
  buildingId,
  subBuildingId,
  consumptionData,
  renderChildren,
  handleClose,
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [saveConsumption] = useMutation(UPDATE_SINGLE_CONSUMPTION, {
    onError: () => {
      enqueueSnackbar(t('DataCollectionSubBuildingConsumption_SaveChanges-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
    onCompleted: () => {
      enqueueSnackbar(t('General_ChangesSaved'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
    refetchQueries: [
      {
        query: GET_BUILDING,
        variables: {
          buildingId: buildingId,
          year: new Date().getFullYear(),
        },
      },
      {
        query: GET_CONSUMPTION_SUMMARIES,
        variables: { buildingId: buildingId, year: new Date().getFullYear() },
      },
    ],
  });
  const [createWasteDetails] = useMutation(INSERT_SINGLE_WASTE_DETAILS);
  const [updateWasteDetails] = useMutation(UPDATE_SINGLE_WASTE_DETAILS);
  const [removeWasteDetails] = useMutation(DELETE_SINGLE_WASTE_DETAILS);

  const ENERGY_SOURCE_TYPE_REQUIRED_MESSAGE = t('DataCollection_ValidationEnergySourceTypeRequired');
  const VALUE_REQUIRED_MESSAGE = t('DataCollection_ValidationValueRequired');
  const COST_REQUIRED_MESSAGE = t('DataCollection_ValidationValueRequired');
  const SUB_TYPE_REQUIRED_MESSAGE = t('DataCollection_ValidationSubTypeRequired');
  const CONSUMPTION_TYPE_MESSAGE = t('DataCollection_ValidationConsumptionTypeRequired');
  const DISPLAYUNIT_VALUE_MESSAGE = t('DataCollection_ValidationConsumptionDisplayUnitValue');
  const PROVIDER_MESSAGE = t('DataCollection_ValidationProviderRequired');
  const INVOICE_PERIOD_MESSAGE = t('DataCollection_ValidationInvoicePeriodRequired');
  const AREA_MESSAGE = t('DataCollection_ValidationAreaRequired');
  const INVOICE_DATE_BIS_VON_MESSAGE = t('DataCollection_ValidationInvoiceDateFromTo');
  const CONSUMPTIONS_REQUIRED_MESSAGE = t('DataCollection_ValidationConsumptionsRequired');
  const moreThanZeroMessage = t('General_MinRangeMessage', {
    min: 0,
  });

  const DataSchema = constructConsumptionValidationSchema({
    t,
    literalTranslations: {
      ENERGY_SOURCE_TYPE_REQUIRED_MESSAGE,
      VALUE_REQUIRED_MESSAGE,
      COST_REQUIRED_MESSAGE,
      SUB_TYPE_REQUIRED_MESSAGE,
      CONSUMPTION_TYPE_MESSAGE,
      DISPLAYUNIT_VALUE_MESSAGE,
      PROVIDER_MESSAGE,
      INVOICE_PERIOD_MESSAGE,
      AREA_MESSAGE,
      INVOICE_DATE_BIS_VON_MESSAGE,
      CONSUMPTIONS_REQUIRED_MESSAGE,
      moreThanZeroMessage,
    },
  });

  const {
    id,
    // sub_building_id,
    area_id,
    from,
    to,
    provider,
    consumption_type_id,
    cost,
    energy_source_type_id,
    sub_type_id,
    value,
    display_unit_value,
    consumption_waste_detail,
  } = consumptionData ?? {};

  const methods = useForm<SingleConsumptionFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    resolver: yupResolver(DataSchema),
    shouldFocusError: false,
    defaultValues: {
      id,
      buildingId,
      // subBuildingId: sub_building_id,

      // TODO: Why? Set it on the database as defined later after migration
      provider: provider!,
      from,
      to,
      areaId: area_id,

      consumptionDrafts: [
        {
          id,
          consumptionTypeId: consumption_type_id,
          energySourceTypeId: energy_source_type_id ?? undefined,
          subTypeId: sub_type_id ?? undefined,
          value:
            value && display_unit_value
              ? convertStandardUnitsToUserInput(
                  consumption_type_id,
                  display_unit_value,
                  value,
                  (energy_source_type_id ?? sub_type_id)!,
                )
              : value ?? undefined,
          displayUnitValue: display_unit_value ?? ('' as consumption_unit_enum),
          cost: cost ?? undefined,

          // TRASH CONSUMPTION ONLY, OPTIONAL
          waste: consumption_waste_detail
            ? {
                id: consumption_waste_detail?.id ?? undefined,
                volume:
                  consumption_waste_detail?.volume && consumption_waste_detail.display_unit_volume
                    ? convertStandardUnitsToUserInput(
                        consumption_type_id,
                        consumption_waste_detail.display_unit_volume,
                        consumption_waste_detail.volume,
                        (energy_source_type_id ?? sub_type_id)!,
                      )
                    : consumption_waste_detail?.volume ?? undefined,
                displayUnitVolume: consumption_waste_detail?.display_unit_volume ?? ('' as consumption_unit_enum),
                frequency:
                  consumption_waste_detail?.frequency && consumption_waste_detail.display_unit_frequency
                    ? convertStandardUnitsToUserInput(
                        consumption_type_id,
                        consumption_waste_detail.display_unit_frequency,
                        consumption_waste_detail.frequency,
                        (energy_source_type_id ?? sub_type_id)!,
                      )
                    : consumption_waste_detail?.frequency ?? undefined,
                displayUnitFrequency: consumption_waste_detail?.display_unit_frequency ?? ('' as consumption_unit_enum),
                amount: consumption_waste_detail?.amount ?? undefined,
                price: consumption_waste_detail?.price ?? undefined,
              }
            : undefined,
        },
      ],
    },
  });

  const {
    handleSubmit,
    getValues,
    formState: { errors },
  } = methods;

  /**
   *
   * @param data The forms native data after validation passes
   *
   * The following process is done for updating an existing consumption.
   *
   * 1. update the consumption
   * 2. If waste details are set create them if they don't exist, update them if they do
   * 3. Reset form with values from the network result
   *
   */
  const saveForm = async (data: SingleConsumptionFormData) => {
    const {
      // subBuildingId: subBuildingIdFromForm,
      from,
      to,
      areaId,
      provider,
      consumptionDrafts,
    } = data;
    const { consumptionTypeId, energySourceTypeId, subTypeId, value, displayUnitValue, cost, waste } =
      consumptionDrafts[0];

    // 1. update the consumption
    const { data: mutationResult } = await saveConsumption({
      variables: {
        consumptionId: data.id!,
        areaId,
        from: from || undefined,
        to: to || undefined,
        value:
          displayUnitValue && value
            ? convertUserInputToStandardUnits(
                consumptionTypeId,
                displayUnitValue,
                value,
                (energySourceTypeId ?? subTypeId)!,
              )
            : value,
        displayUnitValue: displayUnitValue || undefined,
        cost,
        provider: provider || undefined,
        consumptionTypeId,
        energySourceTypeId,
        subTypeId,
      },
    });

    const {
      id,
      area_id,
      source_type_id,
      consumption_type_id,
      created_by_user_id,
      energy_source_type_id,
      sub_type_id,
      from: fromAfterUpdate,
      to: toAfterUpdate,
      value: valueAfterUpdate,
      display_unit_value: displayUnitValueAfterUpdate,
      cost: costAfterUpdate,
      provider: providerAfterUpdate,
      consumption_waste_detail,
    } = mutationResult!.update_consumption_by_pk!;

    const returnData = {
      consumptionId: id,

      subBuildingId,
      areaId: area_id!,
      sourceTypeId: source_type_id,
      consumptionTypeId: consumption_type_id!,
      userId: created_by_user_id!,
      from: fromAfterUpdate!,
      to: toAfterUpdate!,
      value: valueAfterUpdate!,
      displayUnitValue: displayUnitValueAfterUpdate!,
      cost: costAfterUpdate!,
      provider: providerAfterUpdate!,
      energySourceTypeId: energy_source_type_id!,
      subTypeId: sub_type_id!,
    };

    // 2. If waste details are set create them if they don't exist, update them if they do
    const wasteId = consumption_waste_detail?.id;

    const isWasteUnavailable = !waste && !wasteId;
    if (isWasteUnavailable) {
      return handleClose();
    }

    const isWasteRemoved = !waste && wasteId;
    if (isWasteRemoved) {
      await removeWasteDetails({
        variables: {
          id: wasteId,
        },
      });

      return handleClose();
    }

    if (!waste) {
      throw new Error('Waste details are required');
    }

    const wasteObject = {
      volume: waste.displayUnitVolume
        ? convertUserInputToStandardUnits(consumptionTypeId, waste.displayUnitVolume, waste.volume, subTypeId!)
        : waste.volume,
      displayUnitVolume: waste.displayUnitVolume || undefined,
      frequency: waste.displayUnitFrequency
        ? convertUserInputToStandardUnits(consumptionTypeId, waste.displayUnitFrequency, waste.frequency, subTypeId!)
        : waste.frequency,
      displayUnitFrequency: waste.displayUnitFrequency || undefined,
      amount: waste.amount,
      price: waste.price,
    };

    const isWastedAdded = !wasteId;
    if (isWastedAdded) {
      await createWasteDetails({
        variables: {
          consumptionId: returnData.consumptionId,
          ...wasteObject,
        },
      });

      return handleClose();
    }

    await updateWasteDetails({
      variables: {
        id: wasteId,
        ...wasteObject,
      },
    });

    return handleClose();
  };

  const submitForm = async () => {
    const values = getValues();
    return await saveForm(values);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(saveForm)}>
      {renderChildren(submitForm, errors)}
    </FormProvider>
  );
}

export const constructConsumptionValidationSchema = ({
  t,
  literalTranslations,
}: {
  t: TFunction;
  literalTranslations?: {
    ENERGY_SOURCE_TYPE_REQUIRED_MESSAGE: string;
    VALUE_REQUIRED_MESSAGE: string;
    COST_REQUIRED_MESSAGE: string;
    SUB_TYPE_REQUIRED_MESSAGE: string;
    CONSUMPTION_TYPE_MESSAGE: string;
    DISPLAYUNIT_VALUE_MESSAGE: string;
    PROVIDER_MESSAGE: string;
    INVOICE_PERIOD_MESSAGE: string;
    AREA_MESSAGE: string;
    INVOICE_DATE_BIS_VON_MESSAGE: string;
    CONSUMPTIONS_REQUIRED_MESSAGE: string;
    moreThanZeroMessage: string;
  };
}) => {
  const {
    ENERGY_SOURCE_TYPE_REQUIRED_MESSAGE,
    VALUE_REQUIRED_MESSAGE,
    COST_REQUIRED_MESSAGE,
    SUB_TYPE_REQUIRED_MESSAGE,
    CONSUMPTION_TYPE_MESSAGE,
    DISPLAYUNIT_VALUE_MESSAGE,
    PROVIDER_MESSAGE,
    INVOICE_PERIOD_MESSAGE,
    AREA_MESSAGE,
    INVOICE_DATE_BIS_VON_MESSAGE,
    CONSUMPTIONS_REQUIRED_MESSAGE,
    moreThanZeroMessage,
  } = {
    ENERGY_SOURCE_TYPE_REQUIRED_MESSAGE: t('DataCollection_ValidationEnergySourceTypeRequired'),
    VALUE_REQUIRED_MESSAGE: t('DataCollection_ValidationValueRequired'),
    COST_REQUIRED_MESSAGE: t('DataCollection_ValidationValueRequired'),
    SUB_TYPE_REQUIRED_MESSAGE: t('DataCollection_ValidationSubTypeRequired'),
    CONSUMPTION_TYPE_MESSAGE: t('DataCollection_ValidationConsumptionTypeRequired'),
    DISPLAYUNIT_VALUE_MESSAGE: t('DataCollection_ValidationConsumptionDisplayUnitValue'),
    PROVIDER_MESSAGE: t('DataCollection_ValidationProviderRequired'),
    INVOICE_PERIOD_MESSAGE: t('DataCollection_ValidationInvoicePeriodRequired'),
    AREA_MESSAGE: t('DataCollection_ValidationAreaRequired'),
    INVOICE_DATE_BIS_VON_MESSAGE: t('DataCollection_ValidationInvoiceDateFromTo'),
    CONSUMPTIONS_REQUIRED_MESSAGE: t('DataCollection_ValidationConsumptionsRequired'),
    moreThanZeroMessage: t('General_MinRangeMessage', {
      min: 0,
    }),
    ...(literalTranslations ?? {}),
  };

  return Yup.object().shape({
    consumptionDrafts: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number(),
          consumptionTypeId: Yup.string()
            .oneOf(Object.values(consumption_type_enum))
            .typeError(CONSUMPTION_TYPE_MESSAGE)
            .required(CONSUMPTION_TYPE_MESSAGE),
          energySourceTypeId: Yup.string().when('consumptionTypeId', {
            is: (consumption_type: consumption_type_enum) => {
              return (
                consumption_type === consumption_type_enum.HEATING ||
                consumption_type === consumption_type_enum.ELECTRICITY
              );
            },
            then: Yup.string().required(ENERGY_SOURCE_TYPE_REQUIRED_MESSAGE),
          }),
          subTypeId: Yup.string().when('consumptionTypeId', {
            is: (consumption_type: consumption_type_enum) => {
              return (
                consumption_type === consumption_type_enum.WASTE || consumption_type === consumption_type_enum.WATER
              );
            },
            then: Yup.string().required(SUB_TYPE_REQUIRED_MESSAGE),
          }),
          value: Yup.number().when('waste', {
            is: (waste: any) => waste === undefined || Object.values(waste).every((value) => value === undefined),
            then: Yup.number().required(VALUE_REQUIRED_MESSAGE),
            otherwise: Yup.number(),
          }),
          displayUnitValue: Yup.string().when('value', {
            is: (value: number | undefined) => value !== undefined,
            then: Yup.string().oneOf(Object.values(consumption_unit_enum), DISPLAYUNIT_VALUE_MESSAGE),
            otherwise: Yup.string(),
          }),
          cost: Yup.number().when('waste', {
            is: (waste: any) => waste === undefined || Object.values(waste).every((value) => value === undefined),
            then: Yup.number().required(COST_REQUIRED_MESSAGE),
            otherwise: Yup.number(),
          }),

          // TRASH CONSUMPTION ONLY
          waste: Yup.object().when({
            is: (waste: any | undefined) => waste !== undefined,
            then: Yup.object().shape({
              id: Yup.number(),
              volume: Yup.number().required(VALUE_REQUIRED_MESSAGE),
              displayUnitVolume: Yup.string().oneOf(Object.values(consumption_unit_enum), DISPLAYUNIT_VALUE_MESSAGE),
              frequency: Yup.number().moreThan(0, moreThanZeroMessage).required(VALUE_REQUIRED_MESSAGE),
              displayUnitFrequency: Yup.string().oneOf(Object.values(consumption_unit_enum), DISPLAYUNIT_VALUE_MESSAGE),
              amount: Yup.number().required(VALUE_REQUIRED_MESSAGE),
              price: Yup.number().required(VALUE_REQUIRED_MESSAGE),
            }),
            otherwise: Yup.object().nullable(true),
          }),
        }),
      )
      .min(1, CONSUMPTIONS_REQUIRED_MESSAGE),

    from: Yup.date().typeError(INVOICE_PERIOD_MESSAGE).required(INVOICE_PERIOD_MESSAGE),
    to: Yup.date()
      .min(Yup.ref('from'), INVOICE_DATE_BIS_VON_MESSAGE)
      .typeError(INVOICE_PERIOD_MESSAGE)
      .required(INVOICE_PERIOD_MESSAGE),
    provider: Yup.string().typeError(PROVIDER_MESSAGE).required(PROVIDER_MESSAGE),
    areaId: Yup.number().typeError(AREA_MESSAGE).required(AREA_MESSAGE),
  });
};
