import { country_enum, energy_source_type_enum } from '@predium/enums';

//values in €/kwh
type CountryEnergySources = Record<energy_source_type_enum, number>;
const lookup: Record<country_enum, CountryEnergySources> = {
  [country_enum.DE]: {
    [energy_source_type_enum.NATURAL_GAS]: 0.0934,
    [energy_source_type_enum.LPG]: 0.0879,
    [energy_source_type_enum.FUEL_OIL]: 0.1236,
    [energy_source_type_enum.WOODEN_PELLETS]: 0.0979,
    [energy_source_type_enum.WOOD]: 0.0979,
    [energy_source_type_enum.ELECTRICITY_MIX]: 0.2675,
    [energy_source_type_enum.ELECTRICITY_GREEN]: 0.282,
    [energy_source_type_enum.SOLAR]: 0,
    [energy_source_type_enum.BIO_GAS]: 0.212,
    [energy_source_type_enum.LIGNITE]: 0.113,
    [energy_source_type_enum.COAL]: 0.111,
    [energy_source_type_enum.UNSPECIFIED]: 0.282,

    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]: 0.0987,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]: 0.0987,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]: 0.1459,

    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]: 0.0987,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]: 0.0987,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: 0.1459,
  },
  [country_enum.PL]: {
    [energy_source_type_enum.NATURAL_GAS]: 0.0934,
    [energy_source_type_enum.LPG]: 0.0879,
    [energy_source_type_enum.FUEL_OIL]: 0.1236,
    [energy_source_type_enum.WOODEN_PELLETS]: 0.0979,
    [energy_source_type_enum.WOOD]: 0.0979,
    [energy_source_type_enum.ELECTRICITY_MIX]: 0.2675,
    [energy_source_type_enum.ELECTRICITY_GREEN]: 0.282,
    [energy_source_type_enum.SOLAR]: 0,
    [energy_source_type_enum.BIO_GAS]: 0.212,
    [energy_source_type_enum.LIGNITE]: 0.113,
    [energy_source_type_enum.COAL]: 0.111,
    [energy_source_type_enum.UNSPECIFIED]: 0.282,

    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]: 0.0987,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]: 0.0987,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]: 0.1459,

    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]: 0.0987,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]: 0.0987,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: 0.1459,
  },

  [country_enum.AT]: {
    [energy_source_type_enum.NATURAL_GAS]: 0.0934,
    [energy_source_type_enum.LPG]: 0.0879,
    [energy_source_type_enum.FUEL_OIL]: 0.1236,
    [energy_source_type_enum.WOODEN_PELLETS]: 0.0979,
    [energy_source_type_enum.WOOD]: 0.0979,
    [energy_source_type_enum.ELECTRICITY_MIX]: 0.2675,
    [energy_source_type_enum.ELECTRICITY_GREEN]: 0.282,
    [energy_source_type_enum.SOLAR]: 0,
    [energy_source_type_enum.BIO_GAS]: 0.212,
    [energy_source_type_enum.LIGNITE]: 0.113,
    [energy_source_type_enum.COAL]: 0.111,
    [energy_source_type_enum.UNSPECIFIED]: 0.282,

    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]: 0.0987,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]: 0.0987,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]: 0.1459,

    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]: 0.0987,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]: 0.0987,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: 0.1459,
  },
};

/**
 * Given the energy_consumption per square meter, the energy_source_type, and
 * a year, returns the costs per square meter.
 * */
export function getEnergyCostsAtYear(
  energy_consumption: number,
  energy_source_type: energy_source_type_enum,
  year: number,
  country: country_enum,
  postalCode?: string,
): number {
  if (year < 2020) {
    year = 2020;
  }

  //PRE-3210 TODO: remove this again once delta energy is happy and we support custom energy prices
  if (country === country_enum.DE && postalCode === '30559' && energy_source_type) {
    if (energy_source_type === energy_source_type_enum.NATURAL_GAS) {
      return 0.09 * energy_consumption * Math.pow(1.02, year - 2024);
    }
    if (energy_source_type === energy_source_type_enum.ELECTRICITY_MIX) {
      return 0.3 * energy_consumption * Math.pow(1.02, year - 2024);
    }
  }

  return lookup[country][energy_source_type] * energy_consumption * Math.pow(1.02, year - 2020);
}
