/* eslint-disable @typescript-eslint/ban-ts-comment */
import { data_source_type_enum } from '@predium/enums';
import merge from 'lodash/merge';
import { useCallback, useMemo } from 'react';
import { Control, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import useBuilding from '../Context/useBuilding';
import { EditableRowProps, StaticRowProps } from './Components/SquareMeterRow';
import { useRecalculate } from './RecalculationProvider';
import { useValidation } from './Validation/ValidationProvider';
import { AreaFormValues, AreaMutation } from './types';

export const useArea = (control?: Control<AreaFormValues>) => {
  const { hasEditAccess } = useBuilding();
  const { getValues } = useFormContext<AreaFormValues>();

  const areas = useWatch({ control, name: 'areas' });
  const { triggerValidation } = useValidation();
  const { recalculate } = useRecalculate();

  const {
    // https://github.com/react-hook-form/react-hook-form/issues/11788
    fields: unstable_fields,
    append,
    remove,
    update,
  } = useFieldArray({
    name: 'areas',
    control: control,
    // As we get mixed areas from the backend in a single array, and we need to loop over some of them
    // We can eliminate the need of relying on the index (which can change if we filter an array before mapping over it)
    // By having the ids, we can easily update the correct area
    // NOTE: only and only use id to find the right index and as a key for list rendering
    keyName: 'uid',
  });

  // if you have inputs registered, they will not be updated in the `fields` array,
  // so we need to merge them to have the fields with the correct values
  const fields = useMemo(
    () => merge([], unstable_fields, areas).slice(0, unstable_fields.length) as typeof unstable_fields,
    [unstable_fields, areas],
  );

  type Options = {
    skipValidation?: boolean;
    recalculate?: boolean;
  };

  const addArea = useCallback(
    (area: AreaMutation, options?: Options) => {
      append({ ...area, create: true });

      !options?.skipValidation && triggerValidation();
      options?.recalculate && recalculate();
    },
    [append, recalculate, triggerValidation],
  );

  const updateArea = useCallback(
    (areaUid: string, values: Partial<AreaFormValues['areas'][number]>, options?: Options) => {
      const areaIndex = fields.findIndex((area) => area.uid === areaUid);
      const currentArea = getValues('areas')[areaIndex];

      if (values.delete && currentArea.create) {
        remove(areaIndex);
      } else {
        update(areaIndex, {
          ...currentArea,
          ...values,
        });
      }

      !options?.skipValidation && triggerValidation();
      options?.recalculate && recalculate();
    },
    [fields, getValues, recalculate, remove, triggerValidation, update],
  );

  const removeArea = useCallback(
    (areaUid: string, options?: Options) => {
      updateArea(areaUid, { delete: true }, options);
    },
    [updateArea],
  );

  const getPropsForStaticArea = useCallback(
    (areaUid: string): StaticRowProps => {
      const areaIndex = fields.findIndex((a) => a.uid === areaUid);
      const area = fields[areaIndex];

      return {
        value: area.value,

        //@ts-ignore
        source: area.data_source_id,
        areaType: area.area_type_id,
      };
    },
    [fields],
  );

  const getPropsForEditableArea = useCallback(
    (areaUid: string): EditableRowProps | StaticRowProps => {
      if (!hasEditAccess) {
        return getPropsForStaticArea(areaUid);
      }

      const areaIndex = fields.findIndex((a) => a.uid === areaUid);
      const area = fields[areaIndex];

      const name = `areas.${areaIndex}.value` as const;

      return {
        name,
        onClickAway: ({ hasChangedByUser, isDirty }) => {
          if (hasChangedByUser && isDirty) {
            updateArea(areaUid, { data_source_id: data_source_type_enum.MANUAL }, { skipValidation: true });
          }

          triggerValidation();
        },

        //@ts-ignore
        source: area.data_source_id,
        areaType: area.area_type_id,
      };
    },
    [fields, getPropsForStaticArea, hasEditAccess, triggerValidation, updateArea],
  );

  return { areas, fields, addArea, updateArea, removeArea, getPropsForEditableArea, getPropsForStaticArea };
};
